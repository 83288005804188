import { useEffect, useState } from 'react'
import { FormControl, RadioGroup, FormControlLabel, Typography, styled, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

/** API */

/** COMPONENT */
import InputRadio from 'component/Input/InputRadio'

/** STYLE */
import { colors } from 'constants/theme';

export const FormFormatReceipt = styled(FormControlLabel)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: 500
  },
  '&.active': {
    backgroundColor: colors.themeSecondColor06,
    '.MuiTypography-root': {
      color: colors.themeSecondColor
    }
  }
}))

export interface ApplicationInterface {
  medShowType: any
  pdShowType: any
  callbackMedShowType: (value: any) => void
  callbackPdShowType: (value: any) => void
  key: any
}
export default function FormTreatment(props: ApplicationInterface) {
  const { t } = useTranslation()
  const [medShowType, setMedShowType] = useState<any>('')
  const [pdShowType, setPdShowType] = useState<any>('')

  useEffect(() => {

    setMedShowType(props.medShowType)
  }, [props.medShowType])

  useEffect(() => {

    setPdShowType(props.pdShowType)
  }, [props.pdShowType])

  useEffect(() => {
    props.callbackMedShowType(medShowType)
    props.callbackPdShowType(pdShowType)
  }, [medShowType, pdShowType])

  return (
    <div key={props.key} className="px-4 pt-4 pb-4">
      <FormControl className="d-block pl-3">
        <Typography className='mb-1' sx={{ fontWeight: 500 }}>{t('CLINIC_MANAGEMENT.FORM.MED_SHOW_TYPE')}</Typography>
        <RadioGroup row aria-label="defaultReceipt" onChange={(event) => setMedShowType(event.target.value)} className="flex-row" name="row-radio-buttons-group">
          <Grid container spacing={2}>
            <Grid item xs={3.2}><FormFormatReceipt className={`ml-0 mr-2 pr-3 ${medShowType === "1" ? 'active' : ''}`} checked={medShowType === "1"} value="1" control={<InputRadio />} label={t('CLINIC_MANAGEMENT.FORM.MED_TYPE_TABLE')} /></Grid>
            <Grid item xs={3.2}><FormFormatReceipt className={`ml-0 mr-2 pr-3 ${medShowType === "0" ? 'active' : ''}`} checked={medShowType === "0"} value="0" control={<InputRadio />} label={t('CLINIC_MANAGEMENT.FORM.MED_TYPE_IMAGE')} /></Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <FormControl className="d-block pl-3">
        <Typography className='mb-1' sx={{ fontWeight: 500 }}>{t('CLINIC_MANAGEMENT.FORM.PD_SHOW_TYPE')}</Typography>
        <RadioGroup row aria-label="defaultReceipt" onChange={(event) => setPdShowType(event.target.value)} className="flex-row" name="row-radio-buttons-group">
          <Grid container spacing={2}>
            <Grid item xs={3.2}> <FormFormatReceipt className={`ml-0 mr-2 pr-3 ${pdShowType === "1" ? 'active' : ''}`} checked={pdShowType === "1"} value="1" control={<InputRadio />} label={t('CLINIC_MANAGEMENT.FORM.PD_TYPE_TABLE')} /></Grid>
            <Grid item xs={3.2}><FormFormatReceipt className={`ml-0 mr-2 pr-3 ${pdShowType === "0" ? 'active' : ''}`} checked={pdShowType === "0"} value="0" control={<InputRadio />} label={t('CLINIC_MANAGEMENT.FORM.PD_TYPE_IMAGE')} /></Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </div>
  )
}
