import { Box, Grid, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import * as useStyles from 'features/admin/clinic-management/style'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import { icons, Icons } from 'constants/images'
import { FormControl, RadioGroup, FormControlLabel, Typography, styled } from '@mui/material';
import InputRadio from 'component/Input/InputRadio'
import InputCheckbox from 'component/Input/InputCheckbox'

/** STYLE */
import { colors } from 'constants/theme';

export const FormFormatReceipt = styled(FormControlLabel)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: 500
  },
  '&.active': {
    backgroundColor: colors.themeSecondColor06,
    '.MuiTypography-root': {
      color: colors.themeSecondColor
    }
  }
}))
interface Data {
  onchange?: (value: any) => void
  onkeypress?: (value: any) => void
  valArr?: any
  error?: any
  optionCnNumber?: any
  cnNumber?: any
  branchCnNumbers?: any
  resetErrorMessage: () => void
}

export default function FromPatientId(props: Data) {
  const { t } = useTranslation()
  const [dataABout, setDataAbout] = useState<any>(props.valArr)
  const [optionCnNumber, setoptionCnNumber] = useState('')
  const [cnNumber, setcnNumber] = useState('')
  const [branchCnNumbers, setbranchCnNumbers] = useState<any>(props.branchCnNumbers)
  const [disabledCount, setDisabledCount] = useState(true)
  const [disabledBranch, setDisabledBranch] = useState<any>([])
  const [medShowType, setMedShowType] = useState<any>("0")
  const [tab, setTab] = useState('about')

  useEffect(() => {
    if (props.onchange) props.onchange(dataABout)
  }, [dataABout])

  const handleChange = (index: any) => (e: any) => {
    const newArr = dataABout.branchCnNumbers.map((item: any, i: any) => {
      if (index === i) {
        return { ...item, branchCnNumber: e.target.value }
      } else {
        return item
      }
    })
    setDataAbout({ ...dataABout, branchCnNumbers: newArr })
  }

  useEffect(() => {
    setDataAbout(props.valArr)
    setoptionCnNumber(props.optionCnNumber)
    setcnNumber(props.cnNumber)
    setbranchCnNumbers(props.branchCnNumbers)
  }, [props.valArr, props.optionCnNumber, props.cnNumber, props.branchCnNumbers])

  const diasbleCountCheck = () => {
    if (disabledCount === true) {
      setDisabledCount(false)
    } else {
      setDisabledCount(true)
    }
  }

  const diasbleBranchCheck = (index: number) => {
    if (disabledBranch.includes(index)) {
      const del = disabledBranch.findIndex((d: any) => d.id === index);
      disabledBranch.splice(del, 1);
    } else {
      setDisabledBranch([...disabledBranch, Number(index)])
    }
  }

  useEffect(() => {
    return
  }, [disabledBranch])

  return (
    <div className="px-4 pt-4 pb-4">
      <useStyles.TitleFirst>
        <p className={`d-flex align-items-center mb-3`}>{t('CLINIC_MANAGEMENT.TITLE.CN_NUMBER')}</p>
      </useStyles.TitleFirst>
      <div className="pl-2">
        <div className={`d-flex`}>
          <div className='p-2' style={{ whiteSpace: 'nowrap' }}>
            {t('CLINIC_MANAGEMENT.TITLE.PASS_CN_NUMBER')}
          </div>
          <div className='p-2'>
            {cnNumber}
          </div>
          <div className='p-2'>
            <useStyles.img style={{ paddingTop: '2px' }} onClick={diasbleCountCheck}>
              {disabledCount === false && (
                <Box className="align-baseline" sx={{ svg: { width: 17, height: 16 } }}>
                  <Icons.DropdownEditActive />
                </Box>
              ) || (
                  <img src={icons.dropdownEdit} alt="dropdownEdit" className="align-baseline" style={{ width: 17, height: 16 }} />
                )}
            </useStyles.img>
          </div>
          <Grid container spacing={2} pl={1}>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <InputTextField
                disabled={disabledCount}
                required
                inputProps={{ maxLength: 7 }}
                value={dataABout?.cnNumber}
                name="ลำดับรหัสผู้ป่วยเริ่มต้น"
                label={t('CLINIC_MANAGEMENT.FORM.COUNT_NUMBER')}
                onchange={(e) => {
                  setDataAbout({ ...dataABout, cnNumber: e.target.value })
                  props.resetErrorMessage()
                }}
                helperText={props.error.CN_NUMBER}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <hr className="hr" />
      <div>
        <useStyles.TitleFirst>
          <p className={`d-flex align-items-center `}>{t('CLINIC_MANAGEMENT.FORM.CN_NUMBER_BR')}</p>
        </useStyles.TitleFirst>
        <div className="pl-2">
          <p className={`d-flex align-items-center mb-3`}>{t('CLINIC_MANAGEMENT.TITLE.TYPE_CN_NUMBER')}</p>
        </div>
        <div className="pb-3">
          <Grid container spacing={2} pl={1}>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <BasicSelect
                name={t('CLINIC_MANAGEMENT.FORM.TYPE_CN_NUMBER')}
                label={t('CLINIC_MANAGEMENT.FORM.TYPE_CN_NUMBER')}
                selectId={t('CLINIC_MANAGEMENT.FORM.TYPE_CN_NUMBER')}
                labelId={t('CLINIC_MANAGEMENT.FORM.TYPE_CN_NUMBER')}
                value={dataABout?.formatBranchCnNumber || ''}
                style={{ fontSize: '16px' }}
                options={[
                  <MenuItem key="1" value="7">
                    {optionCnNumber + '00001'}
                  </MenuItem>,
                  <MenuItem key="2" value="5">
                    00001
                  </MenuItem>
                ]}
                onchange={(e) => {
                  setDataAbout({ ...dataABout, formatBranchCnNumber: e.target.value })
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Row>
          <Col sm={3} className="pl-4" style={{ fontWeight: 500 }}>
            {t('CLINIC_MANAGEMENT.FORM.NAME_BR')}
          </Col>
          <Col sm={3} style={{ fontWeight: 500 }}>{t('CLINIC_MANAGEMENT.FORM.CN_NUMBER_BR_CURRENT')}</Col>
          <Col sm={3} style={{ fontWeight: 500 }} className={`d-flex align-items-center`}>{t('CLINIC_MANAGEMENT.FORM.CN_NUMBER_BR_FIRST')} <Box className="ml-3">
            <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.INVALID_ORDER')} />
          </Box></Col>
        </Row>
        {branchCnNumbers?.map((val: any, index: any) => {
          const bNumber = val.branchCnNumber ? val.branchCnNumber : '6500001'
          const cnNumber7 = bNumber
          const cnNumber5 = bNumber.substr(2, 7)
          return (
            <Row key={index}>
              <Col sm={3} className="pt-4 pl-4">
                {val.branchName}
              </Col>
              <Col sm={3} className="pt-4 d-flex">
                {val.branchCode} {dataABout?.formatBranchCnNumber === '7' ? cnNumber7 : cnNumber5}
                <useStyles.img className='pl-2' style={{ paddingTop: '2px' }} onClick={() => diasbleBranchCheck(val.branchId)} >
                  {disabledBranch.includes(val.branchId) === true && (
                    <Box className="align-baseline" sx={{ svg: { width: 17, height: 16 } }}>
                      <Icons.DropdownEditActive />
                    </Box>
                  ) || (
                      <img src={icons.dropdownEdit} alt="dropdownEdit" className="align-baseline" style={{ width: 17, height: 16 }} />
                    )}
                </useStyles.img>
              </Col>
              <Col sm={4} className="pt-4">
                <InputTextField
                  disabled={disabledBranch.includes(val.branchId) ? false : true}
                  inputProps={{ maxLength: dataABout?.formatBranchCnNumber }}
                  value={
                    (dataABout?.formatBranchCnNumber === '7' && dataABout.branchCnNumbers[index].branchCnNumber === val.branchCnNumber && dataABout.branchCnNumbers[index].branchCnNumber)
                    || (dataABout?.formatBranchCnNumber === '7' && dataABout.branchCnNumbers[index].branchCnNumber)
                    || dataABout.branchCnNumbers[index].branchCnNumber.substr(2, 7)
                  }
                  name={t('CLINIC_MANAGEMENT.FORM.CN_NUMBER_BR_FIRST')}
                  label={t('CLINIC_MANAGEMENT.FORM.CN_NUMBER_BR_FIRST')}
                  onchange={handleChange(index)}
                  helperText={props.error.BRANCH_CN_NUMBER}
                />
              </Col>
            </Row>
          )
        })}

      </div>
      <hr className="hr" />
      <div>
        <useStyles.TitleFirst>
          <p className={`d-flex align-items-center `}>{t('CLINIC_MANAGEMENT.FORM.SN_CODE_BR')}</p>
        </useStyles.TitleFirst>
        <div className='pl-4'>
          <Row>
            <Col className="pl-2">
              <InputCheckbox
                checked={dataABout?.snType === "1" ? true : false}
                onChange={(event) => {
                  setDataAbout({ ...dataABout, snType: event.target.checked ? "1" : "0" })
                }}
                label={t(`CLINIC_MANAGEMENT.FORM.USE_SN_CODE`)}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>

  )
}
