import { Box, IconButton } from "@mui/material"
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import InputTextField from "component/Input/InputTextField"
import InputTextarea from "component/Input/InputTextarea"
import BtnFacebook from "assets/button/BtnFacebook"
import * as UseStyles from 'features/application/manage-web/contact-us/style'
import MenuItem from '@mui/material/MenuItem'
import BasicSelect from "component/Select/BasicSelect"
import InputNewRangeTimePicker from "component/Input/InputNewRangeTimePicker"
import moment from "moment"
import { icons } from "constants/images"
import ButtonCustom from "component/Button/ButtonCustom"
import { Col, Row } from "react-bootstrap"
import BtnIG from "assets/button/BtnIG"
import BtnLine from "assets/button/BtnLine"
import BtnTiktok from "assets/button/BtnTiktok"
import BtnWebsite from "assets/button/BtnWebsite"
import { WebContactUsProps } from "api/application/manageWeb-interface"
import ManageWebApi from "api/application/manageWeb"
import { notiError, notiSuccess } from "component/notifications/notifications"
import BtnCopy from "assets/button/BtnCopy"
import Loading from "component/Loading"
import { insertDashesIphone } from "utils/app.utils"



type ContactUsFormProps = {
    dataContact?: any
    branchContact?: any
    index: number
    status: boolean
    setStatus: (value: any) => void
}

const ContactUsForm = (props: ContactUsFormProps) => {
    const initStateErrorMessage = {
        CN_NAME: ``,
        EMAIL: ``,
        PHONE: ``,
        DETAIL: ``,
    }
    const { t } = useTranslation()
    const t_CONTACT_US = "MANAGE_WEB.CONTACT_US"
    const objBranchTime = [
        {
            typetime: 'OPEN',
            timeStart: '00:00',
            timeEnd: '00:00',
            days: { sun: false, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false }
        }
    ]
    const [dataEdit, setDataEdit] = useState<any>(props.dataContact)
    const [branchTimes, setBranchTimes] = useState<any>([...objBranchTime])
    const dateOfWeek = [`Mon`, `Tue`, `Wed`, `Thu`, `Fri`, `Sat`, `Sun`]
    const officeTimes = [{}]
    const [statusAdd, setStatusAdd] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

    useEffect(() => {
        officetimes(props.dataContact.officeTimes, false)
        setErrorMessage(initStateErrorMessage)
    }, [props.dataContact]);

    const officetimes = useCallback((times, status) => {
        if (times === undefined) {
            setBranchTimes([...objBranchTime]);
        } else if (times) {
            const tempOfficeTime: any = []
            for (const officeTime in times) {
                if (Object.prototype.hasOwnProperty.call(times, officeTime)) {
                    const ot = times[officeTime];
                    if (ot) {
                        tempOfficeTime.push({
                            timeStart: ot.timeIn,
                            timeEnd: ot.timeOut,
                            typetime: ot.officeStatus,
                            days: {
                                sun: ot?.day.indexOf('Sun') !== -1 ? true : false,
                                mon: ot?.day.indexOf('Mon') !== -1 ? true : false,
                                tue: ot?.day.indexOf('Tue') !== -1 ? true : false,
                                wed: ot?.day.indexOf('Wed') !== -1 ? true : false,
                                thu: ot?.day.indexOf('Thu') !== -1 ? true : false,
                                fri: ot?.day.indexOf('Fri') !== -1 ? true : false,
                                sat: ot?.day.indexOf('Sat') !== -1 ? true : false
                            }
                        });
                    }
                }
            }
            if (dataEdit.contactCustomerId !== undefined) setBranchTimes(tempOfficeTime);
            if (status && dataEdit.contactCustomerId === undefined) setBranchTimes(tempOfficeTime);
        }
    }, []);


    const handleChangeBranchTimeType = (value: string, index: number) => {
        const branchTimeTemp: any = [...branchTimes]
        branchTimeTemp[index].typetime = value
        setBranchTimes(branchTimeTemp)
        setDataEdit({ ...dataEdit, officeTimes })
    }

    const handleChangeBranchTimeDate = (day: string, index: number) => {
        const branchTimeTemp: any = [...branchTimes]
        branchTimeTemp[index].days[day] = !branchTimeTemp[index].days[day]
        setBranchTimes(branchTimeTemp)
        setDataEdit({ ...dataEdit, officeTimes })
    }

    const handleChangeBranchTime = (times: any, index: number) => {
        const branchTimeTemp: any = [...branchTimes]
        branchTimeTemp[index].timeStart = times[0]
        branchTimeTemp[index].timeEnd = times[1]
        setBranchTimes(branchTimeTemp)
        setDataEdit({ ...dataEdit, officeTimes })
    }

    const addBranchTime = () => setBranchTimes([...branchTimes, ...objBranchTime])

    const deleteBranchTime = (e: any, index: number) => {
        const branchTimeTemp: any = []
        for (const iBt in branchTimes) {
            if (Object.prototype.hasOwnProperty.call(branchTimes, iBt)) {
                const element = branchTimes[iBt]
                if (Number(iBt) !== Number(index)) branchTimeTemp.push(element)
            }
        }
        setBranchTimes(branchTimeTemp)
    }

    const onPull = (status: boolean, item?: any) => {
        if (item) {
            officetimes(item.officeTimes, true)
            const newPayload: WebContactUsProps = {
                contactCustomerId: 0,
                branchId: item.branchId,
                name: item.branchName,
                email: item.branchEmail,
                phone: item.branchPhone,
                detail: item.branchFullAddress,
                addressMap: item.addressMap ?? '',
                website: '',
                tiktok: '',
                facebook: item.branchFacebook,
                line: item.branchLine,
                instagram: item.branchInstagram,
                officeTimes: item.officeTimes,
                isEdit: status,
                isPull: !status
            }
            setDataEdit({ ...newPayload })
        }
    }


    const onSave = async (i: number, branchId: number) => {
        const dateTimes = branchTimes.map((item: any) => {
            const { typetime, timeStart, timeEnd, days } = item;
            return Object.keys(days).map(day => {
                if (days[day]) {
                    return {
                        dayOfWeek: day.charAt(0).toUpperCase() + day.slice(1),
                        timeIn: typetime === 'OPEN' ? timeStart : '00:00',
                        timeOut: typetime === 'OPEN' ? timeEnd : '00:00',
                        officeStatus: typetime
                    };
                }
                return null;
            }).filter(Boolean);
        }).flat();
        const arr: any = [];
        dateTimes.forEach((e: any) => { arr.push(e.dayOfWeek) });
        const newPayload: WebContactUsProps = Object.assign(dataEdit, { branchId: branchId, officeTimes: [...dateTimes] })
        const id = dataEdit.contactCustomerId || 0

        if (!newPayload.name || !newPayload.email || !newPayload.phone || !newPayload.detail) {
            let keyName = ''
            if (!newPayload.name) keyName = 'name="name"'
            else if (!newPayload.email) keyName = 'name="email"'
            else if (!newPayload.phone) keyName = 'name="phone"'
            else if (!newPayload.detail) keyName = 'name="detail"'

            if (keyName) {
                const inputs: any = document.querySelector(`input[${keyName}]`);
                if (inputs) { inputs.focus(); }
            }

            return setErrorMessage({
                ...errorMessage,
                ...{
                    CN_NAME: !newPayload.name ? t(`${t_CONTACT_US}.VALIDATION.CN_NAME`) : '',
                    EMAIL: !newPayload.email ? t(`${t_CONTACT_US}.VALIDATION.EMAIL`) : '',
                    PHONE: !newPayload.phone ? t(`${t_CONTACT_US}.VALIDATION.PHONE`) : '',
                    DETAIL: !newPayload.detail ? t(`${t_CONTACT_US}.VALIDATION.DETAIL`) : '',
                }
            })
        }


        if (newPayload.name && newPayload.email && newPayload.phone && newPayload.detail && arr.every((item: string, index: number) => arr.indexOf(item) === index)) {
            setStatusAdd(true)
            if (id === 0) {
                delete dataEdit.contactCustomerId
                const res = await ManageWebApi.createWebContactUs(newPayload)
                if (res === "pass") {
                    onEdit(i, true, newPayload, 'pass')
                } else {
                    onEdit(i, true, newPayload, 'error')
                }
            } else {
                const res = await ManageWebApi.updateWebContactUs(newPayload.branchId, newPayload)
                if (res === "pass") {
                    onEdit(i, true, newPayload, 'pass')
                } else {
                    onEdit(i, true, newPayload, 'error')
                }
            }
            setStatusAdd(false)
        } else {
            notiError(t(`${t_CONTACT_US}.NOTI_DAY`))
        }
    }



    const onEdit = (i: number, status: boolean, item?: any, pass?: string) => {
        if (item && item.name !== undefined) {
            setDataEdit(item)
        }
        if (pass && item > 0) {
            setBranchTimes([...objBranchTime])
            props.setStatus({ status: status, newPayload: item, pass: 'cancel' })
        } else if (pass === undefined && item === 0) {
            setDataEdit({})
            setBranchTimes([...objBranchTime])
        } else {
            setBranchTimes([...objBranchTime])
            props.setStatus({ status: status, newPayload: item, pass: pass })
        }
    }


    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingRight: '16px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                        {t(`${t_CONTACT_US}.TITLE`)}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ButtonCustom
                            startIcon={<BtnCopy />}
                            style={{ marginRight: '4px' }}
                            variant={'outlined'}
                            textButton={t(`${t_CONTACT_US}.BTN_PULL`)}
                            onClick={() => {
                                onPull(false, props.branchContact[props.index])
                            }}
                        />
                        <ButtonCustom
                            style={{ marginRight: '4px' }}
                            variant={'outlined'}
                            textButton={t(`${t_CONTACT_US}.BTN_CANCEL`)}
                            onClick={() => {
                                onEdit(props.index, true, dataEdit.contactCustomerId)
                            }}
                        />
                        <ButtonCustom
                            textButton={t(`${t_CONTACT_US}.BTN_SAVE`)}
                            disabled={statusAdd}
                            onClick={() => {
                                onSave(props.index, props.branchContact[props.index].branchId)
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{ paddingTop: '8px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ paddingTop: '8px', width: '33%' }}>
                        <InputTextField
                            required
                            label={t(`${t_CONTACT_US}.NAME`)}
                            value={dataEdit?.name ?? ''}
                            onchange={(e) => {
                                setDataEdit({ ...dataEdit, name: e.target.value })
                            }}
                            helperText={errorMessage.CN_NAME}
                        />
                    </Box>
                    <Box sx={{ paddingTop: '8px', width: '33%' }}>
                        <InputTextField
                            required
                            label={t(`${t_CONTACT_US}.EMAIL`)}
                            value={dataEdit?.email ?? ''}
                            onchange={(e) => {
                                setDataEdit({ ...dataEdit, email: e.target.value })
                            }}
                            helperText={errorMessage.EMAIL}
                        />
                    </Box>
                    <Box sx={{ paddingTop: '8px', width: '33%' }}>
                        <InputTextField
                            required
                            label={t(`${t_CONTACT_US}.TELEPHONE`)}
                            value={dataEdit?.phone ?? ''}
                            onchange={(e) => {
                                setDataEdit({ ...dataEdit, phone: e.target.value })
                            }}
                            helperText={errorMessage.PHONE}
                        />
                    </Box>
                </Box>
                <Box sx={{ marginTop: '8px' }}>
                    <InputTextarea
                        required
                        label={t(`${t_CONTACT_US}.FULL_ADDRESS`)}
                        value={dataEdit?.detail ?? ''}
                        onchange={(e) => {
                            setDataEdit({ ...dataEdit, detail: e.target.value })
                        }}
                        helperText={errorMessage.DETAIL}
                    />
                </Box>

                <Box style={{ paddingRight: '10px' }}>
                    <Row>
                        <Col xs={12} md={10} xl={10} >
                            {branchTimes.map((branchTime: any, iBranchTime: any) => (
                                <Row key={`row-${iBranchTime}`} className="mt-3">
                                    <Col xs={12} md={3} xl={3} >
                                        <BasicSelect
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value) {
                                                    handleChangeBranchTimeType(value, iBranchTime)
                                                }
                                            }}
                                            label={''}
                                            selectId="select-status"
                                            value={branchTime.typetime}
                                            labelId="label-status"
                                            options={[
                                                <MenuItem key="1" value="OPEN">
                                                    {t('BRANCH.INPUT.WORK_DATE_TYPE_OPEN')}
                                                </MenuItem>,
                                                <MenuItem key="2" value="CLOSE">
                                                    {t('BRANCH.INPUT.WORK_DATE_TYPE_CLOSE')}
                                                </MenuItem>
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={12} md={4} xl={4} >
                                        {dateOfWeek.map((day, i) => (
                                            <UseStyles.BtnDay
                                                key={day}
                                                className={`btn mx-1 text-nowrap text-center px-1 btn-day d-inline-flex align-items-center justify-content-center 
                                                 ${branchTime.days[day.toLowerCase()] ? 'active' : ''}
                                                 ${branchTime.typetime === 'OPEN' ? 'open' : 'close'}`}
                                                onClick={(e) => handleChangeBranchTimeDate(day.toLowerCase(), iBranchTime)}
                                            >
                                                {t(`DAY_SHORT_${day.toUpperCase()}`)}
                                            </UseStyles.BtnDay>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={3} xl={3} >
                                        <InputNewRangeTimePicker
                                            value={[moment(branchTime.timeStart, 'HH:mm').format('HH:mm'), moment(branchTime.timeEnd, 'HH:mm').format('HH:mm')]}
                                            onChange={(e) => handleChangeBranchTime(e, iBranchTime)}
                                            disabled={branchTime.typetime === 'CLOSE'}
                                            style={{ maxWidth: '300px' }}
                                        />
                                    </Col>
                                    <Col xs={12} md={1} xl={1} className="d-flex align-items-center justify-content-start">
                                        <IconButton
                                            className={'btn-delete'}
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            aria-label="delete"
                                            onClick={(e) => deleteBranchTime(e, iBranchTime)}
                                            sx={{
                                                backgroundColor: 'transparent !important',
                                                padding: 0,
                                                '&:disabled': {
                                                    filter: 'grayscale(1)',
                                                    opacity: 0.6
                                                }
                                            }}
                                        >
                                            <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                                        </IconButton>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                        <Col xs={2} md={2} xl={2} className="mt-3" align='start'>
                            <ButtonCustom
                                mode={'add'}
                                style={{ marginRight: '-5px' }}
                                variant={'outlined'}
                                textButton={t('BRANCH.TAB.ADD_ITEM_WORK')}
                                onClick={() => addBranchTime()}
                            />
                        </Col>
                    </Row>
                </Box>

                <Box sx={{ paddingTop: '20px' }}>
                    <InputTextField
                        label={t(`${t_CONTACT_US}.IFRAME`)}
                        value={dataEdit?.addressMap ?? ''}
                        onchange={(e) => {
                            setDataEdit({ ...dataEdit, addressMap: e.target.value })
                        }}
                    />
                </Box>
                <Box sx={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ fontWeight: '500' }}>
                        {t(`${t_CONTACT_US}.CHANNEL_CONTACT`)}
                    </Box>
                    <UseStyles.ContainerSocial>
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnWebsite color="#808080" width="28" height="28" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.WEBSITE`)}
                                value={dataEdit?.website ?? ''}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, website: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                    <UseStyles.ContainerSocial>
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnFacebook color="#808080" width="28" height="28" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.FACEBOOK`)}
                                value={dataEdit?.facebook ?? ''}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, facebook: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                    <UseStyles.ContainerSocial>
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnLine color="#808080" width="30" height="30" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.LINE`)}
                                value={dataEdit?.line ?? ''}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, line: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                    <UseStyles.ContainerSocial>
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnIG color="#808080" width="32" height="32" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.INSTAGRAM`)}
                                value={dataEdit?.instagram ?? ''}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, instagram: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                    <UseStyles.ContainerSocial >
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnTiktok color="#808080" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.TIKTOK`)}
                                value={dataEdit?.tiktok ?? ''}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, tiktok: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                </Box>
            </Box>
        </>
    )
}

export default ContactUsForm
