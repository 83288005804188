import _ from 'lodash'
import { styled } from '@mui/material/styles'
import styleds from 'styled-components'
import { useTranslation } from "react-i18next"
import { getBranchName, numberFormat, getClinicInfo } from "utils/app.utils";
import { useSelector } from "react-redux"
import { selectMe } from 'app/slice/user.slice'
import { dateToView } from "utils/date.utils"
import PersonnelApi from 'api/setting/personnel-managements/personnel.api';
import { useCallback, useEffect, useState } from 'react';
import { fileUrl } from 'api/api';

const Images = styled('div')(({ theme }) => ({
    width: 110,
    height: 65,
    margin: 'auto'
}))

const Styles = styleds.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: center;
      :last-child {
        border-right: 0;
      }
    }
  }
`

type Data = {
    data?: any
    patient?: any
    doctors?: any
    remark?: any
    createAt?: any
    title?: any
}

export default function PdfTreatmentPlan(props: Data) {
    const { t } = useTranslation()
    const branchName = getBranchName()
    const user = useSelector(selectMe)
    const data = getClinicInfo()
    const [signature, setSignature] = useState('')


    const renderImage = (img: any) => {
        if (img) return `${fileUrl}/${img}`
        else return ''
    }
    const loadData = useCallback(() => {
        if (!signature) PersonnelApi.findSignature().then((res) => {
            if (res.status === 200) {
                if (res.data) {
                    const { fullpath } = res.data
                    setSignature(renderImage(fullpath))
                }
            };
        })
    }, [props.data])
    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <body>
            <div className="pt-1">
                <Images>
                    <img src={data.branchLogo} alt="" style={{ maxWidth: '100%', maxHeight: '100%', alignItems: 'center', margin: 'auto', display: 'block' }} />
                </Images>
            </div>
            <div className="text-center pt-2" style={{ fontSize: 18, fontWeight: 500 }}>
                {t('TXP.TITLE')} {props.title}
            </div>
            <div className="text-center pt-2" style={{ paddingBottom: 10, borderBottom: '1px solid', fontWeight: 500 }}>
                {t('TXP.PRINT.BRANCH')} {branchName || '-'}
            </div>
            <div className="d-flex">
                <div className="pt-3" style={{ width: '50%' }}>
                    {t('TXP.PRINT.NAME')}  {props.patient.firstname} {props.patient.lastname} {props.patient?.branchCnNumber || ''}
                </div>
                <div className="pt-3" style={{ width: '50%' }}>
                    {t('TXP.PRINT.DATE')}  {props.createAt}
                </div>
            </div>

            <Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                    <thead>
                        <tr>
                            <th>{t('TXP.PRINT.TABLE.NO')}</th>
                            <th>{t('TXP.PRINT.TABLE.TEETH')}</th>
                            <th className="text-left">{t('TXP.PRINT.TABLE.TYPE')}</th>
                            <th className="text-left">{t('TXP.PRINT.TABLE.DETAIL')}</th>
                            <th className="text-left">{t('TXP.PRINT.TABLE.DATE_START')}</th>
                            <th>{t('TXP.PRINT.TABLE.TIME')}</th>
                            <th className="text-left">{t('TXP.PRINT.TABLE.PRICE')}</th>
                            <th className="text-left">{t('TXP.PRINT.TABLE.DOCTOR')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(props.data, (val: any, index: number) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td style={{ wordBreak: 'break-all' }}>{val.teeth || '-'}</td>
                                    <td className="text-left" style={{ wordBreak: 'break-all' }}>{val.operativeTypeName || '-'}</td>
                                    <td className="text-left" style={{ wordBreak: 'break-all' }}>{val.operativeName || '-'}</td>
                                    <td className="text-left">{val.dateUse ? dateToView(val.dateUse) : '-'}</td>
                                    <td>
                                        {val.duration}
                                        {
                                            (val.durationUnit === 'YEARS' && t('TXP.DATE.YEARS')) ||
                                            (val.durationUnit === 'MONTH' && t('TXP.DATE.MONTH')) ||
                                            (val.durationUnit === 'DAYS' && t('TXP.DATE.DAYS')) ||
                                            (val.durationUnit === 'HOURS' && t('TXP.DATE.HOURS')) ||
                                            t('TXP.DATE.MINUTES') || '-'
                                        }
                                    </td>
                                    <td className="text-left">{numberFormat(val.price) || '-'}</td>
                                    <td className="text-left">{_.find(props.doctors, { userId: val.doctorId })?.fullname || '-'}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Styles>
            <div className="pt-2">
                {t('TXP.PRINT.TABLE.REMARK')} {props.remark}
            </div>
            <footer >
                <div className="pt-3 text-center hard-break" style={{ width: '50%', marginLeft: 'auto', pageBreakAfter: "auto" }}>
                    <div className='row d-flex'>
                        {signature && <div className='col' style={{ marginLeft: 'auto', marginRight: 'auto' }}>

                            <img src={signature} alt="signature" style={{ width: 80, height: 50 }} /></div>}
                        <div  >{'(..................................................................)'}</div>
                    </div>
                    <div style={{ fontWeight: 500 }}>{user.fullname}</div>
                    <div>{t('TXP.PRINT.TABLE.NAME_EXPORT')}</div>
                </div>
            </footer>

        </body >

    )
}

