import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { MenuItem } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import RegisterAppApi from 'api/application/application.api'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { toBuddhistYear } from 'utils/app.utils'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import FilterSelect from 'component/Select/FilterSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { swalActive } from 'component/Alert/Swal'
import { notiSuccess, notiError } from 'component/notifications/notifications'

/** STYLE */
import * as UseStyled from 'features/application/application/useStyled'
import { Config } from 'config';

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function Applications() {
  const { t } = useTranslation()

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('DESC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const [isChecks, setIsChecks] = useState([] as any)
  const [isCheckAll, setIsCheckAll] = useState('')
  const [applications, setApplications] = useState([]);

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status } //fix filter status all
    const resp = await RegisterAppApi.findAll(condition)
    if (resp.status === 200) {
      setRowCount(resp.headers['x-total'])
      setApplications(resp.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const onActive = (id: number, value: { branchCnNumber: string, name: string }, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 475px">${t('APPLICATION.MESSAGE.ALERT.CONFIRM_STATUS')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 450px">${t(`APPLICATION.MESSAGE.ALERT.CONFIRM_STATUS_`, {
        name: `${value.branchCnNumber ? value.branchCnNumber : ''} ${value.name}` || '-',
        status: t(`STATUS.${valueStatus === '1' ? 'ACTIVE' : 'INACTIVE'}`)
      })}</p>`,
      (res: any) => {
        if (res) {
          RegisterAppApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('APPLICATION.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('APPLICATION.MESSAGE.ERROR.DEFAULT'))
            })
        }
      },
      'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onActiveByCheckbox = (id: Array<number>, valueStatus: string) => {
    const items = _.map(id, (i) => { return i.toString() })
    const domain = Config.REACT_APP_DOMAIN
    if (domain) {
      swalActive(
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('APPLICATION.MESSAGE.ALERT.CONFIRM_STATUS')}</p>`,
        `<p class="text-left mb-0 mx-auto" style="max-width: 325px">${t(`APPLICATION.MESSAGE.ALERT.CONFIRM_STATUS_PATIENTS`, {
          length: _.size(items) || '-'
        })}</p>`,
        (res: any) => {
          if (res) {
            RegisterAppApi.updateMultipleStatus(items, domain, valueStatus)
              .then((resp) => {
                notiSuccess(t('PATIENT_MANAGEMENT.MESSAGE.SUCCESS.UPDATE_STATUS'))
                loadData()
              })
              .catch((e) => {
                notiError(t('PATIENT_MANAGEMENT.MESSAGE.ERROR'))
              })
              .finally(() => {
                setIsChecks([])
              })
          }
        },
        'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
      )
    }
  }

  const onCheckAll = (event: any) => {
    const { checked } = event
    const id = _.map(applications, (i: any) => {
      return i.patientId
    })
    if (checked) setIsChecks(Array.from(new Set([...isChecks, ...id])))
    else setIsChecks(_.filter(isChecks, (i) => !_.includes(id, i)))
  }

  const onCheck = (event: any) => {
    const { checked, value } = event
    if (checked) setIsChecks([...isChecks, Number(value)])
    else {
      setIsChecks(
        _.filter(isChecks, (i: number) => {
          return i !== Number(value)
        })
      )
    }
  }

  const handleOnCheckAll = () => {
    const hasSome = _.some(applications, (i: any) => _.some(isChecks, (j: number) => Number(i.patientId) === Number(j)))
    const hasAll = _.every(applications, (i: any) => _.some(isChecks, (j: number) => Number(i.patientId) === Number(j)))
    if (!_.isEmpty(isChecks) && !hasAll && hasSome) setIsCheckAll('indeterminate')
    else if (!_.isEmpty(isChecks) && hasAll) setIsCheckAll('checked')
    else setIsCheckAll('')
  }

  useEffect(() => {
    handleOnCheckAll()
  }, [isChecks, applications])

  const headCells = [
    // {
    //   id: 'patientSelect',
    //   disablePadding: false,
    //   width: '70px',
    //   label: 'CHECKBOX',
    //   values: [
    //     { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`) },
    //     { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`) }
    //   ],
    //   indeterminate: isCheckAll === 'indeterminate' ? true : false,
    //   checked: isCheckAll === 'checked' ? true : false,
    //   disableDropdown: _.isEmpty(isChecks),
    //   onCheckAll: (event: any) => onCheckAll(event.target),
    //   onActive: () => onActiveByCheckbox(isChecks, '1'),
    //   onInactive: () => onActiveByCheckbox(isChecks, '0')
    // },
    { id: 'no', disablePadding: false, label: '#', width: '50px' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.CN_NUMBER'), width: '130px' },
    { id: 'branchId', disablePadding: false, label: t('APPLICATION.TABLE.CELL.PATIENT_ID'), width: '200px', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.FULL_NAME'), width: '230px' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.ID_CARD'), width: '190px' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.PHONE'), width: '130px' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.EMAIL'), width: '200px' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.CREATE_APP_AT'), width: '170px' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.UPDATED_APP_AT'), width: '190px' },
    { id: 'status', disablePadding: false, label: t('APPLICATION.TABLE.CELL.STATUS'), width: '150px' },
    { id: 'updatedBy', disablePadding: false, label: t('APPLICATION.TABLE.CELL.UPDATED_BY'), width: '180px' },
    { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { patientId, cnNumber, branchCnNumber, idCard, patientFullName, phone, email, premissionApp, updatedBy, updatedAt, createdAt } = objData
    const statusBtnActive = premissionApp === '1' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = premissionApp === '0' ? true : permissions.isUpdate.disabled
    const rowCheck = _.some(isChecks, (i: number) => Number(i) === Number(patientId))
    const objRenderData = {
      key: patientId,
      id: patientId,
      obj: objData,
      rowSelect: rowCheck,
      columns: [
        // { option: 'CHECKBOX', align: 'center', label: patientId },
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: cnNumber || '-' },
        { option: 'TEXT', align: 'left', label: branchCnNumber || '-' },
        { option: 'TEXT', align: 'left', label: patientFullName || '-' },
        { option: 'TEXT', align: 'left', label: idCard || '-' },
        { option: 'TEXT', align: 'left', label: phone || '-' },
        { option: 'TEXT', align: 'left', label: email || '-' },
        { option: 'TEXT', align: 'left', label: createdAt ? toBuddhistYear(moment(createdAt), 'DD/MM/YYYY HH.mm') : '10/12/2564 08.59' },
        { option: 'TEXT', align: 'left', label: updatedAt ? toBuddhistYear(moment(updatedAt), 'DD/MM/YYYY HH.mm') : '10/12/2564 08.59' },
        { option: 'STATUS', align: 'center', label: premissionApp === '1' ? 'ACTIVE' : 'INACTIVE' },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
          ]
        },
      ]
    }

    return (
      <TableRowCommon
        {...objRenderData}
        // onCheck={(event) => onCheck(event.target)}
        onactive={() => onActive(Number(objRenderData.id), { branchCnNumber: branchCnNumber, name: patientFullName }, '1')}
        oninactive={() => onActive(Number(objRenderData.id), { branchCnNumber: branchCnNumber, name: patientFullName }, '0')}
      />
    )
  }

  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t('APPLICATION.HEADER')} />
      <Card.Body>
        <Card.Title className={'mb-0 pl-xl-3'}>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={12} md={6} xl={3} className={'pt-2'}>
                  <InputTextSearch
                    keyInput={'search'}
                    label={t('INPUT.SEARCH')}
                    value={search}
                    onchange={(event) => {
                      setSearch(event.target.value)
                      setPage(1)
                    }}
                    onClear={(event) => {
                      setSearch('')
                      setPage(1)
                    }}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className={'pl-md-0 pt-2'}>
                  <FilterSelect
                    onchange={(event) => {
                      const value = event.target.value
                      if (value) {
                        handleChangeStatus(value)
                        setPage(1)
                      }
                    }}
                    renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status === 'ALL' && 'ALL' || (status === '1' && 'ACTIVE' || 'INACTIVE')}`)}`}
                    label={t('STATUS.LABEL')}
                    selectId="select-status"
                    value={status}
                    labelId="label-status"
                    options={[
                      <MenuItem key="1" value="ALL">
                        {t('STATUS.ALL')}
                      </MenuItem>,
                      <MenuItem key="2" value="1">
                        {t('STATUS.ACTIVE')}
                      </MenuItem>,
                      <MenuItem key="3" value="0">
                        {t('STATUS.INACTIVE')}
                      </MenuItem>
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Title>

        <UseStyled.TableResponsive className={'mt-3 pl-xl-3 pr-xl-2'}>
          <TableCustom
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            onSort={onRequestSort}
            setPageLimit={handleChangeRowsPerPage}
            setPage={handleChangePage}
            headCells={headCells}
            rowsData={(
              _.map(applications, (item, index: number) => {
                return renderData(item, index)
              })
            )}
            tableFixedWidth
            tableMinWidth={1600}
          />
        </UseStyled.TableResponsive>
      </Card.Body>
    </Card>
  )
}
