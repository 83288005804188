import moment from 'moment'
import _ from 'lodash'
import { Box, TableRow, TableCell, Typography } from '@mui/material'
import { colors } from 'constants/theme'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** INTERFACE */
import { TreatmentPanelProps, ItemRender, NoItem } from 'component/Treatments/PopupEmrOverview/PopupEmrOverview'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'

/** UTILS */
import { toBuddhistYear, numberFormat } from 'utils/app.utils'
import useWindowSize from 'utils/useWindowSize'

/** STYLE */
import * as UseStyled from 'component/Treatments/PopupEmrOverview/UseStyled'

type RenderDataProps = {
  no: number
  item: any
}

export default function EmrTxc(props: TreatmentPanelProps) {
  const { t } = useTranslation()
  const [width] = useWindowSize()

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('TXC.TABLE.CODE') },
    { id: 'message', disablePadding: false, label: t('TXC.TABLE.DETAIL_SERVICE') },
    { id: 'message', disablePadding: false, label: t('TXC.TABLE.ALL_TOTAL'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TXC.TABLE.QTY'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TXC.TABLE.PRICE'), align: 'right' },
    { id: 'message', disablePadding: false, label: t('TXC.TABLE.TOTAL'), align: 'right' },
  ]

  return (
    <>
      {_.map(props.treatments, (item: ItemRender, index: number) => {
        if (!_.isEmpty(item.treatmentCourse))
          return (
            <UseStyled.TreatmentBox key={index}>
              <UseStyled.LabelDate>{toBuddhistYear(moment(item.date), 'DD/MM/YY HH.mm')} {t('TIME_UNIT')}</UseStyled.LabelDate>
              <Box>
                {
                  _.map(item.treatmentCourse, (course: any, indexCourse: number) => {
                    return (
                      <Box
                        sx={{
                          '&:not(:last-of-type)': {
                            paddingBottom: '16px',
                            borderBottom: `1px solid ${colors.lightGray}`
                          }
                        }}>
                        <Box className='d-flex align-items-center flex-wrap mt-3 mb-1'>
                          <Typography className='d-xl-none pr-3' sx={{ flex: 'none', fontWeight: 600, fontSize: '18px' }}>{t('TXC.POPUP.TITLE_BY_USER')}</Typography>
                          <Typography sx={{ fontWeight: { lg: 500 }, fontSize: '18px' }}>{course?.treatmentCourseName}</Typography>
                        </Box>
                        {
                          width >= 1200 ? (
                            <UseStyled.ResponsiveTable type='txc'>
                              <TableCustom
                                hidePagination
                                page={0}
                                pageLimit={0}
                                sortType={'ASC'}
                                sortBy={''}
                                rowCount={0}
                                textEmptyData={t('REGISTER.NO_ITEM')}
                                onSort={() => { return }}
                                setPageLimit={() => { return }}
                                setPage={() => { return }}
                                headCells={headCells}
                                rowsData={
                                  _.map(course?.patientTreatmentCourseItems, (treatment, indexTreatment: number) => {
                                    return <RenderDataAsTable item={treatment} no={indexTreatment + 1} />
                                  })
                                }
                              />
                            </UseStyled.ResponsiveTable>
                          ) : (
                            !_.isEmpty(course?.patientTreatmentCourseItems) && (
                              _.map(course?.patientTreatmentCourseItems, (treatment, indexTreatment: number) => {
                                return (
                                  <Box key={indexTreatment}>
                                    <RenderDataAsList item={treatment} no={indexTreatment + 1} />
                                  </Box>
                                )
                              })
                            ) ||
                            <NoItem />
                          )
                        }
                        <Box className='mt-3' sx={{ paddingLeft: { lg: '44px' } }}>{t('TXC.REMARK')} <span className='ml-3'>{course?.remark || '-'}</span></Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </UseStyled.TreatmentBox>
          )
      })}
    </>
  )
}

function RenderDataAsTable(props: RenderDataProps) {
  return (
    <>
      <TableRow className='main-row'>
        <TableCell align='center'>{props.no}</TableCell>
        <TableCell>{props.item?.itemCode || '-'}</TableCell>
        <TableCell>{props.item?.itemName || props.item?.itemNameEn || '-'}</TableCell>
        <TableCell align='center'>{props.item?.qty || '-'}</TableCell>
        <TableCell align='center'>{props.item?.balance && props.item?.balance > 0 ? props.item?.balance : 0}</TableCell>
        <TableCell align='right'>{numberFormat(props.item?.price || 0)}</TableCell>
        <TableCell align='right'>{numberFormat(props.item?.total || 0)}</TableCell>
      </TableRow>
    </>
  )
}

function RenderDataAsList(props: RenderDataProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo>{props.no}</UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TXC.TABLE.CODE')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.itemCode || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo></UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TXC.TABLE.DETAIL_SERVICE')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.itemName || props.item?.itemNameEn || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex flex-column flex-md-row'>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TXC.TABLE.ALL_TOTAL')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.qty || '-'}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TXC.TABLE.QTY')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.balance && props.item?.balance > 0 ? props.item?.balance : 0}</UseStyled.Typo>
        </Box>
      </Box>
      <Box className='d-flex flex-column flex-md-row'>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TXC.TABLE.PRICE')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1 }}>{numberFormat(props.item?.price || 0)}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TXC.TABLE.TOTAL')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1 }}>{numberFormat(props.item?.total || 0)}</UseStyled.Typo>
        </Box>
      </Box>
    </>
  )
}