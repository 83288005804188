import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { Modal } from 'react-bootstrap'
import Button from '@mui/material/Button'

export const ModalStyle = styled(Modal)(({ theme }) => ({
    overflow: 'hidden',
    zIndex: theme.zIndex.modal,
    paddingLeft: '0 !important',
    '.modal-dialog': {
        maxWidth: 1152,
        overflow: 'hidden',
        [theme.breakpoints.up('xs')]: {
            height: 'calc(100% - 3.5rem)'
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            margin: '1.75rem'
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            maxWidth: 712
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)'
        }
    },
    '.modal-content': {
        height: '100%',
        maxHeight: '100%',
        padding: '1rem 0 1.5rem',
        borderRadius: 10,
        border: 'none !important',
        backdropFilter: 'saturate(1) blur(40px)',
        backgroundColor: colors.white90
    }
}))

export const Header = styled(Modal.Header)(({ theme }) => ({
    border: 'none !important',
    padding: 0,
    textAlign: 'center',
    margin: 'auto',
    p: {
        fontSize: 20,
        fontWeight: 600,
        color: colors.themeMainColor
    }
}))

export const Body = styled(Modal.Body)(({ theme }) => ({
    padding: '1rem 0 1.5rem',
    color: colors.textPrimary,
    overflowY: 'auto',
    overflowX: 'hidden'
}))

export const Footer = styled(Modal.Footer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap'
    }
}))

export const BtnCancel = styled(Button)(({ theme }) => ({
    width: 'max-content',
    height: 44,
    fontSize: 16,
    color: `${colors.themeSecondColor} !important `,
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    padding: '0 16px',
    '&.btn-default': {
        color: `${colors.textPrimary} !important `,
        border: `solid 1px ${colors.textExtraLightGray}  !important`
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginRight: '0.25rem'
    },
    [theme.breakpoints.up('sm')]: {
        marginRight: '.5rem'
    }
}))

export const BtnConfirm = styled(Button)(({ theme }) => ({
    width: 'max-content',
    height: 44,
    fontSize: 16,
    backgroundColor: `${colors.themeSecondColor} !important`,
    color: `${colors.white} !important`,
    padding: '0 16px',
    boxShadow: 'none !important',
    '&:hover': {
        backgroundColor: `${colors.themeSecondColor} !important`,
        color: `${colors.white} !important`
    },
    '&:disabled': {
        backgroundColor: `${colors.disabledGray} !important`
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginLeft: '0.25rem'
    },
    [theme.breakpoints.up('sm')]: {
        marginLeft: '0.5rem'
    }
}))

export const XrayType = styled('section')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
}))

export const ColumnPanel = styled('div')(({ theme }) => ({
    marginBottom: '0.5rem',
    padding: '0 0.25rem',
    width: '15%',
    flex: 'none',
    [theme.breakpoints.down('lg')]: {
        width: '25%'
    },
    [theme.breakpoints.down('sm')]: {
        width: '33.33%'
    }
}))

export const CardImg = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    '.btn-delete': {
        position: 'absolute',
        cursor: 'pointer',
        right: 8,
        bottom: 8,
        zIndex: 5,
        [theme.breakpoints.between('xs', 'sm')]: {
            img: {
                width: '30px !important'
            }
        }
    },
    '.btn-select': {
        position: 'absolute',
        cursor: 'pointer',
        right: 0,
        top: 0,
        zIndex: 5,
    }
}))

export const DivImg = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        border: `1px solid ${colors.lightGray}`
    },
    '&.active': {
        border: `5px solid ${colors.themeSecondColor}`,
        [theme.breakpoints.down('lg')]: {
            borderWidth: 3
        }
    }
}))

export const DivUpload = styled(DivImg)(({ theme }) => ({
    backgroundImage: `repeating-linear-gradient(0deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(90deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(180deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(270deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px) `,
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundSize: '1px 100%, 100% 1px, 1px 100% , 100% 1px',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    '.upload-image': {
        img: {
            width: 56,
            [theme.breakpoints.down('xs')]: {
                width: 32
            }
        },
        p: {
            fontSize: 14,
            color: colors.textExtraLightGray,
            [theme.breakpoints.down('xs')]: {
                fontSize: 12
            }
        }
    }
}))

export const EmptyItem = styled('section')(({ theme }) => ({
    margin: '0 0.25rem',
    height: '100%'
}))

export const PreviewImage = styled(Box)(({ theme }) => ({
    height: '100%',
    overflow: 'hidden'
}))

export const RowPanel = styled('div')(({ theme }) => ({
    paddingRight: 4,
    width: '100%'
}))

export const Carousel = styled('div')(({ theme }) => ({
    margin: '0 -0.75rem',
    [theme.breakpoints.down('sm')]: {
        margin: '0 -0.125rem'
    }
}))
