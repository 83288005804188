import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material';
import { colors } from 'constants/theme'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  btnAddDiagnosiss: {}
}))

export const ButtonAddDiagnosis = styled(Button)(({ theme }) => ({
  padding: 0,
  fontSize: 16,
  color: colors.themeSecondColor,
  backgroundColor: 'transparent !important',
  marginBottom: '-8px',
  '&:hover': {
    fontWeight: 600
  }
}))

export default useStyles
