import { menuSeconds } from 'constants/menus'
import ManageDoctorLabFee from 'features/counter/manage-doctor-lab-fee/manage-doctor-lab-fee'


const routeManageDoctorLabFee = [
  {
    ...menuSeconds.MANAGE_DF_LAB_FEE,
    component: ManageDoctorLabFee,
    appBar: true
  }
]

export default routeManageDoctorLabFee
