import { Box, Button, IconButton, Typography } from '@mui/material'
import { Dropdown } from 'react-bootstrap'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'

export const TagLab = styled(Box)(({ theme }) => ({
  backgroundColor: colors.themeThirdColor,
  color: colors.white,
  borderRadius: 4,
  width: 'max-content',
  height: 'max-content',
  fontSize: '0.75rem',
  lineHeight: 'normal',
  padding: '1px 4px'
}))
export const ResponsiveTable = styled(Box)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  '> div': {
    '&.custom-scroll::-webkit-scrollbar': {
      height: 8
    }
  },
  '.MuiTable-root': {
    minWidth: 1560,
    tableLayout: 'fixed',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:first-of-type': {
          width: 90
        },
        '&:nth-of-type(2)': {
          width: 120
        },
        '&:nth-of-type(3)': {
          paddingRight: 4,
          width: 120
        },
        '&:nth-of-type(4)': {
          paddingRight: 4,
          width: 300
        },
        '&:nth-of-type(5)': {
          paddingLeft: 4,
          width: 120
        },
        '&:nth-of-type(6)': {
          width: 250
        },
        '&:nth-of-type(7)': {
          width: 180
        },
        '&:last-of-type': {
          paddingLeft: 120,
          width: 295
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        padding: '15px !important',
        color: disabled ? colors.black60 : colors.textPrimary,
        verticalAlign: 'top !important',
        wordBreak: 'break-all',
        '&:nth-of-type(6)': {
          padding: '12px 15px !important'
        },
        '&:last-of-type': {
          padding: '12px 15px !important'
        }
      }
    }
  }
}))

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 32,
  border: `1px solid ${colors.themeSecondColor}`,
  borderRadius: 4,
  color: colors.themeSecondColor,
  backgroundColor: colors.white,
  svg: {
    height: 24
  },
  '&:hover': {
    backgroundColor: colors.white
  },
  '&.Mui-disabled': {
    opacity: 0.6
  }
}))

export const DropdownDocument = styled(Dropdown)(({ theme }) => ({
  '.dropdown-toggle': {
    backgroundColor: `${colors.white} !important`,
    color: `${colors.themeSecondColor} !important`,
    border: `1px solid ${colors.themeSecondColor} !important`,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none !important',
    height: 32,
    '&:after': {
      marginLeft: '0.5rem'
    },
    '&[aria-expanded="true"]:after': {
      transform: 'rotate(180deg)'
    }
  },
  '.dropdown-menu': {
    padding: '0',
    position: 'fixed !important',
    overflow: 'scroll',
    maxHeight: 400,
    marginBottom: 4,
    borderRadius: 4,
    background: colors.white,
    border: `1px solid ${colors.lightGray}`,
    zIndex: 1201,
    '.dropdown-item': {
      width: 265,
      '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

export const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
  paddingLeft: 32,
  paddingRight: 32
}))
export const DropdownTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
  backgroundColor: colors.lightGray
}))

export const ButtonOperative = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  minWidth: 'unset',
  maxWidth: '100%',
  span: {

    color: colors.textPrimary,
    fontSize: 16,
    transition: 'color .2s ease'
  },
  '&:hover': {
    span: {
      color: colors.themeSecondColor
    }
  }
}))