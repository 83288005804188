import doctorAppointmentSchedule from 'features/counter/doctor-appointment-schedule/doctor-appointment-schedule'
import { menuSeconds } from 'constants/menus'

const routeDoctorAppointmentSchedule = [
  {
    ...menuSeconds.DOCTOR_APPOINTMENT_SCHEDULE,
    component: doctorAppointmentSchedule,
    appBar: true
  }
]

export default routeDoctorAppointmentSchedule
