import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableCell, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { colors } from 'constants/theme'
import moment from 'moment'

interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    dataExport: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
}

export default function SaleMaterialContent(props: FcComponent) {
    const { t } = useTranslation()

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.#NO', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.DATE', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.BILL_NO', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.CN_NUMBER', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.BRANCH_CN_NUMBER', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.PATIENT_NAME', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.TYPE', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.LIST', align: 'left' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.PRICE', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.QTY', align: 'center' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.DISCOUNT', align: 'right' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.ADDITIONAL', align: 'right' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.TOTAL', align: 'right' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.SALE_SUMMARY_MONTH.AMOUNT_PAID', align: 'right' }
    ]

    const renderData = (data: any, no: number) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1
        return (
            <>
                <TableRowCommon
                    key={num.toString()}
                    id={no.toString()}
                    obj={data}
                    columns={[
                        { option: 'TEXT', align: 'center', label: num },
                        { option: 'TEXT', align: 'center', label: data.createdAt },
                        { option: 'TEXT', align: 'center', label: data.billNo },
                        { option: 'TEXT', align: 'center', label: data.cnNumber },
                        { option: 'TEXT', align: 'left', label: data.branchCnNumber },
                        { option: 'TEXT', align: 'left', label: data.fullName, class: 'text-nowrap' },
                        { option: 'TEXT', align: 'center', label: data.productTypeName, class: 'text-nowrap' },
                        { option: 'TEXT', align: 'center', label: data.itemName || '-', class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', label: numberFormat(data.price) },
                        { option: 'TEXT', align: 'right', label: data.qty || '-' },
                        { option: 'TEXT', align: 'right', label: numberFormat(data.discount) },
                        { option: 'TEXT', align: 'right', label: numberFormat(data.additional) },
                        { option: 'TEXT', align: 'right', label: numberFormat(data.total) },
                        { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }
                    ]}
                />
            </>
        )
    }

    return (
        <>
            <Box>
                <Typography className="pb-1" sx={{ fontWeight: 500 }}>
                    {t('REPORT.TABLE.SALE_SUMMARY_MONTH.MAT')}
                </Typography>
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        tableMinWidth={1920}
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={[
                            _.map(props.data, (d: any, index: number) => {
                                return renderData(d, index)
                            }),
                            <TableRow key={props.dataExport.sumTotal} className="row-summary">
                                <TableCell align="right" scope="row" colSpan={8}>
                                    {t('REPORT.TITLE.SUM')}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.dataExport, 'price'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(
                                        _.sumBy(props.dataExport, (item: any) => Number(item.qty)),
                                        0
                                    )}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.dataExport, 'discount'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.dataExport, 'additional'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.dataExport, 'total'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.dataExport, 'amountPaid'))}
                                </TableCell>
                            </TableRow>
                        ]}
                        customScroll
                    />
                </UseStyled.ResponsiveReportTable>
            </Box>
        </>
    )
}
