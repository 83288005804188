import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
import { TRUE_FALSE } from 'constants/status'
/** UTIILS */

export interface ServiceFactorInterface {
  factorName: string
  isOther: TRUE_FALSE
  status: string
}

export default class ServiceFactorApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('service-factors', { params: { ...props } }).then((res) => res)
  }

  static create(factorName: string, isOther: TRUE_FALSE) {
    return this.api.post('service-factors', { factorName, isOther })
  }

  static findById(id: number): Promise<ServiceFactorInterface> {
    return this.api.get(`service-factors/${id}`)
  }

  static update(id: number, body: ServiceFactorInterface) {
    return this.api.patch(`service-factors/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`service-factors/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`service-factors/${id}`)
  }
}
