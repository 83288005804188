import BaseAPI from "api/api"
import { FindAllInterface } from "api/api-interface"

const path = 'report-permissions'

export default class ReportPermissionApi extends BaseAPI {
    static reportsList(params: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...params } })
    }
    static update(id: number, body: any) {
        return this.api.patch(`${path}/${id}`, body)
    }
}