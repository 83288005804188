import { menuSeconds, menuInside } from 'constants/menus'
import Salary from 'features/finance/salary/salary'
import FormSalary from 'features/finance/salary/form-salary'
import ViewSalary from 'features/finance/salary/view-salary'
import IncomeExpenses from 'features/finance/income-expenses/income-expenses'
import IncomeExpensesForm from 'features/finance/income-expenses/income-expenses-form'
import IncomeExpensesView from 'features/finance/income-expenses/income-expenses-view'
import Payment from 'features/finance/payment/payment'
import PaymentCreate from 'features/finance/payment/payment-create'
import PaymentDetail from 'features/finance/payment/payment-detail'
import Overdue from 'features/finance/overdue/overdue'
import OverdueDetail from 'features/finance/overdue/overdueDetail'
import Advance from 'features/finance/advance/advance'

const routeFinancesMain = [
  {
    key: menuSeconds.SALARY.key,
    name: menuSeconds.SALARY.name,
    textName: `ดู ${menuSeconds.SALARY.name}`,
    active: false,
    path: menuSeconds.SALARY.path + '/view',
    component: ViewSalary,
    appBar: true
  },
  {
    key: menuSeconds.SALARY.key,
    name: menuSeconds.SALARY.name,
    textName: `แก้ไข ${menuSeconds.SALARY.name}`,
    active: false,
    path: menuSeconds.SALARY.path + '/:id',
    component: FormSalary,
    appBar: true
  },
  {
    key: menuSeconds.SALARY.key,
    name: menuSeconds.SALARY.name,
    textName: `เพิ่ม ${menuSeconds.SALARY.name}`,
    active: false,
    path: menuSeconds.SALARY.path + '/create',
    component: FormSalary,
    appBar: true
  },
  {
    ...menuSeconds.SALARY,
    component: Salary,
    appBar: true
  },
  {
    ...menuSeconds.INCOME_EXPENSES,
    component: IncomeExpenses,
    appBar: true
  },
  {
    ...menuSeconds.PAYMENT,
    component: Payment,
    appBar: true
  },
  {
    ...menuInside.PAYMENT_DETAIL,
    component: PaymentDetail,
    appBar: true
  },
  {
    ...menuSeconds.PAYMENT,
    path: menuSeconds.PAYMENT.path + '/create',
    component: PaymentCreate,
    appBar: true
  },
  {
    ...menuSeconds.INCOME_EXPENSES.name,
    key: menuSeconds.INCOME_EXPENSES.key,
    packageKey: menuSeconds.INCOME_EXPENSES.packageKey,
    textname: `เพิ่ม ${menuSeconds.INCOME_EXPENSES.name}`,
    active: false,
    path: menuSeconds.INCOME_EXPENSES.path + '/create',
    component: IncomeExpensesForm,
    appBar: true
  },
  {
    ...menuSeconds.INCOME_EXPENSES.name,
    key: menuSeconds.INCOME_EXPENSES.key,
    packageKey: menuSeconds.INCOME_EXPENSES.packageKey,
    textname: `แก้ไข ${menuSeconds.INCOME_EXPENSES.name}`,
    active: false,
    path: menuSeconds.INCOME_EXPENSES.path + '/update/:id',
    component: IncomeExpensesForm,
    appBar: true
  },
  {
    ...menuSeconds.INCOME_EXPENSES.name,
    key: menuSeconds.INCOME_EXPENSES.key,
    packageKey: menuSeconds.INCOME_EXPENSES.packageKey,
    textname: `ดู ${menuSeconds.INCOME_EXPENSES.name}`,
    active: false,
    path: menuSeconds.INCOME_EXPENSES.path + '/view/:id',
    component: IncomeExpensesView,
    appBar: true
  },
  {
    ...menuSeconds.OVERDUE,
    component: Overdue,
    appBar: true
  },
  {
    ...menuInside.OVERDUE_DETAIL,
    component: OverdueDetail,
    appBar: true
  },
  {
    ...menuSeconds.ADVANCE,
    component: Advance,
    appBar: true
  },
]
export default routeFinancesMain
