import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import _ from 'lodash'
import { getColorStatus } from 'features/counter/doctor-schedule/doctor-schedule'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS AND COMPONENT */
import { colors } from 'constants/theme'
import { Avatar, Box, MenuItem } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppointmentCardItemDayInterface, AppointmentDisplayDayInterface, AppointmentDisplayWeekInterface } from 'features/counter/appointment/appointment-interface'
import CalendarDay, { CalendarItem } from 'component/Calendar/CalendarMonth/CalendarMonth'
import { imgs } from 'constants/images'

/** ICON AND STYLE */
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import AppointmentApi from 'api/counter/appointment.api'
import SectionFilterDoctor, { DataPropsType } from 'features/counter/appointment/dentist-filter'
import { getNameDateOfWeekByShort, getNameMonthByNo } from 'constants/date-time'
import { MarkDoctorDayType } from 'features/counter/appointment/display-week'
import { CustomCalenderOverall, MenuDoctorListOverall } from 'features/counter/appointment/CalendarStyle'

/** COMPONENT */
import SectionAppointment from 'component/Appointment/GraphAppointment'
import CardAppointment from 'component/Appointment/CardAppointment'
import CardAppointmentDetail from 'component/Appointment/CardAppointmentDetail'
import { fileUrl } from 'api/api'
import UseWindowSize from 'utils/useWindowSize'
import CardBreak from 'component/Appointment/CardBreak'
import moment from 'moment'
import { getBranch, isBuddhistYear } from 'utils/app.utils'
import AboutUs from 'api/admin/clinic-manage/about.api'
// import { sizeSectionAppointment } from './appointment'
import { BoxTag } from './style'
import { useFormatSizeAppointment } from './appointment'

export interface IDisplayOverallProps {
  branchId: number
  branch: string
  year: number
  month: number
  timeRange: any
  listAppointment: AppointmentDisplayWeekInterface[]
  onEdit?: (id: number) => void
  onLoading?: (event: boolean) => void
  onEditSpecialInfo?: (id: number) => void
}

const DivRenderDay = styled('div')(({ theme }) => ({
  '& li:nth-child(n+3)': { display: 'none' },
  '& #basic-button': {
    '& .show-slim': { display: 'none' },
    ':hover': { '&:hover': { backgroundColor: colors.white } }
  },

  [theme.breakpoints.down(1200)]: {
    display: 'flex',
    padding: '0.25rem',
    gap: '4px 4px',
    flexWrap: 'wrap',
    alignContent: 'start',
    '&:after': { flex: 'auto' },
    '& li': {
      display: 'inline-flex',
      margin: '0.125rem',
      padding: '0px',
      minHeight: '0px',
      '&:nth-child(n)': { display: 'inline-flex' },
      '&:nth-child(n+9)': { display: 'none' }
    },
    '& .MuiAvatar-root': {
      height: '30px',
      width: '30px',

      fontSize: '0.875rem'
    },
    '& #basic-button': {
      display: 'inline-flex',
      height: '30px',
      width: '30px',
      maxWidth: '30px',
      minWidth: '30px',
      border: '.5px solid',
      borderRadius: '50%',
      backgroundColor: colors.themeSecondColor06,
      margin: '0.125rem',
      '& .show-slim': { display: 'block' },
      '& .show-full': { display: 'none' },
      '& .MuiButton-endIcon': { display: 'none' }
    }
  },
  [theme.breakpoints.down(769)]: {
    gap: '2px 2px',
    '& .MuiAvatar-root': {
      height: '35px',
      width: '35px',
      fontSize: '1rem'
    },
    '& #basic-button': {
      display: 'inline-flex',
      height: '35px',
      width: '35px',
      maxWidth: '35px',
      minWidth: '35px',
      fontSize: '1rem'
    },
    '& li': {
      '&:nth-child(n)': { display: 'inline-flex' },
      '&:nth-child(n+6)': { display: 'none' }
    }
  }
}))

const MenuDropdownItem = styled(MenuItem)(({ theme }) => ({
  padding: '0rem 0.5rem',
  marginTop: '4px',

  '&:hover': {
    background: colors.themeSecondColor06,
    color: colors.themeSecondColor
  },
  '& .title': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4
  },
  '& .subtitle': {
    lineHeight: 1.4,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  },

  '& .name-doctor': {
    fontSize: '1rem'
  },
  '& .time-doctor': {
    marginTop: '-2px!important',
    fontSize: '.875rem'
  },
  [theme.breakpoints.down('lg')]: {
    display: 'inline-flex',
    '& .MuiMenuItem-root': {
      padding: '0px'
    },
    '& .name-doctor': { display: 'none' }
  }
}))

export default function DisplayOverall(props: IDisplayOverallProps) {
  const { t, i18n } = useTranslation()
  const [branch, setBranch] = useState<any>()
  const sizeSectionAppointment = 500;

  useEffect(() => {
    setBranch(props.branch)
  }, [props.branch])

  let countAp = 0
  let checkTimeStart = '';
  const [bodyHeight, setBodyHeight] = useState(Array.from(document.getElementsByClassName('container-content') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0);
  const headHeight = Array.from(document.getElementsByClassName('head-appointment') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0
  const [widthScreen, heightScreen] = UseWindowSize()
  const [viewDetails, setViewDetails] = useState([])
  const [width, setWidth] = useState<number>(widthScreen)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()
  const [dayExpane, setDayExpane] = useState<string>()

  const [appointmentWeek, setAppointmentWeek] = useState<AppointmentDisplayWeekInterface | null>()
  const [markDoctorDay, setMarkDoctorDay] = useState<MarkDoctorDayType>({ SUN: 0, MON: 0, TUE: 0, WED: 0, THU: 0, FRI: 0, SAT: 0 })

  const open = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonWidth = Array.from(document.getElementsByClassName('btn-doctor-over') as HTMLCollectionOf<HTMLElement>)
    setWidth(Number(buttonWidth[0]?.offsetWidth))
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  const handleViewDetail = (val: any) => {
    const temp: any = []
    if (!temp.find((t: any) => t === val)) temp.push(val)
    setViewDetails(temp)
  }

  const handleCloseDetail = (val: any) => {
    const temp: any = [...viewDetails].filter((t: any) => t !== val)
    setViewDetails(temp)
  }

  const getWeek = async (date: string, doctorId: number) => {
    let condition: any = {}
    condition = { ...condition, branchId: props.branchId }
    condition = { ...condition, date: date }
    const appointemntWeek = await AppointmentApi.displayWeek(condition)

    if (appointemntWeek.status === 200) {
      const apWeek: AppointmentDisplayWeekInterface = _.find(appointemntWeek.data, (ap: AppointmentDisplayWeekInterface) => ap.date === date)
      setAppointmentWeek(apWeek)
      const temp: any = { ...markDoctorDay }
      temp[apWeek.dateOfWeek] = doctorId
      setMarkDoctorDay(temp)
    }
  }

  const getDoctorActive = (id: number, dataDoctor: any, dateOfWeek: any) => {
    const temp: any = { ...markDoctorDay }
    temp[dateOfWeek] = id
    countAp = 0
    checkTimeStart = ''
    setMarkDoctorDay(temp)
  }

  const renderHead = (la: AppointmentDisplayWeekInterface) => {
    if (la.doctors.length > 0 && _.get(markDoctorDay, la.dateOfWeek) === 0) getDoctorActive(la.doctors[0].doctorId, la.doctors, la.dateOfWeek)
    const dataRender: DataPropsType[] =
      la.doctors.map((doctor: AppointmentDisplayDayInterface) => {
        const tm: any = doctor.doctorSchedule.split(' - ')
        return {
          doctorId: doctor.doctorId,
          doctorFullName: doctor.doctorFullname || 'Doctor1 Cliniter',
          servicePointName: doctor.servicePointName,
          startWork: tm[0] || '9:00',
          getOffWork: tm[1] || '18:00',
          avatar: `${fileUrl}/${doctor.doctorProfile}` || '',
          status: doctor.statusDoctorSchedule || ''
        }
      }) || []
    const headText = `${t('APPOINTMENT.HEADER.DATE_WEEK_AT', { week: getNameDateOfWeekByShort(la.dateOfWeek, i18n.language) })} ${parseInt(la.date.split('-')[2])} ${getNameMonthByNo(parseInt(la.date.split('-')[1]), i18n.language)} ${parseInt(la.date.split('-')[0]) + isBuddhistYear()}`

    return (
      <SectionFilterDoctor
        headText={headText}
        data={dataRender}
        selectDoctor={dataRender.find((dr: DataPropsType) => dr.doctorId === _.get(markDoctorDay, la.dateOfWeek))}
        onClick={(id) => { getDoctorActive(id, la.doctors, la.dateOfWeek) }}
        onClose={() => (setAppointmentWeek(null))}
        widthButton={sizeSectionAppointment}
      />
    )
  }

  const renderDate = (doctors: AppointmentDisplayDayInterface[], dateRender: string) => {
    const renderDoctor = (doctor: AppointmentDisplayDayInterface, index: number, displayType?: string) => {
      return (
        <MenuDropdownItem
          className={`px-xl-2 text-ellipsis doctor-list-item`}
          key={index}
          onClick={() => {
            getWeek(dateRender, doctor.doctorId)
            handleClose()
          }}>
          <div className="p-0 d-flex overflow-hidden flex-center">
            <Avatar alt="Remy Sharp" src={`${fileUrl}/${doctor.doctorProfile}`} sx={{ backgroundColor: colors.white }}>
              <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
            </Avatar>
            <div className="pl-2 name-doctor text-nowrap-ellipsis -overflow-hidden ">
              <p className="mb-0 fc-main text-nowrap-ellipsis">
                <strong>{doctor.doctorFullname}</strong>
              </p>
              {doctor?.tags?.length ?
                <BoxTag sx={{ flexWrap: 'wrap' }}>
                  {doctor?.tags?.map((item: any, index: number) => {
                    return (
                      <Box
                        key={index}
                        title={item.tagName}
                        className={`items-tag`}
                        sx={{ backgroundColor: item.color, }}
                      />
                    )
                  })}
                </BoxTag>
                : <></>
              }
              <p className="mb-0 text-muted time-doctor text-nowrap-ellipsis">
                {doctor.statusDoctorSchedule === 'NORMAL' && (
                  `${doctor.doctorSchedule} (${doctor.countAppointment} ${t('APPOINTMENT.HEADER.APPOINT')})`
                ) || (
                    <span style={{ color: getColorStatus(doctor.statusDoctorSchedule || '', 'COLOR') }}>{doctor.statusDoctorSchedule ? `(${t(`DOCTOR_SCHEDULE.STATUS.${doctor.statusDoctorSchedule}`)})` : '-'}</span>
                  )}
              </p>
            </div>
          </div>
        </MenuDropdownItem>
      )
    }

    const renderBtn = (label: any) => {
      return (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => {
            handleOpen(e)
            setDayExpane(dateRender)
          }}
          endIcon={<FontAwesomeIcon icon={faCaretDown} />}
          disableRipple
          className={'btn-doctor-over py-0 px-2 w-100 text-nowrap'}
          style={{ color: colors.themeSecondColor }}
          sx={{ ':hover': { '&:hover': { backgroundColor: colors.white } } }}>
          <>{label}</>
        </Button>
      )
    }

    if (doctors.length) {
      return (
        <DivRenderDay className='doctor-list'>
          {_.map(doctors, (doctor: AppointmentDisplayDayInterface, index: number) => renderDoctor(doctor, index))}
          {doctors.length > 2 && (
            <>
              {widthScreen < 1200 && (
                <>
                  {
                    (widthScreen <= 768 && doctors.length > 5) &&
                    renderBtn(
                      <div className="show-slim">
                        <span className='d-flex align-items-center justify-content-center'>{`+${doctors.length - 5}`}</span>
                      </div>
                    ) || (widthScreen < 1200 && doctors.length > 9) &&
                    renderBtn(
                      <div className="show-slim">
                        <span className='d-flex align-items-center justify-content-center'>{`+${doctors.length - 9}`}</span>
                      </div>
                    ) || <></>
                  }
                </>
              ) || (renderBtn(<span className="show-full text-ellipsis">{t('APPOINTMENT.DISPLAY.AND_ON_DUTY')} {doctors.length - 2} {t('APPOINTMENT.DISPLAY.PERSON')}</span>))}
              <MenuDoctorListOverall
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && dayExpane === dateRender}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                classes={{ list: 'custom-scroll' }}
                sx={{ '& .MuiMenu-list': { minWidth: width } }}
              >
                {_.map(doctors, (doctor: AppointmentDisplayDayInterface, index: number) => renderDoctor(doctor, index, 'in'))}
              </MenuDoctorListOverall>
            </>
          )}
        </DivRenderDay>
      )
    }
  }

  useEffect(() => {
    setBodyHeight(Array.from(document.getElementsByClassName('container-content') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0)
  }, [heightScreen]);

  return (
    <div className="position-relative">
      <div className="d-flex">
        <div className="d-inline-flex px-0 pt-3">
          {appointmentWeek && (
            <div className="custom-scroll" style={{ width: `calc(${sizeSectionAppointment}px + 4.05rem)`, maxHeight: `calc(${bodyHeight - headHeight}px - 34px)`, overflowY: 'auto', overflowX: 'hidden' }}>
              <div className="section-head-doctor sticky-top bg-white" style={{ zIndex: 300, paddingLeft: '3.05rem' }}>
                {renderHead(appointmentWeek)}
              </div>
              <div className="section-body-appointment" style={{ paddingLeft: '3.05rem', width: `calc(${1 * sizeSectionAppointment}px + 4.05rem)`, overflow: 'auto' }}>
                <div className={'div-time'} style={{ width: '3.05rem', display: 'inline-block', marginLeft: '-3rem', position: 'sticky', left: '-3rem' }}>
                  <SectionAppointment branchId={props.branchId} timeRange={props.timeRange} isShowTime={true} showMinute />
                </div>
                <div className={'graph-appointment'} style={{ width: `${sizeSectionAppointment}px`, display: 'inline-block', margin: '0 0.05rem 0 0.05rem' }}>
                  <SectionAppointment
                    branchId={props.branchId}
                    width={sizeSectionAppointment}
                    isShowTime={false}
                    showMinute
                    timeRange={props.timeRange}
                    card={_.find(appointmentWeek.doctors, (d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, appointmentWeek.dateOfWeek))
                      ?.appointments?.map((ap: AppointmentCardItemDayInterface, j: number) => {
                        const appointments = _.find(appointmentWeek.doctors, (d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, appointmentWeek.dateOfWeek))?.appointments
                        const count = _.filter(appointments, (list: AppointmentCardItemDayInterface, iList: number) => j + 1 > iList && list.doctorId === ap.doctorId && list.start === ap.start).length
                        const allRow = _.filter(appointments, (list: AppointmentCardItemDayInterface) => list.doctorId === ap.doctorId && list.start === ap.start).length
                        if (checkTimeStart !== ap.start) {
                          checkTimeStart = ap.start
                          countAp = 0
                        } else {
                          countAp = countAp + 1
                        }

                        const handlePatientCode = () => {
                          if (branch?.patientCodeAppointment === '1') {
                            return ap.cnNumber
                          }
                          if (branch?.patientCodeAppointment === '0') {
                            return ap.branchCnNumber
                          }
                          if (branch?.patientCodeAppointment === '2') {
                            return ap.snCode
                          }
                        }
                        return (
                          <CardAppointment
                            key={j}
                            appointmentId={ap.appointmentId}
                            status={ap.statusAppointment}
                            branchCnNumber={handlePatientCode() ? handlePatientCode() : ''}
                            colorBackground={ap.color}
                            serviceText={ap.serviceText}
                            patientFullName={ap.patientFullname}
                            rangeTimeText={ap.rangeTimeText}
                            start={ap.start || ap.rangeTimeText.split(' - ')[0].replace('.', ':')}
                            end={ap.end || ap.rangeTimeText.split(' - ')[1].replace('.', ':')}
                            hasLab={ap.hasLab === 'HAS'}
                            hasXray={ap.hasXray === 'HAS'}
                            onViewDetail={handleViewDetail}
                            timeStart={props.timeRange[0]}
                            width={(sizeSectionAppointment / allRow)}
                            countThis={count}
                            countThisTime={allRow}
                            // translateX={(60 * countAp)}
                            translateX={(sizeSectionAppointment / allRow) * countAp}
                            specialInfo={ap?.specialInfo}
                            allRow={allRow >= 4}
                          />
                        )
                      })}
                    popup={_.find(appointmentWeek.doctors, (d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, appointmentWeek.dateOfWeek))
                      ?.appointments
                      ?.map((ap: AppointmentCardItemDayInterface, j: number) => (
                        <CardAppointmentDetail
                          key={j}
                          timeStart={props.timeRange[0]}
                          timeEnd={props.timeRange[props.timeRange.length - 1]}
                          appointmentId={ap.appointmentId}
                          showPopup={viewDetails.find((v) => v === ap.appointmentId) ? true : false}
                          onClose={handleCloseDetail}
                          onEdit={props?.onEdit ? props?.onEdit : undefined}
                          onEditSpecialInfo={props.onEditSpecialInfo ? props.onEditSpecialInfo : undefined}
                          onLoading={(event: boolean) => props?.onLoading ? props.onLoading(event) : undefined}
                          status={ap.statusAppointment}
                          branch={props.branch}
                        />
                      ))}
                    break={
                      <CardBreak
                        start={moment(appointmentWeek.doctors.find((d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, appointmentWeek.dateOfWeek))?.breakTimeStart, 'HH:mm').format('HH:mm')}
                        end={appointmentWeek.doctors.find((d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, appointmentWeek.dateOfWeek))?.breakTimeEnd}
                        timeStart={props.timeRange[0]}
                        width={sizeSectionAppointment}
                        translateX={sizeSectionAppointment}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <CustomCalenderOverall className={`pr-0 col overflow-auto custom-scroll mx-3 mt-3 ${appointmentWeek ? 'has-appointment-week' : ''}`} sx={{ maxHeight: `calc(${bodyHeight - headHeight}px - 34px)` }}>
          <CalendarDay
            yearAndMonth={[props.year, props.month]}
            renderDay={(calendarDayObject: any) => {
              const laInDay: any = props.listAppointment.find((la: AppointmentDisplayWeekInterface) => la.date === calendarDayObject.dateString)
              if (laInDay?.doctors.length) {
                laInDay.date = calendarDayObject.dateString
              }
              return (
                <CalendarItem
                  calendarDayObject={calendarDayObject}
                  allDentists={laInDay?.doctors || []}
                  items={renderDate(laInDay?.doctors || [], calendarDayObject.dateString)}
                />
              )
            }}
          />
        </CustomCalenderOverall>
      </div>
    </div>
  )
}
