import { colors } from "constants/theme"
import IconProps from "./icon-props"

export default function Schedule(props: IconProps) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_768_106041)">
        <path d="M0.850098 22.549V19.903C0.876386 18.472 1.46971 17.1099 2.49969 16.1161C3.52967 15.1224 4.91206 14.5781 6.3431 14.603H17.3301C18.7611 14.5781 20.1435 15.1224 21.1735 16.1161C22.2035 17.1099 22.7968 18.472 22.8231 19.903V22.552C22.8241 23.211 0.850098 22.549 0.850098 22.549Z" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} stroke={props.color || colors.themeSecondColor} strokeWidth={2.0} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.6858 6.649C16.6858 7.75436 16.3581 8.8349 15.744 9.75398C15.1299 10.6731 14.2571 11.3894 13.2359 11.8125C12.2147 12.2355 11.091 12.3463 10.0068 12.1307C8.92269 11.9151 7.92682 11.3829 7.14515 10.6014C6.36347 9.81984 5.83109 8.82407 5.61533 7.73997C5.39956 6.65588 5.5101 5.53215 5.93297 4.51088C6.35583 3.4896 7.07203 2.61665 7.99101 2.0024C8.90998 1.38816 9.99046 1.0602 11.0958 1.06C11.8299 1.05987 12.5567 1.20434 13.2349 1.48515C13.9131 1.76597 14.5294 2.17763 15.0485 2.69663C15.5676 3.21563 15.9793 3.8318 16.2603 4.50995C16.5412 5.18811 16.6858 5.91496 16.6858 6.649V6.649Z" stroke={props.color || colors.themeSecondColor} strokeWidth={1.6} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.00711 23.995V22.604L5.53311 22.932L5.53311 19.932C5.54023 19.3949 5.76022 18.8826 6.14478 18.5076C6.52934 18.1326 7.04702 17.9256 7.58411 17.932C8.12137 17.9253 8.63931 18.1322 9.02408 18.5073C9.40886 18.8823 9.62899 19.3947 9.63611 19.932V22.932H8.61011V23.932H10.6621V19.932C10.6546 19.2147 10.3926 18.5234 9.92287 17.9812C9.4531 17.4391 8.80606 17.0814 8.09711 16.972L8.09711 13.746H7.00711L7.07211 16.972C6.36316 17.0814 5.71612 17.4391 5.24635 17.9812C4.77657 18.5234 4.51459 19.2147 4.50711 19.932L4.50711 23.932L7.00711 23.995Z" fill={props.active ? `#F1F6F6` : props.color || colors.themeSecondColor} stroke={props.active ? `#F1F6F6` : props.color || colors.themeSecondColor} strokeWidth={0.4} strokeLinejoin="round" />
        <path d="M15.5869 13.693V17.61C15.1393 17.7258 14.7493 18.0009 14.4899 18.3837C14.2306 18.7665 14.1197 19.2307 14.1781 19.6893C14.2365 20.148 14.4601 20.5696 14.8071 20.8752C15.154 21.1808 15.6005 21.3494 16.0629 21.3494C16.5252 21.3494 16.9717 21.1808 17.3187 20.8752C17.6657 20.5696 17.8893 20.148 17.9477 19.6893C18.0061 19.2307 17.8952 18.7665 17.6359 18.3837C17.3765 18.0009 16.9865 17.7258 16.5389 17.61V13.693H15.5869ZM17.0149 19.446C17.0149 19.6343 16.9591 19.8183 16.8544 19.9749C16.7498 20.1315 16.6012 20.2535 16.4272 20.3255C16.2532 20.3976 16.0618 20.4164 15.8772 20.3797C15.6925 20.343 15.5229 20.2523 15.3897 20.1192C15.2566 19.986 15.1659 19.8164 15.1292 19.6317C15.0924 19.4471 15.1113 19.2556 15.1834 19.0817C15.2554 18.9077 15.3774 18.7591 15.534 18.6544C15.6905 18.5498 15.8746 18.494 16.0629 18.494C16.3154 18.494 16.5575 18.5943 16.7361 18.7728C16.9146 18.9514 17.0149 19.1935 17.0149 19.446Z" fill={props.active ? `#F2F6F6` : props.color || colors.themeSecondColor} stroke={props.active ? `#F2F6F6` : props.color || colors.themeSecondColor} strokeWidth={0.6} strokeLinejoin="round" />
        <path d="M5.50684 6.649H16.6238C16.6238 6.649 16.2668 0.930001 11.1348 1.109C6.00283 1.288 5.50684 6.649 5.50684 6.649Z" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} stroke={props.color || colors.themeSecondColor} />
      </g>
      <defs>
        <clipPath id="clip0_768_106041">
          <rect width="23.674" height="23.735" fill="white" transform="translate(0 0.459999)" />
        </clipPath>
      </defs>
    </svg>
  )
}
