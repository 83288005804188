import moment from 'moment'
import _ from 'lodash'
import { Box, TableRow, TableCell, Typography } from '@mui/material'
import { colors } from 'constants/theme'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** INTERFACE */
import { TreatmentPanelProps, ItemRender } from 'component/Treatments/PopupEmrOverview/PopupEmrOverview'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'
import { NoItem } from 'component/Treatments/PopupEmrOverview//PopupEmrOverview'

/** UTILS */
import { toBuddhistYear, numberFormat } from 'utils/app.utils'
import useWindowSize from 'utils/useWindowSize'

/** STYLE */
import * as UseStyled from 'component/Treatments/PopupEmrOverview//UseStyled'

type RenderDataProps = {
  no: number
  item: any
}

export default function EmrTxp(props: TreatmentPanelProps) {
  const { t } = useTranslation()
  const [width] = useWindowSize()

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('TXP.TABLE.TYPE') },
    { id: 'message', disablePadding: false, label: t('TXP.TABLE.CODE') },
    { id: 'message', disablePadding: false, label: t('TXP.TABLE.DETAIL_OPERATIVE') },
    { id: 'message', disablePadding: false, label: t('TXP.TABLE.PRICE'), align: 'right' },
    { id: 'message', disablePadding: false, label: t('TXP.TABLE.DATE') },
    { id: 'message', disablePadding: false, label: t('TXP.TABLE.DOCTOR') },
    { id: 'message', disablePadding: false, label: t('TXP.TABLE.TIME') },
    { id: 'message', disablePadding: false, label: t('TXP.TABLE.STATUS') },
  ]

  return (
    <>
      {_.map(props.treatments, (item: ItemRender, index: number) => {
        if (!_.isEmpty(item.treatmentPlan))
          return (
            <UseStyled.TreatmentBox key={index}>
              <UseStyled.LabelDate>{toBuddhistYear(moment(item.date), 'DD/MM/YY HH.mm')} {t('TIME_UNIT')}</UseStyled.LabelDate>
              <Box>
                {
                  _.map(item.treatmentPlan, (plan: any, indexPlan: number) => {
                    return (
                      <Box
                        sx={{
                          '&:not(:last-of-type)': {
                            paddingBottom: '16px',
                            borderBottom: `1px solid ${colors.lightGray}`
                          }
                        }}>
                        <Box className='d-flex align-items-center flex-wrap mt-3 mb-1'>
                          <Typography className='d-xl-none pr-3' sx={{ flex: 'none', fontWeight: 600, fontSize: '18px' }}>{t('ชื่อแผนการรักษา')}</Typography>
                          <Typography sx={{ fontWeight: { lg: 500 }, fontSize: '18px' }}>{plan?.treatmentPlanName}</Typography>
                        </Box>
                        {
                          width >= 1200 ? (
                            <UseStyled.ResponsiveTable type='txp'>
                              <TableCustom
                                hidePagination
                                page={0}
                                pageLimit={0}
                                sortType={'ASC'}
                                sortBy={''}
                                rowCount={0}
                                textEmptyData={t('REGISTER.NO_ITEM')}
                                onSort={() => { return }}
                                setPageLimit={() => { return }}
                                setPage={() => { return }}
                                headCells={headCells}
                                rowsData={
                                  _.map(plan?.patientTreatmentPlanItems, (treatment, indexTreatment: number) => {
                                    return <RenderDataAsTable item={treatment} no={indexTreatment + 1} />
                                  })
                                }
                              />
                            </UseStyled.ResponsiveTable>
                          ) : (
                            !_.isEmpty(plan?.patientTreatmentPlanItems) && (
                              _.map(plan?.patientTreatmentPlanItems, (treatment, indexTreatment: number) => {
                                return (
                                  <Box key={indexTreatment}>
                                    <RenderDataAsList item={treatment} no={indexTreatment + 1} />
                                  </Box>
                                )
                              })
                            ) ||
                            <NoItem />
                          )
                        }
                        <Box className='mt-3' sx={{ paddingLeft: { lg: '44px' } }}>{t('TXP.REMARK')} <span className='ml-3'>{plan?.remark || '-'}</span></Box>
                      </Box>
                    )
                  })
                }
              </Box>
            </UseStyled.TreatmentBox>
          )
      })}
    </>
  )
}

function RenderDataAsTable(props: RenderDataProps) {
  const { t } = useTranslation()

  return (
    <>
      <TableRow className='main-row'>
        <TableCell align='center'>{props.no}</TableCell>
        <TableCell>{props.item?.operativeTypeName || props.item?.itemTypeName || '-'}</TableCell>
        <TableCell>{props.item?.operativeCode || '-'}</TableCell>
        <TableCell>{props.item?.operativeName || props.item?.itemName || '-'}</TableCell>
        <TableCell align='right'>{numberFormat(props.item?.price || 0)}</TableCell>
        <TableCell>{props.item?.dateUse ? toBuddhistYear(moment(props.item?.dateUse), 'DD/MM/YYYY') : '-'}</TableCell>
        <TableCell>{!_.isEmpty(props.item?.user) ? `${props.item?.user?.firstname} ${props.item?.user?.lastname}` : props.item?.createdBy || '-'}</TableCell>
        <TableCell>{props.item?.duration ? `${props.item?.duration} ${t(`TXP.DATE.${props.item?.durationUnit}`)}` : '-'}</TableCell>
        <TableCell>{props.item?.status ? t(`TX_INFORMATION.STATUS.${props.item?.status}`) : '-'}</TableCell>
      </TableRow>
    </>
  )
}

function RenderDataAsList(props: RenderDataProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo>{props.no}</UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TXP.TABLE.TYPE')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.operativeTypeName || props.item?.itemTypeName || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo></UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TXP.TABLE.CODE')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.operativeCode || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo></UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TXP.TABLE.DETAIL_OPERATIVE')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.operativeName || props.item?.itemName || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex flex-column flex-md-row'>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TXP.TABLE.PRICE')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1 }}>{numberFormat(props.item?.price || 0)}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TXP.TABLE.DATE')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.dateUse ? toBuddhistYear(moment(props.item?.dateUse), 'DD/MM/YYYY') : '-'}</UseStyled.Typo>
        </Box>
      </Box>
      <Box className='d-flex flex-column flex-md-row'>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TXP.TABLE.DOCTOR')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1 }}>{!_.isEmpty(props.item?.user) ? `${props.item?.user?.firstname} ${props.item?.user?.lastname}` : props.item?.createdBy || '-'}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TXP.TABLE.TIME')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.duration ? `${props.item?.duration} ${t(`TXP.DATE.${props.item?.durationUnit}`)}` : '-'}</UseStyled.Typo>
        </Box>
      </Box>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo></UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TXP.TABLE.STATUS')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.status ? t(`TX_INFORMATION.STATUS.${props.item?.status}`) : '-'}</UseStyled.Typo>
      </Box>
    </>
  )
}