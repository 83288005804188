import { menuThirds } from 'constants/menus'
import ClinicFees from 'features/setting/treatments/clinic-fees/clinic-fees'
import DiagnosisTypes from 'features/setting/treatments/diagnosis-types/diagnosis-types'
import FormDiagnosis from 'features/setting/treatments/diagnosis/form-diagnosis'
import Diagnosis from 'features/setting/treatments/diagnosis/diagnosis'
import ImageTypes from 'features/setting/treatments/image-types/image-types'
import OperativeTypes from 'features/setting/treatments/operative-types/operative-types'
import OperativeUnits from 'features/setting/treatments/operative-units/operative-units'
import Recommends from 'features/setting/treatments/recommends/recommends'
import XrayFilmTypes from 'features/setting/treatments/xray-film-types/xray-film-types'
import Operatives from 'features/setting/treatments/operatives/operatives'
import FormOperatives from 'features/setting/treatments/operatives/form-operatives'
import Recalls from 'features/setting/treatments/recalls/recalls'
import TemplateDoctorNote from 'features/setting/treatments/template-doctor-note/template-doctor-note'
import FormRecalls from 'features/setting/treatments/recalls/form-recalls'

const routeTreatments = [
  {
    ...menuThirds.CLINIC_FEE,
    component: ClinicFees,
    appBar: true
  },
  {
    ...menuThirds.IMAGE_TYPE,
    component: ImageTypes,
    appBar: true
  },
  {
    ...menuThirds.XRAY_FILM_TYPE,
    component: XrayFilmTypes,
    appBar: true
  },
  {
    ...menuThirds.OPERATIVE_TYPE,
    component: OperativeTypes,
    appBar: true
  },
  {
    ...menuThirds.OPERATIVE_UNIT,
    component: OperativeUnits,
    appBar: true
  },
  {
    ...menuThirds.DIAGNOSIS_TYPE,
    component: DiagnosisTypes,
    appBar: true
  },
  {
    ...menuThirds.RECOMMEND,
    component: Recommends,
    appBar: true
  },
  // {
  //   ...menuThirds.TEMPLATE_DOCTOR_NOTE,
  //   component: TemplateDoctorNote,
  //   appBar: true
  // },
  /** คำวินิจฉัย */
  {
    name: menuThirds.DIAGNOSIS.name,
    key: menuThirds.DIAGNOSIS.key,
    textName: 'เพิ่มคำวินิจฉัย',
    active: false,
    path: menuThirds.DIAGNOSIS.path + '/create',
    component: FormDiagnosis,
    appBar: true
  },
  {
    name: menuThirds.DIAGNOSIS.name,
    key: menuThirds.DIAGNOSIS.key,
    textName: 'แก้ไขคำวินิจฉัย',
    active: false,
    path: menuThirds.DIAGNOSIS.path + '/:id',
    component: FormDiagnosis,
    appBar: true
  },

  {
    ...menuThirds.DIAGNOSIS,
    component: Diagnosis,
    appBar: true
  },
  /** คำวินิจฉัย */

  /** หัตถการ */
  {
    name: menuThirds.OPERATIVE.name,
    key: menuThirds.OPERATIVE.key,
    textName: 'เพิ่มหัตถการ',
    active: false,
    path: menuThirds.OPERATIVE.path + '/create',
    component: FormOperatives,
    appBar: true
  },
  {
    name: menuThirds.OPERATIVE.name,
    key: menuThirds.OPERATIVE.key,
    textName: 'แก้ไขหัตถการ',
    active: false,
    path: menuThirds.OPERATIVE.path + '/:id',
    component: FormOperatives,
    appBar: true
  },
  {
    ...menuThirds.OPERATIVE,
    component: Operatives,
    appBar: true
  },
  /** หัตถการ */
  {
    ...menuThirds.RECALL,
    textName: 'เพิ่ม Recall',
    path: menuThirds.RECALL.path + '/create',
    component: FormRecalls,
    appBar: true
  },
  {
    name: menuThirds.RECALL.name,
    key: menuThirds.RECALL.key,
    textName: 'แก้ไข Recall',
    active: false,
    path: menuThirds.RECALL.path + '/update',
    component: FormRecalls,
    appBar: true
  },
  {
    ...menuThirds.RECALL,
    component: Recalls,
    appBar: true
  },
]

export default routeTreatments
