import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
import { getBranch } from 'utils/app.utils'

export interface TemplateDoctorFeeOperatives {
  operativeId: number
  dfAmount: number
  cfAmount: number
  dfUnit: "PERCENT" | "BAHT"
  labDoctorAmount: number
  labClinicAmount: number
}

export interface TemplateDoctorFeeOperativeType {
  operativeTypeId: number
  dfAmount: number
  cfAmount: number
  labDoctorAmount: number
  labClinicAmount: number
  templateDoctorFeeOperatives: TemplateDoctorFeeOperatives[]
}
export interface TemplateDoctorFee {
  templateDoctorFeeName: string
  amount: number
  labDoctorAmount: number
  isMain: '0' | '1'
  isLink?: '0' | '1'
  status?: string
  templateDoctorFeeOperativeTypes: TemplateDoctorFeeOperativeType[]
}

export default class TemplateDoctorFeeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('template-doctor-fees', { params: { ...props } }).then((res) => res)
  }

  static create(payload: TemplateDoctorFee) {
    return this.api.post('template-doctor-fees', payload)
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`template-doctor-fees/${id}`)
  }

  static update(id: number, body: any) {
    return this.api.patch(`template-doctor-fees/${id}`, body)
  }
  static copy(id: number, body: any) {
    return this.api.post(`template-doctor-fees/${id}/copy`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`template-doctor-fees/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`template-doctor-fees/${id}`)
  }

  static findAllWithOperatives(id?: number): Promise<any> {
    return this.api.get('template-doctor-fees/operatives', { params: { templateDoctorFeeId: id, branchId: getBranch() } }).then((res) => res)
  }
}
