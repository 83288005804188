import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface CardTypesInterface {
  cardTypeShortname: string
  cardTypeName: string
  cardTypeLogo?: string
  hasCredit: string
  hasDebit: string
  image: any
  status?: string
}

export default class CardTypesApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('card-types', { params: { ...props } })
  }
  static create(payload: any) {
    return this.api.post('card-types', payload)
  }
  static findById(id: number): Promise<CardTypesInterface> {
    return this.api.get(`card-types/${id}`)
  }
  static update(id: number, body: CardTypesInterface) {
    return this.api.patch(`card-types/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`card-types/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`card-types/${id}`)
  }
}
