import { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { Grid, Divider, Box } from '@mui/material';
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { routeName } from 'routes/routes-name'
import ReportApi from 'api/report.api'

/** COMPONENT */
import { notiSuccess } from 'component/notifications/notifications';
import InputCheckbox from 'component/Input/InputCheckbox'
import HeaderLabel from 'component/CardCustom/HeaderLabel'

/** CONSTANT */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'
import ReportPermissionApi from 'api/setting/personnel-managements/report-permission.api'

const LabelHeader = styled(Box)(({ theme }) => ({
    backgroundColor: colors.themeSecondColor10,
    marginBottom: '20px',
    '.MuiFormControlLabel-label': {
        fontWeight: 500
    }
}))

export default function FormReportPermission() {
    const { t } = useTranslation()
    const history = useHistory()
    const location: any = useLocation()

    const [roleId, setRoleId] = useState<number>(0)
    const [objEdit, setObjEdit] = useState<any>({})

    const [allReports, setAllReports] = useState<any[]>([])
    const [reports, setReports] = useState<any[]>([])
    const [reportsSelect, setReportsSelect] = useState<number[]>([])

    const loadData = async () => {
        ReportApi.reportActive().then((resp) => {
            if (resp.status === 200) {
                setAllReports(resp.data)
                const listReports: any = _.chain(resp.data)
                    .groupBy('reportGroup')
                    .map((value: any, key: any) => ({ key, data: value })).value()
                setReports(listReports)
            }
        })
    }

    const submit = () => {
        if (roleId) update()
    }

    const update = () => {
        ReportPermissionApi.update(roleId, { roleId, reportPermissions: _.map(reportsSelect, (rps: any) => ({ reportId: rps })) }).then((res: any) => {
            notiSuccess(t(`REPORT_ROLE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
            history.push(routeName.reportPermission)
        })
    }

    useEffect(() => { loadData() }, [])

    useEffect(() => {
        const locationState: any = location?.state
        if (locationState?.roleId) {
            setRoleId(locationState?.roleId)
            setObjEdit(locationState)
            setReportsSelect([..._.map(locationState.reportPermissions, (rep: any) => rep.reportId)])
        }
    }, [location])

    const onGroup = async (checked: boolean, valueReports: any[]) => {
        const reportCheck: any[] = valueReports.map((rp: any) => rp.reportId)
        if (checked) {
            setReportsSelect([...reportsSelect, ...reportCheck])
        } else {
            setReportsSelect([..._.filter(reportsSelect, (rId: any) => !reportCheck.includes(rId))])
        }
    }

    return (
        <div className={`container-card h-100 overflow-hidden`}>
            <Box className="d-flex flex-column h-100" sx={{ overflowY: 'auto' }}>
                <HeaderLabel
                    text={roleId ? t('REPORT_ROLE.TITLE_UPDATE') : t('REPORT_ROLE.TITLE_CREATE')}
                    navigator={{ previousTo: routeName.reportPermission, previousTitle: t('REPORT_ROLE.TITLE'), currentTitle: roleId ? t('REPORT_ROLE.TITLE_UPDATE') : t('REPORT_ROLE.TITLE_CREATE') }}
                    goBack={routeName.reportPermission}
                    onSubmit={submit}
                />
                <Card className="border shadow-sm mx-4 mb-4">
                    <Card.Body className="h-100">
                        <Grid container spacing={2}>
                            <Grid item sm={6} lg={4} className='w-100'>
                                <div style={{ fontSize: '1.5rem' }}> <strong>{t('REPORT_ROLE.FORM.ROLE')} :</strong>  {objEdit.roleName}</div>
                            </Grid>
                        </Grid>
                        <Divider className="mx-0 mt-4 mb-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <Grid container spacing={2}>
                            <Grid item className='w-100'>
                                <InputCheckbox label={t('REPORT_ROLE.FORM.CHECK_ALL')} onChange={(e) => {
                                    if (e.target.checked) setReportsSelect([..._.map(allReports, (rp: any) => rp.reportId)])
                                    else setReportsSelect([])
                                }} checked={reportsSelect.length === allReports.length} className="ml-0" />
                            </Grid>
                            <Grid item className='w-100 pt-0'>
                                {_.map(reports, (report: any, index: number) => {
                                    if (report?.key && report.key !== "null") {
                                        return (
                                            <Box key={index}>
                                                <LabelHeader className='mt-3 py-1'>
                                                    <InputCheckbox
                                                        label={t(`REPORT.REPORT_GROUP.${report.key}`)}
                                                        onChange={(e) => onGroup(e.target.checked, report.data)}
                                                        checked={_.find(reportsSelect, (rId: any) => [..._.map(report.data, (rp: any) => rp.reportId)].includes(rId)) ? true : false}
                                                        className="ml-0" />
                                                </LabelHeader>
                                                <Grid container spacing={2}>
                                                    {_.map(report.data, (rp: any, indexRp: number) => (
                                                        <Grid item key={indexRp} xs={6} md={4} xl={3} className='w-100 pt-1'>
                                                            <InputCheckbox
                                                                label={rp.reportName || rp.reportNameEn}
                                                                onChange={(e) => {
                                                                    const checked: boolean = e.target.checked
                                                                    if (checked) {
                                                                        setReportsSelect([...reportsSelect, rp.reportId])
                                                                    } else {
                                                                        setReportsSelect([..._.filter(reportsSelect, (rps: any) => Number(rps) !== Number(rp.reportId))])
                                                                    }
                                                                }}
                                                                checked={reportsSelect.includes(rp.reportId)}
                                                                className="ml-0"
                                                                style={{
                                                                    alignItems: 'start !important',
                                                                    '.MuiFormControlLabel-label': {
                                                                        paddingTop: '6px'
                                                                    }
                                                                }} />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                }
                                )}
                            </Grid>
                        </Grid>
                    </Card.Body>
                </Card>
            </Box>
        </div>
    )
}
