import { menuFirsts } from "constants/menus"
import LabOrder from "features/lab-order/lab-order"
import LabOrderPatient from "features/lab-order/lab-order-patient"

const routeLabOrder = [
    {
        ...menuFirsts.LAB,
        component: LabOrder,
        appBar: true
    },
    {
        ...menuFirsts.LAB,
        key: menuFirsts.LAB.key,
        packageKey: menuFirsts.LAB.packageKey,
        path: menuFirsts.LAB.path + '/view-history',
        component: LabOrderPatient,
        appBar: true
    }
]

export default routeLabOrder