import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ProductPreservesInterface {
  group: string
  productPreserveName: string
  productPreserveNameEn: string
  status?: string
}

export default class ProductPreservesApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('product-preserves', { params: { ...props } }).then((res) => res)
  }
  static create(payload: any) {
    return this.api.post('product-preserves', payload)
  }
  static findById(id: number): Promise<ProductPreservesInterface> {
    return this.api.get(`product-preserves/${id}`)
  }
  static update(id: number, body: ProductPreservesInterface) {
    return this.api.patch(`product-preserves/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`product-preserves/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`product-preserves/${id}`)
  }
}
