import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, ClickAwayListener, FormControlLabel, MenuItem, RadioGroup } from '@mui/material'
import { routeName } from 'routes/routes-name'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { getBranch, getClinicInfo } from 'utils/app.utils'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'
import LabGroupsApi from 'api/setting/default-labs/lab-group.api'
import LabCompanyApi from 'api/setting/default-labs/lab-company.api'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'

/** COMPONENT */
import Loading from 'component/Loading'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import RemunerationClinicFeeList from './RemunerationClinicFeeList'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import { SpanClearFilter } from 'features/report/useStyled'
import { colors } from 'constants/theme'
import { ReportFormulaDF, optionFormula } from '../report-option-df'
import ButtonCustom from 'component/Button/ButtonCustom'
import { KEY_STORAGE } from 'constants/common'
import InputRadio from 'component/Input/InputRadio'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

export default function RemunerationClinicFee() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [selectedOptionPrice, setSelectedOptionPrice] = useState<any>(localStorage.getItem(KEY_STORAGE.REPORT_CONDITION) || 'TREATMENT')
  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches] = useState<any[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])
  const [prefix, setPrefix] = useState('ALL')

  const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [selectDoctors, setSelectDoctors] = useState<any[]>(['ALL'])
  const [orderedSelectedDoctors, setOrderedSelectedDoctors] = useState<any[]>([])
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  const [labGroup, setLabGroup] = useState<any[]>([])
  const [labCompany, setLabCompany] = useState<any[]>([])
  const [labGroupId, setLabGroupId] = useState<number>(0)
  const [labCompanyId, setLabCompanyId] = useState<number>(0)

  const [optionDf, setOptionDf] = useState<'0' | '1' | '2'>('1')

  const [remunerationClinicFeeData, getRemunerationClinicFeeData] = useState<any[]>([])
  const [remunerationClinicFeePage, setRemunerationClinicFeePage] = useState<number>(1)
  const [remunerationClinicFeeTypePage, setRemunerationClinicFeeTypePage] = useState<number>(1)
  const [remunerationClinicFeeLimitPage, setRemunerationClinicFeeLimitPage] = useState<number>(10)

  const [checkDF, setCheckDF] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)

  const [popFormula, setPopFormula] = useState<boolean>(false)
  const [imageFormula, setImageFormula] = useState<string>('')

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }

    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'REMUNERATION_CLINIC_FEE') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    setOptionDf(getClinicInfo().defaultDfCon)
    if (_.isEmpty(branches)) {
      ClinicApi.findAllBranches()
        .then(({ data }) => setBranches(data))
        .catch((e) => {
          return
        })
    }

    if (_.isEmpty(receiptPrefix)) {
      ClinicApi.allBillPrefix()
        .then((resp) => setReceiptPrefix(resp.data))
        .catch((e) => {
          return
        })
    }

    if (_.isEmpty(doctors)) {
      ClinicApi.findAllDoctors()
        .then((resp) => {
          setDoctors(resp.data)
          const dentists: DentistInterface[] = resp?.data?.map((d: any) => {
            return {
              dentistId: d.userId,
              dentistFullname: d.fullname
            }
          })
          setDoctorsMultiple(dentists)
        })
        .catch((e) => {
          return
        })
    }

    if (_.isEmpty(labGroup)) {
      LabGroupsApi.findAll({ page: 1, pageLimit: 100, status: 'ACTIVE' })
        .then(({ data: dataLabGroup }) => {
          setLabGroup(dataLabGroup)
        })
        .catch((e) => {
          return
        })
    }

    if (_.isEmpty(labCompany)) {
      LabCompanyApi.findAll({ page: 1, pageLimit: 100, status: 'ACTIVE' })
        .then(({ data: dataLabCompany }) => {
          setLabCompany(dataLabCompany)
        })
        .catch((e) => {
          return
        })
    }

    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
      const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
        return {
          dentistId: d.userId,
          dentistFullname: d.fullname
        }
      })
      // setDoctorsMultiple(dentists)
      setSelectDoctors(['ALL', ...dentists.map((d: DentistInterface) => d.dentistId)])
    })
    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [branchId])

  const fetchRemunerationClinicFee = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: remunerationClinicFeePage,
      pageLimit: remunerationClinicFeeLimitPage,
      branchId: branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1],
      optionPrice: selectedOptionPrice

    }
    condition = { ...condition, labCompanyId: labCompanyId }
    condition = { ...condition, labGroupId: labGroupId }
    if (selectDoctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }
    if (prefix) condition = { ...condition, prefix: prefix === 'ALL' ? '' : prefix }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (optionDf) condition = { ...condition, optionDf: Number(optionDf) }
    if (rangeDate) {
      if ((rangeDate[0] !== null || rangeDate[0] !== '') && (rangeDate[1] !== null || rangeDate[1] !== '')) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }

    if (condition.doctorId) {
      ReportApi.reportRemittanceClinicFee(condition)
        .then(({ headers, data }) => {
          getRemunerationClinicFeeData(data)
          setRowCount(headers['x-total'])
        })
        .catch((e) => setLoading(false))
        .finally(() => setLoading(false))
    }
  }, [sortBy, sortType, branchId, labCompanyId, labGroupId, remunerationClinicFeeTypePage, prefix, optionDf, rangeDate, selectDoctors, selectedOptionPrice])

  const setDoc = (data: any) => {
    const sortedSelectData = data.sort((a: any, b: any) => {
      const idA = a.dentistId
      const idB = b.dentistId
      return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB)
    })
    return sortedSelectData
  }
  const filter = [
    branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
    rangeDate[0],
    rangeDate[1],
    prefix === 'ALL' ? `${t(`REPORT.FILTER.ALL`)}` : prefix,
    _.isEmpty(selectDoctors)
      ? ` ${t('REPORT.FILTER.NO_SELECT')}`
      : selectDoctors.filter((i) => typeof i === 'number').length === doctors.length
        ? `${t('REPORT.FILTER.ALL')}`
        : `${setDoc(doctors.filter((d: any) => selectDoctors.includes(d.userId)))
          ?.map((d: any) => d.fullname)
          .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
          .join(', ')}`,
    labGroupId ? `${_.get(_.find(labGroup, { labGroupId: labGroupId }), 'labGroupName' || 'labGroupNameEn')} ` : `${t(`REPORT.FILTER.ALL`)}`,
    labCompanyId ? `${_.get(_.find(labCompany, { labCompanyId: labCompanyId }), 'labCompanyName' || 'labCompanyNameEn')} ` : `${t(`REPORT.FILTER.ALL`)}`,
    optionDf === '0' ? t('REPORT.FILTER.NO_CAL_DF') : t(`REPORT.FILTER.DF${optionDf}`)
  ]

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE`)} (${rangeDate[0]} - ${rangeDate[1]})`,
    sheetName: `${t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE`)}`
  })

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setRemunerationClinicFeeLimitPage(limit)

  const handleChangePage = (val: number) => setRemunerationClinicFeePage(val)

  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }
  useEffect(() => {
    fetchRemunerationClinicFee()
  }, [fetchRemunerationClinicFee])

  useEffect(() => {
    if (imageFormula === '') {
      setPopFormula(false)
    } else {
      setPopFormula(true)
    }
  }, [imageFormula])

  return (
    <div>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <RadioGroup
                row
                value={selectedOptionPrice}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="d-inline"
                onChange={(e) => {
                  localStorage.setItem(KEY_STORAGE.REPORT_CONDITION, e.target.value.toString())
                  setSelectedOptionPrice(e.target.value)
                }}
              >
                <FormControlLabel value="TREATMENT" control={<InputRadio />} label={t('REPORT.FILTER.PRICE_OF_TREATMENT')} />
                <FormControlLabel value="PAID" control={<InputRadio />} label={t('REPORT.FILTER.ACTUAL_PRICE_PAID')} />
              </RadioGroup>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setRemunerationClinicFeePage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                    setRemunerationClinicFeePage(1)
                    setRemunerationClinicFeeTypePage(1)
                  }}
                  label={''}
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setPrefix(e.target.value)
                    setRemunerationClinicFeePage(1)
                    setRemunerationClinicFeeTypePage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}`}
                  label={''}
                  selectId="select-prefix"
                  labelId="label-prefix"
                  value={prefix}
                  options={[
                    <MenuItem key="0" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    _.map(receiptPrefix, (data: BillInterface) => {
                      return (
                        <MenuItem key={data.billPrefixId} value={data.prefix}>
                          {data.prefix}
                        </MenuItem>
                      )
                    })
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col
                sm={6}
                md={4}
                xl={3}
                className={'pt-2 px-1'}
                onClick={() => {
                  setCheckDF(!checkDF)
                  setRemunerationClinicFeePage(1)
                }}
              >
                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                  <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                    <AutocompleteSelect
                      open={openFilterDoctors}
                      multiple
                      labelId="label-doctors"
                      options={['ALL', ...doctors, 'CLEAR']}
                      getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                      renderOption={(props, option) => {
                        if (option === 'ALL') {
                          return (
                            <ListFilterDoctors {...props} key={option} component="li" value={option}>
                              <InputCheckbox
                                label={t('REPORT.REPORT_GROUP.ALL')}
                                checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                                indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                                className={'pe-none'}
                              />
                            </ListFilterDoctors>
                          )
                        } else if (option === 'CLEAR') {
                          return (
                            <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                              <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                            </Box>
                          )
                        } else {
                          return (
                            <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                              <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <>
                            <span className="pr-1">{t('USER_DOCTOR')}:</span>
                            {!openFilterDoctors &&
                              ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                                <Box className="text-ellipsis">
                                  {_.map(tagValue, (option: any, index: number) => {
                                    return (
                                      <span>
                                        {_.find(doctors, { userId: option })?.fullname}
                                        {index !== _.findLastIndex(tagValue) && option && `,`}
                                      </span>
                                    )
                                  })}
                                </Box>
                              ))}
                          </>
                        )
                      }}
                      onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                      noOptionsText={t('NOT_FOUND')}
                      filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                      value={selectDoctors}
                      height={32}
                      disableClearable
                    />
                  </CustomFilterDoctor>
                </ClickAwayListener>
              </Col>
            </Row>

            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <UseStyled.HeightAutocompleteSelect>
                  <AutocompleteSelect
                    key={'lab-group'}
                    labelId="lab-group"
                    inputLabel={t('REPORT.FILTER.LAB_GROUP')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={labGroup}
                    getOptionLabel={(option: any) => option.labGroupName}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.labGroupName}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      if (value) {
                        setLabGroupId(value.labGroupId)
                      }
                    }}
                    value={_.find(labGroup, (val: any) => Number(val.labGroupId) === Number(labGroupId)) || null}
                    disableClearable
                  />
                </UseStyled.HeightAutocompleteSelect>
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <UseStyled.HeightAutocompleteSelect>
                  <AutocompleteSelect
                    key={'lab-company'}
                    labelId="lab-company"
                    inputLabel={t('REPORT.FILTER.LAB_COMPANY')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={labCompany}
                    getOptionLabel={(option: any) => option.labCompanyName}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.labCompanyName}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      if (value) {
                        setLabCompanyId(value.labCompanyId)
                      }
                    }}
                    value={_.find(labCompany, (val: any) => Number(val.labCompanyId) === Number(labCompanyId)) || null}
                    disableClearable
                  />
                </UseStyled.HeightAutocompleteSelect>
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setOptionDf(e.target.value)
                    setRemunerationClinicFeePage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.OPTION_DF')}: ${optionDf === '0' ? t('REPORT.FILTER.NO_CAL_DF') : t(`REPORT.FILTER.DF${optionDf}`)}`}
                  label={''}
                  selectId="select-optionDf"
                  labelId="label-optionDf"
                  value={optionDf}
                  options={[
                    <MenuItem key="0" value="0">
                      {t('REPORT.FILTER.NO_CAL_DF')}
                    </MenuItem>,
                    new Array(3).fill(null).map((item: any, index: number) => {
                      return (
                        <MenuItem key={index + 1} value={`${index + 1}`}>
                          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>{t(`REPORT.FILTER.DF_${index + 1}`)}</Box>
                            <Box>
                              <ButtonCustom
                                textButton={t('REPORT.FORMULA')}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  setImageFormula(optionFormula[`DF_${index + 1}`] || '')
                                }}
                              />
                            </Box>
                          </Box>
                        </MenuItem>
                      )
                    })
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 1122.519685px 800.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <RemunerationClinicFeeList
              page={remunerationClinicFeePage}
              pageLimit={remunerationClinicFeeLimitPage}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={remunerationClinicFeeData}
              doctors={doctorsMultiple}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              filter={filter}
              filterDateStart={rangeDate[0]}
              filterDateEnd={rangeDate[1]}
              componentRef={componentRef}
            />
          </Box>
        </Card.Body>
      </Card>
      <Loading show={loading} type="fullLoading" />
      {popFormula && <ReportFormulaDF img={imageFormula} setImg={setImageFormula} />}
    </div>
  )
}
