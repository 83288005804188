import { Box, Grid, Avatar } from '@mui/material';
import { selectMe } from 'app/slice/user.slice';
import { useSelector } from 'react-redux';
import { fileUrl } from 'api/api'
import { colors } from 'constants/theme'
import ButtonCustom from 'component/Button/ButtonCustom'
import { routeName } from 'routes/routes-name';
import { useHistory } from 'react-router-dom';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** STYLE */
import { Title, Subtitle } from 'features/page/style'
import { imgs } from 'constants/images';
import { templateImage } from 'constants/theme';
import { getPermissionShortcuts } from 'utils/app.utils';
import _ from 'lodash';
import { menuSeconds } from 'constants/menus';

export default function Home() {
  const history = useHistory()
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  const shortcutPermission = getPermissionShortcuts()
  const checkPermission = (key: string) => {
    return shortcutPermission ? _.includes(shortcutPermission, key) : false
  }
  const renderImage = (img: any) => {
    if (img) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  return (
    <div className='h-100 p-3'>
      <Grid container className='h-100' justifyContent={'center'}>
        <Grid item lg={6} xl={5} className='w-100 d-flex align-items-center justify-content-center d-none d-xl-flex'>
          <img src={templateImage.welcome} alt="" style={{ maxWidth: '100%' }} />
        </Grid>
        <Grid item lg={6} xl={5} className='w-100 d-flex flex-column align-items-center justify-content-xl-center mt-5 mt-xl-0'>
          <Avatar alt="Profile" src={renderImage(user?.profile?.fullpath)} className={'w-100 h-100'} sx={{ maxWidth: '146px', maxHeight: '146px', backgroundColor: colors.white, border: `1px solid ${colors.lightGray}`, borderRadius: '100px' }}>
            <img src={imgs.defaultAvatar} className="w-100 h-100" alt="" />
          </Avatar>
          <Box>
            <Title>{user?.fullname ? t('WELCOME', { full_name: user?.fullname }) : ''}</Title>
            <Subtitle>{t('WELCOME_DESCRIPTION', { clinic: t('CLINIC_TYPE') })}</Subtitle>
          </Box>
          {checkPermission(menuSeconds.HOME.key) && <Box className='w-100 mx-auto mt-5 mt-sm-4' sx={{ maxWidth: '300px' }}>
            <ButtonCustom textButton={t('BUTTON.GO_TO_HOME')} onClick={() => history.push(routeName.home)} className={'w-100'} />
          </Box>}
        </Grid>
      </Grid>
    </div>
  )
}
