import _ from 'lodash'
/** ROUTE NAME */
import { routeName } from 'routes/routes-name'
import { MENUS_FIRST } from 'constants/menus'

const subStringRouteName = (name: string) => {
  const preName = name.substring(1).split('/')
  if (!_.isEmpty(preName)) return preName[0]
  else return ''
}

export const matchRoute = (name: string) => {
  /**
   * key name level 1
   * "COUNTER"
   * "DENTIST"
   * "FINANCE"
   * "LAB"
   * "X_RAY"
   * "APPLICATION"
   * "CLINIC_MANAGEMENT"
   * "WAREHOUSES"
   * "REPORT"
   * "SETTING"
   */

  if (
    name === subStringRouteName(routeName.register) ||
    name === subStringRouteName(routeName.doctorSchedule) ||
    name === subStringRouteName(routeName.appointment) ||
    name === subStringRouteName(routeName.patientManagement) ||
    name === subStringRouteName(routeName.retrospectiveOrder)
  ) {
    return MENUS_FIRST.COUNTER
  } else if (name === 'treatments') {
    return MENUS_FIRST.DENTIST
  } else if (
    name === subStringRouteName(routeName.payment) ||
    name === subStringRouteName(routeName.paymentDetail) ||
    name === subStringRouteName(routeName.overdue) ||
    name === subStringRouteName(routeName.overdueDetail) ||
    name === subStringRouteName(routeName.salary) ||
    name === subStringRouteName(routeName.incomeExpenses) ||
    name === subStringRouteName(routeName.advance)
  ) {
    return MENUS_FIRST.FINANCE
  } else if (name === subStringRouteName(routeName.labOrder)) {
    return MENUS_FIRST.LAB
  } else if (name === subStringRouteName(routeName.xray)) {
    return MENUS_FIRST.X_RAY
  } else if (
    name === subStringRouteName(routeName.product) ||
    name === subStringRouteName(routeName.importProduct) ||
    name === subStringRouteName(routeName.medicine) ||
    name === subStringRouteName(routeName.adjustment) ||
    name === subStringRouteName(routeName.inventory)
  ) {
    return MENUS_FIRST.WAREHOUSES
  } else if (
    name === subStringRouteName(routeName.registerApp) ||
    name === subStringRouteName(routeName.news) ||
    name === subStringRouteName(routeName.promotions)
  ) {
    return MENUS_FIRST.APPLICATION
  } else if (name === subStringRouteName(routeName.report)) {
    return MENUS_FIRST.REPORT
  } else if (name === subStringRouteName(routeName.clinicManagement)) {
    return MENUS_FIRST.CLINIC_MANAGEMENT
  } else if (name === subStringRouteName(routeName.custom)) {
    return MENUS_FIRST.CUSTOM
  }
  else if (
    name === subStringRouteName(routeName.servicePoint) ||
    name === subStringRouteName(routeName.branch) ||
    name === subStringRouteName(routeName.roles) ||
    name === subStringRouteName(routeName.positions) ||
    name === subStringRouteName(routeName.personnels) ||
    name === subStringRouteName(routeName.treatmentRights) ||
    name === subStringRouteName(routeName.handInsurances) ||
    name === subStringRouteName(routeName.templateDoctorFee) ||
    name === subStringRouteName(routeName.doctorFee) ||
    name === subStringRouteName(routeName.doctorFeeDetail) ||
    name === subStringRouteName(routeName.clinicFees) ||
    name === subStringRouteName(routeName.recommend) ||
    name === subStringRouteName(routeName.diagnosisType) ||
    name === subStringRouteName(routeName.diagnosis) ||
    name === subStringRouteName(routeName.operativeUnits) ||
    name === subStringRouteName(routeName.operativeTypes) ||
    name === subStringRouteName(routeName.operatives) ||
    name === subStringRouteName(routeName.xrayFilmTypes) ||
    name === subStringRouteName(routeName.imageTypes) ||
    name === subStringRouteName(routeName.recalls) ||
    name === subStringRouteName(routeName.treatmentPlanTypes) ||
    name === subStringRouteName(routeName.treatmentPlans) ||
    name === subStringRouteName(routeName.treatmentCourseTypes) ||
    name === subStringRouteName(routeName.treatmentCourse) ||
    name === subStringRouteName(routeName.labs) ||
    name === subStringRouteName(routeName.labType) ||
    name === subStringRouteName(routeName.labGroup) ||
    name === subStringRouteName(routeName.labCompany) ||
    name === subStringRouteName(routeName.medicineTypes) ||
    name === subStringRouteName(routeName.medicineFormats) ||
    name === subStringRouteName(routeName.medicineUnits) ||
    name === subStringRouteName(routeName.medicineInstructions) ||
    name === subStringRouteName(routeName.productUses) ||
    name === subStringRouteName(routeName.medicinePreserves) ||
    name === subStringRouteName(routeName.productTypes) ||
    name === subStringRouteName(routeName.productInstructions) ||
    name === subStringRouteName(routeName.productPreserves) ||
    name === subStringRouteName(routeName.banks) ||
    name === subStringRouteName(routeName.cardTypes) ||
    name === subStringRouteName(routeName.currencies) ||
    name === subStringRouteName(routeName.expenseTypes) ||
    name === subStringRouteName(routeName.prefixes) ||
    name === subStringRouteName(routeName.careers) ||
    name === subStringRouteName(routeName.experts) ||
    name === subStringRouteName(routeName.accessChannels) ||
    name === subStringRouteName(routeName.patientTypes) ||
    name === subStringRouteName(routeName.frequenciesAlcohols) ||
    name === subStringRouteName(routeName.frequenciesCigarettes) ||
    name === subStringRouteName(routeName.templateText) ||
    name === subStringRouteName(routeName.appointmentDurations) ||
    name === subStringRouteName(routeName.productUnits)
  ) {
    return MENUS_FIRST.SETTING
  } else return ''
}
