import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/47.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginBottom: 'auto',
  top: '1.4%'
}))

export default function Teeth47(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: "40.56", height: "77.522", viewBox: "0 0 40.56 77.522" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <TextNumberL>47</TextNumberL>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <DivTeethL className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`lg teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`lg ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9170">

              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5339"
                d="m182.825 504.476 1.356 9.479a.566.566 0 0 1-.1.406c-5.67 7.984-6.544 17.366-8.105 26.512-1.869 10.912-3.429 21.926-10.209 31.214-1.447 1.977-2.839 4.1-5.351 4.865a.591.591 0 0 1-.484-.067c-1.825-1.187-1.381-2.864-1-4.463 2.024-8.5 3.371-17.06 2.017-25.819-.153-1-.363-1.988-.684-3.54a.575.575 0 0 0-1.118-.027c-2.768 10.827-3.134 21.3-8.945 29.938a2.3 2.3 0 0 1-3.721.157c-1.45-1.812-1.4-4.005-1.5-6.053-.522-10.235 1.025-20.341 2.217-30.453.877-7.4 1.3-14.727-.516-22.049a14.684 14.684 0 0 1 .264-8.888c1.1-2.907 2.881-4.718 6.381-3.79 2.739.722 5.382-2 8.965-.458.013.006.032.006.045.013a6.056 6.056 0 0 1 .677.342c.161.09.322.193.49.3a21.31 21.31 0 0 0-1.225-.11h-.006c-2.668-.161-4.692.29-5.968 1.592a6 6 0 0 0-1.534 3.783c-.387 3.989 1.508 6.419 5.491 6.838a14.843 14.843 0 0 1 6.458 1.708c4.041 2.475 11.994-.883 12.922-5.549a53.681 53.681 0 0 0 .581-6.148l-.154-.442c-.661-.7-1.316-1.4-2.354-2.516l-.058-.064a4.56 4.56 0 0 1-.174-.187 9.585 9.585 0 0 1 5.216 3.177.581.581 0 0 1 .126.299z"
                transform="translate(-144.87 -499.454)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6495"
                  d="M179.067 525.043c-1.487 4.937-2.169 10.425-3.092 15.83-1.869 10.912-3.429 21.926-10.209 31.214-1.447 1.977-2.839 4.1-5.351 4.865a.591.591 0 0 1-.484-.067c-1.825-1.187-1.381-2.864-1-4.463 2.024-8.5 3.371-17.06 2.017-25.819-.153-1-.363-1.988-.684-3.54a.575.575 0 0 0-1.118-.027c-2.768 10.827-3.134 21.3-8.945 29.938a2.3 2.3 0 0 1-3.721.157c-1.45-1.812-1.4-4.005-1.5-6.053-.522-10.235 1.025-20.341 2.217-30.453a81.316 81.316 0 0 0 .632-14.675c.625 1.391.6 2.381 2.828 4.313 2.563 1.953 3.313 1.469 5.375 3.135 1.766 1.24 1.734 1.818 4.563-.135.922-.828 3.078-2.766 4.344-2.187 1.828.25 2.672 2.663 5.438 2.781 1.438-.062 3.094-.219 6.3-2.641 1.209-1.14 1.084-.827 2.39-2.173z"
                  transform="translate(-144.87 -499.454)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 9704">
                <path
                  data-name="Path 5343"
                  d="M147.393 515.933s-4.129-8.781 0-13.454 6.707-2.993 8.319-3.511 7.05-.469 7.473.715 2.679 1.318 4.29.768 4.028-2.076 6.445-1.8 7.631 1.536 8.379 3.179 4.84 4.989 2.823 12.242c0 0-5.4 6.59-4.918 9.188 0 0-7.9 9.184-12.729 4.351s-7.09 4.584-10.634 1.406-9.241-2.007-9.448-13.084z"
                  transform="translate(-145.114 -498.622)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 6589"
                  d="M149.415 517.234s-3.781-8.042 0-12.322 6.143-2.74 7.619-3.215 6.456-.43 6.843.655 2.453 1.207 3.929.7 3.689-1.9 5.9-1.645 6.989 1.407 7.674 2.912 4.433 4.57 2.586 11.213c0 0-4.945 6.035-4.5 8.414 0 0-7.231 8.411-11.658 3.984s-6.493 4.2-9.739 1.288-8.463-1.84-8.654-11.984z"
                  transform="translate(-146.235 -500.148)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5345"
                  d="M153.3 518.332s-3.114-6.624 0-10.149 5.059-2.257 6.274-2.648 5.317-.354 5.636.539 2.021.994 3.236.579 3.038-1.565 4.861-1.355 5.756 1.159 6.32 2.4 3.651 3.763 2.129 9.234c0 0-4.073 4.971-3.709 6.929 0 0-5.955 6.927-9.6 3.281s-5.347 3.458-8.022 1.06-6.966-1.516-7.125-9.87z"
                  transform="translate(-147.375 -500.987)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />

                {(isToothRoot && props.type === 'treatment') ?
                  <>
                    <path data-name="Path 6553" d="M9.276 10.238c-.045 1.2-6.39 42.468-5.687 43.34-.08.705 6.067-37.095 7.312-42.3-1.224-.718.282-.328-1.625-1.04z" transform="translate(-.443 18.753)" style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }} />
                    <path
                      data-name="Path 6554"
                      d="M7.483 12.457c.687 5.808-4.432 27.783-4.477 27.907-3.476 9.524-6.262 16.423-7.027 17.374.041.357 7.9-19.927 9.578-33.759 1.391-10.1-.063-11.329.295-11.309-.032.207-.016.09.007-.04.771-.021-1.017.285 1.624-.173z"
                      transform="translate(19.633 18.218)"
                      style={{

                        fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                        stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                      }}
                    />
                  </> : ''}
                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9466">
                      <path data-name="Path 6581" d="m38.246 7.215-40 68.285" transform="rotate(2 147.227 182.04)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6583" d="m-1.754 7.215 40 68.285" transform="rotate(2 147.227 182.04)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') && (
                  <>
                    <defs>
                      <linearGradient id="2nl69q2iqa" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="p29j1w7f0b" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9357">
                      <path data-name="Path 5339" d="m182.825 504.476 1.356 9.479a.566.566 0 0 1-.1.406c-5.67 7.984-6.544 17.366-8.105 26.512-1.869 10.912-3.429 21.926-10.209 31.214-1.447 1.977-2.839 4.1-5.351 4.865a.591.591 0 0 1-.484-.067c-1.825-1.187-1.381-2.864-1-4.463 2.024-8.5 3.371-17.06 2.017-25.819-.153-1-.363-1.988-.684-3.54a.575.575 0 0 0-1.118-.027c-2.768 10.827-3.134 21.3-8.945 29.938a2.3 2.3 0 0 1-3.721.157c-1.45-1.812-1.4-4.005-1.5-6.053-.522-10.235 1.025-20.341 2.217-30.453.877-7.4 1.3-14.727-.516-22.049a14.684 14.684 0 0 1 .264-8.888c1.1-2.907 2.881-4.718 6.381-3.79 2.739.722 5.382-2 8.965-.458.013.006.032.006.045.013a6.056 6.056 0 0 1 .677.342c.161.09.322.193.49.3a21.31 21.31 0 0 0-1.225-.11h-.006c-2.668-.161-4.692.29-5.968 1.592a6 6 0 0 0-1.534 3.783c-.387 3.989 1.508 6.419 5.491 6.838a14.843 14.843 0 0 1 6.458 1.708c4.041 2.475 11.994-.883 12.922-5.549a53.681 53.681 0 0 0 .581-6.148l-.154-.442c-.661-.7-1.316-1.4-2.354-2.516l-.058-.064a4.56 4.56 0 0 1-.174-.187 9.585 9.585 0 0 1 5.216 3.177.581.581 0 0 1 .126.299z" transform="translate(-144.87 -499.454)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5340" d="m186.965 500.674-7.994 7.282c-1.328-1.192-1.034-2.607-1.1-4.242 2.977-.999 5.514-3.352 9.094-3.04z" transform="translate(-156.6 -499.341)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5341" d="M168.23 501.877c-3.741-1.088-6.9.018-9.668 2.578z" transform="translate(-149.737 -499.632)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5342" d="M188.585 501.107c-4.214.46-7.659 1.995-9.023 6.445z" transform="translate(-157.202 -499.506)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5343" d="M147.393 515.933s-4.129-8.781 0-13.454 6.707-2.993 8.319-3.511 7.05-.469 7.473.715 2.679 1.318 4.29.768 4.028-2.076 6.445-1.8 7.631 1.536 8.379 3.179 4.84 4.989 2.823 12.242c0 0-5.4 6.59-4.918 9.188 0 0-7.9 9.184-12.729 4.351s-7.09 4.584-10.634 1.406-9.241-2.007-9.448-13.084z" transform="translate(-145.114 -498.622)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5344" d="M149.415 517.234s-3.781-8.042 0-12.322 6.143-2.74 7.619-3.215 6.456-.43 6.843.655 2.453 1.207 3.929.7 3.689-1.9 5.9-1.645 6.989 1.407 7.674 2.912 4.433 4.57 2.586 11.213c0 0-4.945 6.035-4.5 8.414 0 0-7.231 8.411-11.658 3.984s-6.493 4.2-9.739 1.288-8.463-1.84-8.654-11.984z" transform="translate(-145.888 -499.603)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5345" d="M153.3 518.332s-3.114-6.624 0-10.149 5.059-2.257 6.274-2.648 5.317-.354 5.636.539 2.021.994 3.236.579 3.038-1.565 4.861-1.355 5.756 1.159 6.32 2.4 3.651 3.763 2.129 9.234c0 0-4.073 4.971-3.709 6.929 0 0-5.955 6.927-9.6 3.281s-5.347 3.458-8.022 1.06-6.966-1.516-7.125-9.87z" transform="translate(-147.375 -500.987)" style={{ fill: colors.white }} />
                      <g data-name="Group 9395">
                        <g data-name="Group 9396" transform="translate(14.516 11.105)">
                          <g data-name="Rectangle 3927" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.602" height="11.602" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.602" height="10.602" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.119 41.119 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(-6.653 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(3.089 2.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(3.289 2.015)" style={{ fill: 'url(#2nl69q2iqa)' }} />
                          <g data-name="Group 9397" transform="translate(0 .5)">
                            <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(3.289 6.215)" style={{ fill: '#cbcccc' }} />
                            <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(3.289 6.015)" style={{ fill: 'url(#p29j1w7f0b)' }} />
                          </g>
                        </g>
                        <path data-name="Path 6488" d="M-1.219 8.579s15.993 7.608 39.611.331" transform="translate(2.085 4.429)" style={{ strokeLinecap: 'round', stroke: '#b5b7b7', fill: 'none' }} />
                        <path data-name="Path 6486" d="M-1.115 8.61s16.967 8.022 39.589.333" transform="translate(1.899 3.606)" style={{ stroke: '#babbbb', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </>
                ) || ""}

                {(isFixture && props.type === 'treatment') ? (
                  <>
                    <defs>
                      <linearGradient id="6zdpyhxnfa" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="js1s0896tb" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9499">
                      <path data-name="Path 5340" d="m177.867 500.674 7.994 7.282c1.328-1.192 1.034-2.607 1.1-4.242-2.977-.999-5.514-3.352-9.094-3.04z" transform="translate(-167.673 -499.341)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5341" d="M158.562 501.877c3.741-1.088 6.9.018 9.668 2.578z" transform="translate(-136.496 -499.631)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5342" d="M179.562 501.107c4.214.46 7.659 1.995 9.023 6.445z" transform="translate(-170.386 -499.506)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5343" d="M183.839 515.933s4.129-8.781 0-13.454-6.707-2.992-8.319-3.511-7.05-.469-7.472.715-2.679 1.318-4.29.768-4.028-2.076-6.445-1.8-7.631 1.536-8.379 3.179-4.84 4.989-2.823 12.242c0 0 5.4 6.59 4.918 9.188 0 0 7.9 9.184 12.729 4.35s7.09 4.584 10.634 1.406 9.238-2.006 9.447-13.083z" transform="translate(-145.558 -498.622)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5344" d="M182.794 517.234s3.781-8.042 0-12.322-6.143-2.74-7.619-3.215-6.456-.43-6.843.655-2.453 1.207-3.929.7-3.689-1.9-5.9-1.645-6.989 1.407-7.674 2.912-4.433 4.57-2.586 11.213c0 0 4.945 6.035 4.5 8.414 0 0 7.231 8.411 11.658 3.984s6.493 4.2 9.739 1.288 8.46-1.84 8.654-11.984z" transform="translate(-145.762 -499.602)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5345" d="M180.791 518.332s3.114-6.624 0-10.148-5.059-2.257-6.274-2.648-5.317-.354-5.636.539-2.021.994-3.236.579-3.038-1.566-4.861-1.355-5.756 1.159-6.32 2.4-3.65 3.763-2.129 9.234c0 0 4.073 4.971 3.709 6.929 0 0 5.955 6.927 9.6 3.281s5.348 3.458 8.022 1.06 6.968-1.517 7.125-9.871z" transform="translate(-146.158 -500.987)" style={{ fill: colors.white }} />
                      <g data-name="Group 9536">
                        <g data-name="Path 6490" transform="translate(10.947 29.247)" style={{ fill: 'url(#6zdpyhxnfa)' }}>
                          <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                          <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(.001 24.044)" style={{ strokeLinejoin: 'round', fill: 'url(#js1s0896tb)' }}>
                          <path d="M1.602 7.569c-.113 0-.118 0-.207-.53C1.28 6.348 1.035 4.906.1 1.9.108.906.942.1 1.965.1H39.31c1.025 0 1.86.807 1.866 1.802-.496 2.006-.756 3.303-.928 4.162-.3 1.493-.307 1.493-.722 1.493h-.094l-.122-.001H1.965c-.1 0-.182.004-.248.008-.045.003-.084.005-.115.005z" style={{ stroke: 'none' }} />
                          <path d="M1.965.2C1.002.2.217.953.2 1.884c.934 3.003 1.177 4.445 1.294 5.14.034.202.064.38.09.445h.018c.028 0 .066-.002.11-.005.067-.004.15-.008.253-.008H39.31l.216.001c.24 0 .26-.02.309-.118.092-.183.171-.578.315-1.295.172-.858.432-2.152.926-4.154-.013-.934-.8-1.69-1.766-1.69H1.965m0-.2H39.31c1.086 0 1.966.857 1.966 1.914-1.478 5.981-.88 5.742-1.966 5.742H1.965c-1.085 0 0 .568-1.965-5.742C0 .857.88 0 1.965 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(5.436 14.383)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(5.723 19.582)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(6.171 42.109)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(5.979 24.125)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(5.979 30.125)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(5.979 36.125)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(6.17 47.747)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </>
                ) : ""}
              </g>
            </g>
          </svg>}
      </DivTeethL>
    </Div>
  )
}
