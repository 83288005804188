import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface LabsInterface {
  labName: string
  inputSupport: string
  status: string
}

export default class LabsApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('labs', { params: { ...props } }).then((res) => res)
  }

  static findAllActive(props: FindAllInterface): Promise<any> {
    return this.api.get('labs/all-active', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('labs', playload)
  }

  static findById(id: number): Promise<LabsInterface> {
    return this.api.get(`labs/${id}`)
  }

  static update(id: number, body: LabsInterface) {
    return this.api.patch(`labs/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`labs/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`labs/${id}`)
  }
}
