import { colors } from "constants/theme"
import IconProps from "./icon-props"

export default function Home(props: IconProps) {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.586 21H4.70604V11.6H1.18604L12.9 1.03601L24.669 11.595H21.149V20.995H15.265V13.955H10.586V21Z" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} stroke={props.color || colors.themeSecondColor} strokeWidth="1.6" strokeLinejoin="round" />
    </svg>
  )
}
