import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { Dropdown, Image } from 'react-bootstrap'
import Button from '@mui/material/Button'

export const PreviewImage = styled(Box)(({ theme }) => ({
    height: '100%',
    overflow: 'hidden'
}))

export const ColumnPanel = styled('div')(({ theme }) => ({
    marginBottom: '0.5rem',
    padding: '0 0.25rem',
    width: '100%',
    flex: 'none',
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
}))

export const CardImg = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    '.btn-delete': {
        position: 'absolute',
        cursor: 'pointer',
        right: 8,
        top: -38,
        zIndex: 5,
        [theme.breakpoints.between('xs', 'sm')]: {
            img: {
                width: '20px !important'
            }
        },
        [theme.breakpoints.down('md')]: {
            top: -38
        },
        [theme.breakpoints.down('md')]: {
            top: -35
        },
        [theme.breakpoints.down('xs')]: {
            top: -35,
            width: 20,
            hieght: 20
        }
    },
    '.btn-select': {
        position: 'absolute',
        cursor: 'pointer',
        right: 0,
        top: -38,
        zIndex: 5,
        [theme.breakpoints.down('md')]: {
            top: -38
        },
        [theme.breakpoints.down('md')]: {
            top: -35
        },
        [theme.breakpoints.down('xs')]: {
            top: -35
        }
    }
}))

export const DivImg = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        border: `1px solid ${colors.lightGray}`
    },
    '&.active': {
        border: `5px solid ${colors.themeSecondColor}`,
        [theme.breakpoints.down('lg')]: {
            borderWidth: 3
        }
    }
}))

export const DivUpload = styled(DivImg)(({ theme }) => ({
    backgroundImage: `repeating-linear-gradient(0deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(90deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(180deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(270deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px) `,
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundSize: '1px 100%, 100% 1px, 1px 100% , 100% 1px',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    '.upload-image': {
        img: {
            width: 56,
            [theme.breakpoints.down('xs')]: {
                width: 32
            }
        },
        p: {
            fontSize: 14,
            color: colors.textExtraLightGray,
            [theme.breakpoints.down('xs')]: {
                fontSize: 12
            }
        }
    }
}))

export const DropdownMenu = styled(Dropdown.Menu)(({ theme }) => ({}))

export const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
    color: colors.textPrimary,
    '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
    }
}))

export const ImageLabel = styled(Image)(({ theme }) => ({
    verticalAlign: 'top',
    marginTop: '5px',
    marginRight: '4px'
}))

export const XrayBox = styled(Box)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('xl')]: {
        width: '100%'
    },
    [theme.breakpoints.down('md')]: {
        width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
        width: '100%'
    }
}))

export const XrayCard = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    position: 'relative',
    color: colors.textPrimary,
    border: `1px solid ${colors.lightGray}`,
    borderRadius: 5,
    transition: 'border .3s ease',
    '.card-image': {
        '> .MuiBox-root': {
            paddingTop: '82.18%',
            img: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                '&.no-val-image': {
                    transform: 'scale(0.65)',
                    opacity: 0.5
                }
            }
        }
    },
    '.card-detail': {
        borderTop: `1px solid ${colors.lightGray}`,
        padding: '0.5rem 0.5rem 0.625rem'
    },
    '&.active': {
        border: `1px solid ${colors.themeSecondColor}`
    }
}))

export const BtnConfirm = styled(Button)(({ theme }) => ({
    width: 78,
    height: 44,
    fontSize: 16,
    backgroundColor: `${colors.themeSecondColor} !important`,
    color: `${colors.white} !important`,
    padding: '0 16px',
    boxShadow: 'none !important',
    borderColor: 'transparent',
    '&:hover': {
        backgroundColor: `${colors.themeSecondColor} !important`,
        color: `${colors.white} !important`
    },
    '&:disabled': {
        backgroundColor: `${colors.disabledGray} !important`,
        borderColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginLeft: '0.25rem'
    },
    [theme.breakpoints.up('sm')]: {
        marginLeft: '0.5rem'
    }
}))

export const BtnCancel = styled(Button)(({ theme }) => ({
    width: 78,
    height: 44,
    fontSize: 16,
    color: `${colors.themeSecondColor} !important `,
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    padding: '0 16px',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    '&.btn-default': {
        color: `${colors.textPrimary} !important `,
        border: `solid 1px ${colors.textExtraLightGray}  !important`
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginRight: '0.25rem'
    },
    [theme.breakpoints.up('sm')]: {
        marginRight: '.5rem'
    }
}))