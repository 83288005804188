import { menuThirds } from 'constants/menus'
import Banks from 'features/setting/finances/banks/banks'
import CardTypes from 'features/setting/finances/card-types/card-types'
import Currencies from 'features/setting/finances/currencies/currencies'
import ExpenseTypes from 'features/setting/finances/expense-types/expense-types'

const routeFinances = [
  {
    ...menuThirds.CURRENCY,
    component: Currencies,
    appBar: true
  },
  {
    ...menuThirds.EXPENSE_TYPE,
    component: ExpenseTypes,
    appBar: true
  },
  {
    ...menuThirds.BANK,
    component: Banks,
    appBar: true
  },
  {
    ...menuThirds.CARD_TYPE,
    component: CardTypes,
    appBar: true
  }
]
export default routeFinances
