import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/default-users/prefixes/style'

/** API */
import PrefixApi from 'api/setting/default-users/prefix.api'
import TreatmentsAPI from 'api/dentists/treatment.api'
/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { selectMe } from 'app/slice/user.slice'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import _ from 'lodash'
import moment from 'moment'
import { Box } from '@mui/material'
import SearchPatients from 'component/SearchPatients'
import { useHistory } from 'react-router-dom'
import InputDatePicker from 'component/Input/InputDatePicker'
import Customs from 'api/admin/custom/custom.api'
import { routeName } from 'routes/routes-name'
import { isBuddhistYear } from 'utils/app.utils'

export default function Custom() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const user = useSelector(selectMe)
  const [patientCaseId, setPatientCaseId] = useState(Number)
  const [vn, setVn] = useState<string>('')
  const [date, setDate] = useState(Date)

  const [prefixName, setPrefixName] = useState('')
  const [prefixNameEn, setPrefixNameEn] = useState('')
  const [prefixStatus, setPrefixStatus] = useState('')

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [customList, setCustomList] = useState('CUSTOM_DATE_TREATMENT')

  const [patient, setPatient]: any = useState({})

  const [dateReceive, setDateReceive] = useState(Date)

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (user?.isAdmin !== '1') history.push(routeName.welcome)
    }
  }, [user])

  const history = useHistory()
  const loadData = useCallback(() => {
    let condition: any = {}
    if (search !== '') {
      condition.name = search
    }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    patient.patientId
      ? TreatmentHistoryApi.findSummeryAllByPatientId(patient.patientId, condition)
        .then((res) => {
          if (res.status === 200 && !_.isEmpty(res.data)) {
            setData(res.data)
            setRowCount(res.headers['x-total'])
          } else {
            setData([])
            setRowCount(0)
          }
        })
        .catch(() => {
          setData([])
          setRowCount(0)
        })
      : setData([])
  }, [search, page, pageLimit, patient])

  useEffect(() => {
    loadData()
  }, [loadData])

  const getPatientId = (id: number) => {
    loadPatient(id)
  }

  const handleClear = () => {
    setPatient({})
  }

  const loadPatient = async (patientId: number) => {
    if (patientId) {
      const patientData = await TreatmentsAPI.findPatient(patientId)
      if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
        setPatient(patientData.data)
        const currentPath = window.location.pathname
        history.replace(currentPath, { patientId: patientId })
      } else setPatient([])
    }
  }

  const submitEdit = () => {
    if (!date) {
      notiError(t(`CUSTOM.MESSAGES.DATE_ANY_REQUIRED`))
      return false
    }
    dispatch(submitModal())
    Customs.update({ patientCaseId, date })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`CUSTOM.MESSAGES.SUCCESS`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          notiError(t(`CUSTOM.MESSAGES.UN_SUCCESS`))
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`CUSTOM.MESSAGES.UN_SUCCESS`))
        dispatch(unSubmitModal())
      })
  }

  const resetForm = () => {
    setPatientCaseId(0)
    setDate(Date)
  }

  const onEdit = (data: any) => {
    const { patientCaseId: patientCaseId, vnNumber: vnNumber, createAt: createAt } = data
    setPatientCaseId(patientCaseId)
    setVn(vnNumber)
    setDate(createAt)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setCustomList(statusVal)
  }
  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'prefixNameEn', disablePadding: false, label: t('CUSTOM.DATE_TREATMENT.DATE') },
    { id: 'cn_number', disablePadding: false, label: t('CUSTOM.DATE_TREATMENT.CN_NUMBER') },
    { id: 'vn_number', disablePadding: false, label: t('CUSTOM.DATE_TREATMENT.VN') },
    { id: 'patient_name', disablePadding: false, labelKey: 'CUSTOM.DATE_TREATMENT.PATIENT_NAME' },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { branchCnNumber: branchCnNumber, createdAt: createdAt, patientCaseId: patientCaseId, cnNumber: cnNumber, vnNumber: vnNumber } = objData
    const objRenderData = {
      key: patientCaseId,
      id: patientCaseId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: moment(createdAt).add(isBuddhistYear(), 'y').format('DD/MM/YYYY') },
        { option: 'TEXT', align: 'left', label: cnNumber },
        { option: 'TEXT', align: 'left', label: vnNumber },
        { option: 'TEXT', align: 'left', label: patient.firstname + ' ' + patient.lastname },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [{ option: 'EDIT', label: t(`BUTTON.EDIT`) }]
        }
      ]
    }
    return <TableRowCommon {...objRenderData} onedit={() => onEdit(objRenderData.obj)} />
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('CUSTOM.TITLE.CUSTOM')} />
        <Col sm={12} md={6} lg={4} className={'pl-md-0 pt-2'}>
          <FilterSelect
            onchange={(event) => {
              const value = event.target.value
              if (value) {
                handleChangeStatus(value)
                setPage(1)
              }
            }}
            renderValue={() => t('CUSTOM.FILTER.LIST') + ': ' + t(`CUSTOM.KEY.${customList}`)}
            label={t('ADJUSTMENT.FILTER.DOCUMENT_STATUS.LABEL')}
            selectId="select-customList"
            value={customList}
            labelId="label-customList"
            options={[
              <MenuItem key="CUSTOM_DATE_TREATMENT" value="CUSTOM_DATE_TREATMENT">
                {t(`CUSTOM.KEY.CUSTOM_DATE_TREATMENT`)}
              </MenuItem>,
              <MenuItem key="CUSTOM_CN_NUMBER" value="CUSTOM_CN_NUMBER">
                {t(`CUSTOM.KEY.CUSTOM_CN_NUMBER`)}
              </MenuItem>
            ]}
            formControlStyle={{ maxWidth: 'unset !important' }}
          />
        </Col>
        {customList === 'CUSTOM_DATE_TREATMENT' && (
          <Card.Body>
            <Card.Title className={'mb-0 pl-xl-3'}>
              <Box sx={{ maxWidth: { sm: '650px' } }}>
                <SearchPatients status="ACTIVE" callbackPatientId={getPatientId} patientId={patient.patientId} patient={patient} onClear={handleClear} />
              </Box>
              <Row className="align-items-center">
                <Col md={8}>
                  <Row className="flax-row">
                    <Col sm={12} md={6} xl={4} className="pt-2">
                      <InputTextSearch
                        keyInput={'search'}
                        label={t('INPUT.SEARCH')}
                        value={search}
                        onchange={(event) => {
                          setSearch(event.target.value)
                          setPage(1)
                        }}
                        onClear={(event) => {
                          setSearch('')
                          setPage(1)
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Title>

            <div className={'mt-3 pl-xl-3 pr-xl-2'}>
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                rowCount={rowCount}
                headCells={headCells}
                rowsData={data.map((val, i) => renderData(val, i))}
              />
            </div>
          </Card.Body>
        )}
      </Card>

      <ModalCustom
        title={`${t('CUSTOM.INPUT.EDIT_VN_SERVICE_DATE')} ${vn}`}
        component={
          <div className="pb-2">
            <InputDatePicker required={true} key={'dateReceive'} label={t('CUSTOM.INPUT.SERVICE_DATE')} dateFormat="DD/MM/YY" value={date} helperText={''} onChange={(e: any) => setDate(e.format('YYYY-MM-DD '))} />
          </div>
        }
        onReset={resetForm}
        onSubmit={submitEdit}
        textBtnCancel={t('CUSTOM.BUTTON.CANCEL')}
        textBtnConfirm={t('CUSTOM.BUTTON.SAVE')}
      />
    </div>
  )
}
