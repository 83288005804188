type iconProps = {
    width?: string
    height?: string
    color?: string
}


const BtnWebsite = (props: iconProps) => {
    return (
        <svg
            viewBox="0 0 20 20"
            width={props?.width || "24"}
            height={props?.height || "24"}
            id="icons"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10 20a10 10 0 110-20 10 10 0 010 20zm7.75-8a8.01 8.01 0 000-4h-3.82a28.81 28.81 0 010 4h3.82zm-.82 2h-3.22a14.44 14.44 0 01-.95 3.51A8.03 8.03 0 0016.93 14zm-8.85-2h3.84a24.61 24.61 0 000-4H8.08a24.61 24.61 0 000 4zm.25 2c.41 2.4 1.13 4 1.67 4s1.26-1.6 1.67-4H8.33zm-6.08-2h3.82a28.81 28.81 0 010-4H2.25a8.01 8.01 0 000 4zm.82 2a8.03 8.03 0 004.17 3.51c-.42-.96-.74-2.16-.95-3.51H3.07zm13.86-8a8.03 8.03 0 00-4.17-3.51c.42.96.74 2.16.95 3.51h3.22zm-8.6 0h3.34c-.41-2.4-1.13-4-1.67-4S8.74 3.6 8.33 6zM3.07 6h3.22c.2-1.35.53-2.55.95-3.51A8.03 8.03 0 003.07 6z"
                fill={props?.color || "white"} />
        </svg>
    )
}

export default BtnWebsite

