import { colors } from 'constants/theme'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import HelperText from 'component/Input/HelperText'

const CustomTextArea = styled(TextField)(({ theme }) => ({
  width: '100%',
  '.MuiOutlinedInput-root': {
    paddingTop: 10,
    paddingBottom: 10,
    '&:not(.Mui-disabled)': {
      backgroundColor: colors.white
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${colors.black12}`
    },
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.black12}`
      }
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.themeSecondColor}`
      }
    },
    '&.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.statusInactive} !important`
      }
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    }
  },
  '.MuiInputLabel-root': {
    transform: 'translate(14px, 10px) scale(1)',
    '&.Mui-focused, &[data-shrink="true"]': {
      transform: 'translate(14px, -9px) scale(0.75)'
    }
  },
  '.MuiInputLabel-root, .MuiInputLabel-root span': {
    fontSize: 16,
    color: `${colors.black60} !important`,
    backgroundColor: 'transparent !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      top: '50%',
      zIndex: -1
    },
    '&[data-shrink="true"]': {
      '&.Mui-focused': {
        color: `${colors.themeSecondColor} !important`
      },
      '&:before': {
        backgroundColor: colors.white
      },
      span: {
        color: `${colors.statusInactive} !important`
      }
    }
  },
  '.MuiOutlinedInput-input': {
    fontSize: 16,
    color: `${colors.textPrimary} !important`,
    textFillColor: 'unset',
    scrollbarWidth: 'thin',
    '-webkit-text-fill-color': 'unset !important',
    '&.Mui-disabled': {
      color: `${colors.black60} !important`
    },
    '&::placeholder': {
      color: colors.black60,
      opacity: 1
    },
    '&::-webkit-scrollbar': {
      width: 3
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.disabledGray,
      borderRadius: 50
    }
  },
  '&.filed-error': {
    '.MuiInputLabel-root[data-shrink="true"], .MuiInputLabel-root[data-shrink="true"] span': {
      color: `${colors.statusInactive} !important`
    }
  },
  '.MuiFormHelperText-root': {
    marginLeft: 0
  }
}))

type InputProps = {
  onchange?: (value: any) => void
  onkeyup?: (value: any) => void
  onkeydown?: (value: any) => void
  required?: boolean
  disabled?: boolean
  name?: string
  placeholder?: string
  variant?: any
  size?: 'medium' | 'small'
  label?: string
  value?: any
  type?: string
  helperText?: string | ''
  register?: any
  key?: string | 'input-text'
  rows?: number
  inputProps?: any
  height?: number | string
  hookValue?: boolean
  InputLabelProps?: any
  style?: any
}
export default function InputTextarea(props: InputProps) {
  const cusProps = props.hookValue ? { value: props.value } : { defaultValue: props.value }
  const shrink = props.value ? { shrink: true } : ''

  return (
    <>
      <CustomTextArea
        fullWidth
        multiline
        key={props.key}
        rows={props.rows || 3}
        InputProps={{
          ...props.inputProps
        }}
        style={{ backgroundColor: props.disabled ? colors.black12 : '', ...props.style }}
        className={`${props.helperText ? 'filed-error' : ''}`}
        InputLabelProps={{ ...props.InputLabelProps, key: props.key, ...shrink }}
        {...props.register}
        {...(props.register ? props.register : cusProps)}
        {...(props.register ? { defaultValue: props.value, value: props.value } : cusProps)}
        onChange={props.onchange}
        onKeyUp={props.onkeyup}
        onKeyDown={props.onkeydown}
        variant={props.variant || 'outlined'}
        size={props.size || 'medium'}
        required={props.required}
        defaultValue={props.value}
        value={props.value}
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        disabled={props.disabled}
        type={props.type || 'text'}
        error={props.helperText !== '' && props.helperText !== undefined}
        sx={{
          '.MuiOutlinedInput-root': {
            height: props.height || 'auto'
          }
        }}
        helperText={
          props.helperText ? (
            <HelperText label={props.helperText} />
          ) : (
            ''
          )
        }
      />
    </>
  )
}
