import IconProps from "assets/icon/payment-method/iconProps"

export default function Credit(props: IconProps) {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="59.961" height="40.111" viewBox="0 0 59.961 40.111">
            <g data-name="Component 265">
                <path data-name="Path 4042" d="M551.794 261h-45.627a7.175 7.175 0 0 0-7.167 7.168v25.775a7.176 7.176 0 0 0 7.167 7.168h45.626a7.176 7.176 0 0 0 7.167-7.168v-25.775a7.175 7.175 0 0 0-7.166-7.168zM557 293.943c0 3.377-3.441 5.168-5.206 5.168h-45.627c-1.765 0-5.149-2.243-5.168-5.168v-15.832h56zm0-20.931h-56v-4.848c-.026-3.219 3.4-5.056 5.167-5.056h45.626c1.766 0 5.2 1.955 5.206 5.056z" transform="translate(-499 -261)" style={{ fill: props.active || '#9e9e9e' }} />
                <path data-name="Path 4043" d="M550.5 440.34h20.2c.723 0 1.309-.178 1.309-.4v-1.207c0-.219-.586-.4-1.309-.4h-20.2c-.723 0-1.309.177-1.309.4v1.207c0 .219.586.4 1.309.4zm0 0" transform="translate(-541.007 -407.229)" style={{ fill: props.active || '#9e9e9e' }} />
                <path data-name="Path 6621" d="M550.166 441.34h15.046c.539 0 .975-.267.975-.595v-1.81c0-.329-.437-.6-.975-.6h-15.046c-.539 0-.975.266-.975.6v1.81c0 .328.437.595.975.595zm0 0" transform="translate(-541.007 -412.229)" style={{ fill: props.active || '#9e9e9e' }} />
                <g data-name="Ellipse 486" transform="translate(44 25.281)" style={{ stroke: props.active || '#9e9e9e', fill: props.active || '#9e9e9e' }}>
                    <circle cx="4" cy="4" r="4" style={{ stroke: 'none' }} />
                    <circle cx="4" cy="4" r="3.5" style={{ fill: 'none' }} />
                </g>
                <g data-name="Ellipse 487" transform="translate(39 25.281)" style={{ stroke: props.active || '#9e9e9e', fill: props.active || '#9e9e9e' }}>
                    <circle cx="4" cy="4" r="4" style={{ stroke: 'none' }} />
                    <circle cx="4" cy="4" r="3.5" style={{ fill: 'none' }} />
                </g>
            </g>
        </svg>
    )
}
