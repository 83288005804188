import React from 'react'

const IconCommon = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.75 2.66667L1 3.5L3.25 6L7 1.83333L6.25 1L3.25 4.33333L1.75 2.66667Z" fill="#5A5A5A" />
    </svg>
  )
}

export default IconCommon