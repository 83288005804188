import { Box, styled } from '@mui/material'
import IconCommon from 'assets/icon/IconCommon'
import { colors } from 'constants/theme'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const BoxPDFLabOrder = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '595px',
    height: '420px',
    padding: '32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    backgroundColor: '#fff',
    '.fs-14': {
        fontSize: '14px',
        fontWeight: '500'
    },
    '.fs-10': {
        fontSize: '10px',
        fontWeight: '500'
    },
    '.fs-8-topic': {
        fontSize: '8px',
        fontWeight: '500'
    },
    '.fs-8-topic-color': {
        fontSize: '8px',
        fontWeight: '500',
        color: colors.themeSecondColor,
    },
    '.fs-8-info': {
        fontSize: '8px',
    },
    '.pdf-laborder-header': {
        display: 'flex',
        alignItems: 'center',
        gap: '28px',
        '.header-img': {
            'img': {
                width: '42px',
                height: '42px',
            }
        },
    },
    '.pdf-laborder-detail-content': {
        marginTop: '12px',
        display: 'flex'
    },
    '.pdf-laborder-topic-detail': {
        minWidth: '40px',
        marginRight: '8px'
    },
    '.pdf-laborder-header-title': {
        backgroundColor: colors.themeSecondColor10,
        padding: '4px 10px'
    },
    '.pdf-laborder-form-laborder-content': {
        marginTop: '12px',
        '.pdf-laborder-form-laborder-topic-detail': {
            minWidth: '45px',
            marginRight: '24px'
        },
        '.form-laborder-detail': {
            display: 'flex'
        }
    }
}))

type LabOrderProps = {
    dataLabOrder: any
}

const PDFLabOrder = (props: LabOrderProps) => {

    const { dataLabOrder } = props
    const { t } = useTranslation()

    return (
        <Box>
            <BoxPDFLabOrder>
                <Box className={`pdf-laborder-header`}>
                    <Box className={`header-img`}>
                        <img src={dataLabOrder?.branchImage} />
                    </Box>
                    <Box>
                        <Box className={`fs-14`}>
                            {dataLabOrder?.title || ''}
                        </Box>
                        <Box className={`fs-10`}>
                            {dataLabOrder?.branchName || ''}
                        </Box>
                    </Box>

                </Box>
                <Box className='w-100'>
                    <Box className='w-100 pdf-laborder-header-title'>
                        <Box className={`fs-10`}>
                            {t('ข้อมูลผู้ส่ง')}
                        </Box>
                    </Box>
                    <Box className='w-100 pdf-laborder-detail-content' >
                        <Box className='w-50'>
                            <Box className='d-flex'>
                                <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('ผู้ส่ง')}</Box>
                                <Box className={`fs-8-info`}>{dataLabOrder?.doctorFullname || ''}</Box>
                            </Box>
                            <Box className='d-flex'>
                                <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('บริษัทที่ส่ง')}</Box>
                                <Box className={`fs-8-info`}>{dataLabOrder?.labCompanyName || ''}</Box>
                            </Box>
                        </Box>
                        <Box className='w-50'>
                            <Box className='d-flex'>
                                <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('วันที่สั่ง')}</Box>
                                <Box className={`fs-8-info`}>{dataLabOrder?.dateOrder || ''}</Box>
                            </Box>
                            <Box className='w-100' sx={{ display: 'flex' }}>
                                <Box className='w-50 d-flex'>
                                    <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('วันที่ส่ง')}</Box>
                                    <Box className={`fs-8-info`}>{dataLabOrder?.dateExport || ''}</Box>
                                </Box>
                                <Box className='w-50 d-flex'>
                                    <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('วันที่รับ')}</Box>
                                    <Box className={`fs-8-info`}>{dataLabOrder?.dateImport || ''}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='w-100'>
                    <Box className='w-100 pdf-laborder-header-title'>
                        <Box className={`fs-10`}>
                            {t('ข้อมูลผู้ป่วย')}
                        </Box>
                    </Box>
                    <Box className='w-100 pdf-laborder-detail-content' >
                        <Box className='w-50'>
                            <Box className='d-flex'>
                                <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('ชื่อ-สกุล')}</Box>
                                <Box className={`fs-8-info`}>{dataLabOrder?.patientFullname || ''}</Box>
                            </Box>
                            <Box className='d-flex'>
                                <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('สาขา')}</Box>
                                <Box className={`fs-8-info`}>{dataLabOrder?.branchName || ''}</Box>
                            </Box>
                        </Box>
                        <Box className='w-50'>
                            <Box className='d-flex'>
                                <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('เบอร์มือถือ')}</Box>
                                <Box className={`fs-8-info`}>{dataLabOrder?.patientPhone || ''}</Box>
                            </Box>
                            <Box className='d-flex'>
                                <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('เบอร์สาขา')}</Box>
                                <Box className={`fs-8-info`}>{dataLabOrder?.branchTel || ''}</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`w-100`}>
                        <Box className='d-flex'>
                            <Box className={`fs-8-topic pdf-laborder-topic-detail`} >{t('ที่อยู่')}</Box>
                            <Box className={`fs-8-info`}>{dataLabOrder?.receiptFullAddress || ''}</Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='w-100'>
                    <Box className='w-100 pdf-laborder-header-title'>
                        <Box className={`fs-10`}>
                            {t('ประเภทแบบฟอร์มสั่ง Lab')}
                        </Box>
                    </Box>
                    <Box className='w-100 pdf-laborder-form-laborder-content' >
                        <Box className={`d-flex`}>
                            <Box className={`fs-8-topic pdf-laborder-form-laborder-topic-detail`}>
                                {t('Lab')}
                            </Box>
                            <Box className={`form-laborder-detail`} sx={{ gap: '8px' }}>
                                {dataLabOrder?.details?.length && dataLabOrder.details.map((item: any, index: number) => {
                                    return (
                                        <Box key={index}>
                                            <Box className={`fs-8-topic-color`}>
                                                {item?.title || ''}
                                            </Box>
                                            {item?.data?.length && item.data.map((info: any, idx: number) => {
                                                return (
                                                    <Box key={idx} className={`d-flex align-items-center`} >
                                                        <IconCommon />
                                                        <Box className={`fs-8-info ml-1`}>
                                                            <span style={{ marginRight: '2px' }}> {info?.labName || ''}</span>
                                                            <span>{info?.labText || ''}</span>
                                                        </Box>
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box className={`d-flex mt-2`}>
                            <Box className={`fs-8-topic pdf-laborder-form-laborder-topic-detail`}>
                                {t('รายละเอียด')}
                            </Box>
                            <Box className={`fs-8-info`}> {dataLabOrder?.remark || ''}</Box>
                        </Box>
                    </Box>
                </Box>
            </BoxPDFLabOrder>
        </Box>
    )
}

export default PDFLabOrder