import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Typography } from '@mui/material'
import { getColorStatus } from 'features/counter/doctor-schedule/doctor-schedule'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { imgs } from 'constants/images'

/** ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

/** STYLE */
import * as UseStyled from 'features/home/UseStyled'

type FilterDoctorProps = {
  doctors: any
  options?: any
  filterDoctor?: any
  onClick?: (event: any) => void
  clinicIsClose?: boolean
  key?: any
}

export default function FilterDoctor(props: FilterDoctorProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [width, setWidth] = useState(0)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    const widthOpen = Array.from(document.getElementsByClassName('btn-filterDoctor') as HTMLCollectionOf<HTMLElement>)
    setWidth(Number(widthOpen[0]?.offsetWidth))
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getDataActive = (item: string) => {
    if (!_.isEmpty(props.filterDoctor)) {
      return _.get(props.filterDoctor, item)
    }
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img)) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  const renderDoctorName = () => {
    if (!_.isEmpty(props.doctors) && getDataActive('doctorFullname')) return getDataActive('doctorFullname')
    else if (!_.isEmpty(props.doctors)) return t('HOME.TITLE.PLEASE_SELECT_DOCTOR')
    else return t('HOME.TITLE.NO_DOCTOR')
  }

  useEffect(() => {
    handleClose()
  }, [props.filterDoctor])

  return (
    <>
      <UseStyled.ButtonDropdown
        key={props.key}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        endIcon={!props.clinicIsClose && <FontAwesomeIcon icon={faCaretDown} />}
        className={`btn-filterDoctor ${props.clinicIsClose && 'pe-none'}`}
        disableRipple>
        {!props.clinicIsClose && (
          <div className={'text-ellipsis'} style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <UseStyled.CustomAvatar alt={getDataActive('doctorFullname')} src={renderImage(getDataActive('avatar') || getDataActive('doctorProfile'))} sx={{ width: 40, height: 40, backgroundColor: colors.white }}>
              <img src={imgs.defaultAvatar} className="w-100 h-100" alt="defaultAvatar" />
            </UseStyled.CustomAvatar>
            <div className={'ml-3'} style={{ overflow: 'hidden' }}>
              <div className={'title text-ellipsis'} style={{ color: _.isEmpty(props.doctors) || !getDataActive('doctorFullname') ? colors.gray : '', textTransform: 'none' }}>
                {renderDoctorName()}
              </div>
              <div className={'subtitle text-ellipsis'}>
                <span className='pr-1'>{t('HOME.TITLE.START_WORK')}</span>
                {props.filterDoctor.statusDoctorSchedule === 'NORMAL' && (
                  `${moment(getDataActive('startWork') || getDataActive('doctorSchedule') && getDataActive('doctorSchedule').split(' - ')[0] || '00:00', 'HH:mm').format('HH.mm')} - ${moment(getDataActive('getOffWork') || getDataActive('doctorSchedule') && getDataActive('doctorSchedule').split(' - ')[1] || '00:00', 'HH:mm').format('HH.mm')} ${t('TIME_UNIT')}`
                ) || (
                    <span style={{ color: getColorStatus(props.filterDoctor.statusDoctorSchedule || '', 'COLOR') }}>{props.filterDoctor.statusDoctorSchedule ? `(${t(`DOCTOR_SCHEDULE.STATUS.${props.filterDoctor.statusDoctorSchedule}`)})` : '-'}</span>
                  )}
              </div>
            </div>
            {!_.isEmpty(props.doctors) && (
              <UseStyled.MoreAvatar size={props.doctors ? props.doctors.length : 0} className="more-dentists">
                <UseStyled.CustomAvatar alt={`${getDataActive('doctorId')}`} src={renderImage(getDataActive('avatar'))} className={'active'} sx={{ width: 32, height: 32, backgroundColor: colors.white }} onClick={props.onClick}>
                  <img src={imgs.defaultAvatar} className="w-100 h-100" alt="defaultAvatar" />
                </UseStyled.CustomAvatar>
                {_.map(props.doctors, (i, index: number) => {
                  if (i.doctorId !== getDataActive('doctorId')) {
                    return (
                      <UseStyled.CustomAvatar key={index} alt={`${i.doctorId}`} src={renderImage(i.avatar)} sx={{ width: 32, height: 32, backgroundColor: colors.white }} onClick={props.onClick}>
                        <img src={imgs.defaultAvatar} className="w-100 h-100" alt="defaultAvatar" />
                      </UseStyled.CustomAvatar>
                    )
                  }
                })}
                {props.doctors && props.doctors.length > 3 && (
                  <UseStyled.CustomAvatar className={'more'} sx={{ width: 32, height: 32 }}>
                    +{props.doctors.length - 3}
                  </UseStyled.CustomAvatar>
                )}
              </UseStyled.MoreAvatar>
            )}
          </div>
        ) || (
            <Typography className='text-center my-4 mx-auto' sx={{ fontWeight: 500, color: colors.textLightGray }}>{t('CLINIC_CLOSE')}</Typography>
          )}
      </UseStyled.ButtonDropdown>
      <UseStyled.MenuDropdown
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        classes={{ list: 'custom-scroll' }}
        sx={{ '& .MuiMenu-list': { width: width } }}
      >
        {(!_.isEmpty(props.doctors) &&
          _.map(props.doctors, (i, index) => {
            return (
              <UseStyled.MenuDropdownItem key={index} value={i.doctorId} onClick={props.onClick}>
                <UseStyled.CustomAvatar alt={i.doctorFullname} src={renderImage(i.avatar || i.doctorProfile)} sx={{ width: 40, height: 40, backgroundColor: colors.white }}>
                  <img src={imgs.defaultAvatar} className="w-100 h-100" alt="defaultAvatar" />
                </UseStyled.CustomAvatar>
                <div className={'pl-3 w-100'} style={{ overflow: 'hidden' }}>
                  <p className={'title text-ellipsis m-0'}>{i.doctorFullname}</p>
                  <p className={'subtitle text-ellipsis m-0'}>
                    <span className='pr-1'>{t('HOME.TITLE.START_WORK')}</span>
                    {i.statusDoctorSchedule === 'NORMAL' && (
                      `${moment(i.startWork || i.doctorSchedule && i.doctorSchedule.split(' - ')[0], 'HH:mm').format('HH.mm')} - ${moment(i.getOffWork || i.doctorSchedule && i.doctorSchedule.split(' - ')[1], 'HH:mm').format('HH.mm')} ${t('TIME_UNIT')}`
                    ) || (
                        <span style={{ color: getColorStatus(i.statusDoctorSchedule || '', 'COLOR') }}>{i.statusDoctorSchedule ? `(${t(`DOCTOR_SCHEDULE.STATUS.${i.statusDoctorSchedule}`)})` : '-'}</span>
                      )}
                  </p>
                </div>
              </UseStyled.MenuDropdownItem>
            )
          })) || (
            <UseStyled.MenuDropdownItem>
              <span className={'w-100 text-center'}>{t('HOME.TITLE.NO_DOCTOR_SCHEDULE')}</span>
            </UseStyled.MenuDropdownItem>
          )}
      </UseStyled.MenuDropdown>
    </>
  )
}
