import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import ServicePointArea from 'component/Appointment/ServicePointsArea'
import CardServicePoint from 'component/Appointment/CardServicePoint'

/** STYLE */
import PaymentApi from 'api/counter/payment/payment.api'
import { PaymentListQueue } from 'api/counter/payment/payment-interface'
import { notiError } from 'component/notifications/notifications'

import { useSelector } from 'react-redux'

interface PaymentQueueProps {
    branchId: number
    branch: string
    setCountServicePoint?: (count: number) => void
}
export default function PaymentQueue(props: PaymentQueueProps) {
    const history = useHistory()
    const { t, i18n } = useTranslation()

    const [paymentQueue, setPaymentQueue] = useState<PaymentListQueue>()

    const loadQueue = () => {
        PaymentApi.listQueue({ branchId: props.branchId }).then(({ data }) => {
            setPaymentQueue(data)
        })
    }

    const dataAlert = useSelector((state: any) => state.modal.dataAlert)
    useEffect(() => { loadQueue() }, [dataAlert])

    const onPayment = async (queue: any) => {
        if (queue.status !== "PAYMENT") {
            PaymentApi.sendPayment(queue.patientCaseId)
                .then((send) => {
                    if (send.status === 200) history.push(routeName.payment + '/create', { patientId: queue.patientId, patientCaseId: queue.patientCaseId, servicePointId: send.data?.servicePointId })
                })
                .catch((e) => {
                    const message = e?.response?.data?.message
                    if (message === 'NOT_FOUND_VISIT')
                        notiError(t('PAYMENT.MESSAGE.ERROR.NOT_FOUND_VISIT'))
                    else if (message === 'VISIT_IS_DONE')
                        notiError(t('PAYMENT.MESSAGE.ERROR.VISIT_IS_DONE'))
                    else notiError(t('PAYMENT.MESSAGE.ERROR.ERROR'))
                })
        } else {
            history.push(routeName.payment + '/create', { patientId: queue.patientId, patientCaseId: queue.patientCaseId, servicePointId: paymentQueue?.servicePointId })
        }

    }

    return (
        <ServicePointArea
            key={paymentQueue?.servicePointId}
            headerName={`${paymentQueue?.[`servicePointName${i18n.language !== "th" ? "En" : ""}`] || paymentQueue?.servicePointNameEn || '-'}`}
            serviceType={`${paymentQueue?.pointOfService}`}
            count={paymentQueue?.queue?.length || 0}
            body={_.map(_.orderBy(paymentQueue?.queue, ['sorting'], ['asc']), (queue: any, index: number) => (
                <CardServicePoint
                    key={index}
                    hideFooterAction
                    type="queue"
                    patient={queue}
                    number={index}
                    serviceType={`${paymentQueue?.pointOfService}`}
                    onClick={() => { onPayment(queue) }}
                    branch={props.branch}
                />
            ))}
        />
    )
}
