import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export enum APPROVE_STATUS {
    APPROVE = 'APPROVAL',
    PENDING = 'PENDING_APPROVAL',
    CANCEL = 'UNAPPROVAL'
}

export enum INVENTORY_STATUS {
    INCREASE = 'INCREASE',
    DECREASE = 'DECREASE'
}

export interface AdjustmentItemProps {
    adjustmentReceiptItemId?: number | null
    adjustmentReceiptId?: number | null
    inventoryId: number
    qtyCurrent: number
    qtyNew: number
    qtyAdd: number
    qtyDiff: number
    qtyDiffType: INVENTORY_STATUS
    detail?: any
}

export interface AdjustmentProps {
    adjustmentReceiptId?: number | null
    branchId?: number
    prefix?: string
    documentNo?: string
    subject: string
    date: string
    remark?: string
    status: APPROVE_STATUS
    items: AdjustmentItemProps[]
}

const path = 'adjustment-receipts'

export default class AdjustmentApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static findById(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`).then((res) => res)
    }

    static createAdjustment(payload: AdjustmentProps): Promise<any> {
        return this.api.post(path, payload).then((res) => res)
    }

    static updateAdjustment(id: number, payload: AdjustmentProps): Promise<any> {
        return this.api.patch(`${path}/${id}`, payload).then((res) => res)
    }

    static updateAdjustmentStatus(id: number, status: APPROVE_STATUS): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { status: status }).then((res) => res)
    }
}