import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Card, Col } from 'react-bootstrap'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { resetModal, showModal, closeModal } from 'app/slice/modal.slice'

/** API */
import BranchApi from 'api/setting/branch-service-points/branch.api'
import { FormAppointment } from 'features/counter/appointment/form-appointment'
import AppointmentApi from 'api/counter/appointment.api'

/** COMPONENT */
import HeadAppointment, { DateInterface, ViewDisplayType } from 'features/counter/appointment/head-appointment'
import { AppointmentDisplayDayInterface, AppointmentDisplayWeekInterface } from 'features/counter/appointment/appointment-interface'
import DisplayDay from 'features/counter/appointment/display-day'
import DisplayWeek from 'features/counter/appointment/display-week'
import DisplayMonth from 'features/counter/appointment/display-month'
import { swalDelete } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'

import DisplayOverall from 'features/counter/appointment/display-overall'
import ViewHistory from 'features/counter/appointment/view-history'
import { useLocation } from 'react-router-dom'
import { getBranch } from 'utils/app.utils'
import Loading from 'component/Loading'
import { DivNoneData } from './style'
import useWindowSize from 'utils/useWindowSize'
import AboutUs from 'api/admin/clinic-manage/about.api'
import $ from 'jquery'
import AppointmentApp from './appointment-app'
import { TIME_APPOINTMENT } from 'features/setting/branch-service-point/branch/form-branch'
import HomeApi from 'api/home/home.api'
import { setFormAppointment } from 'app/slice/appointment.slice'
import { KEY_STORAGE } from 'constants/common'

export default function DoctorAppointment() {
  const location = useLocation()
  const [width, height] = useWindowSize()
  const fromAppointmentRef = useRef<HTMLDivElement>(null)
  const sizeSectionAppointment = useFormatSizeAppointment();

  //** New */
  const [viewDisplay, setViewDisplay] = useState<ViewDisplayType>('DATE')
  const dateNow = moment().format('YYYY-MM-DD-ddd').split('-')
  const [filterDate, setFilterDate] = useState<DateInterface>({ year: Number(dateNow[0]), month: Number(dateNow[1]), date: Number(dateNow[2]), day: String(dateNow[3]) })
  const [filterPatient, setFilterPatient] = useState<number>(0)
  const [filterBranch, setFilterBranch] = useState<number>(getBranch() || 1)
  const [filterDoctor, setFilterDoctor] = useState<string>('')
  const [formAppointmentWidth, setFormAppointmentWidth] = useState<number>(0)
  const [reRender, setRerender] = useState(false)

  const [showForm, setShowForm] = useState<boolean>(false)
  const [showListAppointment, setShowListAppointment] = useState<boolean>(false)
  const [appointmentId, setAppointmentId] = useState<number>()
  const [timeRange, setTimeRange] = useState<string[]>([])
  const [clinicInfo, setClinicInfo] = useState<{ timeIn?: string; timeOut?: string; officeTimes?: any, timeAppointment?: TIME_APPOINTMENT }>({})
  const [clinicDayClose, setClinicDayClose] = useState<string[]>([])
  const [clinicIsClose, setClinicIsClose] = useState(false)

  const [listAppointmentDay, setListAppointmentDay] = useState<AppointmentDisplayDayInterface[]>([])
  const [listAppointmentWeek, setListAppointmentWeek] = useState<AppointmentDisplayWeekInterface[]>([])
  const [listAppointmentMonth, setListAppointmentMonth] = useState<AppointmentDisplayWeekInterface[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [editSpecialInfo, setEditSpecialInfo] = useState<boolean>(false)

  const [dataReRender, setDataReRender] = useState<any>({})
  const [doctorWeek, setDoctorWeek] = useState<any>({})

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const branchId = getBranch()
  const [branch, setBranch] = useState<any>()

  const [key, setKey] = useState<number>(0)

  const [popApp, setPopApp] = useState<boolean>(false)
  const popAppRef = useRef<HTMLDivElement>(null)

  const [patientIdApp, setPatientIdApp] = useState<any>({})
  const [dataAppAppointment, setDataAppAppointment] = useState<any>([])

  const loadBranchInfo = () => {
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        setBranch(data)
        const newForm = data?.formAppointment ? JSON.parse(data?.formAppointment) : []
        const formAppointment = newForm?.sub[0]?.inputName?.length ? newForm?.sub[0]?.inputName : []
        dispatch(setFormAppointment(formAppointment))
      })
      .catch((e) => { return })
  }

  useEffect(() => {
    loadBranchInfo()
  }, [branchId])

  const loadTimeBranch = useCallback(() => {
    if (filterBranch) {
      BranchApi.findById(filterBranch)
        .then(({ data }) => {
          if (data) {
            let rDayOfWeek: string[] = []
            _.forEach(data.officeTimes, (oft) => {
              if (oft.officeStatus === 'CLOSE') rDayOfWeek = [...rDayOfWeek, oft.dayOfWeek.toUpperCase()]
            })
            setClinicDayClose(rDayOfWeek)
            setClinicInfo(data)
          }
        })
        .catch(() => {
          return
        })
    }
  }, [filterBranch])

  const handleLoadTimeBranch = useCallback(() => {
    setClinicIsClose(false)
    const defaultRange = ['9.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00']
    if (!_.isEmpty(clinicInfo)) {
      let objTime: { timeIn?: string; timeOut?: string; officeStatus?: string } = {}
      if (viewDisplay === 'DATE') {
        const daySelect = moment(`${filterDate.year}-${String(filterDate.month).padStart(2, '0')}-${String(filterDate.date).padStart(2, '0')}`, 'YYYY-MM-DD')
        objTime = _.find(clinicInfo?.officeTimes, (time) => time?.dayOfWeek === moment(daySelect).locale('en').format('ddd'))
      } else objTime = clinicInfo

      if (objTime?.officeStatus === 'CLOSE') setClinicIsClose(true)

      let start: any = moment(objTime?.timeIn, 'HH:mm')
      let end: any = moment(objTime?.timeOut, 'HH:mm')
      if (parseInt(start.format('mm')) > 0) start = moment(objTime?.timeIn, 'HH:mm').startOf('hour')
      if (parseInt(end.format('mm')) > 0) end = moment(objTime?.timeOut, 'HH:mm').endOf('hour').add(1, 'minute')

      const timeEnd = parseInt(end.format('HH')) === 0 ? 24 : parseInt(end.format('HH'))
      const arrayTimeRanges: any = []
      for (let index = parseInt(start.format('HH')); index <= timeEnd; index++) {
        arrayTimeRanges.push(`${String(index).padStart(2, '0')}:00`)
      }

      if (!_.isEmpty(arrayTimeRanges)) setTimeRange(arrayTimeRanges)
      else setTimeRange(defaultRange)
    } else setTimeRange(defaultRange)
  }, [clinicInfo, viewDisplay, filterDate])

  const loadAppointment = useCallback(async (inputDate?: any) => {

    setLoading(true)
    setListAppointmentWeek([])
    setListAppointmentMonth([])
    setListAppointmentDay([])
    const { date, month, year }: any = inputDate || filterDate
    let condition: any = {}
    condition = { ...condition, branchId: filterBranch }
    condition = { ...condition, date: `${year}-${String(month).padStart(2, '0')}-${String(date).padStart(2, '0')}` }
    condition = { ...condition, doctorId: filterDoctor !== '' ? filterDoctor : '0' }
    if (condition.doctorId === '0') return setLoading(false)
    if (filterPatient !== 0) condition = { ...condition, patientId: filterPatient }

    const display = inputDate?.display || viewDisplay
    if (display === 'WEEK') {
      await AppointmentApi.displayWeek(condition)
        .then(({ data }) => data && setListAppointmentWeek(data))
        .finally(() => setLoading(false))
    } else if (display === 'MONTH' || display === 'OVERALL') {
      await AppointmentApi.displayMonth(condition)
        .then(({ data }) => data && setListAppointmentMonth(data))
        .finally(() => setLoading(false))
    } else {
      await AppointmentApi.displayDay(condition)
        .then(({ data }) => {
          if (data) {
            const lang = i18n.language === 'th' ? 'servicePointName' : 'servicePointNameEn'
            const newDataSort = data.slice().sort((a: any, b: any) => {
              const isNumeric = (value: any) => /^\d+$/.test(value);
              const compareServicePoint = (sp1: any, sp2: any) => {
                if (sp1 === null) return 1;
                if (sp2 === null) return -1;

                if (isNumeric(sp1) && isNumeric(sp2)) {
                  return Number(sp1) - Number(sp2);
                }

                if (isNumeric(sp1)) return -1;
                if (isNumeric(sp2)) return 1;

                return sp1.localeCompare(sp2, 'th');
              };
              const spComparison = compareServicePoint(a[lang], b[lang]);
              if (spComparison !== 0) return spComparison;
              return a.doctorFullname.localeCompare(b.doctorFullname, 'th');

            })

            setListAppointmentDay(newDataSort || data)
          }
        })
        .finally(() => setLoading(false))
    }

    loadDataApp()

  }, [viewDisplay, filterDate, filterPatient, filterBranch, filterDoctor, reRender])

  useEffect(() => {
    loadAppointment()
  }, [loadAppointment])

  // useMemo(() => {
  //   return loadAppointment()
  // }, [loadAppointment])

  useEffect(() => {
    loadTimeBranch()
  }, [loadTimeBranch])
  useEffect(() => {
    handleLoadTimeBranch()
  }, [handleLoadTimeBranch])

  useEffect(() => {
    const patientState: any = location.state
    if (patientState) {
      dispatch(showModal())
      setShowForm(true)
      loadTimeBranch()
      handleLoadTimeBranch()
    }
  }, [location.state])

  const addAppointment = () => {
    if (!showForm) {
      dispatch(showModal())
      setShowForm(true)
    }
  }

  const onViewHistory = () => {
    dispatch(showModal())
    setShowListAppointment(true)
  }

  const onEdit = (id: number, specialInfo: boolean) => {
    if (!showForm) {
      dispatch(showModal())
    }
    setAppointmentId(id)
    setShowListAppointment(false)
    setShowForm(true)
    setKey(key + 1)
    setEditSpecialInfo(specialInfo)
    setPopApp(false)
  }

  const onDelete = (id: number, obj: any) => {
    swalDelete(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('APPOINTMENT.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="text-left mb-0 mx-auto" style="max-width: 306px">${t(`APPOINTMENT.ALERT.CONFIRM_DELETE_`, {
        cnNumber: obj.branchCnNumber ? `${obj.branchCnNumber}` : '',
        patientFullName: obj.patientFullname,
        time: obj.timeStart ? moment(obj.timeStart, 'HH:mm').format('HH:mm') : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          AppointmentApi.remove(id)
            .then((resp) => {
              notiSuccess(t('APPOINTMENT.MESSAGE.SUCCESS.DELETE'))
              loadAppointment()
            })
            .catch((e) => notiError(t('APPOINTMENT.MESSAGE.ERROR')))
        }
      },
      { confirmButtonText: t("APPOINTMENT.BUTTON.DEL_APPOINTMENT"), cancelButtonText: t('BUTTON.CANCEL') }
    )
  }

  const setData_ViewDisplay = () => {
    if (viewDisplay === 'DATE') {
      const data = { data: listAppointmentDay, viewDisplay: viewDisplay }
      return data
    } else if (viewDisplay === 'WEEK') {
      const data = { data: listAppointmentWeek, viewDisplay: viewDisplay }
      return data
    } else {
      const data = { data: listAppointmentMonth, viewDisplay: viewDisplay }
      return data
    }
  }

  const handleSetDataReRender = (data: any) => {
    setDataReRender(data)
  }

  const handleScrollAppointment = useCallback((appId: number, index: number) => {
    const desiredTitle = appId
    if (desiredTitle) {
      setTimeout(() => {
        const conDiv = document.querySelector(`.container-schedule [data-value="${desiredTitle}"]`)
        if (conDiv) {
          const style = getComputedStyle(conDiv);
          const transform = style.getPropertyValue('transform');
          const matrix = new DOMMatrix(transform);
          const translateX = sizeSectionAppointment * (index);
          const translateY = matrix.m42;
          $('#service-slider-container').delay(500).animate({ scrollLeft: translateX, scrollTop: translateY }, 500)
          setDataReRender({})
          setDoctorWeek({})
        }
      }, 500)
    }
  }, [listAppointmentDay])

  useEffect(() => {
    if (dataReRender.timeStart) {
      if (viewDisplay === 'WEEK') {
        const data = listAppointmentWeek
        const newDoctorWeek = {
          doctorId: dataReRender.doctorId,
          date: dataReRender.date
        }
        setDoctorWeek(newDoctorWeek)
        for (const index in data) {
          const item = data[index]
          if (item.date === dataReRender.date) {
            for (const index_2 in item.doctors) {
              const item_2 = item.doctors[index_2];
              if (item_2.doctorId === dataReRender.doctorId) {
                const groupAppointments = item_2.groupAppointments[dataReRender.timeStart];

                if (groupAppointments && Array.isArray(groupAppointments) && groupAppointments.length > 0) {
                  const appId = groupAppointments[0].appointmentId;
                  handleScrollAppointment(appId, Number(index));
                }

                break;
              }
            }

          }
        }
      } else {
        for (const index in listAppointmentDay) {
          const item = listAppointmentDay[index];
          if (item.doctorId === dataReRender.doctorId) {
            const groupAppointments = item.groupAppointments[dataReRender.timeStart];
            if (groupAppointments && groupAppointments.length > 0) {
              const appId = groupAppointments[0].appointmentId;
              handleScrollAppointment(appId, Number(index));
              break;
            }
          }
        }
      }
    }
  }, [listAppointmentDay, listAppointmentWeek, dataReRender])

  useEffect(() => {
    if (fromAppointmentRef.current) {
      const element: any = fromAppointmentRef.current.getElementsByClassName('css-1umagr2')
      const width = element[0]?.clientWidth

      setFormAppointmentWidth(width);
    }
  }, [fromAppointmentRef, showForm, location.state]);

  const handleOpenAMApp = (status: boolean) => {
    if (!status) {
      setPopApp(true)
    } else {
      setPopApp(false)
    }

  }

  useEffect(() => {
    if (popAppRef?.current) {
      const element = popAppRef.current.getElementsByClassName('appointment-app')
      const width = element[0]?.clientWidth || 0

      setFormAppointmentWidth(width);
    }
  }, [popAppRef, popApp])

  const handleApp = (item: any) => {
    setPatientIdApp(item)
    // addAppointment()
    onEdit(item?.appointmentId, false)
  }

  const loadDataApp = async () => {
    await AppointmentApi.waitingApprove({ branchId: filterBranch, status: 'WAITING_APPROVE' }).then((res) => {
      const newData = res?.data.filter((item: any) => {
        if (item.status !== 'DELETED') {
          return item
        }
      })
      setDataAppAppointment(newData || [])
    })
  }

  return (
    <div className="h-100">
      <Loading show={loading} type="fullLoading" />
      <Card className="appointment border-0 h-100 overflow-hidden">
        <HeadAppointment
          branchId={filterBranch}
          viewDisplay={viewDisplay}
          date={filterDate}
          doctor={filterDoctor}
          handleViewDisplay={setViewDisplay}
          handleBranchId={setFilterBranch}
          handleDate={(val) => {
            setFilterDate(val)
          }}
          handlePatient={setFilterPatient}
          handleClickAdd={addAppointment}
          handleDoctor={setFilterDoctor}
          handleClickView={onViewHistory}
          statusShowForm={showForm}
          handleOpenAMApp={handleOpenAMApp}
          statusPopApp={popApp}
          countApp={dataAppAppointment?.length || 0}
        />
        <Card.Body className={`px-1 pb-1 pt-0 overflow-hidden custom-scroll`} style={{ zIndex: 2, position: 'relative' }}>
          <Col className={`px-1 pb-1 pt-0 overflow-hidden custom-scroll`} style={{ height: '100%', width: `calc(100% - ${formAppointmentWidth}px)` }} >
            {(!loading && (
              <>
                {viewDisplay === 'DATE' ? (
                  (listAppointmentDay.length && (
                    <DisplayDay
                      branch={branch}
                      branchId={filterBranch}
                      timeRange={timeRange}
                      listAppointmentDay={listAppointmentDay}
                      onEdit={(id: number) => onEdit(id, false)}
                      onEditSpecialInfo={(id: number) => onEdit(id, true)}
                      clinicIsClose={clinicIsClose}
                      formAppointmentWidth={formAppointmentWidth}
                    />
                  )) || <DivNoneData>{t('APPOINTMENT.NONE_DATA')}</DivNoneData>
                ) : (
                  <></>
                )}
                {viewDisplay === 'WEEK' ? (
                  (listAppointmentWeek.length && (
                    <DisplayWeek
                      branch={branch}
                      branchId={filterBranch}
                      timeRange={timeRange}
                      listAppointment={listAppointmentWeek}
                      onEdit={(id: number) => onEdit(id, false)}
                      onEditSpecialInfo={(id: number) => onEdit(id, true)}
                      clinicDayClose={clinicDayClose}
                      doctorWeek={doctorWeek}
                    />
                  )) || <DivNoneData>{t('APPOINTMENT.NONE_DATA')}</DivNoneData>
                ) : (
                  <></>
                )}
                {viewDisplay === 'MONTH' ? (
                  <DisplayMonth branch={branch} branchId={filterBranch} year={filterDate.year} month={filterDate.month} listAppointment={listAppointmentMonth} onEdit={(id: number) => onEdit(id, false)} onEditSpecialInfo={(id: number) => onEdit(id, true)} />
                ) : (
                  <></>
                )}
                {viewDisplay === 'OVERALL' ? (
                  <DisplayOverall
                    branchId={filterBranch}
                    year={filterDate.year}
                    month={filterDate.month}
                    listAppointment={listAppointmentMonth}
                    onEdit={(id: number) => onEdit(id, false)}
                    onEditSpecialInfo={(id: number) => onEdit(id, true)}
                    timeRange={timeRange}
                    branch={branch}
                  />
                ) : (
                  <></>
                )}
              </>
            )) || (
                <Box className="w-max mx-auto my-4">
                  <Loading show type={'softLoading'} />
                </Box>
              )}
          </Col>
          <Col ref={fromAppointmentRef}>
            {!_.isEmpty(clinicInfo) && showForm && (

              <FormAppointment
                key={key}
                typeForm={'slice'}
                showForm={showForm}
                viewDisplay={viewDisplay}
                handleSuccess={(inputDate: any) => loadAppointment(inputDate)}
                dataAppointment={setData_ViewDisplay()}
                date={viewDisplay === 'DATE' ? filterDate : ''}
                branchId={filterBranch}
                appointmentId={appointmentId}
                onReset={() => {
                  dispatch(resetModal())
                  setAppointmentId(undefined)
                  setShowForm(false)
                  setEditSpecialInfo(false)
                  setFormAppointmentWidth(0)
                  setPatientIdApp({})
                }}
                handleDate={(val) => {
                  setFilterDate(val)
                  if (val?.branchId) {
                    setFilterBranch(val.branchId)
                  }
                }}
                handleBranch={(val) => {
                  setFilterBranch(val)
                }}
                onDelete={onDelete}
                editSpecialInfo={editSpecialInfo}
                officeTime={clinicInfo.officeTimes}
                reRender={handleSetDataReRender}
                setRerender={(value) => setRerender(value)}
                reRenderValue={reRender}
                patientIdApp={patientIdApp}
                timeAppointment={clinicInfo?.timeAppointment}
              />
            )}
          </Col>
          <Col ref={popAppRef}>
            {popApp &&
              <AppointmentApp
                handleOpenAMApp={handleOpenAMApp}
                handleApp={handleApp}
                dataAppAppointment={dataAppAppointment}
              />
            }
          </Col>
        </Card.Body>

        {showListAppointment && <ViewHistory onClose={() => setShowListAppointment(false)} onEdit={(id: number, isEditSpecialInfo: boolean) => onEdit(id, isEditSpecialInfo)} />}
      </Card>
    </div>
  )
}

export const useFormatSizeAppointment = () => {
  const [sizeSectionAppointment, setSizeSectionAppointment] = useState(() => {
    const status = localStorage?.getItem(KEY_STORAGE.FORMATAPPOINTMENT) || '0';
    return status === '0' ? 500 : 284;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const status = localStorage?.getItem(KEY_STORAGE.FORMATAPPOINTMENT) || '0';
      setSizeSectionAppointment(status === '0' ? 500 : 284);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return sizeSectionAppointment;
};
