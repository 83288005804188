import { ExportProps } from "features/report/report-constants"
import moment from "moment"
import _ from 'lodash'
import { Box, Typography, Divider } from '@mui/material'
import { numberFormat, toBuddhistYear } from "utils/app.utils"

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from "features/report/component/emptyData"

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

interface FcComponent {
    exportFilter: any
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    componentRef: any
}

export default function SchedulesList(props: FcComponent) {
    const { t } = useTranslation()

    const renderSchedulesTable = (data: any) => {
        const items = !_.isEmpty(data) ? _.chain(data)
            .groupBy('doctorId')
            .map((value: any, key: string) => ({ doctorName: value[0]?.doctorFullName || '-', data: value }))
            .value() : []

        return items
    }

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.TIME', align: 'center', class: 'text-nowrap', width: '80px' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.RANG_TIME', align: 'center', class: 'text-nowrap', width: '120px' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.CN_NUMBER', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.BRANCH_CN', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.PATIENT_NAME', align: 'center', class: 'text-nowrap', width: '200px' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.PHONE', align: 'left', class: 'text-nowrap', width: '140px' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.OPERATIVE', align: 'left', class: 'text-nowrap', width: '300px' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.STATUS', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.PRICE', align: 'right', class: 'text-nowrap', width: '150px' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.PRACTICE', align: 'left', class: 'text-nowrap', width: 'auto' },
        { id: 'message', disablePadding: false, labelKey: 'X-Ray', align: 'center', class: 'text-nowrap', width: '80px' },
        { id: 'message', disablePadding: false, labelKey: 'Lab', align: 'center', class: 'text-nowrap', width: '80px' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES.REMARK', align: 'left', class: 'text-nowrap', width: 'auto' },

    ]

    const renderData = (data: any, no: number, exportData: boolean) => {
        const { timeStart, durationMinute, cnNumber, branchCnNumber, patientFullName, phone, treatment, statusAppointment, price, practice, remark, lab, xray } = data
        const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
        const objRenderData = {
            key: num.toString(),
            id: no.toString(),
            obj: data,
            columns: [
                { option: 'TEXT', align: 'center', label: `${moment(timeStart, 'HH:mm').format('HH:mm')} ${t('TIME_UNIT')}`, class: 'px-1' },
                { option: 'TEXT', align: 'center', label: `${durationMinute} ${t('TIME_DURATION_TYPE.MINUTES')}` },
                { option: 'TEXT', align: 'left', label: cnNumber || '-', class: 'text-ellipsis' },
                { option: 'TEXT', align: 'left', label: branchCnNumber || '-', class: 'text-ellipsis' },
                { option: 'TEXT', align: 'center', label: patientFullName || '-', class: 'text-ellipsis' },
                { option: 'TEXT', align: 'left', label: phone || '-' },
                { option: 'TEXT', align: 'left', label: treatment || '-' },
                { option: 'TEXT', align: 'left', label: statusAppointment ? t(`APPOINTMENT.STATUS.${statusAppointment}`) : '-' },
                { option: 'TEXT', align: 'right', label: numberFormat(price || 0) },
                { option: 'TEXT', align: 'left', label: practice || '-', class: 'text-ellipsis' },
                { option: 'TEXT', align: 'center', label: t(`${xray}`) },
                { option: 'TEXT', align: 'center', label: t(`${lab}`) },
                { option: 'TEXT', align: 'left', label: remark || '-', class: 'text-ellipsis' },
            ]
        }

        return (
            <>
                {exportData === true && <TableRowExport {...objRenderData} /> ||
                    <TableRowCommon  {...objRenderData} />
                }
            </>
        )
    }

    return (
        <div>
            {!_.isEmpty(props.data) && (
                _.map(props.data, (val: any, index: number) => {
                    return (
                        <>
                            {index > 0 && <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}
                            <Typography className={`mb-1`} sx={{ fontWeight: 500, fontSize: '20px' }}>
                                <span className='mr-2'>{t('MOMENT_AT')}</span>
                                {toBuddhistYear(moment(val.date), 'DD/MM/YYYY')}
                            </Typography>
                            {
                                _.map(renderSchedulesTable(val.obj), (obj, indexObj: number) => {
                                    return (
                                        <Box key={indexObj}>
                                            <Typography className={`${indexObj > 0 ? 'mt-3' : ''} mb-1`} sx={{ fontWeight: 500 }}>
                                                <span className='pr-2'>{t('REPORT.FILTER.DOCTOR')}</span>
                                                {obj.doctorName}
                                            </Typography>
                                            <UseStyled.ResponsiveReportTable className='mt-2' verticalAlign='top'>
                                                <TableCustom
                                                    customScroll
                                                    tableMinWidth={1756}
                                                    tableFixedWidth
                                                    hidePagination
                                                    page={props.page}
                                                    pageLimit={props.pageLimit}
                                                    sortType={props.sortType}
                                                    sortBy={props.sortBy}
                                                    rowCount={props.rowCount}
                                                    onSort={props.onRequestSort}
                                                    setPageLimit={props.handleChangeRowsPerPage}
                                                    setPage={props.handleChangePage}
                                                    headCells={headCells}
                                                    rowsData={
                                                        _.map(obj.data, (d: any, indexData: number) => {
                                                            return renderData(d, indexData, false)
                                                        })
                                                    }
                                                />
                                            </UseStyled.ResponsiveReportTable>
                                        </Box>
                                    )
                                })
                            }
                        </>
                    )
                })
            ) || (
                    <EmptyData className="my-4" />
                )}
            <div className="print-show" ref={props.componentRef}>
                <SchedulesExport
                    header={props.exportFilter.header}
                    componant={
                        _.map(props.data, (val: any, index: number) => {
                            return (
                                <>
                                    {index > 0 && <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}
                                    <Typography className={`mb-1`} sx={{ fontWeight: 500, fontSize: '20px' }}>
                                        <span className='mr-2'>{t('MOMENT_AT')}</span>
                                        {toBuddhistYear(moment(val.date), 'DD/MM/YYYY')}
                                    </Typography>
                                    {
                                        _.map(renderSchedulesTable(val.obj), (obj, indexObj: number) => {
                                            return (
                                                <>
                                                    <Typography className={`${indexObj > 0 ? 'mt-3' : ''} mb-1`} sx={{ fontWeight: 500 }}>
                                                        <span className='pr-2'>{t('REPORT.FILTER.DOCTOR')}</span>
                                                        {obj.doctorName}
                                                    </Typography>
                                                    <UseStyled.Styles className="pt-3">
                                                        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    {_.map(headCells, (valCell: any) => {
                                                                        return (
                                                                            <td style={{ textAlign: valCell.align, fontSize: 10, fontWeight: 500 }}> {t(valCell.labelKey)}</td>
                                                                        )
                                                                    })}
                                                                </tr>
                                                            </thead>
                                                            <tbody >
                                                                {
                                                                    _.map(obj.data, (d: any, indexData: number) => {
                                                                        return renderData(d, indexData, false)
                                                                    })
                                                                }
                                                                {!obj.data.length && (
                                                                    <td colSpan={headCells.length}>
                                                                        {t('REPORT.TITLE.NO_DATA')}
                                                                    </td>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </UseStyled.Styles >
                                                </>

                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    } />
            </div>
        </div>
    )
}

export function SchedulesExport(props: ExportProps) {

    return (
        <>
            {props.header}
            {props.componant}
        </>
    )
}