import { Box } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import Loading from 'component/Loading'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { routeName } from 'routes/routes-name'
import { ApprovalSelectorCustom } from '../approvel-list'
import { useHistory, useLocation } from 'react-router-dom'
import PopupManageTreatmentCourse from 'component/Treatments/PopupManageTreatmentCourse'
import { colors } from 'constants/theme'
import { swalCustom } from 'component/Alert/Swal'
import ApprovalApi, { ApprovalCancelReceipt, ApprovalLinkProps, ApprovalStatus } from 'api/approval/approval.api'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { getBranch } from 'utils/app.utils'

const ChangeCost = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const [load, setLoad] = useState<boolean>(false)
    const [approval, setApproval] = useState<ApprovalStatus>(ApprovalStatus.PENDING)
    const [approvalDisabled, setApprovalDisabled] = useState<boolean>(false)
    const [approvalItemId, setApprovalItemId] = useState<number>(0)
    const [dataApproval, setDataApproval] = useState<any>({})

    const [objDataCurrent, setObjDataCurrent] = useState<any>({})
    const [objDataChange, setObjDataChange] = useState<any>({})

    const [outToken, setOutToken] = useState<boolean>(false)
    const search = window.location.search;
    const params = new URLSearchParams(search);


    const handleSetDataApproval = (info: any) => {
        setDataApproval(info)
        setApprovalItemId(info?.approvalItemId)
        setApproval(info?.status || ApprovalStatus.PENDING)
        setObjDataCurrent(JSON.parse(info?.body))
        setObjDataChange(JSON.parse(info?.newBody))
        setApprovalDisabled(info?.status === ApprovalStatus.APPROVED ||
            info?.status === ApprovalStatus.REJECT ||
            info?.status === ApprovalStatus.CANCEL)
    }

    const loadChangeCostApproval = async () => {
        if (params.get('id') && typeof params.get('id') === 'string') {
            setLoad(true)
            await ApprovalApi.findApprovalChangeCost(String(params.get('id'))).then(({ data }) => {
                const resData = data
                if (resData?.status === ApprovalStatus.APPROVED || resData?.status === ApprovalStatus.REJECT) {
                    swalCustom(
                        t(`APPROVELLIST.MESSAGE.IS_APPROVED_TITLE`),
                        t(`APPROVELLIST.MESSAGE.IS_APPROVED_SUB`),
                        () => history.push(routeName.login),
                        'warning',
                        { cancelButton: { showCancelButton: false }, showCloseButton: false }
                    )
                } else {
                    handleSetDataApproval(resData)
                }
            }).finally(() => setLoad(false))
        }
    }

    const loadData = () => {
        setLoad(true)
        const newLocation: any = location
        if (newLocation?.search && params.get('id')) {
            setOutToken(true)
            loadChangeCostApproval()
            return
        }

        if (newLocation?.state?.objData?.body && newLocation?.state?.objData?.newBody) {
            handleSetDataApproval(newLocation?.state?.objData)
        }
        setLoad(false)
    }

    useEffect(() => {
        loadData()
    }, [location])

    const onGoBackApproval = () => {
        history.push(routeName.approvelList)
    }

    const onSubmit = () => {
        if (approval === 'PENDING' || approvalDisabled) {
            history.push(routeName.approvelList)
            return
        }

        swalCustom(
            t('APPROVELLIST.MESSAGE.APPROVAL_SAVE_TITLE'),
            t('APPROVELLIST.MESSAGE.APPROVAL_SAVE_SUB', { approval: t(`APPROVELLIST.FILTER.${approval}`) }),
            async (res: any) => {
                if (res) {
                    setLoad(true)
                    const newPayload: ApprovalCancelReceipt = {
                        requestReason: dataApproval?.requestReason,
                        requestUserName: dataApproval?.requestUserName,
                        requestUserId: dataApproval?.requestUserId,
                        approveRemark: dataApproval?.approveRemark,
                        refId: dataApproval?.refId,
                        branchId: getBranch(),
                        status: approval
                    }
                    await ApprovalApi.approvalCancelReceipt(approvalItemId, newPayload).then((res) => {
                        notiSuccess(t('APPROVAL.MESSAGE.SUC_CREATE'))
                        history.push(routeName.approvelList)
                    }).catch((e) => {
                        const error = e.response?.data?.message
                        const errorMessage = ["IS_APPROVED", "IS_CANCEL"]
                        if (errorMessage.includes(error)) {
                            swalCustom(
                                t(`APPROVELLIST.MESSAGE.${error}_TITLE`),
                                t(`APPROVELLIST.MESSAGE.${error}_SUB`),
                                () => history.push(routeName.approvelList),
                                'warning',
                                { cancelButton: { showCancelButton: false }, showCloseButton: false }
                            )
                        } else {
                            notiError(t('APPROVELLIST.MESSAGE.ERROR'))
                        }
                    }).finally(() => {
                        setLoad(false)
                    })
                }
            },
        )
    }

    const onSubmitApprovalOutToken = async (status: ApprovalStatus.APPROVED | ApprovalStatus.REJECT) => {
        if (approvalItemId && params?.get('token')) {
            setLoad(true)
            const newPayload: ApprovalLinkProps = {
                approveRemark: '',
                status: status,
                approveToken: String(params.get('token'))
            }
            await ApprovalApi.approvalLink(approvalItemId, newPayload).then((res) => {
                swalCustom(
                    t('APPROVELLIST.MESSAGE.IS_APPROVED_SUCCESS_TITLE'),
                    `<div style="width:300px" ></div>`,
                    () => history.push(routeName.login),
                    'warning',
                    { cancelButton: { showCancelButton: false }, showCloseButton: false }
                )
            }).catch((e) => {
                const error = e.response?.data?.message
                const errorMessage = ["IS_APPROVED", "IS_CANCEL"]
                if (errorMessage.includes(error)) {
                    swalCustom(
                        t(`APPROVELLIST.MESSAGE.${error}_TITLE`),
                        t(`APPROVELLIST.MESSAGE.${error}_SUB`),
                        () => history.push(routeName.login),
                        'warning',
                        { cancelButton: { showCancelButton: false }, showCloseButton: false }
                    )
                } else {
                    notiError(t('APPROVELLIST.MESSAGE.ERROR'))
                }
            })
                .finally(() => setLoad(false))
        }

    }

    return (
        <>
            <Box className={`container-card container-card h-100 overflow-auto`} sx={{ minHeight: '100vh' }}>
                <Loading show={load} />
                <Box className="d-flex flex-column" sx={{ height: '100vh' }}>
                    {!outToken &&
                        <HeaderLabel
                            text={t('APPROVELLIST.CHANGE_COST.TITLE')}
                            componentBtnCustom={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: ' 16px' }}>
                                    <Box>
                                        <ApprovalSelectorCustom
                                            approval={approval}
                                            onChange={setApproval}
                                            disabled={approvalDisabled}
                                        />
                                    </Box>
                                    <Box sx={{ width: '100px', button: { width: '100%', padding: '6px 16px', filter: 'grayscale(100%) sepia(0%) brightness(35%) contrast(120%)' } }}>
                                        <ButtonCustom
                                            variant={"outlined"}
                                            textButton={t("BUTTON.CANCEL")}
                                            onClick={onGoBackApproval}
                                        />
                                    </Box>
                                    <Box sx={{ width: '100px', button: { width: '100%', padding: '6px 16px', } }}>
                                        <ButtonCustom
                                            // disabled={disabledSave}
                                            textButton={t("BUTTON.SAVE")}
                                            onClick={onSubmit}
                                        />
                                    </Box>
                                </Box>
                            }
                        />
                    }
                    <Card className="border shadow-sm mx-4 mb-4 h-100 overflow-hidden card" style={{ maxHeight: '100%', margin: !outToken ? 0 : '24px' }}>
                        <Box className="overflow-hidden p-4 h-100">
                            <Card.Body className="d-flex flex-column h-100 custom-scroll p-0" style={{ overflowY: !outToken ? 'auto' : 'hidden', overflowX: 'hidden', position: 'relative' }}>
                                {outToken &&
                                    <Box sx={{ fontSize: '48px', fontWeight: '600', margin: '0 0 16px 0 ', textAlign: 'center' }}>
                                        {t('APPROVELLIST.TITLE_OUTTOKEN_CHANGECOST')}
                                    </Box>
                                }
                                <Row className={`pt-2`} style={!outToken ? {} : { overflow: 'auto', height: 'calc(100% - 140px)' }}>
                                    <Col xl={6} lg={6} xs={12} style={{ borderRight: `1px solid ${colors.lightGray}` }}>
                                        <Box>
                                            <Box className={`mb-0`} sx={{ fontSize: '24px', fontWeight: '500' }}>
                                                {t('APPROVELLIST.CHANGE_COST.TITLE_OLD')}
                                            </Box>
                                            <Box sx={{ padding: '24px 32px' }}>
                                                <Box sx={{ marginBottom: '24px', fontSize: '20px', fontWeight: '500', color: colors.themeMainColor, textAlign: 'center' }}>
                                                    {t('APPROVELLIST.CHANGE_COST.TITLE')}
                                                </Box>
                                                {(objDataCurrent?.operativeId && objDataCurrent?.doctorId) &&
                                                    <Box>
                                                        <PopupManageTreatmentCourse
                                                            userId={objDataCurrent?.doctorId}
                                                            itemId={0}
                                                            operative={objDataCurrent}
                                                            onSubmit={() => console.log("")}
                                                            onReset={() => console.log("")}
                                                            noModal
                                                            branchId={dataApproval?.branchId}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xl={6} lg={6} xs={12}>
                                        <Box>
                                            <Box className={`mb-0`} sx={{ fontSize: '24px', fontWeight: '500' }}>
                                                {t('APPROVELLIST.CHANGE_COST.TITLE_NEW')}
                                            </Box>
                                            <Box sx={{ padding: '24px 32px' }}>
                                                <Box sx={{ marginBottom: '24px', fontSize: '20px', fontWeight: '500', color: colors.themeMainColor, textAlign: 'center' }}>
                                                    {t('APPROVELLIST.CHANGE_COST.TITLE')}
                                                </Box>
                                                {(objDataChange?.operativeId && objDataCurrent?.doctorId) &&
                                                    <Box>
                                                        <PopupManageTreatmentCourse
                                                            userId={objDataCurrent?.doctorId}
                                                            itemId={0}
                                                            operative={objDataChange}
                                                            onSubmit={() => console.log("")}
                                                            onReset={() => console.log("")}
                                                            noModal
                                                            branchId={dataApproval?.branchId}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                                {outToken &&
                                    <Box sx={{
                                        marginTop: '24px',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: '12px',
                                        width: '100%'
                                    }}>
                                        <Box sx={{ width: '100px' }}>
                                            <ButtonCustom
                                                textButton={t('APPROVELLIST.FILTER.REJECT')}
                                                variant={"outlined"}
                                                mode={'del'}
                                                btnStyle={{ width: "100%" }}
                                                onClick={() => onSubmitApprovalOutToken(ApprovalStatus.REJECT)}
                                            />
                                        </Box>
                                        <Box sx={{ width: '100px' }}>
                                            <ButtonCustom
                                                textButton={t('APPROVELLIST.FILTER.APPROVED')}
                                                btnStyle={{ width: "100%" }}
                                                onClick={() => onSubmitApprovalOutToken(ApprovalStatus.APPROVED)}
                                            />
                                        </Box>
                                    </Box>
                                }
                            </Card.Body>
                        </Box>
                    </Card>
                </Box>
            </Box >
        </>
    )
}

export default ChangeCost