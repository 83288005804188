import { RenderExcel } from 'component/Excel/interfaces/render-excel'
import { useEffect, useMemo, useState } from 'react'
import { excel } from 'component/Excel/lib'

export function useDownloadExcel({ tableExportRef, filename, sheetName }: RenderExcel) {
  const [payload, setPayload] = useState({} as RenderExcel)

  useEffect(() => {
    setPayload({
      tableExportRef,
      filename,
      sheetName
    })
  }, [tableExportRef, filename, sheetName])

  return useMemo(() => excel(payload), [payload])
}
