export const ValueLabel = {
  userCode: '',
  username: '',
  password: '',
  confirmPassword: '',
  prefix: 'นาย',
  firstname: '',
  lastname: '',
  firstnameEn: '',
  lastnameEn: '',
  displayName: '',
  email: '',
  idCard: '',
  dateOfBirth: '2021-12-23T06:49:44.319Z',
  phone: '',
  telephone: '',
  facebook: '',
  line: '',
  address: 'string',
  countryId: 0,
  provinceId: 0,
  districtId: 0,
  subDistrictId: 0,
  postcode: 'string',
  positionId: 0,
  roleId: 0,
  certificate: 'string',
  salary: 0,
  creditCardFee: 0,
  creditCardFeeType: 'BAHT',
  expertId: 0,
  bankId: 0,
  bankAccountNumber: 'string',
  bankAccountName: 'string',
  isInsure: '1',
  isAdmin: '1',
  ownAppointment: '1',
  ownSchedule: '1',
  ownDocument: '1',
  isViewDfAndLab: '1',
  isViewDfConfirm: '1',
  hasCancelBill: 'PERMISS',
  hasCancelVisit: 'PERMISS',
  hasCancelLabOrder: 'PERMISS',
  hasInsuranceCost: 'PERMISS',
  hasReplyLabOrder: 'PERMISS',
  hasViewIncome: 'PERMISS',
  hasChangeTreatmentCost: 'PERMISS',
  hasChangeTreatmentNote: 'PERMISS',
  hasChangePrice: 'UNPERMISS',
  hasChangeDiscountAdditional: 'UNPERMISS',
  hasDiagnosis: 'PERMISS',
  hasTreatment: 'PERMISS',
  hasTreatmentPlan: 'PERMISS',
  hasTreatmentCourse: 'PERMISS',
  hasDoctorNote: 'PERMISS',
  userWorkplaces: [
    {
      name: 'string',
      dayOfWork: 'string',
      timeOfWork: 'string'
    }
  ],
  userBackgrounds: [],
  userAttachments: [],
  profile: {
    fileId: 0,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  },
  signatureAttachments: {
    fileId: 0,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
}
