
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, TableSortLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'

import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'

//component
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import FucntionPrint from "component/Print/print"
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

//utils
import { getBranch, isBuddhistYear, toBuddhistYear } from 'utils/app.utils'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'

//API
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import Loading from 'component/Loading'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import { ExportProps } from '../report-constants'
import OperativeApi from 'api/setting/treatments/operative.api'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import InputTextSearch from 'component/Input/InputTextSearch'
import FollowUpList from './followUpList'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { SORT_TYPE } from 'constants/common'

export default function FollowUp() {
    const { t } = useTranslation()
    const history = useHistory();
    const momentDate = () => [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

    const componentRef = useRef<HTMLDivElement>(null)
    const [branches, setBranches] = useState<any[]>([])
    const [opertatives, setOperative] = useState<any[]>([])
    const [operativeType, setOperativeType] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true);

    const [page, setPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(10)
    const [search, setSearch] = useState<string>('')

    const [branchId, setBranchId] = useState<any>(0)
    const [operativeId, setOperativeId] = useState<any>(null)
    const [operativeTypeId, setOperativeTypeId] = useState<any>(null)

    const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
    const [followUpData, setFollowUpData] = useState<any>({ rows: [] });
    const [followUpExportData, setFollowUpExportData] = useState<any>({ rows: [] });
    const [fetch, setFetch] = useState<boolean>(false)

    const [rowCount, setRowCount] = useState<number>(0);
    const [sortType, setSortType] = useState<SORT_TYPE>(SORT_TYPE.DESC)
    const [sortBy, setSortBy] = useState<string>('order_treatments.orderTreatmentId')


    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.FOLLOW_UP`)} ${rangeDate[0] ? `(${rangeDate[0]}-${rangeDate[1]})` : '-'} `,
        sheetName: `${t(`REPORT.REPORT_NAME.FOLLOW_UP`)}`
    })

    const getAllFilter = () => {
        return [{
            label: t('REPORT.FILTER.BRANCH'),
            value: _.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')
        }, {
            label: t('REPORT.FILTER.TYPE_OPERATION'),
            value: operativeTypeId ? operativeTypeId.operativeTypeName : t('REPORT.REPORT_GROUP.ALL')
        },
        {
            label: t('REPORT.FILTER.OPERATION'),
            value: operativeId ? operativeId.operativeName : t('REPORT.REPORT_GROUP.ALL')
        },
        {
            label: t('REPORT.FILTER.START'),
            value: rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'
        },
        {
            label: t('REPORT.FILTER.DATE_END'),
            value: rangeDate[0] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'
        }

        ]

    }

    const loadFollupExport = useCallback(async () => {
        let condition: FindAllReportInterface = {
            page: page, pageLimit: limit,
            dateStart: rangeDate[0],
            dateEnd: rangeDate[1],
            sortBy: sortBy,
            sortType: sortType
        }
        if (branchId && branchId !== 0) {
            condition = { ...condition, branchId }
        }

        if (rangeDate) {
            if (rangeDate[0] !== null && rangeDate[1] !== null)
                condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        if (operativeId) {
            condition.operativeId = operativeId.operativeId
        }
        if (operativeTypeId && operativeTypeId?.operativeTypeId !== 0) {
            condition.operativeTypeId = operativeTypeId.operativeTypeId
        }
        if (!_.isEmpty(search)) {
            condition.search = search
        }
        ReportApi.reportFollowUp(condition)
            .then(({ headers, data }) => {
                setFollowUpExportData(data)
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
                setFetch(false)
            })

    }, [page, limit, fetch, sortType, sortBy])


    const fetchFollowUp = useCallback(() => {
        setLoading(true)
        let condition: FindAllReportInterface = {
            page: page, pageLimit: limit,
            dateStart: rangeDate[0],
            dateEnd: rangeDate[1],
            sortBy: sortBy,
            sortType: sortType

        }

        if (branchId && branchId !== 0) {
            condition = { ...condition, branchId }
        }
        if (rangeDate) {
            if (rangeDate[0] !== null && rangeDate[1] !== null)
                condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        if (operativeId) {
            condition.operativeId = operativeId.operativeId
        }
        if (operativeTypeId && operativeTypeId?.operativeTypeId !== 0) {
            condition.operativeTypeId = operativeTypeId.operativeTypeId
        }
        if (!_.isEmpty(search)) {
            condition.search = search
        }

        ReportApi.reportFollowUp(condition)
            .then(({ headers, data }) => {
                setFollowUpData(data)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
                setFetch(false)
            })

    }, [page, limit, fetch, sortType, sortBy])

    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'FOLLOW_UP') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => {
                const branchesWithAllOption = [
                    { branchId: 0, branchName: t('REPORT.REPORT_GROUP.ALL'), branchNameEn: 'ALL' },
                    ...data, // เพิ่มข้อมูลสาขาที่ได้รับจาก API ด้านล่างของตัวเลือก "ทั้งหมด"
                ];
                setBranches(branchesWithAllOption);
            })
            .catch((e) => {
                return;
            });

        OperativeApi.findAll({ export: 1 }).then(({ data }) => {
            setOperative([{ operativeName: t('REPORT.FILTER.OPERATION_ALL'), operativeId: 0 }, ...data])
        }).catch((e) => { return })

        OperativeTypeApi.findAll({ export: 1 }).then(({ data }) => {
            setOperativeType([{ operativeTypeName: t('REPORT.FILTER.TYPE_OPERATION_ALL'), operativeTypeId: 0 }, ...data])
        }).catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    useEffect(() => {
        fetchFollowUp()
    }, [fetchFollowUp])

    useEffect(() => {
        loadFollupExport()
    }, [loadFollupExport])

    useEffect(() => {
        setOperativeId(null)
    }, [operativeTypeId])

    const handleChangeRowsPerPage = (limit: number) => {
        setLimit(limit)
    }

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.PATIENTNAME', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.PHONE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.LINE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.EMAIL', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.FACEBOOK', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.OPERATIVE_TYPE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.OPERATIVE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DOCTOR', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.1', align: 'center', class: 'text-nowrap', rowSpan: 1, colSpan: 3 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE_FOLLOW', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.RESULT', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.2', align: 'center', class: 'text-nowrap', rowSpan: 1, colSpan: 3 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE_FOLLOW', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.RESULT', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.3', align: 'center', class: 'text-nowrap', rowSpan: 1, colSpan: 3 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE_FOLLOW', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.RESULT', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.4', align: 'center', class: 'text-nowrap', rowSpan: 1, colSpan: 3 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE_FOLLOW', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.RESULT', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.CREATEDBY', align: 'center', class: 'text-nowrap', rowSpan: 2 },
    ]

    const renderData = (data: any, no: number) => {
        return <>
            <TableRowCommon
                key={no.toString()}
                id={no.toString()}
                obj={data}
                columns={[
                    { option: 'TEXT', align: 'center', label: moment(data.createdAt).add(isBuddhistYear(), 'y').format('DD/MM/YYYY'), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.patientFullName, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.phone || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.line || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.email || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.facebook || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.operativeTypeName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.operativeName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.createdBy || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpDate1 ? moment(data.followUpDate1).add(isBuddhistYear(), 'y').format('DD/MM/YYYY') : '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpStatus1 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpRemark1 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpDate2 ? moment(data.followUpDate2).add(isBuddhistYear(), 'y').format('DD/MM/YYYY') : '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpStatus2 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpRemark2 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpDate3 ? moment(data.followUpDate3).add(isBuddhistYear(), 'y').format('DD/MM/YYYY') : '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpStatus3 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpRemark3 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpDate4 ? moment(data.followUpDate4).add(isBuddhistYear(), 'y').format('DD/MM/YYYY') : '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpStatus4 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpRemark4 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.remark || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.createdBy || '-', class: 'text-nowrap' },
                ]} />
        </>
    }
    const handleChangePage = (val: number) => setPage(val)
    return (
        <Card className={'border-0 h-100'}>
            <HeaderCard text={t(`REPORT.REPORT_NAME.FOLLOW_UP`)} />
            <Card.Body>
                <Card.Title className={'mb-0'}>
                    <Row className='mx-0'>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <InputTextSearch
                                keyInput={'search'}
                                label={t('INPUT.SEARCH')}
                                value={search}
                                onchange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                onClear={(event) => {
                                    setSearch('')
                                }}
                            />
                        </Col>

                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setBranchId(e.target.value)
                                    setPage(1)
                                }}
                                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn') || t('REPORT.REPORT_GROUP.ALL')} `}
                                label={''}
                                selectId="select-branch"
                                labelId="label-branch"
                                value={branchId}
                                options={
                                    _.map([{ branchId: 0 }, ...branches], (b: BranchInterface, index) => {
                                        return (
                                            <MenuItem style={{ overflow: 'hidden' }} key={b.branchId} value={b.branchId}>
                                                {b.branchName || b.branchNameEn}
                                            </MenuItem>
                                        )
                                    })
                                }
                                style={{ overflow: 'hidden' }}
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setOperativeTypeId(e.target.value)
                                }}
                                renderValue={() => `${t('REPORT.TABLE.FOLLOW_UP.OPERATIVE_TYPE')}: ${operativeTypeId ? operativeTypeId.operativeTypeName : t('REPORT.FILTER.TYPE_OPERATION_ALL')}`}
                                label={''}
                                selectId="select-operativeType"
                                labelId="label-operativeType"
                                value={operativeTypeId || { operativeTypeName: t('REPORT.FILTER.TYPE_OPERATION_ALL'), operativeTypeId: 0 }}
                                options={
                                    _.map([...operativeType], (b: any, index) => {
                                        return (
                                            <MenuItem key={b.operativeTypeId} value={b}>
                                                {b.operativeTypeName}
                                            </MenuItem>
                                        )
                                    })
                                }
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setOperativeId(e.target.value)
                                }}
                                renderValue={() => `${t('REPORT.TABLE.FOLLOW_UP.OPERATIVE')}: ${operativeId ? operativeId.operativeName : t('REPORT.FILTER.OPERATION_ALL')}`}
                                label={''}
                                selectId="select-operative"
                                labelId="label-operative"
                                disabled={operativeTypeId ? false : true}
                                value={operativeTypeId || { operativeName: t('REPORT.FILTER.OPERATION_ALL'), operativeId: 0 }}
                                options={
                                    _.map([...opertatives].filter(item => item.operativeTypeId === operativeTypeId?.operativeTypeId), (b: any, index) => {
                                        return (
                                            <MenuItem key={b.operativeId} value={b}>
                                                {b.operativeName}
                                            </MenuItem>
                                        )
                                    })
                                }
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <InputSecondNewRangePicker
                                inputHeight={32}
                                value={rangeDate || emptyRangePicker}
                                onchange={(val: any) => {
                                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                }}
                                allowClear
                                onClear={() => setRangeDate(emptyRangePicker)}
                                label={''}
                            />
                        </Col>
                        <Col md={1} xl={1} className={'pt-0'}>
                            <ButtonCustom onClick={() => {
                                setFetch(true);
                                setPage(1);
                            }}
                                textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                        </Col>
                        <Col className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                            <UseStyled.DropdownDocument>
                                <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                    {t('PRINT_DOCUMENT')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={onDownload}>
                                        {t('Excel')}

                                    </Dropdown.Item>
                                    {/* <FucntionPrint
                                        content={() => componentRef.current}
                                        docTitle={t(`REPORT.REPORT_NAME.OPERATIVE_SUMMARY_MONTH`)}
                                        removeIfram
                                        trigger={() => <Dropdown.Item >
                                            {t('PDF')}
                                        </Dropdown.Item>}
                                        style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                    /> */}
                                </Dropdown.Menu>
                            </UseStyled.DropdownDocument>
                        </Col>
                    </Row>
                </Card.Title>

                <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                    <FollowUpList
                        page={page}
                        pageLimit={limit}
                        rowCount={rowCount}
                        data={followUpData}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage}
                        onRequestSort={() => { return }}
                        sortBy={sortBy}
                        sortType={sortType}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                    ></FollowUpList>

                </Box>

            </Card.Body>
            <Loading show={loading} type='fullLoading' />

            <div className="print-show" ref={componentRef}>
                <ExportData
                    componant={
                        <>
                            <span style={{ fontWeight: 'bold' }}>{t(`REPORT.REPORT_NAME.FOLLOW_UP`)}</span>
                            <table>
                                <tr>
                                    {getAllFilter().map((filter: any, index: number) => (
                                        <TableSortLabel key={index} >
                                            <td className='mr-2' style={{ textAlign: 'left', fontSize: 10, fontWeight: 800 }}>{t(filter.label)} : </td>
                                            <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500 }}>{filter.value}</td>
                                        </TableSortLabel >
                                    ))}
                                </tr>
                            </table>
                            <UseStyled.Styles className="pt-3">
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        {_.map([1, 2], (val: number) => {
                                            return (
                                                <tr>
                                                    {_.map(headCells, (val2: any, index: number) => {
                                                        return val === (val2.row || 1) ? <td rowSpan={val2.rowSpan || 1} colSpan={val2.colSpan || 1} style={{ textAlign: val2.align, fontSize: 10, fontWeight: 500 }}> {t(val2.labelKey)}</td> : <></>
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </thead>
                                    <tbody>
                                        {_.map(followUpExportData.rows, (d: any, index: number) => {
                                            return (
                                                renderData(d, index)
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </UseStyled.Styles>
                        </>
                    }
                />
            </div>
        </Card>
    )
}

export function ExportData(props: ExportProps) {
    return <>{props.componant}</>
}