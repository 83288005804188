import CalendarDay, { CalendarItem } from 'component/Calendar/CalendarMonth/CalendarMonth'
import { useState, useCallback, useEffect } from 'react'
import { Row, Col, Image, Dropdown, DropdownButton, ButtonGroup, Card } from 'react-bootstrap'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/counter/doctor-appointment-schedule/style'

/** COMPONENT */
import HeaderAppointmentCalendar from 'component/CardCustom/HeaderAppointmentCalendar'
import ModalCustomForm from 'component/ModalCustom/ModalCustomForm'
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import MenuItem from '@mui/material/MenuItem'
import MultipleSelect from 'component/Select/MultipleSelect'
import { Select } from 'antd'
import InputTextarea from 'component/Input/InputTextarea'

import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import InputRadio from 'component/Input/InputRadio'

/** UTILS || SLICE */
import { submitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { useDispatch } from 'react-redux'
import { colors } from 'constants/theme'

/** ICON || IMAGE */
import { Icons, icons } from 'constants/images'
import cancel from 'assets/icon/status/cancel.svg'
import changeSchedule from 'assets/icon/status/changeSchedule.svg'
import enter from 'assets/icon/status/enter.svg'
import notConnect from 'assets/icon/status/notConnect.svg'
import userCheckFalse from 'assets/icon/status/userCheckFalse.svg'
import userCheckTrue from 'assets/icon/status/userCheckTrue.svg'

/** API */
import AppointmentDurationApi from 'api/setting/defaults/appointment-duration.api'
import BranchApi from 'api/setting/branch-service-points/branch.api'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'


const patients = [
  { number: '11111', name: 'สมรัก เก่งกาจ' },
  { number: '11122', name: 'สมหยอย เก่งจัง' }
]

const tableData = [
  {
    id: 1,
    branchName: 'hihi',
    date: '01-01-2020',
    timeStart: '20:20',
    timeRange: '3',
    dentist: 'จักพร',
    service: 'รักษาฟันกราม',
    status: 'เสร็จสิ้น',
    cost: '2000',
    operation: '-',
    comment: '-',
    lab: 'มี'
  },
  {
    id: 2,
    branchName: 'hihi',
    date: '01-01-2020',
    timeStart: '20:20',
    timeRange: '3',
    dentist: 'จักพร',
    service: 'รักษาฟันกราม',
    status: 'เสร็จสิ้น',
    cost: '2000',
    operation: '-',
    comment: '-',
    lab: 'มี'
  }
]

const allDentists = [
  {
    dentist: 'วีรพล เจริญผลพืชผักสวนครัว',

    patient: { number: '11111', name: 'สมหยอย เก่งจัง' },
    time: { timeStart: '09:00', timeEnd: '10:00' },
    assistant: ['สมรักษ์ เก่งกาจ', 'สมใจ เก่งกาจ'],
    costCondition: 10000,
    date: '2021-01-03',
    treatments: ['1', '2'],
    worngDuty: false,
    cancelDuty: false,
    status: {
      arrived: false,
      unarrived: false,
      cancel: false,
      enter: true,
      unconnect: false,
      change: false
    }
  },
  {
    dentist: 'สมรักษ์ เก่งกาจ',

    patient: { number: '11122', name: 'สมหยอย เก่งจัง' },
    time: { timeStart: '00:00', timeEnd: '9:00' },
    assistant: ['สมใจ เก่งกาจ', 'วีรพล เจริญผลวัฒนา'],
    costCondition: 20000,
    date: '2021-01-03',
    treatments: ['1', '2'],
    worngDuty: false,
    cancelDuty: false,
    status: {
      arrived: true,
      unarrived: false,
      cancel: false,
      enter: false,
      unconnect: false,
      change: false
    }
  },
  {
    dentist: 'อัญชิสา ศิริวงศ์วัฒนา',

    patient: { number: '11133', name: 'วีรพล เก่งจัง' },
    time: { timeStart: '00:00', timeEnd: '11:00' },
    assistant: ['อารยา พิกุลเทพ', 'วีรพล เจริญผลวัฒนา'],
    costCondition: 90000,
    date: '2021-01-03',
    treatments: ['กำจัดคราบฟัน', 'ถอนฟัน', 'วางแผนการ', 'รักษารากฟัน', 'ตรวจชีวิตฟัน'],
    worngDuty: false,
    cancelDuty: false,
    status: {
      arrived: false,
      unarrived: false,
      cancel: false,
      enter: false,
      unconnect: true,
      change: false
    }
  },

  {
    date: '2021-01-04',

    patient: { number: '11123', name: 'อัญชิสา เก่งจัง' },
    phone: '0891334256',
    branch: 'สำนักงานใหญ่',
    dentist: 'อัญชิสา ศิริวงศ์วัฒนา',
    time: { timeStart: '00:00', timeEnd: '11:00' },
    timeRange: '1',
    treatments: ['1', '2'],
    costCondition: 50000,
    lab: false,
    xray: true,
    note: 'test1',
    rule: 'test2',
    worngDuty: false,
    cancelDuty: false,
    status: {
      arrived: false,
      unarrived: false,
      cancel: false,
      enter: false,
      unconnect: false,
      change: false
    }
  }
]

export default function DoctorAppointmentSchedule() {
  const [indexHandleValue, setIndexHandleValue] = useState(Number)
  const [yearAndMonth, setYearAndMonth] = useState([2021, 1])
  const [year, month] = yearAndMonth
  const [appointment, setAppointment] = useState(false)
  const [date, setDate] = useState('')
  const [patient, setPatient] = useState('')
  const [phone, setPhone] = useState('')
  const [branch, setBranch]: any = useState('')
  const [dentist, setDentist]: any = useState('')
  const [timeRange, setTimeRange]: any = useState('')
  const [timeStart, setTimeStart] = useState('')
  const [operativeType, setOperativeType] = useState([])
  const [cost, setCost] = useState(Number)
  const [lab, setLab] = useState(false)
  const [xray, setXray] = useState(false)
  const [rule, setRule] = useState('')
  const [note, setNote] = useState('')
  const [newPatient, setNewPatient] = useState(false)
  const [fnamePatient, setFnamePatient] = useState('')
  const [lnamePatient, setLnamePatient] = useState('')
  const [phonePatient, setPhonePatient] = useState('')
  const [value, setValue]: any = useState(allDentists)
  const [appointmentDurations, setAppointmentDurations] = useState([])
  const [selectBranch, setSelectBranch] = useState([])
  const [selectDentist, setSelectDentist] = useState([])
  const [selectOperativeType, setSelectOperativeType] = useState([])
  const [labelStatus, setLabelStatus]: any = useState('')
  const [noteStatus, setNoteStatus] = useState('')
  const [edit, setEdit] = useState(false)
  const { t } = useTranslation()

  const statusIcon: any = {
    cancel: { src: cancel, color: colors.appointmentStatus.statusCancel, label: t('DOCTOR_SCHEDULE.STATUS.CANCEL') },
    unconnect: { src: notConnect, color: colors.appointmentStatus.statusUnConnect, label: t('DOCTOR_SCHEDULE.STATUS.UN_CONNECT') },
    change: { src: changeSchedule, color: colors.appointmentStatus.statusChange, label: t('DOCTOR_SCHEDULE.STATUS.CHANGE') },
    enter: { src: enter, color: colors.appointmentStatus.statusEnter, label: t('DOCTOR_SCHEDULE.STATUS.ENTER') },
    arrived: { src: userCheckTrue, color: colors.appointmentStatus.statusArrived, label: t('DOCTOR_SCHEDULE.STATUS.ARRIVED') },
    unarrived: { src: userCheckFalse, color: colors.appointmentStatus.statusUnArrived, label: t('DOCTOR_SCHEDULE.STATUS.UN_ARRIVED') }
  }

  const props = {
    backgroundColor: `${statusIcon[`${labelStatus}`] !== undefined ? statusIcon[`${labelStatus}`].color : colors.lightGray}`,
    color: colors.black
  }
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { Option } = Select

  /** load data from api */
  const loadAppointmentDurations = useCallback(async () => {
    const condition: any = {}
    const res = await AppointmentDurationApi.findAll(condition)
    if (res.status === 200) {
      setAppointmentDurations(res.data)
    }
  }, [])

  const loadBranch = useCallback(async () => {
    const condition: any = {}
    const res = await BranchApi.findAll(condition)
    if (res.status === 200) {
      setSelectBranch(res.data)
    }
  }, [])

  const loadDentist = useCallback(async () => {
    const condition: any = {}
    const res = await PersonnelApi.findAll(condition)
    if (res.status === 200) {
      setSelectDentist(res.data)
    }
  }, [])

  const loadOperativeType = useCallback(async () => {
    const condition: any = {}
    const res = await OperativeTypeApi.findAll(condition)
    if (res.status === 200) {
      setSelectOperativeType(res.data)
    }
  }, [])

  useEffect(() => {
    loadAppointmentDurations()
    loadBranch()
    loadDentist()
    loadOperativeType()

  }, [loadAppointmentDurations, loadBranch, loadDentist, loadOperativeType])

  const handleMonthNavBackButtonClick = () => {
    const dateMonthNav = {
      nextYear: year,
      nextMonth: month - 1
    }

    if (dateMonthNav.nextMonth === 0) {
      dateMonthNav.nextMonth = 12
      dateMonthNav.nextYear = year - 1
    }
    setYearAndMonth([dateMonthNav.nextYear, dateMonthNav.nextMonth])
  }

  const handleMonthNavForwardButtonClick = () => {
    const dateForward = {
      nextYear: year,
      nextMonth: month + 1
    }

    if (dateForward.nextMonth === 13) {
      dateForward.nextMonth = 1
      dateForward.nextYear = year + 1
    }
    setYearAndMonth([dateForward.nextYear, dateForward.nextMonth])
  }

  const handleMonthSelect = (evt: any) => {
    const dateMonth = {
      nextYear: year,
      nextMonth: parseInt(evt.target.value, 10)
    }

    setYearAndMonth([dateMonth.nextYear, dateMonth.nextMonth])
  }

  const handleMonthSelectCalendar = (evt: any) => {
    const dateMonthCalendar = {
      nextYear: year,
      nextMonth: parseInt(evt, 10)
    }

    setYearAndMonth([dateMonthCalendar.nextYear, dateMonthCalendar.nextMonth])
  }

  const handleYearSelect = (evt: any) => {
    const dateYear = {
      nextYear: parseInt(evt, 10),
      nextMonth: month
    }

    setYearAndMonth([dateYear.nextYear, dateYear.nextMonth])
  }

  const handleMonthYearSelect = (monthVal: any, yearVal: any) => {
    const dateMonthYear = {
      nextYear: yearVal,
      nextMonth: monthVal
    }

    setYearAndMonth([dateMonthYear.nextYear, dateMonthYear.nextMonth])
  }

  const addAppointment = () => {
    resetForm()
    setIndexHandleValue(-1)
    setEdit(false)
    setAppointment(true)
    dispatch(showModal())
  }

  const viewHistory = () => {
    setAppointment(false)
    dispatch(showModal())
  }

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const rowCount = 0
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const headCells = [
    {
      id: 'Id',
      disablePadding: false,
      label: '#'
    },
    {
      id: 'branchName',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.BRANCH')
    },
    {
      id: 'date',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.TIME_APPOINTMENT')
    },
    {
      id: 'timeStart',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.TIME_START')
    },
    {
      id: 'timeRange',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.PERIOD')
    },
    {
      id: 'dentist',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.USER_DOCTOR')
    },
    {
      id: 'service',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.SERVICE')
    },
    {
      id: 'status',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.STATUS')
    },
    {
      id: 'cost',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.PRICE')
    },
    {
      id: 'operation',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.PRACTICE')
    },
    {
      id: 'comment',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.REMARK')
    },
    {
      id: 'Lab',
      disablePadding: false,
      label: t('APPOINTMENT.CELL.LAB')
    }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { id, branchName, date: appDate, timeStart: appTimeStart, timeRange: appTimeRange, dentist: appDentist, service, status, cost: appCost, operation, comment, lab: appLab } = objData

    const objRenderData = {
      key: id,
      id: id,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: branchName },
        { option: 'TEXT', align: 'left', label: appDate },
        { option: 'TEXT', align: 'left', label: appTimeStart },
        { option: 'TEXT', align: 'left', label: appTimeRange },
        { option: 'TEXT', align: 'left', label: appDentist },
        { option: 'TEXT', align: 'left', label: service },
        { option: 'TEXT', align: 'left', label: status },
        { option: 'TEXT', align: 'left', label: appCost },
        { option: 'TEXT', align: 'left', label: operation },
        { option: 'TEXT', align: 'left', label: comment },
        { option: 'TEXT', align: 'left', label: appLab }
      ]
    }
    return <TableRowCommon {...objRenderData} />
  }

  const resetForm = () => {
    setDate('')
    setPhone('')
    setPatient('')
    setBranch('')
    setDentist('')
    setOperativeType([])
    setCost(0)
    setLab(false)
    setXray(false)
    setRule('')
    setNote('')
    setFnamePatient('')
    setLnamePatient('')
    setPhonePatient('')
    setTimeStart('')
    setTimeRange('')
    setNewPatient(false)
  }

  const submitAdd = () => {
    resetForm()
    const tempPatients = value
    const addPatient = {
      name: `${fnamePatient} ${lnamePatient}`
    }
    const patientObj = {
      date: date,
      patient: newPatient ? addPatient : patient,
      phone: newPatient ? phonePatient : phone,
      branch: branch,
      dentist: dentist,
      time: { timeStart: moment(timeStart, 'HH:mm').format('HH:mm'), timeEnd: moment(timeStart, 'HH:mm').add(timeRange.durationMinute, 'minutes').format('HH:mm') },
      timeRange: timeRange,
      treatments: operativeType,
      costCondition: cost,
      lab: lab,
      xray: xray,
      note: note,
      rule: rule,
      worngDuty: false,
      cancelDuty: false,
      status: {
        arrived: false,
        unarrived: false,
        cancel: false,
        enter: false,
        unconnect: false,
        change: false
      }
    }
    tempPatients.push(patientObj)

    setValue(tempPatients)
    dispatch(submitModal())
    dispatch(resetModal())
  }

  const handleStatus = (status: any, index: number) => {
    const tempValue = value
    const tempPatients = value[index]
    const statuslabel: any = {
      arrived: false,
      unarrived: false,
      cancel: false,
      enter: false,
      unconnect: false,
      change: false
    }

    _.map(Object.keys(statuslabel), (keyName: any, i) => {
      statuslabel[keyName] = false
    })

    statuslabel[status] = true
    tempPatients.status = statuslabel
    tempValue[index] = tempPatients
    setValue(tempValue)
  }

  const handleLabelStatus = (status: any) => {
    const labelObj = { label: '' }
    Object.keys(status).forEach(function (key) {

      if (status[key]) {
        labelObj.label = key
      }
    })

    return labelObj.label
  }

  const editAppointment = (obj: any, index: number) => {
    resetForm()
    const status = handleLabelStatus(obj.status)
    setIndexHandleValue(index)
    handleStatus(status, index)
    setLabelStatus(status)
    setDate(obj.date)
    setPhone(obj.phone)
    setPatient(obj.patient)
    setBranch(obj.branch)
    setDentist(obj.dentist)
    setOperativeType(obj.treatments)
    setCost(obj.costCondition)
    setLab(obj.lab)
    setXray(obj.xray)
    setRule(obj.rule)
    setNote(obj.note)
    setTimeStart(obj.time.timeStart)
    setEdit(true)
    setAppointment(true)
    setTimeRange(obj.timeRange)
    dispatch(showModal())
  }

  const submitEdit = () => {
    const tempValue = value
    const patientObj = {
      date: date,
      patient: patient,
      phone: phone,
      branch: branch,
      dentist: dentist,
      time: { timeStart: moment(timeStart, 'HH:mm').format('HH:mm'), timeEnd: moment(timeStart, 'HH:mm').add(timeRange.durationMinute, 'minutes').format('HH:mm') },
      timeRange: timeRange,
      treatments: operativeType,
      costCondition: cost,
      noteStatus: noteStatus,
      lab: lab,
      xray: xray,
      note: note,
      rule: rule,
      worngDuty: false,
      cancelDuty: false,
      status: tempValue[indexHandleValue].status
    }

    tempValue[indexHandleValue] = patientObj
    setValue(tempValue)
    dispatch(submitModal())
    dispatch(resetModal())
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderAppointmentCalendar
          handleMonthNavBackButtonClick={handleMonthNavBackButtonClick}
          handleMonthNavForwardButtonClick={handleMonthNavForwardButtonClick}
          handleYearSelect={handleYearSelect}
          handleMonthSelect={handleMonthSelect}
          handleMonthSelectCalendar={handleMonthSelectCalendar}
          handleMonthYearSelect={handleMonthYearSelect}
          month={month}
          year={year}
          onClickAddAppointment={addAppointment}
          onClickViewHistory={viewHistory}
        />
        <Card.Body className={classes.bodyCalendar}>
          <div>
            <CalendarDay
              yearAndMonth={yearAndMonth}
              onYearAndMonthChange={setYearAndMonth}
              handleMonthNavBackButtonClick={handleMonthNavBackButtonClick}
              handleMonthNavForwardButtonClick={handleMonthNavForwardButtonClick}
              handleYearSelect={handleYearSelect}
              handleMonthSelect={handleMonthSelect}
              renderDay={(calendarDayObject: any) => {
                return (
                  <CalendarItem
                    editAppointment={editAppointment}
                    calendarDayObject={calendarDayObject}
                    allDentists={value}
                  />
                )
              }}
            />
          </div>
        </Card.Body>
      </Card>
      <ModalCustomForm
        size={appointment ? 'lg' : 'xl'}
        title={appointment ? t('APPOINTMENT.FORM.ADD') : t('APPOINTMENT.FORM.HISTORY')}
        component={
          appointment ? (
            <div>
              <Row className={`mb-2`}>
                <Col sm={3}>
                  <InputTextField
                    InputLabelProps={{ shrink: true }}
                    label={t('APPOINTMENT.FORM.DATE')}
                    type="date"
                    value={date}
                    inputProps={{ shrink: true }}
                    onchange={(e) => {
                      setDate(e.target.value)
                    }}
                  />
                </Col>
                <Col sm={3}>
                  <BasicSelect
                    disabled={newPatient}
                    style={{ fontSize: '16px' }}
                    onchange={(event) => {
                      const valuePatient = event.target.value
                      if (valuePatient) {
                        setPatient(valuePatient)
                      }
                    }}
                    label={t('APPOINTMENT.FORM.PATIENT_')}
                    selectId="patient"
                    value={patient}
                    labelId="patient"
                    options={patients.map((patientData: any) => {
                      return (
                        <MenuItem key={patientData.number} value={patientData}>
                          {patientData.number + '|' + patientData.name}
                        </MenuItem>
                      )
                    })}
                  />
                </Col>
                <Col sm={3}>
                  <InputTextField
                    disabled={newPatient}
                    label={t('APPOINTMENT.FORM.PATIENT_PHONE')}
                    type="phone"
                    value={phone}
                    inputProps={{ shrink: true }}
                    onchange={(e) => {
                      setPhone(e.target.value)
                    }}
                  />
                </Col>
                <Col sm={3}>
                  <button
                    style={{ display: edit ? 'none' : '' }}
                    className={`${newPatient ? classes.btnDisable : classes.btn} d-flex align-items-center`}
                    disabled={newPatient}
                    onClick={(e) => {
                      setNewPatient(true)
                    }}
                  >
                    {newPatient ? <Icons.IconUserPlusDis /> : <Icons.IconUserPlus />}
                    <span style={{ marginLeft: '6px' }}>{t('APPOINTMENT.FORM.ADD_PERSON')}</span>
                  </button>
                  <>
                    <DropdownButton
                      className={classes.dropdownColor}
                      style={{ display: edit ? '' : 'none' }}
                      onSelect={(e) => {
                        handleStatus(e, indexHandleValue)
                        setLabelStatus(e)
                      }}
                      as={ButtonGroup}
                      title={
                        <div style={{ display: 'flex' }}>
                          <Image src={statusIcon[`${labelStatus}`] !== undefined ? statusIcon[`${labelStatus}`].src : ''} />
                          {statusIcon[`${labelStatus}`] !== undefined ? statusIcon[`${labelStatus}`].label : t('APPOINTMENT.FORM.SELECT_STATUS')}
                        </div>
                      }
                    >
                      {Object.keys(statusIcon).map((keyName, i) => {
                        return (
                          <Dropdown.Item key={i} eventKey={keyName}>
                            {statusIcon[`${keyName}`].label}
                          </Dropdown.Item>
                        )
                      })}
                    </DropdownButton>
                  </>
                </Col>
              </Row>
              <Row style={{ display: newPatient ? '' : 'none', overflow: 'hidden' }}>
                <div className={classes.newPatient}>
                  <div>เพิ่มคนไข้ใหม่</div>
                </div>
                <Row className={'mt-3 mb-2'} style={{ marginLeft: '20px' }}>
                  <Col sm={3}>
                    <InputTextField
                      label={t('APPOINTMENT.FORM.PATIENT_NAME')}
                      type="text"
                      value={fnamePatient}
                      inputProps={{ shrink: true }}
                      onchange={(e) => {
                        setFnamePatient(e.target.value)
                      }}
                    />
                  </Col>
                  <Col sm={3}>
                    <InputTextField
                      label={t('APPOINTMENT.FORM.PATIENT_LASTNAME')}
                      type="text"
                      value={lnamePatient}
                      inputProps={{ shrink: true }}
                      onchange={(e) => {
                        setLnamePatient(e.target.value)
                      }}
                    />
                  </Col>
                  <Col sm={3}>
                    <InputTextField
                      label={t('APPOINTMENT.FORM.PATIENT_PHONE')}
                      type="phone"
                      value={phonePatient}
                      inputProps={{ shrink: true }}
                      onchange={(e) => {
                        setPhonePatient(e.target.value)
                      }}
                    />
                  </Col>
                  <Col sm={3}>
                    <Image
                      style={{ marginLeft: '7vw' }}
                      src={icons.iconDelete}
                      onClick={(e) => {
                        setNewPatient(false)
                      }}
                    />
                  </Col>
                </Row>
              </Row>
              <Row className={`mb-3 mt-3`}>
                <Col sm={3}>
                  <BasicSelect
                    style={{ fontSize: '16px' }}
                    onchange={(event) => {
                      const valueBranch = event.target.value
                      if (valueBranch) {
                        setBranch(valueBranch)
                      }
                    }}
                    label={t('APPOINTMENT.FORM.BRANCH')}
                    selectId="branch"
                    value={branch}
                    labelId="branch"
                    options={selectBranch.map((br: any) => {
                      return (
                        <MenuItem key={br} value={br.branchName}>
                          {br.branchName}
                        </MenuItem>
                      )
                    })}
                  />
                </Col>
                <Col sm={3}>
                  <BasicSelect
                    style={{ fontSize: '16px' }}
                    onchange={(event) => {
                      const valueDentist = event.target.value
                      if (valueDentist) {
                        setDentist(valueDentist)
                      }
                    }}
                    label={t('APPOINTMENT.FORM.USER_DOCTOR')}
                    selectId="dentist"
                    value={dentist}
                    labelId="dentist"
                    options={selectDentist.map((dentistData: any) => {
                      return (
                        <MenuItem key={dentistData} value={dentistData.firstname}>
                          {dentistData.firstname}
                        </MenuItem>
                      )
                    })}
                  />
                </Col>
                <Col sm={3}>
                  <InputTextField
                    InputLabelProps={{ shrink: true }}
                    label={t('APPOINTMENT.FORM.TIME_APPOINTMENT')}
                    type="time"
                    value={timeStart}
                    inputProps={{ shrink: true }}
                    onchange={(e) => {
                      setTimeStart(e.target.value)
                    }}
                  />
                </Col>

                <Col sm={3}>
                  <BasicSelect
                    style={{ fontSize: '16px' }}
                    onchange={(event) => {
                      const valueRange = event.target.value
                      if (valueRange) {
                        setTimeRange(valueRange)
                      }
                    }}
                    label={t('APPOINTMENT.FORM.PERIOD')}
                    selectId="timeRange"
                    value={timeRange}
                    labelId="timeRange"
                    options={appointmentDurations.map((timeRangeItem: any) => {
                      return (
                        <MenuItem key={timeRangeItem} value={timeRangeItem}>
                          {`${timeRangeItem.duration} ${timeRangeItem.durationType}`}
                        </MenuItem>
                      )
                    })}
                  />
                </Col>
              </Row>

              <Row className={`mt-1 mb-2`} style={{ display: edit ? '' : 'none' }}>
                <InputTextarea
                  onchange={(event) => {
                    setNoteStatus(event.target.value)
                  }}
                  value={noteStatus}
                  label={t('APPOINTMENT.FORM.REMARK_CANCEL')}
                  helperText={''}
                />
              </Row>
              <Row className={`mb-2`}>
                <Col sm={9}>
                  <MultipleSelect
                    labelId="operative-type"
                    selectId="operative-type"
                    label={' '}
                    value={operativeType}
                    setValue={(newValue: any) => {
                      setOperativeType(newValue)
                    }}
                    options={selectOperativeType.map((valueOptType: any, index: number) => {
                      return (
                        <Option key={valueOptType.operativeTypeId} value={valueOptType.operativeTypeName}>
                          {valueOptType.operativeTypeName}
                        </Option>
                      )
                    })}
                    placeholder={t('APPOINTMENT.FORM.TREATEMENT')}
                  />
                </Col>
                <Col sm={3}>
                  <InputTextField
                    label={t('APPOINTMENT.FORM.PRICE')}
                    type="phone"
                    value={cost}
                    inputProps={{ shrink: true }}
                    onchange={(e) => {
                      setCost(e.target.value)
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <div className={`${classes.containerRadio}`}>
                  <span className={`${classes.formLabel}`}>{t('APPOINTMENT.FORM.LIST_ORDER_LAB')}</span>
                  <FormControl>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel
                        className={`${classes.radio}`}
                        value="lab"
                        control={
                          <InputRadio
                            onClick={(e: any) => {
                              setLab(true)
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 20
                              }
                            }}
                          />
                        }
                        label={t('APPOINTMENT.FORM.HAS_LAB')}
                      />
                      <FormControlLabel
                        className={`${classes.radio}`}
                        value="nonlab"
                        control={
                          <InputRadio
                            onClick={(e: any) => {
                              setLab(false)
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 20
                              }
                            }}
                          />
                        }
                        label={t('APPOINTMENT.FORM.NOT_HAS_LAB')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className={`${classes.containerRadio}`}>
                  <span className={`${classes.formLabel}`}>{t('APPOINTMENT.FORM.LIST_ORDER_XRAY')}</span>
                  <FormControl>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel
                        className={`${classes.radio}`}
                        value="xray"
                        control={
                          <InputRadio
                            onClick={(e: any) => {
                              setXray(true)
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 20
                              }
                            }}
                          />
                        }
                        label={t('APPOINTMENT.FORM.HAS_XRAY')}
                      />
                      <FormControlLabel
                        className={`${classes.radio}`}
                        value="nonxray"
                        control={
                          <InputRadio
                            onClick={(e: any) => {
                              setXray(false)
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 20
                              }
                            }}
                          />
                        }
                        label={t('APPOINTMENT.FORM.NOT_HAS_XRAY')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Row>
              <Row className={`mb-3 mt-2`}>
                <InputTextarea
                  onchange={(event) => {
                    setNote(event.target.value)
                  }}
                  value={note}
                  label={t('APPOINTMENT.FORM.PRACTICE')}
                  helperText={''}
                />
              </Row>
              <Row>
                <InputTextarea
                  onchange={(event) => {
                    setRule(event.target.value)
                  }}
                  value={rule}
                  label={t('APPOINTMENT.FORM.REMARK')}
                  helperText={''}
                />
              </Row>
            </div>
          ) : (
            <div>
              {' '}
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                rowCount={rowCount}
                headCells={headCells}
                rowsData={tableData.map((val: any, i: any) => {
                  return renderData(val, i)
                })}
              />
            </div>
          )
        }

        onSubmit={indexHandleValue >= 0 ? submitEdit : submitAdd}
        onReset={() => {
          return
        }}

        textBtnCancel={t('APPOINTMENT.FORM.CANCEL')}
        textBtnConfirm={t('APPOINTMENT.FORM.ADD')}
      />
    </div>
  )
}
