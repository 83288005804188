import { numberFormat } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { ExportProps } from '../report-constants';

interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    dataExport: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    componentRef: any
    filter: any
}

export default function PatientDateOfBirthList(props: FcComponent) {
    const { t } = useTranslation();

    const headCells = [
        { id: 'no', disablePadding: false, labelKey: 'REPORT.TABLE.ACCESS_CHANNEL.#NO', align: 'left' },
        { id: 'access_channel', disablePadding: false, labelKey: 'REPORT.TABLE.ACCESS_CHANNEL.ACCESS_CHANNEL', align: 'left' },
        { id: 'count', disablePadding: false, labelKey: 'REPORT.TABLE.ACCESS_CHANNEL.COUNT', align: 'center', width: '70%', class: 'text-nowrap' },
    ]

    const renderData = (data: any, no: number) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1;
        return (
            <TableRowCommon
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={[
                    { option: 'TEXT', align: 'left', label: num },
                    { option: 'TEXT', align: 'left', label: data.result_channelName },
                    { option: 'TEXT', align: 'center', label: data.totalCount, width: '70%' }
                ]}
            />
        );
    }


    return (
        <>
            <Box ref={props.componentRef}>
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        tableMinWidth={992}
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={props.data.rows ? _.map(props.data.rows, (d: any, index: number) => renderData(d, index)) : []}
                        customScroll
                    />
                </UseStyled.ResponsiveReportTable>
            </Box>
            <div className="print-show" ref={props.componentRef}>
                <TreatmentRightListExport
                    header={props.filter}
                    headCells={headCells}
                    rowsData={props.data.rows ? _.map(props.data.rows, (d: any, index: number) => renderData(d, index)) : []} />
            </div>
        </>
    )
}
export function TreatmentRightListExport(props: ExportProps) {
    const { t } = useTranslation()
    const { header } = props
    const head = props.headCells
    const headCells = head.filter((headCell: any) => headCell.id !== 'action')
    return (
        <UseStyled.Styles className="pt-3">

            <h5>{header[0]}</h5>

            <TableSortLabel> <td style={{ fontSize: 16, fontWeight: 500 }}>{t('REPORT.FILTER.BRANCH')} : </td></TableSortLabel>
            <TableSortLabel><td style={{ fontSize: 15, fontWeight: 400 }}>  {header[1]}</td></TableSortLabel>
            <TableSortLabel><td style={{ fontSize: 15, fontWeight: 500 }}>{t('วันที่เริ่ม')} : </td></TableSortLabel>
            <TableSortLabel><td style={{ fontSize: 15, fontWeight: 400 }}>  {header[2]}</td></TableSortLabel>
            <TableSortLabel><td style={{ fontSize: 15, fontWeight: 500 }}>{t('วันที่สิ้นสุด')} : </td></TableSortLabel>
            <TableSortLabel><td style={{ fontSize: 15, fontWeight: 400 }}>  {header[3]}</td></TableSortLabel>
            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%', textAlign: 'left', alignItems: 'self-start' }}>
                <thead>
                    <tr>
                        {_.map(headCells, (val: any, index: number) => {
                            return (
                                < td style={{ textAlign: 'center', fontSize: 12, fontWeight: 500, }}> {t(val.labelKey)}</td>
                            )
                        })}
                    </tr>
                </thead>
                <tbody style={{ border: 'none' }}>

                    {props.rowsData}

                    {!props.rowsData.length && (
                        <td colSpan={headCells.length} style={{ fontSize: 10, }}>
                            {t('REPORT.TITLE.NO_DATA')}
                        </td>
                    )}
                </tbody>
            </table>
        </UseStyled.Styles >
    )
}