import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/83.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 31.95, height: 100, viewBox: "0 0 31.95 105.04" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 31.95, height: 105.04, viewBox: "0 0 31.95 105.04" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>83</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12349">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5552"
                                d="M26.3757 5.38815C24.627 7.67761 22.5599 9.39267 19.7163 12.3288C16.2281 15.937 12.3512 16.0097 7.92945 12.1637C4.17527 8.89747 2.29848 4.32604 0.652344 0.249023C1.33469 2.80124 1.644 5.4388 1.57065 8.07963C1.1834 23.3059 3.54967 38.2828 5.53618 53.2987C6.37254 59.5726 5.28405 65.7813 4.69982 71.9963C4.46128 74.5561 4.88301 76.3038 7.71927 77.1558C11.174 75.9284 11.6848 72.6359 12.7859 69.8785C18.9556 54.3348 20.5271 37.8676 22.825 21.5654C23.6003 16.0432 24.6957 10.6403 26.3757 5.38815Z"
                                transform={"translate(2 ,25)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />

                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5552"
                                    d="M26.3757 5.38815C24.627 7.67761 22.5599 9.39267 19.7163 12.3288C16.2281 15.937 12.3512 16.0097 7.92945 12.1637C4.17527 8.89747 2.29848 4.32604 0.652344 0.249023C1.33469 2.80124 1.644 5.4388 1.57065 8.07963C1.1834 23.3059 3.54967 38.2828 5.53618 53.2987C6.37254 59.5726 5.28405 65.7813 4.69982 71.9963C4.46128 74.5561 4.88301 76.3038 7.71927 77.1558C11.174 75.9284 11.6848 72.6359 12.7859 69.8785C18.9556 54.3348 20.5271 37.8676 22.825 21.5654C23.6003 16.0432 24.6957 10.6403 26.3757 5.38815Z"
                                    transform={"translate(2 ,25)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12349">
                                <path
                                    data-name="Path 5553"
                                    d="M0.943657 13.3227C0.195146 17.9596 0.636176 23.5141 1.69749 28.0783C2.5776 29.9981 3.27163 31.9979 3.77007 34.0502C5.31752 38.2162 7.08165 42.8875 10.6119 46.2245C14.7681 50.1538 18.4133 50.0801 21.694 46.3931C24.3659 43.3926 26.3109 41.6405 27.9535 39.302C29.9362 36.4852 30.2173 33.4912 32.1097 25.7535C32.3067 24.9373 32.5196 21.9332 32.5206 20.9899C32.5415 8.32761 19.5554 1.0243 18.027 0.255899C14.6499 -1.44342 1.09513 12.3798 0.943657 13.3227Z"
                                    transform={"translate(-1 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5554"
                                    d="M2.40436 13.4516C1.72676 17.6548 2.12584 22.6898 3.08827 26.8263C3.88545 28.5669 4.51418 30.3799 4.96584 32.2404C6.36891 36.0164 7.96816 40.2504 11.168 43.2754C14.936 46.8383 18.2395 46.7702 21.2139 43.4286C23.6367 40.7094 25.3988 39.1204 26.8882 37.0005C28.685 34.4463 28.9399 31.7334 30.6559 24.7184C30.8356 23.9792 31.0268 21.2552 31.0286 20.3999C31.0483 8.92229 19.2752 2.3019 17.8903 1.60539C14.8291 0.0666136 2.54224 12.5973 2.40436 13.4516Z"
                                    transform={"translate(-1 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5555"
                                    d="M4.01039 13.9597C3.41001 17.684 3.76358 22.1467 4.61629 25.813C5.32313 27.3552 5.8805 28.9616 6.28074 30.6102C7.52327 33.9562 8.94111 37.7086 11.7764 40.389C15.1151 43.5454 18.043 43.4865 20.678 40.5242C22.825 38.1152 24.3868 36.7074 25.7066 34.828C27.2993 32.5649 27.5253 30.1597 29.0453 23.9445C29.2051 23.2895 29.3745 20.876 29.3764 20.1176C29.3928 9.94711 18.9609 4.07958 17.7328 3.46265C15.02 2.09858 4.13193 13.2033 4.01039 13.9597Z"
                                    transform={"translate(-1 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />

                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6569"
                                        d="M6.74085 2.31855C6.7309 2.58013 6.4389 7.92896 6.22322 14.0526C6.12091 16.9515 6.34599 20.8122 6.05732 24.185C5.49379 30.7295 1.5329 54.873 1.69217 55.0704C2.4901 50.9883 5.85378 28.3281 6.22267 24.185C6.50582 21.1014 6.36701 17.0356 6.54729 14.0526C6.80381 10.1055 7.26045 6.17399 7.91547 2.2732C7.52353 2.24107 7.12915 2.25629 6.74085 2.31855Z"
                                        transform="translate(9 45)"
                                        style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}

                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.317 -1.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.317 -1.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154145" x1="16.49" y1="18.7778" x2="14.3061" y2="31.2433" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154145" x1="16.4822" y1="28.1632" x2="16.4822" y2="23.5398" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path transform={"translate(-1 ,0)"} d="M28.982 32.782C27.2598 35.0367 25.224 36.7258 22.4236 39.6174C18.9882 43.171 15.17 43.2426 10.8153 39.4549C7.11798 36.2381 5.26963 31.7359 3.64844 27.7207C4.32044 30.2342 4.62507 32.8318 4.55283 35.4327C4.17144 50.4282 6.50187 65.1782 8.45827 79.9666C9.28196 86.1454 8.20996 92.26 7.63458 98.3809C7.39966 100.902 7.815 102.623 10.6083 103.462C14.0107 102.253 14.5137 99.0108 15.5981 96.2951C21.6744 80.987 23.222 64.7693 25.4851 48.7141C26.2487 43.2756 27.3275 37.9546 28.982 32.782Z" fill="#E3D1B4" />
                                            <path transform={"translate(-1 ,0)"} d="M0.980969 13.021C0.2438 17.5876 0.678147 23.0579 1.72338 27.5529C2.59015 29.4437 3.27367 31.4132 3.76456 33.4344C5.28856 37.5373 7.02595 42.1378 10.5027 45.4242C14.5959 49.294 18.1859 49.2214 21.4168 45.5903C24.0483 42.6352 25.9638 40.9097 27.5815 38.6066C29.5342 35.8325 29.8111 32.8838 31.6748 25.2634C31.8687 24.4595 32.0785 21.501 32.0794 20.5719C32.1 8.10155 19.3107 0.908909 17.8055 0.152146C14.4795 -1.52142 1.13015 12.0924 0.980969 13.021Z" fill="#EFEDE7" />
                                            <path transform={"translate(-1 ,0)"} d="M2.41877 13.148C1.75144 17.2876 2.14447 22.2462 3.09231 26.3201C3.87742 28.0343 4.49662 29.8198 4.94144 31.6521C6.32325 35.3709 7.89827 39.5408 11.0497 42.5199C14.7605 46.0289 18.014 45.9617 20.9433 42.6709C23.3294 39.9928 25.0648 38.4279 26.5316 36.3401C28.3012 33.8246 28.5522 31.1528 30.2423 24.2442C30.4192 23.5161 30.6075 20.8334 30.6093 19.9911C30.6287 8.68737 19.034 2.1673 17.67 1.48134C14.6552 -0.0341221 2.55457 12.3067 2.41877 13.148Z" fill="#F7F7F7" />
                                            <path transform={"translate(-1 ,0)"} d="M4.00585 13.6485C3.41457 17.3163 3.76278 21.7114 4.60257 25.3222C5.2987 26.841 5.84762 28.4231 6.2418 30.0466C7.46551 33.342 8.86186 37.0375 11.6542 39.6774C14.9423 42.7859 17.8258 42.7279 20.4209 39.8105C22.5354 37.4379 24.0735 36.0515 25.3733 34.2006C26.9419 31.9718 27.1644 29.603 28.6614 23.482C28.8188 22.8369 28.9856 20.46 28.9875 19.7131C29.0036 9.69671 18.7298 3.91808 17.5203 3.3105C14.8486 1.9671 4.12555 12.9036 4.00585 13.6485Z" fill="white" />
                                            <path transform={"translate(-1 ,0)"} d="M21.4142 15H11.5858C10.1577 15 9 16.1577 9 17.5858V27.4142C9 28.8423 10.1577 30 11.5858 30H21.4142C22.8423 30 24 28.8423 24 27.4142V17.5858C24 16.1577 22.8423 15 21.4142 15Z" fill="#C1C4C5" />
                                            {/* <path transform={"translate(-1 ,0)"} d="M21.4123 15.6465H11.5839C10.5128 15.6465 9.64453 16.5147 9.64453 17.5858V27.4143C9.64453 28.4853 10.5128 29.3536 11.5839 29.3536H21.4123C22.4834 29.3536 23.3517 28.4853 23.3517 27.4143V17.5858C23.3517 16.5147 22.4834 15.6465 21.4123 15.6465Z" stroke="#C1C4C5" /> */}
                                            <path transform={"translate(-1 ,0)"} d="M15.3667 29.3284C15.1081 29.7964 17.5336 29.565 19.3527 29.5417C22.06 29.4848 23.605 30.0382 23.5791 23.7884C23.6494 21.5707 23.5807 19.3508 23.3735 17.1417C22.687 16.4746 23.5545 23.9112 21.7975 27.1822C20.14 29.5443 15.8102 28.8268 15.3667 29.3284Z" fill="#B0B1B1" />
                                            <path transform={"translate(-1 ,0)"} d="M18.818 17.6064H13.6464C13.2894 17.6064 13 17.8959 13 18.2529V19.5458C13 19.9028 13.2894 20.1922 13.6464 20.1922H18.818C19.175 20.1922 19.4644 19.9028 19.4644 19.5458V18.2529C19.4644 17.8959 19.175 17.6064 18.818 17.6064Z" fill="#9E9E9E" />
                                            <path transform={"translate(-1 ,0)"} d="M19.0758 17.6064H13.9043C13.5472 17.6064 13.2578 17.8959 13.2578 18.2529V19.5458C13.2578 19.9028 13.5472 20.1922 13.9043 20.1922H19.0758C19.4328 20.1922 19.7222 19.9028 19.7222 19.5458V18.2529C19.7222 17.8959 19.4328 17.6064 19.0758 17.6064Z" fill="url(#paint0_linear_4126_154145)" />
                                            <path transform={"translate(-1 ,0)"} d="M19.068 23.6797H13.8964C13.5394 23.6797 13.25 23.9691 13.25 24.3261V25.619C13.25 25.976 13.5394 26.2654 13.8964 26.2654H19.068C19.425 26.2654 19.7144 25.976 19.7144 25.619V24.3261C19.7144 23.9691 19.425 23.6797 19.068 23.6797Z" fill="#CBCCCC" />
                                            <path transform={"translate(-1 ,0)"} d="M19.068 23.4209H13.8964C13.5394 23.4209 13.25 23.7103 13.25 24.0673V25.3602C13.25 25.7172 13.5394 26.0067 13.8964 26.0067H19.068C19.425 26.0067 19.7144 25.7172 19.7144 25.3602V24.0673C19.7144 23.7103 19.425 23.4209 19.068 23.4209Z" fill="url(#paint1_linear_4126_154145)" />
                                            <path transform={"translate(-1 ,0)"} d="M0.613281 21.1035C8.13036 21.5699 14.4856 23.1735 31.854 21.2074" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path transform={"translate(-1 ,0)"} d="M0.613281 20.5742C7.60161 21.0844 16.0269 22.669 31.854 20.5903" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
