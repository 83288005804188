import { Box, Typography, Grid } from '@mui/material';
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS */
import { numberFormat, toBuddhistYear, checkPackage } from 'utils/app.utils'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { PACKAGE_ADDONS } from 'constants/package'
import { summerySub, valPayment } from 'app/slice/payment.slice'
import { useSelector } from 'react-redux'
import { PaymentInterface } from 'api/counter/payment/payment-interface'
import { useEffect, useState } from 'react';

type SummaryReceiptType = {
  treatments?: any
  initBalance?: any
  patient?: any
  date?: any
}

export default function SummaryReceipt(props: SummaryReceiptType) {
  const { t } = useTranslation()
  const valSummerySub = useSelector(summerySub)
  const objPayment: PaymentInterface = useSelector(valPayment)
  const [allItem, setAllItem] = useState<any>([])

  const priceOutStanding = props?.treatments?.outStandingPayment?.overdueTotal || 0

  useEffect(() => {
    // setAllItem(props.treatments.order?.orderTreatments)
    _.map(props.treatments.order?.orderTreatments, (item: any) => {
      allItem.push(item)
    })
    _.map(props.treatments.order?.orderProducts, (item: any) => {
      allItem.push(item)
    })
    // setAllItem((current: any) => [current, props.treatments.order?.orderProducts[0]])
  }, [])

  const renderListOrder = (treatment: any, index: any) => {
    const itemName: any = treatment?.operativeName || treatment?.itemName || '-'
    const price: any = treatment?.total ? (treatment?.total - (!_.isEmpty(props?.initBalance) ? Number(props?.initBalance[index] || 0) : 0)) : 0
    let paid: any = 0
    _.map(Object.values(valSummerySub), (valSub: any) => { paid += valSub[index]?.value || 0 })

    return <>
      <Grid container spacing={1} className={'my-0'}>
        <Grid item sm={6} sx={{ flex: 1 }} className={'overflow-hidden'}>
          <Typography className='pl-2' sx={{ fontWeight: 100 }}>{itemName}</Typography>
        </Grid>
        <Grid item sm={3} className={``}>
          <Typography className='text-right' sx={{ fontWeight: 100 }}>{numberFormat(price)} {t('CURRENCY_CHANGE.TH')}</Typography>
        </Grid>
        <Grid item sm={3} className={``} >
          <Typography className='text-right' sx={{ fontWeight: 100 }}>{numberFormat(paid)} {t('CURRENCY_CHANGE.TH')}</Typography>
        </Grid>
      </Grid>
    </>
  }

  return (
    <>
      <Box className='px-md-2 px-xl-3 mb-3'>
        <Box className='d-flex justify-content-between'>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{props.patient.branchCnNumber ? `${props.patient.branchCnNumber}` : ''} <span className='pl-3 pr-2'>{t('PAYMENT.FORM_PAYMENT.NAME_PATIENT')}</span>{props.patient.firstname} {props.patient.lastname}</Typography>
          {/* <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{toBuddhistYear(moment(props.patient.updatedAt), 'DD/MM/YYYY HH.mm')} {t('TIME_UNIT')}</Typography> */}
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{toBuddhistYear(moment(props.date), 'DD/MM/YYYY')}</Typography>
        </Box>
      </Box>
      <Box className='px-md-2 px-xl-3 mb-3'>
        <Grid container spacing={2} className={'my-0'}>
          <Grid item sm={6} sx={{ flex: 1 }} className={'overflow-hidden'}>
            <Typography sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.TABLE.TREATMENT')}</Typography>
          </Grid>
          <Grid item sm={3} className={``}>
            <Typography className='text-right' sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.TABLE.PRICE_LIST')}</Typography>
          </Grid>
          <Grid item sm={3} className={``} >
            <Typography className='text-right' sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.TABLE.TOTAL_PAY')}</Typography>
          </Grid>
        </Grid>
        {props?.treatments?.order?.orderTreatments ?
          _.map(allItem,
            (treatment: any, index: any) => renderListOrder(treatment, index))
          :
          _.map(props?.treatments?.summeryTreatments,
            (treatment: any, index: any) => renderListOrder(treatment, index))
        }
      </Box>
      <Box className='px-md-2 px-xl-3 pb-3 mb-3' sx={{ borderBottom: `2px solid ${colors.textLightGray}` }}>
        <Box className='d-flex align-items-center justify-content-between pt-1'>
          <Typography sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.SUM_TREATMENT_DISCOUNT')}</Typography>
          <Typography sx={{ fontWeight: 500 }}>{numberFormat(objPayment.discountTreatmentRight)} {t('CURRENCY_CHANGE.TH')}</Typography>
        </Box>
        {checkPackage(PACKAGE_ADDONS.ADDON_ADVANCE) && (
          <Box className='d-flex align-items-center justify-content-between pt-1'>
            <Typography sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.SUM_ADVANCE_BALANCE')}</Typography>
            <Typography sx={{ fontWeight: 500 }}>{numberFormat(objPayment.advance)} {t('CURRENCY_CHANGE.TH')}</Typography>
          </Box>
        )}
        <Box className='d-flex align-items-center justify-content-between pt-1'>
          <Typography sx={{ fontWeight: 600 }}>{t('PAYMENT.FORM_PAYMENT.SUM_NET_BALANCE')}</Typography>
          <Typography sx={{ fontWeight: 600 }}>{numberFormat(objPayment.subTotal + priceOutStanding)} {t('CURRENCY_CHANGE.TH')}</Typography>
        </Box>
      </Box>
      <Box className='px-md-2 px-xl-3 mb-3'>
        <Box className='d-flex align-items-center justify-content-between pt-1'>
          <Typography sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.SUM_NET_INCOME')}</Typography>
          <Typography sx={{ fontWeight: 500 }}>{numberFormat(objPayment.amountReceipt)} {t('CURRENCY_CHANGE.TH')}</Typography>
        </Box>
        <Box className='d-flex align-items-center justify-content-between pt-1'>
          <Typography sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.SUM_NET_CHANGE')}</Typography>
          <Typography sx={{ fontWeight: 500 }}>{numberFormat(objPayment.amountChange)} {t('CURRENCY_CHANGE.TH')}</Typography>
        </Box>
        <Box className='d-flex align-items-center justify-content-between pt-1'>
          <Typography sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.SUM_NET_OUT_STANDING')}</Typography>
          <Typography sx={{ fontWeight: 500 }}>{numberFormat(objPayment.overdue + priceOutStanding)} {t('CURRENCY_CHANGE.TH')}</Typography>
        </Box>
      </Box>
    </>
  )
}
