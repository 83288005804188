import { useState, useEffect, useCallback } from 'react'
import { Card } from 'react-bootstrap'
import { Link, useLocation, useHistory } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { TableCell, TableRow, IconButton, Button, Grid, Typography, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'
import HeaderLabel from 'component/CardCustom/HeaderLabel'

/** STYLE */
import { DivContainer, CustomTable } from 'features/warehouses/import-products/style'
/** API */
import { routeName } from 'routes/routes-name'
import BranchApi from 'api/setting/branch-service-points/branch.api'
import ImportInventoryApi, { LastLotNumberProps } from 'api/warehouses/import-inventory.api'

/** CONSTANT */
import { colors } from 'constants/theme'
import { icons } from 'constants/images'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import TableCustom from 'component/Table/TableCustom'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications';
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputRadio from 'component/Input/InputRadio'
import { dateToView } from 'utils/date.utils'
import { getClinicInfo } from 'utils/app.utils'

export interface InventoryItemsInterface {
  item: [{
    type: string,
    name: string,
    value: any
  }]
  group: string
  itemId: number
  expireDate: string
  qty: number
  productUnitName: string
  lotPrice: number
  priceTotal: number
  lotNo: string
  typeId: number
}

export interface FormValues {
  subject: string
  branchId: number
  date: string
  total: number
  inventoryItems: InventoryItemsInterface[]
  lotNo: string
}

enum eRadioGroup {
  ALL = "ALL",
  TYPE = "TYPE",
  SELF = "SELF",
}

export default function ImportProductForm() {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<FormValues>();
  const [radioGroup, setRadioGroup] = useState('SELF')
  const [checkedAllProductTypes, setCheckedAllProductTypes]: any = useState({ 'MEDICINE': false, 'PRODUCT': false })
  const [checkedProductTypes, setCheckedProductTypes]: any = useState({ MEDICINE: [], PRODUCT: [] })
  const [rowProduct, setRowProduct]: any = useState<InventoryItemsInterface>()

  const [inputAutoId, setInputAutoId] = useState<boolean>(false)

  /** CALL API */
  const [branches, setBranches]: any = useState([])
  const [productTypes, setProductTypes]: any = useState([])
  const [tempProductTypes, setTempProductTypes]: any = useState([])
  const [products, setProducts]: any = useState([])
  const [productsAll, setProductsAll] = useState<any>([])
  const [productsNumberAll, setProductsNumberAll] = useState<any>([])

  /** ERROR MESSAGE */
  const initStateErrorMessage = {
    EMPTY_NAME: '',
    EMPTY_BRANCH: '',
    EMPTY_DATE: ''
  }
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);

  const branchInfo = getClinicInfo()

  useEffect(() => {
    if (branchInfo?.branchId) {
      setInputs((values: any) => ({ ...values, branchId: branchInfo.branchId }))
    }
  }, [])

  const handleGenAutoId = (itemNewLotNo?: any, lotNoOnly?: { index: number, id: number, group: "PRODUCT" | "MEDICINE" }, productAll?: any, numberCurrent?: any) => {

    const handleDateId = () => {
      const newDate = new Date()
      const [day, month, year]: string[] = dateToView(newDate).split('/')
      const newYear = year.slice(2, 4)
      return `${day}${month}${newYear}`
    }

    const handleCodeItem = (id: number) => {
      const product = _.find(productAll || products, (x: any) => x.productId === id)
      return product?.productCode || ''
    }

    const handleLotNumber = (item: any) => {
      const lotCurrent = numberCurrent || productsNumberAll

      if (lotCurrent?.length) {
        let number = `01`
        for (const index in lotCurrent) {
          const data = lotCurrent[index]
          if (data.group === item.group && (data?.itemId || data?.productId) === (item?.id || item?.itemId || item?.productId)) {
            number = `${(Number(data.lotNumber) + 1).toString().padStart(2, '0')}`
            break
          }
        }
        return number
      } else {
        return `01`
      }
    }

    if (lotNoOnly?.id) {
      return inputAutoId ? `${handleLotNumber(lotNoOnly)}${handleDateId()}${handleCodeItem(lotNoOnly.id)}` : ''
    }

    const data = itemNewLotNo || inputs?.inventoryItems
    if (data && inputAutoId) {
      const newData = data.map((item: InventoryItemsInterface, index: number) => {
        return { ...item, lotNo: `${handleLotNumber(item)}${handleDateId()}${handleCodeItem(item.itemId)}` }
      })
      return itemNewLotNo && newData || setInputs((values: any) => ({ ...values, inventoryItems: newData }))
    } else {
      const newData = data?.map((item: InventoryItemsInterface, index: number) => {
        return { ...item, lotNo: `` }
      }) || []
      return itemNewLotNo && newData || setInputs((values: any) => ({ ...values, inventoryItems: newData }))
    }
  }

  const handleLoadLotNumber = async (status: "PRODUCT" | "MEDICINE", item: string[]) => {

    const newData: LastLotNumberProps = {
      branchId: inputs?.branchId || 0,
      item: item.toString(),
      group: status
    }

    const res = await ImportInventoryApi.lastLotNumber(newData)

    return res?.data || []
  }

  const handleItemLotNumber = async (item?: any) => {
    const newItemsLot = item || inputs?.inventoryItems || []
    const itemProduct: string[] = []
    const itemMedicine: string[] = []
    if (newItemsLot?.length && inputs?.branchId && inputAutoId) {
      newItemsLot.forEach((item: any) => {
        if (item.group === "PRODUCT") {
          itemProduct.push(item?.itemId || item?.productId)
        }
        if (item.group === "MEDICINE") {
          itemMedicine.push(item?.itemId || item?.productId)
        }

      })
      const newItemProduct = await handleLoadLotNumber("PRODUCT", itemProduct)
      const newItemMedicine = await handleLoadLotNumber("MEDICINE", itemMedicine)
      setProductsNumberAll([...newItemProduct, ...newItemMedicine])
      return [...newItemProduct, ...newItemMedicine]
    }
    return []
  }

  useEffect(() => {
    if (inputAutoId) {
      reRenderBranchLotNumber()
    } else {
      handleGenAutoId()
    }
  }, [inputAutoId])

  const reRenderBranchLotNumber = async () => {
    const numberCurrent = await handleItemLotNumber()
    handleGenAutoId(undefined, undefined, undefined, numberCurrent)
  }

  useEffect(() => {
    if (inputAutoId) {
      reRenderBranchLotNumber()
    }
  }, [inputs?.branchId])

  const loadBranch = useCallback(async () => {
    const branchApi = await BranchApi.findAll({})
    const tempBranches: any[] = []
    if (branchApi.status === 200) {
      _.map(branchApi.data, (branch: any) => {
        tempBranches.push({ branchCode: branch.branchCode, branchId: branch.branchId, branchNumber: branch.branchNumber, branchName: branch.branchName, branchNameEn: branch.branchNameEn })
      })

      setBranches(tempBranches)
    }
  }, [])

  const loadProductTypes = useCallback(async () => {
    const respProductTypes = await ImportInventoryApi.findAllProductsType({})
    if (respProductTypes.status === 200) {
      setTempProductTypes(respProductTypes.data)
    }
  }, [])

  const loadProducts = useCallback(async () => {
    let condition: any = { branchId: inputs?.branchId }
    let productTypeIds: any = []

    if (checkedProductTypes['MEDICINE']) productTypeIds = [...productTypeIds, ...checkedProductTypes['MEDICINE']]
    if (checkedProductTypes['PRODUCT']) productTypeIds = [...productTypeIds, ...checkedProductTypes['PRODUCT']]
    if (radioGroup === eRadioGroup.TYPE) condition = { ...condition, productTypeId: productTypeIds.toString() }

    const resProducts = await ImportInventoryApi.findAllProducts(condition)
    const tempProducts: any[] = []
    const optionProducts: any[] = []
    let itemsAlreadySelect: InventoryItemsInterface[] = _.merge([], inputs?.inventoryItems)
    itemsAlreadySelect = _.filter(itemsAlreadySelect, (item: InventoryItemsInterface) => _.includes(productTypeIds, item.typeId))
    const items: InventoryItemsInterface[] = []

    if (resProducts.status === 200) {
      _.map(resProducts.data, (productsData: any) => {
        tempProducts.push(productsData)
        optionProducts.push({ type: 'text', name: productsData.productName, value: productsData.productId })
        if (radioGroup === eRadioGroup.TYPE && productTypeIds.length && productTypeIds.indexOf(productsData.productTypeId) !== -1 && !_.some(itemsAlreadySelect, { typeId: productsData?.productTypeId || productsData?.productType?.productTypeId || 0 })) {
          items.push({
            item: [{ type: 'text', name: productsData.productName, value: productsData.productId }],
            group: productsData.group,
            itemId: productsData.productId,
            expireDate: '',
            qty: inputs?.total || 0,
            productUnitName: productsData?.productUnit?.productUnitName || '-',
            lotPrice: productsData.costPrice,
            priceTotal: productsData.costPrice * (inputs?.total || 0),
            lotNo: productsData?.lotNo,
            typeId: productsData?.productTypeId || productsData?.productType?.productTypeId || 0
          })
        }

        if (radioGroup === eRadioGroup.ALL) {
          items.push({
            item: [{ type: 'text', name: productsData.productName, value: productsData.productId }],
            group: productsData.group,
            itemId: productsData.productId,
            expireDate: '',
            qty: inputs?.total || 0,
            productUnitName: productsData?.productUnit?.productUnitName || '-',
            lotPrice: productsData.costPrice,
            priceTotal: productsData.costPrice * (inputs?.total || 0),
            lotNo: productsData?.lotNo,
            typeId: productsData?.productTypeId || productsData?.productType?.productTypeId || 0
          })
        }
      })
      setProductsAll(optionProducts)
      setProducts(tempProducts)
    }

    const newItemsLot = [...itemsAlreadySelect, ...items]
    let numberCurrent: any[] = []
    if (newItemsLot?.length) {
      numberCurrent = await handleItemLotNumber(newItemsLot)
    }

    const newItem = handleGenAutoId([...itemsAlreadySelect, ...items], undefined, tempProducts, numberCurrent)
    setInputs((values: any) => ({ ...values, inventoryItems: newItem }))
  }, [checkedProductTypes, radioGroup])

  useEffect(() => {
    loadBranch()
    loadProductTypes()
  }, [loadBranch, loadProductTypes,])

  useEffect(() => {
    loadProducts()
  }, [loadProducts])

  useEffect(() => {
    const importProd: any = location.state
    if (!_.isEmpty(importProd)) {
      const itemId = importProd?.itemId
      const itemName = importProd?.name
      const salePrice = importProd?.salePrice
      const group = importProd?.group
      const unit = importProd?.unit

      const onLoad = async () => {
        await handleAddRow()
        handleChangeProduct({ type: 'text', name: itemName, value: itemId }, { salePrice: salePrice, group: group, unit: unit })
      }
      onLoad()
    }
  }, [location])

  const selectProductType = () => {
    const typeGroupAll: any[] = []
    const typeGroupMedicines: any[] = []
    const typeGroupProducts: any[] = []
    setProductTypes([])
    _.map(tempProductTypes, (productType: any) => {
      const detail = {
        GROUP_TEXT: productType.GROUP_TEXT,
        group: productType.group,
        productTypeId: productType.productTypeId,
        productTypeName: productType.productTypeName,
        productTypeNameEn: productType.productTypeNameEn,
        products: [],
        checked: false
      }
      if (productType.group === 'ALL') typeGroupAll.push(detail)
      if (productType.group === 'MEDICINE') typeGroupMedicines.push(detail)
      if (productType.group === 'PRODUCT') typeGroupProducts.push(detail)
    })
    if (typeGroupAll.length) setProductTypes((values: any) => [...values, { GROUP_TEXT: typeGroupAll[0].GROUP_TEXT, GROUP: typeGroupAll[0].group, productTypes: typeGroupAll }])
    if (typeGroupMedicines.length) setProductTypes((values: any) => [...values, { GROUP_TEXT: typeGroupMedicines[0].GROUP_TEXT, GROUP: typeGroupMedicines[0].group, productTypes: typeGroupMedicines }])
    if (typeGroupProducts.length) setProductTypes((values: any) => [...values, { GROUP_TEXT: typeGroupProducts[0].GROUP_TEXT, GROUP: typeGroupProducts[0].group, productTypes: typeGroupProducts }])
  }

  const handleChange = (event: any) => {
    setErrorMessage(initStateErrorMessage)
    const name = event.target.name
    const value = event.target.value
    setInputs((values: any) => ({ ...values, [name]: value }))
  }

  const handleAddRow = async () => {
    let condition: any = { branchId: inputs?.branchId }
    let productTypeIds: any = []

    if (checkedProductTypes['MEDICINE']) productTypeIds = [...productTypeIds, ...checkedProductTypes['MEDICINE']]
    if (checkedProductTypes['PRODUCT']) productTypeIds = [...productTypeIds, ...checkedProductTypes['PRODUCT']]
    if (radioGroup === eRadioGroup.TYPE) condition = { ...condition, productTypeId: productTypeIds.toString() }

    const resProducts = await ImportInventoryApi.findAllProducts(condition)
    const optionProducts: any[] = []
    const tempProducts: any[] = []
    if (resProducts.status === 200) {
      _.map(resProducts.data, (productsData: any) => {
        tempProducts.push(productsData)
        optionProducts.push({ type: 'text', name: productsData.productName, value: productsData.productId })
      })
    }

    if (!optionProducts.length) return notiError(t('IMPORT_PRODUCT.MESSAGE.ERROR_NOTFIND'))

    setProducts(tempProducts)
    setRowProduct({ ...rowProduct, item: optionProducts, group: '', itemId: null, expireDate: '', qty: inputs?.total || 0, productUnitName: '-', lotPrice: 0, priceTotal: 0, lotNo: inputs?.lotNo || '' })
  }

  const handleRemoveRow = (i: number) => {
    const inventoryItems: any = []
    const productSelecteds: any = []

    if (inputs?.inventoryItems && inputs?.inventoryItems.length > 1)
      _.map(inputs?.inventoryItems, (inventoryItem: any, index: number) => {
        if (index !== i) {
          inventoryItems.push(inventoryItem)
          productSelecteds.push(inventoryItem.itemId)
        }
      })
    setInputs((values: any) => ({ ...values, inventoryItems: inventoryItems }))
  }

  const handleChangeProduct = async (pr: any, item?: any, edit?: number) => {
    const product = _.find(products, (x: any) => x.productId === pr.value)

    const newData = {
      item: [pr],
      group: product?.group || item?.group || '',
      itemId: product?.productId || pr.value, expireDate: '',
      qty: inputs?.total || 0,
      productUnitName: product?.productUnit?.productUnitName || product?.productUnit?.productUnitNameEn || item?.unit || '-',
      lotPrice: product?.salePrice || item?.salePrice || 0,
      priceTotal: ((inputs?.total || 0) * (product?.salePrice || item?.salePrice || 0)),
      lotNo: rowProduct?.lotNo || '',
      typeId: product?.productTypeId || product?.productType?.productTypeId || 0
    }
    const numberCurrent = await handleItemLotNumber([newData, ...(inputs?.inventoryItems || [])])
    if (!edit && edit === undefined) {
      const newItem = handleGenAutoId([newData, ...(inputs?.inventoryItems || [])], undefined, undefined, numberCurrent)
      setInputs((values: any) => ({
        ...values,
        inventoryItems: newItem
      }))
      setRowProduct('')
    } else {
      if (inputs?.inventoryItems) {
        const newInventory: any[] = inputs.inventoryItems
        newInventory[edit] = { ...newData, lotNo: handleGenAutoId(undefined, { index: edit, id: newData.itemId, group: newData.group }, undefined, numberCurrent) }
        setInputs((values: any) => ({ ...values, inventoryItems: newInventory }))
      }
    }
  }

  const submit = () => {

    if (!inputs?.subject || !inputs?.branchId || !inputs?.date) {
      return setErrorMessage({
        EMPTY_NAME: !inputs?.subject ? t('IMPORT_PRODUCT.MESSAGE.NONE_DOCUMENT_NAME') : '',
        EMPTY_BRANCH: !inputs?.branchId ? t('IMPORT_PRODUCT.MESSAGE.NONE_BRANCH') : '',
        EMPTY_DATE: !inputs?.date ? t('IMPORT_PRODUCT.MESSAGE.NONE_DATE') : ''
      })
    }

    const inventoryItems: any = []
    if (inputs?.inventoryItems)
      for (const ele of inputs?.inventoryItems ?? {}) {
        // if (_.some(inventoryItems, { itemId: ele.itemId, lotNo: ele.lotNo })) return notiError(`มีรายการนำเข้า ${ele.item[0].name} ซ้ำ`)
        // if (!ele.lotNo) return notiError(`กรุณากรอกรหัสล็อต ${ele.item[0].name}`)
        // if (!ele.qty) return notiError(`${t('IMPORT_PRODUCT.MESSAGE.NONE_PRODUCTLIST')} ${ele.item[0].name}`)
        if (!ele.lotPrice && ele.lotPrice !== 0) return notiError(`${t('IMPORT_PRODUCT.MESSAGE.NONE_PRODUCTPRICE')} ${ele.item[0].name}`)
        if (ele.itemId) {
          inventoryItems.push({
            expireDate: ele.expireDate,
            group: ele.group,
            itemId: ele.itemId,
            lotPrice: ele.lotPrice,
            qty: ele.qty,
            lotNo: ele.lotNo
          })
        }
      }

    if (inventoryItems.length === 0) return notiError(t("IMPORT_PRODUCT.MESSAGE.ERROR_CHOOSE_PRODUCT"))

    setLoading(true)
    ImportInventoryApi.create({ ...inputs, total: inputs?.total || 0, inventoryItems: inventoryItems })
      .then((res: any) => {
        setLoading(false)
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('IMPORT_PRODUCT.MESSAGE.IMPORT_PRODUCT_SUCCESS'))
          history.push(routeName.importProduct)
        }
        else notiError(t('IMPORT_PRODUCT.MESSAGE.ERROR'))
      })
      .catch((e) => {
        setLoading(false)
        handleSumbitError(e)

      })
  }

  const handleSumbitError = (error: any) => {
    const dataError = error?.response?.data || []
    const checkDupProduct = (id: number) => {
      for (const index in productsAll) {
        const dataPro = productsAll[index]
        if (dataPro.value === id) {
          notiError(`${t('IMPORT_PRODUCT.MESSAGE.ISDUP_LOTCODE', { productName: dataPro.name })}`)
          break;
        }
      }

    }

    if (dataError?.length) {
      dataError.forEach((item: any) => {
        checkDupProduct(item.itemId)
      })
    } else {
      notiError(t('IMPORT_PRODUCT.MESSAGE.ERROR'))
    }
  }

  const handleDupInventory = (invenName: string) => {
    let check = false
    if (inputs?.inventoryItems) {
      for (const index in inputs?.inventoryItems) {
        const data = inputs?.inventoryItems[index]
        if (data.item[0]?.name === invenName) {
          check = true
          break;
        }
      }
      return check
    }
    return check

  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '60px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.LOT_NO') },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.PRODUCT_LIST') },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.EXPIRATION_DATE'), width: '200px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.COUNT'), align: 'center', width: '90px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.UNIT'), align: 'left', width: '135px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.PRICE'), align: 'right', width: '155px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.TOTAL'), align: 'right', width: '155px' },
    { id: 'message', disablePadding: false, label: 'Action', align: 'center', width: '85px' },
  ]

  const addNewRow = () => {
    return (
      <TableRow className="row-add-new-row">
        <TableCell
          align="left"
          scope="row"
          colSpan={_.size(headCells)}
          className="p-2"
          onClick={() => handleAddRow()}>
          <Button variant="text" className="btn-add-new-row d-flex align-items-baseline">
            <span className={'mr-1'}>+</span> {t('IMPORT_PRODUCT.FORM.ADD_NEW_PRODUCT')}
          </Button>
        </TableCell>
      </TableRow >
    )
  }

  return (
    <DivContainer className="container-card overflow-hidden">
      <div className='d-flex flex-column h-100 overflow-auto'>
        <HeaderLabel
          text={t('IMPORT_PRODUCT.FORM.TITLE')}
          goBack={routeName.importProduct}
          onSubmit={submit}
          disabledSend={loading}
          menuNavigation={
            <>
              <Link to={routeName.importProduct} className={'m-0 p-0 link-nav w-100'}>
                {t('IMPORT_PRODUCT.FORM.HEADER')}
              </Link>
              {` > ${t('IMPORT_PRODUCT.FORM.TITLE')}`}
            </>
          }
        />

        <Card className="border shadow-sm mx-4 mb-4 card">
          <Card.Body className="h-100">
            <Grid container spacing={2}>
              <Grid item sm={12} className='w-100'>
                <Grid container spacing={2}>
                  <Grid item sm={4} className='w-100'>
                    <InputTextField
                      hookValue={true}
                      required={true}
                      inputProps={{ maxLength: 255 }}
                      label={t('IMPORT_PRODUCT.FORM.DOCUMENT_NAME')}
                      key="subject"
                      name="subject"
                      onchange={handleChange}
                      value={inputs?.subject || ''}
                      helperText={errorMessage.EMPTY_NAME || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} lg={3} className='w-100'>
                <BasicSelect
                  required
                  onchange={(event) => {
                    handleChange(event)
                  }}
                  name="branchId"
                  label={t('IMPORT_PRODUCT.FORM.BRANCH')}
                  selectId="sl-branch-id"
                  defaultValue={branchInfo?.branchId || inputs?.branchId}
                  labelId="lb-branch-id"
                  options={branches.map((i: any) => {
                    return (
                      <MenuItem key={i.branchId} value={i.branchId}>
                        {i.branchName}
                      </MenuItem>
                    )
                  })}
                  helperText={errorMessage.EMPTY_BRANCH || ''}
                  value={inputs?.branchId}
                />
              </Grid>
              <Grid item sm={6} lg={3} className='w-100'>
                <InputSecondNewDatePicker
                  key={'date'}
                  required
                  label={t('IMPORT_PRODUCT.FORM.DOCUMENT_DATE')}
                  value={inputs?.date || ''}
                  onchange={(date: any) => {
                    setInputs((values: any) => ({ ...values, date: moment(date).format('YYYY-MM-DD') }))
                    setErrorMessage(initStateErrorMessage)
                  }}
                  helperText={errorMessage.EMPTY_DATE || ''}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='mt-0'>
              <Grid item md={6} lg={5} className='w-100'>
                <Box className='d-flex flex-column flex-sm-row'>
                  <Typography className='pr-3 pt-sm-2' sx={{ flex: 'none' }}>{t('IMPORT_PRODUCT.FORM.SELECT_PRODUCT')}</Typography>
                  <RadioGroup
                    row
                    key={`radio-group`}
                    aria-label="pointOfProduct"
                    onChange={(event) => {
                      setRadioGroup(event.target.value)
                      setInputs((values: any) => ({ ...values, branchId: inputs?.branchId, inventoryItems: [] }))
                      setCheckedAllProductTypes({ 'MEDICINE': false, 'PRODUCT': false })
                      setCheckedProductTypes({ MEDICINE: [], PRODUCT: [] })
                      selectProductType()
                      setRowProduct('')
                    }}
                    value={radioGroup}
                    defaultValue={radioGroup}
                    className="flex-column"
                    name="row-radio-buttons-group"
                    style={{ justifyContent: 'flex-start' }}>
                    <FormControlLabel key={`form-control-label-all`} className="ml-0"
                      checked={radioGroup === eRadioGroup.ALL}
                      value={eRadioGroup.ALL} control={<InputRadio key={`radio-all`} />} label={t('IMPORT_PRODUCT.FORM.PRODUCT_ALL')} />
                    <FormControlLabel key={`form-control-label-type`} className="ml-0"
                      checked={radioGroup === eRadioGroup.TYPE}
                      value={eRadioGroup.TYPE} control={<InputRadio key={`radio-type`} />} label={t('IMPORT_PRODUCT.FORM.PRODUCT_TYPE')} />
                    <FormControlLabel key={`form-control-label-self`} className="ml-0"
                      checked={radioGroup === eRadioGroup.SELF}
                      value={eRadioGroup.SELF} control={<InputRadio key={`radio-self`} />} label={t('IMPORT_PRODUCT.FORM.PRODUCT_YOURSELF')} />
                  </RadioGroup>
                </Box>
              </Grid>
              <Grid item md={6} lg={7} className='w-100'>
                <Box className='d-flex align-items-center'>
                  <Typography className='pr-3' sx={{ flex: 'none', width: '140px' }}>{t('IMPORT_PRODUCT.FORM.PRODUCT_DEFAULT')}</Typography>
                  <Box sx={{ maxWidth: '120px' }}>
                    <InputTextField
                      hookValue={true}
                      key="total"
                      name="total"
                      inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                      label={''}
                      onchange={(e) => {
                        const valTotal = e.target.value === '' ? 0 : String(e.target.value).replace(/[^0-9.]/g, '')
                        const preInventoryItems = _.merge([], inputs?.inventoryItems)
                        _.forEach(preInventoryItems, (item) => {
                          item.qty = Number(valTotal || 0)
                          item.priceTotal = Number(valTotal || 0) * item?.lotPrice
                        })
                        setInputs((values: any) => ({ ...values, total: Number(valTotal || 0), inventoryItems: preInventoryItems }))
                        if (!_.isEmpty(rowProduct)) setRowProduct({ ...rowProduct, qty: Number(valTotal || 0) })
                      }}
                      value={inputs?.total || 0}
                      hideControl />
                  </Box>
                </Box>
                <Box className='d-flex align-items-center mt-3'>
                  {/* <Typography className='pr-3' sx={{ flex: 'none', width: '140px' }}>{t('รหัสล็อตตั้งต้น')}</Typography>
                  <Box sx={{ maxWidth: '120px' }}>
                    <InputTextField
                      hookValue={true}
                      key="lotNo"
                      name="lotNo"
                      label={''}
                      onchange={(e) => {
                        const valLot = e.target.value
                        const preInventoryItems = _.merge([], inputs?.inventoryItems)
                        _.forEach(preInventoryItems, (item) => item.lotNo = valLot)
                        setInputs((values: any) => ({ ...values, lotNo: valLot, inventoryItems: preInventoryItems }))
                        if (!_.isEmpty(rowProduct)) setRowProduct({ ...rowProduct, lotNo: valLot })
                      }}
                      value={inputs?.lotNo || ''}
                      hideControl
                      inputProps={{ maxLength: 20 }} />
                  </Box> */}
                  <InputCheckbox
                    label={t('IMPORT_PRODUCT.FORM.CREACT_LOT_NO')}
                    checked={inputAutoId}
                    onChange={(e) => { setInputAutoId(!inputAutoId) }}
                    disabled={!inputs?.branchId}
                  />
                </Box>
              </Grid>
            </Grid>

            <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

            <Box className='mb-3'>
              <div className="ant-check-product" style={{ display: radioGroup === eRadioGroup.TYPE ? '' : 'none' }}>
                {productTypes.map((group: any, iGroup: number) => {
                  return (
                    group && (
                      <>
                        <Box className='px-3 py-1 mt-3' sx={{ backgroundColor: colors.themeSecondColor10 }}>
                          <InputCheckbox
                            label={group.GROUP_TEXT}
                            checked={checkedAllProductTypes[group.GROUP]}
                            onChange={(e) => {
                              checkedAllProductTypes[group.GROUP] = e.target.checked
                              setCheckedAllProductTypes({ ...checkedAllProductTypes })
                              if (e.target.checked) {
                                group.productTypes &&
                                  _.map(group.productTypes, (productType: any, iType: any) => {
                                    if (!checkedProductTypes[group.GROUP]) checkedProductTypes[group.GROUP] = []
                                    checkedProductTypes[group.GROUP].push(productType.productTypeId)
                                  })

                              } else checkedProductTypes[group.GROUP] = []
                              setCheckedProductTypes({ ...checkedProductTypes })
                              handleGenAutoId()
                            }}
                          />
                        </Box>
                        <Grid key={`row-group-${iGroup}`} container spacing={2} className="mt-0 px-3">
                          {group.productTypes &&
                            group.productTypes.map((productType: any, iType: any) => {
                              return (
                                <Grid key={`${iGroup}-${iType}`} item xs={6} md={3} lg={2} className='w-100'>
                                  <Box>
                                    <InputCheckbox
                                      label={productType.productTypeName}
                                      checked={checkedProductTypes[productType.group].indexOf(parseInt(productType.productTypeId)) > -1 || false}
                                      onChange={(e) => {
                                        if (!checkedProductTypes[productType.group]) checkedProductTypes[productType.group] = []
                                        if (e.target.checked) checkedProductTypes[productType.group].push(productType.productTypeId)
                                        else {
                                          let newProductTypes = []
                                          newProductTypes = _.filter(checkedProductTypes[productType.group], (typeId: any, index: number) => {
                                            return typeId !== productType.productTypeId
                                          })
                                          checkedProductTypes[productType.group] = newProductTypes
                                        }
                                        setCheckedProductTypes({ ...checkedProductTypes })

                                        if (checkedProductTypes[productType.group] && checkedProductTypes[productType.group].length === group.productTypes.length)
                                          setCheckedAllProductTypes({ ...checkedAllProductTypes, [group.GROUP]: true })
                                        else
                                          setCheckedAllProductTypes({ ...checkedAllProductTypes, [group.GROUP]: false })

                                        handleGenAutoId()
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              )
                            })}
                        </Grid>
                      </>
                    )
                  )
                })}
              </div>
            </Box>

            <CustomTable
              sx={{
                '.MuiTableContainer-root': {
                  minHeight: 'unset !important',
                  '.MuiTable-root': {
                    '.MuiTableHead-root': {
                      '.MuiTableCell-head': {
                        borderWidth: '1px 0 1px 0 !important'
                      }
                    }
                  }
                }
              }}>
              <TableCustom
                hidePagination
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => { return }}
                setPageLimit={() => { return }}
                setPage={() => { return }}
                headCells={headCells}
                rowsData={
                  [
                    addNewRow(),
                    rowProduct && (
                      <TableRow key={(inputs?.inventoryItems?.length || 0) + 1}>
                        <TableCell className='text-center align-middle py-2'>#</TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            disabled
                            hookValue={true}
                            key={`lotNo-${(inputs?.inventoryItems?.length || 0) + 1}`}
                            inputProps={{ maxLength: 20 }}
                            label={''}
                            onchange={(e) => {
                              setRowProduct({ ...rowProduct, lotNo: e.target.value })
                              setErrorMessage(initStateErrorMessage)
                            }}
                            value={rowProduct.lotNo}
                            hideControl
                          />
                        </TableCell>
                        <TableCell className='py-2'>
                          <AutocompleteSelect
                            labelId=""
                            noOptionsText={t('IMPORT_PRODUCT.NOT_FIND')}
                            options={rowProduct.item}
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props, option: any) => (
                              <Box key={option.value} component="li" {...props} aria-disabled={handleDupInventory(option.name)}>
                                {option.name}
                              </Box>
                            )}
                            onchange={(e, value: any) => {
                              if (value) handleChangeProduct(value)
                              setErrorMessage(initStateErrorMessage)
                            }}
                            disabled={rowProduct.itemId ? true : false}
                            disableClearable
                            required
                            value={_.find(rowProduct.item, { value: rowProduct.itemId })} />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputSecondNewDatePicker
                            disabled
                            key={`expire-date-${(inputs?.inventoryItems?.length || 0) + 1}`}
                            value={rowProduct.expireDate}
                            onchange={(date: any) => {
                              return
                            }}
                          />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            hookValue={true}
                            required={true}
                            disabled={true}
                            type="number"
                            key={`qty-${(inputs?.inventoryItems?.length || 0) + 1}`}
                            inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                            label={''}
                            onchange={(e) => { return }}
                            value={rowProduct.qty}
                            inputTextAlign='center'
                            hideControl
                          />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            hookValue={false}
                            disabled={true}
                            key={`product-unit-${(inputs?.inventoryItems?.length || 0) + 1}`}
                            value={rowProduct.productUnitName}
                            inputTextAlign='left' />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            hookValue={true}
                            disabled={true}
                            type="number"
                            key={`lot-price-${(inputs?.inventoryItems?.length || 0) + 1}`}
                            inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={rowProduct.lotPrice}
                            name={`inventoryItems.${(inputs?.inventoryItems?.length || 0) + 1}.lotPrice`}
                            onchange={(e) => {
                              rowProduct.lotPrice = e.target.value
                              rowProduct.priceTotal = rowProduct.qty * (e.target.value || 0)
                              setErrorMessage(initStateErrorMessage)
                            }}
                            inputTextAlign='right'
                            hideControl
                          />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            hookValue={true}
                            disabled={true}
                            key={`price-total-${(inputs?.inventoryItems?.length || 0) + 1}`}
                            value={rowProduct.priceTotal}
                            inputTextAlign='right'
                            hideControl
                          />
                        </TableCell>
                        <TableCell className='text-center py-2'>
                          <IconButton aria-label="delete" onClick={() => setRowProduct('')} className="p-0" sx={{ backgroundColor: 'transparent !important' }}>
                            <img src={icons.btnTrash} alt="discard" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ),
                    inputs?.inventoryItems && inputs?.inventoryItems.map((item: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell className='text-center align-middle py-2'>{index + 1}</TableCell>
                        <TableCell className='text-center align-middle py-2'>
                          <InputTextField
                            disabled
                            hookValue={true}
                            key={`lotNo-${(inputs?.inventoryItems?.length || 0) + 1}`}
                            inputProps={{ maxLength: 20 }}
                            label={''}
                            onchange={(e) => {
                              item.lotNo = e.target.value
                              setInputs((values: any) => ({ ...values, inventoryItems: inputs?.inventoryItems ? [...inputs.inventoryItems] : [] }))
                              setErrorMessage(initStateErrorMessage)
                            }}
                            value={item.lotNo ? `${item.lotNo}` : ''}
                            hideControl
                          />
                        </TableCell>
                        <TableCell className='py-2'>
                          <AutocompleteSelect
                            labelId=""
                            noOptionsText={t('IMPORT_PRODUCT.NOT_FIND')}
                            options={productsAll}
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props, option: any) => {
                              return (
                                <Box key={option.value} component="li" {...props} aria-disabled={handleDupInventory(option.name)} >
                                  {option.name}
                                </Box>
                              )
                            }}
                            onchange={(e, value: any) => {
                              if (value) {
                                handleChangeProduct(value, null, index)
                              }
                              setErrorMessage(initStateErrorMessage)
                            }}
                            // disabled={item.itemId ? true : false}
                            disableClearable
                            required
                            value={_.find(item.item, { value: item.itemId })} />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputSecondNewDatePicker
                            key={`expire-date-${index}`}
                            value={item.expireDate}
                            onchange={(date: any) => {
                              const newDate = moment(date)?.format('YYYY-MM-DD').toString() || ''
                              item.expireDate = newDate
                              setInputs((values: any) => ({ ...values, inventoryItems: inputs?.inventoryItems ? [...inputs.inventoryItems] : [] }))
                            }}
                            allowClear
                            onClear={() => {
                              item.expireDate = ''
                              setInputs((values: any) => ({ ...values, inventoryItems: inputs?.inventoryItems ? [...inputs.inventoryItems] : [] }))
                            }}
                          />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            hookValue={true}
                            required={true}
                            key={`qty-${index}`}
                            inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                            label={''}
                            onchange={(e) => {
                              const valProductTotal = e.target.value === '' ? 0 : String(e.target.value).replace(/[^0-9.]/g, '')
                              item.qty = Number(valProductTotal || 0)
                              item.priceTotal = (Number(valProductTotal || 0) * item.lotPrice) || 0
                              setInputs((values: any) => ({ ...values, inventoryItems: inputs?.inventoryItems ? [...inputs.inventoryItems] : [] }))
                              setErrorMessage(initStateErrorMessage)
                            }}
                            value={item.qty}
                            inputTextAlign='center'
                            hideControl
                          />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            hookValue={false}
                            disabled={true}
                            key={`product-unit-${index}`}
                            value={item.productUnitName}
                            inputTextAlign='left' />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            hookValue={true}
                            disabled={true}
                            key={`lot-price-${index}`}
                            inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={item.lotPrice}
                            name={`inventoryItems.${index}.lotPrice`}
                            onchange={(e) => {
                              const valLotPrice = e.target.value === '' ? 0 : String(e.target.value).replace(/[^0-9.]/g, '')
                              item.lotPrice = Number(valLotPrice || 0)
                              item.priceTotal = (item.qty * Number(valLotPrice || 0)) || 0
                              setInputs((values: any) => ({ ...values, inventoryItems: inputs?.inventoryItems ? [...inputs.inventoryItems] : [] }))
                              setErrorMessage(initStateErrorMessage)
                            }}
                            inputTextAlign='right'
                            hideControl
                          />
                        </TableCell>
                        <TableCell className='py-2'>
                          <InputTextField
                            hookValue={true}
                            disabled={true}
                            key={`price-total-${index}`}
                            value={item.priceTotal}
                            inputTextAlign='right'
                            hideControl
                          />
                        </TableCell>
                        <TableCell className='text-center py-2'>
                          <IconButton aria-label="delete" onClick={() => handleRemoveRow(index)} className="p-0" sx={{ backgroundColor: 'transparent !important' }}>
                            <img src={icons.btnTrash} alt="discard" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ]
                }
                tableFixedWidth
                tableMinWidth={1200}
              />
            </CustomTable>
          </Card.Body>
        </Card>
      </div>
    </DivContainer >
  )
}
