import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import { routeName } from 'routes/routes-name';
import { useHistory } from 'react-router-dom';
import { Box, Button, MenuItem, makeStyles } from '@mui/material';
import moment from 'moment';
import 'moment/locale/th';
import _ from 'lodash';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch, toBuddhistYear } from 'utils/app.utils';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/**COMPONENT */
import AccessChanelList from './AccessChannelList';
import Loading from 'component/Loading';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'

/**STYLE */
import * as UseStyled from 'features/report/useStyled';
/** CONSTANTS */
import { colors } from 'constants/theme';
import InputSecondNewRangePicker, { PICKER_VIEWS, emptyRangePicker } from 'component/Input/InputSecondNewRangePicker';
import InputTextSearch from 'component/Input/InputTextSearch';
import ButtonCustom from 'component/Button/ButtonCustom';

type Doctors = {
    userId: number
    firstname: string
    lastname: string
    fullname: string
}

export interface DentistInterface {
    dentistId: number
    dentistFullname: string
}

export default function AccessChanel() {
    const { t } = useTranslation();

    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);

    const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const momentDate = () => momentNowRange

    const [branchId, setBranchId] = useState<any>(0);
    const [branches, setBranches] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');

    const [doctors, setDoctors] = useState<Doctors[]>([]);
    const [doctorId, setDoctorId] = useState<number>(0);

    const [search, setSearch] = useState<string>('');
    const [patientAccessChannel, setPatientAccessChannel] = useState<any[]>([]);
    const [patientAccessChannelExport, setPatientAccessChannelExport] = useState<any[]>([]);
    const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
    const [accessChannelPage, setAccessChannelPage] = useState<number>(1);
    const [accessChannelPageLimit, setAccessChannelPageLimit] = useState<number>(10);
    const [labSecondPage, setLabSecondPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [fetch, setFetch] = useState<boolean>(false);

    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'ACCESS_CHANNEL') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        ClinicApi.findAllDoctors()
            .then((resp) => setDoctors(resp.data))
            .catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    const fetchAccessChannelLogic = async (setDataFunction: any, exportCondition?: number) => {
        await verifyPermission();
        let condition: FindAllReportInterface = {
            page: accessChannelPage,
            pageLimit: accessChannelPageLimit,
            search: search,
            dateStart: rangeDate[0],
            dateEnd: rangeDate[1]
        };

        if (branchId) condition = { ...condition, branchId: branchId }
        if (exportCondition) {
            condition = { ...condition, export: exportCondition };
        }
        if (sortBy) condition = { ...condition, sortBy: sortBy };
        if (sortType) condition = { ...condition, sortType: sortType };

        return ReportApi.reportAccessChannel(condition)
            .then(({ headers, data }) => {
                setDataFunction(data);
                setRowCount(headers['x-total']);
            });
    };

    const fetchAccessChannelExport = useCallback(async () => {
        try {
            await fetchAccessChannelLogic(setPatientAccessChannelExport, 1);
        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
            setFetch(false);
        }
    }, [fetch]);

    const fetchAccessChannel = useCallback(async () => {
        try {
            await fetchAccessChannelLogic(setPatientAccessChannel);
        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
            setFetch(false);
        }
    }, [accessChannelPage, labSecondPage, accessChannelPageLimit, fetch]);

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.ACCESS_CHANNEL`)}`,
        sheetName: `${t(`REPORT.REPORT_NAME.ACCESS_CHANNEL`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setAccessChannelPageLimit(limit)
    const handleChangePage = (val: number) => setAccessChannelPage(val)

    useEffect(() => {
        fetchAccessChannel()
    }, [fetchAccessChannel])

    return (
        <>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.ACCESS_CHANNEL`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <Col sm={12} md={6} lg={5} xl={4} xxl={3} className={'pt-2'}>
                                <InputTextSearch
                                    keyInput={'search'}
                                    label={t('INPUT.SEARCH')}
                                    value={search}
                                    onchange={(event) => {
                                        setSearch(event.target.value)
                                    }}
                                    onClear={(event) => {
                                        setSearch('')
                                    }}
                                />
                            </Col>
                            <Col sm={4} md={4} xl={3} xxl={3} className={'pt-2'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)

                                    }}
                                    renderValue={() => branchId === 0 ? `${t('REPORT.FILTER.BRANCH')}: ${t(`REPORT.FILTER.ALL`)}` : `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={[
                                        <MenuItem key={0} value={0}>{t(`REPORT.FILTER.ALL`)}</MenuItem>,
                                        ..._.map(branches, (b: BranchInterface, index) => (
                                            <MenuItem key={b.branchId} value={b.branchId}>
                                                {b.branchName || b.branchNameEn}
                                            </MenuItem>
                                        ))
                                    ]}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>
                            <Col sm={4} md={4} xl={3} xxl={3} className={'pt-2'} >
                                <InputSecondNewRangePicker
                                    inputHeight={32}
                                    value={rangeDate || emptyRangePicker}
                                    onchange={(val: any) => {
                                        if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                        else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                    }}
                                    allowClear
                                    onClear={() => setRangeDate(emptyRangePicker)}
                                    label={''}
                                />
                            </Col>

                            <Col md={1} xl={1} className={'pt-0'}>
                                <ButtonCustom onClick={() => {
                                    setFetch(true);
                                    setAccessChannelPage(1);
                                    setLoading(true)
                                }}
                                    textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                            </Col>

                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                        <FucntionPrint
                                            content={() => componentRef.current}
                                            docTitle={t(`REPORT.REPORT_NAME.ACCESS_CHANNEL`)}
                                            removeIfram
                                            trigger={() => <Dropdown.Item >
                                                {t('PDF')}
                                            </Dropdown.Item>}
                                            style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                        />
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <AccessChanelList
                            page={accessChannelPage}
                            pageLimit={accessChannelPageLimit}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={patientAccessChannel}
                            dataExport={patientAccessChannelExport}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                            filter={[`${t(`REPORT.REPORT_NAME.ACCESS_CHANNEL`)}`, branchId === 0 ? `${t('REPORT.MONTH.ALL')}` : `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `, rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-', rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-']}
                        />
                    </Box>
                </Card.Body>
                <Loading show={loading} type='fullLoading' />
            </Card>
        </>
    )
}