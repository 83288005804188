import React from 'react'


type IconProps = {
    width?: string
    height?: string
    fill?: string
}

const IconHistoryTreatment = (props: IconProps) => {
    return (
        <svg
            width={props?.width || "24"}
            height={props?.height || "25"}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6 2.5C4.9 2.5 4.01 3.4 4.01 4.5L4 20.5C4 21.6 4.89 22.5 5.99 22.5H18C19.1 22.5 20 21.6 20 20.5V9.33C20 8.8 19.79 8.29 19.41 7.92L14.58 3.09C14.21 2.71 13.7 2.5 13.17 2.5H6ZM13 8.5V4L18.5 9.5H14C13.45 9.5 13 9.05 13 8.5Z"
                fill={props?.fill || "#1CB99A"}
            />
        </svg>
    )
}

export default IconHistoryTreatment