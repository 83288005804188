import { useState, useEffect, useCallback } from 'react'
import { Grid, Typography, Box, FormControl, RadioGroup, FormControlLabel, MenuItem } from '@mui/material'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import TreatmentHistoryApi, { PrintMedicalReferralProps } from 'api/dentists/treatment-history.api'
import TreatmentApi, { LastNumberProps } from 'api/dentists/treatment.api'
import ClinicApi from 'api/master/clinic.api'

/** UTILS || SLICE */
import { closeModal, resetModal } from 'app/slice/modal.slice'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import Loading from 'component/Loading'
import InputTextarea from 'component/Input/InputTextarea'
import InputRadio from 'component/Input/InputRadio'
import InputTextField from 'component/Input/InputTextField'
import { renderAge } from 'utils/app.utils'
import { TreatmentStateInterface, treatment } from 'app/slice/treatment.slice'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import ComponentApi from 'api/components-api'

type ModalCustomProps = {
    isShow: boolean | false
    setIsShow: (show: boolean) => void
    onClose?: () => void
    patientId: number
    branchId: number
    patient?: any
}

type Doctors = {
    userId: number
    firstname: string
    lastname: string
    fullname: string
}

export default function ReferralEaxmination(props: ModalCustomProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const patient = useSelector((state: any) => state.register.patient)
    const [doctors, setDoctors] = useState<Doctors[]>([])
    const [errorDoctorSelect, setErrorDoctorSelect] = useState('')

    const [loading, setLoading] = useState(false)

    const treatments: TreatmentStateInterface = useSelector(treatment)
    const [signExaminer, setSignExaminer] = useState<boolean>(true)
    const [statusFrom, setStatusFrom] = useState<"th" | "empty">("th")
    /** FILTER */
    const [doctor, setDoctor] = useState<any>(null)
    const [no, setNo] = useState<string>("")
    const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'))
    const [fullName, setFullName] = useState<string>("")
    const [age, setAge] = useState<string>("")
    const [bodyHistory, setBodyHistory] = useState<string>("")
    const [note, setNote] = useState<string>("")
    const [other_text, setOther_text] = useState<string>("")

    const [disableDoctor, setDisableDoctor] = useState(false)

    const loadDoctor = async () => {
        const resp = await ClinicApi.findAllDoctors()
        if (resp.status === 200) setDoctors(resp.data)
    }

    const loadPrefix = async (name: string, id: number | undefined) => {
        ComponentApi.findAllPrefixes().then((resPrefixes) => {
            if (resPrefixes.status === 200) {
                const prefixesItems = resPrefixes.data
                for (const prefix of prefixesItems) {
                    const { prefixName, prefixId } = prefix
                    if (prefixId === id) {
                        setFullName(`${prefixName || ''}${name}`)
                    }
                }
            }
        })
    }

    const loadLastNumber = async () => {
        const date = moment().format('YYYY-MM')
        const obj: LastNumberProps = {
            documentKey: "MEDICAL_REFERRAL",
            documentDate: date
        }
        const res = await TreatmentApi.lastNumberCertificate(obj)
        if (res.status === 200) {
            const num = res.data.split("-")
            setNo(`${num[0]}-${num[1]}-${(parseInt(num[2], 10) + 1).toString().padStart(num[2].length, '0')}`)
        } else {
            setNo(`${date}-0000`)
        }
    }

    const handleSetAge = (date: string | null) => {
        if (_.isEmpty(date)) {
            return ""
        } else {
            return `${renderAge(date, 'year')} ${t('TIME_DURATION_TYPE.YEAR')} ${renderAge(date, 'month')} ${t('TIME_DURATION_TYPE.MONTH')} ${renderAge(date, 'day')} ${t('TIME_DURATION_TYPE.DAY')}`
        }
    }

    useEffect(() => {
        if (props.patient) {
            loadLastNumber()
            setAge(handleSetAge(props.patient?.dateOfBirth))
            const newFullName = `${props.patient?.firstname} ${props.patient?.lastname}`
            if (props.patient?.prefix?.prefixName) {
                setFullName(`${props.patient?.prefix?.prefixName || ''}${props.patient?.firstname} ${props.patient?.lastname}`)
            } else {
                console.log(props.patient?.prefixId);
                loadPrefix(newFullName, props.patient?.prefixId)
            }
        }
        loadDoctor()
    }, [])

    const onPrint = async () => {
        const { address, addressEn, cnNumber, branchCnNumber } = props?.patient || {}
        const patientDiagnosisPlus = isChecks.map((item: any) => {
            return {
                patientDiagnosisId: item.patientDiagnosisId,
                diagnosisId: item.diagnosisId,
                diagnosisName: item.diagnosisName,
            }
        })
        const systolicBloodPressure = patient.systolicBloodPressure
        const diastolicBloodPressure = patient.diastolicBloodPressure
        const diastolicBloodPressureCheck = () => {
            if (diastolicBloodPressure) {
                return `/${diastolicBloodPressure}`
            } else {
                return ""
            }
        }
        const obj: PrintMedicalReferralProps = {
            "referenceNumber": setValue(no),
            "branchId": props?.branchId,
            "date": setValue(date),
            "doctorName": setValue(doctor?.fullname) || "",
            "doctorId": setValue(doctor?.userId) || 0,
            "certificate": setValue(doctor?.certificate) || "",
            "patientId": props?.patientId,
            "address": setValue(address) || "",
            "addressEn": setValue(addressEn) || "",
            "patientFullName": setValue(fullName),
            "age": setValue(age) || "",
            "cnNumber": cnNumber,
            "branchCnNumber": branchCnNumber,
            "physicalExaminationHistory": setValue(bodyHistory),
            "patientDiagnosis": setValue(patientDiagnosisPlus, "send"),
            "note": setValue(note),
            "other": setValue(other_text),
            "signedByTheExaminer": signExaminer,
            "height": patient.height || "",
            "temperature": patient.temperature || "",
            "weight": patient.weight || "",
            "bmi": patient.bmi || "",
            "o2Sat": patient.o2Sat || "",
            "pulseRate": patient.pulseRate || "",
            "rr": patient.rr || "",
            "bloodPressure": systolicBloodPressure ? `${systolicBloodPressure}${diastolicBloodPressureCheck()}` : ""
        }
        setLoading(true)
        const print = await TreatmentHistoryApi.printMedicalReferral(props.patientId, obj).finally(() => setLoading(false))

        if (print.status === 201) {
            window.open(`${fileUrl}/${print.data}`, '_blank')
            clearData()
            props.setIsShow(false)
            dispatch(closeModal())
        }
    }

    const clearData = () => {
        setDoctor(null)
        setNo("")
        setDate(moment().format('YYYY-MM-DD'))
        setFullName("")
        setAge("")
        setBodyHistory("")
        setNote("")
        setOther_text("")
    }

    const sendOutClinic = () => {
        if (statusFrom === "th") {
            setDisableDoctor(true)
            setStatusFrom("empty")
        } else {
            setStatusFrom("th")
            setDisableDoctor(false)
        }
    }

    const setDisable_input = () => {
        if (statusFrom === "th") {
            return false
        } else {
            return true
        }
    }

    const setValue = useCallback((info: any, status?: "send") => {
        if (statusFrom === "th") {
            return info;
        } else {
            if (typeof info === "string") {
                return "";
            } else if (typeof info === "number") {
                return 0;
            } else {
                return status ? [] : null;
            }

        }
    }, [statusFrom]);

    const [isChecks, setIsChecks] = useState([] as any)
    const [isCheckAll, setIsCheckAll] = useState('')

    const handleOnCheckAll = () => {
        const hasSome = _.some(treatments.patientDiagnosis, (i: any) => _.some(isChecks, (j: any) => i.diagnosisCode === j.diagnosisCode))
        const hasAll = _.every(treatments.patientDiagnosis, (i: any) => _.some(isChecks, (j: any) => i.diagnosisCode === j.diagnosisCode))
        if (!_.isEmpty(isChecks) && !hasAll && hasSome) setIsCheckAll('indeterminate')
        else if (!_.isEmpty(isChecks) && hasAll) setIsCheckAll('checked')
        else setIsCheckAll('')
    }

    useEffect(() => {
        if (statusFrom !== "empty") {
            handleOnCheckAll()
        }
    }, [isChecks, treatments.patientDiagnosis])

    const onCheckAll = (event: any) => {
        const { checked } = event
        const id = treatments.patientDiagnosis
        if (statusFrom !== "empty") {
            if (!checked || isChecks.length === 4) {
                setIsChecks(_.filter(isChecks, (i) => !_.includes(id, i)))
            } else {
                const newData = Array.from(new Set([...isChecks, ...id]))
                setIsChecks(newData.slice(0, 4))
            }
        }

    }

    const onCheck = (event: any) => {
        const { checked, value } = event
        const data = _.filter(treatments.patientDiagnosis, { diagnosisCode: value })
        if (statusFrom !== "empty") {
            if (checked && isChecks.length < 4) {
                setIsChecks([...isChecks, data[0]])
            } else {
                setIsChecks(
                    _.filter(isChecks, (i: any) => {
                        return i !== data[0]
                    })
                )
            }
        }
    }

    const headCells = [
        {
            id: 'patientSelect',
            disablePadding: false,
            label: 'CHECKBOX',
            values: [],
            width: '80px',
            indeterminate: statusFrom !== "empty" && isCheckAll === 'indeterminate',
            checked: statusFrom !== "empty" && isCheckAll === 'checked',
            onCheckAll: (event: any) => onCheckAll(event.target),
        },
        { id: 'no', disablePadding: false, align: 'center', label: '#', width: '70px' },
        { id: 'message', disablePadding: false, align: 'center', label: t('DIAGNOSIS_SUMMARY.TITLE.CODE'), width: '100px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS') },
    ]

    const renderData = (objData: any, no: number) => {
        no = no + 1

        const { diagnosisCode, diagnosisName } = objData
        const rowCheck = _.some(isChecks, (i: any) => i.diagnosisCode === diagnosisCode)

        const objRenderData = {
            key: diagnosisCode,
            id: diagnosisCode,
            obj: objData,
            rowSelect: statusFrom !== "empty" ? rowCheck : false,
            columns: [
                { option: 'CHECKBOX', align: 'center', label: diagnosisCode },
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: diagnosisCode || '-' },
                { option: 'TEXT', align: 'left', label: diagnosisName || '-' }
            ]
        }
        return <TableRowCommon
            {...objRenderData}
            onCheck={(event) => onCheck(event.target)}
        />
    }

    return (
        <ModalCustom
            title={t('MODAL_REFERRAL_EXAMINATION.MODAL.TITLE')}
            alignFooter={'end'}
            size={'xl'}
            footerInline
            onClose={props.onClose}
            onSubmit={onPrint}
            onReset={() => {
                dispatch(resetModal())
                props.setIsShow(false)
            }}
            textBtnCancel={t('BUTTON.CANCEL')}
            textBtnConfirm={t('BUTTON.PRINT')}
            modalStyle={'modal-referral-examination'}
            component={
                <>
                    <Grid container spacing={2} className="pt-2">
                        <Grid item className='w-100'>
                            <Box className="d-flex align-items-center">
                                <Typography className='mr-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM')}</Typography>
                                <FormControl>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                        <FormControlLabel value="false" control={<InputRadio checked={disableDoctor === false} className="radio" onClick={() => sendOutClinic()} />} label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_THAI')} />
                                        <FormControlLabel value="true" control={<InputRadio checked={disableDoctor === true} onClick={() => sendOutClinic()} />} label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_EMPTY')} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'no'}
                                label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_NO')}
                                value={setValue(no)}
                            />
                        </Grid>
                        <Grid item xs={3} className='w-100'>
                            <InputSecondNewDatePicker
                                required={statusFrom !== "empty" ? true : false}
                                disabled={statusFrom !== "empty" ? false : true}
                                key={'date'}
                                label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_DATE')}
                                value={setValue(date)}
                                onchange={(e: any) => setDate(moment(e).format('YYYY-MM-DD'))} />
                        </Grid>
                        <Grid item xs={3} className='w-100'>
                            <BasicSelect
                                name="doctorId"
                                labelId="lb-dentist"
                                selectId="sl-dentist"
                                label={t('HISTORY_APPOINTMENT.DOCTOR')}
                                value={setValue(doctor) || null}
                                options={[
                                    _.map(doctors, (doctorData: any, i: number) => (
                                        <MenuItem key={i} value={doctorData}>
                                            {doctorData.fullname}
                                        </MenuItem>
                                    ))
                                ]}
                                onchange={(event) => {
                                    setDoctor(event.target.value)
                                    setErrorDoctorSelect('')
                                }}
                                classesOption="style-select-doctors-mb"
                                disabled={disableDoctor}
                                helperText={errorDoctorSelect || ''}
                                allowClear={true}
                                onClear={() => setDoctor(null)}
                            />
                        </Grid>
                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'FORM_NODOC'}
                                label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_NODOC')}
                                value={setValue(doctor?.certificate) || ""}
                            />
                        </Grid>

                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'FORM_NODOC'}
                                label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_NAME')}
                                value={setValue(fullName)} />
                        </Grid>

                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'FORM_NODOC'}
                                label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_AGE')}
                                value={setValue(age, 'send')}
                            />
                        </Grid>
                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'FORM_NODOC'}
                                label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_CN')}
                                value={setValue(props.patient?.cnNumber)}
                            />
                        </Grid>
                    </Grid>
                    <div className="pt-2 mt-3">
                        <InputTextarea
                            label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_BODYHISTORY')}
                            onchange={(e: any) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 900) {
                                    setBodyHistory(inputValue);
                                }
                            }}
                            disabled={setDisable_input()}
                            value={setValue(bodyHistory)}
                            inputProps={{
                                style: {
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-start',
                                    fontSize: "12px"
                                },
                                startAdornment: (
                                    <div style={{ position: "absolute", top: "-18px" }}>
                                        <div>{`${setValue(bodyHistory).length}/900`}</div>
                                    </div>
                                ),
                            }}
                        />
                    </div>
                    <div className='pt-2 mt-2'>
                        <TableCustom
                            hidePagination
                            customScroll
                            page={0}
                            pageLimit={0}
                            sortType={'ASC'}
                            sortBy={''}
                            rowCount={0}
                            textEmptyData={t('REGISTER.NO_ITEM')}
                            onSort={() => {
                                return
                            }}
                            setPageLimit={() => {
                                return
                            }}
                            setPage={() => {
                                return
                            }}
                            headCells={headCells}
                            rowsData={_.map(treatments.patientDiagnosis, (val: any, i: number) => {
                                return renderData(val, i)
                            })}
                        />
                    </div>
                    <div className='pt-2 mt-3'>
                        <InputTextField
                            label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_DIAGETEXT')}
                            onchange={(e: any) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 130) {
                                    setNote(e.target.value)
                                }
                            }}
                            disabled={setDisable_input()}
                            value={setValue(note)}
                            inputHeight={51}
                            inputProps={{
                                style: {
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-start',
                                    paddingLeft: "0px",
                                    fontSize: "12px"
                                },
                                startAdornment: (
                                    <div style={{ position: "absolute", top: "-18px", right: "14px" }}>
                                        <div>{`${setValue(note).length}/130`}</div>
                                    </div>
                                ),
                            }}
                        />
                    </div>
                    <div className='pt-2 mt-3'>
                        <InputTextarea
                            label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_OTHER')}
                            onchange={(e: any) => {
                                const inputValue = e.target.value;
                                if (inputValue.length <= 900) {
                                    setOther_text(e.target.value)
                                }
                            }}
                            disabled={setDisable_input()}
                            value={setValue(other_text)}
                            inputProps={{
                                style: {
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-start',
                                    fontSize: "12px"
                                },
                                startAdornment: (
                                    <div style={{ position: "absolute", top: "-18px" }}>
                                        <div>{`${setValue(other_text).length}/900`}</div>
                                    </div>
                                ),
                            }} />
                    </div>
                    <div className='pt-2 d-flex justify-content-start align-items-center'>
                        <InputCheckbox onChange={() => setSignExaminer(!signExaminer)} checked={signExaminer} />
                        <span className='ml-1'>{t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_SIGN')}</span>
                    </div>

                    {loading && <Loading show={true} type="fullLoading" />}
                </>
            }
        />
    )
}

