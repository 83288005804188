import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** CONSTANT */
import { imgs } from 'constants/images'
import { colors } from 'constants/theme'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'

export interface FormRetrieveProp {
  disabled?: boolean
  error?: any
  resetErrorMessage?: () => void
}

const CardProfile = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  width: 123,
  height: 123,
  flexDirection: 'column',
  alignItems: 'center',
  outline: 'none',
  cursor: 'default',
  border: `5px solid ${colors.black12}`,
  borderRadius: '50%',
  [theme.breakpoints.down('sm')]: {
    width: 107,
    height: 107
  },
  img: {
    width: '100%',
    height: '100%'
  }
}))

export default function FormRetrieve(props: FormRetrieveProp) {
  const { t } = useTranslation()
  const { register, setValue } = useFormContext()

  const [inputs, setInputs]: any = useState([])

  /** PROFILE */
  const [imgData, setImgData]: any = useState()

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      uploadImage(acceptedFiles)
      setImgData(URL.createObjectURL(acceptedFiles[0]))
    }
  })

  const handleChange = (event: any) => {
    const name = event.target.name
    const value = event.target.value
    setInputs((values: any) => ({ ...values, [name]: value }))
  }

  const uploadImage = (files: any) => {
    if (files[0]) {
      const file = files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        /** reader.result is base64 */
        const base64 = reader.result.split(',')
        setImgData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    window.addEventListener('paste', (e: any) => {
      if (e.clipboardData.files.length) {
        if (e.clipboardData.files && e.clipboardData.files[0]) {
          uploadImage(e.clipboardData.files)
          setValue('retrieve.profile', e.clipboardData.files)
        }
      }
    })
  }, [])

  return (
    <Modal.Body className={'show-grid py-2 px-0'}>
      <Grid container>
        <input type="hidden" {...register('retrieve')} value={inputs} />
        <input type="hidden" {...register('retrieve.profile')} />
        <Grid item md={'auto'} className={'m-auto'}>
          <CardProfile {...getRootProps({ isDragActive, isDragAccept, isDragReject })} className={'card-profile'}>
            <input {...getInputProps()} disabled={true} />
            {!imgData ? (
              <div className={'personal-figure position-relative'}>
                <img src={imgs.defaultAvatar} className={'personal-avatar'} alt="avatar" />
              </div>
            ) : (
              <img src={imgData} alt="imageData" className={'personal-avatar'} />
            )}
          </CardProfile>
        </Grid>
      </Grid>

      <Grid container className={'justify-content-start d-flex align-content-center text-left mt-2'} spacing={2}>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.prefixId', { onChange: handleChange })} value={inputs.prefixId} label={t('REGISTER.FORM.PREFIXES')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.firstname', { onChange: handleChange })} hookValue={true} value={inputs.firstname} label={t('REGISTER.FORM.FIRST_NAME_TH')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.lastname', { onChange: handleChange })} value={inputs.lastname} label={t('REGISTER.FORM.LAST_NAME_TH')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.firstnameEn', { onChange: handleChange })} hookValue={true} value={inputs.firstnameEn} label={t('REGISTER.FORM.FIRST_NAME')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.lastnameEn', { onChange: handleChange })} value={inputs.lastnameEn} label={t('REGISTER.FORM.LAST_NAME')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.idCard', { onChange: handleChange })} value={inputs.idCard} label={t('REGISTER.FORM.ID_CARD')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.gender', { onChange: handleChange })} value={inputs.gender} label={t('REGISTER.FORM.GENDER')} size="small" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField type="text" onchange={handleChange} register={register('retrieve.dateOfBirth', { onChange: handleChange })} value={inputs.dateOfBirth} label={t('REGISTER.FORM.BIRTHDAY')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.address', { onChange: handleChange })} value={inputs.address} label={t('REGISTER.FORM.ADDRESS')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.soi', { onChange: handleChange })} value={inputs.soi} label={t('REGISTER.FORM.ALLEY')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.road', { onChange: handleChange })} value={inputs.road} label={t('REGISTER.FORM.ROAD')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.province', { onChange: handleChange })} value={inputs.province} label={t('REGISTER.FORM.PROVINCE')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField onchange={handleChange} register={register('retrieve.district', { onChange: handleChange })} value={inputs.district} label={t('REGISTER.FORM.DISTRICT')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={'w-100'}>
          <InputTextField key="treatmentCourseTypeNameEn" onchange={handleChange} register={register('retrieve.subDistrict', { onChange: handleChange })} value={inputs.subDistrict} label={t('REGISTER.FORM.SUB_DISTRICT')} />
          <div className={'d-none'}>
            <input type="file" id="document_attachment_doc" />
          </div>
        </Grid>
      </Grid>
    </Modal.Body>
  )
}
