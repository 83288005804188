import React from 'react'

type IconProps = {
    width?: string
    height?: string
    fill?: string
}

const IconCheckInSuccess = (props: IconProps) => {
    return (
        <svg
            width={props?.width || "33"}
            height={props?.height || "32"}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.5003 2.66602C9.14033 2.66602 3.16699 8.63935 3.16699 15.9993C3.16699 23.3594 9.14033 29.3327 16.5003 29.3327C23.8603 29.3327 29.8337 23.3594 29.8337 15.9993C29.8337 8.63935 23.8603 2.66602 16.5003 2.66602ZM12.887 21.7194L8.10033 16.9327C7.58033 16.4127 7.58033 15.5727 8.10033 15.0527C8.62033 14.5327 9.46033 14.5327 9.98033 15.0527L13.8337 18.8927L23.007 9.71935C23.527 9.19935 24.367 9.19935 24.887 9.71935C25.407 10.2393 25.407 11.0793 24.887 11.5993L14.767 21.7194C14.2603 22.2394 13.407 22.2394 12.887 21.7194Z"
                fill={props?.fill || "#1CB99A"}
            />
        </svg>

    )
}

export default IconCheckInSuccess