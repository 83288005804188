import { Box, Tabs, IconButton, Typography, Button } from '@mui/material';
import { clinicTheme } from 'api/api';

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${colors.extraLightBlue}`,
  minHeight: 'unset',
  '.MuiTabs-indicator': {
    backgroundColor: colors.themeSecondColor
  },
  '.MuiTab-root': {
    minHeight: 'unset',
    height: 40,
    minWidth: 'unset',
    width: '100%',
    padding: 0,
    color: colors.textPrimary,
    fontSize: 16,
    maxWidth: '33.33%',
    '&.Mui-selected': {
      color: colors.themeSecondColor
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      maxWidth: 120
    }
  }
}))

export const NetPrice = styled(Box)(({ theme }) => ({
  height: 48,
  fontSize: 20,
  color: colors.themeMainColor,
  backgroundColor: colors.themeMainColor06,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18
  }
}))

export const ResponsiveSummaryTreatmentTable = styled(Box)(({ theme }) => ({
  '*': {
    color: colors.textPrimary
  },
  '> div': {
    borderRadius: '10px 10px 0 0'
  },
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        paddingLeft: 8,
        paddingRight: 8,
        '&.MuiTableCell-head:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 30,
          textAlign: 'center'
        },
        '&.MuiTableCell-head:last-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 65,
          textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
          '&.MuiTableCell-head:nth-of-type(2)': {
            width: '18%'
          },
          '&.MuiTableCell-head:nth-of-type(3)': {
            width: '18%'
          },
          '&.MuiTableCell-head:nth-of-type(4)': {
            width: clinicTheme.theme === clinicTheme.type.DENTAL ? '25%' : '80px'
          },
          '&.MuiTableCell-head:nth-of-type(5)': {
            width: clinicTheme.theme === clinicTheme.type.DENTAL ? 80 : 100
          },
          '&.MuiTableCell-head:nth-of-type(6)': {
            width: clinicTheme.theme === clinicTheme.type.DENTAL ? 100 : 176
          },
          '&.MuiTableCell-head:nth-of-type(7)': {
            width: clinicTheme.theme === clinicTheme.type.DENTAL ? 176 : 130
          },
          '&.MuiTableCell-head:nth-of-type(8), &.MuiTableCell-head:nth-of-type(9), &.MuiTableCell-head:nth-of-type(10)': {
            width: 130
          }
        },
        [theme.breakpoints.down('lg')]: {
          textAlign: 'left',
          '&:not(.MuiTableCell-head:first-of-type)': {
            fontSize: 0
          }
        }
      }
    },
    '.MuiTableBody-root': {
      '.main-table-row': {
        [theme.breakpoints.up('lg')]: {
          '.MuiTableCell-body': {
            borderBottomColor: 'transparent !important'
          },
          '&:hover + tr, &.table-row-hover': {
            '.MuiTableCell-body': {
              backgroundColor: `${colors.extraLightGray} !important`
            }
          }
        }
      },
      '.MuiTableCell-body': {
        padding: '8px',
        verticalAlign: 'top !important',
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
        [theme.breakpoints.down('lg')]: {
          verticalAlign: 'top'
        }
      }
    }
  },
  '.MuiTableFooter-root': {
    display: 'none'
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  width: 130,
  flex: 'none',
  [theme.breakpoints.down('xs')]: {
    width: 120
  }
}))

export const ResponsiveSummaryDiagnosisTable = styled(Box)(({ theme }) => ({
  '*': {
    color: colors.textPrimary
  },
  '> div': {
    borderRadius: '10px 10px 0 0'
  },
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        paddingLeft: 8,
        paddingRight: 8,
        '&.MuiTableCell-head:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 40,
          textAlign: 'center'
        },
        '&.MuiTableCell-head:nth-of-type(2)': {
          width: 100,
          textAlign: 'center',
          [theme.breakpoints.up('lg')]: {
            width: 120
          }
        },
        '&.MuiTableCell-head:nth-of-type(4)': {
          width: '25%',
          textAlign: 'left',
          [theme.breakpoints.up('lg')]: {
            width: '20%'
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&:not(:first-of-type):not(:last-of-type)': {
            fontSize: 0
          }
        }
      }
    },
    '.MuiTableBody-root': {
      '.main-table-row': {
        [theme.breakpoints.up('sm')]: {
          '.MuiTableCell-body.MuiTableCell-root': {
            borderBottomColor: 'transparent !important'
          },
          '&:hover, &.table-row-hover': {
            '.MuiTableCell-body': {
              borderBottom: `1px solid ${colors.extraLightGray} !important`
            }
          },
          '&:hover + tr, &.table-row-hover': {
            '.MuiTableCell-body': {
              backgroundColor: `${colors.extraLightGray} !important`,
            }
          }
        }
      },
      '.MuiTableCell-body': {
        padding: '8px',
        verticalAlign: 'top !important',
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
        [theme.breakpoints.down('sm')]: {
          verticalAlign: 'top'
        }
      }
    },
    '.MuiTableFooter-root': {
      display: 'none'
    }
  }
}))

export const InputFormControl = styled(Box)(({ theme }) => ({
  '&.next-visit': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: `${colors.black12} !important`
    }
  },
  '.MuiOutlinedInput-root': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    textarea: {
      height: '50px !important'
    }
  },
  '.MuiButton-root': {
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    minWidth: 53,
    padding: '0 !important'
  }
}))

export const InputToothChart = styled(Box)(({ theme }) => ({
  '.MuiOutlinedInput-root, .MuiOutlinedInput-input': {
    height: 36
  }
}))

export const BtnEditToothChart = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  '&:not(.active):hover': {
    svg: {
      g: {
        path: {
          stroke: `${colors.themeSecondColor} !important`
        }
      }
    }
  }
}))

export const ButtonOperative = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  minWidth: 'unset',
  maxWidth: '100%',
  span: {

    color: colors.textPrimary,
    fontSize: 16,
    transition: 'color .2s ease'
  },
  '&:hover': {
    span: {
      color: colors.themeSecondColor
    }
  }
}))

export const DentistList = styled(Box)(({ theme }) => ({
  padding: '4px 12px',
  justifyContent: 'space-between',
  minHeight: 'auto',
  svg: {
    '&:hover': {
      path: {
        fill: `${colors.themeSecondColor15} !important`
      }
    }
  }
}))