import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { Box, MenuItem } from '@mui/material'
import moment from 'moment'
import { colors } from 'constants/theme';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { routeName } from 'routes/routes-name'
import DentistCostApi from 'api/finances/dentist-cost.api'
import BranchApi from 'api/setting/branch-service-points/branch.api'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { getBranch } from 'utils/app.utils';

/** STYLE */
import styles from 'features/finance/salary/style'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { swalActive } from 'component/Alert/Swal'
import { Card, Col, Row } from 'react-bootstrap'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'
import { icons } from 'constants/images'
import ButtonExport from 'component/Button/ButtonExport'
import TableRowExport from 'component/Pdf/TableRowExport'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function Salary() {
  const { t } = useTranslation()
  const classes = styles()
  const history = useHistory()
  const [useType] = useState('')
  const [exportData, setExportData] = useState([])

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('documentDate')
  const [sortType, setSortType] = useState('DESC')
  const [search, setSearch] = useState<string>('')
  const status = ''
  const [monthly, setMonthly] = useState('')

  /** CALL API */
  const [branches, setBranches] = useState([])
  const [dentistCost, setDentistCost] = useState([])
  const [branchId, setBranchId] = useState<number>(Number(getBranch()))

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status }
    if (branchId) condition = { ...condition, branchId: branchId }
    if (monthly) condition = { ...condition, monthly: monthly }

    const res = await DentistCostApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setDentistCost(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status, branchId, monthly])

  const loadBranch = useCallback(async () => {
    let condition: any = {}
    if (useType) condition = { ...condition, page: useType }
    const res = await BranchApi.findAll(condition)
    if (res.status === 200) {
      setBranches(res.data)
    }
  }, [])

  useEffect(() => {
    loadBranch()
    loadData()
  }, [loadBranch, loadData])

  const loadDataForExport = useCallback(async () => {
    let condition: any = {}
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status }
    if (branchId) condition = { ...condition, branchId: branchId }
    if (monthly) condition = { ...condition, monthly: monthly }
    const res = await DentistCostApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status, branchId, monthly])

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  const onCreate = () => {
    window.location.href = routeName.salary + '/create'
  }

  const onEdit = (data: any) => {
    const { dentistCostId } = data
    history.push(routeName.salary + '/update', { dentistCostId: dentistCostId })
  }

  const onView = (data: any) => {
    const { dentistCostId } = data
    history.push(routeName.salary + '/view', { dentistCostId: dentistCostId })
  }

  const onDelete = (data: any) => {
    const { dentistCostId, documentNo } = data

    swalActive(t('SALARY.ALERT.CONFIRM_DELETE'), t('SALARY.ALERT.DOCUMENT_NO') + ' - ' + documentNo, (res: any) => {
      if (res) {
        DentistCostApi.remove(dentistCostId)
          .then((resp) => {
            notiSuccess(t('SALARY.MESSAGE.SUCCESS.DELETE'))
            loadData()
          })
          .catch((e) => {
            notiError(t('SALARY.MESSAGE.ERROR'))
          })
      }
    }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }

  const handleChangePage = (val: number) => setPage(val)

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangeMonthly = (valueMonthly: string) => {
    setMonthly(valueMonthly)
  }

  const headCells = [
    { id: 'dentistCostItemId,', disablePadding: false, label: t('SALARY.TABLE.CELL.HEADER.ID'), align: 'center', width: '80px' },
    { id: 'documentNo', disablePadding: false, label: t('SALARY.TABLE.CELL.HEADER.DOCUMENT_NO') },
    { id: 'monthly', disablePadding: false, label: t('SALARY.TABLE.CELL.HEADER.MONTHLY') },
    { id: 'documentDate', disablePadding: false, label: t('SALARY.TABLE.CELL.HEADER.DOCUMENT_CREATION_DATE'), width: '150px' },
    { id: 'price', disablePadding: false, label: t('SALARY.TABLE.CELL.HEADER.TOTAL'), width: '150px' },
    { id: 'documentStatus', disablePadding: false, label: t('SALARY.TABLE.CELL.HEADER.DOCUMENT_STATUS'), width: '180px' },
    { id: 'updatedBy', disablePadding: false, label: t('SALARY.TABLE.CELL.HEADER.CREATED_BY'), width: '180px' },
    { id: 'action', disablePadding: false, label: t('SALARY.TABLE.CELL.HEADER.ACTION'), width: '150px' }
  ]

  const renderData = (objData: any, no: number, valueExportData: boolean) => {
    const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { dentistCostItemId, documentNo, monthly: salaryMonthly, documentDate, total, documentStatus, updatedBy, updatedAt, prefix } = objData

    const documentNoId = prefix + documentNo

    const formatYmd = new Date(salaryMonthly)
    const resultMonthly = formatYmd.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long'
    })

    const formatDate = new Date(documentDate)
    const resultDocumentDate = formatDate.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })

    const numb = total
    const numbTotal = numb.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    let docStatusBgColor = ''
    let documentStatusbar = ''
    if (documentStatus === 'PENDING_APPROVAL') {
      docStatusBgColor = colors.approveStatus.pending
      documentStatusbar = t('SALARY.DOCUMENT_STATUS.PENDING_APPROVAL')
    } else if (documentStatus === 'APPROVAL') {
      docStatusBgColor = colors.approveStatus.approve
      documentStatusbar = t('SALARY.DOCUMENT_STATUS.APPROVAL')
    } else if (documentStatus === 'UNAPPROVAL') {
      docStatusBgColor = colors.approveStatus.cancel
      documentStatusbar = t('SALARY.DOCUMENT_STATUS.UNAPPROVAL')
    } else {
      docStatusBgColor = 'transparent'
    }

    const objRenderData = {
      key: dentistCostItemId,
      id: dentistCostItemId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'left', label: documentNoId },
        { option: 'TEXT', align: 'left', label: resultMonthly },
        { option: 'TEXT', align: 'left', label: resultDocumentDate },
        { option: 'TEXT', align: 'right', label: numbTotal },
        {
          option: 'COMPONENT', align: 'left', class: 'py-1', component: exportData ? (<>{documentStatusbar}</>) : (
            <div style={{ borderRadius: '8px', padding: '1px 8px', backgroundColor: docStatusBgColor, color: colors.white, width: 'fit-content', textDecoration: 'none' }}><span>{documentStatusbar}</span></div>
          )
        },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'COMPONENT',
          align: 'center',
          label: 'action',
          component: (
            <Box className='d-flex align-items-center justify-content-center py-1'>
              <img src={icons.iconEdit} alt="iconEdit" onClick={() => onEdit(objRenderData.obj)} style={{ width: '18px', height: '18px', cursor: 'pointer', marginRight: '8px' }} />
              <img src={icons.iconFaTrash} alt="iconFaTrash" onClick={() => onDelete(objRenderData.obj)} style={{ width: '18px', height: '18px', cursor: 'pointer', marginLeft: '8px' }} />
            </Box>
          )
        }
      ]
    }

    return (
      <>
        {valueExportData === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon {...objRenderData} rowLink={true} onClick={() => onView(objRenderData.obj)} />
        }
      </>
    )
  }

  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('DASHBOARD.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName') || '-'}` },
    { label: `${t('SALARY.INPUT.MONTH')} : ${monthly || '-'}` },
  ]

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('SALARY.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={6} xl={3}>
                <InputTextSearch
                  size="medium"
                  keyInput={'search'}
                  label={t('SALARY.INPUT.MAIN_SEARCH')}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                    setPage(1)
                  }}
                  onClear={(event) => {
                    setSearch('')
                    setPage(1)
                  }}
                />
              </Col>
              <Col sm={6} xl={2} className="mt-3 mt-md-0">
                <FilterSelect
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  onchange={(event: any) => {
                    setBranchId(event.target.value)
                    setPage(1)
                  }}
                  renderValue={() => `${t('DASHBOARD.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName') || '-'}`}
                  name="branchId"
                  label={t('SALARY.INPUT.BRANCH')}
                  selectId="sl-branch-id"
                  value={branchId}
                  labelId="lb-branch-id"
                  options={branches.map((i: any) => {
                    return (
                      <MenuItem key={i.branchId} value={i.branchId}>
                        {i.branchName}
                      </MenuItem>
                    )
                  })}
                />
              </Col>
              <Col sm={6} xl={2} className="mt-3 mt-xl-0">
                <InputSecondNewDatePicker
                  allowClear
                  views={PICKER_VIEWS.MONTH}
                  // views={[PICKER_VIEWS.YEAR, PICKER_VIEWS.MONTH]}
                  onchange={(e: any) => {
                    handleChangeMonthly(moment(e).format('YYYY-MM-DD'))
                    loadData()
                    setPage(1)
                  }}
                  onClear={() => setMonthly('')}
                  value={monthly}
                  dateFormat="MMMM YYYY"
                  inputHeight={32}
                  placeholder={t('SALARY.INPUT.MONTH')} />
              </Col>
              <Col md={12} xl={5} className="mt-3 mt-xl-0">
                <Box className='d-flex flex-column flex-sm-row align-items-end align-items-sm-center justify-content-end pr-2'>
                  <Box className='mb-3 mb-sm-0 pr-sm-3'>
                    <ButtonExport headCells={headCells} portrait={false} filter={filter} fileName={t('SALARY.TITLE')}
                      rowsData={exportData.map((val, i) => renderData(val, i, true))} />
                  </Box>
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('SALARY.BUTTON.ADD')} style={{ margin: '0px' }} />
                </Box>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={dentistCost.map((val, i) => {
                return renderData(val, i, false)
              })}
              tableFixedWidth
              tableMinWidth={1336}
            />
          </div>
        </Card.Body>
      </Card >
    </div >
  )
}
