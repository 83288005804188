import moment from 'moment'
import _ from 'lodash'
import Avatar from '@mui/material/Avatar'
import { Image } from 'react-bootstrap'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'

/** COMPONENT */
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import Box from '@mui/material/Box'

/** UTILS */
import { getBranch, getClinicInfo, memberTypeOutline } from 'utils/app.utils'

/** CONSTANTS */
import { ThemeIcon, colors } from 'constants/theme'
import { icons, imgs } from 'constants/images';

/** STYLE */
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';
import { BoxStatus, TextStatus } from './UseStyled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faUser, faUserTimes } from '@fortawesome/free-solid-svg-icons'
import AboutUs from 'api/admin/clinic-manage/about.api'

const AutoCompleteWrap = styled('div')(({ theme }) => ({}))

type FilterPatientType = {
  data: any
  onchange: (event: any, value: any) => void
  disabled?: boolean
  branch: string
}


export default function FilterPatient(props: FilterPatientType) {
  const { t } = useTranslation()
  const selectAll = [{ appointmentId: 0, cnNumber: '' }]
  const [selected, setSelected]: any = useState({ appointmentId: 0, cnNumber: '' });

  /** VARIABLE */
  const statusIcon: any = {
    NORMAL: { icon: <FontAwesomeIcon style={{ color: colors.white }} icon={faUser} />, color: colors.appointmentStatus.statusNormal, label: t('APPOINTMENT.STATUS.NORMAL'), disabled: false },
    CANCEL: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconCancel} />, color: colors.appointmentStatus.statusCancel, label: t('APPOINTMENT.STATUS.CANCEL'), disabled: false },
    CAN_NOT_CONTACT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconNotConnect} />, color: colors.appointmentStatus.statusUnConnect, label: t('APPOINTMENT.STATUS.CAN_NOT_CONTACT'), disabled: false },
    PUT_OFF: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconChangeSchedule} />, color: colors.appointmentStatus.statusChange, label: t('APPOINTMENT.STATUS.PUT_OFF'), disabled: false },
    CONFIRM: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconEnter} />, color: colors.appointmentStatus.statusEnter, label: t('APPOINTMENT.STATUS.CONFIRM'), disabled: false },
    ARRIVED: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconUserCheckTrue} />, color: colors.appointmentStatus.statusArrived, label: t('APPOINTMENT.STATUS.ARRIVED'), disabled: false },
    NOT_YET: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconUserCheckFalse} />, color: colors.appointmentStatus.statusUnArrived, label: t('APPOINTMENT.STATUS.NOT_YET'), disabled: false },
    PAYMENT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconPendingPayment} />, color: colors.appointmentStatus.statusWaitPayment, label: t('APPOINTMENT.STATUS.PAYMENT'), disabled: true },
    PENDING_PAYMENT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconWaitPayment} />, color: colors.appointmentStatus.statusPayment, label: t('APPOINTMENT.STATUS.PENDING_PAYMENT'), disabled: true },
    PENDING_SERVICE: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconCounterCheckIn} />, color: colors.appointmentStatus.statusCounterCheckIn, label: t('APPOINTMENT.STATUS.PENDING_SERVICE'), disabled: true },
    SERVICE: { icon: <Image style={{ height: '17px' }} src={icons.appointment.iconService} />, color: colors.appointmentStatus.statusService, label: t('APPOINTMENT.STATUS.SERVICE'), disabled: true },
    DONE: { icon: <Image style={{ height: '15px' }} src={ThemeIcon.DoneTreatment} />, color: colors.appointmentStatus.statusDone, label: t('APPOINTMENT.STATUS.DONE'), disabled: true },
  }
  const branchId = getBranch()
  const [branch, setBranch] = useState<any>()

  useEffect(() => {
    setBranch(props.branch)
  }, [props.branch])

  const renderImage = (img: any) => {
    if (!_.isEmpty(img)) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }



  const renderAppointStatus = (status: string) => {
    return <BoxStatus style={{ marginLeft: '10px' }} bg={statusIcon[status]?.color || ''}>
      {statusIcon[status]?.icon || ''}
      <TextStatus  >
        {statusIcon[status]?.label || ''}
      </TextStatus>
    </BoxStatus>
  }



  return (
    <AutoCompleteWrap>
      <AutocompleteSelect
        labelId="filter-patient"
        inputLabel={''}
        onOpen={() => setSelected({ appointmentId: '', cnNumber: '' })}
        options={[...selectAll, ...props.data]}
        renderOption={(props, option) => {
          const handlePatientCode = () => {
            if (branch?.patientCodeAppointment === '1') {
              return option.cnNumber
            }
            if (branch?.patientCodeAppointment === '0') {
              return option.branchCnNumber
            }
            if (branch?.patientCodeAppointment === '2') {
              return option.snCode
            }
          }
          if (option.appointmentId === 0) {
            return (
              <Box key="All" component="li" {...props}>
                <p className={'m-0'} style={{ fontWeight: 500 }}>
                  {t('HOME.TITLE.SELECT_SCHEDULE')}
                </p>
              </Box>
            )
          } else {
            return (
              <Box component="li" {...props}>
                <Avatar alt={option.patientFullname} src={renderImage(option.patientProfile)} sx={{ width: 40, height: 40, border: `2px solid ${memberTypeOutline(option.rank)}`, backgroundColor: colors.white }}>
                  <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
                </Avatar>
                <div className={'ml-3'}>
                  <p className={'mb-0'}>
                    {handlePatientCode() ? `${handlePatientCode()} |` : ''} {option.patientFullname}
                  </p>
                  <p className={'mb-0 d-flex align-item-center'} style={{ fontSize: 13, color: colors.disabledGray }}>
                    {option.doctorFullname} {moment(option.timeStart, 'HH:mm').format('HH.mm')} {t('TIME_UNIT')}
                    {renderAppointStatus(option.statusAppointment)}
                  </p>
                </div>
              </Box>
            )
          }
        }}
        value={selected}
        defaultValue={selected}
        getOptionLabel={(option) => {
          const handlePatientCode = () => {
            if (branch?.patientCodeAppointment === '1') {
              return option.cnNumber
            }
            if (branch?.patientCodeAppointment === '0') {
              return option.branchCnNumber
            }
            if (branch?.patientCodeAppointment === '2') {
              return option.snCode
            }
          }
          if (option.appointmentId === '') {
            return ''
          } else if (option.appointmentId === 0) {
            return `${t('HOME.TITLE.SELECT_SCHEDULE')}`
          } else {
            return `${handlePatientCode() ? `${handlePatientCode()} |` : ''} ${option.patientFullname} (${option.doctorFullname} ${moment(option.timeStart, 'HH:mm').format('HH.mm')} ${t('TIME_UNIT')})`
          }
        }}
        onchange={(e, value: any, reason: string) => {
          setSelected(value)
          props.onchange(e, value)
        }}
        filterOptions={(option) => option.cnNumber + option.branchCnNumber + option.patientFullname + option.doctorFullname}
        noOptionsText={t('HOME.TITLE.FILTER_NOT_FOUND')}
        classesOption={'indexBelowSidebar'}
        disableClearable
        disabledBorder
        disabled={props.disabled}
      ></AutocompleteSelect>
    </AutoCompleteWrap>
  )
}
