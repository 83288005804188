import React from 'react'

type IconProps = {
    width?: string
    height?: string
    fill?: string
}

const IconMoveTableRow = (props: IconProps) => {
    return (
        <div>
            <svg width={props?.width || "24"} height={props?.height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 11C10.55 11 11 10.55 11 10C11 9.45 10.55 9 10 9C9.45 9 9 9.45 9 10C9 10.55 9.45 11 10 11ZM10 15C10.55 15 11 14.55 11 14C11 13.45 10.55 13 10 13C9.45 13 9 13.45 9 14C9 14.55 9.45 15 10 15ZM10 7C10.55 7 11 6.55 11 6C11 5.45 10.55 5 10 5C9.45 5 9 5.45 9 6C9 6.55 9.45 7 10 7ZM10 11C10.55 11 11 10.55 11 10C11 9.45 10.55 9 10 9C9.45 9 9 9.45 9 10C9 10.55 9.45 11 10 11ZM10 15C10.55 15 11 14.55 11 14C11 13.45 10.55 13 10 13C9.45 13 9 13.45 9 14C9 14.55 9.45 15 10 15ZM10 7C10.55 7 11 6.55 11 6C11 5.45 10.55 5 10 5C9.45 5 9 5.45 9 6C9 6.55 9.45 7 10 7Z" fill={props?.fill || "#5A5A5A"} />
                <path d="M14 11C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9C13.45 9 13 9.45 13 10C13 10.55 13.45 11 14 11ZM14 15C14.55 15 15 14.55 15 14C15 13.45 14.55 13 14 13C13.45 13 13 13.45 13 14C13 14.55 13.45 15 14 15ZM14 7C14.55 7 15 6.55 15 6C15 5.45 14.55 5 14 5C13.45 5 13 5.45 13 6C13 6.55 13.45 7 14 7ZM14 11C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9C13.45 9 13 9.45 13 10C13 10.55 13.45 11 14 11ZM14 15C14.55 15 15 14.55 15 14C15 13.45 14.55 13 14 13C13.45 13 13 13.45 13 14C13 14.55 13.45 15 14 15ZM14 7C14.55 7 15 6.55 15 6C15 5.45 14.55 5 14 5C13.45 5 13 5.45 13 6C13 6.55 13.45 7 14 7Z" fill={props?.fill || "#5A5A5A"} />
                <path d="M11 18C11 18.55 10.55 19 10 19C9.45 19 9 18.55 9 18C9 17.45 9.45 17 10 17C10.55 17 11 17.45 11 18Z" fill={props?.fill || "#5A5A5A"} />
                <path d="M15 18C15 18.55 14.55 19 14 19C13.45 19 13 18.55 13 18C13 17.45 13.45 17 14 17C14.55 17 15 17.45 15 18Z" fill={props?.fill || "#5A5A5A"} />
            </svg>
        </div>
    )
}

export default IconMoveTableRow