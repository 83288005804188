
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, TableSortLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { UseSnCode } from 'utils/useSnCode'
import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'

//component
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import FucntionPrint from "component/Print/print"
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

//utils
import { getBranch, numberFormat } from 'utils/app.utils'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'

//API
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import Loading from 'component/Loading'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import { ExportProps } from '../report-constants'
import OperativeApi from 'api/setting/treatments/operative.api'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import InputTextSearch from 'component/Input/InputTextSearch'
import TreatmentRightBalanceList from './treatmentRightBalanceList'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { SORT_TYPE } from 'constants/common'
import TreatmentRightApi from 'api/setting/treatment-right.api'

export default function TreatmentRightBalance() {
    const { t } = useTranslation()
    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null)
    const [branches, setBranches] = useState<any[]>([])
    const [treatmentRight, setTreatmentRight] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true);

    const [page, setPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(10)
    const [search, setSearch] = useState<string>('')

    const [branchId, setBranchId] = useState<any>(0)
    const [treatmentRightId, setTreatmentRightId] = useState<any>(null)

    const [data, setData] = useState<any>([]);
    const [dataExport, setDataExport] = useState<any>([]);
    const [fetch, setFetch] = useState<boolean>(false)

    const [rowCount, setRowCount] = useState<number>(0);

    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, [])

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_BALANCE`)}`,
        sheetName: `${t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_BALANCE`)}`
    })

    const getAllFilter = () => {
        return [
            {
                label: t('INPUT.SEARCH'),
                value: search
            }, {
                label: t('REPORT.FILTER.BRANCH'),
                value: _.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn') || t('REPORT.REPORT_GROUP.ALL')
            },
            {
                label: t('REPORT.FILTER.TREATMENT'),
                value: treatmentRightId ? treatmentRightId.treatmentRightName : t('REPORT.REPORT_GROUP.ALL')
            }]
    }

    const loadDataExport = useCallback(async () => {
        setLoading(true)
        let condition: any = {
            page: page,
            pageLimit: limit,
            export: 1
        }

        if (branchId && branchId !== 0) {
            condition = { ...condition, branchId }
        }
        if (treatmentRightId) {
            if (treatmentRightId.treatmentRightId !== 0) condition = { ...condition, treatmentRightId: treatmentRightId.treatmentRightId }

        }
        if (!_.isEmpty(search)) {
            condition.search = search
        }

        ReportApi.reportTreatmentRightBalance(condition)
            .then(({ headers, data }) => {
                setDataExport(data)
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
                setFetch(false)
            })

    }, [page, limit, fetch,])


    const fetchTreatmentRightBalance = useCallback(() => {
        setLoading(true)
        let condition: any = {
            page: page, pageLimit: limit
        }

        if (branchId && branchId !== 0) {
            condition = { ...condition, branchId }
        }
        if (treatmentRightId) {
            if (treatmentRightId.treatmentRightId !== 0) condition = { ...condition, treatmentRightId: treatmentRightId.treatmentRightId }

        }
        if (!_.isEmpty(search)) {
            condition.search = search
        }

        ReportApi.reportTreatmentRightBalance(condition)
            .then(({ headers, data }) => {
                setData(data)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
                setFetch(false)
            })

    }, [page, limit, fetch])

    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'TREATMENT_RIGHT_BALANCE') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        /** MASTER DATA */
        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        TreatmentRightApi.findAll({ export: 1 })
            .then(({ data }) => setTreatmentRight([{ treatmentRightName: t('REPORT.REPORT_GROUP.ALL'), treatmentRightId: 0 }, ...data]))
            .catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    useEffect(() => {
        fetchTreatmentRightBalance()
    }, [fetchTreatmentRightBalance])
    const handleChangePage = (val: number) => setPage(val)
    const handleChangeRowsPerPage = (limit: number) => {
        setLimit(limit)
    }
    useEffect(() => {
        loadDataExport()
    }, [loadDataExport])



    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.#', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.CN', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.BRANCH_CN_NUMBER', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.SN_CODE', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.PATIENT', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.TREATMENT_RIGHT', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.UNIT', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.AMOUNT', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.AMOUNT_USED', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.BALANCE', align: 'center', class: 'text-nowrap', },

    ]
    if (!snType) {
        headCells.splice(3, 1)
    }
    const renderData = (data: any, no: number) => {
        const columns = [
            { option: 'TEXT', align: 'center', label: no + 1, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.cnNumber, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.branchCnNumber, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.snCode ?? '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.patientFullName ?? '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.treatmentRightName ?? '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.unit || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.unit === t('REPORT.UNIT.BATH') ? numberFormat(data.amount) : data.amount, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.unit === t('REPORT.UNIT.BATH') ? numberFormat(data.amountUsed) : data.amountUsed, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.unit === t('REPORT.UNIT.BATH') ? numberFormat(data.balance) : data.balance, class: 'text-nowrap' },
        ]
        if (!snType) {
            columns.splice(3, 1)
        }
        return <>
            <TableRowCommon
                key={no.toString()}
                id={no.toString()}
                obj={data}
                columns={columns} />
        </>
    }

    return (
        <Card className={'border-0 h-100'}>
            <HeaderCard text={t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_BALANCE`)} />
            <Card.Body>
                <Card.Title className={'mb-0'}>
                    <Row className='mx-0'>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <InputTextSearch
                                keyInput={'search'}
                                label={t('INPUT.SEARCH')}
                                value={search}
                                onchange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                onClear={(event) => {
                                    setSearch('')
                                }}
                            />
                        </Col>

                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setBranchId(e.target.value)
                                }}
                                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn') || t('REPORT.REPORT_GROUP.ALL')} `}
                                label={''}
                                selectId="select-branch"
                                labelId="label-branch"
                                value={branchId}
                                options={
                                    _.map([{ branchName: t('REPORT.REPORT_GROUP.ALL'), branchId: 0 }, ...branches], (b: BranchInterface, index) => {
                                        return (
                                            <MenuItem style={{ overflow: 'hidden' }} key={b.branchId} value={b.branchId}>
                                                {b.branchName || b.branchNameEn}
                                            </MenuItem>
                                        )
                                    })
                                }
                                style={{ overflow: 'hidden' }}
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setTreatmentRightId(e.target.value)
                                }}
                                renderValue={() => `${t('REPORT.FILTER.TREATMENT')}: ${treatmentRightId ? treatmentRightId.treatmentRightName : t('REPORT.REPORT_GROUP.ALL')}`}
                                label={''}
                                selectId="select-treatmentRight"
                                labelId="label-treatmentRight"
                                value={treatmentRightId || { treatmentRightName: t('REPORT.REPORT_GROUP.ALL'), treatmentRightId: 0 }}
                                options={
                                    _.map([...treatmentRight], (b: any, index) => {
                                        return (
                                            <MenuItem key={b.treatmentRightId} value={b}>
                                                {b.treatmentRightName || b.treatmentRightNameEN}
                                            </MenuItem>
                                        )
                                    })
                                }
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>


                        <Col md={1} xl={1} className={'pt-0'}>
                            <ButtonCustom onClick={() => {
                                setFetch(true);
                                setPage(1);
                            }}
                                textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                        </Col>
                        <Col className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                            <UseStyled.DropdownDocument>
                                <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                    {t('PRINT_DOCUMENT')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={onDownload}>
                                        {t('Excel')}
                                    </Dropdown.Item>
                                    <FucntionPrint
                                        content={() => componentRef.current}
                                        docTitle={t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_BALANCE`)}
                                        removeIfram
                                        trigger={() => <Dropdown.Item >
                                            {t('PDF')}
                                        </Dropdown.Item>}
                                        style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                    />
                                </Dropdown.Menu>
                            </UseStyled.DropdownDocument>
                        </Col>
                    </Row>
                </Card.Title>

                <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                    <TreatmentRightBalanceList
                        page={page}
                        pageLimit={limit}
                        rowCount={rowCount}
                        data={data}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage}
                        onRequestSort={() => { return }}
                    ></TreatmentRightBalanceList>

                </Box>

            </Card.Body>
            <Loading show={loading} type='fullLoading' />

            <div className="print-show" ref={componentRef}>
                <ExportData
                    componant={
                        <>
                            <span style={{ fontWeight: 'bold' }}>{t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_BALANCE`)}</span>
                            <table>
                                <tr>
                                    {getAllFilter().map((filter: any, index: number) => (
                                        <TableSortLabel key={index} >
                                            <td className='mr-2' style={{ textAlign: 'left', fontSize: 10, fontWeight: 800 }}>{t(filter.label)} : </td>
                                            <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500 }}>{filter.value}</td>
                                        </TableSortLabel >
                                    ))}
                                </tr>
                            </table>
                            <UseStyled.Styles className="pt-3">
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            {_.map(headCells, (val: any, index: number) => {
                                                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(dataExport, (d: any, index: number) => {
                                            return (
                                                renderData(d, index)
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </UseStyled.Styles>
                        </>
                    }
                />
            </div>
        </Card>
    )
}

export function ExportData(props: ExportProps) {
    return <>{props.componant}</>
}