import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, Grid, TableSortLabel, Tooltip, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'
import { dateTimeToView, dateToView } from 'utils/date.utils'

export interface DoctorFeeComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
  componentRef: any
}

interface AccountingCodeIF {
  createdAt: string
  date: string
  paymentNumber: string
  statusOrder: string
  cnNumber: string
  branchCnNumber: string
  snCode: string
  patientFullName: string
  doctorFullName: string
  code: string
  itemName: string
  price: number
  qty: number
  total: number
  overdue: number
  totalOriginal: number
  dfPercentOriginal: number
  dfOriginal: number
  cfOriginal: number
  dfBahtOriginal: number
  cfPercentOriginal: number
  cfBahtOriginal: number
  dfDiscount: number
  cfDiscount: number
  dfDiscountTr: number
  cfDiscountTr: number
  discountTotal: number
  additionalTotal: number
  dfNet: number
  totalNonVat: number
  totalBeforeVat: number
  totalVat: number
  cfNet: number
  totalNet: number
  amountPaid: number
  amountPaidOverdue: number
  receiptAdvance: number
  amountPaidTotal: number
  amountCash: number
  amountTransfer: number
  amountCredit: number
  amountDebit: number
  amountVoucher: number
  amountAdvance: number
  amountTreatmentRight: number
  receiptAdvanceCash: number
  receiptAdvanceTransfer: number
  receiptAdvanceCredit: number
  receiptAdvanceDebit: number
  creditCost: number
  creditCardFee: number
  dfCreditCost: number
  cfCreditCost: number
  totalCreditPercent: number
  dfTotalNet: number
  cfTotalNet: number
  overdueDf: number
  overdueCf: number
  balance: number
  code_01: number
  code_02: number
  code_03: number
  code_04: number
  code_05: number
  code_06: number
  code_07: number
  code_08: number
  code_09: number
  code_10: number
  code_11: number
  code_12: number
  code_13: number
  code_14: number
  code_15: number
  code_16: number
  staff: string
  remark: string
  cancelBy: string
  cancelRemark: string

}
export default function AccountingCodeList(props: DoctorFeeComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: '2', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: '3', disablePadding: false, labelKey: 'เลขที่ใบเสร็จ', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: '4', disablePadding: false, labelKey: 'สถานะใบเสร็จ', align: 'center', class: 'text-nowrap', width: '250px' },
    { id: '5', disablePadding: false, labelKey: 'รหัสผู้ป่วย', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '6', disablePadding: false, labelKey: 'รหัสผู้ป่วยในสาขา', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '7', disablePadding: false, labelKey: 'รหัสผู้ป่วยพิเศษ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '8', disablePadding: false, labelKey: 'ชื่อผู้ป่วย', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '35', disablePadding: false, labelKey: 'เงินสด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '36', disablePadding: false, labelKey: 'เงินโอน', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '37', disablePadding: false, labelKey: 'เดบิด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '38', disablePadding: false, labelKey: 'เครดิต', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '39', disablePadding: false, labelKey: 'Voucher', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '40', disablePadding: false, labelKey: 'สิทธิการรักษา', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Clear Advance', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Balance', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Total', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 1', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 2', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 3', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 4', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 5', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 6', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 7', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 8', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 9', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 10', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 11', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 12', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 13', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 14', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 15', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '41', disablePadding: false, labelKey: 'Code 16', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '51', disablePadding: false, labelKey: 'DF', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '52', disablePadding: false, labelKey: 'DF Balance', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '53', disablePadding: false, labelKey: 'Advance', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '54', disablePadding: false, labelKey: 'Clear Balance', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '55', disablePadding: false, labelKey: 'Credit Fee', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '55', disablePadding: false, labelKey: 'Summary', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '56', disablePadding: false, labelKey: 'ผู้บันทึก', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '57', disablePadding: false, labelKey: 'รายละเอียด', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '58', disablePadding: false, labelKey: 'หมายเหตุการยกเลิก', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '59', disablePadding: false, labelKey: 'ผู้ยกเลิก', align: 'center', class: 'text-nowrap', width: '350px' }
  ]

  if (!snType) {
    headCells.splice(5, 1)
  }
  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const accounting: AccountingCodeIF = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: dateToView(accounting.date), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.paymentNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: t(`REPORT.BILL_STATUS.${accounting.statusOrder}`), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.cnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.branchCnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.snCode, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.patientFullName, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountCash), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountTransfer), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountDebit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountCredit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountVoucher), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountTreatmentRight), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountAdvance), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.balance, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.total, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_01, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_02, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_03, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_04, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_05, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_06, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_07, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_08, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_09, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_10, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_11, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_12, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_13, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_14, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_15, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.code_16, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.dfNet, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.overdueDf, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.receiptAdvance, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountPaidOverdue, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.creditCost, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(accounting.amountPaid, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.staff, class: 'text-nowrap' },
          {
            option: 'COMPONENT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16, width: '350px', },
            component: <Tooltip title={accounting.cancelRemark} placement="top">
              <UseStyled.StylesRemark style={{ wordWrap: 'break-word', width: '350px' }}>{accounting.remark || '-'}</UseStyled.StylesRemark>
            </Tooltip>,
            class: 'text-wrap'
          },
          {
            option: 'COMPONENT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16, width: '350px !important', },
            component: <Tooltip title={accounting.cancelRemark} placement="top">
              <UseStyled.StylesRemark style={{ wordWrap: 'break-word', width: '350px' }}>{accounting.cancelRemark || '-'}</UseStyled.StylesRemark>
            </Tooltip>,
            class: 'text-wrap'
          },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: accounting.cancelBy || '-', class: 'text-nowrap' }
        ]
      }
      if (!snType) {
        objRenderData.columns.splice(5, 1)
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: snType ? 6 : 5 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCash') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTransfer') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountDebit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCredit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountVoucher') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTreatmentRight') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'overdue') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_01') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_02') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_03') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_04') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_05') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_06') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_07') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_08') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_09') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_10') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_11') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_12') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_13') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_14') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_15') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'code_16') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfNet') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'overdueDf') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaidOverdue') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'creditCost') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaid') || 0), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 4 }
        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.dataExport, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <Box>
        <br />
        <Grid container spacing={2} className="mt-1 w-100">
          {renderTableSum({ rowsData: props.dataExport })}
          {renderTableSum2({ rowsData: props.dataExport })}
          {renderTableSum3({ rowsData: props.dataExport })}
          {renderTableSum4({ rowsData: props.dataExport })}
        </Grid>
      </Box>
      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.ACCOUNTING_CODE`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      [
                        _.map(props.dataExport, (d: any, index: number) => {
                          return renderData(d, index, false, 'data')
                        }),
                        renderData(props.dataExport, 0, false, 'sum')
                      ]
                    }
                  </tbody>
                </table>
              </UseStyled.Styles>
              <br />
              <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                <tbody>
                  <tr>
                    <td>{renderTableSum({ rowsData: props.dataExport }, true)}</td>
                    <td></td>
                    <td></td>
                    <td>{renderTableSum2({ rowsData: props.dataExport }, true)}</td>
                  </tr>
                  <tr>
                    <td>{renderTableSum3({ rowsData: props.dataExport }, true)}</td>
                    <td></td>
                    <td></td>
                    <td>{renderTableSum4({ rowsData: props.dataExport }, true)}</td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        />
      </div>
    </>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  )
}


export interface SumProps {
  exportData?: boolean
  headCells?: any
  rowsData?: any
}

const renderTableSum = (props: SumProps, isExport = false) => {
  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'Dr', align: 'left', class: 'text-nowrap', width: '50px' },
    { id: '2', disablePadding: false, labelKey: 'สรุป', align: 'right', class: 'text-nowrap', width: '50px' },
  ]

  const sum = [
    { label: "Cash", value: numberFormat(_.sumBy(props.rowsData, 'amountCash')) },
    { label: "Transfer", value: numberFormat(_.sumBy(props.rowsData, 'amountTransfer')) },
    { label: "Debit", value: numberFormat(_.sumBy(props.rowsData, 'amountDebit')) },
    { label: "Credit", value: numberFormat(_.sumBy(props.rowsData, 'amountCredit')) },
    { label: "Voucher", value: numberFormat(_.sumBy(props.rowsData, 'amountVoucher')) },
    { label: "Treatment Rights", value: numberFormat(_.sumBy(props.rowsData, 'amountTreatmentRight')) },
    { label: "Clear Advance", value: numberFormat(_.sumBy(props.rowsData, 'amountAdvance')) },
    {
      label: "Total", value: numberFormat(
        (_.sumBy(props.rowsData, 'amountCash') || 0) +
        (_.sumBy(props.rowsData, 'amountTransfer') || 0) +
        (_.sumBy(props.rowsData, 'amountDebit') || 0) +
        (_.sumBy(props.rowsData, 'amountCredit') || 0) +
        (_.sumBy(props.rowsData, 'amountVoucher') || 0) +
        (_.sumBy(props.rowsData, 'amountTreatmentRight') || 0) +
        (_.sumBy(props.rowsData, 'amountAdvance') || 0)
      )
    },
  ]

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(sum, (val, i) => {
            return <>
              <TableRowExport key={`total-${i}`} id={`total-${i}`} obj={[]}
                columns={[
                  { option: 'TEXT', align: 'left', label: val.label, width: '5px' },
                  { option: 'TEXT', align: 'right', label: val.value, width: '50px' }
                ]} />
            </>
          })}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={3} md={6} sm={12} className="order-sm-last w-100 ">
    <TableCustom
      tableFixedWidth
      tableMinWidth={150}
      hidePagination
      page={1}
      pageLimit={10}
      sortType={''}
      sortBy={''}
      rowCount={0}
      onSort={() => { return false }}
      setPageLimit={() => { return false }}
      setPage={() => { return false }}
      headCells={headCells}
      rowsData={_.map(sum, (val, i) => <TableRowCommon key={`total-${i}`} id={`total-${i}`} obj={[]} columns={[
        { option: 'TEXT', align: 'left', label: val.label, width: '5px' },
        { option: 'TEXT', align: 'right', label: val.value, width: '50px' }
      ]} />)}
      customScroll
    />
  </Grid>
}

const renderTableSum2 = (props: SumProps, isExport = false) => {

  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'Cr', align: 'left', class: 'text-nowrap', },
    { id: '2', disablePadding: false, labelKey: 'สรุป', align: 'right', class: 'text-nowrap', },
  ]

  const sum = [
    { label: "Code 1", value: numberFormat(_.sumBy(props.rowsData, 'code_01')) },
    { label: "Code 2", value: numberFormat(_.sumBy(props.rowsData, 'code_02')) },
    { label: "Code 3", value: numberFormat(_.sumBy(props.rowsData, 'code_03')) },
    { label: "Code 4", value: numberFormat(_.sumBy(props.rowsData, 'code_04')) },
    { label: "Code 5", value: numberFormat(_.sumBy(props.rowsData, 'code_05')) },
    { label: "Code 6", value: numberFormat(_.sumBy(props.rowsData, 'code_06')) },
    { label: "Code 7", value: numberFormat(_.sumBy(props.rowsData, 'code_07')) },
    { label: "Code 8", value: numberFormat(_.sumBy(props.rowsData, 'code_08')) },
    { label: "Code 9", value: numberFormat(_.sumBy(props.rowsData, 'code_09')) },
    { label: "Code 10", value: numberFormat(_.sumBy(props.rowsData, 'code_10')) },
    { label: "Code 11", value: numberFormat(_.sumBy(props.rowsData, 'code_11')) },
    { label: "Code 12", value: numberFormat(_.sumBy(props.rowsData, 'code_12')) },
    { label: "Code 13", value: numberFormat(_.sumBy(props.rowsData, 'code_13')) },
    { label: "Code 14", value: numberFormat(_.sumBy(props.rowsData, 'code_14')) },
    { label: "Code 15", value: numberFormat(_.sumBy(props.rowsData, 'code_15')) },
    { label: "Code 16", value: numberFormat(_.sumBy(props.rowsData, 'code_16')) },
    { label: "DF", value: numberFormat(_.sumBy(props.rowsData, 'dfNet')) },
    { label: "Advance", value: numberFormat(_.sumBy(props.rowsData, 'receiptAdvance')) },
    { label: "Clear Balance", value: numberFormat(_.sumBy(props.rowsData, 'amountPaidOverdue')) },
    { label: "Total", value: numberFormat((_.sumBy(props.rowsData, 'amountPaid') || 0)) },
  ]

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(sum, (val, i) => {
            return <>
              <TableRowExport key={`total-${i}`} id={`total-${i}`} obj={[]}
                columns={[
                  { option: 'TEXT', align: 'left', label: val.label, width: '5px' },
                  { option: 'TEXT', align: 'right', label: val.value, width: '50px' }
                ]} />
            </>
          })}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={3} md={6} sm={12} className="order-sm-last  ">
    <TableCustom
      tableFixedWidth
      tableMinWidth={150}
      hidePagination
      page={1}
      pageLimit={10}
      sortType={''}
      sortBy={''}
      rowCount={0}
      onSort={() => { return false }}
      setPageLimit={() => { return false }}
      setPage={() => { return false }}
      headCells={headCells}
      rowsData={_.map(sum, (val, i) => <TableRowCommon key={`total-${i}`} id={`total-${i}`} obj={[1]} columns={[
        { option: 'TEXT', align: 'left', label: val.label },
        { option: 'TEXT', align: 'right', label: val.value }
      ]} />)}
      customScroll
    />
  </Grid>
}
const renderTableSum3 = (props: SumProps, isExport = false) => {
  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'Dr', align: 'left', class: 'text-nowrap', },
    { id: '2', disablePadding: false, labelKey: 'สรุป', align: 'right', class: 'text-nowrap', },
  ]

  const sum = [
    { label: "Balance", value: numberFormat(_.sumBy(props.rowsData, 'balance')) },
    { label: "Total", value: numberFormat((_.sumBy(props.rowsData, 'balance') || 0)) },
  ]

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(sum, (val, i) => {
            return <>
              <TableRowExport key={`total-${i}`} id={`total-${i}`} obj={[]}
                columns={[
                  { option: 'TEXT', align: 'left', label: val.label, width: '5px' },
                  { option: 'TEXT', align: 'right', label: val.value, width: '50px' }
                ]} />
            </>
          })}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={3} md={6} sm={12} className="order-sm-last  ">
    <TableCustom
      tableFixedWidth
      tableMinWidth={150}
      hidePagination
      page={1}
      pageLimit={10}
      sortType={''}
      sortBy={''}
      rowCount={0}
      onSort={() => { return false }}
      setPageLimit={() => { return false }}
      setPage={() => { return false }}
      headCells={headCells}
      rowsData={_.map(sum, (val, i) => <TableRowCommon key={`total-${i}`} id={`total-${i}`} obj={[1]} columns={[
        { option: 'TEXT', align: 'left', label: val.label },
        { option: 'TEXT', align: 'right', label: val.value }
      ]} />)}
      customScroll
    />
  </Grid>
}
const renderTableSum4 = (props: SumProps, isExport = false) => {

  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'Cr', align: 'left', class: 'text-nowrap', },
    { id: '2', disablePadding: false, labelKey: 'สรุป', align: 'right', class: 'text-nowrap', },
  ]

  const sum = [
    { label: "Code 1", value: numberFormat(_.sumBy(props.rowsData, 'code_01_balance')) },
    { label: "Code 2", value: numberFormat(_.sumBy(props.rowsData, 'code_02_balance')) },
    { label: "Code 3", value: numberFormat(_.sumBy(props.rowsData, 'code_03_balance')) },
    { label: "Code 4", value: numberFormat(_.sumBy(props.rowsData, 'code_04_balance')) },
    { label: "Code 5", value: numberFormat(_.sumBy(props.rowsData, 'code_05_balance')) },
    { label: "Code 6", value: numberFormat(_.sumBy(props.rowsData, 'code_06_balance')) },
    { label: "Code 7", value: numberFormat(_.sumBy(props.rowsData, 'code_07_balance')) },
    { label: "Code 8", value: numberFormat(_.sumBy(props.rowsData, 'code_08_balance')) },
    { label: "Code 9", value: numberFormat(_.sumBy(props.rowsData, 'code_09_balance')) },
    { label: "Code 10", value: numberFormat(_.sumBy(props.rowsData, 'code_10_balance')) },
    { label: "Code 11", value: numberFormat(_.sumBy(props.rowsData, 'code_11_balance')) },
    { label: "Code 12", value: numberFormat(_.sumBy(props.rowsData, 'code_12_balance')) },
    { label: "Code 13", value: numberFormat(_.sumBy(props.rowsData, 'code_13_balance')) },
    { label: "Code 14", value: numberFormat(_.sumBy(props.rowsData, 'code_14_balance')) },
    { label: "Code 15", value: numberFormat(_.sumBy(props.rowsData, 'code_15_balance')) },
    { label: "Code 16", value: numberFormat(_.sumBy(props.rowsData, 'code_16_balance')) },
    { label: "DF", value: numberFormat(_.sumBy(props.rowsData, 'overdueDf')) },
    { label: "Total", value: numberFormat((_.sumBy(props.rowsData, 'overdueCf') || 0) + (_.sumBy(props.rowsData, 'overdueDf') || 0)) },
  ]

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(sum, (val, i) => {
            return <>
              <TableRowExport key={`total-${i}`} id={`total-${i}`} obj={[]}
                columns={[
                  { option: 'TEXT', align: 'left', label: val.label, width: '5px' },
                  { option: 'TEXT', align: 'right', label: val.value, width: '50px' }
                ]} />
            </>
          })}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={3} md={6} sm={12} className="order-sm-last  ">
    <TableCustom
      tableFixedWidth
      tableMinWidth={150}
      hidePagination
      page={1}
      pageLimit={20}
      sortType={''}
      sortBy={''}
      rowCount={0}
      onSort={() => { return false }}
      setPageLimit={() => { return false }}
      setPage={() => { return false }}
      headCells={headCells}
      rowsData={_.map(sum, (val, i) => <TableRowCommon key={`total-${i}`} id={`total-${i}`} obj={[1]} columns={[
        { option: 'TEXT', align: 'left', label: val.label },
        { option: 'TEXT', align: 'right', label: val.value }
      ]} />)}
      customScroll
    />
  </Grid>
}