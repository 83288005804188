import { menuSeconds } from 'constants/menus'
import TreatmentRight from 'features/setting/treatment-right/treatment-right/treatment-right'

const routeTreatmentRight = [
  {
    ...menuSeconds.TREATMENT_RIGHT,
    component: TreatmentRight,
    appBar: true
  }
]

export default routeTreatmentRight
