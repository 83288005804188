import { styled } from '@mui/material/styles'
import { Box, Menu } from '@mui/material'
import { colors } from 'constants/theme'

export const CustomCalender = styled(Box)(({ theme }) => ({
  '.calendar-root': {
    minWidth: '1280px',
    '.days-of-week,.days-grid': {
      width: '100%',
      gridTemplateColumns: 'repeat(7, minmax(0,1fr))'
    },
    '.days-of-week': {
      backgroundColor: colors.white,
      '.day-of-week-header-cell': {
        fontSize: '18px',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        [theme.breakpoints.down('lg')]: {
          fontSize: '16px',
        }
      },
    },
    '.days-grid': {
      '.day-grid-item-container': {
        '.day-content-wrapper': {
          '.day-grid-item-header': {
            fontWeight: 400
          },
        }
      }
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: '650px',
    }
  },
}))

export const CustomCalenderOverall = styled(CustomCalender)(({ theme }) => ({
  '.calendar-root': {
    '.days-grid': {
      '.day-grid-item-container': {
        '.day-content-wrapper': {
          '.container-item': {
            display: 'flex',
            flexDirection: 'column',
            '.doctor-list': {
              overflowY: 'auto',
              gap: 'unset',
              '&::-webkit-scrollbar': {
                height: '3px',
                width: '3px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: colors.disabledGray,
                borderRadius: '50px'
              },
              '.doctor-list-item': {
                '.MuiAvatar-root': {
                  [theme.breakpoints.down('xl')]: {
                    width: '32px',
                    height: '32px'
                  },
                  [theme.breakpoints.down('lg')]: {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  },
                },
                [theme.breakpoints.down('lg')]: {
                  flex: '0 0 25%',
                  maxWidth: '25%',
                  margin: 0,
                  padding: 2,
                  '> div': {
                    position: 'relative',
                    paddingTop: '100% !important',
                    height: '100%',
                    width: '100%',
                  }
                },
                [theme.breakpoints.down('md')]: {
                  flex: '0 0 33%',
                  maxWidth: '33%',
                },
              },
              [theme.breakpoints.down('lg')]: {
                padding: 2,
              },
            },
            '.btn-doctor-over': {
              lineHeight: 'normal',
              '.MuiButton-endIcon': {
                marginRight: 0,
                marginLeft: 4,
                svg: {
                  [theme.breakpoints.down(1536)]: {
                    fontSize: '16px'
                  }
                }
              },
              [theme.breakpoints.down('lg')]: {
                minWidth: 'unset !important',
                flex: '0 0 25%',
                maxWidth: '25% !important',
                width: '100% !important',
                height: 'auto !important',
                margin: '0 !important',
                padding: '2px !important',
                border: 'none !important',
                '> div': {
                  paddingTop: '100%',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  '> span': {
                    fontSize: '14px',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    border: `1px solid ${colors.themeSecondColor}`,
                    borderRadius: '100%',
                    [theme.breakpoints.down(1024)]: {
                      fontSize: '12px',
                    }
                  }
                },
              },
              [theme.breakpoints.down('md')]: {
                flex: '0 0 33%',
                maxWidth: '33% !important',
              },
            }
          }
        }
      }
    },
  },
  [theme.breakpoints.up(1536)]: {
    '&:not(.has-appointment-week)': {
      marginLeft: '40px !important',
      marginRight: '40px !important',
    }
  },
  [theme.breakpoints.down('lg')]: {
    '&.has-appointment-week': {
      '.btn-doctor-over': {
        '> div': {
          '> span': {
            fontSize: '8px !important',
          }
        }
      }
    },
  }
}))

export const CustomCalenderMonth = styled(CustomCalender)(({ theme }) => ({
  [theme.breakpoints.up(1536)]: {
    marginLeft: '40px !important',
    marginRight: '40px !important',
  }
}))

export const CustomCalenderDoctorSchedule = styled(CustomCalenderOverall)(({ theme }) => ({
  '.calendar-root': {
    '.days-of-week-edit': {
      width: '100%',
      gridTemplateColumns: 'repeat(7, minmax(0,1fr))',
      backgroundColor: colors.white,
      '.day-of-week-header-cell-edit': {
        fontSize: '18px',
        [theme.breakpoints.down('lg')]: {
          fontSize: '16px',
        }
      }
    },
    '.days-grid': {
      '.day-grid-item-container': {
        '.day-content-wrapper': {
          '.dropdown': {
            '.btn-link': {
              boxShadow: 'none !important'
            }
          }
        }
      }
    }
  },
  '&.calender-edit': {
    '.calendar-root': {
      [theme.breakpoints.down('lg')]: {
        minWidth: '1200px'
      }
    }
  }
}))

export const MenuDoctorListOverall = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '8px',
    overflow: 'hidden',
    '.MuiList-root': {
      overflowY: 'auto',
      paddingTop: 0,
      paddingBottom: 0,
      maxWidth: '250px',
      maxHeight: '250px',
      '.MuiMenuItem-root': {
        display: 'flex',
        '.MuiAvatar-root': {
          [theme.breakpoints.down('xl')]: {
            width: '32px',
            height: '32px'
          },
          [theme.breakpoints.down('lg')]: {
            width: '28px',
            height: '28px'
          }
        },
        '.name-doctor': {
          display: 'block',
          [theme.breakpoints.down('lg')]: {
            '> p:first-of-type': {
              fontSize: '14px'
            },
            '> p:last-of-type': {
              fontSize: '12px'
            }
          }
        },
      },
      [theme.breakpoints.down(375)]: {
        maxWidth: '100%'
      }
    }
  }
}))