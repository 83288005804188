import { Box, Button } from '@mui/material';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'

export const TxStyled = styled(Box)(({ theme }) => ({
  '&.tx-disabled': {
    '.btn-group-teeth': {
      color: `${colors.disabledGray} !important`,
      borderColor: `${colors.disabledGray} !important`,
    },
    '.btn-toothChart-operative': {
      opacity: .85
    },
  },
  '.tx-table-disabled': {
    '.MuiButton-root': {
      border: `1px solid ${colors.disabledGray} !important`,
    },
    '.MuiButton-root, .MuiIconButton-root': {
      backgroundColor: `${colors.white} !important`,
      span: {
        color: `${colors.disabledGray} !important`,
      },
      path: {
        fill: `${colors.disabledGray} !important`,
      },
      '&.active ': {
        span: {
          color: `${colors.white} !important`,
        },
        path: {
          fill: `${colors.white} !important`,
        },
        backgroundColor: `${colors.disabledGray} !important`,
      }
    },
    '.MuiTablePagination-root': {
      '.MuiButtonBase-root': {
        color: `${colors.disabledGray} !important`,
        borderColor: `${colors.disabledGray} !important`,
        backgroundColor: `${colors.white} !important`,
        '&.active ': {
          color: `${colors.white} !important`,
          backgroundColor: `${colors.disabledGray} !important`,
        }
      }
    }
  },
  [theme.breakpoints.up(1600)]: {
    display: 'flex'
  }
}))

export const FragmentToothChart = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up(1600)]: {
    paddingRight: 12,
    maxWidth: 770,
  },
}))

export const FragmentTxTable = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '1rem',
  [theme.breakpoints.up(1600)]: {
    paddingLeft: 12,
    marginTop: 0
  }
}))

export const ButtonTreatmentOverview = styled(Button)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: colors.textLightGray,
  borderColor: `${colors.textLightGray} !important`,
  '&.Mui-disabled': {
    borderColor: `${colors.disabledGray} !important`,
    color: `${colors.disabledGray} !important`
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    width: '100%'
  }
}))

export const TypeTabs = styled(Box)(({ theme }) => ({
  margin: '0 -0.25rem',
  maxHeight: 76,
  overflowY: 'auto',
  '> .MuiBox-root': {
    width: '14.28%',
    [theme.breakpoints.down('md')]: {
      width: '16.667%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '33.33%'
    }
  }
}))

export const ButtonType = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 30,
  color: colors.textPrimary,
  borderColor: `${colors.themeSecondColor} !important`,
  '&.active': {
    backgroundColor: colors.themeSecondColor,
    color: colors.white
  }
}))

export const ResponsiveTxTable = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:nth-of-type(1)': {
          width: '6%',
          [theme.breakpoints.down('lg')]: {
            width: 100
          }
        },
        '&:nth-of-type(2)': {
          width: '13%',
          [theme.breakpoints.down('lg')]: {
            width: 'auto'
          }
        },
        '&:nth-of-type(3)': {
          width: 90,
          [theme.breakpoints.down('lg')]: {
            width: 100
          }
        },
        '&:nth-of-type(4)': {
          [theme.breakpoints.down('lg')]: {
            width: 120
          }
        },
        '&:nth-of-type(5)': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 95,
        },
        '&:nth-of-type(6)': {
          width: 110
        },
        '&:last-of-type': {
          width: 85,
          [theme.breakpoints.down('lg')]: {
            width: 80
          }
        },
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        padding: '6px 15px',
        verticalAlign: 'middle',
        '&:nth-of-type(3)': {
          paddingLeft: 4,
          paddingRight: 4,
        },
        '&:last-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
        },
      }
    }
  },
  '.MuiTablePagination-root': {
    '.MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiInputBase-root': {
      display: 'none'
    }
  }
}))

export const ButtonToothChart = styled('div')(({ theme }) => ({
  cursor: 'pointer'
}))
