import { Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'
import StatusSelect from 'features/finance/income-expenses/statusSelector-income-expenses'
import { useState } from 'react'
import IncomeExpensesApi from 'api/finances/income-expenses.api'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { routeName } from 'routes/routes-name'

export const Header = styled(`div`)<{ approval: '0' | '1' }>(({ theme, approval }) => {
  const styleApproval = approval === '1' ? {
    '.header-content': {
      display: 'flex',
      justifyContent: 'space-between',
      '.header-sub': {
        width: 'fit-content !important'
      }
    }
  } : {}
  return {
    backgroundColor: colors.white,
    padding: '20px 15px',
    '& .btn-discard': {
      width: '100%',
      backgroundColor: `${colors.white} !important `,
      color: `${colors.themeSecondColor} !important `,
      border: `solid 1px ${colors.themeSecondColor}  !important`,
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: `${colors.white} !important `,
        color: `${colors.themeSecondColor} !important `,
        border: `solid 1px ${colors.themeSecondColor}  !important`
      }
    },
    '& .menu-navigation': {
      fontSize: '0.875rem'
    },
    ...styleApproval
  }
})

interface NavigatorInterface {
  previousTo: string
  previousTitle: string
  currentTitle: string
}

type HeaderProps = {
  text?: string
  menuNavigation?: any
  inputStatus?: any
  goBack?: string
  disabled?: boolean
  disabledSend?: boolean
  disabledDelete?: boolean
  unDisplayButton?: boolean
  unDisplayCancel?: boolean
  navigator?: NavigatorInterface
  isEditPersonnel?: boolean
  componentBtnCustom?: any
  ondelete?: (val: any) => void
  onSubmit?: (value: any) => void
  onEditPersonnel?: (value: any) => void
  noAction?: boolean
  submitText?: string
  submitTheme?: 'solid' | 'outlined'
  componentButton?: any,
  approval?: boolean
}

const ButtonBase = styled(Button)(({ theme }) => ({
  height: 44,
  fontSize: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  [theme.breakpoints.down('xs')]: {
    fontSize: '0.875rem',
  }
}))

const ButtonSave = styled(ButtonBase)<{ typeTheme?: 'solid' | 'outlined' }>(({ theme, typeTheme }) => ({
  backgroundColor: typeTheme === 'outlined' ? `${colors.white} !important` : `${colors.themeSecondColor} !important`,
  color: typeTheme === 'outlined' ? `${colors.themeSecondColor} !important` : `${colors.white} !important`,
  border: typeTheme === 'outlined' ? `1px solid ${colors.themeSecondColor} !important` : 'none'
}))

const ButtonClear = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${colors.themeSecondColor} !important`,
  color: `${colors.themeSecondColor} !important`
}))

const ButtonEdit = styled(ButtonClear)(({ theme }) => ({
  width: 'auto'
}))

const ButtonDelete = styled(ButtonBase)(({ theme }) => ({
  width: 'auto'
}))

export default function HeaderLabel(props: HeaderProps) {
  const { t } = useTranslation()

  return (
    <Header approval={props?.approval ? '1' : '0'} >
      <Row className={'mx-0 header-content'}>
        <Col className={'header-sub'} xs={12} sm={props.isEditPersonnel || !props.noAction ? 12 : 6} md={6} lg={6}>
          <Row>
            <Col sm={12} className="mt-1 menu-navigation">
              {props.menuNavigation && <div className={` menu-navigation `}>{props.menuNavigation}</div>}
              {props.navigator && (
                <>
                  <Link to={props.navigator.previousTo} className={'ml-0 p-0 link-nav w-auto'} style={{ textDecoration: 'none' }}>
                    {props.navigator.previousTitle}
                  </Link>
                  {props.navigator.currentTitle && (
                    <span>
                      <span className="px-2">{'>'}</span> {props.navigator.currentTitle}
                    </span>
                  )}
                </>
              )}
            </Col>
            <Col sm={12} className="mt-2 mb-1" style={{ fontSize: '1.5rem', fontWeight: 500 }}>
              {props.text}
            </Col>
          </Row>
        </Col>
        {props.noAction !== true && (
          <>
            {props?.componentBtnCustom ? (
              <Col xs={12} sm={12} md={6} lg={6} className="flex-row flex-end ml-auto header-sub">
                {props?.componentBtnCustom}
              </Col>
            ) : (
              <Col xs={12} sm={12} md={6} lg={6} className="flex-row flex-end ml-auto header-sub">
                {props?.componentButton && (
                  props.componentButton
                )}
                {props.isEditPersonnel && (
                  <ButtonEdit onClick={props.onEditPersonnel} type="button" className={'mr-2 mr-md-3 px-2 px-md-3'}>
                    {t('EDIT_PASSWORD')}
                  </ButtonEdit>
                )}
                {props.inputStatus}
                {props.goBack && (
                  <Link to={props.goBack} className={`mr-2 mr-md-3 p-0 w-auto`} style={{ textDecoration: 'none' }}>
                    <ButtonClear style={{ display: props.unDisplayButton ? 'none' : '' }}>{t('BUTTON.DISCARD')}</ButtonClear>
                  </Link>
                )}
                {!props.disabledSend && <ButtonSave onClick={props.onSubmit} disabled={props.disabledSend || false} type="submit" typeTheme={props.submitTheme || 'solid'} style={{ display: props.unDisplayButton ? 'none' : '' }}>
                  {props.submitText || t('BUTTON.SAVE')}
                </ButtonSave>}
                <ButtonDelete
                  disabled={props.disabledDelete || false}
                  style={{ border: `${props.disabledDelete ? `1px solid ${colors.disabledGray}` : `1px solid ${colors.themeThirdColor}`}`, color: `${props.disabledDelete ? colors.disabledGray : colors.themeThirdColor}`, display: props.unDisplayCancel ? '' : 'none', minWidth: 'fit-content' }}
                  onClick={props.ondelete}>
                  {t('BUTTON.CANCEL_DOCUMENT')}
                </ButtonDelete>
              </Col>
            )}
          </>
        )}
      </Row>
    </Header >
  )
}
