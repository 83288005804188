import { styled } from '@mui/material/styles'

export const Layout = styled('div')(({ theme }) => ({
  height: '100%'
}))

export const ResponsiveTable = styled('div')(({ theme }) => ({
  '& .MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '1536px',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:first-child': {
          width: '5.5%'
        },

        '&:nth-of-type(2)': {
          width: '5.5%'
        },

        '&:nth-of-type(3)': {
          width: '8%'
        },

        '&:nth-of-type(4)': {
          width: '10%'
        },

        '&:nth-of-type(5)': {
          width: '18%'
        },

        '&:nth-of-type(6)': {
          width: '13%'
        },

        '&:nth-of-type(7)': {
          width: '9%'
        },

        '&:nth-of-type(8)': {
          width: '9%'
        },

        '&:nth-of-type(9)': {
          width: '12%'
        },

        '&:nth-of-type(10)': {
          width: '12%'
        },
        '&:nth-of-type(11)': {
          width: '6%'
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        verticalAlign: 'top !important',
        paddingTop: 14,
        paddingBottom: 2,
        '&:first-child': {
          paddingTop: 8
        },
      }
    }
  },
}))
