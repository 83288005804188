import IconProps from 'assets/pick/iconProps'

export default function Counter(props: IconProps) {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width="85" height="70" viewBox="0 0 85 70">
      <path data-name="Rectangle 4336" style={{ fill: 'none' }} d="M0 0h85v70H0z" />
      <g data-name="Group 11682">
        <g data-name="Rectangle 3131" style={{ stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: '#fff' }} transform="translate(21.729 11.094)">
          <rect width="41.6" height="48" rx="3" style={{ stroke: 'none' }} />
          <rect x="1.5" y="1.5" width="38.6" height="45" rx="1.5" style={{ fill: 'none' }} />
        </g>
        <g data-name="Rectangle 3132" transform="translate(28.75 11.094)" style={{ stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: '#fff' }}>
          <rect width="27.354" height="10.638" rx="1" style={{ stroke: 'none' }} />
          <rect x="1.5" y="1.5" width="24.354" height="7.638" rx=".5" style={{ fill: 'none' }} />
        </g>
        <g data-name="Icon feather-list">
          <path data-name="Path 5170" d="M12 9h18.538" transform="translate(24.1 18.912)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: 'none' }} />
          <path data-name="Path 5171" d="M12 18h18.538" transform="translate(24.1 20.324)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: 'none' }} />
          <path data-name="Path 5172" d="M12 27h18.538" transform="translate(24.1 21.736)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: 'none' }} />
          <path data-name="Path 5173" d="M4.5 9h0" transform="translate(24.47 18.912)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: 'none' }} />
          <path data-name="Path 5174" d="M4.5 18h0" transform="translate(24.47 20.324)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: 'none' }} />
          <path data-name="Path 5175" d="M4.5 27h0" transform="translate(24.47 21.736)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: 'none' }} />
        </g>
      </g>
    </svg>
  )
}
