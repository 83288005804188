import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { Card } from 'react-bootstrap'
import { Box } from '@mui/material'

export const CardContainer = styled(Box)(({ theme }) => ({
  '*': {
    color: colors.textPrimary
  },
  [theme.breakpoints.down('lg')]: {
    '&::before': {
      content: '""',
      backgroundColor: colors.white70,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
    }
  }
}))

export const TextBranchName = styled(Box)<{ font: number, color: string }>(({ theme, font, color }) => ({
  fontSize: `${font}px`,
  paddingTop: '10px !important',
  fontWeight: 600,
  color: color,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  textOverflow: 'ellipsis',
  height: '46px'
}))

export const CardForm = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  maxWidth: 1040,
  padding: '44px 60px',
  borderRadius: 10,
  backdropFilter: 'blur(5px)',
  backgroundColor: colors.white90,
  boxShadow: `0px 4px 10px ${colors.black30}`,
  [theme.breakpoints.down('lg')]: {
    backgroundColor: colors.white,
  },
  [theme.breakpoints.down('xs')]: {
    paddingTop: 16,
    paddingBottom: 16,
    '.MuiDivider-root': {
      marginTop: 24
    }
  },
}))

export const CustomSlider = styled(Box)(({ theme }) => ({
  minHeight: 150,
  '.slick-track': {
    marginLeft: 0,
    marginRight: 0,
    paddingTop: 4,
    paddingBottom: 4
  },
  '.slick-slide': {
    padding: '0 12px',
    '> div': {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
        margin: 'auto'
      }
    },
  },
  '.slick-arrow': {
    width: 34,
    height: 34,
    transform: 'translateY(-32px)',
    backgroundColor: `${colors.lightGray} !important`,
    borderRadius: 100,
    textAlign: 'center',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      width: 27,
      height: 27,
    }
  },
  '.slick-arrow:before': {
    fontFamily: '"Material Icons" !important',
    fontSize: 27,
    fontWeight: 'bold',
    color: colors.white,
    opacity: 1
  },
  '.slick-arrow.slick-prev:before': {
    content: '"chevron_left"',
    marginRight: 4,
    [theme.breakpoints.down('lg')]: {
      marginRight: 1,
    }
  },
  '.slick-arrow.slick-prev': {
    left: -42,
  },
  '.slick-arrow.slick-next': {
    right: -42,
  },
  '.slick-arrow.slick-next:before': {
    content: '"chevron_right"',
    marginLeft: 4,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 1,
    }
  },
  '.slick-arrow.slick-disabled': {
    visibility: 'hidden'
  },
  '.slick-dots': {
    position: 'relative',
    bottom: 0,
    lineHeight: 0,
    li: {
      width: 15,
      height: 15,
      margin: '16px 8px 0',
      button: {
        width: 15,
        height: 15,
        backgroundColor: colors.disabledGray,
        borderRadius: '100px',
        '&::before': {
          display: 'none'
        }
      },
      '&.slick-active': {
        button: {
          backgroundColor: colors.themeSecondColor,
        }
      },
      [theme.breakpoints.down('md')]: {
        width: 8,
        height: 8,
        button: {
          width: 8,
          height: 8,
        }
      }
    }
  }
}))

export const CustomSliderItem = styled(Box)<{ active: boolean }>(({ theme, active }) => ({
  position: 'relative',
  paddingTop: '100%',
  '> div': {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '100%',
    border: !active ? `5px solid ${colors.transparent}` : `5px solid ${colors.themeSecondColor}`,
    boxShadow: !active ? `0px 0px 6px ${colors.black10}` : 'none',
    '&:hover': {
      boxShadow: `0px 0px 6px ${colors.black30}`
    },
    '> svg': {
      width: 65,
      height: 53
    },
    '.MuiTypography-root': {
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      padding: '4px 0 0',
      fontSize: 14,
    }
  }
}))

export const Logo = styled(`img`)(({ theme }) => ({
  position: 'absolute',
  maxWidth: 150,
  top: 48,
  left: 40,
  zIndex: 0,
}))

export const ImageBackground = styled(`img`)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  zIndex: 0,
  maxWidth: '50%',
  [theme.breakpoints.between('lg', 'xl')]: {
    maxWidth: '285px',
  }
}))

export const ImageBackgroundLeft = styled(ImageBackground)(({ theme }) => ({
  left: 0
}))

export const ImageBackgroundRight = styled(ImageBackground)(({ theme }) => ({
  right: 0
}))

export const CardPickService = styled(Card)(({ theme }) => ({
  width: '1063px',
  paddingBlock: '1rem',
  paddingInline: '3rem',
  borderRadius: 10,
  backgroundColor: colors.white,
  background: colors.white90,
  boxShadow: `0 0px 10px 0 ${colors.black20}`,
  backdropFilter: 'blur( 16.5px )',
  WebkitBackdropFilter: 'blur( 16.5px )',
  border: `1px solid ${colors.white18}`,
  margin: '1rem',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '90%',
    width: '55rem',
    paddingBlock: '1rem',
    paddingInline: '1rem'
  },
  [theme.breakpoints.down('md')]: {
    paddingBlock: '0.25rem',
    paddingInline: '0.25rem'
  }
}))

export const ImageLeft = styled(`div`)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 355,
  maxHeight: 165,
  overflow: 'hidden',
  position: 'absolute',
  left: -20,
  bottom: 0,
  img: {
    width: '100%'
  },
  [theme.breakpoints.down('xl')]: {
    maxWidth: 315,
    maxHeight: 146
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

export const ImageRight = styled(ImageLeft)(({ theme }) => ({
  maxHeight: 167,
  left: 'auto',
  right: -20,
  [theme.breakpoints.down('xl')]: {
    maxWidth: 310,
    maxHeight: 148
  }
}))

export const Title = styled(Card.Title)(({ theme }) => ({
  fontSize: '20px !important',
  marginBottom: '0rem !important',
  paddingBottom: '0rem !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))
