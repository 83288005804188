import IconProps from "assets/icon/payment-method/iconProps"

export default function Bank(props: IconProps) {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g data-name="Group 10265">
                <g data-name="Ellipse 467" style={{ stroke: props.active || '#9e9e9e', fill: '#fff', strokeWidth: '2px' }}>
                    <circle cx="20" cy="20" r="20" style={{ stroke: 'none' }} />
                    <circle cx="20" cy="20" r="19" style={{ fill: 'none' }} />
                </g>
                <path data-name="Path 6619" d="m17.613 11.521 1.1 1.1 5.58-5.581-5.586-5.585-1.1 1.1 3.711 3.711H1.125V7.81h20.2l-3.711 3.711z" transform="translate(7.479 7.148)" style={{ fill: props.active || '#9e9e9e', strokeWidth: '.7px', stroke: props.active || '#9e9e9e' }} />
                <path data-name="Path 6620" d="M24.385 23.136H4.186L7.9 19.425l-1.1-1.1-5.578 5.586 5.578 5.58 1.1-1.1-3.714-3.706h20.2z" transform="translate(7.449 1.888)" style={{ fill: props.active || '#9e9e9e', strokeWidth: '.7px', stroke: props.active || '#9e9e9e' }} />
            </g>
        </svg>
    )
}
