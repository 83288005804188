import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Grid, MenuItem, Typography, Box, Avatar } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { imgs } from 'constants/images'

/** COMPONENT */
import { CardSalesProps, handleChangeFilterSelect, renderFilterSelect } from 'features/dashboard/dashboard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** UTILS || SLICE */
import { numberFormat } from 'utils/app.utils'

/** API */
import ClinicApi, { DentistInterface } from 'api/master/clinic.api'
import { fileUrl } from 'api/api'

/** STYLE */
import * as UseStyled from 'features/dashboard/useStyled'
import DashboardApi, { IFSumeryDoctorFilter, IFTopDoctorFee } from 'api/dashboard.api'
export default function DoctorFee(props: CardSalesProps) {
  const { t } = useTranslation()

  const [doctors, setDoctors] = useState<DentistInterface[]>([])
  const [doctor, setDoctor] = useState<(string | number)[]>(['ALL'])
  const [rangeDate, setRangeDate] = useState([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
  const [branch, setBranch] = useState<(string | number)[]>(['ALL'])
  const [doctorsDF, setDoctorsDF]: any = useState([])
  const [sumDoctorsDF, setSumDoctorsDF] = useState(0)

  const loadDefaultData = async () => {
    await ClinicApi.findAllDentists().then(({ data }) => setDoctors(data))
  }

  const handleChangeSelectBranch = (e: any) => {
    setDoctor(handleChangeFilterSelect(e))
  }

  const renderImage = (img: any) => {
    if (img) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  useEffect(() => {
    setRangeDate(props.rangeDate || [])
  }, [props.rangeDate])

  useEffect(() => {
    setBranch(props.branch || [])
  }, [props.branch])
  useEffect(() => {
    loadDefaultData()
  }, [])

  const loadData = useCallback(async () => {
    const condition: IFSumeryDoctorFilter = { dateStart: rangeDate[0], dateEnd: rangeDate[1], doctorId: doctor[0] === 'ALL' ? `` : doctor.toString(), branchId: branch[0] === 'ALL' ? `` : branch.toString() }
    const res = await DashboardApi.findTopDoctorFee(condition)
    const products: any = []
    setDoctorsDF([])
    if (res.status === 200) {
      const doctorFee: IFTopDoctorFee = res.data
      setSumDoctorsDF(doctorFee.sumery)
      for (const x of doctorFee.lists) {
        products.push({
          name: x.doctorFullname,
          salary: x.dfAmountNet,
          avatar: x.profile
        },)
      }
    }
    setDoctorsDF(products)
  }, [doctor, rangeDate])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <UseStyled.Card className='d-flex flex-column'>
      <Box className='pt-3 px-3 px-xl-4' sx={{ borderBottom: `1px solid ${colors.lightGray}`, paddingBottom: '12px' }}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item className={'w-100'}>
            <UseStyled.Title type='title' sx={{ flex: 'none' }}>{t('DASHBOARD.DOCTOR_FEE')}</UseStyled.Title>
          </Grid>
          <Grid item xs={6} sm={12} className={'w-100 pr-sm-2 pr-md-0'}>
            <FilterSelect
              multiple
              label={t('')}
              labelId="label-branch"
              selectId="select-branch"
              onchange={handleChangeSelectBranch}
              renderValue={() => `${t('DASHBOARD.FILTER.DOCTOR')}: ${renderFilterSelect('dentist', doctors, doctor) === 'ALL' ? t('STATUS.ALL') : renderFilterSelect('dentist', doctors, doctor)}`}
              value={doctor}
              options={[
                <MenuItem
                  key="ALL"
                  value="ALL"
                  sx={{
                    '&.MuiMenuItem-root.Mui-selected': {
                      pointerEvents: _.includes(doctor, 'ALL') ? `none` : 'auto',
                      backgroundColor: _.includes(doctor, 'ALL') ? `${colors.themeSecondColor06} !important` : 'transparent',
                      color: _.includes(doctor, 'ALL') ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                    }
                  }}>
                  {t('STATUS.ALL')}
                </MenuItem>,
                _.map(doctors, (val: DentistInterface, indexBranch: number) => {
                  return (
                    <MenuItem
                      key={indexBranch}
                      value={val.dentistId}
                      sx={{
                        '&.MuiMenuItem-root.Mui-selected': {
                          backgroundColor: _.includes(doctor, val.dentistId) ? `${colors.themeSecondColor06} !important` : 'transparent',
                          color: _.includes(doctor, val.dentistId) ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                        }
                      }}>
                      {val.dentistFullname}
                    </MenuItem>
                  )
                })
              ]}
              formControlClass={'not-max-width'}
              classesOption="style-select-doctors"
              isClear={doctor[0] === 'ALL' ? false : true}
              onClear={() => setDoctor(['ALL'])}
            />
          </Grid>
          <Grid item xs={6} sm={12} className={'w-100'}>
            <InputSecondNewRangePicker
              inputHeight={32}
              value={rangeDate}
              onchange={(val) => {
                if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
              }}
              allowClear
              onClear={() => setRangeDate(emptyRangePicker)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className='p-3 px-xl-4 d-flex flex-column h-100'>
        <Box className='pb-2'>
          <Typography sx={{ fontSize: 24, fontWeight: 500 }}>{numberFormat(sumDoctorsDF)}</Typography>
          <Typography sx={{ fontSize: 14, color: `${colors.textExtraLightGray} !important` }}>{t('DASHBOARD.DOCTOR_FEE_CHART.SUM_DF')}</Typography>
        </Box>
        <Box className='pt-1 d-flex flex-column h-100'>
          <Typography className='pb-2' sx={{ fontWeight: 500 }}>{t('DASHBOARD.DOCTOR_FEE_CHART.DF_TOP_THREE')}</Typography>
          {!_.isEmpty(doctorsDF) ? (
            _.map(doctorsDF, (doctorDF, indexDoctor) => {
              return (
                <Box className='d-flex align-items-center py-2'>
                  <Typography className='mr-3'>{indexDoctor + 1}</Typography>
                  <Avatar src={renderImage(doctorDF.avatar)} sx={{ backgroundColor: colors.white }}>
                    <img src={imgs.defaultAvatar} alt='' className="w-100 h-100" />
                  </Avatar>
                  <Typography className='ml-2 mr-3'>{doctorDF.name}</Typography>
                  <Typography className='ml-auto'>{numberFormat(doctorDF.salary)}</Typography>
                </Box>
              )
            })
          ) : (
            <Box className='d-flex align-items-center justify-content-center h-100 py-3'>{t('NOT_FOUND')}</Box>
          )}
        </Box>
      </Box>
    </UseStyled.Card>
  )
}
