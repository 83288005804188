import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ImageTypeInterface {
  imageTypeName: string
  imageTypeNameEn?: string
  status: string
}

export default class ImageTypeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('image-types', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('image-types', playload)
  }

  static findById(id: number): Promise<ImageTypeInterface> {
    return this.api.get(`image-types/${id}`)
  }

  static update(id: number, body: ImageTypeInterface) {
    return this.api.patch(`image-types/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`image-types/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`image-types/${id}`)
  }
}
