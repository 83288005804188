import IconProps from './icon-props'
import { colors } from "constants/theme"

export default function Bento(props: IconProps) {
  return (
    <svg id="icon_navigation_apps_24px" data-name="icon/navigation/apps_24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Boundary" width="24" height="24" fill="none" />
      <path id="_Color" data-name=" ↳Color" d="M16,16H12V12h4v4Zm-6,0H6V12h4v4ZM4,16H0V12H4v4Zm12-6H12V6h4v4Zm-6,0H6V6h4v4ZM4,10H0V6H4v4ZM16,4H12V0h4V4ZM10,4H6V0h4V4ZM4,4H0V0H4V4Z" transform="translate(4 4)" fill={colors.themeSecondColor} />
    </svg>
  )
}
