import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, styled } from '@mui/material'
import _ from 'lodash'

/** API */


/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { colors } from 'constants/theme'


/** CONSTANT */


/** SLICE & UTILS & FEATURES */
import { resetModal } from 'app/slice/modal.slice'
import { selectMe } from 'app/slice/user.slice'



export const TableWidth = styled(Box)(({ theme }) => ({
    height: '100%',
    '.MuiTableContainer-root': {
        height: '100%',
    },
    '.MuiTable-root': {
        minWidth: 200,
        '.MuiTableHead-root': {
            '.MuiTableRow-head': {
                position: 'sticky',
                top: 0,
                zIndex: 10,
                '.MuiTableCell-head': {
                    color: '#000000 !important',
                    background: '#fff',
                    padding: 8,
                    borderTop: '1px solid  #E5E5E5',
                    borderBottom: '1px solid  #E5E5E5',
                    borderLeft: '1px hidden #ffffff !important',
                    borderRight: '1px hidden #ffffff !important'
                },
            }
        },
        '.MuiTableBody-root': {
            '.MuiTableCell-body': {
                border: 'none',
                padding: `8px !important`,
                verticalAlign: 'middle',
                borderLeft: '1px hidden #ffffff !important',
                borderRight: '1px hidden #ffffff !important',
                '&.add-new-list': {
                    '&.disabled': {
                        button: {
                            color: colors.disabledGray
                        }
                    }
                }
            }
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: 'unset',
            maxWidth: '100%',
            '.MuiTableBody-root': {
                '.MuiTableCell-body': {
                    verticalAlign: 'top',
                    '&:first-of-type:not(.add-new-list), &:last-of-type:not(.add-new-list)': {
                        paddingTop: `18px !important`
                    }
                }
            },
        }
    }
}))


/** VARIABLE */


export interface ConfirmCloseProps {
    isShow: boolean
    onReset?: () => void
    handleSuccess?: () => void
    treatments?: any
}

export function PopupConfirmClose(props: ConfirmCloseProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [loading] = useState<boolean>(false)
    const user = useSelector(selectMe)
    /** OPTION */

    /** DATA */
    const [allPrice, setAllPrice] = useState<any>(0)



    useEffect(() => {
        setAllPrice(0)
        _.map(props.treatments?.summeryTreatments, (i) => {
            if (i.dfAmountNet) setAllPrice((allPrice: number) => allPrice + i.dfAmountNet)
        })
    }, [])


    const resetForm = async () => {
        dispatch(resetModal())
        if (props.onReset) props.onReset()
    }


    return props.isShow ? (

        <ModalCustom
            size={'lg'}
            title={t('TREATMENTS.POPUP_CONFIRM_CLOSE.TITLE')}
            alignFooter={'center'}
            fullscreen={'sm-down'}
            component={
                <>

                </>
            }
            onSubmit={props.handleSuccess}
            onReset={() => resetForm()}
            disabledSubmit={loading}
            textBtnCancel={t('TREATMENTS.POPUP_CONFIRM_CLOSE.CANCEL_SAVE')}
            textBtnConfirm={t('TREATMENTS.POPUP_CONFIRM_CLOSE.SAVE')}
            btnCancelTheme={'danger'}
            closeButton={true}

        />
    ) : (
        <></>
    )
}
