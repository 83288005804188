import BaseAPI from "api/api"

const path = 'overdue'

export interface FindAllOverdueProps {
    page?: number
    pageLimit?: number
    sortType?: number
    sortBy?: string
    search?: string
    dateStart?: string
    dateEnd?: string
}

export interface PdfOutOverdueProps {
    overdueTotal: number,
    hasPrint: 0 | 1,
    patientCaseId: number,
    overdueDate: string,
    overdueDateStart: string,
    patientSignature: string,
    doctorId: number,
    doctorName: string,
    doctorSignature: string,
    managerName: string,
    managerSignature: string,
}

export default class OverdueApi extends BaseAPI {
    static findAll(props: FindAllOverdueProps): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props } })
    }

    static findOverduePatient(props: FindAllOverdueProps, patientId: number): Promise<any> {
        return this.api.get(`${path}/patient/${patientId}`, { params: { ...props } })
    }

    static findDetailPayment(orderId: number, orderPaymentId: number): Promise<any> {
        return this.api.get(`${path}/patient/${orderId}/${orderPaymentId}`)
    }
    static paidOverdue(orderPaymentId: number, payload: any): Promise<any> {
        return this.api.post(`${path}/payment/${orderPaymentId}`, payload)
    }

    static pdfOutOverdue(orderPaymentId: number, payload: PdfOutOverdueProps): Promise<any> {
        return this.api.patch(`${path}/print/${orderPaymentId}`, payload)
    }


}