import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/65.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth65(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 55.25, height: 107.63, viewBox: "0 0 55.25 107.63" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }


        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 55.25, height: 107.63, viewBox: "0 0 55.25 107.63" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={width > 1440 ? 70 : 100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12343" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5444"
                                d="M33.6182 107.034C27.8399 105.002 22.3727 103.638 15.9769 104.587C8.01801 105.761 4.44265 100.412 0.378906 95.6174L1.3172 85.5293C8.94745 75.7867 9.09138 64.5811 7.16015 53.1078C4.70743 38.5493 7.45611 24.1742 8.67458 9.73398C8.70872 9.03484 8.92563 8.35687 9.30364 7.76773C9.88843 6.90814 11.3771 7.18197 11.5403 8.17183C14.7948 27.9718 23.3759 42.5959 24.3985 47.0734C24.5716 47.8275 25.8032 47.8088 25.9466 47.0503C26.9053 41.9907 26.8167 22.1173 21.1306 3.49058C20.5945 1.7335 22.6458 0.102072 25.0427 0.00373978C27.1979 -0.0874722 28.0325 1.50835 29.2748 2.75954C45.1209 18.7032 45.1696 38.2175 45.3043 57.6214C45.3682 66.6464 47.0198 75.3079 50.666 83.6958C52.4054 87.7028 52.9424 91.9064 53.2394 96.0817C53.7127 102.675 41.337 109.74 33.6182 107.034Z"
                                transform={width > 1440 ? "translate(0 10)" : "translate(0 0)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5444"
                                    d="M33.6182 107.034C27.8399 105.002 22.3727 103.638 15.9769 104.587C8.01801 105.761 4.44265 100.412 0.378906 95.6174L1.3172 85.5293C8.94745 75.7867 9.09138 64.5811 7.16015 53.1078C4.70743 38.5493 7.45611 24.1742 8.67458 9.73398C8.70872 9.03484 8.92563 8.35687 9.30364 7.76773C9.88843 6.90814 11.3771 7.18197 11.5403 8.17183C14.7948 27.9718 23.3759 42.5959 24.3985 47.0734C24.5716 47.8275 25.8032 47.8088 25.9466 47.0503C26.9053 41.9907 26.8167 22.1173 21.1306 3.49058C20.5945 1.7335 22.6458 0.102072 25.0427 0.00373978C27.1979 -0.0874722 28.0325 1.50835 29.2748 2.75954C45.1209 18.7032 45.1696 38.2175 45.3043 57.6214C45.3682 66.6464 47.0198 75.3079 50.666 83.6958C52.4054 87.7028 52.9424 91.9064 53.2394 96.0817C53.7127 102.675 41.337 109.74 33.6182 107.034Z"
                                    transform={width > 1440 ? "translate(0 10)" : "translate(0 0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12343" >
                                <path
                                    data-name="Path 5245"
                                    d="M13.266 3.14618C11.4914 4.33264 10.1891 6.10395 9.58599 8.15168C8.32596 12.591 5.21051 22.7475 2.37851 25.4766C-1.27671 28.9981 -0.00859156 40.3568 6.27022 43.7294C12.549 47.1019 24.336 46.082 26.5768 46.3062C30.1337 46.6614 54.4296 54.0719 55.8322 37.0836C57.3236 19.0294 48.1889 15.8766 48.304 9.08112C48.304 9.08112 44.765 -2.68004 34.1087 2.48075C32.9902 3.00839 31.7449 3.20809 30.5174 3.0567C29.1527 2.89544 28.895 1.5848 25.5748 0.915911C19.8674 -0.233284 15.7878 1.41738 13.266 3.14618Z"
                                    transform={width > 1440 ? "translate(-1 75)" : "translate(-1 60)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5246"
                                    d="M14.3064 4.82777C12.6706 5.91341 11.4681 7.53924 10.9091 9.42127C9.74468 13.4956 6.86803 22.8173 4.25444 25.3224C0.879402 28.5552 2.04899 38.9797 7.84769 42.074C13.6464 45.1683 24.5285 44.2344 26.5975 44.44C29.8814 44.7661 52.3147 51.5668 53.6102 35.9751C54.9862 19.4051 46.5524 16.512 46.6588 10.2757C46.6588 10.2757 43.3908 -0.519682 33.5516 4.21661C32.5181 4.70137 31.3688 4.88472 30.2358 4.7456C28.9758 4.59762 28.7374 3.39512 25.6723 2.78108C20.4021 1.72637 16.636 3.24079 14.3064 4.82777Z"
                                    transform={width > 1440 ? "translate(-1 75)" : "translate(-1 60)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5247"
                                    d="M17.1942 7.34101C15.7701 8.29305 14.7249 9.7144 14.2408 11.3576C13.2297 14.9197 10.7301 23.0691 8.45803 25.2588C5.52501 28.0854 6.54143 37.1999 11.5806 39.9051C16.6198 42.6102 26.0764 41.7934 27.8743 41.9733C30.7284 42.2577 50.2235 48.2042 51.3487 34.572C52.545 20.0851 45.2161 17.5562 45.3081 12.1033C45.3081 12.1033 42.4682 2.66552 33.9172 6.80624C33.0201 7.23001 32.0211 7.39017 31.0365 7.26807C29.9412 7.13876 29.7339 6.08694 27.0703 5.55083C22.4903 4.62985 19.218 5.95416 17.1942 7.34101Z"
                                    transform={width > 1440 ? "translate(-1 75)" : "translate(-1 60)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6546" d="M2.18572 11.123C2.16961 15.2871 5.02939 43.4367 5.45789 47.5787C5.89549 51.195 6.59687 54.7745 7.55643 58.2886C7.72111 58.2587 7.88758 58.2398 8.05475 58.2318C7.80043 56.5857 6.42926 51.9682 5.75297 47.4038C4.95544 42.0223 1.94326 11.756 2.18572 11.123Z" transform={width > 1440 ? "translate(8 14)" : "translate(8 5)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                        <path data-name="Path 6547" d="M28.7167 58.8782C28.9779 58.8892 29.2353 58.9445 29.4781 59.0417C29.4874 56.5784 20.7692 6.21712 16.6289 1.83691C18.0467 5.6381 22.679 9.79464 25.3986 31.2412C26.5417 36.4114 28.604 53.5844 28.7167 58.8782Z" transform={width > 1440 ? "translate(9 14)" : "translate(10 5)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                    </>
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154155" x1="28.9701" y1="80.0365" x2="26.058" y2="96.6586" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154155" x1="28.9701" y1="91.6947" x2="28.9701" y2="85.5297" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9545">
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M34.5569 103.768C28.955 101.798 23.6547 100.476 17.454 101.396C9.738 102.534 6.27176 97.3473 2.33203 92.6994L3.24169 82.9191C10.6391 73.4739 10.7786 62.6102 8.90633 51.4871C6.52846 37.3729 9.19325 23.4364 10.3745 9.43693C10.4076 8.75912 10.6179 8.10184 10.9844 7.53068C11.5513 6.69732 12.9946 6.9628 13.1528 7.92245C16.308 27.1181 24.6272 41.296 25.6186 45.6368C25.7865 46.3679 26.9804 46.3498 27.1194 45.6144C28.0489 40.7093 27.963 21.4423 22.4504 3.38405C21.9307 1.68059 23.9194 0.0989567 26.2432 0.00362565C28.3326 -0.0848028 29.1417 1.46232 30.3461 2.67532C45.7086 18.1324 45.7558 37.0512 45.8864 55.863C45.9483 64.6125 47.5496 73.0097 51.0845 81.1416C52.7708 85.0263 53.2914 89.1016 53.5793 93.1496C54.0382 99.5418 42.0401 106.391 34.5569 103.768Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M12.8874 61.2198C11.1669 62.37 9.90442 64.0873 9.31968 66.0725C8.0981 70.3764 5.07773 80.2229 2.33216 82.8687C-1.21151 86.2827 0.0179053 97.2949 6.1051 100.564C12.1923 103.834 23.6195 102.845 25.792 103.063C29.2403 103.407 52.7948 110.591 54.1546 94.1215C55.6004 76.6183 46.7445 73.5617 46.8561 66.9736C46.8561 66.9736 43.4251 55.5714 33.0941 60.5747C32.0096 61.0862 30.8024 61.2798 29.6123 61.133C28.2893 60.9767 28.0395 59.7061 24.8206 59.0576C19.2873 57.9435 15.3323 59.5438 12.8874 61.2198Z" fill="#EFEDE7" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M13.8956 62.8493C12.3096 63.9019 11.1439 65.4781 10.6019 67.3027C9.47301 71.2527 6.68416 80.2899 4.15033 82.7185C0.878288 85.8527 2.01219 95.9591 7.63392 98.9589C13.2557 101.959 23.8057 101.053 25.8116 101.253C28.9952 101.569 50.7439 108.162 51.9998 93.0462C53.3339 76.9818 45.1574 74.177 45.2606 68.131C45.2606 68.131 42.0923 57.6651 32.5534 62.2568C31.5515 62.7268 30.4373 62.9046 29.3388 62.7697C28.1172 62.6262 27.8861 61.4604 24.9146 60.8651C19.8052 59.8426 16.154 61.3108 13.8956 62.8493Z" fill="#F7F7F7" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M16.6942 65.2858C15.3136 66.2088 14.3003 67.5868 13.8309 69.1799C12.8508 72.6332 10.4274 80.534 8.2247 82.6568C5.38118 85.3972 6.36658 94.2335 11.252 96.8561C16.1374 99.4787 25.3054 98.6868 27.0484 98.8612C29.8154 99.137 48.7156 104.902 49.8064 91.6858C50.9662 77.641 43.861 75.1893 43.9502 69.9028C43.9502 69.9028 41.1969 60.753 32.9069 64.7674C32.0372 65.1782 31.0686 65.3335 30.1141 65.2151C29.0522 65.0898 28.8513 64.07 26.269 63.5503C21.8287 62.6574 18.6563 63.9413 16.6942 65.2858Z" fill="white" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M35.552 75H22.448C20.5437 75 19 76.5437 19 78.448V91.552C19 93.4563 20.5437 95 22.448 95H35.552C37.4563 95 39 93.4563 39 91.552V78.448C39 76.5437 37.4563 75 35.552 75Z" fill="#C1C4C5" />
                                            {/* <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M35.5572 75.8623H22.4532C21.025 75.8623 19.8672 77.0201 19.8672 78.4483V91.5523C19.8672 92.9805 21.025 94.1383 22.4532 94.1383H35.5572C36.9854 94.1383 38.1432 92.9805 38.1432 91.5523V78.4483C38.1432 77.0201 36.9854 75.8623 35.5572 75.8623Z" stroke="#C1C4C5" /> */}
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M27.4942 94.1082C27.1494 94.7323 30.3836 94.4237 32.8092 94.3927C36.4193 94.3168 38.4794 95.0547 38.445 86.7209C38.5386 83.7637 38.4471 80.8036 38.1708 77.8579C37.2554 76.9683 38.4122 86.8847 36.0693 91.2464C33.8591 94.3961 28.0855 93.4393 27.4942 94.1082Z" fill="#B0B1B1" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M32.4181 85.7139H25.5222C25.0461 85.7139 24.6602 86.0998 24.6602 86.5759V88.2999C24.6602 88.7759 25.0461 89.1618 25.5222 89.1618H32.4181C32.8942 89.1618 33.2801 88.7759 33.2801 88.2999V86.5759C33.2801 86.0998 32.8942 85.7139 32.4181 85.7139Z" fill="#CBCCCC" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M32.0783 78.4746H25.1823C24.7062 78.4746 24.3203 78.8605 24.3203 79.3366V81.0606C24.3203 81.5367 24.7062 81.9226 25.1823 81.9226H32.0783C32.5543 81.9226 32.9403 81.5367 32.9403 81.0606V79.3366C32.9403 78.8605 32.5543 78.4746 32.0783 78.4746Z" fill="#9E9E9E" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M32.4181 78.4746H25.5222C25.0461 78.4746 24.6602 78.8605 24.6602 79.3366V81.0606C24.6602 81.5367 25.0461 81.9226 25.5222 81.9226H32.4181C32.8942 81.9226 33.2801 81.5367 33.2801 81.0606V79.3366C33.2801 78.8605 32.8942 78.4746 32.4181 78.4746Z" fill="url(#paint0_linear_4126_154155)" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M32.4181 85.3711H25.5222C25.0461 85.3711 24.6602 85.757 24.6602 86.2331V87.9571C24.6602 88.4331 25.0461 88.8191 25.5222 88.8191H32.4181C32.8942 88.8191 33.2801 88.4331 33.2801 87.9571V86.2331C33.2801 85.757 32.8942 85.3711 32.4181 85.3711Z" fill="url(#paint1_linear_4126_154155)" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M1.26953 85.1782C1.26953 85.1782 23.7472 80.2065 53.294 84.1464" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M1.26953 85.6879C18.1974 82.6929 36.0789 82.3297 53.294 84.6312" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>65</TextNumberU>
        </Div >
    )
}
