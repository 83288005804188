import { numberFormat, numberNonDigit } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { dateToView } from 'utils/date.utils';
import { colors } from 'constants/theme'
import { CALCULATE_ADVANCE } from 'constants/status';

export interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    conditionAdvance: string
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
}

type DataItem = {
    countPaid: string;
    countPaidNormal: string;
    countPaidCancel: string;
};

interface IFIncomeMonthly {
    paidDate: string
    advancePaid: number
    cancelPaid: number
    cashPaid: number
    countPaid: number
    countPaidCancel: number
    countPaidNormal: number
    creditPaid: number
    debitPaid: number
    overDue: number
    receiveAdvance: number
    totalNoAdvancePaid: number
    totalPaid: number
    transferPaid: number
    treamentRightPaid: number
}

export default function IncomeMonthlyTotal(props: FcComponent) {
    const isDecrease = props.conditionAdvance === CALCULATE_ADVANCE.DECREASE

    const { t } = useTranslation()

    const headCells = [
        { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT', align: 'right', class: 'text-nowrap' },
        { id: '2', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT_CANCEL', align: 'right', class: 'text-nowrap' },
        { id: '3', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.NET_CANCEL', align: 'right', class: 'text-nowrap' },
        { id: '4', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT_NORMAL', align: 'right', class: 'text-nowrap' },
        { id: '5', disablePadding: false, labelKey: isDecrease ? 'REPORT.TABLE.INCOME_MONTH.ADVANCE_INCOME_NOT_CAL' : 'REPORT.TABLE.INCOME_MONTH.ADVANCE_INCOME', align: 'right', class: `text-nowrap ${isDecrease ? 'c-bbbbbb' : ''}` },
        { id: '6', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.CASH', align: 'right', class: 'text-nowrap' },
        { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TRANSFER', align: 'right', class: 'text-nowrap' },
        { id: '8', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.CREDIT', align: 'right', class: 'text-nowrap' },
        { id: '9', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.DEBIT', align: 'right', class: 'text-nowrap' },
        { id: '10', disablePadding: false, labelKey: isDecrease ? 'REPORT.TABLE.INCOME_MONTH.ADVANCE_DECREASE' : 'REPORT.TABLE.INCOME_MONTH.ADVANCE_DECREASE_NOT_CAL', align: 'right', class: `text-nowrap ${!isDecrease ? 'c-bbbbbb' : ''}` },
        { id: '11', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TREATMENT_RIGHT', align: 'right', class: 'text-nowrap' },
        { id: '12', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TOTAL', align: 'right', class: 'text-nowrap' },
        { id: '13', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.OVERDUE', align: 'right', class: 'text-nowrap' }
    ]

    return (
        <>
            <Box>
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        customScroll
                        hidePagination
                        tableMinWidth={992}
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={[
                            <TableRow key={props.data} className='row-summary'>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {_.sumBy(props.data, (item: DataItem) => Number(item.countPaid))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {_.sumBy(props.data, (item: DataItem) => Number(item.countPaidCancel))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'cancelPaid'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {_.sumBy(props.data, (item: DataItem) => Number(item.countPaidNormal))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'receiveAdvance'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'cashPaid'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'transferPaid'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'creditPaid'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'debitPaid'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'advancePaid'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'treamentRightPaid'))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, (item: IFIncomeMonthly) => item.totalNoAdvancePaid + (isDecrease ? item?.advancePaid || 0 : item?.receiveAdvance || 0)))}
                                </TableCell>
                                <TableCell align="right" scope="row" colSpan={1}>
                                    {numberFormat(_.sumBy(props.data, 'overDue'))}
                                </TableCell>
                            </TableRow>]
                        }
                    />
                </UseStyled.ResponsiveReportTable>
            </Box>
        </>
    )
}