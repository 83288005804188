
type IconProps = {
    style?: any
    type?: string
}

export default function TeethAdult(props: IconProps) {
    const color = props.type === 'ADULT' ? '#1CB99A' : '#707070'
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.4112 0H4.58881C2.05817 0 0 2.05873 0 4.58991V30.4101C0 32.9418 2.05873 35 4.58881 35H30.4112C32.9407 35 35 32.9418 35 30.4101V4.58991C35 2.05873 32.9407 0 30.4112 0ZM24.4569 17.6924C24.2378 19.1081 24.6837 20.6943 24.2626 23.8829C23.826 27.1034 23.0693 29.9294 21.5968 29.9294C19.8554 29.9294 20.0889 26.7154 20.292 24.1003C20.3516 23.3133 20.3957 22.6333 20.3538 22.1675C20.1772 20.2191 19.3388 18.847 17.5312 18.847C15.7341 18.847 14.8841 20.2191 14.708 22.1675C14.6661 22.6338 14.7102 23.3138 14.7698 24.1003C14.973 26.7154 15.2053 29.9294 13.4651 29.9294C11.9925 29.9294 11.2358 27.104 10.7992 23.8829C10.3775 20.6943 10.824 19.1081 10.6044 17.6924C10.1849 15.0099 7.84413 12.6051 8.56662 9.18479C8.96788 7.28778 10.0127 6.3953 11.0465 6.01998C13.4314 5.15234 14.9707 6.82415 17.5301 6.82415C20.09 6.82415 21.6293 5.15234 24.0131 6.01998C25.048 6.39475 26.0923 7.28723 26.493 9.18479C27.2166 12.6051 24.8747 15.0099 24.4558 17.6924H24.4569Z" fill={`${color}`} stroke={`${color}`} />
        </svg>
    )
}

