import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import useWindowSize from 'utils/useWindowSize'

import { Grid } from '@mui/material';
import useStyles from 'features/treatments/MainMenu/VitalSign/style'
import { colors } from 'constants/theme'

import InputTextField from 'component/Input/InputTextField'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import ButtonCustom from 'component/Button/ButtonCustom'

import TreatmentApi from 'api/dentists/treatment.api'

export interface PatientInfo {
  patient?: any
  onchange?: (value: any) => void
  loadPatient?: (value: any) => void
}
export default function IndexVitalSign(props: PatientInfo) {
  const { t } = useTranslation()
  const [vitalSign, setVitalSign] = useState<any>(props.patient || {})
  const classes = useStyles()
  const [width] = useWindowSize()

  useEffect(() => {
    setVitalSign(props.patient)
  }, [props.patient])

  useEffect(() => {
    if (props.onchange) props.onchange(vitalSign)
  }, [vitalSign])

  const onSummit = () => {
    const data = {
      weight: vitalSign.weight,
      height: vitalSign.height,
      systolicBloodPressure: vitalSign.systolicBloodPressure,
      diastolicBloodPressure: vitalSign.diastolicBloodPressure,
      waistline: vitalSign.waistline,
      temperature: vitalSign.temperature,
      pulseRate: vitalSign.pulseRate,
      heartRate: vitalSign.heartRate,
      regIrreq: vitalSign.regIrreq,
      rr: vitalSign.rr,
      o2Sat: vitalSign.o2Sat,
      bmi: vitalSign.bmi,
      bsa: vitalSign.bsa
    }

    TreatmentApi.updateVitalSign(vitalSign.patientId, data)
      .then((res) => {
        notiSuccess(t('VITAL_SIGN.MESSAGE.SUCCESS.UPDATE_STATUS'))
        props.loadPatient && props.loadPatient(vitalSign.patientId)
      })
      .catch((e) => {
        notiError(t('VITAL_SIGN.MESSAGE.ERROR'))
      })
  }

  return (
    <div>
      <div className={'header-text'} style={{ height: 40, backgroundColor: colors.themeSecondColor10 }}>
        <p className={`${classes.titleFirst}`} style={{ paddingTop: 5, paddingLeft: 25 }}>{t('VITAL_SIGN.TITLE.VITAL_SIGN')}</p>
      </div>
      <Grid container spacing={2} className={'px-4 pt-3'}>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="weight"
            key={'weight'}
            value={vitalSign.weight || ''}
            label={t('VITAL_SIGN.FORM.WEIGHT')}
            onchange={(e) => {
              setVitalSign({ ...vitalSign, weight: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="height"
            key={'height'}
            value={vitalSign.height || ''}
            label={t('VITAL_SIGN.FORM.HEIGHT')}
            onchange={(e) => {
              setVitalSign({ ...vitalSign, height: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <div className={'d-flex align-items-center'}>
            <InputTextField
              hookValue={true}
              inputProps={{ maxLength: 255 }}
              name="systolicBloodPressure"
              key={'systolicBloodPressure'}
              value={vitalSign.systolicBloodPressure || ''}
              label={t('VITAL_SIGN.FORM.SYSTOLIC_BLOOD_PRESSURE')}
              onchange={(e) => {
                setVitalSign({ ...vitalSign, systolicBloodPressure: e.target.value })
              }}
            />
            <div style={{ margin: '0 12px', color: colors.textPrimary, fontSize: 20, fontWeight: 500 }}>/</div>
            <InputTextField
              hookValue={true}
              inputProps={{ maxLength: 255 }}
              name="diastolicBloodPressure"
              key={'diastolicBloodPressure'}
              value={vitalSign.diastolicBloodPressure || ''}
              label={t('VITAL_SIGN.FORM.SYSTOLIC_BLOOD_PRESSURE')}
              onchange={(e) => {
                setVitalSign({ ...vitalSign, diastolicBloodPressure: e.target.value })
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="waistline"
            key={'waistline'}
            value={vitalSign.waistline || ''}
            label={t('VITAL_SIGN.FORM.WAISTLINE')}
            onchange={(e) => {
              setVitalSign({ ...vitalSign, waistline: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="temperature"
            key={'temperature'}
            value={vitalSign.temperature || ''}
            label={t('VITAL_SIGN.FORM.TEMPERATURE')}
            onchange={(e) => {
              setVitalSign({ ...vitalSign, temperature: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="pulseRate"
            key={'pulseRate'}
            value={vitalSign.pulseRate || ''}
            label={t('VITAL_SIGN.FORM.PULSE_RATE')}
            onchange={(e) => {
              setVitalSign({ ...vitalSign, pulseRate: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="heartRate"
            key={'heartRate'}
            value={vitalSign.heartRate || ''}
            label={t('VITAL_SIGN.FORM.HEART_RATE')}
            onchange={(e) => {
              setVitalSign({ ...vitalSign, heartRate: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="regIrreq"
            key={'regIrreq'}
            value={vitalSign.regIrreq || ''}
            label="Reg/Irreq"
            onchange={(e) => {
              setVitalSign({ ...vitalSign, regIrreq: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="rr"
            key={'rr'}
            value={vitalSign.rr || ''}
            label="RR"
            onchange={(e) => {
              setVitalSign({ ...vitalSign, rr: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="o2Sat"
            key={'o2Sat'}
            value={vitalSign.o2Sat || ''}
            label="O2Sat(%)"
            onchange={(e) => {
              setVitalSign({ ...vitalSign, o2Sat: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="bmi"
            key={'bmi'}
            value={vitalSign.bmi || ''}
            label={t('VITAL_SIGN.FORM.BMI')}
            onchange={(e) => {
              setVitalSign({ ...vitalSign, bmi: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2} className={`w-100`}>
          <InputTextField
            hookValue={true}
            inputProps={{ maxLength: 255 }}
            name="bsa"
            key={'bsa'}
            value={vitalSign.bsa || ''}
            label={t('VITAL_SIGN.FORM.BSA')}
            onchange={(e) => {
              setVitalSign({ ...vitalSign, bsa: e.target.value })
            }}
          />
        </Grid>
      </Grid>
      <div className={width <= 700 ? "d-flex justify-content-center pt-3 px-4" : "d-flex flex-row-reverse pt-3 px-4"}>
        <ButtonCustom
          className={'p-2'}
          onClick={onSummit}
          textButton={
            <>
              <span className="d-md-flex">{t('VITAL_SIGN.BUTTON.SAVE')}</span>
            </>
          }
          style={{ margin: 0, fontSize: 16, textTransform: 'none' }}
        />
      </div>
    </div>
  )
}
