import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const selectSlice = createSlice({
  name: 'select',
  initialState: {
    options: [[]],
    index: 0,
    disabled: [false],
    value: [null]
  },
  reducers: {
    addOptions: (state, action: PayloadAction<any>) => {
      state.options[action.payload.key] = action.payload.value
    },
    selectIndex: (state, action: PayloadAction<any>) => {
      state.index = action.payload
    },
    setSelected: (state, action: PayloadAction<any>) => {
      state.value[action.payload.key] = action.payload.value
    },
    resetSelected: (state, action: PayloadAction<any>) => {
      state.value = action.payload
    },
    setDisabled: (state, action: PayloadAction<any>) => {
      state.disabled[action.payload.key] = action.payload.value
    }
  }
})

export const { addOptions, setDisabled, setSelected, resetSelected } = selectSlice.actions

export default selectSlice.reducer
