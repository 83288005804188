import BaseAPI from 'api/api'

const url = `treatments`

export interface ListQueue {
  branchId: number
  servicePointId: number
}

export interface ListAppointment {
  branchId: number
  doctorId: number
  date: string
}

export interface ListSummary {
  branchId: number
  date: string
}

export default class TreatmentQueueAPI extends BaseAPI {
  static listQueue(params: ListQueue): Promise<any> {
    return this.api.get(`${url}/list-queue`, { params: { ...params } }).then((res) => res)
  }

  static listAppointment(params: ListAppointment): Promise<any> {
    return this.api.get(`${url}/list-appointment`, { params: { ...params } }).then((res) => res)
  }

  static summary(doctorId: number, params: ListSummary): Promise<any> {
    return this.api.get(`${url}/summary/${doctorId}`, { params: { ...params } }).then((res) => res)
  }
}
