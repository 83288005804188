import axios from 'axios'
import { Config } from 'config'
import { getToken } from 'utils/app.utils'
import { CLINIC_THEME } from 'constants/common'

const token = getToken()
export const fileUrl = Config.REACT_APP_IMAGE_URL

const instance = axios.create({
  baseURL: Config.REACT_APP_BASE_URL,
  // baseURL: 'http://192.168.50.66:3800/api',

  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : ''
  }
})

instance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return error
  })

// instance.interceptors.response.use(function (response) {
//   if (response.status === 500) {
//     // Do something here with Hooks or something else
//     return response;
//   }
//   return response;
// });

if (token) {
  instance.interceptors.response.use(function (response) {
    return response
  })
}

export default class BaseAPI {
  static api = instance
}

export const lineConnectConfig = {
  LINE_NOTI_CLIENT_ID: Config.REACT_APP_LINE_NOTI_CLIENT_ID,
  LINE_NOTI_CLIENT_SECRET: Config.REACT_APP_LINE_NOTI_CLIENT_SECRET,
  LINE_NOTI_CALLBACK_URL: Config.REACT_APP_LINE_NOTI_CALLBACK_URL
}

export const clinicTheme = {
  type: CLINIC_THEME,
  theme: Config.REACT_APP_CLINIC_THEME || CLINIC_THEME.DENTAL
}