import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import { routeName } from 'routes/routes-name';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem } from '@mui/material';
import _ from 'lodash';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch } from 'utils/app.utils';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/**COMPONENT */
import Loading from 'component/Loading';
import TreatmentCourseList from './TreatmentCourseList';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';

/**STYLE */
import * as UseStyled from 'features/report/useStyled';
import InputTextSearch from 'component/Input/InputTextSearch';
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker';
import ButtonCustom from 'component/Button/ButtonCustom';
import moment from 'moment';

export default function TreatmentCourse() {
    const { t } = useTranslation();

    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);

    const [branchId, setBranchId] = useState<any>(getBranch());
    const [branches, setBranches] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');


    const [treatmentRightId, setTreatmentRightId] = useState<number>(0);

    const [treatmentCourseData, setTreatmentCourseData] = useState<any[]>([]);
    const [courseType, setCourseType] = useState<any[]>([]);
    const [courseTypeSelected, setCourseTypeSelected] = useState<any>('all');
    const [courseItem, setCourseItem] = useState<any[]>([]);
    const [courseItemSelected, setCourseItemSelected] = useState<any>('all');

    const [treatmentCourseExport, setTreatmentCourseExport] = useState<any[]>([]);
    const [treatmentCoursePage, setTreatmentCoursePage] = useState<number>(1);
    const [treatmentCourseLimitPage, setTreatmentCourseLimitPage] = useState<number>(10);

    const [search, setSearch] = useState<string>('');
    const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const momentDate = () => momentNowRange
    const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
    const [fetchData, setFetchData] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true);

    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'TREATMENT_COURES') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    const fetchTreatmentCourse = useCallback(async () => {
        await verifyPermission()
        let condition: FindAllReportInterface = {
            page: treatmentCoursePage, pageLimit: treatmentCourseLimitPage,
            branchId: branchId, treatmentRightId: treatmentRightId
        }
        if (rangeDate) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        if (courseTypeSelected !== 'all') condition = { ...condition, courseType: courseTypeSelected }
        if (courseItemSelected !== 'all') condition = { ...condition, courseName: courseItemSelected }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }

        ReportApi.reportTreatmentCourse(condition)
            .then(({ headers, data }) => {
                setTreatmentCourseData(data.rows)
                setCourseType(data.courses)
                setCourseItem(data.courseItems)
                if (data.courses.length === 0) {
                    setCourseTypeSelected('all')
                }
                if (data.courseItems.length === 0) {
                    setCourseItemSelected('all')
                }

                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))
        setFetchData(false)
    }, [treatmentCoursePage, treatmentCourseLimitPage, sortBy, sortType, treatmentRightId, fetchData])

    const fetchPatientInformationExport = useCallback(async () => {
        let condition: FindAllReportInterface = {
            page: 1, pageLimit: 10000,
            branchId: branchId,
            treatmentRightId: treatmentRightId,
            export: 1
        }
        if (search) condition = { ...condition, search: search }
        if (rangeDate) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        if (courseTypeSelected !== 'all') condition = { ...condition, courseType: courseTypeSelected }
        if (courseItemSelected !== 'all') condition = { ...condition, courseName: courseItemSelected }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }

        ReportApi.reportTreatmentCourse(condition)
            .then(({ data }) => {
                setTreatmentCourseExport(data.rows)
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

    }, [sortBy, sortType, treatmentRightId, fetchData])

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.TREATMENT_COURES`)}`,
        sheetName: `${t(`REPORT.REPORT_NAME.TREATMENT_COURES`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setTreatmentCourseLimitPage(limit)

    const handleChangePage = (val: number) => setTreatmentCoursePage(val)

    /** UseEffect */
    useEffect(() => {
        fetchTreatmentCourse()
    }, [fetchTreatmentCourse])

    useEffect(() => {
        fetchPatientInformationExport()
    }, [fetchPatientInformationExport])

    return (
        <>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.TREATMENT_COURES`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <Col sm={12} md={6} lg={5} xl={3} xxl={3} className={'pt-2 px-1'}>
                                <InputTextSearch
                                    keyInput={'search'}
                                    label={t('INPUT.SEARCH')}
                                    value={search}
                                    onchange={(event) => {
                                        setSearch(event.target.value)
                                    }}
                                    onClear={(event) => {
                                        setSearch('')
                                    }}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={5} xl={3} xxl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)

                                    }}

                                    renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={
                                        _.map(branches, (b: BranchInterface, index) => {
                                            return (
                                                <MenuItem key={b.branchId} value={b.branchId}>
                                                    {b.branchName || b.branchNameEn}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            <Col sm={12} md={6} lg={5} xl={3} xxl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setCourseTypeSelected(e.target.value);
                                    }}
                                    renderValue={() => courseTypeSelected === "all" ? `${t('REPORT.FILTER.COURSE_NAME')}: ${t('REPORT.REPORT_GROUP.ALL')}` : `${t('REPORT.FILTER.COURSE_NAME')}: ${_.get(_.find(courseType, { treatmentCourseName: courseTypeSelected }), 'treatmentCourseName')} `}
                                    label={''}
                                    selectId="select-course-type"
                                    labelId="label-course-type"
                                    value={courseTypeSelected}
                                    options={[
                                        <MenuItem key="all" value="all">
                                            {t('REPORT.REPORT_GROUP.ALL')}
                                        </MenuItem>,
                                        ..._.map(courseType, (b: any, index) => (
                                            <MenuItem key={b.treatmentCourseName} value={b.treatmentCourseName}>
                                                {b.treatmentCourseName}
                                            </MenuItem>
                                        ))
                                    ]}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>



                            <Col sm={12} md={6} lg={5} xl={3} xxl={2} className={'pt-2 px-1'}>
                                <InputSecondNewRangePicker
                                    inputHeight={32}
                                    value={rangeDate || emptyRangePicker}

                                    onchange={(val: any) => {
                                        if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                        else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])

                                    }}
                                    label={''}
                                    allowClear
                                    onClear={() => setRangeDate(emptyRangePicker)}
                                />
                            </Col>
                            <Col md={1} xl={1} className={'pt-0'}>
                                <ButtonCustom onClick={() => {
                                    setFetchData(true);
                                    setLoading(true);
                                    setTreatmentCoursePage(1);

                                }}
                                    textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                            </Col>
                        </Row>
                        <Row className='mx-0'>
                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                        <FucntionPrint
                                            content={() => componentRef.current}
                                            docTitle={t(`REPORT.REPORT_NAME.VISIT`)}
                                            removeIfram
                                            trigger={() => <Dropdown.Item >
                                                {t('PDF')}
                                            </Dropdown.Item>}
                                            style={`@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                        />
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <TreatmentCourseList
                            filter={{
                                branch: `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
                                courseType: courseTypeSelected === 'all' ? t('REPORT.REPORT_GROUP.ALL') : courseTypeSelected,
                                courseItem: courseItemSelected === 'all' ? t('REPORT.REPORT_GROUP.ALL') : courseItemSelected,
                                rangeDate: rangeDate
                            }}
                            page={treatmentCoursePage}
                            pageLimit={treatmentCourseLimitPage}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={treatmentCourseData}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                            exportData={treatmentCourseExport} />
                    </Box>
                </Card.Body>
                <Loading show={loading} type='fullLoading' />
            </Card>
        </>
    )
}