/** API */
import ManageDoctorLabFeeApi from 'api/counter/manage-doctor-lab-fee.api'
/** UTILS || SLICE */
import { numberToCurrencyFormat, toBuddhistYear } from 'utils/app.utils';
import { getBranch, numberFormat } from 'utils/app.utils'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputTextField from 'component/Input/InputTextField';
import AutocompleteSelect from 'component/Select/AutocompleteSelect';

/** STYLE */
import * as UseStyled from './style'
import { notiError, notiSuccess } from 'component/notifications/notifications';
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Grid } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import ClinicApi from 'api/master/clinic.api';
import TreatmentApi from 'api/dentists/treatment.api';

type FromDoctorFeeProps = {
    type: 'CLINIC_FEE' | 'DIAGNOSES'
    data: any
    onReset: () => void

}
export interface FindDoctorFeeProps {
    operativeId: number
    branchId: number
    doctorId: number
}
interface LabOrderPatientInterface {
    branchCnNumber: string
    branchId: number
    patientsFullname: string
}
interface LabOrderFeeService {
    labOrderId: string;
    labOrderNumber: string;
    doctorId: number;
    doctorFullname: string;
    patientsFullname: string;
    vnNumber: string;
    branchId: number;
    dateOrder: string;
    timeOrder: string;
    labCompanyId: number;
    labCompanyName: string;
    dateExport: string;
    dateImport: string;
    dateReceive: string;
    labGroupId: number;
    labGroupName: string;
    labOrderPrice: number;
    labDoctorAmount: number;
    labClinicAmount: number;
    labUnit: string;
    labOrderStatus: string;
    remark: string | null;
    labTypeId: number;
    labTypeName: string;
    labText: string;
    tempOrderTreatmentId: number | null;
    orderTreatmentId: number;
    operativeId: number,
    listOfProcedures: string;
    cancelRemark: string | null;
    cancelCode: string | null;
    cancelBy: string | null;
    status: string;
    updatedBy: string;
    updatedAt: string;
    createdBy: string;
    createdAt: string;
    // dfNet: number;
    dfAmount: number
    dfAmountNet: number
    cfAmount: number
    cfAmountNet: number
    currentDfAmount: number,
    currentCfAmount: number,

}
interface orderDetailInface {
    labOrderNumber: string
    dateOrder: string
    labCompanyId: number
    labCompanyName: string
    listOfProcedures: string
    labOrderStatus: string
    vnNumber: string
    timeOrder: string
    doctorId: number
    doctorFullname: string
    dateExport: string
    dateImport: string
    dateReceive: string
    labTypeName: string
    operativeId: number
    labOrderPrice: number
    labDoctorAmount: number
    labClinicAmount: number
    currentDfAmount: number
    currentCfAmount: number
    // dfNet: number;
}
export interface DentistInterface {
    dentistId: number
    dentistFullname: string
}
export interface labDfInterface {
    doctorId: number
    labDoctorAmount: number
    labClinicAmount: number
    // dfNet: number;
    dfAmount: number
    dfAmountNet: number
    cfAmount: number
    cfAmountNet: number

}
export default function ManageDoctorLabFee(props: FromDoctorFeeProps) {
    const { t } = useTranslation()
    const [labOrderService, setLabOrderService] = useState<LabOrderFeeService>(props.data)
    const [doctors, setDoctors] = useState<DentistInterface[]>([])
    // const [doctorId, setDoctorId] = useState<any>([])
    const [dataUpdate, setDataUpdate] = useState<labDfInterface>({
        labDoctorAmount: labOrderService.labDoctorAmount,
        labClinicAmount: labOrderService.labClinicAmount,
        doctorId: labOrderService.doctorId,
        // dfNet: labOrderService.dfNet,
        dfAmount: labOrderService.dfAmount,
        dfAmountNet: labOrderService.dfAmount,
        cfAmount: labOrderService.cfAmount,
        cfAmountNet: labOrderService.cfAmountNet,



    })

    const [patient, setPatient] = useState<LabOrderPatientInterface>({
        branchId: labOrderService.branchId,
        branchCnNumber: labOrderService.vnNumber,
        patientsFullname: labOrderService.patientsFullname
    })
    const [orderDetail, setOrderDetail] = useState<orderDetailInface>({
        labOrderNumber: labOrderService.labOrderNumber,
        dateOrder: toBuddhistYear(moment(labOrderService.dateOrder)),
        labCompanyId: labOrderService.labCompanyId,
        labCompanyName: labOrderService.labCompanyName,
        operativeId: labOrderService.operativeId,
        listOfProcedures: labOrderService.listOfProcedures,
        labOrderStatus: labOrderService.labOrderStatus,
        vnNumber: labOrderService.vnNumber,
        timeOrder: labOrderService.timeOrder,
        doctorId: labOrderService.doctorId,
        doctorFullname: labOrderService.doctorFullname,
        dateExport: toBuddhistYear(moment(labOrderService.dateExport)),
        dateImport: toBuddhistYear(moment(labOrderService.dateImport)),
        dateReceive: toBuddhistYear(moment(labOrderService.dateReceive)),
        labTypeName: labOrderService.labTypeName,
        labOrderPrice: labOrderService.labOrderPrice,
        labDoctorAmount: labOrderService.labDoctorAmount,
        labClinicAmount: labOrderService.labClinicAmount,
        // dfNet: labOrderService.dfNet,
        currentDfAmount: labOrderService.currentDfAmount,
        currentCfAmount: labOrderService.currentCfAmount,
    })
    const loadData = useCallback(() => {
        ClinicApi.findAllDentists().then(({ data }) => setDoctors([{ dentistId: 0, dentistFullname: '' }, ...data]))
    }, [])
    const loadDoctorFee = async (doctorId: number, type: 'change' | 'default') => {
        const condition: FindDoctorFeeProps = {
            operativeId: labOrderService.operativeId,
            branchId: Number(getBranch()),
            doctorId: doctorId
        }
        const resp = await TreatmentApi.findDoctorFee(condition)
        if (resp.status === 200 && resp.data) {
            if (type === 'change')
                resp.data && setDataUpdate((val) => ({ ...val, doctorId, dfAmount: resp.data.dfAmount, cfAmount: resp.data.cfAmount }))
        }
    }
    const getLabelLabOrderStatus = (status: string) => {
        let label: any = ''
        if (status === 'ORDER_LAB') label = t('LAB_ORDER.LAB_STATUS.ORDER_LAB')
        else if (status === 'SEND_LAB') label = t('LAB_ORDER.LAB_STATUS.SEND_LAB')
        else if (status === 'RECEIVE_LAB') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_LAB')
        else if (status === 'RECEIVE_BILL') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_BILL')
        else if (status === 'REPLY_LAB') label = t('LAB_ORDER.LAB_STATUS.REPLY_LAB')
        else if (status === 'CANCEL_LAB') label = t('LAB_ORDER.LAB_STATUS.CANCEL_LAB')
        else if (status === 'CLOSE_LAB') label = t('LAB_ORDER.LAB_STATUS.CLOSE_LAB')
        return label
    }
    const handleChang = (key: 'labDoctorAmount' | 'labClinicAmount', value: any) => {
        if (key === 'labDoctorAmount') {
            let totalDf = value
            let totalCf = 0
            totalDf = totalDf > 100 ? 100 : totalDf
            totalCf = 100 - totalDf

            setDataUpdate({ ...dataUpdate, labDoctorAmount: totalDf, labClinicAmount: totalCf })

        }

        else if (key === 'labClinicAmount') {
            let totalDf = dataUpdate.labDoctorAmount
            let totalCf = value

            totalCf = totalCf > 100 ? 100 : totalCf
            totalDf = 100 - totalCf


            setDataUpdate({ ...dataUpdate, labDoctorAmount: totalDf, labClinicAmount: totalCf })
        }

    }
    const calculate = () => {
        let totalDf = 0
        let totalCf = 0

        totalDf = (Number(dataUpdate.labDoctorAmount) / 100) * Number(labOrderService.labOrderPrice)
        totalCf = (Number(dataUpdate.labClinicAmount) / 100) * Number(labOrderService.labOrderPrice)

        setDataUpdate({ ...dataUpdate, dfAmountNet: totalDf, cfAmountNet: totalCf })

    }
    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        setLabOrderService(props.data)
        setPatient({
            branchId: labOrderService.branchId,
            branchCnNumber: labOrderService.vnNumber,
            patientsFullname: labOrderService.patientsFullname
        })
        setDataUpdate({ labDoctorAmount: labOrderService.labDoctorAmount, labClinicAmount: labOrderService.labClinicAmount, doctorId: labOrderService.doctorId, dfAmount: labOrderService.dfAmount, dfAmountNet: labOrderService.dfAmountNet, cfAmount: labOrderService.cfAmount, cfAmountNet: labOrderService.cfAmountNet })
        setOrderDetail({
            labOrderNumber: labOrderService.labOrderNumber,
            dateOrder: toBuddhistYear(moment(labOrderService.dateOrder)),
            labCompanyId: labOrderService.labCompanyId,
            labCompanyName: labOrderService.labCompanyName,
            operativeId: labOrderService.operativeId,
            listOfProcedures: labOrderService.listOfProcedures,
            labOrderStatus: labOrderService.labOrderStatus,
            vnNumber: labOrderService.vnNumber,
            timeOrder: labOrderService.timeOrder,
            doctorId: labOrderService.doctorId,
            doctorFullname: labOrderService.doctorFullname,
            dateExport: toBuddhistYear(moment(labOrderService.dateExport)) === "Invalid dateNaN" ? "-" : toBuddhistYear(moment(labOrderService.dateExport)),
            dateImport: toBuddhistYear(moment(labOrderService.dateImport)) === "Invalid dateNaN" ? "-" : toBuddhistYear(moment(labOrderService.dateImport)),
            dateReceive: toBuddhistYear(moment(labOrderService.dateReceive)) === "Invalid dateNaN" ? "-" : toBuddhistYear(moment(labOrderService.dateReceive)),
            labTypeName: labOrderService.labTypeName,
            labOrderPrice: labOrderService.labOrderPrice,
            labDoctorAmount: labOrderService.labDoctorAmount,
            labClinicAmount: labOrderService.labClinicAmount,
            currentDfAmount: labOrderService.currentDfAmount,
            currentCfAmount: labOrderService.currentCfAmount,


        })
    }, [props.data])

    useEffect(() => {
        calculate()


    }, [dataUpdate.labDoctorAmount, dataUpdate.labClinicAmount])
    // , dataUpdate.labClinicAmount, dataUpdate.dfNet
    const submit = () => {
        if (!dataUpdate?.doctorId) {
            return notiError(t("MANAGE_DOCTOR_LAB_FEE.MESSAGE.DOCTOR_EMPTY"))
        }
        ManageDoctorLabFeeApi.update(parseInt(labOrderService.labOrderId), dataUpdate).then((res: any) => {
            if (res.status === 200) {
                notiSuccess(t(`MANAGE_DOCTOR_LAB_FEE.MESSAGE.EDIT_SUCCESS`), '', null, () => {
                    props.onReset()
                })
            } else {
                const err = res.response?.data
                notiError(t(`${err.message}`))
            }
        })
            .catch((e) => {
                const err = e.response.data
                notiError(t(`${err.message}`))
            })

    }
    return (<ModalCustom
        size={'xl'}
        title={t('MANAGE_DOCTOR_LAB_FEE.TITLE_FORM')}
        alignFooter={'end'}
        // footerInline
        onSubmit={submit}
        onReset={props.onReset}
        textBtnConfirm={t('BUTTON.SAVE')}
        textBtnCancel={t('BUTTON.CANCEL')}
        component={
            <>
                <UseStyled.Body>
                    <div>
                        <Grid container spacing={2} >
                            {/* detail orders */}
                            {<Grid item lg={3} sm={12} spacing={2}  >
                                <Grid container spacing={2}  >
                                    <Grid item md={4} lg={5} xs={6}>{t('MANAGE_DOCTOR_LAB_FEE.FORM.NO_LAB')}</Grid>
                                    {<Grid item md={8} lg={7} xs={6}>{orderDetail.labOrderNumber}</Grid>}
                                </Grid>

                                <Grid container spacing={2} sx={{ marginBottom: "3px" }}  >
                                    <Grid item md={4} lg={5} xs={6}>{t('MANAGE_DOCTOR_LAB_FEE.FORM.PATIENT_NAME')}</Grid>
                                    <Grid item md={8} lg={7} xs={6} >{patient.patientsFullname}</Grid>
                                </Grid>
                                <Grid container spacing={2}  >
                                    <Grid item md={4} lg={5} xs={6} >{t('MANAGE_DOCTOR_LAB_FEE.FORM.DATE_ORDER')}</Grid>
                                    <Grid item md={8} lg={7} xs={6} >{orderDetail.dateOrder}</Grid>
                                </Grid>
                                <Grid container spacing={2}  >
                                    <Grid item md={4} lg={5} xs={6}>{t('MANAGE_DOCTOR_LAB_FEE.FORM.COMPANY')}</Grid>
                                    <Grid item md={8} lg={7} xs={6} >{orderDetail.labCompanyName}</Grid>
                                </Grid>
                                <Grid container spacing={2}  >
                                    <Grid item md={4} lg={5} xs={6}>{t('MANAGE_DOCTOR_LAB_FEE.FORM.LIST_PROCEDURES')}</Grid>
                                    <Grid item md={8} lg={7} xs={6} style={{ lineHeight: '1.25', display: 'block' }} >{orderDetail.listOfProcedures}</Grid>
                                </Grid>
                            </Grid>}
                            {/* detail of status */}
                            {<Grid item lg={2} sm={12} spacing={2}  >
                                <Grid container spacing={2}  >
                                    <Grid item md={4} lg={4} xs={6}>{t('MANAGE_DOCTOR_LAB_FEE.FORM.STATUS')}:</Grid>
                                    {<Grid item md={8} lg={8} xs={6}>{getLabelLabOrderStatus(orderDetail.labOrderStatus)}</Grid>}
                                </Grid>
                                <Grid container spacing={2}  >
                                    <Grid item md={4} lg={4} xs={6}>VN</Grid>
                                    {<Grid item md={8} lg={8} xs={6}>{orderDetail.vnNumber}</Grid>}
                                </Grid>
                                <Grid container spacing={2}  >
                                    <Grid item md={4} lg={6} xs={6}>{t('MANAGE_DOCTOR_LAB_FEE.FORM.TIME_ORDER')}</Grid>
                                    {<Grid item md={8} lg={6} xs={6} sx={{ marginLeft: '-20px' }} >{orderDetail.timeOrder}</Grid>}
                                </Grid>
                            </Grid>}
                            {/* detail of doctor */}
                            {<Grid item lg={7} sm={12} spacing={2} >
                                <Grid container spacing={0.5} >
                                    <Grid item spacing={0.5} md={4} lg={4} xs={6} style={{ marginTop: '50px' }} >{t('MANAGE_DOCTOR_LAB_FEE.FORM.DOCTOR')}</Grid>
                                    {<Grid item md={8} lg={8} xs={6} style={{ marginTop: '50px', marginLeft: '-100px' }}>
                                        {orderDetail.doctorFullname}
                                    </Grid>}
                                </Grid>
                                {/* detail of  time */}
                                <Grid container spacing={0.5} >
                                    <Grid item spacing={0.5} md={4} lg={4} xs={6} >
                                        <Grid item spacing={0.5} md={4} lg={12} xs={6} >
                                            {t('MANAGE_DOCTOR_LAB_FEE.FORM.DATE_EXPORT')}  {orderDetail.dateExport}

                                        </Grid>
                                    </Grid>
                                    <Grid item spacing={0.5} md={4} lg={4} xs={6} >
                                        <Grid item spacing={0.5} md={4} lg={12} xs={6} >
                                            {t('MANAGE_DOCTOR_LAB_FEE.FORM.DATE_IMPORT')}  {orderDetail.dateImport}

                                        </Grid>
                                    </Grid>
                                    <Grid item spacing={0.5} md={4} lg={4} xs={6} >
                                        <Grid item spacing={0.5} md={4} lg={12} xs={6} >
                                            {t('MANAGE_DOCTOR_LAB_FEE.FORM.DATE_RECEIVE')}  {orderDetail.dateReceive}


                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={0.5} >
                                    <Grid item spacing={0.5} md={4} lg={4} xs={6}  > {t('MANAGE_DOCTOR_LAB_FEE.FORM.LABTYPE_NAME')}</Grid>
                                    {<Grid item md={8} lg={8} xs={6} >
                                        {orderDetail.labTypeName}
                                    </Grid>}
                                </Grid>

                            </Grid>}
                        </Grid>
                        {/* section แก้ไข DF */}
                        <Grid item lg={12} sm={12} spacing={2} >
                            <Grid item xs={12}  >
                                <Grid container className=' border-top' sx={{ marginTop: "20px" }} >
                                    <Grid item lg={12} sm={12} spacing={2} sx={{ marginTop: "20px" }}>
                                        <UseStyled.Title>{t('MANAGE_DOCTOR_LAB_FEE.FORM.LAB_PRICE')} {numberToCurrencyFormat(labOrderService.labOrderPrice)} {t('CURRENCY_CHANGE.TH')}</UseStyled.Title>
                                    </Grid>
                                    <Grid item lg={12} sm={12} spacing={2}>
                                        <UseStyled.SubTitle>{t('MANAGE_DOCTOR_LAB_FEE.FORM.EDIT_DOCTOR_DF')}</UseStyled.SubTitle>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={8} md={8} maxWidth={500} sx={{ marginBottom: "10px" }}>
                            {/* <AutocompleteSelect
                                required={true}
                                inputLabel={t('APPOINTMENT.FORM.DOCTOR')}
                                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                value={doctors && doctorId ? _.find(doctors, { doctorId: doctorId }) : null}
                                onchange={(event, val) => {
                                    setDoctorId(val.doctorId)
                                }}
                                labelId="dentist"
                                options={doctors}
                                getOptionLabel={(option: any) => option.dentistFullname}
                                // errMessage={errorMessage.DOCTOR_ANY_REQUIRED}
                                // disabled={disabled}
                                disableClearable
                            /> */}
                            <AutocompleteSelect
                                labelId="sl-dentist"
                                options={doctors}
                                getOptionLabel={(option: DentistInterface) => option.dentistFullname}

                                onchange={(e, value) => {
                                    loadDoctorFee(value?.dentistId || 0, 'change')
                                }}
                                noOptionsText={t('NOT_FOUND')}
                                filterOptions={(option) => option.dentistFullname}
                                value={_.find(doctors, { dentistId: dataUpdate.doctorId }) || ''}
                                onOpen={() => setDataUpdate({ ...dataUpdate, doctorId: 0 })}
                                disableClearable
                            />
                        </Grid>

                        <Grid item lg={12} sm={12} spacing={2} >
                            <Grid item xs={6}  >
                                <Grid container>
                                    <Grid item lg={6} sm={6} spacing={2} sx={{ borderRight: 1, borderColor: "#DEDEDE" }}>
                                        <Grid container spacing={12} sx={{ textAlign: 'right' }} >
                                            <Grid item spacing={0.5} md={4} lg={4} xs={6}  >
                                                {t('MANAGE_DOCTOR_LAB_FEE.FORM.DEFAULT')} :
                                            </Grid>
                                            {<Grid item md={8} lg={8} xs={6} sx={{ paddingRight: "30px" }}>
                                                {orderDetail.currentDfAmount} %
                                            </Grid>}
                                        </Grid>
                                        <Grid container spacing={12} sx={{ textAlign: 'right', }} >
                                            <Grid item md={4} lg={4} xs={6}  >
                                                {t('MANAGE_DOCTOR_LAB_FEE.FORM.CURRENT')} :
                                            </Grid>
                                            {<Grid item md={8} lg={8} xs={6} sx={{ paddingRight: "30px" }}>
                                                {orderDetail.labDoctorAmount} %
                                            </Grid>}
                                        </Grid>
                                        <Grid container spacing={12} sx={{ textAlign: 'right' }}  >
                                            <Grid item md={4} lg={4} xs={6}  >
                                                {t('MANAGE_DOCTOR_LAB_FEE.FORM.FIX_DF_LAB')} :
                                            </Grid>
                                            {<Grid item md={8} lg={8} xs={6} sx={{ paddingRight: "30px" }}>
                                                <Row>
                                                    <Col >
                                                        <InputTextField
                                                            style={{ width: "70px" }}
                                                            size={`small`}
                                                            key={`newDfAmount`}
                                                            name={`newDfAmount`}
                                                            label={''}
                                                            value={dataUpdate.labDoctorAmount}
                                                            onchange={(e: any) => handleChang('labDoctorAmount', e.target.value)}
                                                            type="number"
                                                            hideControl />%
                                                    </Col>
                                                </Row>

                                            </Grid>}
                                        </Grid>

                                        <Grid container spacing={12} sx={{ textAlign: 'right' }} >
                                            <Grid item md={4} lg={4} xs={6}  >
                                                {t('MANAGE_DOCTOR_LAB_FEE.FORM.DF_NET')} :
                                            </Grid>
                                            {<Grid item md={8} lg={8} xs={6} sx={{ paddingRight: "30px" }} >
                                                {numberFormat(dataUpdate.dfAmountNet)} {t('CURRENCY_CHANGE.TH')}
                                            </Grid>}
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} sm={6} spacing={2} >
                                        <Grid container spacing={12} sx={{ textAlign: 'right' }} >
                                            <Grid item spacing={0.5} md={4} lg={4} xs={6}  >
                                                {t('MANAGE_DOCTOR_LAB_FEE.FORM.DEFAULT')} :
                                            </Grid>
                                            {<Grid item md={8} lg={8} xs={6} >
                                                {orderDetail.currentCfAmount} %
                                            </Grid>}
                                        </Grid>
                                        <Grid container spacing={12} sx={{ textAlign: 'right' }} >
                                            <Grid item md={4} lg={4} xs={6}  >
                                                {t('MANAGE_DOCTOR_LAB_FEE.FORM.CURRENT')} :
                                            </Grid>
                                            {<Grid item md={8} lg={8} xs={6} >
                                                {orderDetail.labClinicAmount} %
                                            </Grid>}
                                        </Grid>
                                        <Grid container spacing={12} sx={{ textAlign: 'right' }}  >
                                            <Grid item md={4} lg={4} xs={6}  >
                                                {t('MANAGE_DOCTOR_LAB_FEE.FORM.FIX_CF_LAB')} :
                                            </Grid>
                                            {<Grid item md={8} lg={8} xs={6} style={{ alignItems: 'left' }} >
                                                <InputTextField
                                                    style={{ width: "70px" }}
                                                    size={`small`}
                                                    key={`newDfAmount`}
                                                    name={`newDfAmount`}
                                                    label={''}
                                                    value={dataUpdate.labClinicAmount}
                                                    onchange={(e: any) => handleChang('labClinicAmount', e.target.value)}
                                                    type="number"
                                                    hideControl />%

                                            </Grid>}

                                        </Grid>
                                        <Grid container spacing={12} sx={{ textAlign: 'right' }} >
                                            <Grid item md={4} lg={4} xs={6}  >
                                                {t('MANAGE_DOCTOR_LAB_FEE.FORM.CF_NET')} :
                                            </Grid>
                                            {<Grid item md={8} lg={8} xs={6} >
                                                {numberFormat(dataUpdate.cfAmountNet)} {t('CURRENCY_CHANGE.TH')}
                                            </Grid>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </UseStyled.Body>
            </>
        }
    />)
}




