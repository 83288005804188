import { useState, useEffect, useCallback } from 'react'
import { Grid, Box, Typography } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */

/** CONSTANTS */
import { colors } from 'constants/theme'

/** UTILS || SLICE */
import { numberFormat } from 'utils/app.utils'

/** STYLE */
import { styled } from '@mui/material/styles'
import * as UseStyled from 'features/dashboard/useStyled'
import Coin from 'assets/dashboard/coin2.svg'
import Arrow from 'assets/dashboard/arrow.svg'
import NewUser from 'assets/dashboard/new_user.svg'
import Map from 'assets/dashboard/map.svg'
import { CardSalesProps } from 'features/dashboard/dashboard'
import moment from 'moment'
import DashboardApi, { IFSellSumery, IFSumeryFilter } from 'api/dashboard.api'

const Title = styled(Typography)<{ size?: 'large' | 'medium' | 'small' }>(({ theme, size }) => ({
  color: colors.white,
  fontSize: (size === 'large' && 35) || (size === 'small' && 24) || 29,
  fontWeight: 600,
  lineHeight: size === 'large' ? '1.2' : ''
}))

const Subtitle = styled(Typography)(({ theme }) => ({
  color: colors.white,
  fontSize: 14,
}))

export default function Summary(props: CardSalesProps) {
  const { t } = useTranslation()

  const [sellSumery, setSellSumery] = useState<IFSellSumery>()
  const [diffSumery, setDiffSumery] = useState<{ dateDiff: number, sumery: number, productSumery: number, treatmentSumery: number, type: 'sales_margin_up' | 'sales_margin_down' }>({ dateDiff: 0, sumery: 0, productSumery: 0, treatmentSumery: 0, type: 'sales_margin_up' })
  const [branch, setBranch] = useState<(string | number)[]>(['ALL'])
  const [rangeDate, setRangeDate] = useState([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])

  useEffect(() => {
    setRangeDate(props.rangeDate || [])
  }, [props.rangeDate])

  useEffect(() => {
    setBranch(props.branch || [])
  }, [props.branch])

  const loadData = useCallback(async () => {
    const condition: IFSumeryFilter = { dateStart: rangeDate[0], dateEnd: rangeDate[1], branchId: branch[0] === 'ALL' ? `` : branch.toString() }
    const res = await DashboardApi.findSellSumery(condition)
    if (res.status === 200) {
      const summeris: IFSellSumery = res.data
      setSellSumery(summeris)
      const dateDiff = summeris.sumeryDiff.dateDiff
      const sumery = summeris.sumeryAll.sumery - summeris.sumeryDiff.sumeryBefore
      const productSumery = summeris.sumeryAll.productSumery - summeris.sumeryDiff.productSumeryBefore
      const treatmentSumery = summeris.sumeryAll.treatmentSumery - summeris.sumeryDiff.treatmentSumeryBefore
      const type = summeris.sumeryAll.sumery > summeris.sumeryDiff.sumeryBefore ? 'sales_margin_up' : 'sales_margin_down'
      setDiffSumery({ dateDiff: dateDiff, sumery: sumery, productSumery: productSumery, treatmentSumery: treatmentSumery, type: type })
    }
  }, [branch, rangeDate])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <Box>
      <Grid container spacing={2} className="mb-4">
        <Grid item sm={6} lg={4} className='w-100'>
          <UseStyled.SummaryBox type='sales' className='summary-box d-flex justify-content-between'>
            <Box sx={{ flex: 1 }}>
              <Title size='large'>{numberFormat(sellSumery?.sumeryAll?.sumery || 0)}</Title>
              <Subtitle>{t('DASHBOARD.SALES')}</Subtitle>
            </Box>
            <Box className='d-flex flex-column mt-3 mt-xl-0' sx={{ flex: 1 }}>
              <Box>
                <Title size='small'>{numberFormat(sellSumery?.sumeryAll?.productSumery || 0)}</Title>
                <Subtitle>{t('DASHBOARD.SALES_PRODUCT')}</Subtitle>
              </Box>
              <Box className='mt-auto'>
                <Title size='small'>{numberFormat(sellSumery?.sumeryAll?.treatmentSumery || 0)}</Title>
                <Subtitle>{t('DASHBOARD.SALES_OPERATIVE')}</Subtitle>
              </Box>
            </Box>
            <img src={Coin} alt="coin" />
          </UseStyled.SummaryBox>
        </Grid>
        <Grid item sm={6} lg={4} className='w-100'>
          <UseStyled.SummaryBox type={diffSumery.type} className='summary-box d-flex justify-content-between'>
            <Box sx={{ flex: 1 }}>
              <Title size='large'>{(diffSumery.sumery > 0 ? '+' : '')}{numberFormat(diffSumery.sumery)}</Title>
              <Subtitle>{t('DASHBOARD.SALES_MARGIN', { day: diffSumery.dateDiff })}</Subtitle>
            </Box>
            <Box className='d-flex flex-column mt-3 mt-xl-0' sx={{ flex: 1 }}>
              <Box>
                <Title size='small'>{(diffSumery.productSumery > 0 ? '+' : '')}{numberFormat(diffSumery.productSumery)}</Title>
                <Subtitle>{t('DASHBOARD.SALES_PRODUCT')}</Subtitle>
              </Box>
              <Box className='mt-auto'>
                <Title size='small'>{(diffSumery.treatmentSumery > 0 ? '+' : '')}{numberFormat(diffSumery.treatmentSumery)}</Title>
                <Subtitle>{t('DASHBOARD.SALES_OPERATIVE')}</Subtitle>
              </Box>
            </Box>
            <img src={Arrow} alt="arrow" />
          </UseStyled.SummaryBox>
        </Grid>
        <Grid item xs={6} lg={2} className='w-100'>
          <UseStyled.SummaryBox type='service' className='summary-box d-flex align-items-end'>
            <Box>
              <Title size='medium'>{numberFormat(sellSumery?.serviceUsaged || 0, 0, 0)}</Title>
              <Subtitle>{t('DASHBOARD.SERVICE')}</Subtitle>
            </Box>
            <img src={Map} alt="map" />
          </UseStyled.SummaryBox>
        </Grid>
        <Grid item xs={6} lg={2} className='w-100'>
          <UseStyled.SummaryBox type='customer' className='summary-box d-flex align-items-end'>
            <Box>
              <Title size='medium'>{numberFormat(sellSumery?.newCustomer || 0, 0, 0)}</Title>
              <Subtitle>{t('DASHBOARD.NEW_CUSTOMER')}</Subtitle>
            </Box>
            <img src={NewUser} alt="newUser" />
          </UseStyled.SummaryBox>
        </Grid>
      </Grid>
    </Box>
  )
}
