import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface TreatmentCourseInterface {
  treatmentCourseId?: number
  treatmentCourseTypeId: number
  treatmentCourseName: string
  treatmentCourseNameEn: string
  status?: 'ACTIVE' | 'ISACTIVE'
  treatmentCourseItems: TreatmentCourseItemsInterface[]
  paymentType?: string
  paymentDetail?: string

}

export interface TreatmentCoursePaymentInterface {
  payType: string
  status: boolean
  detail: string
  date?: string
}
export interface TreatmentCourseItemsInterface {
  type: 'OPERATIVE' | 'MEDICINE' | 'PRODUCT'
  itemId: number
  price: number
  qty: number
}

export default class TreatmentCourseApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('treatment-courses', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('treatment-courses', playload)
  }

  static findById(id: number): Promise<TreatmentCourseInterface> {
    return this.api.get(`treatment-courses/${id}`)
  }

  static update(id: number, body: TreatmentCourseInterface) {
    return this.api.patch(`treatment-courses/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`treatment-courses/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`treatment-courses/${id}`)
  }
}
