
export default function IconHighlightPen(props: any) {
    return (
        <svg width="29" height="27" viewBox="-5 -2 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="13.3184" y="9.8042" width="5.12615" height="9.23631" transform="rotate(44.8667 13.3184 9.8042)" fill={props.color}/>
<path d="M16.4338 13.4123L13.2921 10.3141L7.25048 16.2722L10.3921 19.3703L16.4338 13.4123ZM15.014 8.61612L18.1557 11.7143L24.1671 5.78606L21.0255 2.68789L15.014 8.61612ZM12.4765 7.78199L19.0015 14.2166L12.0838 21.0386C11.6005 21.5152 11.0366 21.7535 10.3921 21.7535C9.7477 21.7535 9.18381 21.5152 8.70048 21.0386L8.64006 20.979L8.54944 21.0684C8.32791 21.2868 8.07094 21.4556 7.77852 21.5748C7.4861 21.694 7.17919 21.7535 6.85777 21.7535H3.26298C2.98103 21.7535 2.78971 21.6344 2.68902 21.3961C2.58832 21.1577 2.63867 20.9393 2.84006 20.7407L5.61923 18L5.55881 17.9404C5.07548 17.4638 4.83381 16.9077 4.83381 16.2722C4.83381 15.6366 5.07548 15.0806 5.55881 14.6039L12.4765 7.78199ZM12.4765 7.78199L19.3338 1.01965C19.8171 0.543008 20.381 0.304688 21.0255 0.304688C21.6699 0.304688 22.2338 0.543008 22.7171 1.01965L25.8588 4.11782C26.3421 4.59446 26.5838 5.15054 26.5838 5.78606C26.5838 6.42158 26.3421 6.97766 25.8588 7.4543L19.0015 14.2166L12.4765 7.78199Z" fill="black"/>
<line x1="1.09278e-07" y1="25.6738" x2="29" y2="25.6738" stroke={props.color} strokeWidth="2.5"/>
        </svg>
        
    )
}