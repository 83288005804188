import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface PrefixInterface {
  data?: any
  prefixId?: number
  prefixName: string
  prefixNameEn?: string
  status: string
}

export default class PrefixApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('prefixes', { params: { ...props } }).then((res) => res)
  }

  static create(prefixName: string, prefixNameEn: string) {
    return this.api.post('prefixes', { prefixName, prefixNameEn })
  }

  static findById(id: number): Promise<PrefixInterface> {
    return this.api.get(`prefixes/${id}`)
  }

  static update(id: number, body: PrefixInterface) {
    return this.api.patch(`prefixes/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`prefixes/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`prefixes/${id}`)
  }
}
