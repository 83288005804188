import { menuThirds } from 'constants/menus'
import Careers from 'features/setting/default-users/careers/careers'
import Experts from 'features/setting/default-users/experts/experts'
import FrequenciesAlcohols from 'features/setting/default-users/frequencies-alcohols/frequencies-alcohols'
import FrequenciesCigarettes from 'features/setting/default-users/frequencies-cigarettes/frequencies-cigarettes'
import PatientTypes from 'features/setting/default-users/patient-types/patient-types'
import Prefixes from 'features/setting/default-users/prefixes/prefixes'
import Personnels from 'features/setting/personnel-managements/personnels/personnels'
import FormPersnnel from 'features/setting/personnel-managements/personnels/form-personnel'
import Roles from 'features/setting/personnel-managements/roles/roles'
import FormRole from 'features/setting/personnel-managements/roles/form-roles'
import AccessChannels from 'features/setting/default-users/access-channels/access-channels'
import ServiceFactor from 'features/setting/default-users/service-factor/service-factor'

const routeDefaultUsers = [
  {
    ...menuThirds.PERSONNEL,
    component: Personnels,
    appBar: true
  },
  {
    ...menuThirds.PERSONNEL.name,
    key: menuThirds.PERSONNEL.key,
    textname: `เพิ่ม ${menuThirds.PERSONNEL.name}`,
    active: false,
    path: menuThirds.PERSONNEL.path + '/create',
    component: FormPersnnel,
    appBar: true
  },
  {
    ...menuThirds.PERSONNEL.name,
    key: menuThirds.PERSONNEL.key,
    textname: `แก้ไข ${menuThirds.PERSONNEL.name}`,
    active: false,
    path: menuThirds.PERSONNEL.path + '/update',
    component: FormPersnnel,
    appBar: true
  },
  {
    ...menuThirds.PREFIX,
    component: Prefixes,
    appBar: true
  },
  {
    ...menuThirds.CAREER,
    component: Careers,
    appBar: true
  },
  {
    ...menuThirds.ACCESS_CHANNEL,
    component: AccessChannels,
    appBar: true
  },
  {
    ...menuThirds.SERVICE_FACTOR,
    component: ServiceFactor,
    appBar: true
  },
  {
    ...menuThirds.EXPERT,
    component: Experts,
    appBar: true
  },
  {
    ...menuThirds.FREQUENCIES_ALCOHOL,
    component: FrequenciesAlcohols,
    appBar: true
  },
  {
    ...menuThirds.FREQUENCIES_CIGARETTE,
    component: FrequenciesCigarettes,
    appBar: true
  },
  {
    ...menuThirds.PATIENT_TYPE,
    component: PatientTypes,
    appBar: true
  },
  {
    ...menuThirds.ROLE,
    component: Roles,
    appBar: true
  },
  {
    ...menuThirds.ROLE.name,
    key: menuThirds.ROLE.key,
    textname: `เพิ่ม ${menuThirds.ROLE.name}`,
    active: false,
    path: menuThirds.ROLE.path + '/create',
    component: FormRole,
    appBar: true
  }
]

export default routeDefaultUsers
