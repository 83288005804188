import { useState, useEffect, useCallback } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, MenuItem, Divider, IconButton, Paper, Box, Typography } from '@mui/material'
import 'features/setting/treatment-plans/treatment-plans/treatment-plans.css'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'
import HeaderLabel from 'component/CardCustom/HeaderLabel'

/** STYLE */
import useStyles from 'features/setting/treatment-plans/treatment-plans/style'
import { colors } from 'constants/theme'
import * as SummaryTreatmentStyle from 'features/treatments/SummaryTreatment/UseStyle'
import * as UseStyled from 'features/treatments/SummaryTreatment/UseStyle'

/** UTILS || SLICE */
import { numberFormat } from 'utils/app.utils'

/** CONSTANT */
import { Icons, icons } from 'constants/images'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import TreatmentPlanTypeApi from 'api/setting/treatment-plans/treatment-plan-type.api'
import TreatmentPlanApi, { TreatmentPlanInterface } from 'api/setting/treatment-plans/treatment-plan.api';
import { notiSuccess } from 'component/notifications/notifications';
import { routeName } from 'routes/routes-name'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { TreatmentStateInterface, historyToothChart, isEditTootChart, isEditTootChartType, setColorToothChart, setEditTootChart, setEditTootChartType, setSubmitTootChart, setSummeryTreatments, setToothChartSelected, setTreatment, setTreatmentByKey, toothChartSelected, treatment } from 'app/slice/treatment.slice';
import $ from 'jquery'
import { selectMe } from 'app/slice/user.slice';
import Tx from 'features/treatments/MainMenu/OPD/Tx';

const initStateErrorMessage = {
  TREATMENT_PLAN_TYPE_STRING_BASE: '',
  TREATMENT_PLAN_TYPE_MAXIMUM_LENGTH: '',
  TREATMENT_PLAN_TYPE_STRING_EMPTY: '',
  TREATMENT_PLAN_TYPE_ANY_REQUIRED: '',

  TREATMENT_PLAN_NAME_STRING_BASE: '',
  IS_DUPLICATE_TREATMENT_PLAN_NAME: '',
  TREATMENT_PLAN_NAME_MAXIMUM_LENGTH: '',
  TREATMENT_PLAN_NAME_STRING_EMPTY: '',
  TREATMENT_PLAN_NAME_ANY_REQUIRED: '',
  NAME: '',
  TYPE: '',
}

interface ItemsTreatmentPlans {
  typeId: number
  typeName: string
  value: ValueInterface[]
}
interface ValueInterface {
  itemId: string
  type: string
  itemName: string
  unit: string
  price: number
  img?: string
}

interface SummaryTablePlansInterface {
  typeId: number
  itemId: number
  itemCode: string
  itemName: string
  priceUnit: number
  color?: string
  teeth?: any
  teethJson?: string
  teethType?: string
  operativeId?: number

}

export default function FormTreatmentPlans() {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location: any = useLocation()
  const [initLocation, setInitLocation] = useState()
  const [editMenu, setEditMenu] = useState<TreatmentPlanInterface>()

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const [plansName, setPlansName] = useState('')
  const [planTypes, setPlanTypes] = useState([])

  const [treatmentPlanId, setTreatmentPlanId] = useState<number>()
  const [selectPlanType, setSelectPlanType] = useState('')
  const [selectFilterItem, setSelectFilterItem] = useState('ALL')
  const [summaryTablePlans, setSummaryTablePlans] = useState<SummaryTablePlansInterface[]>([])

  const [operatives, setOperatives] = useState([])
  const [operativeTypes, setOperativeTypes] = useState([])

  const isEditTootCharts: any = useSelector(isEditTootChart)
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')

  const user = useSelector(selectMe)
  const historyToothCharts = useSelector(historyToothChart)
  const valueToothChartSelected = useSelector(toothChartSelected)

  const treatments: TreatmentStateInterface = useSelector(treatment)

  const headTable = [
    {
      title: t('TREATMENT_PLAN.FORM.SUMMARY.TABLE.NO'),
      align: 'center'
    },
    {
      title: t('TREATMENT_PLAN.FORM.SUMMARY.TABLE.TOOTGH'),
      align: 'left'
    },
    {
      title: t('TREATMENT_PLAN.FORM.SUMMARY.TABLE.CODE'),
      align: 'left'
    },
    {
      title: t('TREATMENT_PLAN.FORM.SUMMARY.TABLE.ITEM'),
      align: 'left'
    },
    {
      title: t('TREATMENT_PLAN.FORM.SUMMARY.TABLE.PRICE'),
      align: 'right'
    }, {

      title: t('TREATMENT_PLAN.FORM.SUMMARY.TABLE.ACTION'),
      align: 'center'
    }
  ]

  //TABLE DISPLAY
  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(5)

  const loadTreatmentPlans = useCallback(() => {
    if (!initLocation) setInitLocation(location)

    if (initLocation !== undefined) {
      const { state }: any = initLocation
      setEditMenu(state)
      if (editMenu?.treatmentPlanId) onEdit()
    }
  }, [location, initLocation, editMenu])

  const loadOperative = useCallback(() => {
    if (!planTypes.length) TreatmentPlanTypeApi.findAll({ status: 'ACTIVE', pageLimit: 100 }).then(({ data }) => data && setPlanTypes(data))
    if (!operativeTypes.length) OperativeTypeApi.findAllActive({}).then(({ data }) => data && setOperativeTypes(data))
  }, [page, pageLimit, selectFilterItem, search])

  useEffect(() => {
    loadOperative()
    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [] }))
    dispatch(setEditTootChartType(''))
    dispatch(setEditTootChart(null))
    dispatch(setToothChartSelected([]))
  }, [loadOperative]);

  useEffect(() => {
    loadTreatmentPlans()
  }, [loadTreatmentPlans])

  const onEdit = () => {
    if (editMenu?.treatmentPlanId) {
      if (editMenu?.treatmentPlanId)
        setTreatmentPlanId(editMenu?.treatmentPlanId)
      setPlansName(editMenu.treatmentPlanName)
      setSelectPlanType(String(editMenu.treatmentPlanTypeId))
      const teethJson: any = []
      const tempSummaryTablePlans: any = editMenu.treatmentPlanItems?.map((tx: any) => {
        if (tx.teethJson !== "") {
          teethJson.push(JSON.parse(tx.teethJson))
        }
        const hasOverdue = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || null })
        const item = {
          ...tx,
          qty: 1,
          total: tx.price,
          doctorId: !hasOverdue ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || null : null,
          discount: 0,
          additional: 0,
          discountUnit: 'BAHT',
          additionalUnit: 'BAHT',
          dfAmount: 0,
          cfAmount: 0,
          dfDiscountAmount: 0,
          cfDiscountAmount: 0,
          dfDiscountUnit: 'BAHT',
          cfDiscountUnit: 'BAHT',
          isEdit: null
        }
        return item
      })
      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, ...tempSummaryTablePlans] }))
      dispatch(setToothChartSelected([...teethJson, ...(valueToothChartSelected || []),]))
    }
  }

  const submit = () => {
    const tempTreatmentPlan: TreatmentPlanInterface = {
      treatmentPlanTypeId: parseInt(selectPlanType),
      treatmentPlanName: plansName,
      treatmentPlanItems: treatments.summeryTreatments.map((stp: SummaryTablePlansInterface) => {
        return {
          type: 'OPERATIVE',
          itemId: stp.operativeId || stp.itemId,
          teeth: stp.teeth || "",
          teethJson: stp.teethJson || "",
          teethType: stp.teethType || ""
        }
      })
    }
    if (!selectPlanType || !plansName) {
      const msg: any = {
        NAME: !plansName ? t(`TREATMENT_PLAN.MESSAGE.TREATMENT_PLAN_NAME`) : '',
        TYPE: !selectPlanType ? t(`TREATMENT_PLAN.MESSAGE.TREATMENT_PLAN_TYPE`) : '',
      }
      setErrorMessage({ ...initStateErrorMessage, ...msg })
      return false
    } else {
      if (!treatmentPlanId) create(tempTreatmentPlan)
      else {
        tempTreatmentPlan.status = editMenu?.status || 'ACTIVE'
        update(tempTreatmentPlan)
      }
    }
  }

  const create = (treatmentPlan: TreatmentPlanInterface) => {
    TreatmentPlanApi.create(treatmentPlan)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t(`TREATMENT_PLAN.MESSAGE.SUCCESS.CREATE`), '', null, () => {
            return
          })
          history.push(routeName.treatmentPlans)
        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_PLAN.MESSAGE.${err.message}`) } })
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_PLAN.MESSAGE.${err.message}`) } })
      })
  }

  const update = (treatmentPlan: TreatmentPlanInterface) => {
    TreatmentPlanApi.update(Number(treatmentPlanId), treatmentPlan)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`TREATMENT_PLAN.MESSAGE.SUCCESS.UPDATE`), '', null, () => {
            return

          })
          history.push(routeName.treatmentPlans)
        }
        else {
          const err = res.response?.data
          setErrorMessage({ ...errorMessage, ...{ [err?.message]: t(`TREATMENT_PLAN.MESSAGE.${err?.message}`) } })
        }
      })
      .catch((e) => {
        const err = e.response?.data
        setErrorMessage({ ...errorMessage, ...{ [err?.message]: t(`TREATMENT_PLAN.MESSAGE.${err?.message}`) } })
      })
  }

  const onDelete = (index: any) => {
    const products: any = []
    _.forEach(summaryTablePlans, (data: any, i: any) => {
      if (i !== index) products.push(data)
    });
    setSummaryTablePlans([...products])
    const items = _.filter(treatments.summeryTreatments, (treatmentSummary, i: number) => i !== index)
    const toothChartSelected: any = []

    if (!_.isEmpty(items)) {
      _.map(items, (item, index) => {
        if (item.teethJson) toothChartSelected.push(JSON.parse(item.teethJson))
      })
    }
    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: items }))
    dispatch(setToothChartSelected([...historyToothCharts, ...toothChartSelected]))
    dispatch(setEditTootChart(null))
    dispatch(setEditTootChartType(''))
  }

  const clearErrorMessage = async () => setErrorMessage(initStateErrorMessage)

  const editToothChart = async (id: number) => {
    if (!treatments.summeryTreatments[id].teeth) {
      const resp: any = await OperativeTypeApi.findById(treatments.summeryTreatments[id].operativeTypeId || treatments.summeryTreatments[id].itemTypeId)
      if (resp.status === 200) {
        dispatch(setSummeryTreatments({ index: id, key: 'color', value: resp.data.color }))
        $('.div-component').delay(500).animate({ scrollTop: 0 }, 500)
        dispatch(setEditTootChartType(treatments.summeryTreatments[id].teethType))
        dispatch(setEditTootChart(id))
      }
    } else {
      $('.div-component').delay(500).animate({ scrollTop: 0 }, 500)
      dispatch(setEditTootChartType(treatments.summeryTreatments[id].teethType))
      dispatch(setEditTootChart(id))
    }
  }

  return (
    <div className={`${classes.root} container-card`}>
      <div className="d-flex flex-column">
        <HeaderLabel
          text={`${treatmentPlanId ? t('TREATMENT_PLAN.FORM.FORM_EDIT') : t('TREATMENT_PLAN.FORM.FORM_ADD')}`}
          navigator={{ previousTo: routeName.treatmentPlans, previousTitle: t('TREATMENT_PLAN.TITLE'), currentTitle: treatmentPlanId ? t('TREATMENT_PLAN.FORM.FORM_EDIT') : t('TREATMENT_PLAN.FORM.FORM_ADD') }}
          goBack={routeName.treatmentPlans}
          onSubmit={submit}
        />
        <Card className="border shadow-sm mx-4 mb-4 h-auto card">
          <Card.Body>
            <Row>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <Row>
                  <Col sm={6} lg={4} className="pr-sm-2">
                    <BasicSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          setSelectPlanType(value)
                          clearErrorMessage()
                        }
                      }}
                      label={t('TREATMENT_PLAN.FORM.TREATMENT_PLAN_TYPE')}
                      selectId="courseType"
                      value={selectPlanType}
                      labelId="courseType"
                      helperText={errorMessage.TYPE || ''}
                      options={planTypes.map((obj: any) => {
                        return (
                          <MenuItem key={obj.treatmentPlanTypeId} value={obj.treatmentPlanTypeId}>
                            {obj.treatmentPlanTypeName}
                          </MenuItem>
                        )
                      })}
                    />
                  </Col>
                  <Col sm={6} lg={4} className="pt-3 pt-sm-0 pl-sm-2">
                    <InputTextField
                      label={t('TREATMENT_PLAN.FORM.TREATMENT_PLAN_NAME')}
                      value={plansName}
                      onchange={(e) => {
                        setPlansName(e.target.value)
                        clearErrorMessage()
                      }}
                      onkeypress={() => { clearErrorMessage() }}
                      helperText={errorMessage.NAME || errorMessage.IS_DUPLICATE_TREATMENT_PLAN_NAME || ''}
                    />
                  </Col>
                </Row>
                <Divider className="mx-0 mt-4 mb-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                <div style={{ height: "fit-content" }}>
                  <Tx
                    userId={user.userId}
                    patientId={0}
                    // disabled={props.disabledMenu} 
                    type={"SettingTreatmentPlan"}
                    hasCreateTreatment={'PERMISS'}
                  />
                </div>


                <Row>
                  <Row className='mt-3'>
                    <Col>
                      <div className={`${classes.titleSecond}`} style={{ fontWeight: 600, color: colors.themeMainColor }}>
                        {t('TREATMENT_PLAN.FORM.SUMMARY_PLAN')}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pr-0">
                    <Col className='pr-0'>
                      <TableContainer component={Paper} className={`mt-3`} style={{ boxShadow: 'none' }}>
                        <Table aria-label="simple table" style={{ minWidth: '1024px' }}>
                          <TableHead className={classes.header}>
                            <TableRow>
                              {headTable.map((title: any) => (
                                <TableCell key={title} align={title.align}>
                                  <div className={`${classes.titleSecond}`}>{title.title}</div>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody className={`sm-data-table ${classes.body}`}>
                            {treatments.summeryTreatments.length === 0 && (
                              <TableRow>
                                <TableCell colSpan={5} className="align-middle text-center p-2" style={{ fontSize: '16px' }}>{t('REGISTER.NO_ITEM')}</TableCell>
                              </TableRow>
                            )}
                            {treatments.summeryTreatments
                              .map(({ itemId, operativeCode, itemCode, operativeName, itemName, total, teeth }: any, index: any) => {
                                return (
                                  <TableRow key={`index-opd-${index}`}>
                                    <TableCell align='center' width="5%" className='p-3' style={{ fontSize: '16px' }}>{index + 1}</TableCell>
                                    <TableCell align='left' width="25%" className='p-3' style={{ fontSize: '16px' }}>
                                      <>
                                        <UseStyled.InputToothChart className={`d-sm-flex w-100 text-nowrap `}>
                                          <InputTextField disabled value={teeth || ''} />
                                          <UseStyled.BtnEditToothChart disableFocusRipple disableRipple
                                            onClick={() => editToothChart(index)}
                                            className={`p-0 ml-2 ${isEditTootCharts === index + 1 ? 'active' : ''} `}>
                                            {isEditTootCharts === index ? <Icons.ToothSolid /> : <Icons.ToothOutlined />}
                                          </UseStyled.BtnEditToothChart>
                                        </UseStyled.InputToothChart>
                                      </>
                                    </TableCell>
                                    <TableCell width="10%" className='p-3' style={{ fontSize: '16px' }}>{operativeCode || itemCode}</TableCell>
                                    <TableCell width="25%" className='p-3' style={{ fontSize: '16px' }}>{operativeName || itemName}</TableCell>
                                    <TableCell align='right' width="10%" className='p-3' style={{ fontSize: '16px' }}>{numberFormat(total)}</TableCell>
                                    <TableCell align='center' width="7%">
                                      <IconButton disableFocusRipple disableRipple onClick={(e) => onDelete(index)} className="p-0" sx={{ backgroundColor: 'transparent' }}>
                                        <img src={icons.btnMinus} alt="discard" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                  </Row>
                </Row>
                <SummaryTreatmentStyle.NetPrice className={`d-flex align-items-center justify-content-between justify-content-md-end mt-2 px-3 px-md-4`}>
                  {t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')} <span className="pl-2 pl-md-5 text-right">{numberFormat(treatments.summeryTreatments.map(({ total }: any) => total).reduce((a: any, b: any) => a + b, 0) || 0)} {t('CURRENCY_CHANGE.TH')}</span>
                </SummaryTreatmentStyle.NetPrice>
              </Box>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
