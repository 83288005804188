import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Box, TableRow, TableCell, Grid, Typography } from '@mui/material'
import useWindowSize from 'utils/useWindowSize'

export interface IFRowTxC {
    disabled: boolean
    no: number
    itemTypes: any[]
    items: any[]
    itemId: number
    itemTypeId: number
    itemCode: string
    qty: number
    price: number
    edit?: any
    type?: any
    data?: any
    id?: number
    balance: number
    totalQtyNow: number
    handleChangeInputItemType?: (e: any, value: any) => void
    handleChangeItemType?: (e: any, value: any) => void
    handleChangeInputItem?: (e: any, value: any) => void
    handleChangeItem?: (e: any, value: any) => void
    handleChangeQty?: (event: any) => void
    handleChangePrice?: (event: any) => void
    handleDiscard?: (event: any) => void
}
export default function RowTxC(props: IFRowTxC) {
    const { t } = useTranslation()
    const [width] = useWindowSize()
    const { no, data } = props
    const valueShow = (type: any) => {
        const { totalQty, lastBalance, lastPaid, totalPrice } = props.edit
        const checkNo = no === 0
        const valueMap: any = {
            SUMMARY: checkNo ? t('TXC.TABLE.SUMMARY_NOW') : t('TXC.TABLE.SUMMARY_NEW'),
            ALL_QTY_NOW: checkNo ? totalQty : _.sumBy(data, (o: any) => o.qty),
            ALL_USE_NOW: checkNo ? totalQty - lastBalance : _.sumBy(data, (o: any) => o.qty - o.balance),
            ALL_BALANCE_NOW: checkNo ? lastBalance : _.sumBy(data, (o: any) => o.balanceNew),
            ALREADY_PAY: lastPaid,
            MUST_PAY: totalPrice - lastPaid,
            TOTAL_COUSE_PRICE: checkNo ? totalPrice : _.sumBy(data, (o: any) => o.price * o.qty),
        }
        return valueMap[type]

    }
    return (
        <TableRow key={no}>
            {width >= 1200 && (
                <>
                    <TableCell align="center" scope="row" className="py-3 py-md-2">{valueShow('SUMMARY')}</TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2" style={{ color: '#1CB99A' }}>{`${t('TXC.TABLE.ALL_QTY_NOW')} : ${valueShow('ALL_QTY_NOW')}`}</TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2" style={{ color: '#FB5252' }}>{`${t('TXC.TABLE.ALL_USE_NOW')} :  ${valueShow('ALL_USE_NOW')}`}</TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2" style={{ color: '#1CB99A' }}>{`${t('TXC.TABLE.ALL_BALANCE_NOW')} : ${valueShow('ALL_BALANCE_NOW')}`}</TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2" style={{ color: '#1CB99A' }}>{`${t('TXC.TABLE.ALREADY_PAY')} : ${valueShow('ALREADY_PAY')}`}</TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2">{`${t('TXC.TABLE.MUST_PAY')} : ${valueShow('MUST_PAY')}`}</TableCell>
                    <TableCell align="center" scope="row" className="py-3 py-md-2" style={{ color: '#FB5252' }}>{`${t('TXC.TABLE.TOTAL_COUSE_PRICE')} : ${valueShow('TOTAL_COUSE_PRICE')}`}</TableCell>
                </>
            ) || (
                    <TableCell align="center" scope="row" className="py-3 py-md-2">
                        <Grid container spacing={1}>
                            <Grid item className='w-100'>
                                <Box className='d-flex align-items-center'>
                                    <Typography className='text-left' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {valueShow('SUMMARY')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#1CB99A' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {t('TXC.TABLE.ALL_QTY_NOW')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#1CB99A' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {`${valueShow('ALL_QTY_NOW')}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#FB5252' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {t('TXC.TABLE.ALL_USE_NOW')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#FB5252' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {`${valueShow('ALL_USE_NOW')}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#1CB99A' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {t('TXC.TABLE.ALL_BALANCE_NOW')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#1CB99A' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {`${valueShow('ALL_BALANCE_NOW')}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#1CB99A' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {t('TXC.TABLE.ALREADY_PAY')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#1CB99A' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {`${valueShow('ALREADY_PAY')}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {t('TXC.TABLE.MUST_PAY')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {`${valueShow('MUST_PAY')}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#FB5252' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {t('TXC.TABLE.TOTAL_COUSE_PRICE')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='w-100 pr-md-2'>
                                <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                                    <Typography style={{ color: '#FB5252' }} className='text-left pb-2 pb-sm-0' sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                                        {`${valueShow('TOTAL_COUSE_PRICE')}`}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </TableCell>
                )}
        </TableRow>
    )
}
