import { Box, Tabs, Typography } from '@mui/material'

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

export const ResponsiveTable = styled(Box)(({ theme }) => ({
    '.MuiTable-root': {
        '.MuiTableBody-root': {
            '.MuiTableCell-root': {
                color: colors.textPrimary,
                '&:first-of-type': {
                    paddingLeft: 4,
                    paddingRight: 4
                },
                '&:not(.update-by)': {
                    paddingTop: 10,
                    paddingBottom: 10
                },
                '&.date-expired': {
                    color: colors.themeThirdColor
                }
            }
        }
    }
}))

export const ImageProduct = styled(Box)(({ theme }) => ({
    '> .image-wrap': {
        paddingTop: '100%',
        border: `1px solid ${colors.lightGray}`,
        img: {
            objectFit: 'contain'
        }
    },
    [theme.breakpoints.up('xs')]: {
        maxWidth: 200,
    }
}))

export const InformationProduct = styled(Box)(({ theme }) => ({
    paddingTop: 32,
    paddingBottom: 32,
    [theme.breakpoints.down('xs')]: {
        paddingTop: 16,
        paddingBottom: 0,
    }
}))

export const CustomTabs = styled(Tabs)(({ theme }) => ({
    borderBottom: `1px solid ${colors.extraLightBlue}`,
    minHeight: 'unset',
    '.MuiTabs-scroller ': {
        overflow: 'auto !important'
    },
    '.MuiTabs-indicator': {
        backgroundColor: colors.themeSecondColor
    },
    '.MuiTab-root': {
        minHeight: 'unset',
        height: 40,
        minWidth: 'unset',
        color: colors.textPrimary,
        fontSize: 16,
        width: 'max-content',
        maxWidth: 'unset',
        padding: '0 16px',
        fontWeight: 400,
        '&.Mui-selected': {
            color: colors.themeSecondColor
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 18
        }
    }
}))

export const Typo = styled(Typography)<{ type?: 'title' }>(({ theme, type }) => ({
    color: colors.textPrimary,
    fontWeight: type === 'title' ? 500 : 400,
    flex: type === 'title' ? 'none' : 1,
    maxWidth: type === 'title' ? 200 : 'auto',
    //width: '100%',
    [theme.breakpoints.up('lg')]: {
        marginRight: type === 'title' ? 24 : 0
    }
}))

export const TypoTitleOrder = styled(Typo)(({ theme }) => ({
    maxWidth: '100px !important',
    marginRight: '0 !important',
    paddingRight: '20px'
}))
