import Custom from 'features/admin/custom/custom'

const routeCustom = [
    {
        path: '/admin/custom',
        component: Custom,
        active: false,
        key: "BACKEND",
        appBar: true
    }
]

export default routeCustom
