import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface LabTypeInterface {
  labTypeName: string
  status: string
  mapLab: [
    {
      labName: string
      inputSupport: string
    }
  ]
}

export default class LabTypeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('lab-types', { params: { ...props } }).then((res) => res)
  }

  static create(playload: LabTypeInterface) {
    return this.api.post('lab-types', playload)
  }

  static findLabs(labTypeId: number): Promise<any> {
    return this.api.get('lab-types/find-labs', { params: { labTypeId: labTypeId } }).then((res) => res)
  }

  static findById(id: number): Promise<LabTypeInterface> {
    return this.api.get(`lab-types/${id}`)
  }

  static update(id: number, body: LabTypeInterface) {
    return this.api.patch(`lab-types/${id}`, body).then((res) => res)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`lab-types/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`lab-types/${id}`)
  }
}
