export default function IconEllipsis() {
  return (
    <svg id="meatballs_manageSchedule" data-name="meatballs manageSchedule" xmlns="https://www.w3.org/2000/svg" width="33.333" height="32" viewBox="0 0 33.333 32">
      <rect id="Rectangle_3985" data-name="Rectangle 3985" style={{ fill: 'none' }} width="33.333" height="32" />
      <g id="Icon_feather-more-vertical" data-name="Icon feather-more-vertical" transform="translate(22.219 14.782) rotate(90)">
        <path id="Path_5758" data-name="Path 5758" style={{ fill: 'none', stroke: '#29302e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M2,1A1,1,0,1,1,1,0,1,1,0,0,1,2,1Z" transform="translate(0 4.836)" />
        <path id="Path_5759" data-name="Path 5759" style={{ fill: 'none', stroke: '#29302e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M2,1A1,1,0,1,1,1,0,1,1,0,0,1,2,1Z" transform="translate(0 -2)" />
        <path id="Path_5760" data-name="Path 5760" style={{ fill: 'none', stroke: '#29302e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '3px' }} d="M2,1A1,1,0,1,1,1,0,1,1,0,0,1,2,1Z" transform="translate(0 11.219)" />
      </g>
    </svg>
  )
}
