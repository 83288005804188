
type iconProps = {
    width?: string
    height?: string
    color?: string
}

const BtnDelFilled = (props: iconProps) => {
    return (
        <svg
            width={props?.width || "18"}
            height={props?.height || "18"}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.5001 2.99999H13.9167C13.518 1.25518 11.8126 0.00225 9.83339 0H8.16671C6.18745 0.00225 4.48206 1.25518 4.08339 2.99999H1.50007C1.03983 2.99999 0.666748 3.33577 0.666748 3.74998C0.666748 4.16419 1.03983 4.5 1.50007 4.5H2.33339V14.25C2.33616 16.32 4.20003 17.9975 6.50007 18H11.5001C13.8001 17.9975 15.664 16.32 15.6667 14.25V4.5H16.5001C16.9603 4.5 17.3334 4.16422 17.3334 3.75001C17.3334 3.3358 16.9603 2.99999 16.5001 2.99999ZM8.16675 12.75C8.16675 13.1642 7.79366 13.5 7.33343 13.5C6.87315 13.5 6.50007 13.1642 6.50007 12.75V8.25001C6.50007 7.8358 6.87315 7.50002 7.33339 7.50002C7.79362 7.50002 8.16671 7.8358 8.16671 8.25001V12.75H8.16675ZM11.5001 12.75C11.5001 13.1642 11.127 13.5 10.6667 13.5C10.2065 13.5 9.83343 13.1642 9.83343 12.75V8.25001C9.83343 7.8358 10.2065 7.50002 10.6667 7.50002C11.127 7.50002 11.5001 7.8358 11.5001 8.25001V12.75ZM5.80925 2.99999C6.1637 2.10171 7.10761 1.50114 8.16675 1.49998H9.83343C10.8926 1.50114 11.8365 2.10171 12.1909 2.99999H5.80925Z"
                fill={props?.color || "white"}
            />
        </svg>

    )
}

export default BtnDelFilled