import { useState, useEffect } from 'react'
import { Box, Typography, Button, TableRow, TableCell, IconButton } from '@mui/material'
import _ from 'lodash'
import { useForm, useFieldArray } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { treatment, TreatmentStateInterface, setSummeryTreatments, setDiagnosisTreatments } from 'app/slice/treatment.slice'
import * as modalSlice from 'app/slice/modal.slice'

/** API */
import TreatmentApi, { MessageTemplate } from 'api/dentists/treatment.api'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { icons } from 'constants/images'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import InputTextField from 'component/Input/InputTextField'
import { notiPopup } from 'component/notifications/notifications'
import { ToastContainer } from 'react-toastify'
import { closeModal } from 'app/slice/modal.slice'

/** STYLE */
import { styled } from '@mui/material/styles'

const FragmentTabs = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${colors.white}`
}))

const ButtonTab = styled(Button)(({ theme }) => ({
  width: '50%',
  height: 40,
  color: colors.textPrimary,
  padding: 0,
  borderBottom: `1px solid transparent`,
  borderRadius: 0,
  '&.active': {
    color: colors.themeSecondColor,
    borderColor: colors.themeSecondColor
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 185
  }
}))

const FragmentMessage = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: '50%'
  }
}))

const ResponsiveTable = styled('div')(({ theme }) => ({
  maxHeight: '100%',
  '.MuiPaper-root': {
    minHeight: 'unset !important',
    maxHeight: '100%'
  },
  '.MuiTable-root': {
    minWidth: 'unset',
    width: '100%',
    tableLayout: 'fixed',
    '.MuiTableHead-root': {
      border: 'none !important',
      '.MuiTableCell-root': {
        fontWeight: 400,
        padding: 12,
        position: 'sticky',
        top: 0,
        zIndex: 5
      },
      '.MuiTableCell-root:nth-of-type(1)': {
        width: 50,
        borderTopLeftRadius: 4
      },
      '.MuiTableCell-root:nth-of-type(2)': {},
      '.MuiTableCell-root:nth-of-type(3)': {
        width: 80,
        textAlign: 'center',
        borderTopRightRadius: 4
      }
    },
    '.MuiTableBody-root': {
      position: 'relative',
      zIndex: 0,
      '.MuiTableCell-root': {
        fontWeight: 400,
        padding: 10,
        backgroundColor: colors.white,
        verticalAlign: 'top'
      },
      '.MuiOutlinedInput-root, .MuiOutlinedInput-root input': {
        height: 35
      },
      '.new-field': {
        '.MuiTableCell-root:not(:nth-of-type(2))': {
          paddingTop: 16
        }
      }
    }
  },
  '.MuiTableFooter-root': {
    display: 'none'
  },
  '.row-add-new-message': {
    '.btn-add-new-message': {
      padding: '0 0 0 14px',
      fontSize: 16,
      color: colors.themeSecondColor,
      backgroundColor: 'transparent !important',
      fontWeight: 400,
      span: {
        fontSize: 20,
        color: colors.themeSecondColor
      },
      '&:hover': {
        fontWeight: 600
      }
    },
    '.MuiTableCell-root:hover': {
      cursor: 'pointer',
      '.btn-add-new-message': {
        fontWeight: 600
      }
    }
  }
}))

type BranchMessageProps = {
  messages: MessageTemplate[]
  addMessage?: (msg: string) => void
}

type UserMessageProps = {
  messages: MessageTemplate[]
  addMessage?: (msg: string) => void
  setTemplateUserMessage?: (message: MessageTemplate[]) => void
}

export interface IPopupMessage {
  type: 'summaryTreatment' | 'diagnosis'
  itemId?: number
  userId?: number
  onReset?: () => void
}

export default function PopupMessage(props: IPopupMessage) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const modal: boolean = useSelector(modalSlice.handleShow)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const tabs: string[] = ['TREATMENT_SUMMARY.POPUP.TREATMENT_MESSAGE.DEFAULT_MESSAGE', 'TREATMENT_SUMMARY.POPUP.TREATMENT_MESSAGE.USER_MESSAGE']
  const [activeTab, setActiveTab] = useState(0)

  const [branchMessage, setBranchMessage] = useState<MessageTemplate[]>([])
  const [userMessage, setUserMessage] = useState<MessageTemplate[]>([])
  const [userTemplateMessage, setUserTemplateMessage] = useState<MessageTemplate[]>([])

  const loadTemplateMessageBranch = () => {
    TreatmentApi.templateMessageBranch()
      .then(({ data }) => {
        setBranchMessage(data)
      })
      .catch(() => setBranchMessage([]))
  }

  const loadTemplateMessageUser = () => {
    TreatmentApi.templateMessageUser()
      .then(({ data }) => {
        setUserMessage(data)
      })
      .catch(() => setUserMessage([]))
  }

  const handleAddMessage = (message: string) => {
    if (props.type === 'summaryTreatment') {
      const msg = _.get(treatments.summeryTreatments[props.itemId || 0], 'note')
      dispatch(setSummeryTreatments({ index: props.itemId, key: 'note', value: !msg ? message : `${msg}, ${message}` }))
    } else if (props.type === 'diagnosis') {
      const msg = _.get(treatments.patientDiagnosis[props.itemId || 0], 'note')
      dispatch(setDiagnosisTreatments({ index: props.itemId, key: 'note', value: !msg ? message : `${msg}, ${message}` }))
    }
  }

  const handleSubmit = () => {
    notiPopup('clear')
    let template: MessageTemplate[] = []
    _.map(userTemplateMessage, (msg: MessageTemplate, index) => {
      if (msg.message) return (template = [...template, msg])
    })
    TreatmentApi.newTemplateMessage(template)
      .then(() => {
        notiPopup('success', t(`HOME.LINE_MESSAGE.MESSAGE.SUCCESS.USER_MESSAGE`))
        loadTemplateMessageUser()
        dispatch(closeModal())
        if (props?.onReset) props?.onReset()
      })
      .catch(() => { return })
  }

  useEffect(() => {
    if (modal) {
      new Promise<void>((resolve) => resolve())
        .then(loadTemplateMessageBranch)
        .then(loadTemplateMessageUser)
    }
  }, [modal])

  return (
    <ModalCustom
      size={'xl'}
      title={t('TREATMENT_SUMMARY.POPUP.TREATMENT_MESSAGE.TITLE')}
      alignFooter={'end'}
      fullscreen={'xxl-down'}
      closeButton
      footerInline
      textBtnCancel={t('BUTTON.CANCEL')}
      textBtnConfirm={t('BUTTON.SAVE')}
      onReset={props.onReset}
      onSubmit={handleSubmit}
      modalStyle={'modal-treatment-message'}
      component={
        <>
          <Box className="pt-1 d-flex flex-column overflow-hidden" sx={{ '*': { color: colors.textPrimary } }}>
            <FragmentTabs className="d-xl-none">
              {_.map(tabs, (tab: string, indexTab: number) => {
                return (
                  <ButtonTab className={`${indexTab === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(indexTab)}>
                    {t(tab)}
                  </ButtonTab>
                )
              })}
            </FragmentTabs>
            <Box className="d-flex overflow-hidden">
              <FragmentMessage className={`w-100 ${activeTab === 0 ? 'd-flex' : 'd-none'} d-xl-flex flex-column pr-xl-2`}>
                <Typography className="d-none d-xl-block mb-1" sx={{ fontSize: 18, fontWeight: 500 }}>
                  {t('TREATMENT_SUMMARY.POPUP.TREATMENT_MESSAGE.DEFAULT_MESSAGE')}
                </Typography>
                <BranchMessage messages={branchMessage} addMessage={(msg: string) => handleAddMessage(msg)} />
              </FragmentMessage>

              <FragmentMessage className={`w-100 ${activeTab === 1 ? 'd-flex' : 'd-none'} d-xl-flex flex-column pl-xl-2`}>
                <Typography className="d-none d-xl-block mb-1" sx={{ fontSize: 18, fontWeight: 500 }}>
                  {t('TREATMENT_SUMMARY.POPUP.TREATMENT_MESSAGE.USER_MESSAGE')}
                </Typography>
                <ToastContainer containerId={'popup'} className={'Toast-popup'} />
                <UserMessage
                  messages={userMessage}
                  addMessage={(msg: string) => handleAddMessage(msg)}
                  setTemplateUserMessage={(messages: MessageTemplate[]) => {
                    if (!_.isEmpty(messages)) setUserTemplateMessage(messages)
                    else setUserTemplateMessage([])
                  }}
                />
              </FragmentMessage>
            </Box>
          </Box>
        </>
      }
    />
  )
}

function BranchMessage(props: BranchMessageProps) {
  const { t } = useTranslation()

  const handleAddMessage = (message: string) => {
    if (_.isEmpty(message)) {
      return
    }
    else if (props.addMessage) props.addMessage(message)
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.MESSAGE') },
    { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.ACTION') }
  ]

  const renderData = (data: MessageTemplate, no: number) => {
    return (
      <TableRow key={`BranchMessage-${no}`}>
        <TableCell align="center" scope="row">
          {props.messages?.length ? props.messages?.length - no : 0}
        </TableCell>
        <TableCell scope="row">{data.message}</TableCell>
        <TableCell align="center" scope="row">
          <IconButton aria-label="add" onClick={() => handleAddMessage(data.message)} sx={{ backgroundColor: 'transparent !important', padding: 0 }}>
            <img src={icons.btnAdd} alt="add" style={{ width: 24 }} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ResponsiveTable className={'mt-2 overflow-hidden'}>
      <TableCustom
        customScroll
        hidePagination
        page={1}
        pageLimit={0}
        sortType={'ASC'}
        sortBy={''}
        rowCount={0}
        onSort={() => {
          return
        }}
        setPageLimit={() => {
          return
        }}
        setPage={() => {
          return
        }}
        headCells={headCells}
        rowsData={_.map(props.messages, (message: MessageTemplate, indexBranch) => {
          return renderData(message, indexBranch)
        })}
      />
    </ResponsiveTable>
  )
}

function UserMessage(props: UserMessageProps) {
  const { t } = useTranslation()
  const [error, setError] = useState('')

  const { register, control, getValues } = useForm()

  const formMessage = useFieldArray({
    control,
    name: 'message'
  })

  const handleAddMessage = (message: string) => {
    if (_.isEmpty(message)) {
      return
    }
    if (props.addMessage) props.addMessage(message)
  }

  useEffect(() => {
    if (!_.isEmpty(props.messages)) {
      formMessage.replace([...props.messages])
      if (props.setTemplateUserMessage) props.setTemplateUserMessage([...props.messages])
    }
  }, [props.messages])

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.MESSAGE') },
    { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.ACTION') }
  ]

  const addNewMessage = () => {
    return (
      <TableRow className="row-add-new-message">
        <TableCell
          align="left"
          scope="row"
          colSpan={3}
          onClick={() => {
            if (_.some(getValues('message'), (msg, index) => !msg.message)) {
              return setError(t('VALIDATION.ENTER_MESSAGE'))
            } else formMessage.prepend({ templateTextId: null, message: '' })
          }}
        >
          <Button variant="text" className="btn-add-new-message d-flex align-items-baseline">
            <span className={'mr-1'}>+</span> {t('HOME.LINE_MESSAGE.BUTTON.ADD_MESSAGE')}
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  const renderData = (data: MessageTemplate, no: number) => {
    return (
      <TableRow key={`UserMessage-${no}`} className={`${!getValues(`message.${no}.templateTextId`) ? 'new-field' : ''}`}>
        <TableCell align="center" scope="row">
          {formMessage.fields.length - no}
        </TableCell>
        <TableCell scope="row">
          {(!getValues(`message.${no}.templateTextId`) && (
            <InputTextField
              inputProps={{ maxLength: 255 }}
              name="message"
              key={`message.${no}.templateTextId`}
              register={register(`message.${no}.message`, {
                onChange: () => {
                  if (props.setTemplateUserMessage) props.setTemplateUserMessage(getValues('message'))
                  setError('')
                }
              })}
              helperText={!getValues(`message.${no}.message`) ? error : ''}
            />
          )) ||
            data.message}
        </TableCell>
        <TableCell align="center" scope="row">
          <IconButton className="mr-1" aria-label="add" onClick={() => handleAddMessage(getValues(`message.${no}.message`))} sx={{ backgroundColor: 'transparent !important', padding: 0 }}>
            <img src={icons.btnAdd} alt="add" style={{ width: 24 }} />
          </IconButton>
          <IconButton
            className="ml-1"
            aria-label="trash"
            onClick={() => {
              formMessage.remove(no)
              if (props.setTemplateUserMessage) props.setTemplateUserMessage(getValues('message'))
              setError('')
            }}
            sx={{ backgroundColor: 'transparent !important', padding: 0 }}>
            <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ResponsiveTable className={'mt-2 overflow-hidden'}>
      <TableCustom
        customScroll
        hidePagination
        page={1}
        pageLimit={0}
        sortType={'ASC'}
        sortBy={''}
        rowCount={0}
        onSort={() => {
          return
        }}
        setPageLimit={() => {
          return
        }}
        setPage={() => {
          return
        }}
        headCells={headCells}
        rowsData={[
          addNewMessage(),
          _.map(formMessage.fields, (message: MessageTemplate, indexUser) => {
            return renderData(message, indexUser)
          })
        ]}
      />
    </ResponsiveTable>
  )
}
