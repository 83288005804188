import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function Inventory(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g data-name="Group 8646">
        <path data-name="Path 6280" d="M-49.069-51.539v16.668l14.556 6.109 13.8-5.807v-16.517l-14.177-5.741z" transform="translate(50.855 58.828)" style={{ fill: '#fff' }} />
        <path
          data-name="Path 6279"
          d="M15.423 2.125a.942.942 0 0 0-.7 0L3.478 6.622l4.529 1.81L19.6 3.8zm6.715 2.686L10.543 9.448l4.529 1.81 11.594-4.636-4.529-1.81zm6.124 3.2-12.246 4.9v14.927l12.246-4.9V8.016zM14.13 27.841V12.912L1.884 8.015v14.927zM14.023.375a2.826 2.826 0 0 1 2.1 0l13.431 5.373a.942.942 0 0 1 .591.874v16.32a1.884 1.884 0 0 1-1.185 1.748L15.423 30.1a.942.942 0 0 1-.7 0L1.187 24.691A1.884 1.884 0 0 1 0 22.942V6.622a.942.942 0 0 1 .592-.874z"
          transform="translate(1 .828)"
          style={{ fill: props.color || colors.themeSecondColor, fillRule: 'evenodd' }}
        />
      </g>
      <path data-name="Rectangle 3868" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
    </svg>
  )
}
