import IconProps from "assets/icon/payment-method/iconProps"
export default function Advance(props: IconProps) {
    return (
        <>
            <svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.5195 27.2354C41.5195 32.7584 37.0425 37.2354 31.5195 37.2354C29.7295 37.2354 28.0475 36.7654 26.5935 35.9404C25.0537 35.0675 23.7728 33.802 22.8814 32.2728C21.9901 30.7436 21.5201 29.0054 21.5195 27.2354C21.5195 24.6674 22.4875 22.3254 24.0775 20.5554C25.0144 19.5093 26.1616 18.6729 27.444 18.1007C28.7265 17.5286 30.1153 17.2337 31.5195 17.2354C37.0425 17.2354 41.5195 21.7124 41.5195 27.2354Z" style={{ stroke: props.active || '#9e9e9e' }} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M31.5195 7.44141V17.2355C30.1153 17.2338 28.7265 17.5547 27.444 18.1773C26.1616 18.7999 25.0144 19.7101 24.0775 20.8485C22.4274 22.8435 21.5159 25.4337 21.5195 28.1179C21.5201 30.0441 21.9901 31.9357 22.8814 33.5998C23.7728 35.264 25.0537 36.6411 26.5935 37.591C23.9315 38.467 20.3975 39.0002 16.5195 39.0002C8.23553 39.0002 1.51953 36.5637 1.51953 33.5591V7.44141" style={{ stroke: props.active || '#9e9e9e' }} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.51953 24.8529C1.51953 27.8576 8.23553 30.2941 16.5195 30.2941C18.3265 30.2941 20.0595 30.1788 21.6635 29.9655M1.51953 16.1471C1.51953 19.1517 8.23553 21.5882 16.5195 21.5882C19.2755 21.5882 21.8585 21.3184 24.0775 20.8482M35.5195 28.1176H31.5195V23.7647M31.5195 7.44118C31.5195 10.4458 24.8035 12.8824 16.5195 12.8824C8.23553 12.8824 1.51953 10.4458 1.51953 7.44118C1.51953 4.43656 8.23553 2 16.5195 2C24.8035 2 31.5195 4.43656 31.5195 7.44118Z" style={{ stroke: props.active || '#9e9e9e' }} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </>
    )
}

