import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ModalCustom from 'component/ModalCustom/ModalCustom'
import { styled } from '@mui/material/styles'
import { Box, Collapse, IconButton } from '@mui/material'

import $ from 'jquery'

import FormTrementCoursePayment from 'features/setting/treatment-courses/treatment-course/form-trement-course-payment'

import { TreatmentCourseInterface, TreatmentCoursePaymentInterface } from 'api/setting/treatment-courses/treatment-course.api'
import { notiError } from 'component/notifications/notifications'
import { colors } from 'constants/theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { numberFormat } from 'utils/app.utils'
import InputTextField from 'component/Input/InputTextField'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { dateToView } from 'utils/date.utils'


export const PayTableCollapseHead = styled('div')(({ theme }) => ({
    backgroundColor: colors.themeMainColor,
    color: `${colors.white}`,
    borderTopRightRadius: `8px  `,
    borderTopLeftRadius: `8px  `,
    padding: 15,
    fontSize: 16,
    fontWeight: 500,
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}))

export const PayTableCollapseBody = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: '500'
}))

export const PayTableCollapseBodySplitPay = styled('div')(({ theme }) => ({
    '.count-payment': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        paddingLeft: '1.5rem',
        '.sum-payment': {
            width: '150px',
            margin: '0 1rem'
        }
    },


}))

interface PopupPaymentChanelProps {
    handlePopPaymentChannel: any
    onClose: any
    summaryCourses: number
    countCourses: number
    dataEdit: any
}

const PopupPaymentChanel = (props: PopupPaymentChanelProps) => {
    const { t } = useTranslation()

    const [dataPayment, setDataPayment] = useState<any>([])
    const [errorPaymentChanel, setErrorPaymentChanel] = useState<boolean>(false)

    const handlePaymentChannel = (val: TreatmentCoursePaymentInterface) => {
        const newData = {
            paymentType: val.payType,
            paymentDetail: val.detail,
        }
        setDataPayment(newData)
    }

    const onClosePopup = () => {
        props.onClose(false)
    }

    const onSubmit = () => {
        if (handleCheckPayDetail(dataPayment)) {
            props.handlePopPaymentChannel(dataPayment)
            onClosePopup()
        }
    }

    const handleCheckPayDetail = (tempTreatmentCourse: TreatmentCourseInterface) => {
        if (tempTreatmentCourse?.paymentType === "") {
            notiError(t('TREATMENT_COURSE.MESSAGE.ERROR.EMPTY_PAYMENT_TYPE'))
            return false
        }
        if (tempTreatmentCourse?.paymentType === "SPLITPAY") {
            if (tempTreatmentCourse?.paymentDetail === "[]") {
                notiError(t('TREATMENT_COURSE.MESSAGE.ERROR.EMPTY_SPLITPAY'))
                return false
            } else {
                const data: any = tempTreatmentCourse.paymentDetail
                const dataDetail = JSON.parse(data)
                if (dataDetail[dataDetail.length - 1].balance !== 0) {
                    const divElement = document.querySelector('.select-payment-chanel-table div');
                    if (divElement) {
                        const divBottom = divElement.scrollHeight;
                        $('.select-payment-chanel-table div').delay(300).animate({ scrollTop: divBottom }, 500)
                    }

                    setErrorPaymentChanel(true)
                    return false
                } else {
                    return true
                }
            }
        } else {
            return true
        }
    }

    return (
        <ModalCustom
            closeButton
            size='lg'
            modalStyle={'modal-poppaychanel'}
            title={t('TXC.COURSE.POPUP.TITLE')}
            onSubmit={() => onSubmit()}
            textBtnConfirm={t('TXC.COURSE.POPUP.SAVE')}
            onReset={() => onClosePopup()}
            textBtnCancel={t('TXC.COURSE.POPUP.CANCEL')}
            alignFooter={'end'}
            component={
                <Box sx={{
                    '.select-payment-chanel': {
                        marginTop: '4px'
                    },
                    '.pl-md-3': {
                        marginLeft: '0',
                        paddingLeft: '0 !important'
                    },
                    '.select-payment-chanel-table': {
                        marginBottom: '0 !important'
                    }
                }}>
                    <FormTrementCoursePayment
                        type={'modal'}
                        handlePaymentChannel={handlePaymentChannel}
                        countCourses={props.countCourses}
                        summaryTableCourses={props.summaryCourses}
                        dataEdit={props.dataEdit.paymentDetail ? props.dataEdit : undefined}
                        statusError={errorPaymentChanel}
                        setErrorPaymentChanel={setErrorPaymentChanel}
                        maxHeightTable={'410px'}
                    />
                    {errorPaymentChanel &&
                        <div style={{ fontSize: '12px', color: colors.statusInactive }}>
                            *{t('TXC.COURSE.POPUP.ERROR_TOTAL')}
                        </div>
                    }
                </Box>
            }
        />
    )
}

export default PopupPaymentChanel

export const PaymentChanelTableCollapse = (props: any) => {
    const { t } = useTranslation()

    const [openCollapse, setOpenCollapse] = useState<boolean>(false)

    const paymentType = useCallback(() => {
        if (props?.dataPayment) {
            const val = props.dataPayment
            let newText = ''
            if (val.paymentType !== '') {
                if (val.paymentType === "CASH") {
                    newText = `${t('TXC.COURSE.PAY_CASH')}`
                } else if (val.paymentType === 'SPLITPAY') {
                    newText = `${t('TXC.COURSE.PAY_SPLITPAY')}`
                } else {
                    newText = `${t('TXC.COURSE.PAY_CHOOSEPAY')}`
                }
            }
            return newText
        }
    }, [props.dataPayment])

    const handleInputPaymentChanel = useCallback(() => {
        const val: any = props.dataPayment
        let newText = ''
        if (val.paymentType !== '') {
            if (val.paymentType === "CASH") {
                newText = `${t('TXC.COURSE.PAY_CASHALL')}`
            } else if (val.paymentType === 'SPLITPAY') {
                newText = `${t('TXC.COURSE.PAY_SPLITPAY')} ${JSON.parse(val.paymentDetail).length} ${t('TXC.COURSE.TIME')}`
            } else {
                newText = `${t('TXC.COURSE.PAY_CHOOSEPAY')}(${t('TXC.COURSE.COUNT')} ${props.countTXC} ${t('TXC.COURSE.LIST')})`
            }
        }
        return newText
    }, [props.dataPayment, props.sumTXC, props.countTXC])

    const handleTypeSplitPay = useCallback(() => {
        const val: any = props.dataPayment
        const valParse = JSON.parse(val.paymentDetail || [])
        let newText = ''

        if (val.paymentType === "SPLITPAY") {
            if (valParse[0]?.statusSplitPay === "SEPARATE") {
                newText = `${t('TREATMENT_COURSE.FORM.PAYMENT.SEPARATE')}`
            } else {
                newText = `${t('TREATMENT_COURSE.FORM.PAYMENT.NOTSEPARATE')}`
            }
        }
        return newText

    }, [props.dataPayment])

    const handleDataPayment = useCallback(() => {
        const val: any = props.dataPayment
        const valParse = JSON.parse(val.paymentDetail || [])

        return valParse
    }, [props.dataPayment])

    const headCells: any = [
        {
            id: 'no',
            disablePadding: false,
            label: t('TREATMENT_PLAN.FORM.LIST.TABLE.NO'),
            width: "8%"
        },
        {
            id: 'times',
            disablePadding: false,
            label: t('TREATMENT_COURSE.FORM.PAYMENT.TIMES'),
            width: "10%"
        }, {
            id: 'date',
            disablePadding: false,
            label: t('TXC.COURSE.DATE'),
            width: "auto"
        },
        {
            id: 'total_paid',
            disablePadding: false,
            label: t('TREATMENT_COURSE.FORM.PAYMENT.TOTAL_PAID'),
            align: 'right',
            width: '17%'
        },
        {
            id: 'balance',
            disablePadding: false,
            label: t('TREATMENT_COURSE.FORM.PAYMENT.BALANCE'),
            class: 'pr-3',
            align: 'right',
            width: '17%'
        },
    ]

    const renderData = (objData: any) => {
        const { index, total_paid, balance, date } = objData

        const columns = [
            { option: 'TEXT', align: 'center', label: index, class: 'text-ellipsis' },
            { option: 'TEXT', align: 'left', label: `${t('TREATMENT_COURSE.FORM.PAYMENT.TIMES')}${index}`, class: 'text-ellipsis' },
            { option: 'TEXT', align: 'left', label: date ? dateToView(date) : '-', class: 'text-ellipsis' },
            {
                option: 'COMPONENT',
                align: 'center',
                class: 'text-ellipsis',
                component: (
                    <div>
                        <InputTextField
                            key={`amount${index}`}
                            name={`amount${index}`}
                            inputTextAlign={'right'}
                            value={numberFormat(total_paid || 0)}
                            disabled
                            style={{ 'input': { textAlign: 'right' } }}
                        />
                        <div style={{ height: props.errorPayChanel && index === handleDataPayment().length && '23.91px' || '0px' }}></div>
                    </div>
                )
            },
            {
                option: 'COMPONENT',
                align: 'center',
                class: 'text-ellipsis',
                component: (
                    <InputTextField
                        inputTextAlign={'right'}
                        value={numberFormat(balance || 0)}
                        disabled
                        style={{ 'input': { textAlign: 'right' } }}
                        helperText={props.errorPayChanel && index === handleDataPayment().length &&
                            t('TREATMENT_COURSE.MESSAGE.ERROR.NOTFULL_SPLITPAY') || ''}
                    />
                )
            },
        ]

        const objRenderData = {
            key: index,
            id: index,
            obj: objData,
            columns: columns
        }

        return <TableRowCommon {...objRenderData} />
    }

    useEffect(() => {
        if (props.errorPayChanel) {
            setOpenCollapse(true)
            const elementTable = document.querySelector('.popup-create-txc')
            if (elementTable) {
                $('.popup-create-txc').delay(500).animate({ scrollTop: elementTable.scrollHeight }, 500)
            }
        }

    }, [props.errorPayChanel])

    return (
        <>
            <div className='mt-2' >
                <PayTableCollapseHead>
                    <div>
                        {paymentType()}
                    </div>
                    <div onClick={() => setOpenCollapse(!openCollapse)}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            className={'ml-3'}
                            onClick={() => setOpenCollapse(!openCollapse)}
                            style={{
                                transform: `${openCollapse ? 'rotate(180deg)' : 'rotate(0deg)'}`
                            }}>
                            <ExpandMoreIcon className={'expand-more'} sx={{ color: "#fff", border: '1px solid #fff', borderRadius: '50%' }} />
                        </IconButton>
                    </div>
                </PayTableCollapseHead>
                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                    <div style={{ backgroundColor: '#fff', padding: '1rem 0' }}>
                        {props.dataPayment.paymentType !== 'SPLITPAY' ?
                            <PayTableCollapseBody>
                                <div style={{ padding: '0 0 0 1.5rem' }}>
                                    {handleInputPaymentChanel()}
                                </div>
                                <div style={{ padding: '0 1rem 0 0' }}>
                                    {numberFormat(props.sumTXC)} {t('TXC.COURSE.BATH')}
                                </div>
                            </PayTableCollapseBody>
                            :
                            <PayTableCollapseBodySplitPay>
                                <div className='count-payment' >
                                    <div style={{ width: '110px' }}>{t('TXC.COURSE.COUNT')}{t('TXC.COURSE.TIME')}<span style={{ color: 'red' }}>*</span></div>
                                    <div className='sum-payment'>
                                        <InputTextField
                                            value={handleDataPayment()?.length || ""}
                                            style={{ 'input': { textAlign: 'right' } }}
                                            disabled
                                        />
                                    </div>
                                    <div>{t('TXC.COURSE.TIME')}</div>
                                </div>
                                <div className='count-payment pt-3' style={{ justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '110px' }}>{`${t('TREATMENT_COURSE.FORM.PAYMENT.CHOOSE')}`}:</div>
                                        <div className='sum-payment'>{handleTypeSplitPay()}</div>
                                    </div>
                                    <div style={{ padding: '0 1rem 0 0' }}>
                                        {numberFormat(props.sumTXC)} {t('TXC.COURSE.BATH')}
                                    </div>
                                </div>
                                <div style={{ padding: '0 1.5rem', margin: ' 1rem 0 0 0' }}>
                                    <TableCustom
                                        page={1}
                                        pageLimit={0}
                                        sortType={''}
                                        sortBy={''}
                                        onSort={() => { return }}
                                        setPageLimit={() => { return }}
                                        setPage={() => { return }}
                                        rowCount={0}
                                        headCells={headCells}
                                        rowsData={handleDataPayment().map((val: any, i: any) => {
                                            return renderData(val)
                                        })}
                                        tableFixedWidth
                                        // tableMinWidth={props.type !== 'modal' ? 1280 : undefined}
                                        hidePagination
                                    />
                                </div>
                            </PayTableCollapseBodySplitPay>
                        }

                    </div>
                </Collapse>
            </div>

        </>
    )
}
