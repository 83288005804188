import { useState } from 'react'
import { Box, CardContent, Paper, TextField, Accordion, AccordionDetails, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** ICONS */
import IconStar from 'assets/icon/IconStar'

const BoxSpecialInfo = styled(Box)(({ theme }) => ({}))

const TitleSpecialInfo = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 16,
  fontWeight: 500,
  color: colors.white,
  background: colors.themeMainColor,
  padding: '0 10px',
  height: 32,
  border: 'none',
  boxShadow: 'none'
}))

const BodySpecialInfo = styled(CardContent)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    paddingTop: 10,
    paddingBottom: 10,
    // backgroundColor: 'rgba(0, 0, 0, 0.12)'
  },
  '.MuiOutlinedInput-input': {
    fontSize: 16,
    color: colors.textPrimary,
    '&::placeholder, &.Mui-disabled::placeholder': {
      color: colors.black60,
      opacity: 1
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '0 8px 8px 8px',
    borderColor: `${colors.borderInput} !important`
  }
}))

const AccordionExpanded = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  '.box-special-info-tab': {
    width: 'fit-content',
    cursor: 'pointer'
  },
  '.expanded-icon': {
    width: 22,
    height: 22,
    borderRadius: 100,
    border: `1px solid ${colors.textLightGray}`
  }
}))

type SpecialInfoType = {
  key: string
  name: string
  placeholder?: string
  rows?: number
  onChange?: (event: any) => void
  variant?: 'filled' | 'outlined'
  size?: 'small' | 'medium'
  disabled?: boolean
  value?: any
  readOnly?: boolean
  expanded?: boolean
  styleExpanded?: any
  expandedIconClasses?: string
}

export default function SpecialInfo(props: SpecialInfoType) {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<boolean>(true)
  return (
    <BoxSpecialInfo className="box-special-info">
      <AccordionExpanded expanded={props.expanded ? expanded : true} sx={{ ...props.styleExpanded }}>
        <Box className={`d-flex justify-content-between box-special-info-tab overflow-hidden ${!props.expanded ? 'pe-none' : ''}`} onClick={() => setExpanded(!expanded)}>
          <TitleSpecialInfo variant="outlined" sx={{ flex: 1, borderRadius: expanded ? '8px 8px 0 0' : '8px', svg: { width: '16px', height: '16px' } }}>
            <IconStar fill='transparent' />
            <span className='ml-2'>{t('REGISTER.SPACIAL_INFO')}</span>
          </TitleSpecialInfo>
          {props.expanded &&
            <IconButton onClick={() => setExpanded(!expanded)} className={`expanded-icon p-0 ${props.expandedIconClasses || ''}`}>
              <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', fill: colors.themeMainColor }} />
            </IconButton>}
        </Box>

        <AccordionDetails className="p-0">
          <BodySpecialInfo className={'p-0'}>
            <TextField
              {...props}
              multiline
              fullWidth
              key={props.key}
              name={props.name}
              rows={props.rows || 3}
              variant={props.variant || 'outlined'}
              size={props.size || 'medium'}
              placeholder={props.placeholder}
              type={'text'}
              onChange={props.onChange}
              disabled={props.disabled}
              value={props.value}
              className={`w-100 h-100 `}
              InputProps={{
                readOnly: props.readOnly,
              }}
              style={{
                caretColor: props.readOnly ? 'transparent' : colors.black,
                backgroundColor: props?.disabled ? 'rgba(0, 0, 0, 0.12)' : '#fff'
              }}
            />
          </BodySpecialInfo>
        </AccordionDetails>
      </AccordionExpanded>
    </BoxSpecialInfo>
  )
}
