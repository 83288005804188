import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  bodyCalendar: {
    '& .card-body': {
      padding: '1px'
    }
  },
  dentistContainer: {
    display: 'flex',
    border: `1px solid ${colors.lightGray}`,
    height: '72px',
    cursor: 'pointer',
    paddingLeft: '16px',
    borderRadius: '4px',
    boxShadow: `0 3px 3px 0 ${colors.lightGray}`,
    '& --bs-gutter-x': '1.0rem'
  },
  dentistImg: {
    alignSelf: 'center',
    width: '20%',
    marginRight: '8px'
  },
  img: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    "& img": {
      width: '35px',
      height: '35px',
      borderRadius: '50%',

    }
  },
  dentistText: {
    alignSelf: 'center',
    display: 'block',
    width: '60%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  dentistName: {
    fontSize: '16px',
    color: colors.themeMainColor,
    fontWeight: 700
  },
  dentistRole: {
    display: 'block',
    fontSize: '14px'
  },
  dentistCheckBox: {
    width: '20%',
    paddingLeft: '13px'
  },
  checkBox: {
    width: '20%',
    color: colors.lightGray,
    '& .MuiCheckboxColorPrimary': `${colors.lightGray} !important`
  },
  title: {
    fontSize: '16px',
    fontWeight: 700
  },
  checkbox: {
    '& .ant-checkbox-inner': {
      width: '20px',
      height: '20px'
    }
  },
  formControll: {
    '& MuiFormControl-root': { '& .MuiInputBase-root-MuiOutlinedInput-root': { fontSize: '16px' } },
    '& .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label': { fontSize: '16px' }
  }
}))

export default useStyles
