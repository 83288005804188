import { useState, useEffect } from 'react'
import { Grid, Typography, Box, FormControl, RadioGroup, FormControlLabel, MenuItem } from '@mui/material'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import ClinicApi from 'api/master/clinic.api'
import TreatmentApi from 'api/dentists/treatment.api'
import BranchApi from 'api/setting/branch-service-points/branch.api'
import PrefixApi from 'api/setting/default-users/prefix.api'

/** UTILS || SLICE */
import { closeModal, resetModal } from 'app/slice/modal.slice'
import { getClinicInfo, renderAge } from 'utils/app.utils'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import Loading from 'component/Loading'
import InputTextarea from 'component/Input/InputTextarea'
import InputRadio from 'component/Input/InputRadio'
import InputTextField from 'component/Input/InputTextField'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { CustomTagMultiple } from 'features/counter/register/usedStyles'
import ComponentApi from 'api/components-api'




type ModalCustomProps = {
  isShow: boolean | false
  setIsShow: (show: boolean) => void
  onClose?: () => void
  patientId: number
  branchId: number
  patientCaseId?: number
  createdAt?: string
  treatments?: any
  diagnosis?: any
  patient?: any
  user?: any
  type?: any
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export default function MedicalCertificateFiveDisease(props: ModalCustomProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [errorDoctorSelect, setErrorDoctorSelect] = useState('')
  const [errorCongenitalDisease, setErrorCongenitalDisease] = useState('')
  const [errorSurgery, setErrorSurgery] = useState('')
  const [errorHospitalAdmission, setErrorHospitalAdmission] = useState('')
  const [errorOtherImportentHistory, setErrorOtherImportentHistory] = useState('')
  const [errorGeneralMedicalExaminationReveals, setErrorGeneralMedicalExaminationReveals] = useState('')
  const [errorEpilepsy, setErrorEpilepsy] = useState('')

  const [loading, setLoading] = useState(false)
  const patient = useSelector((state: any) => state.register.patient)
  /** FILTER */
  const [doctor, setDoctor] = useState<number>(0)
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'))
  const [disableDoctor, setDisableDoctor] = useState(false)

  const [branch, setBranch] = useState<any>()
  const [congenitalDisease, setCongenitalDisease] = useState<any>()
  const [congenitalDiseaseDetail, setCongenitalDiseaseDetail] = useState<any>()
  const [surgery, setSurgery] = useState<any>()
  const [surgeryDetail, setSurgeryDetail] = useState<any>()
  const [hospitalAdmission, setHospitalAdmission] = useState<any>()
  const [hospitalAdmissionDetail, setHospitalAdmissionDetail] = useState<any>()
  const [otherImportentHistory, setOtherImportentHistory] = useState<any>()
  const [otherImportentHistoryDetail, setOtherImportentHistoryDetail] = useState<any>()
  const [epilepsy, setEpilepsy] = useState<any>()
  const [epilepsyDetail, setEpilepsyDetail] = useState<any>('')
  const [generalMedicalExaminationReveals, setGeneralMedicalExaminationReveals] = useState<any>()
  const [generalMedicalExaminationRevealsDetail, setGeneralMedicalExaminationRevealsDetail] = useState<any>()
  const [signApprove, setSignApprove] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
  const [prefixPatient, setPrefixPatient] = useState<any>('')
  const [doctorDetail, setDoctorDetail] = useState<any>('')
  const [doctors, setDoctors] = useState<any[]>([])
  const [lang, setLang] = useState<any>('TH')
  const [other, setOther] = useState<any>('')
  const [summaryComment, setSummaryComment] = useState<any>('')
  const [disease, setDisease] = useState([])
  const [documentNumber, setDocumentNumber] = useState('')

  const [isEmpty, setIsEmpty] = useState<boolean>(false)

  const branchInfo = getClinicInfo()

  const loadLang = () => {
    if (branchInfo?.driverLicenseDoctorCertificate !== '1') {
      if (branchInfo?.driverLicenseDoctorCertificateEn !== '1') {
        return setLang('EMPTY')
      }
      return setLang('EN')
    }
    return setLang('TH')
  }

  useEffect(() => {
    loadLang()
  }, [])

  useEffect(() => {
    if (lang === 'EMPTY') {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
  }, [lang])

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors()
    if (resp.status === 200) setDoctors(resp.data)
  }
  const loadBranch = async () => {
    const res = await BranchApi.findById(props.branchId)
    if (res.status === 200) setBranch(res.data)
  }

  const loadPrefixes = async () => {
    const res = await PrefixApi.findById(props.patient?.prefixId)
    setPrefixPatient(res.data)
  }
  const loadDisease = async () => {
    const res = await ComponentApi.findAllDisease()
    if (res.status === 200) {
      setDisease(res.data)
    }
  }



  useEffect(() => {
    loadDoctor()
    loadBranch()
    loadPrefixes()
    loadDisease()
    onGetDocumentNumber()
  }, [])

  useEffect(() => {
    loadDoctor()
    loadBranch()
    loadPrefixes()
    loadDisease()
    onGetDocumentNumber()
  }, [])
  useEffect(() => {
    const obj = doctors.filter((obj) => obj.userId === doctor)
    setDoctorDetail(obj)
  }, [doctor])

  const onChangeCongenitalDisease = (e: any, val: any) => {
    const arr: any = _.filter(val, (newV: any, indexNewVal: number) => {
      return val.indexOf(newV) === indexNewVal
    })
    setCongenitalDiseaseDetail(arr)
    setErrorCongenitalDisease('')
  }

  const onChangeSurgery = (e: any) => {
    const val = e.target.value
    setSurgeryDetail(val)
    setErrorSurgery('')
  }
  const onChangeHospitalAdmission = (e: any) => {
    const val = e.target.value
    setHospitalAdmissionDetail(val)
    setErrorHospitalAdmission('')
  }

  const onChangeEpilepsy = (e: any) => {
    const val = e.target.value
    setEpilepsyDetail(val)
    setErrorEpilepsy('')
  }

  const onChangeOtherImportentHistory = (e: any) => {
    const val = e.target.value
    setOtherImportentHistoryDetail(val)
    setErrorOtherImportentHistory('')
  }
  const onChangeGeneralMedicalExaminationReveals = (e: any) => {
    const val = e.target.value
    setGeneralMedicalExaminationRevealsDetail(val)
    setErrorGeneralMedicalExaminationReveals('')
  }
  const onChangeOther = (e: any) => {
    const val = e.target.value
    setOther(val)
  }

  const onChangeSummaryComment = (e: any) => {
    const val = e.target.value
    setSummaryComment(val)
  }
  const onCheckPhysical = (type: any) => {

    if (type === "NORMAL") {
      return true
    }
    else if (type === "ABNORMAL") {
      return false
    } else {
      return null
    }
  }
  const onCheck = (type: any) => {
    if (type === "HAVE") {
      return true
    }
    else if (type === "DONT_HAVE") {
      return false
    } else {
      return null
    }
  }

  const onSendValue = (type: string, value: string) => {
    if (type === "HAVE" || type === "ABNORMAL") {
      return value
    } else {
      return ''
    }
  }

  const handleName = () => {
    if (lang === 'EN') {
      if (props.patient?.firstnameEn && props.patient?.lastnameEn) return `${prefixPatient.prefixNameEn ?? ""} ${props.patient?.firstnameEn} ${props.patient?.lastnameEn}`
      else return `${props.patient?.firstname} ${props.patient?.lastname}`
    } else if (lang === 'TH' || lang === 'EMPTY') {
      return `${prefixPatient.prefixName}${props.patient?.firstname} ${props.patient?.lastname}`
    }
  }
  const handleSetAge = (date: Date | null) => {
    if (!date) return ''
    return `${renderAge(date, 'year')} ${t('TIME_DURATION_TYPE.YEAR')} ${renderAge(date, 'month')} ${t('TIME_DURATION_TYPE.MONTH')} ${renderAge(date, 'day')} ${t('TIME_DURATION_TYPE.DAY')}`
  }

  const onGetDocumentNumber = async () => {
    const type = 'CERTIFICATE_DRIVING_LICENSE'
    const res = await TreatmentApi.getDocumentLastNumber({
      documentKey: type,
      documentDate: date
    })
    if (res.status === 200) {
      const inputString = res.data
      const parts = inputString.split("-");
      const lastPart = parts[2];
      const incrementedLastPart = (parseInt(lastPart) + 1).toString().padStart(lastPart.length, '0');
      parts[2] = incrementedLastPart;
      setDocumentNumber(parts.join('-'))

    }

  }

  const onSave = async () => {

    setLoading(true)
    const print = await TreatmentApi.printMedicalDrivingLicense(props.patientId, {
      type: lang,
      documentNumber: documentNumber,
      documentDate: date,
      address: lang === 'EN' ? props.patient.addressEn : props.patient.address,
      doctorId: doctor,
      branchId: props.branchId,
      patientId: props.patient.patientId,
      idCard: props.patient.idCard,
      patientFullname: handleName(),
      age: handleSetAge(props.patient.dateOfBirth),
      cnNumber: props.patient.cnNumber,
      congenitalDisease: {
        check: onCheck(congenitalDisease),
        value: lang === 'EMPTY' ? '' : onSendValue('HAVE', congenitalDiseaseDetail)
      },
      clinicAddress: branch.branchFullAddress,
      accident: {
        check: onCheck(surgery),
        value: lang === 'EMPTY' ? '' : onSendValue('HAVE', surgeryDetail)
      },
      hospitalHistory: {
        check: onCheck(hospitalAdmission),
        value: lang === 'EMPTY' ? '' : onSendValue('HAVE', hospitalAdmissionDetail)
      },
      otherHistory: {
        check: onCheck(otherImportentHistory),
        value: lang === 'EMPTY' ? '' : onSendValue('HAVE', otherImportentHistoryDetail)
      },
      epilepsy: {
        check: onCheck(epilepsy),
        value: lang === 'EMPTY' ? '' : onSendValue('HAVE', epilepsyDetail)
      },
      physical: {
        check: onCheckPhysical(generalMedicalExaminationReveals),
        value: lang === 'EMPTY' ? '' : onSendValue('ABNORMAL', generalMedicalExaminationRevealsDetail)
      },
      other: other,
      doctorComment: summaryComment,
      signDoctor: signApprove === 'CHECK' ? true : false

    }).finally(() => setLoading(false))

    if (print.status === 201) {
      window.open(`${fileUrl}/${print.data}`, '_blank')
      clearData()
      props.setIsShow(false)
      dispatch(closeModal())
    }
  }

  const clearData = () => {
    setDoctor(0)
    setDate(moment().format('YYYY-MM-DD'))
    setDisableDoctor(false)
    setCongenitalDisease('')
    setSurgery('')
    setHospitalAdmission('')
    setOtherImportentHistory('')
    setSignApprove('UNCHECK')
  }


  return (
    <ModalCustom
      title={t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_DRIVING_LICENSE')}
      alignFooter={'end'}
      size={'xl'}
      footerInline
      onClose={props.onClose}
      onSubmit={onSave}
      onReset={() => {
        dispatch(resetModal())
        props.setIsShow(false)
      }}
      textBtnCancel={t('BUTTON.CANCEL')}
      textBtnConfirm={t('BUTTON.PRINT')}
      modalStyle={'modal-referral-examination'}
      component={
        (
          <>
            <Grid container spacing={2} className="pt-2">
              <Grid item className='w-100'>
                <Box className="d-flex align-items-center">
                  <Typography className='mr-3' sx={{ fontWeight: 500 }}>{t('TREATMENTS.FORM.FORM')}</Typography>
                  <FormControl>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel value="TH" control={<InputRadio checked={lang === 'TH'} className="radio" onClick={(e: any) => setLang('TH')} />} label={t('TREATMENTS.FORM.TH')} disabled={branchInfo?.driverLicenseDoctorCertificate !== "1"} />
                      <FormControlLabel value="EN" control={<InputRadio checked={lang === 'EN'} onClick={(e: any) => setLang('EN')} />} label={t('TREATMENTS.FORM.EN')} disabled={branchInfo?.driverLicenseDoctorCertificateEn !== "1"} />
                      <FormControlLabel value="EMPTY" control={<InputRadio checked={lang === 'EMPTY'} onClick={(e: any) => { setLang('EMPTY') }} />} label={t('TREATMENTS.FORM.EMPTY')} disabled={branchInfo?.driverLicenseDoctorCertificateBlank !== "1"} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={3} className="w-100">
                <InputTextField disabled value={!isEmpty && `${documentNumber}` || ''} inputTextAlign={'left'} label={t('TREATMENTS.FORM.LABEL.NUMBER')} />
              </Grid>
              <Grid item xs={3} className="w-100">
                <InputSecondNewDatePicker
                  required={!isEmpty}
                  key={'date'}
                  label={t('SSO.DATE')}
                  value={!isEmpty && date}
                  onchange={(e: any) => setDate(moment(e).format('YYYY-MM-DD'))}
                  disabled={isEmpty}
                />
              </Grid>
              <Grid item xs={3} className="w-100">
                <BasicSelect
                  allowClear
                  onClear={() => setDoctor(0)}
                  name="doctorId"
                  labelId="lb-dentist"
                  selectId="sl-dentist"
                  label={t('TREATMENTS.FORM.LABEL.DOCTOR_NAME')}
                  value={!isEmpty && doctor || null}
                  options={
                    _.map(doctors, (doctorData: any, i: number) => (
                      <MenuItem key={doctorData.userId} value={doctorData.userId}>
                        {doctorData.fullname}
                      </MenuItem>
                    ))
                  }
                  onchange={(event) => {
                    setDoctor(event.target.value)
                    setErrorDoctorSelect('')
                  }}
                  classesOption="style-select-doctors-mb"
                  disabled={disableDoctor || isEmpty}
                  helperText={errorDoctorSelect || ''}
                />
              </Grid>
              <Grid item xs={3} className="w-100">
                <InputTextField disabled value={!isEmpty && `${doctorDetail[0]?.certificate || ''}` || ''} inputTextAlign={'left'} label={t('TREATMENTS.FORM.LABEL.DOCTOR_PROFESSIONAL_LICENSE')} />
              </Grid>
              <Grid item xs={3} className="w-100">
                <InputTextField disabled value={!isEmpty && handleName() || ''} inputTextAlign={'left'} label={t('TREATMENTS.FORM.LABEL.PANTIST_NAME')} />
              </Grid>
              <Grid item xs={3} className="w-100">
                <InputTextField disabled value={!isEmpty && handleSetAge(props.patient.dateOfBirth) || ''} inputTextAlign={'left'} label={t('TREATMENTS.FORM.LABEL.AGE')} />

              </Grid>
              <Grid item xs={3} className="w-100">
                <InputTextField disabled value={!isEmpty && `${props.patient.cnNumber}` || ''} inputTextAlign={'left'} label={t('TREATMENTS.FORM.LABEL.CN')} />
              </Grid>
              <Grid item xs={3} className='w-100'>
                <Box className="d-flex align-items-center">
                </Box>
              </Grid>

              <Grid item xs={3} className='w-100 pt-4'>
                <Box className="d-flex align-items-center">
                  <Typography className='mr-3' sx={{ fontWeight: 500 }}>{t('TREATMENTS.FORM.CONGENITAL_DISEASE')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className='w-100'>
                <Box className="d-flex align-items-center">
                  <FormControl disabled={lang === 'EMPTY'}>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel value="HAVE" control={<InputRadio checked={!isEmpty ? congenitalDisease === 'HAVE' : null} className="radio" onClick={(e: any) => setCongenitalDisease('HAVE')} />} label={t('TREATMENTS.FORM.HAVE')} />
                      <FormControlLabel value="DONT_HAVE" control={<InputRadio checked={!isEmpty ? congenitalDisease === 'DONT_HAVE' : null} onClick={(e: any) => setCongenitalDisease('DONT_HAVE')} />} label={t('TREATMENTS.FORM.DONT_HAVE')} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <AutocompleteSelect
                  multiple
                  labelId="disease"
                  inputLabel={t('REGISTER.FORM.DISEASE')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={disease}
                  getOptionLabel={(option: any) => option}
                  renderOption={(props, option: any) => (
                    <Box key={option} component="li" {...props}>
                      {option}
                    </Box>
                  )}
                  renderTags={(tagValue, getTagProps) => _.map(!isEmpty ? tagValue : [], (option: any, index: number) => <CustomTagMultiple type="disease" size="small" label={option} {...getTagProps({ index })} />)}
                  onchange={onChangeCongenitalDisease}
                  onInputChange={(e, value: any) => {
                    const text = value.split(',')
                    if (text.length > 1) {
                      const val = (_.isEmpty(patient.disease) && []) || (typeof patient.disease === 'string' && patient.disease.split(',')) || patient.disease
                      const item = [...val, text[0]]
                      onChangeCongenitalDisease(e, item)
                    }
                  }}
                  errMessage={errorCongenitalDisease}
                  value={!isEmpty ? congenitalDiseaseDetail : []}
                  key={`disease-id`}
                  disabled={congenitalDisease === 'HAVE' && !isEmpty ? false : true}
                  popupIcon={<span></span>}
                  disableClearable
                  freeSolo
                  clearOnBlur
                  clearOnEscape
                />
              </Grid>

              <Grid item xs={3} className='w-100 pt-4'>
                <Box className="d-flex align-items-center">
                  <Typography className='mr-3' sx={{ fontWeight: 500 }}>{t('TREATMENTS.FORM.SURGERY')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className='w-100'>
                <Box className="d-flex align-items-center">
                  <FormControl disabled={lang === 'EMPTY'}>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel value="HAVE" control={<InputRadio checked={!isEmpty ? surgery === 'HAVE' : null} className="radio" onClick={(e: any) => setSurgery('HAVE')} />} label={t('TREATMENTS.FORM.HAVE')} />
                      <FormControlLabel value="DONT_HAVE" control={<InputRadio checked={!isEmpty ? surgery === 'DONT_HAVE' : null} onClick={(e: any) => setSurgery('DONT_HAVE')} />} label={t('TREATMENTS.FORM.DONT_HAVE')} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <InputTextField helperText={errorSurgery} value={lang === 'EMPTY' ? '' : surgeryDetail} disabled={surgery === 'HAVE' && !isEmpty ? false : true} inputTextAlign={'left'} onchange={onChangeSurgery} label={t('TREATMENTS.FORM.SURGERY')} />
              </Grid>

              <Grid item xs={3} className='w-100 pt-4'>
                <Box className="d-flex align-items-center">
                  <Typography className='mr-3' sx={{ fontWeight: 500 }}>{t('TREATMENTS.FORM.HOSPITAL_ADMISSION')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className='w-100'>
                <Box className="d-flex align-items-center">
                  <FormControl disabled={lang === 'EMPTY'}>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel value="HAVE" control={<InputRadio checked={!isEmpty ? hospitalAdmission === 'HAVE' : null} className="radio" onClick={(e: any) => setHospitalAdmission('HAVE')} />} label={t('TREATMENTS.FORM.HAVE')} />
                      <FormControlLabel value="DONT_HAVE" control={<InputRadio checked={!isEmpty ? hospitalAdmission === 'DONT_HAVE' : null} onClick={(e: any) => setHospitalAdmission('DONT_HAVE')} />} label={t('TREATMENTS.FORM.DONT_HAVE')} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <InputTextField helperText={errorHospitalAdmission} value={lang === 'EMPTY' ? '' : hospitalAdmissionDetail} disabled={hospitalAdmission === 'HAVE' && !isEmpty ? false : true} inputTextAlign={'left'} onchange={onChangeHospitalAdmission} label={t('TREATMENTS.FORM.HOSPITAL_ADMISSION')} />
              </Grid>

              <Grid item xs={3} className='w-100 pt-4'>
                <Box className="d-flex align-items-center">
                  <Typography className='mr-3' sx={{ fontWeight: 500 }}>{t('TREATMENTS.FORM.EPILEPSY')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className='w-100'>
                <Box className="d-flex align-items-center">
                  <FormControl disabled={lang === 'EMPTY'}>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel value="HAVE" control={<InputRadio checked={!isEmpty ? epilepsy === 'HAVE' : null} className="radio" onClick={(e: any) => setEpilepsy('HAVE')} />} label={t('TREATMENTS.FORM.HAVE')} />
                      <FormControlLabel value="DONT_HAVE" control={<InputRadio checked={!isEmpty ? epilepsy === 'DONT_HAVE' : null} onClick={(e: any) => setEpilepsy('DONT_HAVE')} />} label={t('TREATMENTS.FORM.DONT_HAVE')} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <InputTextField helperText={errorEpilepsy} value={lang === 'EMPTY' ? '' : epilepsyDetail} disabled={epilepsy === 'HAVE' && !isEmpty ? false : true} inputTextAlign={'left'} onchange={onChangeEpilepsy} label={t('TREATMENTS.FORM.EPILEPSY')} />
              </Grid>

              <Grid item xs={3} className='w-100 pt-4'>
                <Box className="d-flex align-items-center">
                  <Typography className='mr-3' sx={{ fontWeight: 500 }}>{t('TREATMENTS.FORM.OTHER_IMPORTANT_HISTORY')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className='w-100'>
                <Box className="d-flex align-items-center">
                  <FormControl disabled={lang === 'EMPTY'}>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel value="HAVE" control={<InputRadio checked={!isEmpty ? otherImportentHistory === 'HAVE' : null} className="radio" onClick={(e: any) => setOtherImportentHistory('HAVE')} />} label={t('TREATMENTS.FORM.HAVE')} />
                      <FormControlLabel value="DONT_HAVE" control={<InputRadio checked={!isEmpty ? otherImportentHistory === 'DONT_HAVE' : null} onClick={(e: any) => setOtherImportentHistory('DONT_HAVE')} />} label={t('TREATMENTS.FORM.DONT_HAVE')} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <InputTextField helperText={errorOtherImportentHistory} value={lang === 'EMPTY' ? '' : otherImportentHistoryDetail} disabled={otherImportentHistory === 'HAVE' && !isEmpty ? false : true} inputTextAlign={'left'} onchange={onChangeOtherImportentHistory} label={t('TREATMENTS.FORM.OTHER_IMPORTANT_HISTORY')} />
              </Grid>

              <Grid item xs={3} className='w-100 pt-4'>
                <Box className="d-flex align-items-center">
                  <Typography className='mr-3' sx={{ fontWeight: 500 }}>{t('TREATMENTS.FORM.GENERAL_MEDICAL_EXAMINATION_REVEALS')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className='w-100'>
                <Box className="d-flex align-items-center">
                  <FormControl disabled={lang === 'EMPTY'}>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel value="NORMAL" control={<InputRadio checked={!isEmpty ? generalMedicalExaminationReveals === 'NORMAL' : null} className="radio" onClick={(e: any) => setGeneralMedicalExaminationReveals('NORMAL')} />} label={t('TREATMENTS.FORM.NORMAL')} />
                      <FormControlLabel value="ABNORMAL" control={<InputRadio checked={!isEmpty ? generalMedicalExaminationReveals === 'ABNORMAL' : null} onClick={(e: any) => setGeneralMedicalExaminationReveals('ABNORMAL')} />} label={t('TREATMENTS.FORM.ABNORMAL')} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <InputTextField helperText={errorGeneralMedicalExaminationReveals} value={lang === 'EMPTY' ? '' : generalMedicalExaminationRevealsDetail} disabled={generalMedicalExaminationReveals === 'ABNORMAL' && !isEmpty ? false : true} inputTextAlign={'left'} onchange={onChangeGeneralMedicalExaminationReveals} label={t('TREATMENTS.FORM.GENERAL_MEDICAL_EXAMINATION_REVEALS')} />
              </Grid>

              <Grid item className='w-100'>
                <InputTextField disabled={lang === 'EMPTY'} value={lang === 'EMPTY' ? '' : other} inputTextAlign={'left'} onchange={onChangeOther} label={t('TREATMENTS.FORM.OTHER')} />
              </Grid>
              <Grid item className='w-100'>
                <InputTextarea disabled={lang === 'EMPTY'} value={lang === 'EMPTY' ? '' : summaryComment} onchange={onChangeSummaryComment} label={t('TREATMENTS.FORM.SUMMARY_COMMENT')} />
              </Grid>

              <Grid item className='w-100'>
                <InputCheckbox
                  label={t('TREATMENTS.FORM.SIGN_APPROVE')}
                  className="hover-none-bg"
                  checked={signApprove === 'CHECK'}
                  onChange={(e) => {
                    setSignApprove(e.target.checked ? 'CHECK' : 'UNCHECK')
                  }}
                  value={String(signApprove)}
                />
              </Grid>
            </Grid>

            {loading && <Loading show={true} type="fullLoading" />}
          </>
        )
      }
    />
  )
}

