import { Box } from '@mui/material'
import { fileUrl } from 'api/api'
import ManageWebApi from 'api/application/manageWeb'
import { BannerProps } from 'api/application/manageWeb-interface'
import BtnDelFilled from 'assets/button/BtnDelFilled'
import { swalActive, swalDelete } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import Loading from 'component/Loading'
import UploadImage from 'component/Upload/UploadImage'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type ManageWebBannerProps = {
    setBannerImg: (img: string) => void
    bannerImg: string
}

const Banner = (props: ManageWebBannerProps) => {
    const { t } = useTranslation()
    const t_BANNER = "MANAGE_WEB.BANNER"
    const maxHeight = 700
    const maxWidth = 1440

    const [load, setLoad] = useState<boolean>(true)

    const [banner, setBanner] = useState<any>({})
    const [bannerId, setBannerId] = useState<any>({})

    const loadData = async () => {
        setLoad(true)
        await ManageWebApi.getBanner().then((res) => {
            setBanner(res.data)
            setBannerId(res.data)
        }).catch(() => {
            return
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])


    const handleDel = () => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 400px">${t(t_BANNER + '.NOTI_DEL')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 306px">${t(t_BANNER + '.NOTI_DEL')}</p>`,
            async (res: any) => {
                if (res) {
                    const resDel = await ManageWebApi.delBanner(banner.bannerId)
                    if (resDel === "pass") {
                        setBanner({})
                        setBannerId({})
                        loadData()
                        notiSuccess(t(`${t_BANNER}.DEL_SUC`))
                    } else {
                        notiError(t(`${t_BANNER}.SAVE_ERROR`))
                        return
                    }

                }
            }, 'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const uploadNewsImageSign = (files: any) => {
        if (files[0]) {
            //   const checkFileName = ["jpg", "png"];
            const file = files[0];
            const fileName = file.name.split(".");
            //   && checkFileName.includes(fileName[fileName.length - 1])
            const detailImage = {
                base64: "",
                type: bannerId?.fileType || "",
                fileExtension: fileName[fileName.length - 1],
                fileSize: file.size.toString(),
                fileId: bannerId?.bannerId || 0,
            };

            const reader: any = new FileReader();
            reader.addEventListener("load", () => {
                const image = new Image();
                image.src = reader?.result as string;
                image.addEventListener("load", () => {
                    const width = image.width;
                    const height = image.height;

                    if (maxHeight < height || maxWidth < width) {
                        const errorWidth = maxWidth < width && ` ${t('MANAGE_WEB.BANNER.ERROR_WIDTH')} ${maxWidth} ` || ''
                        const errorHeight = maxHeight < height && ` ${t('MANAGE_WEB.BANNER.ERROR_HEIGHT')} ${maxHeight} ` || ''
                        const error = `Banner ${errorWidth} ${maxHeight < height && maxWidth < width && ` ${t('MANAGE_WEB.CONNECTOR.AND')} ` || ''} ${errorHeight}`
                        notiError(error)
                    } else {
                        const base64 = reader.result.split(",");
                        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result;
                        const newData = { ...banner, ...detailImage, base64Show: reader.result, }
                        setBanner(newData)
                    }
                });
            });
            reader.readAsDataURL(file);
        }
    };

    const onBannerRefresh = () => {
        loadData()
    }

    const onSaveBanner = async () => {
        const newPayload: BannerProps = {
            bannerId: banner?.fileId !== 0 && bannerId?.bannerId || undefined,
            base64: banner?.base64 || '',
            type: banner?.type || '',
            fileExtension: banner?.fileExtension || '',
            fileSize: banner?.fileSize || ''
        }
        if (newPayload.base64) {
            await ManageWebApi.saveBanner(newPayload).catch(() => {
                return
            }).then(() => {
                notiSuccess(t(`${t_BANNER}.SUCCESS_IMG`))
                props.setBannerImg(banner?.base64Show || '')
                loadData()
            })
        } else {
            return notiError(t(`${t_BANNER}.NOTI_IMAGE`))
        }
    }

    const onRenderImg = () => {
        if (props?.bannerImg && props?.bannerImg !== '') {
            return props?.bannerImg
        } else {
            return `${fileUrl}/${banner?.filePath}/${banner?.fileName}`
        }
    }

    return (
        <>
            <Loading show={load} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {!load &&
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                            {t(`${t_BANNER}.TITLE`)}
                        </Box>
                        {banner?.filePath &&
                            <Box>
                                <ButtonCustom
                                    startIcon={<BtnDelFilled color='#fff' />}
                                    mode={'del'}
                                    textButton={t(`${t_BANNER}.BTN_DEL`)}
                                    onClick={() => { handleDel() }}
                                />
                            </Box>
                            ||
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ButtonCustom
                                    style={{ marginRight: '4px' }}
                                    variant={'outlined'}
                                    textButton={t(`${t_BANNER}.BTN_CANCEL`)}
                                    onClick={() => { onBannerRefresh() }}
                                />
                                <ButtonCustom
                                    textButton={t(`${t_BANNER}.BTN_SAVE`)}
                                    onClick={() => { onSaveBanner() }}
                                />
                            </Box>
                        }
                    </Box>
                }

                {!load &&
                    <Box sx={{ marginTop: '32px', height: banner?.filePath || banner?.base64Show ? '100%' : '90%', overflow: 'auto' }}>
                        {banner?.filePath &&
                            <img
                                src={onRenderImg()}
                                style={{ width: "100%" }}
                            />
                            ||
                            <Box
                                sx={{
                                    width: banner?.filePath || banner?.base64Show ? 'auto !important' : "100%",
                                    height: banner?.filePath || banner?.base64Show ? 'auto !important' : "calc(100% - 32px)",
                                    ".MuiFormControl-root": {
                                        height: '100%'
                                    },
                                    ".MuiFormControl-root div": {
                                        height: banner?.filePath || banner?.base64Show ? 'auto !important' : '150px'
                                    }
                                }}
                            >
                                <UploadImage
                                    setValue={uploadNewsImageSign}
                                    pathFile={banner?.base64Show || banner?.filePath || ''}
                                />
                                <Box sx={{ color: "#BBBBBB", marginTop: "8px" }}>
                                    {'('}{t(`${t_BANNER}.FILESIZE`)}{` ${maxWidth} x ${maxHeight} px)`}
                                </Box>
                            </Box>
                        }
                    </Box>
                }
            </Box>
        </>
    )
}

export default Banner