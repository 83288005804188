import { colors } from "constants/theme"
import IconProps from 'assets/pick/iconProps'

export default function Branch(props: IconProps) {
  return (
    <svg width="62" height="64" viewBox="0 0 62 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_3535_168461" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="62" height="64">
        <rect width="62" height="64" fill={props.color || colors.disabledGray} />
      </mask>
      <g mask="url(#mask0_3535_168461)">
        <path d="M10 39.486L13.0402 36.5098M22.5455 27.205L13.0402 36.5098M22.5455 27.205V9.8999H52V53.9999H32.0507M22.5455 27.205L32.0507 36.5098M35.0909 39.486L32.0507 36.5098M13.0402 36.5098V53.9999H32.0507M32.0507 36.5098V53.9999" stroke={props.color || colors.disabledGray} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="40.4492" y="37.1998" width="5.25" height="5.25" fill={props.color || colors.disabledGray} />
        <rect x="40.4492" y="27.7499" width="5.25" height="5.25" fill={props.color || colors.disabledGray} />
        <rect x="40.4492" y="18.2999" width="5.25" height="5.25" fill={props.color || colors.disabledGray} />
        <rect x="29.9492" y="18.2999" width="5.25" height="5.25" fill={props.color || colors.disabledGray} />
        <rect x="19.9746" y="40.3499" width="5.25" height="5.25" fill={props.color || colors.disabledGray} />
      </g>
    </svg>
  )
}
