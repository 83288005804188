import useWindowSize from 'utils/useWindowSize'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { colors } from 'constants/theme'

const Div = styled(`div`)(({ theme }) => ({
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'max-content',
  maxHeight: 15,
  padding: '0 3px',
  backgroundColor: colors.textPrimary,
  color: colors.white,
  margin: 'auto',
  left: 0,
  right: 0,
  borderRadius: 3,
  zIndex: 2,
  [theme.breakpoints.up(1200)]: {
    maxHeight: 18,
  },
  [theme.breakpoints.up(1600)]: {
    maxHeight: 15,
  }
}))

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  textAlign: 'center',
  lineHeight: '1.2',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up(1200)]: {
    fontSize: 13,
  },
  [theme.breakpoints.up(1600)]: {
    fontSize: 12,
  }
}))

type IconProps = {
  style?: any
  position?: 'L' | 'U'
}

export default function IconXray(props: IconProps) {
  const [width] = useWindowSize()

  return (
    <Div
      {...props}
      style={{
        ...(props.position === 'L' ?
          {
            top: (width <= 960 && 28) || (width <= 1280 && 38) || (width <= 1600 && 66) || 24,
            bottom: 'auto'
          }
          : {
            top: 'auto',
            bottom: (width <= 960 && 30) || (width <= 1280 && 42) || (width <= 1600 && 66) || 34
          })
      }}
    >
      <Text>x-ray</Text>
    </Div>
  )
}
