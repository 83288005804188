import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface RecallInterface {
  operativeTypeId: number
  operativeId: number
  recallAmount: string
  recallUnit: string
  message: string
  status: string
}

export interface FindAllListInterface extends FindAllInterface {
  branchId?: number
  patientId?: number
  operativeId?: number
  operativeTypeId?: number
  dateStart?: string
  dateEnd?: string
}

export interface CancelRecallInterface {
  branchId: number
  operativeId: number
  patientId: number
  recallId: number
}

export default class RecallApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('recalls', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('recalls', playload)
  }

  static findById(id: number): Promise<RecallInterface> {
    return this.api.get(`recalls/${id}`)
  }

  static update(id: number, body: RecallInterface) {
    return this.api.patch(`recalls/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`recalls/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`recalls/${id}`)
  }

  static findAllList(params: FindAllListInterface) {
    return this.api.get(`recalls/list`, { params: { ...params } })
  }

  static historyRecall(id: number) {
    return this.api.get(`recalls/${id}/history`)
  }

  static cancelRecall(payload: CancelRecallInterface) {
    return this.api.patch(`recalls/cancel`, payload)
  }
}
