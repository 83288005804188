import { Box, MenuItem } from '@mui/material'
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import FilterSelect from 'component/Select/FilterSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import FormManageTag from './form-manage-tag'
import { useDispatch } from 'react-redux'
import { resetModal, showModal } from 'app/slice/modal.slice'
import { BoxTableTag } from './style'
import TagsApi, { tagProps } from 'api/setting/personnel-managements/tags.api'

const permissions = {
    // isCreate: isCreate(),
    // isDelete: isDelete(),
    // isUpdate: isUpdate(),
    // isView: isView()
    isCreate: false,
    isDelete: false,
    isUpdate: false,
}

const initManageTag: tagProps = {
    tagId: 0,
    color: '',
    tagName: '',
    tagNameEn: '',
    status: 'ACTIVE',
}


const ManageTag = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('tagId')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('ALL')

    const [maganeTag, setManageTag] = useState<any[]>([])
    const [popTag, setPopTag] = useState<boolean>(false)
    const [popTagIsEdit, setPopTagIsEdit] = useState<boolean>(false)
    const [popDataEdit, setPopDataEdit] = useState<any>(initManageTag)

    const loadData = useCallback(async () => {
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

        const res = await TagsApi.findAll(condition)
        if (res.status === 200) {
            setRowCount(res.headers['x-total'])
            setManageTag(res.data)
        }
    }, [page, pageLimit, search, sortBy, sortType, status])

    useEffect(() => {
        loadData()
    }, [loadData])

    const onCreate = () => {
        setPopDataEdit(initManageTag)
        setPopTagIsEdit(false)
        setPopTag(true)
        dispatch(showModal())
    }

    const onClosePopTag = () => {
        setPopTag(false)
        dispatch(resetModal())
    }

    const onActive = (id: number, valueStatus: string) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('OPERATIVE.ALERT.CONFIRM_STATUS')}</p>`,
            null, (res: any) => {
                if (res) {
                    TagsApi.updateStatusTag(id, valueStatus)
                        .then((resp) => {
                            notiSuccess(t('TAGS.MESSAGE.CHANGE_STATUS'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('TAGS.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
    }

    const onDelete = (data: any) => {
        const { tagName, tagId } = data
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TAGS.MESSAGE.CONFIRM_DEL')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TAGS.MESSAGE.CONFIRM_DEL_ITEM', {
                tagName: tagName,
            })}</p>`,
            (res: any) => {
                if (res) {
                    TagsApi.deleteTag(tagId)
                        .then((resp) => {
                            notiSuccess(t('TAGS.MESSAGE.DEL_SUCCESSFUL'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('TAGS.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const onEdit = (data: any) => {
        setPopDataEdit(data)
        setPopTagIsEdit(true)
        setPopTag(true)
        dispatch(showModal())
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }
    const handleChangeStatus = (statusVal: string) => {
        setStatus(statusVal)
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '80px', class: 'tb-tag-no' },
        { id: 'tagColor', disablePadding: false, label: t('TAGS.TABLE.COLOR'), width: '120px', class: 'tb-tag-tag-color' },
        { id: 'tagName', disablePadding: false, label: t('TAGS.TABLE.TAGNAME'), class: 'tb-tag-tag-name' },
        { id: 'status', disablePadding: false, label: t('TAGS.TABLE.STATUS'), width: '200px', class: 'tb-tag-status' },
        { id: 'updatedBy', disablePadding: false, label: t('BRANCH.TABLE.CELL.UPDATED_BY'), width: '180px', class: 'tb-tag-updateby' },
        { id: 'action', disablePadding: false, label: 'Action', width: '160px', class: 'tb-tag-action' }
    ]

    const renderData = (objData: any, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { tagId, tagName, color, status, updatedBy, updatedAt } = objData
        // permissions.isUpdate.disabled
        const permissUpdate = false
        const statusBtnActive = status === 'ACTIVE' ? true : permissUpdate
        const statusBtnInActive = status === 'INACTIVE' ? true : permissUpdate

        const objRenderData = {
            key: tagId,
            id: tagId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'COMPONENT', align: 'left', component: (<Box sx={{ backgroundColor: color, height: '21px', width: '80px' }} />) },
                { option: 'TEXT', align: 'left', label: tagName },
                { option: 'STATUS', align: 'center', label: status },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
                        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissUpdate },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissUpdate }
                    ]
                }
            ]
        }
        return (
            <TableRowCommon
                {...objRenderData}
                onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
                oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
                onedit={() => onEdit(objRenderData.obj)}
                ondelete={() => onDelete(objRenderData.obj)}
            />
        )
    }

    return (
        <div>
            <Card className="border-0 h-100">
                <HeaderCard text={t('TAGS.TITLE')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Row className="flax-row">
                                    <Col sm={12} md={6} xl={4} className="pt-2">
                                        <InputTextSearch
                                            keyInput={'search'}
                                            label={t('INPUT.SEARCH')}
                                            value={search}
                                            onchange={(event) => {
                                                setSearch(event.target.value)
                                                setPage(1)
                                            }}
                                            onClear={(event) => {
                                                setSearch('')
                                                setPage(1)
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                                        <FilterSelect
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value) {
                                                    handleChangeStatus(value)
                                                    setPage(1)
                                                }
                                            }}
                                            renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                                            label={t('STATUS.LABEL')}
                                            selectId="select-status"
                                            value={status}
                                            labelId="label-status"
                                            options={[
                                                <MenuItem key="1" value="ALL">
                                                    {t('STATUS.ALL')}
                                                </MenuItem>,
                                                <MenuItem key="2" value="ACTIVE">
                                                    {t('STATUS.ACTIVE')}
                                                </MenuItem>,
                                                <MenuItem key="3" value="INACTIVE">
                                                    {t('STATUS.INACTIVE')}
                                                </MenuItem>
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} className="mt-2 mt-md-auto">
                                <div className="pr-xl-2 d-flex justify-content-end">
                                    <div className='pl-3'>
                                        <ButtonCustom
                                            mode="add"
                                            // disabled={permissions.isCreate.disabled} 
                                            onClick={() => onCreate()}
                                            textButton={t('TAGS.BUTTON.ADD_TAG')}
                                            className="w-auto ml-auto d-flex mt-auto"
                                            btnStyle={{ height: '44px' }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Title>

                    <BoxTableTag className={'mt-4 pl-xl-3 pr-xl-2'} >
                        <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            onSort={onRequestSort}
                            setPageLimit={handleChangeRowsPerPage}
                            setPage={handleChangePage}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={maganeTag.map((item: any, index: number) => renderData(item, index))}
                        />
                    </BoxTableTag>
                </Card.Body>
            </Card>
            {popTag &&
                <FormManageTag
                    onClosePopTag={onClosePopTag}
                    isEdit={popTagIsEdit}
                    data={popDataEdit}
                    reLoadData={loadData}
                />
            }
        </div>
    )
}

export default ManageTag