import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface MedicinesInterface {
  group: string
  productCode: string
  productName: string
  productNameEn: string
  productTypeId: number | null
  productFormatId: number | null
  productUnitId: number | null
  productInstructionId: number | null
  productPreserveId: number | null
  productUseId: number | null
  strength: string
  salePrice: number
  costPrice: number
  minStock: number
  explanation: string
  showOnReceipt: string
  status?: string
  files: any
  image: any
  warnings?: string | null
  numberBarcode?: string
}

export default class MedicinesApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('medicines', { params: { ...props } }).then((res) => res)
  }
  static create(payload: any) {
    return this.api.post('medicines', payload)
  }
  static findById(id: number): Promise<MedicinesInterface> {
    return this.api.get(`medicines/${id}`)
  }
  static update(id: number, body: MedicinesInterface) {
    return this.api.patch(`medicines/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`medicines/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`medicines/${id}`)
  }
}
