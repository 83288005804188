import AboutUs from 'api/admin/clinic-manage/about.api';
import { KEY_STORAGE } from 'constants/common';

type UseSnCodeProps = {
    type?: 'data' | 'sn'
}

export const UseSnCode = async (props?: UseSnCodeProps) => {

    const res = await AboutUs.findAboutUs();
    if (res.status === 200) {

        if (res?.data?.formatAppointment) {
            localStorage.setItem(KEY_STORAGE.FORMATAPPOINTMENT, res?.data?.formatAppointment || '0')
        }

        if (props?.type === 'data') {
            return res.data
        } else {
            if (res.data.snType === '1') { return true }
            return false
        }
    }
    return false

}

