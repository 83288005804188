import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/64.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth64(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 49.86, height: 92.82, viewBox: "0 0 49.86 92.82" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }


        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 49.86, height: 92.82, viewBox: "0 0 49.86 92.82" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12342" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5440"
                                d="M39.9356 51.8904C40.881 57.9398 45.0891 62.8089 46.7169 68.4127L45.9472 75.6202L45.2736 81.9965C43.6867 84.7577 41.7629 87.1997 38.9902 89.1067C34.0609 92.5136 30.2294 88.7283 25.6527 87.2884C20.2342 92.7813 16.4108 89.8119 13.4131 84.9278C2.71245 84.6169 -3.00985 74.0022 2.86505 63.4334C7.3939 55.268 6.6238 47.0814 6.14368 38.8423C5.47826 27.7306 7.51359 16.9529 9.16484 6.09388C9.27945 5.57181 9.49072 5.07579 9.78773 4.63141C9.82932 4.56659 9.88819 4.51467 9.95771 4.48151C10.0272 4.44836 10.1046 4.43529 10.1812 4.44377C10.2577 4.45225 10.3304 4.48196 10.3909 4.52953C10.4515 4.5771 10.4976 4.64064 10.524 4.713C12.0392 9.50452 15.9193 38.1339 21.7092 44.2303C25.6682 48.3986 26.1628 30.7138 25.0767 1.47074C25.0484 0.72642 25.5312 0.503971 26.1736 0.952334C29.025 2.94245 29.6298 6.17047 30.5342 9.03961C34.9663 23.0684 37.6911 37.4754 39.9356 51.8904Z"
                                transform={width > 1440 ? "translate(0 10)" : "translate(0 0)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5440"
                                    d="M39.9356 51.8904C40.881 57.9398 45.0891 62.8089 46.7169 68.4127L45.9472 75.6202L45.2736 81.9965C43.6867 84.7577 41.7629 87.1997 38.9902 89.1067C34.0609 92.5136 30.2294 88.7283 25.6527 87.2884C20.2342 92.7813 16.4108 89.8119 13.4131 84.9278C2.71245 84.6169 -3.00985 74.0022 2.86505 63.4334C7.3939 55.268 6.6238 47.0814 6.14368 38.8423C5.47826 27.7306 7.51359 16.9529 9.16484 6.09388C9.27945 5.57181 9.49072 5.07579 9.78773 4.63141C9.82932 4.56659 9.88819 4.51467 9.95771 4.48151C10.0272 4.44836 10.1046 4.43529 10.1812 4.44377C10.2577 4.45225 10.3304 4.48196 10.3909 4.52953C10.4515 4.5771 10.4976 4.64064 10.524 4.713C12.0392 9.50452 15.9193 38.1339 21.7092 44.2303C25.6682 48.3986 26.1628 30.7138 25.0767 1.47074C25.0484 0.72642 25.5312 0.503971 26.1736 0.952334C29.025 2.94245 29.6298 6.17047 30.5342 9.03961C34.9663 23.0684 37.6911 37.4754 39.9356 51.8904Z"
                                    transform={width > 1440 ? "translate(0 10)" : "translate(0 0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}

                            <g data-name="Group 12342" >
                                <path
                                    data-name="Path 5441"
                                    d="M7.26486 7.60937L2.06116 19.4619C2.06116 19.4619 -0.801054 27.8808 2.06116 33.092C4.92337 38.3032 17.1462 44.0184 31.9741 43.5142C46.802 43.01 50.6096 32.2518 50.6096 32.2518C50.6096 32.2518 51.703 16.9065 43.0415 5.91964C43.0415 5.91964 32.7761 -3.38611 21.1539 3.50598C21.1543 3.50598 12.0098 1.29072 7.26486 7.60937Z"
                                    transform={width > 1440 ? "translate(-1 60)" : "translate(-1 55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5442"
                                    d="M8.92109 9.88205L4.37973 20.2246C4.37973 20.2246 1.88179 27.5712 4.37973 32.1187C6.87767 36.6663 17.5447 41.6535 30.4843 41.2136C43.4239 40.7737 46.746 31.385 46.746 31.385C46.746 31.385 47.7002 17.9951 40.141 8.40765C40.141 8.40765 31.1834 0.287081 21.0411 6.3013C21.0411 6.3013 13.0628 4.36795 8.92109 9.88205Z"
                                    transform={width > 1440 ? "translate(-1 60)" : "translate(-1 55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5443"
                                    d="M12.7986 12.1118L8.72258 21.2644C8.72258 21.2644 6.48154 27.7666 8.72258 31.7903C10.9636 35.8141 20.5378 40.2278 32.1519 39.8387C43.7661 39.4496 46.7478 31.1409 46.7478 31.1409C46.7478 31.1409 47.6037 19.291 40.8198 10.8058C40.8198 10.8058 32.7793 3.61947 23.6764 8.94133C23.6764 8.94191 16.5156 7.23178 12.7986 12.1118Z"
                                    transform={width > 1440 ? "translate(-1 60)" : "translate(-1 55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6546" d="M4.4041 47.0801C5.33575 40.6699 2.91295 15.0783 2.3407 6.56674C2.10753 5.86671 4.40823 33.5469 5.42809 46.8546C5.08295 46.9113 4.74112 46.9866 4.4041 47.0801Z" transform={width > 1440 ? "translate(8 14)" : "translate(8 12)"} style={{
                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                        <path data-name="Path 6547" d="M22.8294 45.9064C23.2565 42.8917 23.1116 43.3673 23.3937 40.3933C23.8312 34.0162 19.3633 3.68518 19.3633 2.44039C19.7522 1.81207 23.2648 44.2788 22.4585 45.8502C22.5204 45.8538 22.7324 45.8981 22.8294 45.9064Z" transform={width > 1440 ? "translate(9 14)" : "translate(8 12)"} style={{
                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                    </>
                                    : ''}

                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154154" x1="25.9779" y1="66.0365" x2="23.0658" y2="82.6586" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154154" x1="25.9779" y1="77.6947" x2="25.9779" y2="71.5297" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9545">
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M40.5411 49.8181C41.4576 55.6829 45.5373 60.4034 47.1154 65.8362L46.3692 72.8237L45.7162 79.0055C44.1777 81.6824 42.3126 84.0498 39.6245 85.8986C34.8457 89.2016 31.1311 85.5318 26.694 84.1358C21.4409 89.4611 17.7342 86.5823 14.828 81.8473C4.45388 81.5458 -1.09379 71.2551 4.60182 61.0088C8.99246 53.0926 8.24586 45.1558 7.7804 37.1682C7.13529 26.3956 9.1085 15.9468 10.7094 5.41919C10.8205 4.91306 11.0253 4.43217 11.3132 4.00135C11.3536 3.93851 11.4106 3.88817 11.478 3.85603C11.5454 3.82389 11.6205 3.81121 11.6947 3.81944C11.7689 3.82766 11.8393 3.85646 11.8981 3.90258C11.9568 3.9487 12.0014 4.0103 12.027 4.08045C13.496 8.72575 17.2577 36.4815 22.8709 42.3918C26.7091 46.4328 27.1886 29.2878 26.1356 0.937139C26.1082 0.215533 26.5763 -0.000127509 27.199 0.434552C29.9634 2.36393 30.5498 5.49344 31.4266 8.27502C35.7234 21.8757 38.3651 35.8431 40.5411 49.8181Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M6.93051 55.3616L1.88562 66.8525C1.88562 66.8525 -0.889247 75.0144 1.88562 80.0666C4.66048 85.1187 16.5103 90.6595 30.8857 90.1707C45.2611 89.682 48.9525 79.2521 48.9525 79.2521C48.9525 79.2521 50.0125 64.375 41.6154 53.7235C41.6154 53.7235 31.6632 44.7017 20.3957 51.3835C20.3961 51.3835 11.5307 49.2358 6.93051 55.3616Z" fill="#EFEDE7" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M8.53377 57.5654L4.131 67.5924C4.131 67.5924 1.70929 74.7148 4.131 79.1235C6.55271 83.5322 16.8942 88.3673 29.4389 87.9408C41.9836 87.5143 45.2043 78.4122 45.2043 78.4122C45.2043 78.4122 46.1295 65.4309 38.8009 56.136C38.8009 56.136 30.1167 48.2633 20.2839 54.094C20.2839 54.094 12.5491 52.2196 8.53377 57.5654Z" fill="#F7F7F7" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M12.2923 59.7276L8.34062 68.6009C8.34062 68.6009 6.16797 74.9047 8.34062 78.8056C10.5133 82.7065 19.7953 86.9856 31.055 86.6084C42.3147 86.2312 45.2054 78.176 45.2054 78.176C45.2054 78.176 46.0352 66.6877 39.4583 58.4615C39.4583 58.4615 31.6632 51.4944 22.838 56.6539C22.838 56.6545 15.8958 54.9965 12.2923 59.7276Z" fill="white" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M32.552 61H19.448C17.5437 61 16 62.5437 16 64.448V77.552C16 79.4563 17.5437 81 19.448 81H32.552C34.4563 81 36 79.4563 36 77.552V64.448C36 62.5437 34.4563 61 32.552 61Z" fill="#C1C4C5" />
                                            {/* <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M32.5533 61.8623H19.4493C18.0211 61.8623 16.8633 63.0201 16.8633 64.4483V77.5523C16.8633 78.9805 18.0211 80.1383 19.4493 80.1383H32.5533C33.9815 80.1383 35.1393 78.9805 35.1393 77.5523V64.4483C35.1393 63.0201 33.9815 61.8623 32.5533 61.8623Z" stroke="#C1C4C5" /> */}
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M24.4981 80.1082C24.1533 80.7323 27.3875 80.4237 29.8131 80.3927C33.4232 80.3168 35.4833 81.0547 35.4489 72.7209C35.5425 69.7637 35.451 66.8036 35.1747 63.8579C34.2593 62.9683 35.4161 72.8847 33.0732 77.2464C30.863 80.3961 25.0894 79.4393 24.4981 80.1082Z" fill="#B0B1B1" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M29.4259 71.7139H22.53C22.0539 71.7139 21.668 72.0998 21.668 72.5759V74.2999C21.668 74.7759 22.0539 75.1618 22.53 75.1618H29.4259C29.902 75.1618 30.2879 74.7759 30.2879 74.2999V72.5759C30.2879 72.0998 29.902 71.7139 29.4259 71.7139Z" fill="#CBCCCC" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M29.0783 64.4746H22.1823C21.7062 64.4746 21.3203 64.8605 21.3203 65.3366V67.0606C21.3203 67.5367 21.7062 67.9226 22.1823 67.9226H29.0783C29.5543 67.9226 29.9403 67.5367 29.9403 67.0606V65.3366C29.9403 64.8605 29.5543 64.4746 29.0783 64.4746Z" fill="#9E9E9E" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M29.4259 64.4746H22.53C22.0539 64.4746 21.668 64.8605 21.668 65.3366V67.0606C21.668 67.5367 22.0539 67.9226 22.53 67.9226H29.4259C29.902 67.9226 30.2879 67.5367 30.2879 67.0606V65.3366C30.2879 64.8605 29.902 64.4746 29.4259 64.4746Z" fill="url(#paint0_linear_4126_154154)" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M29.4259 71.3711H22.53C22.0539 71.3711 21.668 71.757 21.668 72.2331V73.9571C21.668 74.4331 22.0539 74.8191 22.53 74.8191H29.4259C29.902 74.8191 30.2879 74.4331 30.2879 73.9571V72.2331C30.2879 71.757 29.902 71.3711 29.4259 71.3711Z" fill="url(#paint1_linear_4126_154154)" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M1.10547 71.727C1.10547 71.727 21.3794 66.7554 48.0295 70.6953" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={width > 1440 ? "translate(-1 10)" : "translate(-1 8)"} d="M1.10547 72.2367C16.3737 69.2417 32.5021 68.8785 48.0295 71.1801" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>64</TextNumberU>
        </Div >
    )
}
