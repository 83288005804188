import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Box, Typography } from '@mui/material'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/warehouses/medicines/style'

/** API */
import { routeName } from 'routes/routes-name'
import MedicinesApi from 'api/warehouses/medicine.api'

/** UTILS */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import { numberFormat } from 'utils/app.utils'
import TableRowExport from 'component/Pdf/TableRowExport'
import ButtonExport from 'component/Button/ButtonExport'
import AutocompleteSelect from 'component/Select/AutocompleteSelect';
import { dateTimeToView } from 'utils/date.utils';

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

type StatusProps = {
  status: 'ACTIVE' | 'INACTIVE' | 'ALL'
  name: string
}

export default function Medicines() {
  const classes = useStyles()
  const { t } = useTranslation()

  const [medicines, setMedicines] = useState([])
  const [exportData, setExportData] = useState([])

  const [medicineTypes, setMedicineTypes] = useState([]);
  const [medicineType, setMedicineType]: any = useState({});
  const [medTypeLoading, setMedTypeLoading] = useState(false);

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('productCode')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [statusMED, setStatusMED] = useState<StatusProps>({ status: "ALL", name: t('ALL') })

  const loadData = useCallback(async () => {
    let condition: any = { group: 'MEDICINE', status: '' }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search || !_.isEmpty(medicineType)) condition = { ...condition, search: search || medicineType?.productTypeName || '' }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (statusMED) condition = { ...condition, status: statusMED.status === "ALL" ? '' : statusMED.status }
    const res = await MedicinesApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setMedicines(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, medicineType, statusMED])

  useEffect(() => {
    loadData()
  }, [loadData])

  const loadDataForExport = useCallback(async () => {
    let condition: any = { status: '' }
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search || !_.isEmpty(medicineType)) condition = { ...condition, search: search || medicineType?.productTypeName || '' }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (statusMED) condition = { ...condition, status: statusMED.status === "ALL" ? '' : statusMED.status }

    const res = await MedicinesApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, medicineType, statusMED])

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  const loadProductTypes = (medSearch?: string) => {
    setMedTypeLoading(true)
    const condition: any = { group: 'MEDICINE', pageLimit: 10, status: 'ACTIVE', search: medSearch || '' }
    ProductTypesApi.findAll(condition)
      .then(({ data }) => setMedicineTypes(data))
      .catch((e) => { return })
      .finally(() => setMedTypeLoading(false))
  }

  const handleInputSearch = useCallback(
    _.debounce((medSearch?: string) => {
      loadProductTypes(medSearch)
      setPage(1)
    }, 1000), []);

  useEffect(() => {
    loadProductTypes()
  }, []);

  const onEdit = (data: any) => {
    window.location.href = `${routeName.medicine}/${data.productId}`
  }

  const onCreate = () => {
    window.location.href = routeName.medicine + '/create'
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MEDICINE.TEXT.CHANGE_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          MedicinesApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('MEDICINE.MSG.UPDATE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('MEDICINE.MSG.UN_SUCCESS'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }

  const onDelete = (data: any) => {
    const { productId, productName } = data
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MEDICINE.TEXT.DEL_MED1')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t(`MEDICINE.TEXT.DEL_MED2`, { name: productName })}</p>`,
      (res: any) => {
        if (res) {
          MedicinesApi.remove(productId)
            .then((resp) => {
              notiSuccess(t('MEDICINE.MSG.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('MEDICINE.MSG.UN_SUCCESS'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '80px' },
    { id: 'productTypeId', disablePadding: false, label: t('MEDICINE.TABLE.CELLS_MED.MEDICINE_TYPE'), width: '200px' },
    { id: 'productCode', disablePadding: false, label: t('MEDICINE.TABLE.CELLS_MED.MEDICINE_CODE'), width: '150px' },
    { id: 'productName', disablePadding: false, label: t('MEDICINE.TABLE.CELLS_MED.MEDICINE_NAME') },
    { id: 'price', disablePadding: false, label: t('MEDICINE.TABLE.CELLS_MED.PRICE'), width: '150px' },
    { id: 'status', disablePadding: false, label: t('MEDICINE.TABLE.CELLS_MED.STATUS'), width: '150px', align: 'center' },
    { id: 'updatedBy', disablePadding: false, label: t('MEDICINE.TABLE.CELLS_MED.UPDATED_BY'), width: '175px', align: 'center' },
    { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
  ]

  const renderData = (objData: any, no: number, valueExportData: boolean) => {
    const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { productId, productType, productCode, productName, salePrice, updatedBy, updatedAt, status: medStatus } = objData

    const colUpdateExport = {
      option: 'TEXT', align: 'left', label: `${updatedBy} ${dateTimeToView(updatedAt)}`
    }
    const renderColUpdate = valueExportData ? colUpdateExport : { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } }

    const statusBtnActive = medStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = medStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: productId,
      id: productId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'left', label: productType.productTypeName },
        { option: 'TEXT', align: 'left', label: productCode },
        { option: 'TEXT', align: 'left', label: productName },
        { option: 'TEXT', align: 'right', label: numberFormat(salePrice) },
        { option: 'STATUS', align: 'center', label: medStatus },
        { ...renderColUpdate },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <>
        {valueExportData === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon
            {...objRenderData}
            onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
            oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
            onedit={() => onEdit(objRenderData.obj)}
            ondelete={() => onDelete(objRenderData.obj)}
          />
        }
      </>
    )
  }

  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('MEDICINE.TEXT.TYPE')}: ${!_.isEmpty(medicineType) ? medicineType?.productTypeName : '-'}` }
  ]

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('MEDICINE.HEADER.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row>
              <Col lg={7}>
                <Row className="">
                  <Col sm={12} md={4} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('MEDICINE.TEXT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={4} xl={4} className="pl-md-0 pt-2">
                    <Box className='position-relative' sx={{ '.MuiOutlinedInput-input': { paddingLeft: '70px !important' } }}>
                      <Typography className='position-absolute top-50 translate-middle-y' sx={{ left: '12px', zIndex: 1 }}>{t('MEDICINE.TEXT.TYPE')}:</Typography>
                      <AutocompleteSelect
                        labelId="medicineType"
                        noOptionsText={t('NOT_FOUND')}
                        options={medicineTypes}
                        getOptionLabel={(option: any) => option.productTypeName || t('ALL')}
                        renderOption={(props, option: any) => (
                          <Box component="li" {...props}>
                            {option.productTypeName}
                          </Box>
                        )}
                        onchange={(e, value: any) => {
                          setPage(1)
                          setMedicineType(value)
                        }}
                        inputProps={{
                          onChange: (e: any) => {
                            setMedicineTypes([])
                            handleInputSearch(e.target.value)
                          }
                        }}
                        onOpen={(e: any) => loadProductTypes()}
                        value={!_.isEmpty(medicineType) ? medicineType : ''}
                        loading={medTypeLoading}
                        height={32}
                        disabledBorder
                        disableClearable={_.isEmpty(medicineType)}
                      />
                    </Box>
                  </Col>
                  <Col sm={12} md={4} xl={4} className="pl-md-0 pt-2">
                    <Box className='position-relative' sx={{ '.MuiOutlinedInput-input': { paddingLeft: '70px !important' } }}>
                      <Typography className='position-absolute top-50 translate-middle-y' sx={{ left: '12px', zIndex: 1 }}>{t('MEDICINE.TEXT.STATUS')}:</Typography>
                      <AutocompleteSelect
                        labelId="statusMED"
                        noOptionsText={t('NOT_FOUND')}
                        options={[
                          { status: 'ALL', name: t('ALL') },
                          { status: 'ACTIVE', name: t('MEDICINE.FILTER.ACTIVE') },
                          { status: 'INACTIVE', name: t('MEDICINE.FILTER.INACTIVE') }
                        ]}
                        getOptionLabel={(option: any) => option?.name || ''}
                        renderOption={(props, option: any) => (
                          <Box component="li" {...props}>
                            {t(`MEDICINE.TEXT.STATUS_${option.status}`)}
                          </Box>
                        )}
                        onchange={(e, value: any) => {
                          setPage(1)
                          setStatusMED(value)
                        }}
                        onOpen={(e: any) => loadProductTypes()}
                        value={!_.isEmpty(statusMED) ? statusMED : ''}
                        height={32}
                        disabledBorder
                        disableClearable={_.isEmpty(statusMED)}
                      />
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col lg={5} className="mt-2 mt-lg-auto">
                <div className="pr-xl-2 d-flex">
                  <div className='ml-auto'>
                    <ButtonExport headCells={headCells} portrait={false} filter={filter} fileName={t('MEDICINE.BUTTON.ITEM_MED')}
                      rowsData={exportData.map((val, i) => {
                        return renderData(val, i, true)
                      })} />
                  </div>
                  <div className='pl-3'>
                    <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('MEDICINE.BUTTON.ADD_MED')} className="w-auto ml-auto d-flex mt-auto" btnStyle={{ height: '44px' }} />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={medicines.map((val, i) => {
                return renderData(val, i, false)
              })}
              tableFixedWidth
              tableMinWidth={1300}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
