import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface LastLotNumberProps {
  branchId: number
  item: string
  group: "PRODUCT" | "MEDICINE"
}

export interface DisposeLotProps {
  inventoryId:number
  remark?:string
}

const url = 'import-inventories'
export default class ImportInventoryApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(url, { params: { ...props } }).then((res) => res)
  }

  static findAllDocument(props: FindAllInterface): Promise<any> {
    return this.api.get(`${url}/documents`, { params: { ...props } }).then((res) => res)
  }

  static findDocumentById(id: number) {
    return this.api.get(`${url}/documents/${id}`).then((res) => res)
  }

  static findAllProducts(props: any): Promise<any> {
    return this.api.get(`${url}/products`, { params: { ...props } }).then((res) => res)
  }

  static findAllProductsType(props: any): Promise<any> {
    return this.api.get(`${url}/product-types`, { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post(url, playload).then((res) => res)
  }

  static cancel(id: number, playload: any) {
    return this.api.patch(`${url}/cancel/${id}`, playload)
  }

  // last-lot-number
  static lastLotNumber(playload: LastLotNumberProps) {
    return this.api.get(`${url}/last-lot-number`, { params: { ...playload } })
  }

  static disposeLot( payload:DisposeLotProps ) {
    return this.api.patch(`${url}/dispose-lot`, payload)
  }

}
