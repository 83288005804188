import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/85.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 57.08, height: 100, viewBox: "0 0 57.08 105.04" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 57.08, height: 100, viewBox: "0 0 57.08 105.04" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>85</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' width={width > 1440 ? 70 : 100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12350">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5556"
                                d="M53.415 5.89598L55.3235 19.2356C55.3513 19.437 55.301 19.6414 55.1828 19.8069C47.2037 31.0442 45.0811 44.0663 44.683 57.1178C44.2167 72.4031 48.9759 102.557 42.7329 103.866C37.8377 104.893 27.6652 -14.6014 7.49736 102.295C7.12193 104.467 3.63663 104.238 2.26015 102.517C0.220265 99.9659 0.292752 96.8802 0.146792 93.9977C-0.58793 79.594 1.58905 65.3717 3.2669 51.1403C4.50037 40.7275 5.09012 30.4146 2.54124 20.1108C1.50711 15.9384 1.36214 11.6936 2.91313 7.60277C4.45506 3.51216 6.9675 0.963288 11.8927 2.26944C15.7475 3.28525 19.4664 -0.542397 24.5094 1.62553C24.5276 1.63459 24.5547 1.63458 24.5729 1.64365C24.9 1.78384 25.2181 1.94436 25.5252 2.12427C25.752 2.25132 25.9783 2.39649 26.2147 2.55053C25.6161 2.47804 25.0537 2.43234 24.4913 2.39649H24.4823C20.7271 2.16958 17.879 2.80462 16.083 4.6369C14.8858 5.85244 14.151 7.59372 13.9243 9.96118C13.3801 15.575 16.0467 18.9953 21.6523 19.5847C24.8542 19.9195 27.9471 20.2831 30.7408 21.9878C36.4279 25.4707 47.6207 20.7451 48.9268 14.1783C49.4874 11.3656 49.5083 8.44939 49.7431 5.52507L49.5264 4.90381C48.5977 3.91617 47.6749 2.93109 46.2149 1.36197L46.1333 1.27116C46.0518 1.18962 45.97 1.09881 45.8885 1.0082C49.2808 1.8808 51.5003 3.4381 53.2292 5.47957C53.329 5.59815 53.3934 5.74247 53.415 5.89598Z"
                                transform={width > 1440 ? "translate(0 -10)" : "translate(0 -5)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />

                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5556"
                                    d="M53.415 5.89598L55.3235 19.2356C55.3513 19.437 55.301 19.6414 55.1828 19.8069C47.2037 31.0442 45.0811 44.0663 44.683 57.1178C44.2167 72.4031 48.9759 102.557 42.7329 103.866C37.8377 104.893 27.6652 -14.6014 7.49736 102.295C7.12193 104.467 3.63663 104.238 2.26015 102.517C0.220265 99.9659 0.292752 96.8802 0.146792 93.9977C-0.58793 79.594 1.58905 65.3717 3.2669 51.1403C4.50037 40.7275 5.09012 30.4146 2.54124 20.1108C1.50711 15.9384 1.36214 11.6936 2.91313 7.60277C4.45506 3.51216 6.9675 0.963288 11.8927 2.26944C15.7475 3.28525 19.4664 -0.542397 24.5094 1.62553C24.5276 1.63459 24.5547 1.63458 24.5729 1.64365C24.9 1.78384 25.2181 1.94436 25.5252 2.12427C25.752 2.25132 25.9783 2.39649 26.2147 2.55053C25.6161 2.47804 25.0537 2.43234 24.4913 2.39649H24.4823C20.7271 2.16958 17.879 2.80462 16.083 4.6369C14.8858 5.85244 14.151 7.59372 13.9243 9.96118C13.3801 15.575 16.0467 18.9953 21.6523 19.5847C24.8542 19.9195 27.9471 20.2831 30.7408 21.9878C36.4279 25.4707 47.6207 20.7451 48.9268 14.1783C49.4874 11.3656 49.5083 8.44939 49.7431 5.52507L49.5264 4.90381C48.5977 3.91617 47.6749 2.93109 46.2149 1.36197L46.1333 1.27116C46.0518 1.18962 45.97 1.09881 45.8885 1.0082C49.2808 1.8808 51.5003 3.4381 53.2292 5.47957C53.329 5.59815 53.3934 5.74247 53.415 5.89598Z"
                                    transform={width > 1440 ? "translate(0 -10)" : "translate(0 -5)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12350">
                                <path
                                    data-name="Path 5560"
                                    d="M3.20758 25.1921C3.20758 25.1921 -2.60323 12.8328 3.20758 6.2569C9.01839 -0.318961 12.6475 2.04634 14.9147 1.31752C17.1819 0.588709 24.8358 0.657052 25.4309 2.32328C26.0259 3.9895 29.2004 4.17821 31.4682 3.4035C33.736 2.62879 37.1372 0.482924 40.5386 0.875696C43.94 1.26847 51.2779 3.03712 52.33 5.35002C53.382 7.66292 59.1418 12.3722 56.3028 22.58C56.3028 22.58 48.7036 31.8544 49.3822 35.5087C49.3822 35.5087 38.2712 48.4339 31.4684 41.6313C24.6656 34.8287 21.4911 48.0821 16.5023 43.6096C11.5135 39.137 3.50147 40.7804 3.20758 25.1921Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5561"
                                    d="M4.96668 25.642C4.96668 25.642 -0.354837 14.3231 4.96668 8.30035C10.2882 2.2776 13.6116 4.44374 15.6887 3.77619C17.7658 3.10864 24.7744 3.17207 25.3197 4.69785C25.8649 6.22363 28.7719 6.39677 30.849 5.68746C32.9261 4.97814 36.0409 3.01252 39.1557 3.37279C42.2705 3.73306 48.9915 5.35201 49.9547 7.4699C50.918 9.58779 56.1934 13.8998 53.5929 23.2495C53.5929 23.2495 46.6341 31.7421 47.2554 35.09C47.2554 35.09 37.0784 46.9282 30.8488 40.6973C24.6192 34.4663 21.7105 46.6066 17.1418 42.5095C12.5731 38.4124 5.23516 39.9177 4.96668 25.642Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5562"
                                    d="M8.34241 25.2393C8.34241 25.2393 3.95968 15.9168 8.34241 10.9572C12.7251 5.99749 15.4627 7.78092 17.1725 7.23115C18.8822 6.68139 24.6554 6.7332 25.1043 7.9903C25.5532 9.24741 27.9479 9.38884 29.6576 8.8048C31.3674 8.22077 33.9344 6.60162 36.4994 6.89826C39.0644 7.19491 44.6001 8.52804 45.3937 10.2733C46.1873 12.0185 50.531 15.5696 48.3897 23.2684C48.3897 23.2684 42.6577 30.2634 43.1698 33.0209C43.1698 33.0209 34.7889 42.7697 29.6572 37.6386C24.5256 32.5076 22.1327 42.5039 18.3693 39.1307C14.6059 35.7575 8.56381 36.9963 8.34241 25.2393Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />

                                {(isToothRoot && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6577" d="M7.28497 2.64614C6.82483 4.0361 6.24756 7.47991 6.56239 14.3817C6.99851 24.1566 1.49185 65.6756 1.61062 65.8993C1.56358 66.0484 7.51628 18.5272 7.19193 14.3514C6.701 8.34949 7.30095 4.92812 7.99373 2.4403C7.75212 2.48891 7.51503 2.55776 7.28497 2.64614Z" transform={width > 1440 ? "translate(4 18)" : "translate(4 26)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                        <path data-name="Path 6578" d="M32.9608 5.40982C32.5006 6.79978 31.9233 10.2436 32.2382 17.1454C32.6743 26.9203 41.5916 64.8446 41.7103 65.0683C41.6633 65.2175 33.1921 21.2909 32.8677 17.1151C32.3768 11.1132 32.9767 7.6918 33.6695 5.20397C33.4279 5.25258 33.1908 5.32144 32.9608 5.40982Z" transform={width > 1440 ? "translate(2 18)" : "translate(2 26)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                    </>
                                    : ''}

                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4107_155406" x1="26.9815" y1="18.0325" x2="24.0696" y2="34.6531" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4107_155406" x1="26.9776" y1="30.5545" x2="26.9776" y2="24.39" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M52.6056 7.67747L54.4852 20.815C54.5126 21.0133 54.463 21.2146 54.3467 21.3776C46.4885 32.4446 44.398 45.2694 44.0059 58.1231C43.5468 73.1769 48.2338 102.873 42.0854 104.163C37.2643 105.174 27.246 -12.5093 7.38376 102.616C7.01401 104.755 3.58152 104.529 2.22591 102.834C0.216928 100.322 0.288316 97.2831 0.144568 94.4442C-0.579022 80.2587 1.56498 66.252 3.2174 52.2362C4.43218 41.9812 5.01299 31.8246 2.50273 21.6769C1.48428 17.5678 1.3415 13.3872 2.86899 9.3584C4.38756 5.32977 6.86193 2.81952 11.7125 4.10588C15.5089 5.1063 19.1715 1.33665 24.1381 3.47172C24.1559 3.48065 24.1827 3.48064 24.2005 3.48957C24.5227 3.62763 24.836 3.78572 25.1385 3.96291C25.3618 4.08803 25.5847 4.23101 25.8174 4.38271C25.2279 4.31132 24.6741 4.26632 24.1202 4.23101H24.1113C20.413 4.00753 17.6081 4.63295 15.8393 6.43747C14.6602 7.63459 13.9366 9.34948 13.7133 11.6811C13.1773 17.2098 15.8036 20.5783 21.3242 21.1587C24.4775 21.4885 27.5236 21.8466 30.275 23.5254C35.8759 26.9556 46.8991 22.3015 48.1855 15.8342C48.7376 13.0642 48.7581 10.1922 48.9894 7.31218L48.776 6.70033C47.8613 5.72766 46.9525 4.7575 45.5146 3.21216L45.4343 3.12273C45.354 3.04242 45.2735 2.95298 45.1932 2.86375C48.5341 3.72313 50.72 5.25683 52.4227 7.26737C52.521 7.38415 52.5844 7.52629 52.6056 7.67747Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M42.0823 2.56259C38.2787 6.02864 34.6617 9.32301 31.0029 12.6558C29.1627 11.0041 29.5691 9.0425 29.4727 6.77784C33.6041 5.39254 37.1208 2.13018 42.0823 2.56259Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M25.6375 3.82891C20.4511 2.32198 16.0758 3.8549 12.2383 7.40185L25.6375 3.82891Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M43.4983 2.93555C37.6579 3.57242 32.8824 5.70128 30.9922 11.8685L43.4983 2.93555Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M3.16064 24.7106C3.16064 24.7106 -2.56212 12.5386 3.16064 6.06234C8.8834 -0.413884 12.4575 1.91557 14.6903 1.1978C16.9232 0.480032 24.4611 0.547339 25.0472 2.18832C25.6332 3.82929 28.7596 4.01514 30.993 3.25217C33.2265 2.4892 36.5761 0.37585 39.926 0.76267C43.2758 1.14949 50.5026 2.89135 51.5387 5.1692C52.5748 7.44705 58.2473 12.085 55.4513 22.1381C55.4513 22.1381 47.9673 31.272 48.6356 34.8709C48.6356 34.8709 37.6929 47.6003 30.9932 40.9008C24.2935 34.2012 21.1672 47.2538 16.2539 42.849C11.3407 38.4442 3.45007 40.0627 3.16064 24.7106Z" fill="#EFEDE7" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M4.89178 25.1545C4.89178 25.1545 -0.349102 14.0071 4.89178 8.07561C10.1327 2.14412 13.4057 4.27744 15.4513 3.62C17.497 2.96256 24.3994 3.02503 24.9364 4.52769C25.4733 6.03036 28.3363 6.20087 30.3819 5.50231C32.4276 4.80374 35.4952 2.8679 38.5628 3.22271C41.6304 3.57752 48.2495 5.17194 49.1982 7.25774C50.1468 9.34354 55.3423 13.5902 52.7812 22.7982C52.7812 22.7982 45.9279 31.1622 46.5397 34.4593C46.5397 34.4593 36.5169 46.1182 30.3817 39.9817C24.2465 33.8451 21.3819 45.8014 16.8824 41.7664C12.383 37.7314 5.15619 39.2138 4.89178 25.1545Z" fill="#F7F7F7" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M8.21133 24.7568C8.21133 24.7568 3.89501 15.5755 8.21133 10.691C12.5277 5.8065 15.2238 7.5629 16.9076 7.02146C18.5915 6.48003 24.2772 6.53105 24.7193 7.76911C25.1614 9.00717 27.5197 9.14645 29.2036 8.57127C30.8874 7.99608 33.4155 6.40147 35.9417 6.69362C38.4679 6.98577 43.9196 8.2987 44.7012 10.0175C45.4828 11.7362 49.7607 15.2335 47.6518 22.8157C47.6518 22.8157 42.0067 29.7047 42.511 32.4204C42.511 32.4204 34.2571 42.0215 29.2032 36.9682C24.1493 31.9149 21.7927 41.7598 18.0863 38.4377C14.3799 35.1156 8.42938 36.3356 8.21133 24.7568Z" fill="white" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M33.5523 13H20.4477C18.5436 13 17 14.5436 17 16.4477V29.5523C17 31.4564 18.5436 33 20.4477 33H33.5523C35.4564 33 37 31.4564 37 29.5523V16.4477C37 14.5436 35.4564 13 33.5523 13Z" fill="#C1C4C5" />
                                            {/* <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M33.5498 13.8623H20.4451C19.0171 13.8623 17.8594 15.02 17.8594 16.4481V29.5527C17.8594 30.9808 19.0171 32.1385 20.4451 32.1385H33.5498C34.9779 32.1385 36.1355 30.9808 36.1355 29.5527V16.4481C36.1355 15.02 34.9779 13.8623 33.5498 13.8623Z" stroke="#C1C4C5" /> */}
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M25.4981 32.1039C25.1533 32.7279 28.3872 32.4193 30.8127 32.3883C34.4224 32.3125 36.4824 33.0503 36.4479 24.7172C36.5416 21.7603 36.45 18.8004 36.1738 15.8549C35.2585 14.9654 36.4152 24.881 34.0725 29.2423C31.8625 32.3917 26.0894 31.435 25.4981 32.1039Z" fill="#B0B1B1" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.0893 16.4707H23.194C22.7179 16.4707 22.332 16.8566 22.332 17.3326V19.0565C22.332 19.5325 22.7179 19.9184 23.194 19.9184H30.0893C30.5653 19.9184 30.9512 19.5325 30.9512 19.0565V17.3326C30.9512 16.8566 30.5653 16.4707 30.0893 16.4707Z" fill="#9E9E9E" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.4292 16.4707H23.5338C23.0578 16.4707 22.6719 16.8566 22.6719 17.3326V19.0565C22.6719 19.5325 23.0578 19.9184 23.5338 19.9184H30.4292C30.9052 19.9184 31.2911 19.5325 31.2911 19.0565V17.3326C31.2911 16.8566 30.9052 16.4707 30.4292 16.4707Z" fill="url(#paint0_linear_4107_155406)" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.4253 24.5781H23.5299C23.0539 24.5781 22.668 24.964 22.668 25.44V27.1639C22.668 27.6399 23.0539 28.0258 23.5299 28.0258H30.4253C30.9013 28.0258 31.2872 27.6399 31.2872 27.1639V25.44C31.2872 24.964 30.9013 24.5781 30.4253 24.5781Z" fill="#CBCCCC" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.4253 24.2314H23.5299C23.0539 24.2314 22.668 24.6173 22.668 25.0934V26.8172C22.668 27.2932 23.0539 27.6791 23.5299 27.6791H30.4253C30.9013 27.6791 31.2872 27.2932 31.2872 26.8172V25.0934C31.2872 24.6173 30.9013 24.2314 30.4253 24.2314Z" fill="url(#paint1_linear_4107_155406)" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M1.58984 21.1035C14.3306 21.8032 25.1023 24.2085 54.5402 21.2594" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M1.58984 20.5742C13.4345 21.3395 27.7146 23.7164 54.5402 20.5984" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
