import { useState, useEffect, useCallback } from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { Divider, Box, Grid, Typography, IconButton, Collapse } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import MenuItem from '@mui/material/MenuItem'
import { UseSnCode } from 'utils/useSnCode'
/** TRANSLATION */
import { useTranslation } from 'react-i18next'
import HeaderLabel from 'component/CardCustom/HeaderLabel'

/** STYLE */
import * as UseStyles from 'features/setting/branch-service-point/branch/style'
import { colors } from 'constants/theme'

/** API */
import { fileUrl } from 'api/api'
import BranchApi from 'api/setting/branch-service-points/branch.api'
import BanksApi from 'api/setting/finances/bank.api'
import ServicePointApi from 'api/setting/branch-service-points/service-point.api'
import ComponentApi from 'api/components-api'

/** CONSTANT */
import { icons, onImgError } from 'constants/images'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { routeName } from 'routes/routes-name'
import UploadImage from 'component/Upload/UploadImage'
import InputTextarea from 'component/Input/InputTextarea'
import BasicSelect from 'component/Select/BasicSelect'

import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import SelectAddress from 'component/Select/SelectAddress'
import InputCheckbox from 'component/Input/InputCheckbox'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import PreviewImage from 'component/Image/PreviewImage'
import InputNewRangeTimePicker from 'component/Input/InputNewRangeTimePicker'
import InputRadio from 'component/Input/InputRadio'

/** RECEIPT FORMAT */
import ReceiptA5 from 'assets/images/receipt-format/receiptA5.png'
import ReceiptA5Landscape from 'assets/images/receipt-format/receiptA5-landscape-1.png'
import ReceiptA5LandscapeDf from 'assets/images/receipt-format/receiptA5-landscape-df.png'
import ReceiptA4 from 'assets/images/receipt-format/receiptA4.png'
// Old
// import Thermal1 from 'assets/images/receipt-format/thermal-1.png'
// import Thermal2 from 'assets/images/receipt-format/thermal-2.png'
// import Thermal3 from 'assets/images/receipt-format/thermal-3.png'
// New
import Thermal1 from 'assets/images/receipt-format/ReceiptThermal-1.png'
import Thermal2 from 'assets/images/receipt-format/ReceiptThermal-2.png'
import Thermal3 from 'assets/images/receipt-format/ReceiptThermal-3.png'
import Thermal4 from 'assets/images/receipt-format/ReceiptThermal-4.png'
// ReceiptThermal-1
import Sticker1 from 'assets/sticker/format1.png'
import Sticker2 from 'assets/sticker/format2.png'
import Sticker3 from 'assets/sticker/format3.png'

import $ from 'jquery'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormTemplaceDocDentist, { FormTemplaceDocDentisInfo, ModalExamDoc, TemplaceDocInfo, ModalExamDocTitle } from './form-templace-doc-dentist'
import FormFormatForm from './form-formatform'

// pdf example
import OPD_CARD_COVER from '../../../../assets/branch/templaceDoc/OPD_CARD_COVER.png'
import OPD_CARD_V1 from '../../../../assets/branch/templaceDoc/OPD_CARD_V1.png'
import OPD_CARD_COVER_V1 from '../../../../assets/branch/templaceDoc/OPD_CARD_COVER_V1.png'
import OPD_CARD_HOSPITAL_1 from '../../../../assets/branch/templaceDoc/OPD_CARD_HOSPITAL_1.png'
import OPD_CARD_HOSPITAL_2 from '../../../../assets/branch/templaceDoc/OPD_CARD_HOSPITAL_2.png'
import OPD_CARD_BLANK_1 from '../../../../assets/branch/templaceDoc/OPD_CARD_BLANK_1.png'
import OPD_CARD_BLANK_2 from '../../../../assets/branch/templaceDoc/OPD_CARD_BLANK_2.png'
import OPD_CARD from '../../../../assets/branch/templaceDoc/OPD_CARD.png'

import REFERRALPATIENT from '../../../../assets/branch/templaceDoc/REFERRALPATIENT.png'
import DENTALREFERRAL from '../../../../assets/branch/templaceDoc/DENTALREFERRAL.png'

import DENTISTCERTIFICATE from '../../../../assets/branch/templaceDoc/DENTISTCERTIFICATE.png'
import DENTISTCERTIFICATE_EN from '../../../../assets/branch/templaceDoc/DENTISTCERTIFICATE_EN.png'
import DENTISTCERTIFICATE_BLANK from '../../../../assets/branch/templaceDoc/DENTISTCERTIFICATE_BLANK.png'
import DENTISTCERTIFICATE_BLANK_EN from '../../../../assets/branch/templaceDoc/DENTISTCERTIFICATE_BLANK_EN.png'
import DOCTORCERTIFICATEFOR5DISEASES from '../../../../assets/branch/templaceDoc/DOCTORCERTIFICATEFOR5DISEASES.png'
import DOCTORCERTIFICATEFOR5DISEASES_BLANK from '../../../../assets/branch/templaceDoc/DOCTORCERTIFICATEFOR5DISEASES_BLANK.png'
import DOCTORCERTIFICATEFOR5DISEASES_EN from '../../../../assets/branch/templaceDoc/DOCTORCERTIFICATEFOR5DISEASES_EN.png'
import DRIVERLICENSEDOCTORCERTIFICATE from '../../../../assets/branch/templaceDoc/DRIVERLICENSEDOCTORCERTIFICATE.png'
import DRIVERLICENSEDOCTORCERTIFICATE_BLANK from '../../../../assets/branch/templaceDoc/DRIVERLICENSEDOCTORCERTIFICATE_BLANK.png'
import DRIVERLICENSEDOCTORCERTIFICATE_EN from '../../../../assets/branch/templaceDoc/DRIVERLICENSEDOCTORCERTIFICATE_EN.png'
import DENTISTCERTIFICATEA5 from '../../../../assets/branch/templaceDoc/DENTISTCERTIFICATEA5.png'
import DENTISTCERTIFICATEA5_BLANK from '../../../../assets/branch/templaceDoc/DENTISTCERTIFICATEA5_BLANK.png'
import DENTISTCERTIFICATEA5_EN from '../../../../assets/branch/templaceDoc/DENTISTCERTIFICATEA5_EN.png'

import MEDICALSERVICEUSAGEFORM from '../../../../assets/branch/templaceDoc/MEDICALSERVICEUSAGEFORM.png'
import SOCIALSECURITYCERTIFICATE from '../../../../assets/branch/templaceDoc/SOCIALSECURITYCERTIFICATE.png'
// import PDFINVOICE from '../../../../assets/branch/templaceDoc/PDFINVOICE.png'
import PDFINVOICE from '../../../../assets/branch/templaceDoc/PDFINVOICE.jpg'
import DRUGSTICKER from '../../../../assets/branch/templaceDoc/DRUGSTICKER.png'
import PDFINVOICEFEE from '../../../../assets/branch/templaceDoc/PDFINVOICEFEE.png'

import OUTSTANDINGPAYMENT from '../../../../assets/branch/templaceDoc/OUTSTANDINGPAYMENT.png'
import OUTSTANDINGPAYMENT_SAVE1 from '../../../../assets/branch/templaceDoc/OUTSTANDINGPAYMENT_SAVE1.png'
import OUTSTANDINGPAYMENT_SAVE2 from '../../../../assets/branch/templaceDoc/OUTSTANDINGPAYMENT_SAVE2.png'

import { useDispatch } from 'react-redux'
import ButtonCustom from 'component/Button/ButtonCustom'
import { showModal } from 'app/slice/modal.slice'
import ClinicApi from 'api/master/clinic.api'
import { swalSuccess, swalWarning } from 'component/Alert/Swal'

export enum TIME_APPOINTMENT {
  CLINIC = `CLINIC`,
  SCHEDULE = `SCHEDULE`,
}

export enum CALCULATE_ADVANCE {
  INCREASE = `INCREASE`,
  DECREASE = `DECREASE`,
}

const initStateErrorMessage = {
  BRANCH_CODE: '',
  IS_DUPLICATE_BRANCH_CODE: '',
  BRANCH_CODE_STRING_BASE: '',
  BRANCH_CODE_STRING_EMPTY: '',
  BRANCH_CODE_MAXIMUM_LENGTH: '',
  BRANCH_CODE_ANY_REQUIRED: '',

  BRANCH_NUMBER: '',
  IS_DUPLICATE_BRANCH_NUMBER: '',
  BRANCH_NUMBER_STRING_BASE: '',
  BRANCH_NUMBER_STRING_EMPTY: '',
  BRANCH_NUMBER_MAXIMUM_LENGTH: '',
  BRANCH_NUMBER_ANY_REQUIRED: '',

  BRANCH_NAME: '',
  IS_DUPLICATE_BRANCH_NAME: '',
  BRANCH_NAME_STRING_BASE: '',
  BRANCH_NAME_STRING_EMPTY: '',
  BRANCH_NAME_MAXIMUM_LENGTH: '',
  BRANCH_NAME_ANY_REQUIRED: '',

  BRANCH_NAME_EN: '',
  IS_DUPLICATE_BRANCH_NAME_EN: '',
  BRANCH_NAME_EN_STRING_BASE: '',
  BRANCH_NAME_EN_STRING_EMPTY: '',
  BRANCH_NAME_EN_MAXIMUM_LENGTH: '',
  BRANCH_NAME_EN_ANY_REQUIRED: '',

  BRANCH_LICENSE: '',
  BRANCH_TAX: '',
  BRANCH_PHONE: '',
  BRANCH_TEL: '',
  BRANCH_FAX: '',
  BRANCH_LINE: '',
  BRANCH_WEBSITE: '',
  BRANCH_EMAIL: '',
  BRANCH_FACEBOOK: '',
  BRANCH_INSTAGRAM: '',
  BRANCH_ADDRESS: '',
  BRANCH_ADDRESS_EN: '',

  EMPTY_BANK_ACCOUNT_NAME: '',
  EMPTY_BANK_ACCOUNT_NUMBER: ''
}

const initStateEditData = {
  branchId: '',
  branchCode: '',
  branchAddress: '',
  branchAddressEn: '',
  branchEmail: '',
  branchFacebook: '',
  branchFax: '',
  branchFullAddress: '',
  branchFullAddressEn: '',
  branchInstagram: '',
  branchLicense: '',
  branchLine: '',
  branchName: '',
  branchNameEn: '',
  hospitalName: '',
  hospitalCode: '',
  branchNumber: '',
  branchPhone: '',
  branchTax: '',
  branchTel: '',
  branchWebsite: '',
  countryId: '',
  defaultReceipt: '',
  defaultReceiptRemarkStatus: '',
  defaultReceiptPracticeStatus: '',
  districtId: '',
  latitude: '',
  longitude: '',
  postcode: '',
  provinceId: '',
  hasRecieptAddress: '',
  receiptAddress: '',
  receiptAddressEn: '',
  receiptCountryId: null,
  receiptProvinceId: '',
  receiptDistrictId: '',
  receiptSubDistrictId: '',
  receiptPostcode: '',
  receiptFullAddress: '',
  receiptFullAddressEn: '',
  opdCard: '',
  opdCardCover: '',
  opdCardBlank: '',
  opdCardHospital: '',
  dentistCertificate: '',
  dentistCertificateBlank: '',

  showCostEstimateReceipt: '',

  receiptA5Default: '',
  receiptA5Original: '',
  receiptA5Copy: '',
  receiptA5DefaultEn: '',
  receiptA5OriginalEn: '',
  receiptA5CopyEn: '',
  receiptA5DefaultLandscape: '',
  receiptA5OriginalLandscape: '',
  receiptA5CopyLandscape: '',
  receiptA5DfLscape: '',
  receiptA5DfOrgLscape: '',
  receiptA5DfCopyLscape: '',
  receiptA4Default: '',
  receiptA4Original: '',
  receiptA4Copy: '',
  receiptThermal: '',
  receiptThermalDf: '',

  receiptA5FixDfLscape: '',
  receiptA5FixDfOrgLscape: '',
  receiptA5FixDfCopyLscape: '',

  drugSticker: '',
  doctorCertificate: '',
  doctorCertificateFor5Diseases: '',
  driverLicenseDoctorCertificate: '',
  doctorCertificateA5: '',
  referralPatient: '',
  dentalReferral: '',
  opdCardForPrint: '',
  socialSecurityCertificate: '',
  medicalServiceUsageForm: '',

  pdfInvoice: '',
  pdfInvoiceTreatment: '',
  pdfInvoiceTreatmentCondition: '',

  outStandingPayment: '',

  status: '',
  subDistrictId: '',
  thermalFormat: '',
  receiptNumberPrefix: '',
  thermalRemark: '',
  timeIn: '',
  timeOut: '',
  updatedAt: '',
  updatedBy: '',
  createdAt: '',
  createdBy: '',
  bankAccounts: [{}],
  branchServicePoints: [{}, {}],
  officeTimes: [{}],
  branch_logo: [],
  branch_logo_thermal: [],
  branch_image: [],

  patientCodeQueue: '',
  patientCodeAppointment: '',
  patientCodeLab: '',
  stickerFormat: '',

  defaultDfReport: '',
  defaultDfCon: '',
  addressMap: '',

  opdCardBlank2: '',
  dentistCertificateEn: '',
  dentistCertificateBlankEn: '',
  doctorCertificateFor5DiseasesEn: '',
  doctorCertificateFor5DiseasesBlank: '',
  driverLicenseDoctorCertificateEn: '',
  driverLicenseDoctorCertificateBlank: '',
  doctorCertificateA5En: '',
  doctorCertificateA5Blank: '',

  opdCardFirst: '',

  formRegister: '',
  formAppointment: '',

  calculateAdvance: CALCULATE_ADVANCE.INCREASE,
  timeAppointment: TIME_APPOINTMENT.CLINIC,
  apptService: '',
  qrCodeReceiptDetail: '',
  qrCodeReceipt: [],

}

interface BranchBankInterface {
  bankId: number
  bankName: string
  bankAccountName: string
  bankAccountNumber: string
  hasTransfer: boolean | string
  hasCredit: boolean | string
  hasDebit: boolean | string
  disabledTransfer: boolean
  disabledCredit: boolean
  disabledDebit: boolean
}

interface BranchOfficeTimesInterface {
  dayOfWeek: string
  timeIn: string
  timeOut: string
  officeStatus: string
}
interface BranchSelectAddressInterface {
  district_id?: any
  district_name_en?: any
  district_name_th?: any
  latitude?: any
  longtitude?: any
  postcode?: any
  province_id?: any
  province_name_en?: any
  province_name_th?: any
  sub_district_id?: any
  sub_district_name_en?: any
  sub_district_name_th?: any
}
interface BanksInterface {
  bankId: any
  bankShortname: any
  bankName: any
  bankLogo: any
  hasTransfer: any
  hasCredit: any
  hasDebit: any
}
interface ServicePointInterface {
  servicePointId: any
  servicePointMenus: any
  servicePointName: any
  servicePointNameEn: any
}

export default function FormBranch() {
  const objImage = { fileId: null, base64: '', fileExtension: '', fileSize: '' }
  const objBranchTime = [
    {
      typetime: 'OPEN',
      timeStart: '00:00',
      timeEnd: '00:00',
      days: { sun: false, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false }
    }
  ]

  const objBranchServicePoint = [
    {
      servicePointId: 0
    }
  ]

  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [branchLogoData, setBranchLogoData] = useState<any>()
  const [branchLogo, setBranchLogo] = useState(objImage)
  const [branchImageData, setBranchImageData] = useState<any>()
  const [branchImage, setBranchImage] = useState(objImage)
  const [branchLogoThermalData, setBranchLogoThermalData] = useState<any>()
  const [branchLogoThermal, setBranchLogoThermal] = useState(objImage)
  const [loading, setLoading] = useState(false)
  const [initLocation, setInitLocation] = useState({})
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [editData, setEditData] = useState(initStateEditData)

  const [branchId, setBranchId] = useState(Number)

  const [branchTab, setBranchTab] = useState('INFORMATION')
  const [openPreviewReceipt, setOpenPreviewReceipt] = useState(false)
  const [openPreviewSticker, setOpenPreviewSticker] = useState(false)

  const [branchCode, setBranchCode] = useState('')
  const [branchNumber, setBranchNumber] = useState('')
  const [branchName, setBranchName] = useState('')
  const [branchNameEn, setBranchNameEn] = useState('')
  const [hospitalName, setHospitalName] = useState('')
  const [hospitalCode, setHospitalCode] = useState('')
  const [branchLicense, setBranchLicense] = useState('')
  const [branchTax, setBranchTax] = useState('')
  const [branchPhone, setBranchPhone] = useState('')
  const [branchTel, setBranchTel] = useState('')
  const [branchFax, setBranchFax] = useState('')
  const [branchLine, setBranchLine] = useState('')
  const [branchWebsite, setBranchWebsite] = useState('')
  const [branchEmail, setBranchEmail] = useState('')
  const [branchFacebook, setBranchFacebook] = useState('')
  const [branchInstagram, setBranchInstagram] = useState('')
  const [country, setCountry] = useState<any>()
  const [branchSelectAddress, setBranchSelectAddress] = useState<BranchSelectAddressInterface>()
  const [branchAddress, setBranchAddress] = useState('')
  const [branchAddressEn, setBranchAddressEn] = useState('')

  const [receiptCountry, setRecieptCountry] = useState<any>()
  const [receiptSelectAddress, setRecieptSelectAddress] = useState<BranchSelectAddressInterface>()
  const [receiptAddress, setRecieptAddress] = useState('')
  const [receiptAddressEn, setRecieptAddressEn] = useState('')
  const [addressRecieptSameBranch, setAddressRecieptSameBranch] = useState(true)
  const [defaultReceipt, setDefaultReceipt] = useState('THERMAL')
  const [defaultReceiptRemarkStatus, setDefaultReceiptRemarkStatus] = useState<any>('1')
  const [defaultReceiptPracticeStatus, setDefaultReceiptPracticeStatus] = useState<any>('1')
  const [thermalFormat, setThermalFormat] = useState('FORMAT_1')
  const [receiptNumberPrefix, setReceiptNumberPrefix] = useState('CN')
  const [thermalRemark, setThermalRemark] = useState('')
  const [opdCard, setOpdCard] = useState(true)
  const [opdCardCover, setOpdCardCover] = useState(true)
  const [opdCardBlank, setOpdCardBlank] = useState(true)
  const [opdCardFirst, setOpdCardFirst] = useState(true)
  const [opdCardCoverV1, setOpdCardCoverV1] = useState(true)
  const [opdCardHospital, setOpdCardHospital] = useState(true)
  const [dentistCertificate, setDentistCertificate] = useState(true)
  const [dentistCertificateBlank, setDentistCertificateBlank] = useState(true)

  const [showCostEstimateReceipt, setShowCostEstimateReceipt] = useState('1')

  const [opdCardBlank2, setOpdCardBlank2] = useState<boolean>(true)
  const [dentistCertificateEn, setDentistCertificateEn] = useState<boolean>(true)
  const [dentistCertificateBlankEn, setDentistCertificateBlankEn] = useState<boolean>(true)
  const [doctorCertificateFor5DiseasesEn, setDoctorCertificateFor5DiseasesEn] = useState(true)
  const [doctorCertificateFor5DiseasesBlank, setDoctorCertificateFor5DiseasesBlank] = useState(true)
  const [driverLicenseDoctorCertificateEn, setDriverLicenseDoctorCertificateEn] = useState(true)
  const [driverLicenseDoctorCertificateBlank, setDriverLicenseDoctorCertificateBlank] = useState(true)
  const [doctorCertificateA5En, setDoctorCertificateA5En] = useState(true)
  const [doctorCertificateA5Blank, setDoctorCertificateA5Blank] = useState(true)

  const [receiptA5Default, setReceiptA5Default] = useState(true)
  const [receiptA5Original, setReceiptA5Original] = useState(true)
  const [receiptA5Copy, setReceiptA5Copy] = useState(true)
  const [receiptA5DefaultEn, setReceiptA5DefaultEn] = useState(true)
  const [receiptA5OriginalEn, setReceiptA5OriginalEn] = useState(true)
  const [receiptA5CopyEn, setReceiptA5CopyEn] = useState(true)
  const [receiptA5DefaultLandscape, setReceiptA5DefaultLandscape] = useState(true)
  const [receiptA5OriginalLandscape, setReceiptA5OriginalLandscape] = useState(true)
  const [receiptA5CopyLandscape, setReceiptA5CopyLandscape] = useState(true)
  const [receiptA5DfLscape, setReceiptA5DfLscape] = useState(true)
  const [receiptA5DfOrgLscape, setReceiptA5DfOrgLscape] = useState(true)
  const [receiptA5DfCopyLscape, setReceiptA5DfCopyLscape] = useState(true)
  const [receiptA4Default, setReceiptA4Default] = useState(true)
  const [receiptA4Original, setReceiptA4Original] = useState(true)
  const [receiptA4Copy, setReceiptA4Copy] = useState(true)
  const [receiptThermal, setReceiptThermal] = useState(true)
  const [receiptThermalDf, setReceiptThermalDf] = useState(true)

  const [receiptA5FixDfLscape, setReceiptA5FixDfLscape] = useState(true)
  const [receiptA5FixDfOrgLscape, setReceiptA5FixDfOrgLscape] = useState(true)
  const [receiptA5FixDfCopyLscape, setReceiptA5FixDfCopyLscape] = useState(true)

  const [drugSticker, setDrugSticker] = useState(true)
  const [doctorCertificate, setDoctorCertificate] = useState(true)
  const [doctorCertificateFor5Diseases, setDoctorCertificateFor5Diseases] = useState(true)
  const [driverLicenseDoctorCertificate, setDriverLicenseDoctorCertificate] = useState(true)
  const [doctorCertificateA5, setDoctorCertificateA5] = useState(true)

  const [pdfInvoice, setPdfInvoice] = useState(true)
  const [pdfInvoiceTreatment, setPdfInvoiceTreatment] = useState(true)
  const [pdfInvoiceTreatmentCondition, setPdfInvoiceTreatmentCondition] = useState<string>('0')

  const [outStandingPayment, setOutStandingPayment] = useState<boolean>(true)

  const [referralPatient, setReferralPatient] = useState(true)
  const [dentalReferral, setDentalReferral] = useState(true)
  const [opdCardForPrint, setOpdCardForPrint] = useState(true)
  const [socialSecurityCertificate, setSocialSecurityCertificate] = useState(true)
  const [medicalServiceUsageForm, setMedicalServiceUsageForm] = useState(true)

  const [branchTimes, setBranchTimes] = useState([...objBranchTime])
  const [branchServicePoints, setBranchServicePoints] = useState([...objBranchServicePoint])

  const [banks, setBanks] = useState<BanksInterface[]>([])
  const [branchBanks, setBranchBanks] = useState<BranchBankInterface[]>([])
  const [servicePoints, setServicePoint] = useState<ServicePointInterface[]>([])

  const [countryOptions, setCountryOptions] = useState<any[]>([])

  const [patientCodeAll, setPatientCodeAll] = useState<string>('1')
  const [patientCodeQueue, setSettingQueue] = useState<any>('1')
  const [patientCodeAppointment, setSettingAppointment] = useState<any>('1')
  const [patientCodeLab, setSettingLab] = useState<any>('1')
  const [stickerFormat, setStickerFormat] = useState('FORMAT_1')
  const [snType, setSnType] = useState(false)
  const [defaultDfReport, setDefaultDfReport] = useState<string>('1')
  const [defaultDfCon, setDefaultDfCon] = useState<string>('0')
  const [timeAppointment, setTimeAppointment] = useState<TIME_APPOINTMENT.CLINIC | TIME_APPOINTMENT.SCHEDULE>(TIME_APPOINTMENT.CLINIC)
  const [calculateAdvance, setCalculateAdvance] = useState<CALCULATE_ADVANCE.INCREASE | CALCULATE_ADVANCE.DECREASE>(CALCULATE_ADVANCE.INCREASE)
  const [apptService, setApptService] = useState<string>("1")
  const [qrCodeReceiptDetail, setQRCodeReceiptDetail] = useState<string>('')
  const [qrCodeReceipt, setQRCodeReceipt] = useState<any>(objImage)
  const [imgQRData, setImgQRData] = useState<string>("")


  const [formRegister, setFormRegister] = useState([])
  const [formAppointment, setFormAppointment] = useState([])

  const [addressMap, setAddressMap] = useState<string>('')
  const [scrollMap, setScrollMap] = useState<boolean>(false)
  const [loadingBackup, setLoadingBackup] = useState<boolean>(false)

  const [openCollapseSticker, setOpenCollapseSticker] = useState<boolean>(true)

  const [modalImg, setModalImg] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<ModalExamDocTitle>({ title: t('BRANCH.INPUT.PDFINVOICEFEE'), page: ['TREATMENT'], example: PDFINVOICEFEE })

  useEffect(() => {
    setSettingQueue(patientCodeAll)
    setSettingAppointment(patientCodeAll)
    setSettingLab(patientCodeAll)
  }, [patientCodeAll])

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const dateOfWeek = [`Mon`, `Tue`, `Wed`, `Thu`, `Fri`, `Sat`, `Sun`]
  const loadBranches = useCallback(() => {
    setInitLocation(location)
    if (!banks.length) BanksApi.findAll({ pageLimit: 50, status: 'ACTIVE' }).then(({ data }) => data && setBanks(data))
    if (!servicePoints.length) ServicePointApi.findAll({ pageLimit: 50, status: 'ACTIVE' }).then(({ data }) => data && setServicePoint(data))
    const { state }: any = initLocation
    setEditData(state)
    if (editData?.branchId) onEdit()
  }, [location, banks, servicePoints, initLocation])

  const loadCountries = () => {
    ComponentApi.findAllCountries().then(({ data }) => data && setCountryOptions(data))
  }

  useEffect(() => {
    loadCountries()
  }, [])

  useEffect(() => {
    loadBranches()
  }, [loadBranches])

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const onEdit = () => {
    const {
      branchId,
      branchCode,
      branchAddress,
      branchAddressEn,
      branchEmail,
      branchFacebook,
      branchFax,
      branchInstagram,
      branchLicense,
      branchLine,
      branchName,
      branchNameEn,
      hospitalName,
      hospitalCode,
      branchNumber,
      branchPhone,
      branchTax,
      branchTel,
      branchWebsite,
      countryId,
      districtId,
      postcode,
      provinceId,
      hasRecieptAddress,
      receiptAddress,
      receiptAddressEn,
      receiptCountryId,
      receiptProvinceId,
      receiptDistrictId,
      receiptSubDistrictId,
      receiptPostcode,
      subDistrictId,
      defaultReceipt,
      defaultReceiptRemarkStatus,
      defaultReceiptPracticeStatus,
      thermalFormat,
      receiptNumberPrefix,
      thermalRemark,
      branchServicePoints,
      officeTimes,
      bankAccounts,
      branch_logo,
      branch_image,
      branch_logo_thermal,
      opdCard,
      opdCardCover,
      opdCardBlank,
      opdCardHospital,
      dentistCertificate,
      dentistCertificateBlank,

      showCostEstimateReceipt,

      receiptA5Default,
      receiptA5Original,
      receiptA5Copy,
      receiptA5DefaultEn,
      receiptA5OriginalEn,
      receiptA5CopyEn,
      receiptA5DefaultLandscape,
      receiptA5OriginalLandscape,
      receiptA5CopyLandscape,
      receiptA5DfLscape,
      receiptA5DfOrgLscape,
      receiptA5DfCopyLscape,
      receiptA4Default,
      receiptA4Original,
      receiptA4Copy,
      receiptThermal,
      receiptThermalDf,

      receiptA5FixDfLscape,
      receiptA5FixDfOrgLscape,
      receiptA5FixDfCopyLscape,

      drugSticker,
      doctorCertificate,
      doctorCertificateFor5Diseases,
      driverLicenseDoctorCertificate,
      doctorCertificateA5,

      pdfInvoice,
      pdfInvoiceTreatment,
      pdfInvoiceTreatmentCondition,

      outStandingPayment,

      referralPatient,
      dentalReferral,
      opdCardForPrint,
      socialSecurityCertificate,
      medicalServiceUsageForm,

      patientCodeQueue,
      patientCodeAppointment,
      patientCodeLab,
      stickerFormat,

      defaultDfReport,
      defaultDfCon,
      addressMap,

      opdCardBlank2,
      dentistCertificateEn,
      dentistCertificateBlankEn,
      doctorCertificateFor5DiseasesEn,
      doctorCertificateFor5DiseasesBlank,
      driverLicenseDoctorCertificateEn,
      driverLicenseDoctorCertificateBlank,
      doctorCertificateA5En,
      doctorCertificateA5Blank,

      opdCardFirst,

      formRegister,
      timeAppointment,
      apptService,
      calculateAdvance,
      qrCodeReceiptDetail,
      qrCodeReceipt

    } = editData
    setBranchId(Number(branchId))
    setBranchCode(branchCode)
    setBranchNumber(branchNumber)
    setBranchName(branchName)
    setBranchNameEn(branchNameEn)
    setHospitalName(hospitalName)
    setHospitalCode(hospitalCode)
    setBranchLicense(branchLicense)
    setBranchTax(branchTax)
    setBranchPhone(branchPhone)
    setBranchTel(branchTel)
    setBranchFax(branchFax)
    setBranchLine(branchLine)
    setBranchWebsite(branchWebsite)
    setBranchEmail(branchEmail)
    setBranchFacebook(branchFacebook)
    setBranchInstagram(branchInstagram)
    setBranchAddress(branchAddress)
    setBranchAddressEn(branchAddressEn)
    setDefaultReceipt(defaultReceipt)
    setDefaultReceiptRemarkStatus(defaultReceiptRemarkStatus)
    setDefaultReceiptPracticeStatus(defaultReceiptPracticeStatus)
    setThermalFormat(thermalFormat)
    setReceiptNumberPrefix(receiptNumberPrefix)
    setThermalRemark(thermalRemark)
    setAddressRecieptSameBranch(hasRecieptAddress === '1')
    setRecieptAddress(receiptAddress)
    setRecieptAddressEn(receiptAddressEn)
    setCountry(countryId)
    setRecieptCountry(receiptCountryId)
    setOpdCard(opdCard === '1')
    setOpdCardCover(opdCardCover === '1')
    setOpdCardBlank(opdCardBlank === '1')
    setOpdCardHospital(opdCardHospital === '1')
    setDentistCertificate(dentistCertificate === '1')
    setDentistCertificateBlank(dentistCertificateBlank === '1')

    setShowCostEstimateReceipt(showCostEstimateReceipt)

    setReceiptA5Default(true)
    setReceiptA5Original(receiptA5Original === '1')
    setReceiptA5Copy(receiptA5Copy === '1')
    setReceiptA5DefaultEn(receiptA5DefaultEn === '1')
    setReceiptA5OriginalEn(receiptA5OriginalEn === '1')
    setReceiptA5CopyEn(receiptA5CopyEn === '1')
    setReceiptA5DefaultLandscape(receiptA5DefaultLandscape === '1')
    setReceiptA5OriginalLandscape(receiptA5OriginalLandscape === '1')
    setReceiptA5CopyLandscape(receiptA5CopyLandscape === '1')
    setReceiptA5DfLscape(receiptA5DfLscape === '1')
    setReceiptA5DfOrgLscape(receiptA5DfOrgLscape === '1')
    setReceiptA5DfCopyLscape(receiptA5DfCopyLscape === '1')
    setReceiptA4Default(true)
    setReceiptA4Original(receiptA4Original === '1')
    setReceiptA4Copy(receiptA4Copy === '1')
    setReceiptThermal(true)
    setReceiptThermalDf(receiptThermalDf === '1')

    setReceiptA5FixDfLscape(receiptA5FixDfLscape === '1')
    setReceiptA5FixDfOrgLscape(receiptA5FixDfOrgLscape === '1')
    setReceiptA5FixDfCopyLscape(receiptA5FixDfCopyLscape === '1')

    setDrugSticker(drugSticker === '1')
    setDoctorCertificate(doctorCertificate === '1')
    setDoctorCertificateFor5Diseases(doctorCertificateFor5Diseases === '1')
    setDriverLicenseDoctorCertificate(driverLicenseDoctorCertificate === '1')
    setDoctorCertificateA5(doctorCertificateA5 === '1')

    setPdfInvoice(pdfInvoice === '1')
    setPdfInvoiceTreatment(pdfInvoiceTreatment === '1')
    setPdfInvoiceTreatmentCondition(pdfInvoiceTreatmentCondition || '0')

    setOutStandingPayment(outStandingPayment === '1')

    setReferralPatient(referralPatient === '1')
    setDentalReferral(dentalReferral === '1')
    setOpdCardForPrint(opdCardForPrint === '1')
    setSocialSecurityCertificate(socialSecurityCertificate === '1')
    setMedicalServiceUsageForm(medicalServiceUsageForm === '1')

    setPatientCodeAll(patientCodeQueue)
    // setSettingQueue(patientCodeQueue)
    // setSettingAppointment(patientCodeAppointment)
    // setSettingLab(patientCodeLab)

    setStickerFormat(stickerFormat)

    setDefaultDfReport(defaultDfReport)
    setDefaultDfCon(defaultDfCon)
    setAddressMap(addressMap)

    setOpdCardBlank2(opdCardBlank2 === '1')
    setDentistCertificateEn(dentistCertificateEn === '1')
    setDentistCertificateBlankEn(dentistCertificateBlankEn === '1')
    setDoctorCertificateFor5DiseasesEn(doctorCertificateFor5DiseasesEn === '1')
    setDoctorCertificateFor5DiseasesBlank(doctorCertificateFor5DiseasesBlank === '1')
    setDriverLicenseDoctorCertificateEn(driverLicenseDoctorCertificateEn === '1')
    setDriverLicenseDoctorCertificateBlank(driverLicenseDoctorCertificateBlank === '1')
    setDoctorCertificateA5En(doctorCertificateA5En === '1')
    setDoctorCertificateA5Blank(doctorCertificateA5Blank === '1')
    setTimeAppointment(timeAppointment)
    setApptService(apptService || "1")
    setCalculateAdvance(calculateAdvance)
    setQRCodeReceiptDetail(qrCodeReceiptDetail || '')
    setQRCodeReceipt(qrCodeReceipt || objImage)

    setOpdCardFirst(opdCardFirst === '1')

    if (qrCodeReceipt) {
      const { fileId, filePath, fileName }: any = qrCodeReceipt
      setImgQRData(`${fileUrl}/${filePath}/${fileName}`)
    }


    const form = JSON.parse(formRegister)
    setFormRegister(form)
    const formAp = editData.formAppointment ? JSON.parse(editData.formAppointment) : []
    setFormAppointment(formAp)


    if (subDistrictId) {
      setBranchSelectAddress({ province_id: provinceId, district_id: districtId, sub_district_id: subDistrictId, postcode: postcode })
    }
    if (receiptSubDistrictId) {
      setRecieptSelectAddress({ province_id: receiptProvinceId, district_id: receiptDistrictId, sub_district_id: receiptSubDistrictId, postcode: receiptPostcode })
    }
    if (branchServicePoints.length) {
      const tempBSP = branchServicePoints.map((bsp: any) => {
        return {
          servicePointId: bsp.servicePointId
        }
      })
      setBranchServicePoints(tempBSP)
    }
    if (officeTimes.length) {
      const tempOfficeTime: any = []
      for (const officeTime in officeTimes) {
        if (Object.prototype.hasOwnProperty.call(officeTimes, officeTime)) {
          const ot: any = officeTimes[officeTime]
          if (!tempOfficeTime.find((tt: any) => tt.timeStart === ot.timeIn && tt.timeEnd === ot.timeOut)) {
            const search = officeTimes.filter((officeT: any) => officeT.timeIn === ot.timeIn && officeT.timeOut === ot.timeOut && officeT.timeType === ot.timeType)
            tempOfficeTime.push({
              timeStart: ot.timeIn,
              timeEnd: ot.timeOut,
              typetime: ot.officeStatus,
              days: {
                sun: search.find((officeT: any) => officeT.dayOfWeek === 'Sun') ? true : false,
                mon: search.find((officeT: any) => officeT.dayOfWeek === 'Mon') ? true : false,
                tue: search.find((officeT: any) => officeT.dayOfWeek === 'Tue') ? true : false,
                wed: search.find((officeT: any) => officeT.dayOfWeek === 'Wed') ? true : false,
                thu: search.find((officeT: any) => officeT.dayOfWeek === 'Thu') ? true : false,
                fri: search.find((officeT: any) => officeT.dayOfWeek === 'Fri') ? true : false,
                sat: search.find((officeT: any) => officeT.dayOfWeek === 'Sat') ? true : false
              }
            })
          }
        }
      }
      setBranchTimes(tempOfficeTime)
    }
    if (bankAccounts.length) {
      let tempBranchBanks: any = []
      _.map(bankAccounts, (ba: any) => {
        if (
          _.get(
            banks.find((b: any) => b.bankId === ba.bankId),
            'bankName'
          )
        ) {
          tempBranchBanks = [
            ...tempBranchBanks,
            {
              bankId: ba.bankId,
              bankName:
                _.get(
                  banks.find((b: any) => b.bankId === ba.bankId),
                  'bankName'
                ) || '',
              bankAccountName: ba.bankAccountName,
              bankAccountNumber: ba.bankAccountNumber,
              hasTransfer: banks.find((b: any) => b.bankId === ba.bankId && b.hasTransfer === 'SUPPORT')
                ? ba.hasTransfer
                : _.get(
                  banks.find((b: any) => b.bankId === ba.bankId),
                  'hasTransfer'
                ),
              hasCredit: banks.find((b: any) => b.bankId === ba.bankId && b.hasCredit === 'SUPPORT')
                ? ba.hasCredit
                : _.get(
                  banks.find((b: any) => b.bankId === ba.bankId),
                  'hasCredit'
                ),
              hasDebit: banks.find((b: any) => b.bankId === ba.bankId && b.hasDebit === 'SUPPORT')
                ? ba.hasDebit
                : _.get(
                  banks.find((b: any) => b.bankId === ba.bankId),
                  'hasCredit'
                ),
              disabledTransfer: banks.find((b: any) => b.bankId === ba.bankId && b.hasTransfer === 'SUPPORT') ? false : true,
              disabledCredit: banks.find((b: any) => b.bankId === ba.bankId && b.hasCredit === 'SUPPORT') ? false : true,
              disabledDebit: banks.find((b: any) => b.bankId === ba.bankId && b.hasDebit === 'SUPPORT') ? false : true
            }
          ]
        }
      })
      setBranchBanks(tempBranchBanks)
    }
    if (branch_logo) {
      const { fileId, filePath, fileName }: any = branch_logo
      setBranchLogoData(`${fileUrl}/${filePath}/${fileName}`)
      setBranchLogo({ fileId: fileId, base64: '', fileExtension: '', fileSize: '' })
    }
    if (branch_image) {
      const { fileId, filePath, fileName }: any = branch_image
      setBranchImageData(`${fileUrl}/${filePath}/${fileName}`)
      setBranchImage({ fileId: fileId, base64: '', fileExtension: '', fileSize: '' })
    }
    if (branch_logo_thermal) {
      const { fileId, filePath, fileName }: any = branch_logo_thermal
      setBranchLogoThermalData(`${fileUrl}/${filePath}/${fileName}`)
      setBranchLogoThermal({ fileId: fileId, base64: '', fileExtension: '', fileSize: '' })
    }
  }

  const submitUpdate = () => {
    if (!branchCode || !branchNumber || !branchName || !branchPhone || _.some(branchBanks, (bank, index) => !bank.bankAccountName) || _.some(branchBanks, (bank, index) => !bank.bankAccountNumber)) {
      return setErrorMessage({
        ...errorMessage,
        BRANCH_CODE: !branchCode ? t(`BRANCH.MESSAGE.ERROR_MESSAGE.BRANCH_CODE`) : '',
        BRANCH_NUMBER: !branchNumber ? t(`BRANCH.MESSAGE.ERROR_MESSAGE.BRANCH_NUMBER`) : '',
        BRANCH_NAME: !branchName ? t(`BRANCH.MESSAGE.ERROR_MESSAGE.BRANCH_NAME`) : '',
        BRANCH_PHONE: !branchPhone ? t(`BRANCH.MESSAGE.ERROR_MESSAGE.BRANCH_PHONE`) : '',
        EMPTY_BANK_ACCOUNT_NAME: _.some(branchBanks, (bank, index) => !bank.bankAccountName) ? t(`BRANCH.MESSAGE.ERROR_MESSAGE.EMPTY_BANK_ACCOUNT_NAME`) : '',
        EMPTY_BANK_ACCOUNT_NUMBER: _.some(branchBanks, (bank, index) => !bank.bankAccountNumber) ? t(`BRANCH.MESSAGE.ERROR_MESSAGE.EMPTY_BANK_ACCOUNT_NUMBER`) : ''
      })
    }

    let branchServicePoint: any = []
    _.map(servicePoints, (i, index) => {
      if (_.some(branchServicePoints, (j) => j.servicePointId === i.servicePointId)) branchServicePoint = [...branchServicePoint, i]
    })

    if (_.some(branchServicePoint, (service, index) => service.pointOfService === 'PAYMENT') === false) {
      setBranchTab('SERVICE_POINT')
      return notiError(t('BRANCH.MESSAGE.NOT_FOUND_PAYMENT'))
    }

    const sun = branchTimes.filter((bt) => Boolean(bt.days.sun) === true)
    const mon = branchTimes.filter((bt) => Boolean(bt.days.mon) === true)
    const tue = branchTimes.filter((bt) => Boolean(bt.days.tue) === true)
    const wed = branchTimes.filter((bt) => Boolean(bt.days.wed) === true)
    const thu = branchTimes.filter((bt) => Boolean(bt.days.thu) === true)
    const fri = branchTimes.filter((bt) => Boolean(bt.days.fri) === true)
    const sat = branchTimes.filter((bt) => Boolean(bt.days.sat) === true)

    if (_.isEmpty(sun) || _.isEmpty(mon) || _.isEmpty(tue) || _.isEmpty(wed) || _.isEmpty(thu) || _.isEmpty(fri) || _.isEmpty(sat)) {
      notiError(t('BRANCH.MESSAGE.ERROR_MESSAGE.DATE_NOT_COMPLETE'))
      setBranchTab('WORK_TIME')
      return false
    }

    if (_.size(sun) > 1 || _.size(mon) > 1 || _.size(tue) > 1 || _.size(wed) > 1 || _.size(thu) > 1 || _.size(fri) > 1 || _.size(sat) > 1) {
      notiError(t('BRANCH.MESSAGE.ERROR_MESSAGE.DATE_ALREADY_SELECT'))
      setBranchTab('WORK_TIME')
      return false
    }

    const branchOfficeTimes: BranchOfficeTimesInterface[] = [
      { dayOfWeek: 'sun', timeIn: sun[0].timeStart, timeOut: sun[0].timeEnd, officeStatus: sun[0].typetime },
      { dayOfWeek: 'mon', timeIn: mon[0].timeStart, timeOut: mon[0].timeEnd, officeStatus: mon[0].typetime },
      { dayOfWeek: 'tue', timeIn: tue[0].timeStart, timeOut: tue[0].timeEnd, officeStatus: tue[0].typetime },
      { dayOfWeek: 'wed', timeIn: wed[0].timeStart, timeOut: wed[0].timeEnd, officeStatus: wed[0].typetime },
      { dayOfWeek: 'thu', timeIn: thu[0].timeStart, timeOut: thu[0].timeEnd, officeStatus: thu[0].typetime },
      { dayOfWeek: 'fri', timeIn: fri[0].timeStart, timeOut: fri[0].timeEnd, officeStatus: fri[0].typetime },
      { dayOfWeek: 'sat', timeIn: sat[0].timeStart, timeOut: sat[0].timeEnd, officeStatus: sat[0].typetime }
    ]

    const objUpdate = {
      branchCode: branchCode,
      branchNumber: branchNumber,
      branchName: branchName,
      branchNameEn: branchNameEn,
      hospitalName: hospitalName,
      hospitalCode: hospitalCode,
      branchEmail: branchEmail,
      branchTel: branchTel,
      branchPhone: branchPhone,
      branchFax: branchFax,
      branchTax: branchTax,
      branchLicense: branchLicense,
      branchFacebook: branchFacebook,
      branchLine: branchLine,
      branchInstagram: branchInstagram,
      branchWebsite: branchWebsite,
      defaultReceipt: defaultReceipt,
      defaultReceiptRemarkStatus: defaultReceiptRemarkStatus,
      defaultReceiptPracticeStatus: defaultReceiptPracticeStatus,
      thermalFormat: thermalFormat,
      receiptNumberPrefix: receiptNumberPrefix,
      thermalRemark: thermalRemark,
      branchAddress: branchAddress,
      branchAddressEn: branchAddressEn,
      countryId: country,
      provinceId: branchSelectAddress?.province_id,
      districtId: branchSelectAddress?.district_id,
      subDistrictId: branchSelectAddress?.sub_district_id,
      postcode: String(branchSelectAddress?.postcode),
      branchFullAddress: '',
      branchFullAddressEn: '',
      latitude: branchSelectAddress?.latitude,
      longitude: branchSelectAddress?.longtitude,
      opdCard: opdCard ? '1' : '0',
      opdCardCover: opdCardCover ? '1' : '0',
      opdCardBlank: opdCardBlank ? '1' : '0',
      opdCardHospital: opdCardHospital ? '1' : '0',
      dentistCertificate: dentistCertificate ? '1' : '0',

      showCostEstimateReceipt: showCostEstimateReceipt,

      receiptA5Default: receiptA5Default ? '1' : '0',
      receiptA5Original: receiptA5Original ? '1' : '0',
      receiptA5Copy: receiptA5Copy ? '1' : '0',
      receiptA5DefaultEn: receiptA5DefaultEn ? '1' : '0',
      receiptA5OriginalEn: receiptA5OriginalEn ? '1' : '0',
      receiptA5CopyEn: receiptA5CopyEn ? '1' : '0',
      receiptA5DefaultLandscape: receiptA5DefaultLandscape ? '1' : '0',
      receiptA5OriginalLandscape: receiptA5OriginalLandscape ? '1' : '0',
      receiptA5CopyLandscape: receiptA5CopyLandscape ? '1' : '0',
      receiptA5DfLscape: receiptA5DfLscape ? "1" : "0",
      receiptA5DfOrgLscape: receiptA5DfOrgLscape ? "1" : "0",
      receiptA5DfCopyLscape: receiptA5DfCopyLscape ? "1" : "0",
      receiptA4Default: receiptA4Default ? '1' : '0',
      receiptA4Original: receiptA4Original ? '1' : '0',
      receiptA4Copy: receiptA4Copy ? '1' : '0',
      receiptThermal: receiptThermal ? '1' : '0',
      receiptThermalDf: receiptThermalDf ? '1' : '0',

      receiptA5FixDfLscape: receiptA5FixDfLscape ? '1' : '0',
      receiptA5FixDfOrgLscape: receiptA5FixDfOrgLscape ? '1' : '0',
      receiptA5FixDfCopyLscape: receiptA5FixDfCopyLscape ? '1' : '0',

      drugSticker: drugSticker ? '1' : '0',
      doctorCertificate: doctorCertificate ? '1' : '0',
      doctorCertificateFor5Diseases: doctorCertificateFor5Diseases ? '1' : '0',
      driverLicenseDoctorCertificate: driverLicenseDoctorCertificate ? '1' : '0',
      doctorCertificateA5: doctorCertificateA5 ? '1' : '0',

      pdfInvoice: pdfInvoice ? '1' : '0',
      pdfInvoiceTreatment: pdfInvoiceTreatment ? '1' : '0',
      pdfInvoiceTreatmentCondition: pdfInvoiceTreatmentCondition,

      outStandingPayment: outStandingPayment ? '1' : '0',

      referralPatient: referralPatient ? '1' : '0',
      dentalReferral: dentalReferral ? '1' : '0',
      opdCardForPrint: opdCardForPrint ? '1' : '0',
      socialSecurityCertificate: socialSecurityCertificate ? '1' : '0',
      medicalServiceUsageForm: medicalServiceUsageForm ? '1' : '0',

      dentistCertificateBlank: dentistCertificateBlank ? '1' : '0',
      hasRecieptAddress: addressRecieptSameBranch ? '1' : '0',
      receiptAddress: !addressRecieptSameBranch ? receiptAddress : '',
      receiptAddressEn: !addressRecieptSameBranch ? receiptAddressEn : '',
      receiptCountryId: !addressRecieptSameBranch ? receiptCountry : null,
      receiptProvinceId: !addressRecieptSameBranch ? receiptSelectAddress?.province_id : null,
      receiptDistrictId: !addressRecieptSameBranch ? receiptSelectAddress?.district_id : null,
      receiptSubDistrictId: !addressRecieptSameBranch ? receiptSelectAddress?.sub_district_id : null,
      receiptPostcode: !addressRecieptSameBranch ? String(receiptSelectAddress?.postcode) : null,
      receiptFullAddress: null,
      receiptFullAddressEn: null,
      timeIn: '',
      timeOut: '',
      status: 'ACTIVE',
      branchServicePoints: branchServicePoints,
      branchOfficeTimes: branchOfficeTimes,
      branchBankAccounts: _.map(branchBanks, (bBank: any) => {
        if (bBank.bankId) {
          return {
            bankId: bBank.bankId,
            bankAccountName: bBank.bankAccountName,
            bankAccountNumber: bBank.bankAccountNumber,
            hasTransfer: bBank.hasTransfer === 'SUPPORT' ? 'SUPPORT' : 'NOT_SUPPORT',
            hasCredit: bBank.hasCredit === 'SUPPORT' ? 'SUPPORT' : 'NOT_SUPPORT',
            hasDebit: bBank.hasDebit === 'SUPPORT' ? 'SUPPORT' : 'NOT_SUPPORT'
          }
        }
      }),

      patientCodeQueue: patientCodeQueue,
      patientCodeAppointment: patientCodeAppointment,
      patientCodeLab: patientCodeLab,
      stickerFormat: stickerFormat,

      defaultDfReport: defaultDfReport,
      defaultDfCon: defaultDfCon,
      addressMap: addressMap,

      opdCardBlank2: opdCardBlank2 ? '1' : '0',
      dentistCertificateEn: dentistCertificateEn ? '1' : '0',
      dentistCertificateBlankEn: dentistCertificateBlankEn ? '1' : '0',
      doctorCertificateFor5DiseasesEn: doctorCertificateFor5DiseasesEn ? '1' : '0',
      doctorCertificateFor5DiseasesBlank: doctorCertificateFor5DiseasesBlank ? '1' : '0',
      driverLicenseDoctorCertificateEn: driverLicenseDoctorCertificateEn ? '1' : '0',
      driverLicenseDoctorCertificateBlank: driverLicenseDoctorCertificateBlank ? '1' : '0',
      doctorCertificateA5En: doctorCertificateA5En ? '1' : '0',
      doctorCertificateA5Blank: doctorCertificateA5Blank ? '1' : '0',
      formRegister: JSON.stringify(formRegister),
      formAppointment: JSON.stringify(formAppointment),
      timeAppointment,
      apptService,
      calculateAdvance,
      qrCodeReceiptDetail,
      qrCodeReceipt,

      opdCardFirst: opdCardFirst ? '1' : '0'
    }

    const objValidate = JSON.parse(JSON.stringify(objUpdate, (key, value) => (value === undefined || value === 'undefined' ? '' : value)))
    objValidate.branch_logo = branchLogo
    objValidate.branch_image = branchImage
    objValidate.branchLogoThermal = branchLogoThermal

    BranchApi.update(branchId, objValidate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`BRANCH.MESSAGE.SUCCESS.UPDATE`), '', null, () => {
            return
          })
          history.push(routeName.branch)
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`BRANCH.MESSAGE.${err.message}`) } })
        }
      })
      .catch((e) => {
        const err: any = e?.response?.data
        setErrorMessage({ ...errorMessage, ...{ [err?.message]: t(`BRANCH.MESSAGE.${err?.message}`) } })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const uploadBranchLogo = (files: any) => {
    if (files[0]) {
      /**
       * files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file = files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        /** reader.result is base64 */
        const base64 = reader.result.split(',')
        setBranchLogoData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setBranchLogo(detailImage)
    }
  }
  const uploadBranchLogoThermal = (files: any) => {
    if (files[0]) {
      /**
       * files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file = files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        /** reader.result is base64 */
        const base64 = reader.result.split(',')
        setBranchLogoThermalData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setBranchLogoThermal(detailImage)
    }
  }

  const uploadBranchImage = (files: any) => {
    if (files[0]) {
      /**
       * files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file = files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        /** reader.result is base64 */
        const base64 = reader.result.split(',')
        setBranchImageData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setBranchImage(detailImage)
    }
  }

  const uploadQRCode = (files: any) => {
    if (files[0]) {
      const file = files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        const base64 = reader.result.split(',')
        setImgQRData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setQRCodeReceipt(detailImage)
    }
  }


  const handleChangCheckAddress = (event: any) => {
    setAddressRecieptSameBranch(!addressRecieptSameBranch)
  }

  const handleChangeBranchTimeType = (value: string, index: number) => {
    const branchTimeTemp: any = [...branchTimes]
    branchTimeTemp[index].typetime = value
    setBranchTimes(branchTimeTemp)
  }

  const handleChangeBranchTimeDate = (day: string, index: number) => {
    const branchTimeTemp: any = [...branchTimes]
    branchTimeTemp[index].days[day] = !branchTimeTemp[index].days[day]
    setBranchTimes(branchTimeTemp)
  }

  const handleChangeBranchTime = (times: any, index: number) => {
    const branchTimeTemp: any = [...branchTimes]
    branchTimeTemp[index].timeStart = times[0]
    branchTimeTemp[index].timeEnd = times[1]
    setBranchTimes(branchTimeTemp)
  }

  const addBranchTime = () => setBranchTimes([...branchTimes, ...objBranchTime])

  const deleteBranchTime = (e: any, index: number) => {
    const branchTimeTemp: any = []
    for (const iBt in branchTimes) {
      if (Object.prototype.hasOwnProperty.call(branchTimes, iBt)) {
        const element = branchTimes[iBt]
        if (Number(iBt) !== Number(index)) branchTimeTemp.push(element)
      }
    }
    setBranchTimes(branchTimeTemp)
  }
  const handleChangeServicePoint = (servicePointId: number, index: number) => {
    const branchServicePointsTemp: any = [...branchServicePoints]
    branchServicePointsTemp[index].servicePointId = servicePointId
    setBranchServicePoints(branchServicePointsTemp)
  }

  const addBranchServicePoint = () => setBranchServicePoints([...branchServicePoints, ...objBranchServicePoint])

  const deleteBranchServicePoint = (e: any, index: number) => {
    const branchServicePointsTemp: any = []
    for (const iBsp in branchServicePoints) {
      if (Object.prototype.hasOwnProperty.call(branchServicePoints, iBsp)) {
        const element = branchServicePoints[iBsp]
        if (Number(iBsp) !== Number(index)) branchServicePointsTemp.push(element)
      }
    }
    setBranchServicePoints(branchServicePointsTemp)
  }
  const selectBank = (bankId: number) => {
    const useBank = branchBanks.find((branchBank) => branchBank.bankId === bankId)
    const objBank = banks.find((b) => Number(b.bankId) === bankId)
    if (useBank) {
      const tempBranchBanks: any = [...branchBanks].filter((branchBank) => branchBank.bankId !== bankId)
      setBranchBanks(tempBranchBanks)
    } else {
      if (objBank) {
        const tempBranchBanks: BranchBankInterface[] = [
          {
            bankId: Number(objBank.bankId),
            bankName: objBank.bankName,
            bankAccountName: '',
            bankAccountNumber: '',
            hasTransfer: objBank.hasTransfer === 'SUPPORT' ? 'SUPPORT' : 'NOT_SUPPORT',
            hasCredit: objBank.hasCredit === 'SUPPORT' ? 'SUPPORT' : 'NOT_SUPPORT',
            hasDebit: objBank.hasDebit === 'SUPPORT' ? 'SUPPORT' : 'NOT_SUPPORT',
            disabledTransfer: banks.find((b: any) => b.bankId === bankId && b.hasTransfer === 'SUPPORT') ? false : true,
            disabledCredit: banks.find((b: any) => b.bankId === bankId && b.hasCredit === 'SUPPORT') ? false : true,
            disabledDebit: banks.find((b: any) => b.bankId === bankId && b.hasDebit === 'SUPPORT') ? false : true
          }
        ]
        setBranchBanks([...branchBanks, ...tempBranchBanks])
      }
    }
  }
  const handleChangeBranchBank = (value: any, index: number, key: string) => {
    clearErrorMessage()
    const branchBanksTemp: any = [...branchBanks]
    branchBanksTemp[index][key] = value
    setBranchBanks(branchBanksTemp)
  }

  const renderBankLogo = (bankId: number) => {
    const item = banks.find((b: any) => b.bankId === bankId)
    if (_.get(item, 'image')) {
      const image = _.get(item, 'image')
      return `${fileUrl}/${image.filePath}/${image.fileName}`
    } else if (_.get(item, 'bankLogo')) {
      return _.get(item, 'bankLogo')
    } else return ''
  }

  const handleChangChecksetOpdCard = (event: any) => {
    setOpdCardForPrint(!opdCardForPrint)
    // setOpdCard(!opdCard)
  }

  const handleChangCheckOpdCardCover = (event: any) => {
    setOpdCardCover(!opdCardCover)
  }

  const handleChangCheckOpdCardBlank = (event: any) => {
    setOpdCardBlank(!opdCardBlank)
  }

  const handleChangCheckOpdCardHospital = (event: any) => {
    setOpdCardHospital(!opdCardHospital)
  }

  const handleChangCheckDentistCertificate = (event: any) => {
    setDentistCertificate(!dentistCertificate)
  }

  const handleChangCheckDentistCertificateBlank = (event: any) => {
    setDentistCertificateBlank(!dentistCertificateBlank)
  }

  const onRenderMap = useCallback(() => {
    const check = ['<iframe', '</iframe>']
    return addressMap && addressMap.includes(check[0]) && addressMap.includes(check[1])
  }, [addressMap])

  useEffect(() => {
    const divInfo = document.querySelector('.INFORMATION')
    if (divInfo && scrollMap && onRenderMap()) {
      $('.content-tab').delay(500).animate({ scrollTop: divInfo.clientHeight }, 300)
    }
  }, [addressMap])

  const branchTabInfo = [
    {
      tabName: "INFORMATION",
      require: true,
      className: `${errorMessage.BRANCH_CODE || errorMessage.BRANCH_NUMBER || errorMessage.BRANCH_NAME || errorMessage.BRANCH_PHONE ? 'error' : ''}`,
    },
    {
      tabName: "RECIEVE_STICKER",
      style: {
        width: 'fit-content'
      }
    },
    {
      tabName: "TEMPLACE_DOC",
    },
    {
      tabName: "WORK_TIME",
    },
    {
      tabName: "SERVICE_POINT",
    },
    {
      tabName: "BANK",
      className: `${errorMessage.EMPTY_BANK_ACCOUNT_NAME || errorMessage.EMPTY_BANK_ACCOUNT_NUMBER ? 'error' : ''}`
    },
    {
      tabName: "FORMATFORM",
    },
    {
      tabName: "SENTTING",
      textName: t('BRANCH.TAB.SENTTING.TITLE')
    },
  ]

  const templaceDoc_Opd: FormTemplaceDocDentisInfo[] = [
    {
      docName: 'OPD_CARD_COVER',
      checked: opdCardCover,
      onChange: () => setOpdCardCover(!opdCardCover),
      example: OPD_CARD_COVER,
      page: ['REGISTER',]
    },
    {
      docName: 'OPD_CARD',
      checked: opdCardFirst,
      onChange: () => setOpdCardFirst(!opdCardFirst),
      example: [OPD_CARD_COVER_V1, OPD_CARD_V1],
      page: ['REGISTER', 'TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    // {
    //   docName: 'OPD_CARD',
    //   checked: opdCardForPrint,
    //   onChange: () => setOpdCardForPrint(!opdCardForPrint),
    //   example: OPD_CARD,
    //   page: ['REGISTER']
    // },
    {
      docName: 'OPD_CARD_HOSPITAL',
      checked: opdCardHospital,
      onChange: () => setOpdCardHospital(!opdCardHospital),
      example: [OPD_CARD_HOSPITAL_1, OPD_CARD_HOSPITAL_2],
      page: ['REGISTER']
    },
    {
      docName: 'OPD_CARD_BLANK_1',
      checked: opdCardBlank,
      onChange: () => setOpdCardBlank(!opdCardBlank),
      example: OPD_CARD_BLANK_1,
      page: ['REGISTER']
    },
    {
      docName: 'OPD_CARD_BLANK_2',
      checked: opdCardBlank2,
      onChange: () => setOpdCardBlank2(!opdCardBlank2),
      example: OPD_CARD_BLANK_2,
      page: ['REGISTER']
    },
    // {
    //   docName: 'OPD_CARD_V1',
    //   checked: opdCardFirst,
    //   onChange: () => setOpdCardFirst(!opdCardFirst),
    //   example: [OPD_CARD_COVER_V1, OPD_CARD_V1],
    //   page: ['REGISTER', 'TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    // }
  ]

  const templaceDoc_Referral: FormTemplaceDocDentisInfo[] = [
    {
      docName: 'REFERRALPATIENT',
      checked: referralPatient,
      onChange: () => setReferralPatient(!referralPatient),
      example: REFERRALPATIENT,
      page: ['REGISTER', 'TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DENTALREFERRAL',
      checked: dentalReferral,
      onChange: () => setDentalReferral(!dentalReferral),
      example: DENTALREFERRAL,
      page: ['REGISTER', 'TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
  ]

  const templaceDoc_DoctorCertificate: FormTemplaceDocDentisInfo[] = [
    {
      docName: 'DOCTORCERTIFICATE_FORM_TH',
      checked: doctorCertificate,
      onChange: () => setDoctorCertificate(!doctorCertificate),
      example: DENTISTCERTIFICATE,
      page: ['REGISTER', 'TREATMENT', 'PAYMENT']
    },
    {
      docName: 'DOCTORCERTIFICATE_FORM_EN',
      checked: dentistCertificateEn,
      onChange: () => setDentistCertificateEn(!dentistCertificateEn),
      example: DENTISTCERTIFICATE_EN,
      page: ['REGISTER', 'TREATMENT', 'PAYMENT']
    },
    {
      docName: 'DOCTORCERTIFICATE_FORM_BLANK_TH',
      checked: dentistCertificateBlank,
      onChange: () => setDentistCertificateBlank(!dentistCertificateBlank),
      example: DENTISTCERTIFICATE_BLANK,
      page: ['REGISTER']
    },
    {
      docName: 'DOCTORCERTIFICATE_FORM_BLANK_EN',
      checked: dentistCertificateBlankEn,
      onChange: () => setDentistCertificateBlankEn(!dentistCertificateBlankEn),
      example: DENTISTCERTIFICATE_BLANK_EN,
      page: ['REGISTER']
    },

    {
      docName: 'DOCTORCERTIFICATEFOR5DISEASES',
      checked: doctorCertificateFor5Diseases,
      onChange: () => setDoctorCertificateFor5Diseases(!doctorCertificateFor5Diseases),
      example: DOCTORCERTIFICATEFOR5DISEASES,
      page: ['REGISTER', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DOCTORCERTIFICATEFOR5DISEASES_EN',
      checked: doctorCertificateFor5DiseasesEn,
      onChange: () => setDoctorCertificateFor5DiseasesEn(!doctorCertificateFor5DiseasesEn),
      example: DOCTORCERTIFICATEFOR5DISEASES_EN,
      page: ['REGISTER', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DOCTORCERTIFICATEFOR5DISEASES_BLANK',
      checked: doctorCertificateFor5DiseasesBlank,
      onChange: () => setDoctorCertificateFor5DiseasesBlank(!doctorCertificateFor5DiseasesBlank),
      example: DOCTORCERTIFICATEFOR5DISEASES_BLANK,
      page: ['REGISTER', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DRIVERLICENSEDOCTORCERTIFICATE',
      checked: driverLicenseDoctorCertificate,
      onChange: () => setDriverLicenseDoctorCertificate(!driverLicenseDoctorCertificate),
      example: DRIVERLICENSEDOCTORCERTIFICATE,
      page: ['TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DRIVERLICENSEDOCTORCERTIFICATE_BLANK',
      checked: driverLicenseDoctorCertificateBlank,
      onChange: () => setDriverLicenseDoctorCertificateBlank(!driverLicenseDoctorCertificateBlank),
      example: DRIVERLICENSEDOCTORCERTIFICATE_BLANK,
      page: ['TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DRIVERLICENSEDOCTORCERTIFICATE_EN',
      checked: driverLicenseDoctorCertificateEn,
      onChange: () => setDriverLicenseDoctorCertificateEn(!driverLicenseDoctorCertificateEn),
      example: DRIVERLICENSEDOCTORCERTIFICATE_EN,
      page: ['TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DOCTORCERTIFICATE_A5',
      checked: doctorCertificateA5,
      onChange: () => setDoctorCertificateA5(!doctorCertificateA5),
      example: DENTISTCERTIFICATEA5,
      page: ['TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DOCTORCERTIFICATE_A5_EN',
      checked: doctorCertificateA5En,
      onChange: () => setDoctorCertificateA5En(!doctorCertificateA5En),
      example: DENTISTCERTIFICATEA5_EN,
      page: ['TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'DOCTORCERTIFICATE_A5_BLANK',
      checked: doctorCertificateA5Blank,
      onChange: () => setDoctorCertificateA5Blank(!doctorCertificateA5Blank),
      example: DENTISTCERTIFICATEA5_BLANK,
      page: ['TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
  ]

  const templaceDoc_Other: FormTemplaceDocDentisInfo[] = [
    {
      docName: 'MEDICALSERVICEUSAGEFORM',
      checked: medicalServiceUsageForm,
      onChange: () => setMedicalServiceUsageForm(!medicalServiceUsageForm),
      example: MEDICALSERVICEUSAGEFORM,
      page: ['REGISTER', 'TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'SOCIALSECURITYCERTIFICATE',
      checked: socialSecurityCertificate,
      onChange: () => setSocialSecurityCertificate(!socialSecurityCertificate),
      example: SOCIALSECURITYCERTIFICATE,
      page: ['REGISTER', 'TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'PDFINVOICE',
      checked: pdfInvoice,
      onChange: () => setPdfInvoice(!pdfInvoice),
      example: PDFINVOICE,
      page: ['TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
    {
      docName: 'OUTSTANDINGPAYMENT',
      checked: outStandingPayment,
      onChange: () => setOutStandingPayment(!outStandingPayment),
      example: [],
      page: [],
      docMulti: [
        {
          docName: 'OUTSTANDINGPAYMENT',
          example: OUTSTANDINGPAYMENT,
          page: ['TREATMENT', 'PAYMENT'],
        },
        {
          docName: 'OUTSTANDINGPAYMENT_SAVE',
          example: [OUTSTANDINGPAYMENT_SAVE1, OUTSTANDINGPAYMENT_SAVE2],
          page: ['PAYMENT'],
        }
      ]
    },
    {
      docName: 'DRUGSTICKER',
      checked: drugSticker,
      onChange: () => setDrugSticker(!drugSticker),
      example: DRUGSTICKER,
      page: ['TREATMENT', 'TREATMENTHISTORY', 'PAYMENT']
    },
  ]

  const templaceDoc: TemplaceDocInfo[] = [
    {
      title: 'OPD',
      info: templaceDoc_Opd
    },
    {
      title: 'REFERRAL',
      info: templaceDoc_Referral
    },
    {
      title: 'DOCTORCERTIFICATE',
      info: templaceDoc_DoctorCertificate
    },
    {
      title: 'OTHER',
      info: templaceDoc_Other
    },
  ]

  const handleBackUpData = () => {
    setLoadingBackup(true)
    ClinicApi.backUpData().then((res) => {
      if (res.status === 200) {
        if (res.data) {
          const { filePath, fileName } = res.data
          const link = document.createElement("a");
          link.href = `${fileUrl}/${filePath}`
          link.download = fileName;
          link.target = "_blank";
          link.click();
          swalSuccess(t('BRANCH.MESSAGE.BACKUP_SUCCESS'))
          setLoadingBackup(false)
        } else swalWarning(t('BRANCH.MESSAGE.BACKUP_ERROR_TITLE'), t('BRANCH.MESSAGE.BACKUP_ERROR_SUBTITLE'))
      }
    }).catch((e) => {
      swalWarning('', t('BRANCH.MESSAGE.BACKUP_ERROR_AGAIN'))
      setLoadingBackup(false)
    })
  }

  return (
    <>
      <div className={`h-100 container-card overflow-hidden`} style={{ backgroundColor: colors.white }}>
        <div className="h-100 d-flex flex-column">
          <HeaderLabel
            text={t('BRANCH.TITLE_UPDATE')}
            goBack={routeName.branch}
            disabledSend={loading}
            onSubmit={submitUpdate}
            navigator={{ previousTo: routeName.branch, previousTitle: t('BRANCH.TITLE'), currentTitle: t('BRANCH.TITLE_UPDATE') }}
            componentButton={(
              <Box className={'mr-3'}>
                <ButtonCustom
                  disabled={loadingBackup}
                  textButton={t('Backup Data')}
                  btnStyle={{ height: '44px' }}
                  onClick={() => handleBackUpData()}
                />
              </Box>
            )}
          />
          <Card className="border shadow-sm mx-4 mb-4 h-100 overflow-hidden">
            <Card.Body className="h-100 overflow-hidden py-0">
              <Grid container spacing={2} className="pl-3 pl-lg-0 mt-0 h-100 flex-column flex-xl-row flex-nowrap flex-xl-wrap">
                <Grid item lg={2} className="p-3 w-100" sx={{ borderRight: { lg: `1px solid ${colors.lightGray}` } }}>
                  <UseStyles.UploadImage className="align-content-center flex-center">
                    <UploadImage setValue={uploadBranchLogo} pathFile={branchLogoData} />
                  </UseStyles.UploadImage>
                  <div className={'mt-3'}>
                    <Divider className="mx-0 mt-3 d-xl-none" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                  </div>
                </Grid>
                <Grid item lg={10} className={'pl-0 pl-lg-3 py-3 w-100 h-100 overflow-hidden d-flex flex-column'}>
                  <div className="overflow-auto custom-scroll-branch w-100 text-nowrap " style={{ borderBottom: `1px solid ${colors.extraLightBlue}`, flex: 'none' }}>
                    {branchTabInfo.map((item, index: number) => {
                      return (
                        <UseStyles.ButtonTab
                          key={index}
                          className={`btn ${item?.className || ''}`}
                          style={{ borderBottom: branchTab === item.tabName ? `3px solid ${colors.themeSecondColor}` : 'none', color: branchTab === item.tabName ? colors.themeSecondColor : '', ...item?.style }}
                          type={'button'}
                          onClick={(e) => setBranchTab(item.tabName)}
                        >
                          {item?.textName || t(`BRANCH.TAB.${item.tabName}`)}{item?.require && '*' || ''}
                        </UseStyles.ButtonTab>
                      )
                    })}
                  </div>
                  <div className="overflow-auto custom-scroll content-tab ">
                    {branchTab === 'INFORMATION' && (
                      <div className="mt-3 INFORMATION" style={{ display: branchTab === 'INFORMATION' ? 'block' : 'none' }}>
                        <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.SIMPLE_INFORMATION')}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <Grid container spacing={2}>
                              <Grid item sx={{ width: '50%' }}>
                                <InputTextField
                                  inputProps={{ maxLength: 3 }}
                                  label={t('BRANCH.INPUT.BRANCH_CODE')}
                                  value={branchCode}
                                  hookValue={true}
                                  onchange={(event) => {
                                    setBranchCode(event.target.value)
                                    clearErrorMessage()
                                  }}
                                  helperText={
                                    errorMessage.BRANCH_CODE ||
                                    errorMessage.IS_DUPLICATE_BRANCH_CODE ||
                                    errorMessage.BRANCH_CODE_STRING_BASE ||
                                    errorMessage.BRANCH_CODE_STRING_EMPTY ||
                                    errorMessage.BRANCH_CODE_MAXIMUM_LENGTH ||
                                    errorMessage.BRANCH_CODE_ANY_REQUIRED
                                  }
                                  required={true}
                                />
                              </Grid>
                              <Grid item sx={{ width: '50%' }}>
                                <InputTextField
                                  label={t('BRANCH.INPUT.BRANCH_NUMBER')}
                                  value={branchNumber}
                                  hookValue={true}
                                  onchange={(event) => {
                                    setBranchNumber(event.target.value)
                                    clearErrorMessage()
                                  }}
                                  helperText={
                                    errorMessage.BRANCH_NUMBER ||
                                    errorMessage.IS_DUPLICATE_BRANCH_NUMBER ||
                                    errorMessage.BRANCH_NUMBER_STRING_BASE ||
                                    errorMessage.BRANCH_NUMBER_STRING_EMPTY ||
                                    errorMessage.BRANCH_NUMBER_MAXIMUM_LENGTH ||
                                    errorMessage.BRANCH_NUMBER_ANY_REQUIRED
                                  }
                                  required={true}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_NAME')}
                              value={branchName}
                              hookValue={true}
                              // inputProps={{ maxLength: 255 }}
                              onchange={(event) => {
                                setBranchName(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={
                                errorMessage.BRANCH_NAME ||
                                errorMessage.IS_DUPLICATE_BRANCH_NAME ||
                                errorMessage.BRANCH_NAME_STRING_BASE ||
                                errorMessage.BRANCH_NAME_STRING_EMPTY ||
                                errorMessage.BRANCH_NAME_MAXIMUM_LENGTH ||
                                errorMessage.BRANCH_NAME_ANY_REQUIRED
                              }
                              required={true}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_NAME_EN')}
                              value={branchNameEn}
                              hookValue={true}
                              // inputProps={{ maxLength: 255 }}
                              onchange={(event) => {
                                setBranchNameEn(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={
                                errorMessage.BRANCH_NAME_EN ||
                                errorMessage.IS_DUPLICATE_BRANCH_NAME_EN ||
                                errorMessage.BRANCH_NAME_EN_STRING_BASE ||
                                errorMessage.BRANCH_NAME_EN_STRING_EMPTY ||
                                errorMessage.BRANCH_NAME_EN_MAXIMUM_LENGTH ||
                                errorMessage.BRANCH_NAME_EN_ANY_REQUIRED
                              }
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_LICENSE')}
                              value={branchLicense}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchLicense(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_LICENSE}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_TAX')}
                              value={branchTax}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchTax(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_TAX}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.HOSPITAL_NAME')}
                              value={hospitalName}
                              hookValue={true}
                              onchange={(event) => {
                                setHospitalName(event.target.value)
                                clearErrorMessage()
                              }}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.HOSPITAL_CODE')}
                              value={hospitalCode}
                              hookValue={true}
                              onchange={(event) => {
                                setHospitalCode(event.target.value)
                                clearErrorMessage()
                              }}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_PHONE')}
                              value={branchPhone}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchPhone(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_PHONE}
                              required={true}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_TEL')}
                              value={branchTel}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchTel(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_TEL}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_FAX')}
                              value={branchFax}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchFax(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_FAX}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_LINE')}
                              value={branchLine}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchLine(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_LINE}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_WEBSITE')}
                              value={branchWebsite}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchWebsite(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_WEBSITE}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_EMAIL')}
                              value={branchEmail}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchEmail(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_EMAIL}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_FACEBOOK')}
                              value={branchFacebook}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchFacebook(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_FACEBOOK}
                            />
                          </Grid>
                          <Grid item className={'w-100'} sm={6} lg={4} xl={3}>
                            <InputTextField
                              label={t('BRANCH.INPUT.BRANCH_INSTAGRAM')}
                              value={branchInstagram}
                              hookValue={true}
                              onchange={(event) => {
                                setBranchInstagram(event.target.value)
                                clearErrorMessage()
                              }}
                              helperText={errorMessage.BRANCH_INSTAGRAM}
                            />
                          </Grid>
                        </Grid>
                        <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.ADDRESS_INFORMATION')}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item sm={6} lg={4} xl={3} className="w-100">
                            <AutocompleteSelect
                              labelId={`lb-parent-country`}
                              inputLabel={t('PERSONNEL.INPUT.INFORMATION.COUNTRY')}
                              noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                              options={countryOptions}
                              getOptionLabel={(option: any) => option.countryNameTh}
                              renderOption={(props, option: any) => (
                                <Box key={option} component="li" {...props}>
                                  {option.countryNameTh}
                                </Box>
                              )}
                              onchange={(e, value: any) => {
                                if (value) {
                                  const val: number = value.countryId
                                  setCountry(val)
                                }
                              }}
                              key={`lb-parent-country`}
                              value={_.find(countryOptions, (countries) => countries.countryId === country) || null}
                              disableClearable
                            />
                          </Grid>
                          <Grid item sm={6} lg={8} xl={9} className="w-100">
                            <SelectAddress
                              patientId={0}
                              countryId={country}
                              provinceId={branchSelectAddress?.province_id}
                              districtId={branchSelectAddress?.district_id}
                              subDistrictId={branchSelectAddress?.sub_district_id}
                              postcode={branchSelectAddress?.postcode}
                              callbackAddress={(address: any) => {
                                if (address.provinceId || address.districtId || address.subDistrictId || address.postcode) {
                                  setBranchSelectAddress({ ...branchSelectAddress, province_id: address.provinceId, district_id: address.districtId, sub_district_id: address.subDistrictId, postcode: address.postcode })
                                }
                              }}
                            />
                          </Grid>
                          <Grid item className={'w-100'}>
                            <InputTextarea
                              onchange={(event) => {
                                setBranchAddress(event.target.value)
                                clearErrorMessage()
                              }}
                              value={branchAddress}
                              label={t('BRANCH.INPUT.BRANCH_ADDRESS')}
                              helperText={errorMessage.BRANCH_ADDRESS}
                            />
                          </Grid>
                          <Grid item className={'w-100'}>
                            <InputTextarea
                              onchange={(event) => {
                                setBranchAddressEn(event.target.value)
                                clearErrorMessage()
                              }}
                              value={branchAddressEn}
                              label={t('BRANCH.INPUT.BRANCH_ADDRESS_EN')}
                              helperText={errorMessage.BRANCH_ADDRESS_EN}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {branchTab === 'RECIEVE_STICKER' && (
                      <div className="mt-3" style={{ display: branchTab === 'RECIEVE_STICKER' ? 'block' : 'none' }}>
                        <Typography className="mb-2" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.ADDRESS_RECIEVE')}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item sx={{ marginLeft: '5px' }}>
                            <InputCheckbox label={t('BRANCH.ADDRESS_BY_BRANCH')} key={'addressRecieptSameBranch'} checked={addressRecieptSameBranch || false} onChange={handleChangCheckAddress} />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item className={'w-100 mt-3'} sm={6} lg={3}>
                            <AutocompleteSelect
                              labelId={`lb-parent-country`}
                              inputLabel={t('PERSONNEL.INPUT.INFORMATION.COUNTRY')}
                              noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                              options={countryOptions}
                              getOptionLabel={(option: any) => option.countryNameTh}
                              renderOption={(props, option: any) => {
                                return (
                                  <Box key={option} component="li" {...props}>
                                    {option.countryNameTh}
                                  </Box>
                                )
                              }}
                              onchange={(e, value: any) => {
                                if (value) {
                                  const val: number = value.countryId
                                  setRecieptCountry(val)
                                }
                              }}
                              key={`lb-parent-country`}
                              value={_.find(countryOptions, (countries) => countries.countryId === receiptCountry) || null}
                              disabled={addressRecieptSameBranch}
                              disableClearable
                            />
                          </Grid>
                          <Grid item className={'w-100 mt-md-3'} sm={6}>
                            <SelectAddress
                              patientId={0}
                              countryId={receiptCountry}
                              provinceId={receiptSelectAddress?.province_id}
                              districtId={receiptSelectAddress?.district_id}
                              subDistrictId={receiptSelectAddress?.sub_district_id}
                              postcode={receiptSelectAddress?.postcode}
                              callbackAddress={(address: any) => {
                                if (address.provinceId || address.districtId || address.subDistrictId || address.postcode) {
                                  setRecieptSelectAddress({ ...receiptSelectAddress, province_id: address.provinceId, district_id: address.districtId, sub_district_id: address.subDistrictId, postcode: address.postcode })
                                }
                              }}
                              disabled={addressRecieptSameBranch}
                            />
                          </Grid>
                          <Grid item className={'w-100'} xs={12}>
                            <InputTextarea
                              disabled={addressRecieptSameBranch}
                              onchange={(event) => {
                                setRecieptAddress(event.target.value)
                                clearErrorMessage()
                              }}
                              value={receiptAddress}
                              label={t('BRANCH.INPUT.BRANCH_ADDRESS')}
                              helperText={errorMessage.BRANCH_ADDRESS}
                            />
                          </Grid>
                          <Grid item className={'w-100'} xs={12}>
                            <InputTextarea
                              disabled={addressRecieptSameBranch}
                              onchange={(event) => {
                                setRecieptAddressEn(event.target.value)
                                clearErrorMessage()
                              }}
                              value={receiptAddressEn}
                              label={t('BRANCH.INPUT.BRANCH_ADDRESS_EN')}
                              helperText={errorMessage.BRANCH_ADDRESS_EN}
                            />
                          </Grid>
                        </Grid>
                        <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.IMAGE_BRANCH')}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item className={'w-100 mt-3'} sm={6} lg={3}>
                            <FormControl className="d-block pl-3 col">
                              <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                {t('BRANCH.INPUT.IMAGE.COLOR')}
                              </Typography>
                              <UseStyles.UploadReceiptImageBranch className="mt-3 w-100">
                                <UploadImage
                                  setValue={uploadBranchImage}
                                  pathFile={branchImageData}
                                  canDelete
                                  onDelete={() => {
                                    setBranchImage(objImage)
                                    setBranchImageData(undefined)
                                  }}
                                />
                              </UseStyles.UploadReceiptImageBranch>
                            </FormControl>
                          </Grid>
                          <Grid item className={'w-100 mt-3'} sm={6} lg={3}>
                            <FormControl className="d-block pl-3 col">
                              <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                {t('BRANCH.INPUT.IMAGE.BLACK_WHITE')}
                              </Typography>
                              <UseStyles.UploadReceiptImageBranch className="mt-3 w-100">
                                <UploadImage
                                  setValue={uploadBranchLogoThermal}
                                  pathFile={branchLogoThermalData}
                                  canDelete
                                  onDelete={() => {
                                    setBranchLogoThermal(objImage)
                                    setBranchLogoThermalData(undefined)
                                  }}
                                />
                              </UseStyles.UploadReceiptImageBranch>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.IMAGE_BILL')}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item lg={7} xl={6} className="w-100">
                            <FormControl className="d-block pl-3">
                              <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                {t('BRANCH.TAB.DEFAULT_BILL')}
                              </Typography>
                              <RadioGroup row aria-label="defaultReceipt" onChange={(event) => setDefaultReceipt(event.target.value)} className="flex-row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt className={`ml-0 mr-2 pr-3 ${defaultReceipt === 'A5' ? 'active' : ''}`} checked={defaultReceipt === 'A5'} value="A5" control={<InputRadio />} label={t('BRANCH.TAB.BILL_A5')} />
                                <UseStyles.FormFormatReceipt className={`ml-0 mr-2 pr-3 ${defaultReceipt === 'A4' ? 'active' : ''}`} checked={defaultReceipt === 'A4'} value="A4" control={<InputRadio />} label={t('BRANCH.TAB.BILL_A4')} />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${defaultReceipt === 'THERMAL' ? 'active' : ''}`}
                                  checked={defaultReceipt === 'THERMAL'}
                                  value="THERMAL"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.BILL_THERMAL')}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${defaultReceipt === 'A5_LANDSCAPE' ? 'active' : ''}`}
                                  checked={defaultReceipt === 'A5_LANDSCAPE'}
                                  value="A5_LANDSCAPE"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.BILL_A5_LANDSCAPE')}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${defaultReceipt === 'A5_LANDSCAPE_DF' ? 'active' : ''}`}
                                  checked={defaultReceipt === 'A5_LANDSCAPE_DF'}
                                  value="A5_LANDSCAPE_DF"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.BILL_A5_LANDSCAPE_DF')}
                                />
                              </RadioGroup>
                            </FormControl>
                            {/* showCostEstimateReceipt */}
                            <FormControl className="d-block pl-3">
                              <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                {t('BRANCH.TAB.COSTESTIMATERECEIP')}
                              </Typography>
                              <RadioGroup row aria-label="defaultReceipt" onChange={(event) => setShowCostEstimateReceipt(event.target.value)} className="flex-row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${showCostEstimateReceipt === '1' ? 'active' : ''}`}
                                  checked={showCostEstimateReceipt === '1'}
                                  value="1"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.SHOWCOSTESTIMATERECEIP')}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${showCostEstimateReceipt === '0' ? 'active' : ''}`}
                                  checked={showCostEstimateReceipt === '0'}
                                  value="0"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.NONESHOWCOSTESTIMATERECEIP')}
                                />
                              </RadioGroup>
                            </FormControl>

                            <FormControl className="d-block pl-3">
                              <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                {t('BRANCH.TAB.DEFAULT_BILL_REMARK')}
                              </Typography>
                              <RadioGroup row aria-label="defaultReceipt" onChange={(event) => setDefaultReceiptRemarkStatus(event.target.value)} className="flex-row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${defaultReceiptRemarkStatus === '1' ? 'active' : ''}`}
                                  checked={defaultReceiptRemarkStatus === '1'}
                                  value="1"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.SHOW_BILL_REMARK')}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${defaultReceiptRemarkStatus === '0' ? 'active' : ''}`}
                                  checked={defaultReceiptRemarkStatus === '0'}
                                  value="0"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.HIDE_BILL_REMARK')}
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl className="d-block pl-3">
                              <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                {t('BRANCH.TAB.DEFAULT_BILL_PRACTICE')}
                              </Typography>
                              <RadioGroup row aria-label="defaultReceipt" onChange={(event) => setDefaultReceiptPracticeStatus(event.target.value)} className="flex-row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${defaultReceiptPracticeStatus === '1' ? 'active' : ''}`}
                                  checked={defaultReceiptPracticeStatus === '1'}
                                  value="1"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.SHOW_BILL_PRACTICE')}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${defaultReceiptPracticeStatus === '0' ? 'active' : ''}`}
                                  checked={defaultReceiptPracticeStatus === '0'}
                                  value="0"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.HIDE_BILL_PRACTICE')}
                                />
                              </RadioGroup>
                            </FormControl>
                            {defaultReceipt === 'THERMAL' && (
                              <>
                                <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                                <FormControl className={`d-block pl-3 mt-3`}>
                                  <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                    {t('BRANCH.TAB.FORMAT_BILL')}
                                  </Typography>
                                  <RadioGroup row aria-label="thermalFormat" onChange={(event) => setThermalFormat(event.target.value)} className="flex-row" name="row-radio-buttons-group">
                                    <UseStyles.FormFormatReceipt
                                      className={`ml-0 mr-2 pr-3 ${thermalFormat === 'FORMAT_1' ? 'active' : ''}`}
                                      checked={thermalFormat === 'FORMAT_1'}
                                      value="FORMAT_1"
                                      control={<InputRadio />}
                                      label={t('BRANCH.TAB.FORMAT_1')}
                                    />
                                    <UseStyles.FormFormatReceipt
                                      className={`ml-0 mr-2 pr-3 ${thermalFormat === 'FORMAT_2' ? 'active' : ''}`}
                                      checked={thermalFormat === 'FORMAT_2'}
                                      value="FORMAT_2"
                                      control={<InputRadio />}
                                      label={t('BRANCH.TAB.FORMAT_2')}
                                    />
                                    <UseStyles.FormFormatReceipt
                                      className={`ml-0 mr-2 pr-3 ${thermalFormat === 'FORMAT_3' ? 'active' : ''}`}
                                      checked={thermalFormat === 'FORMAT_3'}
                                      value="FORMAT_3"
                                      control={<InputRadio />}
                                      label={t('BRANCH.TAB.FORMAT_3')}
                                    />
                                    <UseStyles.FormFormatReceipt
                                      className={`ml-0 mr-2 pr-3 ${thermalFormat === 'FORMAT_4' ? 'active' : ''}`}
                                      checked={thermalFormat === 'FORMAT_4'}
                                      value="FORMAT_4"
                                      control={<InputRadio />}
                                      label={t('BRANCH.TAB.FORMAT_4')}
                                    />

                                  </RadioGroup>
                                </FormControl>
                                <br />
                                <InputTextarea
                                  onchange={(event) => {
                                    const inputValue = event.target.value;
                                    if (inputValue.length <= 100) {
                                      setThermalRemark(event.target.value)
                                    }
                                  }}
                                  value={thermalRemark}
                                  label={t('BRANCH.INPUT.THERMAL_REMARK')}
                                  inputProps={{
                                    style: {
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'flex-start',
                                      fontSize: "12px"
                                    },
                                    startAdornment: (
                                      <div style={{ position: "absolute", top: "-18px" }}>
                                        <div>{`${thermalRemark?.length || 0}/100`}</div>
                                      </div>
                                    ),
                                  }}
                                />
                              </>
                            )}
                            <br />
                            <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                            <FormControl className={`d-block pl-3 mt-3`}>
                              <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                {t('BRANCH.TAB.FORMAT_NUMBER')}
                              </Typography>
                              <RadioGroup row aria-label="receiptNumberPrefix" onChange={(event) => setReceiptNumberPrefix(event.target.value)} className="flex-row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${receiptNumberPrefix === 'CN' ? 'active' : ''}`}
                                  checked={receiptNumberPrefix === 'CN'}
                                  value="CN"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.FORMAT_NUMBER_CN')}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 ${receiptNumberPrefix === 'PT' ? 'active' : ''}`}
                                  checked={receiptNumberPrefix === 'PT'}
                                  value="PT"
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.FORMAT_NUMBER_PT')}
                                />
                                {snType &&
                                  <UseStyles.FormFormatReceipt
                                    className={`ml-0 mr-2 pr-3 ${receiptNumberPrefix === 'SN' ? 'active' : ''}`}
                                    checked={receiptNumberPrefix === 'SN'}
                                    value="SN"
                                    control={<InputRadio />}
                                    label={t('BRANCH.TAB.FORMAT_CODE_SN')}
                                  />
                                }
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          <Grid item lg={5} className="w-100">
                            <Grid container spacing={2} className="h-100 mt-0 pl-3 pl-xl-4">
                              {(defaultReceipt !== 'THERMAL' && (
                                <Grid item xs={6} sm={4} md={3} lg={5} xl={4} className="w-100 pt-0">
                                  <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                    {t('BRANCH.RECEIPT.DEFAULT')}
                                  </Typography>
                                  <UseStyles.ImgFormatReceipt onClick={() => setOpenPreviewReceipt(true)} className="cursor-pointer">
                                    {defaultReceipt === 'A4' ? <img src={ReceiptA4} alt="" />
                                      :
                                      defaultReceipt === 'A5' ? <img src={ReceiptA5} alt="" />
                                        :
                                        defaultReceipt === 'A5_LANDSCAPE' ? <img src={ReceiptA5Landscape} alt="" />
                                          :
                                          <img src={ReceiptA5LandscapeDf} alt="" />
                                    }
                                  </UseStyles.ImgFormatReceipt>
                                </Grid>
                              )) || (
                                  <Grid item xs={6} sm={4} md={3} lg={5} xl={4} className="w-100" sx={{ paddingTop: { xs: `0 !important` } }}>
                                    <Typography className="mb-1" sx={{ fontWeight: 500 }}>
                                      {t('BRANCH.RECEIPT.THERMAL')}
                                    </Typography>
                                    <UseStyles.ImgFormatReceipt onClick={() => setOpenPreviewReceipt(true)} className="cursor-pointer">
                                      {thermalFormat === 'FORMAT_1' && <img src={Thermal1} alt="" />}
                                      {thermalFormat === 'FORMAT_2' && <img src={Thermal2} alt="" />}
                                      {thermalFormat === 'FORMAT_3' && <img src={Thermal3} alt="" />}
                                      {thermalFormat === 'FORMAT_4' && <img src={Thermal4} alt="" />}
                                    </UseStyles.ImgFormatReceipt>
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {openPreviewReceipt && (
                          <UseStyles.PreviewReceipt>
                            <Box className="preview-wrap">
                              {defaultReceipt === 'A4' && <PreviewImage imageFile={ReceiptA4} />}
                              {defaultReceipt === 'A5' && <PreviewImage imageFile={ReceiptA5} />}
                              {defaultReceipt === 'A5_LANDSCAPE' && <PreviewImage imageFile={ReceiptA5Landscape} type="LANDSCAPE" />}
                              {defaultReceipt === 'A5_LANDSCAPE_DF' && <PreviewImage imageFile={ReceiptA5LandscapeDf} type="LANDSCAPE" />}
                              {defaultReceipt === 'THERMAL' && thermalFormat === 'FORMAT_1' && <PreviewImage imageFile={Thermal1} />}
                              {defaultReceipt === 'THERMAL' && thermalFormat === 'FORMAT_2' && <PreviewImage imageFile={Thermal2} />}
                              {defaultReceipt === 'THERMAL' && thermalFormat === 'FORMAT_3' && <PreviewImage imageFile={Thermal3} />}
                              {defaultReceipt === 'THERMAL' && thermalFormat === 'FORMAT_4' && <PreviewImage imageFile={Thermal4} />}
                            </Box>
                            <Box onClick={() => setOpenPreviewReceipt(false)} className="preview-backdrop"></Box>
                          </UseStyles.PreviewReceipt>
                        )}

                        <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <Typography className="mb-2" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.INPUT.QRCODE_TITLE')}
                        </Typography>
                        <UseStyles.BoxQRcode>
                          <Box>
                            <UseStyles.UploadReceiptImageBranch className="w-100" width={200} height={200}>
                              <UploadImage
                                setValue={uploadQRCode}
                                pathFile={imgQRData}
                                canDelete
                                onDelete={() => {
                                  setQRCodeReceipt(objImage)
                                  setImgQRData("")
                                }}
                              />
                            </UseStyles.UploadReceiptImageBranch>
                          </Box>
                          <Box className={`qr-textarea`}>
                            <Box>
                              <InputTextarea
                                label={t('BRANCH.INPUT.QRCODE_DETAIL')}
                                onchange={(e: any) => {
                                  const inputValue = e.target.value;
                                  if (inputValue.length <= 100) {
                                    setQRCodeReceiptDetail(e.target.value)
                                  }
                                }}
                                value={qrCodeReceiptDetail}
                                inputProps={{
                                  style: {
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-start',
                                    fontSize: "12px"
                                  },
                                  startAdornment: (
                                    <Box sx={{ position: "absolute", bottom: "2px" }}>
                                      <Box sx={{ fontSize: '12px', color: '#808080' }}>{`${qrCodeReceiptDetail?.length || 0}/100`}</Box>
                                    </Box>
                                  ),
                                }} />
                            </Box>
                          </Box>
                        </UseStyles.BoxQRcode>

                        <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <Box>
                          <UseStyles.FormSticker >
                            <Box className={'sticker-header'}>
                              {t(`BRANCH.TAB.SENTTING.STICKER_MED`)}
                            </Box>
                            <Box
                              sx={{
                                transform: `rotate(${openCollapseSticker ? 0 : 180}deg)`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={() => setOpenCollapseSticker(!openCollapseSticker)}
                            >
                              <ExpandMoreIcon
                                sx={{
                                  backgroundColor: colors.white,
                                  border: `0.5px solid ${colors.textLightGray}`,
                                  borderRadius: '50px',
                                  color: colors.themeMainColor
                                }}
                              />
                            </Box>
                          </UseStyles.FormSticker>
                          <Collapse in={openCollapseSticker}  >
                            <Box sx={{ padding: "0 16px" }}>
                              <Grid container spacing={2}>
                                <Grid item sm={12} xs={12} md={6} xl={4} className={'w-100 pl-3'}>
                                  <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                                    {t('BRANCH.TAB.SENTTING.STICKER_MEDICINE')}{' '}
                                  </Typography>
                                  <FormControl className="d-block pl-3">
                                    <RadioGroup row aria-label="defaultSticker" onChange={(event) => setStickerFormat(event.target.value)} className="flex-column" name="row-radio-buttons-group">
                                      <UseStyles.FormFormatSticker
                                        className={`ml-0 mr-2 pr-3  ${stickerFormat === 'FORMAT_1' ? 'active' : ''}`}
                                        checked={stickerFormat === 'FORMAT_1'}
                                        value="FORMAT_1"
                                        control={<InputRadio />}
                                        label={`${t('BRANCH.TAB.FORMAT_1')}`}
                                      />
                                      <UseStyles.FormFormatSticker
                                        className={`ml-0 mr-2 pr-3  ${stickerFormat === 'FORMAT_2' ? 'active' : ''}`}
                                        checked={stickerFormat === 'FORMAT_2'}
                                        value="FORMAT_2"
                                        control={<InputRadio />}
                                        label={`${t('BRANCH.TAB.FORMAT_2')}`}
                                      />
                                      <UseStyles.FormFormatSticker
                                        className={`ml-0 mr-2 pr-3  ${stickerFormat === 'FORMAT_3' ? 'active' : ''}`}
                                        checked={stickerFormat === 'FORMAT_3'}
                                        value="FORMAT_3"
                                        control={<InputRadio />}
                                        label={`${t('BRANCH.TAB.FORMAT_3')}`}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item className={'w-auto pl-3'}>
                                  <Typography className="mb-1" sx={{ fontSize: '18px', fontWeight: 500 }}>
                                    {t(`BRANCH.STICKER_MEDICINE.${stickerFormat}`)}
                                  </Typography>
                                  <UseStyles.ImgFormatSticker onClick={() => setOpenPreviewSticker(true)} className="cursor-pointer">
                                    {stickerFormat === 'FORMAT_1' && <img src={Sticker1} alt="" />}
                                    {stickerFormat === 'FORMAT_2' && <img src={Sticker2} alt="" />}
                                    {stickerFormat === 'FORMAT_3' && <img src={Sticker3} alt="" />}
                                  </UseStyles.ImgFormatSticker>
                                </Grid>
                              </Grid>
                              {openPreviewSticker && (
                                <UseStyles.PreviewSticker>
                                  <Box className="preview-wrap">
                                    {stickerFormat === 'FORMAT_1' && <PreviewImage imageFile={Sticker1} />}
                                    {stickerFormat === 'FORMAT_2' && <PreviewImage imageFile={Sticker2} />}
                                    {stickerFormat === 'FORMAT_3' && <PreviewImage imageFile={Sticker3} />}
                                  </Box>
                                  <Box onClick={() => setOpenPreviewSticker(false)} className="preview-backdrop"></Box>
                                </UseStyles.PreviewSticker>
                              )}
                            </Box>

                          </Collapse>
                        </Box>
                      </div>
                    )}
                    {branchTab === 'TEMPLACE_DOC' && (
                      <div className="mt-3" style={{ display: branchTab === 'TEMPLACE_DOC' ? 'block' : 'none' }}>
                        <Typography className="mb-2" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TEMPLATE_EXPORT')}
                        </Typography>
                        <div className="pl-3">
                          <Box>
                            <FormTemplaceDocDentist templaceDoc={templaceDoc} />
                          </Box>

                          <div className={'mt-3 mb-3'}>
                            <Divider className="mx-0 mt-3 " sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                          </div>
                          <Typography className="mb-2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                            {t('BRANCH.INPUT.TREATMENTDOCUMENT')}
                          </Typography>
                          <Grid item className={'w-100 pl-3'}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box className={`d-flex align-items-center gap-3`}>
                                  <InputCheckbox label={t('PDF_INVOICE.HEADER')} key={'addressRecieptSameBranch'} checked={pdfInvoiceTreatment || false} onChange={() => setPdfInvoiceTreatment(!pdfInvoiceTreatment)} />
                                  <Box>
                                    <ButtonCustom
                                      textButton={t('BRANCH.INPUT.VIEW_EXAMPLE')}
                                      variant={'outlined'}
                                      btnStyle={{
                                        height: '25px',
                                        padding: '4px 10px',
                                        fontSize: '13px',
                                        whiteSpace: 'nowrap'
                                      }}
                                      onClick={() => {
                                        dispatch(showModal())
                                        setModalImg(!modalImg)
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item sm={12} md={3}>
                                <Box sx={{ paddingLeft: '32px' }}>
                                  <Box>
                                    {t('BRANCH.INPUT.CONDITION')}
                                  </Box>
                                  <RadioGroup aria-label="pdfInvoiceTreatmentCondition" onChange={(event) => setPdfInvoiceTreatmentCondition(event.target.value)} className="flex-col" name="row-radio-buttons-group">
                                    <UseStyles.FormFormatReceipt
                                      className={`ml-0 mr-2 pr-3 ${pdfInvoiceTreatmentCondition === '0' ? 'active' : ''}`}
                                      checked={pdfInvoiceTreatmentCondition === '0'}
                                      value="0"
                                      control={<InputRadio />}
                                      label={t('BRANCH.INPUT.CONDITION_1')}
                                    />
                                    <UseStyles.FormFormatReceipt
                                      className={`ml-0 mr-2 pr-3 ${pdfInvoiceTreatmentCondition === '1' ? 'active' : ''}`}
                                      checked={pdfInvoiceTreatmentCondition === '1'}
                                      value="1"
                                      control={<InputRadio />}
                                      label={t('BRANCH.INPUT.CONDITION_2')}
                                    />
                                  </RadioGroup>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <div className={'mt-3 mb-3'}>
                            <Divider className="mx-0 mt-3 " sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                          </div>
                          <Typography className="mb-2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                            {t('BRANCH.TEMPLATE_BILL')}
                          </Typography>
                          <Grid item className={'w-100 pl-3'}>
                            <Grid container>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA4DEFAULT')} key={'addressRecieptSameBranch'} disabled checked={receiptA4Default || false} onChange={() => setReceiptA4Default(!receiptA4Default)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA4ORIGINAL')} key={'addressRecieptSameBranch'} checked={receiptA4Original || false} onChange={() => setReceiptA4Original(!receiptA4Original)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA4COPY')} key={'addressRecieptSameBranch'} checked={receiptA4Copy || false} onChange={() => setReceiptA4Copy(!receiptA4Copy)} />
                              </Grid>
                              {/* A5 */}
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA5DEFAULT')} key={'addressRecieptSameBranch'} disabled checked={receiptA5Default || false} onChange={() => setReceiptA5Default(!receiptA5Default)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA5ORIGINAL')} key={'addressRecieptSameBranch'} checked={receiptA5Original || false} onChange={() => setReceiptA5Original(!receiptA5Original)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA5COPY')} key={'addressRecieptSameBranch'} checked={receiptA5Copy || false} onChange={() => setReceiptA5Copy(!receiptA5Copy)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA5DEFAULTEN')} key={'addressRecieptSameBranch'} checked={receiptA5DefaultEn || false} onChange={() => setReceiptA5DefaultEn(!receiptA5DefaultEn)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA5ORIGINALEN')} key={'addressRecieptSameBranch'} checked={receiptA5OriginalEn || false} onChange={() => setReceiptA5OriginalEn(!receiptA5OriginalEn)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA5COPYEN')} key={'addressRecieptSameBranch'} checked={receiptA5CopyEn || false} onChange={() => setReceiptA5CopyEn(!receiptA5CopyEn)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox
                                  label={t('BRANCH.INPUT.RECEIPTA5DEFAULTLANDSCAPE')}
                                  key={'addressRecieptSameBranch'}
                                  checked={receiptA5DefaultLandscape || false}
                                  onChange={() => setReceiptA5DefaultLandscape(!receiptA5DefaultLandscape)}
                                />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox
                                  label={t('BRANCH.INPUT.RECEIPTA5ORIGINALLANDSCAPE')}
                                  key={'addressRecieptSameBranch'}
                                  checked={receiptA5OriginalLandscape || false}
                                  onChange={() => setReceiptA5OriginalLandscape(!receiptA5OriginalLandscape)}
                                />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.RECEIPTA5COPYLANDSCAPE')} key={'addressRecieptSameBranch'} checked={receiptA5CopyLandscape || false} onChange={() => setReceiptA5CopyLandscape(!receiptA5CopyLandscape)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('RECEIPT.A5_DF_LSCAPE')} key={'addressRecieptSameBranch'} checked={receiptA5DfLscape || false} onChange={() => setReceiptA5DfLscape(!receiptA5DfLscape)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('RECEIPT.A5_DF_ORG_LSCAPE')} key={'addressRecieptSameBranch'} checked={receiptA5DfOrgLscape || false} onChange={() => setReceiptA5DfOrgLscape(!receiptA5DfOrgLscape)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('RECEIPT.A5_DF_COPY_LSCAPE')} key={'addressRecieptSameBranch'} checked={receiptA5DfCopyLscape || false} onChange={() => setReceiptA5DfCopyLscape(!receiptA5DfCopyLscape)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('RECEIPT.A5_FIX_DF_LSCAPE')} key={'addressRecieptSameBranch'} checked={receiptA5FixDfLscape || false} onChange={() => setReceiptA5FixDfLscape(!receiptA5FixDfLscape)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('RECEIPT.A5_FIX_DF_ORG_LSCAPE')} key={'addressRecieptSameBranch'} checked={receiptA5FixDfOrgLscape || false} onChange={() => setReceiptA5FixDfOrgLscape(!receiptA5FixDfOrgLscape)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('RECEIPT.A5_FIX_DF_COPY_LSCAPE')} key={'addressRecieptSameBranch'} checked={receiptA5FixDfCopyLscape || false} onChange={() => setReceiptA5FixDfCopyLscape(!receiptA5FixDfCopyLscape)} />
                              </Grid>
                              <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('BRANCH.INPUT.THERMAL')} key={'addressRecieptSameBranch'} disabled checked={receiptThermal || false} onChange={() => setReceiptThermal(!receiptThermal)} />
                              </Grid>
                              {/* <Grid item sm={6} lg={3} xl={3}>
                                <InputCheckbox label={t('RECEIPT.THERMAL_DF')} key={'addressRecieptSameBranch'} checked={receiptThermalDf || false} onChange={() => setReceiptThermalDf(!receiptThermalDf)} />
                              </Grid> */}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {branchTab === 'WORK_TIME' && (
                      <div className="mt-3" style={{ display: branchTab === 'WORK_TIME' ? 'block' : 'none' }}>
                        <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.WORK_TIME_TITLE')}
                        </Typography>
                        <UseStyles.CustomTable className="ml-xl-2 overflow-auto custom-scroll">
                          <Table className={'table'} sx={{ border: `1px solid ${colors.lightGray}`, minWidth: 800 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="py-3 title-work">{t('BRANCH.INPUT.WORK_DATE_COL')}</TableCell>
                                <TableCell className="py-3 title-work" sx={{ width: '320px' }}>
                                  {t('TIME_DURATION_TYPE.DAY')}
                                </TableCell>
                                <TableCell className="py-3 title-work">{t('BRANCH.INPUT.WORK_TIME')}</TableCell>
                                <TableCell align="center" className="py-3 title-work" sx={{ width: '70px' }}>
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <a className="btn text-second" onClick={addBranchTime}>
                                    <span style={{ color: colors.themeSecondColor }}>+</span>
                                    <span className="ml-1" style={{ color: colors.themeSecondColor }}>
                                      {t('BRANCH.TAB.ADD_ITEM_WORK')}
                                    </span>
                                  </a>
                                </TableCell>
                              </TableRow>
                              {branchTimes.map((branchTime: any, iBranchTime) => (
                                <TableRow key={`row-${iBranchTime}`}>
                                  <TableCell>
                                    <BasicSelect
                                      onchange={(event) => {
                                        const value = event.target.value
                                        if (value) {
                                          handleChangeBranchTimeType(value, iBranchTime)
                                        }
                                      }}
                                      label={''}
                                      selectId="select-status"
                                      value={branchTime.typetime}
                                      labelId="label-status"
                                      options={[
                                        <MenuItem key="1" value="OPEN">
                                          {t('BRANCH.INPUT.WORK_DATE_TYPE_OPEN')}
                                        </MenuItem>,
                                        <MenuItem key="2" value="CLOSE">
                                          {t('BRANCH.INPUT.WORK_DATE_TYPE_CLOSE')}
                                        </MenuItem>
                                      ]}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {dateOfWeek.map((day, i) => (
                                      <UseStyles.BtnDay
                                        key={day}
                                        className={`btn mx-1 text-nowrap text-center px-1 btn-day d-inline-flex align-items-center justify-content-center ${branchTime.days[day.toLowerCase()] ? 'active' : ''} ${branchTime.typetime === 'OPEN' ? 'open' : 'close'
                                          }`}
                                        onClick={(e) => handleChangeBranchTimeDate(day.toLowerCase(), iBranchTime)}
                                      >
                                        {t(`DAY_SHORT_${day.toUpperCase()}`)}
                                      </UseStyles.BtnDay>
                                    ))}
                                  </TableCell>
                                  <TableCell>
                                    <InputNewRangeTimePicker
                                      value={[moment(branchTime.timeStart, 'HH:mm').format('HH:mm'), moment(branchTime.timeEnd, 'HH:mm').format('HH:mm')]}
                                      onChange={(e) => handleChangeBranchTime(e, iBranchTime)}
                                      disabled={branchTime.typetime === 'CLOSE'}
                                      style={{ maxWidth: '300px' }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton
                                      className={'btn-delete'}
                                      disableFocusRipple
                                      disableRipple
                                      disableTouchRipple
                                      aria-label="delete"
                                      onClick={(e) => deleteBranchTime(e, iBranchTime)}
                                      sx={{
                                        backgroundColor: 'transparent !important',
                                        padding: 0,
                                        '&:disabled': {
                                          filter: 'grayscale(1)',
                                          opacity: 0.6
                                        }
                                      }}
                                    >
                                      <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </UseStyles.CustomTable>
                      </div>
                    )}
                    {branchTab === 'SERVICE_POINT' && (
                      <div className="mt-3" style={{ display: branchTab === 'SERVICE_POINT' ? 'block' : 'none' }}>
                        <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.SERVICE_POINT_BRANCH')}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} className="w-100">
                            <a className="btn text-second p-0 mb-1" onClick={addBranchServicePoint}>
                              <span style={{ color: colors.themeSecondColor }}>+</span>
                              <span className="ml-1" style={{ color: colors.themeSecondColor }}>
                                {t('BRANCH.INPUT.ADD_SERVICE_POINT')}
                              </span>
                            </a>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={4} className="w-100">
                            <Grid container spacing={3} className={''}>
                              {branchServicePoints.map((branchServicePoint: any, iBranchServicePoint) => (
                                <Grid item xs={12} key={`service-point-${iBranchServicePoint}`} className="w-100 d-flex">
                                  <BasicSelect
                                    onchange={(event) => {
                                      const servicePointId = event.target.value
                                      if (servicePointId) {
                                        handleChangeServicePoint(servicePointId, iBranchServicePoint)
                                      }
                                    }}
                                    label={t('BRANCH.SERVICE_POINT')}
                                    selectId="select-status"
                                    value={branchServicePoint.servicePointId}
                                    labelId="label-status"
                                    options={servicePoints.map((servicePoint: any, iServicePoint) => (
                                      <MenuItem
                                        key="1"
                                        value={servicePoint.servicePointId}
                                        className={`${_.some(branchServicePoints, (i) => i.servicePointId === servicePoint.servicePointId) ? 'd-none' : ''}`}
                                        disabled={_.some(branchServicePoints, { servicePointId: servicePoint.servicePointId }) ? true : false}
                                      >
                                        {servicePoint.servicePointName}
                                      </MenuItem>
                                    ))}
                                  // style={{ pointerEvents: branchServicePoints.length === 1 || iBranchServicePoint === 0 ? 'none' : 'auto' }}
                                  />
                                  <IconButton
                                    className={'btn-delete ml-3'}
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                    disabled={branchServicePoints.length === 1 || iBranchServicePoint === 0}
                                    aria-label="delete"
                                    onClick={(e) => deleteBranchServicePoint(e, iBranchServicePoint)}
                                    sx={{
                                      backgroundColor: 'transparent !important',
                                      padding: 0,
                                      '&:disabled': {
                                        filter: 'grayscale(1)',
                                        opacity: 0.6
                                      }
                                    }}
                                  >
                                    <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                                  </IconButton>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {branchTab === 'BANK' && (
                      <div className="mt-3" style={{ display: branchTab === 'BANK' ? 'block' : 'none' }}>
                        <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.BANK_NAME')}
                        </Typography>
                        <UseStyles.BankType className="ml-xl-2">
                          {banks.map((bank: any) => (
                            <a className={`btn bg-second-color bank-logo p-0 mx-1 mb-3 ${branchBanks.length && branchBanks.find((bb) => bb.bankId === bank.bankId) ? 'active' : ''}`} onClick={(e) => selectBank(bank.bankId)} key={bank.bankId}>
                              <img src={bank.image ? `${fileUrl}/${bank.image?.filePath}/${bank.image?.fileName}` : bank.bankLogo} alt={''} onError={onImgError} />
                            </a>
                          ))}
                        </UseStyles.BankType>
                        <UseStyles.CustomTable className="ml-xl-2 overflow-auto custom-scroll">
                          <Table className={'table'} sx={{ minWidth: '800px' }}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="border-0" sx={{ width: '22%' }}>
                                  {t('BRANCH.INPUT.BANK_NAME')}
                                </TableCell>
                                <TableCell className="border-0" sx={{ width: { lg: '280px' } }}>
                                  <span className="mr-2">{t('BRANCH.INPUT.SERVICE_SUPPORT')}</span>
                                  <ButtonInfoSimple
                                    text={
                                      <>
                                        {t('BRANCH.PAYMENT.BANK')}
                                        <br />
                                        {t('BRANCH.PAYMENT.CREDIT')}
                                      </>
                                    }
                                  />
                                </TableCell>
                                <TableCell className="border-0">{t('BRANCH.INPUT.BANK_ACCOUNT_NAME_TRANSFER')}</TableCell>
                                <TableCell className="border-0">{t('BRANCH.INPUT.BANK_ACCOUNT_NUMBER')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(!_.isEmpty(branchBanks) &&
                                branchBanks.map((branchBank: any, iBranchBank) => (
                                  <TableRow key={`row-${iBranchBank}`}>
                                    <TableCell className="border-0">
                                      <div className="d-flex align-items-center">
                                        <div style={{ width: '35px', height: '35px' }}>
                                          <img
                                            src={renderBankLogo(branchBank.bankId)}
                                            alt=""
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              objectFit: 'contain'
                                            }}
                                            onError={onImgError}
                                          />
                                        </div>
                                        <span className="pl-2">{branchBank.bankName}</span>
                                      </div>
                                    </TableCell>
                                    <TableCell className="border-0">
                                      <div className="d-flex">
                                        <InputCheckbox
                                          label={t('BRANCH.INPUT.TRANSFER')}
                                          key={`transfer-${iBranchBank}`}
                                          checked={branchBank.hasTransfer === 'SUPPORT' || branchBank.hasTransfer === true}
                                          onChange={(e) => handleChangeBranchBank(e.target.checked ? 'SUPPORT' : 'NOT_SUPPORT', iBranchBank, 'hasTransfer')}
                                          disabled={branchBank.disabledTransfer}
                                        />
                                        <InputCheckbox
                                          label={t('BRANCH.INPUT.DEBIT')}
                                          key={`debit-${iBranchBank}`}
                                          checked={branchBank.hasDebit === 'SUPPORT' || branchBank.hasDebit === true}
                                          onChange={(e) => handleChangeBranchBank(e.target.checked ? 'SUPPORT' : 'NOT_SUPPORT', iBranchBank, 'hasDebit')}
                                          disabled={branchBank.disabledDebit}
                                        />
                                        <InputCheckbox
                                          label={t('BRANCH.INPUT.CREDIT')}
                                          key={`credit-${iBranchBank}`}
                                          checked={branchBank.hasCredit === 'SUPPORT' || branchBank.hasCredit === true}
                                          onChange={(e) => handleChangeBranchBank(e.target.checked ? 'SUPPORT' : 'NOT_SUPPORT', iBranchBank, 'hasCredit')}
                                          disabled={branchBank.disabledCredit}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell className="border-0">
                                      <InputTextField
                                        value={branchBank.bankAccountName}
                                        hookValue={true}
                                        onchange={(event) => handleChangeBranchBank(event.target.value, iBranchBank, 'bankAccountName')}
                                        helperText={!branchBank.bankAccountName ? errorMessage.EMPTY_BANK_ACCOUNT_NAME : ''}
                                        disabled={branchBank.disabledTransfer && branchBank.disabledDebit && branchBank.disabledCredit}
                                      />
                                    </TableCell>
                                    <TableCell className="border-0">
                                      <InputTextField
                                        value={branchBank.bankAccountNumber}
                                        hookValue={true}
                                        onchange={(event) => handleChangeBranchBank(event.target.value, iBranchBank, 'bankAccountNumber')}
                                        helperText={!branchBank.bankAccountNumber ? errorMessage.EMPTY_BANK_ACCOUNT_NUMBER : ''}
                                        disabled={branchBank.disabledTransfer && branchBank.disabledDebit && branchBank.disabledCredit}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))) || (
                                  <TableRow key={`empty-iBranchBank`}>
                                    <TableCell className="border-0 text-center" colSpan={4}>
                                      <Typography>{t('BRANCH.PAYMENT.NO_BANKING')}</Typography>
                                    </TableCell>
                                  </TableRow>
                                )}
                            </TableBody>
                          </Table>
                        </UseStyles.CustomTable>
                      </div>
                    )}
                    {/*  */}
                    {branchTab === 'FORMATFORM' && (
                      <div className="mt-4" style={{ display: branchTab === 'FORMATFORM' ? 'block' : 'none' }}>
                        <FormFormatForm
                          formRegister={{ data: formRegister, setData: setFormRegister }}
                          formAppointment={{ data: formAppointment, setData: setFormAppointment }}
                        />
                      </div>
                    )}
                    {branchTab === 'SENTTING' && (
                      <div className="mt-4" style={{ display: branchTab === 'SENTTING' ? 'block' : 'none' }}>
                        <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                          {t('BRANCH.TAB.SENTTING.SHOWPATIENT')}
                          {t('BRANCH.TAB.SENTTING.SYSTEM')}
                        </Typography>
                        <Grid item sm={12} xs={12} md={10} className={'w-100 pl-3'}>
                          <FormControl className="d-block pl-3 ">
                            <RadioGroup
                              row
                              aria-label="defaultReceipt"
                              onChange={(event) => setPatientCodeAll(event.target.value)}
                              className="flex-row row"
                              name="row-radio-buttons-group"
                            >
                              <UseStyles.FormFormatReceipt
                                className={`ml-0 mr-2 pr-3 col-12 col-md ${patientCodeAll === '1' ? 'active' : ''}`}
                                checked={patientCodeAll === '1'}
                                value="1"
                                control={<InputRadio />}
                                label={`${t('BRANCH.TAB.SENTTING.SHOW_CN')}`}
                              />
                              <UseStyles.FormFormatReceipt
                                className={`ml-0 mr-2 pr-3 col-12 col-md ${patientCodeAll === '0' ? 'active' : ''}`}
                                checked={patientCodeAll === '0'}
                                value="0"
                                control={<InputRadio />}
                                label={`${t('BRANCH.TAB.SENTTING.SHOW_PT')}`}
                              />
                              {snType && (
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${patientCodeAll === '2' ? 'active' : ''}`}
                                  checked={patientCodeAll === '2'}
                                  value="2"
                                  control={<InputRadio />}
                                  label={`${t('BRANCH.TAB.SENTTING.SHOW_SN')}`}
                                />
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <div className={'mt-3 mb-3'}>
                          <Divider className="mx-0 mt-3 " sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        </div>

                        <Box sx={{ mt: '1.2rem' }}>
                          <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                            {t('BRANCH.INPUT.APPOINTMENT_TIME')}
                          </Typography>
                          <Grid item sm={12} xs={12} md={10} xl={6} lg={8} className={'w-100 pl-3'}>
                            <FormControl className="d-block pl-3">
                              <RadioGroup row aria-label="timeAppointment" onChange={(event: any) => setTimeAppointment(event.target.value)} className="flex-row row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${timeAppointment === TIME_APPOINTMENT.CLINIC ? 'active' : ''}`}
                                  checked={timeAppointment === TIME_APPOINTMENT.CLINIC}
                                  value={TIME_APPOINTMENT.CLINIC}
                                  control={<InputRadio />}
                                  label={`${t('BRANCH.INPUT.APPOINTMENT_TIME_BRANCH')}`}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${timeAppointment === TIME_APPOINTMENT.SCHEDULE ? 'active' : ''}`}
                                  checked={timeAppointment === TIME_APPOINTMENT.SCHEDULE}
                                  value={TIME_APPOINTMENT.SCHEDULE}
                                  control={<InputRadio />}
                                  label={`${t('BRANCH.INPUT.APPOINTMENT_TIME_SCHEDULE')}`}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                        </Box>
                        <div className={'mt-3 mb-3'}>
                          <Divider className="mx-0 mt-3 " sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        </div>

                        <Box sx={{ mt: '1.2rem' }}>
                          <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                            {t('BRANCH.INPUT.APPT_SERVICE')}
                          </Typography>
                          <Grid item sm={12} xs={12} md={10} xl={6} lg={8} className={'w-100 pl-3'}>
                            <FormControl className="d-block pl-3">
                              <RadioGroup row aria-label="apService" onChange={(event: any) => setApptService(event.target.value)} className="flex-row row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${apptService === '2' ? 'active' : ''}`}
                                  checked={apptService === '2'}
                                  value={'2'}
                                  control={<InputRadio />}
                                  label={`${t('BRANCH.INPUT.APPT_SERVICE_TYPE')}`}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${apptService === '1' ? 'active' : ''}`}
                                  checked={apptService === '1'}
                                  value={'1'}
                                  control={<InputRadio />}
                                  label={`${t('BRANCH.INPUT.APPT_SERVICE_TX')}`}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                        </Box>

                        <div className={'mt-3 mb-3'}>
                          <Divider className="mx-0 mt-3 " sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        </div>
                        <Box sx={{ mt: '1.2rem' }}>
                          <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                            {t('BRANCH.TAB.SENTTING.DF_TREAMENT')}
                          </Typography>
                          <Grid item sm={12} xs={12} md={10} xl={6} lg={8} className={'w-100 pl-3'}>
                            <FormControl className="d-block pl-3">
                              <RadioGroup row aria-label="defaultReceipt" onChange={(event) => setDefaultDfReport(event.target.value)} className="flex-row row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${defaultDfReport === '0' ? 'active' : ''}`}
                                  checked={defaultDfReport === '0'}
                                  value="0"
                                  control={<InputRadio />}
                                  label={`${t('BRANCH.TAB.SENTTING.DF_TREAMENT_PAY')}`}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${defaultDfReport === '1' ? 'active' : ''}`}
                                  checked={defaultDfReport === '1'}
                                  value="1"
                                  control={<InputRadio />}
                                  label={`${t('BRANCH.TAB.SENTTING.DF_TREAMENT_OP')}`}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                        </Box>



                        <div className={'mt-3 mb-3'}>
                          <Divider className="mx-0 mt-3 " sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        </div>
                        <Grid item sm={12} xs={12} className={`w-100 pl-3 `}>
                          <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                            {t('BRANCH.TAB.SENTTING.CONDITION_REPORT_DF')}
                          </Typography>
                          <FormControl className="d-block pl-3">
                            <RadioGroup row aria-label="defaultReceipt" onChange={(event) => setDefaultDfCon(event.target.value)} className="flex-row row" name="row-radio-buttons-group">
                              <UseStyles.FormFormatReceipt
                                className={`ml-0 mr-2 pr-3 col-12 col-md-4 col-lg-3 col-xl-4   ${defaultDfCon === '0' ? 'active' : ''}`}
                                checked={defaultDfCon === '0'}
                                value="0"
                                control={<InputRadio />}
                                label={`${t('BRANCH.TAB.SENTTING.DF_TREAMENT_PAY_1')}`}
                              />
                              <UseStyles.FormFormatReceipt
                                className={`ml-0 mr-2 pr-3 col-12 col-md-4 col-lg-3 col-xl-4   ${defaultDfCon === '1' ? 'active' : ''}`}
                                checked={defaultDfCon === '1'}
                                value="1"
                                control={<InputRadio />}
                                label={`${t('BRANCH.TAB.SENTTING.DF_TREAMENT_PAY_2')}`}
                              />
                              <UseStyles.FormFormatReceipt
                                className={`ml-0 mr-2 pr-3 col-12 col-md-4 col-lg-3 col-xl-4   ${defaultDfCon === '2' ? 'active' : ''}`}
                                checked={defaultDfCon === '2'}
                                value="2"
                                control={<InputRadio />}
                                label={`${t('BRANCH.TAB.SENTTING.DF_TREAMENT_PAY_3')}`}
                              />
                              <UseStyles.FormFormatReceipt
                                className={`ml-0 mr-2 pr-3 col-12 col-md-4 col-lg-3 col-xl-4   ${defaultDfCon === '3' ? 'active' : ''}`}
                                checked={defaultDfCon === '3'}
                                value="3"
                                control={<InputRadio />}
                                label={`${t('BRANCH.TAB.SENTTING.DF_TREAMENT_PAY_4')}`}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <div className={'mt-3 mb-3'}>
                          <Divider className="mx-0 mt-3 " sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        </div>

                        <Box sx={{ mt: '1.2rem' }}>
                          <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 500 }}>
                            {t('BRANCH.TAB.SENTTING.CAL_ADVANCE')}
                          </Typography>
                          <Grid item sm={12} xs={12} md={10} xl={6} lg={8} className={'w-100 pl-3'}>
                            <FormControl className="d-block pl-3">
                              <RadioGroup row aria-label="calculateAdvance" onChange={(event: any) => setCalculateAdvance(event.target.value)} className="flex-row row" name="row-radio-buttons-group">
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${calculateAdvance === CALCULATE_ADVANCE.DECREASE ? 'active' : ''}`}
                                  checked={calculateAdvance === CALCULATE_ADVANCE.DECREASE}
                                  value={CALCULATE_ADVANCE.DECREASE}
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.SENTTING.PAY_DECREASE')}
                                />
                                <UseStyles.FormFormatReceipt
                                  className={`ml-0 mr-2 pr-3 col-12 col-md ${calculateAdvance === CALCULATE_ADVANCE.INCREASE ? 'active' : ''}`}
                                  checked={calculateAdvance === CALCULATE_ADVANCE.INCREASE}
                                  value={CALCULATE_ADVANCE.INCREASE}
                                  control={<InputRadio />}
                                  label={t('BRANCH.TAB.SENTTING.PAY_INCREASE')}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                        </Box>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Card.Body>
          </Card>
        </div>
        {modalImg &&
          <ModalExamDoc
            onClose={() => setModalImg(!modalImg)}
            modalTitle={modalTitle}
          />
        }
      </div>
    </>
  )
}
