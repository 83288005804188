import { useState } from 'react'
import { Box, Grid, } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'
import InputSecondNewRangePicker, { PICKER_VIEWS as PICKER_VIEWS_RANGE } from 'component/Input/InputSecondNewRangePicker'
import InputNewRangeTimePicker from 'component/Input/InputNewRangeTimePicker'

export default function TestInputDate() {
    const [date, setDate] = useState<string>('');
    const [rangeDate, setRangeDate] = useState<string[]>([]);
    const [rangeTime, setRangeTime] = useState<string[]>([]);

    const handleChangeDate = (value: any) => setDate(moment(value).format('YYYY-MM-DD'))
    const handleChangeRangeDate = (value: any) => setRangeDate([value[0] ? moment(value[0]).format('YYYY-MM-DD') : '', value[1] ? moment(value[1]).format('YYYY-MM-DD') : ''])

    return (
        <Box className='m-3 overflow-auto'>
            <Box>
                DATE PICKER : {date ? moment(date).format('DD/MM/YYYY') : ''}
                <Box className='mt-3 d-flex'>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            required
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE'}
                        />
                    </Box>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            disabled
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE DISABLED'}
                        />
                    </Box>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE ERROR'}
                            helperText={'กรุณาระบุวันที่'}
                        />
                    </Box>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            allowClear
                            value={date || ''}
                            onchange={handleChangeDate}
                            onClear={() => setDate('')}
                            label={'DATE ALLOW CLEAR'}
                        />
                    </Box>
                </Box>
                <Box className='mt-2 d-flex'>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE WEEK'}
                            views={PICKER_VIEWS.WEEK}
                        />
                    </Box>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE PAST'}
                            disablePast
                        />
                    </Box>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE FUTURE'}
                            disableFuture
                        />
                    </Box>
                    <Box>
                        <InputSecondNewDatePicker
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'HIDE TODAY BUTTON'}
                            hideTodayButton
                        />
                    </Box>
                </Box>
                <Box className='mt-4 d-flex'>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE MONTH'}
                            views={PICKER_VIEWS.MONTH}
                        />
                    </Box>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            disablePast
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE MONTH AND DISABLED PAST'}
                            views={PICKER_VIEWS.MONTH}
                        />
                    </Box>
                    <Box>
                        <InputSecondNewDatePicker
                            disableFuture
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE MONTH AND DISABLED FUTURE'}
                            views={PICKER_VIEWS.MONTH}
                        />
                    </Box>
                </Box>
                <Box className='mt-4 d-flex'>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE YEAR'}
                            views={PICKER_VIEWS.YEAR}
                        />
                    </Box>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            disablePast
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE YEAR AND DISABLED PAST'}
                            views={PICKER_VIEWS.YEAR}
                        />
                    </Box>
                    <Box>
                        <InputSecondNewDatePicker
                            disableFuture
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE YEAR AND DISABLED FUTURE'}
                            views={PICKER_VIEWS.YEAR}
                        />
                    </Box>
                </Box>
                <Box className='mt-3 d-flex'>
                    <Box className='mr-2'>
                        <InputSecondNewDatePicker
                            disabledTextField
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE'}
                        />
                    </Box>
                    <Box>
                        <InputSecondNewDatePicker
                            disabled
                            disabledTextField
                            value={date || ''}
                            onchange={handleChangeDate}
                            label={'DATE'}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className='mt-3'>
                DATE RANGE PICKER : {!_.includes(rangeDate, '') ? `${moment(rangeDate[0]).format('DD/MM/YYYY')}  »  ${moment(rangeDate[1]).format('DD/MM/YYYY')}` : ''}
                <Box className='mt-3 d-flex'>
                    <Box className='mr-2' sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE'}
                            placeholder={'RANGE DATE'}
                        />
                    </Box>
                    <Box className='mr-2' sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            disabled
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE DISABLED'}
                            placeholder={'RANGE DATE DISABLED'}
                        />
                    </Box>
                    <Box className='mr-2' sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE ERROR'}
                            placeholder={'RANGE DATE ERROR'}
                            helperText={'กรุณาระบุวันที่'}
                        />
                    </Box>
                    <Box sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            allowClear
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            onClear={() => setRangeDate(['', ''])}
                            label={'RANGE DATE ALLOW CLEAR'}
                            placeholder={'RANGE DATE ALLOW CLEAR'}
                        />
                    </Box>
                </Box>
                <Box className='mt-2 d-flex'>
                    <Box className='mr-2' sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            disablePast
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE DISABLED PAST'}
                            placeholder={'RANGE DATE DISABLED PAST'}
                        />
                    </Box>
                    <Box sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            disableFuture
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE DISABLED FUTURE'}
                            placeholder={'RANGE DATE DISABLED FUTURE'}
                        />
                    </Box>
                </Box>
                <Box className='mt-4 d-flex'>
                    <Box className='mr-2' sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            views={PICKER_VIEWS_RANGE.MONTH}
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE MONTH'}
                            placeholder={'RANGE DATE MONTH'}
                        />
                    </Box>
                    <Box className='mr-2' sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            disablePast
                            views={PICKER_VIEWS_RANGE.MONTH}
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE MONTH AND DISABLED PAST'}
                            placeholder={'RANGE DATE MONTH AND DISABLED PAST'}
                        />
                    </Box>
                    <Box sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            disableFuture
                            views={PICKER_VIEWS_RANGE.MONTH}
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE MONTH AND DISABLED FUTURE'}
                            placeholder={'RANGE DATE MONTH AND DISABLED FUTURE'}
                        />
                    </Box>
                </Box>
                <Box className='mt-4 d-flex'>
                    <Box className='mr-2' sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            views={PICKER_VIEWS_RANGE.YEAR}
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE YEAR'}
                            placeholder={'RANGE DATE YEAR'}
                        />
                    </Box>
                    <Box className='mr-2' sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            disablePast
                            views={PICKER_VIEWS_RANGE.YEAR}
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE YEAR AND DISABLED PAST'}
                            placeholder={'RANGE DATE YEAR AND DISABLED PAST'}
                        />
                    </Box>
                    <Box sx={{ width: '300px' }}>
                        <InputSecondNewRangePicker
                            disableFuture
                            views={PICKER_VIEWS_RANGE.YEAR}
                            value={rangeDate}
                            onchange={handleChangeRangeDate}
                            label={'RANGE DATE YEAR AND DISABLED FUTURE'}
                            placeholder={'RANGE DATE YEAR AND DISABLED FUTURE'}
                        />
                    </Box>
                </Box>
            </Box>

            <Grid container spacing={2} className={'mt-4'}>
                <Grid item lg={3}>
                    <InputNewRangeTimePicker
                        value={rangeTime}
                        onChange={(e) => setRangeTime(e)}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
