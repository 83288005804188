import React, { useCallback, useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import moment from 'moment'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

/** TRANSITION */
import 'i18n.js'

/** API */
import { routeName } from 'routes/routes-name'
import routes from 'routes'
import { menuFirsts, menuInside } from 'constants/menus'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'
import AboutUs from 'api/admin/clinic-manage/about.api'

/** COMPONENT */
import Header from 'component/Header/Header'
import Sidebar from 'component/SlideMenu/Sidebar'
import PageNotFound from 'features/page/notFound'
import PageExpiredWebsite from 'features/page/expiredWebsite'
import TestInputDatePage from 'features/page/TestInputDatePage'
import Login from 'features/auth/login'
import LineCallback from 'features/counter/register/line/line-callback'
import Pick from 'features/pick/pick'
import ResetPassword from 'features/auth/reset-password'
import ConfirmAppointment from 'features/auth/confirm-appointment'
import PermissionDenied from 'features/page/permissionDenied'


/** UTILS | CONTEXT  */
import { getBranch, getServicePoint, getToken, logout, checkPackage, getPackageExpired, getPermissionMenus, getPermissionShortcuts, getPermissions, getClinicInfo } from 'utils/app.utils'
import { checkRememberLogin } from 'context/login-context'
import { KEY_STORAGE, reloadClinicInfoAsMinute } from 'constants/common'
import { editMe, editBranchInfo } from 'app/slice/user.slice'
import { ThemeIcon } from 'constants/theme'

/** STYLE  */
import { styled } from '@mui/material/styles'
import { scale } from 'constants/scale'
import { Config } from 'config'
import ChangeCost from 'features/approvel-list/list/change-cost'
import PaymentDetail from 'features/finance/payment/payment-detail'

const ContainerComponent = styled('div')(({ theme }) => ({
  '&.no-appBar': {
    top: '0 !important',
    height: '100vh !important'
  }
}))

const ContainerMenu = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '0',
  zIndex: 1200,
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    maxWidth: `calc(${scale.sidebarSize})`
  },
  [theme.breakpoints.up('xl')]: {
    width: '100%',
    maxWidth: scale.sidebarSize
  }
}))

const ContainerContent = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    maxWidth: `calc(100% - ${scale.sidebarSize})`
  },
  '> div': {
    width: '100% !important',
    display: 'block !important',
    minHeight: 'unset !important',
    border: 'none !important',
    boxShadow: 'none important',
    marginTop: '0 !important'
  },
  '& .card-header': {
    width: '100% !important',
    height: 'auto !important'
  }
}))

export default function App() {
  const dispatch = useDispatch()
  const [webExpired, setWebExpired] = useState(false)
  const [loadPermission, setLoadPermission] = useState(true)
  const [menuKeys, setMenuKeys] = useState<string[]>([menuInside.DOCTOR_EMR_HISTORY.key, menuFirsts.WELCOME.key])
  const [menuPermissions, setMenuPermissions] = useState([])

  const currPath = window.location.href
  const token = getToken()
  const branchId = getBranch()
  const servicePointId = getServicePoint()
  const permissionMenus = getPermissionMenus()
  const permissionShortcuts = getPermissionShortcuts()
  const clinicInfo = getClinicInfo()
  document.title = `${Config.REACT_APP_APP_NAME}`

  const [languageCurrent, setLanguageCurrent] = useState(localStorage.getItem(KEY_STORAGE.LANGUAGE) || 'TH')

  useEffect(() => {
    moment.locale(languageCurrent.toLowerCase())
  }, [languageCurrent])

  const whoami = useCallback(() => {
    PersonnelApi.findProfile()
      .then(({ data }) => {
        if (data) dispatch(editMe({ value: data, key: 'me' }))
        else autoLogout()
      })
      .catch((e) => autoLogout())
  }, [])

  function PrivateRoute({ exact, path, key, keyName, component: Component }: any) {
    whoami()
    const menuPermissionByKey = _.find(menuPermissions, { menuFirstKey: keyName }) || _.find(menuPermissions, { menuSecondKey: keyName }) || _.find(menuPermissions, { menuThirdKey: keyName })
    const permissions = { ...getPermissions(), activeMenu: menuPermissionByKey }
    localStorage.setItem(KEY_STORAGE.PERMISSION, JSON.stringify(permissions))
    return (
      <Route exact={exact} path={path} key={key}>
        {Component}
      </Route>
    )
  }

  function PickRoute({ exact, path, key, component: Component }: any) {
    if (token && (!branchId || !servicePointId || !permissionMenus || !permissionShortcuts || !clinicInfo)) {
      return (
        <>
          <Route exact={exact} path={path} key={key} component={Component} />
          <Redirect to={path} />
        </>
      )
    } else if (currPath.includes('reset-password')) {
      return <Redirect to={window.location.pathname} />
    } else if (currPath.includes('confirm-appointment')) {
      return <Redirect to={window.location.pathname} />
    } else if (currPath.includes('approvel-list/change-cost/?')) {
      return <Redirect to={window.location.pathname} />
    } else if (currPath.includes('approvel-list/cancel-reciept/?')) {
      return <Redirect to={window.location.pathname} />
    } else {
      logout()
      return <Redirect to={routeName.login} />
    }
  }

  const handleLoadMenus = (menus: any) => {
    const allMenusKey: any = []
    const menuPermission: any = []
    _.map(menus, (menu, indexMenus1) => {
      allMenusKey.push(menu.menuFirstKey)
      menuPermission.push(_.omit(menu, 'menuSeconds'))
      _.map(menu.menuSeconds, (menu2, indexMenus2) => {
        allMenusKey.push(menu2.menuSecondKey)
        menuPermission.push(_.omit(menu2, 'menuThirds'))
        _.map(menu2.menuThirds, (menu3, indexMenus3) => {
          allMenusKey.push(menu3.menuThirdKey)
          menuPermission.push(menu3)
        })
      })
    })
    setMenuPermissions(menuPermission)
    setMenuKeys([...menuKeys, ...allMenusKey])
    setLoadPermission(false)
  }

  const checkDateExpired = (expiredDate: string) => {
    const day = expiredDate || getPackageExpired()
    if (day) {
      const diff = moment(day).add(1, 'days').diff(moment().subtract(1, 'days'), 'days')
      if (diff <= 0) setWebExpired(true)
      else setWebExpired(false)
    }
  }

  const checkPermission = (key: string) => {
    return _.includes(menuKeys, key)
  }

  const checkLastUpdateClinicInfo = () => {
    const now = moment()
    const lastUpdate = _.get(clinicInfo, 'lastUpdate') ? moment(clinicInfo.lastUpdate, 'YYYY-MM-DD HH:mm') : ''
    const duration = lastUpdate ? moment.duration(now.diff(lastUpdate)).asMinutes() : 0
    if (duration >= reloadClinicInfoAsMinute && branchId) {
      AboutUs.detailClinic(Number(branchId))
        .then(({ data }) => {
          dispatch(editBranchInfo({ value: { data } }))
          localStorage.setItem(KEY_STORAGE.CLINIC_INFO, JSON.stringify({ ...data, lastUpdate: moment().format('YYYY-MM-DD HH:mm') }))
        })
        .catch((e) => {
          return
        })
    }
  }

  const autoLogout = () => {
    logout()
    if (!currPath.includes('login')) window.location.href = routeName.login
    return <Redirect to={routeName.login} />
  }

  useEffect(() => {
    const favicon: any = document.querySelector('[rel=icon]')
    const apFavicon: any = document.querySelector('[rel=apple-touch-icon]')
    if (favicon) favicon.href = ThemeIcon.Favicon
    if (apFavicon) apFavicon.href = ThemeIcon.Favicon
  }, [])

  useEffect(() => {
    checkDateExpired(getPackageExpired())
  }, [getPackageExpired])

  useEffect(() => {
    checkRememberLogin()
    checkLastUpdateClinicInfo()
    handleLoadMenus(permissionMenus)
  }, [])
  const userIdFromParam = window.location?.search?.substring(1)
  const pathReportDf = window.location?.pathname === '/report/df-lab'
  const pathReportLabOperative = window.location?.pathname === '/report/df-lab-operative'
  return (
    <>
      <Router>
        <Switch>
          <Route exact key="pdf" path={'/test-date-picker'} component={TestInputDatePage} />
          {currPath.includes('line-connect/callback') && <Route key="lineCallback" path={`${routeName.register}/line-connect/callback`} component={LineCallback} />}
          {currPath.includes('reset-password') && <Route key="reset-password" path={routeName.resetPassword} component={ResetPassword} />}
          {currPath.includes('confirm-appointment') && <Route key="confirm-appointment" path={routeName.confirmAppointment} component={ConfirmAppointment} />}
          {currPath.includes('approvel-list/change-cost/?') && <Route key="change-cost" path={routeName.approvelListCost} component={ChangeCost} />}
          {currPath.includes('approvel-list/cancel-reciept/?') && <Route key="change-cost" path={routeName.approvelListCancelReciept} component={PaymentDetail} />}
          {(token &&
            branchId &&
            servicePointId &&
            permissionMenus &&
            permissionShortcuts &&
            !_.isEmpty(clinicInfo) &&
            ((webExpired && <Route key="PageExpiredWebsite" component={PageExpiredWebsite} />) ||
              routes.map(({ name, path, component, appBar, textName, key, packageKey }, idx) => (
                <PrivateRoute
                  exact
                  path={path}
                  key={name || idx}
                  keyName={key}
                  component={
                    <>
                      {appBar && <Header menuName={textName} />}
                      <ContainerComponent className={`div-component d-flex ${appBar ? 'flex-row' : 'flex-column no-appBar'}`}>
                        {appBar && <ContainerMenu className={'container-menu'}>{<Sidebar />}</ContainerMenu>}
                        <ContainerContent className={`container-content ${!appBar && 'mw-100'}`}>
                          {!loadPermission &&
                            ((checkPermission(key) && checkPackage(packageKey)) || (userIdFromParam && (pathReportDf || pathReportLabOperative))
                              ? component && React.createElement(component)
                              : (key === 'BACKEND' && component && React.createElement(component)) || <PermissionDenied />)}
                        </ContainerContent>
                      </ContainerComponent>
                    </>
                  }
                />
              )))) || (
              <>
                <Route key="login" path={routeName.login} component={Login} />
                <PickRoute key="pick" path={routeName.pick} component={Pick} />
              </>
            )}
          <Route key="PageNotFound" component={PageNotFound} />
        </Switch>
      </Router>
      <ToastContainer enableMultiContainer />
    </>
  )
}
