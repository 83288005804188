export const scale = {
  navbarHeight: '96px',
  sidebarSize: '80px',
  expiredAlertHeight: '35px',
  secondToggle: '130px',
  secondToggleStore: '198px',
  containerCollapse: {
    leftIsCollapse: '2rem',
    leftSide: '30%',
    rightSide: '70%'
  },
  borderRadiusXs: '4px',
  rangeOfMinute: 6
}
