import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface CurrenciesInterface {
  currencyCode: string
  currencyDisplay: string
  currencyName: string
  currencyNameEn: string
  fixRatio: string
  ratio: number
  sorting: number
  status?: string
}

export default class CurrenciesApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('currencies', { params: { ...props } }).then((res) => res)
  }
  static create(props: CurrenciesInterface) {
    return this.api.post('currencies', { ...props })
  }
  static findById(id: number): Promise<CurrenciesInterface> {
    return this.api.get(`currencies/${id}`)
  }
  static update(id: number, body: CurrenciesInterface) {
    return this.api.patch(`currencies/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`currencies/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`currencies/${id}`)
  }
}
