import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash'

import { numberFormat, toBuddhistYear } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'

import * as UseStyled from 'features/report/useStyled'
import { Box, TableCell, TableRow, styled } from '@mui/material';

const BoxTable = styled(Box)(({ theme }) => ({
    'div': {
        'div': {
            'table': {
                minWidth: "500px",
            }
        }
    }
}))

interface LabReportListTotalProps {
    type: string
    date: any
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
}

export default function LabReportListTotal(props: LabReportListTotalProps) {
    const { t } = useTranslation()
    const componentRefPDF = useRef<HTMLDivElement>(null)

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: '#', align: 'center', class: 'text-nowrap', width: "100px" },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.LAB_FEE', align: 'center', class: 'text-nowrap', width: "140px" },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.DF_LAB_FEE', align: 'center', class: 'text-nowrap', width: "140px" },
    ]

    const renderDataDF = (data: any, no: number, Export?: boolean) => {

        const num = props.page * props.pageLimit - props.pageLimit + no + 1
        const objRenderData = {
            key: num.toString(),
            id: no.toString(),
            obj: data,
            columns: [
                { option: 'TEXT', align: 'center', style: { fontSize: 16 }, label: toBuddhistYear(moment(data[0].dateOrder), 'DD/MM/YYYY'), class: 'text-nowrap' },
                { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberFormat(_.sumBy(data, 'labOrderPrice')), class: 'text-nowrap' },
                { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberFormat(_.sumBy(data, 'labDoctorTotal')), class: 'text-nowrap' },
            ]
        }
        return (
            <>
                <TableRowCommon  {...objRenderData} />
            </>
        )

    }

    const rendeerSum = (data: any) => {
        return (
            <TableRow>
                <TableCell style={{ fontWeight: 'bold' }} align="left" scope="row">
                    {t('REPORT.TITLE.SUM')}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right" scope="row">
                    {numberFormat(_.sumBy(data, 'labOrderPrice'))}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right" scope="row">
                    {numberFormat(_.sumBy(data, 'labDoctorTotal'))}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            {props.type !== "export" ?
                <BoxTable sx={{ maxWidth: '500px' }}>
                    <UseStyled.ResponsiveReportTable>
                        <TableCustom
                            customScroll
                            hidePagination
                            page={props.page}
                            pageLimit={props.pageLimit}
                            sortType={props.sortType}
                            sortBy={props.sortBy}
                            rowCount={props.rowCount}
                            onSort={props.onRequestSort}
                            setPageLimit={props.handleChangeRowsPerPage}
                            setPage={props.handleChangePage}
                            headCells={headCells}
                            rowsData={[
                                _.map(_.groupBy(props.data, 'dateOrder'), (data: any, index: any) => {
                                    return !_.isEmpty(data) ? [
                                        renderDataDF(data, index)
                                    ] : []
                                }),
                                rendeerSum(props.data)

                            ]}
                        />
                    </UseStyled.ResponsiveReportTable>
                </BoxTable>
                :
                <div className="print-show" ref={componentRefPDF}>
                    <Box sx={{ mt: "2rem" }}>
                        <Box sx={{ fontSize: 10, fontWeight: 500, }}>
                            {`${t('REPORT.FILTER.DAY')} ${toBuddhistYear(moment(props.date[0]), 'DD/MM/YYYY')} >> ${toBuddhistYear(moment(props.date[1]), 'DD/MM/YYYY')}`}
                        </Box>
                        <UseStyled.Styles className="pt-3">
                            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '500px', maxWidth: '100%' }}>
                                <thead>
                                    <tr>
                                        {_.map(headCells, (val: any, index: number) => {
                                            return <td style={{ textAlign: index === 0 ? "left" : val.align, fontSize: 10, fontWeight: 500, border: "1px solid", width: index === 0 ? "100px" : "auto" }}> {t(val.labelKey)}</td>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(_.groupBy(props.data, 'dateOrder'), (data: any, index: any) => {
                                        return <tr>
                                            <td style={{ textAlign: "left", fontSize: 10, border: "1px solid" }}>{toBuddhistYear(moment(data[0].dateOrder), 'DD/MM/YYYY')}</td>
                                            <td style={{ textAlign: "right", fontSize: 10, border: "1px solid" }}>{numberFormat(_.sumBy(data, 'labOrderPrice'))}</td>
                                            <td style={{ textAlign: "right", fontSize: 10, border: "1px solid" }}>{numberFormat(_.sumBy(data, 'labDoctorTotal'))}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td style={{ textAlign: "left", fontSize: 10, border: "1px solid" }}>{t('รวม')}</td>
                                        <td style={{ textAlign: "right", fontSize: 10, border: "1px solid" }}>{numberFormat(_.sumBy(props.data, 'labOrderPrice'))}</td>
                                        <td style={{ textAlign: "right", fontSize: 10, border: "1px solid" }}>{numberFormat(_.sumBy(props.data, 'labDoctorTotal'))}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </UseStyled.Styles>
                    </Box>
                </div>}
        </>
    )
}

