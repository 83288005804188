import Webcam from "react-webcam";

/** STYLE */
import * as UseStyle from 'features/counter/register/usedStyles';

interface PropInsertImage {
    trigger: boolean,
    children?: any
}

export default function PopupInsertImg(props: PropInsertImage) {
    console.assert(props.children)

    return props.trigger ? (
        <UseStyle.Background>
            <UseStyle.Popupstyles>
                <UseStyle.InsidePopup>
                    {props.children}
                </UseStyle.InsidePopup>
            </UseStyle.Popupstyles>
        </UseStyle.Background>
    ) : <></>
}