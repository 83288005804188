import { Box, styled } from "@mui/material";
import { colors } from "constants/theme";
import { makeStyles } from '@mui/styles'

export const BoxCheckIn = styled(Box)(({ theme }) => ({
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'hidden',
    '.box-container-input': {
        display: 'none'
    },
    [theme.breakpoints.down(1000)]: {
        '.box-container-input': {
            display: 'block !important',
            '.box-content-welcome-check-in': {
                position: 'fixed',
                zIndex: '3',
                top: '50%',
                left: '0',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '.box-content-input-phone': {
                    padding: '0 24px',
                    maxWidth: '390px',
                    width: '100%'
                },
                [theme.breakpoints.down(400)]: {
                    left: '0 !important',
                    width: '100%',
                    '.box-content-input-phone': {
                        maxWidth: '100% !important',
                    }
                },
            },
            '.box-content-btn-check-in': {
                position: 'fixed',
                zIndex: '3',
                bottom: '82px',
                left: '0',
                width: '100%',
                '.btn-check-in': {
                    padding: '0 24px',
                    '.MuiButtonBase-root': {
                        maxWidth: '390px'
                    },
                    [theme.breakpoints.down(500)]: {
                        '.MuiButtonBase-root': {
                            maxWidth: '100% !important'
                        }
                    },
                }
            }
        },
    },
    '.box-clinic-detail': {
        maxWidth: '766px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '2',
        [theme.breakpoints.down(1500)]: {
            maxWidth: '40%',
        },
        [theme.breakpoints.down(1000)]: {
            maxWidth: '100vw',
            justifyContent: 'flex-start',
        },
        '.box-clinic-img-xl': {
            'img': {
                width: '100%',
                height: '100vh',
                objectFit: 'cover'
            }
        },
        '.box-clinic-content': {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down(1500)]: {
                '.text-clinicname': {
                    fontSize: '20px !important'
                },
                '.text-clinicnameen': {
                    fontSize: '20px !important'
                },
                '.text-branch-name': {
                    fontSize: '16px !important'
                },
            },
            [theme.breakpoints.down(1000)]: {
                // marginTop: '30px',
                top: '0px',
                width: '100%',
                padding: '30px 24px 0 24px',
                height: '100%',
                justifyContent: 'flex-start',
                '.box-clinic-lang': {
                    display: 'block !important',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                    '.menu-lang': {
                        right: '170px',
                        left: 'unset'
                    }
                },
                '.box-clinic-logo': {
                    'img': { width: '100% !important', height: '120px !important' }
                },
                '.text-welcome-check-in': {
                    display: 'block !important'
                },
            },
            '.box-clinic-lang': {
                display: 'none',
            },
            '.box-clinic-logo': {
                'img': { width: '100%', height: '198px' }
            },
            '.text-welcome-check-in': {
                display: 'none'
            },
        }
    },
    '.box-check-in-content': {
        maxWidth: 'calc(100% - 766px)',
        width: '100%',
        position: 'relative',
        backgroundColor: '#F8FCFC',
        [theme.breakpoints.down(1500)]: {
            maxWidth: '60%',
        },
        [theme.breakpoints.down(1000)]: {
            display: 'none'
        },
        '.check-in-content-img': {
            position: 'absolute',
            zIdex: '1'
        },
        '.box-container-check-in': {
            zIndex: '2',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '70px',
            [theme.breakpoints.down(1750)]: {
                padding: '20px 70px 0px',
            },
            [theme.breakpoints.down(1500)]: {
                padding: '30px',
            },
            '.box-content-lang-date': {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '32px',
                width: '100%',
                '.box-select-lang': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#808080',
                    cursor: 'pointer',
                    position: 'relative',
                    '.menu-lang': {
                        left: '-100%', top: '32px'
                    },
                    [theme.breakpoints.down(1500)]: {
                        '.text-lang-current': {
                            fontSize: '16px !important'
                        }
                    },
                },
                '.box-show-date': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: '#808080',
                    fontSize: '24px',
                    fontWeight: '600',
                    [theme.breakpoints.down(1500)]: {
                        fontSize: '16px !important'
                    },
                }
            },
            '.box-content-welcome-check-in': {
                position: 'relative',
                zIndex: '2',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '120px',
                [theme.breakpoints.down(1500)]: {
                    gap: '80px',
                },
                '.text-welcome-check-in': {
                    color: colors.themeSecondColor,
                    fontSize: '64px',
                    fontWeight: '500',
                    [theme.breakpoints.down(1500)]: {
                        fontSize: '32px',
                    },
                },
                '.box-content-input-phone': {
                    [theme.breakpoints.down(1500)]: {
                        maxWidth: '400px',
                        width: '100%',
                    },
                    '.text-label': {
                        fontSize: '32px',
                        [theme.breakpoints.down(1500)]: {
                            fontSize: '16px',
                        },
                    },
                    '.box-input-phone': {
                        marginTop: '24px',
                        [theme.breakpoints.down(1500)]: {
                            marginTop: '12px',
                        },
                        'label': {
                            top: 'calc(50% - 32px) !important',
                            fontSize: '32px !important',
                            [theme.breakpoints.down(1500)]: {
                                top: '0px !important',
                                fontSize: '16px  !important',
                            },
                            'span': {
                                fontSize: '32px !important',
                                [theme.breakpoints.down(1500)]: {
                                    fontSize: '16px  !important',
                                },
                            },
                            '&[data-shrink="true"]': {
                                top: '-10px !important',
                                backgroundColor: '#F8FCFC !important',
                                [theme.breakpoints.down(1500)]: {
                                    top: '0px !important',
                                },
                            }
                        },
                        '.MuiInputBase-root':
                        {
                            height: '80px !important',
                            [theme.breakpoints.down(1500)]: {
                                height: '43px !important',
                                'input': {
                                    fontSize: '16px  !important',
                                }
                            },
                            'input': {
                                fontSize: '32px',
                            }
                        }
                    }
                },
            },
            '.box-content-list-appointment': {
                height: '100%',
                '.text-select-appointment': {
                    fontSize: '32px',
                    padding: '48px 48px 0 48px',
                    [theme.breakpoints.down(1600)]: {
                        padding: '0px 20px 0 20px',
                    },
                    [theme.breakpoints.down(1500)]: {
                        fontSize: '20px',
                        padding: '20px 20px 0 20px',
                    }
                },
                '.box-container-list': {
                    width: '100%',
                    position: 'relative',
                    '.box-content-list': {
                        padding: '16px 48px ',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflow: 'auto',
                        overflowX: 'hidden',
                        width: '100%',
                        alignItems: 'center',
                        gap: '16px',
                        // [theme.breakpoints.down(1500)]: {
                        //     gap: '8px',
                        // }
                    }
                }
            }
        },

    },
    '.btn-check-in': {
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.down(1500)]: {
            '.MuiButton-root': {
                maxWidth: '360px',
                fontSize: '16px !important'
            }
        },
        [theme.breakpoints.down(500)]: {
            '.MuiButton-root': {
                maxWidth: '100%',
            }
        }
    },
    '.box-content-qr-code': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        '.text-qr-code': {
            fontSize: '32px',
            [theme.breakpoints.down(1500)]: {
                fontSize: '16px',
            },
        }
    },
    // sm
    '.box-clinic-info-sm': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        '.box-clinic-logo-sm': {
            padding: '0 14px',
            img: {
                height: "60px",
                width: '100%'
            }
        }
    },
    '.box-check-in-content-sm': {
        width: '100%',
        // position: 'relative',
        // position: 'fixed',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '16px 24px 0 24px',
        '.text-select-appointment': {
            fontSize: '20px',
            // paddingTop: '20px'
        },
        '.box-container-list-sm': {
            width: '100%',
            position: 'relative',
            '.box-content-list-sm': {
                padding: '4px 12px 16px 12px ',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'auto',
                overflowX: 'hidden',
                // width: '100%',
                alignItems: 'center',
                gap: '16px',
                margin: '-0px -16px 0 -16px',
                width: 'calc(100% + 36px)'
            }
        }
    },
    '.box-linear-list': {
        position: 'fixed',
        bottom: 0,
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 5.76%, #FFF 50%)',
        height: '250px',
        width: '100%',
        margin: '0 -24px',
        pointerEvents: 'none',
    }

}))

export const CardCheckIn = styled(Box)<{ isselect: string, isdisabled: string }>(({ theme, isselect, isdisabled }) => ({
    width: '100%',
    minHeight: '171px',
    maxHeight: '171px',
    borderRadius: '12px',
    boxShadow: '0px 0px 12px 0px #CCC',
    border: `2px solid ${isselect === '1' ? colors.themeSecondColor : isdisabled !== '1' ? 'transparent' : '#BBB'}`,
    padding: '24px',
    backgroundColor: `${isdisabled === '1' ? '#E0E0E0' : isselect === '1' ? '#F1FBF9' : '#fff'}`,
    cursor: 'pointer',
    transition: 'all 0.15s ease-in-out',
    display: 'flex',
    // pointerEvents: isdisabled === '1'  ? 'none' : 'auto',
    pointerEvents: 'none',
    position: 'relative',
    // [theme.breakpoints.down(1500)]: {
    //     minHeight: '148px',
    //     maxHeight: '148px',
    //     padding: '20px 16px',
    // },
    '@media (max-height:800px)': {
        minHeight: '148px',
        maxHeight: '148px',
        padding: '20px 16px',
    },
    '.card-check-in-checked': {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '24px',
        display: 'flex', alignItems: 'center', gap: '8px',
        'svg': {
            width: '25px'
        },
        '.card-check-in-status': {
            color: colors.themeSecondColor,
            fontSize: '24px',
            [theme.breakpoints.down(1500)]: {
                fontSize: '18px',
            },
            [theme.breakpoints.down(500)]: {
                display: 'none'
            },
        },
    },

    '.card-checkin-detail': {
        filter: isdisabled === '1' ? 'grayscale(1) ' : 'unset',
        maxWidth: 'calc(100% - 48px)',
        '.card-patient-name': {
            fontSize: '24px',
            fontWeight: '500',
            marginBottom: '6px',
            [theme.breakpoints.down(1500)]: {
                fontSize: '18px',
                marginBottom: '6px',
            }
        },
        '.card-patient-detail': {
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            color: '#5A5A5A',
            [theme.breakpoints.down(1500)]: {
                fontSize: '14px',
                gap: '6px',
            },
            '.card-container-patient-detail': {
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
            },
            '.card-patient-icon': {
                color: colors.themeSecondColor,
                width: 24,
                height: 24
            },
            '.card-patient-icon-treatment': {
                flex: 'none',
                width: '24px',
                svg: {
                    width: '24px',
                    height: '18.5px',
                    path: {
                        fill: 'transparent !important',
                        stroke: `${colors.themeSecondColor} !important`
                    }
                }
            }
        }
    }
}))

export const BoxCheckInSuccess = styled(Box)(({ theme, }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(1000)]: {
        display: 'block',
        paddingTop: '20px'
    },
    '.card-check-in-success': {
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: '32px 64px 64px 64px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '48px',
        borderRadius: '12px',
        background: 'var(--Other-White, #FFF)',
        /* Shadown/Container */
        boxShadow: '0px 0px 12px 0px #CCC',
        [theme.breakpoints.down(1000)]: {
            padding: '24px',
            gap: '24px',
        },
        '.card-check-in-status': {
            color: colors.themeSecondColor,
            fontSize: '32px',
            [theme.breakpoints.down(1500)]: {
                fontSize: '32px',
            },
            [theme.breakpoints.down(1000)]: {
                fontSize: '18px',
            }
        },
        '.card-checkin-detail': {
            [theme.breakpoints.down(1000)]: {
                marginBottom: '16px'
            },
            '.card-patient-name': {
                fontSize: '32px',
                fontWeight: '500',
                marginBottom: '16px',
                [theme.breakpoints.down(1500)]: {
                    fontSize: '24px',
                    marginBottom: '24px',
                }
            },
            '.card-patient-detail': {
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                color: '#5A5A5A',
                [theme.breakpoints.down(1500)]: {
                    fontSize: '16px',
                    gap: '12px',
                },
                '.card-container-patient-detail': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '16px',
                    fontSize: '24px',
                    [theme.breakpoints.down(1500)]: {
                        fontSize: '18px',
                    },
                },
                '.card-patient-icon': {
                    color: colors.themeSecondColor,
                    width: 36,
                    height: 36,
                    [theme.breakpoints.down(1000)]: {
                        width: 24,
                        height: 24,
                    },
                },
                '.card-patient-icon-treatment': {
                    flex: 'none',
                    width: '36px',
                    svg: {
                        width: '36px',
                        height: '29px',
                        path: {
                            stroke: `${colors.themeSecondColor} !important`
                        }
                    },
                    [theme.breakpoints.down(1000)]: {
                        width: '24px',
                        svg: {
                            width: '24px',
                            height: '18.5px',
                        }
                    },
                }
            }
        },
    },
    '.card-checkin-footer-text': {
        fontSize: '32px',
        color: '#5A5A5A',
        [theme.breakpoints.down(1500)]: {
            fontSize: '20px',
        }
    },
    '.btn-check-success': {
        marginTop: '32px',
        textAlign: 'right',

        [theme.breakpoints.down(1500)]: {
            '.MuiButton-root': {
                maxWidth: '360px',
                fontSize: '16px !important'
            }
        },
        [theme.breakpoints.down(1000)]: {
            '.MuiButton-root': {
                maxWidth: 'fit-content',
                backgroundColor: '#fff'
            }
        }
    },

}))

