import { Box } from '@mui/material'
import { fileUrl } from 'api/api'
import ManageWebApi from 'api/application/manageWeb'
import { PriceListProps } from 'api/application/manageWeb-interface'
import BtnDelFilled from 'assets/button/BtnDelFilled'
import { swalActive, swalDelete } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import Loading from 'component/Loading'
import UploadImage from 'component/Upload/UploadImage'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type PriceListWebProps = {
    priceListImg: string
    setPriceListImg: (img: string) => void
}

const PriceList = (props: PriceListWebProps) => {
    const { t } = useTranslation()
    const t_PRICE_LIST = "MANAGE_WEB.PRICE_LIST"
    const maxHeight = 1080
    const maxWidth = 1080

    const [load, setLoad] = useState<boolean>(true)

    const [priceList, setPriceList] = useState<any>({})
    const [priceListId, setPriceListId] = useState<any>({})

    const loadData = async () => {
        setLoad(true)
        await ManageWebApi.getPriceList().then((res) => {
            setPriceList(res.data)
            setPriceListId(res.data)
        }).catch(() => {
            return
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    const handleDel = () => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 425px">${t(t_PRICE_LIST + '.NOTI_DEL')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 306px">${t(t_PRICE_LIST + '.NOTI_DEL')}</p>`,
            async (res: any) => {
                if (res) {
                    const resDel = await ManageWebApi.delPriceList(priceList.priceListId)
                    if (resDel === "pass") {
                        setPriceList({})
                        setPriceListId({})
                        loadData()
                        notiSuccess(t(`${t_PRICE_LIST}.DEL_SUC`))
                    } else {
                        notiError(t(`${t_PRICE_LIST}.SAVE_ERROR`))
                        return
                    }

                }
            }, 'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )

    }

    const uploadNewsImageSign = (files: any) => {
        if (files[0]) {
            const file = files[0];
            const fileName = file.name.split(".");
            const detailImage = {
                base64: "",
                type: priceListId?.fileType || "",
                fileExtension: fileName[fileName.length - 1],
                fileSize: file.size.toString(),
                fileId: priceListId?.priceListId || 0,
            };

            const reader: any = new FileReader();
            reader.addEventListener("load", () => {
                const image = new Image();
                image.src = reader?.result as string;
                image.addEventListener("load", () => {
                    const width = image.width;
                    const height = image.height;

                    if (maxHeight < height || maxWidth < width) {
                        const errorWidth = maxWidth < width && ` ${t('MANAGE_WEB.PRICE_LIST.ERROR_WIDTH')} ${maxWidth} ` || ''
                        const errorHeight = maxHeight < height && ` ${t('MANAGE_WEB.PRICE_LIST.ERROR_HEIGHT')} ${maxHeight} ` || ''
                        const error = `${t('MANAGE_WEB.PRICE_LIST.IMAGE')} ${errorWidth} ${maxHeight < height && maxWidth < width && ` ${t('MANAGE_WEB.CONNECTOR.AND')} ` || ''} ${errorHeight}`
                        notiError(error)
                    } else {
                        const base64 = reader.result.split(",");
                        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result;
                        const newData = { ...priceList, ...detailImage, base64Show: reader.result, }
                        setPriceList(newData)
                    }
                });
            });
            reader.readAsDataURL(file);
        }
    };

    const onSavePriceList = async () => {
        const newPayload: PriceListProps = {
            priceListId: priceList?.fileId !== 0 && priceListId?.fileId || undefined,
            base64: priceList?.base64 || '',
            type: priceList?.type || '',
            fileExtension: priceList?.fileExtension || '',
            fileSize: priceList?.fileSize || ''
        }

        if (newPayload.base64) {
            await ManageWebApi.savePriceList(newPayload).catch(() => {
                return
            }).then(() => {
                notiSuccess(t(`${t_PRICE_LIST}.SUCCESS_IMG`))
                props.setPriceListImg(priceList?.base64Show || '')
                loadData()
            })
        } else {
            return notiError(t(`${t_PRICE_LIST}.NOTI_IMAGE`))
        }
    }

    const onPriceListRefresh = () => {
        loadData()
    }

    const onRenderImg = () => {
        if (props?.priceListImg && props?.priceListImg !== '') {
            return props?.priceListImg
        } else {
            return `${fileUrl}/${priceList?.filePath}/${priceList?.fileName}`
        }
    }

    return (
        <>
            <Loading show={load} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {!load &&
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                            {t(`${t_PRICE_LIST}.TITLE`)}
                        </Box>
                        {priceList?.filePath &&
                            <Box>
                                <ButtonCustom
                                    startIcon={<BtnDelFilled color='#fff' />}
                                    mode={'del'}
                                    textButton={t(`${t_PRICE_LIST}.BTN_DEL`)}
                                    onClick={() => { handleDel() }}
                                />
                            </Box>
                            ||
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ButtonCustom
                                    style={{ marginRight: '4px' }}
                                    variant={'outlined'}
                                    textButton={t(`${t_PRICE_LIST}.BTN_CANCEL`)}
                                    onClick={() => { onPriceListRefresh() }}
                                />
                                <ButtonCustom
                                    textButton={t(`${t_PRICE_LIST}.BTN_SAVE`)}
                                    onClick={() => { onSavePriceList() }}
                                />
                            </Box>
                        }
                    </Box>
                }

                {!load &&
                    <Box sx={{ marginTop: '32px', height: priceList?.filePath || priceList?.base64Show ? '100%' : '90%', overflow: 'auto' }}>
                        {priceList?.filePath &&
                            <img
                                src={onRenderImg()}
                                style={{
                                    width: "100%"
                                }}
                            />
                            ||
                            <Box
                                sx={{
                                    width: priceList?.filePath || priceList?.base64Show ? 'auto !important' : "100%",
                                    height: priceList?.filePath || priceList?.base64Show ? 'auto !important' : "calc(100% - 32px)",
                                    ".MuiFormControl-root": {
                                        height: '100%'
                                    },
                                    ".MuiFormControl-root div": {
                                        height: priceList?.filePath || priceList?.base64Show ? 'auto !important' : '150px'
                                    }
                                }}
                            >
                                <UploadImage
                                    setValue={uploadNewsImageSign}
                                    pathFile={priceList?.base64Show || priceList?.filePath || ''}
                                />
                                <Box sx={{ color: "#BBBBBB", marginTop: "8px" }}>
                                    {'('}{t(`${t_PRICE_LIST}.FILESIZE`)}{` ${maxWidth} x ${maxHeight} px)`}
                                </Box>
                            </Box>
                        }
                    </Box>
                }
            </Box>

        </>
    )
}

export default PriceList