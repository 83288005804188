import { useCallback, useEffect, useRef, useState, ChangeEvent } from 'react';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import { routeName } from 'routes/routes-name';
import { useHistory } from 'react-router-dom';
import { Box, Button, MenuItem, makeStyles, Select, FormControl, InputLabel } from '@mui/material';

import moment from 'moment';
import _ from 'lodash';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch } from 'utils/app.utils';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/**COMPONENT */
import PatientDateOfBirthList from './PatientDateOfBirthList';
import Loading from 'component/Loading';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputCheckbox from 'component/Input/InputCheckbox';

/**STYLE */
import * as UseStyled from 'features/report/useStyled';
/** CONSTANTS */
import { colors } from 'constants/theme';
import InputSecondNewRangePicker, { PICKER_VIEWS, emptyRangePicker } from 'component/Input/InputSecondNewRangePicker';
import InputTextSearch from 'component/Input/InputTextSearch';
import ButtonCustom from 'component/Button/ButtonCustom';




type Doctors = {
    userId: number
    firstname: string
    lastname: string
    fullname: string
}

export interface DentistInterface {
    dentistId: number
    dentistFullname: string
}

export default function PatientDateOfBirth() {



    const { t } = useTranslation();

    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);
    const momentNow = moment().format('YYYY-MM-DD');

    const [branchId, setBranchId] = useState<any>(getBranch());
    const [branches, setBranches] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');

    const [search, setSearch] = useState<string>('');
    const [patientDateOfBirthData, setPatientDateOfBirthData] = useState<any[]>([]);
    const [patientDateOfBirthDataExport, setPatientDateOfBirthDataExport] = useState<any[]>([]);
    const [dateRange, setLabDateRange] = useState<string>(momentNow);
    const [dataPage, setDataPage] = useState<number>(1);
    const [dataPageLimit, setDataPageLimit] = useState<number>(10);
    const [dataSecondPage, setDataSecondPage] = useState<number>(1);

    const [loading, setLoading] = useState<boolean>(true);
    const [fetch, setFetch] = useState<boolean>(false);

    const [selectedMonths, setSelectedMonths] = useState<(number | string)[]>(Array.from({ length: 12 }, (_, i) => i + 1));



    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'PATIENT_DATE_OF_BIRTH') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })
        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    const handleMonthChange = (event: ChangeEvent<{ value: unknown }>) => {
        const selectedValues = event.target.value as (number | string)[];

        if (selectedValues.includes("ALL")) {
            if (selectedMonths.length === 12) { // ถ้าเลือกทั้งหมดอยู่แล้ว
                setSelectedMonths([]); // ยกเลิกการเลือกทั้งหมด
            } else {
                setSelectedMonths(Array.from({ length: 12 }, (_, i) => i + 1)); // เลือกทั้งหมด
            }
        } else {
            setSelectedMonths(selectedValues);
        }
    };


    const fetchPatientDateOfBirth = useCallback(async () => {
        await verifyPermission()
        let condition: FindAllReportInterface = {
            page: dataPage, pageLimit: dataPageLimit, search: search,
            date: String(selectedMonths)
        }
        if (branchId) condition = { ...condition, branchId: branchId }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        ReportApi.reportPatientDateOfBirth(condition)
            .then(({ headers, data }) => {
                setPatientDateOfBirthData(data)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

        setFetch(false)
    }, [dataPage, dataSecondPage, dataPageLimit, fetch])

    const fetchPatientDateOfBirthExport = useCallback(async () => {
        await verifyPermission()
        let condition: FindAllReportInterface = {
            page: dataPage, pageLimit: 100000, search: search,
            date: String(selectedMonths), export: 1
        }
        if (branchId) condition = { ...condition, branchId: branchId }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        ReportApi.reportPatientDateOfBirth(condition)
            .then(({ headers, data }) => {
                setPatientDateOfBirthDataExport(data)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

        setFetch(false)
    }, [dataPage, dataSecondPage, dataPageLimit, fetch])


    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.PATIENT_DATE_OF_BIRTH`)}`,
        sheetName: `${t(`REPORT.REPORT_NAME.PATIENT_DATE_OF_BIRTH`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setDataPageLimit(limit)
    const handleChangePage = (val: number) => setDataPage(val)

    useEffect(() => {
        fetchPatientDateOfBirthExport()
        fetchPatientDateOfBirth()
    }, [fetchPatientDateOfBirth, fetchPatientDateOfBirthExport])


    return (
        <>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.PATIENT_DATE_OF_BIRTH`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <Col sm={12} md={6} lg={5} xl={4} xxl={3} className={'pt-2'}>
                                <InputTextSearch
                                    keyInput={'search'}
                                    label={t('INPUT.SEARCH')}
                                    value={search}
                                    onchange={(event) => {
                                        setSearch(event.target.value)
                                    }}
                                    onClear={(event) => {
                                        setSearch('')
                                    }}
                                />
                            </Col>
                            <Col sm={4} md={4} xl={3} xxl={3} className={'pt-2'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)
                                        setDataPage(1)
                                    }}
                                    renderValue={() => branchId === 0 ? `${t('REPORT.FILTER.BRANCH')}: ${t('REPORT.REPORT_GROUP.ALL')}` : `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName')} `}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}

                                    options={[
                                        <MenuItem key={0} value={0}>{t('REPORT.REPORT_GROUP.ALL')}</MenuItem>,
                                        ..._.map(branches, (b: BranchInterface, index) => (
                                            <MenuItem key={b.branchId} value={b.branchId}>
                                                {b.branchName || b.branchNameEn}
                                            </MenuItem>
                                        ))
                                    ]
                                    }

                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            <Col sm={4} md={3} xl={3} xxl={2} className={'pt-2'} >
                                <FilterSelect
                                    multiple
                                    value={selectedMonths}
                                    onchange={handleMonthChange}
                                    renderValue={(selected) => {
                                        if (Array.isArray(selected)) {
                                            if (selected.length === 0) return `${t(`REPORT.MONTH.DATE_OF_BIRTH`)} : ${t(`REPORT.MONTH.NULL`)}`;
                                            if (selected.length === 12 || selected.includes(`ALL`)) return `${t(`REPORT.MONTH.DATE_OF_BIRTH`)} : ${t('REPORT.REPORT_GROUP.SELECT_ALL')}`;
                                            return `${t(`REPORT.MONTH.DATE_OF_BIRTH`)} : ${selected.map(monthNum => t(`REPORT.MONTH.${monthNum}`)).join(', ')}`;
                                        }
                                        return '';
                                    }}

                                    options={[
                                        <MenuItem key="ALL" value="ALL">
                                            <InputCheckbox
                                                checked={selectedMonths.length === 12 || selectedMonths.includes(`ALL`)}

                                            />
                                            {t('REPORT.MONTH.ALL')}
                                        </MenuItem>,
                                        ...Array.from({ length: 12 }).map((_, index) => (
                                            <MenuItem key={index} value={index + 1}>
                                                <InputCheckbox
                                                    checked={selectedMonths.includes(index + 1)}

                                                />
                                                {t(`REPORT.MONTH.${index + 1}`)}
                                            </MenuItem>
                                        ))
                                    ]}
                                    labelId="month-label-id"
                                    selectId="month-select-id"
                                    label={t('SELECT.MONTH')}
                                />
                            </Col>

                            <Col md={1} xl={1} className={'pt-0'}>
                                <ButtonCustom onClick={() => {
                                    setFetch(true);
                                    setDataPage(1);
                                }} textButton={t('INPUT.SEARCH')} disabled={selectedMonths.length === 0} style={{ height: '30px', marginTop: '5px' }} />
                            </Col>

                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                        <FucntionPrint
                                            content={() => componentRef.current}
                                            removeIfram
                                            trigger={() => <Dropdown.Item >
                                                {t('PDF')}
                                            </Dropdown.Item>}
                                            style={`
                                           @page { size: 1122.519685px 793.7007874px; margin: 0.8cm; }
                                           @media print { 
                                             body { -webkit-print-color-adjust: exact; }
                                             .container { break-inside: avoid; }
                                             thead { display: table-row-group; }
                                             table { table-layout: fixed; }
                                             tr { page-break-inside: avoid; }
                                             thead:after {
                                               display: block;
                                               height: 1cm;
                                             }
                                           }
                                         `}
                                        />
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <PatientDateOfBirthList
                            page={dataPage}
                            pageLimit={dataPageLimit}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={patientDateOfBirthData}
                            dataExport={patientDateOfBirthDataExport}
                            filter={[
                                `${t(`REPORT.REPORT_NAME.PATIENT_DATE_OF_BIRTH`)}`,
                                branchId === 0 ? ` ${t('REPORT.REPORT_GROUP.ALL')}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName', 'branchNameEn')}`,
                                ((selected) => {
                                    if (Array.isArray(selected)) {
                                        if (selected.length === 0) return `${t('REPORT.MONTH.NULL')}`;
                                        if (selected.length === 12 || selected.includes('ALL')) return `${t('REPORT.REPORT_GROUP.SELECT_ALL')}`;
                                        return ` ${selected.map(monthNum => t(`REPORT.MONTH.${monthNum}`)).join(', ')}`;
                                    }
                                    return '';
                                })(selectedMonths)
                            ]}

                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                        />
                    </Box>
                </Card.Body>
                <Loading show={loading} type='fullLoading' />
            </Card>
        </>
    )
}