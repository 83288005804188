import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface DoctorNoteProps {
    typeName?: string,
    typeNameEn?: string,
    status?: string
}

export default class ImageDoctorNoteType extends BaseAPI {

    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get('image-doctor-note-types', { params: { ...props } }).then((res) => res)
    }

    static create(payload: DoctorNoteProps) {
        return this.api.post('image-doctor-note-types', payload)
    }

    static update(id: number, body: any) {
        return this.api.patch(`image-doctor-note-types/${id}`, body)
    }
  
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`image-doctor-note-types/${id}/status`, { id, status })
  }

    static remove(id: number) {
        return this.api.delete(`image-doctor-note-types/${id}`)
    }
}
