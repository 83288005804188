import { useState, useEffect } from 'react'
import _ from 'lodash'
import { styled } from '@mui/material/styles'
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { ProductStickerType, StickerType, treatment } from 'app/slice/treatment.slice';
import { getClinicInfo, toBuddhistYear } from 'utils/app.utils';
import { colors, templateImage } from 'constants/theme';
import { onImgError } from 'constants/images'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import moment from 'moment'
import { DoctorsType } from 'features/treatments/treatmentsIndex'
import { SummaryTreatmentsInterface } from 'app/slice/treatment.slice'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import TreatmentsAPI from 'api/dentists/treatment.api'

const PreviewMedicineLabel = styled(Box)<{ fontSizeBody?: string }>(({ theme, fontSizeBody }) => ({
    border: `1px solid ${colors.lightGray}`,
    backgroundColor: colors.white,
    '.format-3-border': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '.line': {
            width: '97%',
            backgroundColor: `${colors.lightGray}`,
            height: '1px',
            marginTop: '-2px',
            marginBottom: '6px'
        }
    },
    '.header': {
        // fontWeight: 'bold',
        // borderBottom: `1px solid ${colors.lightGray}`,
        // p: {
        //     fontWeight: 'bold'
        // },
        '.logo': {
            maxWidth: 35,
            maxHeight: 35,
            objectFit: 'contain'
        },
        '.chip-tel': {
            minHeight: '9px',
            minWidth: 60,
            maxWidth: '100%',
            fontSize: '9px',
            borderRadius: 100,
            backgroundColor: colors.themeMainColor,
            color: colors.white,
            svg: {
                marginRight: '2px',
                fontSize: '9px'
            }
        }
    },
    '.body': {
        p: {
            fontSize: fontSizeBody || '7px',
            // fontWeight: 'bold'
        }
    }
}))

type PdfMedStickerType = {
    stickerData: ProductStickerType[]
    doctors: DoctorsType[]
}

interface ProductPrintStickerType extends SummaryTreatmentsInterface {
    qty?: number
    sticker: StickerType
}

export default function PdfMedSticker(props: PdfMedStickerType) {
    const { t } = useTranslation()
    const location = useLocation()

    const [stickers, setStickers] = useState<ProductPrintStickerType[]>([]);
    const [doctorPrefix, setDoctorPrefix] = useState<any>()

    const branch = getClinicInfo()
    // const [branch, setBranch] = useState(getClinicInfo())

    const [textDc, setTextDc] = useState<string>(':')
    const [patient, setPatient] = useState<any>([])

    // useEffect(() => {
    //     setBranch({ ...branch, stickerFormat: 'FORMAT_3' })
    // }, [getClinicInfo])

    const loadPatient = async (patientId: number) => {
        const patientData = await TreatmentsAPI.findPatient(patientId)
        if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
            setPatient(patientData.data)
        } else setPatient([])
    }

    useEffect(() => {
        const patientState: any = location.state
        if (patientState?.patientId) {
            loadPatient(patientState.patientId)
        }
    }, [location])

    useEffect(() => {
        let sticker: ProductPrintStickerType[] = []
        _.forEach(props.stickerData, (stk: ProductStickerType) => {
            if (stk.sticker) sticker = [...sticker, { ...stk, sticker: JSON.parse(stk.sticker) }]
        })
        setStickers(sticker)
        loadPrefixesDoc()
    }, [props.stickerData]);

    const loadPrefixesDoc = async () => {
        const docId: any = stickers[0]?.doctorId

        const res = await PersonnelApi.findById(docId)
        if (res.status === 200) setDoctorPrefix(res.data?.prefix)

    }

    const renderLogo = (pSticker: ProductPrintStickerType) => {

        return (<img src={pSticker.sticker.logo || branch?.branchLogo || templateImage.logoHorizontal} alt='' className='logo w-100 h-100' />)

    }

    const renderAddress = (pSticker: ProductPrintStickerType) => {
        if (branch.stickerFormat === 'FORMAT_3') {
            return (
                <>
                    <Typography
                        sx={{
                            paddingTop: '4px',
                            lineHeight: '11px',
                            fontSize: '7px'
                        }}
                    >
                        {pSticker.sticker.branchAddress || branch.branchFullAddress || ''} {pSticker.sticker.branchTel || branch.branchPhone || ''}
                    </Typography>
                </>
            )
        }

        return (
            <>
                <Box className={`d-flex justify-content-between`}>
                    <Typography sx={{ lineHeight: '11px', fontSize: '7px' }}>{pSticker.sticker.branchAddress || branch.branchFullAddress || ''} </Typography>
                    {branch.stickerFormat === 'FORMAT_1' &&
                        <Box className='d-flex' sx={{ '.MuiTypography-root': { fontSize: '8px', fontWeight: '400' }, alignItems: 'flex-end' }} >
                            <Typography className='d-inline-block' sx={{ flex: 'none', whiteSpace: 'nowrap' }}>{'วันที่จ่ายยา : '}</Typography>
                            <Typography className='d-inline-block pl-1'>{toBuddhistYear(moment(), 'DD/MM/YYYY')}</Typography>
                        </Box>
                    }
                </Box >
            </>
        )

    }


    return (
        <div className="h-100 w-100">
            {_.map(stickers, (pSticker: ProductPrintStickerType, index: number) => {
                return (
                    <div key={index} className='h-100 w-100 p-2' style={{ pageBreakAfter: 'always' }}>
                        <PreviewMedicineLabel
                            className='h-100 w-100 d-flex flex-column'
                            // branch.stickerFormat !== 'FORMAT_3' ? '7px' : 
                            fontSizeBody={'9.5px'}
                        >
                            <Box className='header mb-1 d-flex align-items-center ' sx={{ p: " 1px 2px 2px 2px", border: 'none ' }}>
                                {branch.stickerFormat === 'FORMAT_3' && renderLogo(pSticker)}
                                <Box className={`ml-1 ${branch.stickerFormat !== 'FORMAT_3' && 'mr-1'}`}>
                                    <Typography sx={{ fontSize: '10px', fontWeight: 500 }}>{`${pSticker.sticker.clinicName || branch.clinicAndBranchName || ''}`} <span style={{ fontSize: branch?.branchPhone1.split(',').length > 1 ? '8px' : '10px', fontWeight: 500 }}>{`${branch.stickerFormat !== 'FORMAT_3' && branch?.branchPhone1 || ''}`}</span></Typography>
                                    {renderAddress(pSticker)}
                                </Box>
                            </Box>
                            {branch.stickerFormat === 'FORMAT_3' &&
                                <Box className={`format-3-border`}>
                                    <Box className={`line`} />
                                </Box>
                            }

                            <Box
                                className={`body d-flex flex-column h-100 `}
                                sx={{
                                    p: `${branch.stickerFormat !== 'FORMAT_3' ? '0px' : '2px'} 4px 2px ${branch.stickerFormat !== 'FORMAT_3' ? '10px' : '4px'}`,
                                    gap: `3px`,
                                    marginTop: '-3px'
                                }}
                            >
                                <Box className='d-flex justify-content-between'>
                                    <Box className='d-flex' >
                                        {branch.stickerFormat !== 'FORMAT_3' && <Typography className='d-inline-block' sx={{ flex: 'none' }}>{'CN : '}</Typography>}
                                        {branch.stickerFormat !== 'FORMAT_3' && <Typography className='d-inline-block pl-1'>{patient?.cnNumber || ''}</Typography>}
                                        {branch.stickerFormat === 'FORMAT_3' && <Typography className='d-inline-block' sx={{ flex: 'none' }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PREVIEW.PATIENT_NAME')}{textDc}</Typography>}
                                        <Typography className='d-inline-block pl-2'>{`${pSticker.sticker.patientFullName || ''}`}</Typography>
                                    </Box>
                                    {branch.stickerFormat === 'FORMAT_2' &&
                                        <Box className='d-flex'>
                                            <Typography className='d-inline-block' sx={{ flex: 'none', whiteSpace: 'nowrap' }}>{'DATE : '}</Typography>
                                            <Typography className='d-inline-block pl-1'>{toBuddhistYear(moment(), 'DD/MM/YYYY')}</Typography>
                                        </Box>
                                    }
                                    {branch.stickerFormat === 'FORMAT_3' &&
                                        <Box className='d-flex' sx={{ '.MuiTypography-root': { fontWeight: '700' } }}>
                                            <Typography className='d-inline-block' sx={{ flex: 'none' }}>{'CN : '}</Typography>
                                            <Typography className='d-inline-block pl-1'>{patient?.cnNumber || ''}</Typography>
                                        </Box>
                                    }
                                </Box>
                                <Box className={``}>
                                    <Box className={`d-flex ${branch.stickerFormat === 'FORMAT_3' && 'justify-content-between'}`}>
                                        <Box className='d-flex'>
                                            <Typography sx={{ flex: 'none', whiteSpace: 'nowrap' }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PREVIEW.NAME')}{textDc}</Typography>
                                            <Typography className='d-inline-block pl-2'>{pSticker.sticker.itemName || pSticker.sticker.itemNameEn || ''}</Typography>
                                            {branch.stickerFormat !== 'FORMAT_3' &&
                                                <Box className='ml-2 d-flex'>
                                                    {pSticker.sticker.dosage && <Typography className='mr-1'>{pSticker.sticker.dosage || ''}</Typography> || <></>}
                                                    {pSticker.sticker.productUnitName && <Typography className=''>{pSticker.sticker.productUnitName}</Typography> || <></>}
                                                </Box>
                                            }
                                        </Box>
                                        {branch.stickerFormat === 'FORMAT_3' &&
                                            <Box className='d-flex' sx={{ '.MuiTypography-root': { fontWeight: '700' } }}>
                                                <Typography className='d-inline-block' sx={{ flex: 'none' }}>{t('วันที่') + ' : '}</Typography>
                                                <Typography className='d-inline-block pl-1'>{toBuddhistYear(moment(), 'DD/MM/YYYY')}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                {branch.stickerFormat !== 'FORMAT_3' ?
                                    <Box className={`d-flex justify-content-between`}>
                                        <Box className={`d-flex`} sx={{ width: 250 }}>
                                            <Typography className='mr-2'>{pSticker?.sticker?.productInstructionName || ''}</Typography>
                                        </Box>
                                        <Box className={`d-flex`} sx={{ width: 80, justifyContent: 'end' }}>
                                            <Typography className='d-inline-block pl-1'>{pSticker?.qty || pSticker?.sticker?.productUnitName ? `${pSticker?.qty || ""} ${pSticker?.sticker?.productUnitName || ''}` : '-'}</Typography>
                                        </Box>
                                    </Box>
                                    :
                                    <Box>
                                        <Box className='d-flex justify-content-between'>
                                            <Box className='d-flex' sx={{ width: 200 }}>
                                                <Typography className='d-inline-block' sx={{ whiteSpace: 'nowrap' }}>{t('วิธีใช้')}{textDc}</Typography>
                                                <Typography className='d-inline-block pl-1' sx={{ wordBreak: 'break-word' }}>{`${pSticker?.sticker?.productInstructionName || ''}`}</Typography>
                                            </Box>
                                            <Box className='d-flex' sx={{ '.MuiTypography-root': { fontWeight: '700' }, width: 100, justifyContent: 'end' }}>
                                                <Typography className='d-inline-block' sx={{ flex: 'none' }}>{t('จำนวน') + ' : '}</Typography>
                                                <Typography className='d-inline-block pl-1'>{pSticker?.qty || pSticker?.sticker?.productUnitName ? `${pSticker?.qty || ""} ${pSticker?.sticker?.productUnitName || ''}` : '-'}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ paddingLeft: '24px', marginTop: '-8px' }}>
                                            <Typography className='d-inline-block pl-0' sx={{ wordBreak: 'break-word' }}>{`${t('ครั้งละ')} ${pSticker?.sticker?.dosage} ${t('เม็ด')} ${pSticker?.sticker?.productUseDetail || ''}`}</Typography>
                                        </Box>
                                    </Box>

                                }

                                {branch.stickerFormat !== 'FORMAT_3' && pSticker?.sticker?.productUseDetail &&
                                    <Box>
                                        <Typography>{pSticker?.sticker?.productUseDetail || ''}</Typography>
                                    </Box>
                                }

                                {branch.stickerFormat !== 'FORMAT_3' && pSticker?.sticker?.productSymptomDetail &&
                                    <Box>
                                        <Typography>{pSticker?.sticker?.productSymptomDetail || ''}</Typography>
                                    </Box>
                                }


                                {branch.stickerFormat === 'FORMAT_3' &&
                                    <Box className='d-flex justify-content-between'>
                                        <Box className='d-flex'>
                                            <Typography className='d-inline-block' sx={{ whiteSpace: 'nowrap' }}>{t('ข้อบ่งชี้')}{textDc}</Typography>
                                            <Typography className='d-inline-block pl-1' sx={{ wordBreak: 'break-word' }}>{`${pSticker?.sticker?.productSymptomDetail || '-'}`}</Typography>
                                        </Box>
                                    </Box>
                                }
                                {branch.stickerFormat !== 'FORMAT_3' ?
                                    <Box className=''>
                                        <Box className='d-flex'>
                                            <Typography></Typography>
                                            <Typography className='' sx={{ wordBreak: 'break-word' }}>{pSticker.sticker.itemRecommend || ''}</Typography>
                                        </Box>
                                    </Box>
                                    :
                                    <Box className='d-flex justify-content-between'>
                                        <Box className='d-flex'>
                                            <Typography sx={{ whiteSpace: 'nowrap' }}>{t('ข้อควรระวัง')}{textDc}</Typography>
                                            <Typography className='pl-1' sx={{ wordBreak: 'break-word' }}>{pSticker.sticker.itemRecommend || '-'}</Typography>
                                        </Box>
                                    </Box>
                                }
                                <Box className='d-flex justify-content-between mt-auto'>
                                    <Box className='d-flex'>
                                        <Typography className='d-inline-block' sx={{ flex: 'none' }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PREVIEW.DOCTOR')}</Typography>
                                        <Typography className='d-inline-block pl-1'>{`${doctorPrefix || ''}${_.get(_.find(props.doctors, { userId: pSticker.doctorId }), 'fullname') || '-'}`}</Typography>
                                    </Box>
                                    {pSticker.sticker.expiredDate && (
                                        <Box className='d-flex'>
                                            <Typography className='d-inline-block' sx={{ flex: 'none' }}>{branch.stickerFormat === 'FORMAT_2' ? t('EXP') : t('วันหมดอายุ') + textDc}</Typography>
                                            <Typography className='d-inline-block pl-1'>{toBuddhistYear(moment(pSticker.sticker.expiredDate))}</Typography>
                                        </Box>
                                    ) || <></>}
                                </Box>
                            </Box>
                        </PreviewMedicineLabel>
                    </div >
                )
            })}
        </div >
    )
}

