import BaseAPI from 'api/api'

const path = 'treatment-history-management'

export default class TreatmentHistoryManagementApi extends BaseAPI {
  static findTreatmentHistoryEmr(patientId: number, patientCaseId: number): Promise<any> {
    return this.api.get(`${path}/${patientId}/emr/${patientCaseId}`)
  }

  static updateTreatmentHistoryEmr(patientId: number ,patientCaseId: number, payload: any): Promise<any> {
    return this.api.patch(`${path}/update/emr/${patientId}/${patientCaseId}`, payload)
  }
}