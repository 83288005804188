import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// config
import TreatmentApi, { TreatmentCoursesPaidHistoryProps } from 'api/dentists/treatment.api'

// 
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import Loading from 'component/Loading'
import TableRowCommon from 'component/Table/TableRowCommon'
import { dateTimeToView } from 'utils/date.utils'
import { numberFormat } from 'utils/app.utils'
import { Box, TableCell, TableRow } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'



type PopupTreatmentHistoryCoursesProps = {
    onClose: () => void
    val: any
}

const PopupTreatmentHistoryCourses = (props: PopupTreatmentHistoryCoursesProps) => {
    const { t } = useTranslation()

    const [load, setLoading] = useState<boolean>(false)

    const [dataCourses, setDataCourses] = useState<any>([])
    const [page, setPage] = useState<number>(1)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [rowCount, setRowCount] = useState<number>(0);

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }
    const handleChangePage = (val: number) => setPage(val)

    const loadData = useCallback(async () => {
        const condition: TreatmentCoursesPaidHistoryProps = {
            page: page,
            pageLimit: pageLimit
        }
        const patienId = props.val.patientTreatmentCourseId
        setLoading(true)
        await TreatmentApi.treatmentCoursesPaidHistory(condition, patienId).then((res) => {
            setDataCourses(res.data)
            setRowCount(res.headers['x-total'])
        }).catch((err) => {
            setLoading(false)
        })
        setLoading(false)
    }, [page, pageLimit])

    useEffect(() => {
        loadData()
    }, [page, pageLimit])

    const headCells = [
        { id: 'index', disablePadding: false, labelKey: 'TXC.POPUP_HISTORY.TABLE.INDEX', align: 'center', class: 'text-nowrap', width: '3rem' },
        { id: 'createdAt', disablePadding: false, labelKey: 'TXC.POPUP_HISTORY.TABLE.DATE', align: 'left', class: 'text-nowrap', width: '12rem' },
        { id: 'branchName', disablePadding: false, labelKey: 'TXC.POPUP_HISTORY.TABLE.BRANCH', align: 'left', class: 'text-nowrap', width: '15rem' },
        { id: 'operativeName', disablePadding: false, labelKey: 'TXC.POPUP_HISTORY.TABLE.SERVICE', align: 'left', class: 'text-nowrap' },
        { id: 'total', disablePadding: false, labelKey: 'TXC.POPUP_HISTORY.TABLE.PAYMENT', align: 'center', class: 'text-nowrap', width: '10rem' },
    ]

    const renderData = (data: any, no: number) => {
        const { createdAt, branchName, operativeName, total } = data
        const index = page * pageLimit - pageLimit + no + 1
        return <>
            <TableRowCommon
                key={no.toString()}
                id={no.toString()}
                obj={data}
                columns={[
                    { option: 'TEXT', align: 'center', label: index || "-" },
                    { option: 'TEXT', align: 'left', label: dateTimeToView(createdAt) || "-" },
                    { option: 'TEXT', align: 'left', label: branchName || "-" },
                    { option: 'TEXT', align: 'left', label: operativeName || "-" },
                    { option: 'TEXT', align: 'center', label: numberFormat(total) || "-" },
                ]}
            />
        </>
    }

    const renderSum = (data: any) => {
        return (
            <TableRow className="payTotal" style={{ background: "#E9E9E9" }}>
                <TableCell style={{ fontWeight: 'bolder', fontSize: '18px' }} align="left" scope="row">
                    {t('TXC.POPUP_HISTORY.TABLE.TOTAL')}
                </TableCell>
                <TableCell colSpan={3} style={{ fontWeight: 'bold' }} align="right" scope="row">
                </TableCell>
                <TableCell style={{ fontWeight: 'bolder', fontSize: '18px' }} align="center" scope="row">
                    {numberFormat(data)}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            <ModalCustom
                closeButton
                onClose={() => { props.onClose() }}
                title={t('TXC.POPUP_HISTORY.TITLE')}
                size='xl'
                component={
                    <>
                        <div style={{ position: "relative", height: 'calc(100vh - 200px)' }}>
                            <Loading show={load} />
                            <Box
                                sx={{
                                    '.MuiTableContainer-root': {
                                        maxHeight: '660px',
                                        overflow: 'auto',
                                        'table ': {
                                            'thead ': {
                                                position: 'sticky',
                                                top: '0',
                                                zIndex: '2'
                                            },
                                            '.payTotal': {
                                                position: 'sticky',
                                                bottom: '0',
                                                zIndex: '2'
                                            }
                                        }
                                    },

                                }}
                            >
                                <TableCustom
                                    page={page}
                                    pageLimit={pageLimit}
                                    sortType={''}
                                    sortBy={''}
                                    rowCount={rowCount}
                                    onSort={() => { return }}
                                    setPageLimit={handleChangeRowsPerPage}
                                    setPage={handleChangePage}
                                    headCells={headCells}
                                    rowsData={[
                                        dataCourses.rows && dataCourses.rows.length !== 0 && dataCourses.rows.map((item: any, index: number) => {
                                            return renderData(item, index)
                                        }).reverse() || [],
                                        renderSum(dataCourses.sum || 0)
                                    ]
                                    }
                                />
                            </Box>
                            <div style={{ position: 'absolute', bottom: '0', right: '0' }}>
                                <ButtonCustom
                                    onClick={() => {
                                        props.onClose()
                                    }}
                                    style={{
                                        height: '40px', minWidth: '45px',
                                    }}
                                    mode={'del'}
                                    textButton={"TXC.POPUP_HISTORY.CLOSE"}
                                />
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default PopupTreatmentHistoryCourses