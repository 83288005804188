import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/54.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth54(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 49.51, height: 92.16, viewBox: "0 0 49.51 92.16" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 49.51, height: 92.16, viewBox: "0 0 49.51 92.16" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={width > 1440 ? 70 : 100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12357" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5440"
                                d="M7.62356 50.9323C6.68488 56.9386 2.5068 61.773 0.890625 67.3368L1.65486 74.493L2.32357 80.8239C3.89924 83.5654 5.80926 85.99 8.56204 87.8833C13.4562 91.2661 17.2604 87.5077 21.8046 86.078C27.1844 91.5318 30.9808 88.5836 33.9571 83.7343C44.5815 83.4256 50.263 72.8865 44.43 62.393C39.9338 54.2861 40.6982 46.1569 41.1751 37.9767C41.836 26.9442 39.815 16.2433 38.1755 5.46167C38.0617 4.94331 37.8519 4.45082 37.557 4.00961C37.5157 3.94525 37.4573 3.8937 37.3883 3.86078C37.3192 3.82787 37.2424 3.81489 37.1664 3.82331C37.0904 3.83173 37.0182 3.86122 36.9581 3.90845C36.898 3.95569 36.8522 4.01878 36.826 4.09062C35.3216 8.848 31.4691 37.2734 25.7205 43.3264C21.7891 47.4653 21.2981 29.9069 22.3768 0.871654C22.4049 0.132637 21.9255 -0.0882282 21.2877 0.35694C18.4566 2.33288 17.8567 5.53809 16.9583 8.38678C12.5575 22.3156 9.85207 36.62 7.62356 50.9323Z"
                                transform={width > 1440 ? "translate(0 10)" : "translate(0 0)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5440"
                                    d="M7.62356 50.9323C6.68488 56.9386 2.5068 61.773 0.890625 67.3368L1.65486 74.493L2.32357 80.8239C3.89924 83.5654 5.80926 85.99 8.56204 87.8833C13.4562 91.2661 17.2604 87.5077 21.8046 86.078C27.1844 91.5318 30.9808 88.5836 33.9571 83.7343C44.5815 83.4256 50.263 72.8865 44.43 62.393C39.9338 54.2861 40.6982 46.1569 41.1751 37.9767C41.836 26.9442 39.815 16.2433 38.1755 5.46167C38.0617 4.94331 37.8519 4.45082 37.557 4.00961C37.5157 3.94525 37.4573 3.8937 37.3883 3.86078C37.3192 3.82787 37.2424 3.81489 37.1664 3.82331C37.0904 3.83173 37.0182 3.86122 36.9581 3.90845C36.898 3.95569 36.8522 4.01878 36.826 4.09062C35.3216 8.848 31.4691 37.2734 25.7205 43.3264C21.7891 47.4653 21.2981 29.9069 22.3768 0.871654C22.4049 0.132637 21.9255 -0.0882282 21.2877 0.35694C18.4566 2.33288 17.8567 5.53809 16.9583 8.38678C12.5575 22.3156 9.85207 36.62 7.62356 50.9323Z"
                                    transform={width > 1440 ? "translate(0 10)" : "translate(0 0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12357" >
                                <path
                                    data-name="Path 5441"
                                    d="M44.0455 6.61006L49.2115 18.378C49.2115 18.378 52.0531 26.7368 49.2115 31.9109C46.3699 37.085 34.234 42.7596 19.5117 42.2589C4.78945 41.7581 1.00895 31.0767 1.00895 31.0767C1.00895 31.0767 -0.0766422 15.8407 8.52332 4.93218C8.52332 4.93218 18.7158 -4.30726 30.255 2.53572C30.2558 2.53591 39.3334 0.336437 44.0455 6.61006Z"
                                    transform={width > 1440 ? "translate(-2 60)" : "translate(-2 55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5442"
                                    d="M42.3986 8.86612L46.9066 19.135C46.9066 19.135 49.3868 26.4293 46.9066 30.9444C44.4265 35.4595 33.8364 40.4112 20.989 39.9746C8.14164 39.538 4.84319 30.2161 4.84319 30.2161C4.84319 30.2161 3.89572 16.9216 11.4011 7.40222C11.4011 7.40222 20.2949 -0.660482 30.3649 5.31089C30.3649 5.31089 38.2858 3.39131 42.3986 8.86612Z"
                                    transform={width > 1440 ? "translate(-2 60)" : "translate(-2 55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5443"
                                    d="M38.5465 11.0801L42.5935 20.1675C42.5935 20.1675 44.8184 26.6234 42.5935 30.6184C40.3686 34.6135 30.8625 38.9958 19.3311 38.6095C7.79968 38.2232 4.83921 29.9736 4.83921 29.9736C4.83921 29.9736 3.98919 18.2082 10.725 9.7834C10.725 9.7834 18.7082 2.64829 27.7463 7.93223C27.7463 7.9328 34.8558 6.23486 38.5465 11.0801Z"
                                    transform={width > 1440 ? "translate(-2 60)" : "translate(-2 55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6546" d="M22.9624 47.4371C23.4185 44.2183 23.2637 44.7261 23.565 41.5507C24.0321 34.7417 27.799 5.8986 27.799 4.56951C28.2143 3.89864 23.4273 45.6994 22.5664 47.3771C22.6325 47.381 22.8589 47.4283 22.9624 47.4371Z" transform={width > 1440 ? "translate(10 14)" : "translate(10 11)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                        <path data-name="Path 6547" d="M2.1875 46.1738C3.18225 39.3296 6.01722 9.84025 8.79712 1.53967C8.54815 0.792236 2.19191 31.7241 3.28084 45.9331C2.91233 45.9936 2.54735 46.074 2.1875 46.1738Z" transform={width > 1440 ? "translate(12 14)" : "translate(10 11)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                    </>
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154147" x1="24.9623" y1="66.0356" x2="22.0502" y2="82.6576" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154147" x1="24.9623" y1="77.6947" x2="24.9623" y2="71.5297" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>

                                        <g data-name="Group 9545">
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M9.34777 49.8584C8.43774 55.6814 4.38716 60.3683 2.82031 65.7623L3.56123 72.7001L4.20953 78.8378C5.73711 81.4957 7.58884 83.8462 10.2576 85.6818C15.0024 88.9613 18.6905 85.3177 23.096 83.9316C28.3117 89.219 31.9922 86.3607 34.8777 81.6594C45.1779 81.3601 50.686 71.1427 45.031 60.9694C40.672 53.1099 41.4131 45.2288 41.8754 37.2982C42.5161 26.6024 40.5568 16.2281 38.9673 5.77545C38.857 5.27292 38.6536 4.79546 38.3677 4.36772C38.3277 4.30532 38.271 4.25534 38.2041 4.22343C38.1372 4.19152 38.0627 4.17893 37.989 4.1871C37.9153 4.19526 37.8454 4.22385 37.7871 4.26964C37.7288 4.31543 37.6844 4.3766 37.659 4.44625C36.2006 9.05845 32.4656 36.6164 26.8925 42.4846C23.081 46.4972 22.605 29.4747 23.6508 1.32552C23.678 0.609055 23.2132 0.394931 22.595 0.826514C19.8502 2.74215 19.2686 5.84955 18.3977 8.61131C14.1311 22.115 11.5083 35.9829 9.34777 49.8584Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M42.719 55.3628L47.7274 66.7716C47.7274 66.7716 50.4823 74.8753 47.7274 79.8915C44.9725 84.9077 33.207 90.4092 18.934 89.9237C4.66101 89.4382 0.995889 79.0828 0.995889 79.0828C0.995889 79.0828 -0.0565772 64.3118 8.28094 53.7361C8.28094 53.7361 18.1623 44.7786 29.3494 51.4128C29.3501 51.413 38.1507 49.2806 42.719 55.3628Z" fill="#EFEDE7" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M41.1263 57.5508L45.4968 67.5062C45.4968 67.5062 47.9012 74.5779 45.4968 78.9552C43.0923 83.3326 32.8255 88.1331 20.3701 87.7099C7.9148 87.2866 4.71701 78.2491 4.71701 78.2491C4.71701 78.2491 3.79846 65.3604 11.0748 56.1315C11.0748 56.1315 19.6972 48.3149 29.4599 54.104C29.4599 54.104 37.1391 52.243 41.1263 57.5508Z" fill="#F7F7F7" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M37.3918 59.698L41.3153 68.508C41.3153 68.508 43.4723 74.7669 41.3153 78.6401C39.1583 82.5132 29.9422 86.7618 18.7628 86.3872C7.58328 86.0127 4.71316 78.0149 4.71316 78.0149C4.71316 78.0149 3.88907 66.6085 10.4193 58.4409C10.4193 58.4409 18.1589 51.5235 26.9211 56.6462C26.9211 56.6467 33.8137 55.0006 37.3918 59.698Z" fill="white" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M31.552 61H18.448C16.5437 61 15 62.5437 15 64.448V77.552C15 79.4563 16.5437 81 18.448 81H31.552C33.4563 81 35 79.4563 35 77.552V64.448C35 62.5437 33.4563 61 31.552 61Z" fill="#C1C4C5" />
                                            {/* <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M31.5533 61.8623H18.4493C17.0211 61.8623 15.8633 63.0201 15.8633 64.4483V77.5523C15.8633 78.9805 17.0211 80.1383 18.4493 80.1383H31.5533C32.9815 80.1383 34.1393 78.9805 34.1393 77.5523V64.4483C34.1393 63.0201 32.9815 61.8623 31.5533 61.8623Z" stroke="#C1C4C5" /> */}
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M23.4825 80.1082C23.1377 80.7323 26.3719 80.4237 28.7975 80.3927C32.4075 80.3168 34.4677 81.0547 34.4332 72.7209C34.5269 69.7637 34.4354 66.8036 34.1591 63.8579C33.2437 62.9683 34.4005 72.8847 32.0576 77.2464C29.8474 80.3961 24.0738 79.4393 23.4825 80.1082Z" fill="#B0B1B1" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M28.4103 71.7139H21.5143C21.0383 71.7139 20.6523 72.0998 20.6523 72.5759V74.2999C20.6523 74.7759 21.0383 75.1618 21.5143 75.1618H28.4103C28.8864 75.1618 29.2723 74.7759 29.2723 74.2999V72.5759C29.2723 72.0998 28.8864 71.7139 28.4103 71.7139Z" fill="#CBCCCC" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M28.0705 64.4736H21.1745C20.6984 64.4736 20.3125 64.8596 20.3125 65.3356V67.0596C20.3125 67.5357 20.6984 67.9216 21.1745 67.9216H28.0705C28.5465 67.9216 28.9324 67.5357 28.9324 67.0596V65.3356C28.9324 64.8596 28.5465 64.4736 28.0705 64.4736Z" fill="#9E9E9E" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M28.4103 64.4736H21.5143C21.0383 64.4736 20.6523 64.8596 20.6523 65.3356V67.0596C20.6523 67.5357 21.0383 67.9216 21.5143 67.9216H28.4103C28.8864 67.9216 29.2723 67.5357 29.2723 67.0596V65.3356C29.2723 64.8596 28.8864 64.4736 28.4103 64.4736Z" fill="url(#paint0_linear_4126_154147)" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M28.4103 71.3711H21.5143C21.0383 71.3711 20.6523 71.757 20.6523 72.2331V73.9571C20.6523 74.4331 21.0383 74.8191 21.5143 74.8191H28.4103C28.8864 74.8191 29.2723 74.4331 29.2723 73.9571V72.2331C29.2723 71.757 28.8864 71.3711 28.4103 71.3711Z" fill="url(#paint1_linear_4126_154147)" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M4.83594 71.2172C4.83594 71.2172 22.6858 66.2456 46.1495 70.1855" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={width > 1440 ? "translate(-2 10)" : "translate(-2 8)"} d="M4.83594 71.727C18.2787 68.732 32.4787 68.3688 46.1495 70.6703" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>54</TextNumberU>
        </Div >
    )
}
