import { useState, useEffect } from 'react'
import { Box, Typography, Button, TableRow, TableCell, IconButton } from '@mui/material'
import { useForm, useFieldArray } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import RecommendApi from 'api/setting/treatments/recommend.api'

/** CONSTANTS */
import { SORT_TYPE } from 'constants/common'
import { colors } from 'constants/theme'
import { icons } from 'constants/images'

/** UTILS || SLICE */
import { handleShow, resetModal } from 'app/slice/modal.slice'
import { treatment, setRecommendByKey, setRecommendTreatments, TreatmentStateInterface } from 'app/slice/treatment.slice'

/** COMPONENT */
import { notiPopup } from 'component/notifications/notifications'
import { ToastContainer } from 'react-toastify'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import InputTextField from 'component/Input/InputTextField'
import Loading from 'component/Loading'

/** STYLE */
import { styled } from '@mui/material/styles'

const FragmentTabs = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${colors.white}`
}))

const ButtonTab = styled(Button)(({ theme }) => ({
  width: '50%',
  height: 40,
  color: colors.textPrimary,
  padding: 0,
  borderBottom: `1px solid transparent`,
  borderRadius: 0,
  '&.active': {
    color: colors.themeSecondColor,
    borderColor: colors.themeSecondColor
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 185
  }
}))

const FragmentMessage = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: '50%'
  }
}))

const ResponsiveTable = styled('div')(({ theme }) => ({
  maxHeight: '100%',
  '.MuiPaper-root': {
    minHeight: 'unset !important',
    maxHeight: '100%'
  },
  '.MuiTable-root': {
    minWidth: 'unset',
    width: '100%',
    tableLayout: 'fixed',
    '.MuiTableHead-root': {
      border: 'none !important',
      '.MuiTableCell-root': {
        fontWeight: 400,
        padding: 12,
        position: 'sticky',
        top: 0,
        zIndex: 5
      },
      '.MuiTableCell-root:nth-of-type(1)': {
        width: 50,
        borderTopLeftRadius: 4
      },
      '.MuiTableCell-root:nth-of-type(2)': {},
      '.MuiTableCell-root:nth-of-type(3)': {
        width: 80,
        textAlign: 'center',
        borderTopRightRadius: 4
      }
    },
    '.MuiTableBody-root': {
      position: 'relative',
      zIndex: 0,
      '.MuiTableCell-root': {
        fontWeight: 400,
        padding: 10,
        backgroundColor: colors.white,
        verticalAlign: 'top'
      },
      '.MuiOutlinedInput-root, .MuiOutlinedInput-root input': {
        height: 35
      },
      '.new-field': {
        '.MuiTableCell-root:not(:nth-of-type(2))': {
          paddingTop: 16
        }
      }
    }
  },
  '.MuiTableFooter-root': {
    display: 'none'
  },
  '.row-add-new-message': {
    '.btn-add-new-message': {
      padding: '0 0 0 14px',
      fontSize: 16,
      color: colors.themeSecondColor,
      backgroundColor: 'transparent !important',
      fontWeight: 400,
      span: {
        fontSize: 20,
        color: colors.themeSecondColor
      },
      '&:hover': {
        fontWeight: 600
      }
    },
    '.MuiTableCell-root:hover': {
      cursor: 'pointer',
      '.btn-add-new-message': {
        fontWeight: 600
      }
    }
  }
}))

export interface PopupRecommendProps {
  onSubmit?: () => void
  onReset?: () => void
  no: number | null
}

interface RecommendType {
  recommendId: number | null
  recommendText: string
}

export default function PopupRecommend(props: PopupRecommendProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const modal: boolean = useSelector(handleShow)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const patientRecommends: RecommendType[] = treatments.patientRecommends

  const tabs: string[] = ['RECOMMEND_SUMMARY.POPUP.DEFAULT_MESSAGE', 'RECOMMEND_SUMMARY.POPUP.USER_MESSAGE']
  const [activeTab, setActiveTab] = useState<number>(0)

  const [defaultRecommends, setDefaultRecommends] = useState<RecommendType[]>([]);
  const [userRecommends, setUserRecommends] = useState<RecommendType[]>([]);
  const [userTemplateRecommends, setUserTemplateRecommends] = useState<RecommendType[]>([])
  const [defaultLoading, setDefaultLoading] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(false);

  const loadDefaultRecommends = () => {
    setDefaultLoading(true)
    RecommendApi.findAll({ pageLimit: 100, useWith: 'ALL', sortType: SORT_TYPE.DESC })
      .then(({ data }) => setDefaultRecommends(data))
      .catch(() => setDefaultRecommends([]))
      .finally(() => setDefaultLoading(false))
  }

  const loadUserRecommends = () => {
    setUserLoading(true)
    RecommendApi.findAll({ pageLimit: 100, useWith: 'USER', sortType: SORT_TYPE.DESC })
      .then(({ data }) => setUserRecommends(data))
      .catch(() => setUserRecommends([]))
      .finally(() => setUserLoading(false))
  }

  const handleAddRecommend = async (msg: RecommendType, type: 'default' | 'user', no?: number) => {
    if (!msg.recommendId && type === 'user' && (no || no === 0)) {
      const data = await RecommendApi.updateByUserOne({ recommendId: msg.recommendId, recommendText: msg.recommendText })
      const newRecommend: RecommendType = {
        recommendId: data.data.recommendId,
        recommendText: data.data.recommendText,
      }
      if (props.no !== null) dispatch(setRecommendTreatments({ index: props.no, value: newRecommend }))
      else dispatch(setRecommendByKey({ key: 'patientRecommends', value: [...patientRecommends, newRecommend] }))
      return data.data.recommendId
    }
    if (props.no !== null) dispatch(setRecommendTreatments({ index: props.no, value: msg }))
    else dispatch(setRecommendByKey({ key: 'patientRecommends', value: [...patientRecommends, msg] }))
    return null
  }

  const handleSaveRecommend = () => {
    notiPopup('clear')
    let template: RecommendType[] = []
    _.forEach(userTemplateRecommends, (msg: RecommendType) => {
      if (msg.recommendText) return template = [...template, { recommendId: msg.recommendId, recommendText: msg.recommendText }]
    })
    RecommendApi.updateByUser(template)
      .then(() => {
        notiPopup('success', t(`RECOMMEND_SUMMARY.POPUP.MESSAGE.SUCCESS.USER_MESSAGE`))
        loadUserRecommends()
      })
      .catch(() => { return })
  }

  const resetPopup = () => {
    dispatch(resetModal())
  }

  useEffect(() => {
    loadDefaultRecommends()
    loadUserRecommends()
  }, []);

  useEffect(() => {
    if (!modal) resetPopup()
  }, [modal]);

  return (
    <ModalCustom
      size={'xl'}
      title={t('RECOMMEND_SUMMARY.TITLE.RECOMMEND')}
      textBtnCancel={t('BUTTON.CANCEL')}
      textBtnConfirm={t('BUTTON.SAVE')}
      alignFooter={'end'}
      fullscreen
      footerInline
      onReset={resetPopup}
      onSubmit={handleSaveRecommend}
      onClose={resetPopup}
      modalStyle={'modal-recommend'}
      component={
        <Box className="pt-1 d-flex flex-column overflow-hidden h-100" sx={{ '*': { color: colors.textPrimary } }}>
          <FragmentTabs className="d-xl-none">
            {_.map(tabs, (tab: string, indexTab: number) => {
              return (
                <ButtonTab className={`${indexTab === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(indexTab)}>
                  {t(tab)}
                </ButtonTab>
              )
            })}
          </FragmentTabs>
          {
            (!defaultLoading || !userLoading) && (
              <Box className="d-flex overflow-hidden">
                <FragmentMessage className={`w-100 ${activeTab === 0 ? 'd-flex' : 'd-none'} d-xl-flex flex-column pr-xl-2`}>
                  <Typography className="d-none d-xl-block mb-1" sx={{ fontSize: 18, fontWeight: 500 }}>
                    {t('RECOMMEND_SUMMARY.POPUP.DEFAULT_MESSAGE')}
                  </Typography>
                  <DefaultRecommendLayout messages={defaultRecommends} addMessage={(msg: RecommendType) => handleAddRecommend(msg, 'default')} />
                </FragmentMessage>

                <FragmentMessage className={`w-100 ${activeTab === 1 ? 'd-flex' : 'd-none'} d-xl-flex flex-column pl-xl-2`}>
                  <Typography className="d-none d-xl-block mb-1" sx={{ fontSize: 18, fontWeight: 500 }}>
                    {t('RECOMMEND_SUMMARY.POPUP.USER_MESSAGE')}
                  </Typography>
                  <ToastContainer containerId={'popup'} className={'Toast-popup'} />
                  <UserRecommendLayout
                    messages={userRecommends}
                    addMessage={(msg: RecommendType, no) => handleAddRecommend(msg, 'user', no)}
                    setTemplateUserMessage={(messages: RecommendType[]) => {
                      if (!_.isEmpty(messages)) setUserTemplateRecommends(messages)
                      else setUserTemplateRecommends([])
                    }}
                  />
                </FragmentMessage>
              </Box>
            ) || (
              <Box className='w-max mx-auto'>
                <Loading show type={'softLoading'} />
              </Box>
            )}
        </Box>
      }
    />
  )
}

type DefaultRecommendLayoutProps = {
  messages: RecommendType[]
  addMessage?: (msg: RecommendType) => void
}

function DefaultRecommendLayout(props: DefaultRecommendLayoutProps) {
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const patientRecommends: RecommendType[] = treatments.patientRecommends
  const { t } = useTranslation()

  const handleAddMessage = (msg: RecommendType) => {
    if (props.addMessage) props.addMessage({ recommendId: msg.recommendId, recommendText: msg.recommendText })
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('RECOMMEND_SUMMARY.POPUP.DEFAULT_MESSAGE_CELL') },
    { id: 'message', disablePadding: false, label: t('Action') }
  ]

  const renderData = (data: RecommendType, no: number) => {
    return (
      <TableRow key={`defaultMessage-${no}`}>
        <TableCell align="center" scope="row">
          {props.messages?.length - no}
        </TableCell>
        <TableCell scope="row">{data.recommendText}</TableCell>
        <TableCell align="center" scope="row">
          <IconButton
            aria-label="add"
            onClick={() => handleAddMessage(data)}
            disabled={_.some(patientRecommends, { recommendId: data.recommendId })}
            sx={{
              backgroundColor: 'transparent !important',
              padding: 0,
              filter: _.some(patientRecommends, { recommendId: data.recommendId }) ? 'grayscale(1)' : 'grayscale(0)',
              opacity: _.some(patientRecommends, { recommendId: data.recommendId }) ? .5 : 1
            }}>
            <img src={icons.btnAdd} alt="add" style={{ width: 24 }} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ResponsiveTable className={'mt-2 overflow-hidden'}>
      <TableCustom
        customScroll
        hidePagination
        page={1}
        pageLimit={0}
        sortType={'ASC'}
        sortBy={''}
        rowCount={0}
        onSort={() => { return }}
        setPageLimit={() => { return }}
        setPage={() => { return }}
        headCells={headCells}
        rowsData={_.map(props.messages, (message: RecommendType, index) => {
          return renderData(message, index)
        })}
      />
    </ResponsiveTable>
  )
}

type UserRecommendLayoutProps = {
  messages: RecommendType[]
  addMessage?: (msg: RecommendType, no: number) => Promise<number | null>
  setTemplateUserMessage?: (message: RecommendType[]) => void
}

function UserRecommendLayout(props: UserRecommendLayoutProps) {
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const patientRecommends: RecommendType[] = treatments.patientRecommends

  const { t } = useTranslation()
  const { register, control, getValues, setValue } = useForm()

  const [error, setError] = useState('')

  const formMessage = useFieldArray({
    control,
    name: 'message'
  })

  const handleAddMessage = async (msg: RecommendType, no: number) => {
    const originalString = msg.recommendText
    const trimmedString = originalString.replace(/^\s+/, ""); // Replace white spaces at the start
    if (trimmedString === "") return setError(t('VALIDATION.ENTER_MESSAGE'))
    if (props.addMessage) {
      const id = await props.addMessage({ recommendId: msg.recommendId, recommendText: trimmedString }, no)
      if (id) {
        const updatedMessages = getValues('message')
        updatedMessages[no] = { ...updatedMessages[no], recommendId: id };
        setValue('message', updatedMessages)
      }
    }
  }

  useEffect(() => {
    if (!_.isEmpty(props.messages)) {
      formMessage.replace(props.messages)
      if (props.setTemplateUserMessage) props.setTemplateUserMessage(props.messages)
    }
  }, [props.messages])

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('RECOMMEND_SUMMARY.POPUP.USER_MESSAGE_CELL') },
    { id: 'message', disablePadding: false, label: t('Action') }
  ]

  const addNewMessage = () => {
    return (
      <TableRow className="row-add-new-message">
        <TableCell
          align="left"
          scope="row"
          colSpan={3}
          onClick={() => {
            if (_.some(getValues('message'), (msg) => !msg.recommendText)) return setError(t('VALIDATION.ENTER_MESSAGE'))
            else formMessage.prepend({ recommendId: null, recommendText: '' })
          }}>
          <Button variant="text" className="btn-add-new-message d-flex align-items-baseline">
            <span className={'mr-1'}>+</span> {t('RECOMMEND_SUMMARY.POPUP.ADD_RECOMMEND')}
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  const renderData = (data: RecommendType, no: number) => {
    return (
      <TableRow key={`UserMessage-${no}`} className={`${!getValues(`message.${no}.recommendId`) ? 'new-field' : ''}`}>
        <TableCell align="center" scope="row">
          {formMessage.fields.length - no}
        </TableCell>
        <TableCell scope="row">
          {(!getValues(`message.${no}.recommendId`) && (
            <InputTextField
              inputProps={{ maxLength: 255 }}
              name="message"
              key={`message.${no}.recommendId`}
              register={register(`message.${no}.recommendText`, {
                onChange: () => {
                  if (props.setTemplateUserMessage) props.setTemplateUserMessage(getValues('message'))
                  setError('')
                }
              })}
              helperText={!getValues(`message.${no}.recommendText`) ? error : ''}
            />
          )) ||
            data.recommendText}
        </TableCell>
        <TableCell align="center" scope="row">
          <IconButton
            className="mr-1"
            aria-label="add"
            onClick={() => handleAddMessage(getValues(`message.${no}`), no)}
            disabled={_.some(patientRecommends, { recommendId: data.recommendId })}
            sx={{
              backgroundColor: 'transparent !important',
              padding: 0,
              filter: _.some(patientRecommends, { recommendId: data.recommendId }) ? 'grayscale(1)' : 'grayscale(0)',
              opacity: _.some(patientRecommends, { recommendId: data.recommendId }) ? .5 : 1
            }}>
            <img src={icons.btnAdd} alt="add" style={{ width: 24 }} />
          </IconButton>
          <IconButton
            className="ml-1"
            aria-label="trash"
            onClick={() => {
              formMessage.remove(no)
              if (props.setTemplateUserMessage) props.setTemplateUserMessage(getValues('message'))
              setError('')
            }}
            disabled={_.some(patientRecommends, { recommendId: data.recommendId })}
            sx={{
              backgroundColor: 'transparent !important',
              padding: 0,
              filter: _.some(patientRecommends, { recommendId: data.recommendId }) ? 'grayscale(1)' : 'grayscale(0)',
              opacity: _.some(patientRecommends, { recommendId: data.recommendId }) ? .5 : 1
            }}>
            <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <ResponsiveTable className={'mt-2 overflow-hidden'}>
      <TableCustom
        customScroll
        hidePagination
        page={1}
        pageLimit={0}
        sortType={'ASC'}
        sortBy={''}
        rowCount={0}
        onSort={() => { return }}
        setPageLimit={() => { return }}
        setPage={() => { return }}
        headCells={headCells}
        rowsData={[
          addNewMessage(),
          _.map(formMessage.fields, (message: RecommendType, index: number) => {
            return renderData(message, index)
          })
        ]}
      />
    </ResponsiveTable>
  )
}