import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface PaymentChannelsInterface {
  paymentChannelId: string
  paymentChannelName: string
  paymentChannelNameEn: string
  status: string
  updatedBy: string
  updatedAt: string
}

export default class PaymentChannelsApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('payment-channels', { params: { ...props } })
  }
}
