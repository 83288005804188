import { useEffect, useState } from 'react'
import classNames from 'classnames'
import 'component/Calendar/Style.css'
import { Image, CloseButton, Modal, Dropdown } from 'react-bootstrap'
import { TimePicker, Select } from 'antd'
import moment from 'moment'
import Input from '@mui/material/Input'
import { colors } from 'constants/theme'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import MultipleSelect from 'component/Select/MultipleSelect'

/** ICON */
import iconAssistant from 'assets/icon/carlendar/iconAssistant.svg'
import iconBank from 'assets/icon/carlendar/iconBank.svg'
import iconTime from 'assets/icon/carlendar/iconTime.svg'
import iconDelete from 'assets/icon/iconDelete.svg'
import iconEllipsis from 'assets/icon/iconEllipsis.svg'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

/** UTILS || SLICE */
import { daysOfWeek, createDaysForCurrentMonth, createDaysForNextMonth, createDaysForPreviousMonth, isWeekendDay, getWeekday } from 'utils/calendar-helper.utils'

interface Item {
  permission: boolean
  text: string
}

interface DayOfWeekObjectInterface {
  [key: number]: Item
}

interface CalendarDayObjectInterface {
  dateString: string
  dayOfMonth: number
  isCurrentMonth: boolean
  isPreviousMonth?: boolean
  isNextMonth?: boolean
}

interface CalendarProps {
  className?: string
  yearAndMonth: number[]
  onYearAndMonthChange: (a: any) => any
  onEditDaysOfWeek: (day: number) => void
  onGetFirstMessage: (day: string) => void
  daysOfWeekObject: DayOfWeekObjectInterface
  headerDayOfWeek: boolean
  renderDay: (val: CalendarDayObjectInterface) => any
}

export default function Calendar(props: CalendarProps) {
  const [year, month] = props.yearAndMonth
  const currentMonthDays = createDaysForCurrentMonth(year, month)
  const previousMonthDays = createDaysForPreviousMonth(year, month, currentMonthDays)
  const nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays)
  const calendarGridDayObjects = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays]
  return (
    <div className="calendar-root">
      <div className="days-of-week-edit sticky-top" style={{ display: props.headerDayOfWeek === false ? 'none' : '' }}>
        {daysOfWeek.map((day, index) => (
          <div
            style={{
              backgroundColor: props.daysOfWeekObject[index].permission ? colors.themeSecondColor : colors.white,
              border: `1px solid ${colors.themeSecondColor}`,
              color: props.daysOfWeekObject[index].permission ? colors.white : colors.themeSecondColor
            }}
            onClick={(e) => {
              const headerFilterDate = calendarGridDayObjects.filter((date: any) => getWeekday(date.dateString) === index && date.isCurrentMonth)
              props.onEditDaysOfWeek(index)
              props.onGetFirstMessage(headerFilterDate[0].dateString)
            }}
            key={day}
            className={classNames('day-of-week-header-cell-edit', {
              'weekend-day': [6, 0].includes(index)
            })}
          >
            {day}
          </div>
        ))}
      </div>
      <div className="days-of-week sticky-top" style={{ display: props.headerDayOfWeek === true ? 'none' : '' }}>
        {daysOfWeek.map((day, index) => (
          <div
            key={day}
            className={classNames('day-of-week-header-cell', {
              'weekend-day': [6, 0].includes(index)
            })}
          >
            {day}
          </div>
        ))}
      </div>
      <div className="days-grid">
        {calendarGridDayObjects.map((day: any) => {
          return (
            <div
              key={day.dateString}
              className={classNames('day-grid-item-container', {
                'weekend-day': isWeekendDay(day.dateString),
                'current-month': day.isCurrentMonth
              })}
            >
              <div className="day-content-wrapper">{props.renderDay(day)}</div>
            </div>
          )
        })}
      </div>
    </div >
  )
}
interface TimeInterface {
  timeStart: string
  timeEnd: string
}

interface DentistFilteredInterface {
  name: string
  time: TimeInterface
  assistant: any
  costCondition: any
  date: string
  worngDuty: boolean
  cancelDuty: boolean
}

const assistant = ['สมใจ เก่งกาจ', 'วีรพล เจริญผลวัฒนา', 'อารยา พิกุลเทพ', 'สมรักษ์ เก่งกาจ']
const dayOfWeekTH = ['จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์', 'อาทิตย์']
const monthTH = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
type CalendarItem = {
  calendarDayObject: CalendarDayObjectInterface
  data: DentistFilteredInterface[]
  handleData: (data: any) => void
  editTable: boolean
  dentistName: string
  dentistFilteredLabel: DentistFilteredInterface
  setDayOfWeekMessage: any
  dayOfWeekMessage: any
  daysOfWeekObject: any
  onEditDaysOfWeek: (day: number) => void
  handleWeeksMessage: any
  handleDeleteData: (obj: any) => void
}

export function CalendarDayHeader({ calendarDayObject, data, editTable, handleData, dentistName, setDayOfWeekMessage, daysOfWeekObject, onEditDaysOfWeek, handleWeeksMessage, handleDeleteData, dentistFilteredLabel }: CalendarItem) {
  const { t } = useTranslation()
  const tempData: DentistFilteredInterface[] = data
  const [onClickeEdit, setOnClickEdit] = useState(false)
  const [message, setMessage]: any = useState('')
  const [onClickTrash, setOnClickTrash] = useState(true)
  const [time, setTime]: any = useState({ timeStart: '0:00', timeEnd: '0:00' })
  const [dentistAssistant, setDentistAssistant] = useState([])
  const [worngDuty, setWorngDuty] = useState(false)
  const [cancelDuty, setCancelDuty] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [manageItem, setManageItem] = useState(true)
  const { Option } = Select
  const [smShow, setSmShow] = useState(false)
  const [modalItem, setModalItem]: any = useState({})
  const [modalDate, setModalDate] = useState('')

  const item: DentistFilteredInterface[] = []
  useEffect(() => {
    setMessage('')
    setDentistAssistant([])
    setTime({ timeStart: '0:00', timeEnd: '0:00' })
    if (onClickeEdit || (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission && calendarDayObject.isCurrentMonth)) {
      setOnClickEdit(true)
    }

    if (onClickTrash === false && daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission === true) {
      setOnClickEdit(false)
    }

    if (!editTable) {
      setOnClickEdit(false)
    }

    if (dentistFilteredLabel && dentistFilteredLabel.name === dentistName) {
      setOnClickEdit(true)
      setTime({ timeStart: dentistFilteredLabel.time.timeStart, timeEnd: dentistFilteredLabel.time.timeEnd })
      setDentistAssistant(dentistFilteredLabel.assistant)
      setMessage(dentistFilteredLabel.costCondition)
      setCancelDuty(dentistFilteredLabel.cancelDuty)
      setWorngDuty(dentistFilteredLabel.worngDuty)
    } else {
      setMessage('')
      setOnClickEdit(false)
    }

    if (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission && calendarDayObject.isCurrentMonth) {
      setMessage(daysOfWeekObject.text)
      setTime(daysOfWeekObject.time)
    }
  }, [dentistName, daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission && calendarDayObject.isCurrentMonth, onClickTrash, editTable])

  for (const i in tempData) {
    if ((tempData[i].date === calendarDayObject.dateString && (dentistName === t('CALENDAR.ALL') || dentistName === '')) || (tempData[i].date === calendarDayObject.dateString && tempData[i].name === dentistName)) {
      item.push(tempData[i])
    }
  }

  if (editTable) {
    if (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission && calendarDayObject.isCurrentMonth) {
      if (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].text !== '') {
        const obj = {
          date: calendarDayObject.dateString,
          name: dentistName,
          time: daysOfWeekObject[getWeekday(calendarDayObject.dateString)].time,
          costCondition: daysOfWeekObject[getWeekday(calendarDayObject.dateString)].text,
          assistant: daysOfWeekObject[getWeekday(calendarDayObject.dateString)].assistant,
          worngDuty: false,
          cancelDuty: false
        }
        handleData(obj)
      }
    }
  }

  const onChangeTime = (times: any) => {
    const time1 = times !== null ? times[0].format('HH:mm') : '0:00'
    const time2 = times !== null ? times[1].format('HH:mm') : '0:00'
    setTime({ timeStart: time1, timeEnd: time2 })

    if (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission) {
      handleWeeksMessage(getWeekday(calendarDayObject.dateString), { timeStart: time1, timeEnd: time2 }, 'time')
      setDayOfWeekMessage({ timeStart: time1, timeEnd: time2 })

    } else {
      if (editTable) {
        const obj = {
          date: calendarDayObject.dateString,
          name: dentistName,
          assistant: dentistAssistant,
          time: { timeStart: time1, timeEnd: time2 },
          costCondition: message,
          worngDuty: false,
          cancelDuty: false
        }

        handleData(obj)
      }
    }
  }

  const onEditClass = () => {
    if ((onClickeEdit || (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission && calendarDayObject.isCurrentMonth))) {
      if (worngDuty) return 'container-item-worng-duty'
      else if (cancelDuty) return 'container-item-cancel-duty'
      else return 'container-item-edit'
    } else return 'container-item'
  }

  return editTable && calendarDayObject.isCurrentMonth && (
    <div
      className={onEditClass()}
    >
      <div className="header-item">
        <div
          className="day-grid-item-header"
          onClick={() => {
            setOnClickEdit(true)
          }}
        >
          {calendarDayObject.dayOfMonth}
        </div>
        <div
          style={{ display: (calendarDayObject.isCurrentMonth && onClickeEdit) || (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission && calendarDayObject.isCurrentMonth) ? '' : 'none', marginRight: '8px' }}
          className="trash-icon"
          onClick={() => {
            setOnClickEdit(false)
            setOnClickTrash(true)
            onEditDaysOfWeek(getWeekday(calendarDayObject.dateString))
            setMessage(0)
            const obj = {
              date: calendarDayObject.dateString,
              name: dentistName,
              costCondition: message,
              worngDuty: false,
              cancelDuty: false
            }
            handleDeleteData(obj)
          }}
        >
          <Image src={iconDelete} />
        </div>
        <Dropdown style={{ display: (calendarDayObject.isCurrentMonth && onClickeEdit) || (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission && calendarDayObject.isCurrentMonth) ? '' : 'none' }}>
          <Dropdown.Toggle variant="link" bsPrefix="p-0 " className="text-black-50">
            <Image src={iconEllipsis} />
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ fontSize: '16px' }}>
            <Dropdown.Item
              disabled={cancelDuty}
              onClick={(e) => {
                setCancelDuty(true)
                setWorngDuty(false)
                setManageItem(false)
                if (editTable) {
                  const obj = {
                    date: calendarDayObject.dateString,
                    name: dentistName,
                    assistant: dentistAssistant,
                    time: time,
                    worngDuty: false,
                    cancelDuty: true
                  }

                  handleData(obj)
                }
              }}
            >
              {t('CALENDAR.LEAVE')}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={worngDuty}
              onClick={(e) => {
                setWorngDuty(true)
                setCancelDuty(false)
                setManageItem(false)
                if (editTable) {
                  const obj = {
                    date: calendarDayObject.dateString,
                    name: dentistName,
                    assistant: dentistAssistant,
                    time: time,
                    worngDuty: true,
                    cancelDuty: false
                  }

                  handleData(obj)
                }
              }}
            >
              {t('CALENDAR.OFF_DUTY')}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={manageItem}
              onClick={(e) => {
                setCancelDuty(false)
                setWorngDuty(false)
                setManageItem(true)
                if (editTable) {
                  const obj = {
                    date: calendarDayObject.dateString,
                    name: dentistName,
                    assistant: dentistAssistant,
                    time: time,
                    worngDuty: false,
                    cancelDuty: false
                  }

                  handleData(obj)
                }
              }}
            >
              {t('CALENDAR.MANAGE_SHIFT')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {onClickeEdit || (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission && calendarDayObject.isCurrentMonth) &&
        ((cancelDuty || worngDuty) && (
          <div className="dentist-container-worng-duty">
            {' '}
            <div>{cancelDuty ? t('CALENDAR.LEAVE') : t('CALENDAR.OFF_DUTY')}</div>
          </div>
        ) || (
            <div>
              <TimePicker.RangePicker
                style={{ marginBottom: '6px' }}
                format={'HH:mm'}
                value={
                  daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission
                  && [moment(daysOfWeekObject[getWeekday(calendarDayObject.dateString)].time.timeStart, 'HH:mm'), moment(daysOfWeekObject[getWeekday(calendarDayObject.dateString)].time.timeEnd, 'HH:mm')]
                  || [moment(time.timeStart, 'HH:mm'), moment(time.timeEnd, 'HH:mm')]
                }
                onChange={(e) => onChangeTime(e)}
                minuteStep={5}
              />

              <MultipleSelect
                labelId="assist-dentists"
                selectId="assist-dentists"
                label={''}
                value={dentistAssistant}
                setValue={(newValue: any) => {
                  setDentistAssistant(newValue)
                  if (editTable) {
                    const obj = {
                      date: calendarDayObject.dateString,
                      name: dentistName,
                      assistant: newValue,
                      time: time,
                      worngDuty: false,
                      cancelDuty: false
                    }

                    handleData(obj)
                  }
                }}
                options={assistant.map((value: any, index: number) => {
                  return (
                    <Option value={value} key={index}>
                      {value.split(' ')[0]}
                    </Option>
                  )
                })}
                placeholder={t('USER_MEDICINE_ASSISTANT')}
              />
              <div className="input" style={{ marginTop: '6px', border: `1px solid ${colors.black12}`, background: colors.white, height: '32px' }}>
                <Input
                  className="input-cost-condition"
                  placeholder={t('CALENDAR.GUARANTEE_SHIFT_COST')}
                  value={daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission ? daysOfWeekObject[getWeekday(calendarDayObject.dateString)].text : message}
                  onChange={(e) => {
                    if (daysOfWeekObject[getWeekday(calendarDayObject.dateString)].permission) {
                      handleWeeksMessage(getWeekday(calendarDayObject.dateString), e.target.value, 'text')
                      setDayOfWeekMessage(e.target.value)
                      setMessage(e.target.value)
                    } else {
                      setMessage(e.target.value)
                      if (editTable) {
                        const obj = {
                          date: calendarDayObject.dateString,
                          name: dentistName,
                          assistant: dentistAssistant,
                          time: time,
                          costCondition: e.target.value,
                          worngDuty: false,
                          cancelDuty: false
                        }

                        handleData(obj)
                      }
                    }
                  }}
                />
              </div>{' '}
            </div>
          )) || (
          <div
            className="body-item"
            onClick={() => {
              setOnClickEdit(true)
              setMessage()
            }}
          ></div>
        )}
    </div>
  ) || (dentistName === t('CALENDAR.ALL') || dentistName === undefined || dentistName === '') && (
    <div className="container-item">
      <div className="day-grid-item-header">{calendarDayObject.dayOfMonth}</div>
      {item.map((value: any, index: number) => (
        <div className="dentist-container" key={index} style={{ display: index > 1 ? 'none' : '' }}>
          <div className="img-container"></div>
          <div className="text-container">
            <div className="dentist-name">{value.name}</div>
            {(value.worngDuty || value.cancelDuty) ? (
              <div className={`${value.worngDuty ? 'worng-duty' : 'cancel-duty'}`}>{value.worngDuty ? t('CALENDAR.LEAVE') : t('CALENDAR.OFF_DUTY')}</div>
            ) : (
              <div className="dentist-time">
                {`${value.time.timeStart} ${t('CALENDAR.HOURS')} -  
              ${value.time.timeEnd} ${t('CALENDAR.HOURS')}`}
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="more-time" style={{ display: item.length > 2 ? '' : 'none' }}>
        <span onMouseDown={() => setDropDown(!dropDown)} style={{ paddingLeft: '2vw' }}>
          {`${t('CALENDAR.AND_SHIFT_AGAIN')} ${item.length - 2} ${t('CALENDAR.PERSON')}`} <ArrowDropDownIcon style={{ width: '1.2vw', height: '1.2vw' }} />
        </span>
      </div>

      <div className="dropdown-all-dentist" style={{ display: dropDown ? 'block' : 'none' }}>
        {item.map((value: any, index: number) => (
          <div className="dentist-container" key={index} style={{ display: index < 2 ? 'none' : '' }}>
            <div className="img-container"></div>
            <div className="text-container">
              <div className="dentist-name">{value.name}</div>
              {value.worngDuty && (
                <div className="worng-duty">{t('CALENDAR.OFF_DUTY')}</div>
              ) || value.cancelDuty || (
                  <div className="cancel-duty">{t('CALENDAR.LEAVE')}</div>
                ) || (
                  <div className="dentist-time">
                    {`${value.time.timeStart} ${t('CALENDAR.HOURS')} -  
              ${value.time.timeEnd} ${t('CALENDAR.HOURS')}`}
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) || (dentistFilteredLabel !== undefined && dentistFilteredLabel.worngDuty) && (
    <div className="container-item-worng-duty">
      <div className="day-grid-item-header">{calendarDayObject.dayOfMonth}</div>
      <div className="dentist-container-worng-duty">
        {' '}
        <div>{t('CALENDAR.OFF_DUTY')}</div>
      </div>
    </div>
  ) || (dentistFilteredLabel !== undefined && dentistFilteredLabel.cancelDuty) && (
    <div className="container-item-cancel-duty">
      <div className="day-grid-item-header">{calendarDayObject.dayOfMonth}</div>
      <div className="dentist-container-cancel-duty">
        {' '}
        <div>{t('CALENDAR.LEAVE')}</div>
      </div>
    </div>
  ) || (
      <div className="container-item">
        <div className="day-grid-item-header">
          {calendarDayObject.dayOfMonth}
        </div>
        {item.map((value: any, index: number) => (
          <div
            className="dentist-container"
            key={index}
            onClick={(e) => {
              setModalItem(value)
              setModalDate(
                `${t('CALENDAR.DAY')}${dayOfWeekTH[Number(calendarDayObject.dateString.slice(5, 7))]}${t('CALENDAR.AT')} ${Number(calendarDayObject.dateString.slice(5, 7))} ${monthTH[Number(calendarDayObject.dateString.slice(8, 11))]} ${calendarDayObject.dateString.slice(0, 4)}`
              )
              setSmShow(true)
            }}
          >
            <div className="text-container-single">
              <div className="dentist-header-single">
                <div className="img-container"></div>

                <div className="dentist-name-single">{value.name}</div>
              </div>
              <div className="dentist-time-single">
                <span className="icon-time">
                  <Image src={iconTime} />
                </span>
                <span>{`${t('CALENDAR.TIME')}: ${value.time.timeStart} - ${value.time.timeEnd} ${t('CALENDAR.HOURS')}(10)`}</span>
              </div>
              <div className="dentist-assistant">
                <div className="title-assistant">
                  <span className="icon-assistant">
                    <Image src={iconAssistant} />
                  </span>
                  <span style={{ whiteSpace: 'nowrap' }}>{t('CALENDAR.ASSISTANT')}:</span>
                </div>
                <div className="name-assistant">{value.assistant?.map((a: string, indexAssistant: number) => (indexAssistant < 2 ? <span key={indexAssistant}>{a + ','}</span> : <span key={indexAssistant}>.</span>))}</div>
              </div>

              <div className="dentist-cost-condition">
                <span className="icon-bank">
                  <Image src={iconBank} />
                </span>
                <span>{`${t('CALENDAR.SHIFT_GUARANTEE')}: ${value.costCondition} ${t('CALENDAR.BATH')}`}</span>
              </div>
            </div>
          </div>
        ))}

        <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm" centered>
          <Modal.Header
            className="border-0 mx-auto my-0 px-auto pb-0"
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <span>{`${t('USER_DOCTOR')}${t('CALENDAR.CLOCK_IN')}`}</span>
            </Modal.Title>
            <CloseButton
              style={{ top: 20, right: 20 }}
              className="closeButton"
              onClick={() => {
                setSmShow(false)
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="date-model">{modalDate}</div>
            <div className="dentist-container-modal">
              <div className="img-container-modal"></div>
              <div className="text-container-modal">
                <div className="dentist-header-modal">
                  <div className="dentist-name-modal">{modalItem.name}</div>
                </div>
                <div className="dentist-time-modal">
                  <span className="icon-time">
                    <Image src={iconTime} />
                  </span>
                  <span>{`เวลา: ${modalItem?.time?.timeStart} - ${modalItem?.time?.timeEnd} ${t('CALENDAR.HOURS')}(10)`}</span>
                </div>
                <div className="dentist-assistant-modal">
                  <div className="title-assistant" style={{ width: '30%' }}>
                    <span className="icon-assistant">
                      <Image src={iconAssistant} />
                    </span>
                    <span>{t('CALENDAR.ASSISTANT')}:</span>
                  </div>
                  <div className="name-assistant-modal">
                    {modalItem.assistant?.map((a: string, index: number) => (
                      <div key={index}>{a + ','} </div>
                    ))}
                  </div>
                </div>
                <div className="dentist-cost-condition-modal">
                  <span className="icon-bank">
                    <Image src={iconBank} />
                  </span>
                  <span>{`${t('CALENDAR.SHIFT_GUARANTEE')}: ${modalItem.costCondition} ${t('CALENDAR.BATH')}`}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
}
