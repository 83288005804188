import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export default class MasterInventoryApi extends BaseAPI {
  static findAllProducts(props: FindAllInterface): Promise<any> {
    return this.api.get('master-inventories/products', { params: { ...props } }).then((res) => res)
  }

  static findAllProductsType(props: FindAllInterface): Promise<any> {
    return this.api.get('master-inventories/product-types', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('master-inventories', playload).then((res) => res)
  }
}
