import { Grid, Typography } from '@material-ui/core'
import { Card, Col, Row } from 'react-bootstrap'
import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router'
import { Config } from 'config'

import { useTranslation } from 'react-i18next'

/** API */
import { routeName } from 'routes/routes-name'
import LoginApi from 'api/login-api'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'

/** COMPONENT */
import ButtonSubmit from 'component/Button/ButtonSubmit'
import ButtonCustom from 'component/Button/ButtonCustom'
import { notiSuccess } from 'component/notifications/notifications'
import InputPasswordField from 'component/Input/InputPasswordField'
import { templateImage } from 'constants/theme'

/** STYLE */
import useStyles, { BgImageLeft, BgImageRight, CardResetPassword, MainDiv } from 'features/auth/style-reset-password'
import { Logo } from 'features/auth/style'

const initStateErrorMessage = {
  PASSWORD: '',
  INVALID_PASSWORD: ``,
  TEMP_MESSAGE: '',
  PASSWORD_CONFIRM: '',
  INVALID_PASSWORD_CONFIRM: ``
}

export default function ResetPassword() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { token }: any = useParams()

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [inputs, setInputs]: any = useState({})
  const [user, setUser]: any = useState(null)

  const loadUser = useCallback(async () => {
    const response = await LoginApi.findByToken(token)
    if (response.status === 200) setUser(response.data)
  }, [])

  useEffect(() => {
    loadUser()
  }, [token, loadUser])

  const handleChange = (event: any) => {
    const name = event.target.name
    const value = event.target.value
    setInputs((values: any) => ({ ...values, [name]: value }))
    clearErrorMessage()
  }
  const handleSubmit = (event: any) => {
    event.preventDefault()
    console.log(inputs.password.search(user));
    const tempMessage = t('FORGET_PASSWORD.MESSAGE.LENGTH_PASSWORD')
    if (!inputs.password) return setErrorMessage({ ...errorMessage, ...{ PASSWORD: t('FORGET_PASSWORD.MESSAGE.PLEASE_NEW_PASSWORD') } })
    /** ความยาวอย่างน้อย 8 หลัก */
    if (inputs.password.length < 8) return setErrorMessage({ ...errorMessage, ...{ TEMP_MESSAGE: tempMessage } })
    /** ประกอบด้วยตัวอักษมีอักษรตัวใหญ่และเล็กผสมกัน และ ตัวเลข */
    else if (inputs.password.toLowerCase() === inputs.password) return setErrorMessage({ ...errorMessage, ...{ TEMP_MESSAGE: tempMessage } })
    else if (inputs.password.toUpperCase() === inputs.password) return setErrorMessage({ ...errorMessage, ...{ TEMP_MESSAGE: tempMessage } })
    else if (!/\d/.test(inputs.password)) return setErrorMessage({ ...errorMessage, ...{ TEMP_MESSAGE: tempMessage } })
    else if (/[ก-๙]/.test(inputs.password)) return setErrorMessage({ ...errorMessage, ...{ TEMP_MESSAGE: tempMessage } })
    /**  e. ต้องไม่มีชื่อผู้ใช้งานเป็นส่วนหนึ่งส่วนใดของรหัสผ่าน */
    else if (user && inputs.password && inputs.password.search(user.username) !== -1) return setErrorMessage({ ...errorMessage, ...{ TEMP_MESSAGE: tempMessage } })

    if (!inputs.password_confrim) return setErrorMessage({ ...errorMessage, ...{ PASSWORD_CONFIRM: t('FORGET_PASSWORD.MESSAGE.CONFIRM_PASSWORD') } })
    if (inputs.password !== inputs.password_confrim) return setErrorMessage({ ...errorMessage, ...{ PASSWORD_CONFIRM: t('FORGET_PASSWORD.MESSAGE.PASSWORD_NOT_MATCH') } })

    PersonnelApi.resetPassword({ token: token, userId: user.userId, password: inputs.password })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) notiSuccess(t('FORGET_PASSWORD.MESSAGE.CHANGE_PASSWORD_AGAING'), 'go', routeName.login)
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: err.message } })
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: err.message } })
      })
      .finally(() => {
        return
      })
  }
  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const goLogin = () => {
    window.location.href = routeName.login
  }
  return (
    <MainDiv className={` main-reset-password`}>
      <div className={`w-100  position-absolute`} style={{ bottom: '-126px' }}>
        <BgImageLeft />
        <BgImageRight />
      </div>
      <div className={'w-100 h-100 flex-center'}>
        <Grid>
          <Grid item>
            <Grid xs={9} xl={3} md={4} sm={8} className={classes.marginCard}>
              <Row>
                {user && (
                  <>
                    <Col xs={12} className="text-center">
                      <Logo>
                        <div className='logo-wrap'>
                          <img src={Config.REACT_APP_LOGO || templateImage.logoHorizontal} alt="Logo" />
                        </div>
                      </Logo>
                    </Col>
                    <Col xs={12}>
                      <CardResetPassword className={`shadow `}>
                        <Card.Body>
                          <Card.Title>
                            <Typography className={''}>{t('FORGET_PASSWORD.MESSAGE.CHANGE_PASSWORD')}</Typography>
                          </Card.Title>
                          <Card.Body>
                            <form onSubmit={handleSubmit}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                  <InputPasswordField onchange={handleChange} name="password" label={t('FORGET_PASSWORD.MESSAGE.NEW_PASSWORD')} helperText={errorMessage.PASSWORD || errorMessage.INVALID_PASSWORD || errorMessage.TEMP_MESSAGE} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <InputPasswordField onchange={handleChange} name="password_confrim" label={t('FORGET_PASSWORD.MESSAGE.CONFIRM_NEW_PASSWORD')} helperText={errorMessage.PASSWORD_CONFIRM || errorMessage.INVALID_PASSWORD_CONFIRM} />
                                </Grid>
                                <Grid item xs={12} sm={12} className="pb-2 pt-2">
                                  <ButtonSubmit type="submit" textButton={t('FORGET_PASSWORD.MESSAGE.CHANGE_PASSWORD')} className={'w-100 h-100 m-0'} style={{ height: '48px', marginTop: '10px' }} />
                                </Grid>
                              </Grid>
                            </form>
                          </Card.Body>
                        </Card.Body>
                      </CardResetPassword>
                    </Col>
                    <Col xs={12}>
                      <div className={classes.licen}>Copyright © 2020. Cliniter | All rights reserved.</div>
                    </Col>
                  </>
                )}
                {!user && (
                  <>
                    <Col xs={12}>
                      <CardResetPassword className={`shadow `}>
                        <Card.Body>
                          <Card.Title>
                            <Typography className={''}>{t('FORGET_PASSWORD.MESSAGE.ALERT')}</Typography>
                          </Card.Title>
                          <Card.Body>
                            <ButtonCustom onClick={() => goLogin()} textButton={t('FORGET_PASSWORD.MESSAGE.GO_LOGIN_PAGE')} className="w-auto m-auto  " />
                          </Card.Body>
                        </Card.Body>
                      </CardResetPassword>
                    </Col>
                  </>
                )}
              </Row>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </MainDiv>
  )
}
