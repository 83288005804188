import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface ProductInstructionsInterface {
  group: string
  productInstructionName: string
  productInstructionNameEn: string
  status?: string
}

export interface FindAllProductInstructionsInterface extends FindAllInterface {
  group: string
}

export default class ProductInstructionsApi extends BaseAPI {
  static findAll(props: FindAllProductInstructionsInterface): Promise<any> {
    return this.api.get('product-instructions', { params: { ...props } }).then((res) => res)
  }
  static create(payload: any) {
    return this.api.post('product-instructions', payload)
  }
  static findById(id: number): Promise<ProductInstructionsInterface> {
    return this.api.get(`product-instructions/${id}`)
  }
  static update(id: number, body: ProductInstructionsInterface) {
    return this.api.patch(`product-instructions/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`product-instructions/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`product-instructions/${id}`)
  }
}
