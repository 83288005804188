import { Collapse, Typography, Chip, Box, Link } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'

export const THead = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: colors.themeMainColor,
  borderRadius: '8px 8px 0 0',
  padding: '0.5rem 1rem',
  fontSize: 16,
  color: colors.white
}))

export const TBody = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  border: `1px solid ${colors.lightGray}`,
  borderTopWidth: 0,
  color: colors.textPrimary,
  fontSize: 16,
  padding: '0.625rem 1rem 0.375rem',
  maxHeight: !open ? '45px' : 'unset',
  '.expand-more': {
    '.expand-more-icon': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.textLightGray}`,
      color: colors.themeMainColor,
      borderRadius: 100,
      fontSize: 22,
      transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: '-4px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    maxHeight: !open ? '72px' : 'unset',
    paddingBottom: '0.625rem'
  }
}))

export const Cell = styled('div')<{ type?: 'default' | 'branch' | 'date' | 'action' }>(({ theme, type }) => ({
  width: (type === 'branch' && '60px') || (type === 'date' && '130px') || (type === 'action' && '36px') || 'auto',
  flex: !type || type === 'default' ? 1 : 'none',
  paddingRight: '10px'
}))

export const Typo = styled(Typography)<{ type?: 'title' }>(({ theme, type }) => ({
  fontWeight: type === 'title' ? 600 : 400,
  width: type === 'title' ? '120px' : 'auto'
}))

export const a = styled(Link)<{ type?: 'title' }>(({ theme, type }) => ({
  display: 'inline',
  textDecoration: 'none',
  color: 'inherit',
  fontWeight: type === 'title' ? 600 : 400,
  width: type === 'title' ? '120px' : 'auto',
  '&:hover': {
    textDecoration: 'none',
    color: 'inherit',
  }
}))

export const TypoHis = styled(Typography)<{ type?: 'title' }>(({ theme, type }) => ({
  fontWeight: type === 'title' ? 600 : 400,
  width: type === 'title' ? '120px' : 'auto',
  paddingRight: 32
}))

export const CustomCollapse = styled(Collapse)(({ theme }) => ({
  '.collapse-wrap': {
    margin: '0.625rem -0.5rem 0',
    padding: '0.5rem 0.5rem 0.25rem',
    borderTop: `1px solid ${colors.lightGray}`
  }
}))

export const ChipDoctor = styled(Chip)(({ theme }) => ({
  backgroundColor: colors.themeSecondColor,
  height: 23,
  padding: '0 0.25rem',
  borderRadius: 8,
  marginRight: 4,
  verticalAlign: 'top',
  span: {
    fontSize: 14,
    color: colors.white,
    padding: 0
  }
}))

export const TagLab = styled(Box)(({ theme }) => ({
  backgroundColor: colors.themeThirdColor,
  color: colors.white,
  borderRadius: 4,
  width: 'max-content',
  height: 'max-content',
  fontSize: '0.75rem',
  lineHeight: 'normal',
  padding: '1px 4px'
}))

export const ChipRemark = styled(ChipDoctor)(({ theme }) => ({
  backgroundColor: colors.themeMainColor
}))

export const ChipProduct = styled(ChipDoctor)(({ theme }) => ({
  backgroundColor: colors.orange
}))

export const ChipVisit = styled(ChipDoctor)(({ theme }) => ({
  backgroundColor: colors.disabledGray
}))

export const ChipTx = styled(ChipDoctor)(({ theme }) => ({
  backgroundColor: colors.sky
}))

export const ChipDoctorRetrospectiveEdit = styled(ChipDoctor)(({ theme }) => ({
  backgroundColor: colors.sky
}))