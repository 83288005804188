import { Box } from "@mui/material"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { CardManageWeb } from "../useStyles"
import { fileUrl } from "api/api"
import DrageMove from "assets/icon/appointment/card/drag-move"
import { colors } from "constants/theme"
import BtnEdit from "assets/button/BtnEdit"
import BtnDelFilled from "assets/button/BtnDelFilled"
import { useState } from "react"
import { dateToView } from "utils/date.utils"
import { ArticleProps, ArticleSortProps } from "api/application/manageWeb-interface"
import ManageWebApi from "api/application/manageWeb"
import { useTranslation } from "react-i18next"
import { notiError, notiSuccess } from "component/notifications/notifications"
import { ManegeWebAction, ManegeWebStatus } from "../ManageWeb"
import { swalActive } from "component/Alert/Swal"


type ArticleListProps = {
    dataList: any
    onEdit: (status: boolean, item?: any) => void
    onDel: (id: number, name: string) => void
    statusDel: boolean
    reLoad: () => void
}

const ArticleList = (props: ArticleListProps) => {
    const { t } = useTranslation()
    const t_ARTICLE = "MANAGE_WEB.ARTICLE"

    const [isDragging, setIsDragging] = useState<boolean>(false)

    const onDragStart = (result: any) => {
        setIsDragging(true)
    }

    const onDragEnd = async (result: any) => {
        if (!result.destination) {
            setIsDragging(false)
            return
        }
        const { source, destination } = result;
        const data = props?.dataList
        const [removed] = data.splice(source.index, 1);
        data.splice(destination.index, 0, removed);

        const newData: ArticleSortProps[] = data.map((item: any, index: number) => {
            return { articleId: item.articleId, sorting: index + 1 }
        })
        await ManageWebApi.sortArticle(newData).finally(() => {
            setIsDragging(false)
            props.reLoad()
        })
    }

    const handleActive = async (status: 'ACTIVE' | 'INACTIVE', data: any) => {
        const id = data?.articleId || 0
        const newPayload: ArticleProps = { ...data, status: status }
        const onSaveStatus = async () => {
            if (id !== 0) {
                const res = await ManageWebApi.updateArticle(id, newPayload)
                if (res === "pass") {
                    props.reLoad()
                    notiSuccess(t(`${t_ARTICLE}.SAVE_STATUS`))
                } else {
                    notiError(t(`${t_ARTICLE}.SAVE_ERROR`))
                }
            } else {
                notiError(t(`${t_ARTICLE}.SAVE_ERROR`))
            }
        }
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 475px">${t(t_ARTICLE + '.NOTI_CHANGE_STATUS')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 450px">${t(`${t_ARTICLE}.ALERT.CONFIRM_STATUS_`, {
                subject: data.topic || '-',
                status: t(`STATUS.${status}`)
            })}</p>`,
            async (res: any) => {
                if (res) {
                    await onSaveStatus()
                }
            }, 'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )

    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} >
                <Box sx={{ paddingRight: '16px', paddingBottom: isDragging ? '200px' : '' }}>
                    <Droppable droppableId={`${'Review'}`}>
                        {(dropProvided, dropSnapshot) => (
                            <Box ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}>
                                {props.dataList && props.dataList.map((item: any, index: number) => {
                                    return (
                                        <Draggable
                                            key={index}
                                            draggableId={`${item.reviewId}-${index}`}
                                            index={index}
                                        >
                                            {(dragProvided, dragSnapshot) => {
                                                return (
                                                    <CardManageWeb
                                                        {...dragProvided.draggableProps}
                                                        ref={dragProvided.innerRef}
                                                    >
                                                        <Box className={'content-card'} sx={{ width: 'calc(100%)' }}>
                                                            <Box className={'container-img'} >
                                                                <img src={`${fileUrl}/${item?.filePath}/${item?.fileName}`} />
                                                            </Box>
                                                            <Box className={'container-des'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Box sx={{ width: 'calc(100% - 100px)' }}>
                                                                        <Box className={'text-ellipsis'} sx={{ fontSize: '18px', fontWeight: '500', minHeight: '27px' }}>
                                                                            {item?.topic}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                        <Box
                                                                            sx={{ cursor: 'pointer' }}
                                                                            {...dragProvided.dragHandleProps}
                                                                        >
                                                                            <DrageMove
                                                                                fill={dragSnapshot?.isDragging ? colors.themeSecondColor : undefined}
                                                                                width={'20px'}
                                                                                height={'20px'}
                                                                            />
                                                                        </Box>
                                                                        <Box sx={{ cursor: 'pointer', marginLeft: '8px' }}>
                                                                            <ManegeWebAction
                                                                                onActive={() => handleActive("ACTIVE", item)}
                                                                                onInActive={() => handleActive("INACTIVE", item)}
                                                                                disabledActive={item.status === "ACTIVE"}
                                                                                onEdit={() => props.onEdit(true, item)}
                                                                                onDelete={() => props.onDel(item.articleId, item.topic)}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ marginTop: '8px', paddingBottom: '8px', width: '100%', height: '100%' }}>
                                                                    <Box sx={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        textAlign: 'left',
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 3,
                                                                        WebkitBoxOrient: 'vertical'
                                                                    }} >
                                                                        {item?.subTopic}
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Box sx={{ width: 'fit-content', marginLeft: '12px' }}>
                                                                        <ManegeWebStatus status={item.status} />
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontSize: '14px', color: '#535962' }}>
                                                                        <Box className={'text-ellipsis'} sx={{ maxWidth: 'calc(100%)', width: 'fit-content', paddingRight: '32px' }}>
                                                                            {item?.updatedBy || item?.createdBy}
                                                                        </Box>
                                                                        <Box>
                                                                            {dateToView(item?.updatedAt) || dateToView(item?.createdAt)}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </CardManageWeb>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                            </Box>
                        )}
                    </Droppable>
                </Box>
            </DragDropContext>
        </>
    )
}

export default ArticleList