import { ColorThemeInterface, SidebarInterface, TemplateImageInterface, TemplatePickRoomInterface, SpecificIconInterface } from "constants/theme"
import { Icons, imgs, icons, favicon, pickBackground, loginBackground, logo, watermark } from "constants/images"
import { clinicLocaleTH, clinicLocaleEN } from "locales/dental/clinic.locale"

export const dentalOperativeType = [
    '#FFEBE5',
    '#FFE5E5',
    '#FFE5EE',
    '#FFDDF5',
    '#FFCDD2',
    '#F1D4DF',

    '#DCFAFF',
    '#B9EDF3',
    '#BAE9FD',
    '#D1EFFF',
    '#D6E9F8',
    '#D5E0FF',

    '#FFF7D5',
    '#FFF7B3',
    '#FFFAA5',
    '#FFF0B2',
    '#FFE6B9',
    '#FCDBBA',

    '#F8F1F1',
    '#EADEDE',
    '#EFF2F7',
    '#EFF2F7',
    '#E9E9E9',
    '#D9D5D9',

    '#FCEDF9',
    '#FCE7FF',
    '#EFEAF7',
    '#F2E8FF',
    '#E8D5FF',
    '#DDD4E6',

    '#B6F4E8',
    '#EBF7AC',
    '#F1F8E9',
    '#DAECC6',
    '#D3EBD4',
    '#DBE5E1',

    '#EAD9B6',
    '#EBD6CE',
    '#EACBB5',
    '#ECC7C0',
    '#D2C4BF',
    '#D8D6C6'
]

export const dentalColor: ColorThemeInterface = {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000000',

    themeMainColor: '#2F4269',
    themeSecondColor: '#1CB99A',
    themeSecondDarkColor: '#1AA58A',
    themeThirdColor: '#FB5252',

    statusActive: '#1CB99A',
    statusInactive: '#B00020',
    statusSuccess: '#68BB86',
    statusDangerColor: '#DC3545',
    statusWarning: '#F88F41',

    extraLightBlue: '#F1F9FF',
    borderHeaderCard: '#F3F1F1',
    bgBase: '#F9FAFC',
    highlightSelect: '#E7F8F5',
    lightYellow: '#FFF7D4',
    blue: '#007BFF',
    extraLightSky: '#c9dae1',
    sky: '#469ACC',
    secondSky: '#87adbd',
    orange: '#E67E22',

    extraLightGray: '#F5F5F5',
    lightGray: '#E5E5E5',
    gray: '#6C757D',
    darkGray: '#343A40',

    disabledLightGray: '#C5C5C5',
    disabledGray: '#9E9E9E',
    borderInput: '#D3D3D4',

    textExtraLightGray: '#929292',
    textLightGray: '#707070',
    textPrimary: '#29302E',

    navbar: 'linear-gradient(92deg, rgba(241, 245, 249, 0.77) 0%, rgba(30, 178, 154, 0.2) 76%, rgba(51, 102, 154, 0.2) 105%)',
    navbarLinkActive: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 1%, rgba(242, 242, 242, 0.8) 171%)',

    white18: 'rgba(255, 255, 255, 0.18)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white80: 'rgba(255, 255, 255, 0.8)',
    white90: 'rgba(255, 255, 255, 0.9)',
    black03: 'rgba(0, 0, 0, 0.03)',
    black05: 'rgba(0, 0, 0, 0.05)',
    black08: 'rgba(0, 0, 0, 0.08)',
    black10: 'rgba(0, 0, 0, 0.10)',
    black12: 'rgba(0, 0, 0, 0.12)',
    black16: 'rgba(0, 0, 0, 0.16)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black25: 'rgba(0, 0, 0, 0.25)',
    black30: 'rgba(0, 0, 0, 0.3)',
    black60: 'rgba(0, 0, 0, 0.6)',
    black75: 'rgba(0, 0, 0, 0.75)',

    themeMainColor06: 'rgba(47, 66, 105, 0.06)',
    themeMainColor09: 'rgba(47, 66, 105, 0.9)',
    themeSecondColor06: 'rgba(28, 185, 154, 0.06)',
    themeSecondColor10: 'rgba(28, 185, 154, 0.1)',
    themeSecondColor15: 'rgba(28, 185, 154, 0.15)',
    themeSecondColor30: 'rgba(28, 185, 154, 0.3)',
    themeSecondColor80: 'rgba(28, 185, 154, 0.8)',
    statusInactive10: 'rgba(176, 0, 32, 0.1)',

    lightThemeMain: 'rgba(220, 223, 230, 0.9)',
    backdrop: 'rgba(35, 31, 32, 0.3)',
    filterDropdown: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.53), rgba(242, 242, 242, 0.34))',

    // service type
    servicePoint: {
        panel: `linear-gradient(174deg, rgba(242, 242, 242, 0.5) -9%, rgba(233, 227, 227, 0.2) 98%)`,
        counter: 'linear-gradient(99deg, #FFCF1B -11%, rgba(255, 171, 27, 0.9) 44%, rgba(255, 136, 27, 0.9) 97%)',
        roomServices: 'linear-gradient(107deg, #F1F5F9 -50%, #1EB29A 44%, rgba(51, 102, 154, 0.5) 125%)',
        services: '#F5F5F5',
        payment: 'linear-gradient(105deg, #96C93D -10%, rgba(0, 176, 155, 0.8) 101%)',
        lab: `linear-gradient(107deg, #F1F5F9 -83%, #1EB29A 42%, rgba(51, 102, 154, 0.5) 125%)`,
        label: {
            counter: 'linear-gradient(170deg, #FFCF1B -5.61%, rgba(255, 171, 27, 0.898) 47.12%, rgba(255, 136, 27, 0.902) 98.6%)',
            roomServices: 'linear-gradient(150.94deg, #F1F5F9 -91.79%, #1EB29A 45.11%, rgba(51, 102, 154, 0.502) 163.63%)',
            payment: 'linear-gradient(160deg, #96C93D -14.09%, rgba(0, 176, 155, 0.8) 100.72%)',
        }
    },

    // member ranking
    memberRanking: {
        rankingBronze: '#EA4D2C',
        rankingSilver: '#C1C4C5',
        rankingGold: '#FFBE0D',
        rankingPlatinum: '#3A3985',
        rankingDiamond: '#5DBFEB',
        background: {
            rankingBronze: 'linear-gradient(to bottom, #FFA62E, #EA4D2C 127%)',
            rankingSilver: 'linear-gradient(to bottom, #C1C4C5, #7A7A7D 127%)',
            rankingGold: 'linear-gradient(179deg, #FFBE0D -2%, #FF9201 97%)',
            rankingPlatinum: 'linear-gradient(176deg, #3499FF 14%, #3A3985 109%)',
            rankingDiamond: 'linear-gradient(to bottom, #64E8DE -24%, #5DBFEB)',
        }
    },

    // status
    appointmentStatus: {
        statusNormal: '#9E9E9E',
        statusCancel: '#FB5252',
        statusUnConnect: '#FFCB1E',
        statusChange: '#33669A',
        statusEnter: '#1CB99A',
        statusArrived: '#7BB6C1',
        statusUnArrived: '#D46532',
        statusWaitPayment: '#FFAF36',
        statusPayment: '#C66B3F',
        statusService: '#9932CC',
        statusCounterCheckIn: '#7BB6C1',
        statusDone: '#BCBEC0',
        statusCancelVisit: '#FF002E'
    },

    doctorScheduleStatus: {
        leave: '#E2A70619',
        missing: '#FB52521A',
        solid: {
            leave: '#E2A706',
            missing: '#FB5252',
        }
    },

    // tag
    tag: {
        disease: '#D34848',
        medicineUse: '#469ACC',
        beAllergic: '#F88F41',
        df: '#0C98D6',
        lab: '#FF881B',
    },

    // expired 
    expired: {
        danger: '#e60000',
        danger2: '#c33012',
        warning: '#e29124',
        warningLinear: 'linear-gradient(180.53deg, #e29124 0.5%, #c33012 140.52%)'
    },

    tooth: {
        defaultLayer1: '#EFEDE7',
        defaultLayer2: '#F7F7F7',
        defaultLayer3: '#FFFFFF',
        layer1: 'rgba(51, 191, 163, 0.7)',
        layer2: 'rgba(38, 217, 181, 1)',
        layer3: 'rgba(74, 215, 187, 0.8)',
        lowerTeeth: '#E3D1B4'
    },

    approveStatus: {
        approve: '#1CB99A',
        pending: '#F5B700',
        cancel: '#FB5252'
    },

    paletteColors: ["#FF0013", "#FF008F", "#99004E", "#FFC126", "#FF7829", "#691506", "#11B03C", "#017420", "#26C9FF", "#0050CD", "#666666", "#000000"],
    paletteColorsDoctorNote: ["#D5C0A9", "#FBF2E2", "#EBDBC5", "#A28847", "#C5B29A", "#A28F84", "#615346", "#564D47", "#333333", "#686754", "#B9B295", "#7C8865", "#698478", "#779698", "#ACB9B2", "#4A5B54", "#738187", "#201E1F", "#58A6A1", "#9AC9C6", "#EECA87", "#E1B3A5", "#D97156", "#D7483C", "#B00020", "#2F4269"],

    // dashboard
    dashboard: {
        summaryBox: {
            sales: 'transparent linear-gradient(253deg, #FFCE00 0%, #F47243 100%) 0% 0% no-repeat padding-box',
            salesMarginUp: 'transparent linear-gradient(252deg, #7FB04C 0%, #008C75 100%) 0% 0% no-repeat',
            salesMarginDown: 'transparent linear-gradient(252deg, #EA5360 0%, #C94E82 100%) 0% 0% no-repeat padding-box',
            service: 'transparent linear-gradient(237deg, #FF3CAC 0%, #784BA0 100%) 0% 0% no-repeat padding-box',
            customer: 'transparent linear-gradient(237deg, #19AADE 0%, #498B72 100%) 0% 0% no-repeat padding-box'
        },
        saleProduct: {
            evenBg: ['#47BBE4', '#49DCDC'],
            oddBg: ['#4488B2', '#469A98']
        },
        saleOperative: ['#176BA0', '#98BF6F'],
        paymentMethod: ['#1BD4D4', '#EABD3B', '#EA3B9C', '#5A4798', '#4AABF6', '#10A19D'],
        totalExpenses: ['#1BD4D4', '#EABD3B', '#EA3B9C', '#5A4798', '#4AABF6'],
        operativeType: dentalOperativeType,
        hightSaleOperative: ['#1BD4D4', '#EABD3B', '#EA3B9C', '#5A4798', '#4AABF6']
    },

    appointmentDoctor: {
        colorEven: '#D0E2E9',
        colorOdd: '#D8F2EE'
    }
}

export const dentalSidebarIcon: SidebarInterface = {
    Counter: Icons.Counter,
    Doctor: Icons.Dentist,
    Finance: Icons.Finance,
    Lab: Icons.Lab,
    Xray: Icons.XRay,
    Management: Icons.Clinic,
    Application: Icons.Application,
    Warehouses: Icons.Inventory,
    Report: Icons.Report,
    Setting: Icons.Settings,
    Update: Icons.Updates,
    Custom: Icons.WarningCircle
}

export const dentalLocaleTH: any = clinicLocaleTH
export const dentalLocaleEN: any = clinicLocaleEN

export const dentalTemplateImage: TemplateImageInterface = {
    logo: logo.dentalLogo,
    logoHorizontal: logo.dentalLogoHorizontal,
    login: loginBackground.bgDentalLogin,
    welcome: imgs.dentalWelcome,
    pick: {
        left: pickBackground.bgDentalPickLeft,
        right: pickBackground.bgDentalPickRight
    }
}

export const DentalTemplatePickRoom: TemplatePickRoomInterface = {
    Branch: pickBackground.DentalBranch,
    Counter: pickBackground.CounterRoom,
    Service: pickBackground.DentalRoomServiceRoom,
    Payment: pickBackground.PaymentRoom,
    OtherService: pickBackground.DentalOtherServiceRoom,
}

export const DentalIcons: SpecificIconInterface = {
    Favicon: favicon.dentalFavicon,
    SendExam: Icons.DentalIconExam,
    Treatment: Icons.ApTeeth,
    Counter: Icons.CounterRoom,
    Service: Icons.DentalRoomServiceRoom,
    Payment: Icons.PaymentRoom,
    DoneTreatment: icons.iconDone
}

export const dentalWatermark = watermark.dental