import { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

import { useDispatch } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import { Grid, Divider } from '@mui/material'
import { Document, Page, pdfjs } from 'react-pdf'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import * as UseStyled from './style'
import { colors } from 'constants/theme'

/** API */
import { fileUrl } from 'api/api'
import TemplateFormsTypeApi from 'api/setting/doctor-note/template-form-type.api'

/** UTILS || SLICE */
import { resetModal, unSubmitModal } from 'app/slice/modal.slice'
import { icons } from 'constants/images'
import UseWindowSize from 'utils/useWindowSize'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputTextField from 'component/Input/InputTextField'

import HelperText from 'component/Input/HelperText'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import TemplateFormsApi, { IFTemplateFormsProps } from 'api/setting/doctor-note/template-forms.api'


type IFModal = {
  data: any
  resetPopup: () => void
  callback: () => void
  view?: boolean
}
type IFTemplateFormType = {
  templateFormTypeId: number
  typeName: string
  typeNameEn: string
}
type IFServictPoint = {
  servicePointId: number
  servicePointName: string
}

const initStateErrorMessage = {
  TEMPLATE_FORM: '',
  TEMPLATE_FORM_NAME: '',
  TEMPLATE_FORM_TYPE_ID: ''
}

export default function ModalTemplateForm(props: IFModal) {
  const { t } = useTranslation()
  const [height] = UseWindowSize()
  const dispatch = useDispatch()

  const [imgData, setImgData] = useState<string>()
  const [image, setImage] = useState<{
    fileId?: number | null
    base64: string
    fileExtension: string
    fileSize: string
  }>({
    fileId: null,
    base64: '',
    fileExtension: '',
    fileSize: ''
  })
  const [templateFormsId, setTemplateFormsId] = useState<number | null>(0)
  const [templateFormName, setTemplateFormName] = useState<string | null>()
  const [templateFormTypeId, setTemplateFormTypeId] = useState<number | null>()
  const [templateFormTypes, setTemplateFormTypes] = useState<IFTemplateFormType[]>([])
  const [templateFormStatus, setTemplateFormStatus] = useState('')
  const [statusFlag, setStatusFlag] = useState(false)

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const loadData = useCallback(async () => {
    const condition = { page: 1, pageLimit: 100, status: 'ACTIVE', export: 1 }
    const res = await TemplateFormsTypeApi.findAll(condition)
    if (res.status === 200) setTemplateFormTypes(res.data)
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    if (statusFlag) {
      TemplateFormsApi.updateStatus(Number(templateFormsId), templateFormStatus)
        .then((resp) => {
          setStatusFlag(!statusFlag)
        })
    }
    loadData()
  }, [templateFormStatus, statusFlag])
  useEffect(() => {
    if (props.data) {
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
      const { templateFormsId, name, templateFormTypeId, image, status }: IFTemplateFormsProps = props.data
      setTemplateFormsId(templateFormsId)
      setTemplateFormName(name)
      setTemplateFormTypeId(templateFormTypeId)
      setTemplateFormStatus(status)
      setImgData(`${fileUrl}/${image?.filePath}`)
      setImage({ fileId: image?.fileId, base64: '', fileExtension: 'pdf', fileSize: '' })
    }
  }, [props.data])

  const submit = () => {
    if (!templateFormName) return setErrorMessage({ ...errorMessage, ...{ TEMPLATE_FORM_NAME: t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORM_NAME') } })
    if (!templateFormTypeId) return setErrorMessage({ ...errorMessage, ...{ TEMPLATE_FORM_TYPE_ID: t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORM_TYPE_ID') } })
    const objCreate = { name: templateFormName, templateFormTypeId: templateFormTypeId, ...image }
    TemplateFormsApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('TEMPLATE_FORM.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_FORM.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        console.log(err)
        {
          err.message === "\"base64\" is not allowed to be empty" &&
            notiError(t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORMS_IS_FILE_EMPTY'), '', null, null)
        }
        {
          err.message === "IS_DUPLICATE_TEMPLATE_FORMS" &&
            notiError(t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORMS_IS_DUPLICATE'), '', null, null)
        }
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_FORM.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    setStatusFlag(!statusFlag)
    if (!templateFormName) return setErrorMessage({ ...errorMessage, ...{ TEMPLATE_FORM_NAME: t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORM_NAME') } })
    if (!templateFormTypeId) return setErrorMessage({ ...errorMessage, ...{ TEMPLATE_FORM_TYPE_ID: t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORM_TYPE_ID') } })
    const objCreate = { name: templateFormName, templateFormTypeId: templateFormTypeId, ...image, servicePointId: [] }
    if (objCreate.base64 === "" && !objCreate.fileId) return notiError(t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORMS_IS_FILE_EMPTY'), '', null, null)
    TemplateFormsApi.update(Number(templateFormsId), objCreate).then((res: any) => {
      if (res.status !== undefined && res.status === 200) {
        notiSuccess(t('TEMPLATE_FORM.MESSAGE.SUCCESS.UPDATE'), '', null, null)
        resetForm()
        loadData()
        dispatch(resetModal())
      } else {
        const err = res.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_FORM.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      }
    })
      .catch((e) => {
        const err = e.response.data
        {
          err.message === "\"base64\" is not allowed to be empty" &&
            notiError(t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORMS_IS_FILE_EMPTY'), '', null, null)
        }
        {
          err.message === "IS_DUPLICATE_TEMPLATE_FORMS" &&
            notiError(t('TEMPLATE_FORM.MESSAGE.TEMPLATE_FORMS_IS_DUPLICATE'), '', null, null)
        }
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_FORM.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }
  const clearImg = () => {
    { imgData === `${fileUrl}/${props?.data?.image?.filePath}` ? setImgData(`${fileUrl}/undefined`) : setImgData(`${fileUrl}/${props?.data?.image?.filePath}`) }
    // setImgData(`${fileUrl}/${props?.data?.image?.filePath}`)
    setImage({
      fileId: null,
      base64: '',
      fileExtension: '',
      fileSize: ''
    })
  }

  const resetForm = () => {
    setTemplateFormName('')
    setTemplateFormTypeId(0)
    setTemplateFormTypes([])
    loadData()
    setImgData('')
    setImage({
      fileId: null,
      base64: '',
      fileExtension: '',
      fileSize: ''
    })
    props.resetPopup()
    clearErrorMessage()
  }
  const onActive = async () => {
    { templateFormStatus === 'ACTIVE' ? setTemplateFormStatus('INACTIVE') : setTemplateFormStatus('ACTIVE') }
  }


  const onChangePicture = (e: any) => {
    if (e.target.files[0]) {
      /**
       * e.target.files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file = e.target.files[0]

      const fileName = file.name.split('.')
      const detailImage = {
        // fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        /** reader.result is base64 */
        const base64 = reader.result.split(',')
        setImgData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setImage(detailImage)
    }
  }

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }: any) {

    setNumPages(numPages)
  }
  return (
    <ModalCustom
      size={'xl'}
      title={templateFormsId ? props.view ? t('TEMPLATE_FORM.TITLE_VIEW') : t('TEMPLATE_FORM.TITLE_UPDATE') : t('TEMPLATE_FORM.TITLE_CREATE')}
      component={
        <UseStyled.Body>
          <Grid container spacing={2}>
            <Grid item md={4} lg={3} xs={6}>
              <InputTextField
                required={true}
                disabled={props.view ? true : false}
                key={`template-form-name`}
                name={`template-form-name`}
                label={t('TEMPLATE_FORM.INPUT.TEMPLATE_FORM_NAME')}
                value={templateFormName}
                onchange={(e: any) => {
                  setTemplateFormName(e.target.value)
                  clearErrorMessage()
                }}
                helperText={errorMessage.TEMPLATE_FORM_NAME}
              />
            </Grid>
            <Grid item md={4} lg={3} xs={6}>
              <AutocompleteSelect
                labelId="sl-image-doctor-note-type"
                required={true}
                disabled={props.view ? true : false}
                inputLabel={t('TEMPLATE_FORM.OPTION.TEMPLATE_FORM_TYPE')}
                options={templateFormTypes}
                getOptionLabel={(option: IFTemplateFormType) => option.typeName}
                onchange={(e, value: IFTemplateFormType) => {
                  setTemplateFormTypeId(value.templateFormTypeId)
                  clearErrorMessage()
                }}
                noOptionsText={t('NOT_FOUND')}
                filterOptions={(option: IFTemplateFormType) => option.typeName}
                value={_.find(templateFormTypes, (type: IFTemplateFormType) => String(type.templateFormTypeId) === String(templateFormTypeId)) || null}
                onOpen={() => setTemplateFormTypeId(0)}
                disableClearable
                errMessage={errorMessage.TEMPLATE_FORM_TYPE_ID}
              />
            </Grid>
            {templateFormsId && !props.view &&
              <Grid item md={8} lg={6} xs={12}>
                <div className={'mt-3'}>
                  <Form className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-xl-between w-100">
                    <Form.Label className="mr-5 mb-0 ml-xl-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      onChange={onActive}
                      checked={templateFormStatus === 'ACTIVE' ? true : false}
                      className="ml-xl-pl-auto"
                    />
                  </Form>
                  <Divider className="mx-0 mt-3 d-xl-none" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                </div>
              </Grid>
            }
            <Grid item md={12} lg={12} xs={12}>
              {errorMessage.TEMPLATE_FORM && <HelperText label={errorMessage.TEMPLATE_FORM} />}
              {imgData !== `${fileUrl}/undefined` &&
                <>
                  {imgData?.includes("data:application") ?
                    <div style={{ alignItems: 'center', display: 'flex', position: 'absolute', right: '40px', zIndex: '1', marginTop: '20px' }}>
                      {!props.view &&
                        <div onClick={clearImg} style={{ height: '50px', width: '50px', marginLeft: '30px' }}>
                          <img src={icons.btnTrash} alt="delete" style={{ height: '100%' }} />
                        </div>
                      }
                    </div>
                    :
                    <div style={{ alignItems: 'center', display: 'flex', position: 'absolute', right: '40px', zIndex: '1', marginTop: '20px' }}>
                      <a href={imgData} target={'_blank'} rel="noopener noreferrer" style={{ height: '100%', width: '50px', background: '#F0F0F0', borderRadius: '35px' }}>
                        <img src={icons.previewImg} alt="previewImg" style={{ width: '50%', border: 'unset', margin: '10px 5px 10px 13px' }} />
                      </a>
                      {!props.view &&
                        <div onClick={clearImg} style={{ height: '30px', width: '30px', marginLeft: '20px' }}>
                          <img src={icons.btnTrash} alt="delete" style={{ height: '100%' }} />
                        </div>
                      }
                    </div>}
                </>
              }
              <section style={{ height: height * (1 / 2), width: '100%', backgroundColor: '#D9D9D9' }}>
                <UseStyled.CardImg>
                  {imgData && (
                    <div>
                      <Document
                        file={imgData}
                        onLoadSuccess={onDocumentLoadSuccess}
                        error={
                          <UseStyled.DivUpload
                            style={{ background: '#FFFFFF' }}
                            onClick={() => {
                              $('#profilePic').trigger("click")
                            }}
                            className={'d-flex align-items-center justify-content-center'}
                          >
                            <div className="d-none">
                              <input id="profilePic" type="file" accept="application/pdf" onChange={onChangePicture} />
                            </div>
                            <div className={'upload-image text-center '}>
                              <img src={icons.addFrom} alt="uploadImage" style={{ border: 'unset', height: '100px', width: '100px' }} />
                              <p className={'pt-1 pt-sm-2 m-0'}>{t('TEMPLATE_FORM.INPUT.TEMPLATE_FORM_UPLOAD')}</p>
                            </div>
                          </UseStyled.DivUpload>
                        }
                      >
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                          <Page height={height * (1 / 2)} pageNumber={pageNumber} error={errorMessage.TEMPLATE_FORM} className={'mr-0'}></Page>
                        </div>
                      </Document>
                    </div>
                  )}

                  {/* </Box> */}
                </UseStyled.CardImg>

                {t('TEMPLATE_FORM.ALERT.SIZE')}
              </section>
            </Grid>
          </Grid>
        </UseStyled.Body>
      }
      onReset={resetForm}
      onSubmit={templateFormsId ? submitEdit : submit}
      textBtnCancel={props.view ? t('TEMPLATE_FORM.BUTTON.CLOSE') : t('TEMPLATE_FORM.BUTTON.CANCEL')}
      textBtnConfirm={props.view ? '' : t('TEMPLATE_FORM.BUTTON.SAVE')}
    />
  )
}
