import { useState, useEffect } from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import { styled } from '@mui/material/styles'
import UseWindowSize from 'utils/useWindowSize'
import _ from 'lodash'
import { colors } from 'constants/theme'

/** COMPONENT */
import { AppointmentCardItemDayInterface, AppointmentDisplayDayInterface, AppointmentDisplayWeekInterface } from 'features/counter/appointment/appointment-interface'
import { getColorStatusAppointment, renderIconStatusAppointment } from 'component/Appointment/CardAppointment'
import CardAppointmentDetail from 'component/Appointment/CardAppointmentDetail'
import CalendarDay, { CalendarItem } from 'component/Calendar/CalendarMonth/CalendarMonth'
import { CustomCalenderMonth } from 'features/counter/appointment/CalendarStyle'

export interface IDisplayMonthProps {
  branchId: number
  branch?: string
  year: number
  month: number
  listAppointment: AppointmentDisplayWeekInterface[]
  onEdit?: (id: number) => void
  onEditSpecialInfo?: (id: number) => void
  onLoading?: (event: boolean) => void
}

export default function DisplayMonth(props: IDisplayMonthProps) {
  const [bodyHeight, setBodyHeight] = useState(Array.from(document.getElementsByClassName('container-content') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0);
  const headHeight = Array.from(document.getElementsByClassName('head-appointment') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0
  const [heightScreen] = UseWindowSize()
  const [viewDetails, setViewDetails] = useState([])

  useEffect(() => {
    setBodyHeight(Array.from(document.getElementsByClassName('container-content') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0)
  }, [heightScreen]);

  const handleViewDetail = (val: any) => {
    const temp: any = []
    if (!temp.find((t: any) => t === val)) temp.push(val)
    setViewDetails(temp)
  }
  const handleCloseDetail = (val: any) => {
    const temp: any = [...viewDetails].filter((t: any) => t !== val)
    setViewDetails(temp)
  }

  const DivIcon = styled('div')(({ theme }) => ({
    '& img': {
      width: '17px',
      height: '17px',
      verticalAlign: 'top',
      marginTop: '0.25rem'
    }
  }))

  const DivCustomDetail = styled('div')(({ theme }) => ({
    '& .card': {
      border: `1px solid ${colors.lightGray}`,
      '&:hover': {
        boxShadow: `0px 3px 6px ${colors.black08}`
      },
      '& .div-section-icon': {
        width: '30px',
        [theme.breakpoints.down('lg')]: {
          width: '10px'
        }
      },
      '& .div-icon': {
        width: '17px',
        height: '17px',
        [theme.breakpoints.down('lg')]: {
          display: 'none'
        }
      }
    }
  }))

  const renderDate = (doctors: AppointmentDisplayDayInterface[]) => {
    if (doctors.length) {
      const arrayAppointments: AppointmentCardItemDayInterface[] = []
      for (const key in doctors) {
        if (Object.prototype.hasOwnProperty.call(doctors, key)) {
          const doctor: AppointmentDisplayDayInterface = doctors[key]
          if (doctor.appointments?.length)
            for (const iAp in doctor.appointments) {
              if (Object.prototype.hasOwnProperty.call(doctor.appointments, iAp)) {
                const ap: AppointmentCardItemDayInterface = doctor.appointments[iAp]
                arrayAppointments.push(ap)
              }
            }
        }
      }

      return (
        <div className="custom-scroll mt-1" style={{ overflow: 'auto', overflowX: 'hidden', height: '120px' }}>
          {_.sortBy(arrayAppointments, 'start')?.map((ap: AppointmentCardItemDayInterface, j: number) => (
            <DivCustomDetail key={j} className="-position-relative px-2">
              <Card style={{ cursor: 'pointer', marginBottom: '2px', backgroundColor: ap.color }} onClick={() => handleViewDetail(ap.appointmentId)}>
                <div className="d-flex">
                  <div className=" d-inline-block " style={{ backgroundColor: getColorStatusAppointment(ap.statusAppointment), borderRadius: '0.25rem 0 0 0.25rem' }}>
                    <div className="d-flex justify-content-center div-section-icon" >
                      <DivIcon className="text-center div-icon" >
                        {renderIconStatusAppointment(ap.statusAppointment, true)}
                      </DivIcon>
                    </div>
                  </div>
                  <div title={`${ap.rangeTimeText} | ${ap.patientFullname} | ${ap.serviceText}`} className="d-inline-block text-ellipsis pl-1" style={{ fontWeight: 400 }}>
                    {ap.rangeTimeText} | {ap.patientFullname} | {ap.serviceText}
                  </div>
                </div>
              </Card>
              <CardAppointmentDetail
                key={j}
                fixPosition={{ top: `36px`, left: '0px' }}
                appointmentId={ap.appointmentId}
                showPopup={viewDetails.find((v) => v === ap.appointmentId) ? true : false}
                onClose={handleCloseDetail}
                onEdit={props?.onEdit ? props?.onEdit : undefined}
                onEditSpecialInfo={props.onEditSpecialInfo ? props.onEditSpecialInfo : undefined}
                onLoading={(event: boolean) => props?.onLoading ? props.onLoading(event) : undefined}
                status={ap.statusAppointment}
                branch={props.branch}
              />
            </DivCustomDetail>
          ))}
        </div>
      )
    }
  }

  return (
    <div className="position-relative">
      <Row className="mx-0">
        <Col className="d-none d-sm-block px-0 col-auto"></Col>
        <Col className="px-0 overflow-auto mt-3 custom-scroll" style={{ maxHeight: `calc(${bodyHeight - headHeight}px - 34px)` }}>
          <CustomCalenderMonth className='mx-3'>
            <CalendarDay
              yearAndMonth={[props.year, props.month]}
              renderDay={(calendarDayObject: any) => {
                const laInDay: any = props.listAppointment.find((la: AppointmentDisplayWeekInterface) => la.date === calendarDayObject.dateString)
                if (laInDay?.doctors.length) {
                  laInDay.date = calendarDayObject.dateString
                }
                return (
                  <CalendarItem
                    calendarDayObject={calendarDayObject}
                    allDentists={laInDay?.doctors || []}
                    items={renderDate(laInDay?.doctors || [])}
                  />
                )
              }}
            />
          </CustomCalenderMonth>
        </Col>
      </Row>
    </div>
  )
}
