import { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, MenuItem, Typography, Grid, Tooltip } from '@mui/material'
import { Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** CONSTANTS */
import { colors, sidebarIcon } from 'constants/theme'
import { checkPackage, getBranch, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { PACKAGE_ADDONS } from 'constants/package'
import * as modalSlice from 'app/slice/modal.slice'
import useWindowSize from 'utils/useWindowSize'
import { showModal } from 'app/slice/modal.slice'

/** COMPONENT */
import InputTextSearch from 'component/Input/InputTextSearch'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import ButtonCustom from 'component/Button/ButtonCustom'
import MedicalCertificate from 'component/Treatments/MedicalCertificate'
import Loading from 'component/Loading'

/** COMPONENT POPUP */
import PopupFollowTreatment from 'component/HistoryTreatments/PopupFollowTreatment'
import PopupTreatmentConsent from 'component/Treatments/popupTreatmentConsent'
import { OrderTreatmentsType, OrderProductsType } from 'component/Register/TreatmentHistory'
import ReferralExamination from 'component/Treatments/Referral-examination'

/** API */
import { clinicTheme } from 'api/api'
import HistoryTreatmentApi, { AllTreatmentHistory } from 'api/historyTreatment/historyTreatmentApi'
import { fileUrl } from 'api/api'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import PaymentApi from 'api/counter/payment/payment.api'
import { exportOPD, exportSSO } from 'component/Print/exportPdf'

/** INTERFACE */
import { PrintReceiptInterface } from 'api/counter/payment/payment-interface'

/** STYLED */
import * as UseStyled from 'features/historyTreatments/MainMenu/OPD/UseStyle'
import * as UseStyleRetrospectiveEdit from 'features/historyTreatments/MainMenu/OPD/UseStyleRetrospectiveEdit'
import { ChipDoctor, ChipRemark, ChipProduct, ChipVisit, ChipTx, ChipDoctorRetrospectiveEdit } from 'component/Register/style/styleTreatmentHistory'
import SSO from 'component/Treatments/SSO'
import MedicalCertificateFiveDisease from 'component/Treatments/PopupMedicalCertificateFiveDisease'
import MedicalCertificateDrivingLicense from 'component/Treatments/MedicalCertificateDrivingLicense'
import MedicalCertificate_A5 from 'component/Treatments/MedicalCertificate_A5'
import { ProductStickerType, setDiagnosisByKey } from 'app/slice/treatment.slice'
import { branchInfo, editBranchInfo, selectMe } from 'app/slice/user.slice'
import AboutUs from 'api/admin/clinic-manage/about.api'
import { KEY_STORAGE } from 'constants/common'
import OverdueApi from 'api/finances/overdue.api'
import HistoryPayment from 'features/finance/payment/component/history-payment'
import ReferralDental from 'component/Treatments/Referral-dental'
import TreatmentProductApi from 'api/dentists/treatmentProduct.api'
import { ProductStickerSelectType } from 'component/Treatments/PopupSelectPrintProduct'
import FunctionPrint from 'component/Print/print'
import PdfMedSticker from 'features/pdf/pdf-med-sticker'
import PopupTreatmentFees from 'component/Treatments/PopupTreatmentFees'
import TreatmentsAPI, { SignatureAttachmentsProps } from 'api/dentists/treatment.api'

type ConfigModal = {
  type?: '' | 'FollowTreatment' | 'MedicalCertificate' | 'treatmentConsent' | 'MedicalServices' | 'MedicalDrivingLicense' | 'MedicalFiveDisease' | 'MedicalCertificate_A5' | 'ReferralDental' | 'treatmentFees'
  patientId?: number
  patientCaseId?: number
  vnNumber?: string
  createdAt?: string
  treatments?: any
  diagnosis?: any
  servicePointId?: number
  patientRecommendId?: any
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

type HistoryType = {
  patient: any
  disabled?: boolean
  type?: any
  setGotoPage?: (page: 'Lab' | 'Xray') => void
}

type ColumnTreatmentType = {
  treatments: OrderTreatmentsType[] & OrderProductsType[]
  type?: any
}

type ColumnDetailType = {
  hasLab?: boolean
  hasXray?: boolean
  onLab?: () => void
  onXray?: () => void
  onEmr?: () => void
}

type ColumnActionProps = {
  id?: string
  patientInvoiceId?: number | null
  type?: any
  onFollow?: () => void
  onDocumentReceipt?: (type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL', isShow?: 'DF', isFixDF?: boolean) => void
  onMedicalCertificate?: () => void
  onSSO?: () => void
  onMedicalCertificate_A5?: () => void
  onMedicalFiveDisease?: () => void
  onMedicalDrivingLicense?: () => void
  onTreatmentConsent?: () => void
  onTreatmentFees?: () => void
  onEmrEdit?: () => void
  onStickerMed?: () => void
  checkMed?: boolean
  invoice?: boolean
}

export default function History(props: HistoryType) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const branchIdServicePoint = getBranch()
  const [width] = useWindowSize()
  const [configModal, setConfigModal] = useState<ConfigModal>({})
  const modal: boolean = useSelector(modalSlice.handleShow)
  const [loading, setLoading] = useState(false)
  const [modalData, setModalData] = useState<{ isShow: boolean; data: any }>({ isShow: false, data: {} })
  const branchesInfo = useSelector(branchInfo)
  const user = useSelector(selectMe)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('date')
  const [sortType, setSortType] = useState('ASC')

  /** FILTER */
  const [search, setSearch] = useState('')
  const [doctorId, setDoctorId] = useState<number>(0)
  const [rangeDate, setRangeDate] = useState<string[]>(emptyRangePicker)
  const [branchId, setBranchId] = useState(0)

  /** DATA */
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [branches, setBranches] = useState<BranchInterface[]>([])
  const [historyTreatments, setHistoryTreatments] = useState([])
  const exportMedicalCertificate = false
  const [exportReferralExamination, setExportReferralExamination] = useState(false)
  const [exportDentalReferral, setExportDentalReferral] = useState(false)

  const [productsSelect, setProductsSelect] = useState<ProductStickerSelectType[]>([]);
  const stickerMedRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const [statusInvoice, setStatusInvoice] = useState(false)

  const loadAllHistoryTreatment = useCallback(() => {

    if (props.patient.patientId) {
      let condition: AllTreatmentHistory = {
        patientId: props.patient.patientId,
        page: page,
        pageLimit: pageLimit,
      }
      if (search) condition = { ...condition, search: search }
      if (!_.isEmpty(rangeDate) && rangeDate !== null && rangeDate !== emptyRangePicker) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
      if (doctorId) condition = { ...condition, doctorId: doctorId }
      if (branchId) condition = { ...condition, branchId: branchId }

      HistoryTreatmentApi.findAllTreatmentHistory(props.patient.patientId, condition).then((resp) => {
        if (resp.status === 200) {
          setHistoryTreatments(resp.data)
          setRowCount(resp.headers['x-total'])
        }
      })



    } else clearData()
  }, [props.patient.patientId, page, pageLimit, search, doctorId, rangeDate, branchId])


  const loadDoctor = () => {
    ClinicApi.findAllDoctors().then((resp) => {
      if (resp.status === 200) setDoctors(resp.data)
    })
  }

  const loadBranches = () => {
    ClinicApi.findAllBranches().then((resp) => {
      if (resp.status === 200) setBranches(resp.data)
    })
  }

  const clearData = () => {
    setSearch('')
    setBranchId(0)
    setDoctorId(0)
    setRangeDate(emptyRangePicker)
    setHistoryTreatments([])
    setPage(1)
    setPageLimit(10)
    setRowCount(0)
  }

  const onPaidHistory = async (orderId: number, valueOrderPaymentId: number) => {
    const resp = await OverdueApi.findDetailPayment(orderId, valueOrderPaymentId)
    if (resp.status === 200) {
      setModalData({ isShow: true, data: resp?.data?.history || {} })
      dispatch(showModal())
    }
  }

  const onLab = () => {
    props.setGotoPage && props.setGotoPage('Lab')
  }

  const onXray = () => {
    props.setGotoPage && props.setGotoPage('Xray')
  }

  const onEmr = (patientCaseId: number) => {
    window.open(`${routeName.treatmentsEmrHistory}?patientId=${props.patient.patientId}&patientCaseId=${patientCaseId}`, '_blank')
  }

  const onEmrEdit = (patientCaseId: number) => {
    window.open(`${routeName.treatmentsEmrHistory}?patientId=${props.patient.patientId}&patientCaseId=${patientCaseId}&type=RetrospectiveEdit`, '_blank')
  }

  const onFollowTreatment = (patientId: number, patientCaseId: number, vnNumber: string) => {
    setConfigModal({ type: 'FollowTreatment', patientId: patientId, patientCaseId: patientCaseId, vnNumber: vnNumber })
    dispatch(modalSlice.showModal())
  }

  const onMedicalCertificate = (patientId: number, patientCaseId: number, createdAt: string, treatments: any, diagnosis: any, patientRecommendId: any) => {
    console.log("222 patientRecommends", patientRecommendId)
    setConfigModal({ type: 'MedicalCertificate', patientId: patientId, patientCaseId: patientCaseId, createdAt: createdAt, treatments: treatments, diagnosis: diagnosis, patientRecommendId: patientRecommendId })
    dispatch(modalSlice.showModal())
  }

  const onSSO = (patientId: number, patientCaseId: number, createdAt: string, treatments: any, diagnosis: any) => {
    setConfigModal({ type: 'MedicalServices', patientId: patientId, patientCaseId: patientCaseId, createdAt: createdAt, treatments: treatments, diagnosis: diagnosis })
    dispatch(modalSlice.showModal())
  }

  const onMedicalCertificate_A5 = (patientId: number, patientDiagnosis: any) => {
    dispatch(setDiagnosisByKey({ key: 'patientDiagnosis', value: patientDiagnosis }))
    setConfigModal({ type: 'MedicalCertificate_A5', patientId: patientId })
    dispatch(modalSlice.showModal())
  }

  const onMedicalFiveDisease = (patientId: number, patientCaseId: number, createdAt: string, treatments: any, diagnosis: any) => {
    setConfigModal({ type: 'MedicalFiveDisease', patientId: patientId, patientCaseId: patientCaseId, createdAt: createdAt, treatments: treatments, diagnosis: diagnosis })
    dispatch(modalSlice.showModal())
  }
  const onMedicalDrivingLicense = (patientId: number, patientCaseId: number, createdAt: string, treatments: any, diagnosis: any) => {
    setConfigModal({ type: 'MedicalDrivingLicense', patientId: patientId, patientCaseId: patientCaseId, createdAt: createdAt, treatments: treatments, diagnosis: diagnosis })
    dispatch(modalSlice.showModal())
  }


  const loadStickerProduct = async (patientCaseId: number) => {
    if (patientCaseId) {
      setLoading(true)
      await TreatmentProductApi.findAllSticker(patientCaseId)
        .then(({ data }) => {
          let newData: ProductStickerSelectType[] = []
          _.forEach(data, (p: ProductStickerType) => {
            if (p.sticker) newData = [...newData, { ...p, stickerObj: JSON.parse(p.sticker) }]
          })
          setProductsSelect(newData)
        })
        .catch(() => { return })
      setTimeout(() => {
        buttonRef.current?.click()
        setLoading(false)
      }, 0)
    }
  }


  const onTreatmentConsent = (patientCaseId: number, servicePointId: number) => {
    setConfigModal({ type: 'treatmentConsent', patientCaseId: patientCaseId, servicePointId: servicePointId })
    dispatch(modalSlice.showModal())
  }

  const onTreatmentFees = (patientCaseId: number, servicePointId: number) => {

    setConfigModal({ type: 'treatmentFees', patientCaseId: patientCaseId, servicePointId: servicePointId })
    dispatch(modalSlice.showModal())
  }

  const onDocument = async (type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL', paymentId: number, isShow?: 'DF', isFixDF?: boolean) => {
    const printProp: PrintReceiptInterface = { lang: 'TH', type: 'FULL' }
    let receipt: any = ''
    setLoading(true)
    if (type === 'THERMAL') {
      printProp.hasServicePrice = isShow === 'DF' ? "1" : '0'
      receipt = await PaymentApi.printThermal(paymentId, printProp)
    } else if (type === 'A5' || type === 'A5_ORI' || type === 'A5_COPY' || type === 'A5_EN' || type === 'A5_EN_ORI' || type === 'A5_EN_COPY') {
      if (type === 'A5_ORI' || type === 'A5_EN_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A5_COPY' || type === 'A5_EN_COPY') printProp.type = 'COPY'

      if (type === 'A5_EN' || type === 'A5_EN_ORI' || type === 'A5_EN_COPY') printProp.lang = 'EN'
      printProp.fixDF = isFixDF ? '1' : '0'

      receipt = await PaymentApi.printA5(paymentId, printProp)
    } else if (type === 'A5_LANDSCAPE' || type === 'A5_LANDSCAPE_ORI' || type === 'A5_LANDSCAPE_COPY') {
      printProp.hasServicePrice = isShow === 'DF' ? "1" : '0'
      printProp.orientation = 'LANDSCAPE'
      printProp.fixDF = isFixDF ? '1' : '0'
      if (type === 'A5_LANDSCAPE_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A5_LANDSCAPE_COPY') printProp.type = 'COPY'
      receipt = await PaymentApi.printA5(paymentId, printProp)
    } else if (type === 'A4' || type === 'A4_ORI' || type === 'A4_COPY') {
      if (type === 'A4_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A4_COPY') printProp.type = 'COPY'
      receipt = await PaymentApi.printA4(paymentId, printProp)
    }
    setLoading(false)
    if (receipt && receipt.status === 200) return window.open(`${fileUrl}/${receipt.data}`, '_blank')
    return false
  }

  const onExportReferralExamination = async () => {
    setExportReferralExamination(true)
    dispatch(modalSlice.showModal())
  }

  const onExportDentalReferral = async () => {
    setExportDentalReferral(true)
    dispatch(modalSlice.showModal())
  }

  const exportOpd = async (patientId: number) => {
    setLoading(true)
    exportOPD(patientId)
      .catch((e) => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadAllHistoryTreatment()
  }, [loadAllHistoryTreatment])

  useEffect(() => {
    if (!modal) setConfigModal({ type: '' })
  }, [modal])

  const loadPermissExport = useCallback(async () => {
    const branchId = getBranch()
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        dispatch(editBranchInfo({ value: { data } }))
        localStorage.setItem(KEY_STORAGE.CLINIC_INFO, JSON.stringify({ ...data, lastUpdate: moment().format('YYYY-MM-DD HH:mm') }))
      })
      .catch((e) => { return })
  }, [])

  useEffect(() => {
    loadDoctor()
    loadBranches()
    loadPermissExport()
  }, [])

  const headCells = [
    { id: 'branch', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.BRANCH'), align: 'center' },
    { id: 'vn', disablePadding: false, label: t('VN') },
    { id: 'date', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.DATE') },
    { id: 'treatment-service', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.TREATMENT_SERVICE') },
    { id: 'price', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.PRICE') },
    { id: 'payment', disablePadding: false, align: 'center', label: t('HISTORY_TREATMENT.TABLE.PAYMENT') },
    { id: 'detail', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.DETAIL') },
    { id: 'action-treatment', disablePadding: false, label: t('Action') }
  ]
  const headCellsRetrospectiveEdit = [
    { id: 'vn', disablePadding: false, label: t('VN') },
    { id: 'date', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.DATE') },
    { id: 'treatment-service', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.TREATMENT_SERVICE') },
    { id: 'price', align: 'right', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.PRICE'), width: '30%' },
    { id: 'payment', align: 'center', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.PAYMENT') },
    // { id: 'detail', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.DETAIL') },
    { id: 'edit-by', align: 'center', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.EDIT_BY') },
    { id: 'action-treatment', disablePadding: false, label: t('Action') }
  ]

  const renderData = (objData: any, no: number) => {
    const { branch, patientId, patientCaseId, invoice, vnNumber, createdAt, order, patientInvoiceId, countLabOrder, countXray, patientDiagnosis, updatedBy, updatedAt, patientRecommends } = objData
    const checkItemMed = order?.orderProducts?.length ? order?.orderProducts.some((item: any) => item.group === 'MEDICINE') : false

    const objRenderData = {
      key: String(patientCaseId),
      id: String(patientCaseId),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: branch?.branchCode || '-' },
        { option: 'TEXT', align: 'left', label: vnNumber },
        { option: 'TEXT', align: 'left', label: toBuddhistYear(moment(createdAt), 'DD/MM/YYYY') },
        {
          option: 'COMPONENT',
          align: 'left',
          component: <ColumnTreatment type={props.type === 'RetrospectiveEdit' ? 'RetrospectiveEdit' : ''} treatments={[...order.orderTreatments, ...order.orderProducts]} />
        },
        // { option: 'TEXT', align: 'right', label: numberFormat(order?.totalPrice || 0) },
        {
          option: 'TEXT', align: 'right', label:
            <UseStyled.ButtonOperative
              variant="text"
              disableFocusRipple
              disableElevation
              disableRipple
              disableTouchRipple
              onClick={() => {
                onPaidHistory(order?.orderId, order?.payments?.orderPaymentId)
              }}
            >
              <span className="text-ellipsis" style={{ textDecoration: 'underline', marginTop: '-10px', marginRight: '-20px' }}>
                {numberFormat(order?.totalPrice || 0)}
              </span>
            </UseStyled.ButtonOperative>
        },
        { option: 'TEXT', align: 'center', label: order?.payments?.paymentChannelName || order?.payments?.paymentChannelNameEn || '-' },
        {
          option: 'COMPONENT',
          align: 'left',
          component: <ColumnDetail hasLab={countLabOrder} hasXray={countXray} onLab={onLab} onXray={onXray} onEmr={() => onEmr(patientCaseId)} />
        },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: order.updatedBy, updatedAt: updatedAt }, class: 'text-nowrap-ellipsis' },
        {
          option: 'COMPONENT',
          align: 'left',
          component: (
            props.type === 'RetrospectiveEdit' ?
              <ColumnAction
                type='RetrospectiveEdit'
                id={patientCaseId}
                patientInvoiceId={patientInvoiceId}
                onEmrEdit={() => onEmrEdit(patientCaseId)}
              />
              :
              <ColumnAction
                id={patientCaseId}
                patientInvoiceId={patientInvoiceId}
                onFollow={() => onFollowTreatment(patientId, patientCaseId, vnNumber)}
                onMedicalCertificate={() => onMedicalCertificate(patientId, patientCaseId, createdAt, order?.orderTreatments, patientDiagnosis, patientRecommends)}
                onSSO={() => onSSO(patientId, patientCaseId, createdAt, order?.orderTreatments, patientDiagnosis)}
                onMedicalDrivingLicense={() => onMedicalDrivingLicense(patientId, patientCaseId, createdAt, order?.orderTreatments, patientDiagnosis)}
                onMedicalCertificate_A5={() => onMedicalCertificate_A5(patientId, patientDiagnosis)}
                onMedicalFiveDisease={() => onMedicalFiveDisease(patientId, patientCaseId, createdAt, order?.orderTreatments, patientDiagnosis)}
                onTreatmentConsent={() => onTreatmentConsent(patientCaseId, order?.orderTreatments[0]?.servicePointId || 0)}
                onTreatmentFees={() => onTreatmentFees(patientCaseId, order?.orderTreatments[0]?.servicePointId || 0)}
                onDocumentReceipt={(type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL', isShow?: 'DF', isFixDF?: boolean) =>
                  onDocument(type, order?.payments?.orderPaymentId, isShow, isFixDF)
                }
                checkMed={!checkItemMed}
                onStickerMed={() => loadStickerProduct(patientCaseId)}
                invoice={invoice}
              />
          )
        }
      ]
    }
    if (props.type === 'RetrospectiveEdit') {
      delete objRenderData.columns[0]
      delete objRenderData.columns[6]
    }
    if (props.type !== 'RetrospectiveEdit') {
      delete objRenderData.columns[7]
    }

    return <TableRowCommon {...objRenderData} />
  }

  return (
    <Box>
      <Loading show={loading} type="fullLoading" />
      <Grid container spacing={2} className="">
        <Grid item xs={6} md={4} lg={3} className="w-100">
          <InputTextSearch
            disabled={props.disabled}
            keyInput={'search'}
            label={t('HISTORY_APPOINTMENT.SEARCH')}
            value={search}
            onchange={(event) => {
              setSearch(event.target.value)
              setPage(1)
            }}
            onClear={(event) => {
              setSearch('')
              setPage(1)
            }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3} xl={2} className="w-100">
          <FilterSelect
            disabled={props.disabled}
            renderValue={() => `${t('HISTORY_TREATMENT.FILTER.BRANCH')}: ${_.find(branches, (b: BranchInterface) => b.branchId === branchId)?.branchName || t('HISTORY_APPOINTMENT.STATUS_ALL')}`}
            label={t('STATUS.LABEL')}
            selectId="select-status"
            value={branchId}
            onchange={(event) => {
              setBranchId(event.target.value)
              setPage(1)
            }}
            labelId="label-status"
            options={[
              <MenuItem key={'all'} value={0}>
                {t('HISTORY_APPOINTMENT.STATUS_ALL')}
              </MenuItem>,
              _.map(branches, (branch: BranchInterface, i: number) => (
                <MenuItem key={i} value={branch.branchId}>
                  {branch.branchName || branch.branchNameEn}
                </MenuItem>
              ))
            ]}
            formControlStyle={{
              minWidth: '100% !important',
              maxWidth: '100% !important'
            }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3} xl={2} className="w-100">
          <InputSecondNewRangePicker
            disabled={props.disabled}
            value={rangeDate || []}
            inputHeight={32}
            onchange={(val: any) => {
              if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
              else {
                setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                setPage(1)
              }
            }}
            allowClear
            onClear={() => setRangeDate(emptyRangePicker)}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3} xl={2} className="w-100">
          <FilterSelect
            disabled={props.disabled}
            renderValue={() => `${t('HISTORY_APPOINTMENT.DOCTOR')}: ${_.find(doctors, (d: Doctors) => d.userId === doctorId)?.fullname || t('HISTORY_APPOINTMENT.STATUS_ALL')}`}
            label={t('STATUS.LABEL')}
            selectId="select-status"
            value={doctorId}
            onchange={(event) => {
              setDoctorId(event.target.value)
              setPage(1)
            }}
            labelId="label-status"
            options={[
              <MenuItem key={'all'} value={0}>
                {t('HISTORY_APPOINTMENT.STATUS_ALL')}
              </MenuItem>,
              _.map(doctors, (doctor: Doctors, i: number) => (
                <MenuItem key={i} value={doctor.userId}>
                  {doctor.fullname}
                </MenuItem>
              ))
            ]}
            formControlStyle={{
              minWidth: '100% !important',
              maxWidth: '100% !important'
            }}
            classesOption="style-select-doctors"
          />
        </Grid>
        {props.type !== "RetrospectiveEdit" &&
          <Grid item md={4} lg={12} xl={2} className="w-100" style={{ pointerEvents: props.disabled ? 'none' : 'all', opacity: props.disabled ? 0.4 : '' }}>
            <UseStyled.DropdownDocument className="w-max ml-xl-auto" sx={{ marginLeft: { xl: `0 !important` } }}>
              <Dropdown.Toggle id="dropdown-basic">


                <span>{t('REGISTER.BUTTON.PRINT_DOCUMENT')}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>

                {branchesInfo?.data?.referralPatient === "1" &&
                  <Dropdown.Item onClick={onExportReferralExamination}>
                    {t('TREATMENTS.BUTTON.REFERRAL_EXAMINATION')}
                  </Dropdown.Item>
                }
                {
                  branchesInfo?.data?.dentalReferral === "1" && (
                    <Dropdown.Item onClick={onExportDentalReferral}>
                      {t('TREATMENTS.BUTTON.REFERRAL_DENTAL')}
                    </Dropdown.Item>
                  )
                }
                {branchesInfo?.data?.opdCardForPrint === "1" &&
                  <Dropdown.Item href="javascript:void(0)" onClick={() => exportOpd(props.patient.patientId)}>
                    {t('REGISTER.BUTTON.OPD_CARD')}
                  </Dropdown.Item>
                }
                {branchesInfo?.data?.socialSecurityCertificate === "1" &&
                  <Dropdown.Item href="javascript:void(0)" onClick={exportSSO}>
                    {t('REGISTER.BUTTON.SSO')}
                  </Dropdown.Item>
                }
              </Dropdown.Menu>
            </UseStyled.DropdownDocument>
          </Grid>
        }
      </Grid>
      <Box className="mt-3">
        {props.type !== 'RetrospectiveEdit' && (
          <UseStyled.ResponsiveTable disabled={props.disabled}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              onSort={(sortByVal: string, sortTypeVal: string) => {
                setSortType(sortTypeVal)
                setSortBy(sortByVal)
              }}
              setPageLimit={setPageLimit}
              setPage={setPage}
              headCells={props.type === 'RetrospectiveEdit' ? headCellsRetrospectiveEdit : headCells}
              rowsData={_.map(historyTreatments, (val, i) => renderData(val, i))}
              textEmptyData={t('REGISTER.NO_ITEM')}
              customScroll
            />
          </UseStyled.ResponsiveTable>
        )}
        {props.type === 'RetrospectiveEdit' && (
          <UseStyleRetrospectiveEdit.ResponsiveRetrospectiveEditTable disabled={props.disabled}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              onSort={(sortByVal: string, sortTypeVal: string) => {
                setSortType(sortTypeVal)
                setSortBy(sortByVal)
              }}
              setPageLimit={setPageLimit}
              setPage={setPage}
              headCells={props.type === 'RetrospectiveEdit' ? headCellsRetrospectiveEdit : headCells}
              rowsData={_.map(historyTreatments, (val, i) => renderData(val, i))}
              textEmptyData={t('REGISTER.NO_ITEM')}
              customScroll
            />
          </UseStyleRetrospectiveEdit.ResponsiveRetrospectiveEditTable>
        )}
      </Box>

      {(configModal.type === 'FollowTreatment' && <PopupFollowTreatment patientId={configModal.patientId} patientCaseId={configModal.patientCaseId} patient={props.patient} vnNumber={configModal.vnNumber} />) || <></>}
      {configModal.type === 'MedicalCertificate' && (
        <MedicalCertificate
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          patientCaseId={configModal.patientCaseId || 0}
          createdAt={configModal.createdAt || ''}
          type={'historyTreatment'}
          treatments={configModal.treatments}
          diagnosis={configModal.diagnosis}
          patientRecommendId={configModal.patientRecommendId}
        />
      )}
      {configModal.type === 'treatmentConsent' && (
        <PopupTreatmentConsent
          type="historyTreatment"
          patientCaseId={configModal.patientCaseId || 0}
          servicePointId={configModal.servicePointId || 0}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
        />
      )}
      {configModal.type === 'treatmentFees' && (
        <PopupTreatmentFees
          type='historyTreatment'
          patientCaseId={configModal.patientCaseId || 0}
          servicePointId={configModal.servicePointId || 0}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
        />
      )}
      {configModal.type === 'MedicalServices' && (
        <SSO
          hidePhoneNumber={user.hasShowPhoneNumber === 'UNPERMISS'}
          patient={props.patient}
          branchId={branchIdServicePoint}
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          patientCaseId={configModal.patientCaseId || 0}
          createdAt={configModal.createdAt || ''}
          type={'historyTreatment'}
          treatments={configModal.treatments}
          diagnosis={configModal.diagnosis}
        />
      )}
      {configModal.type === 'MedicalCertificate_A5' && (
        <MedicalCertificate_A5 isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          branchId={branchIdServicePoint}
          patient={props.patient} />
      )}
      {configModal.type === 'MedicalFiveDisease' && (
        <MedicalCertificateFiveDisease
          patient={props.patient}
          branchId={branchIdServicePoint}
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          patientCaseId={configModal.patientCaseId || 0}
          createdAt={configModal.createdAt || ''}
          type={'historyTreatment'}
          treatments={configModal.treatments}
          diagnosis={configModal.diagnosis}
        />
      )}
      {configModal.type === 'MedicalDrivingLicense' && (
        <MedicalCertificateDrivingLicense
          patient={props.patient}
          branchId={branchIdServicePoint}
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          patientCaseId={configModal.patientCaseId || 0}
          createdAt={configModal.createdAt || ''}
          type={'historyTreatment'}
          treatments={configModal.treatments}
          diagnosis={configModal.diagnosis}
        />
      )}
      {exportReferralExamination && <ReferralExamination isShow={exportMedicalCertificate} setIsShow={(show: boolean) => setExportReferralExamination(show)} patientId={props.patient?.patientId} branchId={branchIdServicePoint} patient={props.patient} />}
      {
        exportDentalReferral && (
          <ReferralDental
            isShow={exportDentalReferral}
            setIsShow={(show: boolean) => setExportDentalReferral(show)}
            patientId={props.patient?.patientId}
            branchId={branchIdServicePoint}
          />
        )
      }
      <HistoryPayment {...modalData} onReset={() => setModalData({ isShow: false, data: {} })} />
      <>
        <FunctionPrint
          content={() => stickerMedRef.current}
          docTitle={t('TX_INFORMATION.TITLE')}
          removeIfram
          trigger={() => <div ref={buttonRef} className={`d-none`}>print</div>}
          style={'@page { size: 302.36220472px 188.97637795px;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
        />
        <div ref={stickerMedRef} className="print-show h-100 w-100">
          <PdfMedSticker stickerData={productsSelect} doctors={doctors} />
        </div>
      </>
    </Box>
  )
}

function ColumnTreatment(props: ColumnTreatmentType) {
  const { t } = useTranslation()
  const { treatments } = props
  const renderTreatmentNextVisit = (value: any) => {
    const treatment = typeof value === 'string' && value !== '-' && value ? JSON.parse(value) : value
    if (!_.isEmpty(treatment)) {
      return `${treatment[0]?.operativeName || treatment[0]?.operativeTypeName} ${_.size(treatment) > 1
        ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT_MORE_TREATMENT', {
          treatment_count: _.size(treatment) - 1
        })
        : ''
        }`
    } else return ''
  }

  const groupOrderTreatments = (item: any) => {
    const newArr = _.chain(item)
      .map((i: any) => {
        return i
      })
      .groupBy('doctorFullname')
      .map((value: any, key: string) => ({ key, data: value }))
      .value()

    return newArr
  }
  const groupTreatments = groupOrderTreatments(treatments)


  return props.type === 'RetrospectiveEdit' ? (
    <>
      {_.map(groupTreatments, (i: any, index: number) => {
        return (
          <>
            <Box className={`mr-2`} sx={{ marginTop: '2px' }}>
              <ChipDoctorRetrospectiveEdit label={i.key} size="small" />
              <span className={'align-middle'}>
                :
              </span>
            </Box>
            {_.map(i.data, (treatment: OrderTreatmentsType & OrderProductsType, index: number) => {
              return (
                <>
                  <Box key={index} className={`mr-2`} sx={{ marginTop: index > 0 ? '2px' : '' }}>
                    {treatment.itemName ? <ChipProduct label={'สินค้า'} size="small" /> : <></>}
                    <span className={'align-middle'}>{treatment.itemName || treatment.itemNameEn || ''}</span>
                  </Box>

                  <Box key={index} className={`mr-2`} sx={{ marginTop: index > 0 ? '2px' : '' }}>
                    <span className={'align-middle'}>
                      {treatment.operativeName || treatment.operativeNameEn || ''}
                      {clinicTheme.theme === clinicTheme.type.DENTAL && (treatment.teeth ? `(${treatment.teeth})` : '')}
                    </span>
                  </Box>
                  {(treatment.note && (
                    <Box className={`mr-2`} sx={{ marginTop: '2px' }}>
                      <ChipRemark label={t('Note.')} size="small" />
                      <span className={'align-middle'}>
                        <pre className="d-inline-block note"  >
                          {treatment.note}
                        </pre>
                      </span>
                    </Box>
                  )) || <></>}
                  {(!_.isEmpty(treatment.nextVisit) && (
                    <Box sx={{ marginTop: '2px' }}>
                      <ChipVisit label={t('Nv.')} size="small" />
                      <span>{`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT', {
                        date: treatment?.nextVisit?.date ? toBuddhistYear(moment(treatment?.nextVisit?.date), 'DD/MM/YYYY') : '-',
                        time: treatment?.nextVisit?.timeStart ? toBuddhistYear(moment(treatment?.nextVisit.timeStart, 'HH:mm'), 'HH:mm') : '-',
                        dentist: treatment?.nextVisit.doctorFullname || '',
                        treatment: renderTreatmentNextVisit(treatment?.nextVisit?.treatment)
                      })}`}</span>
                    </Box>
                  )) || <></>}
                </>
              )
            })}
          </>
        )
      })}
    </>
  )
    :
    (
      <>
        {_.map(groupTreatments, (i: any) => {
          return (
            <>
              <Box className={`mr-2`} sx={{ marginTop: '2px' }}>
                <ChipDoctor label={'Dr.'} size="small" />
                <span className={'align-middle'}>{i.key}</span>
              </Box>
              {_.map(i.data, (treatment: OrderTreatmentsType & OrderProductsType, index: number) => {
                return (
                  <>
                    <Box key={index} className={`mr-2`} sx={{ marginTop: index > 0 ? '2px' : '' }}>
                      {treatment.itemName ? <ChipProduct label={'Med./Pd.'} size="small" /> : <></>}
                      <span className={'align-middle'}>{treatment.itemName || treatment.itemNameEn || ''}</span>
                    </Box>

                    <Box key={index} className={`mr-2`} sx={{ marginTop: index > 0 ? '2px' : '' }}>
                      {treatment.operativeName || treatment.operativeNameEn ? <ChipTx label={'Tx.'} size="small" /> : <></>}
                      <span className={'align-middle'}>
                        {treatment.operativeName || treatment.operativeNameEn || ''}
                        {clinicTheme.theme === clinicTheme.type.DENTAL && (treatment.teeth ? `(${treatment.teeth})` : '')}
                      </span>
                    </Box>
                    {(treatment.note && (
                      <Box sx={{ marginTop: '2px' }}>
                        <ChipRemark label={t('Note.')} size="small" />
                        <span className={'align-middle'}>
                          <pre className="d-inline-block note"  >
                            {treatment.note}
                          </pre>
                        </span>
                      </Box>
                    )) || <></>}
                    {(!_.isEmpty(treatment.nextVisit) && (
                      <Box sx={{ marginTop: '2px' }}>
                        <ChipVisit label={t('Nv.')} size="small" />
                        <span>
                          {`${t('HISTORY_TREATMENT.MAKE_DATE')}: ${treatment?.nextVisit?.date ? toBuddhistYear(moment(treatment?.nextVisit?.date), 'DD/MM/YYYY') : '-'} ${t('HISTORY_TREATMENT.DATE_TIME')}: ${treatment?.nextVisit?.timeStart ? toBuddhistYear(moment(treatment?.nextVisit.timeStart, 'HH:mm'), 'HH:mm') : '-'}`}
                        </span>
                        <div style={{ textIndent: '33px' }}>
                          {treatment?.noteNextVisit}
                        </div>
                        <UseStyled.TagLab sx={{ display: treatment?.nextVisit?.hasLab === 'HAS' ? '' : 'none' }}>{t('HISTORY_TREATMENT.LAB')}</UseStyled.TagLab>
                        <UseStyled.TagLab sx={{ display: treatment?.nextVisit?.hasXray === 'HAS' ? '' : 'none' }}>{t('HISTORY_TREATMENT.XRAY')}</UseStyled.TagLab>
                      </Box>
                    )) || <></>}
                  </>
                )
              })}
            </>
          )
        })}
      </>
    )
}

function ColumnDetail(props: ColumnDetailType) {
  return (
    <Box className="d-flex align-items-center">
      {checkPackage(PACKAGE_ADDONS.ADDON_LAB) && (
        <UseStyled.CustomIconButton disabled={!props.hasLab} onClick={props.onLab} className="mr-2 p-0">
          <sidebarIcon.Lab />
        </UseStyled.CustomIconButton>
      )}
      {checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) && (
        <UseStyled.CustomIconButton disabled={!props.hasXray} onClick={props.onXray} className="mr-2 p-0">
          <sidebarIcon.Xray />
        </UseStyled.CustomIconButton>
      )}
      <ButtonCustom onClick={props.onEmr} textButton={'EMR'} variant="outlined" btnStyle={{ height: '32px', backgroundColor: colors.white, fontSize: '16px' }} style={{ margin: 0 }} />
    </Box>
  )
}

function ColumnAction(props: ColumnActionProps) {
  const { t } = useTranslation()
  const branchesInfo = useSelector(branchInfo)

  const onPrint = (type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL', isShow?: 'DF', isFixDF?: boolean) => {
    if (props.onDocumentReceipt) return props.onDocumentReceipt(type, isShow, isFixDF)
    else return false
  }


  return (
    <Box className="d-flex align-items-center" sx={{ flexWrap: 'wrap', gap: '8px' }}>
      <ButtonCustom
        onClick={props.type === 'RetrospectiveEdit' ? props.onEmrEdit : props.onFollow}
        textButton={props.type === 'RetrospectiveEdit' ? t('HISTORY_TREATMENT.BUTTON_EMR_EDIT') : t('HISTORY_TREATMENT.BUTTON_FOLLOW_TREATMENT')}
        variant="outlined"
        className={'mr-2 text-nowrap'}
        btnStyle={{ height: '36px', backgroundColor: colors.white, fontSize: '16px' }}
        style={{ margin: 0 }}
      />
      {props.type !== 'RetrospectiveEdit' && (
        <UseStyled.DropdownDocument>
          <Dropdown.Toggle id={`dropdown-basic${props.id || ''}`}>{t('REGISTER.BUTTON.PRINT_DOCUMENT')}</Dropdown.Toggle>
          <Dropdown.Menu>
            <UseStyled.DropdownTitle className="px-3">{t('HISTORY_TREATMENT.DOCUMENT.RECEIPT')}</UseStyled.DropdownTitle>
            {branchesInfo?.data?.receiptA5Default === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5Original === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5_ORI')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_ORI')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5Copy === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5_COPY')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_COPY')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5DefaultEn === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5_EN')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5OriginalEn === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5_EN_ORI')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN_ORI')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5CopyEn === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5_EN_COPY')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN_COPY')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5DefaultLandscape === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5_LANDSCAPE')}>
                {t('HISTORY_TREATMENT.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5OriginalLandscape === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5_LANDSCAPE_ORI')}>
                {t('HISTORY_TREATMENT.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE_ORI')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5CopyLandscape === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A5_LANDSCAPE_COPY')}>
                {t('HISTORY_TREATMENT.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE_COPY')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5DfLscape === "1" &&
              <UseStyled.DropdownItem className='text-ellipsis' title={t('RECEIPT.A5_DF_LSCAPE')} onClick={() => onPrint('A5_LANDSCAPE', 'DF')}>{t('RECEIPT.A5_DF_LSCAPE')}</UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5DfOrgLscape === "1" &&
              <UseStyled.DropdownItem className='text-ellipsis' title={t('RECEIPT.A5_DF_ORG_LSCAPE')} onClick={() => onPrint('A5_LANDSCAPE_ORI', 'DF')}>{t('RECEIPT.A5_DF_ORG_LSCAPE')}</UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5DfCopyLscape === "1" &&
              <UseStyled.DropdownItem className='text-ellipsis' title={t('RECEIPT.A5_DF_COPY_LSCAPE')} onClick={() => onPrint('A5_LANDSCAPE_COPY', 'DF')}>{t('RECEIPT.A5_DF_COPY_LSCAPE')}</UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5FixDfLscape === "1" &&
              <UseStyled.DropdownItem className='text-ellipsis' title={t('RECEIPT.A5_FIX_DF_LSCAPE')} onClick={() => onPrint('A5_LANDSCAPE', 'DF', true)}>{t('RECEIPT.A5_FIX_DF_LSCAPE')}</UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5FixDfOrgLscape === "1" &&
              <UseStyled.DropdownItem className='text-ellipsis' title={t('RECEIPT.A5_FIX_DF_ORG_LSCAPE')} onClick={() => onPrint('A5_LANDSCAPE_ORI', 'DF', true)}>{t('RECEIPT.A5_FIX_DF_ORG_LSCAPE')}</UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA5FixDfCopyLscape === "1" &&
              <UseStyled.DropdownItem className='text-ellipsis' title={t('RECEIPT.A5_FIX_DF_COPY_LSCAPE')} onClick={() => onPrint('A5_LANDSCAPE_COPY', 'DF', true)}>{t('RECEIPT.A5_FIX_DF_COPY_LSCAPE')}</UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA4Default === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A4')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA4Original === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A4_ORI')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4_ORI')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptA4Copy === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('A4_COPY')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4_COPY')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.receiptThermal === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('THERMAL')}>
                {t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.THERMAL')}
              </UseStyled.DropdownItem>
            }
            {/* {branchesInfo?.data?.receiptThermalDf === "1" &&
              <UseStyled.DropdownItem onClick={() => onPrint('THERMAL', 'DF')}>
                {t('RECEIPT.THERMAL_DF')}
              </UseStyled.DropdownItem>
            } */}
            <UseStyled.DropdownTitle className="px-3">{t('HISTORY_TREATMENT.DOCUMENT.MEDICAL_DOCUMENT')}</UseStyled.DropdownTitle>
            {branchesInfo?.data?.doctorCertificate === "1" &&
              <UseStyled.DropdownItem onClick={props.onMedicalCertificate}>
                {t('HISTORY_TREATMENT.DOCUMENT.MEDICAL_DOCUMENT_TYPE.MEDICAL_CERTIFICATE')}
              </UseStyled.DropdownItem>
            }
            <UseStyled.DropdownItem disabled={props.patientInvoiceId ? false : true} onClick={props.onTreatmentConsent}>
              {t('HISTORY_TREATMENT.DOCUMENT.MEDICAL_DOCUMENT_TYPE.TREATMENT_CONSENT')}
            </UseStyled.DropdownItem>
            <UseStyled.DropdownItem disabled={!props.invoice} onClick={props.onTreatmentFees}>
              {t('HISTORY_TREATMENT.DOCUMENT.MEDICAL_DOCUMENT_TYPE.TREATMENT_STATEMENT')}
            </UseStyled.DropdownItem>
            {branchesInfo?.data?.medicalServiceUsageForm === "1" &&
              <Tooltip placement={'right'} title={<p style={{ color: 'white', fontSize: '14px' }}>{t('TREATMENTS.BUTTON.SSO')}</p>} >
                <UseStyled.DropdownItem className={'text-ellipsis overflow-hidden'} onClick={props.onSSO}>{t('TREATMENTS.BUTTON.SSO')}</UseStyled.DropdownItem>
              </Tooltip>
            }
            {branchesInfo?.data?.doctorCertificateA5 === "1" &&
              <UseStyled.DropdownItem onClick={props.onMedicalCertificate_A5}>
                {t('MODAL_MEDICALCERTIFICATE_A5.TITLE')}
              </UseStyled.DropdownItem>}
            {branchesInfo?.data?.doctorCertificateFor5Diseases === "1" &&
              <UseStyled.DropdownItem onClick={props.onMedicalFiveDisease}>
                {t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_5_DISEASE')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.driverLicenseDoctorCertificate === "1" &&
              <UseStyled.DropdownItem onClick={props.onMedicalDrivingLicense}>
                {t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_DRIVING_LICENSE')}
              </UseStyled.DropdownItem>
            }
            {branchesInfo?.data?.drugSticker === "1" &&
              <>
                <UseStyled.DropdownTitle className="px-3">{t('TREATMENTS.BUTTON.STICKER_MEDICINE')}</UseStyled.DropdownTitle>

                <UseStyled.DropdownItem disabled={props?.checkMed} onClick={props.onStickerMed}>
                  {t('TREATMENTS.BUTTON.PRINT_STICKER_MEDICINE')}
                </UseStyled.DropdownItem>
              </>
            }
          </Dropdown.Menu>
        </UseStyled.DropdownDocument>
      )}
    </Box>
  )
}
