import { Image, Dropdown } from 'react-bootstrap'
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/theme'

/** ASSEST */
import iconTime from 'assets/icon/carlendar/iconTime.svg'
import operative from 'assets/icon/carlendar/operative.svg'
import phone from 'assets/icon/carlendar/phone.svg'
import closePopup from 'assets/icon/carlendar/closePopup.svg'
import iconEllipsis from 'assets/icon/iconEllipsis.svg'
import iconEdit from 'assets/icon/iconEdit.svg'
import print from 'assets/icon/carlendar/print.svg'
import { routeName } from 'routes/routes-name'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

type PopupAppointmentProps = {
  appointment: any
  setDropDown: any
  setDentistName: any
  editAppointment?: any
  index: any
}

const useStyles = makeStyles({
  header: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: '-10px'
  },
  patient: {
    fontSize: '1.2vw',
    color: colors.themeMainColor,
    fontWeight: 700
  },
  dentistName: {
    fontSize: '1.0vw',
    color: colors.textExtraLightGray
  },
  containerTag: {
    marginLeft: '10%'
  },
  tag: {
    backgroundColor: colors.themeThirdColor,
    fontSize: '0.8vw',
    color: colors.white,
    width: 'fitContent',
    borderRadius: '4px',
    margin: '3px',
    padding: '2px 4px'
  },
  general: {
    display: 'flex',
    fontSize: '1.0vw',
    color: colors.textPrimary
  },
  titleGeneral: {
    width: '9vw'
  },
  iconGeneral: {
    width: '10%'
  },
  closePopup: {
    cursor: 'pointer'
  }
})

export default function PopupAppointment(props: PopupAppointmentProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { appointment, setDropDown, setDentistName, editAppointment, index } = props

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <div className={classes.header}>
          <div style={{ marginLeft: 'auto' }}>
            <Dropdown>
              <Dropdown.Toggle variant="link" bsPrefix="p-0  " className=" text-black-50  ">
                <Image src={iconEllipsis} />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ fontSize: '16px' }}>
                <Dropdown.Item href="#/action-1">{t('APPOINTMENT.VIEW_EMR')}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={(e) => {
                    editAppointment(appointment, index)
                  }}
                >
                  <Image src={iconEdit} className="pr-1" />
                  {t('APPOINTMENT.EDIT')}
                </Dropdown.Item>
                <Dropdown.Item href={routeName.appointment + '/PDFAppointment'} target="_blank">
                  <Image src={print} className="pr-1" />
                  {t('APPOINTMENT.PRINT')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <Image
              className={classes.closePopup}
              src={closePopup}
              onClick={(e) => {
                setDropDown(false)
                setDentistName('')
              }}
            />
          </div>
        </div>
        <div className={classes.patient}>
          {appointment.patient.number} || {appointment.patient.name}
        </div>
        <div className={classes.dentistName}>{`${t('APPOINTMENT.DOCTOR')} ${appointment.dentist}`}</div>
        <div className={classes.general}>
          <div className={classes.iconGeneral}>
            <Image src={iconTime} />
          </div>
          {`${appointment.time.timeStart} ${t('TIME_UNIT')} -  ${appointment.time.timeEnd} ${t('TIME_UNIT')}`}
        </div>
        <div className={classes.general}>
          <div className={classes.iconGeneral}>
            <Image src={phone} />
          </div>
          {`${appointment.phone}`}
        </div>
        <div className={classes.general}>
          <div className={classes.iconGeneral}>
            <Image src={operative} />
          </div>
          <span style={{ width: '90%' }}>{`${appointment.treatments.map((treatment: string) => {
            return ` ${treatment}`
          })}`}</span>
        </div>
        <div className={classes.containerTag}>
          <span className={classes.tag} style={{ display: appointment.lab ? '' : 'none' }}>
            LAB
          </span>
          <span className={classes.tag} style={{ display: appointment.xray ? '' : 'none' }}>
            X-Ray
          </span>
        </div>
        <div className={classes.general}>
          <span className={classes.titleGeneral}>{t('APPOINTMENT.APPOINT_PRICE')}</span>
          <span>{`${appointment.costCondition === undefined ? '-' : appointment.costCondition}`}</span>
        </div>
        <div className={classes.general}>
          <span className={classes.titleGeneral}>{t('APPOINTMENT.FORM.PRACTICE_WAY')}:</span>
          <span>{`${appointment.rule === undefined ? '-' : appointment.rule}`}</span>
        </div>
        <div className={classes.general}>
          <span className={classes.titleGeneral}>{t('APPOINTMENT.FORM.REMARK')}:</span>
          <span>{`${appointment.note === undefined ? '-' : appointment.note}`}</span>
        </div>
      </div>
    </div>
  )
}
