/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'

export const TableConfig = styled('div')<{ expiredAlert: boolean }>(({ theme, expiredAlert }) => ({
    '& .MuiTableCell-head:nth-of-type(11)': {
        width: 100
    },
    [theme.breakpoints.up('lg')]: {
        '& .table-list': { top: 150, maxHeight: expiredAlert ? `calc(100vh - 150px - calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}))` : `calc(100vh - 150px - ${scale.navbarHeight})` }
    },
    [theme.breakpoints.up(1441)]: {
        '& .table-list': { top: 150, maxHeight: expiredAlert ? `calc(100vh - 150px - calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}))` : `calc(100vh - 150px - ${scale.navbarHeight})` }
    }
}))

export const THead = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: colors.themeMainColor,
    borderRadius: '8px 8px 0 0',
    padding: '0.5rem 1.5rem',
    fontSize: 16,
    color: colors.white
}))

export const TBody = styled('div')(({ theme }) => ({
    border: `1px solid ${colors.lightGray}`,
    borderTopWidth: 0,
    color: colors.textPrimary,
    fontSize: 16,
    padding: '0.625rem 1.5rem',
    '.expand-more': {
        backgroundColor: colors.white,
        border: `1px solid ${colors.textLightGray}`,
        color: colors.themeMainColor,
        borderRadius: 100,
        fontSize: 22
    }
}))

export const Cell = styled('div')(({ theme }) => ({ '&:first-of-type': { marginRight: '1.25rem', width: '100%' } }))