import { colors } from "constants/theme"
import IconProps from 'assets/pick/iconProps'

export default function Tooth(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="94.063" height="54.416" viewBox="0 0 94.063 54.416">
      <g data-name="Group 8758">
        <g data-name="Group 5678">
          <path data-name="Path 5692" d="M276.693 181.694c3.1-4.444 5.153-16.455 5.153-16.455s.005-.378 0-.723c-.156-18.279-17.265-19.136-22.15-13.839-.832.9-2.313 1.268-3.015.261-3.456-4.959-21.84-3.967-21.687 13.978A29.564 29.564 0 0 0 240.446 182a1.953 1.953 0 0 1 .3 1.61c-1.227 4.988-3.7 12.5 3.394 12.438 7.589-.065 6.468-8.491 14.473-8.559 7.434-.064 7.828 8.13 14.134 8.076 5.915-.05 4.751-7.421 3.634-12.307a1.957 1.957 0 0 1 .312-1.564z" transform="translate(-212.34 -143.128)" style={{ strokeWidth: '3.3px', strokeMiterlimit: 10, stroke: props.color || colors.disabledGray, fill: 'none' }} />
          <g data-name="Icon feather-smile">
            <path data-name="Path 5644" d="M12 21a9.75 9.75 0 0 0 7.223 3.611A9.75 9.75 0 0 0 26.446 21" transform="translate(27.192 6.006)" style={{ strokeWidth: '2.3px', strokeLinecap: 'round', strokeLinejoin: 'round', stroke: props.color || colors.disabledGray, fill: 'none' }} />
            <path data-name="Path 5645" d="M13.5 13.5h.018" transform="translate(27.498 6.999)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '3.3px', stroke: props.color || colors.disabledGray, fill: 'none' }} />
            <path data-name="Path 5646" d="M22.5 13.5h.018" transform="translate(29.332 6.999)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '3.3px', stroke: props.color || colors.disabledGray, fill: 'none' }} />
          </g>
          <g data-name="Group 5258">
            <g data-name="Group 5257">
              <path data-name="Path 5630" d="m249.287 6.589-4.033-2.206-2.207-4.034a.755.755 0 0 0-1.272 0l-2.207 4.034-4.033 2.206a.725.725 0 0 0 0 1.273l4.033 2.206 2.207 4.034a.725.725 0 0 0 1.272 0l2.207-4.034 4.033-2.206a.725.725 0 0 0 0-1.273zM244.371 8.9a.728.728 0 0 0-.288.288l-1.672 3.057-1.672-3.057a.728.728 0 0 0-.288-.288l-3.057-1.672 3.057-1.672a.727.727 0 0 0 .288-.288l1.672-3.057 1.672 3.057a.728.728 0 0 0 .288.288l3.056 1.672z" transform="translate(-155.851 .25)" style={{ strokeWidth: '.7px', fill: props.color || colors.disabledGray, stroke: props.color || colors.disabledGray }} />
            </g>
          </g>
          <g data-name="Group 5260">
            <g data-name="Group 5259">
              <path data-name="Path 5631" d="m177.889 108.354-2.421-1.324-1.325-2.421a.755.755 0 0 0-1.272 0l-1.325 2.421-2.421 1.324a.725.725 0 0 0 0 1.273l2.421 1.324 1.325 2.421a.725.725 0 0 0 1.272 0l1.325-2.421 2.421-1.324a.725.725 0 0 0 0-1.273zm-3.3 1.426a.727.727 0 0 0-.288.288l-.79 1.443-.79-1.443a.728.728 0 0 0-.288-.288l-1.443-.79 1.443-.79a.728.728 0 0 0 .288-.288l.79-1.444.79 1.444a.728.728 0 0 0 .288.288l1.443.79z" transform="translate(-96.499 -92.932)" style={{ strokeWidth: '.7px', fill: props.color || colors.disabledGray, stroke: props.color || colors.disabledGray }} />
            </g>
          </g>
          <g data-name="Group 5679">
            <g data-name="Group 5259">
              <path data-name="Path 5631" d="m177.889 108.354-2.421-1.324-1.325-2.421a.755.755 0 0 0-1.272 0l-1.325 2.421-2.421 1.324a.725.725 0 0 0 0 1.273l2.421 1.324 1.325 2.421a.725.725 0 0 0 1.272 0l1.325-2.421 2.421-1.324a.725.725 0 0 0 0-1.273zm-3.3 1.426a.727.727 0 0 0-.288.288l-.79 1.443-.79-1.443a.728.728 0 0 0-.288-.288l-1.443-.79 1.443-.79a.728.728 0 0 0 .288-.288l.79-1.444.79 1.444a.728.728 0 0 0 .288.288l1.443.79z" transform="translate(-168.499 -77.932)" style={{ strokeWidth: '.7px', fill: props.color || colors.disabledGray, stroke: props.color || colors.disabledGray }} />
            </g>
          </g>
          <g data-name="Group 5680">
            <g data-name="Group 5259">
              <path data-name="Path 5631" d="m174.657 106.906-1.565-.856-.856-1.565a.488.488 0 0 0-.822 0l-.856 1.565-1.565.856a.469.469 0 0 0 0 .823l1.565.856.856 1.565a.469.469 0 0 0 .822 0l.856-1.565 1.565-.856a.469.469 0 0 0 0-.823zm-2.135.922a.47.47 0 0 0-.186.186l-.511.933-.511-.933a.471.471 0 0 0-.186-.186l-.933-.511.933-.51a.47.47 0 0 0 .186-.186l.511-.933.511.933a.47.47 0 0 0 .186.186l.933.51z" transform="translate(-158.499 -66.932)" style={{ strokeWidth: '.7px', fill: props.color || colors.disabledGray, stroke: props.color || colors.disabledGray }} />
            </g>
          </g>
          <g data-name="Group 5282">
            <g data-name="Group 5274">
              <g data-name="Group 5273">
                <path data-name="Path 5641" d="M16.176 0H1.244C.557 0 0 .14 0 .313v2.816c0 1.205 3.908 2.186 8.71 2.186s8.71-.981 8.71-2.186V.313C17.421.14 16.864 0 16.176 0zm-1.244 3.129c0 .861-2.791 1.561-6.222 1.561s-6.222-.7-6.222-1.561V.625h12.444v2.5z" transform="rotate(58 -3.383 10.412)" style={{ fill: props.color || colors.disabledGray, stroke: props.color || colors.disabledGray }} />
              </g>
            </g>
            <g data-name="Group 5286">
              <g data-name="Group 5225">
                <path data-name="Path 5614" d="M3.912 0H.436C.2 0 0 .284 0 .635v18.412c0 1.745.975 3.165 2.174 3.165s2.174-1.42 2.174-3.165V.635C4.348.284 4.153 0 3.912 0zm-.436 19.047c0 1.045-.584 1.9-1.3 1.9s-1.3-.851-1.3-1.9V1.269h2.6v17.778z" transform="rotate(-32 48.17 -9.573)" style={{ fill: props.color || colors.disabledGray, stroke: props.color || colors.disabledGray }} />
              </g>
            </g>
            <path data-name="Path 5762" d="M.112 5.1 0 .4l1.577.509.934-.656L3.577.8 5.129.143l.715.8L7.375.369l.762.393 1.671-.38.846.436L11.769 0l.975.8L14.01.386l1.076.056.152 4.511z" transform="rotate(58 .092 12.933)" style={{ fill: props.color || colors.disabledGray }} />
          </g>
        </g>
      </g>
    </svg>


  )
}
