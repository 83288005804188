import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  inputCheckBox: {
    '& $label': {
      fontFamily: 'Kanit',
      fontSize: '16px',
      fontWeight: 500
    },
    '& $span': {
      fontSize: '16px'
    }
  },
  modal: {
    '& .modal-sm': {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '400px !important'
      }
    }
  }
}))

export default useStyles
