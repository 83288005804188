import { Box, Typography, Button, Tab } from '@mui/material';

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { Dropdown } from 'react-bootstrap';

export const SectionImage = styled(Box)(({ theme }) => ({
    minHeight: 450,
    maxHeight: 650,
    [theme.breakpoints.down('xl')]: {
        maxHeight: 480,
    },
    [theme.breakpoints.down('lg')]: {
        maxHeight: 650,
    },
    [theme.breakpoints.down('md')]: {
        maxHeight: 700,
    },
    [theme.breakpoints.down('xs')]: {
        maxHeight: 540,
    }
}))

export const DropdownDocument = styled(Dropdown)(({ theme }) => ({
    '.dropdown-toggle': {
        backgroundColor: `${colors.white} !important`,
        color: `${colors.themeSecondColor} !important`,
        border: `0 solid ${colors.themeSecondColor} !important`,
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none !important',
        height: 44,
        width: '100%',
        '&:after': {
            marginLeft: '0.5rem'
        },
        '&[aria-expanded="false"]:after': {
            transform: 'rotate(180deg)'
        }
    },
    '.dropdown-menu': {
        maxWidth: '20rem',
        marginBottom: 4,
        borderRadius: 4,
        position: 'fixed !important',
        // zIndex: 999,
        background: colors.white,
        border: `1px solid ${colors.lightGray}`,
        '.dropdown-item': {
            '&:hover': {
                backgroundColor: colors.themeSecondColor06,
                color: colors.themeSecondColor
            }
        }
    },
    '.dropdown-menu.main': {
        minWidth: '5rem',
        marginBottom: 4,
        borderRadius: 4,
        position: 'fixed',
        // zIndex: 999,
        background: colors.white,
        border: `1px solid ${colors.lightGray}`,
        '.dropdown-item': {
            '&:hover': {
                backgroundColor: colors.themeSecondColor06,
                color: colors.themeSecondColor
            }
        }
    }


}))

export const FragmentLeft = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        maxWidth: 250,
        marginRight: 12,
    }
}))


export const FragmentRight = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 12
    }
}))

export const TypographyTitle = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 500
}))

export const TreatmentList = styled('section')(({ theme }) => ({}))

export const TreatmentListItem = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        display: 'flex'
    }
}))

export const Item = styled(Button)(({ theme }) => ({
    height: 32,
    width: '100%',
    justifyContent: 'start',
    border: 'none',
    boxShadow: 'none',
    padding: '0 1rem',
    margin: '0.25rem 0',
    color: colors.textPrimary,
    fontSize: 16,
    '&:hover, &.active': {

        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor,
    }
}))

export const TreatmentListSelect = styled('div')(({ theme }) => ({}))

export const TreatmentType = styled('section')(({ theme }) => ({
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
}))

export const TreatmentTypeTabs = styled(Box)(({ theme }) => ({
    maxHeight: 78,
    flex: 'none',
    paddingRight: 4,
    overflowY: 'auto',
    '.MuiTabs-flexContainer': {
        marginLeft: '-0.25rem',
        marginRight: '-0.25rem',
        flexWrap: 'wrap',
        maxWidth: '100%'
    }
}))

export const ButtonType = styled(Tab)(({ theme }) => ({
    position: 'relative',
    margin: '0.5rem 0 0',
    minWidth: 'unset',
    maxWidth: 'unset',
    width: '8.33%',
    minHeight: 'unset',
    height: 30,
    padding: '0 0.5rem',
    border: 'none !important',
    backgroundColor: 'transparent !important',
    color: colors.textPrimary,
    flexShrink: 'unset',
    lineHeight: 2,
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '0.25rem',
        right: '0.25rem',
        zIndex: -1,
        borderRadius: 4,
        border: `1px solid ${colors.themeSecondColor}`
    },
    '.MuiTouchRipple-root': {
        color: colors.white
    },
    '&.Mui-selected': {
        color: colors.white,
        '&:before': {
            backgroundColor: colors.themeSecondColor
        }
    },
    [theme.breakpoints.down('xl')]: {
        width: '12.5%'
    },
    [theme.breakpoints.down('md')]: {
        width: '16.67%'
    },
    [theme.breakpoints.down('xs')]: {
        width: '33.33%'
    }
}))

export const EmptyItem = styled('section')(({ theme }) => ({
    margin: '0 0.25rem',
    height: '100%'
}))

export const RowPanel = styled('div')(({ theme }) => ({
    paddingRight: 4,
    width: '100%'
}))

export const ColumnPanel = styled('div')(({ theme }) => ({
    position: 'relative',
    marginBottom: '0.5rem',
    padding: '0 0.25rem',
    width: '12.5%',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 4,
        right: 4,
        bottom: 0,
        zIndex: 1,
        transition: 'background .5s ease'
    },
    '.time-label': {
        marginBottom: '-100px',
        transition: 'margin .3s ease'
    },
    '&:hover': {
        '.time-label': {
            marginBottom: 0
        },
        '&:before': {
            background: `linear-gradient(180deg, ${colors.transparent} 0%, ${colors.black60} 100%)`,
        }
    },
    [theme.breakpoints.down('lg')]: {
        width: '16.667%'
    },
    [theme.breakpoints.down('md')]: {
        width: '25%'
    },
    [theme.breakpoints.down('xs')]: {
        width: '33.33%'
    }
}))

export const CardImg = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
}))

export const DivImg = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        border: `1px solid ${colors.lightGray}`
    },
}))

export const CardTime = styled(Box)(({ theme }) => ({
    position: 'absolute',
    left: 10,
    right: 10,
    bottom: 8,
    zIndex: 1,
    p: {
        fontSize: 12,
        color: colors.white
    }
}))