import { useCallback, useEffect, useState } from 'react'
import { Box, TableCell, TableRow, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

/** UTILS || SLICE */
import { treatment, setTreatmentByKey, setSummeryTreatments, TreatmentStateInterface, StickerType, ProductStickerType } from 'app/slice/treatment.slice'
import { getBranch, getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { showModal, resetModal, handleShow } from 'app/slice/modal.slice'
import useWindowSize from 'utils/useWindowSize'

/** API */
import ComponentApi, { Bookmark } from 'api/components-api'
import TreatmentProductApi, { AllProductType, AllProduct, ProductType } from 'api/dentists/treatmentProduct.api'

/** CONSTANTS */
import { fileUrl } from 'api/api'
import { colors } from 'constants/theme'
import { Icons, icons, onImgError } from 'constants/images'

/** COMPONENT */
import InputTextSearch from 'component/Input/InputTextSearch'
import AlertProductOutOfStock, { AlertProductOutOfStockType } from 'component/Treatments/AlertProductOutOfStock'
import PopupManageProduct, { initialProductState } from 'component/Treatments/PopupManageProduct'
import AddBoxIcon from '@mui/icons-material/AddBox'

/** STYLE */
import { IconAddBox, IconBookmark } from 'features/treatments/MainMenu/OPD/style/UseStyle'
import { TypeTabs, ButtonType, ProductItem, ProductBox, ProductCard, InventoryChip, ProductQty } from 'features/treatments/MainMenu/OPD/style/ProductUseStyle'
import ButtonCustom from 'component/Button/ButtonCustom'
import ReMed from 'component/Treatments/PopupReMed'
import { ResponsiveTxTable } from './style/TxUseStyle'
import TableCustom from './Table/TableCustom'
import { selectMe } from 'app/slice/user.slice'
import { notiError } from 'component/notifications/notifications'

type MedType = {
  type: 'Treatment' | 'Payment' | 'RetrospectiveOrder'
  userId?: number
  patient?: any
  patientCaseId: number
  hasChangeProductPrice: 'PERMISS' | 'UNPERMISS'
  hasChangeProductDiscountAdditional: 'PERMISS' | 'UNPERMISS'
  medShowType?: '1' | '0'
}

export type ImageType = {
  fileId?: number
  filePath?: string
  fileName?: string
}

const initProductOutOfStock = {
  name: '',
  code: '',
  qty: 0,
  unit: ''
}

export default function MED(props: MedType) {
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const modal: boolean = useSelector(handleShow)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const user = useSelector(selectMe)
  const branch_ = getClinicInfo()

  const userId = props.userId
  const branchId = Number(getBranch())
  const [typeSelected, setTypeSelected] = useState<string | number>('All')
  const [medicinesType, setMedicinesType] = useState([])
  const [medicines, setMedicines] = useState([])
  const [popupProductOutOfStock, setPopupProductOutOfStock] = useState<AlertProductOutOfStockType>(initProductOutOfStock)

  const [numberPage, setNumberPage] = useState(1)
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(props.medShowType === '1' ? 6 : 50)
  const [rowCount, setRowCount] = useState(0)
  const [search, setSearch] = useState('')

  // popup manage product
  const [popupManageProduct, setPopupManageProduct] = useState<ProductStickerType>(initialProductState)
  const [isShowPopupProduct, setIsShowPopupProduct] = useState(false)
  const [isShowReMed, setIsShowReMed] = useState(false)

  const [isNotAddMed, setIsNotAddMed] = useState<boolean>(false)

  useEffect(() => {
    setIsNotAddMed((user?.hasCreateMed === 'UNPERMISS' && props.type === 'Payment') ? true : false)
  }, [user])

  const loadMedicineType = async () => {
    const params: AllProductType = {
      group: 'MEDICINE'
    }
    const resp = await TreatmentProductApi.findAllProductType(params)
    if (resp.status === 200) setMedicinesType(resp.data)
  }
  const loadMedicine = useCallback(
    async (valueUserId: number) => {
      let condition: AllProduct = { page: page, pageLimit: pageLimit, branchId: branchId, userId: valueUserId, group: 'MEDICINE' }
      if (search) condition = { ...condition, search: search }
      if (typeSelected === 'BOOKMARK') condition = { ...condition, isBookmark: '1' }
      if (typeSelected && typeSelected !== 'BOOKMARK' && typeSelected !== 'All' && typeSelected !== 'OUT_OF_STOCK') { condition = { ...condition, typeId: Number(typeSelected) } }

      if (typeSelected === 'OUT_OF_STOCK') {
        const resp = await TreatmentProductApi.findAllProductOutOfStock(condition)
        if (resp.status === 200) {
          setMedicines(resp.data.map((item: any) => { return { ...item, isOutOfStock: true } }))
          setRowCount(resp.headers['x-total'])
        }
      } else {
        const resp = await TreatmentProductApi.findAllProduct(condition)
        if (resp.status === 200) {
          setMedicines(resp.data)
          setRowCount(resp.headers['x-total'])
        }
      }

    },
    [search, typeSelected, page]
  )

  const handleChangeType = (type: string | number) => {
    if (type === 'BOOKMARK') {
      setTypeSelected('BOOKMARK')
    } else if (type === 'All') setTypeSelected(0)
    else if (type === 'OUT_OF_STOCK') setTypeSelected('OUT_OF_STOCK')
    else setTypeSelected(Number(type))
  }

  const handleBookmark = async (id: number, bookmarkId: string) => {
    const payload: Bookmark = {
      bookmarkType: 'MEDICINE',
      userId: userId || 0,
      itemId: id,
      isBookmark: bookmarkId ? '0' : '1'
    }

    const resp = await ComponentApi.bookmark(payload)

    if (resp.status === 201) loadMedicine(userId || 0)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleAddProduct = (med: any) => {
    const hasOverdue = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId })
    if (med.qty < 1) {
      setPopupProductOutOfStock({ name: med.itemName || med.itemNameEn, code: med.itemCode, qty: med.qty, unit: med.itemUnitName || med.itemUnitNameEn })
      dispatch(showModal())
      return
    }
    const item = {
      ...med,
      total: med.price,
      doctorId: !hasOverdue ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId : null,
      discount: 0,
      additional: 0,
      discountUnit: 'BAHT',
      additionalUnit: 'BAHT',
      stock: med.qty,
      isEdit: null
    }
    const hasItemInSummary = _.some(treatments.summeryTreatments, (sum, index) => sum.itemId === item.itemId)
    // if (!hasItemInSummary && props.patientCaseId) {
    if (!hasItemInSummary) {
      const doctorIsInConsentDocument = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId })
      const sticker: StickerType = {
        itemId: med.itemId,
        itemName: med.itemName,
        itemNameEn: med.itemNameEn,
        itemCode: med.itemCode,
        image: {
          fileId: med?.image?.fileId || null,
          filePath: med?.image?.filePath || '',
          fileName: med?.image?.fileName || ''
        },
        stock: med.qty,
        defaultProductUnitName: med.itemUnitName || med.itemUnitNameEn,
        dosage: 1,
        expiredDate: med.expireDate || ''
        // itemUnitName: med.itemUnitName,
        // itemUnitNameEn: med.itemUnitNameEn,
      }

      if (props.patientCaseId && props.type !== "RetrospectiveOrder") {
        TreatmentProductApi.findStickerById(props.patientCaseId, item.itemId)
          .then(({ data }) => {
            const newItem: ProductStickerType = {
              ...med,
              sticker: JSON.stringify(sticker),
              itemId: med.itemId,
              tempOrderProductId: data.tempOrderProductId,
              doctorId: !doctorIsInConsentDocument ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId : null,
              qty: 1,
              price: med.price,
              discount: 0,
              discountUnit: 'BAHT',
              additional: 0,
              additionalUnit: 'BAHT',
              total: med.price
            }
            setPopupManageProduct(newItem)
            setIsShowPopupProduct(true)
            dispatch(showModal())
          })
          .catch((e) => {
            return
          })
      } else {
        const newItem: ProductStickerType = {
          ...med,
          sticker: JSON.stringify(sticker),
          itemId: med.itemId,
          // tempOrderProductId: data.tempOrderProductId,
          doctorId: !doctorIsInConsentDocument ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId : null,
          qty: 1,
          price: med.price,
          discount: 0,
          discountUnit: 'BAHT',
          additional: 0,
          additionalUnit: 'BAHT',
          total: med.price
        }

        setPopupManageProduct(newItem)
        setIsShowPopupProduct(true)
        dispatch(showModal())
      }

    } else {
      const index = _.findIndex(treatments.summeryTreatments, { itemId: item.itemId })
      if (index < 0) dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, { ...item, qty: 1 }] }))
      else {
        const currentQty = Number(treatments.summeryTreatments[index].qty) + 1
        if (currentQty > med.qty) {
          setPopupProductOutOfStock({ name: med.itemName || med.itemNameEn, code: med.itemCode, qty: med.qty, unit: med.itemUnitName || med.itemUnitNameEn })
          dispatch(showModal())
          return
        } else {
          dispatch(setSummeryTreatments({ index: index, key: 'qty', value: currentQty <= med.qty ? currentQty : med.qty }))
          dispatch(setSummeryTreatments({ index: index, key: 'total', value: currentQty <= med.qty ? currentQty * med.price : med.qty * med.price }))
        }
      }
    }
  }

  const renderImage = (image: ImageType) => {
    if (image?.filePath && image?.fileName) return `${fileUrl}/${image.filePath}/${image.fileName}`
    else return icons.medDefault
  }

  const renderDateExpired = (date: string, day: number) => {
    if (date && day) {
      const now = moment()
      const diff = moment(date).add('days', 1).diff(now, 'days')
      if (diff <= day) return colors.themeThirdColor
      else return colors.textLightGray
    } else return colors.textLightGray
  }

  const desktopHeadCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'type', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.MEDICINE_TYPE') }
  ]

  const mobileHeadCells = [{ id: 'action', disablePadding: false, label: 'Action', align: width >= 768 ? 'center' : 'right', class: 'pr-1 pr-md-3' }]

  const tableHeadCells = [
    { id: 'code', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.MEDICINE_CODE'), align: 'center' },
    { id: 'service', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.MEDICINE_NAME') },
    { id: 'unit', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.MEDICINE_UNIT_NAME'), align: 'center' },
    { id: 'price', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.MEDICINE_PRICE'), align: 'right' },
    { id: 'exp', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.MEDICINE_EXPIRE_DATE'), align: 'center' },
    { id: 'qty', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.MEDICINE_QTY'), align: 'center' }
  ]

  const headCells = width >= 768 ? (width >= 1200 && [...desktopHeadCells, ...tableHeadCells, ...mobileHeadCells]) || [...tableHeadCells, ...mobileHeadCells] : [...mobileHeadCells]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { amounDayBeforeExpired, bookmark, bookmarkId, itemId, itemCode, expireDate, group, inventoryId, isOutOfStock, itemName, itemTypeName, itemUnitName, minStock, price, qty, qtyExpireDate } = objData
    return (
      <TableRow key={itemId}>
        {(width >= 768 && (
          <>
            {width >= 1200 && (
              <>
                <TableCell align="center" scope="row">
                  {no}
                </TableCell>
                <TableCell align="left" scope="row" className="text-ellipsis" title={itemTypeName}>
                  {itemTypeName}
                </TableCell>
              </>
            )}
            <TableCell align="center" scope="row" className="text-ellipsis">
              {itemCode}
            </TableCell>
            <TableCell align="left" scope="row" className="text-ellipsis">
              {itemName}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis">
              {itemUnitName}
            </TableCell>
            <TableCell align="right" scope="row" className="text-ellipsis">
              {numberFormat(price)}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis" style={{ color: renderDateExpired(expireDate, amounDayBeforeExpired) }}>
              {`${toBuddhistYear(moment(expireDate), 'DD/MM/YY') === 'NaN' ? '-' : toBuddhistYear(moment(expireDate), 'DD/MM/YY')}`}{`${toBuddhistYear(moment(expireDate), 'DD/MM/YY') === 'NaN' ? '' : `(${numberFormat(qtyExpireDate || 0, 0, 0)})`}`}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis" style={{ color: qty < minStock ? 'red' : '' }}>
              {qty}
            </TableCell>
            <TableCell align="center" scope="row">
              <IconBookmark key={itemId} className="p-0 mr-1" onClick={() => handleBookmark(itemId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
              </IconBookmark>
              <IconAddBox disabled={(props?.hasChangeProductPrice === 'PERMISS' ? false : true) || isOutOfStock || isNotAddMed} key={itemId} className="p-0 ml-1" onClick={() => handleAddProduct(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                <AddBoxIcon style={{ width: 30, height: 30 }} />
              </IconAddBox>
            </TableCell>
          </>
        )) || (
            <>
              <TableCell align="left" scope="row">
                <Box className="d-flex justify-content-between">
                  <Box>
                    {/* <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                      {t('TREATMENT_PRODUCT.TABLE.MEDICINE_CODE')}
                    </Typography> */}
                    <Typography className="d-inline">{itemCode}</Typography>
                  </Box>
                  <Box>
                    <IconBookmark key={itemId} className="p-0 mr-1" onClick={() => handleBookmark(itemId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                      {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
                    </IconBookmark>
                    <IconAddBox disabled={props?.hasChangeProductPrice === 'PERMISS' ? false : true || isNotAddMed} key={itemId} className="p-0 ml-1" onClick={() => handleAddProduct(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                      <AddBoxIcon style={{ width: 30, height: 30 }} />
                    </IconAddBox>
                  </Box>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.MEDICINE_NAME')}
                  </Typography>
                  <Typography className="d-inline">{itemName}</Typography>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.MEDICINE_UNIT_NAME')}
                  </Typography>
                  <Typography className="d-inline">{itemUnitName}</Typography>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.MEDICINE_PRICE')}
                  </Typography>
                  <Typography className="d-inline">{numberFormat(price)}</Typography>
                </Box>
              </TableCell>
            </>
          )}
      </TableRow>
    )
  }

  const onCancelPopupManageProduct = () => {
    dispatch(resetModal())
    setPopupManageProduct(initialProductState)
    setIsShowPopupProduct(false)
  }

  const onReMed = () => {
    setIsShowReMed(true)
    dispatch(showModal())
  }

  useEffect(() => {
    loadMedicineType()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [typeSelected])

  useEffect(() => {
    if (userId) loadMedicine(userId)
  }, [loadMedicine, userId])

  useEffect(() => {
    if (!modal) {
      onCancelPopupManageProduct()
      setPopupProductOutOfStock(initProductOutOfStock)
      dispatch(resetModal())
    }
  }, [modal])

  const handleCannotAddMed = () => {
    if (isNotAddMed) {
      return notiError(t('TREATMENT_PRODUCT.MEDICINE.ERROR.NOTADD'))
    }
  }

  return (
    <Box>
      <Box className="d-flex justify-content-between align-items-center pb-3">
        <Typography sx={{ fontSize: 18, fontWeight: 500, flex: 'none' }}>{t('TREATMENT_PRODUCT.TITLE.MEDICINE')}</Typography>
        <Box className="w-100 pl-3" sx={{ maxWidth: 379 }} style={{ justifyContent: 'end', display: 'flex' }}>
          <ButtonCustom className={'px-2 px-md-3'} btnStyle={{ height: 30, maxWidth: { sm: '100px' } }} textButton={t('TREATMENT_PRODUCT.MEDICINE.REMED')} onClick={() => onReMed()} style={{ fontSize: 16, width: 'fit-content' }} />
          <InputTextSearch keyInput={'search'} label={t('INPUT.SEARCH')} value={search} onchange={(event) => setSearch(event.target.value)} onClear={(event) => setSearch('')} />
        </Box>
      </Box>
      <TypeTabs className="d-flex flex-wrap custom-scroll">
        <Box className="px-1 pt-2">
          <ButtonType variant="outlined" title={t('ALL')} className={`${typeSelected === 'All' || typeSelected === 0 ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={() => handleChangeType('All')} key={`type-all`}>
            <span className="text-ellipsis">{t('ALL')}</span>
          </ButtonType>
        </Box>
        <Box className="px-1 pt-2" >
          <ButtonType variant="outlined" title={t('TREATMENT_PRODUCT.MEDICINE.MED_OUT')} className={`${typeSelected === 'OUT_OF_STOCK' || typeSelected === 'OUT_OF_STOCK' ? 'active' : ''} overflow-hidden`} data-value={'OUT_OF_STOCK'} onClick={() => handleChangeType('OUT_OF_STOCK')} key={`out-of-stock`}>
            <span className="text-ellipsis">{t('TREATMENT_PRODUCT.MEDICINE.MED_OUT')}</span>
          </ButtonType>
        </Box>
        <Box className="px-1 pt-2">
          <ButtonType variant="outlined" title={t('BOOKMARK')} className={`${typeSelected === 'BOOKMARK' ? 'active' : ''}`} data-value={'BOOKMARK'} onClick={() => handleChangeType('BOOKMARK')} key={`type-bookmark`}>
            {typeSelected === 'BOOKMARK' ? <Icons.StarActive fill={colors.white} /> : <Icons.StarInActive fill={colors.textPrimary} />}
          </ButtonType>
        </Box>
        {_.map(medicinesType, (type: ProductType, index: number) => {
          return (
            <Box key={`med-type-${index}`} className="px-1 pt-2">
              <ButtonType
                variant="outlined"
                key={type.productTypeId}
                title={type.productTypeName || type.productTypeNameEn}
                onClick={() => handleChangeType(type.productTypeId)}
                className={`${Number(typeSelected) === Number(type.productTypeId) ? 'active' : ''} text-ellipsis`}
              >
                <span className="text-ellipsis">{type.productTypeName || type.productTypeNameEn}</span>
              </ButtonType>
            </Box>
          )
        })}
      </TypeTabs>
      {(props.medShowType === '0' || branch_?.medShowType === '0') && (
        <ProductItem className="d-flex align-items-start flex-wrap custom-scroll mt-3 mt-xl-4">
          {(!_.isEmpty(medicines) &&
            _.map(medicines, (med: any, index: number) => {
              return (
                <ProductBox key={`med-${index}`} className="px-1 px-xl-2 mb-2 mb-xl-3">
                  <ProductCard>
                    {(_.get(_.find(treatments.summeryTreatments, { itemId: med.itemId }), 'qty') && <ProductQty>{_.get(_.find(treatments.summeryTreatments, { itemId: med.itemId }), 'qty')}</ProductQty>) || <></>}
                    <Box onClick={() => (med.isOutOfStock || isNotAddMed) ? handleCannotAddMed() : handleAddProduct(med)} className="card-image">
                      <Box className="position-relative">
                        {med.isOutOfStock && <span className='position-absolute top-50 start-50 translate-middle fw-bolder' style={{ fontSize: '22px', opacity: 0.9, zIndex: 1, borderBottom: '3px solid', paddingBottom: '' }}>{t('TREATMENT_PRODUCT.MEDICINE.OUT_STOCK')}</span> || <span></span>}
                        <img src={renderImage(med.image)} alt={med.itemName} className={`${_.isEmpty(med.image) || !med.image?.filePath || !med.image?.fileName ? 'no-prod-image' : ''}`} onError={onImgError} style={{ ...(med.isOutOfStock && { opacity: 0.5 }) }} />
                      </Box>
                    </Box>
                    <Box className="card-detail">
                      {(med.itemCode && (
                        <Typography onClick={() => (med.isOutOfStock || isNotAddMed) ? handleCannotAddMed() : handleAddProduct(med)} className="text-ellipsis" sx={{ paddingBottom: '2px', color: colors.textLightGray, fontSize: '11px', fontWeight: 500 }}>
                          {med.itemCode}
                        </Typography>
                      )) || <></>}
                      <Box className="d-flex align-items-center justify-content-between">
                        <Typography onClick={() => (med.isOutOfStock || isNotAddMed) ? handleCannotAddMed() : handleAddProduct(med)} className="text-ellipsis" sx={{ flex: 1, lineHeight: 1.6 }}>
                          {med.itemName || med.itemNameEn}
                        </Typography>
                        <IconBookmark className="p-0 ml-2" onClick={() => handleBookmark(med.itemId, med.bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                          {med.isBookmark ? <Icons.StarActive width="20" height="20" /> : <Icons.StarInActive width="20" height="20" />}
                        </IconBookmark>
                      </Box>
                      <Typography onClick={() => (med.isOutOfStock || isNotAddMed) ? handleCannotAddMed() : handleAddProduct(med)} className="text-ellipsis" sx={{ marginTop: '-2px', fontSize: '14px', fontWeight: 500 }}>
                        {numberFormat(med.price)} {t('CURRENCY_CHANGE.TH')}
                      </Typography>
                      {med.isOutOfStock === 0 &&
                        <Box onClick={() => (med.isOutOfStock || isNotAddMed) ? handleCannotAddMed() : () => handleAddProduct(med)} className="d-flex align-items-center justify-content-between pt-1">
                          <Typography
                            className="text-ellipsis"
                            sx={{
                              color: renderDateExpired(med.expireDate, med.amounDayBeforeExpired),
                              fontSize: '11px',
                              fontWeight: 500
                            }}
                          >
                            {(med.expireDate && `EXP ${toBuddhistYear(moment(med.expireDate), 'DD/MM/YY')} (${numberFormat(med.qtyExpireDate || 0, 0, 0)})`) || `Lot (${numberFormat(med.qtyExpireDate || 0, 0, 0)})`}
                          </Typography>
                          <InventoryChip label={`${t('TREATMENT_PRODUCT.CARD.QUANTITY')}: ${numberFormat(med.qty, 0, 0)}`} limit={med.qty <= med.minStock} className="ml-2 p-0" />
                        </Box>
                        ||
                        <Box sx={{ height: '22px' }}>
                        </Box>
                      }
                    </Box>
                  </ProductCard>
                </ProductBox>
              )
            })) || (
              <Typography className="w-100 text-center" sx={{ color: colors.disabledGray, paddingTop: '48px' }}>
                {t('TREATMENT_PRODUCT.TITLE.EMPTY_MEDICINE')}
              </Typography>
            )}
        </ProductItem>
      )}
      {(props.medShowType === '1' || branch_?.medShowType === '1') && (
        <ResponsiveTxTable className="mt-2">
          <TableCustom
            page={page}
            pageLimit={pageLimit}
            setPageLimit={handleChangeRowsPerPage}
            setPage={handleChangePage}
            rowCount={rowCount}
            headCells={headCells}
            rowsData={medicines.map((val, i) => {
              return renderData(val, i)
            })}
          />
        </ResponsiveTxTable>
      )}

      {isShowReMed &&
        <ReMed
          isShow={isShowReMed}
          setIsShow={(show: boolean) => setIsShowReMed(show)}
          patientId={props.patient.patientId}
          branchId={branchId}
          patient={props.patient}
          type={'treatment'}
          treatments={treatments}
          user={userId}
          countMed={rowCount}
        />}

      {popupProductOutOfStock.name && <AlertProductOutOfStock type="medicine" name={popupProductOutOfStock.name} code={popupProductOutOfStock.code} qty={popupProductOutOfStock.qty} unit={popupProductOutOfStock.unit} />}

      {isShowPopupProduct ? (
        <PopupManageProduct
          page={props.type}
          group="MEDICINE"
          type="thumbnail"
          item={popupManageProduct}
          patientCaseId={props.patientCaseId}
          onCancel={onCancelPopupManageProduct}
          onSubmit={onCancelPopupManageProduct}
          patientPhone={props.patient.phone}
          patientName={`${props.patient.prefix.prefixName}${props.patient.firstname} ${props.patient.lastname}`}
          hasChangeProductPrice={props.hasChangeProductPrice}
          hasChangeProductDiscountAdditional={props.hasChangeProductDiscountAdditional}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
