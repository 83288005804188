import { Box, Grid, IconButton, MenuItem, Typography } from "@mui/material"
import HeaderLabel from "component/CardCustom/HeaderLabel"
import { Card, Col, Dropdown, Row } from "react-bootstrap"
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import moment from "moment"
import $ from 'jquery'

/** TRANSLATION */
import { useTranslation } from "react-i18next"

/** STYLE */
import { colors } from "constants/theme"
import * as xrayUseStyle from "component/Xray/style"

/** API */
import XrayFilmTypeApi from "api/setting/treatments/xray-film-type.api"
import UploadTreatmentApi from "api/master/uploadTreatment.api"
import TreatmentsAPI from 'api/dentists/treatment.api'
import xrayApi from "api/xray.api"
import { fileUrl } from "api/api"

/** COMPONENT */
import SearchPatients from "component/SearchPatients"
import BasicSelect from "component/Select/BasicSelect"
import InputTextarea from "component/Input/InputTextarea"
import PreviewImage from "component/Image/PreviewImage"
import { Icons, icons, imgs, onImgError } from "constants/images"
import { notiError, notiPopup, notiSuccess } from "component/notifications/notifications"
import InputCheckbox from "component/Input/InputCheckbox"
import printIcon from 'assets/icon/carlendar/print.svg'
import PdfXray from "features/pdf/pdf-xray"
import FucntionPrint from "component/Print/print"
import { dateTimeToView } from "utils/date.utils"
import useWindowSize from "utils/useWindowSize"
import ModalCustom from "component/ModalCustom/ModalCustom"
import * as modalSlice from "app/slice/modal.slice"
import { useDispatch } from "react-redux"


type ListXray = {
    updateSuccess?: () => void
}
type NewImageType = {
    xRayFileId: any
    base64: string
    type: string
    fileExtension: string
    fileSize: string
    fileName: string
    createdAt: any
}

type PreviewImageProps = {
    createdAt?: string
    fileName?: string
    filePath?: string
    fullPath?: string
    refFile?: string
    refId?: string
    xRayFileId?: number
    base64?: string
    fileExtension?: string
    remark?: string
}

type PatientHistory = {
    data: {
        patientGalleryId?: number
        patientId?: number
        cnNumber?: string
        branchCnNumber?: string
        vnNumber?: string
        date?: string
        galleryNumber?: string
        xrayFilmTypeName?: string
        xrayFilmTypeId?: number
        patientFullName?: string
        countXRay?: number
        updatedBy?: string
        updatedAt?: string
        createdAt?: string
    }
    routeName: string
    text: string
    isCreate: boolean | false
    isEdit: boolean | false
    isPrint: boolean | false
}

const initStateErrorMessage = {
    PATIENT: '',
    VN_NUMBER: '',
    XRAY_FILM_TYPE_ID: ''
}

export default function ListXray(props: ListXray) {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const componentRef = useRef<HTMLDivElement>(null)
    const [xrayFilmTypeId, setXrayFilmTypeId] = useState(0)
    const [xrayFilmTypes, setXrayFilmTypes] = useState<any[]>([])
    const [images, setImages] = useState<NewImageType[]>([])
    const [previewImage, setPreviewImage] = useState<PreviewImageProps>({})
    const [xrayImage, setXrayImage] = useState([])
    const [patientGalleryId, setPatientGalleryId] = useState('')
    const [remark, setRemark] = useState('')
    const [patientId, setPatientId] = useState(0)
    const [visits, setVisits] = useState([])
    const [vnNumber, setVnNumber] = useState<any>('')
    const [prints, setPrints] = useState(false)
    const [print, setPrint] = useState<any[]>([])
    const [errorMessage, setErrorMessage] = useState({ ...initStateErrorMessage })
    const [loading, setLoading] = useState(false)
    const [locationState, setLocationState] = useState<PatientHistory>()
    const [patient, setPatient]: any = useState([])
    const [router, setRouter] = useState('')
    const [text, setText] = useState('')
    const [edit, setEdit] = useState(false)
    const [create, setCreate] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        const locationState: any = location.state
        if (!_.isEmpty(locationState)) {
            setPatientId(locationState.data?.patientId || locationState?.patientId || 0)
            setVnNumber(locationState.data?.vnNumber || locationState?.vnNumber || '')
            setXrayFilmTypeId(locationState.data?.xrayFilmTypeId)
            setPatientGalleryId(locationState.data?.patientGalleryId)
            setLocationState(locationState || {})
            setRouter(locationState.routeName)
            setText(locationState.text)
            setPrints(locationState.isPrint)
            setEdit(locationState.isEdit)
            setCreate(locationState.isCreate)
        }

    }, [location])

    const loadFilter = useCallback(async () => {

        const condition: any = {
            page: 1,
            pageLimit: 50,
            sortBy: 'createAt',
            sortType: 'ASC',
            status: 'ACTIVE'
        }

        const res = await XrayFilmTypeApi.findAll(condition)
        if (res.status === 200) {
            setXrayFilmTypes(res.data)
        }
    }, [])

    const loadVisit = async (id: number) => {
        const resVisit = await UploadTreatmentApi.findTreatmentHistory(id)
        if (resVisit.status === 200 && !_.isEmpty(resVisit.data)) setVisits(resVisit.data)
        else setVisits([])
    }

    const loadPatient = async () => {
        if (patientId) {
            const patientData = await TreatmentsAPI.findPatient(patientId)
            if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
                setPatient(patientData.data)
            } else setPatient([])
        }
    }

    const loadGallery = async () => {
        const condition: any = {}
        const resGallery = await xrayApi.findXrayPatientGallery(String(patientGalleryId), condition)
        if (resGallery.status === 200 && !_.isEmpty(resGallery.data)) {
            setXrayImage(resGallery.data.xrayImages)
            setPreviewImage(resGallery.data.xrayImages && resGallery.data.xrayImages.length ? resGallery.data.xrayImages[0] : {})
            setRemark(resGallery.data.xrayImages && resGallery.data.xrayImages.length ? resGallery.data.xrayImages[0].remark : '')
        } else {
            setXrayImage([])
            setPreviewImage({})
            setRemark('')
        }
    }

    useEffect(() => {
        if (patientId) loadVisit(patientId)
        loadPatient()
    }, [patientId])

    useEffect(() => {
        if (patientGalleryId) loadGallery()
    }, [patientGalleryId]);

    useEffect(() => {
        loadFilter()
    }, [location])


    const getPatientId = (id: number) => {
        setPatientId(id)
        clearErrorMessage()
    }

    const handleChangeVn = (vn: any) => {
        setVnNumber(vn)
        clearErrorMessage()
    }

    const handleChangeXrayFilmType = (filmTypeId: any) => {
        setXrayFilmTypeId(filmTypeId)
        clearErrorMessage()
    }
    const clearErrorMessage = () => {
        setErrorMessage(initStateErrorMessage)
    }

    const onSetRemark = async (e: any) => {
        setRemark(e.target.value)
        const val = xrayImage.findIndex(((obj: any) => obj.xRayFileId === previewImage.xRayFileId));
        const val1 = images.findIndex(((obj: any) => obj.xRayFileId === previewImage.xRayFileId));
        if (val >= 0) {
            const data: any = _.map(xrayImage, (obj: any) => {
                if (obj.xRayFileId === previewImage.xRayFileId) {
                    return { ...obj, remark: e.target.value };
                } else {
                    return obj;
                }

            });
            setXrayImage(data);
        } else if (val1 >= 0) {
            const data: any = _.map(images, (obj: any) => {
                if (obj.xRayFileId === previewImage.xRayFileId) {
                    return { ...obj, remark: e.target.value };
                } else {
                    return obj;
                }
            });
            setImages(data);
        }
    }

    const onChangeFiles = async (e: any) => {
        notiPopup('clear')
        if (!_.isEmpty(e.target.files)) {
            let files: any = []
            _.map(e.target.files, (file) => {
                if (!_.some(images, (img: ImageType) => `${img.fileName}.${img.fileExtension}` === `${file.name}`)) {
                    if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                        files = [...files, renderBaseFile(file)]
                    } else {
                        notiPopup('warning', t(`REGISTER.MESSAGE.UPLOAD_TREATMENT.NOT_PIC`))
                    }
                } else {
                    notiPopup('warning', t(`REGISTER.MESSAGE.UPLOAD_TREATMENT.ERROR_FILE`, {
                        file_name: file.name
                    }))
                }
            })
            const newImages = await Promise.all(files)
            setImages([...images, ...newImages])
            e.target.value = null
        }
    }

    const renderBaseFile = (file: any) => {
        return new Promise<NewImageType>((resolve, reject) => {
            const reader: any = new FileReader();
            reader.onload = () => {
                const base64 = reader.result.split(',')
                resolve({
                    xRayFileId: Number(images.length + 1),
                    base64: base64.length > 0 ? base64[base64.length - 1] : reader.result,
                    type: '',
                    fileExtension: _.last(file.name.split('.')) || '',
                    fileSize: file.size.toString(),
                    fileName: _.first(file.name.split('.')) || '',
                    createdAt: moment().format('YYYY-MM-DD')
                })
            };
            reader.onerror = reject;
            reader.readAsDataURL(file)
        })
    }

    const handlePreImageAfterDel = (type: string, gallery: any, indexPreview: number) => {
        const newImage = gallery[indexPreview] ? gallery[indexPreview] : gallery[indexPreview - 1]
        if (type === 'gallery') {
            const preImage = newImage ? newImage : images[0]
            setPreviewImage(preImage || {})

        } else {
            const preImage = newImage ? newImage : xrayImage[xrayImage.length - 1]
            setPreviewImage(preImage || {})
        }
    }

    const onDelete = (item: any) => {
        if (item.name === previewImage.fileName) setPreviewImage({})
        if (item.type === 'gallery') {
            let indexPreview = 0
            const gallery: any = _.filter(xrayImage, (image: any, index: number) => {
                if (image.xRayFileId === item.val) {
                    indexPreview = index
                }
                return image.xRayFileId !== item.val
            })
            setXrayImage(gallery)

            handlePreImageAfterDel(item.type, gallery, indexPreview)

        } else {
            let indexPreview = 0
            const gallery: any = _.filter(images, (image: any, index: number) => {
                if (index === item.val) {
                    indexPreview = index
                }
                return index !== item.val
            })
            setImages(gallery)
            handlePreImageAfterDel(item.type, gallery, indexPreview)

        }
    }

    const onCheck = (event: any) => {
        const { checked, value } = event.target
        if (checked) {
            setPrint([...print, String(value)])
        } else {
            setPrint(
                _.filter(print, (i: string) => {
                    return i !== String(value)
                })
            )
        }
    }

    const onSubmit = async () => {
        setLoading(true)
        if (!patientId || !vnNumber || !xrayFilmTypeId) {
            const msg: any = {
                PATIENT: !patientId ? t(`LAB_ORDER.MESSAGE.PATIENT`) : '',
                VN_NUMBER: !vnNumber ? t(`LAB_ORDER.MESSAGE.VN_NUMBER`) : '',
                XRAY_FILM_TYPE_ID: !xrayFilmTypeId ? t(`XRAY.MESSAGE.XRAY_FILM_TYPE_ID`) : '',
            }
            setLoading(false)
            setErrorMessage({ ...initStateErrorMessage, ...msg })
            return false
        }
        const patientTreatmentImage: any = []
        _.map([...xrayImage, ...images], (i: any, index: number) => {
            patientTreatmentImage.push({ xRayFileId: i.base64 ? null : i.xRayFileId, base64: i.base64 || '', type: i.type || '', fileExtension: i.fileExtension || '', fileSize: i.fileSize || '', remark: i.remark || '' })
        })
        const updateXray = {
            patientGalleryId: patientGalleryId || 0,
            patientId: patientId || 0,
            vnNumber: vnNumber,
            imageTypeId: xrayFilmTypeId,
            xrayImages: patientTreatmentImage
        }
        await xrayApi.updateXray(updateXray).then((res: any) => {
            if (res.status === 201) {
                notiSuccess(t('XRAY.MESSAGE.SUCCESS.DEFAULT'))
                history.goBack()
                discardChange()
                setVnNumber('')
                props.updateSuccess && props.updateSuccess()
                goBack()
            } else {
                const err = res.response?.data
                notiError(t(`${err.message}`))
            }
        })
            .catch((e) => {
                const err = e.response.data
                notiError(t(`${err.message}`))
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const discardChange = () => {
        loadGallery()
        setImages([])
        setPreviewImage({})
    }

    useEffect(() => {
        if (previewImage.remark) setRemark(previewImage?.remark)
        else setRemark('')

    }, [previewImage]);

    const goBack = () => {
        dispatch(modalSlice.setXrayPage(true))
        dispatch(modalSlice.setModal_xray(false))
        const state: any = location?.state
        const val = {
            patientId: state?.patientId,
            servicePointId: state?.servicePointId
        }
        history.push(router, val)
    }
    return (
        <Modal_Xray>
            <div className={`h-100 container-card overflow-hidden`} style={{ backgroundColor: colors.white }}>
                <div className='h-100 d-flex flex-column'>
                    <HeaderLabel text={text} disabledSend={loading} navigator={{ previousTo: router, previousTitle: router === '/x-ray' ? t('XRAY.NAVIGATOR.XRAY_LIST') : t('XRAY.NAVIGATOR.TREATMENT'), currentTitle: text }}
                        goBack={router} submitText={t('XRAY.SAVE')} unDisplayButton={!edit && !create && !prints}
                        componentBtnCustom={
                            <>
                                {prints &&
                                    <div className="d-flex">
                                        <div onClick={() => goBack()} className={`p-0 w-auto`} style={{ textDecoration: 'none' }}>
                                            <xrayUseStyle.BtnCancel>{t('BUTTON.CANCEL')}</xrayUseStyle.BtnCancel>
                                        </div>
                                        <FucntionPrint
                                            content={() => componentRef.current}
                                            docTitle="Xray"
                                            removeIfram
                                            trigger={() => (<xrayUseStyle.BtnConfirm disabled={print.length === 0}>
                                                {t('BUTTON.PRINT')}
                                            </xrayUseStyle.BtnConfirm>)}
                                            style={'@page { size:  793.7007874px 1122.519685px;  margin: 1cm; } @media print { body { -webkit-print-color-adjust: exact; } '}
                                        />
                                    </div>}
                                {(edit || create) &&
                                    <div className="d-flex">
                                        {/* <Link to={router} className={` p-0 w-auto`} style={{ textDecoration: 'none' }}>
                                            <xrayUseStyle.BtnCancel fullWidth >
                                                {t('BUTTON.CANCEL')}
                                            </xrayUseStyle.BtnCancel>
                                        </Link> */}
                                        <div onClick={() => goBack()} className={` p-0 w-auto`} style={{ textDecoration: 'none' }}>
                                            <xrayUseStyle.BtnCancel fullWidth >
                                                {t('BUTTON.CANCEL')}
                                            </xrayUseStyle.BtnCancel>
                                        </div>
                                        <xrayUseStyle.BtnConfirm disabled={images.length === 0 && xrayImage.length === 0} fullWidth className={'btn-submit'} onClick={onSubmit}>
                                            {t('BUTTON.SAVE')}
                                        </xrayUseStyle.BtnConfirm>
                                    </div>
                                }
                            </>
                        } />
                    <Card className="border shadow-sm mx-4 mb-4 h-100 overflow-hidden">
                        <Card.Body className="h-100 overflow-hidden py-0">
                            <Grid container spacing={2} className=" pl-lg-0 mt-0 h-100 flex-nowrap ">
                                <Grid item lg={2} xs={2} sm={2} className="p-3 w-50 overflow-hidden" sx={{ borderRight: { lg: `1px solid ${colors.lightGray}` }, maxHeight: '100%' }}>
                                    <>
                                        <ComponentGallery
                                            gallery={xrayImage}
                                            images={images}
                                            onChangeFiles={(e: any) => onChangeFiles(e)}
                                            onDelete={onDelete}
                                            onCheck={onCheck}
                                            setPreview={(image: PreviewImageProps) => {
                                                setPreviewImage(image)
                                            }
                                            }
                                            active={previewImage?.fileName}
                                            previewImage={previewImage}
                                            // preview={preview}
                                            prints={prints}
                                            rowCheck={print}
                                            create={create}
                                            edit={edit}
                                        />
                                    </>
                                </Grid>

                                <Grid item lg={10} xs={10} sm={10} className={'pl-0 pl-lg-3 py-3 w-100 h-100 overflow-hidden d-flex flex-column'}>
                                    <Row className={`pl-1 pr-1 pt-2`}>
                                        <Col xs={12} sm={6} lg={6} className="mb-3 px-2">
                                            <SearchPatients bodyHeight={43} callbackPatientId={getPatientId}
                                                status="ACTIVE"
                                                patientId={patientId}
                                                patient={patient}
                                                search={patientId}
                                                disabled={locationState?.data?.patientId ? true : false}
                                                onClear={() => getPatientId(0)}
                                                helperText={errorMessage.PATIENT || ''} />
                                        </Col>
                                        <Col xs={12} sm={2} lg={2} className={`px-2`}>
                                            <BasicSelect
                                                required={true}
                                                label={t('VN')}
                                                value={vnNumber}
                                                onchange={(event: any) => handleChangeVn(event?.target.value || '')}
                                                selectId="VN"
                                                labelId="VN"
                                                options={visits.map((visit: any, i: number) => (<MenuItem key={i} value={visit.vnNumber}>{visit.vnNumber}</MenuItem>))}
                                                helperText={errorMessage.VN_NUMBER || ''}
                                                disabled={locationState?.data?.vnNumber ? true : false}
                                            />
                                        </Col>
                                        <Col xs={12} sm={2} lg={2} className={`px-2`}>
                                            <BasicSelect
                                                onchange={(event) => {
                                                    const value = event.target.value
                                                    if (value) {
                                                        handleChangeXrayFilmType(value)
                                                    }
                                                }}
                                                required
                                                renderValue={() => xrayFilmTypes.find((p) => p.xrayFilmTypeId === xrayFilmTypeId)?.xrayFilmTypeName}
                                                label={t('XRAY.TITLE.ALL_TYPE')}
                                                selectId="select-status"
                                                value={xrayFilmTypeId}
                                                labelId="label-status"
                                                options={[
                                                    _.map(xrayFilmTypes, (filmType: any, i: number) => (
                                                        <MenuItem key={i} value={filmType.xrayFilmTypeId}>
                                                            {filmType.xrayFilmTypeName}
                                                        </MenuItem>
                                                    ))
                                                ]}
                                                disabled={!edit && !create}
                                                helperText={errorMessage.XRAY_FILM_TYPE_ID || ''}
                                            />
                                        </Col>
                                        {(!edit && !create && !prints) && <Col className="mr">
                                            <Dropdown style={{ textAlign: 'end' }}>
                                                <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="btn-table-action text-black-50 shadow-none">
                                                    <Icons.Meatball />
                                                </Dropdown.Toggle>
                                                <xrayUseStyle.DropdownMenu className="dropdown-table-action position-fixed">
                                                    {/* <xrayUseStyle.DropdownItem onClick={() => setEdit(true)} >
                                                        <img src={icons.dropdownEdit} alt="" className="align-baseline" />
                                                        <span className="ml-2 align-text-bottom">แก้ไข</span>
                                                    </xrayUseStyle.DropdownItem> */}
                                                    <xrayUseStyle.DropdownItem onClick={() => setPrints(true)}>
                                                        <xrayUseStyle.ImageLabel src={printIcon} className="align-baseline" />
                                                        <span className="align-text-bottom">{t('XRAY.BUTTON.PRINT')}</span>
                                                    </xrayUseStyle.DropdownItem>
                                                </xrayUseStyle.DropdownMenu>
                                            </Dropdown>
                                        </Col>}
                                    </Row>
                                    <xrayUseStyle.PreviewImage className="d-flex flex-column pt-2" style={{ height: '55vh' }} >
                                        <PreviewImage image={previewImage} />
                                    </xrayUseStyle.PreviewImage>
                                    <Box className="pt-3" sx={{
                                        '.MuiOutlinedInput-root': {
                                            'textarea': {
                                                paddingTop: "5px"
                                            }
                                        }
                                    }}>
                                        {(!edit && !create && !prints) && <Typography style={{ height: '70px' }}><span style={{ paddingRight: 8 }}>{t('XRAY.TITLE.REMARK')}:</span>{remark ? remark : '-'} </Typography> ||
                                            <InputTextarea
                                                hookValue={true}
                                                inputProps={{ maxLength: 255 }}
                                                name="recommed"
                                                key={'recommed'}
                                                value={remark}
                                                label={t('XRAY.TITLE.REMARK')}
                                                height={90}
                                                size={"small"}
                                                onchange={(e: any) => onSetRemark(e)}
                                                disabled={!edit && !create}
                                            />
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card.Body>
                    </Card>
                    <div ref={componentRef} className="print-show" >
                        <PdfXray img={print} />
                    </div>
                </div>
            </div >
        </Modal_Xray>
    )
}

type ComponentGalleryProps = {
    onChangeFiles?: (e: any) => void
    gallery?: any
    images?: any
    onDelete?: (val: any) => void
    onCheck?: (val: any) => void
    setPreview?: any
    active?: string
    previewImage?: any
    // preview?: boolean | false
    prints?: boolean | false
    edit?: boolean | false
    create?: boolean | false
    rowCheck?: any
}

type ImageType = {
    xRayFileId: any
    base64: string
    type: string
    fileExtension: string
    fileSize: string
    fileName: string
    createdAt: string
}

function ComponentGallery(props: ComponentGalleryProps) {
    const { t } = useTranslation()
    const { gallery, images, onChangeFiles, active, prints, rowCheck, create, edit } = props
    const [width] = useWindowSize()
    const [rowChecks, setRowCheck] = useState<any>()

    const renderImage = (img: any) => {
        if (img?.thumbnailFile?.thumbmailFullpath) return `${fileUrl}/${img.thumbnailFile.thumbmailFullpath}`
        else if (img?.thumbmailFullpath) return `${fileUrl}/${img.thumbmailFullpath}`
        else return imgs.errNoImg
    }

    useEffect(() => {
        if (images.length >= 1 && gallery.length === 0) props.setPreview(images[0])
    }, [images])

    useEffect(() => {
        setRowCheck(rowCheck)
    }, [rowCheck])

    const openImg = (img: any) => {
        window.open(`${fileUrl}/${img.fullPath}`, '_blank')
    }

    const openImgOnInsert = (img: any) => {
        if (img) {
            const w = window.open('_blank');
            if (w) {
                w.document.write('<body style="margin: 0px"></body>')
                w.document.write(
                    `<div style="background-color:black;text-align:center;height:100%">
                    <img style="display:block; margin:auto; height:100%;" src=${"data:image/jpg;base64," + img.base64}></img>
                    </div>`
                )
            }
        }
    }

    return (
        <div className="overflow-auto custom-scroll" style={{ maxHeight: '100%' }}>
            {((create || edit) && <xrayUseStyle.ColumnPanel>
                <xrayUseStyle.CardImg style={{ paddingTop: '100%' }}>
                    <xrayUseStyle.DivUpload style={{ width: '100%', height: '100%' }}
                        onClick={() => {
                            $('#xrayImg').click()
                        }}
                        className={'d-flex align-items-center justify-content-center'}
                    >
                        <input id="xrayImg" type="file" accept='image/*' multiple onChange={onChangeFiles} className={'d-none'} />
                        <div className={'upload-image text-center'}>
                            <img src={imgs.defaultAddImage} alt="uploadImage" style={{ margin: 'auto', border: 'unset' }} />
                            <p className={'pt-1 pt-sm-2 m-0'}>{t('REGISTER.UPLOAD_TREATMENT.UPLOAD_IMAGE')}</p>
                        </div>
                    </xrayUseStyle.DivUpload>
                </xrayUseStyle.CardImg>
            </xrayUseStyle.ColumnPanel>)}
            {
                _.map(gallery, (img: any, index: number) => {
                    return (
                        <xrayUseStyle.XrayBox key={`val-${index}`} className='pt-2 pb-2'>
                            <xrayUseStyle.XrayCard className={`${active === img.fileName ? 'active' : ''}`}>
                                <Box className='card-image'>
                                    <Box className='position-relative'>
                                        <img src={renderImage(img)} alt={img.fileName} onError={onImgError}
                                            onClick={() => {
                                                props.setPreview(img)
                                            }} />
                                    </Box>
                                    <xrayUseStyle.CardImg>
                                        {(edit || create) && <IconButton
                                            className={'btn-delete'}
                                            aria-label="delete"
                                            onClick={() => {
                                                if (props?.onDelete) props?.onDelete({ type: 'gallery', val: img.xRayFileId, name: img.fileName })
                                            }}
                                            sx={{ backgroundColor: 'transparent !important', padding: 0 }}
                                        >
                                            <img src={icons.btnTrash} alt="trash" />
                                        </IconButton>}
                                        {prints &&
                                            <InputCheckbox
                                                onChange={props.onCheck}
                                                value={img.fullPath}
                                                checked={rowChecks !== undefined && rowChecks.includes(img.fullPath) ? true : false}
                                                className="hover-none-bg btn-select"
                                            />
                                        }
                                    </xrayUseStyle.CardImg>
                                </Box>
                                <Box className='card-detail'>
                                    {width > 1024 && <Row>
                                        <Col xs={6} sm={6} lg={6}>
                                            {dateTimeToView(img.createdAt)}
                                        </Col>
                                        <Col xs={6} sm={6} lg={6} style={{ textAlign: 'end' }}>
                                            <img src={icons.previewImg} alt="trash" onClick={() => openImg(img)} />
                                        </Col>
                                    </Row> ||
                                        <Row>
                                            <Col xs={12} sm={12} lg={12} style={{ textAlign: 'end' }}>
                                                <img src={icons.previewImg} alt="trash" onClick={() => openImg(img)} />
                                            </Col>
                                            <Col xs={12} sm={12} lg={12} style={{ fontSize: 13 }}>
                                                {dateTimeToView(img.createdAt)}
                                            </Col>
                                        </Row>}
                                </Box>
                            </xrayUseStyle.XrayCard>
                        </xrayUseStyle.XrayBox>
                    )
                })
            }
            {
                _.map(images, (img: ImageType, index: number) => {
                    return (
                        <xrayUseStyle.XrayBox key={`val-${index}`}>
                            <xrayUseStyle.XrayCard className={`${active === img.fileName ? 'active' : ''}`}>
                                <Box className='card-image'>
                                    <Box className='position-relative'>
                                        <img src={`data:image/${img.fileExtension};base64,${img.base64}`} alt={img.fileName}
                                            onClick={() => {
                                                props.setPreview(img)
                                            }} />
                                    </Box>
                                    <xrayUseStyle.CardImg>
                                        {(edit || create) && <IconButton
                                            className={'btn-delete'}
                                            aria-label="delete"
                                            onClick={() => {
                                                if (props?.onDelete) props?.onDelete({ type: 'upload', val: index, name: img.fileName })
                                            }}
                                            sx={{ backgroundColor: 'transparent !important', padding: 0 }}
                                        >
                                            <img src={icons.btnTrash} alt="trash" />
                                        </IconButton>}
                                    </xrayUseStyle.CardImg>
                                </Box>
                                <Box className='card-detail'>
                                    <Row>
                                        <Col xs={6} sm={6} lg={6}>
                                            {dateTimeToView()}
                                        </Col>
                                        <Col xs={6} sm={6} lg={6} style={{ textAlign: 'end' }}>
                                            <img src={icons.previewImg} alt="trash" onClick={() => openImgOnInsert(img)} />
                                        </Col>
                                    </Row>
                                </Box>
                            </xrayUseStyle.XrayCard>
                        </xrayUseStyle.XrayBox>
                    )
                })
            }
        </div >
    )
}
type ModalProps = {
    children: React.ReactNode;
};

function Modal_Xray(props: ModalProps) {
    const location = useLocation<any>()
    const { t } = useTranslation()

    if (location?.state?.text === t('XRAY.PATH.PREVIEW')) {
        return (
            <ModalCustom
                size="xl"
                modalStyle="modal-xray"
                modalTheme="default"
                title={t('XRAY.PATH.PREVIEW')}
                fullscreen
                closeButton
                component={props.children}
            />
        )
    } else {
        return (
            <div>{props.children}</div>
        )
    }

}