import { createRef, useLayoutEffect, useState } from 'react'

type PropsTextEllipsis = {
  title?: string
  ellipsis?: boolean
}

export default function TextEllipsis(props: PropsTextEllipsis) {
  const ref = createRef<HTMLInputElement>()
  const [ellipsis, setEllipsis] = useState(false)

  useLayoutEffect(() => {
    const width = ref?.current?.clientWidth
    const scrollWidth = ref?.current?.scrollWidth
    if (width && scrollWidth && width < scrollWidth) setEllipsis(true)
    else setEllipsis(false)
  }, [ref])

  return (
    ((ellipsis || props.ellipsis) && (
      <span ref={ref} className={'d-block text-ellipsis customTooltip-sidebar'} data-title={props.title}>
        {props.title}
      </span>
    )) || (
      <span ref={ref} className={'d-block text-ellipsis'}>
        {props.title}
      </span>
    )
  )
}
