import { fileUrl } from "api/api"
import _ from 'lodash'

type ImgType = {
    img?: any,
    staicImg?: any,
}

export default function PdfXray(props: ImgType) {

    const renderImage = (img: any) => {
        return `${fileUrl}/${img}`
    }

    return (
        <div>
            {props.img && _.map(props.img, (img: any) => {
                return (
                    <div className="pt-2 print-show " style={{ width: 793.7007874, height: 500, margin: 'auto' }}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%', alignItems: 'center', margin: 'auto', display: 'block' }} src={renderImage(img)} />
                    </div>
                )
            })}
            {props.staicImg && _.map(props.staicImg, (img: any) => {
                return (
                    <div className="pt-2 print-show " style={{ width: 793.7007874, height: 500, margin: 'auto' }}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%', alignItems: 'center', margin: 'auto', display: 'block' }} src={img} />
                    </div>
                )
            })}
        </div>
    )
}

