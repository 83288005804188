import moment from 'moment'
import { Box, IconButton, Typography } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

/** UTILS || SLICE */
import { toBuddhistYear } from 'utils/app.utils'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'

import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const DateDisplaySelect = styled(Box)(({ theme }) => ({
  color: colors.textPrimary,
  '.text-date': {
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  }
}))

const ArrowButton = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  color: colors.textPrimary,
  svg: {
    fontSize: 18
  }
}))

const ButtonPicker = styled(Box)(({ theme }) => ({
  paddingTop: '2px',
  '.ant-picker': {
    padding: 0,
    width: 35,
    height: 35,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,

    '.ant-picker-input': {
      width: 'auto'
    }
  },
  '&:hover': {
    '.ant-picker': {
      backgroundColor: `${colors.black05} !important`
    }
  }
}))

type SelectDateProps = {
  setDate?: (date: string) => void
}


export default function SelectDate(props: SelectDateProps) {
  const { i18n } = useTranslation()
  // const date = moment()

  const [date, setDate] = useState(moment())

  //** FUNCTION EVENT DATE */
  const handleClickPrevious = () => {
    setDate(moment(date).subtract(1, 'days'))
  }
  const handleClickNext = () => {
    setDate(moment(date).add(1, 'days'))
  }

  useEffect(() => {
    if (props.setDate) props.setDate(moment(date).format('YYYY-MM-DD'))
  }, [date])


  return (
    <DateDisplaySelect className={`d-flex align-items-center`}>
      {/* <Typography className="text-date text-nowrap d-md-none">{toBuddhistYear(date ? moment(date) : moment(), 'DD MMMM YYYY')}</Typography>
      <Typography className="text-date text-nowrap d-none d-md-block">{toBuddhistYear(date ? moment(date) : moment(), `${t('TIME_DURATION_TYPE.DAY')}dddd${t('DAY_AT')} D MMMM YYYY`)}</Typography> */}
      <ArrowButton>
        <ArrowBackIosNewIcon onClick={handleClickPrevious} />
      </ArrowButton>
      <Typography className="text-date text-nowrap">{toBuddhistYear(moment(date).locale(i18n.language), 'DD MMMM YYYY')}</Typography>
      <ArrowButton>
        <ArrowForwardIosIcon onClick={handleClickNext} />
      </ArrowButton>
      <ButtonPicker>
        {/* <InputDatePicker disableTextField value={moment(date).format('YYYY-MM-DD')} onChange={(e: any) => setDate(e)} /> */}
        <InputSecondNewDatePicker disabledTextField value={moment(date).format('YYYY-MM-DD')} onchange={(e: any) => setDate(e)} />
      </ButtonPicker>
    </DateDisplaySelect>
  )
}
