import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function Lab(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g data-name="Group 8643">
        <path
          data-name="Path 6266"
          d="M48.435 193.441v1.7l1.026 1.16v5.355l-3.169 8.122-4.463 9.818A2.9 2.9 0 0 0 45 222.582c3.258-.045 16.11 0 16.11 0s2.945-1.16 2.365-2.633S56 202.054 56 202.054v-6.114s2.03-2.544.691-2.5-8.256.001-8.256.001z"
          transform="translate(-36.354 -192)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 5722"
          d="M22.414 25.992 15.7 10.529a6.718 6.718 0 0 1-.561-2.69V3.751a1.593 1.593 0 0 1 .511-1.165 1.942 1.942 0 0 0 .623-1.421V.586A.6.6 0 0 0 15.652 0h-8.66a.6.6 0 0 0-.62.586v.579a1.942 1.942 0 0 0 .623 1.421 1.593 1.593 0 0 1 .511 1.165v4.088a6.714 6.714 0 0 1-.561 2.69L5.1 14.778.229 25.992a2.765 2.765 0 0 0 .291 2.721A3.122 3.122 0 0 0 3.075 30h16.494a3.123 3.123 0 0 0 2.554-1.287 2.765 2.765 0 0 0 .291-2.721zM8.092 10.973a7.824 7.824 0 0 0 .654-3.135V3.751a2.725 2.725 0 0 0-.874-1.994.81.81 0 0 1-.26-.585h7.419a.81.81 0 0 1-.26.585 2.726 2.726 0 0 0-.871 1.994v4.087a7.828 7.828 0 0 0 .654 3.135l1.5 3.448H6.595zm13 17.087a1.839 1.839 0 0 1-1.524.768H3.075a1.839 1.839 0 0 1-1.524-.768 1.632 1.632 0 0 1-.293-1.217h1.021a.587.587 0 1 0 0-1.172H1.71l.712-1.641h1.046a.587.587 0 1 0 0-1.172h-.537l.712-1.641h1.076a.587.587 0 1 0 0-1.172h-.567l.713-1.641h1.1a.587.587 0 1 0 0-1.172h-.6l.713-1.641h10.48l4.709 10.843a1.629 1.629 0 0 1-.174 1.626zm0 0"
          transform="translate(5.002 1)"
          style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: '.5px' }}
        />
        <path data-name="Path 5723" d="M293.968 438.385a.62.62 0 0 0-1.148.47v.008a.62.62 0 1 0 1.148-.47zm0 0" transform="translate(-269.615 -411.588)" style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: '.5px' }} />
        <path data-name="Path 5724" d="m246.248 322.063-2.221-5.414a.62.62 0 1 0-1.148.471l2.221 5.414a.62.62 0 0 0 1.148-.471zm0 0" transform="translate(-222.771 -297.02)" style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: '.5px' }} />
      </g>
      <path data-name="Rectangle 3864" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
    </svg>
  )
}
