import { Box, Collapse } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputCheckbox from 'component/Input/InputCheckbox'
import { colors } from 'constants/theme'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BoxFormCollapse, BoxInfoTemplaceDoc, BoxUse } from './style'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { useDispatch } from 'react-redux'
import { showModal } from 'app/slice/modal.slice'


export type FormTemplaceDocDentisInfo = {
    docName: string
    checked: boolean
    onChange: () => void
    example: string | string[]
    page: string[]
    docMulti?: {
        docName: string
        example: string | string[]
        page: string[]
    }[]
}

export type TemplaceDocInfo = {
    title: string
    info: FormTemplaceDocDentisInfo[]
}

type FormTemplaceDocDentistProps = {
    templaceDoc: TemplaceDocInfo[]
}

const FormTemplaceDocDentist = (props: FormTemplaceDocDentistProps) => {
    const { templaceDoc } = props

    const { t } = useTranslation()

    return (
        <Box sx={{ margin: '24px 0' }}>
            {templaceDoc && templaceDoc.map((info, idx: number) => {
                return (<CollapseFormTemplaceDocDentist key={idx} info={info} />)
            })}
        </Box>
    )
}

export default FormTemplaceDocDentist

type CollapseFormTemplaceDocDentistProps = {
    info: TemplaceDocInfo
}

export type ModalExamDocTitle = {
    title: string,
    page: string[],
    example: string | string[]
}

const CollapseFormTemplaceDocDentist = (props: CollapseFormTemplaceDocDentistProps) => {
    const { info } = props

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [open, setOpen] = useState<boolean>(true)

    const [modalImg, setModalImg] = useState<boolean>(false)
    const [modalTitle, setModalTitle] = useState<ModalExamDocTitle>({ title: '', page: [], example: '' })


    return (
        <Box>
            <BoxFormCollapse >
                <Box>
                    {t(`BRANCH.INPUT.${info.title}`)}
                </Box>
                <Box
                    className={`box-icon-expand-more`}
                    sx={{ transform: `rotate(${open ? 0 : 180}deg)` }}
                    onClick={() => setOpen(!open)}
                >
                    <ExpandMoreIcon
                        sx={{
                            backgroundColor: colors.white,
                            border: `0.5px solid ${colors.textLightGray}`,
                            borderRadius: '50px',
                            color: colors.themeMainColor
                        }}
                    />
                </Box>
            </BoxFormCollapse>
            <Collapse in={open}  >
                <BoxUse>
                    {/* {t('BRANCH.TEMPLACE_DOC_DENTIST')} */}
                    <Box className={`box-use-1`} sx={{ fontSize: '16px', fontWeight: 500 }}></Box>
                    <Box className={`box-use-2`} sx={{ fontSize: '16px', fontWeight: 500 }}>{t('BRANCH.INPUT.USE')}</Box>
                </BoxUse>
                {info.info.map((item, index: number) => {
                    return (
                        <BoxInfoTemplaceDoc key={index} >
                            {!item?.docMulti?.length ?
                                <Box className={`info-temp-doc-text`}>
                                    <Box>
                                        {t(`BRANCH.INPUT.${item.docName}`)}
                                    </Box>
                                    <Box>
                                        <ButtonCustom
                                            textButton={t('BRANCH.INPUT.VIEW_EXAMPLE')}
                                            variant={'outlined'}
                                            btnStyle={{
                                                height: '25px',
                                                padding: '4px 10px',
                                                fontSize: '13px',
                                                whiteSpace: 'nowrap'
                                            }}
                                            onClick={() => {
                                                dispatch(showModal())
                                                setModalImg(!modalImg)
                                                setModalTitle({ title: t(`BRANCH.INPUT.${item.docName}`), page: item.page, example: item.example })
                                            }}
                                        />
                                    </Box>
                                </Box>
                                :
                                <Box className={`info-temp-doc-text`}>
                                    {item?.docMulti?.map((info, num: number) => {
                                        return (
                                            <Box className={`info-temp-doc-text-sub`} key={num}>
                                                <Box>
                                                    {t(`BRANCH.INPUT.${info.docName}`)}
                                                </Box>
                                                <Box>
                                                    <ButtonCustom
                                                        textButton={t('BRANCH.INPUT.VIEW_EXAMPLE')}
                                                        variant={'outlined'}
                                                        btnStyle={{
                                                            height: '25px',
                                                            padding: '4px 10px',
                                                            fontSize: '13px',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                        onClick={() => {
                                                            dispatch(showModal())
                                                            setModalImg(!modalImg)
                                                            setModalTitle({ title: t(`BRANCH.INPUT.${info.docName}`), page: info.page, example: info.example })
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            }
                            <Box className={`info-temp-doc-checked`}>
                                <InputCheckbox checked={item.checked} onChange={() => item.onChange()} />
                            </Box>
                        </BoxInfoTemplaceDoc>
                    )
                })}
            </Collapse>

            {modalImg &&
                <ModalExamDoc
                    onClose={() => setModalImg(!modalImg)}
                    modalTitle={modalTitle}
                />
            }
        </Box >
    )
}

export type ModalExamDocProps = {
    onClose: () => void
    modalTitle: ModalExamDocTitle
}

export const ModalExamDoc = (props: ModalExamDocProps) => {
    const { onClose, modalTitle } = props
    const { t } = useTranslation()

    return (
        <ModalCustom
            title={`${t('BRANCH.INPUT.EXAMPLE')} ${modalTitle.title}`}
            size='xl'
            onClose={() => onClose()}
            closeButton
            textBtnCancel={t('BRANCH.INPUT.CANCEL')}
            onReset={() => onClose()}
            alignFooter={'end'}
            component={
                <Box >
                    <Box sx={{ maxHeight: '100%', maxWidth: '1076px', 'img': { width: '100%' } }}>
                        {typeof modalTitle.example === "string" ?
                            <img src={modalTitle.example} />
                            :
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                {modalTitle?.example?.length && modalTitle.example.map((item, index: number) => {
                                    return (<Box key={index} sx={{ backgroundColor: '#fff' }}> <img src={item} /></Box>)
                                })}
                            </Box>
                        }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '14px', marginTop: '24px' }}>
                        <Box>
                            {`${t('BRANCH.INPUT.USAGE')} : `}
                        </Box>
                        {modalTitle?.page?.length && modalTitle.page.map((item: string, index: number) => {
                            return <Box key={index}>{`${t('BRANCH.PAGES.' + item)}${index + 1 !== modalTitle?.page?.length ? ',' : ''} `}</Box>
                        })}
                    </Box>

                </Box>}
        />
    )
}