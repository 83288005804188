import React, { useEffect, useState } from 'react'
import * as UseStyled from 'features/report/useStyled'
import TableCustom from 'component/Table/TableCustom'
import styled from '@emotion/styled'
import { Box, TableCell, TableRow } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useSSR, useTranslation } from 'react-i18next'
import { ThemeIcon, colors } from 'constants/theme'
import { icons } from 'constants/images'
import { Image } from 'react-bootstrap'
import _ from 'lodash'
import { toBuddhistYear } from 'utils/app.utils'
import moment from 'moment'

const BoxTable = styled(Box)(({ theme }) => ({
    'div': {
        'div': {
            'table': {
                minWidth: "1000px",
            }
        }
    }
}))

const BoxStatus = styled(Box)<{ bg?: string }>(({ theme, bg }) => ({
    width: "154px",
    height: "70px",
    borderRadius: "10px",
    border: "1px solid #E8E8E8",
    display: "flex",
    '.BoxHeader': {
        width: "24px",
        height: "100%",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        backgroundColor: bg,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    '.BoxBody': {
        marginLeft: "4px",
    }

}))

interface StatusProps {
    date: any
    data: any
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
}

export default function SchedulesDoctorStaus(props: StatusProps) {
    const { t } = useTranslation()

    const [statusCount, setStatusCount] = useState<any>({})
    const statusIcon: any = {
        NORMAL: { icon: <FontAwesomeIcon style={{ color: colors.white }} icon={faUser} />, color: colors.appointmentStatus.statusNormal, label: t('APPOINTMENT.STATUS.NORMAL'), disabled: false },
        CANCEL: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconCancel} />, color: colors.appointmentStatus.statusCancel, label: t('APPOINTMENT.STATUS.CANCEL'), disabled: false },
        CAN_NOT_CONTACT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconNotConnect} />, color: colors.appointmentStatus.statusUnConnect, label: t('APPOINTMENT.STATUS.CAN_NOT_CONTACT'), disabled: false },
        PUT_OFF: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconChangeSchedule} />, color: colors.appointmentStatus.statusChange, label: t('APPOINTMENT.STATUS.PUT_OFF'), disabled: false },
        CONFIRM: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconEnter} />, color: colors.appointmentStatus.statusEnter, label: t('APPOINTMENT.STATUS.CONFIRM'), disabled: false },
        ARRIVED: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconUserCheckTrue} />, color: colors.appointmentStatus.statusArrived, label: t('APPOINTMENT.STATUS.ARRIVED'), disabled: false },
        NOT_YET: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconUserCheckFalse} />, color: colors.appointmentStatus.statusUnArrived, label: t('APPOINTMENT.STATUS.NOT_YET'), disabled: false },
        PAYMENT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconPendingPayment} />, color: colors.appointmentStatus.statusWaitPayment, label: t('APPOINTMENT.STATUS.PAYMENT'), disabled: true },
        PENDING_PAYMENT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconWaitPayment} />, color: colors.appointmentStatus.statusPayment, label: t('APPOINTMENT.STATUS.PENDING_PAYMENT'), disabled: true },
        PENDING_SERVICE: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconCounterCheckIn} />, color: colors.appointmentStatus.statusCounterCheckIn, label: t('APPOINTMENT.STATUS.PENDING_SERVICE'), disabled: true },
        SERVICE: { icon: <Image style={{ height: '17px' }} src={icons.appointment.iconService} />, color: colors.appointmentStatus.statusService, label: t('APPOINTMENT.STATUS.SERVICE'), disabled: true },
        DONE: { icon: <Image style={{ height: '15px' }} src={ThemeIcon.DoneTreatment} />, color: colors.appointmentStatus.statusDone, label: t('APPOINTMENT.STATUS.DONE'), disabled: true },
        CANCEL_VISIT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.cancelVisit} />, color: colors.appointmentStatus.statusCancelVisit, label: t('APPOINTMENT.STATUS.CANCEL_VISIT'), disabled: true }
    }

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: t('REPORT.TABLE.SCHEDULES.DATETOTAL'), align: 'left', class: 'text-nowrap' },
    ]
    const renderData = () => {
        return (
            <TableRow>
                <TableCell align="left" scope="row" className="px-1 py-2">
                    <div className='d-flex align-items-center'>
                        {renderAppointStatus("NORMAL")}
                        {renderAppointStatus("CONFIRM")}
                        {renderAppointStatus("PUT_OFF")}
                        {renderAppointStatus("CAN_NOT_CONTACT")}
                        {renderAppointStatus("CANCEL")}
                        {renderAppointStatus("DONE")}
                    </div>
                    <hr style={{ color: "#E8E8E8" }} />
                    <div className='d-flex align-items-center'>
                        {renderAppointStatus("ARRIVED")}
                        {renderAppointStatus("NOT_YET")}
                    </div>
                    <hr style={{ color: "#E8E8E8" }} />
                    <div className='d-flex align-items-center'>
                        {renderAppointStatus("PENDING_SERVICE")}
                        {renderAppointStatus("SERVICE")}
                        {renderAppointStatus("PENDING_PAYMENT")}
                        {renderAppointStatus("PAYMENT")}
                        {renderAppointStatus("CANCEL_VISIT")}
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    const renderAppointStatus = (status: string) => {
        return <BoxStatus style={{ marginLeft: '10px' }} bg={statusIcon[status]?.color || ''}>
            <div className='BoxHeader'>
                {statusIcon[status]?.icon || ''}
            </div>
            <div className='BoxBody d-flex flex-column py-1'>
                <div style={{ fontSize: "12px", fontWeight: "500" }}>
                    {statusIcon[status]?.label || ''}
                </div>
                <div className='mt-auto' style={{ fontSize: "14px", fontWeight: "bold" }}>
                    {statusCount[status]?.toLocaleString() || '0'}
                </div>
            </div>
        </BoxStatus>
    }

    const loadData = () => {
        const data: any = []
        props.data.forEach((item: any) => {
            item.obj.forEach((item_obg: any) => {
                data.push({ statusAppointment: item_obg.statusAppointment })
            })
        })
        const dataStatus: any = {
            NORMAL: _.filter(data, { statusAppointment: "NORMAL" }).length,
            CANCEL: _.filter(data, { statusAppointment: "CANCEL" }).length,
            CAN_NOT_CONTACT: _.filter(data, { statusAppointment: "CAN_NOT_CONTACT" }).length,
            PUT_OFF: _.filter(data, { statusAppointment: "PUT_OFF" }).length,
            CONFIRM: _.filter(data, { statusAppointment: "CONFIRM" }).length,
            ARRIVED: _.filter(data, { statusAppointment: "ARRIVED" }).length,
            NOT_YET: _.filter(data, { statusAppointment: "NOT_YET" }).length,
            PAYMENT: _.filter(data, { statusAppointment: "PAYMENT" }).length,
            PENDING_PAYMENT: _.filter(data, { statusAppointment: "PENDING_PAYMENT" }).length,
            PENDING_SERVICE: _.filter(data, { statusAppointment: "PENDING_SERVICE" }).length,
            SERVICE: _.filter(data, { statusAppointment: "SERVICE" }).length,
            DONE: _.filter(data, { statusAppointment: "DONE" }).length,
        }
        setStatusCount(dataStatus)
    }

    useEffect(() => {
        loadData()
    }, [props.data])

    return (
        <>
            <div className='mb-2'>
                <span>
                    {t('REPORT.TABLE.SCHEDULES.DATETOTAL')}
                </span>
                <span className='ml-3'>
                    {t('REPORT.TABLE.SCHEDULES.DATE')}
                </span>
                <span className='ml-1'>
                    {`${toBuddhistYear(moment(props.date[0]))} >> ${toBuddhistYear(moment(props.date[1]))}`}
                </span>
            </div>
            <BoxTable sx={{ maxWidth: '1000px' }}>
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        customScroll
                        hidePagination
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={[renderData()]}
                    />
                </UseStyled.ResponsiveReportTable>
            </BoxTable>
        </>
    )
}