import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem'
import { routeName } from 'routes/routes-name'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/default-labs/lab-group/style'

import LabGroupsApi from 'api/setting/default-labs/lab-group.api'

import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function LabGroup() {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [labTypes, setLabTypes] = useState([])

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
    const res = await LabGroupsApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setLabTypes(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onCreate = () => {
    history.push(routeName.labGroup + '/create')
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('ALERT.CONFIRM_STATUS')}</p>`,
      null,
      (res: any) => {
        if (res) {
          LabGroupsApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('LAB_GROUP.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('LAB_GROUP.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { labGroupId, labGroupName } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LAB_GROUP.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LAB_GROUP.ALERT.CONFIRM_DELETE_', {
        labGroupName: labGroupName
      })}</p>`,
      (res: any) => {
        if (res) {
          LabGroupsApi.remove(labGroupId)
            .then((resp) => {
              notiSuccess(t('LAB_GROUP.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('LAB_GROUP.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    history.push(routeName.labGroup + '/update', data)
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }
  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'labGroupName', disablePadding: false, label: t('LAB_GROUP.TABLE.CELL.LAB_GROUP_NAME') },
    { id: 'status', disablePadding: false, label: t('LAB_GROUP.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('LAB_GROUP.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { labGroupId, labGroupName, updatedBy, updatedAt, status: lbGroupStatus } = objData
    const statusBtnActive = lbGroupStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = lbGroupStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: labGroupId,
      id: labGroupId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: labGroupName },
        { option: 'STATUS', align: 'center', label: lbGroupStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('LAB_GROUP.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('LAB_GROUP.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={labTypes.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
