import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface HandInsuranceInterface {
  handInsuranceId?: number
  hour: string
  price: string
  status?: string
}

export default class HandInsuranceAPI extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('hand-insurances', { params: { ...props } }).then((res) => res)
  }

  static create(hour: string, price: string) {
    return this.api.post('hand-insurances', { hour, price })
  }

  static findById(id: number): Promise<HandInsuranceInterface> {
    return this.api.get(`hand-insurances/${id}`)
  }

  static update(id: number, hour: string, price: string, status: string) {
    return this.api.patch(`hand-insurances/${id}`, { hour, price, status })
  }

  static remove(id: number) {
    return this.api.delete(`hand-insurances/${id}`)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`hand-insurances/${id}/status`, { id, status })
  }
}
