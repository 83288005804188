import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, IconButton, TableRow, TableCell, Grid, Typography } from '@mui/material'
import useWindowSize from 'utils/useWindowSize'
import { icons } from 'constants/images'
import InputTextField from 'component/Input/InputTextField'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { numberFormat } from 'utils/app.utils'
import { treatment, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { useEffect, useState } from 'react'
import IconMoveTableRow from 'assets/icon/iconMoveTableRow';

export interface IFRowTxC {
  disabled: boolean
  no: number
  itemTypes: any[]
  items: any[]
  itemId: number
  itemTypeId: number
  itemCode: string
  qty: number
  price: number
  edit?: any
  type?: any
  data?: any
  id?: number
  balance: number
  use: number
  totalQtyNow: number
  qtyNow?: number
  handleChangeInputItemType?: (e: any, value: any) => void
  handleChangeItemType?: (e: any, value: any) => void
  handleChangeInputItem?: (e: any, value: any) => void
  handleChangeItem?: (e: any, value: any) => void
  handleChangeQty: (event: any) => void
  handleChangePrice: (event: any) => void
  handleChangeBalance: (event: any) => void
  handleDiscard: (event: any) => void
  operativeType?: any
  opertatives?: any
  providerDraggable?: any
  handleChangeSort?: (index: number) => void
  itemSort?: number
}
export default function RowTxC(props: IFRowTxC) {
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const treatments: TreatmentStateInterface = useSelector(treatment)

  const { id, disabled, no, itemTypes, items, itemId, itemTypeId, itemCode, qty, price, type, data, balance, use, operativeType, opertatives, itemSort } = props

  const [oldQty, setOldQty] = useState(qty)
  const [oldPrice, setOldPrice] = useState(price)
  const [oldAllPrice, setOldAllPrice] = useState(price * qty)
  const [oldBalance, setOldBalance] = useState(balance)
  const [useNew, setUseNew] = useState(Math.min(qty, use))

  const providerDraggable = props?.providerDraggable ?
    {
      ...props?.providerDraggable?.draggableProps,
      ref: props?.providerDraggable?.innerRef
    } :
    {}

  const { handleChangeInputItemType, handleChangeItemType, handleChangeInputItem, handleChangeItem, handleChangePrice, handleChangeQty, handleChangeBalance, handleDiscard, handleChangeSort } = props
  const hasNoBalance = () => {
    if ((props.no || props.no === no) && props.edit && props.edit?.patientTreatmentCourseItems[props.no]?.lastBalance === 0) return true
    if (_.some(treatments.summeryTreatments, { refId: id })) return true
    else return false
  }
  const hasFilter = () => {
    if (hasNoBalance()) {
      return `grayscale(1)`
    } else {
      return 'none'
    }
  }
  const hasOpacity = () => {
    if (hasNoBalance()) {
      return 0.3
    } else {
      return 1
    }
  }
  const mobileWidth = () => {
    if (width >= 480) {
      return '130px'
    } else {
      return '90px'
    }
  }
  const onChangeQty = (e: any) => {
    handleChangeQty(e)
  }

  useEffect(() => {
    setOldQty(qty)
    setOldPrice(price)
    setOldAllPrice(price * qty)
    setOldBalance(balance)
  }, [props.id])

  useEffect(() => {
    const value = oldQty - oldBalance
    setUseNew(Math.min(qty, use) === 0 ? value : Math.min(qty, use))
  }, [use])

  useEffect(() => {
    const value = oldQty - oldBalance
    handleChangeBalance(value)
    const timeout = setTimeout(() => {
      if (qty <= value) {
        handleChangeQty(value)
      }
    }, 1500)
    return () => {
      clearTimeout(timeout);
    }

  }, [qty])

  const autoSelectDisabled = () => {
    return itemTypeId ? disabled : true
  }
  return (
    <TableRow key={no} {...providerDraggable}>
      {(width >= 1200 && (
        <TableCell align="center" scope="row" className="py-3 py-md-2">
          <Box sx={{ cursor: 'pointer' }} {...props?.providerDraggable?.dragHandleProps}>
            <IconMoveTableRow />
          </Box>

        </TableCell>
      ))}
      <TableCell align="center" scope="row" className="py-3 py-md-2">
        {no + 1}
        <Box>
          {((width < 1200) && (
            <TableCell align="center" scope="row" className="py-3 py-md-2">
              <Box sx={{ cursor: 'pointer' }} {...props?.providerDraggable?.dragHandleProps}>
                <IconMoveTableRow />
              </Box>
            </TableCell>
          ))}
        </Box>
      </TableCell>
      {(width >= 1200 && (
        <>
          <TableCell align="center" scope="row" className="py-3 py-md-2">
            <AutocompleteSelect
              disableClearable
              disabled={disabled}
              labelId={`lb-item-type-${no}`}
              noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
              options={itemTypes}
              getOptionLabel={(option: any) => option.itemTypeName}
              renderOption={(props, option: any) => (
                <Box key={option} component="li" {...props}>
                  {option.itemTypeName}
                </Box>
              )}
              onInputChange={handleChangeInputItemType}
              onchange={handleChangeItemType}
              key={`item-type-${no}`}
              value={
                _.find(itemTypes, (val: any) => {
                  if (val.group && type) return String(val.itemTypeId) === String(itemTypeId) && String(val.group) === String(type)
                  else return String(val.itemTypeId) === String(itemTypeId)
                }) || null
              }
            />
          </TableCell>
          <TableCell align="center" scope="row" className="py-3 py-md-2">
            <AutocompleteSelect
              disableClearable
              labelId={`lb-item-${no}`}
              noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
              options={_.map([...items].filter(val => String(val.itemTypeId) === String(itemTypeId)))}
              disabled={autoSelectDisabled()}
              getOptionLabel={(option: any) => option.itemName}
              renderOption={(props, option: any) => (
                <Box
                  {...props}
                  key={option}
                  component="li"
                  sx={{
                    pointerEvents: _.some(data, { itemId: option?.itemId }) ? 'none !important' : 'auto !important',
                    opacity: _.some(data, { itemId: option?.itemId }) ? 0.5 : 1
                  }}
                >
                  {option.itemName}
                </Box>
              )}
              onInputChange={handleChangeInputItem}
              onchange={handleChangeItem}
              key={`item-${no}`}
              value={_.find(items, (val: any) => String(val.itemId) === String(itemId)) || null}
            />
          </TableCell>
          <TableCell align="center" scope="row" className="py-3 py-md-2">
            <Box
              sx={{ width: '50px', cursor: 'pointer', margin: '0 auto' }}
              onClick={() => handleChangeSort && handleChangeSort(no)}>
              <InputTextField
                key={`sort${no}`}
                inputTextAlign={'right'}
                value={itemSort || ''}
                style={{ pointerEvents: 'none' }}
              />
            </Box>
          </TableCell>
          <TableCell align="center" scope="row" className="py-3 py-md-2">
            {itemCode || '-'}
          </TableCell>
          {props.edit && (
            <TableCell align="center" scope="row" className="py-3 py-md-2">
              {oldQty}
            </TableCell>
          )}
          {!props.edit && (
            <TableCell align="center" scope="row" className="py-3 py-md-2">
              {
                <InputTextField
                  label={t('TXC.TABLE.ALL_TOTAL')}
                  disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                  value={qty}
                  key={`input-qty-${no}`}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onchange={handleChangeQty}
                  inputTextAlign={'center'}
                />
              }
            </TableCell>
          )}
          {props.edit && (
            <>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                <InputTextField
                  disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                  value={qty}
                  key={`input-qty-${no}`}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onchange={(e: any) => {
                    onChangeQty(e)
                  }}
                  inputTextAlign={'center'}
                />
              </TableCell>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                {oldQty - oldBalance}
              </TableCell>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                <InputTextField
                  disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                  value={useNew}
                  key={`input-qty-${no}`}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onchange={(e: any) => {
                    handleChangeBalance(e)
                  }}
                  inputTextAlign={'center'}
                />
              </TableCell>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                {oldBalance}
              </TableCell>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                <InputTextField disabled={disabled} value={balance} key={`input-balance-${no}`} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={handleChangeBalance} inputTextAlign={'center'} />
              </TableCell>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                {oldPrice}
              </TableCell>
            </>
          )}
          {props.edit && (
            <TableCell align="center" scope="row" className="py-3 py-md-2">
              <InputTextField
                placeholder={t('TXC.TABLE.PRICE')}
                label={''}
                value={price}
                key={`input-price-${no}`}
                disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onchange={handleChangePrice}
                inputTextAlign={'right'}
              />
            </TableCell>
          )}
          {!props.edit && (
            <TableCell align="center" scope="row" className="py-3 py-md-2">
              <InputTextField
                placeholder={t('TXC.TABLE.PRICE')}
                label={''}
                value={price}
                key={`input-price-${no}`}
                disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onchange={handleChangePrice}
                inputTextAlign={'right'}
              />
            </TableCell>
          )}
          {props.edit && (
            <TableCell align="center" scope="row" className="py-3 py-md-2">
              {numberFormat(oldAllPrice) || '-'}
            </TableCell>
          )}
          {!props.edit && (
            <TableCell align="right" scope="row" className="py-3 py-md-2">
              {numberFormat(price * qty) || '-'}
            </TableCell>
          )}
          {props.edit && (
            <>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                <InputTextField
                  placeholder={t('TXC.TABLE.PRICE')}
                  label={''}
                  value={numberFormat(price * qty)}
                  key={`input-price-${no}`}
                  disabled={disabled}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onchange={handleChangePrice}
                  inputTextAlign={'right'}
                />
              </TableCell>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                {numberFormat(price * qty - oldAllPrice)}
              </TableCell>
            </>
          )}
        </>
      )) || (
          <TableCell align="center" scope="row" className="py-3 py-md-2">
            <Grid container spacing={1}>
              <Grid item className="w-100">
                <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                  <Typography className="text-left py-2 py-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                    {t('TXC.TYPE')}
                  </Typography>
                  <Box className="w-100">
                    <AutocompleteSelect
                      disableClearable
                      disabled={disabled}
                      labelId={`lb-item-type-${no}`}
                      noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                      options={itemTypes}
                      getOptionLabel={(option: any) => option.itemTypeName}
                      renderOption={(props, option: any) => (
                        <Box key={option} component="li" {...props}>
                          {option.itemTypeName}
                        </Box>
                      )}
                      onInputChange={handleChangeInputItemType}
                      onchange={handleChangeItemType}
                      key={`item-type-${no}`}
                      value={
                        _.find(itemTypes, (val: any) => {
                          if (val.group && type) return String(val.itemTypeId) === String(itemTypeId) && String(val.group) === String(type)
                          else return String(val.itemTypeId) === String(itemTypeId)
                        }) || null
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item className="w-100">
                <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                  <Typography className="text-left pb-2 pb-sm-0" sx={{ width: width >= 577 ? '130px' : '180px', fontWeight: 600, flex: 'none' }}>
                    {t('TXP.TABLE.DETAIL')}
                  </Typography>
                  <Box className="w-100">
                    <AutocompleteSelect
                      disableClearable
                      labelId={`lb-item-${no}`}
                      noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                      options={items}
                      disabled={autoSelectDisabled()}
                      getOptionLabel={(option: any) => option.itemName}
                      renderOption={(props, option: any) => (
                        <Box
                          {...props}
                          key={option}
                          component="li"
                          sx={{
                            pointerEvents: _.some(data, { itemId: option?.itemId }) ? 'none !important' : 'auto !important',
                            opacity: _.some(data, { itemId: option?.itemId }) ? 0.5 : 1
                          }}
                        >
                          {option.itemName}
                        </Box>
                      )}
                      onInputChange={handleChangeInputItem}
                      onchange={handleChangeItem}
                      key={`item-${no}`}
                      value={_.find(items, (val: any) => String(val.itemId) === String(itemId)) || null}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="text-left" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                    {t('TREATMENT_COURSE.FORM.SUMMARY.TABLE.ORDER')}
                  </Typography>
                  <Box
                    sx={{ width: '50px', cursor: 'pointer' }}
                    onClick={() => handleChangeSort && handleChangeSort(no)}>
                    <InputTextField
                      key={`sort${no}`}
                      inputTextAlign={'right'}
                      value={itemSort || ''}
                      style={{ pointerEvents: 'none' }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="text-left" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                    {t('TXC.TABLE.CODE')}
                  </Typography>
                  <Typography className="text-left">{itemCode || '-'}</Typography>
                </Box>
              </Grid>
              <Grid item sm={6} className="w-100 pr-md-2">
                <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                  <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                    {props.edit && t('TXC.TABLE.ALL_TOTAL_NOW')}
                    {!props.edit && t('TXC.TABLE.ALL_TOTAL')}
                  </Typography>
                  {props.edit && <Typography className="text-left">{oldQty}</Typography>}
                  {!props.edit && (
                    <InputTextField
                      value={qty}
                      key={`input-qty-${no}`}
                      disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      onchange={handleChangeQty}
                    />
                  )}
                </Box>
              </Grid>
              {props.edit && (
                <>
                  <Grid item sm={6} className="w-100 pr-md-2">
                    <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.ALL_TOTAL_NEW')}
                      </Typography>
                      <InputTextField
                        disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                        value={qty}
                        key={`input-qty-${no}`}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        onchange={handleChangeQty}
                        inputTextAlign={'center'}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6} className="w-100 pr-md-2">
                    <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.USE_AMOUNT_NOW')}
                      </Typography>
                      <Typography className="text-left">{oldQty - oldBalance}</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6} className="w-100 pr-md-2">
                    <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.USE_AMOUNT_NEW')}
                      </Typography>
                      <InputTextField
                        disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                        value={use}
                        key={`input-qty-${no}`}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        onchange={handleChangeBalance}
                        inputTextAlign={'center'}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6} className="w-100 pr-md-2">
                    <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.REMAINING_AMOUNT_NOW')}
                      </Typography>
                      <Typography className="text-left">{oldBalance}</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6} className="w-100 pr-md-2">
                    <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.REMAINING_AMOUNT_NEW')}
                      </Typography>
                      <InputTextField disabled={disabled} value={balance} key={`input-balance-${no}`} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={handleChangeBalance} inputTextAlign={'center'} />
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item sm={6} className="w-100">
                <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                  <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                    {props.edit && t('TXC.TABLE.PRICE_NOW')}
                    {!props.edit && t('TXC.TABLE.PRICE')}
                  </Typography>
                  {props.edit && oldPrice}
                  {!props.edit && (
                    <Box className="w-100">
                      <InputTextField
                        placeholder={t('TXC.TABLE.PRICE')}
                        label={''}
                        value={price}
                        disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                        key={`input-price-${no}`}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        onchange={handleChangePrice}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
              {props.edit && (
                <>
                  <Grid item sm={6} className="w-100">
                    <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.PRICE_NEW')}
                      </Typography>
                      <Box className="w-100">
                        <InputTextField
                          placeholder={t('TXC.TABLE.PRICE_NEW')}
                          label={''}
                          value={price}
                          disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || hasNoBalance()}
                          key={`input-price-${no}`}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          onchange={handleChangePrice}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm={6} className="w-100">
                    <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.TOTAL_NOW')}
                      </Typography>
                      <Typography className="text-left">{numberFormat(oldAllPrice)}</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6} className="w-100">
                    <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                      <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                        {t('TXC.TABLE.TOTAL_NEW')}
                      </Typography>
                      <Box className="w-100">
                        <InputTextField
                          placeholder={t('TXC.TABLE.PRICE_NEW')}
                          label={''}
                          value={numberFormat(price * qty)}
                          disabled={disabled}
                          key={`input-price-${no}`}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          onchange={handleChangePrice}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item className="w-100">
                <Box className="d-flex flex-column flex-sm-row align-items-sm-center">
                  <Typography className="text-left pb-2 pb-sm-0" sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                    {props.edit && t('TXC.TABLE.TOTAL_DIF')}
                    {!props.edit && t('TXC.TABLE.TOTAL')}
                  </Typography>
                  <Typography className="text-left">{numberFormat(price * qty - oldAllPrice) || '-'}</Typography>
                </Box>
              </Grid>
            </Grid>
          </TableCell>
        )}

      <TableCell align="center" scope="row" className="py-3 py-md-2">
        <IconButton
          key={`btn-discard-${no}`}
          disabled={hasNoBalance()}
          disableFocusRipple
          disableRipple
          onClick={handleDiscard}
          className="p-0 align-self-start mr-2 ml-3"
          sx={{
            backgroundColor: 'transparent',
            filter: hasFilter(),
            opacity: hasOpacity()
          }}
        >
          <img src={icons.btnMinus} alt="discard" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
