import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/32.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginBottom: 'auto',
  top: '1.4%'
}))

export default function Teeth32(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 25.506, height: 80.83, viewBox: "0 0 25.506 80.83" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <TextNumberL>32</TextNumberL>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q3} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <DivTeethL className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`sm teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`sm ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9179">
              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5359"
                d="M433.474 499.84a1.137 1.137 0 0 0-2.225.087 23.854 23.854 0 0 0 .769 11.539c3.815 10.836 5.077 22.125 6.81 33.348 1.469 9.521 5.431 18.048 10.521 26.1 1.476 2.335 2.023 2.535 4.292 1.727a1.134 1.134 0 0 0 .613-.518c.576-1.058.244-2.225.123-3.333-2.391-21.774-4.247-43.534.808-65.218 1.043-4.475-.82-7.331-5.367-7.6-4.875-.287-9.826-1.22-14.661-.171a1.137 1.137 0 0 0 .355 2.242l.045-.005a51.908 51.908 0 0 1 9.951-.095c4.48.336 5.716 1.911 4.515 6.359-1.37 5.072-2.353 6.362-3.712 11.43-.533 1.988-1.08.292-3.61.489-2.613.2-4.182 1.682-5.123-.473-2.03-4.647-3.072-5.028-3.593-10.088-.197-1.902-.024-3.943-.511-5.82z"
                transform="translate(-429.961 -492.246)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6503"
                  d="M433.461 515.938c2.939 10.924 3.634 17.654 5.366 28.877 1.469 9.521 5.431 18.048 10.521 26.1 1.476 2.335 2.023 2.535 4.292 1.727a1.134 1.134 0 0 0 .613-.518c.576-1.058.244-2.225.123-3.333-1.97-17.941-3.577-35.874-1.215-54.061-.795 2.765-.373 1.781-1.467 3.375-.812.813-2.937 1.484-4.373 1.953-1.721.454-3 1.094-5.586.548-.69-.149-1.421-.289-2.445-.491-1.847-.448-3.912-.789-4.482-1.439a12.4 12.4 0 0 1-1.347-2.738z"
                  transform="translate(-429.961 -492.246)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 9711">
                <path
                  data-name="Path 5360"
                  d="M452.174 516.857c1.428-.957 1.724-2.492 2.094-4.171.784-3.561 2.892-11.316 1.915-15.784-1.3-5.937-4.749-6.351-10.547-6.351s-11.631.4-13.127 2.209c-2.284 2.767-2.226 10.73 1.947 21.312.349.882.974 2.917 1.854 3.272a51.241 51.241 0 0 0 7.046 1.711c2.656.552 7.962-1.624 8.818-2.198z"
                  transform="translate(-431.002 -490.551)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5361"
                  d="M452.045 516.491c1.3-.873 1.571-2.271 1.908-3.8.714-3.244 2.635-10.308 1.745-14.38-1.183-5.408-4.327-5.785-9.609-5.785s-10.6.362-11.958 2.012c-2.081 2.521-2.028 9.775 1.774 19.415.317.8.887 2.657 1.689 2.98a46.532 46.532 0 0 0 6.418 1.559c2.42.503 7.253-1.478 8.033-2.001z"
                  transform="translate(-431.625 -491.253)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5362"
                  d="M452.32 516.868c1.174-.787 1.417-2.048 1.721-3.428.644-2.927 2.376-9.3 1.573-12.971-1.066-4.878-3.9-5.219-8.667-5.219s-9.558.327-10.786 1.815c-1.877 2.274-1.829 8.818 1.6 17.513.286.725.8 2.4 1.523 2.688a42.039 42.039 0 0 0 5.79 1.406c2.182.455 6.542-1.332 7.246-1.804z"
                  transform="translate(-432.915 -492.222)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />

                {(isToothRoot && props.type === 'treatment') ?
                  <path
                    data-name="Path 6570"
                    d="M9.045 10.926a129.84 129.84 0 0 0 1.13 22.353c1.7 12.151 5.228 23.684 4.941 24.04.019.171-1.432-4.1-3.319-11.614A92.6 92.6 0 0 1 9.53 33.63C8.2 22.257 6.96 11.372 6.919 11.107a19.1 19.1 0 0 0 2.126-.181z"
                    transform="translate(6.036 17.962)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  : ''}

                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9474">
                      <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.316 -5.572)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.316 -5.572)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <linearGradient id="sgjm5i4y3a" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="djx3jrb0nb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9366">
                      <path data-name="Path 5359" d="M433.474 499.84a1.137 1.137 0 0 0-2.225.087 23.854 23.854 0 0 0 .769 11.539c3.815 10.836 5.077 22.125 6.81 33.348 1.469 9.521 5.431 18.048 10.521 26.1 1.476 2.335 2.023 2.535 4.292 1.727a1.134 1.134 0 0 0 .613-.518c.576-1.058.244-2.225.123-3.333-2.391-21.774-4.247-43.534.808-65.218 1.043-4.475-.82-7.331-5.367-7.6-4.875-.287-9.826-1.22-14.661-.171a1.137 1.137 0 0 0 .355 2.242l.045-.005a51.908 51.908 0 0 1 9.951-.095c4.48.336 5.716 1.911 4.515 6.359-1.37 5.072-2.353 6.362-3.712 11.43-.533 1.988-1.08.292-3.61.489-2.613.2-4.182 1.682-5.123-.473-2.03-4.647-3.072-5.028-3.593-10.088-.197-1.902-.024-3.943-.511-5.82z" transform="translate(-429.961 -492.246)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5360" d="M452.174 516.857c1.428-.957 1.724-2.492 2.094-4.171.784-3.561 2.892-11.316 1.915-15.784-1.3-5.937-4.749-6.351-10.547-6.351s-11.631.4-13.127 2.209c-2.284 2.767-2.226 10.73 1.947 21.312.349.882.974 2.917 1.854 3.272a51.241 51.241 0 0 0 7.046 1.711c2.656.552 7.962-1.624 8.818-2.198z" transform="translate(-431.002 -490.551)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5361" d="M452.045 516.491c1.3-.873 1.571-2.271 1.908-3.8.714-3.244 2.635-10.308 1.745-14.38-1.183-5.408-4.327-5.785-9.609-5.785s-10.6.362-11.958 2.012c-2.081 2.521-2.028 9.775 1.774 19.415.317.8.887 2.657 1.689 2.98a46.532 46.532 0 0 0 6.418 1.559c2.42.503 7.253-1.478 8.033-2.001z" transform="translate(-431.625 -491.253)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5362" d="M452.32 516.868c1.174-.787 1.417-2.048 1.721-3.428.644-2.927 2.376-9.3 1.573-12.971-1.066-4.878-3.9-5.219-8.667-5.219s-9.558.327-10.786 1.815c-1.877 2.274-1.829 8.818 1.6 17.513.286.725.8 2.4 1.523 2.688a42.039 42.039 0 0 0 5.79 1.406c2.182.455 6.542-1.332 7.246-1.804z" transform="translate(-432.915 -492.222)" style={{ fill: colors.white }} />
                      <g data-name="Group 9405">
                        <g data-name="Group 9396" transform="translate(6.516 10.533)">
                          <g data-name="Rectangle 3927" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.602" height="11.602" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.602" height="10.602" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.119 41.119 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(-6.653 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(3.089 2.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(3.289 2.015)" style={{ fill: 'url(#sgjm5i4y3a)' }} />
                          <g data-name="Group 9397" transform="translate(0 .5)">
                            <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(3.289 6.215)" style={{ fill: '#cbcccc' }} />
                            <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(3.289 6.015)" style={{ fill: 'url(#djx3jrb0nb)' }} />
                          </g>
                        </g>
                        <path data-name="Path 6488" d="M6.62 10.793c5.2.951 8.1 2.819 24.05.005" transform="translate(-5.915 4.342)" style={{ strokeLinecap: 'round', stroke: '#b5b7b7', fill: 'none' }} />
                        <path data-name="Path 6486" d="M6.688 10.8c2.5.446 9.231 3.227 24.232 0" transform="translate(-6.101 3.518)" style={{ stroke: '#babbbb', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </> || ""}

                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="ch5ljdb28a" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="ujkicoiq3b" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9507">
                      <path data-name="Path 5360" d="M452.174 516.857c1.428-.957 1.724-2.492 2.094-4.171.784-3.561 2.892-11.316 1.915-15.784-1.3-5.937-4.749-6.351-10.547-6.351s-11.631.4-13.127 2.209c-2.284 2.767-2.226 10.73 1.947 21.312.349.882.974 2.917 1.854 3.272a51.241 51.241 0 0 0 7.046 1.711c2.656.552 7.962-1.624 8.818-2.198z" transform="translate(-431.002 -490.551)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5361" d="M452.045 516.491c1.3-.873 1.571-2.271 1.908-3.8.714-3.244 2.635-10.308 1.745-14.38-1.183-5.408-4.327-5.785-9.609-5.785s-10.6.362-11.958 2.012c-2.081 2.521-2.028 9.775 1.774 19.415.317.8.887 2.657 1.689 2.98a46.532 46.532 0 0 0 6.418 1.559c2.42.503 7.253-1.478 8.033-2.001z" transform="translate(-431.625 -491.253)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5362" d="M452.32 516.868c1.174-.787 1.417-2.048 1.721-3.428.644-2.927 2.376-9.3 1.573-12.971-1.066-4.878-3.9-5.219-8.667-5.219s-9.558.327-10.786 1.815c-1.877 2.274-1.829 8.818 1.6 17.513.286.725.8 2.4 1.523 2.688a42.039 42.039 0 0 0 5.79 1.406c2.182.455 6.542-1.332 7.246-1.804z" transform="translate(-432.915 -492.222)" style={{ fill: colors.white }} />
                      <g data-name="Group 9526">
                        <g data-name="Path 6490" transform="translate(3.284 30.161)" style={{ fill: 'url(#ch5ljdb28a)' }}>
                          <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                          <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(1 24.958)" style={{ strokeLinejoin: 'round', fill: 'url(#ujkicoiq3b)' }}>
                          <path d="M.945 7.569H.938C.912 7.52.892 7.32.865 7.047.795 6.354.653 4.913.1 1.905.103.909.577.1 1.16.1h22.038c.583 0 1.057.81 1.06 1.807-.293 2.008-.447 3.306-.549 4.164-.175 1.486-.187 1.486-.385 1.486a3.548 3.548 0 0 1-.126-.001H1.16c-.062 0-.112.005-.153.008-.025.003-.046.005-.062.005z" style={{ stroke: 'none' }} />
                          <path d="M1.16.2C.643.2.206.975.2 1.896c.552 3.005.695 4.447.764 5.14.019.186.034.341.049.427.039-.003.088-.007.147-.007h22.038l.126.001a.404.404 0 0 0 .064-.003c.068-.093.13-.614.222-1.394.102-.859.255-2.155.547-4.16-.004-.923-.442-1.7-.96-1.7H1.16m0-.2h22.038c.64 0 1.16.857 1.16 1.914-.873 5.981-.52 5.742-1.16 5.742H1.16c-.64 0 0 .568-1.16-5.742C0 .857.52 0 1.16 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(-2.227 15.297)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-1.94 20.496)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(-1.492 43.023)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-1.684 25.039)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(-1.684 31.039)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(-1.684 37.039)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(-1.493 48.661)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </> : ""}
              </g>
            </g>
          </svg>}
      </DivTeethL>
    </Div>
  )
}
