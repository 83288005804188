import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface DiagnosisInterface {
  diagnosisTypeId: number
  diagnosisCode: string
  diagnosisName: string
  diagnosisRecommends?: any[]
  status: string
}

export interface DiagnosisDataInterface {
  diagnosisId?: number
  diagnosisTypeId?: number
  diagnosisCode?: string
  diagnosisName?: string
  status?: string
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
  diagnosisRecommends?: DxRecommendDataInterface[]
}

export interface DxRecommendDataInterface {
  diagnosisRecommendId: number
  diagnosisId: number
  recommendId: number
  recommendText: string
  updatedBy: string
  updatedAt: string
  createdBy: string
  createdAt: string
}

export default class DiagnosisApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('diagnosis', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('diagnosis', playload)
  }

  static findById(id: number): Promise<DiagnosisInterface> {
    return this.api.get(`diagnosis/${id}`)
  }

  static update(id: number, body: DiagnosisInterface) {
    return this.api.patch(`diagnosis/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`diagnosis/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`diagnosis/${id}`)
  }
}
