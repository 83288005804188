import React, { useEffect, useRef, useState } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Box, Button } from '@mui/material'
import { icons, onImgError } from 'constants/images'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Image } from 'react-bootstrap'

import printIcon from 'assets/icon/carlendar/print.svg'
import FucntionPrint from "component/Print/print"

/** API */
import { fileUrl } from 'api/api'
import PdfXray from 'features/pdf/pdf-xray'

import Flip from 'assets/icon/xray/Flip'
import Line from 'assets/icon/xray/Line'
import GroupLine from 'assets/icon/xray/GroupLine'
import Rotate from 'assets/icon/xray/Rotate'
import Seletor from 'assets/icon/xray/Seletor'

type PreviewImageProps = {
  fileName?: string
  filePath?: string
  fullPath?: string
  base64?: string
  fileExtension?: string
  xRayFileId?: number
}

type Image = {
  image?: PreviewImageProps
  imageFile?: string
  type?: string
}

export const ToolsBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  transform: 'translateX(-50%)',
  zIndex: 1,
  backgroundColor: colors.white,
  borderRadius: 100,
  padding: '6px 16px',
  [theme.breakpoints.down('lg')]: {
    padding: '4px 10px'
  }
}))

const Tools = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 'unset',
  width: 30,
  height: 30,
  backgroundColor: colors.extraLightGray,
  borderRadius: 100,
  color: colors.textPrimary,
  svg: {
    fontSize: 18
  },
  '&.btn-reset-position': {
    minWidth: 56,
    width: 'auto',
    position: 'absolute',
    top: '0.5rem',
    right: '8.5rem',
    zIndex: 2,
    textTransform: 'capitalize'
  },
  '&.btn-print-position': {
    minWidth: 56,
    width: 'auto',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 2,
    textTransform: 'capitalize'
  },
  '&.btn-preview-position': {
    minWidth: 56,
    width: 'auto',
    position: 'absolute',
    top: '0.5rem',
    right: '4.5rem',
    zIndex: 2,
    textTransform: 'capitalize'
  },
  '&:hover': {
    backgroundColor: colors.lightGray
  },
  [theme.breakpoints.down('lg')]: {
    width: 24,
    height: 24
  }
}))

export const ToolsBoxScroll = styled(Box)(({ theme }) => ({
  width: '179px',
  textAlign: 'center',
  overflow: 'auto',
  '::-webkit-scrollbar': {
    width: '1px',
    height: '1px',
  }
}))

export const ToolsBoxSeletor = styled(Box)(({ theme }) => ({
  height: '20px',
  marginTop: '-14px',
  position: 'absolute',
  right: 'calc((50% / 2) + 38px)',
}))

export const BoxBallCenter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '.BallCenter': {
    width: '4px',
    height: '4px',
    backgroundColor: '#ffffff',
    borderRadius: '50%'
  }
}))

const ImageLabel = styled(Image)(({ theme }) => ({
  verticalAlign: 'top',
  width: 21
}))

export default function PreviewImage(props: Image) {
  const { t } = useTranslation()
  const componentRef = useRef<HTMLDivElement>(null)

  const elementRotateRef = useRef<any>(null);
  const elementLineRotateRef = useRef<any>(null);
  const widthRuler = 530
  const widthRulerHalf = widthRuler / 2

  const [mouseMoveX, setMouseMoveX] = useState<number>(0)
  const [rotateImg, setRotateImg] = useState<number>(0)
  const [flipImg, setFlipImg] = useState<number>(1)
  const [styleImg, setStyleImg] = useState<any>({})

  const [onDrags, setOnDrags] = useState<boolean>(false)
  const [dragsStartX, setDragesStartX] = useState<number>(0)
  const [dragsScrollLeft, setDragesScrollLeft] = useState<number>(0)

  const [showButton, setShowButton] = useState<boolean>(false)

  const renderImage = (img: any) => {
    if (img?.xRayFileId) {
      if (img?.base64) {
        return `data:image/${img?.fileExtension};base64,${img?.base64}`
      } else {
        return `${fileUrl}/${img.fullPath}`
      }
    } else {
      return `${fileUrl}/${img.fullPath}`
    }

  }

  const preview = (img: any) => {
    if (img) window.open(img, '_blank')
  }

  const scrollToMiddle = () => {
    if (elementRotateRef.current) {
      const element = elementRotateRef.current;
      element.scrollLeft = widthRulerHalf;
    }
  };

  useEffect(() => {
    scrollToMiddle()
  }, [elementRotateRef, props.image, props.imageFile])

  const handleScrollRotate = (event: any) => {
    let scrollLeft = event.target.scrollLeft
    if (elementRotateRef.current) {
      const element = elementRotateRef.current;
      const right = scrollLeft > mouseMoveX
      const left = scrollLeft < mouseMoveX

      if (scrollLeft >= 530 && right) {
        scrollLeft = 0
      } else if (scrollLeft <= 0 && left) {
        scrollLeft = 530
      } else {
        scrollLeft = event.target.scrollLeft
      }

      element.scrollLeft = scrollLeft
      setMouseMoveX((data: any) => {
        const newX = scrollLeft
        return newX
      })
    }
  }

  const handleCalRotate = (numDeg?: number) => {
    const deg = numDeg ? numDeg : 0
    const calDegX = Math.round((((mouseMoveX - widthRulerHalf) / (widthRuler) * 360) + 0) % 360)
    const calDegRO = Math.round((deg) % 180)
    const sum_XRO = calDegX + calDegRO
    let sum_total = 0
    if (sum_XRO <= -180) {
      sum_total = sum_XRO + 360
    } else if (sum_XRO >= 180) {
      sum_total = sum_XRO - 360
    } else {
      sum_total = sum_XRO
    }

    if (numDeg) {
      const sumX = widthRuler / 360 * (sum_total)
      let sumX_total = 0
      if (sumX < 0) {
        sumX_total = sumX + widthRuler
      } else if (sumX > widthRuler) {
        sumX_total = sumX - widthRuler
      } else {
        sumX_total = sumX
      }
      if (elementRotateRef.current) {
        const element = elementRotateRef.current;
        element.scrollLeft = sumX_total
      }
      setMouseMoveX(Math.round(sumX_total))
    }
    setRotateImg(Math.round(sum_total))
  }

  useEffect(() => {
    handleCalRotate()
  }, [mouseMoveX])

  const handleReset = () => {
    if (elementRotateRef.current) {
      const element = elementRotateRef.current;
      setMouseMoveX(widthRulerHalf)
      setRotateImg(0)
      setFlipImg(1)
      element.scrollLeft = widthRulerHalf
    }
  }

  useEffect(() => {
    setStyleImg({
      transform: `scaleX(${flipImg})`,
      WebkitTransform: `scaleX(${flipImg})`
    })
  }, [flipImg])

  const handleMouseDown = (event: any) => {
    setOnDrags(true)
    if (elementRotateRef.current) {
      const element = elementRotateRef.current;
      setDragesStartX(event.pageX - element.offsetLeft)
      setDragesScrollLeft(element.scrollLeft)
    }
  }
  const handleMouseLeave = () => {
    setOnDrags(false)
  }

  const handleMouseMouse = (event: any) => {
    if (!onDrags) return;
    event.preventDefault();

    if (elementRotateRef.current) {
      const element = elementRotateRef.current;
      const x = event.pageX - element.offsetLeft;
      const walk = (x - dragsStartX) * 3; //scroll-fast
      element.scrollLeft = dragsScrollLeft - walk;
    }

  }
  const handleClassName = () => {
    if (props.image?.base64) {
      return 'd-none'
    } else {
      return ''
    }
  }

  const handleClassNameXray = () => {
    if (props.image?.base64 && !props.image?.xRayFileId) {
      return 'd-none'
    } else {
      return ''
    }
  }

  const handlePreview = () => {
    if (props.image) {
      return renderImage(props.image)
    } else {
      return props.imageFile
    }
  }

  useEffect(() => {
    if (props.image?.fileName) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [props.image])

  return (
    <Box className="position-relative h-100 overflow-hidden" style={{ border: `1px solid ${colors.lightGray}` }}>
      <TransformWrapper centerOnInit>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            {props.image?.fileName &&
              <>
                <Tools onClick={() => {
                  resetTransform()
                  handleReset()
                }} className={`btn-reset-position ${handleClassName()}`}>
                  {t('BUTTON.RESET')}
                </Tools>
                <Tools onClick={() => preview(handlePreview())} className={`btn-preview-position ${handleClassName()}`}>
                  <img src={icons.previewImg} alt="trash" />
                </Tools>
                <FucntionPrint
                  content={() => componentRef.current}
                  docTitle="PreviewImg"
                  removeIfram
                  trigger={() => (<Tools className={`btn-print-position ${handleClassName()}`}>
                    <ImageLabel src={printIcon} className="align-baseline" />
                  </Tools>
                  )}
                  style={'@page { size:  793.7007874px 1122.519685px;  margin: 1cm; } @media print { body { -webkit-print-color-adjust: exact; } '}
                />
              </>
            }
            {props?.image?.fileName &&
              <ToolsBox
                className={`d-flex align-items-center justify-content-center mb-2 ${handleClassNameXray()}`}
                style={{
                  left: '50%',
                  background: 'rgb(0 0 0 / 72%)',
                  color: '#ffffff',
                  borderRadius: '3px',
                  padding: '4px 12px'
                }}>
                <div className='d-flex align-items-center justify-content-center' style={{ height: '40px' }}>
                  <div style={{ margin: ' 0 10px 0 0' }}>
                    <div onClick={() => {
                      const flipCheck = () => {
                        if (flipImg === 1) {
                          return -1
                        } else {
                          return 1
                        }
                      }
                      const newFlip = flipCheck()
                      setFlipImg(newFlip)
                    }}>
                      <Flip />
                    </div>
                  </div>
                  <Line />
                  <div style={{ margin: ' 0 10px', position: 'relative' }}>
                    <ToolsBoxSeletor>
                      <div>
                        <Seletor />
                      </div>
                    </ToolsBoxSeletor>

                    <ToolsBoxScroll
                      ref={elementRotateRef}
                      style={{ cursor: 'move' }}
                      onScroll={(event: any) => handleScrollRotate(event)}
                      onMouseDown={(event) => handleMouseDown(event)}
                      onMouseLeave={() => handleMouseLeave()}
                      onMouseUp={() => handleMouseLeave()}
                      onMouseMove={(event: any) => handleMouseMouse(event)}
                    >
                      <Box ref={elementLineRotateRef} style={{ width: 'calc((179px * 4) - (3.3px * 2))', }} >
                        <BoxBallCenter >
                          <div className='BallCenter' />
                        </BoxBallCenter>

                        <div className='d-flex align-items-center justify-content-center'>
                          <div style={{ margin: " 0 0 0 0" }}>
                            <GroupLine />
                          </div>

                          <div
                            style={{ margin: " 0 -3px 0 -3px" }}
                          >
                            <GroupLine />
                          </div>
                          <div>
                            <GroupLine />
                          </div>
                          <div
                            style={{ margin: " 0 -3px 0 -3px" }}
                          >
                            <GroupLine />
                          </div>
                          <div style={{ margin: " 0 0 0 0" }}>
                            <GroupLine />
                          </div>
                        </div>
                      </Box>

                    </ToolsBoxScroll>
                  </div>
                  <Line />
                  <div style={{ margin: ' 0 0 0 10px' }}>
                    <div onClick={() => handleCalRotate(90)} >
                      <Rotate />
                    </div>
                  </div>
                </div>
              </ToolsBox>
            }
            {props?.image?.fileName &&
              <ToolsBox className={`d-flex align-items-center justify-content-center mb-2 ${handleClassName()}`} style={{ right: '-2rem' }} >
                <Tools onClick={() => zoomOut()} className="mr-1 mr-xl-2">
                  <RemoveIcon />
                </Tools>
                <Tools onClick={() => zoomIn()} className="ml-1 ml-xl-2">
                  <AddIcon />
                </Tools>
              </ToolsBox>
            }
            <TransformComponent wrapperClass="w-100 h-100" contentClass="w-100" contentStyle={{ cursor: 'move', height: '100%', backgroundColor: colors.lightGray }}>
              {props.image?.base64 && !props.image?.xRayFileId ? (
                <img src={`data:image/${props.image?.fileExtension};base64,${props.image?.base64}`} alt={props.image?.fileName} className="w-auto h-100 m-auto" onError={onImgError} />
              ) : (
                <img
                  src={props.image ? renderImage(props.image) : props.imageFile}
                  alt={props.image?.fileName}
                  className={props.type === 'LANDSCAPE' ? "m-auto" : "w-auto h-100 m-auto"}
                  style={{
                    ...(props.type === 'LANDSCAPE' && { width: '700px', height: '500px' }),
                    rotate: `${showButton ? rotateImg : 0}deg`,
                    ...styleImg
                  }}
                  onError={onImgError}
                />
              )}
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
      <div ref={componentRef} className="print-show" >
        <PdfXray staicImg={[props.image ? renderImage(props.image) : props.imageFile]} />
      </div>
    </Box>
  )
}
