import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { Image } from 'react-bootstrap'
import { Box, Typography } from '@mui/material'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'


import { icons } from 'constants/images'

/** ICON */

/** STYLE */
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

type AttibuildCard = {
  height: string | number,
  left: number,
  translateY: string | number,
  translateX: string | number,
  width: number
}
const CardBreak = styled('div')<AttibuildCard>(({ theme, height, left, translateY, translateX, width }) => ({
  position: 'absolute',
  top: `calc(${1.5}rem + ${0.125}rem)`,
  right: "100%",
  display: 'flex',
  width: width,
  flexDirection: 'column',
  justifyContent: Number(height) > 75 ? 'flex-start' : 'center',
  background: colors.lightGray,
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 4,
  fontSize: 16,
  color: colors.textPrimary,
  zIndex: 2,
  height: height,
  transform: `translateY(${translateY}px) translateX(${translateX}px)`,
  '& img': {
    width: 15,
    height: 15
  },
  '&:hover': {
    // boxShadow: statusAppointment !== 'DONE' ? `0px 3px 6px ${colors.black08}` : 'none',
    boxShadow: `0px 3px 6px ${colors.black08}`
  }
}))

const TextTime = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  marginLeft: '0',
  padding: 10,
  fontSize: 16,
}))

type CardBreakProps = {
  width?: number
  start?: any
  end?: any
  timeStart: string
  translateY?: number
  translateX?: number
}

export default function Card(props: CardBreakProps) {
  const { t } = useTranslation()
  const [height, setHeight] = useState(0)
  const [translateY, setTranslateY] = useState(0)
  const [translateX, setTranslateX] = useState(0)
  const unit = scale.rangeOfMinute

  const handleNowPoint = useCallback(() => {
    const start = moment(props.start, 'HH:mm')
    const end = moment(props.end, 'HH:mm')
    const defaultTime = moment(props.timeStart, 'HH:mm').toDate()
    const diffHeight = moment.duration(end.diff(start)).asMinutes()

    const diffTransform = moment.duration(start.diff(defaultTime)).asMinutes()
    setHeight(diffHeight * unit)

    setTranslateY(diffTransform * unit)
    setTranslateX(props?.translateX || 0)

  }, [props.start, props.end])
  useEffect(() => {
    handleNowPoint()
  }, [handleNowPoint])

  return (
    <CardBreak
      width={props?.width || 435}
      height={height}
      translateY={translateY}
      translateX={translateX}
      left={translateX}
      className={`  card-appointment`}>
      <Box>

        <TextTime className={'m-0 text-ellipsis '}>
          <Image src={icons.iconCoffeeTime} width={18} height={18} />   {`${props.start} - ${props.end} ${t('APPOINTMENT.TIME')} ${t('APPOINTMENT.BREAK')}`}
        </TextTime>

      </Box>
    </CardBreak>
  )
}
