import { menuSeconds } from 'constants/menus'
import routePromotions from 'routes/application/promotions.routes'
import routeNews from 'routes/application/news.routes'
import Applications from 'features/application/application/applications'
import routeManageWeb from './manage-web.routes'

const routeApplication = [
  {
    ...menuSeconds.REGISTER_APP,
    key: menuSeconds.REGISTER_APP.key,
    name: menuSeconds.REGISTER_APP.name,
    textName: menuSeconds.REGISTER_APP.textName,
    active: false,
    appBar: true,
    component: Applications
  },
  ...routePromotions,
  ...routeNews,
  ...routeManageWeb
]

export default routeApplication
