import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function Report(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g data-name="Group 8647">
        <path
          data-name="Path 5692"
          d="M20.782 3H8.956A2.956 2.956 0 0 0 6 5.956v23.652a2.956 2.956 0 0 0 2.956 2.956H26.7a2.956 2.956 0 0 0 2.956-2.956V11.869z"
          transform="translate(-2 -2)"
          style={{ stroke: props.color || colors.themeSecondColor, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px', fill: '#fff' }}
        />
        <path data-name="Path 5693" d="M20.983 4.266 21 8.5v3.369h7.481" transform="translate(-2.217 -2)" style={{ stroke: props.color || colors.themeSecondColor, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px', fill: '#fff' }} />
        <path data-name="Path 5694" d="M23.826 19.5H12" transform="translate(-2.088 -2.24)" style={{ fill: 'none', stroke: props.color || colors.themeSecondColor, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }} />
        <path data-name="Path 5695" d="M23.826 25.5H12" transform="translate(-2.088 -2.326)" style={{ fill: 'none', stroke: props.color || colors.themeSecondColor, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }} />
        <path data-name="Path 5696" d="M17.143 13.5H12" transform="translate(-2.088 -2.152)" style={{ fill: 'none', stroke: props.color || colors.themeSecondColor, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }} />
      </g>
      <path data-name="Rectangle 3869" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
    </svg>
  )
}
