import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'

/** STYLE */
import * as UseStyled from 'component/Home/style/modal/CancelStyled'
import 'component/Home/style/modal/styleModal.css'

interface ErrorMsg {
  username: string
  password: string
  cancelRemark: string
}

type CancelVisitProps = {
  operativeId: number
  patientVN: string
  patientTime: string
  patientFullName: string
  onReset?: any
  onSubmit?: any
  error?: ErrorMsg
  readOnly?: boolean
  onKeyUser?: (val: any) => void
  onKeyPassword?: (val: any) => void
  onKeyReasonForCancel?: (val: any) => void
  setReadOnly: (readOnly: boolean) => void
}

export default function CancelVisit(props: CancelVisitProps) {
  const { t } = useTranslation()

  return (
    <>
      <ModalCustom
        size="lg"
        modalStyle="modal-cancel-visit"
        title={t('HOME.TITLE.CANCEL_VISIT')}
        textBtnCancel={t('MODAL.CANCEL')}
        textBtnConfirm={t('MODAL.SUBMIT_AND_CONTINUE')}
        modalTheme="danger"
        onReset={props.onReset}
        onSubmit={props.onSubmit}
        component={
          <>
            <UseStyled.Title>
              {t('HOME.MODAL.CANCEL_VISIT.YOU_ARE_CANCELLING')} {t('HOME.TITLE.VN')} {props.patientVN} {props.patientTime ? `${moment(props.patientTime).format('HH.mm')} ${t('TIME_UNIT')}` : ''} ({props.patientFullName}){' '}
              {t('HOME.MODAL.CANCEL_VISIT.PLEASE_FILL_OUT_THE_DETAILS_BELOW_TO_CONTINUE')}
            </UseStyled.Title>
            <UseStyled.Body>
              <UseStyled.RowTextField onClick={() => props.setReadOnly(false)}>
                <InputTextField required label={t('HOME.MODAL.CANCEL_VISIT.USER_NAME')} inputProps={{ readOnly: props.readOnly }} onchange={props.onKeyUser} helperText={props.error?.username} disabledAutoComplete />
                <InputTextField required label={t('HOME.MODAL.CANCEL_VISIT.USER_PASSWORD')} type="password" inputProps={{ readOnly: props.readOnly }} onchange={props.onKeyPassword} helperText={props.error?.password} />
              </UseStyled.RowTextField>
              <InputTextarea required label={t('HOME.MODAL.CANCEL_VISIT.REASON_FOR_CANCEL_VISIT')} onchange={props.onKeyReasonForCancel} helperText={props.error?.cancelRemark} height={96} />
            </UseStyled.Body>
          </>
        }
      ></ModalCustom>
    </>
  )
}
