import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ServicePointInterface {
  servicePointName: string
  servicePointNameEn?: string
  status: string
}

export default class ServicePointApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('service-points', { params: { ...props } }).then((res) => res)
  }
  static findAllActive(): Promise<any> {
    return this.api.get('service-points/active').then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('service-points', playload)
  }

  static findById(id: number): Promise<ServicePointInterface> {
    return this.api.get(`service-points/${id}`)
  }

  static update(id: number, body: ServicePointInterface) {
    return this.api.patch(`service-points/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`service-points/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`service-points/${id}`)
  }
}
