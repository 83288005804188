import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/46.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  margin: '0 6px auto',
  top: '1.4%'
}))

export default function Teeth46(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: "44.339", height: "95.178", viewBox: "0 0 44.339 95.178" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <TextNumberL>46</TextNumberL>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <DivTeethL className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`lg teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`lg ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9172">

              {(isBridge && props.type === 'treatment') ?
                <g></g>
                :
                ""}
              <path
                data-name="Path 5346"
                d="M253.813 519.779c-.271 15.024.683 30.066-.664 45.064-.619 6.864-1.676 13.657-4.744 19.928a10.58 10.58 0 0 1-2.793 3.849 2.516 2.516 0 0 1-3.837-.864c-1.043-2.1-.837-4.573-.808-6.8a87.2 87.2 0 0 0-4.5-28.8 9.2 9.2 0 0 0-.711-1.665.718.718 0 0 0-1.332.261c-1.822 13.544-1.889 27.436-8.315 39.939a2.422 2.422 0 0 1-4.13.275 8.015 8.015 0 0 1-1.051-4.316c-.574-11.247-.786-22.506-1.457-33.747-.767-12.787-1.65-25.6-6.374-37.82v-9.752c2.082-4.557 2.584-4.428 7.193-3.255 1.5-.8 3.055-1.637 5.7-3.061-.168.251-.335.5-.5.728v.007c-.064.1-.135.193-.206.29l-.006.013c-1.489 2.133-2.572 3.59-2.952 5.427-1.727 8.385.522 11.743 8.856 13.876 11.182 2.855 13.735-.709 19.729-12.639.361-.715.664-1.463 1-2.2 1.308.374 1.379 1.863 2.43 2.352l.574 6.6a18.305 18.305 0 0 0-1.102 6.31z"
                transform="translate(-211.729 -496.814)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6496"
                  d="M253.746 524.93c-.125 2.594.75 24.915-.6 39.913-.619 6.864-1.676 13.657-4.744 19.928a10.58 10.58 0 0 1-2.793 3.849 2.516 2.516 0 0 1-3.837-.864c-1.043-2.1-.837-4.573-.808-6.8a87.2 87.2 0 0 0-4.5-28.8 9.2 9.2 0 0 0-.711-1.665.718.718 0 0 0-1.332.261c-1.822 13.544-1.889 27.436-8.315 39.939a2.422 2.422 0 0 1-4.13.275 8.015 8.015 0 0 1-1.051-4.316c-.574-11.247-.786-22.506-1.457-33.747-.582-9.705-1.327-19.783-3.743-29.533a12.28 12.28 0 0 0 6.275 6.373c2.828.875 1.594.734 4.547 1.016a15 15 0 0 0 4.641-.656 16.216 16.216 0 0 0 3.719-1.625 9.636 9.636 0 0 1 4.047-2.25 9.259 9.259 0 0 1 3.975-.471 14.308 14.308 0 0 1 3.4 1 15.229 15.229 0 0 0 3.438.672 5.746 5.746 0 0 0 2.281-.672c1.682-1.296 1.432-1.717 1.698-1.827z"
                  transform="translate(-211.729 -496.814)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 5192">
                <path
                  data-name="Path 5347"
                  d="M220.832 528.456a12.226 12.226 0 0 1-6.661-7.835c-.7-2.414-1.571-5.34-2.118-6.868-3.2-8.962 0-13.885 1.611-14.7s4.028-4.174 12.085-1.527c3.583 1.177 4.592-1.14 6.606-1.06 3.626.145 3.707 1.333 5.317.915 2.9-.753 5.478-2.365 9.829-1.559s5.858 1.368 7.2 6.887c1.215 5-1.419 13.8-1.278 17.571a6.382 6.382 0 0 1-.762 3.465c-1.018 1.754-3.107 3.511-7.253 1.726a10.459 10.459 0 0 0-10.807 1.251 11.192 11.192 0 0 1-2.586 1.494c-2.949 1.199-6.773 2.202-11.183.24z"
                  transform="translate(-210.683 -495.602)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5348"
                  d="M221.619 528.841a11.419 11.419 0 0 1-6.22-7.316c-.653-2.255-1.468-4.987-1.977-6.414-2.991-8.369 0-12.966 1.5-13.727s3.761-3.9 11.284-1.426c3.346 1.1 4.289-1.065 6.169-.99 3.386.136 3.461 1.245 4.965.854 2.709-.7 5.116-2.208 9.178-1.455s5.47 1.277 6.722 6.431c1.134 4.671-1.325 12.891-1.194 16.408a5.961 5.961 0 0 1-.712 3.236c-.951 1.638-2.9 3.278-6.772 1.612a9.768 9.768 0 0 0-10.092 1.168 10.435 10.435 0 0 1-2.414 1.395c-2.748 1.12-6.319 2.056-10.437.224z"
                  transform="translate(-211.201 -496.512)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5349"
                  d="M223.449 529.082a9.991 9.991 0 0 1-5.443-6.4c-.571-1.972-1.284-4.364-1.73-5.612-2.617-7.323 0-11.345 1.317-12.011s3.291-3.41 9.874-1.248c2.927.962 3.752-.932 5.4-.866 2.963.119 3.029 1.089 4.344.747 2.37-.616 4.477-1.932 8.031-1.274s4.786 1.118 5.882 5.627c.993 4.087-1.159 11.28-1.044 14.357a5.218 5.218 0 0 1-.623 2.831c-.832 1.433-2.539 2.868-5.926 1.41a8.546 8.546 0 0 0-8.83 1.021 9.132 9.132 0 0 1-2.113 1.221 11.079 11.079 0 0 1-9.139.197z"
                  transform="translate(-212.273 -497.961)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />

                {(isToothRoot && props.type === 'treatment') ?
                  <>
                    <path
                      data-name="Path 5347"
                      d="M220.832 528.456a12.226 12.226 0 0 1-6.661-7.835c-.7-2.414-1.571-5.34-2.118-6.868-3.2-8.962 0-13.885 1.611-14.7s4.028-4.174 12.085-1.527c3.583 1.177 4.592-1.14 6.606-1.06 3.626.145 3.707 1.333 5.317.915 2.9-.753 5.478-2.365 9.829-1.559s5.858 1.368 7.2 6.887c1.215 5-1.419 13.8-1.278 17.571a6.382 6.382 0 0 1-.762 3.465c-1.018 1.754-3.107 3.511-7.253 1.726a10.459 10.459 0 0 0-10.807 1.251 11.192 11.192 0 0 1-2.586 1.494c-2.949 1.199-6.773 2.202-11.183.24z"
                      transform="translate(-210.683 -495.602)"
                      style={{ fill: 'none', opacity: '.3' }}
                    />
                    <path
                      data-name="Path 6557"
                      d="M8.112 10.316c-.018.473-.829 10.407-1.219 21.479-.57 16.18-.754 35.88-.329 36.408-.047.414.185-19.309 1.2-36.06C8.45 20.8 10 10.853 10.065 10.395c-.453.015-1.53-.079-1.953-.079z"
                      transform="translate(6.078 24.038)"
                      style={{

                        fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                        stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                      }}
                    />
                    <path
                      data-name="Path 6558"
                      d="M8.133 10.548c.018.473.269 13.209.66 24.282.57 16.18-2.691 34.475-3.116 35a161.578 161.578 0 0 0 2.35-35C7.34 23.485 5.818 10.071 5.753 9.613c.453.016 1.905.829 2.38.935z"
                      transform="translate(27.281 20.038)"
                      style={{

                        fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                        stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                      }}
                    />
                  </> : ''}
                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9467">
                      <path data-name="Path 6581" d="m38.246 7.215-40 68.285" transform="translate(4.935 -4.224)" style={{ stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px', fill: 'none' }} />
                      <path data-name="Path 6583" d="m-1.754 7.215 40 68.285" transform="translate(4.935 -4.224)" style={{ stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px', fill: 'none' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') && (
                  <>
                    <defs>
                      <linearGradient id="h129eii9ka" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="2ew2rs6iwb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9359">
                      <path data-name="Path 5346" d="M253.813 519.779c-.271 15.024.683 30.066-.664 45.064-.619 6.864-1.676 13.657-4.744 19.928a10.58 10.58 0 0 1-2.793 3.849 2.516 2.516 0 0 1-3.837-.864c-1.043-2.1-.837-4.573-.808-6.8a87.2 87.2 0 0 0-4.5-28.8 9.2 9.2 0 0 0-.711-1.665.718.718 0 0 0-1.332.261c-1.822 13.544-1.889 27.436-8.315 39.939a2.422 2.422 0 0 1-4.13.275 8.015 8.015 0 0 1-1.051-4.316c-.574-11.247-.786-22.506-1.457-33.747-.767-12.787-1.65-25.6-6.374-37.82v-9.752c2.082-4.557 2.584-4.428 7.193-3.255 1.5-.8 3.055-1.637 5.7-3.061-.168.251-.335.5-.5.728v.007c-.064.1-.135.193-.206.29l-.006.013c-1.489 2.133-2.572 3.59-2.952 5.427-1.727 8.385.522 11.743 8.856 13.876 11.182 2.855 13.735-.709 19.729-12.639.361-.715.664-1.463 1-2.2 1.308.374 1.379 1.863 2.43 2.352l.574 6.6a18.305 18.305 0 0 0-1.102 6.31z" transform="translate(-211.219 -496.813)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <g data-name="Group 5192">
                        <path data-name="Path 5347" d="M220.832 528.456a12.226 12.226 0 0 1-6.661-7.835c-.7-2.414-1.571-5.34-2.118-6.868-3.2-8.962 0-13.885 1.611-14.7s4.028-4.174 12.085-1.527c3.583 1.177 4.592-1.14 6.606-1.06 3.626.145 3.707 1.333 5.317.915 2.9-.753 5.478-2.365 9.829-1.559s5.858 1.368 7.2 6.887c1.215 5-1.419 13.8-1.278 17.571a6.382 6.382 0 0 1-.762 3.465c-1.018 1.754-3.107 3.511-7.253 1.726a10.459 10.459 0 0 0-10.807 1.251 11.192 11.192 0 0 1-2.586 1.494c-2.949 1.199-6.773 2.202-11.183.24z" transform="translate(-210.173 -495.601)" style={{ fill: '#efede7' }} />
                        <path data-name="Path 5348" d="M221.619 528.841a11.419 11.419 0 0 1-6.22-7.316c-.653-2.255-1.468-4.987-1.977-6.414-2.991-8.369 0-12.966 1.5-13.727s3.761-3.9 11.284-1.426c3.346 1.1 4.289-1.065 6.169-.99 3.386.136 3.461 1.245 4.965.854 2.709-.7 5.116-2.208 9.178-1.455s5.47 1.277 6.722 6.431c1.134 4.671-1.325 12.891-1.194 16.408a5.961 5.961 0 0 1-.712 3.236c-.951 1.638-2.9 3.278-6.772 1.612a9.768 9.768 0 0 0-10.092 1.168 10.435 10.435 0 0 1-2.414 1.395c-2.748 1.12-6.319 2.056-10.437.224z" transform="translate(-210.691 -496.511)" style={{ fill: '#f7f7f7' }} />
                        <path data-name="Path 5349" d="M223.449 529.082a9.991 9.991 0 0 1-5.443-6.4c-.571-1.972-1.284-4.364-1.73-5.612-2.617-7.323 0-11.345 1.317-12.011s3.291-3.41 9.874-1.248c2.927.962 3.752-.932 5.4-.866 2.963.119 3.029 1.089 4.344.747 2.37-.616 4.477-1.932 8.031-1.274s4.786 1.118 5.882 5.627c.993 4.087-1.159 11.28-1.044 14.357a5.218 5.218 0 0 1-.623 2.831c-.832 1.433-2.539 2.868-5.926 1.41a8.546 8.546 0 0 0-8.83 1.021 9.132 9.132 0 0 1-2.113 1.221 11.079 11.079 0 0 1-9.139.197z" transform="translate(-211.763 -497.96)" style={{ fill: colors.white }} />
                      </g>
                      <path data-name="Path 5347" d="M220.832 528.456a12.226 12.226 0 0 1-6.661-7.835c-.7-2.414-1.571-5.34-2.118-6.868-3.2-8.962 0-13.885 1.611-14.7s4.028-4.174 12.085-1.527c3.583 1.177 4.592-1.14 6.606-1.06 3.626.145 3.707 1.333 5.317.915 2.9-.753 5.478-2.365 9.829-1.559s5.858 1.368 7.2 6.887c1.215 5-1.419 13.8-1.278 17.571a6.382 6.382 0 0 1-.762 3.465c-1.018 1.754-3.107 3.511-7.253 1.726a10.459 10.459 0 0 0-10.807 1.251 11.192 11.192 0 0 1-2.586 1.494c-2.949 1.199-6.773 2.202-11.183.24z" transform="translate(-210.36 -495.602)" style={{ opacity: '.3', fill: 'none' }} />
                      <g data-name="Group 9398">
                        <g data-name="Group 9396" transform="translate(16.84 12.053)">
                          <g data-name="Rectangle 3927" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.602" height="11.602" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.602" height="10.602" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.119 41.119 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(-6.653 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(3.089 2.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(3.289 2.015)" style={{ fill: 'url(#h129eii9ka)' }} />
                          <g data-name="Group 9397" transform="translate(0 .5)">
                            <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(3.289 6.215)" style={{ fill: '#cbcccc' }} />
                            <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(3.289 6.015)" style={{ fill: 'url(#2ew2rs6iwb)' }} />
                          </g>
                        </g>
                        <path data-name="Path 6488" d="M-3.687 8.605a75.637 75.637 0 0 0 43.9.026" transform="translate(4.409 5.377)" style={{ strokeLinecap: 'round', stroke: '#b5b7b7', fill: 'none' }} />
                        <path data-name="Path 6486" d="M-3.581 8.61s21.37 7.831 43.992.141" transform="translate(4.223 4.553)" style={{ stroke: '#babbbb', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </>
                ) || ""}

                {(isFixture && props.type === 'treatment') ? (
                  <>
                    <defs>
                      <linearGradient id="crizlk5s3a" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="fdn4ektefb" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9500">
                      <g data-name="Group 5192">
                        <path data-name="Path 5347" d="M220.832 528.456a12.226 12.226 0 0 1-6.661-7.835c-.7-2.414-1.571-5.34-2.118-6.868-3.2-8.962 0-13.885 1.611-14.7s4.028-4.174 12.085-1.527c3.583 1.177 4.592-1.14 6.606-1.06 3.626.145 3.707 1.333 5.317.915 2.9-.753 5.478-2.365 9.829-1.559s5.858 1.368 7.2 6.887c1.215 5-1.419 13.8-1.278 17.571a6.382 6.382 0 0 1-.762 3.465c-1.018 1.754-3.107 3.511-7.253 1.726a10.459 10.459 0 0 0-10.807 1.251 11.192 11.192 0 0 1-2.586 1.494c-2.949 1.199-6.773 2.202-11.183.24z" transform="translate(-210.496 -495.601)" style={{ fill: '#efede7' }} />
                        <path data-name="Path 5348" d="M221.619 528.841a11.419 11.419 0 0 1-6.22-7.316c-.653-2.255-1.468-4.987-1.977-6.414-2.991-8.369 0-12.966 1.5-13.727s3.761-3.9 11.284-1.426c3.346 1.1 4.289-1.065 6.169-.99 3.386.136 3.461 1.245 4.965.854 2.709-.7 5.116-2.208 9.178-1.455s5.47 1.277 6.722 6.431c1.134 4.671-1.325 12.891-1.194 16.408a5.961 5.961 0 0 1-.712 3.236c-.951 1.638-2.9 3.278-6.772 1.612a9.768 9.768 0 0 0-10.092 1.168 10.435 10.435 0 0 1-2.414 1.395c-2.748 1.12-6.319 2.056-10.437.224z" transform="translate(-211.014 -496.511)" style={{ fill: '#f7f7f7' }} />
                        <path data-name="Path 5349" d="M223.449 529.082a9.991 9.991 0 0 1-5.443-6.4c-.571-1.972-1.284-4.364-1.73-5.612-2.617-7.323 0-11.345 1.317-12.011s3.291-3.41 9.874-1.248c2.927.962 3.752-.932 5.4-.866 2.963.119 3.029 1.089 4.344.747 2.37-.616 4.477-1.932 8.031-1.274s4.786 1.118 5.882 5.627c.993 4.087-1.159 11.28-1.044 14.357a5.218 5.218 0 0 1-.623 2.831c-.832 1.433-2.539 2.868-5.926 1.41a8.546 8.546 0 0 0-8.83 1.021 9.132 9.132 0 0 1-2.113 1.221 11.079 11.079 0 0 1-9.139.197z" transform="translate(-212.086 -497.96)" style={{ fill: colors.white }} />
                      </g>
                      <path data-name="Path 5347" d="M220.832 528.456a12.226 12.226 0 0 1-6.661-7.835c-.7-2.414-1.571-5.34-2.118-6.868-3.2-8.962 0-13.885 1.611-14.7s4.028-4.174 12.085-1.527c3.583 1.177 4.592-1.14 6.606-1.06 3.626.145 3.707 1.333 5.317.915 2.9-.753 5.478-2.365 9.829-1.559s5.858 1.368 7.2 6.887c1.215 5-1.419 13.8-1.278 17.571a6.382 6.382 0 0 1-.762 3.465c-1.018 1.754-3.107 3.511-7.253 1.726a10.459 10.459 0 0 0-10.807 1.251 11.192 11.192 0 0 1-2.586 1.494c-2.949 1.199-6.773 2.202-11.183.24z" transform="translate(-210.683 -495.602)" style={{ opacity: '.3', fill: 'none' }} />
                      <g data-name="Group 9519">
                        <g data-name="Path 6490" transform="translate(13.672 31.195)" style={{ fill: 'url(#crizlk5s3a)' }}>
                          <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                          <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(2.726 25.992)" style={{ strokeLinejoin: 'round', fill: 'url(#fdn4ektefb)' }}>
                          <path d="M1.602 7.569c-.113 0-.118 0-.207-.53C1.28 6.348 1.035 4.906.1 1.9.108.906.942.1 1.965.1H39.31c1.025 0 1.86.807 1.866 1.802-.496 2.006-.756 3.303-.928 4.162-.3 1.493-.307 1.493-.722 1.493h-.094l-.122-.001H1.965c-.1 0-.182.004-.248.008-.045.003-.084.005-.115.005z" style={{ stroke: 'none' }} />
                          <path d="M1.965.2C1.002.2.217.953.2 1.884c.934 3.003 1.177 4.445 1.294 5.14.034.202.064.38.09.445h.018c.028 0 .066-.002.11-.005.067-.004.15-.008.253-.008H39.31l.216.001c.24 0 .26-.02.309-.118.092-.183.171-.578.315-1.295.172-.858.432-2.152.926-4.154-.013-.934-.8-1.69-1.766-1.69H1.965m0-.2H39.31c1.086 0 1.966.857 1.966 1.914-1.478 5.981-.88 5.742-1.966 5.742H1.965c-1.085 0 0 .568-1.965-5.742C0 .857.88 0 1.965 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(8.161 16.331)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                        <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(8.448 21.53)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                        <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(8.896 44.057)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                        <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(8.704 26.073)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                        <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(8.704 32.073)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                        <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(8.704 38.073)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                        <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(8.895 49.695)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </>
                ) : ""}
              </g>
            </g>
          </svg>}
      </DivTeethL>
    </Div>
  )
}
