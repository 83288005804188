import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import { RowFormManagement } from 'features/counter/register/usedStyles'

/** UTILS || SLICE */
import { getBoolean } from 'utils/app.utils'

/** CONSTANT */
import { colors } from 'constants/theme'

/** COMPONENT */
import InputCheckbox from 'component/Input/InputCheckbox'
import _ from 'lodash'

export interface FormManagementProp {
  disabled?: boolean
}

export default function FormManagement(props: FormManagementProp) {
  const { t } = useTranslation()
  const { register, setValue } = useFormContext()

  const form = useSelector((state: any) => state.register.formRegister)
  const [formRegister, setFormRegister]: any = useState(form)
  const [checkAll, setCheckAll]: any = useState([])

  const inputForms = [
    {
      text: t('REGISTER.TITLE.INFORMATION'),
      disabled: true,
      required: true,
      key: 'generalInformation',
      inputNames: [
        { text: t('REGISTER.FORM.CN_NUMBER'), key: 'cnNumber', disabled: true, required: true },
        { text: t('REGISTER.FORM.BRANCH_NUMBER'), key: 'branchCnNumber', disabled: true, required: true },
        { text: t('REGISTER.FORM.FIRST_NAME'), key: 'firstnameEn', disabled: false, required: false },
        { text: t('REGISTER.FORM.LAST_NAME'), key: 'lastnameEn', disabled: false, required: false },
        { text: t('REGISTER.FORM.PREFIXES'), key: 'prefixId', disabled: true, required: true },
        { text: t('REGISTER.FORM.FIRST_NAME_TH'), key: 'firstname', disabled: true, required: true },
        { text: t('REGISTER.FORM.LAST_NAME_TH'), key: 'lastname', disabled: true, required: true },
        { text: t('REGISTER.FORM.DISPLAY_NAME'), key: 'displayName', disabled: false, required: false },
        { text: t('REGISTER.FORM.ID_CARD'), key: 'idCard', disabled: true, required: true },
        { text: t('REGISTER.FORM.PASSPORT'), key: 'passport', disabled: false, required: false },
        { text: t('REGISTER.FORM.WORK_PERMIT_NUMBER'), key: 'workPermitNumber', disabled: false, required: false },
        { text: t('REGISTER.FORM.GENDER'), key: 'gender', disabled: false, required: false },
        { text: t('REGISTER.FORM.BIRTHDAY'), key: 'dateOfBirth', disabled: true, required: true },
        { text: t('REGISTER.FORM.NATIONALITY'), key: 'nationalityId', disabled: false, required: false },
        { text: t('REGISTER.FORM.ORIGINS'), key: 'originId', disabled: false, required: false },
        { text: t('REGISTER.FORM.RELIGION'), key: 'religion', disabled: false, required: false },
        { text: t('REGISTER.FORM.MARITAL'), key: 'maritalStatus', disabled: false, required: false },
        { text: t('REGISTER.FORM.CAREER'), key: 'careerId', disabled: false, required: false },
        { text: t('REGISTER.FORM.SCHOOL'), key: 'school', disabled: false, required: false },
        { text: t('REGISTER.FORM.ACCESS_CHANNEL'), key: 'accessChannelId', disabled: false, required: false },
        { text: t('REGISTER.FORM.SERVICE_FACTOR'), key: 'serviceFactorId', disabled: false, required: false },

        /** ช่องทางติดต่อ */
        {
          text: t('REGISTER.TITLE.CONTACT'),
          disabled: true,
          required: true,
          key: '',
          inputNames: [
            { text: t('REGISTER.FORM.PHONE'), key: 'phone', disabled: true, required: true },
            { text: t('REGISTER.FORM.TEL'), key: 'telephone', disabled: false, required: false },
            { text: t('REGISTER.FORM.LINE'), key: 'line', disabled: false, required: false },
            { text: t('REGISTER.FORM.EMAIL'), key: 'email', disabled: false, required: false },
            { text: t('REGISTER.FORM.FACEBOOK'), key: 'facebook', disabled: false, required: false }
          ]
        }
      ]
    },
    {
      text: t('REGISTER.TITLE.HEALTH_INFORMATION'),
      disabled: false,
      required: false,
      key: 'healthInformation',
      inputNames: [
        { text: t('REGISTER.FORM.PATIENT_TYPE'), key: 'patientTypeId', disabled: false, required: false },
        { text: t('REGISTER.FORM.BLOOD_TYPE'), key: 'bloodType', disabled: false, required: false },
        { text: t('REGISTER.FORM.FREQUENCY_CIGARETTE'), key: 'frequencyCigarette', disabled: false, required: false },
        { text: t('REGISTER.FORM.FREQUENCY_ALCOHOL'), key: 'frequencyAlcohol', disabled: false, required: false },
        { text: t('REGISTER.FORM.PREGNANT_HISTORY'), key: 'pregnantHistory', disabled: false, required: false },
        { text: t('REGISTER.FORM.SURGERY_HISTORY'), key: 'surgeryHistory', disabled: false, required: false },
        { text: t('REGISTER.FORM.SURGERY_DATE'), key: 'surgeryDate', disabled: false, required: false },
        { text: t('REGISTER.FORM.DISEASE'), key: 'disease', disabled: false, required: false },
        { text: t('REGISTER.FORM.MEDICINE_USE'), key: 'medicineUse', disabled: false, required: false },
        { text: t('REGISTER.FORM.BE_ALLERGIC'), key: 'beAllergic', disabled: false, required: false },
        { text: t('REGISTER.FORM.IMPORTANT_SYMPTOMS'), key: 'importantSymptoms', disabled: false, required: false },
        { text: t('REGISTER.FORM.SYMPTOMS'), key: 'symptoms', disabled: false, required: false },
        { text: t('REGISTER.FORM.IS_PREGNANT'), key: 'isPregnant', disabled: false, required: false },
        { text: t('REGISTER.FORM.IS_RECUPERATE'), key: 'isRecuperate', disabled: false, required: false },
        { text: t('REGISTER.FORM.IS_MEDICAL_CERTIFICATE'), key: 'isMedicalCertificate', disabled: false, required: false },
        { text: t('REGISTER.FORM.IS_INSURE'), key: 'isInsure', disabled: false, required: false },
      ]
    },
    { text: t('REGISTER.TITLE.RIGHT_TO_TREATMENT'), key: 'treatmentRight', disabled: false, required: false, inputNames: [] },
    {
      text: t('REGISTER.TITLE.ADDRESS_ID_CARD'),
      disabled: false,
      required: false,
      key: 'addressCard',
      inputNames: [
        { text: t('REGISTER.FORM.COUNTRY'), key: 'countryId', disabled: false, required: false },
        { text: t('SELECT_ADDRESS'), key: 'address', disabled: false, required: false },
        { text: t('REGISTER.FORM.ADDRESS_DETAILS'), key: 'address_detail', disabled: false, required: false },
        { text: t('REGISTER.FORM.ADDRESS_DETAILS_EN'), key: 'address_detail_en', disabled: false, required: false }
      ]
    },
    {
      text: t('REGISTER.TITLE.ADDRESS_AT_WORK'),
      disabled: false,
      required: false,
      key: 'addressWork',
      inputNames: [
        { text: t('REGISTER.FORM.COUNTRY'), key: 'work_countryId', disabled: false, required: false },
        { text: t('SELECT_ADDRESS'), key: 'work_address', disabled: false, required: false },
        { text: t('REGISTER.FORM.ADDRESS_DETAILS'), key: 'work_address_detail', disabled: false, required: false }
      ]
    },
    { text: t('REGISTER.TITLE.PARENTS'), key: 'parent', disabled: false, required: false, inputNames: [] },
    {
      text: t('REGISTER.TITLE.VITAL_SIGN'),
      disabled: false,
      required: false,
      key: 'vitalSign',
      inputNames: [
        { text: t('REGISTER.FORM.WEIGHT'), key: 'weight', disabled: false, required: false },
        { text: t('REGISTER.FORM.HEIGHT'), key: 'height', disabled: false, required: false },
        { text: t('REGISTER.FORM.SYSTOLIC_BLOOD_PRESSURE'), key: 'bloodPressure', disabled: false, required: false },
        { text: t('REGISTER.FORM.WAISTLINE'), key: 'waistline', disabled: false, required: false },
        { text: t('REGISTER.FORM.TEMPERATURE'), key: 'temperature', disabled: false, required: false },
        { text: t('REGISTER.FORM.PULSE_RATE'), key: 'pulseRate', disabled: false, required: false },
        { text: t('REGISTER.FORM.HEART_RATE'), key: 'heartRate', disabled: false, required: false },
        { text: 'Reg/lrreq', key: 'regIrreq', disabled: false, required: false },
        { text: 'RR', key: 'rr', disabled: false, required: false },
        { text: 'O2Sat(%)', key: 'o2Sat', disabled: false, required: false },
        { text: t('REGISTER.FORM.BMI'), key: 'bmi', disabled: false, required: false },
        { text: t('REGISTER.FORM.BSA'), key: 'bsa', disabled: false, required: false }
      ]
    }
  ]

  const handleChange = (keyForm: any, checkboxId: string) => {
    const clsHead: any = document.getElementById(checkboxId)
    setFormRegister((values: any) => ({ ...values, [keyForm]: clsHead.checked }))
  }

  const handleChangeHeader = (keyForm: any, checkboxId: string) => {
    const clsHead: any = document.getElementById(checkboxId)
    const inputChecked: any = []
    for (const header of inputForms) {
      if (header.key === keyForm) {
        inputChecked[header.key] = clsHead.checked
        if (header.inputNames.length === 0) continue
        if (header.inputNames !== undefined) {
          for (const inputs of header.inputNames) {
            if (inputs.key) {
              inputChecked[inputs.key] = clsHead.checked
              setValue(inputs.key, getBoolean(clsHead.checked))
            }
            if (inputs.inputNames !== undefined) {
              for (const inputThird of inputs.inputNames) {
                inputChecked[inputThird.key] = clsHead.checked
                setValue(inputThird.key, getBoolean(clsHead.checked))
              }
            }
          }
        }
      }
    }
    setFormRegister((values: any) => ({ ...values, ...inputChecked }))
  }

  const loadFormRegister = useCallback(async () => {
    for (const header of inputForms) {
      if (header.inputNames.length === 0) continue
      let allMenu = 0
      let menuChecked = 0
      if (header.inputNames !== undefined) {
        for (const inputs of header.inputNames) {
          if (inputs.key) allMenu++
          if (getBoolean(formRegister[inputs.key])) menuChecked++
          if (inputs.inputNames !== undefined) {
            for (const inputThird of inputs.inputNames) {
              allMenu++
              if (getBoolean(formRegister[inputThird.key])) menuChecked++
            }
          }
        }
      }
      setCheckAll((values: any) => ({ ...values, [header.key]: { all: allMenu, checked: menuChecked } }))
    }
  }, [formRegister])

  useEffect(() => {
    loadFormRegister()
  }, [loadFormRegister])

  useEffect(() => {
    afterHandleChange()
  }, [handleChange])

  const afterHandleChange = () => {
    _.map(inputForms, (i: any) => {
      if (checkAll[i.key]) {
        checkAll[i.key].checked === 0 ? setValue(i.key, false) : setValue(i.key, true)
      }
    })
  }

  return (
    <RowFormManagement>
      {inputForms.map((header: any, iHeader: number) => {
        return (
          <>
            <Col className={'mt-3 header-tab'} key={`section-header-${iHeader}`} sm={12} style={{ backgroundColor: colors.themeSecondColor10 }}>
              <input
                type="hidden"
                {...register(header.key)}
                value={(header.inputNames.length === 0 && getBoolean(formRegister[header.key])) || (header.inputNames.length && checkAll[header.key]?.checked > 0 && true) || getBoolean(formRegister[header.key])}
              />
              <InputCheckbox
                className={`checkbox-input-${iHeader} ${header.required ? 'required' : ''} title`}
                id={`checkbox-input-${iHeader}`}
                key={`checkbox-input-${iHeader}`}
                label={header.text}
                disabled={header.disabled}
                onChange={() => {
                  setValue(header.key, !getBoolean(formRegister[header.key]))
                  handleChangeHeader(header.key, `checkbox-input-${iHeader}`)
                }}
                indeterminate={header.inputNames.length && checkAll[header.key]?.checked > 0 && checkAll[header.key]?.all !== checkAll[header.key]?.checked && true}
                checked={(header.inputNames.length === 0 && getBoolean(formRegister[header.key])) || (header.inputNames.length && checkAll[header.key]?.all === checkAll[header.key]?.checked && true)}
              />
            </Col>
            {header.inputNames !== undefined && (
              <Row key={`section-detail-${iHeader}`} className={'row-menu'}>
                {header.inputNames !== undefined &&
                  header.inputNames.map((inputs: any, iDetail: number) => {
                    return inputs.inputNames === undefined ? (
                      <Col
                        key={`col-input-${iHeader}-${iDetail}`}
                        xs={12}
                        sm={inputs.key === 'address' || inputs.key === 'address_detail_en' || inputs.key === 'work_address' || inputs.key === 'isRecuperate' || inputs.key === 'isMedicalCertificate' || inputs.key === 'isInsure' || inputs.key === 'workPermitNumber' ? 12 : 6}
                        lg={inputs.key === 'address' || inputs.key === 'address_detail_en' || inputs.key === 'workPermitNumber' ? 8 : 4}
                        xl={inputs.key === 'address' || inputs.key === 'address_detail_en' || inputs.key === 'work_address' || inputs.key === 'isRecuperate' || inputs.key === 'isMedicalCertificate' || inputs.key === 'isInsure' || inputs.key === 'workPermitNumber' ? 6 : 3}
                      >
                        <input type="hidden" {...register(inputs.key)} value={getBoolean(formRegister[inputs.key]).toString()} />
                        <InputCheckbox
                          className={`mr-0 checkbox-input-${iHeader} checkbox-input-${iHeader}-${iDetail} ${inputs.required ? 'required' : ''}`}
                          id={`checkbox-input-${iHeader}-${iDetail}`}
                          key={`checkbox-input-${iHeader}-${iDetail}`}
                          checked={getBoolean(formRegister[inputs.key])}
                          label={inputs.text}
                          disabled={inputs.disabled}
                          onChange={() => {
                            setValue(inputs.key, !getBoolean(formRegister[inputs.key]))
                            handleChange(inputs.key, `checkbox-input-${iHeader}-${iDetail}`)
                          }}
                        />
                      </Col>
                    ) : (
                      <div key={`col-input-${iHeader}-${iDetail}`} className={'mt-2'}>
                        <span style={{ fontWeight: 500 }}>{inputs.text}</span>
                        <Row>
                          {inputs.inputNames.map((inputThird: any, iInputThird: number) => (
                            <Col key={`col-input-${iHeader}-${iDetail}-${iInputThird}`} xs={12} sm={6} lg={4} xl={3}>
                              <input type="hidden" {...register(inputThird.key)} value={getBoolean(formRegister[inputThird.key]).toString()} />
                              <InputCheckbox
                                className={`checkbox-input-${iHeader} checkbox-input-${iHeader}-${iDetail}-${iInputThird}  ${inputThird.required ? 'required' : ''}`}
                                id={`checkbox-input-${iHeader}-${iDetail}-${iInputThird}`}
                                key={`checkbox-input-${iHeader}-${iDetail}-${iInputThird}`}
                                disabled={inputThird.disabled}
                                checked={getBoolean(formRegister[inputThird.key])}
                                label={inputThird.text}
                                onChange={() => {
                                  setValue(inputThird.key, !getBoolean(formRegister[inputThird.key]))
                                  handleChange(inputThird.key, `checkbox-input-${iHeader}-${iDetail}-${iInputThird}`)
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )
                  })}
              </Row>
            )}
          </>
        )
      })}
    </RowFormManagement>
  )
}
