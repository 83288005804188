import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'

export interface FormCareerInterface {
  error?: any
  resetErrorMessage: () => void
}
export default function FormCareer(props: FormCareerInterface) {
  const { t } = useTranslation()
  const { register } = useFormContext()

  const [careerName, setCareerName] = useState('')
  const [careerNameEn, setCareerNameEn] = useState('')

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} className={'w-100'}>
          <InputTextField
            key="career-name"
            register={register(`careerName`, {
              value: careerName,
              onChange: (event) => {
                setCareerName(event.target.value)
              }
            })}
            onchange={(event) => {
              setCareerName(event.target.value)
              props.resetErrorMessage()
            }}
            onkeypress={(event) => {
              setCareerName(event.target.value)
              props.resetErrorMessage()
            }}
            value={careerName}
            label={t('CAREER.INPUT.CAREER_NAME')}
            helperText={props.error.CAREER_NAME || props.error.IS_DUPLICATE_CAREER_NAME || props.error.CAREER_NAME_STRING_BASE || props.error.CAREER_NAME_STRING_EMPTY || props.error.CAREER_NAME_MAXIMUM_LENGTH || props.error.CAREER_NAME_ANY_REQUIRED}
            required
          />
        </Grid>
        <Grid item xs={12} className={'w-100'}>
          <InputTextField
            key="career-name-en"
            register={register(`careerNameEn`, {
              value: careerNameEn,
              onChange: (event) => {
                setCareerNameEn(event.target.value)
              }
            })}
            onchange={(event) => {
              setCareerNameEn(event.target.value)
              props.resetErrorMessage()
            }}
            onkeypress={(event) => {
              setCareerNameEn(event.target.value)
              props.resetErrorMessage()
            }}
            value={careerNameEn}
            helperText={
              props.error.CAREER_NAME_EN ||
              props.error.IS_DUPLICATE_CAREER_NAME_EN ||
              props.error.CAREER_NAME_EN_STRING_BASE ||
              props.error.CAREER_NAME_EN_STRING_EMPTY ||
              props.error.CAREER_NAME_EN_MAXIMUM_LENGTH ||
              props.error.CAREER_NAME_EN_ANY_REQUIRED
            }
            label={t('CAREER.INPUT.CAREER_NAME_EN')}
            required={false}
          />
        </Grid>
      </Grid>
    </div>
  )
}
