import { colors } from 'constants/theme'

export default function Settings(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props?.width || "32"} height={props?.height || "32"} viewBox="0 0 32 32">
      <g data-name="Group 8648">
        <path
          data-name="Path 6273"
          d="m3.6 20.018 3.136 5.512 3.6-1.276 2.089 1.566.555 3.307h6.639l.7-3.887 2.263-.986 3.307 1.276 3.1-5.511-2.747-2.495V14.8l2.734-2.5-3.1-5.163-3.887 1.1-2.03-1.1-.348-3.845h-6.635l-.555 3.845-2.089 1.1-3.307-1.1L3.6 12.593l3.133 2.785v2.147z"
          transform="translate(-1.605 -.295)"
          style={{ fill: props?.strokeColor || '#fff' }}
        />
        <path
          data-name="Path 6273"
          d="m3.6 20.018 3.136 5.512 3.6-1.276 2.089 1.566.555 3.307h6.639l.7-3.887 2.263-.986 3.307 1.276 3.1-5.511-2.747-2.495V14.8l2.734-2.5-3.1-5.163-3.887 1.1-2.03-1.1-.348-3.845h-6.635l-.555 3.845-2.089 1.1-3.307-1.1L3.6 12.593l3.133 2.785v2.147z"
          style={{ fill: props?.strokeColor || '#fff' }}
          transform="translate(-.605 -.295)"
        />
        <path
          data-name="Path 5748"
          d="M27.371 17.057v-1.534l1.929-1.685a2.006 2.006 0 0 0 .381-2.557l-2.37-4.012a2.022 2.022 0 0 0-2.377-.9l-2.434.82a11.382 11.382 0 0 0-1.314-.752l-.512-2.527a2.006 2.006 0 0 0-2.006-1.614h-4.695a2.006 2.006 0 0 0-2.006 1.614l-.512 2.527a11.512 11.512 0 0 0-1.324.752l-2.386-.863a2.006 2.006 0 0 0-.641-.06 2.006 2.006 0 0 0-1.735 1L3 11.28a2.006 2.006 0 0 0 .414 2.52l1.9 1.735v1.534l-1.9 1.685A2.006 2.006 0 0 0 3 21.309l2.37 4.011a2.022 2.022 0 0 0 2.377.9l2.437-.822a11.382 11.382 0 0 0 1.314.752l.512 2.527a2.006 2.006 0 0 0 2.006 1.614h4.733a2.006 2.006 0 0 0 2.006-1.614l.512-2.527a11.513 11.513 0 0 0 1.324-.752l2.427.822a2.022 2.022 0 0 0 2.377-.9l2.286-4.011a2.006 2.006 0 0 0-.411-2.517zm-1.8 7.261-3.44-1.163a8.885 8.885 0 0 1-2.718 1.574l-.711 3.6h-4.729l-.712-3.56a9.387 9.387 0 0 1-2.708-1.574L7.1 24.317l-2.362-4.011L7.465 17.9a8.926 8.926 0 0 1 0-3.139l-2.727-2.478L7.1 8.272l3.44 1.163a8.885 8.885 0 0 1 2.718-1.574l.711-3.6h4.733l.712 3.56A9.387 9.387 0 0 1 22.127 9.4l3.449-1.123 2.367 4.011-2.728 2.402a8.926 8.926 0 0 1 0 3.139l2.728 2.477z"
          style={{ fill: props?.color || colors.themeSecondColor }}
          transform="translate(-.605 -.295)"
        />
        <path
          data-name="Path 5749"
          d="M18 24.75a6.683 6.683 0 1 1 4.793-1.957A6.75 6.75 0 0 1 18 24.75zm0-11.25a4.4 4.4 0 0 0-4.5 4.5 4.4 4.4 0 0 0 4.5 4.5 4.4 4.4 0 0 0 4.5-4.5 4.4 4.4 0 0 0-4.5-4.5z"
          transform="translate(-2.263 -2)"
          style={{ fill: props?.color || colors.themeSecondColor }}
        />
      </g>
      <path data-name="Rectangle 3870" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
    </svg>
  )
}
