import { Tab, Box } from '@mui/material';
import _ from 'lodash'
import { useDispatch } from 'react-redux';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { setEditTootChart } from 'app/slice/treatment.slice';

/** COMPONENT */
import SummaryTreatmentEmr from 'features/treatments/SummaryTreatmentEmr/SummaryTreatmentEmr'
import DiagnosisEmr from 'features/treatments/SummaryTreatmentEmr/DiagnosisEmr'
import RecommendEmr from 'features/treatments/SummaryTreatmentEmr/RecommendEmr'

import * as UseStyled from 'features/treatments/SummaryTreatmentEmr/UseStyle'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

type IndexSummaryTreatment = {
  userId?: number
  tabs: string[]
  activeTab: number
  type: 'Treatment' | 'Payment' | 'HistoryTreatment' | 'RetrospectiveEdit'
  setActiveTab: (tab: number) => void
  doctors: Doctors[]
  diagnosis?: any
  patientCaseInfo?: any
  recommends?: any
  summeryProducts?: any
  summeryTreatments?: any
  patient?: any
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export default function IndexSummaryTreatmentEmr(props: IndexSummaryTreatment) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    if (props.setActiveTab) props.setActiveTab(newValue)
    dispatch(setEditTootChart(null))
  }

  return (
    <Box className="mt-4 px-3">
      <Box sx={{ marginBottom: '12px' }}>
        <UseStyled.CustomTabs value={props.activeTab} onChange={handleChangeTab} aria-label="summary tabs">
          {_.map(props.tabs, (tab: string, index: number) => {
            return <Tab key={index} label={t(tab)} {...a11yProps(index)} style={{ width: 'fit-content', maxWidth: '100%', padding: '0 10px' }} />
          })}
        </UseStyled.CustomTabs>
      </Box>
      <TabPanel value={props.activeTab} index={0}>
        <SummaryTreatmentEmr summeryTreatments={props.summeryTreatments} summeryProducts={props.summeryProducts} doctors={props.doctors} userId={props.userId} patient={props.patient} />
      </TabPanel>
      <TabPanel value={props.activeTab} index={1}>
        <DiagnosisEmr diagnosis={props.diagnosis} />
      </TabPanel>
      <TabPanel value={props.activeTab} index={2}>
        <RecommendEmr recommends={props.recommends} />
      </TabPanel>
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `summary-tab-${index}`,
    'aria-controls': `summary-tabpanel-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`summary-tabpanel-${index}`} aria-labelledby={`summary-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
