import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/treatment-right/treatment-right/style'

/** API */
import TreatmentRightApi from 'api/setting/treatment-right.api'
/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { numberFormat, numberOnly } from 'utils/app.utils'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}
const initStateErrorMessage = {
  TREATMENT_RIGHT_CODE: ``,
  IS_DUPLICATE_TREATMENT_RIGHT_CODE: ``,
  TREATMENT_RIGHT_CODE_STRING_BASE: ``,
  TREATMENT_RIGHT_CODE_STRING_EMPTY: ``,
  TREATMENT_RIGHT_CODE_MAXIMUM_LENGTH: ``,
  TREATMENT_RIGHT_CODE_ANY_REQUIRED: ``,

  TREATMENT_RIGHT_NAME: ``,
  IS_DUPLICATE_TREATMENT_RIGHT_NAME: ``,
  TREATMENT_RIGHT_NAME_STRING_BASE: ``,
  TREATMENT_RIGHT_NAME_STRING_EMPTY: ``,
  TREATMENT_RIGHT_NAME_MAXIMUM_LENGTH: ``,
  TREATMENT_RIGHT_NAME_ANY_REQUIRED: ``,

  TREATMENT_RIGHT_NAME_EN: ``,
  IS_DUPLICATE_TREATMENT_RIGHT_NAME_EN: ``,
  TREATMENT_RIGHT_NAME_EN_STRING_BASE: ``,
  TREATMENT_RIGHT_NAME_EN_STRING_EMPTY: ``,
  TREATMENT_RIGHT_NAME_EN_MAXIMUM_LENGTH: ``,
  TREATMENT_RIGHT_NAME_EN_ANY_REQUIRED: ``,

  TREATMENT_RIGHT_PRICE: ``,
  TREATMENT_RIGHT_AMOUNT: ``
}


export default function TreatmentRight() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [treatmentRights, setTreatmentRights] = useState([])
  const [treatmentRightId, setTreatmentRightId] = useState(Number)
  const [treatmentRightCode, setTreatmentRightCode] = useState('')
  const [treatmentRightName, setTreatmentRightName] = useState('')
  const [treatmentRightStatus, setTreatmentRightStatus] = useState('')
  const [amount, setAmount] = useState<any>(1)
  const [price, setPrice] = useState<any>(0)
  const [unit, setUnit] = useState('BAHT')
  const [isDivide, setIsDivide] = useState('0')
  const [isDoctorFee, setIsDoctorFee] = useState('0')
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const units = [
    { value: 'BAHT', label: t('TREATMENT_RIGHT.UNIT.BATH') },
    { value: 'PERCENT', label: t('TREATMENT_RIGHT.UNIT.PERCENT') }
  ]
  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await TreatmentRightApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setTreatmentRights(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const submit = () => {
    if (!treatmentRightCode || !treatmentRightName || price === null || price === 0 || !price || amount === null || amount === 0) {
      return setErrorMessage({
        ...errorMessage,
        ...{
          TREATMENT_RIGHT_CODE: !treatmentRightCode ? t(`TREATMENT_RIGHT.MESSAGE.TREATMENT_RIGHT_CODE`) : '',
          TREATMENT_RIGHT_NAME: !treatmentRightName ? t(`TREATMENT_RIGHT.MESSAGE.TREATMENT_RIGHT_NAME`) : '',
          TREATMENT_RIGHT_PRICE: price === null || !price ? t(`TREATMENT_RIGHT.MESSAGE.TREATMENT_RIGHT_PRICE`) : '',
          TREATMENT_RIGHT_AMOUNT: amount === null || !amount ? t(`TREATMENT_RIGHT.MESSAGE.TREATMENT_RIGHT_AMOUNT`) : ''
        }
      })
    }

    dispatch(submitModal())
    TreatmentRightApi.create({ treatmentRightCode, treatmentRightName, treatmentRightNameEn: treatmentRightName, price, unit, amount, isDivide, isDoctorFee })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201)
          notiSuccess(t('TREATMENT_RIGHT.MESSAGE.SUCCESS.CREATE'), '', null, () => {
            resetForm()

            loadData()
            dispatch(resetModal())
          })
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_RIGHT.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_RIGHT.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    if (!treatmentRightCode || !treatmentRightName || price === null || !price || amount === null || !amount) {
      return setErrorMessage({
        ...errorMessage,
        ...{
          TREATMENT_RIGHT_CODE: !treatmentRightCode ? t(`TREATMENT_RIGHT.MESSAGE.TREATMENT_RIGHT_CODE`) : '',
          TREATMENT_RIGHT_NAME: !treatmentRightName ? t(`TREATMENT_RIGHT.MESSAGE.TREATMENT_RIGHT_NAME`) : '',
          TREATMENT_RIGHT_PRICE: price === null || !price ? t(`TREATMENT_RIGHT.MESSAGE.TREATMENT_RIGHT_PRICE`) : '',
          TREATMENT_RIGHT_AMOUNT: amount === null || !amount ? t(`TREATMENT_RIGHT.MESSAGE.TREATMENT_RIGHT_AMOUNT`) : ''
        }
      })
    }

    dispatch(submitModal())
    TreatmentRightApi.update(treatmentRightId, { treatmentRightCode, treatmentRightName, treatmentRightNameEn: treatmentRightName, price, unit, amount, isDivide, isDoctorFee, status: treatmentRightStatus })
      .then((res: any) => {

        if (res.status !== undefined && res.status === 200) {
          resetForm()
          loadData()
          dispatch(resetModal())
          notiSuccess(t(`TREATMENT_RIGHT.MESSAGE.SUCCESS.UPDATE`), '', null, null)
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_RIGHT.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_RIGHT.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setTreatmentRightId(0)
    setTreatmentRightCode('')
    setTreatmentRightName('')
    setAmount(0)
    setPrice(0)
    setUnit('BAHT')
    setIsDoctorFee('0')
    setIsDivide('0')
    setTreatmentRightStatus('')
    clearErrorMessage()
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TREATMENT_RIGHT.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          TreatmentRightApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('TREATMENT_RIGHT.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('TREATMENT_RIGHT.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }

  const onDelete = (data: any) => {
    const { treatmentRightId: txRightId, treatmentRightName: txRightName, treatmentRightNameEn: txRightNameEn } = data
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TREATMENT_RIGHT.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TREATMENT_RIGHT.ALERT.CONFIRM_DELETE_', {
        treatmentCourseTypeName: txRightName,
        treatmentCourseTypeNameEn: txRightNameEn ? ' (' + txRightNameEn + ')' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          TreatmentRightApi.remove(txRightId)
            .then((resp) => {
              notiSuccess(t('TREATMENT_RIGHT.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('TREATMENT_RIGHT.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { treatmentRightId: txRightId, treatmentRightCode: txRightCode, treatmentRightName: txRightName, price: txRightPrice, amount: txRightAmount, unit: txRightUnit, isDivide: txRightIsDivide, isDoctorFee: txRightIsDoctorFee, status: txRightStatus } = data
    setTreatmentRightCode(txRightCode)
    setTreatmentRightId(txRightId)
    setTreatmentRightName(txRightName)
    setPrice(txRightPrice)
    setAmount(txRightAmount)
    setUnit(txRightUnit)
    setIsDivide(txRightIsDivide)
    setIsDoctorFee(txRightIsDoctorFee)
    setTreatmentRightStatus(txRightStatus)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }
  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'treatmentRightCode', disablePadding: false, label: t('TREATMENT_RIGHT.TABLE.CELL.TREATMENT_RIGHT_CODE') },
    { id: 'treatmentRightName', disablePadding: false, label: t('TREATMENT_RIGHT.TABLE.CELL.TREATMENT_RIGHT_NAME') },
    { id: 'price', disablePadding: false, label: t('TREATMENT_RIGHT.TABLE.CELL.PRICE'), align: 'right' },
    { id: 'unit', disablePadding: false, label: t('TREATMENT_RIGHT.TABLE.CELL.UNIT'), align: 'center' },
    { id: 'amount', disablePadding: false, label: t('TREATMENT_RIGHT.TABLE.CELL.AMOUNT'), align: 'right' },
    { id: 'status', disablePadding: false, label: t('TREATMENT_RIGHT.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('TREATMENT_RIGHT.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { treatmentRightId: txRightId, treatmentRightCode: txRightCode, treatmentRightName: txRightName, price: txRightPrice, unit: txRightUnit, amount: txRightAmount, updatedBy, updatedAt, status: txRightStatus } = objData

    const statusBtnActive = txRightStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = txRightStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: txRightId,
      id: txRightId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: txRightCode },
        { option: 'TEXT', align: 'left', label: txRightName },
        { option: 'TEXT', align: 'right', label: txRightUnit === 'BAHT' ? numberFormat(txRightPrice) : txRightPrice },
        { option: 'TEXT', align: 'right', label: txRightUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%' },
        { option: 'TEXT', align: 'center', label: txRightAmount },
        { option: 'STATUS', align: 'center', label: txRightStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('TREATMENT_RIGHT.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={7}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={5} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('TREATMENT_RIGHT.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={treatmentRights.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </Card>

      <ModalCustom
        size={'lg'}
        title={treatmentRightId ? t('TREATMENT_RIGHT.TITLE_UPDATE') : t('TREATMENT_RIGHT.TITLE_CREATE')}
        component={
          <div>
            {treatmentRightId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('TREATMENT_RIGHT.TITLE_CREATE')}</Form.Label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    onChange={() => setTreatmentRightStatus(treatmentRightStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                    checked={treatmentRightStatus === 'ACTIVE' ? true : false}
                    style={{ marginLeft: 'auto' }}
                  />
                </Form>
              </div>
            ) : (
              ''
            )}

            <InputTextField
              onchange={(event) => {
                setTreatmentRightCode(event.target.value)
                clearErrorMessage()
              }}
              value={treatmentRightCode}
              label={t('TREATMENT_RIGHT.INPUT.TREATMENT_RIGHT_CODE')}
              helperText={
                errorMessage.TREATMENT_RIGHT_CODE ||
                errorMessage.IS_DUPLICATE_TREATMENT_RIGHT_CODE ||
                errorMessage.TREATMENT_RIGHT_CODE_STRING_BASE ||
                errorMessage.TREATMENT_RIGHT_CODE_STRING_EMPTY ||
                errorMessage.TREATMENT_RIGHT_CODE_MAXIMUM_LENGTH ||
                errorMessage.TREATMENT_RIGHT_CODE_ANY_REQUIRED
              }
              required={true}
            />

            <InputTextField
              key="treatmentCourseRightName"
              onchange={(event) => {
                setTreatmentRightName(event.target.value)
                clearErrorMessage()
              }}
              value={treatmentRightName}
              helperText={
                errorMessage.TREATMENT_RIGHT_NAME ||
                errorMessage.IS_DUPLICATE_TREATMENT_RIGHT_NAME ||
                errorMessage.TREATMENT_RIGHT_NAME_STRING_BASE ||
                errorMessage.TREATMENT_RIGHT_NAME_STRING_EMPTY ||
                errorMessage.TREATMENT_RIGHT_NAME_MAXIMUM_LENGTH ||
                errorMessage.TREATMENT_RIGHT_NAME_ANY_REQUIRED
              }
              label={t('TREATMENT_RIGHT.INPUT.TREATMENT_RIGHT_NAME')}
              required={true}
              style={{ marginTop: '1rem' }}
            />
            <Row>
              <Col sm={6} className="mt-3">
                <InputTextField
                  key="price"
                  onchange={(event) => {
                    setPrice(numberOnly(event))
                    clearErrorMessage()
                  }}
                  value={price}
                  label={t('TREATMENT_RIGHT.INPUT.PRICE')}
                  required={true}
                  helperText={errorMessage.TREATMENT_RIGHT_PRICE}
                />
              </Col>
              <Col sm={6} className="mt-3">
                <BasicSelect
                  onchange={(event) => {
                    const value = event.target.value
                    if (value) {
                      if (value === 'PERCENT') setIsDivide('0')
                      if (value === 'PERCENT' && price > 100) setPrice(100)
                      setUnit(value)
                    }
                  }}
                  label={t('TREATMENT_RIGHT.INPUT.UNIT')}
                  selectId="unit"
                  value={unit}
                  labelId="unit"
                  options={units.map((obj: any) => {
                    return (
                      <MenuItem key={obj.value} value={obj.value}>
                        {obj.label}
                      </MenuItem>
                    )
                  })}
                />
              </Col>
            </Row>
            <div className="mt-2">
              <InputCheckbox
                id={`checkbox-input-treatment`}
                key={`checkbox-input-treatment`}
                checked={isDivide === '1' ? true : false}
                label={t('TREATMENT_RIGHT.INPUT.IS_DIVIDE')}
                disabled={unit === 'PERCENT'}
                onChange={(e) => {
                  if (e.target.checked) setAmount(1)
                  setIsDivide(e.target.checked === false ? '0' : '1')
                }}
              />
            </div>

            <Col>
              <InputTextField
                key="amount"
                required={true}
                disabled={isDivide === '1' ? true : false}
                inputProps={{ min: 0 }}
                onchange={(event) => {
                  setAmount(numberOnly(event))
                  clearErrorMessage()
                }}
                value={amount}
                label={t('TREATMENT_RIGHT.INPUT.AMOUNT')}
                helperText={errorMessage.TREATMENT_RIGHT_AMOUNT}
                style={{ marginTop: '1rem' }}
              />
            </Col>
            <div className="mt-2">
              <InputCheckbox
                id={`checkbox-input-treatment-DF`}
                key={`checkbox-input-treatment-DF`}
                checked={isDoctorFee === '0' ? true : false}
                label={t('TREATMENT_RIGHT.INPUT.IS_DOCTOR_FEE')}
                onChange={(e) => setIsDoctorFee(e.target.checked ? '0' : '1')}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={treatmentRightId ? submitEdit : submit}
        textBtnCancel={t('TREATMENT_RIGHT.BUTTON.CANCEL')}
        textBtnConfirm={t('TREATMENT_RIGHT.BUTTON.SAVE')}
      />
    </div>
  )
}
