import { TabContext, TabList } from '@mui/lab'
import { Box, Tab, styled } from '@mui/material'
import { colors } from 'constants/theme'
import React, { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Banner from './banner/Banner'
import AboutUs from './about-us/About-us'
import Service from './service/Service'
import PriceList from './price-list/Price-list'
import Review from './review/Review'
import Article from './article/Article'
import ContactUs from './contact-us/ContactUs'

import MenuGroup from 'assets/icon/menu-group'
import { Dropdown } from 'react-bootstrap'
import { DropdownItem } from 'component/Table/TableRowCommon'
import { icons } from 'constants/images'
import News from '../news/news'
import Promotions from '../promotions/promotions'
import FormPromotion from '../promotions/form-promotion'
import FormNews from '../news/form-news'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js'

const CustomTab = styled(Tab)<{ tabForm: string; selectLabel: string }>(({ theme, tabForm, selectLabel }) => ({
    textTransform: 'none',
    fontSize: '16px',
    color: tabForm === selectLabel ? `${colors.themeSecondColor} !important` : colors.black,
    position: 'relative',
    borderBottom: `2px solid ${colors.transparent}`,
    transition: "all 0.2s",
    '&.Mui-selected': {
        borderBottomColor: colors.themeSecondColor
    },
    '.Mui-selected': {
        color: colors.themeSecondColor
    },
    '&.error': {
        color: `${colors.statusInactive} !important`,
        '&.Mui-selected': {
            borderBottomColor: colors.statusInactive
        }
    }
}))

enum TABS_APP {
    BANNER = "BANNER",
    ABOUT_US = "ABOUT_US",
    SERVICE = "SERVICE",
    PRICE_LIST = "PRICE_LIST",
    REVIEW = "REVIEW",
    ARTICLE = "ARTICLE",
    CONTACT_US = "CONTACT_US"
}

const ManageWeb = () => {
    const { t } = useTranslation()
    const t_MENU = "MANAGE_WEB.MENU"
    const location = useLocation()
    const history = useHistory()

    const [load, setLoad] = useState<boolean>(false)

    const [tab, setTab] = useState<TABS_APP | "">('')
    const [tabForm, setTabForm] = useState<TABS_APP | "">('')
    const [tabStatus, setTabStatus] = useState<'CREATE' | 'EDIT' | ''>('')

    const [bannerImg, setBannerImg] = useState<string>('')
    const [priceListImg, setPriceListImg] = useState<string>('')

    const handleTabChange = (event: React.SyntheticEvent, newValue: TABS_APP) => {
        setTab(newValue)
        const path = '/manage-web/'
        const newPathName = newValue?.replace(/_/g, '-')?.toLowerCase()
        const newLocationState: any = location?.state
        const newStast = {
            bannerImg: bannerImg !== "" && bannerImg || newLocationState?.bannerImg || '',
            priceListImg: priceListImg !== "" && priceListImg || newLocationState?.priceListImg || ''
        }
        history.push(`${path}${newPathName}`, newStast)
    }

    const loadNewPage = () => {
        if (location) {
            const pathName: any = location?.pathname?.split('/')
            const newPathName: TABS_APP = pathName[2]?.replace(/-/g, '_')?.toUpperCase()
            if (pathName?.length === 4) {
                const newStatusPath = pathName[3]?.replace(/-/g, '_')?.toUpperCase()
                setTabStatus(newStatusPath)
            } else {
                setTabStatus('')
            }
            if (newPathName) {
                setTab(TABS_APP[newPathName])
                setTabForm(TABS_APP[newPathName])
            }
        }
        setLoad(false)
    }

    useEffect(() => {
        setLoad(true)
        loadNewPage()
    }, [location])

    const renderBannerImg = useCallback(() => {
        const newLocationState: any = location?.state
        if (bannerImg !== "") {
            return bannerImg
        } else {
            return newLocationState?.bannerImg || ""
        }
    }, [location?.state, bannerImg])

    const renderPriceListImg = useCallback(() => {
        const newLocationState: any = location?.state
        if (priceListImg !== "") {
            return priceListImg
        } else {
            return newLocationState?.priceListImg || ""
        }
    }, [location?.state, priceListImg])

    return (
        <>
            {!load &&
                <Box sx={{ height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box
                            className={`box-banner`}
                            sx={{
                                margin: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                overflow: 'hidden',
                                'div .MuiTabs-root': {
                                    padding: '0 !important'
                                }
                            }}>
                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 1, borderColor: colors.extraLightBlue }}>
                                    <TabList className="pl-3" onChange={handleTabChange} aria-label="personel tabs" variant="scrollable" TabIndicatorProps={{ style: { backgroundColor: colors.transparent, color: colors.themeSecondColor } }}>
                                        {Object.keys(TABS_APP).map((key: any) => {
                                            const newKey: TABS_APP = key
                                            return (
                                                <CustomTab
                                                    key={key}
                                                    tabForm={tabForm}
                                                    selectLabel={newKey}
                                                    label={t(`${t_MENU}.${newKey}`)}
                                                    value={newKey}
                                                    onClick={(e) => setTabForm(newKey)}
                                                />
                                            )
                                        })}
                                    </TabList>
                                </Box>
                                <Box sx={{
                                    height: '100%',
                                    overflow: 'hidden',
                                    '.card': {
                                        height: '100%'
                                    }
                                }}>

                                    <Card>
                                        <Box sx={{ display: 'flex', height: '100%' }}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    padding: '16px',
                                                    height: '100%',
                                                }}
                                            >
                                                {tabForm === TABS_APP.BANNER && <Banner bannerImg={renderBannerImg()} setBannerImg={setBannerImg} />}
                                                {tabForm === TABS_APP.ABOUT_US && <AboutUs />}
                                                {tabForm === TABS_APP.SERVICE && <Service />}
                                                {tabForm === TABS_APP.PRICE_LIST && <PriceList priceListImg={renderPriceListImg()} setPriceListImg={setPriceListImg} />}
                                                {tabForm === TABS_APP.REVIEW && <Review />}
                                                {tabForm === TABS_APP.ARTICLE && <Article />}
                                                {tabForm === TABS_APP.CONTACT_US && <ContactUs />}
                                            </Box>
                                            {/* <Box
                                                sx={{
                                                    width: '50%',
                                                    padding: '16px',
                                                    height: '100%'
                                                }}
                                            >
                                            </Box> */}
                                        </Box>

                                    </Card>
                                </Box>
                            </TabContext>
                        </Box>
                    </Box >
                </Box >
            }
        </>
    )
}

export default ManageWeb

type ManegeWebStatusProps = {
    status: 'ACTIVE' | 'INACTIVE'
}

export const ManegeWebStatus = (props: ManegeWebStatusProps) => {
    const { t } = useTranslation()
    const t_MANAGE_WEB = `MANAGE_WEB`
    const [isColor, setIsColor] = useState<'#37B922' | 'red'>('#37B922')

    const onRenderStatus = () => {
        if (props.status === 'ACTIVE') {
            setIsColor('#37B922')
        } else {
            setIsColor('red')
        }
    }

    useEffect(() => {
        onRenderStatus()
    }, [props.status])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
            <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: isColor }} />
            <Box sx={{ marginLeft: '8px', height: '100%', whiteSpace: ' nowrap' }}>
                {t(`${t_MANAGE_WEB}.STATUS_${props.status}`)}
            </Box>
        </Box>
    )
}

type ManegeWebActionProps = {
    onActive: () => void
    disabledActive: boolean
    onInActive: () => void
    onEdit: () => void
    disabledEdit?: boolean
    onDelete: () => void
    disabledDel?: boolean
}

export const ManegeWebAction = (props: ManegeWebActionProps) => {
    const { t } = useTranslation()
    const t_MANAGE_WEB = `MANAGE_WEB`

    return (
        <Dropdown>
            <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="btn-table-action text-black-50 shadow-none">
                <MenuGroup />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <DropdownItem onClick={props.onActive} disabled={props.disabledActive}>
                    {t(`${t_MANAGE_WEB}.STATUS_ACTIVE`)}
                </DropdownItem>
                <DropdownItem onClick={props.onInActive} disabled={!props.disabledActive}>
                    {t(`${t_MANAGE_WEB}.STATUS_INACTIVE`)}
                </DropdownItem>
                <Dropdown.Divider style={{ backgroundColor: 'transparent', borderColor: colors.lightGray, opacity: 1 }} />
                <DropdownItem onClick={props.onEdit} disabled={props?.disabledEdit}>
                    <img src={icons.dropdownEdit} alt="" className="align-baseline" />
                    <span className="ml-2 align-text-bottom">{t(`${t_MANAGE_WEB}.BUTTON_EDIT`)}</span>
                </DropdownItem>
                <DropdownItem onClick={props.onDelete} disabled={props?.disabledDel}>
                    <img src={icons.dropdownTrash} alt="" className="align-baseline" />
                    <span className="ml-2 align-text-bottom">{t(`${t_MANAGE_WEB}.BUTTON_DEL`)}</span>
                </DropdownItem>

            </Dropdown.Menu>
        </Dropdown>
    )
}

type ManageWebEditor = {
    editorState: EditorState
    defaultContentState?: any
    setEditorState: (editorState: EditorState) => void
}

export const ManageWebEditor = (props: ManageWebEditor) => {
    return (
        <Editor
            editorState={props.editorState}
            defaultContentState={props?.defaultContentState || props.editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={props.setEditorState}
            toolbar={{
                colorPicker: {
                    // icon: <IconColorPicker />,
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)', 'rgb(255 255 255 / 0%)'],
                }
            }}
        />
    )
}