import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { Box, Typography, Tab } from '@mui/material';
import _, { forEach } from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** API */
import { fileUrl } from 'api/api'
import InventoryApi from 'api/warehouses/inventory.api';

/** COMPONENT */
import { InventoryType } from 'features/warehouses/inventory/inventory'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import ButtonCustom from 'component/Button/ButtonCustom'
import GeneralTab from 'features/warehouses/inventory/inventoryTab/general'
import HistoryImportTab from 'features/warehouses/inventory/inventoryTab/historyImport'
import HistoryOrderTab from 'features/warehouses/inventory/inventoryTab/historyOrder';
import HistoryAdjustmentTab from 'features/warehouses/inventory/inventoryTab/historyAdjustment'
import HistoryQuantity from 'features/warehouses/inventory/inventoryTab/historyQuantity';
import InputCheckbox from 'component/Input/InputCheckbox';
import Loading from 'component/Loading';

/** CONSTANTS */
import { colors } from 'constants/theme'
import { icons, onImgError } from 'constants/images'

/** STYLE */
import * as UseStyled from 'features/warehouses/inventory/styled'
import moment from 'moment';
import HistoryDispose from './inventoryTab/historyDispose';

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

export interface InventoryTabs {
    inventory: InventoryType
}

export default function FormInventory() {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const tabs = ['GENERAL', 'IMPORT', 'HISTORY', 'ADJUSTMENT', 'QUANTITY', 'DISPOSE'];
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(true);

    const [inventory, setInventory] = useState<any>({});

    const [quantities, setQuantities] = useState<any>([]);
    const [totalExpire, setTotalExpire] = useState<number>(0)
    const [totalStillExpire, setTotalStillExpire] = useState<number>(0)

    const loadData = async (id: number) => {
        let itemId = 0
        await InventoryApi.findById(id)
            .then(({ data }) => {
                itemId = data?.itemId
                setInventory(data)
            })
            .catch(() => { return })
        // .finally(() => setLoading(false))
        if (!itemId) return setLoading(false)
        const condition: any = { itemId: itemId, page: 1, pageLimit: 100, sortBy: 'date', sortType: 'DESC' }
        await InventoryApi.findInventoryHistoryById(itemId, condition)
            .then(({ data }) => {
                setQuantities(data)

                const dateCurrent = moment();
                let newTotalExpire = 0
                let newTotalStillExpire = 0
                data?.forEach((item: any) => {
                    const dateInfo = moment(item?.expireDate);
                    if (item?.expireDate && dateCurrent.isAfter(dateInfo)) {
                        newTotalExpire += item?.qty
                    } else {
                        newTotalStillExpire += item?.qty
                    }
                })
                setTotalExpire(newTotalExpire)
                setTotalStillExpire(newTotalStillExpire)
            })
            .catch(() => { return })
            .finally(() => setLoading(false))

    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }

    const renderImage = (image: any, group: 'PRODUCT' | 'MEDICINE' | '') => {
        if (!_.isEmpty(image)) return `${fileUrl}/${image.filePath}/${image.fileName}`
        else return group === 'PRODUCT' ? icons.prodDefault : icons.medDefault
    }

    useEffect(() => {
        const inventoryId: any = location.state
        if (!_.isEmpty(inventoryId) && inventoryId.inventoryId) loadData(inventoryId.inventoryId)
        else history.push(routeName.inventory)
    }, [location])

    return (
        <div className={`container-card`}>
            <div className="d-flex flex-column">
                <HeaderLabel
                    unDisplayButton
                    text={t('INVENTORY.HEADER.TITLE')}
                    navigator={{ previousTo: routeName.inventory, previousTitle: t('INVENTORY.HEADER.DEFAULT'), currentTitle: t('INVENTORY.HEADER.VIEW') }}
                    inputStatus={
                        <>
                            <ButtonCustom
                                onClick={() => history.push(routeName.importProduct + '/create',
                                    {
                                        itemId: inventory?.itemId,
                                        name: inventory?.itemName || inventory?.itemNameEn,
                                        salePrice: inventory?.price,
                                        group: inventory?.group,
                                        unit: inventory?.itemUnitName || inventory?.itemUnitNameEn
                                    })}
                                textButton={t('INVENTORY.BUTTON.IMPORT')}
                                className="mr-3"
                                style={{ margin: 0 }}
                                btnStyle={{ fontSize: '16px', padding: '6px 16px !important' }}
                            />
                            <ButtonCustom
                                onClick={() => history.push(routeName.adjustment + '/create',
                                    {
                                        productName: inventory?.itemName
                                    })}
                                textButton={t('INVENTORY.BUTTON.ADJUSTMENT')}
                                style={{ margin: 0 }}
                                btnStyle={{ fontSize: '16px', padding: '6px 16px !important' }} />
                        </>
                    }
                />
                <Card className="border shadow-sm mx-4 mb-4 h-auto card">
                    <Card.Body className="h-100 py-3 px-3 px-xl-4">
                        <Box className='d-flex flex-column flex-sm-row'>
                            <UseStyled.ImageProduct className='position-relative w-100 mx-auto' sx={{ flex: 'none' }}>
                                <Box className='position-relative image-wrap'>
                                    <img src={renderImage(inventory?.image, inventory?.group)} onError={onImgError} alt={''} className='position-absolute top-0 start-0 w-100 h-100' />
                                </Box>
                            </UseStyled.ImageProduct>
                            <UseStyled.InformationProduct className='d-sm-flex flex-column pl-sm-3' sx={{ flex: 1 }}>
                                <Typography sx={{ fontWeight: 500, fontSize: '20px', color: colors.textPrimary }}>{inventory?.itemName || inventory?.itemNameEn || '-'}</Typography>
                                <Typography className='pt-2' sx={{ color: colors.disabledGray }}>{`${t('INVENTORY.FORM.QTY')} ${totalStillExpire} ${inventory?.itemUnitName || inventory?.itemUnitNameEn || ''}`}</Typography>
                                <Typography className='pt-2' sx={{ color: colors.disabledGray }}>{`${t('INVENTORY.FORM.MEDICINE.EXPIRE_CURRENT')} ${totalExpire} ${inventory?.itemUnitName || inventory?.itemUnitNameEn || ''}`}</Typography>
                                <Box className='mt-4 mt-sm-auto'>
                                    <InputCheckbox
                                        disabled
                                        checked={inventory.showOnReceipt === 'SHOW'}
                                        label={t('INVENTORY.FORM.SHOW_ON_RECEIPT')}
                                        style={{ marginLeft: 0, '.MuiCheckbox-root': { padding: '0 8px 0 0 !important' } }} />
                                </Box>
                            </UseStyled.InformationProduct>
                        </Box>
                        <Box className="mt-4">
                            <Box className='mb-3'>
                                <UseStyled.CustomTabs value={activeTab} onChange={handleChangeTab} aria-label="inventory tabs">
                                    {_.map(tabs, (tab: string, index: number) => {
                                        return <Tab key={index} label={t(`INVENTORY.TABS.${tab}`)} {...a11yProps(index)} />
                                    })}
                                </UseStyled.CustomTabs>
                            </Box>
                            <TabPanel value={activeTab} index={0}>
                                {!loading && <GeneralTab inventory={inventory} />}
                            </TabPanel>
                            <TabPanel value={activeTab} index={1}>
                                <HistoryImportTab inventory={inventory} />
                            </TabPanel>
                            <TabPanel value={activeTab} index={2}>
                                <HistoryOrderTab inventory={inventory} />
                            </TabPanel>
                            <TabPanel value={activeTab} index={3}>
                                <HistoryAdjustmentTab inventory={inventory} />
                            </TabPanel>
                            <TabPanel value={activeTab} index={4}>
                                <HistoryQuantity inventory={inventory} setTotalExpire={setTotalExpire}  />
                            </TabPanel>
                            <TabPanel value={activeTab} index={5}>
                                <HistoryDispose inventory={inventory} />
                            </TabPanel>
                        </Box>
                    </Card.Body>
                </Card>
            </div>
            <Loading show={loading} type={'fullLoading'} />
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `inventory-tab-${index}`,
        'aria-controls': `inventory-tabpanel-${index}`
    }
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div role="tabpanel" hidden={value !== index} id={`inventory-tabpanel-${index}`} aria-labelledby={`inventory-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    )
}