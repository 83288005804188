import { menuSeconds } from 'constants/menus'
import { routeName } from 'routes/routes-name'
import Adjustment from 'features/warehouses/adjustment/adjustment'
import FormAdjustment from 'features/warehouses/adjustment/form-adjustment'

const routeAdjustment = [
    {
        ...menuSeconds.ADJUSTMENT,
        key: menuSeconds.ADJUSTMENT.key,
        name: menuSeconds.ADJUSTMENT.name,
        textName: menuSeconds.ADJUSTMENT.textName,
        active: false,
        appBar: true,
        component: Adjustment
    },
    {
        ...menuSeconds.ADJUSTMENT,
        key: menuSeconds.ADJUSTMENT.key,
        name: menuSeconds.ADJUSTMENT.name,
        textName: menuSeconds.ADJUSTMENT.textName,
        active: false,
        appBar: true,
        path: routeName.adjustment + '/create',
        component: FormAdjustment
    },
    {
        ...menuSeconds.ADJUSTMENT,
        key: menuSeconds.ADJUSTMENT.key,
        name: menuSeconds.ADJUSTMENT.name,
        textName: menuSeconds.ADJUSTMENT.textName,
        active: false,
        appBar: true,
        path: routeName.adjustment + '/edit',
        component: FormAdjustment
    },
]

export default routeAdjustment