import { useState } from 'react';
import { Col, Row } from 'react-bootstrap'
import { AppBar, ButtonBase, Toolbar, Typography, Menu, Button } from '@mui/material'
import { useSelector } from 'react-redux';
import _ from 'lodash'
import { routeName } from 'routes/routes-name'
import { expired } from 'app/slice/expired.slice'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ASSETS */
import ExpiredAlert from 'component/ExpiredAlert'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'

/** CONSTANTS */
import { onImgError, Icons } from 'constants/images'
import { colors, templateImage } from 'constants/theme'
import { scale } from 'constants/scale'
import { checkPackage, getPermissionShortcuts, getClinicInfo } from 'utils/app.utils'
import { branchInfo } from 'app/slice/user.slice';
import { menuSeconds } from 'constants/menus'

/** COMPONENT */
import UserMenu from 'component/Header/UserMenu'
import Bento from 'assets/icon/header/navigation-bento';

const AppBarMain = styled(AppBar)(({ theme }) => ({
  height: scale.navbarHeight,
  padding: 0,
  margin: 0,
  top: 0,
  position: 'sticky',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  background: `${colors.navbar} !important`,
  backgroundColor: `${colors.white} !important`,
  border: 'none',
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.down('xl')]: {
    height: `calc(${scale.navbarHeight} - 1rem)`
  },
  [theme.breakpoints.down('lg')]: {
    height: `calc(${scale.navbarHeight} - 1.5rem)`
  },
  [theme.breakpoints.down('sm')]: {
    height: `calc(${scale.navbarHeight} - 2.5rem)`
  },
  '&.header .MuiToolbar-root': {
    minHeight: '0',
    width: '100%'
  },
  '& .logo-clinic': {
    maxHeight: 70,
    maxWidth: 120,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 50,
      maxWidth: 95,
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 40,
      maxWidth: 80,
    }
  },
  '& .menu-header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginLeft: 'auto',
    textAlign: 'end',
    border: 'none !important',
    [theme.breakpoints.down('lg')]: {
      '& .div-label-menu': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    '& .iconMenu': {
      width: 25,
      height: 25,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%'
    },
    '& a:nth-of-type(3) .iconMenu': {
      width: 32
    },
    '& a:nth-of-type(5) .iconMenu': {
      width: 21
    }
  },
  '& .menuName': {
    paddingLeft: '2rem',
    fontSize: '24px !important',
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  '& .menuLabel': {
    color: `${colors.black} !important`,
    fontSize: `16px !important`,
    border: 'none !important'
  },
  '& .benTo': {
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    '& button': {
      padding: 0,
      minWidth: 'unset'
    }
  },
  '& .menuIconHem': {
    color: colors.themeSecondColor,
    marginRight: '.75rem',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  '& .sizeIcon': {
    width: '26px',
    height: '26px'
  },
  '& .btn-menu-nav': {
    borderRadius: '0.4rem !important'
  },
  '& .btn-menu-nav:hover': {
    backgroundColor: colors.themeSecondColor06
  },
  '& .btn-menu-nav.active': {
    background: colors.navbarLinkActive
  }
}))

const MenuBento = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${colors.lightGray}`,
    boxShadow: 'none',
    marginTop: '.375rem',
    transform: 'translateX(40px) !important',
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      transform: 'unset !important'
    }
  },
  '& .MuiList-root': {
    maxWidth: '288px',
    padding: '.75rem',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    }
  },
  '& .MuiButtonBase-root': {
    textAlign: 'center',
    width: '100%',
    '& .flex-block': {
      maxWidth: '100%'
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
      color: colors.textPrimary,
      lineHeight: 'normal',
      paddingTop: '4px'
    }
  }
}))

const SidebarBackdrop = styled('div')<{ expiredAlert: boolean }>(({ theme, expiredAlert }) => ({
  [theme.breakpoints.down('xl')]: {
    top: expiredAlert ? `calc(calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 1rem)` : `calc(${scale.navbarHeight} - 1rem)`
  },
  [theme.breakpoints.down('lg')]: {
    top: expiredAlert ? `calc(calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 1.5rem)` : `calc(${scale.navbarHeight} - 1.5rem)`
  },
  [theme.breakpoints.down('sm')]: {
    top: expiredAlert ? `calc(calc(${scale.navbarHeight} + ${scale.expiredAlertHeight}) - 2.5rem)` : `calc(${scale.navbarHeight} - 2.5rem)`
  }
}))

type HeaderProps = {
  menuName?: string | null
}

export default function Header(props: HeaderProps) {
  const { t } = useTranslation()
  const expire = useSelector(expired)
  const branchesInfo = useSelector(branchInfo)
  const branchInfos = getClinicInfo()
  const shortcutPermission = getPermissionShortcuts()

  const [headerBar, setHeaderBar] = useState<null | HTMLElement>(null)
  const openHeaderBar = Boolean(headerBar)

  const handleClickHeader = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHeaderBar(event.currentTarget)
  }

  const handleCloseHeader = () => {
    setHeaderBar(null)
  }

  const [sideBar, setSideBar] = useState(false)

  function containerMenu() {
    const sidebarHTML: any = document.querySelector('.container-menu .menu-first')
    if (!sideBar) {
      sidebarHTML.classList.add('show')
      setSideBar(true)
    } else {
      sidebarHTML.classList.remove('show')
      setSideBar(false)
    }
  }

  const menus = [
    {
      key: menuSeconds.HOME.key,
      label: t(menuSeconds.HOME.textName),
      disabled: false,
      icon: Icons.Home,
      active: true,
      route: routeName.home
    },
    {
      key: menuSeconds.DASHBOARD.key,
      packageKey: menuSeconds.DASHBOARD.packageKey,
      label: t(menuSeconds.DASHBOARD.textName),
      disabled: false,
      icon: Icons.Chart,
      active: true,
      route: routeName.dashboard
    },
    {
      key: menuSeconds.REGISTER.key,
      label: t(menuSeconds.REGISTER.textName),
      disabled: false,
      icon: Icons.Register,
      active: true,
      route: routeName.register
    },
    {
      key: menuSeconds.APPOINTMENT.key,
      label: t(menuSeconds.APPOINTMENT.textName),
      disabled: false,
      icon: Icons.Appointment,
      active: true,
      route: routeName.appointment
    },
    {
      key: menuSeconds.DOCTOR_SCHEDULE.key,
      label: t(menuSeconds.DOCTOR_SCHEDULE.textName),
      disabled: false,
      icon: Icons.Schedule,
      active: true,
      route: routeName.doctorSchedule
    }
  ]

  const isActive = (route: string): boolean => {
    return route === window.location.pathname ? true : false

  }

  const checkPermission = (key: string) => {
    return shortcutPermission ? _.includes(shortcutPermission, key) : false
  }

  return (
    <>
      <ExpiredAlert />
      <AppBarMain position="static" className={'header top-0 d-flex'} elevation={0}>
        <Toolbar variant="regular" className={`px-3 px-md-4 m-0`}>
          <div
            className="menuIconHem"
            onClick={(e) => {
              containerMenu()
            }}>
            <MenuIcon />
          </div>
          <img className={`logo-clinic cursor-pointer`} src={branchesInfo?.branchLogo || branchInfos?.branchLogo || templateImage.logoHorizontal} alt="logo-clinic" onClick={() => window.location.href = routeName.welcome} onError={onImgError} />
          <div className={'benTo'}>
            <Button id="basic-button" aria-controls="basic-menu" aria-haspopup="true" aria-expanded={openHeaderBar ? 'true' : undefined} onClick={handleClickHeader}>
              <Bento />
            </Button>
            <MenuBento
              id="basic-menu"
              anchorEl={headerBar}
              open={openHeaderBar}
              onClose={handleCloseHeader}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}>
              <div>
                <Row className={'mx-0'}>
                  {menus.map(({ label, disabled, icon: Icon, icon_active: IconActive, active, key, packageKey, ...rest }: any, index) => (
                    (checkPermission(key) && checkPackage(packageKey)) && (
                      <Col xs={4} key={label} className={'px-1 py-1'}>
                        <ButtonBase
                          className={`rounded btn-menu-nav ${isActive(rest.route || 'POST') ? 'active' : ''}`}
                          href={rest.route || '#'}
                          key={label}>
                          <div className={'flex-block'}>
                            <div className={'iconMenu'}>
                              <Icon active={isActive(rest.route || '#')} />
                            </div>
                            <div className={'div-label-menu'}>
                              <Typography className={'menuLabel'} noWrap>
                                {label}
                              </Typography>
                            </div>
                          </div>
                        </ButtonBase>
                      </Col>
                    )
                  ))}
                </Row>
              </div>
            </MenuBento>
          </div>

          <div className={'menu-header'}>
            {menus.map(({ label, disabled, icon: Icon, icon_active: IconActive, active, key, packageKey, ...rest }: any, index) => (
              (checkPermission(key) && checkPackage(packageKey)) && (
                <ButtonBase
                  href={rest.route || '#'}
                  style={{
                    margin: '0 5px',
                    transform: 'unset'
                  }}
                  key={label}
                  className={`p-2 mx-2 rounded btn-menu-nav ${isActive(rest.route || 'POST') ? 'active' : ''}`}
                  disableRipple>
                  <div className={'flex-center'}>
                    <div>
                      <div className={'iconMenu'}>
                        <Icon active={isActive(rest.route || '#')} />
                      </div>
                    </div>
                    <div className={'div-label-menu'}>
                      <Typography className={'menuLabel pl-2'} noWrap>
                        {label}
                      </Typography>
                    </div>
                  </div>
                </ButtonBase>
              )
            ))}
          </div>
          <UserMenu />
        </Toolbar>
      </AppBarMain>
      <SidebarBackdrop expiredAlert={expire} className={`sidebar-backdrop ${sideBar ? 'd-block' : 'd-none'}`} onClick={containerMenu}></SidebarBackdrop>
    </>
  )
}
