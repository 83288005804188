import React from 'react'
import { useLocation } from 'react-router-dom'
import { getPermissionActiveMenu } from './app.utils'

const permissionMenus = () => {
  return getPermissionActiveMenu() || {}
}

export const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const isCreate = () => {
  const permission: any = permissionMenus()

  return {
    style: permission?.isCreate === 1 ? 'create' : 'disable',
    val: permission?.isCreate ? permission.isCreate : 0,
    disabled: permission?.isCreate ? false : true,
  }
}
export const isDelete = () => {
  const permission: any = permissionMenus()

  return {
    style: permission?.isDelete === 1 ? 'delete' : 'disable',
    val: permission?.isDelete ? permission.isDelete : 0,
    disabled: permission?.isDelete ? false : true
  }
}
export const isExport = () => {
  const permission: any = permissionMenus()

  return {
    style: permission?.isExport === 1 ? 'export' : 'disable',
    val: permission?.isExport ? permission.isExport : 0,
    disabled: permission?.isExport ? false : true
  }
}
export const isUpdate = () => {
  const permission: any = permissionMenus()

  return {
    style: permission?.isUpdate === 1 ? 'update' : 'disable',
    val: permission?.isUpdate ? permission.isUpdate : 0,
    disabled: permission?.isUpdate ? false : true
  }
}

export const isView = () => {
  const permission: any = permissionMenus()

  return {
    style: permission?.isView === 1 ? 'view' : 'disable',
    val: permission?.isView ? permission.isView : 0,
    disabled: permission?.isView ? false : true
  }
}

export const isCopy = () => {
  const permission: any = permissionMenus()

  return {
    style: permission?.isCopy === 1 ? 'copy' : 'disable',
    val: permission?.isCopy ? permission.isCopy : 0,
    disabled: permission?.isCopy ? false : true
  }
}
