import _ from 'lodash'
import moment from 'moment'
import { Box, Typography, Grid, TableRow, TableCell } from '@mui/material';
import $ from 'jquery'
import { clinicTheme } from 'api/api';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import useWindowSize from 'utils/useWindowSize'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'

/** STYLE */
import { styled } from '@mui/material/styles'
import * as UseStyled from 'features/treatments/SummaryTreatmentEmr/UseStyle'
import { useEffect, useState } from 'react';
import { TreatmentStateInterface } from 'app/slice/treatment.slice';
import { DoctorsType } from '../treatmentsIndex';

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  width: 130,
  flex: 'none',
  [theme.breakpoints.down('xs')]: {
    width: 120
  }
}))

type ResponsiveRenderDataProps = {
  onViewTreatmentsCourse?: () => void
  onViewManageProduct?: () => void
  onViewMessage?: (no: number) => void
  onViewNextVisit?: () => void
  onChange?: (e: any) => void
  onPinDoctor?: (type: string, doctorId: number) => void
  no?: number
  doctors?: Doctors[]
  doctorsSelect?: DoctorsType[]
  data?: any
  price?: number
  discount?: number
  additional?: number
  total?: number
  renderTreatmentNextVisit?: string
  hasTreatment?: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentCost?: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentNote?: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatment?: 'PERMISS' | 'UNPERMISS'

}


type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

type ISummaryTreatment = {
  summeryTreatments?: any
  summeryProducts?: any
  type?: 'Treatment' | 'Payment' | 'HistoryTreatment'
  doctors: Doctors[]
  patientCaseId?: any
  userId?: any
  patient?: any
  hasTreatment?: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentCost?: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentNote?: 'PERMISS' | 'UNPERMISS'
  hasChangeProductPrice?: 'PERMISS' | 'UNPERMISS'
  hasChangeProductDiscountAdditional?: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatment?: 'PERMISS' | 'UNPERMISS'
}

export interface RawDiscountType {
  price: number
  qty: number
  discount: number
  discountUnit: string
  dfUnit: string
  dfAmount: number
  dfDiscountAmount: number
  dfDiscountUnit: string
  cfAmount: number
  cfDiscountAmount: number
  cfDiscountUnit: string
  additionalNet: number
  doctorFeeAmount: number
  clinicFeeAmount: number
}

export interface RawAdditionalType {
  price: number
  qty: number
  additional: number
  additionalUnit: string
}


export default function SummaryTreatmentEmr(props: ISummaryTreatment) {
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const [data, setData] = useState<any[]>([])
  useEffect(() => {
    if (props.summeryTreatments && props.summeryProducts)
      setData([...props.summeryTreatments, ...props.summeryProducts])
  }, [props.summeryTreatments, props.summeryProducts]);


  const handleRowHover = (type: 'onMouseEnter' | 'onMouseLeave', item: string) => {
    if (type === 'onMouseEnter') $(`#${item}`).prev().addClass('table-row-hover')
    else $(`#${item}`).prev().removeClass('table-row-hover')
  }

  const renderTreatmentNextVisit = (value: any) => {
    const treatment = typeof value === 'string' && value ? JSON.parse(value) : value
    if (!_.isEmpty(treatment)) {
      return `${treatment[0]?.operativeName || treatment[0]?.operativeTypeName} ${_.size(treatment) > 1 ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT_MORE_TREATMENT', {
        treatment_count: _.size(treatment) - 1
      }) : ''}`
    } else return ''
  }

  const headCellsTooth = clinicTheme.theme === clinicTheme.type.DENTAL ? [{ id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TOOTH') }] : []

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    ...headCellsTooth,
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TYPE') },
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.SERVICE') },
    { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.TITLE.QUANTITY') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.NET_PRICE') },
    { id: 'message', disablePadding: false, align: 'left', label: t('TREATMENT_SUMMARY.TITLE.DOCTOR') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.DISCOUNT') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.ADDON') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.TOTAL') }
  ]

  const renderData = (data: any, no: number) => {
    const {
      operativeTypeId,
      operativeTypeName,
      operativeTypeNameEn,
      operativeName,
      operativeNameEn,
      qty,
      note,
      nextVisit,
      noteNextVisit,
      itemTypeName,
      itemTypeNameEn,
      teeth,
      itemName,
      itemNameEn,
      clinicFeeId,
      clinicFeeName,
      clinicFeeNameEn,
      clinicFeePrice,
    } = data


    const price = Number(data.price || 0)
    const total = (price <= 0 && data.dfUnit !== 'PERCENT' && operativeTypeId) ? 0 : Number(data.total || 0)

    const rawAdditional: RawAdditionalType = {
      price: price,
      qty: Number(data.qty),
      additional: Number(data.additional),
      additionalUnit: data.additionalUnit,
    }

    const rawDiscount: RawDiscountType = {
      price: price,
      qty: Number(data.qty),
      discount: Number(data.discount),
      discountUnit: data.discountUnit,
      dfUnit: data.dfUnit,
      dfAmount: Number(data.dfAmount),
      dfDiscountAmount: Number(data.dfDiscountAmount),
      dfDiscountUnit: data.dfDiscountUnit,
      cfAmount: Number(data.cfAmount),
      cfDiscountAmount: Number(data.cfDiscountAmount),
      cfDiscountUnit: data.cfDiscountUnit,
      additionalNet: renderSumAdditional(rawAdditional),
      doctorFeeAmount: data.doctorFeeAmount,
      clinicFeeAmount: data.clinicFeeAmount
    }

    return (
      <>
        <TableRow key={`treatment-${no}`} className={`main-table-row`}>
          <TableCell align="center" scope="row" className="pt-3 pt-sm-3 pt-xl-2">
            {no + 1}
          </TableCell>
          <TableCell align="left" scope="row" colSpan={width >= 1200 ? 1 : _.size(headCells) - 1} className="pt-3 pt-xl-2">
            {clinicTheme.theme === clinicTheme.type.DENTAL && (
              <Box className="d-flex align-items-sm-center d-xl-block" sx={{ wordBreak: 'break-all' }}>
                {width < 1200 && <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TOOTH')}</Title>}
                {teeth ? teeth : '-'}
              </Box>
            ) || (
                <Box className="d-flex align-items-sm-center d-xl-block" sx={{ wordBreak: 'break-all' }}>
                  {width < 1200 && <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TYPE')}</Title>}
                  <Typography sx={{ wordBreak: 'break-all' }}>
                    {operativeTypeId ? (operativeTypeName || operativeTypeNameEn || '-') : (itemTypeName || itemTypeNameEn || '-')}
                  </Typography>
                </Box>
              )}
            {width < 1200 && (
              <ResponsiveRenderData
                data={data}
                doctors={props.doctors}
                price={price || 0}
                discount={Number(operativeTypeId && renderSumDiscount(rawDiscount) || rawDiscount.discount || 0)}
                additional={Number(operativeTypeId && renderSumAdditional(rawAdditional) || rawAdditional.additional || 0)}
                total={total}
                renderTreatmentNextVisit={renderTreatmentNextVisit(nextVisit?.treatment)}
              />
            )}
          </TableCell>
          {width >= 1200 && (
            <>
              {clinicTheme.theme === clinicTheme.type.DENTAL && (
                <TableCell align="left" scope="row">
                  <Typography sx={{ wordBreak: 'break-all' }}>
                    {operativeTypeId ? (operativeTypeName || operativeTypeNameEn || '-') : (itemTypeName || itemTypeNameEn || '-')}
                  </Typography>
                </TableCell>
              )}
              <TableCell align="left" scope="row">
                <span style={{ wordBreak: 'break-all' }}>{operativeTypeId ? (operativeName || operativeNameEn || '-') : (itemName || itemNameEn || '-')}</span>
                {clinicFeeId && (
                  <Box>
                    <Typography className='d-inline' sx={{ fontWeight: 700 }}>CF:</Typography>
                    <Typography className='d-inline pl-2' sx={{ fontWeight: 500, color: colors.textLightGray }}>{clinicFeeName || clinicFeeNameEn} ({numberFormat(clinicFeePrice || 0, 0)} {t('CURRENCY_CHANGE.TH')})</Typography>
                  </Box>
                )}
              </TableCell>
              <TableCell align="center" scope="row">
                {qty}
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(price)}
              </TableCell>
              <TableCell align="left" scope="row">
                {props.doctors.find((p: any) => p.userId === data.doctorId)?.fullname}
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(Number(operativeTypeId && renderSumDiscount(rawDiscount) || rawDiscount.discount || 0))} {t('CURRENCY_CHANGE.TH')}
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(Number(operativeTypeId && renderSumAdditional(rawAdditional) || rawAdditional.additional || 0))} {t('CURRENCY_CHANGE.TH')}
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(total)} {t('CURRENCY_CHANGE.TH')}
              </TableCell>
            </>
          )}
        </TableRow>
        {
          width >= 1200 && (
            <TableRow key={`treatment-row-${no}`} id={`treatment-row-${no}`} onMouseEnter={() => handleRowHover('onMouseEnter', `treatment-row-${no}`)} onMouseLeave={() => handleRowHover('onMouseLeave', `treatment-row-${no}`)}>
              <TableCell align="center" scope="row" className="pt-1"></TableCell>
              <TableCell align="left" scope="row" colSpan={_.size(headCells) - 1} className="pt-1">
                <Box className="d-flex">
                  <Box className="w-100 d-flex" sx={{ paddingRight: operativeTypeId ? '12px' : 0 }}>
                    <Typography className="pr-2" sx={{ flex: 'none' }} >{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}: </Typography>
                    <Typography><pre className='note' >{note ? note : '-'}</pre></Typography>
                  </Box>
                  {operativeTypeId && (

                    <Box className="w-100" sx={{ paddingLeft: '12px' }}>
                      <Box className='d-flex'>
                        <Typography className="pr-2" sx={{ flex: 'none' }}>{t('Next visit')}</Typography>
                        <Typography>{noteNextVisit ? noteNextVisit : '-'}</Typography>
                      </Box>
                      {!_.isEmpty(nextVisit) && (
                        <Box className='d-flex mt-2 mb-1'>
                          <Typography sx={{ flex: 'none', opacity: 0 }}>{t('Next visit')}</Typography>
                          <Typography className='ml-2'>
                            <span style={{ fontWeight: 600 }}>{t('TREATMENT_SUMMARY.TITLE.APPOINTMENT')} :</span>
                            <span className='ml-1' style={{ color: colors.textLightGray }}>{`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT', {
                              date: nextVisit?.date ? toBuddhistYear(moment(nextVisit?.date), 'DD/MM/YYYY') : '-',
                              time: nextVisit?.timeStart ? toBuddhistYear(moment(nextVisit.timeStart, 'HH:mm'), 'HH:mm') : '-',
                              dentist: _.get(_.find(props.doctors, { userId: nextVisit?.doctorId }), 'fullname'),
                              treatment: renderTreatmentNextVisit(nextVisit?.treatment)
                            })}`}</span>
                          </Typography>
                        </Box>
                      ) || <></>}
                    </Box>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          )
        }
      </>
    )
  }


  return (
    <Box>
      <UseStyled.ResponsiveSummaryTreatmentTable className={'overflow-hidden'}>
        <TableCustom
          hidePagination
          customScroll
          page={0}
          pageLimit={0}
          sortType={'ASC'}
          sortBy={''}
          rowCount={0}
          textEmptyData={t('REGISTER.NO_ITEM')}
          onSort={() => {
            return
          }}
          setPageLimit={() => {
            return
          }}
          setPage={() => {
            return
          }}
          headCells={headCells}
          rowsData={_.map(data, (i, index: number) => {
            return renderData(i, index)
          }).reverse()}
        />
      </UseStyled.ResponsiveSummaryTreatmentTable>

      {props.type !== 'Payment' && (
        <UseStyled.NetPrice className={`d-flex align-items-center justify-content-between justify-content-md-end mt-2 px-3 px-md-4`}>
          {t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')} <span className="pl-2 pl-md-5 text-right">{numberFormat(_.sumBy([...(props.summeryTreatments || []), ...(props.summeryProducts || [])], 'total'))} {t('CURRENCY_CHANGE.TH')}</span>
        </UseStyled.NetPrice>
      )}
    </Box>
  )
}


function ResponsiveRenderData(props: ResponsiveRenderDataProps) {
  const { t } = useTranslation()
  const { operativeTypeId, operativeTypeName, operativeTypeNameEn, operativeName, operativeNameEn, itemName, itemNameEn, qty, doctorId, nextVisit, note, noteNextVisit, itemTypeName, itemTypeNameEn, clinicFeeId, clinicFeeName, clinicFeeNameEn, clinicFeePrice } = props.data

  return (
    <>
      <Grid container spacing={2} alignItems="center" className="mt-0">
        {clinicTheme.theme === clinicTheme.type.DENTAL && (
          <Grid item xs={12} className="w-100">
            <Box className="d-flex align-items-start">
              <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TYPE')}</Title>
              <Typography sx={{ wordBreak: 'break-all' }}>
                {operativeTypeId ? (operativeTypeName || operativeTypeNameEn || '-') : (itemTypeName || itemTypeNameEn || '-')}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} className="w-100">
          <Box className="d-flex align-items-start">
            <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.SERVICE')}</Title>
            <Box>
              <span style={{ wordBreak: 'break-all' }}>{operativeTypeId ? (operativeName || operativeNameEn || '-') : (itemName || itemNameEn || '-')}</span>
              {clinicFeeId && (
                <Box>
                  <Typography className='d-inline' sx={{ fontWeight: 700 }}>CF:</Typography>
                  <Typography className='d-inline pl-2' sx={{ fontWeight: 500, color: colors.textLightGray }}>{clinicFeeName || clinicFeeNameEn} ({numberFormat(clinicFeePrice || 0, 0)} {t('CURRENCY_CHANGE.TH')})</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} className="w-100">
          <Box className="d-flex align-items-center">
            <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.QUANTITY')}</Title>
            {qty}
          </Box>
        </Grid>
        <Grid item xs={6} className="w-100">
          <Box className="d-flex align-items-center">
            <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.NET_PRICE')}</Title>
            <Typography>
              {numberFormat(props.price)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className="w-100">
          <Box className="d-flex align-items-center">
            <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.DOCTOR')}</Title>
            <Box className="w-100 overflow-hidden" sx={{ maxWidth: '240px' }}>
              {_.find(props.doctors, (p: any) => p.userId === doctorId)?.fullname || '-'}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} className="w-100">
          <Box className="d-flex align-items-center">
            <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.DISCOUNT')}</Title>
            <Typography>
              {numberFormat(props.discount || 0)} {t('CURRENCY_CHANGE.TH')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className="w-100">
          <Box className="d-flex align-items-center">
            <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.ADDON')}</Title>
            <Typography>
              {numberFormat(props.additional || 0)} {t('CURRENCY_CHANGE.TH')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className="w-100">
          <Box className="d-flex align-items-center">
            <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TOTAL')}</Title>
            <Typography>
              {numberFormat(props.total)} {t('CURRENCY_CHANGE.TH')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={`w-100 ${!operativeTypeId ? 'pb-2' : ''}`}>
          <Box className="w-100 d-flex">
            <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}: </Title>
            <Box className="d-flex w-100">
              <UseStyled.InputFormControl className="d-flex" sx={{ flex: 1 }}>
                <pre className='note'  >{note ? note : '-'}</pre>
              </UseStyled.InputFormControl>
            </Box>
          </Box>
        </Grid>
        {operativeTypeId && (
          <Grid item xs={12} className="w-100 pb-2">
            <Box className="w-100 d-flex">
              <Title className="d-inline-block pr-2">{t('Next visit')}</Title>
              <Box className="d-flex w-100">
                <UseStyled.InputFormControl className="d-flex next-visit" sx={{ flex: 1 }}>
                  {noteNextVisit ? noteNextVisit : '-'}
                </UseStyled.InputFormControl>
              </Box>
            </Box>
            {!_.isEmpty(nextVisit) && (
              <Box className="w-100 d-flex flex-column flex-sm-row">
                <Title className="d-none d-sm-inline-block pr-2" sx={{ flex: 'none', opacity: 0 }}>{t('Next visit')}</Title>
                <Typography className='mt-2'>
                  <span style={{ fontWeight: 600 }}>{t('TREATMENT_SUMMARY.TITLE.APPOINTMENT')} :</span>
                  <span className='ml-1' style={{ color: colors.textLightGray }}>{`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT', {
                    date: nextVisit?.date ? toBuddhistYear(moment(nextVisit?.date), 'DD/MM/YYYY') : '-',
                    time: nextVisit?.timeStart || '-',
                    dentist: _.get(_.find(props.doctors, { userId: nextVisit?.doctorId }), 'fullname'),
                    treatment: props.renderTreatmentNextVisit
                  })}`}</span>
                </Typography>
              </Box>
            ) || <></>}
          </Grid>
        )}
      </Grid>
    </>
  )
}

export const renderSumDiscount = (value: RawDiscountType) => {
  const discount = value.discountUnit === 'PERCENT' ? ((value.price * value.qty) * value.discount) / 100 : value.discount
  const additional = value.additionalNet
  let rawDfAmount = 0
  let rawCfAmount = 0

  if (value.price === 0 && value.dfUnit !== 'PERCENT') {
    rawDfAmount = value.dfAmount || value.doctorFeeAmount
    rawCfAmount = 0
  } else if (value.dfUnit === 'PERCENT') {
    if (value.price === 0) {
      rawDfAmount = 0
      rawCfAmount = 0
    } else {
      rawDfAmount = ((((value.price * value.qty) - discount) + additional) / 100) * (value.doctorFeeAmount || value.dfAmount)
      rawCfAmount = ((((value.price * value.qty) - discount) + additional) / 100) * (value.clinicFeeAmount || value.cfAmount)
    }
  } else {
    rawDfAmount = value.dfAmount || value.doctorFeeAmount
    rawCfAmount = value.cfAmount || (((value.price * value.qty) - discount) + additional) - value.doctorFeeAmount
  }

  const dfDiscount = value.dfDiscountUnit === 'PERCENT' ? (rawDfAmount * value.dfDiscountAmount) / 100 : value.dfDiscountAmount
  const cfDiscount = value.cfDiscountUnit === 'PERCENT' ? (rawCfAmount * value.cfDiscountAmount) / 100 : value.cfDiscountAmount

  return discount + dfDiscount + cfDiscount
}

export const renderSumAdditional = (value: RawAdditionalType) => {
  const additional = value.additionalUnit === 'PERCENT' ? ((value.price * value.qty) * value.additional) / 100 : value.additional

  return additional
}

export const cal_sum = (data: any, rawData: any, status: string) => {
  if (data.group !== "MEDICINE") {
    return rawData
  } else {
    const discount = data.discountUnit === "BAHT" ? data.discount : (data.price * data.qty) * (data.discount / 100)
    const additional = data.additionalUnit === "BAHT" ? data.additional : (data.price * data.qty) * (data.additional / 100)
    const total = ((data.price * data.qty) - discount) + additional
    if (status === "discount") {
      return discount
    }
    if (status === "additional") {
      return additional
    }
    if (status === "total") {
      return total
    }
  }
}

export const doctorHasOverDue = (treatments: TreatmentStateInterface, doctorId: number | null) => {
  const hasOverdue = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments?.summeryTreatments[0], 'pinDoctorId') || doctorId })
  return hasOverdue
}