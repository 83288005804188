import { colors } from "constants/theme"

export default function MarkLine(props: { active?: boolean }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.223" height="18.204" viewBox="0 0 18.223 18.204">
            <g data-name="Group 8501">
                <path d="M2.047 12.791 0 16.357l3.582-2.047.016-.1 2.686-2.67 2.43 2.415q.672.672 1.519-.16v-2.858l4.125-4.285.208.192a.986.986 0 0 0 .744.32A1.065 1.065 0 0 0 16.373 6.1a1.064 1.064 0 0 0-.3-.76L11.017.3a1.065 1.065 0 0 0-.76-.3 1.037 1.037 0 0 0-.751.3 1 1 0 0 0-.312.744 1.035 1.035 0 0 0 .32.76l.208.208L5.42 6.14l-2.862.016q-.831.831-.144 1.5l2.414 2.414-2.685 2.689z" transform="translate(1.35 .5)" style={{ stroke: colors.themeSecondColor, fill: props.active ? colors.themeSecondColor : colors.white }} />
                <path data-name="Path 6417" d="m4.928 12.115 1.715 1.795" style={{ fill: 'none', stroke: colors.themeSecondColor }} transform="translate(1.35 -1.5)" />
            </g>
        </svg>
    )
}
