import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Image } from 'react-bootstrap'
import { styled, Button, Menu, MenuItem, Avatar, Typography, Tooltip } from '@mui/material'
import { keyframes } from '@mui/system';
import { getColorStatus } from 'features/counter/doctor-schedule/doctor-schedule'
import { makeStyles } from "@material-ui/core/styles";
/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */

/** CONSTANTS */
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'
import { imgs } from 'constants/images'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import mark from 'assets/icon/appointment/mark.svg'
import markLine from 'assets/icon/appointment/markLine.svg'
import closePopup from 'assets/icon/carlendar/closePopup.svg'
import { useFormatSizeAppointment } from './appointment'

/** STYLE */
const ButtonDropdown = styled(Button)<{ widthbox: string }>(({ theme, widthbox }) => ({
  width: `${widthbox}px`,
  height: '100px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0 1rem',
  borderRadius: scale.borderRadiusXs,
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
  border: `1px solid ${colors.lightGray}`,
  background: `${colors.filterDropdown} !important`,
  color: colors.textPrimary,
  textAlign: 'left',
  '& .MuiButton-endIcon': { margin: '0 0 0 .5rem', '& svg': { fontSize: 16, '& path': { fill: colors.themeSecondColor } } },
  '&[aria-expanded="true"] .MuiButton-endIcon': { transform: 'rotate(180deg)' },
  '& .title': { fontSize: 16, fontWeight: 500, lineHeight: 1.4 },
  '& .subtitle': { lineHeight: 1.4, [theme.breakpoints.up('lg')]: { fontSize: 16 } },
  '& .head-text': { position: 'absolute', top: '10px', width: '100%', fontSize: '1.125rem' },
  '& .bt-close': { position: 'absolute', top: '10px', right: '10px' },
  '& .bt-label': { flex: 1, display: 'flex', alignItems: 'center' }
}))

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  border: `1px solid ${colors.white}`,
  '&.active': {
    border: `2px solid ${colors.themeSecondColor}`
  },
  '&.more': {
    backgroundColor: colors.white,
    color: colors.textPrimary,
    border: `1px solid ${colors.white}`,
    fontSize: 14,
    display: 'flex !important'
  }
}))

const MenuDropdown = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    mixBlendMode: 'multiply',
    backgroundBlendMode: 'multiply',
    background: `linear-gradient(to bottom, ${colors.white}, ${colors.white})`,
    boxShadow: `0 5px 5px 0 ${colors.black20}`,
    overflow: 'hidden'
  },
  '& .MuiMenu-list': {
    maxHeight: '320px',
    overflowY: 'auto'
  }
}))

const MenuDropdownItem = styled(MenuItem)(({ theme }) => ({
  padding: '.5rem 1rem',
  '&:hover': {
    background: colors.themeSecondColor06,
    color: colors.themeSecondColor,
    // 'span.slide-hover': {
    //   animation: `${myEffect} 6s linear infinite`,
    // }
  },
  '&.no-bg:hover': {
    background: colors.white,
    color: colors.black
  },
  '& .title': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4,
  },
  '& .subtitle': {
    lineHeight: 1.4,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  },

}))

// const myEffect = keyframes`
//   0% {
//   opacity: 1;
//   transform: translateX(20%);
// }
//   100% {
//   opacity: 1;
//   transform: translateX(-100%);
// }
// `;

const ButtomSelectAllWeek = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  color: colors.themeSecondColor,
  border: `1px solid ${colors.themeSecondColor}`,
  background: colors.white,
  borderRadius: '4px'
}))


const DivServicePointName = styled('span')(({ theme }) => ({
  marginLeft: 10,
  color: colors.secondSky,
  fontSize: 11,
  '& div': {
    width: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

export type DataPropsType = {
  doctorId: number
  doctorFullName: string
  servicePointName: string
  startWork: string
  getOffWork: string
  avatar?: any
  status: string
}

type FilterDoctorType = {
  data: DataPropsType[]
  options?: any
  markDoctor?: number
  selectDoctor?: DataPropsType
  headText?: string
  onClick?: (id: number) => void
  onClose?: () => void
  onMarkDoctor?: (doctorId: number) => void
  clinicIsClose?: boolean
  widthButton?: number
}

export default function FilterDoctor(props: FilterDoctorType) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const newSize = useFormatSizeAppointment()
  const sizeSectionAppointment = props?.widthButton || newSize;

  const [isOpen, setIsOpen] = useState(false)
  const open = Boolean(anchorEl)
  const [width, setWidth] = useState(0)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(false)
    const buttonWidth = Array.from(document.getElementsByClassName('btn-filterDoctor') as HTMLCollectionOf<HTMLElement>)
    setWidth(Number(buttonWidth[0]?.offsetWidth))
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handdleSelect = (event: any) => {
    const id = event?.target?.value || event?.target?.alt || event?.target?.offsetParent.value

    if (props.onClick) {
      props.onClick(id)
    }
    setIsOpen(true)
    setAnchorEl(null);
  }

  const handdleId = (id: number) => {
    if (props.onClick) props.onClick(id)
  }

  const getRightAvatar = (no: number) => {
    const row: number = props.data.length || 0
    if (row > 3) {
      if (no === 1) return -27
      else if (no === 2) return -18
      else if (no === 3) return -9
      return 0
    } else if (row === 3) {
      if (no === 1) return -18
      else if (no === 2) return -9
      return 0
    } else if (row === 2) {
      if (no === 1) return -9
      else if (no === 2) return 0
      return 0
    } else return 0
  }

  const MoreAvatar = styled('div')(({ theme }) => ({
    display: 'none',
    marginLeft: 'auto',
    '& .MuiAvatar-root': {
      position: 'relative',
      display: 'none',
      '&:nth-of-type(1)': {
        right: getRightAvatar(1),
        display: 'flex'
      },
      '&:nth-of-type(2)': {
        right: getRightAvatar(2),
        display: 'flex'
      },
      '&:nth-of-type(3)': {
        right: getRightAvatar(3),
        display: 'flex'
      },
      '&:not(.more):not(.active):hover': {
        zIndex: 5
      }
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  }))

  return (
    <>
      <ButtonDropdown
        widthbox={sizeSectionAppointment.toString()}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        endIcon={!props.clinicIsClose && <FontAwesomeIcon icon={faCaretDown} />}
        className={`btn-filterDoctor ${props.clinicIsClose && 'pe-none'}`}
        disableRipple
        style={{ paddingTop: '2.25rem' }}>
        <div className={'head-text text-center'}>{props?.headText}</div>
        {props.onClose && (
          <div className={'bt-close curser-pointer text-center'} onClick={props.onClose}>
            <Image src={closePopup} />
          </div>
        )}
        {!props.clinicIsClose && (
          <div className={'bt-label text-ellipsis'}>
            <CustomAvatar alt={props?.selectDoctor?.doctorFullName || ''} src={props?.selectDoctor?.avatar || ''} sx={{ backgroundColor: colors.white }}>
              <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
            </CustomAvatar>
            <div className={'ml-3 overflow-hidden'}>
              <div className={'title text-ellipsis m-0 d-flex align-items-center'}>{props?.selectDoctor?.doctorFullName || '' || <span className="text-muted">{t('USER_DOCTOR')}{t('APPOINTMENT.BUTTON.NO_ENTER_SHIFT')}</span>}
                {props?.selectDoctor?.doctorFullName ?
                  <DivServicePointName>
                    <Tooltip
                      title={<div>{props?.selectDoctor?.servicePointName}</div>}
                      placement={'top-start'} >
                      <div>{props?.selectDoctor?.servicePointName}</div>
                    </Tooltip>
                  </DivServicePointName> : ''
                }
              </div>
              <div className={'subtitle text-ellipsis'}>
                <span className='pr-1'>{t('HOME.TITLE.START_WORK')}</span>
                {props.selectDoctor?.status === 'NORMAL' && (
                  `${props?.selectDoctor?.startWork || '' ? moment(props?.selectDoctor?.startWork || '', 'HH:mm').format('HH.mm') : '00.00'} - 
                ${props?.selectDoctor?.getOffWork || '' ? moment(props?.selectDoctor?.getOffWork || '', 'HH:mm').format('HH.mm') : '00.00'} ${t('TIME_UNIT')}`
                ) || (
                    <span style={{ color: getColorStatus(props.selectDoctor?.status || '', 'COLOR') }}>{props.selectDoctor?.status ? `(${t(`DOCTOR_SCHEDULE.STATUS.${props.selectDoctor?.status}`)})` : '-'}</span>
                  )}
              </div>
            </div>
            {props.data.length > 0 && (
              <MoreAvatar>
                {props?.selectDoctor?.doctorId !== undefined && (
                  <CustomAvatar
                    alt={`${props?.selectDoctor?.doctorId || ''}`}
                    src={props?.selectDoctor?.avatar || ''}
                    className={'active'}
                    sx={{ width: 32, height: 32, backgroundColor: colors.white }}
                    onClick={() => (props?.selectDoctor?.doctorId ? handdleId(props?.selectDoctor?.doctorId) : undefined)}>
                    <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
                  </CustomAvatar>
                )}
                {_.map(props.data, (i, index: number) => {
                  if (i.doctorId !== props?.selectDoctor?.doctorId) {
                    return (
                      <CustomAvatar key={index} className={props?.selectDoctor?.doctorId === i.doctorId ? 'active' : ''} alt={`${i.doctorId}`} src={i.avatar} sx={{ width: 32, height: 32, backgroundColor: colors.white }} onClick={() => handdleId(i.doctorId)}>
                        <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
                      </CustomAvatar>
                    )
                  }
                })}
                {props.data.length > 3 && (
                  <CustomAvatar className={'more'} sx={{ width: 32, height: 32 }}>
                    {props.data.length > 3 ? `+${props.data.length - 3}` : props.data.length}
                  </CustomAvatar>
                )}
              </MoreAvatar>
            )}
          </div>
        ) || (
            <Typography className='text-center my-4 mx-auto' sx={{ fontWeight: 500, color: colors.textLightGray }}>{t('CLINIC_CLOSE')}</Typography>
          )}
      </ButtonDropdown>
      {!isOpen && (<MenuDropdown id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }} classes={{ list: 'custom-scroll' }} sx={{ '& .MuiMenu-list': { width: width } }}>
        {_.map(props.data, (doctor: DataPropsType, index) => {
          return (
            <MenuDropdownItem key={index} value={doctor.doctorId} onClick={handdleSelect}>
              <CustomAvatar alt={doctor.doctorFullName} src={doctor.avatar} sx={{ width: 40, height: 40, backgroundColor: colors.white }}>
                <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
              </CustomAvatar>
              <div className={'pl-3 '} style={{ overflow: 'hidden' }}>
                <p className="title text-ellipsis m-0 d-flex align-items-center">
                  <Tooltip title={doctor.doctorFullName} placement="top">
                    <span >
                      {doctor.doctorFullName}
                    </span>
                  </Tooltip>

                  <DivServicePointName>
                    <Tooltip
                      title={<div>{doctor.servicePointName}</div>}
                      placement={'top-start'} >
                      <div>{doctor.servicePointName}</div>
                    </Tooltip>
                  </DivServicePointName>
                </p>
                <p className={'subtitle text-ellipsis m-0'}>
                  <span className='pr-1'>{t('HOME.TITLE.START_WORK')}</span>
                  {doctor.status === 'NORMAL' && (
                    `${moment(doctor.startWork, 'HH:mm').format('HH.mm')} - ${moment(doctor.getOffWork, 'HH:mm').format('HH.mm')} ${t('TIME_UNIT')}`
                  ) || (
                      <span style={{ color: getColorStatus(doctor.status || '', 'COLOR') }}>{doctor.status ? `(${t(`DOCTOR_SCHEDULE.STATUS.${doctor.status}`)})` : '-'}</span>
                    )}
                </p>
              </div>
              {props.markDoctor !== undefined && (
                <div style={{ marginLeft: 'auto' }}>
                  <ButtomSelectAllWeek className='ml-1' style={{ padding: '0.25rem 0.5rem' }} onClick={() => (props.onMarkDoctor ? props.onMarkDoctor(doctor.doctorId) : undefined)}>
                    <Image src={props.markDoctor === doctor.doctorId ? mark : markLine} style={{ width: '16px', marginRight: '0.375rem' }} />
                    <span style={{ fontSize: '14px' }}>{t('APPOINTMENT.BUTTON.SELECT_WEEK')}</span>
                  </ButtomSelectAllWeek>
                </div>
              )}
            </MenuDropdownItem>
          )
        })}
        {props.data.length === 0 && (
          <MenuDropdownItem className="text-center no-bg">
            <div className="w-100">{t('APPOINTMENT.STATUS.NO')}{t('USER_DOCTOR')}{t('APPOINTMENT.BUTTON.ENTER_SHIFT_TODAY')}</div>
          </MenuDropdownItem>
        )}
      </MenuDropdown>
      )}
    </>
  )
}
