// const envSettings = (process.env.NODE_ENV === 'development' ? process.env : window) as any
const envSettings = window as any

export class Config {
  static REACT_APP_LINE_NOTI_CLIENT_ID = envSettings.REACT_APP_LINE_NOTI_CLIENT_ID
  static REACT_APP_LINE_NOTI_CLIENT_SECRET = envSettings.REACT_APP_LINE_NOTI_CLIENT_SECRET
  static REACT_APP_LINE_NOTI_CALLBACK_URL = envSettings.REACT_APP_LINE_NOTI_CALLBACK_URL
  static REACT_APP_APP_NAME = envSettings.REACT_APP_APP_NAME
  static REACT_APP_API_KEY = envSettings.REACT_APP_API_KEY
  static REACT_APP_BASE_URL = envSettings.REACT_APP_BASE_URL
  static AZURE_CONTAINER = envSettings.AZURE_CONTAINER
  static REACT_APP_IMAGE_URL = envSettings.REACT_APP_IMAGE_URL
  static REACT_APP_DOMAIN = envSettings.REACT_APP_DOMAIN
  static REACT_APP_URL = envSettings.REACT_APP_URL
  static REACT_APP_CLINIC_THEME = envSettings.REACT_APP_CLINIC_THEME
  static REACT_APP_LOGO = envSettings.REACT_APP_LOGO
  static REACT_APP_BACKGROUND = envSettings.REACT_APP_BACKGROUND
  static REACT_APP_BASE_URL_IO = envSettings.REACT_APP_BASE_URL_IO
}
