import { Box, styled } from "@mui/material";
import { colors } from "constants/theme";

export const BoxTableTag = styled(Box)(({ theme }) => ({
    '.tb-tag-no': {
        minWidth: '80px'
    },
    '.tb-tag-tag-color': {
        minWidth: '120px'
    },
    '.tb-tag-tag-name': {
        minWidth: '300px'
    },
    '.tb-tag-status': {
        minWidth: '200px'
    },
    '.tb-tag-updateby': {
        minWidth: '180px'
    },
    '.tb-tag-action': {
        minWidth: '160px'
    },
}))

export const ColorTag = styled(Box)<{ color: string, error?: boolean }>(({ theme, color, error }) => ({
    height: '43px',
    borderRadius: '4px',
    border: error ? `2px solid ${colors.statusInactive}` : '1px solid #0000001f',
    backgroundColor: color || 'transparent'
}))

export const BoxManageColor = styled(Box)(({ theme }) => ({
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 10px 15px 0px rgba(31, 41, 55, 0.10), 0px 0px 6px 0px rgba(31, 41, 55, 0.05)',
    position: 'absolute',
    zIndex: 1,
    borderRadius: '8px',
    marginTop: '1px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '.content-select-color': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        '.chrome-picker': {
            borderRadius: '4px !important',
            boxShadow: 'none !important',
            overflow: 'hidden'
        },
        '.chrome-picker div:nth-child(2)': {
            padding: '0px !important',
            '.flexbox-fix': {
                display: 'none !important'
            }
        },
        '.hue-picker ': {
            width: "100% !important",
            '.hue-horizontal': {
                borderRadius: '8px !important',
                'div div': {
                    border: '3px solid #fff',
                    backgroundColor: 'transparent !important'
                }
            }
        }
    }
}))