import Dropdown from 'react-bootstrap/Dropdown'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/theme'
import InputCheckbox from './InputCheckbox'
import InputRadio from 'component/Input/InputRadio'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

type InputSelectByProps = {
  title?: string
  objTypeSearch: any
  input: any
  onChangeText: (value: any) => void
  onChangeCheck?: (value: any) => void
  labelCheck?: string
  defaultChecked?: boolean
  checked?: boolean
  indeterminate?: boolean
  disabled?: boolean
}

const useStyles = makeStyles({
  containerInput: {
    height: '35px',
    display: 'flex'
  },
  textInput: {
    '& .MuiFormControl-root': { width: '16px !important' },
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      height: '0.2vw !important',
      fontSize: '16px !important',
      borderRadius: '0px !important',
      width: '100%'
    },
    '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
      height: '100%',
      width: '100%',
      borderRadius: '0px 4px 4px 0px !important'
    }
  },
  containerDropdown: {
    '& .btn': {
      height: '100%',
      fontSize: '16px !important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colors.themeSecondColor,
      border: `1px solid ${colors.themeSecondColor}`,
      borderRadius: '4px 0px 0px 4px ',
      boxShadow: `0 0 0 0.25rem ${colors.white}`
    },
    '& .btn-primary': {
      backgroundColor: `${colors.themeSecondColor} !important`,
      border: `1px solid ${colors.themeSecondColor} !important`
    },
    '& .btn-check': {
      backgroundColor: `${colors.themeSecondColor} !important`
    }
  },
  dropDownMenu: {
    padding: '16px',
    '& .ant-checkbox-wrapper': {
      fontSize: '16px'
    },
    '& .css-ahj2mt-MuiTypography-root': {
      fontSize: '16px'
    }
  },
  textFile: {
    '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
      borderRadius: '0px !important'
    }
  }
})

export default function InputSelectBy(props: InputSelectByProps) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.containerInput}>
      <Dropdown className={`${classes.containerDropdown} d-inline`} autoClose="outside">
        <Dropdown.Toggle id="dropdown-autoclose-outside">{t('SEARCH_BY')}</Dropdown.Toggle>
        <Dropdown.Menu className={classes.dropDownMenu}>
          <InputCheckbox indeterminate={props.indeterminate} checked={props.checked} disabled={props.disabled} onChange={props.onChangeCheck} label={props.labelCheck} />
          <Dropdown.Divider />
          <FormControl>
            <RadioGroup aria-labelledby="share-radio-buttons-group-label" name="radio-buttons-group">
              {props.objTypeSearch.map((type: any) => {
                return (
                  <FormControlLabel
                    key={type.value}
                    value={type.value}
                    className={'text-nowrap'}
                    control={
                      <InputRadio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 16
                          }
                        }}
                      />
                    }
                    label={type.label}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Dropdown.Menu>
      </Dropdown>
      <TextField style={{ fontSize: '0.93vw', width: '100%' }} value={props.input} onChange={props.onChangeText} className={classes.textInput} placeholder={t('SEARCH_PATIENT')} />
    </div>
  )
}
