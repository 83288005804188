import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery';
import { colors } from 'constants/theme'

/** UTILS || SLICE */
import { isSubmitTootChart, resetToothChart, setSubmitTootChart, setSummeryTreatments, setToothChart, setToothChartSelected, toothChart, toothChartColor, toothChartSelected, treatment, setEditTootChart, isEditTootChart, setColorToothChart, historyToothChart, setDiagnosisTreatments, TreatmentStateInterface, setTreatmentByKey, setEditTootChartType } from 'app/slice/treatment.slice'
import { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth';

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import Root from 'assets/toothChart/Operative/root.svg'
import Dentin from 'assets/toothChart/Operative/dentin.svg'
import Xray from 'assets/toothChart/Operative/xray.svg'
import FixRoot from 'assets/toothChart/Operative/fixRoot.svg'
import Braces from 'assets/toothChart/Operative/braces.svg'
import Extraction from 'assets/toothChart/Operative/extraction.svg'
import Implant from 'assets/toothChart/Operative/implant.svg'
import Bridge from 'assets/toothChart/Operative/bridge.svg'

//** ADULT */
import Teeth11 from 'component/ToothChart/Teeth/Q1/Teeth-11'
import Teeth12 from 'component/ToothChart/Teeth/Q1/Teeth-12'
import Teeth13 from 'component/ToothChart/Teeth/Q1/Teeth-13'
import Teeth14 from 'component/ToothChart/Teeth/Q1/Teeth-14'
import Teeth15 from 'component/ToothChart/Teeth/Q1/Teeth-15'
import Teeth16 from 'component/ToothChart/Teeth/Q1/Teeth-16'
import Teeth17 from 'component/ToothChart/Teeth/Q1/Teeth-17'
import Teeth18 from 'component/ToothChart/Teeth/Q1/Teeth-18'
import Teeth21 from 'component/ToothChart/Teeth/Q2/Teeth-21'
import Teeth22 from 'component/ToothChart/Teeth/Q2/Teeth-22'
import Teeth23 from 'component/ToothChart/Teeth/Q2/Teeth-23'
import Teeth24 from 'component/ToothChart/Teeth/Q2/Teeth-24'
import Teeth25 from 'component/ToothChart/Teeth/Q2/Teeth-25'
import Teeth26 from 'component/ToothChart/Teeth/Q2/Teeth-26'
import Teeth27 from 'component/ToothChart/Teeth/Q2/Teeth-27'
import Teeth28 from 'component/ToothChart/Teeth/Q2/Teeth-28'
import Teeth31 from 'component/ToothChart/Teeth/Q3/Teeth-31'
import Teeth32 from 'component/ToothChart/Teeth/Q3/Teeth-32'
import Teeth33 from 'component/ToothChart/Teeth/Q3/Teeth-33'
import Teeth34 from 'component/ToothChart/Teeth/Q3/Teeth-34'
import Teeth35 from 'component/ToothChart/Teeth/Q3/Teeth-35'
import Teeth36 from 'component/ToothChart/Teeth/Q3/Teeth-36'
import Teeth37 from 'component/ToothChart/Teeth/Q3/Teeth-37'
import Teeth38 from 'component/ToothChart/Teeth/Q3/Teeth-38'
import Teeth41 from 'component/ToothChart/Teeth/Q4/Teeth-41'
import Teeth42 from 'component/ToothChart/Teeth/Q4/Teeth-42'
import Teeth43 from 'component/ToothChart/Teeth/Q4/Teeth-43'
import Teeth44 from 'component/ToothChart/Teeth/Q4/Teeth-44'
import Teeth45 from 'component/ToothChart/Teeth/Q4/Teeth-45'
import Teeth46 from 'component/ToothChart/Teeth/Q4/Teeth-46'
import Teeth47 from 'component/ToothChart/Teeth/Q4/Teeth-47'
import Teeth48 from 'component/ToothChart/Teeth/Q4/Teeth-48'


//** CHILD */
/** STYLE */
import Teeth51 from 'component/ToothChart/TeethYoung/Q1/Teeth-51'
import Teeth52 from 'component/ToothChart/TeethYoung/Q1/Teeth-52'
import Teeth53 from 'component/ToothChart/TeethYoung/Q1/Teeth-53'
import Teeth54 from 'component/ToothChart/TeethYoung/Q1/Teeth-54'
import Teeth55 from 'component/ToothChart/TeethYoung/Q1/Teeth-55'
import Teeth61 from 'component/ToothChart/TeethYoung/Q2/Teeth-61'
import Teeth62 from 'component/ToothChart/TeethYoung/Q2/Teeth-62'
import Teeth63 from 'component/ToothChart/TeethYoung/Q2/Teeth-63'
import Teeth64 from 'component/ToothChart/TeethYoung/Q2/Teeth-64'
import Teeth65 from 'component/ToothChart/TeethYoung/Q2/Teeth-65'
import Teeth71 from 'component/ToothChart/TeethYoung/Q3/Teeth-71'
import Teeth72 from 'component/ToothChart/TeethYoung/Q3/Teeth-72'
import Teeth73 from 'component/ToothChart/TeethYoung/Q3/Teeth-73'
import Teeth74 from 'component/ToothChart/TeethYoung/Q3/Teeth-74'
import Teeth75 from 'component/ToothChart/TeethYoung/Q3/Teeth-75'
import Teeth81 from 'component/ToothChart/TeethYoung/Q4/Teeth-81'
import Teeth82 from 'component/ToothChart/TeethYoung/Q4/Teeth-82'
import Teeth83 from 'component/ToothChart/TeethYoung/Q4/Teeth-83'
import Teeth84 from 'component/ToothChart/TeethYoung/Q4/Teeth-84'
import Teeth85 from 'component/ToothChart/TeethYoung/Q4/Teeth-85'

import { BtnPosition, StyleToothChart, StyleToothChartL, StyleToothChartQ1, StyleToothChartQ2, StyleToothChartQ3, StyleToothChartQ4, StyleToothChartU, StyleHeaderToothChart, StyleBtnHeader, BtnOperative } from 'component/ToothChart/style'
import { useTranslation } from 'react-i18next'
import 'component/ToothChart/style.css'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import { useHistory, useLocation } from 'react-router-dom'
import { routeName } from 'routes/routes-name';
import { swalCustom, swalWarning } from 'component/Alert/Swal';

export enum TEETH_OPERATIVES {
  LOWER_TEETH = `LOWER_TEETH`,
  TOOTH = `TOOTH`,
  X_RAY = `X_RAY`,
  TOOTH_ROOT = `TOOTH_ROOT`,
  ORTHODONTICS = `ORTHODONTICS`,
  EXTRACT = `EXTRACT`,
  FIXTURE = `FIXTURE`,
  BRIDGE = `BRIDGE`
}

export type TeethProps = {
  operative: string | null
  teethNumber: number
  color: string
  historyToothChart?: any[],
  type: 'dx' | 'treatment'
}
export type TeethPositionProps = {
  isSelected?: boolean,
  teethNumber: number
  position?: any[],
  handleChangePosition?: (val: any) => void
  quarter: QUARTER_NAME_TYPE
}

// type TeethType = 'CHILD' | 'ADULT'

interface ToothChartProps {
  teethType: string
  patientId?: number
  isRetrospectiveOrder?: boolean
  type: 'dx' | 'treatment'
  handleToothChart?: (data: any) => void
  teethChartJSON?: any
  noneSave?: boolean
  tagColor?: string
}

const positionTeethU = [{ key: 'U', value: 'U' }, { key: 'Ant', value: 'Ant-U' }, { key: 'Post', value: 'Post-U' }, { key: 'Q1', value: 'Q1' }, { key: 'Q2', value: 'Q2' }]
const positionTeethL = [{ key: 'L', value: 'L' }, { key: 'Ant', value: 'Ant-L' }, { key: 'Post', value: 'Post-L' }, { key: 'Q3', value: 'Q3' }, { key: 'Q4', value: 'Q4' }]

const operatives = [
  { value: TEETH_OPERATIVES.LOWER_TEETH, image: Root },
  { value: TEETH_OPERATIVES.TOOTH, image: Dentin },
  { value: TEETH_OPERATIVES.X_RAY, image: Xray },
  { value: TEETH_OPERATIVES.TOOTH_ROOT, image: FixRoot },
  { value: TEETH_OPERATIVES.ORTHODONTICS, image: Braces },
  { value: TEETH_OPERATIVES.EXTRACT, image: Extraction },
  { value: TEETH_OPERATIVES.FIXTURE, image: Implant },
  { value: TEETH_OPERATIVES.BRIDGE, image: Bridge }
]

export const GROUP_TOOTHS: any = {
  'Post-U': {
    ADULT: ['18', '17', '16', '15', '14', '24', '25', '26', '27', '28'],
    CHILD: ['55', '54', '53', '63', '64', '65']
  },
  'Ant-U': {
    ADULT: ['13', '12', '11', '21', '22', '23'],
    CHILD: ['52', '51', '61', '62']
  },
  Q1: {
    ADULT: ['18', '17', '16', '15', '14', '13', '12', '11'],
    CHILD: ['55', '54', '53', '52', '51']
  },
  Q2: {
    ADULT: ['21', '22', '23', '24', '25', '26', '27', '28'],
    CHILD: ['61', '62', '63', '64', '65']
  },
  'Post-L': {
    ADULT: ['38', '37', '36', '35', '34', '44', '45', '46', '47', '48'],
    CHILD: ['75', '74', '73', '83', '84', '85']
  },
  'Ant-L': {
    ADULT: ['33', '32', '31', '41', '42', '43'],
    CHILD: ['72', '71', '81', '82']
  },
  Q3: {
    ADULT: ['38', '37', '36', '35', '34', '33', '32', '31'],
    CHILD: ['75', '74', '73', '72', '71']
  },
  Q4: {
    ADULT: ['41', '42', '43', '44', '45', '46', '47', '48'],
    CHILD: ['81', '82', '83', '84', '85']
  },
  U: {
    ADULT: ['18', '17', '16', '15', '14', '13', '12', '11', '21', '22', '23', '24', '25', '26', '27', '28'],
    CHILD: ['55', '54', '53', '52', '51', '61', '62', '63', '64', '65']
  },
  L: {
    ADULT: ['38', '37', '36', '35', '34', '33', '32', '31', '41', '42', '43', '44', '45', '46', '47', '48'],
    CHILD: ['75', '74', '73', '72', '71', '81', '82', '83', '84', '85']
  },
  Full: {
    ADULT: ['18', '17', '16', '15', '14', '13', '12', '11', '21', '22', '23', '24', '25', '26', '27', '28', '38', '37', '36', '35', '34', '33', '32', '31', '41', '42', '43', '44', '45', '46', '47', '48'],
    CHILD: ['55', '54', '53', '52', '51', '61', '62', '63', '64', '65', '75', '74', '73', '72', '71', '81', '82', '83', '84', '85']
  }
}

export default function ToothChart(props: ToothChartProps) {
  const location = useLocation()
  const history = useHistory()

  const positionsRef: any = useRef<[HTMLButtonElement]>(null)
  const teeth = useSelector(toothChart)

  const valueToothChartSelected = useSelector(toothChartSelected)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)
  const isSubmit = useSelector(isSubmitTootChart)
  const historyToothCharts = useSelector(historyToothChart)

  const tcColor = useSelector(toothChartColor)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [operativeSelected, setOperativeSelected] = useState('TOOTH')
  const [color, setColor]: any = useState(tcColor || null)

  const handleSelectGroupTeeth = (group: any) => {
    const teethType = props.teethType
    const tooths = GROUP_TOOTHS[group][teethType]

    if ($(`.btn-group-teeth-${group}`).hasClass('active')) {
      $(`.btn-group-teeth-${group}`).removeClass('active')
      _.map(tooths, (tNumber: any) => {
        dispatch(setToothChart({ key: tNumber, value: { group: ``, teeth: tNumber, isSelect: false, operative: `` } }))
      })

      const checkValues = $(".btn-group-teeth.active").get()
      _.map(checkValues, (value: any) => {
        const subGroup = value.getAttribute('data-value')
        const subTooths = GROUP_TOOTHS[subGroup][teethType]
        _.map(subTooths, (tNumber: any) => {
          dispatch(setToothChart({ key: tNumber, value: { group: group, teeth: tNumber, isSelect: true, operative: operativeSelected } }))
        })
      })

    } else {
      $(`.btn-group-teeth-${group}`).addClass('active')
      _.map(tooths, (tNumber: any) => {
        dispatch(setToothChart({ key: tNumber, value: { group: group, teeth: tNumber, isSelect: true, operative: operativeSelected } }))
      })

      const checkValues = $(".btn-group-teeth.active").get()
      _.map(checkValues, (value: any) => {
        const subGroup = value.getAttribute('data-value')
        const subTooths = GROUP_TOOTHS[subGroup][teethType]
        _.map(subTooths, (tNumber: any) => {
          dispatch(setToothChart({ key: tNumber, value: { group: group, teeth: tNumber, isSelect: true, operative: operativeSelected } }))
        })
      })
    }
  }

  const handleSelectOperative = (ele: any) => {
    const operative = ele.currentTarget.getAttribute('data-value')
    if (operative === operativeSelected) setOperativeSelected('')
    else setOperativeSelected(operative)
  }

  const handleEditToothChart = (type: 'save' | 'cancel') => {
    if (type === 'cancel') {
      dispatch(setEditTootChart(null))
      dispatch(setEditTootChartType(''))
    }
    else dispatch(setSubmitTootChart(true))
  }

  const loadToothChartInnit = async (patientId: number) => {
    const resp = await TreatmentHistoryApi.findTreatmentHistoryToothChart(patientId).catch((err) => {

      swalWarning(
        `${t('MESSAGE.TOOTCH_PATIENT_NOT_FOUND')}`,
        '',
        (res: boolean) => {
          if (location.pathname === routeName.treatmentsForm) {
            history.push(routeName.treatmentsQueue)
          } else {
            history.push(routeName.payment)
          }
        }, {
        confirmButtonText: t('BUTTON.SUBMIT'),
        showCancelButton: false
      })
      return
    })

    if (resp?.status === 200) {
      const newArray: any = []
      _.map(resp.data, (tooths: any, index: any) => {
        if (tooths.teethJson) newArray.push(JSON.parse(tooths.teethJson))
      })
      dispatch(setToothChartSelected([...newArray, ...(valueToothChartSelected || []),]))
    }
  }

  useEffect(() => {
    if (props?.teethChartJSON) {
      dispatch(resetToothChart())
      $('.btn-group-teeth').removeClass('active')
      setOperativeSelected('')
      dispatch(setSubmitTootChart(false))
    }

    const state: any = location.state
    const patientId = state?.patientId || props.patientId
    if (patientId) loadToothChartInnit(patientId)
  }, [])

  useEffect(() => {
    if (isSubmit === true && props.type === 'treatment') {
      const operative = operativeSelected

      const newDataToothChart: any = {}

      let textToothSelected = ''
      const arrayToothSelected: any = []
      const teethSelected = _.filter(teeth, (teethItem, index: number) => teethItem?.isSelect)
      const textTeeths: any = []

      const positionTeetGroup: any = []

      _.map(teethSelected, (tSelected: any, index: any) => {
        if (tSelected?.isSelect) {
          if (tSelected?.group && textTeeths.indexOf(tSelected?.group) === -1) textTeeths.push(tSelected.group)
          else if (!tSelected?.group) textToothSelected += `${tSelected.teeth}`

          if (tSelected?.positions && tSelected.positions.length) {
            const positionTeeths = tSelected.positions
            if (tSelected?.group && positionTeeths) {
              _.map(positionTeeths, (positionTeeth: any) => {
                if (!positionTeetGroup[tSelected.group]) positionTeetGroup[tSelected.group] = []
                if (positionTeetGroup[tSelected.group].indexOf(positionTeeth) === -1) positionTeetGroup[tSelected.group].push(positionTeeth)
              })
            }
            else textToothSelected += `(${positionTeeths.toString().replace(/,/g, "+")})`
          }

          if (!tSelected?.group && (teethSelected.length - 1) >= 0 && index !== teethSelected.length - 1) textToothSelected += `,`
        }

        if (isEditTootCharts !== null && !tcColor) {
          if (treatments.summeryTreatments[isEditTootCharts]?.teethJson) {
            const historyColor = JSON.parse(treatments.summeryTreatments[isEditTootCharts]?.teethJson)[0].color
            arrayToothSelected.push({ ...tSelected, operative: operative, color: historyColor })
          } else arrayToothSelected.push({ ...tSelected, operative: operative, color: treatments.summeryTreatments[isEditTootCharts]?.color || props?.tagColor || colors.white })
        } else {
          arrayToothSelected.push({ ...tSelected, operative: operative, color: tcColor })
        }
      })

      _.map(textTeeths, (group: any, index: any) => {
        if (textToothSelected) textToothSelected += `,`
        textToothSelected += `${group}`
        if (positionTeetGroup[group]) textToothSelected += `(${positionTeetGroup[group].toString().replace(/,/g, "+")})`
        if ((textTeeths.length - 1) >= 0 && index !== textTeeths.length - 1)
          textToothSelected += `,`
      })

      if (textToothSelected) {
        if (props?.handleToothChart) {
          newDataToothChart.teeth = textToothSelected
          newDataToothChart.teethType = props.teethType
        } else {
          dispatch(setSummeryTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.summeryTreatments.length - 1, key: 'teeth', value: textToothSelected }))
          dispatch(setSummeryTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.summeryTreatments.length - 1, key: 'teethType', value: props.teethType }))
        }
      } else if (!textToothSelected && isEditTootCharts !== null) {
        if (props?.handleToothChart) {
          newDataToothChart.teeth = ''
          newDataToothChart.teethType = ''
        } else {
          dispatch(setSummeryTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.summeryTreatments.length - 1, key: 'teeth', value: '' }))
          dispatch(setSummeryTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.summeryTreatments.length - 1, key: 'teethType', value: '' }))
        }
      }

      if (!_.isEmpty(arrayToothSelected)) {
        if (props?.handleToothChart) {
          newDataToothChart.teethJson = JSON.stringify(arrayToothSelected)
          newDataToothChart.teethType = props.teethType
        } else {
          dispatch(setSummeryTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.summeryTreatments.length - 1, key: 'teethJson', value: JSON.stringify(arrayToothSelected) }))
          dispatch(setSummeryTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.summeryTreatments.length - 1, key: 'teethType', value: props.teethType }))
        }
      } else if (_.isEmpty(arrayToothSelected) && isEditTootCharts !== null) {
        if (props?.handleToothChart) {
          newDataToothChart.teethJson = ''
          newDataToothChart.teethType = ''
        } else {
          dispatch(setSummeryTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.summeryTreatments.length - 1, key: 'teethJson', value: '' }))
          dispatch(setSummeryTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.summeryTreatments.length - 1, key: 'teethType', value: '' }))
        }
      }

      if (props?.handleToothChart) {
        props?.handleToothChart(newDataToothChart)
        dispatch(setEditTootChart(null))
        dispatch(setEditTootChartType(''))
      } else {
        if (isEditTootCharts === null) {
          dispatch(setToothChartSelected([...(valueToothChartSelected || []), arrayToothSelected]))
        } else {
          const newArray: any = [];
          _.map(treatments.summeryTreatments, (summery: any, index: any) => {
            if (index === isEditTootCharts) newArray.push(arrayToothSelected)
            else if (summery.teethJson) newArray.push(JSON.parse(summery.teethJson))
          })
          dispatch(setToothChartSelected([...historyToothCharts, ...newArray]))
          dispatch(setEditTootChart(null))
          dispatch(setEditTootChartType(''))
        }

      }




      dispatch(resetToothChart())
      $('.btn-group-teeth').removeClass('active')
      setOperativeSelected('')
      dispatch(setSubmitTootChart(false))
    } else if (isSubmit === true && props.type === 'dx') {
      let textToothSelected = ''
      const arrayToothSelected: any = []
      const teethSelected = _.filter(teeth, (teethItem, index: number) => teethItem?.isSelect)
      const textTeeths: any = []

      const positionTeetGroup: any = []

      _.map(teethSelected, (tSelected: any, index: any) => {
        if (tSelected?.isSelect) {
          if (tSelected?.group && textTeeths.indexOf(tSelected?.group) === -1) textTeeths.push(tSelected.group)
          else if (!tSelected?.group) textToothSelected += `${tSelected.teeth}`

          if (tSelected?.positions && tSelected.positions.length) {
            const positionTeeths = tSelected.positions
            if (tSelected?.group && positionTeeths) {
              _.map(positionTeeths, (positionTeeth: any) => {
                if (!positionTeetGroup[tSelected.group]) positionTeetGroup[tSelected.group] = []
                if (positionTeetGroup[tSelected.group].indexOf(positionTeeth) === -1) positionTeetGroup[tSelected.group].push(positionTeeth)
              })
            }
            else textToothSelected += `(${positionTeeths.toString().replace(/,/g, "+")})`
          }

          if (!tSelected?.group && (teethSelected.length - 1) >= 0 && index !== teethSelected.length - 1) textToothSelected += `,`
        }

        arrayToothSelected.push(tSelected)
      })

      _.map(textTeeths, (group: any, index: any) => {
        if (textToothSelected) textToothSelected += `,`
        textToothSelected += `${group}`
        if (positionTeetGroup[group]) textToothSelected += `(${positionTeetGroup[group].toString().replace(/,/g, "+")})`
        if ((textTeeths.length - 1) >= 0 && index !== textTeeths.length - 1)
          textToothSelected += `,`
      })

      if (textToothSelected) {
        dispatch(setDiagnosisTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.patientDiagnosis.length - 1, key: 'teeth', value: textToothSelected }))
      } else if (!textToothSelected && isEditTootCharts !== null) {
        dispatch(setDiagnosisTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.patientDiagnosis.length - 1, key: 'teeth', value: '' }))
      }

      if (!_.isEmpty(arrayToothSelected)) {
        dispatch(setDiagnosisTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.patientDiagnosis.length - 1, key: 'teethJson', value: JSON.stringify(arrayToothSelected) }))
      } else if (_.isEmpty(arrayToothSelected) && isEditTootCharts !== null) {
        dispatch(setDiagnosisTreatments({ index: isEditTootCharts !== null ? isEditTootCharts : treatments.patientDiagnosis.length - 1, key: 'teethJson', value: '' }))
      }

      $('.btn-group-teeth').removeClass('active')
      dispatch(setEditTootChart(null))
      dispatch(setEditTootChartType(''))
      dispatch(resetToothChart())
      setOperativeSelected('')
      dispatch(setSubmitTootChart(false))
    }
  }, [tcColor, isSubmit])

  useEffect(() => {
    const treatmentIsEditTootCharts = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = props?.teethChartJSON ? props?.teethChartJSON : treatmentIsEditTootCharts?.teethJson
    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        _.map(tooths, (tooth: any) => {
          dispatch(setToothChart({ key: tooth.teeth, value: tooth, isSelect: tooth.isSelect }))
        })
        setOperativeSelected(tooths[0]?.operative || '')
        setColor(tooths[0]?.color || null)
        dispatch(setColorToothChart({ value: props?.teethChartJSON ? tooths[0]?.color : treatmentIsEditTootCharts.color }))
      }
      else {
        dispatch(resetToothChart())
        dispatch(setColorToothChart({ value: '' }))
      }
    } else {
      dispatch(resetToothChart())
      dispatch(setColorToothChart({ value: '' }))
    }
  }, [isEditTootCharts])

  useEffect(() => {
    if (props.isRetrospectiveOrder) {
      $('.btn-group-teeth').removeClass('active')
      setOperativeSelected('')
    }
  }, [props.patientId]);

  useEffect(() => {
    if (props.teethType) {
      $('.btn-group-teeth').removeClass('active')
      // setOperativeSelected('TOOTH')
      if (treatments.summeryTreatments.length === 0)
        dispatch(setTreatmentByKey({ key: 'teethType', value: props.teethType }))
    }
  }, [props.teethType]);

  return (
    <>
      {isEditTootCharts !== null && props.noneSave === undefined && (
        <Box className='d-flex align-items-center justify-content-between mb-2'>
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{t('TREATMENTS.TOOTH.EDIT_TOOTH')}</Typography>
          <Box className='d-flex align-items-center'>
            <ButtonCustom textButton={t('BUTTON.CANCEL')} variant="outlined" onClick={() => handleEditToothChart('cancel')} btnStyle={{ height: 44, marginRight: '1rem' }} />
            <ButtonCustom textButton={t('BUTTON.SAVE')} onClick={() => handleEditToothChart('save')} btnStyle={{ height: 44 }} />
          </Box>
        </Box>
      )}

      <StyleHeaderToothChart className="justify-content-between">
        <div>
          <StyleBtnHeader>
            {_.map(positionTeethU, (position: any, indexData: number) => {
              return (
                <BtnPosition key={indexData} data-value={position.value} className={`btn-group-teeth btn-group-teeth-${position.value}`} onClick={() => handleSelectGroupTeeth(position.value)} ref={(el) => positionsRef[position.value]} variant="outlined">
                  {position.key}
                </BtnPosition>
              )
            })}
            <BtnPosition style={{ height: '30px', paddingLeft: 6, paddingRight: 6 }} data-value={'Full'} className={`btn-group-teeth btn-group-teeth-Full`} onClick={() => handleSelectGroupTeeth(`Full`)} ref={(el) => positionsRef['full-mouth']} variant="outlined">
              {t('TREATMENTS.TOOTH.FULL_MOUTH')}
            </BtnPosition>
          </StyleBtnHeader>
          <StyleBtnHeader>
            {_.map(positionTeethL, (position: any, indexData: number) => {
              return (
                <BtnPosition key={indexData} className={`btn-group-teeth btn-group-teeth-${position.value}`} data-value={position.value} onClick={() => handleSelectGroupTeeth(position.value)} ref={(el) => positionsRef[position.value]} variant="outlined">
                  {position.key}
                </BtnPosition>
              )
            })}
          </StyleBtnHeader>
        </div>
        {props.type === 'treatment' && (
          <div>
            {_.map(operatives, (operative: any, indexData: number) => {
              return (
                <Tooltip key={indexData} title={t(`TREATMENTS.TOOTH.${operative.value}`) || ''} placement="top-start" classes={{ popper: 'toothChart-operative-tooltip' }}>
                  <BtnOperative onClick={handleSelectOperative} data-value={operative.value} className="btn-toothChart-operative">
                    <img className={`${operativeSelected === operative.value ? 'active' : ''}`} src={operative.image} alt="" />
                  </BtnOperative>
                </Tooltip>
              )
            })}
          </div>
        )}
      </StyleHeaderToothChart>
      {props.teethType === 'ADULT' && (
        <StyleToothChart id='tooth-chart'>
          <StyleToothChartU>
            <StyleToothChartQ1>
              <Teeth18
                teethNumber={18}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth17
                teethNumber={17}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth16
                teethNumber={16}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth15
                teethNumber={15}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth14
                teethNumber={14}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth13
                teethNumber={13}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth12
                teethNumber={12}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth11
                teethNumber={11}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
            </StyleToothChartQ1>
            <StyleToothChartQ2>
              <Teeth21
                teethNumber={21}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth22
                teethNumber={22}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth23
                teethNumber={23}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth24
                teethNumber={24}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth25
                teethNumber={25}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth26
                teethNumber={26}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth27
                teethNumber={27}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth28
                teethNumber={28}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
            </StyleToothChartQ2>
          </StyleToothChartU>
          <StyleToothChartL>
            <StyleToothChartQ4>
              <Teeth48
                teethNumber={48}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth47
                teethNumber={47}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth46
                teethNumber={46}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth45
                teethNumber={45}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth44
                teethNumber={44}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth43
                teethNumber={43}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth42
                teethNumber={42}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth41
                teethNumber={41}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
            </StyleToothChartQ4>
            <StyleToothChartQ3>
              <Teeth31
                teethNumber={31}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth32
                teethNumber={32}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth33
                teethNumber={33}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth34
                teethNumber={34}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth35
                teethNumber={35}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth36
                teethNumber={36}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth37
                teethNumber={37}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
              <Teeth38
                teethNumber={38}
                operative={operativeSelected}
                color={color}
                historyToothChart={valueToothChartSelected}
                type={props.type} />
            </StyleToothChartQ3>
          </StyleToothChartL>
        </StyleToothChart>
      ) || (
          <StyleToothChart id='tooth-chart-child'>
            <StyleToothChartU>
              <StyleToothChartQ1>
                <Teeth55
                  teethNumber={55}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth54
                  teethNumber={54}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth53
                  teethNumber={53}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth52
                  teethNumber={52}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth51
                  teethNumber={51}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
              </StyleToothChartQ1>
              <StyleToothChartQ2>
                <Teeth61
                  teethNumber={61}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth62
                  teethNumber={62}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth63
                  teethNumber={63}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth64
                  teethNumber={64}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth65
                  teethNumber={65}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
              </StyleToothChartQ2>
            </StyleToothChartU>
            <StyleToothChartL>
              <StyleToothChartQ4>
                <Teeth85
                  teethNumber={85}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth84
                  teethNumber={84}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth83
                  teethNumber={83}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth82
                  teethNumber={82}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth81
                  teethNumber={81}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
              </StyleToothChartQ4>
              <StyleToothChartQ3>
                <Teeth71
                  teethNumber={71}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth72
                  teethNumber={72}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth73
                  teethNumber={73}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth74
                  teethNumber={74}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
                <Teeth75
                  teethNumber={75}
                  operative={operativeSelected}
                  color={color}
                  historyToothChart={valueToothChartSelected}
                  type={props.type} />
              </StyleToothChartQ3>
            </StyleToothChartL>
          </StyleToothChart>
        )}

      <img id='preview-tooth-chart' alt="" />
    </>
  )
}