export default function ToothOutlined() {
    return (
        <svg data-name="Edit tooth" xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g data-name="Group 11785">
                <path d="M250.764 156.537a11.529 11.529 0 0 0 .139-3.029c-.053-6.206-5.862-6.5-7.52-4.7-.282.306-.785.431-1.024.089-1.173-1.684-7.415-1.347-7.363 4.746a10.038 10.038 0 0 0 1.85 5.8.663.663 0 0 1 .1.547c-.417 1.693-1.256 4.243 1.152 4.223 2.577-.022 2.2-2.883 4.914-2.906 1.161-.01 1.816.573 2.735 1.573l1.3-2.148a33.611 33.611 0 0 0 2.723-3 14.363 14.363 0 0 0 .994-1.195z" transform="translate(-233.995 -143.849)" style={{ strokeMiterlimit: 10, stroke: '#9e9e9e', strokeLinejoin: 'round', strokeWidth: '1.5px', fill: 'none' }} />
                <path data-name="Path 6722" d="M19.949 3.288A1.606 1.606 0 0 1 22.22 5.56l-7.192 7.192-3.028.757.757-3.028z" transform="translate(-.255 6.636)" style={{ strokeLinecap: 'round', stroke: '#9e9e9e', strokeLinejoin: 'round', strokeWidth: '1.5px', fill: 'none' }} />
            </g>
            <path data-name="Rectangle 4365" style={{ fill: 'none' }} d="M0 0h24v24H0z" />
        </svg>
    )
}
