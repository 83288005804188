import _ from 'lodash'
import { Box, TableRow, TableCell, Button } from '@mui/material';



/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */


/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'


type Recommend = {
  popupRecommend?: () => void
  recommends?: any
}

export const ButtonAddRecommend = styled(Button)(({ theme }) => ({
  padding: '0 0 0 24px',
  fontSize: 16,
  color: colors.themeSecondColor,
  backgroundColor: 'transparent !important',
  fontWeight: 400,
  span: {
    fontSize: 20
  },
  '&:hover': {
    fontWeight: 600
  }
}))

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        paddingLeft: 8,
        paddingRight: 8,
        '&.MuiTableCell-head:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 40,
          textAlign: 'center'
        },
        '&.MuiTableCell-head:nth-of-type(2)': {
          width: '95%',
          textAlign: 'left',
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        padding: '8px',
        verticalAlign: 'top !important',
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        }
      }
    },
  },
  '.MuiTableFooter-root': {
    display: 'none'
  }
}))


export default function RecommendEmr(props: Recommend) {
  const { t } = useTranslation()


  const headCells = [
    { id: 'no', disablePadding: false, align: 'center', label: '#' },
    { id: 'message', disablePadding: false, align: 'left', label: t('RECOMMEND_SUMMARY.TABLE.RECOMMEND_LIST') }
  ]


  const renderData = (data: any, no: number) => {
    return (
      <>
        <TableRow key={`diagnosis-${no}`}>
          <TableCell align="center" scope="row" className="py-3 py-md-2">
            {no + 1}
          </TableCell>
          <TableCell align="left" scope="row" className="py-3 py-md-2">
            {String(data.recommendText)}
          </TableCell>
        </TableRow>
      </>
    )
  }



  return (
    <>
      <Box>
        <TableWidth className={'overflow-hidden'}>
          <TableCustom
            hidePagination
            customScroll
            page={0}
            pageLimit={0}
            sortType={'ASC'}
            sortBy={''}
            rowCount={0}
            textEmptyData={t('REGISTER.NO_ITEM')}
            onSort={() => {
              return
            }}
            setPageLimit={() => {
              return
            }}
            setPage={() => {
              return
            }}
            headCells={headCells}
            rowsData={
              _.map(props.recommends, (i, index: number) => {
                return renderData(i, index)
              }).reverse()
            }
          />
        </TableWidth>
      </Box>
    </>

  )
}
