import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/45.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginBottom: 'auto',
  top: '1.4%'
}))

export default function Teeth45(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: "30.508", height: "92.316", viewBox: "0 0 30.508 92.316" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <TextNumberL>45</TextNumberL>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <DivTeethL className={`sm div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9174">

              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5350"
                d="m312.876 503.418.748 5.085v.006c-6.729 13.451-7.966 28.191-10.293 42.686a150.549 150.549 0 0 1-9.223 33.463 6.047 6.047 0 0 1-1.031 1.868 1.85 1.85 0 0 1-2.5.182c-1.356-1.065-1.591-2.773-1.28-4.519 2.211-12.574.806-25.033-1.025-37.478-1.7-11.569-.161-23.422-3.113-34.874-.129-.5-.258-1.012-.638-2.494a.932.932 0 0 0-.052.116v-4.452a1.89 1.89 0 0 1 .48-1.245c1.069-1.218 2.034-2.9 4.329-2.206a46.85 46.85 0 0 0-.116.471 15.5 15.5 0 0 0-.29 3.745c.2 6.761 3.848 15.146 8.166 16.152 6.561 1.521 8.972-4.634 11.936-8.54 3.119-4.112 2.939-7.586-.271-11.363a26.715 26.715 0 0 0-3.551-3.4c.058-.013.1-.026.155-.032h.006c2.392-.461 4 .209 6.677 3.06a1.854 1.854 0 0 1 .477 1l.361 2.471c.058.129.11.258.161.393z"
                transform="translate(-283.659 -494.793)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6497"
                  d="M308.378 522.67c-2.4 9.043-3.515 18.973-5.048 28.525a150.549 150.549 0 0 1-9.223 33.463 6.047 6.047 0 0 1-1.031 1.868 1.85 1.85 0 0 1-2.5.182c-1.356-1.065-1.591-2.773-1.28-4.519 2.211-12.574.806-25.033-1.025-37.478-1.092-7.45-.872-15.511-1.365-23.307a4.715 4.715 0 0 0 2.031 2.766 2.774 2.774 0 0 0 3.641-.687 4.085 4.085 0 0 1 1.422-.922 6.809 6.809 0 0 1 2.375-.562 13.7 13.7 0 0 1 3.281.063c.891.516.969.563 1.954 1.122a19.208 19.208 0 0 0 2.265.94 4.648 4.648 0 0 0 2.733.086c1.114-.296 1.286-.758 1.77-1.54z"
                  transform="translate(-283.659 -494.793)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 9705">
                <path
                  data-name="Path 5351"
                  d="M286.432 520.157c-.046-.308-1.953-12.072-2.8-13.9a2.509 2.509 0 0 1-.209-.6c-.376-1.977-1.419-10.679 9.511-10.125a23.1 23.1 0 0 0 6.687-1.162 14 14 0 0 1 10.151 1.162c2.175 1.174 3.948 5.2 3.948 8.506a29.422 29.422 0 0 1-.2 3.42 10.51 10.51 0 0 1-1.026 3.454 75.361 75.361 0 0 0-4.387 10.214s-.674 5.245-8.892 0c0 0-5.237-.733-7.17 1.523s-5.182.405-5.613-2.492z"
                  transform="translate(-283.211 -493.875)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5352"
                  d="M288.175 520.4c-.041-.282-1.787-11.043-2.561-12.711a2.337 2.337 0 0 1-.192-.547c-.344-1.809-1.3-9.768 8.7-9.262a21.134 21.134 0 0 0 6.117-1.063 12.809 12.809 0 0 1 9.285 1.063c1.99 1.074 3.611 4.759 3.611 7.781a26.9 26.9 0 0 1-.18 3.128 9.591 9.591 0 0 1-.938 3.159A69 69 0 0 0 308 521.3s-.617 4.8-8.134 0c0 0-4.79-.671-6.559 1.393s-4.738.362-5.132-2.293z"
                  transform="translate(-283.929 -494.758)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5353"
                  d="M289.857 520.872c-.038-.257-1.625-10.05-2.331-11.568a2.137 2.137 0 0 1-.175-.5c-.313-1.646-1.181-8.89 7.918-8.428a19.256 19.256 0 0 0 5.567-.967 11.658 11.658 0 0 1 8.451.967c1.811.977 3.286 4.331 3.286 7.081a24.374 24.374 0 0 1-.164 2.847 8.724 8.724 0 0 1-.854 2.875 62.779 62.779 0 0 0-3.652 8.5s-.561 4.367-7.4 0c0 0-4.359-.61-5.969 1.268s-4.318.337-4.677-2.075z"
                  transform="translate(-284.62 -495.694)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />

                {(isToothRoot && props.type === 'treatment') ?
                  <path
                    data-name="Path 6559"
                    d="M8.113 10.926c-.018.473-.546 10.145-.936 21.218-.185 5.242.222 12.223-.3 18.322C5.858 62.3 3.721 72.434 4.009 72.791a216.436 216.436 0 0 0 3.167-22.325c.512-5.576.261-12.928.587-18.322a211.413 211.413 0 0 1 2.474-21.3 8.849 8.849 0 0 0-2.124.082z"
                    transform="translate(4.28 16.783)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  : ''}
                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9468">
                      <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.317 -4.401)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.317 -4.401)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') && (
                  <>
                    <defs>
                      <linearGradient id="vy0mrvstca" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="8knymgbeqb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9361">
                      <path data-name="Path 5350" d="m312.876 503.418.748 5.085v.006c-6.729 13.451-7.966 28.191-10.293 42.686a150.549 150.549 0 0 1-9.223 33.463 6.047 6.047 0 0 1-1.031 1.868 1.85 1.85 0 0 1-2.5.182c-1.356-1.065-1.591-2.773-1.28-4.519 2.211-12.574.806-25.033-1.025-37.478-1.7-11.569-.161-23.422-3.113-34.874-.129-.5-.258-1.012-.638-2.494a.932.932 0 0 0-.052.116v-4.452a1.89 1.89 0 0 1 .48-1.245c1.069-1.218 2.034-2.9 4.329-2.206a46.85 46.85 0 0 0-.116.471 15.5 15.5 0 0 0-.29 3.745c.2 6.761 3.848 15.146 8.166 16.152 6.561 1.521 8.972-4.634 11.936-8.54 3.119-4.112 2.939-7.586-.271-11.363a26.715 26.715 0 0 0-3.551-3.4c.058-.013.1-.026.155-.032h.006c2.392-.461 4 .209 6.677 3.06a1.854 1.854 0 0 1 .477 1l.361 2.471c.058.129.11.258.161.393z" transform="translate(-283.659 -494.793)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5351" d="M286.432 520.157c-.046-.308-1.953-12.072-2.8-13.9a2.509 2.509 0 0 1-.209-.6c-.376-1.977-1.419-10.679 9.511-10.125a23.1 23.1 0 0 0 6.687-1.162 14 14 0 0 1 10.151 1.162c2.175 1.174 3.948 5.2 3.948 8.506a29.422 29.422 0 0 1-.2 3.42 10.51 10.51 0 0 1-1.026 3.454 75.361 75.361 0 0 0-4.387 10.214s-.674 5.245-8.892 0c0 0-5.237-.733-7.17 1.523s-5.182.405-5.613-2.492z" transform="translate(-283.211 -493.875)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5352" d="M288.175 520.4c-.041-.282-1.787-11.043-2.561-12.711a2.337 2.337 0 0 1-.192-.547c-.344-1.809-1.3-9.768 8.7-9.262a21.134 21.134 0 0 0 6.117-1.063 12.809 12.809 0 0 1 9.285 1.063c1.99 1.074 3.611 4.759 3.611 7.781a26.9 26.9 0 0 1-.18 3.128 9.591 9.591 0 0 1-.938 3.159A69 69 0 0 0 308 521.3s-.617 4.8-8.134 0c0 0-4.79-.671-6.559 1.393s-4.738.362-5.132-2.293z" transform="translate(-283.929 -494.758)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5353" d="M289.857 520.872c-.038-.257-1.625-10.05-2.331-11.568a2.137 2.137 0 0 1-.175-.5c-.313-1.646-1.181-8.89 7.918-8.428a19.256 19.256 0 0 0 5.567-.967 11.658 11.658 0 0 1 8.451.967c1.811.977 3.286 4.331 3.286 7.081a24.374 24.374 0 0 1-.164 2.847 8.724 8.724 0 0 1-.854 2.875 62.779 62.779 0 0 0-3.652 8.5s-.561 4.367-7.4 0c0 0-4.359-.61-5.969 1.268s-4.318.337-4.677-2.075z" transform="translate(-284.62 -495.694)" style={{ fill: colors.white }} />
                      <g data-name="Group 9399">
                        <g data-name="Group 9396" transform="translate(9.517 10.391)">
                          <g data-name="Rectangle 3927" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.602" height="11.602" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.602" height="10.602" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.119 41.119 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(-6.653 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(3.089 2.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(3.289 2.015)" style={{ fill: 'url(#vy0mrvstca)' }} />
                          <g data-name="Group 9397" transform="translate(0 .5)">
                            <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(3.289 6.215)" style={{ fill: '#cbcccc' }} />
                            <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(3.289 6.015)" style={{ fill: 'url(#8knymgbeqb)' }} />
                          </g>
                        </g>
                        <path data-name="Path 6488" d="M4.207 10.534c7.072.79 12.427 3.124 28.768-.206" transform="translate(-2.914 4.2)" style={{ strokeLinecap: 'round', stroke: '#b5b7b7', fill: 'none' }} />
                        <path data-name="Path 6486" d="M4.257 10.565c6.624.792 13.97 3.057 28.972-.169" transform="translate(-3.1 3.376)" style={{ stroke: '#babbbb', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </>
                ) || ""}

                {(isFixture && props.type === 'treatment') ? (
                  <>
                    <defs>
                      <linearGradient id="b0cr0jc1ca" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="n72pu8sdzb" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9502">
                      <path data-name="Path 5351" d="M286.432 520.157c-.046-.308-1.953-12.072-2.8-13.9a2.509 2.509 0 0 1-.209-.6c-.376-1.977-1.419-10.679 9.511-10.125a23.1 23.1 0 0 0 6.687-1.162 14 14 0 0 1 10.151 1.162c2.175 1.174 3.948 5.2 3.948 8.506a29.422 29.422 0 0 1-.2 3.42 10.51 10.51 0 0 1-1.026 3.454 75.361 75.361 0 0 0-4.387 10.214s-.674 5.245-8.892 0c0 0-5.237-.733-7.17 1.523s-5.182.405-5.613-2.492z" transform="translate(-283.211 -493.875)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5352" d="M288.175 520.4c-.041-.282-1.787-11.043-2.561-12.711a2.337 2.337 0 0 1-.192-.547c-.344-1.809-1.3-9.768 8.7-9.262a21.134 21.134 0 0 0 6.117-1.063 12.809 12.809 0 0 1 9.285 1.063c1.99 1.074 3.611 4.759 3.611 7.781a26.9 26.9 0 0 1-.18 3.128 9.591 9.591 0 0 1-.938 3.159A69 69 0 0 0 308 521.3s-.617 4.8-8.134 0c0 0-4.79-.671-6.559 1.393s-4.738.362-5.132-2.293z" transform="translate(-283.929 -494.758)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5353" d="M289.857 520.872c-.038-.257-1.625-10.05-2.331-11.568a2.137 2.137 0 0 1-.175-.5c-.313-1.646-1.181-8.89 7.918-8.428a19.256 19.256 0 0 0 5.567-.967 11.658 11.658 0 0 1 8.451.967c1.811.977 3.286 4.331 3.286 7.081a24.374 24.374 0 0 1-.164 2.847 8.724 8.724 0 0 1-.854 2.875 62.779 62.779 0 0 0-3.652 8.5s-.561 4.367-7.4 0c0 0-4.359-.61-5.969 1.268s-4.318.337-4.677-2.075z" transform="translate(-284.62 -495.694)" style={{ fill: colors.white }} />
                      <g data-name="Group 9534">
                        <g data-name="Path 6490" transform="translate(4.285 30.018)" style={{ fill: 'url(#b0cr0jc1ca)' }}>
                          <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                          <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(2.001 24.815)" style={{ strokeLinejoin: 'round', fill: 'url(#n72pu8sdzb)' }}>
                          <path d="M.945 7.569H.938C.912 7.52.892 7.32.865 7.047.795 6.354.653 4.913.1 1.905.103.909.577.1 1.16.1h22.038c.583 0 1.057.81 1.06 1.807-.293 2.008-.447 3.306-.549 4.164-.175 1.486-.187 1.486-.385 1.486a3.548 3.548 0 0 1-.126-.001H1.16c-.062 0-.112.005-.153.008-.025.003-.046.005-.062.005z" style={{ stroke: 'none' }} />
                          <path d="M1.16.2C.643.2.206.975.2 1.896c.552 3.005.695 4.447.764 5.14.019.186.034.341.049.427.039-.003.088-.007.147-.007h22.038l.126.001a.404.404 0 0 0 .064-.003c.068-.093.13-.614.222-1.394.102-.859.255-2.155.547-4.16-.004-.923-.442-1.7-.96-1.7H1.16m0-.2h22.038c.64 0 1.16.857 1.16 1.914-.873 5.981-.52 5.742-1.16 5.742H1.16c-.64 0 0 .568-1.16-5.742C0 .857.52 0 1.16 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(-1.226 15.154)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-.939 20.353)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(-.491 42.88)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-.683 24.896)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(-.683 30.896)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(-.683 36.896)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(-.492 48.518)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g></>
                ) : ""}
              </g>
            </g>
          </svg>}
      </DivTeethL>
    </Div>
  )
}
