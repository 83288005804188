import patientManagement from 'features/counter/patient-management/patient-management'
import { menuSeconds } from 'constants/menus'

const routePatientManagement = [
  {
    ...menuSeconds.PATIENT_MANAGEMENT,
    component: patientManagement,
    appBar: true
  }
]

export default routePatientManagement
