import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

/** API, CONSTANTS, APP */
import LabOrderApi from 'api/lab-order.api'
import { colors } from 'constants/theme'
import { showModal } from 'app/slice/modal.slice'

/** COMPONENT */
import CardQueue from 'features/lab-order/component/CardQueue'
import QueueArea from 'features/lab-order/component/QueueArea'
import { swalCustom, swalDelete, swalWarning } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import ReceiveLab from 'features/lab-order/component/ReceiveLab'
import { toBuddhistYear } from 'utils/app.utils'
import { useHistory } from 'react-router-dom'

const BoxConfig = styled('div')(({ theme }) => ({
    height: 'calc(100% - 64px)',
    '& .row-queue': { height: 'calc(100%)' },
    [theme.breakpoints.down('sm')]: {
        '& .row-queue': {
            minWidth: 620
        }
    }
}))

export interface ILabOrderQueueProps {
    date?: string
    status?: string | 'ORDER_LAB' | 'SEND_LAB' | 'RECEIVE_LAB' | 'RECEIVE_BILL' | 'REPLY_LAB' | 'CANCEL_LAB' | 'CLOSE_LAB'
    valReload: any
    reload: () => void
    branch: string
    reloadQueue?: (va: boolean) => void
}

export default function LabOrderQueue(props: ILabOrderQueueProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [popupReceive, setPopupReceive] = useState<boolean>(false)
    const [labOrderId, setLabOrderId] = useState<number>(0)
    const [dateReceive, setDateReceive] = useState()
    const [queue, setQueue] = useState({ exportLabs: [], receiveLab: [] })

    useEffect(() => { loadQueue() }, [])
    const loadQueue = () => {
        LabOrderApi.queue().then(({ data }) => {
            setQueue(data)
        })
        props.reload()
    }

    useEffect(() => {
        props.valReload && loadQueue()
    }, [props.valReload])

    const onSend = (objData: any) => {
        const { labOrderId: id, labOrderNumber, labOrderStatus } = objData
        if (labOrderStatus === 'ORDER_LAB')
            swalCustom(
                `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LAB_ORDER.MESSAGE.SEND_LISTLAB')}<br /> ${t('LAB_ORDER.MESSAGE.YESNO')}</p>`,
                `<p class="text-center mb-0 mx-auto" style="max-width: 306px">${t(`LAB_ORDER.MESSAGE.SEND_LISTLAB_DETAIL`)} ${t(`LAB_ORDER.MESSAGE.NO_LAB`)} ${labOrderNumber} </p>`,
                (res: any) => {
                    if (res) {
                        LabOrderApi.send(id)
                            .then((resp) => {
                                loadQueue()
                                notiSuccess(t('LAB_ORDER.MESSAGE.SUCCESS.SEND'))
                            })
                            .catch((e) => (notiError(t('LAB_ORDER.MESSAGE.ERROR'))))
                    }
                },
                'warning',
                {
                    confirmButton: {
                        confirmButtonText: t('LAB_ORDER.BUTTON.SEND_LAB')
                    },
                    cancelButton: {
                        cancelButtonText: t('BUTTON.CANCEL')
                    }
                }
            )
        else swalWarning(t('LAB_ORDER.ALERT.CAN_NOT_SEND'), t('LAB_ORDER.ALERT.CAN_NOT_SEND_DETAIL'), () => false)
    }

    const onReceive = (objData: any) => {
        const { labOrderId: id, dateReceive: dateRe, labOrderStatus: status } = objData
        if (status === 'RECEIVE_BILL') {
            notiError(t('LAB_ORDER.MESSAGE.ERROR.RECEIVE_BILL'))
            return false
        }
        setLabOrderId(id)
        setDateReceive(dateRe)

        dispatch(showModal())
        setPopupReceive(true)
    }
    const onReset = () => {
        setLabOrderId(0)
        setDateReceive(undefined)
        setPopupReceive(false)
        loadQueue()
    }

    return (
        <>
            <div className="p-sm-3 py-3 text-center">
                <h5 style={{ fontWeight: 500, color: colors.themeMainColor }}>{t('LAB_ORDER.LISYLAB_DATE')} {toBuddhistYear(moment(), `D MMMM YYYY`)}</h5>
            </div>
            <BoxConfig className='overflow-auto custom-scroll'>
                <Row className="mx-0 row-queue" >
                    <Col xs={6} sm={6} className="px-1 h-100">
                        <QueueArea
                            count={queue.exportLabs.length}
                            headerName={t('LAB_ORDER.QUEUE.SEND_LABS')}
                            body={_.map(queue.exportLabs, (ex: any, index: number) => (
                                <CardQueue
                                    type={'EXPORT'}
                                    dataCard={ex}
                                    indexNumber={index}
                                    onClick={() => onSend(ex)}
                                    branch={props.branch}
                                />
                            ))} />
                    </Col>
                    <Col xs={6} sm={6} className="px-1 h-100" >
                        <QueueArea
                            count={queue.receiveLab.length}
                            headerName={t('LAB_ORDER.QUEUE.RECEIVE_LABS')}
                            body={_.map(queue.receiveLab, (re: any, index: number) => (
                                <CardQueue
                                    type={'RECEIVE'}
                                    dataCard={re}
                                    indexNumber={index}
                                    onClick={() => onReceive(re)}
                                    branch={props.branch}
                                />
                            ))} />
                    </Col>
                </Row>
            </BoxConfig>
            <ReceiveLab isShow={popupReceive} id={labOrderId} dateReceive={dateReceive} onSuccess={loadQueue} onReset={onReset} />
        </>
    )
}
