import IconProps from 'assets/pick/iconProps'
import { colors } from "constants/theme"

export default function OtherService(props: IconProps) {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="85" height="70" viewBox="0 0 85 70">
            <g data-name="Group 5706" style={{ opacity: .997 }}>
                <path data-name="Rectangle 4342" style={{ fill: 'none' }} d="M0 0h85v70H0z" />
                <g data-name="Group 11685">
                    <g data-name="Group 5691">
                        <path data-name="Path 5692" d="M276.633 181.646a29.538 29.538 0 0 0 5.148-17.153c-.156-18.253-17.24-19.108-22.118-13.819-.831.9-2.31 1.266-3.011.261-3.451-4.952-21.809-3.962-21.655 13.958a29.522 29.522 0 0 0 5.442 17.055 1.95 1.95 0 0 1 .3 1.608c-1.226 4.981-3.694 12.48 3.389 12.42 7.578-.065 6.459-8.479 14.452-8.546 7.423-.064 7.816 8.118 14.114 8.064 5.906-.05 4.744-7.41 3.628-12.289a1.954 1.954 0 0 1 .311-1.559z" transform="translate(-231.994 -135.924)" style={{ strokeWidth: '3.2px', strokeMiterlimit: 10, stroke: props.color ? props.color : colors.disabledGray, fill: 'none' }} />
                        <g data-name="Icon feather-smile">
                            <path data-name="Path 5644" d="M12 21a9.736 9.736 0 0 0 7.212 3.606A9.736 9.736 0 0 0 26.425 21" transform="translate(7.514 13.176)" style={{ strokeWidth: '2.3px', strokeLinecap: 'round', strokeLinejoin: 'round', stroke: props.color ? props.color : colors.disabledGray, fill: 'none' }} />
                            <path data-name="Path 5645" d="M13.5 13.5h.018" transform="translate(7.817 14.179)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '3.3px', stroke: props.color ? props.color : colors.disabledGray, fill: 'none' }} />
                            <path data-name="Path 5646" d="M22.5 13.5h.018" transform="translate(9.636 14.179)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '3.3px', stroke: props.color ? props.color : colors.disabledGray, fill: 'none' }} />
                        </g>
                    </g>
                    <g data-name="Group 5700">
                        <g data-name="Group 5699">
                            <path data-name="Path 5766" d="M33.456 7.846A14.117 14.117 0 0 0 8.165 20.394q.311.626.682 1.219L6.857 24.1a.747.747 0 0 0-.156.564.739.739 0 0 0 .3.5l1.615 1.175L.166 36.74a.745.745 0 0 0 .11 1.048L4 40.766a.745.745 0 0 0 1.042-.112l8.638-10.631 1.5 1.094a.744.744 0 0 0 1.02-.137l2.321-2.9a13.972 13.972 0 0 0 14.937-7.628 13.071 13.071 0 0 0 1.431-6.26 13.021 13.021 0 0 0-1.433-6.346zM4.351 39.143 1.793 37.1l8.028-9.88 2.652 1.929zM15.483 29.5l-1.5-1.091-.085-.062-5.393-3.926 1.231-1.538a14.157 14.157 0 0 0 7.164 4.835zm5.344-2.7a12.645 12.645 0 1 1 12.645-12.649A12.645 12.645 0 0 1 20.827 26.8z" style={{ fill: props.color ? props.color : colors.disabledGray, strokeWidth: '.7px', stroke: props.color ? props.color : colors.disabledGray }} transform="rotate(3 -167.656 894.983)" />
                        </g>
                    </g>
                    <g data-name="Ellipse 355" transform="translate(56.401 14.734)" style={{ strokeWidth: '2.5px', stroke: props.color ? props.color : colors.disabledGray, fill: 'none' }}>
                        <circle cx="10.489" cy="10.489" r="10.489" style={{ stroke: 'none' }} />
                        <circle cx="10.489" cy="10.489" r="9.239" style={{ fill: 'none' }} />
                    </g>
                    <g data-name="Group 10292">
                        <g data-name="Ellipse 477" transform="rotate(14.98 -54.853 246.351)" style={{ fill: '#fff', strokeWidth: '1.5px', stroke: props.color ? props.color : colors.disabledGray }}>
                            <circle cx="1.39" cy="1.39" r="1.39" style={{ stroke: 'none' }} />
                            <circle cx="1.39" cy="1.39" r=".64" style={{ fill: 'none' }} />
                        </g>
                        <g data-name="Ellipse 478" transform="rotate(14.98 -56.946 262.183)" style={{ fill: '#fff', strokeWidth: '1.5px', stroke: props.color ? props.color : colors.disabledGray }}>
                            <circle cx="1.39" cy="1.39" r="1.39" style={{ stroke: 'none' }} />
                            <circle cx="1.39" cy="1.39" r=".64" style={{ fill: 'none' }} />
                        </g>
                        <g data-name="Ellipse 479" transform="rotate(14.98 -59.038 278.01)" style={{ fill: '#fff', strokeWidth: '1.5px', stroke: props.color ? props.color : colors.disabledGray }}>
                            <circle cx="1.39" cy="1.39" r="1.39" style={{ stroke: 'none' }} />
                            <circle cx="1.39" cy="1.39" r=".64" style={{ fill: 'none' }} />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
