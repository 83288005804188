import { menuSeconds } from 'constants/menus'
import { routeName } from 'routes/routes-name'
import Promotions from 'features/application/promotions/promotions'
import FormPromotion from 'features/application/promotions/form-promotion'
import ManageWeb from 'features/application/manage-web/ManageWeb'

const routePromotions = [
  {
    ...menuSeconds.PROMOTIONS,
    key: menuSeconds.PROMOTIONS.key,
    name: menuSeconds.PROMOTIONS.name,
    textName: menuSeconds.PROMOTIONS.textName,
    active: false,
    appBar: true,
    component: Promotions
  },
  {
    ...menuSeconds.PROMOTIONS,
    packageKey: menuSeconds.PROMOTIONS.packageKey,
    path: routeName.promotions + '/create',
    component: FormPromotion,
    appBar: true
  },
  {
    ...menuSeconds.PROMOTIONS,
    packageKey: menuSeconds.PROMOTIONS.packageKey,
    path: routeName.promotions + '/edit',
    component: FormPromotion,
    appBar: true
  }
]

export default routePromotions
