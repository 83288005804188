import { useCallback, useEffect, useState } from 'react'
import { ExportProps } from 'features/report/report-constants'
import moment from 'moment'
import _ from 'lodash'
import { Box, Typography, Divider } from '@mui/material'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import styled from 'styled-components'
import { height, width } from 'pdfkit/js/page'
import { UseSnCode } from 'utils/useSnCode'
const CustomTableCell = styled.td`
  max-width: 100px;
  word-wrap: break-word;
  white-space: nowrap;
`

interface FcComponent {
  selectHeadCells: any
  exportFilter: any
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  componentRef: any
}

export default function SchedulesList(props: FcComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  function checkNumber(text: any) {
    if (!text) return false
    const pattern = /^([a-z0-9])+$/i
    return text.trim().match(pattern)
  }
  function checkNull(text: any) {
    if (!text) return '-'
    return text.trim()
  }

  const renderSchedulesTable = (data: any) => {
    const items = !_.isEmpty(data)
      ? _.chain(data)
          .groupBy('doctorId')
          .map((value: any, key: string) => ({ doctorName: value[0]?.doctorFullName || '-', data: value }))
          .value()
      : []

    return items
  }
  const statusColor: any = {
    NORMAL: { color: colors.appointmentStatus.statusNormal },
    CANCEL: { color: colors.appointmentStatus.statusCancel },
    CAN_NOT_CONTACT: { color: colors.appointmentStatus.statusUnConnect },
    PUT_OFF: { color: colors.appointmentStatus.statusChange },
    CONFIRM: { color: colors.appointmentStatus.statusEnter },
    ARRIVED: { color: colors.appointmentStatus.statusArrived },
    NOT_YET: { color: colors.appointmentStatus.statusUnArrived },
    PAYMENT: { color: colors.appointmentStatus.statusWaitPayment },
    PENDING_PAYMENT: { color: colors.appointmentStatus.statusPayment },
    PENDING_SERVICE: { color: colors.appointmentStatus.statusCounterCheckIn },
    SERVICE: { color: colors.appointmentStatus.statusService },
    DONE: { color: colors.appointmentStatus.statusDone }
  }
  const headCells = [
    { id: '0', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.TIME', width: '80px', align: 'center', class: 'text-nowrap' },
    { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.RANG_TIME', width: 'auto', align: 'center', class: 'text-nowrap' },
    { id: '2', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.CN', width: 'auto', align: 'center', class: 'text-nowrap' },
    { id: '3', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PT', width: 'auto', align: 'center', class: 'text-nowrap' },
    { id: '4', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.SN', width: 'auto', align: 'center', class: 'text-nowrap' },
    { id: '5', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PATIENT_NAME', width: 'auto', align: 'left', class: 'text-nowrap' },
    { id: '6', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PHONE', width: 'auto', align: 'left', class: 'text-nowrap' },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.OPERATIVE_TYPE', width: 'auto', align: 'left', class: 'text-nowrap' },
    { id: '8', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.OPERATIVE', width: '30%', align: 'center', class: 'text-nowrap' },
    { id: '9', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.STATUS', width: 'auto', align: 'left', class: 'text-nowrap' },
    { id: '10', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PRICE', width: 'auto', align: 'left', class: 'text-nowrap' },
    { id: '11', disablePadding: false, labelKey: 'Lab', align: 'left', width: 'auto', class: 'text-nowrap' },
    { id: '12', disablePadding: false, labelKey: 'X-Ray', align: 'left', width: '80px', class: 'text-nowrap' },
    { id: '13', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.SPACIAL', width: 'auto', align: 'left', class: 'text-nowrap' },
    { id: '14', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PRACTICE', width: 'auto', align: 'left', class: 'text-nowrap' },
    { id: '15', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.REMARK', width: 'auto', align: 'left', class: 'text-nowrap' },
    { id: '16', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.CREATE_BY', width: 'auto', align: 'center', class: 'text-nowrap' },
    { id: '17', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.UPDATE_BY', width: 'auto', align: 'center', class: 'text-nowrap' }
  ]

  if (!snType) {
    headCells.splice(4, 1)
  }
  const filteredHeadCells = props.selectHeadCells ? headCells.filter((cell) => props.selectHeadCells.includes(cell.id)) : headCells

  const renderData = (data: any, no: number, exportData: boolean) => {
    const { timeStart, durationMinute, patientFullName, phone, operativeTypeName, operativeName, statusAppointment, price, practice, remark, lab, xray, cnNumber, branchCnNumber, snCode, specialInfo, createdBy, createdAt, updatedBy, updatedAt } = data
    const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1

    const objRenderData = {
      key: num.toString(),
      id: no.toString(),
      obj: data,
      columns: filteredHeadCells.map((headCell, index) => {
        switch (headCell.id) {
          case '0':
            return { option: 'TEXT', align: 'center', label: `${moment(timeStart, 'HH:mm').format('HH:mm')} ${t('TIME_UNIT')}`, class: 'text-nowrap' }
          case '1':
            return { option: 'TEXT', align: 'center', label: `${durationMinute} ${t('TIME_DURATION_TYPE.MINUTES')}`, class: 'text-nowrap' }
          case '2':
            return { option: 'TEXT', align: 'left', label: cnNumber || '-' }
          case '3':
            return { option: 'TEXT', align: 'left', label: branchCnNumber || '-' }
          case '4':
            return { option: 'TEXT', align: 'left', label: snCode || '-' }
          case '5':
            return { option: 'TEXT', align: 'left', label: patientFullName || '-', class: 'text-ellipsis' }
          case '6':
            return { option: 'TEXT', align: 'left', format: checkNumber(phone) ? 'TEXT' : undefined, label: checkNull(phone), class: 'text-nowrap' }
          case '7':
            return { option: 'TEXT', align: 'left', label: operativeTypeName || '-', class: 'text-ellipsis' }
          case '8':
            return { option: 'TEXT', align: 'left', label: operativeName, class: 'text-ellipsis' }
          case '9':
            return { option: 'TEXT', align: 'left', label: statusAppointment ? t(`APPOINTMENT.STATUS.${statusAppointment}`) : '-', class: 'text-ellipsis', style: statusColor[statusAppointment] || {} }
          case '10':
            return { option: 'TEXT', align: 'right', label: numberFormat(price || 0), class: 'text-ellipsis' }
          case '11':
            return { option: 'TEXT', align: 'center', label: t(`${lab}`), class: 'text-ellipsis' }
          case '12':
            return { option: 'TEXT', align: 'center', label: t(`${xray}`), class: 'text-ellipsis' }
          case '13':
            return { option: 'TEXT', align: 'left', label: specialInfo || '-', class: 'text-ellipsis' }
          case '14':
            return { option: 'TEXT', align: 'left', label: practice || '-', class: 'text-ellipsis' }
          case '15':
            return { option: 'TEXT', align: 'left', label: remark || '-', class: 'text-ellipsis' }
          case '16':
            return { option: 'TEXT', align: 'left', label: createdBy || '-', class: 'text-ellipsis' }
          case '17':
            return { option: 'TEXT', align: 'left', label: updatedBy || '-', class: 'text-ellipsis' }
          default:
            return {}
        }
      })
    }
    return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
  }

  const renderDataExport = (data: any, no: number, exportData: boolean) => {
    const { timeStart, durationMinute, patientFullName, phone, operativeTypeName, operativeName, statusAppointment, price, practice, remark, lab, xray, cnNumber, branchCnNumber, snCode, specialInfo, createdBy, createdAt, updatedBy, updatedAt } = data
    const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1

    const objRenderData = {
      key: num.toString(),
      id: no.toString(),
      obj: data,
      columns: filteredHeadCells.map((headCell, index) => {
        switch (headCell.id) {
          case '0':
            return { option: 'TEXT', align: 'center', label: `${moment(timeStart, 'HH:mm').format('HH:mm')} ${t('TIME_UNIT')}`, class: 'text-nowrap', style: { fontSize: '10px' } }
          case '1':
            return { option: 'TEXT', align: 'center', label: `${durationMinute} ${t('TIME_DURATION_TYPE.MINUTES')}`, class: 'text-nowrap', style: { fontSize: '10px' } }
          case '2':
            return { option: 'TEXT', align: 'left', label: cnNumber || '-', style: { fontSize: '10px' } }
          case '3':
            return { option: 'TEXT', align: 'left', label: branchCnNumber || '-', style: { fontSize: '10px' } }
          case '4':
            return { option: 'TEXT', align: 'left', label: snCode || '-', style: { fontSize: '10px' } }
          case '5':
            return { option: 'TEXT', align: 'left', label: patientFullName || '-', style: { overFlowWrap: 'break-word', fontSize: '10px' } }
          case '6':
            return { option: 'TEXT', align: 'left', format: checkNumber(phone) ? 'TEXT' : undefined, label: checkNull(phone), class: 'text-nowrap' }
          case '7':
            return { option: 'TEXT', align: 'left', label: operativeTypeName || '-', style: { overFlowWrap: 'break-word', fontSize: '10px' } }
          case '8':
            return { option: 'TEXT', align: 'left', label: operativeName, style: { overFlowWrap: 'break-word', fontSize: '10px' } }
          case '9':
            return { option: 'TEXT', align: 'left', label: statusAppointment ? t(`APPOINTMENT.STATUS.${statusAppointment}`) : '-', style: { fontSize: '10px' }, class: 'text-nowrap' }
          case '10':
            return { option: 'TEXT', align: 'right', label: numberFormat(price || 0), style: { fontSize: '10px' } }
          case '11':
            return { option: 'TEXT', align: 'center', label: t(`${lab}`), style: { fontSize: '10px' } }
          case '12':
            return { option: 'TEXT', align: 'center', label: t(`${xray}`), style: { fontSize: '10px' } }
          case '13':
            return { option: 'TEXT', align: 'left', label: specialInfo || '-', style: { fontSize: '10px' } }
          case '14':
            return { option: 'TEXT', align: 'left', label: practice || '-', style: { fontSize: '10px' } }
          case '15':
            return { option: 'TEXT', align: 'left', label: remark || '-', style: { fontSize: '10px' } }
          case '16':
            return { option: 'TEXT', align: 'left', label: createdBy || '-', style: { fontSize: '10px' } }
          case '17':
            return { option: 'TEXT', align: 'left', label: updatedBy || '-', style: { fontSize: '10px' } }
          default:
            return {}
        }
      })
    }
    return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
  }
  return (
    <div>
      {(!_.isEmpty(props.data) &&
        _.map(props.data, (val: any, index: number) => {
          return (
            <>
              {index > 0 && <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}
              <Typography className={`mb-1`} sx={{ fontWeight: 500, fontSize: '20px' }}>
                <span className="mr-2">{t('MOMENT_AT')}</span>
                {toBuddhistYear(moment(val.date), 'DD/MM/YYYY')}
              </Typography>
              {_.map(renderSchedulesTable(val.obj), (obj, indexObj: number) => {
                return (
                  <Box key={indexObj}>
                    <Typography className={`${indexObj > 0 ? 'mt-3' : ''} mb-1`} sx={{ fontWeight: 500 }}>
                      <span className="pr-2">{t('REPORT.FILTER.DOCTOR')}</span>
                      {obj.doctorName}
                    </Typography>
                    <UseStyled.ResponsiveReportTable className="mt-2" verticalAlign="top">
                      <TableCustom
                        customScroll
                        hidePagination
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={filteredHeadCells}
                        rowsData={_.map(obj.data, (d: any, indexData: number) => {
                          return renderData(d, indexData, false)
                        })}
                      />
                    </UseStyled.ResponsiveReportTable>
                  </Box>
                )
              })}
            </>
          )
        })) || <EmptyData className="my-4" />}
      <div className="print-show" ref={props.componentRef}>
        <SchedulesExport
          header={props.exportFilter.header}
          componant={_.map(props.data, (val: any, index: number) => {
            return (
              <>
                {index > 0 && <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}
                <Typography className={`mb-1 mt-2`} sx={{ fontWeight: 500, fontSize: '20px' }}>
                  <span className="mr-2">{t('MOMENT_AT')}</span>
                  {toBuddhistYear(moment(val.date), 'DD/MM/YYYY')}
                </Typography>
                {_.map(renderSchedulesTable(val.obj), (obj, indexObj: number) => {
                  return (
                    <>
                      <Typography className={`${indexObj > 0 ? 'mt-3' : ''} mb-1`} sx={{ fontWeight: 500 }}>
                        <span className="pr-2">{t('REPORT.FILTER.DOCTOR')}</span>
                        {obj.doctorName}
                      </Typography>
                      <UseStyled.Styles className="pt-3">
                        <table style={{ fontSize: '10px', tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                          <thead>
                            <tr>
                              {_.map(filteredHeadCells, (valCell: any) => {
                                return <td style={{ textAlign: valCell.align, fontSize: 10 }}> {t(valCell.labelKey)}</td>
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(obj.data, (d: any, indexData: number) => {
                              return renderDataExport(d, indexData, false)
                            })}
                            {!obj.data.length && <td colSpan={headCells.length}>{t('REPORT.TITLE.NO_DATA')}</td>}
                          </tbody>
                        </table>
                      </UseStyled.Styles>
                    </>
                  )
                })}
              </>
            )
          })}
        />
      </div>
    </div>
  )
}
export function SchedulesExport(props: ExportProps) {
  return (
    <>
      {props.header}
      {props.componant}
    </>
  )
}
