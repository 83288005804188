import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'

const LoadingState = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  background: colors.backdrop,
  backdropFilter: 'blur(1px) saturate(1)',
  zIndex: 2500,
}))

const SoftProgress = styled('div')<{ width?: string, height?: string, }>(({ theme, width, height }) => ({
  width: width || `24px`,
  height: height || `24px`,
}))

type LoadingStateProps = {
  show: boolean
  type?: 'fullLoading' | 'softLoading'
  width?: string
  height?: string
  className?: string
}

export default function Loading(props: LoadingStateProps) {
  return (
    props.show ? (
      props.type === 'softLoading' && (
        <SoftProgress className={`${props.className || ''} loading-spinner loading-spinner-small`} width={props.width} height={props.height}></SoftProgress>
      ) ||
      <LoadingState className={'d-flex align-items-center justify-content-center'}>
        <div className='loading-spinner'></div>
      </LoadingState>
    ) : <></>
  )
}
