import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { Typography, Box } from '@mui/material'
import { Modal } from 'react-bootstrap';

export const ModalStyle = styled(Modal)(({ theme }) => ({
  '.modal-content': {
    maxWidth: 1646,
    margin: 'auto',
    borderRadius: 10,
    backdropFilter: 'saturate(1) blur(40px)',
    backgroundColor: colors.white90
  },
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: colors.textPrimary,
  [theme.breakpoints.up('sm')]: {
    fontSize: 18,
  }
}))

export const TitlePayment = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  padding: '4px 10px 4px 10px',
  backgroundColor: colors.themeSecondColor10,
  color: colors.textPrimary,
  [theme.breakpoints.up('sm')]: {
    fontSize: 18,
  }
}))

export const CustomField = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  marginLeft: 'auto',
  '.MuiButtonBase-root': {
    backgroundColor: 'transparent !important',
    transition: 'transform .15s ease',
    '&.icon-expanded.disabled': { opacity: 0.5 },
    '&.isExpanded': {
      transform: 'rotate(-180deg)'
    }
  },
  '.MuiTextField-root': {
    width: 160,
    [theme.breakpoints.down('xs')]: {
      width: 95,
    }
  }
}))

export const SummaryNetPrice = styled(Box)(({ theme }) => ({
  backgroundColor: colors.white,
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 4,
  '> div': {
    width: '50%',
    minHeight: 96,
  },
  [theme.breakpoints.down('sm')]: {
    '> div': {
      minHeight: 67,
    }
  }
}))

export const FooterStepButton = styled(Box)(({ theme }) => ({
  '> div': {
    margin: '0 !important',
    width: '100%',
    '&:first-of-type': {
      marginRight: '4px !important'
    },
    '&:last-of-type': {
      marginLeft: '4px !important'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 72,
      '&:first-of-type': {
        marginRight: '8px !important'
      },
      '&:last-of-type': {
        marginLeft: '8px !important'
      },
    }
  },
  '.MuiButton-root': {
    width: '100%',
    height: 44,
    fontSize: 16,
    '&.MuiButton-outlined': {
      backgroundColor: colors.white
    }
  }
}))

export const FooterPaymentButton = styled(Box)(({ theme }) => ({
  '> div': {
    margin: '0 !important',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center'
    }
  },
  '.MuiButton-root': {
    width: '100%',
    height: 44,
    fontSize: 16,
    '&.MuiButton-outlined': {
      backgroundColor: colors.white
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 544,
      margin: 'auto'
    }
  }
}))
