import doctorSchedule from 'features/counter/doctor-schedule/doctor-schedule'
import { menuSeconds } from 'constants/menus'

const routeDoctorSchedule = [
  /** <-- ห้ามลบเด็ดขาด */
  {
    ...menuSeconds.DOCTOR_SCHEDULE,
    component: doctorSchedule,
    appBar: true
  }
]

export default routeDoctorSchedule
