import { Box } from '@mui/material'
import ApprovalApi from 'api/approval/approval.api'
import { swalActive, swalCustom } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import Loading from 'component/Loading'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

const Approval = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [load, setLoad] = useState<boolean>(false)

  const [dataApproval, setDataApproval] = useState<any>([])

  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('approvalId')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')

  const loadApproval = async () => {
    setLoad(true)
    const condition = {
      page: page,
      pageLimit: pageLimit,
      search: search,
      sortBy: sortBy,
      sortType: sortType
    }

    await ApprovalApi.findApprovalAll(condition).then((res) => {
      setRowCount(res?.headers['x-total'] || 0)
      setDataApproval(res?.data)
    }).finally(() => {
      setLoad(false)
    })
  }

  useEffect(() => {
    loadApproval()
  }, [])

  const onCreate = () => {
    history.push(routeName.approval + '/create')
  }

  const onEdit = (obj: any) => {
    history.push(routeName.approval + '/update', obj)
  }

  const onActive = async (id: number, valueStatus: "ACTIVE" | "INACTIVE", data: any) => {
    const renderSwal = () => {
      swalActive(
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('OPERATIVE.ALERT.CONFIRM_STATUS')}</p>`,
        null, (res: any) => {
          if (res) {
            ApprovalApi.updateStatusApproval(id, valueStatus)
              .then((resp) => {
                notiSuccess(t('OPERATIVE.MESSAGE.SUCCESS.UPDATE_STATUS'))
                loadApproval()
              })
              .catch((e) => {
                notiError(t('APPROVAL.MESSAGE.ERROR.ERROR'))
              })
          }
        }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
    }

    if (valueStatus === 'INACTIVE') {
      await handleCheckInActiveAndDel(data, renderSwal, valueStatus)
    } else {
      renderSwal()
    }
  }

  const onDelete = async (data: any) => {
    const { approvalId, approvalName, status } = data
    const renderSwal = () => {
      swalActive(
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('APPROVAL.MESSAGE.CONFIRM_DELETE')}</p>`,
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('APPROVAL.MESSAGE.CONFIRM_DELETE_', {
          approvalName: approvalName
        })}</p>`,
        (res: any) => {
          if (res) {
            ApprovalApi.deteleApproval(approvalId)
              .then((resp) => {
                notiSuccess(t('APPROVAL.MESSAGE.SUC_DELETE'))
                loadApproval()
              })
              .catch((e) => {
                notiError(t('APPROVAL.MESSAGE.ERROR.ERROR'))
              })
          }
        }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
      )
    }

    if (status === 'INACTIVE') {
      renderSwal()
    } else {
      await handleCheckInActiveAndDel(data, renderSwal, 'DEL')
    }
  }

  const handleCheckInActiveAndDel = async (data: any, func: () => void, status: 'DEL' | 'INACTIVE') => {
    const { hasChangeCost, hasCancelReciept } = data
    const checkApproval = await ApprovalApi.checkApprovePermiss()

    const textStatus = t(`APPROVELLIST.MESSAGE.CHECK_TITLE_${status}`)

    const checkHasChangeCost = hasChangeCost === "UNPERMISS" ? true : checkApproval?.data?.hasChangeCostItems <= 0 || checkApproval?.data?.hasChangeCost > 1
    const checkHasCancelReciept = hasCancelReciept === "UNPERMISS" ? true : checkApproval?.data?.hasCancelRecieptItems <= 0 || checkApproval?.data?.hasCancelReciept > 1

    if (checkHasChangeCost && checkHasCancelReciept) {
      func()
    } else {
      const newTextCheck = []
      if (!checkHasChangeCost) {
        newTextCheck.push(t('APPROVELLIST.TYPE.CHANGE_COST'))
      }
      if (!checkHasCancelReciept) {
        newTextCheck.push(t('APPROVELLIST.TYPE.CANCEL_RECEIPT'))
      }
      swalCustom(
        textStatus,
        t('APPROVELLIST.MESSAGE.CHECK_SUB',
          { textCheck: newTextCheck.join(", ") }),
        () => console.log(),
        'warning',
        { cancelButton: { showCancelButton: false } }
      )
    }
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => setPage(val)

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '50px', align: 'center', },
    { id: 'approvalName', disablePadding: false, label: t('APPROVAL.TABLE.APPROVAL_NAME'), align: 'left', },
    { id: 'status', disablePadding: false, label: t('APPLICATION.TABLE.CELL.STATUS'), width: '150px' },
    { id: 'updatedBy', disablePadding: false, label: t('APPLICATION.TABLE.CELL.UPDATED_BY'), width: '180px' },
    { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
  ]

  const renderData = (objData: any, index: number) => {
    index = page * pageLimit - pageLimit + index + 1
    const { approvalId, approvalName, updatedBy, updatedAt, createdAt, status } = objData

    const objRenderData = {
      key: approvalId,
      id: approvalId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: index },
        { option: 'TEXT', align: 'left', label: approvalName || '' },
        { option: 'STATUS', align: 'center', label: status },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt ?? '10/12/2564 08.59' } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: status === 'ACTIVE' },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: status === 'INACTIVE' },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        },
      ]
    }

    return (
      <TableRowCommon
        {...objRenderData}
        onedit={() => onEdit(objData)}
        ondelete={() => onDelete(objData)}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE', objData)}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE', objData)}
      />
    )
  }

  return (
    <>
      <Box>
        <Loading show={load} />
        <Card className="border-0 h-100">
          <HeaderCard text={t('APPROVAL.TITLE')} />
          <Card.Body className="overflow-auto">
            <Card.Title className={'mb-0 pl-xl-3'}>
              <Row className="align-items-center">
                <Col xs={9}>
                  <Row className="flax-row">
                  </Row>
                </Col>
                <Col xl={3} className="mt-2 mt-xl-auto">
                  <div className="pr-xl-2">
                    <ButtonCustom
                      mode="add"
                      disabled={permissions.isCreate.disabled}
                      onClick={() => onCreate()}
                      textButton={t('APPROVAL.BUTTON.CREATE')}
                      className="w-auto ml-auto d-flex mt-auto"
                    />
                  </div>
                </Col>
              </Row>
            </Card.Title>

            <Box className={'mt-3 pl-xl-3 pr-xl-2'}>
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                rowCount={rowCount}
                headCells={headCells}
                rowsData={dataApproval?.length ? dataApproval?.map((item: any, index: number) => {
                  return renderData(item, index)
                }) : []}
              />
            </Box>
          </Card.Body>
        </Card>
      </Box>

    </>

  )
}

export default Approval