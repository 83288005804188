import { colors } from "constants/theme"
import IconProps from "./icon-props"

export default function Appointment(props: IconProps) {
  return (
    <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 21.633V19.233C1.02419 17.935 1.56252 16.6996 2.4968 15.7981C3.43108 14.8966 4.68492 14.4028 5.983 14.425H15.95C17.2476 14.4028 18.501 14.8962 19.4352 15.797C20.3693 16.6978 20.908 17.9325 20.933 19.23V21.63C20.933 22.234 1 21.633 1 21.633Z" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} stroke={props.color || colors.themeSecondColor} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.0429 6.207C16.0429 7.23689 15.7375 8.24365 15.1653 9.09996C14.5931 9.95627 13.7798 10.6237 12.8283 11.0177C11.8768 11.4118 10.8298 11.5149 9.81971 11.3139C8.80962 11.1129 7.88182 10.6169 7.15365 9.88855C6.42548 9.16024 5.92964 8.23235 5.72884 7.22222C5.52804 6.2121 5.6313 5.16511 6.02555 4.21367C6.41981 3.26224 7.08736 2.44908 7.94378 1.87705C8.8002 1.30502 9.80702 0.999802 10.8369 1C12.2177 1.00027 13.5419 1.54898 14.5182 2.52545C15.4945 3.50192 16.0429 4.82619 16.0429 6.207Z" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} stroke={props.color || colors.themeSecondColor} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="23.5" cy="6.49991" r="3.5" fill={props.active ? props.color || colors.themeSecondColor : colors.transparent} />
      <path fillRule="evenodd" clipRule="evenodd" d="M25.274 6.986C25.3355 6.98311 25.3969 6.99273 25.4545 7.01427C25.5122 7.03581 25.5649 7.06882 25.6094 7.11131C25.6539 7.1538 25.6894 7.20488 25.7136 7.26146C25.7378 7.31804 25.7503 7.37895 25.7503 7.4405C25.7503 7.50204 25.7378 7.56295 25.7136 7.61953C25.6894 7.67612 25.6539 7.7272 25.6094 7.76968C25.5649 7.81217 25.5122 7.84518 25.4545 7.86672C25.3969 7.88826 25.3355 7.89788 25.274 7.895H23.91C23.6691 7.89421 23.4384 7.79818 23.2681 7.62788C23.0978 7.45758 23.0018 7.22684 23.001 6.986V5.167C23.0065 5.05014 23.0567 4.93989 23.1414 4.85914C23.226 4.77838 23.3385 4.73332 23.4555 4.73332C23.5725 4.73332 23.6849 4.77838 23.7696 4.85914C23.8542 4.93989 23.9045 5.05014 23.91 5.167V6.986H25.274Z" fill={props.active ? colors.white : props.color || colors.themeSecondColor} strokeWidth="0.8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.629 11.171C22.7135 11.171 21.8185 10.8995 21.0573 10.3909C20.296 9.88223 19.7027 9.15928 19.3524 8.31344C19.002 7.4676 18.9103 6.53686 19.0889 5.63892C19.2676 4.74099 19.7084 3.91618 20.3558 3.2688C21.0032 2.62142 21.828 2.18055 22.7259 2.00194C23.6239 1.82333 24.5546 1.915 25.4004 2.26536C26.2463 2.61572 26.9692 3.20903 27.4779 3.97026C27.9865 4.7315 28.258 5.62647 28.258 6.542C28.258 7.76968 27.7703 8.94709 26.9022 9.81519C26.0341 10.6833 24.8567 11.171 23.629 11.171ZM27.329 6.542C27.329 5.81021 27.112 5.09485 26.7054 4.48639C26.2989 3.87792 25.721 3.40369 25.0449 3.12364C24.3688 2.8436 23.6249 2.77033 22.9072 2.91309C22.1894 3.05586 21.5302 3.40825 21.0127 3.9257C20.4953 4.44316 20.1429 5.10243 20.0001 5.82016C19.8573 6.53789 19.9306 7.28184 20.2106 7.95792C20.4907 8.63401 20.9649 9.21187 21.5734 9.61843C22.1819 10.025 22.8972 10.242 23.629 10.242C24.1151 10.2424 24.5966 10.147 25.0459 9.96121C25.4951 9.77545 25.9033 9.50297 26.2472 9.15935C26.5911 8.81574 26.8639 8.40772 27.0501 7.95862C27.2362 7.50952 27.332 7.02814 27.332 6.542H27.329Z" fill={props.color || colors.themeSecondColor} stroke={props.color || colors.themeSecondColor} strokeWidth="0.8" />
    </svg>
  )
}
