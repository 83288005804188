import { useRef, useEffect } from 'react'
import { Button } from '@mui/material'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { toBuddhistYear } from 'utils/app.utils'
import EventIcon from '@mui/icons-material/Event';
import ClearIcon from '@mui/icons-material/Clear'
import _ from 'lodash'
import moment from 'moment'
import useCalendarFormat, { thai_format_language, thai_calendar } from 'constants/configDatePicker'
import InputTextField from 'component/Input/InputTextField'
import useWindowSize from 'utils/useWindowSize'
import { CustomDatePicker, CustomBoxDatePicker } from 'component/Input/InputSecondNewDatePicker';
import { useTranslation } from 'react-i18next'

const CustomRangeDatePicker = styled(CustomDatePicker)(({ theme }) => ({
    '& + div': {
        '.rmdp-day-picker, .rmdp-month-picker, .rmdp-year-picker': {
            '.rmdp-week, .rmdp-ym': {
                '.rmdp-day': {
                    boxShadow: 'none',
                    '&.rmdp-range-hover': {
                        backgroundColor: colors.themeSecondColor10,
                        span: {
                            '&:hover': {
                                backgroundColor: `${colors.transparent} !important`
                            }
                        },
                        '&.start': {
                            borderTopLeftRadius: 4,
                            borderBottomLeftRadius: 4
                        },
                        '&.end': {
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4
                        }
                    }
                }
            }
        }
    }
}))

const CustomBoxDateRangePicker = styled(CustomBoxDatePicker)(({ theme }) => ({
    '.icon-calendar': {
        color: colors.themeSecondColor,
        '&.disabled': {
            color: colors.disabledGray
        }
    },
    '.btn-calendar-picker': {
        backgroundColor: `${colors.transparent} !important`,
        '.MuiFormControl-root': {
            pointerEvents: 'none',
            '.MuiInputBase-input': {
                paddingRight: '44px !important'
            }
        },
        '.icon-clear-value': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 14,
            color: colors.black60
        },
        '.icon-calendar': {
            position: 'absolute',
            top: '50%',
            right: 14,
            transform: 'translateY(-50%)',
            '&.disabled': {
                color: colors.black25
            }
        },
        '&.date-picker-error': {
            '.MuiFormHelperText-root.Mui-error': {
                position: 'absolute',
                bottom: '-24px'
            },
        },
    }
}))

export const emptyRangePicker = ['', '']

export enum PICKER_VIEWS {
    MONTH = 'month',
    YEAR = 'year',
}

interface DatePickerType {
    value: any
    onchange: (val: any) => void
    dateFormat?: string
    required?: boolean
    key?: string
    inputName?: string
    label?: string
    helperText?: any
    placeholder?: string
    inputHeight?: number
    calendarsPage?: number
    disabled?: boolean
    allowClear?: boolean
    onClear?: (val: any) => void
    views?: PICKER_VIEWS
    disableFuture?: boolean
    disablePast?: boolean
    fixMainPosition?: boolean
    calendarPosition?: 'top' | 'bottom'
}

export default function InputSecondNewRangePicker(props: DatePickerType) {
    const { t } = useTranslation()
    const calendarFormat = useCalendarFormat()
    const [width] = useWindowSize()
    const datePickerRef = useRef<any>()

    const showOtherDay = () => {
        if (width >= 768 && props.calendarsPage === 1) return false
        else if (width >= 768) return true
        else return false
    }

    const renderFormat = (dateFormat: string, view: PICKER_VIEWS | null) => {
        let format = 'DD/MM/YYYY'
        if (dateFormat) format = dateFormat
        else if (view === PICKER_VIEWS.MONTH) format = 'MMMM YYYY'
        else if (view === PICKER_VIEWS.YEAR) format = 'YYYY'
        return format
    }

    const renderValue = (date: string[], dateFormat: string, view: PICKER_VIEWS | null) => {
        const format = renderFormat(dateFormat, view)
        if (!_.includes(date, '') && date[0] && date[1] && date[0] !== 'Invalid date' && date[1] !== 'Invalid date') {
            return `${toBuddhistYear(moment(date[0]), format)}  »  ${toBuddhistYear(moment(date[1]), format)}`
        } else return ''
    }

    useEffect(() => {
        if (props.value[1] && props.value[1] !== 'Invalid date' && datePickerRef.current) datePickerRef.current.closeCalendar()
    }, [props.value]);

    return (
        <CustomRangeDatePicker
            ref={datePickerRef}
            hideOnScroll
            locale={calendarFormat.format_language}
            calendar={calendarFormat.calendar}
            range
            rangeHover
            disabled={props.disabled}
            value={[new Date(props.value[0]), new Date(props.value[1])]}
            onChange={(value: any) => props.onchange && props.onchange([value[0] ? value[0].toDate() : '', value[1] ? value[1].toDate() : ''])}
            containerClassName={`position-relative w-100`}
            containerStyle={{ marginBottom: props.helperText ? '24px' : 0 }}
            onlyMonthPicker={props.views === PICKER_VIEWS.MONTH}
            onlyYearPicker={props.views === PICKER_VIEWS.YEAR}
            onOpenPickNewDate={false}
            shadow={false}
            showOtherDays={showOtherDay()}
            minDate={props.disablePast ? new Date() : ''}
            maxDate={props.disableFuture ? new Date() : ''}
            numberOfMonths={width >= 768 ? props.calendarsPage || 2 : 1}
            fixMainPosition={props.fixMainPosition}
            calendarPosition={props.calendarPosition || 'bottom-start'}
            onClose={() => {
                if (!props.value[1]) props.onchange && props.onchange([])
            }}
            render={(value: any, openCalendar: any) => {
                return (
                    <CustomBoxDateRangePicker className='position-relative w-100'>
                        <Button
                            onClick={openCalendar}
                            disabled={props.disabled}
                            disableElevation
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            className={`btn-calendar-picker position-relative p-0 w-100 ${props.helperText ? 'date-picker-error' : ''}`}>
                            <InputTextField
                                required={props?.required || false}
                                key={props.key}
                                name={props.inputName || ''}
                                label={props.label}
                                helperText={props.helperText}
                                placeholder={props?.placeholder || `${t('START_DATE')}  »  ${t('END_DATE')}`}
                                inputHeight={props?.inputHeight || 43}
                                disabled={props.disabled}
                                value={renderValue(props.value, props.dateFormat || '', props.views || null)}
                                style={{ pointerEvents: props.allowClear && !_.includes(props.value, '') ? 'none' : 'auto' }}
                            />
                            {(props.allowClear && !_.includes(props.value, '')) &&
                                <ClearIcon className='icon-clear-value cursor-pointer' onClick={props.onClear} /> ||
                                <EventIcon className={`icon-calendar ${props.disabled ? 'disabled' : ''}`} />
                            }
                        </Button>
                    </CustomBoxDateRangePicker>
                )
            }}>
        </CustomRangeDatePicker>
    )
}
