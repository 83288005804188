import { useCallback, useEffect, useState } from 'react'
import { Grid, Box, Typography, TableRow, TableCell } from '@mui/material'
import _ from 'lodash'
import { clinicTheme } from 'api/api'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'
import TreatmentApi from 'api/dentists/treatment.api'
import { dateToView } from 'utils/date.utils'
import { numberFormat } from 'utils/app.utils'
import useWindowSize from 'utils/useWindowSize'
import * as xrayUseStyle from 'component/Treatments/style/xrayUseStyle'
import { CloseButton } from 'react-bootstrap'
import TableCustom from 'features/treatments/MainMenu/OPD/Table/TableCustom'

export const TableWidth = styled(Box)(({ theme }) => ({
    '.MuiTable-root': {
        minWidth: 'unset',
        maxWidth: '100%',
        tableLayout: 'fixed',
        '.MuiTableHead-root': {
            '.MuiTableCell-head': {
                '&:nth-of-type(1)': {
                    width: '2%'
                },
                '&:nth-of-type(2)': {
                    width: '10%'
                },
                '&:nth-of-type(3)': {
                    width: '10%'
                },
                '&:nth-of-type(4)': {
                    width: '20%'
                },
                '&:nth-of-type(5)': {
                    width: '7%'
                },
                '&:nth-of-type(6)': {
                    width: '10%'
                },
                '&:nth-of-type(7)': {
                    padding: '15px',
                    width: '20%'
                },
            }
        },
        '.MuiTableBody-root': {
            backgroundColor: colors.white,
            '.MuiTableCell-root': {
                wordBreak: 'break-word'
            },
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: 972,
        },
    },
    '.MuiToolbar-root': {
        minHeight: 'unset',
        marginTop: 16,
        [theme.breakpoints.down('lg')]: {
            marginTop: 8
        }
    },
    '.MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiTablePagination-displayedRows': {
        display: 'none'
    },
    '.MuiTableContainer-root': {
        overflowY: 'auto',
        height: '100%',
        backgroundColor: 'transparent'
    },
    '.MuiTableFooter-root': {
        display: 'none'
    }
}))

export interface PopupTreatmentCourseProps {
    onSubmit?: () => void
    onReset?: () => void
    courseId?: any
    header?: any
    isShow: boolean | false
    setIsShow: (show: boolean) => void
}

export default function PopupTreatmentCourse(props: PopupTreatmentCourseProps) {

    const { t } = useTranslation()
    const [width] = useWindowSize()
    const [data, setData] = useState<any>('')

    const loadData = useCallback(async () => {
        const res = await TreatmentApi.findTreatmentCourses(props.courseId)
        if (res.status === 200) {
            setData(res.data)
        }
    }, [props.courseId])

    useEffect(() => {
        loadData()
    }, [loadData])

    const defaultHeadCells = [
        { id: 'no', disablePadding: false, align: 'center', label: '#', width: width >= 1200 ? '5%' : '50px' },
    ]

    const teethHeadCell = clinicTheme.theme === clinicTheme.type.DENTAL ? [{ id: 'recommendText', disablePadding: false, align: 'left', label: t('TXC.TABLE.TEETH') }] : []

    const headCells = width >= 1200 ? [
        ...defaultHeadCells,
        { id: 'recommendText', disablePadding: false, align: 'left', label: t('TXC.TABLE.DATE') },
        { id: 'recommendText', disablePadding: false, align: 'left', label: t('TXC.TABLE.BRANCH') },
        { id: 'recommendText', disablePadding: false, align: 'left', label: t('TXP.TABLE.DETAIL') },
        ...teethHeadCell,
        { id: 'recommendText', disablePadding: false, align: 'right', label: t('TXC.TABLE.PRICE') },
        { id: 'recommendText', disablePadding: false, align: 'left', label: t('TXP.TABLE.DOCTOR') }
    ] : [...defaultHeadCells, { id: 'message', disablePadding: false, align: 'left', label: '' }]

    const renderData = (objData: any, no: number) => {
        const { createdAt, doctorFullName, teeth, total, itemName, branchName } = objData
        return (
            <>
                <TableRow key={`diagnosis-${no}`} className="main-table-row">
                    <TableCell align="center" scope="row">
                        {no + 1}
                    </TableCell>
                    {width >= 1200 && (
                        <>
                            <TableCell align="left" scope="row">
                                {dateToView(createdAt || '-')}
                            </TableCell>
                            <TableCell align="left" scope="row" sx={{ wordBreak: 'break-all' }}>
                                {branchName || "-"}
                            </TableCell>
                            <TableCell align="left" scope="row" sx={{ wordBreak: 'break-all' }}>
                                {itemName || "-"}
                            </TableCell>
                            {clinicTheme.theme === clinicTheme.type.DENTAL && (
                                <TableCell align="left" scope="row" sx={{ wordBreak: 'break-all' }}>
                                    {teeth || '-'}
                                </TableCell>
                            )}
                            <TableCell align="right" scope="row">
                                {numberFormat(total) || '-'}
                            </TableCell>
                            <TableCell align="left" scope="row">
                                {doctorFullName || '-'}
                            </TableCell>
                        </>
                    ) || (
                            <TableCell align="left" scope="row">
                                <Grid container spacing={1}>
                                    <Grid item className='w-100'>
                                        <Box className="d-flex">
                                            <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                                                {t('TXC.TABLE.DATE')}
                                            </Typography>
                                            <Typography>{dateToView(createdAt || '-')}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <Box className="d-flex">
                                            <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                                                {t('TXC.TABLE.BRANCH')}
                                            </Typography>
                                            <Typography sx={{ wordBreak: 'break-all' }}>{branchName || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <Box className="d-flex">
                                            <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                                                {t('TXP.TABLE.DETAIL')}
                                            </Typography>
                                            <Typography sx={{ wordBreak: 'break-all' }}>{itemName || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    {clinicTheme.theme === clinicTheme.type.DENTAL && (
                                        <Grid item xs={12} className='w-100'>
                                            <Box className="d-flex">
                                                <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                                                    {t('TXC.TABLE.TEETH')}
                                                </Typography>
                                                <Typography sx={{ wordBreak: 'break-all' }}>{teeth || '-'}</Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={6} className='w-100'>
                                        <Box className="d-flex">
                                            <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                                                {t('TXC.TABLE.PRICE')}
                                            </Typography>
                                            <Typography>{numberFormat(total) || '-'}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} className='w-100'>
                                        <Box className="d-flex">
                                            <Typography sx={{ width: width >= 480 ? '130px' : '107px', fontWeight: 600, flex: 'none' }}>
                                                {t('TXP.TABLE.DOCTOR')}
                                            </Typography>
                                            <Typography>{doctorFullName || '-'}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        )}
                </TableRow>
            </>
        )
    }

    return (
        <xrayUseStyle.ModalStyle returnFocusAfterClose={false} size={'xl'} show={props.isShow} aria-labelledby="contained-modal-title-vcenter" centered>
            <xrayUseStyle.Header>
                <Typography>{props.header}</Typography>
                <CloseButton
                    style={{ position: 'absolute', top: 20, right: 20 }}
                    onClick={() => {
                        props.setIsShow(false)
                    }}
                />
            </xrayUseStyle.Header>
            <xrayUseStyle.Body className="custom-scroll">
                <Box className='d-flex flex-column overflow-hidden px-3'>
                    <TableWidth className={'overflow-hidden d-flex flex-column pt-2 pb-3 overflow-hidden'}>
                        <TableCustom
                            hidePagination
                            customScroll
                            page={0}
                            pageLimit={0}
                            setPageLimit={() => {
                                return
                            }}
                            setPage={() => {
                                return
                            }}
                            rowCount={0}
                            headCells={headCells}
                            rowsData={_.map(data, (i, index: number) => {
                                return renderData(i, index)
                            })}
                        />
                    </TableWidth>
                </Box>
            </xrayUseStyle.Body>
        </xrayUseStyle.ModalStyle >

    )
}
