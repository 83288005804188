import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/51.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth51(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 31.95, height: 100, viewBox: "0 0 31.95 110" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 31.95, height: 100, viewBox: "0 0 31.95 110" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={width > 1440 ? 70 : 100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12356" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5436"
                                d="M31.5904 70.6868C33.0745 75.0874 32.6769 79.8418 32.6479 82.5877C32.6479 95.8029 32.6657 96.0686 21.0315 96.9958C16.7672 97.3363 12.3322 96.5392 8.12506 95.4561C3.24674 94.1951 -0.170356 86.8114 1.00065 80.2095C4.57193 60.0115 5.67511 39.3561 8.91547 19.0971C9.61494 14.7027 9.20053 10.0573 9.30256 5.52634C9.34841 3.48201 9.16614 1.21452 11.3834 0.574085C13.6463 -0.079529 14.9201 1.57524 15.8641 3.73516C18.8255 10.5342 20.5718 17.8302 21.8634 25.289C24.5061 40.6033 26.6668 56.0604 31.5904 70.6868Z"
                                transform={"translate(-2 0)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5436"
                                    d="M31.5904 70.6868C33.0745 75.0874 32.6769 79.8418 32.6479 82.5877C32.6479 95.8029 32.6657 96.0686 21.0315 96.9958C16.7672 97.3363 12.3322 96.5392 8.12506 95.4561C3.24674 94.1951 -0.170356 86.8114 1.00065 80.2095C4.57193 60.0115 5.67511 39.3561 8.91547 19.0971C9.61494 14.7027 9.20053 10.0573 9.30256 5.52634C9.34841 3.48201 9.16614 1.21452 11.3834 0.574085C13.6463 -0.079529 14.9201 1.57524 15.8641 3.73516C18.8255 10.5342 20.5718 17.8302 21.8634 25.289C24.5061 40.6033 26.6668 56.0604 31.5904 70.6868Z"
                                    transform={"translate(-2 0))"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12356" >
                                <path
                                    data-name="Path 5437"
                                    d="M19.367 50.7232C14.6356 50.2544 8.37135 51.9269 4.13614 46.3111C0.279221 41.1943 1.55053 35.4184 2.20052 30.1108C3.32223 20.9619 6.04081 12.113 12.1641 4.50559C16.8173 -1.27453 21.6842 -1.59665 25.6487 4.48267C32.4802 14.9592 38.8745 25.7476 38.4601 38.621C38.1663 47.7681 34.699 50.6212 24.8647 50.7215C23.3542 50.7372 21.8437 50.7232 19.367 50.7232Z"
                                    transform={"translate(-4 50)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5438"
                                    d="M17.9133 49.4327C13.3088 49.0025 7.214 50.5386 3.09246 45.3823C-0.661662 40.686 0.575445 35.3843 1.20881 30.5131C2.29976 22.1154 4.94516 13.9926 10.9039 7.01144C15.432 1.70553 20.168 1.41072 24.0259 6.99042C30.6734 16.6057 36.8955 26.5085 36.4925 38.3242C36.2059 46.7199 32.8326 49.3391 23.2632 49.4306C21.7932 49.4453 20.3232 49.4327 17.9133 49.4327Z"
                                    transform={"translate(-4 50)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5439"
                                    d="M18.2186 48.8324C14.1347 48.4277 8.72752 49.8723 5.07198 45.0238C1.74277 40.6073 2.84003 35.6223 3.40136 31.0405C4.36908 23.1435 6.71644 15.5043 12.0011 8.93859C16.0174 3.9485 20.2192 3.67127 23.6416 8.91948C29.5379 17.9619 35.0576 27.2744 34.7005 38.3862C34.4462 46.2823 31.4525 48.7449 22.9641 48.831C21.6603 48.8438 20.3565 48.8324 18.2186 48.8324Z"
                                    transform={"translate(-4 50)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6537"
                                        d="M5.64792 32.1088C5.58403 23.5769 1.04692 1.90234 2.4242 1.90234C3.24845 3.27935 5.46542 25.106 5.48819 26.6768C5.47993 28.1772 5.64737 30.1976 5.64792 32.2691C5.49204 37.0694 5.19131 43.4504 5.35104 46.0573C5.82363 45.9885 5.88476 45.9472 6.3579 45.9026C6.32045 45.2091 5.13843 41.728 5.64792 32.1088Z"
                                        transform="translate(9 6)"
                                        style={{
                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-5 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-5 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154150" x1="19.4825" y1="74.7759" x2="17.2984" y2="87.2425" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154150" x1="19.4825" y1="83.521" x2="19.4825" y2="78.8973" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9545">
                                            <path transform={"translate(-4 5)"} d="M33.3061 68.1931C34.7449 72.4594 34.3595 77.0687 34.3313 79.7308C34.3313 92.5427 34.3485 92.8003 23.0694 93.6992C18.9353 94.0293 14.6356 93.2566 10.5568 92.2065C5.82739 90.984 2.51458 83.8256 3.64984 77.4252C7.11214 57.8436 8.18165 37.8185 11.3231 18.1778C12.0012 13.9175 11.5995 9.41389 11.6984 5.0212C11.7429 3.03925 11.5661 0.840956 13.7157 0.220069C15.9096 -0.413598 17.1445 1.19067 18.0597 3.28468C20.9308 9.87623 22.6237 16.9496 23.8759 24.1807C26.438 39.0277 28.5327 54.0131 33.3061 68.1931Z" fill="#E3D1B4" />
                                            <path transform={"translate(-4 5)"} d="M19.5239 95.3749C14.9369 94.9204 8.86382 96.5419 4.75786 91.0974C1.01865 86.1368 2.25116 80.5372 2.88131 75.3915C3.96879 66.5219 6.60441 57.943 12.5408 50.5677C17.052 44.964 21.7704 44.6517 25.6139 50.5455C32.2369 60.7023 38.4361 71.1615 38.0344 83.642C37.7495 92.51 34.388 95.276 24.8539 95.3733C23.3895 95.3884 21.925 95.3749 19.5239 95.3749Z" fill="#EFEDE7" />
                                            <path transform={"translate(-4 5)"} d="M18.1084 94.1226C13.6443 93.7054 7.73554 95.1947 3.73979 90.1957C0.100232 85.6428 1.29959 80.5029 1.91362 75.7803C2.97128 67.639 5.53595 59.764 11.3129 52.9959C15.7028 47.8519 20.2942 47.5661 24.0344 52.9755C30.479 62.2973 36.5112 71.8979 36.1205 83.353C35.8427 91.4925 32.5723 94.0318 23.2949 94.1205C21.8698 94.1348 20.4446 94.1226 18.1084 94.1226Z" fill="#F7F7F7" />
                                            <path transform={"translate(-4 5)"} d="M18.4034 93.5414C14.4441 93.149 9.20195 94.5496 5.65797 89.849C2.43036 85.5673 3.49413 80.7345 4.03833 76.2925C4.97651 68.6364 7.25224 61.2304 12.3757 54.865C16.2694 50.0272 20.3429 49.7585 23.6609 54.8465C29.3772 63.613 34.7285 72.6413 34.3823 83.414C34.1358 91.0691 31.2334 93.4565 23.0041 93.5401C21.7401 93.5525 20.4761 93.5414 18.4034 93.5414Z" fill="white" />
                                            <path transform={"translate(-4 5)"} d="M24.414 71H14.586C13.1578 71 12 72.1578 12 73.586V83.414C12 84.8422 13.1578 86 14.586 86H24.414C25.8422 86 27 84.8422 27 83.414V73.586C27 72.1578 25.8422 71 24.414 71Z" fill="#C1C4C5" />
                                            {/* <path transform={"translate(-4 5)"} d="M24.416 71.6465H14.5879C13.5168 71.6465 12.6484 72.5148 12.6484 73.586V83.414C12.6484 84.4852 13.5168 85.3535 14.5879 85.3535H24.416C25.4871 85.3535 26.3554 84.4852 26.3554 83.414V73.586C26.3554 72.5148 25.4871 71.6465 24.416 71.6465Z" stroke="#C1C4C5" /> */}
                                            <path transform={"translate(-4 5)"} d="M18.3667 85.3314C18.1081 85.7995 20.5338 85.568 22.353 85.5447C25.0605 85.4878 26.6057 86.0413 26.5798 79.7909C26.6501 77.573 26.5814 75.353 26.3742 73.1437C25.6876 72.4765 26.5552 79.9138 24.7981 83.185C23.1405 85.5473 18.8102 84.8297 18.3667 85.3314Z" fill="#B0B1B1" />
                                            <path transform={"translate(-4 5)"} d="M22.0685 79.0352H16.8965C16.5394 79.0352 16.25 79.3246 16.25 79.6817V80.9746C16.25 81.3317 16.5394 81.6211 16.8965 81.6211H22.0685C22.4255 81.6211 22.715 81.3317 22.715 80.9746V79.6817C22.715 79.3246 22.4255 79.0352 22.0685 79.0352Z" fill="#CBCCCC" />
                                            <path transform={"translate(-4 5)"} d="M21.8146 73.6045H16.6426C16.2855 73.6045 15.9961 73.8939 15.9961 74.251V75.544C15.9961 75.901 16.2855 76.1905 16.6426 76.1905H21.8146C22.1716 76.1905 22.4611 75.901 22.4611 75.544V74.251C22.4611 73.8939 22.1716 73.6045 21.8146 73.6045Z" fill="#9E9E9E" />
                                            <path transform={"translate(-4 5)"} d="M22.0685 73.6045H16.8965C16.5394 73.6045 16.25 73.8939 16.25 74.251V75.544C16.25 75.901 16.5394 76.1905 16.8965 76.1905H22.0685C22.4255 76.1905 22.715 75.901 22.715 75.544V74.251C22.715 73.8939 22.4255 73.6045 22.0685 73.6045Z" fill="url(#paint0_linear_4126_154150)" />
                                            <path transform={"translate(-4 5)"} d="M22.0685 78.7783H16.8965C16.5394 78.7783 16.25 79.0678 16.25 79.4248V80.7178C16.25 81.0749 16.5394 81.3643 16.8965 81.3643H22.0685C22.4255 81.3643 22.715 81.0749 22.715 80.7178V79.4248C22.715 79.0678 22.4255 78.7783 22.0685 78.7783Z" fill="url(#paint1_linear_4126_154150)" />
                                            <path transform={"translate(-4 5)"} d="M1.42578 79.2787C1.42578 79.2787 17.072 74.3071 37.6389 78.247" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={"translate(-4 5)"} d="M1.42578 79.7885C13.2089 76.7935 25.6558 76.4303 37.6389 78.7318" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>51</TextNumberU>
        </Div >
    )
}
