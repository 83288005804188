import { useCallback, useEffect, useState } from 'react'
import { numberFormat } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { ExportProps } from '../report-constants';
import { UseSnCode } from 'utils/useSnCode'
interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    componentRef: any
    filter: any
    dataExport: any
}

export default function PatientDateOfBirthList(props: FcComponent) {
    const { t } = useTranslation();

    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, [])

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.#NO', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.CN', font: '10px', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.SN', font: '10px', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.FULL_NAME', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.GENDER', font: '10px', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.PATIENT_DATE_OF_BIRTH', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.AGE', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.PHONE', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.TELEPHONE', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.LINE', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.EMAIL', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.FACEBOOK', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.LATEST_SERVICE_BRANCH', font: '10px', align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_DATE_OF_BIRTH.LATEST_SERVICE_DATE', font: '10px', align: 'center', class: 'text-nowrap' },
    ]

    if (!snType) {
        headCells.splice(2, 1)
    }
    const renderData = (data: any, no: number) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1
        const columns = [{ option: 'TEXT', align: 'center', font: '10px', label: num },
        { option: 'TEXT', align: 'left', font: '10px', label: data.cnNumber, class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', font: '10px', label: data.snCode ? data.snCode : "-", class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', font: '10px', label: data.fullName, class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', font: '10px', label: t(`REGISTER.FORM.GENDER_OPTION.${data.gender}`), class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', font: '10px', label: data.dateOfBirthBuddhist },
        { option: 'TEXT', align: 'center', font: '10px', label: data.age },
        { option: 'TEXT', align: 'center', font: '10px', label: data.phone },
        { option: 'TEXT', align: 'center', font: '10px', label: data.telephone },
        { option: 'TEXT', align: 'center', font: '10px', label: data.line },
        { option: 'TEXT', align: 'center', font: '10px', label: data.email },
        { option: 'TEXT', align: 'center', font: '10px', label: data.facebook, class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', font: '10px', label: data.branchName, class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', font: '10px', label: data.createdAtBuddhist, class: 'text-nowrap' }]

        if (!snType) {
            columns.splice(2, 1)
        }
        return <>
            <TableRowCommon
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={columns} />
        </>
    }

    return (
        <>
            <Box ref={props.componentRef}>
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        tableMinWidth={992}
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={props.data.rows ? _.map(props.data.rows, (d: any, index: number) => renderData(d, index)) : []}
                        customScroll
                    />
                </UseStyled.ResponsiveReportTable>
            </Box>

            <div className="print-show" ref={props.componentRef}>
                <PatientDateOfBirthExport
                    header={props.filter}
                    headCells={headCells}
                    rowsData={props.dataExport.rows ? _.map(props.dataExport.rows, (d: any, index: number) => renderData(d, index)) : []} />
            </div>
        </>
    )

}
export function PatientDateOfBirthExport(props: ExportProps) {
    const { t } = useTranslation()
    const { header } = props
    const head = props.headCells
    const headCells = head.filter((headCell: any) => headCell.id !== 'action')

    return (
        <UseStyled.Styles className="pt-3">
            <h5>{header[0]}</h5>
            <TableSortLabel  >
                <td style={{ fontSize: 16, fontWeight: 500 }}>{t('REPORT.FILTER.BRANCH')}: </td>
                <td style={{ fontSize: 16, fontWeight: 500 }}>  {header[1]}</td>
            </TableSortLabel>
            <TableSortLabel  >
                <td style={{ fontSize: 16, fontWeight: 500 }}>{t('REPORT.MONTH.DATE_OF_BIRTH')}: </td>
                <td style={{ fontSize: 16, fontWeight: 500 }}>  {header[2]}</td>
            </TableSortLabel>
            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                <thead>
                    <tr>
                        {_.map(headCells, (val: any, index: number) => {
                            return (
                                < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 400 }}> {t(val.labelKey)}</td>
                            )
                        })}
                    </tr>
                </thead>
                <tbody  >
                    {props.rowsData}
                    {!props.rowsData.length && (
                        <td colSpan={headCells.length} style={{ fontSize: 10, fontWeight: 300 }}>
                            {t('REPORT.TITLE.NO_DATA')}
                        </td>
                    )}
                </tbody>
            </table>
        </UseStyled.Styles >
    )
}