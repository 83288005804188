import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { Box, Grid, IconButton } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import { routeName } from 'routes/routes-name'

/** COMPONENT */
import DefaultPagination from 'component/Pagination/DefaultPagination'

/** UTILS */
import { numberFormat, toBuddhistYear } from 'utils/app.utils'

/** CONSTANTS */
import * as UseStyled from 'component/Register/style/styleTreatmentHistory'
import { colors } from 'constants/theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { icons } from 'constants/images'

export interface BranchType { branchCode: string, branchId: number, branchName: string, branchNameEn: string, branchNumber: string }
export interface OrderTreatmentsType {
  doctorFullname: string
  nextVisit: any
  noteNextVisit: string,
  note: string
  operativeName: string
  operativeNameEn: string
  operativeTypeName: string
  operativeTypeNameEn: string
  orderId: number
  teeth: string | null
  clinicFeeName: string
  clinicFeeNameEn: string
  clinicFeePrice: number
  price: number
}
export interface OrderProductsType {
  doctorFullname: string
  itemCode: string
  itemName: string
  itemNameEn: string
  itemTypeName: string
  itemTypeNameEn: string
  note: string
  orderId: number | null
}
export interface PaymentsType { orderId: number, orderPaymentId: number, paymentChannelName: string, paymentChannelNameEn: string }
export interface TreatmentsType { doctorFullname: string, doctorId: number, nextVisits: any, note: null | string, orderId: number, treatmentName: string, treatmentNameEn: string }
export interface OrderType {
  amountPaid: number
  createdAt: string
  date: string
  orderId: number
  orderProducts: OrderProductsType[]
  orderTreatments: OrderTreatmentsType[]
  overdue: number
  patientCaseId: number
  payments: PaymentsType
  totalPrice: number
  treatments: TreatmentsType[]
}
export interface CreateDataType {
  branch: BranchType
  branchCnNumber: string
  branchId: number
  cnNumber: string
  countLabOrder: null | string | number
  countXray: null | string | number
  createdAt: string
  order: OrderType
  patientCaseId: number
  patientDiagnosis: any
  patientId: number
  vnNumber: string
}

function Row(props: { no: number, data: CreateDataType, page: number }) {
  const { no, data, page } = props
  const [open, setOpen] = useState(no === 0 ? true : false)

  const groupOrderTreatments = (item: any) => {
    const newArr = _.chain([...item.order.orderTreatments, ...item.order.orderProducts])
      .map((i: any) => {
        return i
      })
      .groupBy('doctorFullname')
      .map((value: any, key: string) => ({ key, data: value }))
      .value()

    return newArr
  }
  const groupTreatments = groupOrderTreatments(data)

  useEffect(() => {
    setOpen(no === 0 ? true : false)
  }, [page]);

  return (
    <UseStyled.TBody key={no} open={open}>
      <Box className={'d-flex align-items-start'}>
        <UseStyled.Cell type='branch' className={'d-none d-sm-block text-ellipsis'}>{data.branch.branchCode || '-'}</UseStyled.Cell>
        <UseStyled.Cell type='date' className={'d-none d-sm-block text-ellipsis'}>{data.createdAt ? toBuddhistYear(moment(data.createdAt), 'DD/MM/YYYY') : '-'}</UseStyled.Cell>
        <UseStyled.Cell className='overflow-hidden'>
          <UseStyled.Typo className='d-sm-none' sx={{ marginBottom: '2px' }}>
            {data.branch.branchCode ? `[${data.branch.branchCode}]` : ''} {data.order.date ? toBuddhistYear(moment(data.order.date), 'DD/MM/YYYY') : '-'}
          </UseStyled.Typo>
          <Box className={`d-flex align-items-start  ${open ? '' : 'overflow-hidden'}`}>
            <Box className='w-100 overflow-hidden'>
              {_.map([...groupTreatments], (i: any, index) => {
                const renderClassName = () => {
                  if (index === 0) return 'd-block'
                  else if (open && index > 0) return 'opacity-1'
                  else return 'opacity-0'
                }
                return <>
                  {i.key !== 'null' ? <span className={`${renderClassName()}`} ><UseStyled.ChipDoctor label={'Dr.'} size="small" />{i.key}</span> : <></>}
                  {
                    _.map(i.data, (treatment: OrderTreatmentsType & OrderProductsType, indexTreatment: number) => {
                      return (
                        <RenderTreatment
                          open={open}
                          index={indexTreatment}
                          doctorFullName={_.map(groupTreatments, (i: any) => { return i.key })}
                          treatmentName={treatment.operativeName || treatment.operativeNameEn || treatment.operativeTypeName || treatment.operativeTypeNameEn || ''}
                          itemName={treatment.itemName || treatment.itemNameEn || ''}
                          teeth={treatment.teeth || ''}
                          note={treatment.note || ''}
                          noteNextVisit={treatment.noteNextVisit || ''}
                          nextVisit={treatment.nextVisit}
                          clinicFeeName={treatment.clinicFeeName || treatment.clinicFeeNameEn}
                          price={treatment.price}
                          clinicFeePrice={treatment.clinicFeePrice}
                        />
                      )
                    })
                  }
                </>
              })}

            </Box>
          </Box>
        </UseStyled.Cell>
        <UseStyled.Cell type='action' className='text-right'>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} className={'expand-more'}>
            <ExpandMoreIcon className={'expand-more-icon'} />
          </IconButton>
        </UseStyled.Cell>
      </Box >
      <UseStyled.CustomCollapse in={open} timeout="auto" unmountOnExit>
        <Box className={`collapse-wrap ${open ? 'opacity-1' : 'opacity-0'}`}>
          <RenderTreatmentDetail
            branchName={data.branch.branchName || data.branch.branchNameEn || ''}
            totalPrice={data.order.totalPrice || 0}
            amountPaid={data.order.amountPaid || 0}
            patientId={data.patientId}
            patientCaseId={data.patientCaseId}
            PaymentId={data.order.payments}
          />
        </Box>
      </UseStyled.CustomCollapse>
    </UseStyled.TBody >
  )
}

function RenderTreatment(props: { noteNextVisit: string, open: boolean, index: number, doctorFullName: any, treatmentName: string, teeth: string, note: string, nextVisit: any, itemName: string, price: number, clinicFeeName?: string, clinicFeePrice?: number }) {
  const { t } = useTranslation()
  const { open, index, treatmentName, teeth, note, nextVisit, itemName, price, clinicFeeName, clinicFeePrice } = props

  const renderNextVisit = (value: any) => {
    return t('REGISTER.TITLE.NEXT_VISIT', {
      date: value.date ? toBuddhistYear(moment(value.date), 'DD/MM/YYYY') : '-',
      time: value.timeStart ? toBuddhistYear(moment(value.timeStart, 'HH:mm'), 'HH:mm') : '-'
    })
  }

  const renderClassName = () => {
    if (index === 0) return 'd-block'
    else if (open && index > 0) return 'opacity-1'
    else return 'opacity-0'
  }

  return (
    <Box className={`${renderClassName()}`} sx={{ marginTop: index > 0 ? '4px' : 0 }}>
      {itemName && (
        <Box className={`${open ? 'opacity-1' : 'opacity-0'}`} sx={{ marginTop: '2px' }}>
          <UseStyled.ChipProduct label={t('Med./Pd.')} size="small" />{itemName}
        </Box>
      ) || <></>}
      {treatmentName && (
        <Box className={`${open ? 'opacity-1' : 'opacity-0'}`} sx={{ marginTop: '2px' }}>
          <UseStyled.ChipTx label={t('Tx.')} size="small" />
          {treatmentName}
          {teeth ? <span style={{ fontWeight: 700 }}>{` (${teeth}) `}</span> : <></>}
          {price !== null ? <span style={{ fontWeight: 700 }}>{`(${numberFormat(price, 0)} ${t('REGISTER.UNIT_BATH')})`}</span> : <></>}
          {clinicFeeName !== null ? `: Cf. ${clinicFeeName}` : ''}
          {clinicFeePrice !== null && clinicFeeName !== null ? <span style={{ fontWeight: 700 }}>{`(${numberFormat(clinicFeePrice, 0)} ${t('REGISTER.UNIT_BATH')})`}</span> : <></>}
        </Box>
      ) || <></>}
      {note && (
        <Box className={`${open ? 'opacity-1' : 'opacity-0'}`} sx={{ marginTop: '2px' }}>
          <UseStyled.ChipRemark label={t('Note.')} size="small" />
          <span className='d-inline-block note'  >{note}</span>
        </Box>
      ) || <></>}
      {(!_.isEmpty(nextVisit) || !_.isEmpty(props.noteNextVisit)) && (
        <Box className={`${open ? 'opacity-1' : 'opacity-0'}`} sx={{ marginTop: '2px' }}>
          <div>
            <UseStyled.ChipVisit label={t('Nv.')} size="small" />{!_.isEmpty(nextVisit) ? renderNextVisit(nextVisit) : !(nextVisit?.hasLab === 'HAS' || nextVisit?.hasXray === 'HAS') ? props.noteNextVisit : ''}
            {nextVisit?.hasLab === 'HAS' || nextVisit?.hasXray === 'HAS' ? (
              <Box className='d-flex align-items-start'>
                <Box>
                  <div style={{ textIndent: '33px' }}>
                    {props.noteNextVisit}
                  </div>
                  <UseStyled.TagLab sx={{ display: nextVisit?.hasLab === 'HAS' ? '' : 'none', marginBottom: nextVisit?.hasXray === 'HAS' ? '2px' : '' }}>{t('REGISTER.LAB')}</UseStyled.TagLab>
                  <UseStyled.TagLab sx={{ display: nextVisit?.hasXray === 'HAS' ? '' : 'none' }}>{t('REGISTER.XRAY')}</UseStyled.TagLab>
                </Box>
              </Box>
            ) : ''}
          </div>
        </Box>
      ) || <></>}
    </Box>
  )
}

function RenderTreatmentDetail(props: { branchName: string, totalPrice: number, amountPaid: number, patientId: any, patientCaseId: any, PaymentId?: any }) {
  const { t } = useTranslation()
  const { branchName, totalPrice, amountPaid, patientCaseId, patientId, PaymentId } = props

  return (
    <Grid container spacing={1}>
      <Grid item className='w-100 d-flex'>
        <UseStyled.Typo type='title'>{t('REGISTER.TITLE.BRANCH')}</UseStyled.Typo>
        <UseStyled.Typo>{branchName}</UseStyled.Typo>
      </Grid>
      <Grid item sm={12} className='w-100 d-flex pt-1'>
        <UseStyled.Typo type='title'>{t('REGISTER.TITLE.EXPENSES')}</UseStyled.Typo>
        <UseStyled.Typo>{numberFormat(totalPrice)} {t('REGISTER.UNIT_BATH')}</UseStyled.Typo>
      </Grid>
      <Grid item sm={8} className='w-100 d-flex pt-1 justify-content-end'>
        <span >
          <img src={icons.iconDetail} alt="" className='pb-1' />
        </span>
      </Grid>
      <Grid item sm={4} className='w-100 d-flex pt-1 '>
        <UseStyled.a
          // onClick={() => {history.push(routeName.paymentDetail, { orderPaymentId: orderPaymentId.orderPaymentId })}} 
          onClick={() => { window.open(`${routeName.paymentDetail}?orderPaymentId=${PaymentId.orderPaymentId}`, '_blank') }}
          className='cursor-pointer'>{t('REGISTER.TITLE.PAYOUT')} {numberFormat(amountPaid)} {t('REGISTER.UNIT_BATH')}</UseStyled.a>
      </Grid>
      <Grid item sm={8} className='w-100 d-flex pt-1 justify-content-end'>
        <span >
          <img src={icons.iconDetail} alt="" className='pb-1' />
        </span>
      </Grid>
      <Grid item sm={4} className='w-100 d-flex pt-1 '>
        <UseStyled.a onClick={() => { window.open(routeName.treatmentsEmrHistory + `?patientId=${patientId}&patientCaseId=${patientCaseId}`, '_blank') }} className='cursor-pointer'>{t('REGISTER.TITLE.TREATMENT_HISTORY')}</UseStyled.a>
      </Grid>
    </Grid>
  )
}

export default function TreatmentHistory(props: { patientId: number }) {
  const { t } = useTranslation()
  const patientId = props.patientId
  const [page, setPage] = useState(1)
  const [history, setHistory] = useState([]);
  const pageLimit = 5
  const [rowCount, setRowCount] = useState(0)

  const loadTreatmentHistory = useCallback(async () => {
    let condition: any = {}
    condition = { ...condition, dateStart: '' }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }

    await TreatmentHistoryApi.findSummeryAllByPatientId(patientId, condition).then((res) => {
      if (res.status === 200) {
        setHistory(res.data)
        setRowCount(res.headers['x-total'])
      }
    })
  }, [props.patientId, page, pageLimit])

  useEffect(() => {
    setPage(1)
  }, [patientId]);

  useEffect(() => {
    loadTreatmentHistory()
  }, [loadTreatmentHistory])

  return (
    <>
      <UseStyled.THead>
        <UseStyled.Cell type='branch' className={'d-none d-sm-block'}>{t('REGISTER.TITLE.BRANCH_SERVICE')}</UseStyled.Cell>
        <UseStyled.Cell type='date' className={'d-none d-sm-block'}>{t('REGISTER.TITLE.DATE_SERVICE')}</UseStyled.Cell>
        <UseStyled.Cell>{t('REGISTER.TITLE.SERVICE')}</UseStyled.Cell>
      </UseStyled.THead>
      {(!_.isEmpty(history) && props.patientId && (
        <Box className={'treatment-body'}>
          {_.map(history, (data: any, index: number) => (
            <Row no={index} data={data} page={page} />
          ))}
          <Box className={'mt-2'}>
            <DefaultPagination count={rowCount} allRow={rowCount} pageLimit={pageLimit} page={page} setPage={setPage}></DefaultPagination>
          </Box>
        </Box>
      )) || (
          <UseStyled.TBody open className={'text-center'} style={{ color: colors.black60 }}>
            {t('REGISTER.NO_ITEM')}
          </UseStyled.TBody>
        )}
    </>
  )
}
