import { styled } from '@mui/material/styles'
import { useRef } from 'react'
import { TeethPositionProps } from 'component/ToothChart/ToothChart'
import _ from 'lodash'

export enum QUARTER_NAME_TYPE {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

enum POSITION_NAME_TYPE {
  M = 'M',
  D = 'D',
  Occ = 'Occ',
  Inc = 'Inc',
  Li = 'Li',
  La = 'La',
  Pa = 'Pa',
  B = 'B'
}

const Div = styled(`div`)(({ theme }) => ({
  position: 'relative',
  width: 32,
  height: 32,
  fill: '#ffffff',
  margin: 'auto',
  'svg': {
    cursor: 'pointer',
    '&.cls-2': {
      stroke: '#9e9e9e',
      strokeWidth: 1.5
    },
    '&.cls-3': {
      stroke: 'none'
    },
    '&.svg-up, &.svg-down': {
      display: 'flex',
      width: '100%',
      left: 0,
      right: 0,
      margin: 'auto'
    },
    '&.svg-left, &.svg-right': {
      display: 'flex',
      height: '100%',
      margin: 'auto',
      top: 0,
      bottom: 0
    },
    '&.svg-left': {
      position: 'absolute',
      width: '38% !important'
    },
    '&.svg-right': {
      position: 'absolute',
      right: 0
    },
    '&.svg-up': {
      position: 'absolute'
    },
    '&.svg-down': {
      position: 'absolute',
      bottom: -0.4,
      width: '66.5% !important'
    },
    '&.svg-center': {
      position: 'absolute',
      margin: 'auto',
      display: 'flex',
      fill: '#ffffff',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 2
    },
    '&.active': {
      fill: 'rgba(28, 185, 154, 0.2) !important'
    },
  }
}))

export default function PositionTeeth(props: TeethPositionProps) {
  const positionsRef: any = useRef<[HTMLButtonElement]>(null)
  const teethNumber = props.teethNumber

  const handleSelectPositionTeeth = (ele: any) => {
    const value = ele.currentTarget.getAttribute('data-value')
    const historyPosition: any = !_.isEmpty(props.position) ? props.position : []
    let newPositions: any = []
    if (!_.isEmpty(props.position) && _.includes(historyPosition, value)) {
      newPositions = _.filter(historyPosition, (pos, index) => { if (!pos.includes(value)) return pos })
    }
    else newPositions = [...historyPosition, ...[value]]

    if (props.handleChangePosition) props.handleChangePosition(newPositions)
  }

  const getNameOfQuarterLeft = (quarter: QUARTER_NAME_TYPE) => {
    if (quarter === QUARTER_NAME_TYPE.Q2 || quarter === QUARTER_NAME_TYPE.Q3) return POSITION_NAME_TYPE.M
    else return POSITION_NAME_TYPE.D
  }

  const getNameOfQuarterRight = (quarter: QUARTER_NAME_TYPE) => {
    if (quarter === QUARTER_NAME_TYPE.Q2 || quarter === QUARTER_NAME_TYPE.Q3) return POSITION_NAME_TYPE.D
    else return POSITION_NAME_TYPE.M
  }

  const getNameOfQuarterUP = (quarter: QUARTER_NAME_TYPE) => {
    if ((quarter === QUARTER_NAME_TYPE.Q1 || quarter === QUARTER_NAME_TYPE.Q2) && !isFrontTeeth(props.teethNumber)) return POSITION_NAME_TYPE.B
    else if ((quarter === QUARTER_NAME_TYPE.Q1 || quarter === QUARTER_NAME_TYPE.Q2) && isFrontTeeth(props.teethNumber)) return POSITION_NAME_TYPE.La
    else return POSITION_NAME_TYPE.Li
  }

  const getNameOfQuarterDown = (quarter: QUARTER_NAME_TYPE) => {
    if ((quarter === QUARTER_NAME_TYPE.Q3 || quarter === QUARTER_NAME_TYPE.Q4) && !isFrontTeeth(props.teethNumber)) return POSITION_NAME_TYPE.B
    else if ((quarter === QUARTER_NAME_TYPE.Q3 || quarter === QUARTER_NAME_TYPE.Q4) && isFrontTeeth(props.teethNumber)) return POSITION_NAME_TYPE.La
    else return POSITION_NAME_TYPE.Pa
  }

  const getNameOfQuarterCenter = (quarter: QUARTER_NAME_TYPE) => {
    if (isFrontTeeth(props.teethNumber)) return POSITION_NAME_TYPE.Inc
    else return POSITION_NAME_TYPE.Occ
  }

  const isFrontTeeth = (teethNumberVal: number) => {
    const teethFront = [11, 12, 13, 21, 22, 23, 31, 32, 33, 41, 42, 43]
    if (_.includes(teethFront, Number(teethNumberVal))) return true
    else return false
  }

  return (
    <Div>
      <svg className={`svg-left svg-left-${teethNumber} ${_.includes(props.position, getNameOfQuarterLeft(props.quarter)) ? 'active' : ''} ${props.isSelected ? '' : 'pe-none'}`} ref={positionsRef['left']} data-value={getNameOfQuarterLeft(props.quarter)} data-position='left' onClick={handleSelectPositionTeeth} xmlns="https://www.w3.org/2000/svg" width="12.071" height="23.66" viewBox="0 0 12.071 23.66">
        <g>
          <path
            d="M11.821 12.137A15.973 15.973 0 0 0 23.66 6.885c-2.967-3.206-3.194-3.208-6.6-6.819a6.421 6.421 0 0 1-5.232 2.5c-2.412.012-4.006-1.443-5.27-2.4C4.745 2.041 1.474 5.157 0 6.885a15.907 15.907 0 0 0 11.821 5.252z"
            style={{ stroke: 'none' }}
            transform="rotate(90 6.069 6.069)"
          />
          <path
            d="M11.821 10.637c1.989 0 3.923-.401 5.75-1.192a14.386 14.386 0 0 0 4.006-2.593 98.146 98.146 0 0 0-2.2-2.252c-.623-.622-1.309-1.307-2.332-2.374-1.437 1.21-3.183 1.828-5.207 1.838H11.8c-2.24 0-3.863-.985-5.1-1.902-.412.414-.858.857-1.317 1.314-1.134 1.126-2.378 2.363-3.33 3.374a14.06 14.06 0 0 0 3.838 2.514 14.706 14.706 0 0 0 5.93 1.273m0 1.5c-4.524 0-8.975-1.989-11.821-5.252C1.474 5.157 4.745 2.04 6.56.165c1.264.956 2.858 2.412 5.27 2.4 2.412-.013 4.06-1.042 5.232-2.5 3.404 3.612 3.63 3.613 6.598 6.82a15.973 15.973 0 0 1-11.839 5.252z"
            style={{ fill: '#9e9e9e', stroke: 'none' }}
            transform="rotate(90 6.069 6.069)"
          />
        </g>
      </svg>

      <svg className={`svg-center svg-center-${teethNumber} ${_.includes(props.position, getNameOfQuarterCenter(props.quarter)) ? 'active' : ''} ${props.isSelected ? '' : 'pe-none'}`} ref={positionsRef['center']} data-value={getNameOfQuarterCenter(props.quarter)} data-position='center' onClick={handleSelectPositionTeeth} xmlns="https://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g style={{ stroke: '#9e9e9e', strokeWidth: 1.5 }}>
          <circle cx="8" cy="8" r="8" style={{ stroke: 'none' }} />
          <circle cx="8" cy="8" r="7.25" />
        </g>
      </svg>

      <svg className={`svg-right svg-right-${teethNumber} ${_.includes(props.position, getNameOfQuarterRight(props.quarter)) ? 'active' : ''} ${props.isSelected ? '' : 'pe-none'}`} ref={positionsRef['right']} data-value={getNameOfQuarterRight(props.quarter)} data-position='right' onClick={handleSelectPositionTeeth} xmlns="https://www.w3.org/2000/svg" width="12.077" height="23.661" viewBox="0 0 12.077 23.661">
        <g>
          <path
            d="M15.991-.105A15.974 15.974 0 0 1 27.83 5.146c-2.968 3.206-3.229 3.2-6.633 6.81a6.281 6.281 0 0 0-5.189-2.509 6.465 6.465 0 0 0-5.031 2.524C9.161 10.1 5.644 6.874 4.169 5.146A15.908 15.908 0 0 1 15.991-.105z"
            style={{ stroke: 'none' }}
            transform="rotate(90 8.07 3.902)"
          />
          <path
            d="M15.99 1.395c-2.008 0-4.058.44-5.93 1.272a14.061 14.061 0 0 0-3.83 2.508c.901.937 2.083 2.082 3.162 3.128.55.534 1.085 1.052 1.573 1.532 1.081-.92 2.761-1.887 5.043-1.887 2.032 0 3.74.605 5.18 1.843 1.04-1.082 1.74-1.772 2.374-2.398.677-.668 1.284-1.267 2.184-2.215a14.385 14.385 0 0 0-4.006-2.592 14.39 14.39 0 0 0-5.75-1.191m0-1.5c4.313 0 8.663 1.755 11.84 5.251-2.967 3.207-3.229 3.2-6.633 6.81-1.173-1.456-2.756-2.508-5.19-2.508-2.434 0-4.158 1.41-5.03 2.524-1.816-1.875-5.333-5.098-6.808-6.826C7.016 1.883 11.466-.105 15.991-.105z"
            style={{ fill: '#9e9e9e', stroke: 'none' }}
            transform="rotate(90 8.07 3.902)"
          />
        </g>
      </svg>

      <svg className={`svg-up svg-up-${teethNumber} ${_.includes(props.position, getNameOfQuarterUP(props.quarter)) ? 'active' : ''} ${props.isSelected ? '' : 'pe-none'}`} ref={positionsRef['up']} data-value={getNameOfQuarterUP(props.quarter)} data-position='up' onClick={handleSelectPositionTeeth} xmlns="https://www.w3.org/2000/svg" width="23.661" height="12.137" viewBox="0 0 23.661 12.137">
        <g>
          <path
            d="M15.991-.105A15.974 15.974 0 0 1 27.83 5.146c-2.968 3.206-3.245 3.273-6.648 6.885a6.591 6.591 0 0 0-5.191-2.514 6.4 6.4 0 0 0-5 2.342C9.175 9.984 5.644 6.874 4.169 5.146A15.908 15.908 0 0 1 15.991-.105z"
            style={{ stroke: 'none' }}
            transform="translate(-4.169 .105)"
          />
          <path
            d="M15.99 1.395c-2.008 0-4.058.44-5.93 1.272A14.065 14.065 0 0 0 6.236 5.17c.835.852 1.912 1.871 2.965 2.867.635.6 1.25 1.182 1.804 1.719 1.06-.85 2.743-1.78 5.014-1.738 1.372.025 2.625.334 3.726.917.492.26.958.575 1.406.951 1.028-1.074 1.724-1.772 2.356-2.406.693-.696 1.315-1.32 2.241-2.3a14.386 14.386 0 0 0-4.007-2.594 14.39 14.39 0 0 0-5.75-1.191m0-1.5c4.313 0 8.663 1.755 11.84 5.251-2.967 3.207-3.245 3.274-6.648 6.885-1.214-1.416-2.76-2.468-5.191-2.513-2.432-.045-4.186 1.336-5 2.341-1.816-1.875-5.347-4.985-6.822-6.713C7.016 1.883 11.466-.105 15.991-.105z"
            style={{ fill: '#9e9e9e', stroke: 'none' }}
            transform="translate(-4.169 .105)"
          />
        </g>
      </svg>

      <svg className={`svg-down svg-down-${teethNumber} ${_.includes(props.position, getNameOfQuarterDown(props.quarter)) ? 'active' : ''} ${props.isSelected ? '' : 'pe-none'}`} ref={positionsRef['down']} data-value={getNameOfQuarterDown(props.quarter)} data-position='down' onClick={handleSelectPositionTeeth} xmlns="https://www.w3.org/2000/svg" width="23.661" height="12.137" viewBox="0 0 23.661 12.137">
        <g id="Default_down" className="cls-1" transform="translate(-4.169 0.105)">
          <path className="cls-2" d="M15.991,12.031A15.974,15.974,0,0,0,27.83,6.779c-2.968-3.206-3.245-3.273-6.648-6.885a6.36,6.36,0,0,1-5.191,2.6A6.563,6.563,0,0,1,10.939.107c-1.815,1.875-5.3,4.944-6.77,6.672A15.908,15.908,0,0,0,15.991,12.031Z" />
          <path
            style={{ fill: '#9e9e9e', stroke: 'none' }}
            d="M 15.99072551727295 10.53125095367432 C 17.97928619384766 10.53125095367432 19.91367530822754 10.13037109375 21.74015617370605 9.339740753173828 C 23.21858787536621 8.699771881103516 24.56158065795898 7.830135822296143 25.74701309204102 6.746716976165771 C 24.82059478759766 5.765628814697266 24.19908905029297 5.141878128051758 23.50566673278809 4.445940494537354 C 22.8793888092041 3.817393779754639 22.18990707397461 3.125425577163696 21.17639350891113 2.067230224609375 C 19.71347808837891 3.349984169006348 17.99908447265625 3.998338222503662 16.06094551086426 3.998400688171387 C 16.03265571594238 3.998400688171387 16.00455665588379 3.998260736465454 15.97597599029541 3.99798059463501 C 14.10696506500244 3.979589700698853 12.37000274658203 3.354533195495605 10.95215034484863 2.211213350296021 C 10.40614128112793 2.741369724273682 9.802136421203613 3.313481330871582 9.177895545959473 3.904760599136353 C 8.134016990661621 4.8935227394104 7.065316677093506 5.905792236328125 6.234917640686035 6.755124092102051 C 7.331089496612549 7.769460201263428 8.633249282836914 8.623608589172363 10.06056594848633 9.25869083404541 C 11.93160629272461 10.09121036529541 13.98222541809082 10.53125095367432 15.99072551727295 10.53125095367432 M 15.99072551727295 12.03125095367432 C 11.46640586853027 12.03125095367432 7.015625953674316 10.04297065734863 4.169435977935791 6.779300689697266 C 5.643785953521729 5.051520824432373 9.123536109924316 1.982420682907104 10.93896579742432 0.1074206233024597 C 11.95263576507568 1.308590650558472 13.60790538787842 2.474610567092896 15.99072551727295 2.498050689697266 C 18.37357330322266 2.52147388458252 20.0083065032959 1.351560711860657 21.18163681030273 -0.1054693758487701 C 24.58544540405273 3.505860567092896 24.8625659942627 3.57300066947937 27.83007621765137 6.779300689697266 C 24.65283584594727 10.275390625 20.30296516418457 12.03125095367432 15.99072551727295 12.03125095367432 Z"
          />
        </g>
      </svg>
    </Div>
  )
}
