export default function IconNotConnect(props: any) {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="12.638" height="13.348" viewBox="0 0 12.638 13.348">
            <g id="Group_5458" data-name="Group 5458" transform="translate(0 -.82)">
                <path id="Icon_feather-phone-call" data-name="Icon feather-phone-call" d="M14.438 11.455v1.7a1.133 1.133 0 0 1-1.238 1.133 11.214 11.214 0 0 1-4.89-1.74 11.05 11.05 0 0 1-3.4-3.4 11.214 11.214 0 0 1-1.74-4.913A1.133 1.133 0 0 1 4.3 3H6a1.133 1.133 0 0 1 1.133.975 7.276 7.276 0 0 0 .4 1.592 1.133 1.133 0 0 1-.255 1.2l-.72.72a9.067 9.067 0 0 0 3.4 3.4l.72-.72a1.133 1.133 0 0 1 1.2-.255 7.276 7.276 0 0 0 1.592.4 1.133 1.133 0 0 1 .968 1.143z" transform="translate(-3.168 -.125)" style={{ fill: props.color || '#fff' }} />
                <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="rotate(45 4.495 10.852)">
                    <path id="Path_5700" data-name="Path 5700" style={{ fill: 'none', stroke: props.color || '#fff', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }} d="M0 0v6.318" transform="translate(3.159)" />
                    <path id="Path_5701" data-name="Path 5701" style={{ fill: 'none', stroke: props.color || '#fff', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }} d="M0 0h6.318" transform="translate(0 3.159)" />
                </g>
            </g>
        </svg>
    )
}
