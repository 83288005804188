import { colors } from "constants/theme"

export default function DropdownTrashActive() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.2" height="19.2" viewBox="0 0 16.2 19.2">
            <g data-name="Icon feather-trash-2">
                <g data-name="Group 10714">
                    <path data-name="Path 6362" d="M4.5 9h16.2" transform="translate(-4.5 -4.8)" style={{ fill: 'none', stroke: colors.themeSecondColor, strokeLinejoin: 'round', strokeWidth: '1.2px' }} />
                    <path data-name="Path 6363" d="M20.1 6.6v12.6a1.8 1.8 0 0 1-1.8 1.8h-9a1.8 1.8 0 0 1-1.8-1.8V6.6m2.7 0V4.8A1.8 1.8 0 0 1 12 3h3.6a1.8 1.8 0 0 1 1.8 1.8v1.8" transform="translate(-5.699 -2.4)" style={{ fill: 'none', stroke: colors.themeSecondColor, strokeLinejoin: 'round', strokeWidth: '1.2px' }} />
                    <path data-name="Path 6364" d="M15 16.5v5.4" transform="translate(-8.699 -7.8)" style={{ fill: 'none', stroke: colors.themeSecondColor, strokeLinejoin: 'round', strokeWidth: '1.2px' }} />
                    <path data-name="Path 6365" d="M21 16.5v5.4" transform="translate(-11.1 -7.8)" style={{ fill: 'none', stroke: colors.themeSecondColor, strokeLinejoin: 'round', strokeWidth: '1.2px' }} />
                </g>
            </g>
        </svg>
    )
}
