import { useCallback, useEffect, useState } from 'react';
import { Box, Typography, MenuItem, Tooltip } from '@mui/material';
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { branchInfo, editBranchInfo } from 'app/slice/user.slice';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'

/** CONSTANT */
import { Icons } from 'constants/images';
import { colors } from 'constants/theme'
import { getBranch, numberFormat } from 'utils/app.utils';

/** STYLE */
import * as UseStyled from 'features/treatments/UseStyle'
import IconBack from 'assets/icon/iconBack';

import AboutUs from 'api/admin/clinic-manage/about.api';
import moment from 'moment';
import { KEY_STORAGE } from 'constants/common';

type FragmentFooterProps = {
  type?: 'Treatment' | 'Payment' | 'HistoryTreatment' | 'RetrospectiveOrder'
  tabActive?: string
  onExportOPD?: () => void
  onExportSSO?: () => void
  onExportMedicalCertificate?: () => void
  onExportMedicalCertificateFiveDisease?: () => void
  onExportMedicalCertificateDrivingLisense?: () => void
  onExportMedicalServices?: () => void
  onExportMedicalCertificate_A5?: () => void
  onExportReferralExamination?: () => void
  onExportReferralDental?: () => void
  onCompleteTreatment?: () => void
  onTreatmentDocument?: () => void
  onSave?: () => void
  onSendExam?: () => void
  onTreatmentConsent?: () => void
  onTreatmentClose?: () => void
  onPayment?: () => void
  onExportSticker?: () => void
  onPopupSelectProduct?: () => void
  onTreatmentFees?: () => void
  onOutStandingPayment?: () => void
  netTotal?: number
  netDiscount?: number
  netAdditional?: number
  disabled?: boolean
  patientName?: string
  showButtonPrintMedicineSticker: boolean
  patientCaseId: number
}

export default function FragmentFooter(props: FragmentFooterProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const branchesInfo = useSelector(branchInfo)

  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };


  const onCloseMenu = () => {
    setAnchorEl(null)
  }

  const loadPermissExport = useCallback(async () => {
    const branchId = getBranch()
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        dispatch(editBranchInfo({ value: { ...data } }))
        localStorage.setItem(KEY_STORAGE.CLINIC_INFO, JSON.stringify({ ...data, lastUpdate: moment().format('YYYY-MM-DD HH:mm') }))
      })
      .catch((e) => { return })
  }, [])

  useEffect(() => {
    loadPermissExport()
  }, [])

  return (
    <UseStyled.FooterAction className={`d-flex align-items-center justify-content-between position-fixed px-3 px-md-4 ${props.disabled ? 'fragment-footer-disabled pe-none' : ''}`}>
      <Box>
        <UseStyled.DropdownDocument>
          <Dropdown.Toggle id="dropdown-basic">
            <span className={`${props.type === 'Payment' ? 'd-none d-lg-inline-block' : 'd-none d-sm-inline-block'}`}>{t('REGISTER.BUTTON.PRINT_DOCUMENT')}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className={'w-50'}>
            {branchesInfo?.drugSticker === "1" && props.showButtonPrintMedicineSticker && (
              <Dropdown.Item onClick={props.onPopupSelectProduct}>
                {t('TREATMENTS.BUTTON.STICKER_MEDICINE')}
              </Dropdown.Item>
            )
            }
            {branchesInfo?.medicalServiceUsageForm === "1" &&
              props.type !== 'RetrospectiveOrder' && (
                <Tooltip placement={'right'} open={openTooltip} onOpen={handleOpenTooltip} onClose={handleCloseTooltip} title={<span style={{ color: 'white', fontSize: '14px' }}>{t('TREATMENTS.BUTTON.SSO')}</span>} >
                  <Dropdown.Item
                    className={'text-ellipsis overflow-hidden'}
                    onClick={() => {
                      handleCloseTooltip()
                      props?.onExportMedicalServices && props.onExportMedicalServices()
                    }}
                    onMouseDown={handleCloseTooltip} >
                    {t('TREATMENTS.BUTTON.SSO')}
                  </Dropdown.Item>
                </Tooltip>
              )
            }
            {/* new pdf branchesInfo?.dentistCertificateBlankEn === '1' */}
            {(branchesInfo?.doctorCertificate === "1" || branchesInfo?.dentistCertificateEn === '1' ||
              branchesInfo?.dentistCertificateBlank === "1") &&
              props.type !== 'RetrospectiveOrder' && (
                <Dropdown.Item onClick={props.onExportMedicalCertificate}>
                  {t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE')}
                </Dropdown.Item>
              )
            }
            {(branchesInfo?.doctorCertificateFor5Diseases === "1" || branchesInfo?.doctorCertificateFor5DiseasesEn === '1' ||
              branchesInfo?.doctorCertificateFor5DiseasesBlank === '1') &&
              props.type !== 'RetrospectiveOrder' && (
                <Dropdown.Item className={'text-ellipsis overflow-hidden'} onClick={props.onExportMedicalCertificateFiveDisease}>
                  {t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_5_DISEASE')}
                </Dropdown.Item>
              )
            }
            {(branchesInfo?.driverLicenseDoctorCertificate === "1" || branchesInfo?.driverLicenseDoctorCertificateEn === "1" ||
              branchesInfo?.driverLicenseDoctorCertificateBlank === "1") &&
              props.type !== 'RetrospectiveOrder' && (
                <Dropdown.Item className={'text-ellipsis overflow-hidden'} onClick={props.onExportMedicalCertificateDrivingLisense}>
                  {t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_DRIVING_LICENSE')}
                </Dropdown.Item>
              )
            }
            {(branchesInfo?.doctorCertificateA5 === "1" || branchesInfo?.doctorCertificateA5En === "1" ||
              branchesInfo?.doctorCertificateA5Blank === "1") &&
              props.type !== 'RetrospectiveOrder' && (
                <Dropdown.Item className={'text-ellipsis overflow-hidden'} onClick={props.onExportMedicalCertificate_A5}>
                  {t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_A5')}
                </Dropdown.Item>
              )
            }
            {branchesInfo?.pdfInvoice === '1' &&
              (props.type === 'Treatment' || props.type === 'Payment') &&
              <Dropdown.Item className={'text-ellipsis overflow-hidden'} onClick={props.onTreatmentConsent}>
                {t('TREATMENTS.BUTTON.TREATMENT_CONSENT')}
              </Dropdown.Item>
            }
            {branchesInfo?.referralPatient === "1" &&
              props.type !== 'RetrospectiveOrder' && (
                <Dropdown.Item onClick={props.onExportReferralExamination}>
                  {t('TREATMENTS.BUTTON.REFERRAL_EXAMINATION')}
                </Dropdown.Item>
              )
            }
            {
              branchesInfo?.dentalReferral === "1" && props.type !== 'RetrospectiveOrder' && (
                <Dropdown.Item onClick={props.onExportReferralDental}>
                  {t('TREATMENTS.BUTTON.REFERRAL_DENTAL')}
                </Dropdown.Item>
              )
            }
            {branchesInfo?.opdCardHospital === "1" &&
              <Dropdown.Item href="javascript:void(0)" onClick={props.onExportOPD}>
                {t('REGISTER.BUTTON.OPD_CARD_HOSPITAL')}
              </Dropdown.Item>
            }
            {branchesInfo?.socialSecurityCertificate === "1" &&
              <Dropdown.Item href="javascript:void(0)" onClick={props.onExportSSO}>
                {t('REGISTER.BUTTON.SSO')}
              </Dropdown.Item>
            }
          </Dropdown.Menu >
        </UseStyled.DropdownDocument >
      </Box >
      <Box className="d-flex align-items-center">

        {(props.type === 'Payment' || props.type === 'RetrospectiveOrder') && (
          <Box className='ml-2 ml-lg-3 mr-xl-2 d-none d-md-flex flex-column align-items-end text-right'>
            <Typography sx={{ fontSize: 20, fontWeight: 500, color: "rgba(176, 0, 32, 1)" }}>
              {t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')} <span className="pl-3">{numberFormat(props.netTotal || 0)} {t('CURRENCY_CHANGE.TH')}</span>
            </Typography>
            <Box sx={{ fontSize: 16, color: colors.themeMainColor }}>
              (<Typography className='d-inline-block' sx={{ color: colors.themeMainColor }}>
                <span className='d-none d-lg-inline'>{t('TREATMENT_SUMMARY.TITLE.NET')}</span>{t('TREATMENT_SUMMARY.TITLE.SUM_DISCOUNT')}
                <span className="pl-1 pl-lg-2">{numberFormat(props.netDiscount || 0)} {t('CURRENCY_CHANGE.TH')}</span>
              </Typography>
              <Typography className='d-inline-block pl-2 pl-lg-3' sx={{ color: colors.themeMainColor }}>
                <span className='d-none d-lg-inline'>{t('TREATMENT_SUMMARY.TITLE.NET')}</span>{t('TREATMENT_SUMMARY.TITLE.SUM_ADDON')}
                <span className="pl-1 pl-lg-2">{numberFormat(props.netAdditional || 0)} {t('CURRENCY_CHANGE.TH')}</span>
              </Typography>)
            </Box>
          </Box>
        )}

        {(props.type === 'Treatment' || props.type === 'Payment') && (
          <>
            <UseStyled.CustomButton
              id="btn-more"
              className={'btn-more d-md-none ml-2'}
              variant="outlined"
              aria-controls={open ? 'basic-menu-more' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
              disableRipple>
              <Icons.Meatball />
            </UseStyled.CustomButton>
            <UseStyled.MenuBtnMore
              id="basic-menu-more"
              anchorEl={anchorEl}
              open={open}
              onClose={onCloseMenu}
              onClick={onCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'btn-more'
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              sx={{ '.MuiPaper-root': { marginTop: '-5px' } }}>
              <MenuItem className={branchesInfo?.pdfInvoiceTreatment !== '0' ? 'd-flex' : 'd-none'} onClick={props.onTreatmentFees}>
                {t('PDF_INVOICE.HEADER')}
              </MenuItem>
              <MenuItem className={props.type === 'Treatment' ? 'd-flex' : 'd-none'} onClick={props.onSendExam}>
                {t('TREATMENTS.BUTTON.CONTINUE_EXAMINATION')}
              </MenuItem>
            </UseStyled.MenuBtnMore>
          </>
        )}

        {props.type !== 'RetrospectiveOrder' && <ButtonCustom variant="outlined" className={'ml-2 ml-lg-3 d-none d-md-flex'} onClick={props.onTreatmentClose} startIcon={<IconBack />} textButton={props.type === 'Payment' ? t('TREATMENTS.BUTTON.PATMENT_PAGE') : t('TREATMENTS.BUTTON.QUEUE_PAGE')} style={{ margin: 0 }} />}

        {/* {props.type === 'Treatment' && (
          <ButtonCustom variant="outlined" className={'ml-2 ml-lg-3 d-none d-md-flex'} onClick={props.onTreatmentConsent} textButton={t('TREATMENTS.BUTTON.TREATMENT_CONSENT')} style={{ margin: 0 }} />
        )} */}

        {/* {branchesInfo?.pdfInvoiceTreatment !== '0' &&
          (props.type === 'Treatment' || props.type === 'Payment') &&
          <ButtonCustom
            variant="outlined"
            className={'ml-2 ml-lg-3 d-none d-md-flex'}
            onClick={props.onTreatmentFees}
            textButton={t('PDF_INVOICE.HEADER')}
            style={{ margin: 0 }}
          />
        } */}

        {(props.type === 'Payment' || props.type === 'Treatment') &&
          <Box className={`ml-2 ml-lg-3 d-none d-md-flex`}>
            <UseStyled.DropdownDocument>
              <Dropdown.Toggle id="dropdown-basic">
                <span >{t('เอกสารสำคัญ')}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={'w-50'}>
                {branchesInfo?.pdfInvoiceTreatment !== '0' &&
                  (props.type === 'Treatment' || props.type === 'Payment') &&
                  <Dropdown.Item onClick={props.onTreatmentFees}>
                    {t('PDF_INVOICE.HEADER')}
                  </Dropdown.Item>
                }
                {(props.type === 'Payment' && branchesInfo?.outStandingPayment !== '0') &&
                  <Dropdown.Item onClick={() => props.onOutStandingPayment && props.onOutStandingPayment()}>
                    {t('MODAL_OUTSTANDINGPAYMENT.PDF')}
                  </Dropdown.Item>
                }
              </Dropdown.Menu>
            </UseStyled.DropdownDocument>
          </Box>
        }

        {props.type !== 'RetrospectiveOrder' && (
          <ButtonCustom variant="outlined" className={`ml-2 ml-lg-3 ${props.type === 'Treatment' ? 'd-none' : ''} d-md-flex`} onClick={props.onSendExam} textButton={t('TREATMENTS.BUTTON.CONTINUE_EXAMINATION')} style={{ margin: 0 }} />
        )}

        {props.type !== 'RetrospectiveOrder' && (
          <ButtonCustom variant="outlined" className={'ml-2 ml-lg-3'} onClick={props.onSave} textButton={t('BUTTON.SAVE')} style={{ margin: 0 }} />
        )}

        {props.type === 'Treatment' && (
          <ButtonCustom
            className={'ml-2 ml-lg-3'}
            onClick={props.onCompleteTreatment}
            textButton={
              <>
                <span className="d-md-none">{t('TREATMENTS.BUTTON.COMPLETE')}</span>
                <span className="d-none d-md-flex">{t('TREATMENTS.BUTTON.COMPLETE_TREATMENT')}</span>
              </>
            }
            style={{ margin: 0 }}
          />
        )}

        {(props.type === 'Payment' || props.type === 'RetrospectiveOrder') && (
          <ButtonCustom
            className={'ml-2 ml-lg-3 btn-payment'}
            onClick={props.onPayment}
            textButton={t('TREATMENTS.BUTTON.PAYMENT')}
            style={{ margin: 0 }}
          />
        )}
      </Box>
    </UseStyled.FooterAction >
  )
}
