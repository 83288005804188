import { styled } from '@mui/material/styles'

/** ICON */
import IconDeleteBg from 'assets/button/btn-delete-bg.svg'
import IconDeleteReg from 'assets/button/btn-delete-reg.svg'

const CustomImg = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  ':hover': { opacity: 1 }
}))

interface IButtonDeleteProps {
  onClick?: (e: any) => void
  disabled?: boolean
}

export function ButtonDeleteSimple(props: IButtonDeleteProps) {
  return <CustomImg src={IconDeleteBg} alt="delete" onClick={props?.onClick} />
}

export function ButtonDeleteReg(props: IButtonDeleteProps) {
  return <img src={IconDeleteReg} alt="delete" onClick={props?.onClick} style={{ cursor: 'pointer' }} />
}
