import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useFieldArray, useForm } from 'react-hook-form'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import { CustomTable, NetPrice } from 'features/finance/salary/style';
import { colors } from 'constants/theme'
import { Divider, Box } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile'

/** UTILS */
import { dateToView, fullDateToView } from 'utils/date.utils'

/** API */
import { routeName } from 'routes/routes-name'
import DentistCostsApi from 'api/finances/dentist-cost.api'

/** COMPONENT */
import { Card, Col, Row } from 'react-bootstrap'
import SalaryTableRow from 'features/finance/salary/salary-TableRow'
import StatusSelect from 'features/finance/income-expenses/statusSelector-income-expenses'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { fileUrl } from 'api/api'
import TableCustom from 'component/Table/TableCustom'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import { isBuddhistYear } from 'utils/app.utils';

type FormValuesType = {
  branchId: any
  paymentChannels: any
  paymentChanelId: any
  documentNo: string
  userId: any
  documentDate: any
  documentStatus: string
  monthlyTemplate: any
  cost: number
  total: number
  dentistCostItems: any[]
}

interface TypeItemsInterface {
  dentistCostItemId?: number
  dentistCostItemFile?: string
  itemName: string
  userId: number
  paidDate: string
  paymentChannelId: number
  cost: number
  updatedBy: any
  updatedAt: any
  file: any
}

export default function ViewSalary() {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const [dentistCostId, setDentistCostId] = useState(Number)
  const [branchId, setBranchId] = useState(Number)
  const [prefix, setPrefix] = useState('')
  const [documentNo, setDocumentNo] = useState('')
  const [documentDate, setDocumentDate] = useState('')
  const [documentStatus, setDocumentStatus] = useState('')
  const [monthly, setMonthly] = useState('')
  const [remark, setRemark] = useState('')
  const [totalSummery, setTotalSummery] = useState(0)
  const [logs, setLogs] = useState([])
  const [items, setItems] = useState<TypeItemsInterface[]>([])
  const [docStatusBgColor, setDocStatusBgColor] = useState('')

  /** PAGINATION  */
  const page = 1
  const pageLimit = 10

  const { control, setValue } = useForm<FormValuesType>({
    defaultValues: {
      branchId: branchId
    }
  })

  const formDentistCostItems = useFieldArray({
    control,
    name: 'dentistCostItems'
  })

  const documentNoId = prefix + documentNo
  let branchText = ''
  if (branchId === 1) {
    branchText = 'สำนักงานใหญ่'
  } else if (branchId === 2) {
    branchText = 'สำนักงานใหญ่2'
  } else {
    branchText = '-'
  }
  const branchElement = branchText

  const onEdit = () => history.push(routeName.salary + '/update', location.state)

  const handleChangeStatus = async (newStatus: string) => {
    setDocumentStatus(newStatus)
    await DentistCostsApi.updateDocumentStatus(dentistCostId, { documentStatus: newStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t('SALARY.MESSAGE.SUCCESS.UPDATED'), 'go', routeName.salary)
        } else {
          const err = res.response.data
          notiError(err.message)
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(err.message)
      })
  }

  const changeStatusSelectBgColor = () => {
    if (documentStatus === 'PENDING_APPROVAL') {
      setDocStatusBgColor(colors.approveStatus.pending)
    } else if (documentStatus === 'APPROVAL') {
      setDocStatusBgColor(colors.approveStatus.approve)
    } else if (documentStatus === 'UNAPPROVAL') {
      setDocStatusBgColor(colors.approveStatus.cancel)
    } else {
      setDocStatusBgColor(colors.disabledLightGray)
    }
  }

  useEffect(() => {
    changeStatusSelectBgColor()
  }, [documentStatus])

  const updateText = (val: any) => {
    let action = ''

    if (val.action === 'UPDATED') action = t('SALARY.DOCUMENT_STATUS.UPDATED')
    else if (val.action === 'CREATED') action = t('SALARY.CREATE_DOCUMENT_SALARY')
    else if (val.action === 'UPDTAED_DOCUMENT_STATUS:APPROVAL') action = t('SALARY.DOCUMENT_STATUS.APPROVAL')
    else if (val.action === 'UPDTAED_DOCUMENT_STATUS:UNAPPROVAL') action = t('SALARY.DOCUMENT_STATUS.UNAPPROVAL')
    else if (val.action === 'UPDTAED_DOCUMENT_STATUS:PENDING_APPROVAL') action = t('SALARY.DOCUMENT_STATUS.PENDING_APPROVAL')
    const originalDate = moment(val.updatedAt);
    const newDate = originalDate.add(isBuddhistYear(), 'years');
    const formattedDate = newDate.format('LLLL');
    return (
      <>
        {action} : {val.updatedBy} : {formattedDate}
      </>
    )
  }

  const loadDentistCostByid = async (valueDentistCostId: any) => {
    const { status, data }: any = await DentistCostsApi.findById(valueDentistCostId)
    if (status === 200) {
      setValue('branchId', data.branchId)
      const temp: TypeItemsInterface[] = data.dentistCostItems.map((dct: any) => {
        return {
          dentistCostItemId: dct.dentistCostItemId,
          dentistCostItemFile: dct.dentistCostItemFile,
          itemName: dct.itemName,
          userId: dct.userId,
          paidDate: dct.paidDate,
          paymentChannelId: dct.paymentChannelId,
          paymentChannelName: dct.paymentChannelName,
          paymentChannelNameEn: dct.paymentChannelNameEn,
          userFullname: dct.userFullname,
          cost: dct.cost,
          updatedBy: dct.updatedBy,
          updatedAt: dct.updatedAt,
          file: dct.file
        }
      })

      setItems(temp)
      setPrefix(data.prefix)
      setDentistCostId(data.dentistCostId)
      setBranchId(data.branchId)
      setDocumentNo(data.documentNo)
      setDocumentDate(data.documentDate)
      setDocumentStatus(data.documentStatus)
      setMonthly(data.monthly)
      setRemark(data.remark)
      setTotalSummery(data.total)
      setLogs(JSON.parse(data.logs))

      if (data.remark) {
        setRemark(data.remark)
      } else {
        setRemark('-')
      }

      for (const index in data.dentistCostItems) {
        const item = data.dentistCostItems[index]
        formDentistCostItems.prepend(
          {
            dentistCostItemId: item.dentistCostItemId,
            dentistCostItemFile: item.dentistCostItemFile,
            itemName: item.itemName,
            userId: item.userId,
            paidDate: item.paidDate,
            paymentChannelId: item.paymentChannelId,
            cost: item.cost,
            updateBy: item.updateBy,
            updatedAt: item.updatedAt,
            file: item.file
          },
          { focusIndex: 1 }
        )
      }
    }
  }

  useEffect(() => {
    if (location.state) {
      const obj: any = location.state
      loadDentistCostByid(obj.dentistCostId)
    }
  }, [])

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '80px' },
    { id: 'message', disablePadding: false, label: t('ชื่อรายการ') },
    { id: 'message', disablePadding: false, label: t('ชื่อพนักงาน') },
    { id: 'message', disablePadding: false, label: t('วันที่จ่าย') },
    { id: 'message', disablePadding: false, label: t('ช่องทางการจ่าย') },
    { id: 'message', disablePadding: false, label: t('จำนวนเงิน(บาท)'), align: 'right' },
    { id: 'message', disablePadding: false, label: t('ไฟล์แนบ') },
    { id: 'message', disablePadding: false, label: t('ผู้บันทึก'), align: 'center' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { dentistCostItemId, itemName, userFullname, paidDate, paymentChannelName, cost, file, updatedBy, updatedAt } = objData

    const formatDate = new Date(paidDate)
    const resultPaidDate = formatDate.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })

    const numb = cost
    const numbCost = numb.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    const objRenderData = {
      key: dentistCostItemId,
      id: dentistCostItemId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: itemName },
        { option: 'TEXT', align: 'left', label: userFullname },
        { option: 'TEXT', align: 'left', label: resultPaidDate },
        { option: 'TEXT', align: 'left', label: paymentChannelName },
        { option: 'TEXT', align: 'right', label: numbCost },
        {
          option: 'COMPONENT',
          align: 'left',
          component: file ? (
            <Box onClick={() => window.open(`${fileUrl}/${file.filePath}/${file.fileName}`, '_blank')} className='text-ellipsis cursor-pointer'>
              <AttachFileIcon className="text-dark align-text-top mr-1" style={{ transform: 'rotate(45deg)', fontSize: '22px' }} />
              {file.fileName}
            </Box>
          ) : (
            '-'
          )
        },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } }
      ]
    }

    return <SalaryTableRow {...objRenderData} />
  }

  return (
    <div className={`container-card overflow-hidden`}>
      <div className='d-flex flex-column h-100 overflow-auto mb-4' style={{ backgroundColor: colors.white }}>
        <HeaderLabel
          text={t('SALARY.TITLE_VIEW')}
          menuNavigation={
            <>
              <Link to={routeName.salary} className={'m-0 p-0 link-nav w-100'}>
                {t('SALARY.TITLE')}
              </Link>
              {` > ${t('SALARY.TITLE_VIEW')}`}
            </>
          }
          inputStatus={
            <>
              {dentistCostId ? (
                <Col sm={'auto'} className="" style={{ marginTop: '18px', marginRight: '18px' }}>
                  <StatusSelect
                    onchange={(event) => {
                      const value = event.target.value
                      if (value) {
                        handleChangeStatus(value)
                      }
                    }}
                    label={''}
                    selectId="select-status"
                    value={documentStatus}
                    labelId="label-status"
                    statusSelectorBgColor={docStatusBgColor}
                  />
                </Col>
              ) : (
                <></>
              )}
            </>
          }
          submitText={t('SALARY.BUTTON.EDIT')}
          onSubmit={() => onEdit()}
          submitTheme='outlined'
        />

        <div style={{ backgroundColor: colors.white }}>
          <form>
            <Card className="border shadow-sm mx-4">
              <Card.Body className="h-100">
                <Row>
                  <Box sx={{ width: '100%', typography: 'body1' }}>
                    <div className="text-right">
                      {t('SALARY.TEXT.DOCUMENT_NO')} {documentNoId || '-'}
                    </div>
                    <Row>
                      <Col xs={6} sm={5} md={3}>
                        {t('SALARY.TEXT.DOCUMENT_DATE')}
                      </Col>
                      <Col xs={6} sm={5} md={3}>
                        {dateToView(documentDate)}
                      </Col>
                      <Col xs={6} sm={5} md={3}>
                        {t('SALARY.TEXT.BRANCH')}
                      </Col>
                      <Col xs={6} sm={5} md={3}>
                        {branchElement}
                      </Col>
                      <Col xs={6} sm={5} md={3}>
                        {t('SALARY.TEXT_INPUT.MONTHLY')}
                      </Col>
                      <Col xs={6} sm={5} md={9}>
                        {fullDateToView(monthly)}
                      </Col>
                    </Row>
                  </Box>
                </Row>
                <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                <div className="mx-auto" style={{ fontSize: '18px', fontWeight: 500, color: colors.themeMainColor }}>
                  {t('SALARY.TEXT.SALARY')}
                </div>
                <CustomTable
                  className='mt-3'
                  sx={{
                    '.MuiTableContainer-root': {
                      minHeight: 'unset !important'
                    }
                  }}>
                  <TableCustom
                    hidePagination
                    page={0}
                    pageLimit={0}
                    sortType={'ASC'}
                    sortBy={''}
                    rowCount={0}
                    textEmptyData={t('REGISTER.NO_ITEM')}
                    onSort={() => { return }}
                    setPageLimit={() => { return }}
                    setPage={() => { return }}
                    headCells={headCells}
                    rowsData={items.map((val, i) => {
                      return renderData(val, i)
                    })}
                    tableFixedWidth
                    tableMinWidth={1200}
                  />
                </CustomTable>
                <NetPrice className={`d-flex align-items-center justify-content-between justify-content-md-end mt-3 px-3 px-md-4`}>
                  {`${t('SALARY.TEXT_INPUT.TOTAL')}: ${Intl.NumberFormat('th-TH', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(totalSummery)} ${t('SALARY.TEXT_INPUT.BAHT')}`}
                </NetPrice>
                <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                <div className="mx-auto">
                  {t('SALARY.TEXT.REMARKS')} {remark}
                </div>
              </Card.Body>
            </Card>

            <div className="mt-4 ml-4" style={{ fontSize: '18px', fontWeight: 500, color: colors.themeMainColor }}>
              {t('SALARY.TEXT.EDIT_HISTORY')}
            </div>
            {logs.map((val: any, i) => (
              <>
                <div key={i} className="mt-2 mx-5">
                  <p className="mb-0">- {updateText(val)}</p>
                </div>
              </>
            ))}
          </form>
        </div>
      </div>
    </div>
  )
}
