import appointment from 'locales/dental/th/counter/appointment.json'
import doctorSchedule from 'locales/dental/th/counter/doctor-schedule.json'
import patientManagement from 'locales/dental/th/counter/patient-management.json'
import register from 'locales/dental/th/counter/register.json'
import recallLists from 'locales/dental/th/counter/recallLists.json'
import manageDoctorFee from 'locales/dental/th/counter/manage-doctor-fee.json'
import manageDoctorLabFee from 'locales/dental/th/counter/manage-doctor-lab-fee.json'

export const Counter = {
  ...appointment,
  ...doctorSchedule,
  ...patientManagement,
  ...register,
  ...recallLists,
  ...manageDoctorFee,
  ...manageDoctorLabFee
}
