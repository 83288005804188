import MenuItem from '@mui/material/MenuItem'
import { useHistory } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { colors } from 'constants/theme'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import { makeStyles } from '@material-ui/core'

/** API */
import { routeName } from 'routes/routes-name'
import IncomeExpensesApi from 'api/finances/income-expenses.api'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { dateToView } from 'utils/date.utils'
import { numberFormat } from 'utils/app.utils'

/** COMPONENT */
import { Card, Col, Row } from 'react-bootstrap'
import HeaderCard from 'component/CardCustom/HeaderCard'
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputTextSearch from 'component/Input/InputTextSearch'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'

import TableCustom from 'component/Table/TableCustom'
import FilterSelect from 'component/Select/FilterSelect'
import ButtonExport from 'component/Button/ButtonExport'
import TableRowExport from 'component/Pdf/TableRowExport'
import { DropdownItem } from './style'
import StatusSelect from './statusSelector-income-expenses'
import { selectMe } from 'app/slice/user.slice'
import { useSelector } from 'react-redux'
import { height } from 'pdfkit/js/page';

const permissions = {
  isView: isView(),
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate()
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: 'calc(100% - 80px)'
  }
}))

export default function IncomeExpenses() {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  let docStatusBgColor = ''
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')
  const [rowCount, setRowCount] = useState(0)
  const [pageLimit, setPageLimit] = useState(10)
  const [sortType, setSortType] = useState('ASC')
  const [sortBy, setSortBy] = useState('incomeExpenseId')
  const [incomeExpenses, setIncomeExpenses] = useState([])
  const [exportData, setExportData] = useState([])

  const headCells = [
    { id: 'id', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.ID'), align: 'center', width: '80px' },
    { id: 'documentNo', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.DOCUMENT_NO') },
    { id: 'documentCreationDate', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.DOCUMENT_CREATION_DATE'), width: '180px' },
    { id: 'expenseType', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.EXPENSE_TYPE'), width: '150px' },
    { id: 'total', disablePadding: false, align: 'right', label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.TOTAL'), width: '180px' },
    { id: 'documentStatus', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.DOCUMENT_STATUS'), width: '180px' },
    { id: 'updatedBy', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.CREATED_BY'), width: '180px' },
    { id: 'action', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.ACTION'), width: '150px' }
  ]

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, documentStatus: status === 'ALL' ? '' : status }

    const res = await IncomeExpensesApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setIncomeExpenses(res.data)
    }
  }, [page, pageLimit, sortType, sortBy, search, status])



  const loadDataForExport = useCallback(async () => {
    let condition: any = {}
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, documentStatus: status === 'ALL' ? '' : status }

    const res = await IncomeExpensesApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  const renderData = (objData: any, no: number, exportDataValue: boolean) => {
    const num = exportDataValue === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { incomeExpenseId, documentNo, documentDate, expenseGroup, total, documentStatus, updatedBy, updatedAt, prefix } = objData

    const documentExpenseNo = prefix + documentNo


    let documentStatusText = ''
    if (documentStatus === 'PENDING_APPROVAL') {
      docStatusBgColor = colors.approveStatus.pending
      documentStatusText = t('INCOME_EXPENSES.DOCUMENT_STATUS.PENDING_APPROVAL')
    } else if (documentStatus === 'APPROVAL') {
      docStatusBgColor = colors.approveStatus.approve
      documentStatusText = t('INCOME_EXPENSES.DOCUMENT_STATUS.APPROVAL')
    } else if (documentStatus === 'UNAPPROVAL') {
      docStatusBgColor = colors.approveStatus.cancel
      documentStatusText = t('INCOME_EXPENSES.DOCUMENT_STATUS.UNAPPROVAL')
    } else if (documentStatus === 'CANCEL') {
      docStatusBgColor = colors.approveStatus.cancel
      documentStatusText = t('INCOME_EXPENSES.DOCUMENT_STATUS.CANCEL')
    } else {
      docStatusBgColor = 'transparent'
      documentStatusText = '-'
    }

    let expenseGroupText
    if (expenseGroup === 'INCOME') {
      expenseGroupText = t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.INCOME.TITLE')
    } else if (expenseGroup === 'EXPENSE') {
      expenseGroupText = t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.EXPENSE.TITLE')
    } else {
      return
    }

    const objRenderData = {
      key: incomeExpenseId,
      id: incomeExpenseId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'left', label: documentExpenseNo },
        { option: 'TEXT', align: 'left', label: dateToView(documentDate) },
        { option: 'TEXT', align: 'left', label: expenseGroupText },
        { option: 'TEXT', align: 'right', label: numberFormat(total) },
        {
          option: 'COMPONENT', align: 'left', class: 'py-1', component: exportDataValue ? (<>{documentStatusText}</>) : (
            documentStatus === 'APPROVAL' ?
              <Box sx={{
                borderRadius: '4px',
                backgroundColor: docStatusBgColor,
                color: colors.white,
                textDecoration: 'none',
                height: '34px',
                alignContent: 'center',
                textAlign: 'center',
              }}>
                <span>{documentStatusText}</span></Box> :
              <StatusSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    handleChangeStatus(value, incomeExpenseId)
                  }
                }}
                label={''}
                selectId="select-status"
                value={documentStatus}
                labelId="label-status"
                statusSelectorBgColor={docStatusBgColor}
                disable={false}
                height='34px'
                permissionDenied={user.hasApproveIncomeAndExpenses === 'PERMISS'}
              />
          )
        },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            {
              option: 'COMPONANT', label: (
                <DropdownItem disabled={documentStatus === 'CANCEL'} onClick={() => onChangeStatus(objRenderData.obj, 'CANCEL')} >
                  <span className="ml-2 align-text-bottom">{t(`BUTTON.CANCEL`)}</span>
                </DropdownItem>
              ),
            },
            {
              option: 'COMPONANT', label: (
                <DropdownItem disabled={documentStatus === 'PENDING_APPROVAL'} onClick={() => onChangeStatus(objRenderData.obj, 'PENDING_APPROVAL')} >
                  <span className="ml-2 align-text-bottom">{t(`BUTTON.RESET`)}</span>
                </DropdownItem>
              ),
            },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: user.hasApproveIncomeAndExpenses !== 'PERMISS' ? true : permissions.isUpdate.disabled ? true : documentStatus === 'APPROVAL' ? true : false },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled ? true : documentStatus === 'APPROVAL' ? true : false }
          ]
        }
      ]
    }

    return (
      <>
        {exportDataValue === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon {...objRenderData}
            onedit={() => onEdit(objRenderData.obj)}
            ondelete={() => onDelete(objRenderData.obj)}
            rowLink={true} onClick={(e, val) => onView(e, val)} />
        }
      </>
    )
  }

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  const handleChangeStatus = async (statusVal: string, id?: number) => {
    if (id) {
      const res = await IncomeExpensesApi.updateDocumentStatus(id, statusVal)
      if (res.status === 200) {
        loadData()
        notiSuccess(t('INCOME_EXPENSES.MESSAGE.SUCCESS.UPDATE_STATUS'))
      } else {
        notiError(t('INCOME_EXPENSES.MESSAGE.ERROR'))
      }
    } else {
      setStatus(statusVal)
    }
  }


  const handleChangePage = (val: number) => setPage(val)

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const onCreate = () => history.push(routeName.incomeExpenses + '/create')

  const onEdit = (data: any) => {
    const { incomeExpenseId } = data
    history.push(`${routeName.incomeExpenses}/update/${incomeExpenseId}`)
  }

  const onView = (e: any, data: any) => {
    const { incomeExpenseId } = data
    history.push(`${routeName.incomeExpenses}/view/${incomeExpenseId}`)
  }

  const onDelete = (data: any) => {
    const { incomeExpenseId, prefix, documentNo } = data

    const documentId = prefix + documentNo

    swalActive(
      t('INCOME_EXPENSES.ALERT.CONFIRM_DELETE'),
      t('INCOME_EXPENSES.ALERT.CONFIRM_DELETE_', {
        documentId: documentId
      }),
      (res: any) => {
        if (res) {
          IncomeExpensesApi.remove(incomeExpenseId)
            .then((resp) => {
              notiSuccess(t('INCOME_EXPENSES.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('INCOME_EXPENSES.MESSAGE.ERROR'))
            })
            .finally(() => {
              return
            })
        }
      },
      undefined,
      t('BUTTON.SUBMIT'),
      t('BUTTON.CANCEL')
    )
  }

  const onChangeStatus = (data: any, status: 'PENDING_APPROVAL' | 'CANCEL') => {
    const { incomeExpenseId, prefix, documentNo } = data

    const documentId = prefix + documentNo

    let textUpdate = 'RESET'
    if (status === 'CANCEL') textUpdate = 'CANCEL'

    swalActive(
      t(`INCOME_EXPENSES.ALERT.CONFIRM_${textUpdate}`),
      t(`INCOME_EXPENSES.ALERT.CONFIRM_${textUpdate}_`, { documentId }),
      (res: any) => {
        if (res) {
          IncomeExpensesApi.updateDocumentStatus(incomeExpenseId, status).then((respone) => {
            if (respone.status === 200) {
              notiSuccess(t('INCOME_EXPENSES.MESSAGE.SUCCESS.UPDATE'), '', null, () => {
                loadData()
                loadDataForExport()
              })
            } else {
              notiError('Error Status: 400')
            }
          })

        }
      },
      undefined,
      t('BUTTON.SUBMIT'),
      t('BUTTON.CANCEL')
    )
  }

  useEffect(() => {
    loadData()
  }, [loadData])

  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('INCOME_EXPENSES.DOCUMENT_STATUS_LABEL')}: ${t(`INCOME_EXPENSES.DOCUMENT_STATUS.${status}`)}` },
  ]

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('INCOME_EXPENSES.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col sm={6} md={4} lg={4} xl={3} className="mt-3 mt-sm-0">
                <InputTextSearch
                  keyInput={'search'}
                  label={t('INCOME_EXPENSES.INPUT.MAIN_SEARCH')}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                    setPage(1)
                  }}
                  onClear={(event) => {
                    setSearch('')
                    setPage(1)
                  }}
                />
              </Col>
              <Col sm={6} md={4} lg={3} className="mt-3 mt-sm-0">
                <FilterSelect
                  onchange={(event) => {
                    const value = event.target.value
                    if (value) {
                      handleChangeStatus(value)
                      setPage(1)
                    }
                  }}
                  label={t('INCOME_EXPENSES.INPUT.STATUS_SELECTOR')}
                  selectId="select-status"
                  renderValue={() => `${t('INCOME_EXPENSES.DOCUMENT_STATUS_LABEL')}: ${t(`INCOME_EXPENSES.DOCUMENT_STATUS.${status}`)}`}
                  value={status}
                  labelId="label-status"
                  options={[
                    <MenuItem key="1" value="ALL" style={{ display: 'list-item', padding: '0px 5px 5px 10px' }}>
                      {t('INCOME_EXPENSES.DOCUMENT_STATUS.ALL')}
                    </MenuItem>,
                    <MenuItem key="2" value="PENDING_APPROVAL" style={{ display: 'list-item', padding: '0px 5px 5px 10px' }}>
                      {t('INCOME_EXPENSES.DOCUMENT_STATUS.PENDING_APPROVAL')}
                    </MenuItem>,
                    <MenuItem key="3" value="APPROVAL" style={{ display: 'list-item', padding: '0px 5px 5px 10px' }}>
                      {t('INCOME_EXPENSES.DOCUMENT_STATUS.APPROVAL')}
                    </MenuItem>,
                    <MenuItem key="4" value="UNAPPROVAL" style={{ display: 'list-item', padding: '0px 5px 5px 10px' }}>
                      {t('INCOME_EXPENSES.DOCUMENT_STATUS.UNAPPROVAL')}
                    </MenuItem>,
                    <MenuItem key="5" value="CANCEL" style={{ display: 'list-item', padding: '0px 5px 5px 10px' }}>
                      {t('INCOME_EXPENSES.DOCUMENT_STATUS.CANCEL')}
                    </MenuItem>
                  ]}
                />
              </Col>
              <Col md={12} lg={5} xl={6} className="mt-3 mt-xl-0">
                <Box className='d-flex flex-column flex-sm-row align-items-end align-items-sm-center justify-content-end pr-2'>
                  <Box className='mb-3 mb-sm-0 pr-sm-3'>
                    <ButtonExport headCells={headCells} portrait={false} filter={filter} fileName={t('INCOME_EXPENSES.TITLE')}
                      rowsData={exportData.map((val, i) => renderData(val, i, true))} />
                  </Box>
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('INCOME_EXPENSES.BUTTON.ADD')} style={{ margin: '0px' }} />
                </Box>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortBy={sortBy}
              sortType={sortType}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={incomeExpenses.map((val, i) => {
                return renderData(val, i, false)
              })}
              tableFixedWidth
              tableMinWidth={1330}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
