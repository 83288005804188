import { Box, Typography } from '@mui/material'

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

const colorBox = {
  sales: colors.dashboard.summaryBox.sales,
  salesMarginUp: colors.dashboard.summaryBox.salesMarginUp,
  salesMarginDown: colors.dashboard.summaryBox.salesMarginDown,
  service: colors.dashboard.summaryBox.service,
  customer: colors.dashboard.summaryBox.customer
}

export const SummaryBox = styled(Box)<{ type: 'sales' | 'sales_margin_up' | 'sales_margin_down' | 'service' | 'customer' }>(({ theme, type }) => ({
  height: '100%',
  position: 'relative',
  boxShadow: `0 0 6px 0 ${colors.black10}`,
  borderRadius: 8,
  minHeight: 176,
  background: (type === 'sales' && colorBox.sales) || (type === 'sales_margin_up' && colorBox.salesMarginUp) || (type === 'sales_margin_down' && colorBox.salesMarginDown) || (type === 'service' && colorBox.service) || colorBox.customer,
  padding: 16,
  img: {
    position: 'absolute',
    right: 20,
    bottom: 24,
    opacity: type !== 'service' ? .3 : 1,
    transform: type === 'sales_margin_up' ? 'rotate(180deg)' : 'rotate(0deg)'
  },
  [theme.breakpoints.up('xl')]: {
    padding: 24,
  },
  [theme.breakpoints.down('xl')]: {
    flexDirection: type === 'sales' || type === 'sales_margin_down' || type === 'sales_margin_up' ? 'column' : 'row',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    '> .MuiBox-root': {
      '&:nth-of-type(2)': {
        flexDirection: type === 'sales' || type === 'sales_margin_down' || type === 'sales_margin_up' ? 'row !important' : 'column',
        '.MuiBox-root': {
          marginTop: type === 'sales' || type === 'sales_margin_down' || type === 'sales_margin_up' ? 'auto' : 0,
          '&:nth-of-type(2)': {
            paddingLeft: '16px'
          }
        }
      }
    }
  }
}))

export const Card = styled(Box)(({ theme }) => ({
  backgroundColor: colors.white,
  border: `1px solid ${colors.extraLightGray}`,
  boxShadow: `0 3px 6px 0 ${colors.black16}`,
  borderRadius: 4,
  height: '100%',
  '.MuiTypography-root': {
    color: colors.textPrimary
  },
  '.MuiAvatar-root': {
    width: '48px',
    height: '48px',
    [theme.breakpoints.down('xs')]: {
      width: '38px',
      height: '38px',
    }
  }
}))

export const Title = styled(Typography)<{ type?: 'header' | 'title' }>(({ theme, type }) => ({
  color: colors.textPrimary,
  fontSize: type === 'header' ? 20 : 16,
  fontWeight: 500,
}))

export const DoughnutChart = styled(Box)<{ type: 'operative' | 'payment' }>(({ theme, type }) => ({
  [theme.breakpoints.down('xs')]: {
    maxWidth: '300px'
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: type === 'operative' ? '55%' : '80%'
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '80%'
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: type === 'operative' ? '55%' : '80%'
  }
}))

export const DoughnutDataInside = styled(Box)(({ theme }) => ({
  top: '50%',
  left: 30,
  right: 30,
  transform: 'translateY(-50%)'
}))

export const LabelColor = styled(Box)<{ color: string }>(({ theme, color }) => ({
  flex: 'none',
  width: 16,
  height: 16,
  borderRadius: 4,
  backgroundColor: color
}))

export const EmptyDoughnutChart = styled(Box)(({ theme }) => ({
  background: colors.transparent,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: '100%',
  border: `30px solid ${colors.lightGray}`
}))