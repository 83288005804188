import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** ICON || IMAGE */
import iconEdit from 'assets/icon/iconEdit.svg'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'

import { Col, Row } from 'react-bootstrap'
import { resetModal } from 'app/slice/modal.slice'

import InputTextField from 'component/Input/InputTextField'



/** INTERFACE */
export interface SaveProps {
    isShow: boolean
    canvasRef?: any
    doctorNoteDetail?: any
    vnNumber?: any
    onInsert?: (img: HTMLImageElement) => void
    onCancel?: () => void
    onSubmit: (name: string) => void
}

export function SavePopup(props: SaveProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()




    const [editName, setEditName] = useState<boolean>(true)
    const [name, setName] = useState<any>()



    useEffect(() => {
        if (props.doctorNoteDetail) {
            setName(props.doctorNoteDetail?.doctorNoteName)
        } else {
            setName("New Note")
        }
    }, [props.doctorNoteDetail])

    const handleEditName = (obj: any) => {
        if (name === '') setName(props.doctorNoteDetail?.doctorNoteName)
        editName === true ? setEditName(false) : setEditName(true)

    }
    const handleChangeName = (e: any) => {
        const name = e.target.value === '' ? '' : String(e.target.value)
        setName(name)
    }


    const cancel = async () => {
        setEditName(true)
        dispatch(resetModal())
        return props.onCancel
    }

    const submit = async () => {
        setName('')
        setEditName(true)
        props.onSubmit(name)
        dispatch(resetModal())
    }
    return props.isShow ? (
        <ModalCustom
            size={'lg'}
            title={''}
            alignFooter={'center'}
            fullscreen={'sm-down'}
            component={
                <Col xl={12} xs={12} style={{ minHeight: '100px', marginLeft: '30px' }}>
                    <Row style={{ marginTop: '30px' }}>
                        <Col xs={2} xl={2} style={{ justifyContent: 'end', display: 'flex' }}>
                            {t('DOCTOR_NOTE.SAVE_POPUP.NOTE_NAME')}
                        </Col>
                        <Col xs={7} xl={7}>
                            {editName ?
                                <Col>
                                    <Col xs={10} xl={10} style={{ marginTop: '-10px' }}>
                                        <InputTextField
                                            autoSelect
                                            placeholder={''}
                                            label={''}
                                            value={name}
                                            key={``}
                                            onchange={handleChangeName}
                                            inputTextAlign={'left'}
                                        />
                                    </Col>
                                </Col>
                                :
                                <Col style={{ border: '1px solid black', borderStyle: 'none none solid none' }}>
                                    {
                                        <Col style={{ marginBottom: '10px', paddingLeft: '5px' }}>{name}</Col>
                                    }
                                </Col>
                            }
                        </Col>
                        <Col xs={2} xl={2} style={{ marginLeft: '-45px' }}>
                            <Col onClick={handleEditName}><img src={iconEdit}></img></Col>
                        </Col>
                    </Row>
                </Col>
            }
            onSubmit={submit}
            onReset={cancel}
            textBtnCancel={t('DOCTOR_NOTE.BUTTON.CANCEL')}
            textBtnConfirm={t('DOCTOR_NOTE.BUTTON.SAVE')}
        />
    ) : (
        <></>
    )
}
