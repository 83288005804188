import _ from 'lodash'
import { Box, IconButton, TableRow, TableCell, MenuItem, Grid, Typography } from '@mui/material'
import useWindowSize from 'utils/useWindowSize'
import { Icons, icons } from 'constants/images'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTextField from 'component/Input/InputTextField'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import BasicSelect from 'component/Select/BasicSelect'
import { IFDoctors, TIME_DURATION_TYPE } from 'component/Treatments/PopupCreateByUsers'
import { isEditTootChart, setEditTootChart, setEditTootChartType, setSubmitTootChart, setToothChart, setToothChartPosition, treatment, TreatmentStateInterface } from 'app/slice/treatment.slice';
import { useDispatch, useSelector } from 'react-redux'
import * as UseStyled from 'features/treatments/SummaryTreatment/UseStyle'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'
import InputTextarea from 'component/Input/InputTextarea'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { handleShow, resetModal, showModal } from 'app/slice/modal.slice'
import { useEffect, useState } from 'react'
import { ButtonToothChart, ButtonTreatmentOverview, FragmentToothChart } from 'features/treatments/MainMenu/OPD/style/TxUseStyle'
import ToothChart from 'component/ToothChart/ToothChart'
import TeethAdult from 'assets/toothChart/type-teeth/teethAdult'
import TeethChild from 'assets/toothChart/type-teeth/teethChild'
import PopupEmrOverview from '../PopupEmrOverview/PopupEmrOverview'
import ButtonCustom from 'component/Button/ButtonCustom'

export interface IFRowTxP {
    patientId: number
    disabled: boolean
    no: number
    id: number
    itemTypes: any[]
    items: any[]
    itemId: number
    itemTypeId: number
    itemCode: string
    price: number
    dateUse: string
    doctorId: number
    doctors: any[]
    duration: string
    durationUnit: string
    status: string
    data?: any
    handleChangeInputItemType?: (e: any, value: any) => void
    handleChangeItemType?: (e: any, value: any) => void
    handleChangeInputItem?: (e: any, value: any) => void
    handleChangeItem?: (e: any, value: any) => void
    handleChangePrice: (event: any) => void
    handleChangeDate: (event: any) => void
    handleChangeDoctor: (event: any) => void
    handleChangeDuration: (event: any) => void
    handleChangeDurationUnit: (event: any) => void
    handleChangeStatus: (event: any) => void
    handleDiscard: (event: any) => void
    headCell: any
    handleRemarkInfo: (event: any) => void
    remarkInfo: string
    teeth: string
    teethJson: string
    teethType: string
    handleTeeth: (event: any) => void
    handleTeethJson: (event: any) => void
    handleTeethType: (event: any) => void
    operativeType?: any
    opertatives?: any
    errorTxp?: any
}

export default function RowTxP(props: IFRowTxP) {
    const { t } = useTranslation()
    const [width] = useWindowSize()
    const treatments: TreatmentStateInterface = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)
    const dispatch = useDispatch()
    const [modalToothChart, setModalToothChart] = useState<boolean>(false)

    const modal: boolean = useSelector(handleShow)
    const [isShowPopupEmrOverview, setIsShowPopupEmrOverview] = useState<boolean>(false);
    const [toothChartColor, setToothChartColor] = useState<string>("")
    const [disabledInProgress, setDisabledInProgress] = useState<boolean>(false)

    const { disabled, no, itemTypes, items, itemId, itemTypeId, itemCode, price, dateUse,
        doctorId, doctors, duration, durationUnit, status, id, remarkInfo,
        teeth, teethJson, teethType, operativeType, opertatives, errorTxp } = props

    const {
        handleChangeInputItemType,
        handleChangeItemType,
        handleChangeInputItem,
        handleChangeItem,
        handleChangePrice,
        handleChangeDate,
        handleChangeDoctor,
        handleChangeDuration,
        handleChangeDurationUnit,
        handleDiscard,
        handleRemarkInfo
    } = props

    const editToothChart = async (id: number) => {
        dispatch(setEditTootChartType(''))
        dispatch(setEditTootChart(null))

        if (teethJson === "") {
            const resp: any = await OperativeTypeApi.findById(itemTypeId)
            if (resp.status === 200) {
                setToothChartColor(resp.data.color)
                dispatch(showModal())
                setModalToothChart(true)

                dispatch(setEditTootChartType(teethType))
                dispatch(setEditTootChart(id))
            }
        } else {
            const newTeethJson = JSON.parse(teethJson)
            const numberToothChart: any[] = []
            newTeethJson.forEach((item: any) => {
                numberToothChart.push(item.teeth)
                dispatch(setToothChart({ key: item.teeth, value: { ...(item || {}), teeth: item.teeth, isSelect: item.selected, color: item.color } }))
            })
            dispatch(setToothChartPosition(numberToothChart))

            dispatch(showModal())
            setModalToothChart(true)
            dispatch(setEditTootChartType(teethType))
            dispatch(setEditTootChart(id))
        }
    }

    const hasFilter = () => {
        if (_.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE') {
            return `grayscale(1)`
        } else {
            return 'none'
        }
    }
    const hasOpacity = () => {
        if (_.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE') {
            return .3
        } else {
            return 1
        }
    }
    const mobileWidth = () => {
        if (width >= 480) {
            return '130px'
        } else {
            return '90px'
        }
    }
    const autoSelectDisabled = () => {
        return itemTypeId && !disabledInProgress ? disabled : true
    }

    const onViewEmrOverview = () => {
        dispatch(showModal())
        setIsShowPopupEmrOverview(true)
    }

    const handleToothChart = (data: any) => {
        props.handleTeeth(data)
    }

    const handleEditToothChart = (type: 'save' | 'cancel') => {
        dispatch(setToothChartPosition([]))
        if (type === 'cancel') {
            dispatch(setEditTootChart(null))
            dispatch(setEditTootChartType(''))
        }
        else dispatch(setSubmitTootChart(true))
    }

    const setTeethType = (type: string) => {
        props.handleTeethType(type)
    }

    const displayLarge = () => {
        return <>
            <TableCell align='left' width="25%" className='p-3' style={{ fontSize: '16px' }}>
                <>
                    <UseStyled.InputToothChart className={`d-sm-flex w-100 text-nowrap `}>
                        <InputTextField disabled value={teeth || ''} />
                        <UseStyled.BtnEditToothChart disableFocusRipple disableRipple
                            disabled={(itemTypeId && itemId) && !disabledInProgress ? false : true}
                            onClick={() => editToothChart(no)}
                            className={`p-0 ml-2 ${isEditTootCharts === no + 1 ? 'active' : ''} `}>
                            {isEditTootCharts === no ? <Icons.ToothSolid /> : <Icons.ToothOutlined />}
                        </UseStyled.BtnEditToothChart>
                    </UseStyled.InputToothChart>
                </>
            </TableCell>
            <TableCell align="center" scope="row" >
                <AutocompleteSelect
                    disabled={disabledInProgress}
                    labelId={`lb-item-type-${no}`}
                    inputLabel={t('TXC.TXC_TYPE')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={operativeType}
                    getOptionLabel={(option: any) => option.itemTypeName}
                    renderOption={(props, option: any) => (
                        <Box key={option} component="li" {...props} sx={{ textAlign: "left" }}>
                            {option.itemTypeName}
                        </Box>
                    )}
                    onInputChange={handleChangeInputItemType}
                    onchange={handleChangeItemType}
                    key={`item-type-${no}`}
                    value={_.find(operativeType, (val: any) => String(val.itemTypeId) === String(itemTypeId)) || null}
                    disableClearable
                    required
                    errMessage={errorTxp?.itemTypeId}
                />
            </TableCell>
            <TableCell align="center" scope="row" >
                <AutocompleteSelect
                    labelId={`lb-item-${no}`}
                    inputLabel={t('TXP.TABLE.DETAIL_OPERATIVE')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={_.map([...opertatives].filter(val => String(val.itemTypeId) === String(itemTypeId)))}
                    disabled={itemTypeId && !disabledInProgress ? false : true}
                    getOptionLabel={(option: any) => option.itemName}
                    renderOption={(props, option: any) => (
                        <Box
                            {...props}
                            key={option}
                            component="li"
                            sx={{ textAlign: "left" }}>
                            {option.itemName}
                        </Box>
                    )}
                    onInputChange={handleChangeInputItem}
                    onchange={handleChangeItem}
                    key={`item-${no}`}
                    value={_.find(opertatives, (val: any) => String(val.itemId) === String(itemId)) || null}
                    disableClearable
                    required
                    errMessage={errorTxp?.itemId}
                />
            </TableCell>
            <TableCell align="center" scope="row">
                {itemCode || '-'}
            </TableCell>
            <TableCell align="center" scope="row">
                <InputTextField
                    placeholder={t('TXP.TABLE.PRICE')}
                    label={''}
                    value={price}
                    key={`ip-price-${no}`}
                    disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onchange={handleChangePrice}
                    inputTextAlign={'right'}
                />
            </TableCell>
            <TableCell align="center" scope="row">
                <InputSecondNewDatePicker
                    key={`ip-date-use-${no}`}
                    label={t('TXP.TABLE.DATE')}
                    value={dateUse}
                    disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                    onchange={handleChangeDate}
                />
            </TableCell>
            <TableCell align="center" scope="row">
                <BasicSelect
                    name="doctorId"
                    key={`dentist-${no}`}
                    labelId={`lb-dentist-${no}`}
                    selectId={`sl-dentist-${no}`}
                    label={t('TXP.TABLE.DOCTOR')}
                    value={_.get(_.find(doctors, { userId: doctorId }), 'userId') || null}
                    disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                    renderValue={() => _.find(doctors, { userId: doctorId })?.fullname}
                    options={
                        [
                            _.map(doctors, (doctor: IFDoctors, indexDoctor: number) => {
                                return <MenuItem key={indexDoctor} value={doctor.userId} className="d-flex align-items-center justify-content-between w-100 overflow-hidden">
                                    <span className='text-ellipsis'>
                                        {doctor.fullname}
                                    </span>
                                </MenuItem>
                            })]
                    }
                    onchange={handleChangeDoctor}
                    classesOption="style-select-doctors"
                />
            </TableCell>
            <TableCell align="center" scope="row">
                <Box className='d-flex'>
                    <InputTextField
                        label={t('TXP.TABLE.TIME')}
                        value={duration}
                        key={`duration-${no}`}
                        disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        onchange={handleChangeDuration}
                        style={{ maxWidth: 70 }}
                    />
                    <BasicSelect
                        name="หน่วย"
                        label={t('TXP.TABLE.UNIT')}
                        key={`duration-unit-${no}`}
                        selectId={`sl-duration-unit-${no}`}
                        labelId={`lb-duration-unit-${no}`}
                        disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                        value={durationUnit || ''}
                        style={{ fontSize: '16px' }}
                        options={[
                            _.map(TIME_DURATION_TYPE, (timeDuration: any, i: number) => {
                                return <MenuItem key={i} value={timeDuration.value}> {timeDuration.name} </MenuItem>
                            })
                        ]}
                        onchange={handleChangeDurationUnit}
                        formControlClass={'ml-2'}
                    />
                </Box>
            </TableCell>
            <TableCell align="left" scope="row">
                {_.some(treatments.summeryTreatments, { refId: id }) && t('TX_INFORMATION.STATUS.IN_PROGRESS') || status === 'DONE' && t('TX_INFORMATION.STATUS.DONE') || t('TX_INFORMATION.STATUS.PENDING')}
            </TableCell>
        </>
    }

    const displaySmall = () => {
        return <>
            <TableCell align="center" scope="row">
                <Grid container spacing={1}>
                    <Grid item className='w-100'>
                        <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <Typography className='text-left py-2 py-sm-0' sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                                {t('ฟัน')}
                            </Typography>
                            <UseStyled.InputToothChart className={`d-sm-flex w-100 text-nowrap `}>
                                <InputTextField disabled value={teeth || ''} />
                                <UseStyled.BtnEditToothChart disableFocusRipple disableRipple
                                    disabled={(itemTypeId && itemId) || !disabledInProgress ? false : true}
                                    onClick={() => editToothChart(no)}
                                    className={`p-0 ml-2 ${isEditTootCharts === no + 1 ? 'active' : ''} `}>
                                    {isEditTootCharts === no ? <Icons.ToothSolid /> : <Icons.ToothOutlined />}
                                </UseStyled.BtnEditToothChart>
                            </UseStyled.InputToothChart>
                        </Box>

                    </Grid>
                    <Grid item className='w-100'>
                        <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <Typography className='text-left py-2 py-sm-0' sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                                {t('TXP.TABLE.TYPE')}
                            </Typography>
                            <Box className='w-100'>
                                <AutocompleteSelect
                                    disabled={disabledInProgress}
                                    labelId={`lb-item-type-${no}`}
                                    inputLabel={t('TXC.TXC_TYPE')}
                                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                    options={itemTypes}
                                    getOptionLabel={(option: any) => option.itemTypeName}
                                    renderOption={(props, option: any) => (
                                        <Box key={option} component="li" {...props} sx={{ textAlign: "left" }}>
                                            {option.itemTypeName}
                                        </Box>
                                    )}
                                    onInputChange={handleChangeInputItemType}
                                    onchange={handleChangeItemType}
                                    key={`item-type-${no}`}
                                    value={_.find(itemTypes, (val: any) => String(val.itemTypeId) === String(itemTypeId)) || null}
                                    disableClearable
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item className='w-100'>
                        <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <Typography className='text-left pb-2 pb-sm-0' sx={{ width: '130px', fontWeight: 600, flex: 'none' }}>
                                {t('TXP.TABLE.DETAIL_OPERATIVE')}
                            </Typography>
                            <Box className='w-100'>
                                <AutocompleteSelect
                                    labelId={`lb-item-${no}`}
                                    inputLabel={t('TXP.TABLE.DETAIL_OPERATIVE')}
                                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                    options={items}
                                    disabled={autoSelectDisabled()}
                                    getOptionLabel={(option: any) => option.itemName}
                                    renderOption={(props, option: any) => <Box key={option} component="li" {...props} sx={{ textAlign: "left" }}>   {option.itemName} </Box>}
                                    onInputChange={handleChangeInputItem}
                                    onchange={handleChangeItem}
                                    key={`item-${no}`}
                                    value={_.find(items, (val: any) => String(val.itemId) === String(itemId)) || null}
                                    disableClearable
                                />
                            </Box>
                        </Box>

                    </Grid>
                    <Grid item className='w-100'>
                        <Box className='d-flex align-items-center'>
                            <Typography className='text-left' sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                                {t('TXP.TABLE.CODE')}
                            </Typography>
                            <Typography className='text-left'>{itemCode || "-"}</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} className='w-100 pr-md-2'>
                        <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <Typography className='text-left pb-2 pb-sm-0' sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                                {t('TXP.TABLE.PRICE')}
                            </Typography>
                            <InputTextField
                                label={t('TXP.TABLE.PRICE')}
                                value={price}
                                disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                                key={`ip-price-${no}`}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                onchange={handleChangePrice}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6} className='w-100'>
                        <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <Typography className='text-left pb-2 pb-sm-0' sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                                {t('TXP.TABLE.DATE')}
                            </Typography>
                            <Box className='w-100'>
                                <InputSecondNewDatePicker
                                    key={`ip-date-use-${no}`}
                                    label={t('TXP.TABLE.DATE')}
                                    value={dateUse}
                                    disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                                    onchange={handleChangeDate}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item className='w-100'>
                        <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <Typography className='text-left pb-2 pb-sm-0' sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                                {t('TXP.TABLE.DOCTOR')}
                            </Typography>
                            <Box className='w-100'>
                                <BasicSelect
                                    name="doctorId"
                                    key={`dentist-${no}`}
                                    labelId={`lb-dentist-${no}`}
                                    selectId={`sl-dentist-${no}`}
                                    label={t('TXP.TABLE.DOCTOR')}
                                    value={_.get(_.find(doctors, { userId: doctorId }), 'userId') || null}
                                    disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                                    renderValue={() => _.find(doctors, { userId: doctorId })?.fullname}
                                    options={
                                        [
                                            _.map(doctors, (doctor: IFDoctors, indexDoctor: number) => {
                                                return <MenuItem key={indexDoctor} value={doctor.userId} className="d-flex align-items-center justify-content-between w-100 overflow-hidden">
                                                    <span className='text-ellipsis'>
                                                        {doctor.fullname}
                                                    </span>
                                                </MenuItem>
                                            })]
                                    }
                                    onchange={handleChangeDoctor}
                                    classesOption="style-select-doctors"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={12} className='w-100 pr-md-2'>
                        <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <Typography className='text-left pb-2 pb-sm-0' sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                                {t('TXP.TABLE.TIME')}
                            </Typography>
                            <Box className='w-100'>
                                <Box className='d-flex'>
                                    <InputTextField
                                        label={t('TXP.TABLE.TIME')}
                                        value={duration}
                                        disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                                        key={`duration-${no}`}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        onchange={handleChangeDuration}
                                        style={{ maxWidth: '50%' }}
                                    />
                                    <BasicSelect
                                        name="หน่วย"
                                        label={t('TXP.TABLE.UNIT')}
                                        key={`duration-unit-${no}`}
                                        selectId={`sl-duration-unit-${no}`}
                                        labelId={`lb-duration-unit-${no}`}
                                        value={durationUnit || ''}
                                        style={{ fontSize: '16px' }}
                                        disabled={itemId === null || _.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                                        options={[
                                            _.map(TIME_DURATION_TYPE, (durationType: any, i: number) => (
                                                <MenuItem key={i} value={durationType.value}>
                                                    {durationType.name}
                                                </MenuItem>
                                            ))
                                        ]}
                                        onchange={handleChangeDurationUnit}
                                        formControlClass={'ml-2'}
                                    />
                                </Box>
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item sm={12} className='w-100'>
                        <Box className='d-flex flex-column flex-sm-row align-items-sm-center'>
                            <Typography className='text-left pb-2 pb-sm-0' sx={{ width: mobileWidth(), fontWeight: 600, flex: 'none' }}>
                                {t('TXP.TABLE.STATUS')}
                            </Typography>
                            <Box className='w-100 text-left'>
                                {_.some(treatments.summeryTreatments, { refId: id }) && t('TX_INFORMATION.STATUS.IN_PROGRESS') || status === 'DONE' && t('TX_INFORMATION.STATUS.DONE') || t('TX_INFORMATION.STATUS.PENDING')}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </TableCell>
        </>
    }

    useEffect(() => {
        if (isEditTootCharts === null) {
            dispatch(resetModal())
            setModalToothChart(false)
        }

    }, [isEditTootCharts])

    useEffect(() => {
        if (!modal) {
            if (modalToothChart) {
                dispatch(showModal())
            }
        }
    }, [modal])

    useEffect(() => {
        const newDis = _.some(treatments.summeryTreatments, { refId: id })
        setDisabledInProgress(newDis)
    }, [treatments.summeryTreatments])


    return (
        <>
            <TableRow key={no}>
                <TableCell align="center" scope="row">
                    {no + 1}
                </TableCell>
                {width >= 1200 && (
                    displayLarge()
                ) || (
                        displaySmall()
                    )}
                <TableCell align="center" scope="row">
                    <IconButton
                        disableFocusRipple
                        disableRipple
                        disabled={_.some(treatments.summeryTreatments, { refId: id }) || status === 'DONE'}
                        onClick={handleDiscard}
                        className="p-0 align-self-start"
                        sx={{
                            backgroundColor: 'transparent',
                            filter: hasFilter(),
                            opacity: hasOpacity()
                        }}>
                        <img src={icons.btnMinus} alt="discard" />
                    </IconButton>
                </TableCell>
            </TableRow >

            <TableRow>
                <TableCell align="center" scope="row" >
                </TableCell>
                <TableCell align="center" scope="row" colSpan={width >= 1200 ? _.size(props.headCell) - 1 : 0}>
                    <Box sx={{ p: width >= 1200 ? "0px 1.5rem 0px 0px" : "" }}>
                        <InputTextarea
                            label={t('TXP.TABLE.REMARK')}
                            onchange={(e: any) => {
                                handleRemarkInfo(e)
                            }}
                            value={remarkInfo}
                        />
                    </Box>

                </TableCell>
            </TableRow>

            {isEditTootCharts !== null && modalToothChart &&
                <ModalCustom
                    title={t('TXP.TOOTHCHART.TITLE')}
                    size={'xl'}
                    component={
                        <>
                            <FragmentToothChart className="mx-auto mb-3 mb-md-5 mb-xl-0" sx={{ flex: 'none' }}>
                                <Box className='mb-2' sx={{ fontSize: '18px', fontWeight: 500 }}>
                                    {t('TXP.TOOTHCHART.PROCEDURE')} : {_.find(items, (val: any) => String(val.itemId) === String(itemId)).itemName || ''}
                                </Box>
                                <Box className="d-none d-md-block">
                                    <ToothChart
                                        type={'treatment'}
                                        teethType={teethType}
                                        patientId={0}
                                        handleToothChart={handleToothChart}
                                        noneSave={true}
                                        tagColor={toothChartColor}
                                        teethChartJSON={teethJson}
                                    />
                                </Box>
                                <Box className="d-flex justify-content-center">
                                    <ButtonToothChart onClick={() => setTeethType('ADULT')} className="mt-0 mt-md-3 " sx={{ flex: 'none' }}>
                                        <TeethAdult type={teethType} />
                                    </ButtonToothChart>
                                    <ButtonToothChart onClick={() => setTeethType('CHILD')} className="mt-0 mt-md-3 pl-1 pr-3" sx={{ flex: 'none' }}>
                                        <TeethChild type={teethType} />
                                    </ButtonToothChart>
                                    <ButtonTreatmentOverview variant={'outlined'} onClick={onViewEmrOverview} size="small" className="mt-0 mt-md-3 px-3" >
                                        {t('OPD.BUTTON.TREATMENT_OVERVIEW')}
                                    </ButtonTreatmentOverview>
                                </Box>
                                {/* d-flex justify-content-end align-items-center */}
                                <Box className='d-flex justify-content-end align-items-center mt-2'>
                                    <div >
                                        <ButtonCustom textButton={t('BUTTON.CANCEL')} variant="outlined" onClick={() => handleEditToothChart('cancel')} btnStyle={{ height: 44, marginRight: '1rem' }} />
                                    </div>
                                    <div>
                                        <ButtonCustom textButton={t('BUTTON.SAVE')} onClick={() => handleEditToothChart('save')} btnStyle={{ height: 44 }} />
                                    </div>

                                </Box>
                            </FragmentToothChart>
                        </>
                    }
                />
            }

            {isShowPopupEmrOverview && (
                <PopupEmrOverview
                    patientId={props.patientId}
                    onClose={() => {
                        setIsShowPopupEmrOverview(false)
                    }} />
            )}
        </>
    )
}
