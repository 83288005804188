import { menuSeconds } from 'constants/menus'

import ManageWeb from 'features/application/manage-web/ManageWeb'

const routeManageWeb = [
    {
        ...menuSeconds.BANNER,
        key: menuSeconds.BANNER.key,
        name: menuSeconds.BANNER.name,
        textName: menuSeconds.BANNER.textName,
        active: false,
        appBar: true,
        component: ManageWeb
    },
    {
        ...menuSeconds.ABOUT_US,
        key: menuSeconds.ABOUT_US.key,
        name: menuSeconds.ABOUT_US.name,
        textName: menuSeconds.ABOUT_US.textName,
        active: false,
        appBar: true,
        component: ManageWeb
    },
    {
        ...menuSeconds.SERVICE,
        key: menuSeconds.SERVICE.key,
        name: menuSeconds.SERVICE.name,
        textName: menuSeconds.SERVICE.textName,
        active: false,
        appBar: true,
        component: ManageWeb
    },
    {
        ...menuSeconds.PRICE_LIST,
        key: menuSeconds.PRICE_LIST.key,
        name: menuSeconds.PRICE_LIST.name,
        textName: menuSeconds.PRICE_LIST.textName,
        active: false,
        appBar: true,
        component: ManageWeb
    },
    {
        ...menuSeconds.REVIEW,
        key: menuSeconds.REVIEW.key,
        name: menuSeconds.REVIEW.name,
        textName: menuSeconds.REVIEW.textName,
        active: false,
        appBar: true,
        component: ManageWeb
    },
    {
        ...menuSeconds.ARTICLE,
        key: menuSeconds.ARTICLE.key,
        name: menuSeconds.ARTICLE.name,
        textName: menuSeconds.ARTICLE.textName,
        active: false,
        appBar: true,
        component: ManageWeb
    },
    {
        ...menuSeconds.CONTACT_US,
        key: menuSeconds.CONTACT_US.key,
        name: menuSeconds.CONTACT_US.name,
        textName: menuSeconds.CONTACT_US.textName,
        active: false,
        appBar: true,
        component: ManageWeb
    },
]

export default routeManageWeb