import { styled } from '@mui/material/styles'
import { Box, Button, Tabs, Menu, FormControlLabel } from '@mui/material'
import { colors } from 'constants/theme'

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiButtonBase-root': {
    minWidth: 'unset',
    minHeight: 'unset',
    width: 56,
    height: 42,
    borderRadius: '0 6px 6px 0',
    border: `1px solid ${colors.themeSecondColor}`,
    color: colors.themeMainColor,
    padding: 0,
    fontSize: 16,
    fontWeight: 500,
    pointerEvents: 'auto',
    '&.Mui-selected': {
      backgroundColor: colors.themeSecondColor,
      color: colors.white
    }
  },
  '.MuiTabs-indicator': {
    display: 'none'
  }
}))

export const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  height: '44px !important',
  fontSize: 18,
  borderRadius: '6px !important',
  backgroundColor: `${colors.themeSecondColor} !important`,
  color: `${colors.white} !important`,
  svg: {
    position: 'absolute',
    right: '1rem'
  }
}))

export const MenuBtnMore = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    marginTop: '0.5rem',
    borderRadius: 3,
    mixBlendMode: 'multiply',
    backgroundBlendMode: 'multiply',
    background: `linear-gradient(to bottom, ${colors.white}, ${colors.white})`,
    boxShadow: `none !important`,
    border: `1px solid ${colors.lightGray}`
  },
  '.MuiMenuItem-root': {
    color: colors.textPrimary,
    fontSize: '16px !important',
    minHeight: 'unset',
    '&:hover': {
      background: colors.themeSecondColor06,
      color: colors.themeSecondColor
    }
  }
}))

export const SummaryCourse = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        '&:nth-of-type(4), &:nth-of-type(5)': {
          paddingTop: 6,
          paddingBottom: 6,
        },
      }
    }
  }
}))

export const DivHeadSession = styled('div')(({ theme }) => ({
  padding: '0.5rem',
  fontSize: '1.125rem',
  fontWeight: 500,
  backgroundColor: colors.themeSecondColor10,
  borderRadius: 4,
  width: '100%'
}))

export const paymentChannels = styled('div')(({ theme }) => ({
  width: '162px',
  height: '98px',
  color: '#9E9E9E',
  border: '2px solid #E5E5E5',
  borderRadius: '5px',
  backgroundColor: '#fff',
  // textAlign: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const paymentBody = styled('div')(({ theme }) => ({
  padding: "0 2rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "43px",
  '.detailPayment': {
    fontSize: "16px",
    fontWeight: "500"
  },
  '.sumPayment': {
    fontSize: "20px",
    fontWeight: "700"
  },
}))

export const paymentBody_SplitPay = styled('div')(({ theme }) => ({
  padding: "0 2rem",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  height: "43px",
  '.detailPayment': {
    fontSize: "16px",
    fontWeight: "500",
    margin: " 0 1rem 0 0"
  },
  '.sumPayment': {
    fontSize: "20px",
    fontWeight: "700",
    margin: " 0 1rem 0 0",
    'div div input': {
      textAlign: "right"
    }
  },

}))

export const FormFormatSplitPay = styled(FormControlLabel)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: 500
  },
  '&.active': {
    backgroundColor: colors.themeSecondColor06,
    '.MuiTypography-root': {
      color: colors.themeSecondColor
    }
  }
}))