import { Box, Menu, Typography } from '@mui/material'
import { fileUrl } from 'api/api'
import InputTextField from 'component/Input/InputTextField'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import UploadImage from 'component/Upload/UploadImage'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useStyles, { BtnColorPicker } from './style'
import { colorOperativeTypes } from 'constants/theme'
import InputTextarea from 'component/Input/InputTextarea'
import { BlockPicker } from 'react-color'
import ButtonCustom from 'component/Button/ButtonCustom'
import BtnDelFilled from 'assets/button/BtnDelFilled'

type OperativeTypesFormProps = {
    type: 'SETTING' | 'MANAGEWEB'
    data: {
        operativeTypeId: number
        operativeTypeStatus: string
        operativeTypeName: string
        operativeTypeNameEn: string
        operativeTypeFile?: any
        operativeTypeColor: string
        operativeTypeDes?: string
    },
    errorMessage: any
    clearErrorMessage: () => void
    resetForm: () => void
    submitEdit: () => void
    submit: () => void
    onEdit: (data: any, form: boolean) => void
    setDelImage: (staus: boolean) => void
}

const OperativeTypesForm = (props: OperativeTypesFormProps) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [dataEdit, setDataEdit] = useState<any>(props.data)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const popupTypeColor = Boolean(anchorEl)
    const [popupWidth, setPopupWidth] = useState(0)

    const handleOpenOperativeTypeColor = (event: React.MouseEvent<HTMLButtonElement>) => {
        const width = Array.from(document.getElementsByClassName('btn-color-picker') as HTMLCollectionOf<HTMLElement>)
        setPopupWidth(Number(width[0]?.offsetWidth))
        setAnchorEl(event.currentTarget)
    }

    const handleCloseOperativeTypeColor = () => {
        setAnchorEl(null)
    }

    const handleChangeColor = (val?: any) => {
        if (val) setDataEdit({ ...dataEdit, operativeTypeColor: val })
        else setDataEdit({ ...dataEdit, operativeTypeColor: '' })
    }

    useEffect(() => {
        renderDataSave()
    }, [dataEdit])

    const renderDataSave = () => {
        const newData = {
            operativeTypeId: dataEdit?.operativeTypeId || 0,
            operativeTypeName: dataEdit?.operativeTypeName || '',
            operativeTypeNameEn: dataEdit?.operativeTypeNameEn || '',
            color: dataEdit?.operativeTypeColor,
            status: dataEdit?.operativeTypeStatus,
            file: dataEdit?.operativeTypeFile || {},
            description: dataEdit?.operativeTypeDes || ''
        }
        props?.onEdit(newData, true)
    }

    return (
        <>
            <ModalCustom
                title={dataEdit?.operativeTypeId ? t('OPERATIVE_TYPE.TITLE_UPDATE') : t('OPERATIVE_TYPE.TITLE_CREATE')}
                onClose={() => props?.resetForm()}
                component={
                    <div className="pb-2">

                        {dataEdit?.operativeTypeId && props.type !== 'MANAGEWEB' ? (
                            <div>
                                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                                    <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        onChange={() => setDataEdit({ ...dataEdit, operativeTypeStatus: dataEdit?.operativeTypeStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })}
                                        checked={dataEdit?.operativeTypeStatus === 'ACTIVE' ? true : false}
                                        style={{ marginLeft: 'auto' }}
                                    />
                                </Form>
                            </div>
                        ) : (
                            ''
                        )}

                        <InputTextField
                            onchange={(event) => {
                                setDataEdit({ ...dataEdit, operativeTypeName: event.target.value })
                                props?.clearErrorMessage()
                            }}
                            value={dataEdit?.operativeTypeName}
                            label={t('OPERATIVE_TYPE.INPUT.OPERATIVE_TYPE_NAME')}
                            helperText={
                                props?.errorMessage.OPERATIVE_TYPE_NAME ||
                                props?.errorMessage.IS_DUPLICATE_OPERATIVE_TYPE_NAME ||
                                props?.errorMessage.OPERATIVE_TYPE_NAME_STRING_BASE ||
                                props?.errorMessage.OPERATIVE_TYPE_NAME_STRING_EMPTY ||
                                props?.errorMessage.OPERATIVE_TYPE_NAME_MAXIMUM_LENGTH ||
                                props?.errorMessage.OPERATIVE_TYPE_NAME_ANY_REQUIRED
                            }
                            required={true}
                            style={{ marginTop: '1rem' }}
                        />
                        {props.type !== 'MANAGEWEB' &&
                            <InputTextField
                                key="operativeTypeNameEn"
                                onchange={(event) => {
                                    setDataEdit({ ...dataEdit, operativeTypeNameEn: event.target.value })
                                    props?.clearErrorMessage()
                                }}
                                value={dataEdit?.operativeTypeNameEn}
                                helperText={
                                    props?.errorMessage.OPERATIVE_TYPE_NAME_EN ||
                                    props?.errorMessage.IS_DUPLICATE_OPERATIVE_TYPE_NAME_EN ||
                                    props?.errorMessage.OPERATIVE_TYPE_NAME_EN_STRING_BASE ||
                                    props?.errorMessage.OPERATIVE_TYPE_NAME_EN_STRING_EMPTY ||
                                    props?.errorMessage.OPERATIVE_TYPE_NAME_EN_MAXIMUM_LENGTH ||
                                    props?.errorMessage.OPERATIVE_TYPE_NAME_EN_ANY_REQUIRED
                                }
                                label={t('OPERATIVE_TYPE.INPUT.OPERATIVE_TYPE_NAME_EN')}
                                required={false}
                                style={{ marginTop: '1rem' }}
                            />
                        }

                        {props.type !== 'MANAGEWEB' &&
                            <Box className="mt-3">
                                <Typography className="pb-1">{`${t('OPERATIVE_TYPE.INPUT.OPERATIVE_TYPE_NAME_COLOR')}`}</Typography>
                                <BtnColorPicker
                                    id="basic-button"
                                    disableRipple
                                    aria-controls={popupTypeColor ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={popupTypeColor ? 'true' : undefined}
                                    onClick={handleOpenOperativeTypeColor}
                                    className={'btn-color-picker'}
                                    sx={{ backgroundColor: `${dataEdit?.operativeTypeColor || colorOperativeTypes[0]} !important` }}
                                >
                                    operative type
                                </BtnColorPicker>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={popupTypeColor}
                                    onClose={handleCloseOperativeTypeColor}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button'
                                    }}
                                    classes={{ paper: classes.colorPicker }}
                                    sx={{ '& .MuiMenu-list': { width: popupWidth } }}
                                >
                                    <BlockPicker color={dataEdit?.operativeTypeColor || colorOperativeTypes[0]} colors={colorOperativeTypes} onChange={(color) => handleChangeColor(color.hex)} />
                                </Menu>
                            </Box>
                        }
                    </div>

                }
                onReset={props?.resetForm}
                onSubmit={dataEdit?.operativeTypeId ? props?.submitEdit : props?.submit}
                textBtnCancel={t('OPERATIVE_TYPE.BUTTON.CANCEL')}
                textBtnConfirm={t('OPERATIVE_TYPE.BUTTON.SAVE')}
            />
        </>
    )
}

export default OperativeTypesForm