import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { Box } from '@mui/material';

export const ResponsiveTable = styled(Box)<{ section: 'default' | 'collapse' }>(({ theme, section }) => ({
  '.MuiTableContainer-root': {
    minHeight: 'unset !important',
    '.MuiTable-root': {
      border: 'none',
      minWidth: section === 'default' ? 1280 : 'auto',
      '.MuiTableHead-root': {
        '.MuiTableCell-head': {
          '&:first-of-type': {
            width: 50
          },
          '&:nth-of-type(2)': {
            width: '20%'
          }
        }
      },
      '.MuiTableBody-root': {
        '.MuiTableRow-root': {
          '.MuiTableCell-body': {
            background: 'transparent',
            '&:first-of-type': {
              '.MuiIconButton-root': {
                marginTop: -8
              }
            },
            '&:first-of-type, &:nth-of-type(2), &:nth-of-type(3)': {
              paddingTop: 20
            },
            '&:not(:first-of-type):not(:nth-of-type(2)):not(:nth-of-type(3))': {
              paddingTop: 8
            }
          },
          '&:not(.section-collapse)': {
            '&:hover': {
              '.MuiTableCell-body': {
                background: colors.extraLightGray
              },
            }
          },
          '&.section-collapse': {
            '.MuiTableBody-root': {
              border: 'none !important',
            },
            '.MuiPaper-root': {
              borderRadius: '0 !important',
              '.MuiTable-root': {
                '.MuiTableRow-head': {
                  '.MuiTableCell-head': {
                    padding: 0,
                    fontSize: 0,
                    lineHeight: 0,
                    backgroundColor: 'transparent',
                    border: 'none !important'
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}))