import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import domtoimage from 'dom-to-image'
import _ from 'lodash'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/counter/doctor-schedule/style'
import 'features/counter/doctor-schedule/table-style.css'
/** API */
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputRadio from 'component/Input/InputRadio'

/** UTILS || SLICE */
import { createDaysForCurrentMonth, createDaysForNextMonth, createDaysForPreviousMonth } from 'utils/calendar-helper.utils'
import { notiSuccess } from 'component/notifications/notifications'
import { isBuddhistYear, numberToCurrencyFormat, padCode } from 'utils/app.utils'
import { getNameMonthByNo } from 'constants/date-time'
import { colors } from 'constants/theme'
import { swalCustom, swalWarning } from '../../../component/Alert/Swal';

const ButtonShare = styled(Button)(({ theme }) => ({ fontSize: '14px !important', padding: '5 10', border: `1px solid ${colors.themeSecondColor} !important`, backgroundColor: `${colors.themeSecondColor} !important`, color: `${colors.white} !important`, '&.Mui-disabled': { opacity: 0.7 } }))
const DivDayNumber = styled('div')(({ theme }) => ({ paddingTop: '1px', marginLeft: '.5rem', '&.now': { color: colors.white, backgroundColor: colors.themeMainColor } }))


interface ShareInterface {
  // branchId: any
  // month: any
  // year: any
  // listMonth: any[]
  data: any
}

interface IFDoctors {
  isChecked: boolean | false
  doctorFullname: string
  positionName: string
  positionNameEn: string
  avatar: string | null
  doctorId: number
  branchId: number
  month: string
  year: string
  monthSchedule: string
  firstDate: string
}
export default function DoctorScheduleShare(prop: ShareInterface) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const lang = i18n?.language || 'th'

  const [shareType, setShareType]: any = useState('IMAGE')
  const [dataSchedule, setDataSchedule]: any = useState(prop.data.dataSchedule)
  const [email, setEmail]: any = useState(prop.data.email)
  const [doctorFullname, setDoctorFullname]: any = useState(prop.data.doctorFullname)
  const [month, setMonth]: any = useState(prop.data.month)
  const [year, setYear]: any = useState(prop.data.year)
  const [branchId, setBranchId]: any = useState(prop.data.branchId)
  const [doctorId, setDoctorId]: any = useState(prop.data.doctorId)


  const currentMonthDays = createDaysForCurrentMonth(year, month)
  const previousMonthDays = createDaysForPreviousMonth(year, month, currentMonthDays)
  const nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays)
  const [calendarGridDayObjects, setCalendarGridDayObjects]: any = useState([...previousMonthDays, ...currentMonthDays, ...nextMonthDays])

  const getColorStatus = (status: string, type: 'TEXT' | 'BG') => {
    if (type === 'BG') {
      let bgColorSchedule: any = colors.white
      if (status === 'LEAVE_WORK') bgColorSchedule = colors.doctorScheduleStatus.leave
      else if (status === 'MISSING') bgColorSchedule = colors.doctorScheduleStatus.missing
      return bgColorSchedule
    }
    if (type === 'TEXT') {
      let txtColorSchedule: any = colors.black
      if (status === 'LEAVE_WORK') txtColorSchedule = colors.textPrimary
      else if (status === 'MISSING') txtColorSchedule = colors.textPrimary
      else if (status === 'NORMAL') txtColorSchedule = colors.white
      return txtColorSchedule
    }
  }
  useEffect(() => {
    const { data: { dataSchedule, email, doctorFullname, month, year, branchId, doctorId } } = prop
    const currenShareMonthDays = createDaysForCurrentMonth(year, month)
    const previousShareMonthDays = createDaysForPreviousMonth(year, month, currenShareMonthDays)
    const nextShareMonthDays = createDaysForNextMonth(year, month, currenShareMonthDays)
    const calendarShareGridDayObjects = [...previousShareMonthDays, ...currenShareMonthDays, ...nextShareMonthDays]
    setCalendarGridDayObjects(calendarShareGridDayObjects)
    setDataSchedule(dataSchedule)
    setEmail(email)
    setDoctorFullname(doctorFullname)
    setMonth(month)
    setYear(year)
    setBranchId(branchId)
    setDoctorId(doctorId)
  }, [prop.data])

  function isValiEmail(value: string) {
    const pattern = /\S+@\S+\.\S+/;
    const valid = pattern.test(value);
    return valid
  }

  const sendMail = async () => {
    if (!email || !isValiEmail(email)) return swalCustom(
      `${t('DOCTOR_SCHEDULE.MESSAGE.WARNING.NONE_EMAIL')} `,
      `<div>${t('DOCTOR_SCHEDULE.MESSAGE.WARNING.NONE_EMAIL_SHARE_SUB')}</div>`,
      () => console.log(),
      'warning',
      {
        confirmButton: { showConfirmButton: false },
        cancelButton: { cancelButtonText: t("BUTTON.ENTER") },
        containerClass: 'swal-change-cost',
        showCloseButton: false
      }
    )

    const node = document.getElementById("div-schedule");
    if (node) {
      let base64: any = await domtoimage.toPng(node);
      base64 = base64.split(',')

      DoctorScheduleApi.send({
        type: shareType,
        branchId,
        date: `${year}-${padCode(2, month)}-01`,
        doctorId: doctorId.toString(),
        base64: base64[base64.length - 1],
        filename: `${t('DOCTOR_SCHEDULE.SHARE.DOCTOR_TABLE')} ${getNameMonthByNo(parseInt(month), lang)} ${getYear(year)}`,
      }).then(async (res) => {
        if (res.status !== undefined && res.status === 201) notiSuccess(t('DOCTOR_SCHEDULE.MESSAGE.SUCCESS.EMAIL_SUCCESS'))
        else notiSuccess(t('DOCTOR_SCHEDULE.MESSAGE.ERROR_TRYAGAIN'))
      })
    }
  }

  const handleDownloadImage = async () => {
    const node = document.getElementById("div-schedule");
    if (node) {
      domtoimage.toPng(node).then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = `${t('DOCTOR_SCHEDULE.SHARE.DOCTOR_TABLE')} ${getNameMonthByNo(parseInt(month), lang)} ${getYear(year)}.png`;
        link.href = dataUrl;
        link.click();
      });
    }
  };

  const getYear = (year: string) => {
    return lang === 'th' ? parseInt(year) + isBuddhistYear() : year
  }

  return (
    <ModalCustom
      size="xl"
      title={t('DOCTOR_SCHEDULE.SHARE.SHARE_TABLE')}
      closeButton
      component={
        <div>
          <Row style={{ alignItems: 'center' }}>
            <Col sm={3} className={classes.title}>
              {t('DOCTOR_SCHEDULE.SHARE.TYPE_DOCTOR')}
            </Col>
            <Col className='d-flex'>
              <FormControl className={`${classes.formControll} d-flex`}>
                <RadioGroup row aria-labelledby="share-row-radio-buttons-group-label" value={shareType}
                  onChange={(e) => {
                    const value = e.target.value
                    if (value) setShareType(value)
                  }}
                  name="row-radio-buttons-group">
                  <FormControlLabel value="IMAGE" control={<InputRadio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} label={t('DOCTOR_SCHEDULE.SHARE.PICTURE')} />
                  <FormControlLabel value="MAIL" control={<InputRadio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} label={`${t('DOCTOR_SCHEDULE.SHARE.EMAIL')}:${email}`} />
                </RadioGroup>
              </FormControl>
            </Col>
            <Col sm="auto" style={{ fontSize: 14 }}>
              {t('DOCTOR_SCHEDULE.SHARE.DOCTOR')} {doctorFullname}
            </Col>
            <Col sm="auto" style={{ fontSize: 14 }}>
              {t('DOCTOR_SCHEDULE.SHARE.MONTHLY')}{getNameMonthByNo(parseInt(month), lang)} {getYear(year)}
            </Col>
            <Col sm={1} >
              <ButtonShare className="mx-1 align-top" onClick={shareType === "IMAGE" ? handleDownloadImage : sendMail}  >
                {t('DOCTOR_SCHEDULE.BUTTON.SHARE')}
              </ButtonShare>
            </Col>
          </Row>
          <div className='overflow-auto w-100'>
            <div id='div-schedule' style={{ width: '100%' }}>
              <div style={{ padding: 5, }} >
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600&display=swap" rel="stylesheet" />
                <title>{t('DOCTOR_SCHEDULE.SHARE.DOCTOR_TABLE')}</title>
                <style dangerouslySetInnerHTML={{
                  __html: `\n
                .div-image p {\n margin: 0;\n }\n\n   
                .div-image.mockup-a4-landscape {\n        
                    font-family: 'Sarabun', sans-serif;\n
                    font-size: 10px;\n
                    -webkit-print-color-adjust: exact;\n
                    margin: 0;\n color: ${colors.textPrimary};\n      
                    width: 1130px;\n height: 695px;\n margin: auto;\n }\n\n   
                .div-image .bg-primary {\n background-color: ${colors.themeSecondColor};\n }\n\n   
                .div-image .text-primary {\n color: ${colors.themeSecondColor};\n    }\n\n   
                .div-image .day-of-week-header-cell {\n
                  color: ${colors.themeMainColor};\n
                  font-weight: 700;\n
                  padding: var(--space-md) 0;\n
                  min-width: 0;\n
                  overflow: hidden;\n
                  text-overflow: ellipsis;\n
                  white-space: nowrap;\n
                  text-align: center;\n
                  background-color: ${colors.lightGray};\n  }\n\n
                .div-image .grid-item {\n display: grid;\n grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;\n align-items: center;\n }\n\n   
                .div-image .day {\n border: 1px solid ${colors.lightGray};\n height: 95px;\n padding: 2px;\n overflow: hidden;\n margin: 3px 3px;\n  border-radius: 1px;\n }\n\n   
                .div-image .day-text {\n text-align: center;\n font-size: 14px;\n font-weight: 500;\n margin: 0 3px;\n}\n\n   
                .div-image .day-number {\n 
                  float: left;\n 
                  font-size: 11px;\n 
                  font-weight: 500;\n 
                  color: ${colors.textPrimary};\n 
                }\n
                 .div-image .day-number.current-month {
                    border: 1px solid ${colors.lightGray};\n 
                    border-radius: 10px;\n 
                    width: 20px;\n 
                    height: 20px;\n 
                    justify-content: center;\n 
                    align-items: center;\n 
                    display: flex;\n 
                 } ` }} />
                <div style={{ padding: 5, backgroundColor: colors.white }} className="div-image mockup-a4-landscape">
                  <div style={{ display: 'grid', gridTemplateColumns: 'auto  ', alignItems: 'center', paddingTop: '16px' }}>
                    <div className="bg-primary" style={{ color: colors.white, padding: '4px 8px', fontSize: '16px', margin: '0 8px' }}>
                      {t('DOCTOR_SCHEDULE.SHARE.DOCTOR_TABLE')}  {t('DOCTOR_SCHEDULE.SHARE.MONTH')}: {getNameMonthByNo(parseInt(month), lang)} {getYear(year)} <span style={{ paddingLeft: '4px' }}></span>
                    </div>
                  </div>
                  <div className="grid-item" style={{ padding: '14px 8px 8px' }}>
                    <div className="day-text day-of-week-header-cell">{t('DOCTOR_SCHEDULE.SHARE.SUNDAY')}</div>
                    <div className="day-text day-of-week-header-cell">{t('DOCTOR_SCHEDULE.SHARE.MONDAY')}</div>
                    <div className="day-text day-of-week-header-cell">{t('DOCTOR_SCHEDULE.SHARE.TUESDAY')}</div>
                    <div className="day-text day-of-week-header-cell">{t('DOCTOR_SCHEDULE.SHARE.WEDNESDAY')}</div>
                    <div className="day-text day-of-week-header-cell">{t('DOCTOR_SCHEDULE.SHARE.THURSDAY')}</div>
                    <div className="day-text day-of-week-header-cell">{t('DOCTOR_SCHEDULE.SHARE.FRIDAY')}</div>
                    <div className="day-text day-of-week-header-cell">{t('DOCTOR_SCHEDULE.SHARE.SATURDAY')}</div>
                  </div>
                  <div className="grid-item " style={{ padding: '4px 8px 16px', justifyContent: 'center' }}>
                    {_.map(calendarGridDayObjects, (i, index) => {
                      let daySchdule = dataSchedule.find((schedule: any) => schedule.date === i.dateString)
                      daySchdule = daySchdule?.schedule[0]
                      const dayOfMonth = i.dayOfMonth
                      const isCurrentMonth = i.isCurrentMonth

                      return daySchdule && (
                        <div style={{ backgroundColor: getColorStatus(daySchdule?.statusDoctorSchedule, 'BG'), height: '100%' }}>
                          <div className="  day">
                            <span className="day-number current-month">{dayOfMonth}</span>
                            {daySchdule.statusDoctorSchedule === 'NORMAL' ? (
                              <div className=" d-flex overflow-hidden">
                                <div style={{ paddingLeft: 3 }} className=" name-doctor text-nowrap-ellipsis -overflow-hidden ">
                                  <p className="mb-0 fc-main  text-nowrap-ellipsis">
                                    <strong>{String(daySchdule.doctorFullname || '')}</strong>
                                  </p>
                                  <p className="mb-0 text-muted time-doctor text-nowrap-ellipsis">
                                    {daySchdule.statusDoctorSchedule === 'NORMAL' ? (
                                      <div style={{ fontSize: 10, color: colors.textExtraLightGray }}>
                                        {`${moment(String(daySchdule.timeStart || '00:00'), 'HH:mm').format('HH.mm น.')} - ${moment(String(daySchdule.timeEnd || '00:00'), 'HH:mm').format('HH.mm น.')}`} ({daySchdule.countAppoints} {t('DOCTOR_SCHEDULE.MEETUP')})
                                      </div>
                                    ) : (
                                      <div style={{ fontSize: 14, height: '100%', justifyItems: 'center', color: getColorStatus(daySchdule.statusDoctorSchedule, 'TEXT'), fontWeight: 'bold' }}>{daySchdule.statusDoctorSchedule ? `(${t(`DOCTOR_SCHEDULE.STATUS.${daySchdule.statusDoctorSchedule}`)})` : '-'}</div>
                                    )}
                                  </p>
                                  {daySchdule.statusDoctorSchedule === 'NORMAL' && <>
                                    <p className="mb-0 fz-12 text-wrap  w-100">
                                      <strong>{t('DOCTOR_SCHEDULE.ASSISTANT')}</strong>: {daySchdule?.doctorScheduleAssistants?.map((as: any) => as.staffFullname).join(', ') || '-'}
                                    </p>
                                    {daySchdule.doctors.isInsure === '1' ? <p className="mb-0 fz-12 text-nowrap-ellipsis">
                                      <strong>{t('DOCTOR_SCHEDULE.GUARANTEE')}</strong>: {numberToCurrencyFormat(daySchdule.handInsurance)} {t('DOCTOR_SCHEDULE.CURRENCY')}
                                    </p> : <></>}
                                  </>}
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex flex-center" style={{ height: 'calc(100% - 32px)', marginTop: '-17px' }}>
                                {daySchdule.statusDoctorSchedule ? t(`DOCTOR_SCHEDULE.STATUS.${daySchdule.statusDoctorSchedule}`) : '-'}
                              </div>
                            )}
                          </div>
                        </div>
                      ) || (
                          <div className="day"  >
                            {isCurrentMonth ? <span className="day-number current-month">{dayOfMonth}</span> : <span className="day-number" />}
                          </div>
                        )

                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ div>
      }
      textBtnConfirm={''}
    />
  )
}

