import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { colors } from 'constants/theme'
import 'component/Select/Style.css'
import 'features/finance/income-expenses/style.css'
import { APPROVE_STATUS } from 'api/warehouses/adjustment.api'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

type StatusSelectProps = {
  labelId: string
  selectId: string
  label: string
  value?: any
  onchange?: (value: any) => void
  onclose?: (value: any) => void
  onclick?: (value: any) => void
  fullWidth?: boolean
  required?: boolean
  formControlClass?: any
  name?: any
  defaultValue?: any
  disable?: boolean
  statusSelectorBgColor: string
  height?: string
  hidePendingOption?: boolean
  permissionDenied?: boolean
}

export default function StatusSelect(props: StatusSelectProps) {
  const { t } = useTranslation()


  return (
    <FormControl
      className={`${props.formControlClass}`}
      size="small"
      margin="none"
      fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
      disabled={props.disable}
      sx={{
        '.Mui-focused fieldset, fieldset': {
          borderColor: `${props.statusSelectorBgColor} !important`
        }
      }}>
      <InputLabel id={props.labelId} required={props.required}>{`${props.label}`}</InputLabel>
      <Select
        defaultValue={props.defaultValue}
        style={{ backgroundColor: props.statusSelectorBgColor, color: colors.white, border: 'none' }}
        sx={{
          height: props.height || '44px',
          '.MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            WebkitTextFillColor: 'unset',
            height: props.height || '44px',
            paddingTop: 0,
            paddingBottom: 0
          },
          svg: {
            fill: props.value === APPROVE_STATUS.APPROVE || props.value === APPROVE_STATUS.CANCEL ? colors.white70 : colors.white
          }
        }}
        name={props.name}
        labelId={props.labelId}
        id={props.selectId}
        value={props.value}
        label={props.label}
        onChange={props.onchange}
        onClose={props.onclose}
        onClick={props.onclick}
        multiple={false}
        className="status-income-expenses"
        MenuProps={{
          classes: { paper: `style-filter-select-search custom-scroll` },
          PaperProps: {
            sx: {
              '.MuiMenuItem-root': {
                '&:hover': {
                  backgroundColor: `${colors.themeSecondColor06} !important`,
                  color: colors.themeSecondColor
                }
              },
            },
          },
        }}>
        {
          [
            <MenuItem key="1" value="PENDING_APPROVAL" className={`${!props.permissionDenied ? 'pe-none' : props.value === APPROVE_STATUS.PENDING && 'pe-none'}`} sx={{ opacity: !props.permissionDenied ? .3 : props.value === APPROVE_STATUS.PENDING ? .3 : 1 }}>
              {t('SALARY.DOCUMENT_STATUS.PENDING_APPROVAL')}
            </MenuItem>,
            <MenuItem key="2" value="UNAPPROVAL" className={`${!props.permissionDenied ? 'pe-none' : props.value === APPROVE_STATUS.CANCEL && 'pe-none'}`} sx={{ opacity: !props.permissionDenied ? .3 : props.value === APPROVE_STATUS.CANCEL ? .3 : 1 }}>
              {t('SALARY.DOCUMENT_STATUS.UNAPPROVAL')}
            </MenuItem>,
            <MenuItem key="3" value="APPROVAL" className={`${!props.permissionDenied ? 'pe-none' : props.value === APPROVE_STATUS.APPROVE && 'pe-none'}`} sx={{ opacity: !props.permissionDenied ? .3 : props.value === APPROVE_STATUS.APPROVE ? .3 : 1 }}>
              {t('SALARY.DOCUMENT_STATUS.APPROVAL')}
            </MenuItem>
          ]}
      </Select>
    </FormControl >
  )
}
