import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router'
import { Typography, Box, MenuItem, Grid, Divider, IconButton, Collapse, TableRow, TableCell, Avatar, Chip, styled, ClickAwayListener } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** API */
import { clinicTheme, fileUrl } from 'api/api'

/** STYLE */
import * as UseStyled from 'features/setting/doctor-fee-managements/doctor-fee/style'
import * as TemplateDFUseStyled from 'features/setting/doctor-fee-managements/template-doctor-fee/style'
import { colors } from 'constants/theme'
import { imgs } from 'constants/images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

/** UTILS || SLICE */
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { DF_UNIT } from 'features/setting/doctor-fee-managements/template-doctor-fee/form-template-doctor-fee'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import BasicSelect from 'component/Select/BasicSelect'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TemplateCreditFeeApi from 'api/setting/credit-fee-managements/template-credit-fee.api'
import CreditFeeApi, { CreditFee } from 'api/setting/credit-fee-managements/credit-fee.api'
import InputCheckbox from 'component/Input/InputCheckbox'

type errorMessage = {
  EMPTY_DF_NAME?: string
  EMPTY_DATE_START?: string
  EMPTY_TEMPLATE_DF?: string
  EMPTY_DOCTOR?: string
  EMPTY_DF_AMOUNT?: string
  EMPTY_LAB_AMOUNT?: string
}

const CustomFilterDoctor = styled(Box)<{ open?: boolean }>(({ theme, open }) => ({
  '.MuiOutlinedInput-input': {
    marginTop: '-1px',
    padding: `4px 0 0 ${clinicTheme.theme === clinicTheme.type.DENTAL ? '90px' : '60px'}  !important`
  },
  '.Mui-multiple': {
    '.MuiFormControl-root': {
      '.MuiOutlinedInput-root': {
        marginTop: 0,
        padding: '4px 0 4px 12px !important',
        // flexWrap: 'nowrap'
      },
      '.MuiOutlinedInput-input': {
        height: open ? 'auto' : 0,
        minWidth: 'unset',
        padding: '0 !important'
      }
    }
  },
  '.edit-filter-doctor': {
    zIndex: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    left: '12px'
  }
}))

export default function FormCreditFee() {
  const { t } = useTranslation()
  const { id: creditFeeId }: any = useParams()
  const history = useHistory()

  const [creditFees, setDoctorFees] = useState([])

  const [doctorsId, setDoctorsId] = useState([])
  const [doctors, setDoctors] = useState([])

  const [creditFeeOperativeId, setDoctorFeeOperativeId] = useState(0)
  const units: DF_UNIT[] = [DF_UNIT.PERCENT, DF_UNIT.BATH]
  const [operativeTypes, setOperativeTypes] = useState([])
  const [creditFeeOperatives, setCreditFeeOperatives] = useState<CreditFee>({
    creditFeeName: '',
    templateCreditFeeId: 0,
    dateStart: '',
    amount: 0,
    creditFeeUsers: [],
    creditFeeOperativeTypes: [],
    templateClinicFeeAmount: 0
  })
  const [status, setStatus] = useState('')
  const [templateCreditFees, setTemplateCreditFees] = useState([])
  const [errorMessage, setErrorMessage] = useState<errorMessage>({})
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  const [creditFee, setCreditFee] = useState<number>(0)

  const onSubmitEdit = () => {
    const { creditFeeName, dateStart, templateCreditFeeId, creditFeeUsers, amount } = creditFeeOperatives

    if (!creditFeeName || !dateStart || !templateCreditFeeId || _.isEmpty(creditFeeUsers) || (amount === undefined && amount !== 0)) {
      return setErrorMessage({
        EMPTY_DF_NAME: !creditFeeOperatives.creditFeeName ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        EMPTY_DATE_START: !creditFeeOperatives.dateStart ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_DATE_START') : '',
        EMPTY_TEMPLATE_DF: !creditFeeOperatives.templateCreditFeeId ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_TEMPLATE_DF') : '',
        EMPTY_DOCTOR: _.isEmpty(creditFeeOperatives.creditFeeUsers) ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_DOCTOR') : '',
        EMPTY_DF_AMOUNT: !creditFeeOperatives.amount && creditFeeOperatives.amount !== 0 ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_DF_AMOUNT') : ''
      })
    }

    const rawDoctorFeeOperatives = { ...creditFeeOperatives, status: status, creditFee: creditFee }
    CreditFeeApi.update(creditFeeOperativeId, rawDoctorFeeOperatives)
      .then(() => {
        notiSuccess(t('CREDIT_FEE.MESSAGE.SUCCESS.UPDATE'), '', null, null)
        history.push(routeName.creditFee)
      })
      .catch((e) => {
        if (e.response.data.message === 'IS_DUPLICATE_CREDIT_FEE_NAME') return notiError(t('CREDIT_FEE.MESSAGE.ERROR.IS_DUPLICATE_CREDIT_FEE_NAME'), '', null, null, '')
        else if (e.response.data.message === 'IS_DUPLICATE_DOCTOR') return notiError(t('CREDIT_FEE.MESSAGE.ERROR.IS_DUPLICATE_DOCTOR'), '', null, null, '')
        else return notiError(e.response.data.message, '', null, null, '')
      })
  }
  interface CreditFeeOperatives {
    creditFeeName: string
    dateStart: string
    templateCreditFeeId: number
    creditFeeUsers: any
    amount: number
  }
  const validateCreditFeeOperatives = (creditFeeOperatives: CreditFeeOperatives) => {
    const isCreditFeeNameValid = Boolean(creditFeeOperatives.creditFeeName)
    const isDateStartValid = Boolean(creditFeeOperatives.dateStart)
    const isTemplateCreditFeeIdValid = Boolean(creditFeeOperatives.templateCreditFeeId)
    const areCreditFeeUsersValid = !_.isEmpty(creditFeeOperatives.creditFeeUsers)
    const isAmountValid = creditFeeOperatives.amount === 0 || creditFeeOperatives.amount

    return {
      isCreditFeeNameValid,
      isDateStartValid,
      isTemplateCreditFeeIdValid,
      areCreditFeeUsersValid,
      isAmountValid
    }
  }

  const onSubmit = () => {
    const validationResults = validateCreditFeeOperatives(creditFeeOperatives)
    if (!Object.values(validationResults).every(Boolean)) {
      return setErrorMessage({
        EMPTY_DF_NAME: !validationResults.isCreditFeeNameValid ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        EMPTY_DATE_START: !validationResults.isDateStartValid ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_DATE_START') : '',
        EMPTY_TEMPLATE_DF: !validationResults.isTemplateCreditFeeIdValid ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_TEMPLATE_DF') : '',
        EMPTY_DOCTOR: !validationResults.areCreditFeeUsersValid ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_DOCTOR') : '',
        EMPTY_DF_AMOUNT: !validationResults.isAmountValid ? t('CREDIT_FEE.MESSAGE.ERROR.EMPTY_DF_AMOUNT') : ''
      })
    }

    CreditFeeApi.create({ ...creditFeeOperatives, creditFee: creditFee })
      .then(() => {
        notiSuccess(t('CREDIT_FEE.MESSAGE.SUCCESS.CREATE'), '', null, null)
        history.push(routeName.creditFee)
      })
      .catch((e) => {
        if (e.response.data.message === 'IS_DUPLICATE_CREDIT_FEE_NAME') return notiError(t('CREDIT_FEE.MESSAGE.ERROR.IS_DUPLICATE_CREDIT_FEE_NAME'), '', null, null, '')
        else if (e.response.data.message === 'IS_DUPLICATE_DOCTOR') return notiError(t('CREDIT_FEE.MESSAGE.ERROR.IS_DUPLICATE_DOCTOR'), '', null, null, '')
        else return notiError(e.response.data.message, '', null, null, '')
      })
  }

  const loadTemplateDoctorFee = () => {
    TemplateCreditFeeApi.findAll({ status: 'ACTIVE' })
      .then(({ data }) => setTemplateCreditFees(data))
      .catch((e) => {
        return
      })
      .catch((e) => {
        return
      })
    CreditFeeApi.findAll({ pageLimit: 100 })
      .then(({ data }) => setDoctorFees(data))
      .catch((e) => {
        return
      })
  }

  const loadDoctor = () => {
    CreditFeeApi.findAllDoctors()
      .then(({ data }) => {
        const rawDoctorsId: any = []
        _.map(data, (doc, index) => rawDoctorsId.push({ doctorId: doc.doctorId }))
        setDoctorsId(rawDoctorsId)
        setDoctors(data)
      })
      .catch((e) => {
        return
      })
  }

  const loadDoctorFee = () => {
    let creditFee = {}
    CreditFeeApi.findById(creditFeeId)
      .then(({ data }) => {
        const creditFeeUsers: any = []
        _.map(data.creditFeeUsers, (doctor, index) => {
          if (doctor.doctorId) creditFeeUsers.push({ doctorId: doctor.doctorId })
        })
        setDoctorFeeOperativeId(data.creditFeeId)
        setStatus(data.status)
        creditFee = {
          creditFeeName: data.creditFeeName,
          templateCreditFeeId: data.templateCreditFeeId,
          dateStart: data.dateStart,
          amount: data.amount,
          labDoctorAmount: data.labDoctorAmount,
          creditFeeUsers: creditFeeUsers,
          creditFeeOperativeTypes: [],
          templateClinicFeeAmount: data?.templateClinicFeeAmount || 0
        }
        setCreditFee(data?.creditFee || 0)
      })
      .catch((e) => {
        return
      })

    CreditFeeApi.findAllWithOperatives(creditFeeId)
      .then(({ data }) => {
        setOperativeTypes(data)
        if (!_.isEmpty(data)) handleChangeTemplateOperative('edit', data, creditFee, '')
      })
      .catch((e) => {
        return
      })
  }

  const loadTemplateOperative = (templateCreditFeeId: number) => {
    let templateDoctorFee = {}
    if (templateCreditFeeId) {
      TemplateCreditFeeApi.findById(templateCreditFeeId)
        .then(({ data }) => {
          templateDoctorFee = {
            creditFeeName: creditFeeOperatives.creditFeeName,
            templateCreditFeeId: templateCreditFeeId,
            dateStart: creditFeeOperatives.dateStart,
            amount: data.amount,
            labDoctorAmount: data.labDoctorAmount,
            creditFeeUsers: creditFeeOperatives.creditFeeUsers,
            creditFeeOperativeTypes: [],
            templateClinicFeeAmount: data?.templateClinicFeeAmount || 0
          }
          setCreditFee(data?.creditFee || 0)
        })
        .catch((e) => {
          return
        })
    }

    TemplateCreditFeeApi.findAllWithOperatives(templateCreditFeeId)
      .then(({ data }) => {
        setOperativeTypes(data)
        if (!_.isEmpty(data)) handleChangeTemplateOperative('changeTemplate', data, templateDoctorFee, '')
      })
      .catch((e) => {
        return
      })
  }
  const newData = (operativeId: any, creditAmount: number, creditUnit: any, creditBranch: number) => {
    return { operativeId: operativeId, creditAmount: creditAmount, creditUnit: creditUnit, creditBranch: creditBranch }
  }
  const newType = (operativeTypeId: any, creditAmount: number, creditFeeOperatives: any, creditBranch: number) => {
    return { operativeTypeId: operativeTypeId, creditAmount: creditAmount, creditFeeOperatives: creditFeeOperatives, creditBranch: creditBranch }
  }
  const handleChangeTemplateOperative = (type: 'add' | 'edit' | 'updateDF' | 'updateLab' | 'changeTemplate', operativesData: any, value?: any, valueUpdate?: any) => {
    const operativeTypes: any = []
    _.map(operativesData, (optType, index) => {
      let newDataOperativeType = {}
      const operative: any = []
      _.map(optType.operatives, (opt, indexOpt) => {
        let newDataOperative = {}

        switch (type) {
          case 'changeTemplate':
            newDataOperative = newData(opt.operativeId, Number(opt.templateCreditFeeOperatives?.creditAmount || 0), opt.templateCreditFeeOperatives?.creditUnit || DF_UNIT.PERCENT, opt?.templateCreditFeeOperatives?.creditBranch || 0);
            break;
          case 'updateDF':
            newDataOperative = newData(opt.operativeId, Number(valueUpdate), DF_UNIT.PERCENT, opt?.creditBranch || 0);
            break;
          case 'updateLab':
            newDataOperative = newData(opt.operativeId, Number(value.creditFeeOperativeTypes[index].creditFeeOperatives[indexOpt].amount), opt.creditFeeOperatives?.creditUnit || DF_UNIT.PERCENT, opt?.templateCreditFeeOperatives?.creditBranch || 0);
            break;
          default:
            newDataOperative = newData(opt.operativeId, Number(opt.creditFeeOperatives?.creditAmount) || 0, opt.creditFeeOperatives?.creditUnit || DF_UNIT.PERCENT, opt?.creditFeeOperatives?.creditBranch || 0);
        }
        operative.push(newDataOperative)
      })
      switch (type) {
        case 'changeTemplate':
          newDataOperativeType = newType(optType.operativeTypeId, Number(optType.templateCreditFeeOperativeTypes?.creditAmount || 0), operative, optType?.templateCreditFeeOperativeTypes?.creditBranch || 0);
          break;
        case 'updateDF':
          newDataOperativeType = newType(optType.operativeTypeId, Number(valueUpdate), operative, optType?.creditBranch || 0);
          break;
        case 'updateLab':
          newDataOperativeType = newType(optType.operativeTypeId, Number(value.creditFeeOperativeTypes[index].creditAmount), operative, optType?.templateCreditFeeOperativeTypes?.creditBranch || 0);
          break;
        default:
          newDataOperativeType = newType(optType.operativeTypeId, Number(optType.creditFeeOperativeTypes?.creditAmount) || 0, operative, optType?.creditFeeOperativeTypes?.creditBranch || 0);
      }
      operativeTypes.push(newDataOperativeType);
    })
    handleCreditFeeOperatives(value, operativeTypes)
  }
  const handleCreditFeeOperatives = (value: any, operativeTypes: any) => {
    const rawDoctorFeeOperatives: CreditFee = {
      creditFeeName: value.creditFeeName,
      templateCreditFeeId: value.templateCreditFeeId,
      dateStart: value.dateStart,
      amount: value.amount,
      creditFeeUsers: value.creditFeeUsers,
      creditFeeOperativeTypes: operativeTypes,
      templateClinicFeeAmount: value?.templateClinicFeeAmount || 0
    }
    setCreditFeeOperatives(rawDoctorFeeOperatives)
  }
  const handleChangeDefault = (event: any) => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'amount' || name === 'labDoctorAmount') {
      let stringValue = value === '' ? '0' : String(value).replace(/[^0-9.]/g, '') as any
      const strValueSplit = stringValue.split('.')
      if (strValueSplit.length === 1) {
        stringValue = Number(stringValue).toString()
      }
      if (strValueSplit.length >= 2 && strValueSplit[1].length > 3) {
        stringValue = stringValue.slice(0, -1)
      }
      if (Number(stringValue) > 3) {
        stringValue = '3'
      }
      value = stringValue
      event.target.value = Number(value)
      if (String(value).length === 1) {
        event.target.focus()
        event.target.type = 'string'
        event.target.setSelectionRange(String(value).length, String(value).length)
        event.target.type = 'number'
      }
    }

    setCreditFeeOperatives({ ...creditFeeOperatives, [name]: value })
    clearErrorMessage()
  }

  const handleChangeTemplate = (e: any, value: any) => {
    setCreditFeeOperatives({ ...creditFeeOperatives, templateCreditFeeId: value.templateCreditFeeId })
    clearErrorMessage()
    loadTemplateOperative(value.templateCreditFeeId)
  }

  const handleChangeDoctor = (e: any, value: any) => {
    setCreditFeeOperatives({ ...creditFeeOperatives, creditFeeUsers: value })
    clearErrorMessage()
  }

  const onUpdateDefaultDF = () => {
    const dfValue = creditFeeOperatives.amount
    handleChangeTemplateOperative('updateDF', operativeTypes, creditFeeOperatives, dfValue)
  }

  const clearErrorMessage = () => {
    setErrorMessage({})
  }

  const onChangeValueOperativeType = (event: any, index: number) => {
    const name = event.target.name
    let value = event.target.value
    let stringValue = value === '' ? '0' : String(value).replace(/[^0-9.]/g, '') as any
    const strValueSplit = stringValue.split('.')
    if (strValueSplit.length === 1) {
      stringValue = Number(stringValue).toString()
    }
    if (strValueSplit.length >= 2 && strValueSplit[1].length > 3) {
      stringValue = stringValue.slice(0, -1)
    }
    if (Number(stringValue) > 3) {
      stringValue = '3'
    }
    value = value === '' ? 0 : String(value).replace(/[^0-9.]/g, '')
    const number = Math.floor(Number(value) * 1000) / 1000
    value = Number(number) >= 3 ? 3 : Number(number)
    event.target.value = Number(value)
    if (String(value).length === 1) {
      event.target.focus()
      event.target.type = 'string'
      event.target.setSelectionRange(String(value).length, String(value).length)
      event.target.type = 'number'
    }

    const operativeType = _.merge({}, creditFeeOperatives)
    let selectOperative = _.merge({}, operativeType.creditFeeOperativeTypes[index])

    if (name === 'creditAmount') selectOperative = { ...selectOperative, [name]: stringValue, creditAmount: stringValue }
    else selectOperative = { ...selectOperative, [name]: value }
    _.map(selectOperative.creditFeeOperatives, (opt: any) => {
      opt[name] = value
      opt.creditUnit = DF_UNIT.PERCENT
      if (name === 'creditAmount') opt.creditAmount = Number(value || 0)
    })
    operativeType.creditFeeOperativeTypes[index] = selectOperative
    setCreditFeeOperatives(operativeType)
  }

  const onChangeValueOperative = (event: any, typeIndex: number, index: number, operativePrice: number) => {
    const name = event.target.name
    let value = event.target.value
    let stringValue = value === '' ? '0' : String(value).replace(/[^0-9.]/g, '') as any
    const strValueSplit = stringValue.split('.')
    if (strValueSplit.length === 1) {
      stringValue = Number(stringValue).toString()
    }
    if (strValueSplit.length >= 2 && strValueSplit[1].length > 3) {
      stringValue = stringValue.slice(0, -1)
    }
    if (Number(stringValue) > 3) {
      stringValue = '3'
    }
    const number = Math.floor(Number(value) * 1000) / 1000
    event.target.value = Number(value)
    if (String(value).length === 1) {
      event.target.focus()
      event.target.type = 'string'
      event.target.setSelectionRange(String(value).length, String(value).length)
      event.target.type = 'number'
    }
    value = number
    if (name !== 'creditUnit') {
      value = value === '' ? 0 : String(value).replace(/[^0-9.]/g, '')
      value = Number(value)
      if (name === 'creditAmount') onChangeValueDfAmount(typeIndex, index, value, operativePrice, stringValue)
      if (name === 'labDoctorAmount') onChangeValueLabAmount(typeIndex, index, value)
    } else onChangeValueDfUnit(typeIndex, index, value, operativePrice, stringValue)
  }

  const calculateByPercent = (price: number, percent: number) => {
    return (Number(price) * Number(percent)) / 100
  }

  const onChangeValueDfAmount = (typeIndex: number, index: number, value: number, operativePrice: number, strValue?: any) => {
    const operativeType = _.merge({}, creditFeeOperatives)
    let selectOperative = _.merge({}, operativeType.creditFeeOperativeTypes[typeIndex].creditFeeOperatives[index])
    selectOperative = { ...selectOperative, creditAmount: strValue }

    operativeType.creditFeeOperativeTypes[typeIndex].creditFeeOperatives[index] = selectOperative
    setCreditFeeOperatives(operativeType)
  }

  const onChangeValueDfUnit = (typeIndex: number, index: number, value: DF_UNIT, operativePrice: number, strValue: any) => {
    const operativeType = _.merge({}, creditFeeOperatives)
    const selectOperativeType = _.merge({}, operativeType.creditFeeOperativeTypes[typeIndex])
    let selectOperative = _.merge({}, selectOperativeType.creditFeeOperatives[index])

    if (value === DF_UNIT.PERCENT) {
      selectOperative = {
        ...selectOperative,
        creditUnit: value,
        creditAmount: strValue === '0.0' || strValue === '0.00' ? strValue : selectOperativeType.creditAmount
      }
    } else {
      selectOperative = {
        ...selectOperative,
        creditUnit: value,
        creditAmount: calculateByPercent(operativePrice, selectOperativeType.creditAmount)
      }
    }

    operativeType.creditFeeOperativeTypes[typeIndex].creditFeeOperatives[index] = selectOperative
    setCreditFeeOperatives(operativeType)
  }

  const onChangeValueLabAmount = (typeIndex: number, index: number, value: number) => {
    const operativeType = _.merge({}, creditFeeOperatives)
    let selectOperative = _.merge({}, operativeType.creditFeeOperativeTypes[typeIndex].creditFeeOperatives[index])
    selectOperative = { ...selectOperative }
    operativeType.creditFeeOperativeTypes[typeIndex].creditFeeOperatives[index] = selectOperative
    setCreditFeeOperatives(operativeType)
  }

  const headCells = [
    { id: 'message', disablePadding: false, label: t('') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.CATEGORY') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.PRICE'), align: 'right' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.DF_DOCTOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.DF_BRANCH'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.UNIT'), align: 'center' }
  ]

  const creditFeeUsed = (doctorId: number) => {
    const doctor = _.find(doctors, { doctorId: doctorId }) ?? {}
    const creditFeeId = _.get(doctor, 'lastUsed')?.creditFeeId
    const creditFeeName: string = _.get(_.find(creditFees, { creditFeeId: creditFeeId }), 'creditFeeName')
    const date = _.get(doctor, 'lastUsed')?.dateStart
    return `${t('CREDIT_FEE.TITLE.LAST_USED_DF', { df: creditFeeName || '-' })} ${date ? t('CREDIT_FEE.TITLE.LAST_USED', { date: toBuddhistYear(moment(date), 'DD/MM/YYYY') }) : ''}`
  }

  const renderImage = (img: any) => {
    if (img) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  useEffect(() => {
    loadTemplateDoctorFee()
    loadDoctor()
  }, [])

  useEffect(() => {
    if (creditFeeId) loadDoctorFee()
  }, [creditFeeId])

  return (
    <div className={`container-card h-100 overflow-hidden`}>
      <div className="d-flex flex-column h-100 overflow-auto">
        <HeaderLabel
          text={creditFeeId ? t(`CREDIT_FEE.TITLE.EDIT_DEFAULT`) : t(`CREDIT_FEE.TITLE.ADD_DEFAULT`)}
          navigator={{ previousTo: routeName.creditFee, previousTitle: t('CREDIT_FEE.TITLE.DEFAULT'), currentTitle: creditFeeId ? t('CREDIT_FEE.TITLE.EDIT_DEFAULT') : t('CREDIT_FEE.TITLE.ADD_DEFAULT') }}
          goBack={routeName.creditFee}
          onSubmit={creditFeeId ? onSubmitEdit : onSubmit}
        />

        <Card className="border shadow-sm mx-4 mb-4 card">
          <Card.Body>
            <Grid container spacing={2}>
              <Grid item lg={6} className="w-100">
                <Box className="mt-2">
                  <InputTextField
                    autoSelect
                    key={'creditFeeName'}
                    name={'creditFeeName'}
                    label={t('CREDIT_FEE.TITLE.CF_NAME_LABEL')}
                    value={creditFeeOperatives.creditFeeName}
                    onchange={handleChangeDefault}
                    required={true}
                    helperText={errorMessage.EMPTY_DF_NAME ?? ''}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} className="d-none d-xl-block"></Grid>
              <Grid item sm={6} lg={4} className="w-100">
                <InputSecondNewDatePicker
                  required
                  label={t('CREDIT_FEE.FORM.INPUT_DATE')}
                  value={creditFeeOperatives.dateStart}
                  onchange={(value: any) => {
                    setCreditFeeOperatives({ ...creditFeeOperatives, dateStart: moment(value).format('YYYY-MM-DD') })
                    clearErrorMessage()
                  }}
                  helperText={errorMessage.EMPTY_DATE_START ?? ''}
                />
              </Grid>
              <Grid item sm={6} lg={4} className="w-100">
                <AutocompleteSelect
                  required
                  key={`templateDF`}
                  labelId="templateDF"
                  inputLabel={t('CREDIT_FEE.FORM.TEMPLATE_DF')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={templateCreditFees}
                  getOptionLabel={(option: any) => option.templateCreditFeeName}
                  renderOption={(props, option: any) => (
                    <Box key={option.templateCreditFeeId} component="li" {...props}>
                      {option.templateCreditFeeName}
                    </Box>
                  )}
                  onchange={handleChangeTemplate}
                  value={creditFeeOperatives.templateCreditFeeId ? _.find(templateCreditFees, { templateCreditFeeId: creditFeeOperatives.templateCreditFeeId }) : null}
                  disableClearable
                  errMessage={errorMessage.EMPTY_TEMPLATE_DF ?? ''}
                />
              </Grid>
            </Grid>
            <Box>
              <Typography className="my-3" sx={{ fontSize: '18px', fontWeight: 600 }}>
                {t('CREDIT_FEE.TITLE.DOCTOR_LIST')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} lg={4} className="w-100">
                  <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                    <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                      <AutocompleteSelect
                        multiple
                        required
                        open={openFilterDoctors}
                        labelId="creditFeeUsers"
                        key={'creditFeeUsers'}
                        inputLabel={t('CREDIT_FEE.FORM.DOCTOR_LIST')}
                        noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                        options={doctorsId}
                        getOptionLabel={(option: any) => _.get(_.find(doctors, { doctorId: option.doctorId }), 'fullname') || '-'}
                        renderOption={(props, option: any) => {
                          const isDoctorInCreditFeeUsers = _.some(creditFeeOperatives.creditFeeUsers, (i: any, index: number) => i.doctorId === option.doctorId)
                          const isDoctorInDoctors = _.some(doctors, (i: any, index: number) => {
                            return i.doctorId === option.doctorId && (i?.lastUsed?.creditFeeId && creditFeeId && i?.lastUsed?.creditFeeId !== Number(creditFeeId) || (i?.lastUsed !== null || i?.lastUsed) && !creditFeeId)
                          })

                          const pointerEventsValue = isDoctorInCreditFeeUsers || isDoctorInDoctors ? 'none' : ''
                          const backgroundColorValue = isDoctorInCreditFeeUsers ? colors.themeSecondColor06 : colors.white

                          let colorValue = null

                          if (isDoctorInCreditFeeUsers) {
                            colorValue = colors.themeSecondColor
                          } else if (isDoctorInDoctors) {
                            colorValue = colors.disabledGray
                          }

                          return (
                            <Box
                              key={option.doctorId}
                              component="li"
                              {...props}
                              style={{
                                pointerEvents: pointerEventsValue,
                                backgroundColor: backgroundColorValue,
                                color: colorValue
                              }}
                            >
                              {/* <span>{_.get(_.find(doctors, { doctorId: option.doctorId }), 'fullname') || '-'}</span> */}
                              <InputCheckbox label={_.get(_.find(doctors, { doctorId: option.doctorId }), 'fullname') || '-'} checked={!!creditFeeOperatives.creditFeeUsers.find(item => item.doctorId === option.doctorId)} className={'pe-none'} />
                            </Box>
                          )
                        }}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option: any, index: any) => (
                            <div key={`index-${index}`}>
                              <Chip label={_.get(_.find(doctors, { doctorId: option.doctorId }), 'fullname') || '-'} style={{ fontSize: '1rem' }} {...getTagProps({ index })} />
                            </div>
                          ))
                        }
                        onchange={handleChangeDoctor}
                        value={!_.isEmpty(creditFeeOperatives.creditFeeUsers) ? creditFeeOperatives.creditFeeUsers : []}
                        height={43}
                        errMessage={errorMessage.EMPTY_DOCTOR ?? ''}
                      />
                    </CustomFilterDoctor>
                  </ClickAwayListener>
                </Grid>
              </Grid>
              {!_.isEmpty(creditFeeOperatives.creditFeeUsers) && (
                <Grid container spacing={2} className="mt-0">
                  {_.map(creditFeeOperatives.creditFeeUsers, (doctor, indexDoctor) => {
                    return (
                      <Grid key={indexDoctor} item lg={6} xl={4} className="w-100">
                        <UseStyled.DentistBox className="d-flex align-items-center">
                          <Avatar src={renderImage(_.get(_.find(doctors, { doctorId: doctor.doctorId }), 'doctorAvata'))} sx={{ backgroundColor: colors.white }}>
                            <img src={imgs.defaultAvatar} className="w-100 h-100" alt="" />
                          </Avatar>
                          <Box className="mx-3 overflow-hidden">
                            <Typography className="text-ellipsis name">{_.get(_.find(doctors, { doctorId: doctor.doctorId }), 'fullname') || '-'}</Typography>
                            <Typography className="date mt-1">{creditFeeUsed(doctor.doctorId)}</Typography>
                          </Box>
                        </UseStyled.DentistBox>
                      </Grid>
                    )
                  })}
                </Grid>
              )}
            </Box>
            <Box>
              <Box className="d-flex align-items-center mt-4">
                <Typography className="mr-2" sx={{ fontSize: '20px', fontWeight: 600 }}>
                  {t('TEMPLATE_CREDIT_FEE.TITLE.MANAGE')}
                </Typography>
                <ButtonInfoSimple text={t('TEMPLATE_CREDIT_FEE.TITLE.TOOLTIP')} />
              </Box>
              <Box className="pl-md-3">
                <Grid container spacing={2} className="mt-0">
                  <Grid item xs={2} lg={1} className="w-100">
                    <Typography className="pt-sm-2">{t(`TEMPLATE_CREDIT_FEE.FORM.CREDIT_VALUE`)}</Typography>
                  </Grid>
                  <Grid item xs={10} lg={11} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3} lg={2} className='w-100'>
                        <InputTextField
                          key={'amount'}
                          name={'amount'}
                          label={t('ALL')}
                          value={creditFee}
                          onchange={(event) => {
                            let newInput = event.target.value
                            if (newInput >= 100) {
                              newInput = 100
                            }
                            setCreditFee(newInput)
                          }}
                          required={true}
                          hideControl
                          disabled
                          // helperText={errorMessage.EMPTY_DF_AMOUNT ?? ''}
                          type='number'
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} lg={2} className="w-100">
                        <InputTextField
                          autoSelect
                          type={'number'}
                          key={'amount'}
                          name={'amount'}
                          label={t(`TEMPLATE_CREDIT_FEE.FORM.DOCTOR_SECTION`)}
                          value={creditFeeOperatives.amount || 0}
                          onchange={handleChangeDefault}
                          required={true}
                          hideControl
                          helperText={errorMessage.EMPTY_DF_AMOUNT ?? ''}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} lg={2} className="w-100 pr-sm-2">
                        <BasicSelect
                          name="defaultUnit"
                          labelId="lb-defaultUnit"
                          selectId="sl-defaultUnit"
                          label=""
                          value={DF_UNIT.PERCENT}
                          options={_.map(units, (unit: string, index: number) => {
                            return (
                              <MenuItem key={index} value={unit}>
                                {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                              </MenuItem>
                            )
                          })}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3} sm={3} lg={2} className="w-100">
                        <InputTextField
                          key={'templateClinicFeeAmount'}
                          name={'templateClinicFeeAmount'}
                          label={t('TEMPLATE_CREDIT_FEE.FORM.CLINIC_FEE_AMOUNT')}
                          value={creditFeeOperatives.templateClinicFeeAmount}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} className="w-100 pl-md-4 d-none">
                        <ButtonCustom textButton={t('TEMPLATE_CREDIT_FEE.FORM.BUTTON_UPDATE_VALUE')} onClick={onUpdateDefaultDF} style={{ margin: 0 }} btnStyle={{ fontSize: 16, height: '43px' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
              <TemplateDFUseStyled.ResponsiveTable section={'default'}>
                <TableCustom
                  page={0}
                  pageLimit={0}
                  sortType={'ASC'}
                  sortBy={''}
                  rowCount={0}
                  textEmptyData={t('REGISTER.NO_ITEM')}
                  onSort={() => {
                    return
                  }}
                  setPageLimit={() => {
                    return
                  }}
                  setPage={() => {
                    return
                  }}
                  headCells={headCells}
                  rowsData={_.map(operativeTypes, (temp, index) => {
                    return (
                      <RenderData
                        key={index}
                        no={index}
                        row={temp}
                        headCells={headCells}
                        units={units}
                        creditFeeOperative={creditFeeOperatives?.creditFeeOperativeTypes[index]}
                        onChangeValueOperativeType={onChangeValueOperativeType}
                        onChangeValueOperative={onChangeValueOperative}
                      />
                    )
                  })}
                  hidePagination
                  tableFixedWidth
                />
              </TemplateDFUseStyled.ResponsiveTable>
            </Box>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

function RenderData(props: any) {
  const { t } = useTranslation()
  const { row, no, headCells, units, creditFeeOperative } = props
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} className="p-0" sx={{ backgroundColor: 'transparent !important' }}>
            {open ? <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20, transform: 'rotate(180deg)' }} /> : <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20 }} />}
          </IconButton>
        </TableCell>
        <TableCell>{row.operativeTypeName}</TableCell>
        <TableCell></TableCell>
        <TableCell>
          <InputTextField
            key={`creditAmount`}
            name={`creditAmount`}
            label={''}
            value={creditFeeOperative?.creditAmount}
            onchange={(e) => {
              if (props.onChangeValueOperativeType) props.onChangeValueOperativeType(e, no)
            }}
            disabled={true}
            hideControl
            type="number"
          />
        </TableCell>
        <TableCell>
          <InputTextField key={`creditAmount`} name={`creditAmount`} label={''} value={creditFeeOperative?.creditBranch} disabled type="number" hideControl />
        </TableCell>
        <TableCell>
          <BasicSelect
            key={`defaultUnit`}
            labelId="lb-defaultUnit"
            selectId="sl-defaultUnit"
            label=""
            value={DF_UNIT.PERCENT}
            options={_.map(units, (unit: string, index: number) => {
              return (
                <MenuItem key={index} value={unit}>
                  {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                </MenuItem>
              )
            })}
            onchange={() => {
              return
            }}
            disabled
          />
        </TableCell>
      </TableRow>
      <TableRow className="section-collapse">
        <TableCell colSpan={_.size(headCells)} className="p-0 border-0">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TemplateDFUseStyled.ResponsiveTable section="collapse">
              <TableCustom
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => {
                  return
                }}
                setPageLimit={() => {
                  return
                }}
                setPage={() => {
                  return
                }}
                headCells={headCells}
                rowsData={_.map(row.operatives, (opt, indexOpt) => {
                  return (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{opt.operativeName}</TableCell>
                      <TableCell align="right">{numberFormat(opt.salePrice || 0)}</TableCell>
                      <TableCell>
                        <InputTextField
                          key={`creditAmount`}
                          name={`creditAmount`}
                          label={''}
                          value={creditFeeOperative?.creditFeeOperatives[indexOpt]?.creditAmount}
                          onchange={(e) => {
                            if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                          }}
                          hideControl
                          type="number"
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell>
                        <InputTextField
                          key={`creditAmount`}
                          name={`creditAmount`}
                          label={''}
                          value={creditFeeOperative?.creditFeeOperatives[indexOpt]?.creditBranch}
                          // disabled={creditFeeOperative?.creditFeeOperatives[indexOpt]?.creditUnit === DF_UNIT.PERCENT}
                          hideControl
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell>
                        <BasicSelect
                          key={`creditUnit`}
                          name={`creditUnit`}
                          labelId="lb-defaultUnit"
                          selectId="sl-defaultUnit"
                          label=""
                          value={DF_UNIT.PERCENT}
                          options={
                            <MenuItem key={0} value={DF_UNIT.PERCENT}>
                              {'%'}
                            </MenuItem>
                          }
                          onchange={(e) => {
                            if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                          }}
                          disabled={true}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
                hidePagination
                tableFixedWidth
              />
            </TemplateDFUseStyled.ResponsiveTable>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
