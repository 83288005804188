import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { Box, Button, Drawer } from '@mui/material';
import closePopup from 'assets/icon/appointment/close.svg'

export const DentistBox = styled(Box)(({ theme }) => ({
  height: 80,
  border: `1px solid ${colors.extraLightGray}`,
  padding: '0 16px',
  borderRadius: 5,
  boxShadow: `0 3px 6px 0 ${colors.black10}`,
  '.MuiAvatar-root': {
    height: 56,
    width: 56,
    [theme.breakpoints.down('sm')]: {
      height: 48,
      width: 48,
    }
  },
  '.name': {
    fontSize: 18,
    fontWeight: 600,
    color: colors.themeMainColor
  },
  '.date': {
    fontSize: 14,
    color: colors.black60
  }
}))

export const HistoryList = styled(Drawer)(({ theme }) => ({
  zIndex: 2005,
  '.MuiBackdrop-root': {
    backgroundColor: colors.backdrop,
  },
  '.MuiPaper-root': {
    [theme.breakpoints.down(320)]: {
      width: '100%'
    }
  },
  '.history-list-box': {
    width: '280px',
    [theme.breakpoints.down(320)]: {
      width: '100%'
    }
  }
}))

export const FragmentLeft = styled(Box)(({ theme }) => ({
  flex: 'none',
  '.left-box': {
    boxShadow: `0 0 6px 0 ${colors.black10}`,
    borderRadius: 4,
    backgroundColor: colors.white,
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: '35%',
    height: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 280,
  }
}))

export const FragmentRight = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  backgroundColor: colors.white
}))

export const ProfileBox = styled(Box)(({ theme }) => ({
  '.MuiAvatar-root': {
    height: 48,
    width: 48,
    [theme.breakpoints.up('xs')]: {
      height: 60,
      width: 60,
    },
    [theme.breakpoints.up('sm')]: {
      height: 123,
      width: 123
    }
  },
  '.MuiTypography-root': {
    color: colors.textPrimary,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    }
  },
  [theme.breakpoints.up('xs')]: {
    borderBottom: `1px solid ${colors.lightGray}`,
  }
}))

export const DoctorFeeList = styled(Box)(({ theme }) => ({}))

export const ItemList = styled(Button)(({ theme }) => ({
  height: 32,
  border: 'none',
  boxShadow: 'none',
  color: colors.textPrimary,
  fontSize: 16,
  fontWeight: 400,
  '&:hover, &.active': {
    backgroundColor: colors.themeSecondColor06,
    color: colors.themeSecondColor
  }
}))

export const ButtonClosePopup = styled('button')(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: '12px',
  boxShadow: 'none !important',
  background: `url(${closePopup}) center/1em auto no-repeat`,
  opacity: 0.6,
  '&:hover': {
    opacity: 1
  },
}))

export const Header = styled(Box)(({ theme }) => ({
  height: 80,
  boxShadow: `0 3px 6px 0 ${colors.black05}`,
  border: `1px solid ${colors.borderHeaderCard}`,
  flex: 'none'
}))
