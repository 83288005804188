import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface DoctorNoteProps {
    typeName?: string,
    typeNameEn?: string,
    status?: string
}

export default class TemplateFormsTypeApi extends BaseAPI {

    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get('template-form-type', { params: { ...props } }).then((res) => res)
    }

    static create(payload: DoctorNoteProps) {
        return this.api.post('template-form-type', payload)
    }

    static update(id: number, body: any) {
        return this.api.patch(`template-form-type/${id}`, body)
    }
    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`template-form-type/${id}/status`, { id, status })
    }
    static remove(id: number) {
        return this.api.delete(`template-form-type/${id}`)
    }
}
