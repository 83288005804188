import React from 'react'
import { BoxCheckInSuccess } from './style'
import { DataCheckInProps } from './check-in'
import { Box } from '@mui/material'
import IconCheckInSuccess from 'assets/images/check-in/check-in-success'
import { ThemeIcon, colors } from 'constants/theme'
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useTranslation } from 'react-i18next'
import ButtonCustom from 'component/Button/ButtonCustom'
import { branchInfoProps } from 'features/counter/register/qr-code/form-qr-code'
import { toBuddhistYear } from 'utils/app.utils'
import moment from 'moment'

type CheckInSuccessProps = {
    dataSuccess: DataCheckInProps | undefined
    onReset: () => void
    branchesInfo: branchInfoProps
}

const CheckInSuccess = (props: CheckInSuccessProps) => {
    const { t, i18n } = useTranslation()
    const { dataSuccess, onReset, branchesInfo } = props

    const onSuccessCheckIn = () => {
        onReset()
    }

    const handleTextLang = (data: any, text: string, textEn?: string) => {
        if (!data) {
            return
        }
        if (i18n.language === 'en') {
            return textEn ? data[textEn] : data[`${text}En`] || ''
        }
        return data[text] || ''
    }

    return (
        <BoxCheckInSuccess>
            <Box className={`w-100`}>
                <Box className={`card-check-in-success`}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Box>
                            <IconCheckInSuccess fill={colors.themeSecondColor} />
                        </Box>
                        <Box className={`card-check-in-status`}>
                            {t('CHECK_IN.INPUT.CHECK_IN_SUCCESS')}
                        </Box>
                    </Box>
                    {dataSuccess &&
                        <Box className={`card-checkin-detail w-100`}>
                            <Box className={`card-patient-name`}>
                                {t('CHECK_IN.INPUT.SIR')}{handleTextLang(dataSuccess, 'patientfullName')}
                            </Box>
                            <Box className={`card-patient-detail`}>
                                <Box className={`card-container-patient-detail `}>
                                    <span>
                                        <EventIcon className={`card-patient-icon`} />
                                    </span>
                                    <span>
                                        {toBuddhistYear(moment(dataSuccess.date))} {t('TIME_UNIT')}
                                    </span>
                                </Box>
                                <Box className={`card-container-patient-detail `}>
                                    <span>
                                        <AccessTimeIcon className={`card-patient-icon`} />
                                    </span>
                                    <span>
                                        {dataSuccess.time_range}
                                    </span>
                                </Box>
                                <Box className={`card-container-patient-detail `}>
                                    <Box className={`card-patient-icon-treatment`}>
                                        <ThemeIcon.Treatment />
                                    </Box>
                                    <span style={{
                                        width: '100%',
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 4,
                                        WebkitBoxOrient: "vertical",
                                    }}>
                                        {handleTextLang(dataSuccess, 'serviceText')}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {dataSuccess?.servicePointName && dataSuccess?.servicePointName !== '' ?
                        <Box className={`card-checkin-footer-text`}>
                            <span>
                                {t('CHECK_IN.INPUT.DOCTOR_VISIT', { doctorName: `${handleTextLang(dataSuccess, 'prefix') || ''}${handleTextLang(dataSuccess, 'doctorfullName')}` })}
                            </span>
                            <span style={{ color: colors.themeSecondColor, fontWeight: '700', margin: '0 6px' }}>
                                {handleTextLang(dataSuccess, 'servicePointName') || dataSuccess?.servicePointName || ''}
                            </span>
                            <span>
                                {t('CHECK_IN.INPUT.THANK')}
                            </span>
                        </Box>
                        :
                        <Box className={`card-checkin-footer-text`}>
                            <span>
                                {t('CHECK_IN.INPUT.CONTACT')}
                            </span>
                            <span style={{ color: colors.themeSecondColor, fontWeight: '700', margin: '0 6px' }}>
                                {handleTextLang(branchesInfo, 'servicePointName') || branchesInfo?.servicePointName || ''}
                            </span>
                            <span>
                                {t('CHECK_IN.INPUT.SERVICE_POINT_THANK')}
                            </span>
                        </Box>
                    }
                </Box>
                <Box className={`btn-check-success`}>
                    <ButtonCustom
                        btnStyle={{ fontSize: '32px', maxWidth: '362px', width: '100%' }}
                        textButton={t('CHECK_IN.BUTTON.FINISH')}
                        variant={`outlined`}
                        onClick={() => {
                            onSuccessCheckIn()
                        }}
                    />
                </Box>
            </Box>

        </BoxCheckInSuccess>
    )
}

export default CheckInSuccess