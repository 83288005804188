import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/53.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth53(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 29.79, height: 100.28, viewBox: "0 0 29.79 100.28" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 29.79, height: 100.28, viewBox: "0 0 29.79 100.28" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12354" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5428"
                                d="M23.6617 67.9426C19.4915 59.7051 10.6057 58.967 5.49676 66.758C3.55942 69.708 1.65647 72.7687 0.113281 75.9606C5.09019 53.2946 5.04892 30.1254 6.04835 7.05846C6.16528 4.48775 8.5988 -1.75167 10.1296 1.06091C13.018 5.48661 16.451 38.9969 23.6617 67.9426Z"
                                transform={"translate(1 ,2)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5428"
                                    d="M23.6617 67.9426C19.4915 59.7051 10.6057 58.967 5.49676 66.758C3.55942 69.708 1.65647 72.7687 0.113281 75.9606C5.09019 53.2946 5.04892 30.1254 6.04835 7.05846C6.16528 4.48775 8.5988 -1.75167 10.1296 1.06091C13.018 5.48661 16.451 38.9969 23.6617 67.9426Z"
                                    transform={"translate(1 ,2)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12354" >
                                <path
                                    data-name="Path 5429"
                                    d="M2.74461 32.7452C0.565382 30.2536 -0.486016 24.7233 0.521249 20.7501C0.918555 19.1946 1.40588 17.6636 1.98075 16.1647C1.98782 16.1647 1.98686 16.1576 1.98686 16.1505C3.25645 12.8398 4.8946 9.62947 6.57669 6.52647C11.0117 -1.6652 19.9283 -1.67762 24.7768 6.17989C24.9211 6.40916 25.0584 6.64703 25.1971 6.89158C27.5663 11.1665 29.6771 18.7755 29.9259 28.2044C29.9619 29.4986 29.5751 30.7692 28.8242 31.8239C25.2363 36.8422 19.9031 39.6672 16.3366 40.5266C12.3062 41.4972 4.95822 35.1632 2.74461 32.7452Z"
                                    transform={"translate(-2 ,55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5430"
                                    d="M3.86926 32.6112C1.88893 30.3475 0.934395 25.3213 1.84938 21.7114C2.2098 20.2976 2.65247 18.906 3.17514 17.5438C3.18221 17.5438 3.18126 17.5377 3.18049 17.5314C4.33468 14.5226 5.82322 11.6061 7.35246 8.78644C11.3819 1.34258 19.4847 1.33112 23.8906 8.47139C24.0216 8.67983 24.1466 8.89535 24.2727 9.11812C26.4236 13.0024 28.3422 19.9166 28.5686 28.4849C28.6016 29.6608 28.2501 30.8154 27.5675 31.7736C24.3071 36.3334 19.4612 38.9016 16.2186 39.6819C12.5579 40.5636 5.88111 34.808 3.86926 32.6112Z"
                                    transform={"translate(-2 ,55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5431"
                                    d="M4.89732 31.5834C3.10002 29.5282 2.23242 24.9666 3.06315 21.6894C3.39047 20.4061 3.79238 19.1429 4.26682 17.9064C4.27217 17.9064 4.27217 17.9003 4.27122 17.894C5.31918 15.1632 6.67035 12.5159 8.05782 9.95566C11.716 3.19884 19.0711 3.18833 23.0705 9.66907C23.1893 9.85917 23.3028 10.0544 23.4171 10.2558C25.3703 13.783 27.1122 20.0593 27.3172 27.8365C27.3469 28.9039 27.0278 29.9519 26.4083 30.8216C23.4486 34.9609 19.0499 37.2924 16.1068 38.0007C12.7846 38.803 6.72308 33.5771 4.89732 31.5834Z"
                                    transform={"translate(-2 ,55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6534"
                                        d="M1.93475 1.93366C1.89208 1.55766 4.81966 53.8394 5.9562 56.2575C5.95886 56.4479 6.04953 56.2314 6.5802 56.2575C6.57884 52.6028 1.20835 2.83553 1.93475 1.93366Z"
                                        transform="translate(8 0)"
                                        style={{
                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-8 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-8 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154148" x1="16.4825" y1="78.7759" x2="14.2984" y2="91.2425" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154148" x1="16.4825" y1="87.521" x2="16.4825" y2="82.8973" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9384">
                                            <path transform={"translate(-1 1)"} d="M25.029 65.6561C20.986 57.67 12.3714 56.9544 7.41841 64.5077C5.54019 67.3676 3.69531 70.335 2.19922 73.4294C7.02424 51.4552 6.98423 28.9931 7.95316 6.63003C8.06652 4.13777 10.4258 -1.91124 11.9098 0.815512C14.7101 5.10615 18.0383 37.5938 25.029 65.6561Z" fill="#E3D1B4" />
                                            <path transform={"translate(-1 1)"} d="M2.81346 89.7014C0.700738 87.2858 -0.318574 81.9244 0.657952 78.0723C1.04313 76.5644 1.51558 75.08 2.07291 73.6269C2.07977 73.6269 2.07884 73.62 2.07884 73.6131C3.30968 70.4035 4.89784 67.2911 6.52859 64.2828C10.8283 56.3411 19.4727 56.3291 24.1733 63.9468C24.3131 64.1691 24.4463 64.3997 24.5808 64.6368C26.8776 68.7813 28.924 76.158 29.1652 85.2992C29.2001 86.5539 28.8252 87.7857 28.0972 88.8082C24.6188 93.6734 19.4483 96.4122 15.9906 97.2454C12.0832 98.1863 4.95952 92.0456 2.81346 89.7014Z" fill="#EFEDE7" />
                                            <path transform={"translate(-1 1)"} d="M3.90242 89.5717C1.98253 87.3771 1.05712 82.5043 1.94419 79.0046C2.2936 77.634 2.72277 76.2849 3.22949 74.9642C3.23634 74.9642 3.23542 74.9583 3.23468 74.9522C4.35364 72.0352 5.79676 69.2077 7.27933 66.4741C11.1858 59.2574 19.0413 59.2463 23.3127 66.1686C23.4398 66.3707 23.5609 66.5797 23.6832 66.7956C25.7685 70.5613 27.6285 77.2646 27.848 85.5714C27.88 86.7114 27.5392 87.8308 26.8774 88.7597C23.7165 93.1804 19.0186 95.6702 15.8749 96.4267C12.3259 97.2815 5.85288 91.7015 3.90242 89.5717Z" fill="#F7F7F7" />
                                            <path transform={"translate(-1 1)"} d="M4.90198 88.5751C3.15953 86.5826 2.31841 82.1603 3.12379 78.983C3.44112 77.7389 3.83076 76.5143 4.29072 75.3155C4.29591 75.3155 4.29591 75.3096 4.29499 75.3035C5.31097 72.656 6.6209 70.0895 7.96603 67.6074C11.5126 61.0568 18.6432 61.0466 22.5206 67.3295C22.6358 67.5138 22.7458 67.7032 22.8566 67.8984C24.7502 71.3179 26.4389 77.4027 26.6376 84.9426C26.6664 85.9774 26.3571 86.9934 25.7565 87.8366C22.8871 91.8496 18.6226 94.1099 15.7693 94.7966C12.5486 95.5743 6.67202 90.508 4.90198 88.5751Z" fill="white" />
                                            <path transform={"translate(-1 1)"} d="M21.414 75H11.586C10.1578 75 9 76.1578 9 77.586V87.414C9 88.8422 10.1578 90 11.586 90H21.414C22.8422 90 24 88.8422 24 87.414V77.586C24 76.1578 22.8422 75 21.414 75Z" fill="#C1C4C5" />
                                            {/* <path transform={"translate(-1 1)"} d="M21.416 75.6465H11.5879C10.5168 75.6465 9.64844 76.5148 9.64844 77.586V87.414C9.64844 88.4852 10.5168 89.3535 11.5879 89.3535H21.416C22.4871 89.3535 23.3554 88.4852 23.3554 87.414V77.586C23.3554 76.5148 22.4871 75.6465 21.416 75.6465Z" stroke="#C1C4C5" /> */}
                                            <path transform={"translate(-1 1)"} d="M15.3667 89.3314C15.1081 89.7995 17.5338 89.568 19.353 89.5447C22.0605 89.4878 23.6057 90.0413 23.5798 83.7909C23.6501 81.573 23.5814 79.353 23.3742 77.1437C22.6876 76.4765 23.5552 83.9138 21.7981 87.185C20.1405 89.5473 15.8102 88.8297 15.3667 89.3314Z" fill="#B0B1B1" />
                                            <path transform={"translate(-1 1)"} d="M19.0685 83.0352H13.8965C13.5394 83.0352 13.25 83.3246 13.25 83.6817V84.9746C13.25 85.3317 13.5394 85.6211 13.8965 85.6211H19.0685C19.4255 85.6211 19.715 85.3317 19.715 84.9746V83.6817C19.715 83.3246 19.4255 83.0352 19.0685 83.0352Z" fill="#CBCCCC" />
                                            <path transform={"translate(-1 1)"} d="M18.8146 77.6045H13.6426C13.2855 77.6045 12.9961 77.8939 12.9961 78.251V79.544C12.9961 79.901 13.2855 80.1905 13.6426 80.1905H18.8146C19.1716 80.1905 19.4611 79.901 19.4611 79.544V78.251C19.4611 77.8939 19.1716 77.6045 18.8146 77.6045Z" fill="#9E9E9E" />
                                            <path transform={"translate(-1 1)"} d="M19.0685 77.6045H13.8965C13.5394 77.6045 13.25 77.8939 13.25 78.251V79.544C13.25 79.901 13.5394 80.1905 13.8965 80.1905H19.0685C19.4255 80.1905 19.715 79.901 19.715 79.544V78.251C19.715 77.8939 19.4255 77.6045 19.0685 77.6045Z" fill="url(#paint0_linear_4126_154148)" />
                                            <path transform={"translate(-1 1)"} d="M19.0685 82.7783H13.8965C13.5394 82.7783 13.25 83.0678 13.25 83.4248V84.7178C13.25 85.0749 13.5394 85.3643 13.8965 85.3643H19.0685C19.4255 85.3643 19.715 85.0749 19.715 84.7178V83.4248C19.715 83.0678 19.4255 82.7783 19.0685 82.7783Z" fill="url(#paint1_linear_4126_154148)" />
                                            <path transform={"translate(-1 1)"} d="M1.9375 83.2787C1.9375 83.2787 12.956 78.3071 27.4397 82.247" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={"translate(-1 1)"} d="M1.9375 83.7885C10.2355 80.7935 19.0009 80.4303 27.4397 82.7318" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>53</TextNumberU>
        </Div >
    )
}
