import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface AppointmentDurationInterface {
  duration: any
  durationType: string
  durationMinute: any
  status: string
}

export default class AppointmentDurationApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('appointment-durations', { params: { ...props } }).then((res) => res)
  }

  static create(payload: any) {
    return this.api.post('appointment-durations', payload)
  }

  static findById(id: number): Promise<AppointmentDurationInterface> {
    return this.api.get(`appointment-durations/${id}`)
  }

  static update(id: number, body: AppointmentDurationInterface) {
    return this.api.patch(`appointment-durations/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`appointment-durations/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`appointment-durations/${id}`)
  }
}
