import { Box } from "@mui/material"
import BtnAdd from "assets/button/BtnAdd"
import ButtonCustom from "component/Button/ButtonCustom"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ArticleList from "./ArticleList"
import ManageWebApi from "api/application/manageWeb"
import Loading from "component/Loading"
import ArticleForm from "./ArticleForm"
import { notiError, notiSuccess } from "component/notifications/notifications"
import { ArticleProps } from "api/application/manageWeb-interface"
import { swalActive, swalDelete } from "component/Alert/Swal"


const Article = () => {
    const { t } = useTranslation()
    const t_ARTICLE = "MANAGE_WEB.ARTICLE"

    const [load, setLoad] = useState<boolean>(true)
    const [statusDel, setStatusDel] = useState<boolean>(false)
    const [statusAdd, setStatusAdd] = useState<boolean>(false)

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [Article, setArticle] = useState<any>([])
    const [dataEdit, setDataEdit] = useState<any>({})
    const [dataEditId, setDataEditId] = useState<any>({})

    const loadData = async () => {
        setLoad(true)
        await ManageWebApi.getArticle().then((res) => {
            setArticle(res.data)
        }).catch(() => {
            return
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    const onEdit = (status: boolean, item?: any) => {
        if (item) {
            setDataEdit(item)
            setDataEditId(item)
        }
        if (!status) {
            setDataEdit({})
            setDataEditId({})
        }
        setIsEdit(status)
    }

    const onSave = async () => {
        setStatusAdd(true)
        const id = dataEditId?.articleId || 0
        const newPayload: ArticleProps = dataEdit
        if (id === 0) {
            const res = await ManageWebApi.createArticle(newPayload)
            if (res === "pass") {
                onEdit(false)
                loadData()
                notiSuccess(t(`${t_ARTICLE}.SAVE_SUC`))
            } else {
                notiError(t(`${t_ARTICLE}.SAVE_ERROR`))
            }
        } else {
            const res = await ManageWebApi.updateArticle(id, newPayload)
            if (res === "pass") {
                onEdit(false)
                loadData()
                notiSuccess(t(`${t_ARTICLE}.SAVE_SUC`))
            } else {
                notiError(t(`${t_ARTICLE}.SAVE_ERROR`))
            }
        }
        setStatusAdd(false)
    }

    const onDel = async (id: number, name: string) => {
        setStatusDel(true)
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 400px">${t(t_ARTICLE + '.NOTI_DEL_START')}${t(t_ARTICLE + '.TITLE')}${t(t_ARTICLE + '.NOTI_DEL_END')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t(t_ARTICLE + '.NOTI_DEL_START')}${name}${t(t_ARTICLE + '.NOTI_DEL_END')}</p>`,
            async (res: any) => {
                if (res) {
                    if (id !== 0) {
                        const res = await ManageWebApi.delArticle(id)
                        if (res === "pass") {
                            notiSuccess(t(`${t_ARTICLE}.DEL_SUC`))
                        } else {
                            notiError(t(`${t_ARTICLE}.SAVE_ERROR`))
                        }
                    } else {
                        notiError(t(`${t_ARTICLE}.SAVE_ERROR`))
                    }
                    loadData()
                }
            }, 'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
        setStatusDel(false)

    }

    return (
        <>
            <Loading show={load} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                        {t(`${t_ARTICLE}.TITLE`)}
                    </Box>
                    {!isEdit &&
                        <Box>
                            <ButtonCustom
                                startIcon={<BtnAdd width='20px' height='20px' />}
                                textButton={t(`${t_ARTICLE}.BTN_ADD`)}
                                onClick={() => { onEdit(true) }}
                            />
                        </Box>
                        ||
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ButtonCustom
                                style={{ marginRight: '4px' }}
                                variant={'outlined'}
                                textButton={t(`${t_ARTICLE}.BTN_CANCEL`)}
                                onClick={() => { onEdit(false) }}
                            />
                            <ButtonCustom
                                textButton={t(`${t_ARTICLE}.BTN_SAVE`)}
                                disabled={statusAdd}
                                onClick={() => { onSave() }}
                            />
                        </Box>
                    }
                </Box>
                <Box sx={{ marginTop: '32px', height: '100%', overflow: 'auto' }}>
                    {!isEdit &&
                        <ArticleList dataList={Article} statusDel={statusDel} onEdit={onEdit} onDel={onDel} reLoad={loadData} />
                        ||
                        <ArticleForm dataEdit={dataEdit} setDataEdit={setDataEdit} />
                    }
                </Box>
            </Box >
        </>
    )
}

export default Article