import CreditFee from 'features/setting/credit-fee-managements/credit-fee/credit-fee'
import FormCreditFee from 'features/setting/credit-fee-managements/credit-fee/form-credit-fee'
import FormTemplateCreditFee from 'features/setting/credit-fee-managements/template-credit-fee/form-template-credit-fee'
import { menuThirds, menuInside } from 'constants/menus'
import TemplateCreditFee from 'features/setting/credit-fee-managements/template-credit-fee/template-credit-fee'
import HistoryDoctorUseFee from 'features/setting/doctor-fee-managements/doctor-fee/historyDoctorUseFee'

const routeDoctorFeeManagements = [
  {
    ...menuThirds.TEMPLATE_CREDIT_FEE,
    component: TemplateCreditFee,
    appBar: true
  },
  {
    ...menuThirds.TEMPLATE_CREDIT_FEE.name,
    key: menuThirds.TEMPLATE_CREDIT_FEE.key,
    textname: `เพิ่ม ${menuThirds.TEMPLATE_CREDIT_FEE.name}`,
    active: false,
    path: menuThirds.TEMPLATE_CREDIT_FEE.path + '/create',
    component: FormTemplateCreditFee,
    appBar: true
  },
  {
    ...menuThirds.TEMPLATE_CREDIT_FEE.name,
    key: menuThirds.TEMPLATE_CREDIT_FEE.key,
    textname: `แก้ไข ${menuThirds.TEMPLATE_CREDIT_FEE.name}`,
    active: false,
    path: menuThirds.TEMPLATE_CREDIT_FEE.path + '/:id',
    component: FormTemplateCreditFee,
    appBar: true
  },
  {
    ...menuThirds.CREDIT_FEE,
    component: CreditFee,
    appBar: true
  },
  {
    ...menuThirds.CREDIT_FEE.name,
    key: menuThirds.CREDIT_FEE.key,
    textname: `เพิ่ม ${menuThirds.CREDIT_FEE.name}`,
    active: false,
    path: menuThirds.CREDIT_FEE.path + '/create',
    component: FormCreditFee,
    appBar: true
  },
  {
    ...menuThirds.CREDIT_FEE.name,
    key: menuThirds.CREDIT_FEE.key,
    textname: `แก้ไข ${menuThirds.CREDIT_FEE.name}`,
    active: false,
    path: menuThirds.CREDIT_FEE.path + '/:id',
    component: FormCreditFee,
    appBar: true
  }
]

export default routeDoctorFeeManagements
