import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const buttonSlice = createSlice({
  name: 'button',
  initialState: {
    isDisabled: false
  },
  reducers: {
    setDisabledBtn: (state, action: PayloadAction<any>) => {
      state.isDisabled = action.payload
    }
  }
})

export const { setDisabledBtn } = buttonSlice.actions
export default buttonSlice.reducer
