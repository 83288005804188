import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/73.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 31.78, height: 100, viewBox: "0 0  31.78 104.5" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })


        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 31.78, height: 104.5, viewBox: "0 0  31.78 104.5" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>73</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12345">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5579"
                                d="M0.191406 5.86765C1.93094 8.14509 3.98733 9.85151 6.8162 12.7725C10.2866 16.363 14.1433 16.435 18.5422 12.6083C22.277 9.35985 24.1441 4.81104 25.7815 0.754883C25.1029 3.29422 24.7954 5.91841 24.8685 8.54584C25.2538 23.6935 22.8997 38.5932 20.9237 53.5316C20.0917 59.7729 21.1737 65.9485 21.7555 72.1314C21.9928 74.6789 21.5733 76.4177 18.7515 77.264C15.3146 76.0434 14.8066 72.7681 13.7112 70.0247C7.57338 54.5619 6.01002 38.179 3.72396 21.961C2.95247 16.4677 1.86235 11.0923 0.191406 5.86765Z"
                                transform={"translate(2 ,25)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />

                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5579"
                                    d="M0.191406 5.86765C1.93094 8.14509 3.98733 9.85151 6.8162 12.7725C10.2866 16.363 14.1433 16.435 18.5422 12.6083C22.277 9.35985 24.1441 4.81104 25.7815 0.754883C25.1029 3.29422 24.7954 5.91841 24.8685 8.54584C25.2538 23.6935 22.8997 38.5932 20.9237 53.5316C20.0917 59.7729 21.1737 65.9485 21.7555 72.1314C21.9928 74.6789 21.5733 76.4177 18.7515 77.264C15.3146 76.0434 14.8066 72.7681 13.7112 70.0247C7.57338 54.5619 6.01002 38.179 3.72396 21.961C2.95247 16.4677 1.86235 11.0923 0.191406 5.86765Z"
                                    transform={"translate(2, 25)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12345">
                                <path
                                    data-name="Path 5580"
                                    d="M31.4801 13.9043C32.2235 18.5171 31.786 24.044 30.7301 28.5837C29.8545 30.494 29.1641 32.4838 28.6684 34.5258C27.129 38.6694 25.374 43.3173 21.862 46.6361C17.7273 50.5459 14.1008 50.472 10.8371 46.804C8.17875 43.8198 6.24425 42.0765 4.60995 39.7495C2.63742 36.9459 2.35778 33.9687 0.475202 26.2703C0.279243 25.459 0.0674111 22.4696 0.0664313 21.5312C0.0456596 8.93433 12.9647 1.66875 14.4851 0.904314C17.8446 -0.784658 31.3294 12.9668 31.4801 13.9043Z"
                                    transform={"translate(-1 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5581"
                                    d="M30.0315 14.0338C30.7056 18.2151 30.3084 23.2243 29.3511 27.3408C28.5581 29.0721 27.9327 30.8754 27.4833 32.7259C26.0872 36.4825 24.4964 40.6956 21.3131 43.7049C17.5648 47.2483 14.2782 47.1816 11.3192 43.8566C8.90887 41.1524 7.15582 39.5712 5.67417 37.4617C3.88644 34.9207 3.63286 32.2219 1.92566 25.243C1.74715 24.5076 1.55667 21.7986 1.55471 20.948C1.53512 9.52964 13.2474 2.94346 14.6252 2.25036C17.6716 0.718738 29.8951 13.1847 30.0315 14.0338Z"
                                    transform={"translate(-1 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5582"
                                    d="M28.4263 14.5386C29.0235 18.2444 28.6716 22.684 27.8235 26.3305C27.1204 27.8648 26.5659 29.4629 26.1676 31.103C24.9317 34.4318 23.5212 38.1658 20.7004 40.8322C17.3789 43.9727 14.4661 43.9129 11.8446 40.9666C9.70864 38.5691 8.15488 37.1686 6.84195 35.2999C5.25743 33.0475 5.03285 30.6557 3.52064 24.4716C3.36171 23.82 3.19299 21.4189 3.19143 20.6655C3.17516 10.5467 13.553 4.71027 14.7748 4.09575C17.4735 2.73932 28.3052 13.7859 28.4263 14.5386Z"
                                    transform={"translate(-1 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6573"
                                        d="M2.43758 2.18867C2.42763 2.45025 3.30583 16.0867 3.42583 22.5139C3.45404 26.6992 8.91449 57.5293 9.07376 57.7262C9.05607 57.8816 3.96945 26.8081 4.09333 22.8059C4.17794 16.34 3.57349 2.33467 3.59561 2.18867C3.21156 2.13372 2.82164 2.13372 2.43758 2.18867Z"
                                        transform="translate(12 45)"
                                        style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.317 -1.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.317 -1.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154142" x1="16.8767" y1="19.7778" x2="14.6928" y2="32.2433" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154142" x1="16.8689" y1="29.1632" x2="16.8689" y2="24.5398" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path d="M3.60938 33.2687C5.32255 35.5116 7.34778 37.1922 10.1338 40.0689C13.5516 43.6051 17.3499 43.6759 21.6821 39.9072C25.3603 36.708 27.1991 32.2281 28.8117 28.2334C28.1434 30.7343 27.8406 33.3187 27.9126 35.9063C28.292 50.8244 25.9736 65.4984 24.0275 80.2104C23.2081 86.3571 24.2738 92.4392 24.8468 98.5284C25.0805 101.037 24.6673 102.75 21.8882 103.583C18.5034 102.381 18.0031 99.1555 16.9243 96.4536C10.8795 81.2252 9.33982 65.0904 7.0884 49.1182C6.3286 43.7081 5.255 38.4142 3.60938 33.2687Z" fill="#E3D1B4" />
                                            <path d="M31.465 13.6084C32.1972 18.1514 31.7663 23.5945 30.7264 28.0655C29.8641 29.9467 29.1841 31.9064 28.696 33.9175C27.1799 37.9983 25.4514 42.5758 21.9927 45.8443C17.9206 49.6948 14.3491 49.6221 11.1349 46.0097C8.51676 43.0706 6.61158 41.3538 5.00204 39.062C3.0594 36.3009 2.784 33.3688 0.929945 25.787C0.736955 24.9881 0.528333 22.044 0.527368 21.1198C0.506911 8.7138 13.2302 1.55831 14.7276 0.805459C18.0362 -0.857921 31.3166 12.6852 31.465 13.6084Z" fill="#EFEDE7" />
                                            <path d="M30.0375 13.7351C30.7014 17.8531 30.3102 22.7863 29.3675 26.8405C28.5865 28.5456 27.9705 30.3215 27.5279 32.144C26.153 35.8436 24.5863 39.9929 21.4512 42.9566C17.7597 46.4463 14.5229 46.3807 11.6087 43.106C9.23494 40.4428 7.50845 38.8855 6.04925 36.808C4.28861 34.3055 4.03887 31.6476 2.35754 24.7745C2.18173 24.0502 1.99414 21.3823 1.99221 20.5445C1.97291 9.2992 13.5077 2.81282 14.8646 2.13022C17.8649 0.621808 29.9032 12.8989 30.0375 13.7351Z" fill="#F7F7F7" />
                                            <path d="M28.4619 14.2341C29.0501 17.8837 28.7035 22.2561 27.8682 25.8473C27.1758 27.3583 26.6297 28.9323 26.2375 30.5475C25.0203 33.8259 23.6311 37.5033 20.8531 40.1293C17.5819 43.2221 14.7133 43.1633 12.1314 40.2617C10.0279 37.9005 8.49764 36.5211 7.20461 34.6808C5.64409 32.4626 5.42292 30.1069 3.93362 24.0166C3.7771 23.3749 3.61094 21.0102 3.60939 20.2681C3.59338 10.3027 13.8139 4.55469 15.0172 3.94947C17.6751 2.61359 28.3426 13.4928 28.4619 14.2341Z" fill="white" />
                                            <path d="M21.801 16H11.9725C10.5444 16 9.38672 17.1577 9.38672 18.5858V28.4142C9.38672 29.8423 10.5444 31 11.9725 31H21.801C23.229 31 24.3867 29.8423 24.3867 28.4142V18.5858C24.3867 17.1577 23.229 16 21.801 16Z" fill="#C1C4C5" />
                                            {/* <path d="M21.7991 16.6465H11.9706C10.8995 16.6465 10.0312 17.5147 10.0312 18.5858V28.4143C10.0312 29.4853 10.8995 30.3536 11.9706 30.3536H21.7991C22.8701 30.3536 23.7384 29.4853 23.7384 28.4143V18.5858C23.7384 17.5147 22.8701 16.6465 21.7991 16.6465Z" stroke="#C1C4C5" /> */}
                                            <path d="M15.7534 30.3284C15.4949 30.7964 17.9203 30.565 19.7394 30.5417C22.4467 30.4848 23.9917 31.0382 23.9658 24.7884C24.0361 22.5707 23.9674 20.3508 23.7603 18.1417C23.0737 17.4746 23.9413 24.9112 22.1842 28.1822C20.5268 30.5443 16.1969 29.8268 15.7534 30.3284Z" fill="#B0B1B1" />
                                            <path d="M19.2047 18.6064H14.0332C13.6761 18.6064 13.3867 18.8959 13.3867 19.2529V20.5458C13.3867 20.9028 13.6761 21.1922 14.0332 21.1922H19.2047C19.5617 21.1922 19.8511 20.9028 19.8511 20.5458V19.2529C19.8511 18.8959 19.5617 18.6064 19.2047 18.6064Z" fill="#9E9E9E" />
                                            <path d="M19.4625 18.6064H14.291C13.934 18.6064 13.6445 18.8959 13.6445 19.2529V20.5458C13.6445 20.9028 13.934 21.1922 14.291 21.1922H19.4625C19.8195 21.1922 20.1089 20.9028 20.1089 20.5458V19.2529C20.1089 18.8959 19.8195 18.6064 19.4625 18.6064Z" fill="url(#paint0_linear_4126_154142)" />
                                            <path d="M19.4547 24.6797H14.2832C13.9261 24.6797 13.6367 24.9691 13.6367 25.3261V26.619C13.6367 26.976 13.9261 27.2654 14.2832 27.2654H19.4547C19.8117 27.2654 20.1011 26.976 20.1011 26.619V25.3261C20.1011 24.9691 19.8117 24.6797 19.4547 24.6797Z" fill="#CBCCCC" />
                                            <path d="M19.4547 24.4209H14.2832C13.9261 24.4209 13.6367 24.7103 13.6367 25.0673V26.3602C13.6367 26.7172 13.9261 27.0067 14.2832 27.0067H19.4547C19.8117 27.0067 20.1011 26.7172 20.1011 26.3602V25.0673C20.1011 24.7103 19.8117 24.4209 19.4547 24.4209Z" fill="url(#paint1_linear_4126_154142)" />
                                            <path d="M1 22.1035C8.51707 22.5699 14.8724 24.1735 32.2407 22.2074" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path d="M1 21.5742C7.98833 22.0844 16.4136 23.669 32.2407 21.5903" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
