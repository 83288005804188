import moment from 'moment'
import InputTextField from 'component/Input/InputTextField'
import { styled } from '@mui/material/styles'
import localeProvider from 'antd/lib/locale/th_TH'
import locale from 'antd/es/date-picker/locale/th_TH'
import { ConfigProvider, DatePicker } from 'antd'
import { Box } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import CloseIcon from '@mui/icons-material/Close';

/** UTILS || SLICE */
import { toBuddhistYear } from 'utils/app.utils'

/** CONSTANT */
import { colors } from 'constants/theme'

const ContentWrap = styled('div')<{ inputHeight?: any }>(({ theme, inputHeight }) => ({
  position: 'relative',
  '&.has-text-field': {
    '.ant-picker': {
      position: 'absolute',
      top: inputHeight === 43 ? 22 : (inputHeight / 43) * 22,
      left: 0,
      right: 0,
      transform: 'translateY(-50%)'
    },
    '.MuiFormHelperText-root.Mui-error': {
      marginTop: -1
    },
    '.MuiOutlinedInput-input': {
      '-webkit-text-fill-color': 'unset !important',
      '&::placeholder': {
        color: colors.black60,
        opacity: 1
      }
    }
  }
}))

const CustomDatePicker = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '.ant-picker-input': {
    input: {
      fontSize: 0,
      width: 0
    },
    '.ant-picker-suffix': {
      margin: 0
    }
  },
  '.ant-picker-disabled': {
    cursor: 'default'
  },
  '.clear-value': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '12px',
    color: colors.black60
  }
}))

type PickerProps = {
  key?: string
  inputName?: string
  label?: string
  disabled?: boolean
  required?: boolean
  dateFormat?: string
  value?: string
  onChange: any
  disabledToday?: boolean
  disableFuture?: boolean
  disableTextField?: boolean
  dropdownClassName?: string
  textFiledComponent?: any
  helperText?: any
  inputHeight?: number
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year'
  allowClear?: boolean
  onClear?: any
  placeholder?: string
  showTime?: boolean
}

export default function InputDatePicker(props: PickerProps) {

  const disabledDate: any = (current: any) => {
    if (props.disableFuture && props.disabledToday) return current && current >= moment().startOf('day');
    else if (props.disableFuture) return current && current > moment().endOf('day');
    else return
  };

  return (
    <ContentWrap className={`${!props.disableTextField ? 'has-text-field' : ''}`} inputHeight={props?.inputHeight || 43}>
      {!props.disableTextField &&
        (props.textFiledComponent || (
          <InputTextField
            inputHeight={props?.inputHeight || 43}
            required={props?.required || false}
            disabled={props.disabled}
            key={props.key}
            name={props.inputName || ''}
            label={props.label}
            value={props.value ? toBuddhistYear(moment(props.value), `${props.dateFormat || 'DD-MM-YYYY'}`) : ''}
            helperText={props.helperText}
            placeholder={props?.placeholder || ''}
          />
        ))}
      <CustomDatePicker>
        <ConfigProvider locale={localeProvider}>
          <DatePicker
            locale={locale}
            dropdownClassName={`custom-date-picker ${props.showTime ? 'custom-date-time-picker' : ''} ${props.dropdownClassName || ''}`}
            disabled={props.disabled}
            suffixIcon={props.allowClear && props.value ? <></> : <EventIcon style={{ color: props.disabled ? colors.disabledGray : colors.themeSecondColor, width: 24, height: 24 }} />}
            format={`${props.dateFormat || 'DD-MM-YYYY'}`}
            value={props.value ? moment(props.value) : null}
            bordered={false}
            allowClear={false}
            onChange={props.onChange}

            picker={props.picker || 'date'}
            disabledDate={disabledDate}
            showTime={props.showTime}
          />
        </ConfigProvider>
        {props.allowClear && props.value && (
          <CloseIcon className='clear-value' onClick={props.onClear} />
        )}
      </CustomDatePicker>
    </ContentWrap>
  )
}
