import { Tab } from '@mui/material'
import { colors } from 'constants/theme'
import styled from 'styled-components'

export const CardProfileImg = styled('div')(({ theme }) => ({
  width: '150px',
  height: '150px',
  position: 'relative',
  '&:not(.disabled) .personal-figure': {
    cursor: 'pointer'
  },
  img: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderRadius: 100,
    objectFit: 'cover'
  },
  '.btn-add-profile': {
    position: 'absolute',
    right: 12,
    bottom: 0,
    width: 29,
    height: 29,
    color: colors.white,
    backgroundColor: `${colors.themeSecondColor} !important`,
    svg: {
      width: 16,
      height: 'auto'
    }
  }
}))
//
export const CustomTab = styled(Tab)<{ tabForm: string; selectLabel: string }>(({ theme, tabForm, selectLabel }) => ({
  fontSize: '16px',
  color: tabForm === selectLabel ? `${colors.themeSecondColor} !important` : colors.black,
  '.Mui-selected': {
    color: colors.themeSecondColor
  },
  '&.error': {
    color: `${colors.statusInactive} !important`,
  }
}))
export const DivAdd = styled('div')(({ theme }) => ({ color: colors.themeSecondColor, cursor: 'pointer', width: 'auto', fontSize: '1rem', '&.ac-hover:hover': { backgroundColor: colors.extraLightGray, borderRadius: '0.25rem' } }))
export const DivHeadSession = styled('div')(({ theme }) => ({ padding: '0.5rem', backgroundColor: colors.themeSecondColor10, width: '100%' }))
