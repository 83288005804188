import _ from 'lodash'
import { colors } from 'constants/theme'
import { useTranslation } from 'react-i18next'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { TableRow, TableCell, TableHead, TableSortLabel, Box } from '@mui/material'
import TableCustom from 'component/Table/TableCustom'
import * as UseStyled from 'features/report/useStyled'
import { ExportProps } from 'features/report/report-constants'
import moment from 'moment'
import { Padding } from '@mui/icons-material'
import { SelectorProcessProps } from './remittances'

interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  componentRef: any
  dataProcess: SelectorProcessProps,
  branchInfo: any
  filter: any
}

export default function RemittanceList(props: FcComponent) {
  const styleCellIncome = { '.MuiTableCell-root': { backgroundColor: '#d8e7f480 !important' } }
  const styleCellExpenses = { '.MuiTableCell-root': { backgroundColor: '#ffcdd380 !important' } }
  const { t } = useTranslation()
  const { DF, LAB, TREATMENTRIGHT, CASHSLIP, INCOME_EXPENSES } = props.dataProcess
  const headCells = [
    { id: 'message', disablePadding: false, width: '70%', labelKey: 'REPORT.TABLE.REMITTANCE.LIST', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMITTANCE.QTY', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, width: '10vw', labelKey: '', align: 'right', class: 'text-nowrap' }
  ]
  const header = [DF.isShow ? 'DF' : null, CASHSLIP.isShow ? t('REPORT.TABLE.INCOME_MONTH.CASH') : null, INCOME_EXPENSES.isShow ? t('REPORT.FILTER.INCOME') : null].filter(Boolean).join(', ')
  const calculateAdvance = JSON.parse(props.branchInfo)?.calculateAdvance
  const renderData = (data: any, exports: boolean) => {
    const total = data.rowDf?.length > 0 || data.rowExpenses?.length > 0 || data.rowTreatmentRight?.length > 0 ? _.sumBy(data.rowDf, 'price') + _.sumBy(data.rowExpenses, 'price') : 0
    const expenses = INCOME_EXPENSES.isShow ? _.sumBy(data.rowExpensesCash, 'price') + _.sumBy(data.rowExpensesTransfer, 'price') + _.sumBy(data.rowExpensesDebit, 'price') + _.sumBy(data.rowExpensesCredit, 'price') : 0
    const df = _.sumBy(data.rowDf, 'price')
    const treatmentRight = TREATMENTRIGHT.isProcess ? _.sumBy(data.rowTreatmentRight, 'price') : 0

    const sumAdvance = calculateAdvance === "INCREASE" ? _.sumBy(data.rowAdvance, 'price') || 0 : _.sumBy(data.rowPaidAdvance, 'price') || 0



    const totalIncome = _.sumBy(data.rowIncomeCash?.filter((item: { expenseGroup: string }) => item.expenseGroup === "INCOME"), 'treatment') +
      _.sumBy(data.rowIncomeTransfer?.filter((item: { expenseGroup: string }) => item.expenseGroup === "INCOME"), 'treatment') +
      _.sumBy(data.rowIncomeDebit?.filter((item: { expenseGroup: string }) => item.expenseGroup === "INCOME"), 'treatment')

    const income = CASHSLIP.isProcess ? _.sumBy(data.rowIncomeCash, 'treatment') : _.sumBy(data.rowDailyBalance, 'sumTreatment') +
      _.sumBy(data.rowDailyBalance, 'sumProduct') + treatmentRight + sumAdvance - (INCOME_EXPENSES.isProcess ? 0 : totalIncome)

    return !exports ? (
      <>
        {data.rowDailyBalance &&
          _.map(data.rowDailyBalance, (val: any, index: number) => {
            return (
              <>
                <TableRow sx={styleCellIncome}>
                  <TableCell align="left" scope="row" style={{ color: colors.blue, fontWeight: '800' }}>
                    {t('REPORT.TABLE.REMITTANCE.NET_DAILY')}
                  </TableCell>
                  <TableCell align="right" scope="row" style={{ color: colors.blue }}>
                    {numberFormat(val.sumTreatment + val.sumProduct + treatmentRight + sumAdvance - (INCOME_EXPENSES.isProcess ? 0 : totalIncome))}
                  </TableCell>
                  <TableCell align="right" scope="row" > </TableCell>
                </TableRow>

                <TableRow sx={styleCellIncome}>
                  <TableCell align="left" scope="row"  >
                    <Box style={{ paddingLeft: '1em' }}>{t('REPORT.TABLE.REMITTANCE.NET_OPERATIVE')}</Box>
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(val.sumTreatment)}
                  </TableCell>
                  <TableCell align="right" scope="row" > </TableCell>
                </TableRow>

                <TableRow sx={styleCellIncome}>
                  <TableCell align="left" scope="row">
                    <Box style={{ paddingLeft: '1em' }}> {t('REPORT.TABLE.REMITTANCE.NET_PRODUCT')}</Box>
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(val.sumProduct)}
                  </TableCell>
                  <TableCell align="right" scope="row" > </TableCell>
                </TableRow>

                {INCOME_EXPENSES.isShow && (
                  <TableRow sx={styleCellIncome}>
                    <TableCell align="left" scope="row">
                      <Box style={{ paddingLeft: '1em' }}>  {t('REPORT.TABLE.REMITTANCE.ANOTHER')}</Box>
                    </TableCell>
                    <TableCell align="right" scope="row">
                      {numberFormat(INCOME_EXPENSES.isProcess ? totalIncome : 0)}
                    </TableCell>
                    <TableCell align="right" scope="row" > </TableCell>
                  </TableRow>
                )}
              </>
            )
          })}

        <TableRow sx={styleCellIncome}>
          <TableCell align="left" scope="row" >
            <Box style={{ paddingLeft: '2em' }}> {t('REPORT.TABLE.REMITTANCE.CASH')}</Box>
          </TableCell>
          <TableCell align="right" scope="row">
            {numberFormat(
              INCOME_EXPENSES.isProcess
                ? _.sumBy(data.rowIncomeCash, 'treatment') || 0
                : _.sumBy(data.rowIncomeCash?.filter((item: { expenseGroup: string }) => item.expenseGroup !== "INCOME"), 'treatment') || 0
            )}
          </TableCell>

          <TableCell align="right" scope="row" > </TableCell>
        </TableRow>

        {_.map(data.rowIncomeCash, (val: any, index: number) => {
          // If isShow is false or the detail is "INCOME", skip rendering
          if (!INCOME_EXPENSES.isShow && val.expenseGroup === "INCOME") {
            return null;
          }
          return (
            <TableRow key={index} sx={styleCellIncome}>
              <TableCell align="left" scope="row" sx={{}}>
                <Box style={{ paddingLeft: '3em' }}>- {val.detail || t('REPORT.TITLE.TREATMENT_PRODUCT')}</Box>
              </TableCell>
              <TableCell align="right" scope="row" sx={{}}>
                {numberFormat(val.treatment)}
              </TableCell>
              <TableCell align="right" scope="row"></TableCell>
            </TableRow>
          );
        })}


        <TableRow sx={styleCellIncome}>
          <TableCell align="left" scope="row" >
            <Box style={{ paddingLeft: '2em' }}> {t('REPORT.TABLE.REMITTANCE.TRANSFER_')}</Box>
          </TableCell>
          <TableCell align="right" scope="row" >
            {numberFormat(_.sumBy(data.rowIncomeTransfer, 'treatment') || 0)}
          </TableCell>
          <TableCell align="right" scope="row" > </TableCell>
        </TableRow>
        {
          _.map(data.rowIncomeTransfer, (val: any, index: number) => {
            // If isShow is false or the detail is "INCOME", skip rendering
            if (!INCOME_EXPENSES.isShow && val.expenseGroup === "INCOME") {
              return null;
            }

            return (
              <>
                {val.treatment > 0 && (
                  <TableRow sx={styleCellIncome}>
                    <TableCell align="left" scope="row" sx={{}}>
                      <Box style={{ paddingLeft: '3em' }}>-  {t('REPORT.TABLE.REMUNERATION.PRODUCT_TREATMENT')} ({val.detail})</Box>
                    </TableCell>
                    <TableCell align="right" scope="row" sx={{}}>
                      {numberFormat(val.treatment || 0)}
                    </TableCell>
                    <TableCell align="right" scope="row" > </TableCell>
                  </TableRow>
                )}
              </>
            )
          })
        }

        <TableRow sx={styleCellIncome}>
          <TableCell align="left" scope="row" >
            <Box style={{ paddingLeft: '2em' }}>  {t('REPORT.TABLE.REMITTANCE.DEBIT')}</Box>
          </TableCell>
          <TableCell align="right" scope="row" >
            {numberFormat(_.sumBy(data.rowIncomeDebit, 'treatment'))}
          </TableCell>
          <TableCell align="right" scope="row" > </TableCell>
        </TableRow>
        {
          _.map(data.rowIncomeDebit, (val: any, index: number) => {

            // If isShow is false or the detail is "INCOME", skip rendering
            if (!INCOME_EXPENSES.isShow && val.expenseGroup === "INCOME") {
              return null;
            }

            return (
              <>
                {val.treatment > 0 && (
                  <TableRow sx={styleCellIncome}>
                    <TableCell align="left" scope="row">
                      <Box style={{ paddingLeft: '3em' }}> -  {t('REPORT.TABLE.REMUNERATION.PRODUCT_TREATMENT')} ({val.detail})</Box>

                    </TableCell>
                    <TableCell align="right" scope="row">
                      {numberFormat(val.treatment)}
                    </TableCell>
                    <TableCell align="right" scope="row" > </TableCell>
                  </TableRow>
                )}
              </>
            )
          })
        }

        <TableRow sx={styleCellIncome}>
          <TableCell align="left" scope="row" >
            <Box style={{ paddingLeft: '2em' }}>{t('REPORT.TABLE.REMITTANCE.CREDIT')}</Box>
          </TableCell>
          <TableCell align="right" scope="row" >
            {numberFormat(_.sumBy(data.rowIncomeCredit, 'treatment') || 0)}
          </TableCell>
          <TableCell align="right" scope="row" > </TableCell>
        </TableRow>
        {
          _.map(data.rowIncomeCredit, (val: any, index: number) => {

            // If isShow is false or the detail is "INCOME", skip rendering
            if (!INCOME_EXPENSES.isShow && val.expenseGroup === "INCOME") {
              return null;
            }

            return (
              <>
                {val.treatment > 0 && (
                  <TableRow sx={styleCellIncome}>
                    <TableCell align="left" scope="row" >
                      <Box style={{ paddingLeft: '3em' }}>-  {t('REPORT.TABLE.REMUNERATION.PRODUCT_TREATMENT')} ({val.detail})</Box>
                    </TableCell>
                    <TableCell align="right" scope="row" >
                      {numberFormat(val.treatment || 0)}
                    </TableCell>
                    <TableCell align="right" scope="row" > </TableCell>
                  </TableRow>
                )}
              </>
            )
          })
        }

        {/* rowTreatmentRight */}
        {
          TREATMENTRIGHT.isShow &&
          <TableRow sx={styleCellIncome}>
            <TableCell align="left" scope="row" >
              <Box style={{ paddingLeft: '2em' }}> {t('REPORT.TABLE.REMITTANCE.TREATMENT_RIGHT')}</Box>
            </TableCell>
            <TableCell align="right" scope="row" >
              {numberFormat(_.sumBy(data.rowTreatmentRight, 'price') || 0)}
            </TableCell>
            <TableCell align="right" scope="row" > </TableCell>
          </TableRow>
        }
        {
          TREATMENTRIGHT.isShow &&
          _.map(data.rowTreatmentRight, (val: any, index: number) => {
            return (
              <>
                <TableRow sx={styleCellIncome}>
                  <TableCell align="left" scope="row" >
                    <Box style={{ paddingLeft: '3em' }}> - {val.treatmentRightName}</Box>
                  </TableCell>
                  <TableCell align="right" scope="row" >
                    {numberFormat(val.price || 0)}
                  </TableCell>
                  <TableCell align="right" scope="row" > </TableCell>
                </TableRow>

              </>
            )
          })
        }


        {/* rowAdvance  */}
        <TableRow sx={styleCellIncome}>
          <TableCell align="left" scope="row">
            <Box style={{ paddingLeft: '2em', color: calculateAdvance === 'INCREASE' ? 'rgba(135, 129, 129, 0.8)' : '' }}>
              {t('REPORT.TABLE.REMITTANCE.ADVANCE_PAID')}
              {calculateAdvance === 'INCREASE' ? t('REPORT.TABLE.REMITTANCE.NOT_PROGRESS') : ''}
            </Box>
          </TableCell>
          <TableCell align="right" scope="row" style={{ color: calculateAdvance === 'INCREASE' ? 'rgba(135, 129, 129, 0.8)' : '' }}>
            {numberFormat(_.sumBy(data.rowPaidAdvance, 'price') || 0)}
          </TableCell>
          <TableCell align="right" scope="row" />
        </TableRow>

        <TableRow sx={styleCellIncome}>
          <TableCell align="left" scope="row">
            <Box style={{ paddingLeft: '2em', color: calculateAdvance === 'DECREASE' ? 'rgba(135, 129, 129, 0.8)' : '' }}>
              {t('REPORT.TABLE.REMITTANCE.ADVANCE_NOT_PROGRESS')}
              {calculateAdvance === 'DECREASE' ? t('REPORT.TABLE.REMITTANCE.NOT_PROGRESS') : ''}
            </Box>
          </TableCell>
          <TableCell align="right" scope="row" style={{ color: calculateAdvance === 'DECREASE' ? 'rgba(135, 129, 129, 0.8)' : '' }}>
            {numberFormat(_.sumBy(data.rowAdvance, 'price') || 0)}
          </TableCell>
          <TableCell align="right" scope="row" />
        </TableRow>


        {/*  Expenses */}
        {
          INCOME_EXPENSES.isShow && (
            <>
              <TableRow sx={styleCellExpenses}>
                <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  <Box>{t('REPORT.TABLE.REMITTANCE.TOTAL_EXPENSES')}</Box>
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {numberFormat(_.sumBy(data.rowExpensesCash, 'price') + _.sumBy(data.rowExpensesTransfer, 'price') + _.sumBy(data.rowExpensesDebit, 'price') + _.sumBy(data.rowExpensesCredit, 'price'))}
                </TableCell>
                <TableCell align="right" scope="row" > </TableCell>
              </TableRow>
              <>
                <TableRow sx={styleCellExpenses}>
                  <TableCell align="left" scope="row" >
                    <Box style={{ paddingLeft: '2em' }}>{t('REPORT.TABLE.REMITTANCE.CASH')}</Box>
                  </TableCell>
                  <TableCell align="right" scope="row" >
                    {numberFormat(_.sumBy(data.rowExpensesCash, 'price') || 0)}
                  </TableCell>
                  <TableCell align="right" scope="row" > </TableCell>
                </TableRow>
                {_.map(data.rowExpensesCash, (val: any, index: number) => {
                  return (
                    <>
                      <TableRow sx={styleCellExpenses}>
                        <TableCell align="left" scope="row" >
                          <Box style={{ paddingLeft: '3em' }}>- {val.expenseTypeName}</Box>
                        </TableCell>
                        <TableCell align="right" scope="row">
                          {numberFormat(val.price)}
                        </TableCell>
                        <TableCell align="right" scope="row" > </TableCell>
                      </TableRow>
                    </>
                  )
                })}
              </>

              <>
                <TableRow sx={styleCellExpenses}>
                  <TableCell align="left" scope="row">
                    <Box style={{ paddingLeft: '2em' }}>{t('REPORT.TABLE.REMITTANCE.TRANSFER_')}</Box>
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(_.sumBy(data.rowExpensesTransfer, 'price') || 0)}
                  </TableCell>
                  <TableCell align="right" scope="row" > </TableCell>
                </TableRow>
                {_.map(data.rowExpensesTransfer, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow sx={styleCellExpenses}>
                          <TableCell align="left" scope="row">
                            <Box style={{ paddingLeft: '3em' }}>- {val.expenseTypeName}</Box>

                          </TableCell>
                          <TableCell align="right" scope="row">
                            {numberFormat(val.price || 0)}
                          </TableCell>
                          <TableCell align="right" scope="row" > </TableCell>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>

              <>
                <TableRow sx={styleCellExpenses}>
                  <TableCell align="left" scope="row" >
                    <Box style={{ paddingLeft: '2em' }}>{t('REPORT.TABLE.REMITTANCE.DEBIT')}</Box>
                  </TableCell>
                  <TableCell align="right" scope="row" >
                    {numberFormat(_.sumBy(data.rowExpensesDebit, 'price'))}
                  </TableCell>
                  <TableCell align="right" scope="row" > </TableCell>
                </TableRow>
                {_.map(data.rowExpensesDebit, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow sx={styleCellExpenses}>
                          <TableCell align="left" scope="row">
                            <Box style={{ paddingLeft: '3em' }}>- {val.expenseTypeName}</Box>
                          </TableCell>
                          <TableCell align="right" scope="row">
                            {numberFormat(val.price)}
                          </TableCell>
                          <TableCell align="right" scope="row" > </TableCell>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>

              <>
                <TableRow sx={styleCellExpenses}>
                  <TableCell align="left" scope="row">
                    <Box style={{ paddingLeft: '2em' }}>{t('REPORT.TABLE.REMITTANCE.CREDIT')}</Box>
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(_.sumBy(data.rowExpensesCredit, 'price') || 0)}
                  </TableCell>
                  <TableCell align="right" scope="row" > </TableCell>
                </TableRow>
                {_.map(data.rowExpensesCredit, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow sx={styleCellExpenses}>
                          <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
                            <Box style={{ paddingLeft: '3em' }}>- {val.expenseTypeName}</Box>
                          </TableCell>
                          <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                            {numberFormat(val.price || 0)}
                          </TableCell>
                          <TableCell align="right" scope="row" > </TableCell>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>
            </>
          )
        }

        {/* DF */}
        {
          DF.isShow && (
            <TableRow sx={styleCellExpenses}>
              <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                {t('REPORT.TABLE.REMITTANCE.SUM_FEE_DOCTOR')}
              </TableCell>
              <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                {numberFormat(_.sumBy(data.rowDf, 'price') || 0)}
              </TableCell>
              <TableCell align="right" scope="row" > </TableCell>
            </TableRow>
          )
        }
        {
          DF.isShow && (
            _.map(data.rowDf, (val: any, index: number) => {
              return (
                <>
                  <TableRow sx={styleCellExpenses}>
                    <TableCell align="left" scope="row" >
                      <Box style={{ paddingLeft: '2em' }}> - {val.detail}</Box>

                    </TableCell>
                    <TableCell align="right" scope="row" >
                      {numberFormat(val.price || 0)}
                    </TableCell>
                    <TableCell align="right" scope="row" >

                      <span style={{ color: 'black' }}>
                        {' '}
                        ({t('REPORT.TITLE.TREATMENT')} {numberFormat(val.total || 0)}){' '}
                      </span>

                    </TableCell>
                  </TableRow>

                </>
              )
            }))
        }

        <TableRow>
          <TableCell align="left" scope="row" sx={{ fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.TOTAL_INCOME')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ fontWeight: '800' }}>
            {numberFormat(income)}
          </TableCell>
          <TableCell align="right" scope="row" > </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" sx={{ fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.TOTAL_INCOME_EXPENSES')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ fontWeight: '800' }}>
            {numberFormat((INCOME_EXPENSES.isProcess ? expenses : 0) + ((DF.isProcess ? df : 0)))}
          </TableCell>
          <TableCell align="right" scope="row" > </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" sx={{ fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.TOTAL')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ fontWeight: '800' }}>
            {numberFormat(income - ((INCOME_EXPENSES.isProcess ? expenses : 0) + (DF.isProcess ? df : 0)))}
          </TableCell>
          <TableCell align="right" scope="row" > </TableCell>
        </TableRow>
      </>
    ) : (
      <>
        <>
          {data.rowDailyBalance &&
            _.map(data.rowDailyBalance, (val: any, index: number) => {
              return (
                <>
                  <TableRow>
                    <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.NET_DAILY')}</td>
                    <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(val.sumTreatment + val.sumProduct)}</td>
                  </TableRow>
                  <TableRow>
                    <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '2em' }}>{t('REPORT.TABLE.REMITTANCE.NET_OPERATIVE')}</td>
                    <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.sumTreatment)}</td>
                  </TableRow>
                  <TableRow>
                    <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '2em' }}>{t('REPORT.TABLE.REMITTANCE.NET_PRODUCT')}</td>
                    <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.sumProduct)}</td>
                  </TableRow>
                  <TableRow>
                    <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '2em' }}>{t('REPORT.TABLE.REMITTANCE.ANOTHER')}</td>
                    <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(INCOME_EXPENSES.isProcess ? totalIncome : 0)}</td>
                  </TableRow>
                </>
              )
            })}

          <TableRow>
            <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.CASH')}</td>
            <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowIncomeCash, 'treatment') || 0)}</td>
          </TableRow>
          {_.map(data.rowIncomeCash, (val: any, index: number) => {
            // If isShow is false or the detail is "INCOME", skip rendering
            if (!INCOME_EXPENSES.isShow && val.expenseGroup === "INCOME") {
              return null;
            }
            return (
              <>
                <TableRow>
                  <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}> - {val.detail || t('REPORT.TITLE.TREATMENT_PRODUCT')}</td>
                  <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.treatment)}</td>
                </TableRow>
              </>
            )
          })}
          <TableRow>
            <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.TRANSFER_')}</td>
            <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowIncomeTransfer, 'treatment') || 0)}</td>
          </TableRow>
          {_.map(data.rowIncomeTransfer, (val: any, index: number) => {
            // If isShow is false or the detail is "INCOME", skip rendering
            if (!INCOME_EXPENSES.isShow && val.expenseGroup === "INCOME") {
              return null;
            }
            return (
              <>
                {val.treatment > 0 && (
                  <TableRow>
                    <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.detail}</td>
                    <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.treatment || 0)}</td>
                  </TableRow>
                )}
              </>
            )
          })}

          <TableRow>
            <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.DEBIT')}</td>
            <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowIncomeDebit, 'treatment'))}</td>
          </TableRow>
          {_.map(data.rowIncomeDebit, (val: any, index: number) => {
            // If isShow is false or the detail is "INCOME", skip rendering
            if (!INCOME_EXPENSES.isShow && val.expenseGroup === "INCOME") {
              return null;
            }
            return (
              <>
                {val.treatment > 0 && (
                  <TableRow>
                    <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.detail}</td>
                    <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.treatment)}</td>
                  </TableRow>
                )}
              </>
            )
          })}

          <TableRow>
            <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.CREDIT')}</td>
            <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowIncomeCredit, 'treatment') || 0)}</td>
          </TableRow>
          {_.map(data.rowIncomeCredit, (val: any, index: number) => {
            // If isShow is false or the detail is "INCOME", skip rendering
            if (!INCOME_EXPENSES.isShow && val.expenseGroup === "INCOME") {
              return null;
            }
            return (
              <>
                {val.treatment > 0 && (
                  <TableRow>
                    <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.detail}</td>
                    <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.treatment || 0)}</td>
                  </TableRow>
                )}
              </>
            )
          })}

          <TableRow>
            <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.ADVANCE')}</td>
            <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowAdvance, 'price') || 0)}</td>
          </TableRow>

          <TableRow>
            <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.TREATMENT_RIGHT')}</td>
            <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowTreatmentRight, 'price') || 0)}</td>
          </TableRow>
          {_.map(data.rowTreatmentRight, (val: any, index: number) => {
            return (
              <>
                <TableRow>
                  <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.treatmentRightName}</td>
                  <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.price || 0)}</td>
                </TableRow>
              </>
            )
          })}

          {/*  Expenses */}
          {INCOME_EXPENSES.isShow && (
            <>
              <TableRow>
                <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TOTAL_EXPENSES')}</td>
                <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>
                  {numberFormat(_.sumBy(data.rowExpensesCash, 'price') + _.sumBy(data.rowExpensesTransfer, 'price') + _.sumBy(data.rowExpensesDebit, 'price') + _.sumBy(data.rowExpensesCredit, 'price'))}
                </td>
              </TableRow>
              <>
                <TableRow>
                  <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.CASH')}</td>
                  <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowExpensesCash, 'price') || 0)}</td>
                </TableRow>
                {_.map(data.rowExpensesCash, (val: any, index: number) => {
                  return (
                    <>
                      <TableRow>
                        <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.expenseTypeName}</td>
                        <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.price)}</td>
                      </TableRow>
                    </>
                  )
                })}
              </>

              <>
                <TableRow>
                  <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.TRANSFER_')}</td>
                  <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowExpensesTransfer, 'price') || 0)}</td>
                </TableRow>
                {_.map(data.rowExpensesTransfer, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow>
                          <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.expenseTypeName}</td>
                          <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.price || 0)}</td>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>

              <>
                <TableRow>
                  <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.DEBIT')}</td>
                  <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowExpensesDebit, 'price'))} </td>
                </TableRow>
                {_.map(data.rowExpensesDebit, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow>
                          <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.expenseTypeName}</td>
                          <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.price)}</td>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>

              <>
                <TableRow>
                  <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '3em' }}>{t('REPORT.TABLE.REMITTANCE.CREDIT')}</td>
                  <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowExpensesCredit, 'price') || 0)}</td>
                </TableRow>
                {_.map(data.rowExpensesCredit, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow>
                          <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.expenseTypeName}</td>
                          <td style={{ fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.price || 0)} </td>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>
            </>
          )}
        </>

        {/* DF */}

        {DF.isShow && (
          <TableRow>
            <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'left', }}>{t('REPORT.TABLE.REMITTANCE.SUM_FEE_DOCTOR')}</td>
            <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowDf, 'price') || 0)}</td>
          </TableRow>
        )}
        {_.map(data.rowDf, (val: any, index: number) => {
          return (
            <>
              {val.price > 0 && (
                <TableRow>
                  <td style={{ fontWeight: '300', textAlign: 'left', paddingLeft: '4em' }}>- {val.detail}</td>
                  <td style={{ fontWeight: '300', textAlign: 'right' }}>
                    {numberFormat(val.price || 0)}
                  </td>
                  <td style={{ fontWeight: '300', textAlign: 'right' }}>
                    <span style={{ color: 'black' }}>  {' '}({t('REPORT.TITLE.TREATMENT')} {numberFormat(val.total || 0)}){' '}</span>
                  </td>
                </TableRow>
              )}
            </>
          )
        })}

        <TableRow>
          <td style={{ fontWeight: '500', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TOTAL_INCOME')}</td>
          <td style={{ fontWeight: '500', textAlign: 'right' }}>{numberFormat(income)}</td>
        </TableRow>
        <TableRow>
          <td style={{ fontWeight: '500', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TOTAL_EXPENSES')}</td>
          <td style={{ fontWeight: '500', textAlign: 'right' }}> {numberFormat((INCOME_EXPENSES.isProcess ? expenses : 0) + ((DF.isProcess ? df : 0)))}</td>
        </TableRow>
        <TableRow>
          <td style={{ fontWeight: '500', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TOTAL')}</td>
          <td style={{ fontWeight: '500', textAlign: 'right' }}> {numberFormat(income - ((INCOME_EXPENSES.isProcess ? expenses : 0) + (DF.isProcess ? df : 0)))}</td>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <UseStyled.ResponsiveReportTable>
        <TableCustom
          page={props.page}
          pageLimit={props.pageLimit}
          sortType={props.sortType}
          sortBy={props.sortBy}
          rowCount={props.rowCount}
          onSort={props.onRequestSort}
          setPageLimit={props.handleChangeRowsPerPage}
          setPage={props.handleChangePage}
          headCells={headCells}
          rowsData={[renderData(props.data, false)]}
          hidePagination={true}
          customScroll
        />
      </UseStyled.ResponsiveReportTable>
      <div className="print-show" ref={props.componentRef}>
        <RemittanceExport
          filter={props.filter}
          header={
            <div style={{ fontWeight: 'bolder', fontSize: 20 }}>
              {t('REPORT.REPORT_NAME.REMITTANCE')}
              {header ? ` ( ${header} )` : ''}
            </div>
          }
          headCells={headCells}
          rowsData={[renderData(props.data, true)]}
        />
      </div>
    </>
  )
}

export function RemittanceExport(props: ExportProps) {
  const { t } = useTranslation()
  const head = props.headCells
  const { header, filter } = props
  const headCells = head.filter((headCell: any) => headCell.id !== 'action')
  const TITLES = [
    { key: 'BRANCH', label: 'REPORT.TITLE.BRANCH' },
    { key: 'DATE', label: 'REPORT.TITLE.DATE' }
  ]

  const renderValue = (value: any, index: any) => {
    if (index === 1) {
      return toBuddhistYear(moment(value), 'DD/MM/YY')
    }
    return value
  }

  return (
    <>
      <div >
        <div className="mr-2 col " style={{ fontSize: 18, fontWeight: 300 }} >
          {header}
          {TITLES.map((filter, index) => (
            <>{t(filter.label)} :{' '}{renderValue(props.filter[index], index)}{' '}</>
          ))}
        </div>
      </div>
      <UseStyled.StylesNoneBorder className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead >
            <tr >
              {_.map(headCells, (val: any, index: number) => {
                return <td style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bolder', border: '1px solid black', borderLeft: '1px solid transparent', borderRight: '1px solid transparent' }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody >
            {props.rowsData}
            {!props.rowsData.length && <td colSpan={headCells.length} style={{ border: '1px solid transparent' }}>{t('REPORT.TITLE.NO_DATA')}</td>}
          </tbody>
        </table>
      </UseStyled.StylesNoneBorder>
    </>
  )
}
