import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid, Typography, Avatar, TableRow, TableCell, IconButton, Button } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'
import { useForm, useFieldArray } from 'react-hook-form'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import HomeApi, { MessageTemplate, SendLineMessage } from 'api/home/home.api'

/** UTILS */
import * as modalSlice from 'app/slice/modal.slice'
import { selectMe } from 'app/slice/user.slice'
import { toBuddhistYear } from 'utils/app.utils'
import useWindowSize from 'utils/useWindowSize'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableCustom from 'component/Table/TableCustom'
import InputTextField from 'component/Input/InputTextField'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { notiPopup } from 'component/notifications/notifications'
import { ToastContainer } from 'react-toastify'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { icons, imgs } from 'constants/images'

/** STYLE */
import * as UseStyled from 'component/Home/style/modal/LineMessageStyled'
import 'component/Home/style/modal/styleModal.css'

type LineMessageType = {
  patient: any
  onReset?: any
}

type BranchMessageProps = {
  messages?: string[]
  setMessage?: any
  loading?: boolean
  disabledAddMsg?: boolean
}

type UserMessageProps = {
  messages?: any
  setMessage?: any
  setTemplateUserMessage?: (message: MessageTemplate[]) => void
  loading?: boolean
  disabledAddMsg?: boolean
}

export default function LineMessage(props: LineMessageType) {
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  const modal: any = useSelector(modalSlice.handleShow)
  const [tab, setTab] = useState(0)
  const [userId, setUserId] = useState(0)
  const [newMessage, setNewMessage] = useState<string[]>([])
  const [branchMessage, setBranchMessage] = useState([])
  const [userMessage, setUserMessage] = useState([])
  const [history, setHistory] = useState([])
  const [chatLoading, setChatLoading] = useState(true)
  const [templateMessageLoading, setTemplateMessageLoading] = useState(true)
  const [width] = useWindowSize()

  const loadProfile = () => {
    if (user.userId) {
      setUserId(user.userId)
    }
  }

  const loadTemplateMessageBranch = async () => {
    const messages = await HomeApi.templateMessageBranch()
    if (messages.status === 200 && !_.isEmpty(messages.data)) setBranchMessage(messages.data)
    else setBranchMessage([])
  }

  const loadTemplateMessageUser = async () => {
    const messages = await HomeApi.templateMessageUser()
    if (messages.status === 200 && !_.isEmpty(messages.data)) setUserMessage(messages.data)
    else setUserMessage([])
  }

  const loadHistoryMessage = async () => {
    const respHistory = await HomeApi.lineMessageHistory(props.patient.cnNumber).finally(() => setChatLoading(false))
    handleHistoryQueue(respHistory)
  }

  const handleHistoryQueue = (historyQueue: any) => {
    if (historyQueue.status === 200 && !_.isEmpty(historyQueue.data)) {
      const htr: any = _.chain(historyQueue.data)
        .map((i: any) => {
          i.dateFormat = toBuddhistYear(moment(i.createdAt), 'dd D MMM YY')
          return i
        })
        .groupBy('dateFormat')
        .map((value: any, key: string) => ({ key, data: value }))
        .value()
      setHistory(htr)
    } else setHistory([])
  }

  const onMessageChange = (e: any, value: any) => {
    if (typeof value === 'object') setNewMessage([value.message])
    else setNewMessage([value])
  }

  const handleSetMessage = (msg: string) => {
    if (_.isEmpty(newMessage)) setNewMessage([msg])
    else
      setNewMessage(
        _.map(newMessage, (m) => {
          return m + ' ' + msg
        })
      )
  }

  const onSendMessage = async () => {
    const sendMsg: SendLineMessage = {
      patientCaseId: props.patient.patientCaseId,
      servicePointId: props.patient.servicePointId,
      message: newMessage[0]
    }
    const send = await HomeApi.sendLineMessage(sendMsg)
    if (send.status === 201) {
      setNewMessage([])
      loadHistoryMessage()
    }
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else if (!_.isEmpty(img)) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  const handleSubmitTemplate = async () => {
    notiPopup('clear')
    let template: any = []
    _.map(userMessage, (msg: MessageTemplate, index) => {
      if (msg.message) return (template = [...template, msg])
    })

    const templates = await HomeApi.editTemplateMessage(template)
    if (templates.status === 201) {
      notiPopup('success', t(`HOME.LINE_MESSAGE.MESSAGE.SUCCESS.USER_MESSAGE`))
      loadTemplateMessageUser()
    }
  }

  useEffect(() => {
    loadProfile()
  }, [user])

  useEffect(() => {
    if (modal) {
      setChatLoading(true)
      setTemplateMessageLoading(true)
      loadHistoryMessage()
      setNewMessage([])
      new Promise<void>((resolve) => resolve())
        .then(loadTemplateMessageBranch)
        .then(loadTemplateMessageUser)
        .then(() => setTemplateMessageLoading(false))
    }
  }, [modal])

  return (
    <>
      <ModalCustom
        size="xl"
        modalStyle="modal-send-line-message"
        title={t('HOME.LINE_MESSAGE.TITLE')}
        textBtnCancel={t('MODAL.CANCEL')}
        textBtnConfirm={t('HOME.LINE_MESSAGE.BUTTON.SUBMIT')}
        modalTheme="default"
        onReset={props.onReset}
        onSubmit={handleSubmitTemplate}
        alignFooter="end"
        closeButton
        fullscreen
        component={
          <UseStyled.LineMessage className={`h-100`}>
            <Grid container spacing={2} className={`h-100`}>
              <Grid item lg={6} className={`w-100 h-100 d-flex flex-column`}>
                <Typography className="d-none d-xl-block invisible" sx={{ fontSize: 18, fontWeight: 500 }}>
                  Line
                </Typography>
                <UseStyled.ChatRoom className="h-100 px-3 px-md-4 py-2 mb-2 mb-md-3 custom-scroll" sx={{ overflowY: 'auto' }}>
                  {!chatLoading &&
                    _.map(history, (htr: any) => {
                      return (
                        <div>
                          <UseStyled.ChatDate label={htr.key} className={'mb-3'}></UseStyled.ChatDate>
                          {_.map(htr.data, (data: any, indexHistory) => {
                            return (
                              <UseStyled.ChatMessage key={indexHistory} className={`d-flex mb-3 justify-content-start ${data.userId === userId ? 'flex-row-reverse' : ''}`}>
                                <Avatar alt="profile" src={renderImage(data.userAvata)} className="mt-1" sx={{ backgroundColor: colors.white }}>
                                  <img src={imgs.defaultAvatar} className="w-100 h-100" alt="defaultAvatar" />
                                </Avatar>
                                <div className={`chat-detail ${data.userId === userId ? 'mr-2 mr-md-3' : 'ml-2 ml-md-3'}`}>
                                  <div className={`d-flex flex-column`}>
                                    <Typography className="title" sx={{ textAlign: data.userId === userId ? 'right' : 'left' }}>
                                      {data.doctorFullname}{' '}
                                      <span className="subtitle">
                                        {t('TIME')} {moment(data.createdAt).format('HH.mm')} {t('TIME_UNIT')}
                                      </span>
                                    </Typography>
                                    <Typography className={'text-break'} sx={{ textAlign: data.userId === userId ? 'right' : 'left', lineHeight: 'normal', color: colors.textPrimary }}>
                                      {data.message}
                                    </Typography>
                                  </div>
                                </div>
                              </UseStyled.ChatMessage>
                            )
                          })}
                        </div>
                      )
                    })}
                </UseStyled.ChatRoom>
                <UseStyled.ChatNewMessage className="mt-auto d-flex align-items-center">
                  <Box className={`w-100`}>
                    <AutocompleteSelect
                      labelId="message"
                      getOptionLabel={(option: MessageTemplate) => option}
                      renderOption={(props, option: MessageTemplate) => (
                        <Box key={`templateTextId-${option.templateTextId}`} component="li" {...props}>
                          {option}
                        </Box>
                      )}
                      options={_.map([...branchMessage, ...userMessage], (msg: MessageTemplate, index) => {
                        return msg.message
                      })}
                      placeholder={props.patient.hasLineToken === 'HAS' ? t('HOME.LINE_MESSAGE.NEW_MESSAGE') : t('HOME.LINE_MESSAGE.NO_MESSAGE')}
                      popupIcon={<div></div>}
                      disableClearable
                      disabledBorder
                      multiline
                      height={72}
                      freeSolo
                      onchange={onMessageChange}
                      onInputChange={(e: any, value: any) => onMessageChange(e, value)}
                      value={newMessage}
                      disabled={props.patient.hasLineToken !== 'HAS'}
                    />
                  </Box>
                  <ButtonCustom disabled={props.patient.hasLineToken !== 'HAS'} onClick={onSendMessage} textButton={t('HOME.LINE_MESSAGE.BUTTON.SEND')} className="h-100" style={{ margin: 0, height: '100%' }} />
                </UseStyled.ChatNewMessage>
              </Grid>
              {width >= 1200 && (
                <Grid item lg={6} className={`h-100 w-100 d-none d-xl-block`}>
                  <Box className="d-flex flex-column overflow-hidden" sx={{ height: '50%' }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('HOME.LINE_MESSAGE.BRANCH_MESSAGE')}</Typography>
                    <BranchMessage loading={templateMessageLoading} messages={branchMessage} setMessage={handleSetMessage} disabledAddMsg={props.patient.hasLineToken !== 'HAS'} />
                  </Box>
                  <Box className="d-flex flex-column overflow-hidden pt-xl-3" sx={{ height: '50%' }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('HOME.LINE_MESSAGE.USER_MESSAGE')}</Typography>
                    <ToastContainer containerId={'popup'} className={'Toast-popup'} />
                    <UserMessage
                      loading={templateMessageLoading}
                      messages={userMessage}
                      setMessage={handleSetMessage}
                      setTemplateUserMessage={(messages: any) => {
                        if (!_.isEmpty(messages)) setUserMessage(messages)
                        else setUserMessage([])
                      }}
                      disabledAddMsg={props.patient.hasLineToken !== 'HAS'}
                    />
                  </Box>
                </Grid>
              )}
              {(width >= 768 && width < 1200) && (
                <Grid item lg={6} className={`h-100 w-100 d-none d-md-block d-xl-none`}>
                  <UseStyled.FragmentTab className={'d-block d-xl-none mb-2'}>
                    <button className={`${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>
                      {t('HOME.LINE_MESSAGE.BRANCH_MESSAGE')}
                    </button>
                    <button className={`${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
                      {t('HOME.LINE_MESSAGE.USER_MESSAGE')}
                    </button>
                  </UseStyled.FragmentTab>
                  <Box className={`${tab === 0 ? 'd-block' : 'd-none'}`}>
                    <BranchMessage loading={templateMessageLoading} messages={branchMessage} setMessage={handleSetMessage} disabledAddMsg={props.patient.hasLineToken !== 'HAS'} />
                  </Box>
                  <Box className={`${tab === 1 ? 'd-block' : 'd-none'}`}>
                    <ToastContainer containerId={'popup'} className={'Toast-popup'} />
                    <UserMessage
                      loading={templateMessageLoading}
                      messages={userMessage}
                      setMessage={handleSetMessage}
                      setTemplateUserMessage={(messages: any) => {
                        if (!_.isEmpty(messages)) setUserMessage(messages)
                        else setUserMessage([])
                      }}
                      disabledAddMsg={props.patient.hasLineToken !== 'HAS'}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </UseStyled.LineMessage>
        }
      ></ModalCustom>
    </>
  )
}

function BranchMessage(props: BranchMessageProps) {
  const { t } = useTranslation()

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.MESSAGE') },
    { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.ACTION') }
  ]

  const renderData = (data: any, no: number) => {
    return (
      <TableRow key={`BranchMessage-${no}`}>
        <TableCell align="center" scope="row">
          {props.messages?.length ? props.messages?.length - no : 0}
        </TableCell>
        <TableCell scope="row">{data.message}</TableCell>
        <TableCell align="center" scope="row">
          <IconButton disabled={props.disabledAddMsg} aria-label="add" onClick={() => props.setMessage(data.message)} sx={{ backgroundColor: 'transparent !important', padding: 0 }}>
            <img src={icons.btnAdd} alt="add" style={{ width: 24, filter: props.disabledAddMsg ? 'grayscale(01)' : 'unset' }} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <UseStyled.ResponsiveTable className={'mt-2 overflow-hidden'}>
      <TableCustom
        hidePagination
        customScroll
        page={1}
        pageLimit={0}
        sortType={'ASC'}
        sortBy={''}
        rowCount={0}
        onSort={() => {
          return
        }}
        setPageLimit={() => {
          return
        }}
        setPage={() => {
          return
        }}
        headCells={headCells}
        rowsData={_.map(props.messages, (message, index) => {
          return renderData(message, index)
        })}
      />
    </UseStyled.ResponsiveTable>
  )
}

function UserMessage(props: UserMessageProps) {
  const { t } = useTranslation()
  const [error, setError] = useState('')

  const { register, control, getValues } = useForm()

  const formMessage = useFieldArray({
    control,
    name: 'message'
  })

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.MESSAGE') },
    { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.ACTION') }
  ]

  const addNewMessage = () => {
    return (
      <TableRow className="row-add-new-message">
        <TableCell
          align="left"
          scope="row"
          colSpan={3}
          onClick={() => {
            if (_.some(getValues('message'), (msg, index) => !msg.message)) {
              return setError(t('VALIDATION.ENTER_MESSAGE'))
            } else formMessage.prepend({ templateTextId: null, message: '' })
          }}
        >
          <Button variant="text" className="btn-add-new-message d-flex align-items-baseline">
            <span className={'mr-1'}>+</span> {t('HOME.LINE_MESSAGE.BUTTON.ADD_MESSAGE')}
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    if (!_.isEmpty(props.messages)) formMessage.replace([...props.messages])
  }, [props.messages])

  const renderData = (data: any, no: number) => {
    return (
      <TableRow key={`UserMessage-${no}`} className={`${!getValues(`message.${no}.templateTextId`) ? 'new-field' : ''}`}>
        <TableCell align="center" scope="row">
          {formMessage.fields.length - no}
        </TableCell>
        <TableCell scope="row">
          {(!getValues(`message.${no}.templateTextId`) && (
            <InputTextField
              inputProps={{ maxLength: 255 }}
              name="message"
              key={`message.${no}.templateTextId`}
              register={register(`message.${no}.message`, {
                onChange: () => {
                  if (props.setTemplateUserMessage) props.setTemplateUserMessage(getValues('message'))
                  setError('')
                }
              })}
              helperText={!getValues(`message.${no}.message`) ? error : ''}
            />
          )) ||
            data.message}
        </TableCell>
        <TableCell align="center" scope="row">
          <IconButton
            disabled={!getValues(`message.${no}.message`) || props.disabledAddMsg}
            className="mr-1"
            aria-label="add"
            onClick={() => props.setMessage(getValues(`message.${no}.message`))}
            sx={{ backgroundColor: 'transparent !important', padding: 0 }}
          >
            <img src={icons.btnAdd} alt="add" style={{ width: 24, filter: props.disabledAddMsg ? 'grayscale(01)' : 'unset' }} />
          </IconButton>
          <IconButton
            className="ml-1"
            aria-label="trash"
            onClick={() => {
              formMessage.remove(no)
              if (props.setTemplateUserMessage) props.setTemplateUserMessage(getValues('message'))
              setError('')
            }}
            sx={{ backgroundColor: 'transparent !important', padding: 0 }}
          >
            <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <UseStyled.ResponsiveTable className={'mt-2 overflow-hidden'}>
      <TableCustom
        hidePagination
        customScroll
        page={1}
        pageLimit={0}
        sortType={'ASC'}
        sortBy={''}
        rowCount={0}
        onSort={() => {
          return
        }}
        setPageLimit={() => {
          return
        }}
        setPage={() => {
          return
        }}
        headCells={headCells}
        rowsData={[
          addNewMessage(),
          _.map(formMessage.fields, (message, index) => {
            return renderData(message, index)
          })
        ]}
      />
    </UseStyled.ResponsiveTable>
  )
}
