import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

export const Title = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: 14,
  color: colors.textLightGray
}))

export const Body = styled('div')(({ theme }) => ({
  margin: '1rem 0 .5rem',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0
  }
}))

export const RowTextField = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: '1rem',
  '.MuiFormHelperText-root.Mui-error': {
    span: {
      whiteSpace: 'nowrap'
    }
  },
  [theme.breakpoints.up('sm')]: {
    '& .MuiFormControl-root:first-of-type': {
      marginRight: '0.5rem'
    },
    '& .MuiFormControl-root:last-of-type': {
      marginLeft: '0.5rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& .MuiFormControl-root:last-of-type': {
      marginTop: '1rem'
    }
  }
}))
