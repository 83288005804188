import { FindAllInterface } from 'api/api-interface'
import BaseAPI from 'api/api'

export interface IncomeExpensesInterface {
  branchId: number
  prefix: string
  documentNo: string
  documentDate: string
  documentStatus: string
  expenseGroup: string
  paymentChannelId: number
  hasTax: string
  amountTax: number
  unitTax: string
  net: number
  tax: number
  total: number
  remark: string
  incomeExpenseItems: Array<any>
}
const URL = 'income-expenses'
export default class IncomeExpensesApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(URL, { params: { ...props } })
  }
  static create(payload: any) {
    return this.api.post(URL, payload)
  }
  static findById(id: number): Promise<IncomeExpensesInterface> {
    return this.api.get(`${URL}/${id}`)
  }
  static update(id: number, body: IncomeExpensesInterface) {
    return this.api.patch(`${URL}/${id}`, body)
  }
  static remove(id: number) {
    return this.api.delete(`${URL}/${id}`)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`${URL}/${id}/status`, { id, status })
  }
  static updateDocumentStatus(id: number, documentStatus: string): Promise<any> {
    return this.api.patch(`${URL}/${id}/document-status`, { id, documentStatus })
  }
}
