import { useEffect, useState, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import ServicePointArea from 'component/Appointment/ServicePointsArea'
import CardServicePoint from 'component/Appointment/CardServicePoint'
import CancelVisit from 'component/Home/CancelVisit'
import LineMessage from 'component/Home/LineMessage'
import { notiError, notiSuccess } from 'component/notifications/notifications';
import { swalCustom } from 'component/Alert/Swal'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS */
import UseScroll from 'utils/useScroll'
import * as modalSlice from 'app/slice/modal.slice'

/** API */
import HomeApi from 'api/home/home.api'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'

/** ROUTE */
import { routeName } from 'routes/routes-name'
import ArrowForward from '@mui/icons-material/ChevronRight'
import ArrowBack from '@mui/icons-material/ChevronLeft'
import * as UseStyled from 'features/home/UseStyled'
import PaymentApi from 'api/counter/payment/payment.api'
import SendExaminationApi, { SendQueue, StatusAppointment } from 'api/master/sendExamination.api'
import { selectMe } from 'app/slice/user.slice'

import { DragDropContext } from "react-beautiful-dnd";
import { getBranch } from 'utils/app.utils'
import TreatmentQueueAPI, { ListQueue } from 'api/dentists/treatmentQueue.api'

type ServicePoints = {
  service: any
  hasCollapse?: boolean
  setCancelCase?: any
  setPatientCase?: any
  branch: string
  reRender?: () => void
}

interface IsDraggingProps {
  isDrag: boolean
  droppableId: number
  index: number
}

export default function SliderServicePoints(props: ServicePoints) {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectMe)
  // hideArrow,
  const { hideArrow, scrollBehavior, onPressArrow, startDragging, stopDragging, handleDragging } = UseScroll('.service-slider-container', '.service-slider-wrap', 700)
  const [configModal, setConfigModal] = useState('')
  const [patient, setPatient] = useState<any | null>([])
  const initStateErrorMessage = {
    username: '',
    password: '',
    cancelRemark: ''
  }
  const initStatePopupCancelVisit = {
    username: '',
    password: '',
    cancelRemark: ''
  }
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [msgPopupCancelVisit, setMsgPopupCancelVisit] = useState(initStatePopupCancelVisit)
  const [readOnly, setReadOnly] = useState(true)

  const [isDrag, setIsDrag] = useState<boolean>(false)
  const [hideArrowDrag, setHideArrowDrag] = useState<string>('')
  const elementSlider = useRef<any>(null)

  const branchId = Number(getBranch())

  const onCancelVisit = () => {
    setConfigModal('cancelVisit')
    dispatch(modalSlice.showModal())
  }

  const onSendMessage = () => {
    setConfigModal('lineMessage')
    dispatch(modalSlice.showModal())
  }

  const onPayment = async (queue: any) => {
    const send = await PaymentApi.sendPayment(queue.patientCaseId)
    if (send.status === 200) history.push(routeName.payment + '/create', { patientId: queue.patientId, patientCaseId: queue.patientCaseId, servicePointId: queue?.servicePointId })
  }

  const onSendExam = async (patientCase: any, isDragging?: IsDraggingProps) => {
    setPatient(patientCase)
    if (patientCase?.patientCaseId) {
      const respPatientCase = await HomeApi.patientCase(patientCase.patientCaseId)
      if (respPatientCase.status === 200 && !_.isEmpty(respPatientCase.data)) {
        if (isDragging?.isDrag) {
          submitSendExaminationIsDrag(respPatientCase.data, isDragging)
        } else {
          props.setPatientCase({ key: 'openVisit', value: respPatientCase.data })
        }

      } else {
        if (isDragging?.isDrag) {
          submitSendExaminationIsDrag([], isDragging)
        } else {
          props.setPatientCase({ key: 'openVisit', value: [] })
        }
      }
    }

  }

  const onSubmitPopupVisit = () => {
    if (!msgPopupCancelVisit.username || !msgPopupCancelVisit.password || !msgPopupCancelVisit.cancelRemark) {
      return setErrorMessage({
        username: !msgPopupCancelVisit.username ? t('HOME.MESSAGE.INPUT.WARNING_USERNAME') : '',
        password: !msgPopupCancelVisit.password ? t('HOME.MESSAGE.INPUT.WARNING_PASSWORD') : '',
        cancelRemark: !msgPopupCancelVisit.cancelRemark ? t('HOME.MESSAGE.INPUT.WARNING_REASON_FOR_VISIT') : ''
      })
    } else {
      PersonnelApi.findPermissions({ username: msgPopupCancelVisit.username, password: msgPopupCancelVisit.password })
        .then(({ data }) => {
          if (data.hasCancelVisit === 'PERMISS') {
            HomeApi.cancelVisit(patient.patientCaseId, msgPopupCancelVisit)
              .then(({ data }) => {
                dispatch(modalSlice.resetModal())
                notiSuccess(t('HOME.MESSAGE.SUCCESS.CANCEL'))
                clearPopupVisit()
                props?.reRender && props.reRender()
                props.setCancelCase({ key: 'reloadListQueue', value: 'success' })
              })
              .catch((e) => {
                const message = e?.response?.data?.message
                if (message === 'NOT_FOUND_USER' || message === 'INVALID_PASSWORD') {
                  setErrorMessage({
                    ...initStateErrorMessage,
                    username: message === 'NOT_FOUND_USER' ? t('PAYMENT.MESSAGE.ERROR.NOT_FOUND_USER') : '',
                    password: message === 'INVALID_PASSWORD' ? t('PAYMENT.MESSAGE.ERROR.INVALID_PASSWORD') : ''
                  })
                } else if (message === 'VISIT_IS_DONE') {
                  notiError(t('HOME.MESSAGE.INPUT.VISIT_IS_DONE'))
                } else notiError(t('HOME.MESSAGE.ERROR.ERROR_CANCEL'))
              })
          } else return setErrorMessage({ ...errorMessage, ...{ username: t('HOME.MESSAGE.INPUT.PERMISSION_CANCEL_VISIT_NOT_FOUND') } })
        })
        .catch((e) => {
          const status = e?.response?.status
          setErrorMessage({ ...errorMessage, ...{ username: status === 404 ? t('INPUT.NOT_FOUND_USER_NAME') : '', password: status === 400 ? t('INPUT.PASSWORD_INCORRECT') : '' } })
        })
    }
    setReadOnly(true)
  }

  const clearPopupVisit = () => {
    setErrorMessage(initStateErrorMessage)
    setMsgPopupCancelVisit(initStatePopupCancelVisit)
    setReadOnly(true)
  }

  const sendExam = async (patientCase: any, isDragging?: IsDraggingProps) => {
    if (patientCase?.status === "SERVICE") {
      const optionButton = {
        cancelButton: {
          showCancelButton: false,
        },
        confirmButton: {
          confirmButtonText: t('BUTTON.SUBMIT')
        },
        DenyButton:
        {
          showDenyButton: user.hasCancelTreatmentQueue === "PERMISS" ? true : false,
          denyButtonClassName: "swal2-cancelTreatmentQueue",
          denyButtonText: t('HOME.BUTTON.CANCEL_TREATMENT')
        },
        showCloseButton: false
      }
      return swalCustom(
        t('HOME.MESSAGE.CHANGE_SERVICE.TITLE'),
        `<p class="text-center m-0" style="max-width: 424px">${t('HOME.MESSAGE.CHANGE_SERVICE.SUBTITLE')}</p>`,
        async (res: any) => {
          if (res.isDenied) {
            return onSendExam(patientCase, isDragging)
          } else {
            setIsDrag(false)
            return
          }
        },
        'warning',
        optionButton)
    } else return onSendExam(patientCase, isDragging)
  }

  useEffect(() => {
    onPressArrow('onLoad')
  }, [props.hasCollapse, props.service])

  const onDragStart = () => {
    setIsDrag(true)
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      setIsDrag(false)
      return;
    }

    const { source, destination, draggableId } = result;
    const caseId = draggableId.split('-')[0]

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      setIsDrag(false)
      return;
    } else {
      const newDataDragging: IsDraggingProps = {
        isDrag: true,
        droppableId: Number(destination.droppableId),
        index: destination.index
      }

      const newService = props.service.filter((item: any) => {
        if (Number(source.droppableId) === item.servicePointId) {
          return item
        }
      })
      const newData = newService[0].cases.filter((item: any) => {
        if (Number(caseId) === item.patientCaseId) {
          return item
        }
      })
      sendExam(newData[0], newDataDragging)
    }
  };

  const handleOnScroll = () => {
    if (elementSlider.current) {
      if (elementSlider.current.offsetWidth < elementSlider.current.scrollLeft) {
        setHideArrowDrag('next')
      } else if (0 < elementSlider.current.scrollLeft) {
        setHideArrowDrag('')
      } else {
        setHideArrowDrag('prev')
      }
    }
  }

  useEffect(() => {
    setHideArrowDrag(hideArrow)
  }, [hideArrow])

  const handleTreatments = (data: any) => {
    const newTreatments: any = []
    _.map(data, (val: any, index: number) => {
      newTreatments.push({ operativeTypeId: val.operativeTypeId, operativeId: val.operativeId })
    })
    return newTreatments
  }

  const submitSendExaminationIsDrag = async (dataSendQueue: any, isDragging: IsDraggingProps) => {
    const patientCaseId = dataSendQueue.patientCaseId
    const dataTreatments = dataSendQueue.treatments.lenght !== 0 ? dataSendQueue.treatments : dataSendQueue.appointment?.appointmentOperatives?.lenght !== 0 ? dataSendQueue.appointment?.appointmentOperatives : []

    const newService = await handleLoadListQueue(isDragging.droppableId)
    let dataStatusAppointment = 'PENDING_SERVICE'

    if (newService.pointOfService === "PAYMENT") {
      dataStatusAppointment = 'PENDING_PAYMENT'
    }

    const templateSendQueue: any = {
      servicePointId: isDragging.droppableId,
      importantSymptoms: dataSendQueue.importantSymptoms,
      specialInfo: dataSendQueue.specialInfo,
      appointmentId: dataSendQueue.appointment?.appointmentId || 0,
      message: '',
      statusAppointment: dataStatusAppointment,
      status: dataStatusAppointment,
      remark: dataSendQueue.appointment?.remark || '',
      remarkCancel: dataSendQueue?.appointment?.statusAppointment === 'CANCEL' ? dataSendQueue.appointment?.remarkCancel : '',
      treatments: handleTreatments(dataTreatments),
      sorting: isDragging.index
    }

    await SendExaminationApi.homeSendQueue(patientCaseId, templateSendQueue).then((sendQueueHome) => {
      if (sendQueueHome.status === 201) {
        props?.reRender && props.reRender()
        notiSuccess(t('REGISTER.SEND_EXAM_SUCCESS'), '', null, null, '')
      }
    }).catch((e) => {
      const message = e?.response?.data?.message
      if (message === 'VISIT_IS_DONE')
        notiError(t('REGISTER.VISIT_IS_DONE'))
      else notiError(t('HOME.MESSAGE.ERROR.ERROR_CANCEL'))
    }).finally(() => {
      setIsDrag(false)
    })
  }

  const handleLoadListQueue = async (serviceRoomId: any) => {
    if (branchId && serviceRoomId) {
      const params: ListQueue = {
        branchId: branchId,
        servicePointId: serviceRoomId
      }

      const queue = await TreatmentQueueAPI.listQueue(params)
      if (queue.status === 200 && !_.isEmpty(queue.data)) return queue.data
      else return []
    }
  }

  return (
    <>
      <UseStyled.AreaArrow
        className={`arrow-prev ${hideArrowDrag === 'prev' || hideArrowDrag === 'both' ? 'd-none' : ''}`}
        onClick={() => onPressArrow('onPrev')}
        style={{
          height: 'fit-content',
          margin: 'auto'
        }}
      >
        <UseStyled.CustomIconButton>
          <ArrowBack />
        </UseStyled.CustomIconButton>
      </UseStyled.AreaArrow>
      <UseStyled.AreaArrow
        className={`arrow-next ${hideArrowDrag === 'next' || hideArrowDrag === 'both' ? 'd-none' : ''}`}
        onClick={() => onPressArrow('onNext')}
        style={{
          height: 'fit-content',
          margin: 'auto'
        }}
      >
        <UseStyled.CustomIconButton>
          <ArrowForward />
        </UseStyled.CustomIconButton>
      </UseStyled.AreaArrow>

      <UseStyled.SliderContainer
        // onMouseDown={(event) => startDragging(event)}
        // onMouseUp={(event) => stopDragging(event)}
        // onMouseLeave={(event) => stopDragging(event)}
        // onMouseMove={(event) =>  handleDragging(event)}
        ref={elementSlider}
        onScroll={() => handleOnScroll()}
        className={'service-slider-container h-100'}
        style={{ scrollBehavior: !isDrag ? scrollBehavior : undefined }}
      >
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} >
          <div className={'d-flex service-slider-wrap'} style={{ height: 'fit-content', paddingRight: '0px' }}>
            {_.map(props.service, (servicePoint: any, indexServicePoint: number) => {
              return (
                servicePoint.pointOfService !== 'SERVICES' && (
                  <ServicePointArea
                    type='home'
                    key={indexServicePoint}
                    servicePointId={servicePoint.servicePointId}
                    headerName={`${servicePoint[`servicePointName${i18n.language !== "th" ? "En" : ""}`] || servicePoint.servicePointNameEn || '-'} (${servicePoint.cases.length})`
                    }
                    serviceType={servicePoint.pointOfService}
                    body={
                      _.map(_.orderBy(servicePoint.cases, ['sorting'], ['asc']), (patientCase: any, indexPatientCase: number) => {
                        const draggableId = `${patientCase.patientCaseId}-${indexPatientCase}`
                        return (
                          <CardServicePoint
                            type='home'
                            draggableId={draggableId}
                            key={draggableId}
                            number={Number(indexPatientCase)}
                            serviceType={servicePoint.pointOfService}
                            patient={patientCase}
                            onClick={() => sendExam(patientCase)}
                            onPayment={() => {
                              setPatient(patientCase)
                              onPayment(patientCase)
                            }}
                            onCancel={() => {
                              setPatient(patientCase)
                              onCancelVisit()
                            }}
                            onSendMessage={() => {
                              setPatient(patientCase)
                              onSendMessage()
                            }}
                            branch={props.branch}
                            isDrag={isDrag}
                          />
                        )
                      })
                    }
                  />
                )
              )
            })}
          </div >
        </DragDropContext>
      </UseStyled.SliderContainer >

      {(configModal === 'cancelVisit' && (
        <CancelVisit
          operativeId={_.get(patient, 'patientCaseId')}
          patientVN={_.get(patient, 'vnNumber')}
          patientTime={_.get(patient, 'createdAt')}
          patientFullName={_.get(patient, 'fullname')}
          onKeyUser={(event) => {
            setMsgPopupCancelVisit({ ...msgPopupCancelVisit, ...{ username: event.target.value } })
            setErrorMessage(initStateErrorMessage)
          }}
          onKeyPassword={(event) => {
            setMsgPopupCancelVisit({ ...msgPopupCancelVisit, ...{ password: event.target.value } })
            setErrorMessage(initStateErrorMessage)
          }}
          onKeyReasonForCancel={(event) => {
            setMsgPopupCancelVisit({ ...msgPopupCancelVisit, ...{ cancelRemark: event.target.value } })
            setErrorMessage(initStateErrorMessage)
          }}
          onReset={clearPopupVisit}
          onSubmit={onSubmitPopupVisit}
          error={errorMessage}
          readOnly={readOnly}
          setReadOnly={(readOnlyStatus: boolean) => setReadOnly(readOnlyStatus)}
        ></CancelVisit>
      )) ||
        (configModal === 'lineMessage' && <LineMessage patient={patient}></LineMessage>)
      }
    </>
  )
}
