import { Box, MenuItem, Autocomplete, styled, ClickAwayListener } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { colors } from 'constants/theme'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch } from 'utils/app.utils'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/**COMPONENT */
import LabReportList from './LabReportList'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/**STYLE */
import * as UseStyled from 'features/report/useStyled'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import LabGroupsApi from 'api/setting/default-labs/lab-group.api'
import LabCompanyApi from 'api/setting/default-labs/lab-company.api'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import InputCheckbox from 'component/Input/InputCheckbox'
import ButtonCustom from 'component/Button/ButtonCustom'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import { SpanClearFilter } from 'features/report/useStyled'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

const AutoCompleteForm = styled(Autocomplete)(({ theme }) => ({ '& input.MuiOutlinedInput-input': { height: '30px' } }))

export default function LabReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange
  const momentNow = moment().format('YYYY-MM-DD')

  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches] = useState<any[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [labDate, setlabDate] = useState<string>(momentNow)

  const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [selectDoctors, setSelectDoctors] = useState<any[]>(['ALL'])

  const [labGroup, setLabGroup] = useState<any[]>([])
  const [labCompany, setLabCompany] = useState<any[]>([])
  const [labGroupId, setLabGroupId] = useState<number>(0)
  const [labCompanyId, setLabCompanyId] = useState<number>(0)
  const [selectedLabCompany, setSelectedLabCompany] = useState({ labCompanyId: 0, labCompanyName: t('REPORT.REPORT_GROUP.ALL') })
  const [selectedLabGroup, setSelectedLabGroup] = useState({ labGroupId: 0, labGroupName: t('REPORT.REPORT_GROUP.ALL') })

  const [labData, setLabData]: any = useState<any[]>([])
  const [labPage, setLabPage] = useState<number>(1)
  const [labPageLimit, setLabPageLimit] = useState<number>(10)
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [fetch, setFetch] = useState<boolean>(false)

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'LAB_ORDER') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    return true
  }

  const loadData = useCallback(async () => {
    if (_.isEmpty(branches))
      ClinicApi.findAllBranches()
        .then(({ data }) => setBranches(data))
        .catch((e) => {
          return
        })

    if (_.isEmpty(doctors))
      ClinicApi.findAllDoctors()
        .then((resp) => setDoctors(resp.data))
        .catch((e) => {
          return
        })
  }, [])

  const loadDoctor = useCallback(async () => {
    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
      const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
        return { dentistId: d.userId, dentistFullname: d.fullname }
      })
      setDoctorsMultiple(dentists)
      setSelectDoctors(['ALL', ...dentists.map((d: DentistInterface) => d.dentistId)])
    })
  }, [branchId])

  const loadLabGroup = useCallback((search = '') => {
    LabGroupsApi.findAll({ page: 1, pageLimit: 50, status: 'ACTIVE', search: search })
      .then(({ data: dataLabGroup }) => {
        setLabGroup(dataLabGroup)
      })
      .catch((e) => {
        return
      })
  }, [])

  const loadLabCompany = useCallback((search = '') => {
    LabCompanyApi.findAll({ page: 1, pageLimit: 50, status: 'ACTIVE', search: search })
      .then(({ data: dataLabCompany }) => {
        setLabCompany(dataLabCompany)
      })
      .catch((e) => {
        return
      })
  }, [])

  const fetchLab = useCallback(async () => {
    await verifyPermission()

    let condition: FindAllReportInterface = {
      page: labPage,
      pageLimit: labPageLimit,
      branchId: branchId
    }
    if (doctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }

    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    if (labCompanyId) condition = { ...condition, labCompanyId: labCompanyId }
    if (labGroupId) condition = { ...condition, labGroupId: labGroupId }

    ReportApi.reportLab(condition)
      .then(({ headers, data }) => {
        setLabData(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))

    setFetch(false)
  }, [labPage, labPageLimit, sortBy, sortType, fetch])

  const setDoc = (data: any) => {
    const sortedSelectData = data.sort((a: any, b: any) => {
      const idA = a.dentistId
      const idB = b.dentistId
      return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB)
    })
    return sortedSelectData
  }
  const filter = [
    branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
    rangeDate[0],
    rangeDate[1],
    _.isEmpty(selectDoctors)
      ? ` ${t('REPORT.FILTER.NO_SELECT')}`
      : selectDoctors.filter((i) => typeof i === 'number').length === doctors.length
        ? `${t('REPORT.FILTER.ALL')}`
        : `${setDoc(doctors.filter((d: any) => selectDoctors.includes(d.userId)))
          ?.map((d: any) => d.fullname)
          .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
          .join(', ')}`,
    labGroupId ? `${_.get(_.find(labGroup, { labGroupId: labGroupId }), 'labGroupName' || 'labGroupNameEn')} ` : `${t(`REPORT.FILTER.ALL`)}`,
    labCompanyId ? `${_.get(_.find(labCompany, { labCompanyId: labCompanyId }), 'labCompanyName' || 'labCompanyNameEn')} ` : `${t(`REPORT.FILTER.ALL`)}`
  ]

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.LAB`)} (${rangeDate})`,
    sheetName: `${t(`REPORT.REPORT_NAME.LAB`)}`
  })

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setLabPageLimit(limit)
  const handleChangePage = (val: number) => setLabPage(val)

  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }

  useEffect(() => {
    loadData()
    loadLabGroup()
    loadLabCompany()
  }, [loadData, loadLabGroup, loadLabCompany])
  useEffect(() => {
    loadDoctor()
  }, [loadDoctor])

  useEffect(() => {
    fetchLab()
  }, [fetchLab])

  return (
    <>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.LAB`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setLabPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface, index) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                    setLabPage(1)
                  }}
                  allowClear
                  onClear={() => setRangeDate(emptyRangePicker)}
                  label={''}
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                  <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                    <AutocompleteSelect
                      open={openFilterDoctors}
                      multiple
                      labelId="label-doctors"
                      options={['ALL', ...doctors, 'CLEAR']}
                      getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                      renderOption={(props, option) => {
                        if (option === 'ALL') {
                          return (
                            <ListFilterDoctors {...props} key={option} component="li" value={option}>
                              <InputCheckbox
                                label={t('REPORT.REPORT_GROUP.ALL')}
                                checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                                indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                                className={'pe-none'}
                              />
                            </ListFilterDoctors>
                          )
                        } else if (option === 'CLEAR') {
                          return (
                            <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                              <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                            </Box>
                          )
                        } else {
                          return (
                            <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                              <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <>
                            <span className="pr-1">{t('USER_DOCTOR')}:</span>
                            {!openFilterDoctors &&
                              ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                                <Box className="text-ellipsis">
                                  {_.map(tagValue, (option: any, index: number) => {
                                    return (
                                      <span>
                                        {_.find(doctors, { userId: option })?.fullname}
                                        {index !== _.findLastIndex(tagValue) && option && `,`}
                                      </span>
                                    )
                                  })}
                                </Box>
                              ))}
                          </>
                        )
                      }}
                      onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                      noOptionsText={t('NOT_FOUND')}
                      filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                      value={selectDoctors}
                      height={32}
                      disableClearable
                    />
                  </CustomFilterDoctor>
                </ClickAwayListener>
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <UseStyled.HeightAutocompleteSelect>
                  <AutocompleteSelect
                    key={'lab-group'}
                    labelId="lab-group"
                    inputLabel={t('REPORT.FILTER.LAB_GROUP')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={[{ labGroupId: 0, labGroupName: t('REPORT.REPORT_GROUP.ALL') }, ...labGroup]}
                    getOptionLabel={(option: any) => option.labGroupName}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.labGroupName}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      if (value) {
                        setLabGroupId(value.labGroupId)
                        setSelectedLabGroup({ labGroupId: value.labGroupId, labGroupName: value.labGroupName })
                      } else {
                        setLabGroupId(0)
                        setSelectedLabGroup({ labGroupId: 0, labGroupName: t('REPORT.REPORT_GROUP.ALL') })
                      }
                    }}
                    inputProps={{
                      onChange: (e: any) => {
                        if (labGroup.length === 50) {
                          setLabGroupId(0)
                          setLabGroup([])
                          loadLabGroup(e.target.value)
                        }
                      }
                    }}
                    onOpen={(e: any) => {
                      setLabGroupId(0)
                      setSelectedLabGroup({ labGroupId: 0, labGroupName: t('REPORT.REPORT_GROUP.ALL') })
                      loadLabGroup()
                    }}
                    value={selectedLabGroup}
                    disableClearable
                  />
                </UseStyled.HeightAutocompleteSelect>
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <UseStyled.HeightAutocompleteSelect>
                  <AutocompleteSelect
                    key={'lab-company'}
                    labelId="lab-company"
                    inputLabel={t('REPORT.FILTER.LAB_COMPANY')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={[{ labCompanyId: 0, labCompanyName: t('REPORT.REPORT_GROUP.ALL') }, ...labCompany]}
                    getOptionLabel={(option: any) => option.labCompanyName}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.labCompanyName}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      if (value) {
                        setLabCompanyId(value.labCompanyId)
                        setSelectedLabCompany({ labCompanyId: value.labCompanyId, labCompanyName: value.labCompanyName })
                      } else {
                        setLabCompanyId(0)
                        setSelectedLabCompany({ labCompanyId: 0, labCompanyName: t('REPORT.REPORT_GROUP.ALL') })
                      }
                    }}
                    inputProps={{
                      onChange: (e: any) => {
                        if (labCompany.length === 50) {
                          setLabCompanyId(0)
                          setLabCompany([])
                          loadLabCompany(e.target.value)
                        }
                      }
                    }}
                    onOpen={(e: any) => {
                      setLabCompanyId(0)
                      setSelectedLabCompany({ labCompanyId: 0, labCompanyName: t('REPORT.REPORT_GROUP.ALL') })
                      loadLabCompany()
                    }}
                    value={selectedLabCompany}
                    disableClearable
                  />
                </UseStyled.HeightAutocompleteSelect>
              </Col>

              <Col md={1} xl={1} className={'pt-0'}>
                <ButtonCustom
                  onClick={() => {
                    setFetch(true)
                    setLabPage(1)
                  }}
                  textButton={t('INPUT.SEARCH')}
                  style={{ height: '30px', marginTop: '5px' }}
                />
              </Col>

              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <LabReportList
              date={rangeDate}
              page={labPage}
              pageLimit={labPageLimit}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={labData}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              componentRef={componentRef}
              filter={filter}
            />
          </Box>
        </Card.Body>
      </Card>
    </>
  )
}
