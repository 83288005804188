import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Row, Col } from 'react-bootstrap'
import { AppointmentCardItemDayInterface, AppointmentDisplayDayInterface, AppointmentTags } from 'features/counter/appointment/appointment-interface'
import SectionAppointment from 'component/Appointment/GraphAppointment'
import CardAppointment from 'component/Appointment/CardAppointment'
import { styled } from '@mui/material/styles'
import { Box, Tooltip, Typography } from '@mui/material'
import { imgs, onProfileError } from 'constants/images'
import CardAppointmentDetail from 'component/Appointment/CardAppointmentDetail'
import moment from 'moment'
import { getColorStatus } from 'features/counter/doctor-schedule/doctor-schedule'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS */
import { colors } from 'constants/theme'

/** ICONS */
import IconButton from '@mui/material/IconButton'


import { fileUrl } from 'api/api'
import { ScrollSync, ScrollSyncNode } from 'component/syncScroll'
import CardBreak from 'component/Appointment/CardBreak'
import { getBranch } from 'utils/app.utils'
import AboutUs from 'api/admin/clinic-manage/about.api'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import UseScroll from 'utils/useScroll'
import { useSelector } from 'react-redux'
import { selectMe } from 'app/slice/user.slice'
import { BoxTag } from './style'
import { useFormatSizeAppointment } from './appointment'

const AreaArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  marginTop: 56,
  width: 88,
  zIndex: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&.arrow-next': {
    right: 0
  },
  '&:hover .MuiIconButton-root': {
    background: `${colors.disabledGray} !important`
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: colors.lightGray,
  border: `1px solid ${colors.lightGray}`,
  color: colors.white,
  padding: 0,
  width: 34,
  height: 34,
  transition: 'opacity .3s ease',
  '& svg': {
    fontSize: 26
  }
}))

const DivProfileDoctor = styled('div')(({ theme }) => ({
  border: `1px solid ${colors.black}`,
  height: '40px',
  width: '40px',
  borderRadius: '50rem',
  margin: '0.25rem',
  overflow: 'hidden',
  '& img': {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  }
}))

const DivServicePointName = styled('span')(({ theme }) => ({
  marginLeft: 10,
  color: colors.secondSky,
  fontSize: 11,
  '& div': {
    width: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const DivDisplayDay = styled('div')<{ listAppointmentDay: any, width: string }>(({ theme, listAppointmentDay, width }) => ({
  // overflow: 'auto',
  maxHeight: '100%',
  '& .service-slider-container': {
    '&::-webkit-scrollbar': {
      width: 1,
      height: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.disabledGray,
      borderRadius: 50
    }
    // minWidth: `calc(${listAppointmentDay.length * 435}px + 4.05rem)`
  },
  '& .section-head-doctor': {
    paddingTop: '1rem',
    // paddingLeft: '3.05rem',
    // width: `calc(${listAppointmentDay.length * 435}px + 3.05rem)`,
    zIndex: 300,
    '& .profile-doctor': {
      border: `1px solid ${colors.lightGray}`,
      height: '40px',
      width: '40px',
      borderRadius: '50rem',
      margin: '0.25rem',
      overflow: 'hidden',
      '& img': {
        objectFit: 'cover',
        height: '100%',
        width: '100%'
      }
    }
  },
  '& .section-body-appointment': {
    // paddingLeft: '3.05rem',
    // width: `calc(${listAppointmentDay.length * 435}px + 4.05rem)`,
    // overflow: 'auto',
    '.layout-schedule': {
      overflow: 'hidden'
    },
    '& .graph-appointment': {
      width: `${width}`,
      display: 'inline-block',
      margin: '0 0.05rem 0 0.05rem'
    }
  },
  '& .div-time': {
    width: 48,
    display: 'inline-block',
    position: 'sticky',
    marginTop: 88,
    // overflow: 'auto',
    maxHeight: '100%'
  }
}))

const CardDoctor = styled('div')<{ width: string }>(({ theme, width }) => ({
  display: 'inline-block',
  width: `${width}`,
  minHeight: '90px',
  height: '100%',
  border: `1px solid ${colors.lightGray}`,
  margin: '0 0.05rem 0 0.05rem',
  background: colors.filterDropdown
}))

export interface DisplayDayProps {
  branchId: number
  branch: string
  listAppointmentDay: AppointmentDisplayDayInterface[]
  timeRange: any[]
  onEdit?: (id: number) => void
  onEditSpecialInfo?: (id: number) => void
  clinicIsClose: boolean
  type?: string
  formAppointmentWidth?: number
}

export default function DisplayDay(props: DisplayDayProps) {
  const { t, i18n } = useTranslation()
  const [viewDetails, setViewDetails] = useState([])
  const [branch, setBranch] = useState<any>()
  const user = useSelector(selectMe)
  const sizeSectionAppointment = useFormatSizeAppointment();
  const [widthCardAppointment, setWidthCardAppointment] = useState<number>(sizeSectionAppointment)

  const { hideArrow, scrollBehavior, onPressArrow } = UseScroll('.service-slider-container', '.service-slider-wrap', widthCardAppointment)

  const displayRef = useRef<any>(null)
  const containerRef = useRef<any>(null)

  // width head doctor
  // useEffect(()=>{
  //   const containerTimeRef:any = document.querySelectorAll('#div-time')
  //   if (displayRef?.current,containerRef?.current,containerTimeRef) {
  //     const elementDisplay = displayRef.current
  //     const elementAppointment = containerRef.current
  //     const [elementTime] = containerTimeRef
  //     const countDoctor = elementAppointment?.children?.length || 0

  //     const checkWidth = elementDisplay?.clientWidth >= (elementAppointment?.clientWidth + elementTime?.clientWidth)
  //     if (checkWidth && countDoctor) {
  //       setWidthCardAppointment((elementDisplay?.clientWidth - elementTime?.clientWidth - ((countDoctor * 2) + 2))/countDoctor || sizeSectionAppointment)
  //     }else{
  //       setWidthCardAppointment(sizeSectionAppointment)
  //     }
  //   }
  // },[displayRef,containerRef,props?.formAppointmentWidth])

  useEffect(() => {
    setBranch(props.branch)
  }, [props.branch])

  const handleViewDetail = (val: any) => {
    const temp: any = []
    if (!temp.find((t: any) => t === val)) temp.push(val)
    setViewDetails(temp)
  }
  const handleCloseDetail = (val: any) => {
    const temp: any = [...viewDetails].filter((t: any) => t !== val)
    setViewDetails(temp)
  }

  const groupAppointmentByHour = (apt: any) => {
    const newApt: any = _.chain(apt)
      .map((i: any) => { return i })
      .groupBy('start')
      .map((value: any) => ({ data: value }))
      .value()

    return newApt
  }


  const scrollTabDoctor = (e: any) => {
    e.preventDefault();
    const tabDoctor: any = document.getElementById("service-slider-container");
    document.getElementById("layout-schedule")?.scrollTo(0, tabDoctor?.scrollTop)
    document.getElementById("service-slider-container")?.scrollTo(tabDoctor?.scrollLeft, tabDoctor?.scrollTop)
  }

  const renderImgDoctor = (lad: AppointmentDisplayDayInterface) => {
    if (lad?.doctorProfile && !lad?.doctorProfile?.includes("simple-user-profile.png")) {
      return `${fileUrl}/${lad.doctorProfile}`
    } else {
      return imgs.defaultAvatar
    }
  }

  return (
    !props.clinicIsClose && (
      <DivDisplayDay
        ref={displayRef}
        listAppointmentDay={props.listAppointmentDay}
        className="display-days position-relative d-flex flex-column"
        width={`${widthCardAppointment}px`}
      >
        <AreaArrow className={`arrow-prev ${hideArrow === 'prev' || hideArrow === 'both' ? 'd-none' : ''} opacity-0`}
          style={{ cursor: 'default' }}>
          <CustomIconButton>
            <ArrowBack />
          </CustomIconButton>
        </AreaArrow>
        <AreaArrow className={`arrow-next ${hideArrow === 'next' || hideArrow === 'both' ? 'd-none' : ''} opacity-0`}
          style={{ cursor: 'default' }}>
          <CustomIconButton>
            <ArrowForward />
          </CustomIconButton>
        </AreaArrow>
        <ScrollSync proportional={false}>
          <Box className='overflow-auto' style={{ display: 'flex', position: 'relative', height: '100%' }}
            sx={{
              "#layout-schedule::-webkit-scrollbar": {
                width: '0px',
                height: '0px',
              },
              '.div-time': {
                paddingTop: '18px',
                marginBottom: '28px'
              },
              '.layout-schedule': {
                overflowY: 'hidden !important'
              }
            }}>
            <ScrollSyncNode group="day" onScroll={scrollTabDoctor}>
              <div id='div-time' className={'div-time none-scroll'}>
                {props.listAppointmentDay.length > 0 && (
                  <SectionAppointment branchId={1} timeRange={props.timeRange} isShowTime={true} enableTimeTooltip showMinute />
                )}
              </div>
            </ScrollSyncNode>
            <ScrollSyncNode group="day" onScroll={scrollTabDoctor}>
              <div id='service-slider-container' className="service-slider-container overflow-scroll" >
                <div id='service-slider-wrap' className="service-slider-wrap">
                  <Box
                    ref={containerRef}
                    className="section-head-doctor sticky-top bg-white d-flex flex-row"
                    style={{ width: 'fit-content' }}
                    sx={{
                      '.doctor-bg:nth-child(even)': {
                        background: colors.appointmentDoctor.colorEven,
                      },
                      '.doctor-bg:nth-child(odd)': {
                        background: colors.appointmentDoctor.colorOdd,
                      }
                    }}
                  >
                    {_.map(props.listAppointmentDay, (lad: AppointmentDisplayDayInterface, i: number) => (
                      <CardDoctor key={i} className='doctor-bg' width={`${widthCardAppointment}px`}>
                        <Row className="mx-0" style={{ padding: '0.75rem 0 0.75rem 0', width: `${widthCardAppointment}px` }}>
                          <Col sm={'auto'} className={'text-nowrap overflow-hidden w-auto'}>
                            <DivProfileDoctor className="profile-doctor">
                              <img src={renderImgDoctor(lad)} onError={onProfileError} alt={lad?.doctorFullname} />
                            </DivProfileDoctor>
                          </Col>
                          <Col className={'px-0 text-nowrap overflow-hidden'}>
                            <p className="mb-0 d-flex align-items-center">
                              <Tooltip title={<div>{lad.doctorFullname}</div>} placement={'top-start'}>
                                <strong>{lad.doctorFullname}</strong>
                              </Tooltip>
                              <DivServicePointName>
                                <Tooltip
                                  title={<div>{lad[`servicePointName${i18n.language !== "th" ? "En" : ""}`]}</div>}
                                  placement={'top-start'} >
                                  <div>{lad[`servicePointName${i18n.language !== "th" ? "En" : ""}`]}</div>
                                </Tooltip>
                              </DivServicePointName>
                            </p>
                            {lad?.tags?.length ?
                              <BoxTag>
                                {lad?.tags.map((item: AppointmentTags, index: number) => {
                                  return (
                                    <Box
                                      key={index}
                                      title={item.tagName}
                                      className={`items-tag`}
                                      sx={{ backgroundColor: item.color, }}
                                    />
                                  )
                                })}
                              </BoxTag>
                              : <></>
                            }
                            <span className="fz-14">
                              <span className='pr-1'>{t('HOME.TITLE.START_WORK')}</span>
                              {lad?.statusDoctorSchedule === "NORMAL" && (
                                `${lad.doctorSchedule} ${i18n.language === "th" ? 'น.' : ''} (${lad.countAppointment})`
                              ) || (
                                  <span style={{ color: getColorStatus(lad?.statusDoctorSchedule || '', 'COLOR') }}>{lad?.statusDoctorSchedule ? `(${t(`DOCTOR_SCHEDULE.STATUS.${lad?.statusDoctorSchedule}`)})` : '-'}</span>
                                )}
                            </span>
                          </Col>
                        </Row>
                      </CardDoctor>
                    ))}
                  </Box>
                  <div id='section-body-appointment' className="section-body-appointment d-flex flex-row">
                    {_.map(props.listAppointmentDay, (lad: AppointmentDisplayDayInterface, i: number) => {
                      const countAp = 0
                      const checkTimeStart = '';
                      return (
                        <div key={i} className={'graph-appointment'} style={{ width: `${widthCardAppointment}px` }}>
                          <SectionAppointment
                            enableTimeTooltip
                            branchId={1}
                            width={widthCardAppointment}
                            isShowTime={false}
                            showMinute
                            timeRange={props.timeRange}
                            hideDot={i > 0}
                            card={
                              _.map(groupAppointmentByHour(lad.appointments), (time) => {
                                return _.map(time.data, (ap: AppointmentCardItemDayInterface, j: number) => {
                                  const count = _.filter(time.data, (list: AppointmentCardItemDayInterface, iList: number) => j + 1 > iList && list.doctorId === ap.doctorId && list.start === ap.start).length
                                  const allRow = _.filter(time.data, (list: AppointmentCardItemDayInterface) => list.doctorId === ap.doctorId).length
                                  const handlePatientCode = () => {
                                    if (branch?.patientCodeAppointment === '1') {
                                      return ap.cnNumber
                                    }
                                    if (branch?.patientCodeAppointment === '0') {
                                      return ap.branchCnNumber
                                    }
                                    if (branch?.patientCodeAppointment === '2') {
                                      return ap.snCode
                                    }
                                  }
                                  return (
                                    <CardAppointment
                                      key={j}
                                      appointmentId={ap.appointmentId}
                                      status={ap.statusAppointment}
                                      branchCnNumber={handlePatientCode() ? handlePatientCode() : ''}
                                      colorBackground={ap.color}
                                      serviceText={ap.serviceText}
                                      patientFullName={ap.patientFullname}
                                      rangeTimeText={ap.rangeTimeText}
                                      start={
                                        moment(ap.start, 'HH:mm')
                                          .add(count - 1)
                                          .format('HH:mm') || ap.rangeTimeText.split(' - ')[0].replace('.', ':')
                                      }
                                      end={ap.end || ap.rangeTimeText.split(' - ')[1].replace('.', ':')}
                                      hasLab={ap.hasLab === 'HAS'}
                                      hasXray={ap.hasXray === 'HAS'}
                                      onViewDetail={handleViewDetail}
                                      timeStart={props.timeRange[0]}
                                      width={(widthCardAppointment / allRow)}
                                      countThis={count}
                                      translateX={(widthCardAppointment / allRow) * j}
                                      specialInfo={ap?.specialInfo}
                                      type={props.type}
                                      phone={ap?.phone}
                                      allRow={allRow >= 4}
                                    />
                                  )
                                })
                              })
                            }
                            popup={
                              _.map(lad.appointments, (ap: AppointmentCardItemDayInterface, j: number) => (
                                <CardAppointmentDetail
                                  key={j}
                                  timeStart={props.timeRange[0]}
                                  timeEnd={props.timeRange[props.timeRange.length - 1]}
                                  appointmentId={ap.appointmentId}
                                  showPopup={_.find(viewDetails, (v) => v === ap.appointmentId) ? true : false}
                                  onClose={handleCloseDetail}
                                  onEdit={props?.onEdit ? props?.onEdit : undefined}
                                  onEditSpecialInfo={props.onEditSpecialInfo ? props.onEditSpecialInfo : undefined}
                                  status={ap.statusAppointment}
                                  branch={props.branch}
                                  isNotShowPhoneNumber={user.hasShowPhoneNumber === 'UNPERMISS'}
                                  popAutoScroll
                                />
                              ))
                            }
                            break={

                              <CardBreak
                                start={moment(lad.breakTimeStart, 'HH:mm').format('HH:mm')}
                                end={lad.breakTimeEnd}
                                timeStart={props.timeRange[0]}
                                width={widthCardAppointment}
                                translateX={widthCardAppointment}
                              />
                            }
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </ScrollSyncNode>
          </Box>
        </ScrollSync>



      </DivDisplayDay>
    ) || (
      <Typography className='text-center my-4' sx={{ fontWeight: 500, color: colors.textLightGray }}>{t('CLINIC_CLOSE')}</Typography>
    )
  )
}
