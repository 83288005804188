import { useEffect } from 'react'
import { Box, Grid, styled } from '@mui/material';
import { colors } from 'constants/theme'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'

/** STYLE */
import { Title, Subtitle as Body } from 'features/page/style'
import { Imgs } from 'constants/images'
import { getPermissionShortcuts } from 'utils/app.utils';

export const CustomTitle = styled(Title)(({ theme }) => ({
  fontSize: 80,
  color: colors.themeSecondColor,
  [theme.breakpoints.up('xl')]: {
    fontSize: 120,
  }
}))

export const CustomSubTitle = styled(Title)(({ theme }) => ({
  fontSize: 36,
  [theme.breakpoints.up('xl')]: {
    fontSize: 48,
  }
}))

export const CustomImgNotFound = styled(Box)(({ theme }) => ({
  maxWidth: '748px',
  svg: {
    width: '100%',
    height: 'auto'
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '320px',
  }
}))

export default function PageNotFound() {
  const { t } = useTranslation()
  const history = useHistory()

  const backToHome = () => {
    history.push(routeName.welcome)
  }

  const permissionShortcuts = getPermissionShortcuts()

  useEffect(() => {
    const pathname = window.location.pathname.substring(1).split('/')[0]
    const loginName = routeName.login.substring(1).split('/')[0]
    const pickName = routeName.pick.substring(1).split('/')[0]
    if (pathname === loginName || pathname === pickName) return backToHome()
  }, []);

  return (
    <Box className='h-100 p-3'>
      <Box className='mx-auto h-100 d-flex align-items-center d-xl-block' sx={{ maxWidth: { lg: '85%' } }}>
        <Grid container justifyContent={'center'} sx={{ height: { lg: '100%' } }}>
          <Grid item lg={6} xl={5} className='w-100 d-flex align-items-center justify-content-center d-flex'>
            <CustomImgNotFound className='w-100'>
              <Imgs.ImgNotFound />
            </CustomImgNotFound>
          </Grid>
          <Grid item lg={6} className='w-100 d-flex flex-column align-items-center justify-content-center'>
            <Box>
              <CustomTitle className='mt-0 text-center d-none d-xl-block'>{t('404')}</CustomTitle>
              <CustomSubTitle className='mt-3'>{t('PAGE_NOT_FOUND')}</CustomSubTitle>
              <Box className='d-flex align-items-center flex-wrap mt-1'>
                <Body className='mt-0'>{t('PAGE_NOT_FOUND_SUBTITLE')}</Body>
              </Box>
              {permissionShortcuts.includes('HOME') && <ButtonCustom
                textButton={t('BUTTON.BACK_TO_HOME')}
                onClick={backToHome}
                style={{ margin: 0, textAlign: 'center' }}
                btnStyle={{ height: '52px', marginTop: '40px', fontSize: '16px', width: '100%', maxWidth: '300px' }} />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
