import BaseAPI from 'api/api'

export interface IFSellSumery {
    sumeryAll: {
        sumery: number
        productSumery: number
        treatmentSumery: number
    }
    sumeryDiff: {
        dateStartBefore: string
        dateEndBefore: string
        dateDiff: number
        sumeryBefore: number
        productSumeryBefore: number
        treatmentSumeryBefore: number
    }
    serviceUsaged: number,
    newCustomer: number
}
export interface IFProducts {
    itemId: number
    itemName: string
    itemNameEn: string
    branchId: number
    qty: number
    total: number
    amountPaid: number
}
export interface IFBastSellerProducts {
    branchId: number
    branchName: string
    branchNameEn: string
    products: IFProducts[]
}
export interface IFPayment {
    expenseTypeName: string
    total: number
}
export interface IFBastTotalExpenses {
    branchId: number
    branchName: string
    branchNameEn: string
    expenses: IFPayment[]
}
export interface IFSellerTreatment {
    sumery: number
    doctorFee: number
    clinicFee: number
}
export interface IFdoctor {
    doctorId: number
    doctorFullname: string
    profile: string
    qty: number
    total: number
    amountPaid: number
    dfAmountNet: number
    cfAmountNet: number
}
export interface IFTopDoctorFee {
    sumery: number,
    lists: IFdoctor[]
}
export interface IFInventories {
    productName: string
    productNameEn: string
    itemUnitName: string
    itemUnitNameEn: string
    minStock: number
    qty: number
    qtyDiff: number
    price: number
}
export interface IFInventoriesPurchase {
    productName: string
    productNameEn: string
    qty: number
    total: number
}
export interface IFPaymentChannel {
    paymentChannelId: number
    paymentChannelName: string
    paymentChannelNameEn: string
    sumAmountPaid: number
    countPaymentchannel: number
}
export interface IFTreatmentsType {
    operativeTypeId: number
    operativeTypeName: string
    color: string
    amountPaid: number
}
export interface IFBastSellOperativeType {
    branchId: number
    branchName: string
    branchNameEn: string
    payments: IFTreatmentsType[]
}
export interface IFTreatments {
    amountPaid: number
    operativeCode: string
    operativeId: number
    operativeName: string
}
export interface IFBastTopSellOperative {
    branchId: number
    branchName: string
    branchNameEn: string
    payments: IFTreatments[]
}

/** FILTER */
export interface IFSumeryFilter {
    dateStart: string,
    dateEnd: string,
    branchId: string
}
export interface IFBranchesFilter {
    branchId: string
}
export interface IFSumeryDoctorFilter {
    dateStart: string,
    dateEnd: string,
    doctorId: string
    branchId: string
}
const path = 'dashboard'

export default class DashboardApi extends BaseAPI {
    static findSellSumery(params: IFSumeryFilter): Promise<any> {
        return this.api.get(`${path}/sumery`, { params: { ...params } })
    }
    static findBastSellerProduct(params: IFSumeryFilter): Promise<any> {
        return this.api.get(`${path}/products`, { params: { ...params } })
    }
    static findBastSellerTreatment(params: IFSumeryFilter): Promise<any> {
        return this.api.get(`${path}/top-treatments`, { params: { ...params } })
    }
    static findTopDoctorFee(params: IFSumeryDoctorFilter): Promise<any> {
        return this.api.get(`${path}/summay-doctor-fee`, { params: { ...params } })
    }
    static findSafetyStock(params: IFBranchesFilter): Promise<any> {
        return this.api.get(`${path}/summay-inventory`, { params: { ...params } })
    }
    static findTopBuyProduct(params: IFSumeryFilter): Promise<any> {
        return this.api.get(`${path}/summay-purchase`, { params: { ...params } })
    }
    static findTopPaymentChannel(params: IFSumeryFilter): Promise<any> {
        return this.api.get(`${path}/payment-channel`, { params: { ...params } })
    }
    static findTotalExpenses(params: IFSumeryFilter): Promise<any> {
        return this.api.get(`${path}/summay-expenses`, { params: { ...params } })
    }
    static findSellOperativeType(params: IFSumeryFilter): Promise<any> {
        return this.api.get(`${path}/summay-operative-types`, { params: { ...params } })
    }
    static findTopSellOperative(params: IFSumeryFilter): Promise<any> {
        return this.api.get(`${path}/summay-operatives`, { params: { ...params } })
    }
}
