import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Button } from '@mui/material'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import InputTextField from 'component/Input/InputTextField'
import useStyles from 'features/setting/personnel-managements/personnels/style'
import * as UseStyled from 'features/setting/personnel-managements/personnels/useStyles'
import { ButtonDeleteSimple } from 'component/Button/ButtonDelete'
import { ValueLabel } from 'features/setting/personnel-managements/personnels/form-personnel-value'
import InputNewRangeTimePicker from 'component/Input/InputNewRangeTimePicker'
import moment from 'moment'
import { colors } from 'constants/theme'

/** UTILS || SLICE */
import { selectLoadingSchedule, selectSchedule, editSchedule } from 'app/slice/user.slice'

interface ScheduleInterface {
  dayOfWork: string
  timeOfWork: string
  name: string
}
interface ScheduleProps {
  isEdit: boolean
}
export default function Schedule(props: ScheduleProps) {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector(selectLoadingSchedule)
  const userSchedule = useSelector(selectSchedule)

  const [schedule, setSchedule] = useState<ScheduleInterface[]>([])
  const classes = useStyles()
  const dateOfWeekTH = ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา']
  const dateOfWeeks = [
    { day: t('PERSONNEL.SCHEDULE.NAME_DAY.MON') },
    { day: t('PERSONNEL.SCHEDULE.NAME_DAY.TUE') },
    { day: t('PERSONNEL.SCHEDULE.NAME_DAY.WED') },
    { day: t('PERSONNEL.SCHEDULE.NAME_DAY.THU') },
    { day: t('PERSONNEL.SCHEDULE.NAME_DAY.FRI') },
    { day: t('PERSONNEL.SCHEDULE.NAME_DAY.SAT') },
    { day: t('PERSONNEL.SCHEDULE.NAME_DAY.SUN') }
  ]
  const headTable = [t('PERSONNEL.SCHEDULE.DAY'), t('PERSONNEL.SCHEDULE.WORK_LOCATION'), t('PERSONNEL.SCHEDULE.TIME_WORK'), 'Action']

  useEffect(() => {
    if (!loading) {
      if (props.isEdit) setSchedule(userSchedule.userWorkplaces)
    }
  }, [loading])

  const handleCheckDate = (date: string) => {
    if (i18n.language === 'en') {
      const indexItem = dateOfWeeks.findIndex((item) => item.day === date)
      return dateOfWeekTH[indexItem] || date
    } else {
      return date
    }
  }

  const handleValueSchedule = (dayArray: any, dateOfWeek: any, rowIndex: number) => {
    const newDate = handleCheckDate(dateOfWeek.day)
    const newData: any = [...schedule].map((object: any, stateIndex: any) => {
      const check = dayArray.includes(newDate)
      if (stateIndex === rowIndex) {
        if (!check) {
          return { ...object, dayOfWork: [...dayArray, newDate].toString() }
        } else {
          return { ...object, dayOfWork: dayArray.filter((day: any) => day !== newDate).toString() }
        }
      } else {
        return object
      }
    })
    const dataArray = newData[rowIndex]?.dayOfWork.split(',')
    const sortedDataArray = dataArray.sort((a: any, b: any) => {
      const order = 'จ,อ,พ,พฤ,ศ,ส,อา'.split(',')
      return order.indexOf(a) - order.indexOf(b)
    })
    const newDataSort = sortedDataArray.join(',')
    newData[rowIndex] = { ...newData[rowIndex], dayOfWork: newDataSort }

    dispatch(
      editSchedule({
        value: newData,
        key: 'userWorkplaces'
      })
    )
    setSchedule(newData)
  }

  return (
    <div className="p-3">
      <Row className={`${classes.titleFirst} ml-1 mt-2`}>{t('PERSONNEL.WORK_SCHEDULE')}</Row>
      <TableContainer component={Paper} className={`mt-3`} style={{ boxShadow: 'none', border: `1px solid ${colors.lightGray}` }}>
        <Table aria-label="simple table" sx={{ minWidth: '900px' }}>
          <TableHead>
            <TableRow>
              {headTable.map((head: string, i: number) => (
                <TableCell key={i} className={`${classes.titleSecond}`} style={{ fontWeight: 600, color: colors.themeMainColor }}>
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={`${classes.columeHover}`}
                onClick={() => setSchedule([...schedule, { dayOfWork: '', timeOfWork: '00:00/00:00', name: '' }])}
                colSpan={5}
                sx={{
                  '&.MuiTableCell-root.MuiTableCell-body': {
                    backgroundColor: 'transparent !important'
                  }
                }}
              >
                <UseStyled.DivAdd> + {t('PERSONNEL.ADD_WORK_SCHEDULE')}</UseStyled.DivAdd>
              </TableCell>
            </TableRow>
            {schedule.map(({ TimeId, timeOfWork, name, dayOfWork }: any, rowIndex: any) => (
              <TableRow key={rowIndex}>
                <TableCell style={{ width: '300px' }}>
                  {dateOfWeeks.map((dateOfWeek, index) => {
                    const dayArray = dayOfWork.split(',')
                    return (
                      <Button
                        className={`btn-day ${dayArray.includes(handleCheckDate(dateOfWeek.day)) ? 'active' : ''} d-inline-flex`}
                        key={index}
                        style={{ borderRadius: '50%', minWidth: 32, maxWidth: 32, maxHeight: 32, marginLeft: '5px' }}
                        onClick={() => handleValueSchedule(dayArray, dateOfWeek, rowIndex)}
                        //
                      >
                        {dateOfWeek.day}
                      </Button>
                    )
                  })}
                </TableCell>
                <TableCell>
                  <InputTextField
                    label={t('PERSONNEL.SCHEDULE.NAME_WORK')}
                    value={name}
                    onchange={(e) => {
                      const val: any = [...schedule].map((object: any, stateIndex: any) => (stateIndex === rowIndex ? { ...object, name: e.target.value } : object))
                      setSchedule(val)
                      dispatch(editSchedule({ value: val, key: 'userWorkplaces' }))
                    }}
                  />
                </TableCell>
                <TableCell>
                  <InputNewRangeTimePicker
                    value={[moment(schedule[rowIndex].timeOfWork.split('/')[0], 'HH:mm').format('HH:mm'), moment(schedule[rowIndex].timeOfWork.split('/')[1], 'HH:mm').format('HH:mm')]}
                    onChange={(time) => {
                      if (time[0] && time[1]) {
                        const val: any = [...schedule].map((object: any, stateIndex: any) => {
                          return stateIndex === rowIndex ? { ...object, timeOfWork: `${time[0]}/${time[1]}` } : object
                        })
                        setSchedule(val)
                        dispatch(editSchedule({ value: val, key: 'userWorkplaces' }))
                      } else {
                        const val: any = [...schedule].map((object: any, stateIndex: any) => (stateIndex === rowIndex ? { ...object, timeOfWork: '' } : object))
                        setSchedule(val)
                        dispatch(editSchedule({ value: val, key: 'userWorkplaces' }))
                      }
                      ValueLabel.userWorkplaces = schedule
                    }}
                    style={{ maxWidth: '300px' }}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    const clickId: any = rowIndex
                    const valueUserSchedule: any = schedule.filter((object: any, stateIndex: any) => stateIndex !== clickId)
                    setSchedule(valueUserSchedule)
                    dispatch(editSchedule({ value: valueUserSchedule, key: 'userWorkplaces' }))
                  }}
                >
                  <ButtonDeleteSimple />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
