import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/82.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 26.03, height: 99.89, viewBox: "0 0 26.03 110" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 26.03, height: 99.89, viewBox: "0 0 26.03 110" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>82</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12353">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5575"
                                d="M0.843276 2.75988C3.93443 26.8399 8.94847 58.0926 11.3457 65.9955C11.8147 67.5409 13.6513 67.784 14.3281 66.35C14.7888 65.3722 14.7979 64.0651 14.7843 62.9595C14.5176 43.3658 19.1597 24.5203 21.0836 5.26207C21.1644 4.45762 21.2723 3.66026 21.4011 2.86763C21.5997 1.64401 20.8262 0.503907 19.7704 0.503907H17.8049C17.5291 0.509903 17.2596 0.588097 17.0234 0.730699C16.7873 0.873301 16.5926 1.07532 16.4588 1.31663C16.2131 1.72844 15.9491 2.13508 15.6669 2.53651C13.7368 5.28315 10.7345 6.70138 7.82747 6.26803C5.68516 5.94972 4.7572 4.02683 4.04553 1.7683C3.80522 1.00462 3.18119 0.503907 2.49099 0.503907C1.47262 0.503119 0.690816 1.57172 0.843276 2.75988Z"
                                transform={"translate(1 ,30)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />


                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5575"
                                    d="M0.843276 2.75988C3.93443 26.8399 8.94847 58.0926 11.3457 65.9955C11.8147 67.5409 13.6513 67.784 14.3281 66.35C14.7888 65.3722 14.7979 64.0651 14.7843 62.9595C14.5176 43.3658 19.1597 24.5203 21.0836 5.26207C21.1644 4.45762 21.2723 3.66026 21.4011 2.86763C21.5997 1.64401 20.8262 0.503907 19.7704 0.503907H17.8049C17.5291 0.509903 17.2596 0.588097 17.0234 0.730699C16.7873 0.873301 16.5926 1.07532 16.4588 1.31663C16.2131 1.72844 15.9491 2.13508 15.6669 2.53651C13.7368 5.28315 10.7345 6.70138 7.82747 6.26803C5.68516 5.94972 4.7572 4.02683 4.04553 1.7683C3.80522 1.00462 3.18119 0.503907 2.49099 0.503907C1.47262 0.503119 0.690816 1.57172 0.843276 2.75988Z"
                                    transform={"translate(1 ,30)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12353">
                                <path
                                    data-name="Path 5576"
                                    d="M24.3479 30.5046C24.3479 30.5046 25.6332 23.1488 26.0277 16.7431C26.2625 12.9381 26.4217 7.42233 24.2516 6.55071C23.1288 6.10003 17.8252 0.79899 13.1421 0.424734C8.7584 0.0737217 2.33578 6.63325 2.33578 6.63325C-1.31498 12.071 0.260432 27.3881 2.04918 35.418C2.04918 35.418 3.62834 40.268 6.78568 40.9663C9.94302 41.6645 10.442 39.3708 16.4572 40.9663C22.4725 42.5618 24.3479 30.5046 24.3479 30.5046Z"
                                    transform={"translate(0 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5577"
                                    d="M23.2136 29.9352C23.2136 29.9352 24.3692 23.3083 24.7238 17.5387C24.9342 14.1113 25.0784 9.14316 23.1283 8.35782C22.1196 7.95224 17.3514 3.17734 13.1416 2.83932C9.20205 2.52416 3.42906 8.43208 3.42906 8.43208C0.147432 13.3301 1.56231 27.1269 3.17141 34.3605C3.17141 34.3605 4.58965 38.7296 7.42808 39.3592C10.2665 39.9887 10.7142 37.9212 16.1203 39.3592C21.5263 40.7971 23.2136 29.9352 23.2136 29.9352Z"
                                    transform={"translate(0 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5578"
                                    d="M22.0412 29.7865C22.0412 29.7865 23.0915 23.759 23.4135 18.512C23.6058 15.3944 23.7364 10.8756 21.9624 10.1617C21.0453 9.7926 16.7089 5.44966 12.8792 5.14296C9.29643 4.85558 4.04562 10.2297 4.04562 10.2297C1.06143 14.6843 2.34848 27.2321 3.81162 33.8107C3.81162 33.8107 5.10221 37.7843 7.68378 38.3568C10.2654 38.9292 10.6725 37.0488 15.5894 38.3568C20.5064 39.6647 22.0412 29.7865 22.0412 29.7865Z"
                                    transform={"translate(0 ,0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />=
                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6569"
                                        d="M3.37113 1.74805C3.38109 2.00963 3.26053 8.12439 3.47897 14.2463C3.58128 17.1469 5.34653 59.6179 5.18615 59.8153C5.20385 59.9707 3.05536 17.231 2.87452 14.248C2.49459 7.97452 2.16721 1.89404 2.14453 1.74971C2.55218 1.79493 2.96361 1.79438 3.37113 1.74805Z"
                                        transform="translate(9 39)"
                                        style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}

                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-8.317 -1.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-8.317 -1.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154144" x1="14.4887" y1="19.273" x2="12.596" y2="30.0764" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154144" x1="14.4887" y1="27.4098" x2="14.4887" y2="23.4029" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path d="M4.17502 35.1485C7.21933 58.8637 12.1574 89.6428 14.5183 97.4259C14.9802 98.948 16.789 99.1874 17.4555 97.7751C17.9093 96.8122 17.9182 95.5248 17.9048 94.436C17.6421 75.1392 22.2139 56.5792 24.1087 37.6128C24.1882 36.8206 24.2945 36.0353 24.4214 35.2547C24.6169 34.0496 23.8551 32.9268 22.8153 32.9268H20.8797C20.608 32.9327 20.3426 33.0097 20.11 33.1501C19.8774 33.2906 19.6857 33.4895 19.5539 33.7272C19.3119 34.1327 19.052 34.5332 18.7741 34.9286C16.8731 37.6336 13.9163 39.0303 11.0534 38.6035C8.94353 38.2901 8.02964 36.3963 7.32875 34.172C7.09208 33.4199 6.47751 32.9268 5.79776 32.9268C4.79483 32.926 4.02487 33.9784 4.17502 35.1485Z" fill="#E3D1B4" />
                                            <path d="M25.352 29.9734C25.352 29.9734 26.6178 22.729 27.0063 16.4204C27.2376 12.6731 27.3943 7.2409 25.2571 6.38249C24.1514 5.93863 18.9281 0.717917 14.316 0.349333C9.99867 0.00363904 3.67338 6.46377 3.67338 6.46377C0.07793 11.8191 1.62947 26.9041 3.39111 34.8124C3.39111 34.8124 4.94635 39.5889 8.05585 40.2766C11.1653 40.9643 11.6567 38.7052 17.5808 40.2766C23.505 41.8479 25.352 29.9734 25.352 29.9734Z" fill="#EFEDE7" />
                                            <path d="M24.2352 29.4131C24.2352 29.4131 25.3734 22.8867 25.7226 17.2045C25.9298 13.829 26.0718 8.93613 24.1513 8.16269C23.1578 7.76326 18.4619 3.0607 14.3159 2.72781C10.436 2.41742 4.75051 8.23582 4.75051 8.23582C1.5186 13.0596 2.91204 26.6474 4.49676 33.7714C4.49676 33.7714 5.89351 38.0743 8.68893 38.6943C11.4844 39.3143 11.9253 37.2781 17.2494 38.6943C22.5735 40.1104 24.2352 29.4131 24.2352 29.4131Z" fill="#F7F7F7" />
                                            <path d="M23.081 29.2655C23.081 29.2655 24.1154 23.3293 24.4325 18.1618C24.6219 15.0914 24.7505 10.6411 23.0034 9.93805C22.1002 9.57451 17.8294 5.29738 14.0578 4.99534C10.5293 4.7123 5.35808 10.005 5.35808 10.005C2.41911 14.3921 3.68665 26.7498 5.12762 33.2287C5.12762 33.2287 6.39865 37.1421 8.94111 37.7059C11.4836 38.2696 11.8846 36.4177 16.727 37.7059C21.5694 38.994 23.081 29.2655 23.081 29.2655Z" fill="white" />
                                            <path d="M18.759 16H10.241C9.00333 16 8 17.0033 8 18.241V26.759C8 27.9967 9.00333 29 10.241 29H18.759C19.9967 29 21 27.9967 21 26.759V18.241C21 17.0033 19.9967 16 18.759 16Z" fill="#C1C4C5" />
                                            {/* <path d="M18.7574 16.5615H10.2393C9.31109 16.5615 8.55859 17.314 8.55859 18.2423V26.7603C8.55859 27.6885 9.31109 28.441 10.2393 28.441H18.7574C19.6856 28.441 20.4381 27.6885 20.4381 26.7603V18.2423C20.4381 17.314 19.6856 16.5615 18.7574 16.5615Z" stroke="#C1C4C5" /> */}
                                            <path d="M13.5165 28.4166C13.2924 28.8223 15.3945 28.6217 16.971 28.6015C19.3173 28.5522 20.6563 29.0318 20.6339 23.6153C20.6948 21.6933 20.6353 19.7694 20.4558 17.8548C19.8608 17.2767 20.6126 23.7218 19.0899 26.5566C17.6534 28.6038 13.9009 27.9819 13.5165 28.4166Z" fill="#B0B1B1" />
                                            <path d="M16.5071 18.2578H12.0251C11.7157 18.2578 11.4648 18.5086 11.4648 18.8181V19.9386C11.4648 20.248 11.7157 20.4988 12.0251 20.4988H16.5071C16.8165 20.4988 17.0673 20.248 17.0673 19.9386V18.8181C17.0673 18.5086 16.8165 18.2578 16.5071 18.2578Z" fill="#9E9E9E" />
                                            <path d="M16.7297 18.2578H12.2477C11.9383 18.2578 11.6875 18.5086 11.6875 18.8181V19.9386C11.6875 20.248 11.9383 20.4988 12.2477 20.4988H16.7297C17.0392 20.4988 17.29 20.248 17.29 19.9386V18.8181C17.29 18.5086 17.0392 18.2578 16.7297 18.2578Z" fill="url(#paint0_linear_4126_154144)" />
                                            <path d="M16.7297 23.5225H12.2477C11.9383 23.5225 11.6875 23.7733 11.6875 24.0827V25.2032C11.6875 25.5126 11.9383 25.7635 12.2477 25.7635H16.7297C17.0392 25.7635 17.29 25.5126 17.29 25.2032V24.0827C17.29 23.7733 17.0392 23.5225 16.7297 23.5225Z" fill="#CBCCCC" />
                                            <path d="M16.7297 23.2998H12.2477C11.9383 23.2998 11.6875 23.5506 11.6875 23.8601V24.9805C11.6875 25.29 11.9383 25.5408 12.2477 25.5408H16.7297C17.0392 25.5408 17.29 25.29 17.29 24.9805V23.8601C17.29 23.5506 17.0392 23.2998 16.7297 23.2998Z" fill="url(#paint1_linear_4126_154144)" />
                                            <path d="M1.49609 21.5166C7.48427 21.983 12.547 23.5866 26.3828 21.6205" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path d="M1.49609 20.9863C7.06307 21.4965 13.7747 23.0811 26.3828 21.0024" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
