import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface RecommendAllInterface extends FindAllInterface {
  useWith?: 'ALL' | 'USER'
}

export interface RecommendInterface {
  recommendText: string
  status: string
}

export interface RecommendByUserInterface {
  recommendText: string
  recommendId: number | null
}

export interface RecommendAllInterface extends FindAllInterface {
  useWith?: 'ALL' | 'USER'
}

export default class RecommendApi extends BaseAPI {
  static findAll(props: RecommendAllInterface): Promise<any> {
    return this.api.get('recommends', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('recommends', playload)
  }

  static findById(id: number): Promise<RecommendInterface> {
    return this.api.get(`recommends/${id}`)
  }

  static update(id: number, body: RecommendInterface) {
    return this.api.patch(`recommends/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`recommends/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`recommends/${id}`)
  }

  static updateByUser(payload: RecommendByUserInterface[]) {
    return this.api.post(`recommends/user`, payload)
  }

  static updateByUserOne(payload: RecommendByUserInterface) {
    return this.api.post(`recommends/user/add`, payload)
  }
}

