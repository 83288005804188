import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/72.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 25.9, height: 100, viewBox: "0 0 25.9 110" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })


        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 25.9, height: 100, viewBox: "0 0 25.9 110" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>72</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12348">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5594"
                                d="M21.0093 3.23533C17.9349 27.1917 12.9468 58.2803 10.5617 66.1445C10.0952 67.682 8.26805 67.9238 7.59493 66.4972C7.13658 65.5245 7.12756 64.2241 7.14108 63.1234C7.40641 43.6317 2.78824 24.8827 0.873326 5.72402C0.793767 4.9245 0.686578 4.13048 0.558421 3.34174C0.360698 2.12445 1.13062 0.990234 2.18096 0.990234H4.13624C4.41074 0.996234 4.67886 1.07415 4.91382 1.2162C5.14879 1.35825 5.34237 1.55946 5.47523 1.79974C5.71979 2.20942 5.98244 2.61395 6.26318 3.01331C8.18359 5.74479 11.1702 7.15609 14.0624 6.72557C16.1938 6.40792 17.1168 4.49595 17.8248 2.2481C18.064 1.48836 18.6848 0.990234 19.3715 0.990234C20.3838 0.991018 21.1618 2.05311 21.0093 3.23533Z"
                                transform={"translate(3 ,30)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />

                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5594"
                                    d="M21.0093 3.23533C17.9349 27.1917 12.9468 58.2803 10.5617 66.1445C10.0952 67.682 8.26805 67.9238 7.59493 66.4972C7.13658 65.5245 7.12756 64.2241 7.14108 63.1234C7.40641 43.6317 2.78824 24.8827 0.873326 5.72402C0.793767 4.9245 0.686578 4.13048 0.558421 3.34174C0.360698 2.12445 1.13062 0.990234 2.18096 0.990234H4.13624C4.41074 0.996234 4.67886 1.07415 4.91382 1.2162C5.14879 1.35825 5.34237 1.55946 5.47523 1.79974C5.71979 2.20942 5.98244 2.61395 6.26318 3.01331C8.18359 5.74479 11.1702 7.15609 14.0624 6.72557C16.1938 6.40792 17.1168 4.49595 17.8248 2.2481C18.064 1.48836 18.6848 0.990234 19.3715 0.990234C20.3838 0.991018 21.1618 2.05311 21.0093 3.23533Z"
                                    transform={"translate(3 ,30)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12348">
                                <path
                                    data-name="Path 5595"
                                    d="M2.61718 30.0091C2.61718 30.0091 1.33856 22.6902 0.946052 16.3178C0.712273 12.5325 0.554132 7.04542 2.71282 6.17811C3.82979 5.73034 9.10598 0.456091 13.7649 0.0837686C18.126 -0.264451 24.5154 6.26139 24.5154 6.26139C28.1473 11.6699 26.5801 26.908 24.8006 34.8967C24.8006 34.8967 23.2296 39.7224 20.0877 40.4171C16.9459 41.1118 16.4511 38.8298 10.4677 40.4171C4.48428 42.0043 2.61718 30.0091 2.61718 30.0091Z"

                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5596"
                                    d="M3.73977 29.4427C3.73977 29.4427 2.59008 22.85 2.23735 17.1102C2.02807 13.7005 1.88463 8.758 3.82463 7.97671C4.82794 7.57323 9.57171 2.82298 13.7597 2.4877C17.6789 2.17416 23.4219 8.05157 23.4219 8.05157C26.6868 12.9233 25.2782 26.6499 23.6782 33.8453C23.6782 33.8453 22.2673 38.1919 19.4435 38.8181C16.6198 39.4444 16.1744 37.3876 10.7963 38.8181C5.41816 40.2486 3.73977 29.4427 3.73977 29.4427Z"

                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5597"
                                    d="M4.91305 29.2935C4.91305 29.2935 3.86819 23.2983 3.54702 18.0773C3.35674 14.9769 3.22663 10.4814 4.99163 9.77122C5.90402 9.40399 10.2181 5.08349 14.0277 4.7776C17.592 4.49268 22.8157 9.83805 22.8157 9.83805C25.7845 14.2695 24.5041 26.7534 23.0485 33.2983C23.0485 33.2983 21.7644 37.2504 19.1963 37.8208C16.6283 38.3913 16.223 36.5197 11.3315 37.8208C6.43996 39.122 4.91305 29.2935 4.91305 29.2935Z"

                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />

                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6567"
                                        d="M3.647 2.64258C3.8578 6.77659 3.67168 10.9213 3.09121 15.0198C2.11955 21.8341 1.76863 60.5124 1.92735 60.7099C3.41304 56.39 2.4731 20.1449 3.34782 15.6613C4.13121 11.3788 4.58161 7.04215 4.69498 2.69014C4.34505 2.7189 3.99288 2.70292 3.647 2.64258Z"
                                        transform="translate(9.5 39)"
                                        style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-6.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-6.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154143" x1="13.9926" y1="19.273" x2="12.0999" y2="30.0764" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154143" x1="13.9926" y1="27.4098" x2="13.9926" y2="23.4029" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path d="M23.7185 34.633C20.6907 58.2264 15.7781 88.8438 13.4292 96.5889C12.9697 98.1031 11.1703 98.3413 10.5074 96.9363C10.056 95.9783 10.0471 94.6976 10.0604 93.6136C10.3217 74.4173 5.77351 55.9524 3.88762 37.0839C3.80926 36.2965 3.7037 35.5145 3.57748 34.7378C3.38275 33.5389 4.14101 32.4219 5.17543 32.4219H7.10109C7.37143 32.4278 7.63549 32.5045 7.86689 32.6444C8.09829 32.7843 8.28894 32.9825 8.41979 33.2191C8.66064 33.6226 8.91931 34.021 9.1958 34.4143C11.0871 37.1044 14.0285 38.4943 16.8768 38.0703C18.976 37.7575 19.8849 35.8745 20.5822 33.6607C20.8179 32.9125 21.4292 32.4219 22.1055 32.4219C23.1025 32.4226 23.8686 33.4686 23.7185 34.633Z" fill="#E3D1B4" />
                                            <path d="M2.64849 29.4851C2.64849 29.4851 1.38924 22.2771 1.00268 16.0013C0.772445 12.2733 0.6167 6.86942 2.74268 6.01525C3.84272 5.57426 9.03897 0.379933 13.6273 0.0132522C17.9223 -0.329691 24.2149 6.09726 24.2149 6.09726C27.7918 11.4238 26.2483 26.4311 24.4957 34.2987C24.4957 34.2987 22.9485 39.0513 19.8543 39.7354C16.7601 40.4196 16.2728 38.1722 10.3801 39.7354C4.4873 41.2986 2.64849 29.4851 2.64849 29.4851Z" fill="#EFEDE7" />
                                            <path d="M3.76212 28.9257C3.76212 28.9257 2.62985 22.4329 2.28247 16.78C2.07635 13.422 1.93509 8.55441 3.84569 7.78496C4.8338 7.38759 9.50569 2.70932 13.6303 2.37912C17.4901 2.07033 23.146 7.85868 23.146 7.85868C26.3614 12.6566 24.9742 26.1752 23.3985 33.2616C23.3985 33.2616 22.0089 37.5423 19.2279 38.1591C16.447 38.7759 16.0083 36.7502 10.7117 38.1591C5.41508 39.5679 3.76212 28.9257 3.76212 28.9257Z" fill="#F7F7F7" />
                                            <path d="M4.91419 28.7802C4.91419 28.7802 3.88516 22.8759 3.56885 17.7341C3.38146 14.6806 3.25332 10.2532 4.99158 9.55379C5.89014 9.19213 10.1388 4.93709 13.8907 4.63583C17.401 4.35522 22.5456 9.6196 22.5456 9.6196C25.4694 13.9839 24.2084 26.2787 22.7748 32.7244C22.7748 32.7244 21.5102 36.6166 18.981 37.1784C16.4519 37.7402 16.0528 35.8969 11.2354 37.1784C6.41796 38.4598 4.91419 28.7802 4.91419 28.7802Z" fill="white" />
                                            <path d="M18.2629 16H9.7449C8.50723 16 7.50391 17.0033 7.50391 18.241V26.759C7.50391 27.9967 8.50723 29 9.7449 29H18.2629C19.5006 29 20.5039 27.9967 20.5039 26.759V18.241C20.5039 17.0033 19.5006 16 18.2629 16Z" fill="#C1C4C5" />
                                            {/* <path d="M18.2613 16.5615H9.74324C8.815 16.5615 8.0625 17.314 8.0625 18.2423V26.7603C8.0625 27.6885 8.815 28.441 9.74324 28.441H18.2613C19.1895 28.441 19.942 27.6885 19.942 26.7603V18.2423C19.942 17.314 19.1895 16.5615 18.2613 16.5615Z" stroke="#C1C4C5" /> */}
                                            <path d="M13.0204 28.4166C12.7963 28.8223 14.8984 28.6217 16.4749 28.6015C18.8212 28.5522 20.1602 29.0318 20.1378 23.6153C20.1987 21.6933 20.1392 19.7694 19.9597 17.8548C19.3647 17.2767 20.1165 23.7218 18.5938 26.5566C17.1573 28.6038 13.4048 27.9819 13.0204 28.4166Z" fill="#B0B1B1" />
                                            <path d="M16.011 18.2578H11.529C11.2196 18.2578 10.9688 18.5086 10.9688 18.8181V19.9386C10.9688 20.248 11.2196 20.4988 11.529 20.4988H16.011C16.3204 20.4988 16.5712 20.248 16.5712 19.9386V18.8181C16.5712 18.5086 16.3204 18.2578 16.011 18.2578Z" fill="#9E9E9E" />
                                            <path d="M16.2336 18.2578H11.7517C11.4422 18.2578 11.1914 18.5086 11.1914 18.8181V19.9386C11.1914 20.248 11.4422 20.4988 11.7517 20.4988H16.2336C16.5431 20.4988 16.7939 20.248 16.7939 19.9386V18.8181C16.7939 18.5086 16.5431 18.2578 16.2336 18.2578Z" fill="url(#paint0_linear_4126_154143)" />
                                            <path d="M16.2336 23.5225H11.7517C11.4422 23.5225 11.1914 23.7733 11.1914 24.0827V25.2032C11.1914 25.5126 11.4422 25.7635 11.7517 25.7635H16.2336C16.5431 25.7635 16.7939 25.5126 16.7939 25.2032V24.0827C16.7939 23.7733 16.5431 23.5225 16.2336 23.5225Z" fill="#CBCCCC" />
                                            <path d="M16.2336 23.2998H11.7517C11.4422 23.2998 11.1914 23.5506 11.1914 23.8601V24.9805C11.1914 25.29 11.4422 25.5408 11.7517 25.5408H16.2336C16.5431 25.5408 16.7939 25.29 16.7939 24.9805V23.8601C16.7939 23.5506 16.5431 23.2998 16.2336 23.2998Z" fill="url(#paint1_linear_4126_154143)" />
                                            <path d="M1 21.5166C6.98818 21.983 12.0509 23.5866 25.8867 21.6205" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path d="M1 20.9863C6.56698 21.4965 13.2786 23.0811 25.8867 21.0024" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
