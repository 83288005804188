import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/52.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth52(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 26.96, height: 100, viewBox: "0 0 26.96 110" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 26.96, height: 100, viewBox: "0 0 26.96 110" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12355" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5432"
                                d="M23.037 58.678C26.4963 67.2172 27.9716 76.2746 26.3352 85.7042C26.3045 86.3243 26.2683 86.9437 26.2324 87.5632L26.1021 86.9235C26.0379 87.2674 25.9606 87.6169 25.8839 87.959C23.6071 91.165 20.2542 90.4761 17.5155 90.6556C12.9372 90.9495 8.22757 90.2774 3.90523 87.7911C0.585766 85.4386 -0.318519 81.3203 0.157219 77.3199C2.38746 58.4396 0.834719 38.8459 7.26157 20.7399C8.99295 15.8641 9.05982 10.4482 8.61026 5.11129C8.49066 3.63765 8.40181 2.14739 9.52906 0.589299C12.7024 -0.794544 13.5436 2.40169 14.5079 5.16937C17.9328 15.0309 17.4991 25.6261 18.2891 35.9936C18.891 43.8669 20.1029 51.4598 23.037 58.678Z"
                                transform={"translate(-1 10)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5432"
                                    d="M23.037 58.678C26.4963 67.2172 27.9716 76.2746 26.3352 85.7042C26.3045 86.3243 26.2683 86.9437 26.2324 87.5632L26.1021 86.9235C26.0379 87.2674 25.9606 87.6169 25.8839 87.959C23.6071 91.165 20.2542 90.4761 17.5155 90.6556C12.9372 90.9495 8.22757 90.2774 3.90523 87.7911C0.585766 85.4386 -0.318519 81.3203 0.157219 77.3199C2.38746 58.4396 0.834719 38.8459 7.26157 20.7399C8.99295 15.8641 9.05982 10.4482 8.61026 5.11129C8.49066 3.63765 8.40181 2.14739 9.52906 0.589299C12.7024 -0.794544 13.5436 2.40169 14.5079 5.16937C17.9328 15.0309 17.4991 25.6261 18.2891 35.9936C18.891 43.8669 20.1029 51.4598 23.037 58.678Z"
                                    transform={"translate(-1 10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12355" >
                                <path
                                    data-name="Path 5433"
                                    d="M3.07915 36.4305C1.12347 33.6707 0.218991 27.586 1.16856 23.2346C1.53443 21.5394 2.00176 19.8651 2.53387 18.2155C2.53998 18.2155 2.53998 18.2075 2.53921 18.2006C3.72091 14.5795 5.23659 11.0741 6.79028 7.68546C10.8874 -1.25937 18.978 -1.17301 23.3083 7.51045C23.4367 7.76475 23.5592 8.02631 23.6822 8.29704C25.931 13.325 27.6633 18.6433 28.5474 24.3409C28.3494 29.3988 29.1853 31.095 26.3647 36.8488C22.8823 41.3381 7.68043 42.6375 3.07915 36.4305Z"
                                    transform={"translate(-2 62)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5434"
                                    d="M3.30272 39.2103C1.30367 36.4953 0.304423 30.4326 1.18502 26.06C1.52453 24.3567 1.96549 22.6729 2.47123 21.0109C2.4783 21.0109 2.47734 21.0029 2.47658 20.995C3.60192 17.3483 5.06142 13.8081 6.56238 10.3847C10.5173 1.34763 18.6085 1.24809 23.0743 9.83067C23.2063 10.0813 23.3338 10.341 23.4614 10.6085C25.7875 15.5846 27.6034 20.8615 28.5766 26.5379C28.4578 31.5992 29.32 33.2761 26.5896 39.0941C23.18 43.6629 8.00106 45.3108 3.30272 39.2103Z"
                                    transform={"translate(-2 62)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5435"
                                    d="M4.05351 36.7676C2.329 34.4249 1.46694 29.1927 2.22698 25.4202C2.51987 23.951 2.90008 22.497 3.33722 21.0641C3.34257 21.0641 3.34162 21.057 3.34162 21.0509C4.31201 17.904 5.57186 14.8493 6.86609 11.8949C10.2796 4.09743 17.2607 4.01203 21.1143 11.4173C21.2278 11.6338 21.3379 11.8581 21.4477 12.0877C23.4554 16.382 25.0205 20.9349 25.8612 25.8325C25.7582 30.2007 26.5026 31.647 24.1474 36.6671C21.2049 40.6093 8.10779 42.0311 4.05351 36.7676Z"
                                    transform={"translate(-2 62)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6534"
                                        d="M2.0666 2.13604C2.02393 1.76004 2.15668 48.706 3.29321 51.1242C3.29588 51.3146 3.38655 51.098 3.91721 51.1242C3.91585 47.4695 1.3402 3.0379 2.0666 2.13604Z"
                                        transform="translate(8 10)"
                                        style={{
                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-8 14)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-8 14)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154149" x1="14.4786" y1="74.7759" x2="12.2945" y2="87.2425" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154149" x1="14.4786" y1="83.521" x2="14.4786" y2="78.8973" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9384">
                                            <path transform={"translate(-1 10)"} d="M23.2375 57.5211C26.5912 65.7997 28.0216 74.5807 26.4351 83.7225C26.4052 84.3237 26.3702 84.9242 26.3354 85.5247L26.2091 84.9046C26.1469 85.238 26.0718 85.5768 25.9976 85.9085C23.7902 89.0167 20.5396 88.3487 17.8845 88.5228C13.4459 88.8077 8.88002 88.1561 4.68959 85.7457C1.47143 83.465 0.594738 79.4724 1.05596 75.5941C3.21813 57.2899 1.71278 38.2942 7.9435 20.7408C9.62205 16.0138 9.68688 10.7631 9.25103 5.58909C9.13508 4.16042 9.04895 2.71564 10.1418 1.2051C13.2183 -0.136512 14.0338 2.96218 14.9687 5.6454C18.2891 15.206 17.8686 25.4778 18.6345 35.5289C19.218 43.162 20.3929 50.5232 23.2375 57.5211Z" fill="#E3D1B4" />
                                            <path transform={"translate(-1 10)"} d="M2.91942 87.3342C1.02342 84.6586 0.146544 78.7596 1.06713 74.5411C1.42184 72.8975 1.87491 71.2744 2.39077 69.6751C2.3967 69.6751 2.3967 69.6673 2.39596 69.6606C3.5416 66.15 5.01102 62.7516 6.5173 59.4664C10.4893 50.7945 18.333 50.8783 22.5312 59.2967C22.6557 59.5433 22.7744 59.7968 22.8937 60.0593C25.0739 64.9338 26.7533 70.0898 27.6104 75.6135C27.4185 80.5171 28.2289 82.1615 25.4943 87.7397C22.1182 92.092 7.38028 93.3518 2.91942 87.3342Z" fill="#EFEDE7" />
                                            <path transform={"translate(-1 10)"} d="M3.13843 90.0286C1.20038 87.3965 0.231634 81.5188 1.08535 77.2797C1.4145 75.6284 1.84201 73.9959 2.33231 72.3846C2.33917 72.3846 2.33824 72.3769 2.3375 72.3693C3.4285 68.8338 4.84346 65.4017 6.29861 62.0828C10.1328 53.3215 17.9771 53.225 22.3066 61.5456C22.4346 61.7886 22.5582 62.0404 22.6819 62.2997C24.9371 67.124 26.6975 72.2398 27.641 77.7429C27.5258 82.6498 28.3618 84.2756 25.7147 89.916C22.4091 94.3454 7.69339 95.9429 3.13843 90.0286Z" fill="#F7F7F7" />
                                            <path transform={"translate(-1 10)"} d="M3.86429 87.6612C2.19241 85.39 1.35666 80.3175 2.0935 76.6601C2.37746 75.2357 2.74606 73.8261 3.16987 72.4369C3.17505 72.4369 3.17413 72.4301 3.17413 72.4241C4.1149 69.3732 5.3363 66.4118 6.59104 63.5476C9.90034 55.988 16.6684 55.9053 20.4045 63.0845C20.5145 63.2944 20.6212 63.5119 20.7277 63.7345C22.6741 67.8977 24.1915 72.3117 25.0065 77.0599C24.9066 81.2947 25.6283 82.6969 23.345 87.5638C20.4923 91.3856 7.79484 92.7641 3.86429 87.6612Z" fill="white" />
                                            <path transform={"translate(-1 10)"} d="M19.414 71H9.58598C8.15778 71 7 72.1578 7 73.586V83.414C7 84.8422 8.15778 86 9.58598 86H19.414C20.8422 86 22 84.8422 22 83.414V73.586C22 72.1578 20.8422 71 19.414 71Z" fill="#C1C4C5" />
                                            {/* <path transform={"translate(-1 10)"} d="M19.416 71.6465H9.58793C8.51678 71.6465 7.64844 72.5148 7.64844 73.586V83.414C7.64844 84.4852 8.51678 85.3535 9.58793 85.3535H19.416C20.4871 85.3535 21.3554 84.4852 21.3554 83.414V73.586C21.3554 72.5148 20.4871 71.6465 19.416 71.6465Z" stroke="#C1C4C5" /> */}
                                            <path transform={"translate(-1 10)"} d="M13.3628 85.3314C13.1042 85.7995 15.5299 85.568 17.3491 85.5447C20.0566 85.4878 21.6018 86.0413 21.5759 79.7909C21.6462 77.573 21.5775 75.353 21.3703 73.1437C20.6837 72.4765 21.5513 79.9138 19.7942 83.185C18.1365 85.5473 13.8063 84.8297 13.3628 85.3314Z" fill="#B0B1B1" />
                                            <path transform={"translate(-1 10)"} d="M17.0646 79.0352H11.8926C11.5355 79.0352 11.2461 79.3246 11.2461 79.6817V80.9746C11.2461 81.3317 11.5355 81.6211 11.8926 81.6211H17.0646C17.4216 81.6211 17.7111 81.3317 17.7111 80.9746V79.6817C17.7111 79.3246 17.4216 79.0352 17.0646 79.0352Z" fill="#CBCCCC" />
                                            <path transform={"translate(-1 10)"} d="M16.8146 73.6045H11.6426C11.2855 73.6045 10.9961 73.8939 10.9961 74.251V75.544C10.9961 75.901 11.2855 76.1905 11.6426 76.1905H16.8146C17.1716 76.1905 17.4611 75.901 17.4611 75.544V74.251C17.4611 73.8939 17.1716 73.6045 16.8146 73.6045Z" fill="#9E9E9E" />
                                            <path transform={"translate(-1 10)"} d="M17.0646 73.6045H11.8926C11.5355 73.6045 11.2461 73.8939 11.2461 74.251V75.544C11.2461 75.901 11.5355 76.1905 11.8926 76.1905H17.0646C17.4216 76.1905 17.7111 75.901 17.7111 75.544V74.251C17.7111 73.8939 17.4216 73.6045 17.0646 73.6045Z" fill="url(#paint0_linear_4126_154149)" />
                                            <path transform={"translate(-1 10)"} d="M17.0646 78.7783H11.8926C11.5355 78.7783 11.2461 79.0678 11.2461 79.4248V80.7178C11.2461 81.0749 11.5355 81.3643 11.8926 81.3643H17.0646C17.4216 81.3643 17.7111 81.0749 17.7111 80.7178V79.4248C17.7111 79.0678 17.4216 78.7783 17.0646 78.7783Z" fill="url(#paint1_linear_4126_154149)" />
                                            <path transform={"translate(-1 10)"} d="M0.679688 79.2787C0.679688 79.2787 12.3592 74.3071 27.712 78.247" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={"translate(-1 10)"} d="M0.679688 79.7885C9.47554 76.7935 18.7669 76.4303 27.712 78.7318" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>52</TextNumberU>
        </Div >
    )
}
