import { colors } from "constants/theme"

export default function WarningCircle(props: { type?: 'thin' | 'default', firstFill?: string, secondFill?: string }) {
  return (
    props.type === 'thin' && (
      <svg width="103" height="103" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M51.5 6.4375C42.5875 6.4375 33.8751 9.08037 26.4646 14.0319C19.0541 18.9834 13.2784 26.0212 9.8677 34.2553C6.45702 42.4894 5.56464 51.55 7.30338 60.2913C9.04213 69.0325 13.3339 77.0619 19.636 83.364C25.9381 89.6661 33.9675 93.9579 42.7088 95.6966C51.45 97.4354 60.5106 96.543 68.7447 93.1323C76.9788 89.7216 84.0166 83.9459 88.9681 76.5354C93.9197 69.1249 96.5625 60.4125 96.5625 51.5C96.5625 39.5487 91.8149 28.0869 83.364 19.636C74.9132 11.1851 63.4513 6.4375 51.5 6.4375V6.4375ZM51.5 90.125C43.8607 90.125 36.393 87.8597 30.0411 83.6155C23.6893 79.3713 18.7386 73.3389 15.8152 66.2811C12.8917 59.2234 12.1268 51.4572 13.6172 43.9646C15.1075 36.4721 18.7862 29.5898 24.188 24.188C29.5898 18.7862 36.4721 15.1075 43.9647 13.6172C51.4572 12.1268 59.2234 12.8917 66.2812 15.8152C73.339 18.7386 79.3714 23.6893 83.6155 30.0411C87.8597 36.3929 90.125 43.8607 90.125 51.5C90.125 61.744 86.0556 71.5684 78.812 78.812C71.5684 86.0556 61.744 90.125 51.5 90.125Z" fill="url(#paint0_linear_3679_155265)" />
        <path d="M48.2813 25.75H54.7188V61.1562H48.2813V25.75ZM51.5 70.8125C50.5451 70.8125 49.6116 71.0957 48.8176 71.6262C48.0237 72.1567 47.4048 72.9108 47.0394 73.793C46.674 74.6752 46.5784 75.646 46.7646 76.5825C46.9509 77.5191 47.4108 78.3794 48.086 79.0546C48.7612 79.7298 49.6215 80.1897 50.5581 80.376C51.4946 80.5623 52.4654 80.4667 53.3476 80.1012C54.2299 79.7358 54.9839 79.117 55.5144 78.323C56.045 77.529 56.3281 76.5955 56.3281 75.6406C56.3281 74.3601 55.8194 73.1321 54.914 72.2266C54.0086 71.3212 52.7805 70.8125 51.5 70.8125Z" fill="url(#paint1_linear_3679_155265)" />
        <defs>
          <linearGradient id="paint0_linear_3679_155265" x1="52.5815" y1="1.57075" x2="52.7618" y2="96.5625" gradientUnits="userSpaceOnUse">
            <stop stopColor={`${props.firstFill || colors.memberRanking.rankingGold}`} />
            <stop offset="1" stopColor={`${props.secondFill || `#FF9201`}`} />
          </linearGradient>
          <linearGradient id="paint1_linear_3679_155265" x1="51.6159" y1="22.7952" x2="52.236" y2="80.4623" gradientUnits="userSpaceOnUse">
            <stop stopColor={`${props.firstFill || colors.memberRanking.rankingGold}`} />
            <stop offset="1" stopColor={`${props.secondFill || `#FF9201`}`} />
          </linearGradient>
        </defs>
      </svg>
    ) || (
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="https://www.w3.org/2000/svg">
        <path d="M10.5 1.96875C8.2215 1.96875 6.07819 2.856 4.46775 4.46775C2.85731 6.0795 1.96875 8.2215 1.96875 10.5C1.96875 12.7785 2.856 14.9218 4.46775 16.5322C6.0795 18.1427 8.2215 19.0312 10.5 19.0312C12.7785 19.0312 14.9218 18.144 16.5322 16.5322C18.1427 14.9205 19.0312 12.7785 19.0312 10.5C19.0312 8.2215 18.144 6.07819 16.5322 4.46775C14.9205 2.85731 12.7785 1.96875 10.5 1.96875ZM10.5 0C13.2848 0 15.9555 1.10625 17.9246 3.07538C19.8938 5.04451 21 7.71523 21 10.5C21 13.2848 19.8938 15.9555 17.9246 17.9246C15.9555 19.8938 13.2848 21 10.5 21C7.71523 21 5.04451 19.8938 3.07538 17.9246C1.10625 15.9555 0 13.2848 0 10.5C0 7.71523 1.10625 5.04451 3.07538 3.07538C5.04451 1.10625 7.71523 0 10.5 0V0ZM9.1875 14.4375H11.8125V17.0625H9.1875V14.4375ZM9.1875 3.9375H11.8125V11.8125H9.1875V3.9375Z" fill="url(#paint0_linear_841_96060)" />
        <defs>
          <linearGradient id="paint0_linear_841_96060" x1="9.471" y1="6.63722e-10" x2="9.198" y2="29.673" gradientUnits="userSpaceOnUse">
            <stop stopColor={`${props.firstFill || colors.memberRanking.rankingGold} `} />
            <stop offset="1" stopColor={`${props.secondFill || `#FF9201`} `} />
          </linearGradient>
        </defs>
      </svg>
    )
  )
}
