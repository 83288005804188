import { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'
import { Box, ClickAwayListener, MenuItem } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API, UTIL*/
import { getBranch, getServicePoint, toBuddhistYear } from 'utils/app.utils'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'

/** COMPONENT */
import Loading from 'component/Loading'
import FucntionPrint from 'component/Print/print'
import SearchPatients from 'component/SearchPatients'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import TransactionRevenueList from './TransactionRevenueList'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/**STYLED */
import * as UseStyled from 'features/report/useStyled'
import ProductsApi from 'api/warehouses/product.api'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import OperativeApi from 'api/setting/treatments/operative.api'
import ComponentApi from 'api/components-api'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import InputCheckbox from 'component/Input/InputCheckbox'
import { SpanClearFilter } from 'features/report/useStyled'
import { colors } from 'constants/theme'
import ButtonCustom from 'component/Button/ButtonCustom'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}
interface DentistInterface {
  dentistId: number
  dentistFullname: string
}
export default function TransactionRevenue() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [branchId, setBranchId] = useState<number>(getBranch())
  const [branches, setBranches] = useState<any>([])
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [rowCount, setRowCount] = useState<number>(0)
  const [billStatus, setBillStatus] = useState<string>('ALL')
  const [prefix, setPrefix] = useState<string>('ALL')
  const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')

  const [patientId, setPatientId] = useState<number>(0)

  const [itemCode, setItemCode] = useState<any[]>([])
  const [groupCode, setGroupCode] = useState<string>('')

  const [productTypes, setProductTypes] = useState<any[]>([])
  const [operativeTypes, setOperativeTypes] = useState<any[]>([])
  const [operativeTypeId, setOperativeTypeId] = useState<number>(0)
  const [operativeId, setOperativeId] = useState<number>(0)
  const [group, setGroup] = useState<string>('')

  const [typeSelected, setTypeSelected] = useState({ productTypeName: '', productTypeId: 0, group: '' })
  const [codeSelected, setCodeSelected] = useState({ productCode: ``, productId: 0, group: `` })

  const [doctorId, setDoctorId] = useState<number>(0)
  const [doctors, setDoctors] = useState<Doctors[]>([])

  const [transactionData, setTransactionData] = useState<any[]>([])
  const [transactionDataExport, setTransactionDataExport] = useState<any[]>([])
  const [transactionPage, setTransactionPage] = useState<number>(1)
  const [transactionLimitPage, setTransactionLimitPage] = useState<number>(10)

  const [fetchData, setFetchData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [patientFilter, setPatientFilter] = useState<string>(t('REPORT.REPORT_GROUP.ALL'))
  const [selectDoctors, setSelectDoctors] = useState<any[]>(['ALL'])
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  const setDoc = (data: any) => {
    const sortedSelectData = data.sort((a: any, b: any) => {
      const idA = a.dentistId
      const idB = b.dentistId
      return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB)
    })
    return sortedSelectData
  }

  const getAllFilter = () => {
    return [
      [
        t('SEARCH_BY'),
        patientFilter,
        t('REPORT.FILTER.BRANCH'),
        _.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn'),
        'วันที่เริ่มต้น',
        rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-',
        'วันที่สิ้นสุด',
        rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-',
        t('REPORT.FILTER.RECEIPT_STATUS'),
        billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)
      ],
      [
        t('REPORT.FILTER.RECEIPT_TYPE'),
        prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix,
        t('REPORT.FILTER.OPERATIVE_TYPE'),
        typeSelected.productTypeName || t('REPORT.REPORT_GROUP.ALL'),
        t('REPORT.FILTER.OPERATIVE_CODE'),
        codeSelected.productCode || t('REPORT.REPORT_GROUP.ALL'),
        t('REPORT.FILTER.DOCTOR'),
        _.isEmpty(selectDoctors)
          ? ` ${t('REPORT.FILTER.NO_SELECT')}`
          : selectDoctors.filter((i) => typeof i === 'number').length === doctors.length
            ? `${t('REPORT.FILTER.ALL')}`
            : `${setDoc(doctors.filter((d: any) => selectDoctors.includes(d.userId)))
              ?.map((d: any) => d.fullname)
              .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
              .join(', ')}`
      ]
    ]
  }

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = { page: 1, pageLimit: 200 }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'TRANSACTION_REVENUE') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    ClinicApi.allBillPrefix()
      .then((resp) => setReceiptPrefix(resp.data))
      .catch((e) => {
        return
      })

    ClinicApi.findAllDoctors()
      .then((resp) => {
        setDoctors([{ userId: 0, fullname: t('REPORT.TITLE.NO_DOCTOR') }, ...resp.data])
      })
      .catch((e) => {
        return
      })
    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
      const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
        return {
          dentistId: d.userId,
          dentistFullname: d.fullname
        }
      })
      setSelectDoctors([...dentists.map((d: DentistInterface) => d.dentistId)])
    })
    return true
  }

  const loadOperativeType = useCallback((search = '') => {
    OperativeTypeApi.findAll({ page: 1, status: 'ACTIVE', pageLimit: 50, search: search })
      .then((resp) => setOperativeTypes(resp.data))
      .catch((e) => {
        return
      })
  }, [])

  const loadProductType = useCallback((search = '') => {
    ProductTypesApi.findAll({ page: 1, status: 'ACTIVE', pageLimit: 50, search: search })
      .then((resp) => setProductTypes(resp.data))
      .catch((e) => {
        return
      })
  }, [])

  const loadItemCode = useCallback((search = '', itemTypeId = null, group = ``) => {
    ComponentApi.findAllItemCode({ itemTypeId, group, search: search })
      .then((resp) => setItemCode(resp.data))
      .catch((e) => {
        return
      })
  }, [])

  const handleFilterDoctors = (value: any) => {
    const val = value === 'ALL' ? value : Number(value)
    if ((val === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || val === 'CLEAR') setSelectDoctors([''])
    else if (val === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, val)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== val))
    else setSelectDoctors([...selectDoctors, val])
  }

  useEffect(() => {
    verifyPermission()
  }, [branchId])

  useEffect(() => {
    loadOperativeType()
    loadProductType()
    loadItemCode()
  }, [loadOperativeType, loadProductType, loadItemCode])

  const fetchTransaction = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: transactionPage,
      pageLimit: transactionLimitPage,
      branchId: branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1],
      operativeType: operativeTypeId,
      groupCode: groupCode,
      group: group,
      patientId: patientId,
      billStatus: billStatus,
      operativeCode: operativeId
    }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (prefix) condition = { ...condition, prefix: prefix === 'ALL' ? '' : prefix }
    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    if (doctors) {
      const isAllSelected = selectDoctors.includes('ALL')
      const filteredDoctors = isAllSelected ? selectDoctors.filter((doctor) => doctor !== 'ALL') : selectDoctors
      condition = { ...condition, doctorId: filteredDoctors.join(',').replace(/^,|,$/g, '') }
    }

    await ReportApi.reportTransactionRevenue(condition)
      .then(({ headers, data }) => {
        setTransactionData(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
    setFetchData(false)
  }, [branchId, transactionPage, transactionLimitPage, prefix, rangeDate, operativeId, patientId, groupCode, patientId, billStatus, operativeTypeId, doctorId, group, selectDoctors, fetchData])

  const fetchTransactionExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: transactionPage,
      pageLimit: transactionLimitPage,
      branchId: branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1],
      operativeType: operativeTypeId,
      groupCode: groupCode,
      group: group,
      patientId: patientId,
      billStatus: billStatus,
      operativeCode: operativeId,
      export: 1
    }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (prefix) condition = { ...condition, prefix: prefix === 'ALL' ? '' : prefix }
    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }

    if (doctors) {
      const isAllSelected = selectDoctors.includes('ALL')
      const filteredDoctors = isAllSelected ? selectDoctors.filter((doctor) => doctor !== 'ALL') : selectDoctors
      condition = { ...condition, doctorId: filteredDoctors.join(',').replace(/^,|,$/g, '') }
    }

    await ReportApi.reportTransactionRevenue(condition)
      .then(({ data }) => {
        setTransactionDataExport(data)
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [branchId, transactionPage, transactionLimitPage, prefix, rangeDate, operativeId, patientId, groupCode, patientId, billStatus, operativeTypeId, doctorId, group, selectDoctors])
  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.TRANSACTION_REVENUE`)} ${rangeDate[0] ? `(${rangeDate[0]}-${rangeDate[1]})` : ''}  `,
    sheetName: `${t(`REPORT.REPORT_NAME.TRANSACTION_REVENUE`)} `
  })

  const handleClear = () => setPatientId(0)

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setTransactionLimitPage(limit)

  const handleChangePage = (val: number) => setTransactionPage(val)

  useEffect(() => {
    fetchTransaction()
  }, [fetchTransaction])

  useEffect(() => {
    fetchTransactionExport()
  }, [fetchTransactionExport])

  return (
    <div>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.TRANSACTION_REVENUE`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <SearchPatients
                  status="ACTIVE"
                  callbackPatientId={setPatientId}
                  patientId={patientId}
                  onClear={handleClear}
                  callbackPatientName={(value) => {
                    setPatientFilter(value)
                  }}
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setTransactionPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                    setTransactionPage(1)
                  }}
                  label={''}
                  allowClear
                  onClear={() => setRangeDate(emptyRangePicker)}
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBillStatus(e.target.value)
                    setTransactionPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.RECEIPT_STATUS')}: ${billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)} `}
                  label={''}
                  selectId="select-bill-status"
                  labelId="label-bill-status"
                  value={billStatus}
                  options={[
                    <MenuItem key="1" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    <MenuItem key="2" value="NORMAL">
                      {t('REPORT.FILTER.BILL.NORMAL')}
                    </MenuItem>,
                    <MenuItem key="3" value="CANCEL">
                      {t('REPORT.FILTER.BILL.CANCEL')}
                    </MenuItem>
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>
            </Row>

            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setPrefix(e.target.value)
                    setTransactionPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix} `}
                  label={''}
                  selectId="select-prefix"
                  labelId="label-prefix"
                  value={prefix}
                  options={[
                    <MenuItem key="0" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    _.map(receiptPrefix, (data: BillInterface) => {
                      return (
                        <MenuItem key={data.billPrefixId} value={data.prefix}>
                          {data.prefix}
                        </MenuItem>
                      )
                    })
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <UseStyled.HeightAutocompleteSelect>
                  <AutocompleteSelect
                    key={'operative'}
                    labelId="select-operative-type"
                    inputLabel={t('REPORT.FILTER.OPERATIVE_TYPE')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={[{ productTypeName: t('REPORT.REPORT_GROUP.ALL'), productTypeId: 0 }, ...productTypes, ...operativeTypes]}
                    getOptionLabel={(option: any) => option.productTypeName || option.operativeTypeName || ''}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.productTypeName || option.operativeTypeName}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      const id = value?.productTypeId || value?.operativeTypeId || ''
                      const gr = value ? value?.group || 'OPERATIVE' : ''
                      const name = value?.productTypeName || value?.operativeTypeName || ''
                      setOperativeTypeId(id)
                      setGroup(value?.group || '')
                      setOperativeId(0)
                      setTypeSelected({ productTypeName: name, productTypeId: id || 0, group: gr })
                      loadItemCode(``, id, gr)
                    }}
                    inputProps={{
                      onChange: (e: any) => {
                        if (productTypes.length === 50 || operativeTypes.length === 50) {
                          setOperativeTypeId(0)
                          setGroup('')
                          setOperativeId(0)
                          if (productTypes.length === 50) {
                            setProductTypes([])
                            loadProductType(e.target.value)
                          }
                          if (operativeTypes.length === 50) {
                            setOperativeTypes([])
                            loadOperativeType(e.target.value)
                          }
                        }
                      }
                    }}
                    onOpen={(e: any) => {
                      setOperativeTypeId(0)
                      setGroup('')
                      setOperativeId(0)
                      setTypeSelected({ productTypeName: '', productTypeId: 0, group: '' })
                      setCodeSelected({ productCode: ``, productId: 0, group: `` })
                      loadProductType()
                      loadOperativeType()
                    }}
                    value={typeSelected}
                    disableClearable
                  />
                </UseStyled.HeightAutocompleteSelect>
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <UseStyled.HeightAutocompleteSelect>
                  <AutocompleteSelect
                    key={'operative-code'}
                    labelId="operative-code"
                    inputLabel={t('REPORT.FILTER.OPERATIVE_CODE')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={[{ productCode: t('REPORT.REPORT_GROUP.ALL'), productId: 0, group: '' }, ...itemCode]}
                    getOptionLabel={(option) => option.productCode}
                    renderOption={(props, option) => (
                      <Box key={option} component="li" {...props}>
                        {option.productCode}
                      </Box>
                    )}
                    onchange={(e, value) => {
                      if (value) {
                        setOperativeId(value.productId || 0)
                        setGroupCode(value.group !== 'OPERATIVE' ? value.group : '')
                        setCodeSelected(value)
                      } else {
                        setOperativeId(0)
                        setGroupCode('')
                      }
                    }}
                    inputProps={{
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setOperativeId(0)
                        setGroupCode('')
                        setItemCode([])

                        loadItemCode(e.target.value)
                      }
                    }}
                    onOpen={(e) => {
                      setOperativeId(0)
                      setGroupCode('')
                      setCodeSelected({ productCode: '', productId: 0, group: '' })
                      loadItemCode()
                    }}
                    value={codeSelected}
                    disableClearable
                  />
                </UseStyled.HeightAutocompleteSelect>
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                  <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                    <AutocompleteSelect
                      open={openFilterDoctors}
                      multiple
                      labelId="label-doctors"
                      options={['ALL', ...doctors, 'CLEAR']}
                      getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                      renderOption={(props, option) => {
                        if (option === 'ALL') {
                          return (
                            <ListFilterDoctors {...props} key={option} component="li" value={option}>
                              <InputCheckbox
                                label={t('REPORT.REPORT_GROUP.ALL')}
                                checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                                indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                                className={'pe-none'}
                              />
                            </ListFilterDoctors>
                          )
                        } else if (option === 'CLEAR') {
                          return (
                            <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                              <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                            </Box>
                          )
                        } else {
                          return (
                            <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                              <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <>
                            <span className="pr-1">{t('USER_DOCTOR')}:</span>
                            {!openFilterDoctors &&
                              ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                                <Box className="text-ellipsis">
                                  {_.map(tagValue, (option: any, index: number) => {
                                    return (
                                      <span>
                                        {_.find(doctors, { userId: option })?.fullname || ''}
                                        {(index !== _.findLastIndex(tagValue) && option && `,`) || ''}
                                      </span>
                                    )
                                  })}
                                </Box>
                              ))}
                          </>
                        )
                      }}
                      onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                      noOptionsText={t('NOT_FOUND')}
                      filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                      value={selectDoctors}
                      height={32}
                      disableClearable
                    />
                  </CustomFilterDoctor>
                </ClickAwayListener>
              </Col>
              {/* <Col md={1} xl={1} className={'pt-0'}>
                <ButtonCustom
                  onClick={() => {
                    setFetchData(true)
                    setTransactionPage(1)
                    setLoading(true)
                  }}
                  textButton={t('INPUT.SEARCH')}
                  style={{ height: '30px', marginTop: '5px' }}
                />
              </Col> */}
            </Row>

            <Row className="mx-0">
              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      docTitle={t(`REPORT.REPORT_NAME.SCHEDULES`)}
                      removeIfram
                      style={`@page { size: 1122.519685px 793.7007874px; margin: 0.5cm; } @media print { body { -webkit - print - color - adjust: exact; } `}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <TransactionRevenueList
              tableFilter={getAllFilter()}
              page={transactionPage}
              pageLimit={transactionLimitPage}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={transactionData}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              componentRef={componentRef}
              exportData={transactionDataExport}
              billStatus={billStatus}
              calculateAdvance={_.get(_.find(branches, { branchId: branchId }), 'calculateAdvance') === 'DECREASE'}
            />
          </Box>
        </Card.Body>
      </Card>
      <Loading show={loading} type="fullLoading" />
    </div>
  )
}
