import { Tabs, IconButton, Box } from '@mui/material';

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

export const CustomTabs = styled(Tabs)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  '.MuiButtonBase-root': {
    minWidth: '100%',
    minHeight: 'unset',
    width: 56,
    height: 42,
    borderRadius: '0 6px 6px 0',
    border: `1px solid ${disabled ? colors.disabledGray : colors.themeSecondColor}`,
    color: disabled ? colors.disabledGray : colors.themeMainColor,
    padding: 0,
    fontSize: 16,
    fontWeight: 500,
    pointerEvents: disabled ? 'none' : 'auto',
    '&.Mui-selected': {
      backgroundColor: disabled ? colors.disabledGray : colors.themeSecondColor,
      color: colors.white
    }
  },
  '.MuiTabs-indicator': {
    display: 'none'
  }
}))

export const IconBookmark = styled(IconButton)(({ theme }) => ({
  color: colors.themeSecondColor
}))
export const IconAddBox = styled(IconButton)(({ theme }) => ({
  color: colors.themeSecondColor
}))

export const IconAddBoxDim = styled(IconButton)(({ theme }) => ({
  color: colors.lightGray
}))

export const DxStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up(1600)]: {
    display: 'flex'
  }
}))
