import { menuThirds } from 'constants/menus'
import LabCompany from 'features/setting/default-labs/lab-company/lab-company'
import Labs from 'features/setting/default-labs/labs/labs'
import LabType from 'features/setting/default-labs/lab-type/lab-types'
import LabGroup from 'features/setting/default-labs/lab-group/lab-groups'
import FormLabGroup from 'features/setting/default-labs/lab-group/form-lab-group'
import FormLabType from 'features/setting/default-labs/lab-type/form-lab-type'

const routeDefaultLabs = [
  {
    ...menuThirds.LAB_COMPANY,
    component: LabCompany,
    appBar: true
  },
  {
    ...menuThirds.LAB_LIST,
    component: Labs,
    appBar: true
  },
  {
    ...menuThirds.LAB_TYPE,
    component: LabType,
    appBar: true
  },
  {
    ...menuThirds.LAB_TYPE.name,
    key: menuThirds.LAB_TYPE.key,
    packageKey: menuThirds.LAB_TYPE.packageKey,
    textname: `เพิ่ม ${menuThirds.LAB_TYPE.name}`,
    active: false,
    path: menuThirds.LAB_TYPE.path + '/create',
    component: FormLabType,
    appBar: true
  },
  {
    ...menuThirds.LAB_TYPE.name,
    key: menuThirds.LAB_TYPE.key,
    packageKey: menuThirds.LAB_TYPE.packageKey,
    textname: `แก้ไข ${menuThirds.LAB_TYPE.name}`,
    active: false,
    path: menuThirds.LAB_TYPE.path + '/update',
    component: FormLabType,
    appBar: true
  },

  {
    ...menuThirds.LAB_GROUP,
    component: LabGroup,
    appBar: true
  },
  {
    ...menuThirds.LAB_GROUP.name,
    key: menuThirds.LAB_GROUP.key,
    packageKey: menuThirds.LAB_GROUP.packageKey,
    textname: `เพิ่ม ${menuThirds.LAB_GROUP.name}`,
    active: false,
    path: menuThirds.LAB_GROUP.path + '/create',
    component: FormLabGroup,
    appBar: true
  },
  {
    ...menuThirds.LAB_GROUP.name,
    key: menuThirds.LAB_GROUP.key,
    packageKey: menuThirds.LAB_GROUP.packageKey,
    textname: `แก้ไข ${menuThirds.LAB_GROUP.name}`,
    active: false,
    path: menuThirds.LAB_GROUP.path + '/update',
    component: FormLabGroup,
    appBar: true
  }
]

export default routeDefaultLabs
