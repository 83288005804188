import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function Dentist(props: IconProps) {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <path data-name="Rectangle 3862" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
      <path
        d="M255.113 164.178a14.272 14.272 0 0 0 2.487-8.287c-.075-8.819-8.33-9.232-10.687-6.677-.4.435-1.116.612-1.455.126-1.667-2.393-10.537-1.914-10.463 6.744a14.264 14.264 0 0 0 2.629 8.24.942.942 0 0 1 .146.777c-.592 2.406-1.785 6.03 1.637 6 3.661-.031 3.121-4.1 6.983-4.129 3.587-.031 3.777 3.922 6.819 3.9 2.854-.024 2.292-3.58 1.753-5.937a.944.944 0 0 1 .151-.757z"
        transform="translate(-230.496 -143.35)"
        style={{ fill: '#fff', stroke: props.color || colors.themeSecondColor, strokeMiterlimit: 10, strokeWidth: '2px' }}
      />
    </svg>
  )
}
