import { useState, useEffect, useCallback } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import { Box, Divider } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'
import HeaderLabel from 'component/CardCustom/HeaderLabel'

/** STYLE */
import useStyles, { ButtonAddBranch } from 'features/setting/personnel-managements/roles/style'
import { colors } from 'constants/theme'

/** API */
import { routeName } from 'routes/routes-name'
import RolesApi, { RolePermissionsInterface, RoleInterface } from 'api/setting/personnel-managements/role.api'

/** UTILS || SLICE */
import { checkPackage, getApp } from 'utils/app.utils'
import { PACKAGE_ADDONS } from 'constants/package'

/** COMPONENT */
import { swalWarning } from 'component/Alert/Swal'
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import Loading from 'component/Loading'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { ButtonDeleteSimple } from 'component/Button/ButtonDelete'
import InputCheckbox from 'component/Input/InputCheckbox'
import AboutUs from 'api/admin/clinic-manage/about.api'

const initStateErrorMessage = {
  ROLE_NAME: '',
  IS_DUPLICATE_ROLE_NAME: '',
  ROLE_NAME_STRING_BASE: '',
  ROLE_NAME_STRING_EMPTY: '',
  ROLE_NAME_MAXIMUM_LENGTH: '',
  ROLE_NAME_ANY_REQUIRED: '',

  ROLE_NAME_EN: '',
  IS_DUPLICATE_ROLE_NAME_EN: '',
  ROLE_NAME_EN_STRING_BASE: '',
  ROLE_NAME_EN_STRING_EMPTY: '',
  ROLE_NAME_EN_MAXIMUM_LENGTH: '',
  ROLE_NAME_EN_ANY_REQUIRED: ''
}

const initRole = {
  branchId: 0,
  branchName: '',
  servicePointSelected: [],
  servicePoints: [],
  servicePointMenus: []
}

interface PermissionInterface {
  branchId?: number
  servicePointId?: number
  menuFirstId?: number
  menuSecondId?: number
  menuThirdId?: number
  name?: string
  view: boolean
  add: boolean
  edit: boolean
  del: boolean
  exp: boolean
}
interface ServicePointMenusInterface {
  servicePointId?: number
  menuTag: string
  permission: PermissionInterface[]
  menuSecondKey?: string
}

interface RoleRenderInterface {
  branchId: number
  branchName: string
  servicePointSelected: any[]
  servicePoints: any[]
  servicePointMenus: ServicePointMenusInterface[]
}
interface RolePermissionsRenderInterface {
  branchId: number
  branchName: string
  servicePointMenus: ServicePointMenusInterface[]
}

export default function RoleForm() {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location: any = useLocation()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [initLocation, setInitLocation] = useState()

  /** DATA */
  const [branches, setBranches] = useState<any[]>([])

  /** FIELD */
  const [roleId, setRoleId] = useState<number | null>(null)
  const [roleName, setRoleName] = useState('')
  const [roleNameEn, setRoleNameEn] = useState('')
  const [roles, setRoles] = useState<RoleRenderInterface[]>([initRole])
  const [display, setDisplay]: any = useState({})
  const menuHidden = ['SMART_CARD', 'ADJUSTMENT_PRODUCT', 'CLINIC_MANAGEMENT']
  const [branchApp, setBranchApp] = useState<any>([])

  const loadDataBranch = useCallback(() => {
    RolesApi.findAllBranch().then(({ data }) => setBranches(data))
  }, [])

  const loadDataMenu = (iRole: number, servicePointId = '') => {
    RolesApi.findAllServicePointMenu({ servicePointId: servicePointId }).then(({ data }: any) => {
      const sp = data[0]
      const newServicePointMenu: ServicePointMenusInterface = {
        servicePointId: sp.servicePointId,
        menuTag: sp.servicePointName,
        permission: _.map(_.sortBy(sp?.servicePointMenus, ['menuFirstSorting', 'menuFirstKey']), (spm) => {
          return {
            branchId: roles[iRole]?.branchId,
            servicePointId: sp.servicePointId,
            menuFirstId: spm?.menuFirstId || 0,
            menuSecondId: spm?.menuSecondId || 0,
            menuThirdId: spm?.menuThirdId || 0,
            name: spm?.menuThirdKey || spm?.menuSecondKey || spm?.menuFirstKey || '',
            view: false,
            add: false,
            edit: false,
            del: false,
            exp: false
          }
        })
      }

      const pRoles: any = _.merge([], roles)
      pRoles[iRole].servicePointMenus = [...pRoles[iRole].servicePointMenus, newServicePointMenu]
      setRoles(pRoles)
    })
  }

  const discardServicePoint = (servicePoint: { value: number, label: string }[], iRole: number) => {
    let newServicePointMenu: any = []
    _.forEach(servicePoint, (sp) => {
      newServicePointMenu = [...newServicePointMenu, _.find(roles[iRole].servicePointMenus, { servicePointId: sp.value })]
    })
    const pRoles: any = _.merge([], roles)
    pRoles[iRole].servicePointMenus = newServicePointMenu
    setRoles(pRoles)
  }

  const handleCheckApp = (branchId: number) => {
    if (branchApp?.length) {
      return branchApp.some((item: any) => item.branchId === branchId && item?.isDefaultRegisApp === '1')
    }
    return true
  }

  const loadAboutUs = async () => {
    const res = await AboutUs.findAboutUs()
    if (res.status === 200 && res.data?.branchDefaultRegisterApps?.length) {
      setBranchApp(res.data.branchDefaultRegisterApps)
    }
  }

  useEffect(() => {
    loadAboutUs()
  }, [])

  useEffect(() => {
    setRoles([])
    loadDataBranch()
  }, [loadDataBranch])

  useEffect(() => {
    if (!initLocation) setInitLocation(location)
    if (branches.length) {
      const { state }: any = location
      if (state?.roleId) {
        onEdit()
      }
    }
  }, [branches, location])

  const onEdit = async () => {
    const { state }: any = location
    if (state?.roleId) {
      setRoleId(state.roleId)
      setLoading(true)
      if (state.roleId) {
        RolesApi.findById(state.roleId).then(({ data }) => {

          if (data?.roleId) setRoleId(data?.roleId)
          setRoleName(data?.roleName)
          setRoleNameEn(data?.roleNameEn)

          if (data?.roleBranches?.length) {
            const arrBranches: any = _.groupBy(data?.rolePermissions, 'branchId')

            const temp: any[] = _.map(arrBranches, (b: any, iRp: number) => {
              const findBranch: any = data?.roleBranches.find((branch: any) => branch.branchId === b[0]?.branchId)
              const arrServicePoints: any = _.groupBy(b, 'servicePointId')

              const findb = _.get(_.find(branches, (branch: any, i: any) => branch.branchId === b[0]?.branchId), 'branchServicePoints') || []
              const servicePoint = _.filter(data?.roleBranches, (branch: any, i: any) => branch.branchId === b[0]?.branchId).map((bsp: any) => ({ value: bsp.servicePointId, label: bsp?.servicePoints?.servicePointName || '' })) || []

              const servicePointSelected = _.filter(servicePoint, (element) => {
                return _.some(findb, (findbItem) => {
                  return findbItem.servicePointId === element.value;
                });
              });

              return {
                branchId: findBranch?.branches?.branchId || 0,
                branchName: findBranch?.branches?.branchName || '',
                servicePoints: findBranch?.branchServicePoints || [],
                servicePointSelected: servicePointSelected || _.filter(data?.roleBranches, (branch: any, i: any) => branch.branchId === b[0]?.branchId).map((bsp: any) => ({ value: bsp.servicePointId, label: bsp?.servicePoints?.servicePointName || '' })) || [],
                servicePointMenus: _.map(arrServicePoints, (s: any) => {
                  const findServicePoint: any = data?.roleBranches.find((sp: any) => sp.servicePointId === s[0].servicePointId)
                  return {
                    servicePointId: findServicePoint?.servicePoints?.servicePointId,
                    menuTag: findServicePoint?.servicePoints?.servicePointName || '',
                    permission: _.map(_.sortBy(s, ['menuFirstSorting', 'menuFirstKey']), (spm) => {
                      return {
                        branchId: findBranch.branchId,
                        servicePointId: findServicePoint?.servicePoints?.servicePointId,
                        menuFirstId: spm?.menuFirstId || 0,
                        menuSecondId: spm?.menuSecondId || 0,
                        menuSecondKey: spm?.menuSecondKey || '',
                        menuThirdId: spm?.menuThirdId || 0,
                        name: spm?.menuThirdKey || spm?.menuSecondKey || spm?.menuFirstKey || '',
                        view: spm?.isView === 1 ? true : false,
                        add: spm?.isCreate === 1 ? true : false,
                        edit: spm?.isUpdate === 1 ? true : false,
                        del: spm?.isDelete === 1 ? true : false,
                        exp: spm?.isExport === 1 ? true : false
                      }
                    })
                  }
                })
              }
            })

            for (let iRole = 0; iRole < temp.length; iRole++) {
              const element = temp[iRole];
              let servicePointId: any = _.filter(data?.roleBranches, (branch: any, i: any) => branch.branchId === element?.branchId).map((bsp: any) => { return bsp.servicePointId }).join(',')
              RolesApi.findAllServicePointMenu({ servicePointId: servicePointId }).then(({ data }) => {
                const servicePoints: any = data
                servicePointId = servicePointId.split(",");
                const servicePointsOption = _.get(_.find(branches, (branch: any, i: any) => branch.branchId === element?.branchId), 'branchServicePoints') || []

                const servicePointSelected = _.filter(servicePoints, (element) => {
                  return _.some(servicePointsOption, (findbItem) => {
                    return findbItem.servicePointId === element.servicePointId;
                  });
                });

                temp[iRole].servicePoints = servicePointsOption
                temp[iRole].servicePointMenus = _.map(servicePointSelected, (servicePoint: any) => {
                  const findServicePoint: any = element.servicePointMenus.find((sp: any) => Number(sp.servicePointId) === Number(servicePoint.servicePointId))
                  return {
                    servicePointId: servicePoint.servicePointId,
                    menuTag: servicePoint.servicePointName || '',
                    permission: _.map(_.sortBy(servicePoint?.servicePointMenus, ['menuFirstSorting', 'menuFirstKey']), (spm) => {
                      const findPermission = _.find(findServicePoint?.permission, (sp) => sp.servicePointId === spm.servicePointId && sp.menuFirstId === spm.menuFirstId && sp.menuSecondId === (spm?.menuSecondId || 0) && sp.menuThirdId === (spm?.menuThirdId || 0))
                      return {
                        branchId: temp[iRole].branchId,
                        servicePointId: servicePoint.servicePointId,
                        menuFirstId: spm?.menuFirstId || 0,
                        menuSecondId: spm?.menuSecondId || 0,
                        menuSecondKey: spm?.menuSecondKey || '',
                        menuThirdId: spm?.menuThirdId || 0,
                        name: spm?.menuThirdKey || spm?.menuSecondKey || spm?.menuFirstKey || '',
                        view: findPermission?.view ? true : false,
                        add: findPermission?.add ? true : false,
                        edit: findPermission?.edit ? true : false,
                        del: findPermission?.del ? true : false,
                        exp: findPermission?.exp ? true : false
                      }
                    })
                  }
                })
                setRoles([...temp])
              })
            }
          }
        }).finally(() => setLoading(false))
      }
    }

  }

  const getRolePermissions = () => {
    const rolePermissionsSave: RolePermissionsInterface[] = []
    for (const iRole in roles) {
      if (Object.prototype.hasOwnProperty.call(roles, iRole)) {
        const { branchId, servicePointMenus } = roles[iRole]
        for (const iServicePointMenu in servicePointMenus) {
          if (Object.prototype.hasOwnProperty.call(servicePointMenus, iServicePointMenu)) {
            const { servicePointId, permission } = servicePointMenus[iServicePointMenu]
            const permissions: PermissionInterface[] = permission.filter(({ view, add, edit, del, exp }: any) => view === true || add === true || edit === true || del === true || exp === true)
            for (const iPermission in permissions) {
              if (Object.prototype.hasOwnProperty.call(permissions, iPermission)) {
                const pms: any = permissions[iPermission]
                rolePermissionsSave.push({
                  branchId: branchId,
                  servicePointId: Number(servicePointId),
                  menuFirstId: pms.menuFirstId,
                  menuSecondId: pms.menuSecondId,
                  menuThirdId: pms.menuThirdId,
                  isView: pms.view ? 1 : 0,
                  isCreate: pms.add ? 1 : 0,
                  isUpdate: pms.edit ? 1 : 0,
                  isDelete: pms.del ? 1 : 0,
                  isExport: pms.exp ? 1 : 0
                })
              }
            }
          }
        }
      }
    }

    return rolePermissionsSave
  }

  const submit = async () => {
    if (!roleName) return setErrorMessage({ ...errorMessage, ...{ ROLE_NAME: !roleName ? t('ROLE.MESSAGE.ROLE_NAME') : '' } })
    if (_.some(roles, (r) => _.some(r.servicePointMenus, (rs) => _.every(rs.permission, { view: false, add: false, edit: false, del: false, exp: false })))) return notiError(t('POSITION.MESSAGE.PERSONNEL_NOTI'))
    const permissionsSave = getRolePermissions()
    if (_.isEmpty(permissionsSave)) return notiError(t('ROLE.MESSAGE.AT_LEAST_ONE_PERMISSION_REQUIRED'))
    const roleSave: RoleInterface = {
      roleName: roleName,
      roleNameEn: roleNameEn,
      rolePermissions: permissionsSave
    }

    if (!roleId) create(roleSave)
    else {
      roleSave.status = 'ACTIVE'
      update(roleSave)
    }
  }

  const create = (roleSave: RoleInterface) => {
    setLoading(true)
    RolesApi.create(roleSave)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t(`ROLE.MESSAGE.SUCCESS.CREATE`), '', null, () => {

            return
          })
          history.push(routeName.roles)
        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`ROLE.MESSAGE.${err.message}`) } })
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`ROLE.MESSAGE.${err.message}`) } })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = (roleSave: RoleInterface) => {
    swalWarning(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('ROLE.MESSAGE.POPUP_UPDATE.TITLE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 370px">${t('ROLE.MESSAGE.POPUP_UPDATE.SUBTITLE')}</p>`,
      () => {
        setLoading(true)
        RolesApi.update(Number(roleId), roleSave)
          .then((res: any) => {
            if (res.status !== undefined && res.status === 200) {
              notiSuccess(t(`ROLE.MESSAGE.SUCCESS.UPDATE`), '', null, () => {
                return
              })
              history.push(routeName.roles)
            }
            else {
              const err = res.response?.data
              setErrorMessage({ ...errorMessage, ...{ [err?.message]: t(`ROLE.MESSAGE.${err?.message}`) } })
            }
          })
          .catch((e) => {
            const err = e.response?.data
            setErrorMessage({ ...errorMessage, ...{ [err?.message]: t(`ROLE.MESSAGE.${err?.message}`) } })
          })
          .finally(() => {
            setLoading(false)
          })
      }, { allowOutsideClick: false, showCancelButton: true })
  }

  const selectAllPermission = (iServicePoint: any, key: any, value: any, iRole: any) => {
    const role = roles[iRole]
    roles[iRole].servicePointMenus = role.servicePointMenus.map((menu: any, index_service_select: any) => {
      if (index_service_select === iServicePoint)
        return {
          ...menu,
          permission: menu.permission.map((per: any) => { return { ...per, [key]: value } })
        }
      else return menu
    })
    setRoles([...roles])
  }

  const selectPermission = (iRole: any, iServicePoint: any, name: any, key: any, value: any, iPermission: number) => {
    const role: any = roles[iRole]
    role.servicePointMenus[iServicePoint].permission[iPermission][key] = value
    roles[iRole] = role
    setRoles([...roles])
  }

  const handleChangeServicePoint = (role: RoleRenderInterface, iRole: number, reason: string) => {
    const serviceSelect = role?.servicePointSelected
    if (reason === 'selectOption') loadDataMenu(iRole, _.last(serviceSelect)?.value)
    else discardServicePoint(serviceSelect, iRole)
  }

  return (
    <div className={`${classes.root} container-card`}>
      <Loading show={loading} type="fullLoading" />
      <HeaderLabel
        text={roleId ? t('ROLE.TITLE_UPDATE') : t('ROLE.TITLE_CREATE')}
        navigator={{ previousTo: routeName.roles, previousTitle: t('ROLE.TITLE'), currentTitle: roleId ? t('ROLE.TITLE_UPDATE') : t('ROLE.TITLE_CREATE') }}
        goBack={routeName.roles}
        onSubmit={submit}
      />
      <Card className="border shadow-sm mx-4">
        <Card.Body className="h-100">
          <Row className="mx-0">
            <Col sm={4} className="mt-3 px-1">
              <InputTextField
                required
                label={t('ROLE.TABLE.CELL.ROLE_NAME')}
                value={roleName}
                onchange={(e) => {
                  setRoleName(e.target.value)
                  setErrorMessage(initStateErrorMessage)
                }}
                helperText={errorMessage.ROLE_NAME || errorMessage.IS_DUPLICATE_ROLE_NAME || errorMessage.ROLE_NAME_STRING_BASE || errorMessage.ROLE_NAME_STRING_EMPTY || errorMessage.ROLE_NAME_MAXIMUM_LENGTH || errorMessage.ROLE_NAME_ANY_REQUIRED}
              />
            </Col>
            <Col sm={4} className="mt-3 px-1">
              <InputTextField
                label={t('ROLE.TABLE.CELL.ROLE_NAME_EN')}
                value={roleNameEn}
                helperText={errorMessage.ROLE_NAME_EN || errorMessage.IS_DUPLICATE_ROLE_NAME_EN || errorMessage.ROLE_NAME_EN_STRING_BASE || errorMessage.ROLE_NAME_EN_STRING_EMPTY || errorMessage.ROLE_NAME_EN_MAXIMUM_LENGTH || errorMessage.ROLE_NAME_EN_ANY_REQUIRED}
                onchange={(e) => {
                  setRoleNameEn(e.target.value)
                  setErrorMessage(initStateErrorMessage)
                }}
              />
            </Col>
          </Row>
          {_.map(roles, (role: RoleRenderInterface, iRole: number) => (
            <Row key={iRole} className="mx-0 px-0">
              <Col sm={4} className="px-1 mt-3">
                <BasicSelect
                  onchange={(event) => {
                    const value = event.target.value
                    if (value) {
                      const { branchId: id, branchName: name, branchServicePoints }: any = branches.find((branch: any) => branch.branchId === value)
                      role.branchId = id
                      role.branchName = name
                      role.servicePoints = branchServicePoints
                      role.servicePointSelected = []
                      role.servicePointMenus = []
                      setRoles((values: any) => ([...roles]))
                    }
                  }}
                  label={t('ROLE.BRANCH')}
                  selectId="branch"
                  value={role.branchId}
                  labelId="branch"
                  options={branches.map(({ branchId, branchName }: RolePermissionsRenderInterface) => (
                    <MenuItem
                      key={branchId}
                      value={branchId}
                      sx={{
                        '&.MuiMenuItem-root': {
                          pointerEvents: _.some(roles, { branchId: branchId }) ? 'none' : 'auto',
                          opacity: _.some(roles, { branchId: branchId }) ? .5 : 1
                        }
                      }}>
                      {branchName}
                    </MenuItem>
                  ))}
                />
              </Col>
              <Col className="col px-1 mt-3">
                {branches && (
                  <AutocompleteSelect
                    disableClearable
                    multiple
                    labelId="servicepoint-id"
                    inputLabel={t('ROLE.SERVICE_POINT')}
                    noOptionsText={t('ROLE.NO_SERVICE_POINTS_FOUND')}
                    options={role?.servicePoints.map((bsp: any) => ({ value: bsp.servicePointId, label: bsp.servicePointName || '' })) || []}
                    getOptionLabel={(option: any) => option.label}
                    value={role.servicePointSelected}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li"  {...props}
                        sx={{
                          pointerEvents: _.some(role.servicePointSelected, { value: option?.value }) ? 'none !important' : 'auto !important',
                          opacity: _.some(role.servicePointSelected, { value: option?.value }) ? .5 : 1
                        }} > {option.label}
                      </Box>
                    )}
                    onchange={(e, value: any[], reason: string) => {
                      role.servicePointSelected = value
                      setRoles((values: any) => ([...roles]))
                      handleChangeServicePoint(role, iRole, reason)
                    }}
                  />
                )}
              </Col>
              <Box
                className={`col-auto px-1 mt-3 d-xl-flex align-items-center ${_.size(roles) <= 1 ? 'pe-none' : ''}`}
                sx={{
                  filter: _.size(roles) <= 1 ? 'grayscale(1)' : 'none',
                  opacity: _.size(roles) <= 1 ? .6 : 1
                }}>
                <ButtonDeleteSimple disabled={_.size(roles) <= 1} onClick={() => setRoles(roles.filter((object: any, select_Index: any) => iRole !== select_Index))} />
              </Box>
            </Row>
          ))}
          <Row className="mx-0 mt-2">
            <ButtonAddBranch
              disabled={roles.length === branches.length || _.some(roles, (role) => !role.branchId)}
              className={`w-auto`}
              onClick={() => setRoles([...roles, { ...initRole, branchId: 0 }])}>
              {`+ ${t('ROLE.ADD_BRANCH')}`}
            </ButtonAddBranch>
          </Row>
          <Divider className='mt-3 mx-0' style={{ border: 'none', backgroundColor: colors.lightGray, opacity: 1, margin: '.25rem .5rem 0 !important' }} />
          {_.map(roles, (role: RoleRenderInterface, iRole: number) => (
            <div key={iRole}>
              <Row className={`mt-3 p-1`} style={{ fontWeight: 600, fontSize: '18px' }}>
                <Col sm={12}>{role.branchName}</Col>
              </Row>
              <Row>
                {_.map(role.servicePointMenus, ({ menuTag, permission }: any, iServicePoint: any) => {
                  const view_all = permission.filter(({ view }: any) => view === true)
                  const add_all = permission.filter(({ add }: any) => add === true)
                  const edit_all = permission.filter(({ edit }: any) => edit === true)
                  const del_all = permission.filter(({ del }: any) => del === true)
                  const exp_all = permission.filter(({ exp }: any) => exp === true)
                  return (
                    <div key={iServicePoint}>
                      <Col
                        sm={12}
                        className={`mt-3`}
                        onClick={(e) => {
                          if (display[`{${iRole}_${iServicePoint}}`]) {
                            setDisplay((pre: any) => ({ ...pre, [`{${iRole}_${iServicePoint}}`]: !display[`{${iRole}_${iServicePoint}}`] }))
                          } else {
                            setDisplay((pre: any) => ({ ...pre, [`{${iRole}_${iServicePoint}}`]: true }))
                          }
                        }}
                      >
                        <Box
                          className='d-flex justify-content-between align-items-center py-2 px-3 cursor-pointer'
                          sx={{ margin: 0, fontSize: 18, fontWeight: 600, backgroundColor: `${colors.themeSecondColor10} !important`, borderRadius: '4px' }}>
                          <div>{menuTag}</div>
                          <Box className={`d-flex ${classes.arrow}`} sx={{ backgroundColor: `${colors.white} !important`, transform: display[`{${iRole}_${iServicePoint}}`] ? 'rotate(180deg)' : '' }}>
                            <KeyboardArrowDownIcon style={{ width: '100%' }} />
                          </Box>
                        </Box>
                      </Col>
                      <div id={iServicePoint} style={{ display: display[`{${iRole}_${iServicePoint}}`] ? '' : 'none' }}>
                        <Row className=" mt-2 pl-3 pt-1 pb-1">
                          <Col xs={12} sm={2} className="d-flex align-items-center">
                            <div className={``} style={{ fontWeight: 600, color: colors.themeMainColor }}>
                              {t('ROLE.PERMISSION_IN_SERVICE_POINT')}
                            </div>
                          </Col>
                          <Col xs={2} sm={2}>
                            <InputCheckbox
                              label={t('ROLE.LIST_VIEW')}
                              onChange={(e) => selectAllPermission(iServicePoint, 'view', e.target.checked, iRole)}
                              checked={view_all.length === permission.length ? true : false}
                            />
                          </Col>
                          <Col xs={2} sm={2}>
                            <InputCheckbox
                              label={t('ROLE.LIST_ADD')}
                              onChange={(e) => selectAllPermission(iServicePoint, 'add', e.target.checked, iRole)}
                              checked={add_all.length === permission.length ? true : false}
                            />
                          </Col>
                          <Col xs={2} sm={2}>
                            <InputCheckbox
                              label={t('ROLE.LIST_EDIT')}
                              onChange={(e) => selectAllPermission(iServicePoint, 'edit', e.target.checked, iRole)}
                              checked={edit_all.length === permission.length ? true : false}
                            />
                          </Col>
                          <Col xs={2} sm={2}>
                            <InputCheckbox
                              label={t('ROLE.LIST_DELETE')}
                              onChange={(e) => selectAllPermission(iServicePoint, 'del', e.target.checked, iRole)}
                              checked={del_all.length === permission.length ? true : false}
                            />
                          </Col>
                          <Col xs={2} sm={2}>
                            <InputCheckbox
                              label={t('ROLE.LIST_EXPORT')}
                              onChange={(e) => selectAllPermission(iServicePoint, 'exp', e.target.checked, iRole)}
                              checked={exp_all.length === permission.length ? true : false}
                            />
                          </Col>
                        </Row>
                        {/* name !== 'CLINIC_MANAGEMENT'&& */}
                        {_.map(permission, ({ name, view, add, edit, del, exp, menuFirstId, menuSecondId, menuThirdId, menuSecondKey, branchId }: any, iPermission: any) => {
                          const isCheckApp = menuSecondKey === "APPLICATION" ? handleCheckApp(branchId) : true
                          if (!menuHidden.includes(name) && checkPackage(_.includes(PACKAGE_ADDONS, name) ? name : '') && isCheckApp)
                            // || name === 'MATERIAL'
                            return name === 'TREATMENT' ? null : (
                              <Row key={iPermission} className="pl-3 py-1">
                                {menuFirstId && !menuSecondId && !menuThirdId && iPermission !== 0 && (
                                  <Col className="col-12 w-100">
                                    <Divider className='mx-0 mb-2' style={{ border: 'none', backgroundColor: colors.lightGray, opacity: 1, margin: '.25rem .5rem 0 !important' }} />
                                  </Col>
                                )}
                                <Col xs={6} sm={2} className={`d-flex align-items-center`}>
                                  <div className={`${menuFirstId && !menuSecondId && !menuThirdId ? '' : 'pl-'}`} style={{ fontWeight: menuFirstId && !menuSecondId && !menuThirdId ? 500 : 400 }}>
                                    {t(`MENU.${name}`)}
                                  </div>
                                </Col>
                                <Col xs={1} sm={2} className="pl-sm-4">
                                  <InputCheckbox checked={view} onChange={(e) => selectPermission(iRole, iServicePoint, name, 'view', e.target.checked, iPermission)} />
                                </Col>
                                <Col xs={1} sm={2} className="pl-sm-4">
                                  <InputCheckbox checked={add} onChange={(e) => selectPermission(iRole, iServicePoint, name, 'add', e.target.checked, iPermission)} />
                                </Col>
                                <Col xs={1} sm={2} className="pl-sm-4">
                                  <InputCheckbox checked={edit} onChange={(e) => selectPermission(iRole, iServicePoint, name, 'edit', e.target.checked, iPermission)} />
                                </Col>
                                <Col xs={1} sm={2} className="pl-sm-4">
                                  <InputCheckbox checked={del} onChange={(e) => selectPermission(iRole, iServicePoint, name, 'del', e.target.checked, iPermission)} />
                                </Col>
                                <Col xs={1} sm={2} className="pl-sm-4">
                                  <InputCheckbox checked={exp} onChange={(e) => selectPermission(iRole, iServicePoint, name, 'exp', e.target.checked, iPermission)} />
                                </Col>
                              </Row>
                            )
                        })}
                      </div>
                    </div>
                  )
                })}
              </Row>
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  )
}
