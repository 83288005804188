import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface CreditFeeOperatives {
  operativeId: number
  creditAmount: number
  creditUnit: "PERCENT" | "BAHT"
}
export interface CreditFeeOperativeTypes {
  operativeTypeId: number
  creditAmount: number
  creditFeeOperatives: CreditFeeOperatives[]
}
export interface CreditFeeUsers {
  doctorId: number
}
export interface CreditFee {
  creditFeeName: string
  templateCreditFeeId: number
  dateStart: string
  status?: string
  amount: number
  creditFeeUsers: CreditFeeUsers[]
  creditFeeOperativeTypes: CreditFeeOperativeTypes[]
  templateClinicFeeAmount?: number
  creditFee?: number
}

export default class CreditFeeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('credit-fees', { params: { ...props } }).then((res) => res)
  }

  static create(playload: CreditFee) {
    return this.api.post('credit-fees', playload)
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`credit-fees/${id}`)
  }

  static update(id: number, payload: any) {
    return this.api.patch(`credit-fees/${id}`, payload)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`credit-fees/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`credit-fees/${id}`)
  }

  static findAllWithOperatives(id?: number): Promise<any> {
    return this.api.get('credit-fees/operatives', { params: { creditFeeId: id } }).then((res) => res)
  }

  static findAllDoctors(): Promise<any> {
    return this.api.get(`credit-fees/all-doctor`)
  }

  static doctorFeHistory(doctorId: number): Promise<any> {
    return this.api.get(`credit-fees/history/${doctorId}`)
  }

}
