import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface TemplateFormsProps {
    name?: string,
    doctorNoteTypeId?: number,
    fileId?: number | null
    base64?: string
    fileExtension?: string
    fileSize?: string
    servicePointId?: any
    status?: string
    
}
export interface IFTemplateFormsProps {
    templateFormsId: number
    templateFormTypeId: number | null
    name: string
    status: string
    image: {
        fileId: number
        filePath: string
    }
    templateFormType: {
        templateFormTypeId: 0
        typeName: string
        typeNameEn: string
        status: string
    }
    updatedBy: string
    updatedAt: string
    craetedBy: string
    craetedAt: string
}

export default class TemplateFormsApi extends BaseAPI {

    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get('template-forms', { params: { ...props } }).then((res) => res)
    }

    static create(payload: TemplateFormsProps) {
        return this.api.post('template-forms', payload)
    }

    static update(id: number, body: any) {
        return this.api.patch(`template-forms/${id}`, body)
    }

    static remove(id: number) {
        return this.api.delete(`template-forms/${id}`)
    }
    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`template-forms/${id}/status`, { id, status })
    }
}
