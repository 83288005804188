import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/default-products/product-instructions/style'

/** API */
import ProductInstructionsApi from 'api/setting/default-products/product-instruction.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import { Box } from '@mui/material'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
}

const initStateErrorMessage = {
    PRODUCT_INSTRUCTION_NAME: ``,
    IS_DUPLICATE_PRODUCT_INSTRUCTION_NAME: ``,
    PRODUCT_INSTRUCTION_NAME_STRING_BASE: ``,
    PRODUCT_INSTRUCTION_NAME_STRING_EMPTY: ``,
    PRODUCT_INSTRUCTION_NAME_MAXIMUM_LENGTH: ``,
    PRODUCT_INSTRUCTION_NAME_ANY_REQUIRED: ``,

    PRODUCT_INSTRUCTION_NAME_EN: ``,
    IS_DUPLICATE_PRODUCT_INSTRUCTION_NAME_EN: ``,
    PRODUCT_INSTRUCTION_NAME_EN_STRING_BASE: ``,
    PRODUCT_INSTRUCTION_NAME_EN_STRING_EMPTY: ``,
    PRODUCT_INSTRUCTION_NAME_EN_MAXIMUM_LENGTH: ``,
    PRODUCT_INSTRUCTION_NAME_EN_ANY_REQUIRED: ``
}
export default function MaterialInstructions() {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [productInstructions, setProductInstructions] = useState([])
    const [productInstructionId, setProductInstructionId] = useState(Number)
    const [productInstructionName, setProductInstructionName] = useState('')
    const [productInstructionNameEn, setProductInstructionNameEn] = useState('')
    const [productInstructionStatus, setProductInstructionStatus] = useState('')
    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
    const [textLength, setTextLength] = useState<number>(0)
    const [textLengthEn, setTextLengthEn] = useState<number>(0)
    const [errorName, setErrorName] = useState<any>()
    const [errorMaxLength, setErrorMaxLength] = useState<boolean>(false)


    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('createAt')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('ALL')

    const loadData = useCallback(async () => {
        let condition: any = { group: 'MATERIAL' }
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

        const res = await ProductInstructionsApi.findAll(condition)
        if (res.status === 200) {
            setRowCount(res.headers['x-total'])
            setProductInstructions(res.data)
        }
    }, [page, pageLimit, search, sortBy, sortType, status])

    useEffect(() => {
        loadData()
    }, [loadData])

    const submit = () => {
        if (!productInstructionName) return setErrorMessage({ ...errorMessage, ...{ PRODUCT_INSTRUCTION_NAME: t('MATERIAL_INSTRUCTION.MESSAGE.PRODUCT_INSTRUCTION_NAME') } })

        dispatch(submitModal())

        ProductInstructionsApi.create({ productInstructionName, productInstructionNameEn, group: 'MATERIAL' })
            .then((res: any) => {
                if (res.status !== undefined && res.status === 201) {
                    notiSuccess(t('MATERIAL_INSTRUCTION.MESSAGE.SUCCESS.CREATE'), '', null, null)
                    resetForm()
                    loadData()
                    dispatch(resetModal())
                } else {
                    const err = res.response.data
                    setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MATERIAL_INSTRUCTION.MESSAGE.${err.message}`) } })
                    dispatch(unSubmitModal())
                }
            })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MATERIAL_INSTRUCTION.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            })
    }

    const submitEdit = () => {
        if (!productInstructionName) return setErrorMessage({ ...errorMessage, ...{ PRODUCT_INSTRUCTION_NAME: t(`MATERIAL_INSTRUCTION.MESSAGE.PRODUCT_INSTRUCTION_NAME`) } })

        dispatch(submitModal())
        ProductInstructionsApi.update(productInstructionId, { productInstructionName, productInstructionNameEn, status: productInstructionStatus, group: 'MATERIAL' })
            .then((res: any) => {
                if (res.status !== undefined && res.status === 200) {
                    notiSuccess(t(`MATERIAL_INSTRUCTION.MESSAGE.SUCCESS.UPDATE`), '', null, null)
                    resetForm()
                    loadData()
                    dispatch(resetModal())
                } else {
                    const err = res.response.data

                    setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MATERIAL_INSTRUCTION.MESSAGE.${err.message}`) } })
                    dispatch(unSubmitModal())
                }
            })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MATERIAL_INSTRUCTION.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            })
    }

    const clearErrorMessage = () => {
        setErrorMessage(initStateErrorMessage)
    }

    const handleChangeName = (e: any) => {
        setErrorName('')
        const name = e === '' ? '' : String(e)
        const contentLength = name.length

        if (contentLength > 80) {
            setErrorMaxLength(true)
        } else {
            setProductInstructionName(name)
            setTextLength(contentLength)
            setErrorMaxLength(false)
        }

    }
    const handleChangeNameEn = (e: any) => {
        const nameEn = e === '' ? '' : String(e)
        const contentLength = nameEn.length
        if (contentLength > 80) {
            setErrorMaxLength(true)
        } else {
            setProductInstructionNameEn(nameEn)
            setTextLengthEn(contentLength)
            setErrorMaxLength(false)
        }

    }

    const resetForm = () => {
        setProductInstructionId(0)
        setProductInstructionName('')
        setProductInstructionNameEn('')
        setProductInstructionStatus('')
        clearErrorMessage()
    }

    const onActive = (id: number, valueStatus: string) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MATERIAL_INSTRUCTION.ALERT.CONFIRM_STATUS')}</p>`,
            null,
            (res: any) => {
                if (res) {
                    ProductInstructionsApi.updateStatus(id, valueStatus)
                        .then((resp) => {
                            notiSuccess(t('MATERIAL_INSTRUCTION.MESSAGE.SUCCESS.UPDATE_STATUS'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('MATERIAL_INSTRUCTION.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
    }
    const onDelete = (data: any) => {
        const { productInstructionId: pdInstructionId, productInstructionName: pdInstructionName, productInstructionNameEn: pdInstructionNameEn } = data

        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MATERIAL_INSTRUCTION.ALERT.CONFIRM_DELETE')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MATERIAL_INSTRUCTION.ALERT.CONFIRM_DELETE_', {
                productInstructionName: pdInstructionName,
                productInstructionNameEn: pdInstructionNameEn ? ' (' + pdInstructionNameEn + ')' : ''
            })}</p>`,
            (res: any) => {
                if (res) {
                    ProductInstructionsApi.remove(pdInstructionId)
                        .then((resp) => {
                            notiSuccess(t('MATERIAL_INSTRUCTION.MESSAGE.SUCCESS.DELETE'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('MATERIAL_INSTRUCTION.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const onEdit = (data: any) => {
        const { productInstructionId: pdInstructionId, productInstructionName: pdInstructionName, productInstructionNameEn: pdInstructionNameEn, status: pdInstructionStatus } = data
        setProductInstructionId(pdInstructionId)
        setProductInstructionName(pdInstructionName)
        setProductInstructionNameEn(pdInstructionNameEn)
        setProductInstructionStatus(pdInstructionStatus)
        dispatch(showModal())
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }
    const handleChangeStatus = (statusVal: string) => {
        setStatus(statusVal)
    }
    const headCells = [
        { id: 'no', disablePadding: false, label: '#' },
        { id: 'productInstructionName', disablePadding: false, label: t('MATERIAL_INSTRUCTION.TABLE.CELL.PRODUCT_INSTRUCTION_NAME') },
        { id: 'productInstructionNameEn', disablePadding: false, label: t('MATERIAL_INSTRUCTION.TABLE.CELL.PRODUCT_INSTRUCTION_NAME_EN') },
        { id: 'status', disablePadding: false, label: t('MATERIAL_INSTRUCTION.TABLE.CELL.STATUS') },
        { id: 'updatedBy', disablePadding: false, label: t('MATERIAL_INSTRUCTION.TABLE.CELL.UPDATED_BY') },
        { id: 'action', disablePadding: false, label: 'Action' }
    ]

    const renderData = (objData: any, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { productInstructionId: pdInstructionId, productInstructionName: pdInstructionName, productInstructionNameEn: pdInstructionNameEn, updatedBy, updatedAt, status: pdInstructionStatus } = objData
        const statusBtnActive = pdInstructionStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
        const statusBtnInActive = pdInstructionStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
        const objRenderData = {
            key: pdInstructionId,
            id: pdInstructionId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: pdInstructionName },
                { option: 'TEXT', align: 'left', label: pdInstructionNameEn },
                { option: 'STATUS', align: 'center', label: pdInstructionStatus },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
                        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
                    ]
                }
            ]
        }
        return (
            <TableRowCommon
                {...objRenderData}
                onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
                oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
                onedit={() => onEdit(objRenderData.obj)}
                ondelete={() => onDelete(objRenderData.obj)}
            />
        )
    }

    return (
        <div className={classes.root}>
            <Card className="border-0 h-100">
                <HeaderCard text={t('MATERIAL_INSTRUCTION.TITLE')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Row className="flax-row">
                                    <Col sm={12} md={6} xl={4} className="pt-2">
                                        <InputTextSearch
                                            keyInput={'search'}
                                            label={t('INPUT.SEARCH')}
                                            value={search}
                                            onchange={(event) => {
                                                setSearch(event.target.value)
                                                setPage(1)
                                            }}
                                            onClear={(event) => {
                                                setSearch('')
                                                setPage(1)
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                                        <FilterSelect
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value) {
                                                    handleChangeStatus(value)
                                                    setPage(1)
                                                }
                                            }}
                                            renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                                            label={t('STATUS.LABEL')}
                                            selectId="select-status"
                                            value={status}
                                            labelId="label-status"
                                            options={[
                                                <MenuItem key="1" value="ALL">
                                                    {t('STATUS.ALL')}
                                                </MenuItem>,
                                                <MenuItem key="2" value="ACTIVE">
                                                    {t('STATUS.ACTIVE')}
                                                </MenuItem>,
                                                <MenuItem key="3" value="INACTIVE">
                                                    {t('STATUS.INACTIVE')}
                                                </MenuItem>
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} className="mt-2 mt-md-auto">
                                <div className="pr-xl-2">
                                    <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('MATERIAL_INSTRUCTION.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                                </div>
                            </Col>
                        </Row>
                    </Card.Title>

                    <div className={'mt-3 pl-xl-3 pr-xl-2'}>
                        <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            onSort={onRequestSort}
                            setPageLimit={handleChangeRowsPerPage}
                            setPage={handleChangePage}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={productInstructions.map((val, i) => {
                                return renderData(val, i)
                            })}
                        />
                    </div>
                </Card.Body>
            </Card>

            <ModalCustom
                title={productInstructionId ? t('MATERIAL_INSTRUCTION.TITLE_UPDATE') : t('MATERIAL_INSTRUCTION.TITLE_CREATE')}
                component={
                    <div className="pb-2">
                        {productInstructionId ? (
                            <div>
                                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                                    <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        onChange={() => setProductInstructionStatus(productInstructionStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                                        checked={productInstructionStatus === 'ACTIVE' ? true : false}
                                        style={{ marginLeft: 'auto' }}
                                    />
                                </Form>
                            </div>
                        ) : (
                            ''
                        )}
                        <Box sx={{ fontWeight: 400, fontSize: '10px', color: '#808080', textAlign: 'end', marginLeft: '10px' }}>{`${textLength}/${80}`}</Box>
                        <InputTextField
                            onchange={(event) => {
                                handleChangeName(event.target.value)
                                clearErrorMessage()
                            }}
                            value={productInstructionName}
                            label={t('MATERIAL_INSTRUCTION.INPUT.PRODUCT_INSTRUCTION_NAME')}
                            helperText={
                                errorMessage.PRODUCT_INSTRUCTION_NAME ||
                                errorMessage.IS_DUPLICATE_PRODUCT_INSTRUCTION_NAME ||
                                errorMessage.PRODUCT_INSTRUCTION_NAME_STRING_BASE ||
                                errorMessage.PRODUCT_INSTRUCTION_NAME_STRING_EMPTY ||
                                errorMessage.PRODUCT_INSTRUCTION_NAME_MAXIMUM_LENGTH ||
                                errorMessage.PRODUCT_INSTRUCTION_NAME_ANY_REQUIRED
                            }
                            required={true}
                            style={{ marginBottom: '1rem' }}
                        />
                        <Box sx={{ fontWeight: 400, fontSize: '10px', color: '#808080', textAlign: 'end', marginLeft: '10px' }}>{`${textLengthEn}/${80}`}</Box>
                        <InputTextField
                            key="productInstructionNameEn"
                            onchange={(event) => {
                                handleChangeNameEn(event.target.value)
                                clearErrorMessage()
                            }}
                            value={productInstructionNameEn}
                            helperText={
                                errorMessage.PRODUCT_INSTRUCTION_NAME_EN ||
                                errorMessage.IS_DUPLICATE_PRODUCT_INSTRUCTION_NAME_EN ||
                                errorMessage.PRODUCT_INSTRUCTION_NAME_EN_STRING_BASE ||
                                errorMessage.PRODUCT_INSTRUCTION_NAME_EN_STRING_EMPTY ||
                                errorMessage.PRODUCT_INSTRUCTION_NAME_EN_MAXIMUM_LENGTH ||
                                errorMessage.PRODUCT_INSTRUCTION_NAME_EN_ANY_REQUIRED
                            }
                            label={t('MATERIAL_INSTRUCTION.INPUT.PRODUCT_INSTRUCTION_NAME_EN')}
                            required={false}
                        />
                    </div>
                }
                onReset={resetForm}
                onSubmit={productInstructionId ? submitEdit : submit}
                textBtnCancel={t('MATERIAL_INSTRUCTION.BUTTON.CANCEL')}
                textBtnConfirm={t('MATERIAL_INSTRUCTION.BUTTON.SAVE')}
                size='lg'
            />
        </div>
    )
}
