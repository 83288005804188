import { styled } from '@mui/material/styles'

const StyleBridge = styled(`div`)<{ position?: 'L' | 'U' }>(({ theme, position }) => ({
  position: 'absolute',
  top: position === 'L' ? 2 : 'auto',
  bottom: position === 'L' ? 'auto' : 8,
  zIndex: 0,
  [theme.breakpoints.down(1600)]: {
    width: '86px',
    top: position === 'L' ? 26 : 'auto',
    bottom: position === 'L' ? 'auto' : 20,
  },
  [theme.breakpoints.down(1280)]: {
    width: '64px',
    top: position === 'L' ? 14 : 'auto',
    bottom: position === 'L' ? 'auto' : 12,
  },
  [theme.breakpoints.down(960)]: {
    width: '48px',
    top: position === 'L' ? 10 : 'auto',
    bottom: position === 'L' ? 'auto' : 10,
  }
}))

type IconProps = {
  style?: any
  position?: 'L' | 'U'
  width?: number
}

export default function Bridge(props: IconProps) {
  return (
    <StyleBridge position={props.position}>
      <svg xmlns="https://www.w3.org/2000/svg" width={`${props.width || 46}`} height="10" viewBox={`0 0 ${props.width || 46} 10`}>
        <g id="สะพาน" transform="translate(2.14 -8.701)">
          <line id="Line_410" data-name="Line 410" x2={`${props.width || 44}`} transform="translate(-1.14 9.701)" fill="none" stroke="#3a3985" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_412" data-name="Line 412" x2={`${props.width || 44}`} transform="translate(-1.14 17.701)" fill="none" stroke="#3a3985" strokeLinecap="round" strokeWidth="2" />
          <line id="Line_411" data-name="Line 411" x2={`${props.width || 44}`} transform="translate(-1.14 13.701)" fill="none" stroke="#3a3985" strokeLinecap="round" strokeWidth="2" />
        </g>
      </svg>
    </StyleBridge>
  )
}
