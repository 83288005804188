import { Tabs, Typography, IconButton, MenuItem, Button } from '@mui/material'
import { useState, useEffect, useRef } from 'react'
import { Box } from '@mui/system'
import _ from 'lodash'
import moment from 'moment'
import $ from 'jquery'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import UploadTreatmentApi, { CreateTreatmentImage } from 'api/master/uploadTreatment.api'

/** SLICE */
import { toBuddhistYear } from 'utils/app.utils'

/** CONSTANTS */
import { Icons, icons, imgs, onImgError } from 'constants/images'
import ClosePopup from 'assets/icon/appointment/close.svg'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import { notiPopup } from 'component/notifications/notifications'
import { ToastContainer } from 'react-toastify'
import PreviewImage from 'component/Image/PreviewImage'
import Loading from 'component/Loading'
import FucntionPrint from "component/Print/print"
import PdfXray from 'features/pdf/pdf-xray'

/** STYLE */
import { colors } from 'constants/theme'
import * as UseStyled from 'component/Register/style/styleFormUploadTreatment'


interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  previewImage?: boolean
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      className={'image-panel custom-scroll p-0 mt-2 mt-md-3 mb-0 mx-0'}
      style={{ minHeight: props.previewImage ? 'unset' : 200, overflowY: 'auto', height: props.previewImage ? '30%' : '100%', maxHeight: props.previewImage ? 'unset' : '100%' }}
      hidden={value !== index}
      id={`tabpanel-type-${index}`}
      aria-labelledby={`type-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, margin: '0 -0.25rem', width: '100%', height: '100%' }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `type-tab-${index}`,
    'aria-controls': `tabpanel-type-${index}`
  }
}

type ModalFormUploadTreatmentProps = {
  isShow: boolean | false
  setIsShow: (show: boolean) => void
  patient?: any
  previewImage?: any
  handleSubmit?: (val: 'uploadSuccess' | '') => void
  typeActive?: number
  treatmentActive?: string | number
}

type PreviewImageType = {
  createdBy?: string
  createdAt?: string
  fileName?: string
  filePath?: string
  fullPath?: string
  refFile?: string
  refId?: string
  treatmentFileId?: number
  base64?: string
  fileExtension?: string
}

type ComponentGalleryProps = {
  type?: number
  vnNumber?: string | number
  gallery?: any
  images?: any
  loading?: boolean
  onChangeFiles?: (e: any) => void
  onDelete?: (val: any) => void
  setPreview?: any
  active?: string
}

type VisitType = {
  patientCaseId: number
  branchId: number
  vnNumber: string | number
}

type ImageType = {
  treatmentFileId: null
  base64: string
  type: string
  fileExtension: string
  fileSize: string
  fileName: string
  updatedAt: any
  updatedBy: string
}

type NewImageType = {
  treatmentFileId: null
  base64: string
  type: string
  fileExtension: string
  fileSize: string
  fileName: string
  createdAt: string
}

enum TreatmentType {
  'ALL',
  'VN_NUMBER',
  'NOT_VN_NUMBER'
}

export default function FormUploadTreatment(props: ModalFormUploadTreatmentProps) {
  const { t } = useTranslation()
  const [softLoading, setSoftLoading] = useState(false)
  const [visits, setVisits] = useState([])
  const [treatmentTypes, setTreatmentTypes] = useState([])
  const [typeActive, setTypeActive] = useState(0)
  const [treatmentActive, setTreatmentActive] = useState<string | number>(TreatmentType.ALL)
  const [treatmentImages, setTreatmentImages] = useState([])
  const [treatmentImage, setTreatmentImage] = useState([])
  const [images, setImages] = useState<NewImageType[]>([])
  const [hasDelete, setHasDelete] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [disabledBtnSave, setDisabledBtnSave] = useState(false);
  const [previewImage, setPreviewImage] = useState<PreviewImageType>({})
  const [prints, setPrints] = useState(false)
  const [print, setPrint] = useState<any[]>([])
  const componentRef = useRef<HTMLDivElement>(null)


  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    if (!_.isEmpty(images) || hasDelete) {
      notiPopup(
        'custom',
        '',
        <div className={'d-flex align-items-center justify-content-between'}>
          <span>{t(`REGISTER.MESSAGE.UPLOAD_TREATMENT.INPROGRESS`)}</span>
          <Button
            variant="text"
            disableRipple
            onClick={() => {
              discardChange()
              notiPopup('clear')
            }}
            sx={{ color: colors.statusWarning, fontSize: 16, fontWeight: 400, padding: 0, lineHeight: 1, backgroundColor: 'transparent !important' }}
          >
            {t(`REGISTER.BUTTON.DISCARD`)}
          </Button>
        </div>
      )
      setDisabled(true)
    } else {
      setTypeActive(newValue)
      notiPopup('clear')
      setDisabled(false)
      setPreviewImage({})
    }
  }

  const handleChangeTreatment = (treatment: any) => {
    if (!_.isEmpty(images) || hasDelete) {
      notiPopup(
        'custom',
        '',
        <div className={'d-flex align-items-center justify-content-between'}>
          <span>{t(`REGISTER.MESSAGE.UPLOAD_TREATMENT.INPROGRESS`)}</span>
          <Button
            variant="text"
            disableRipple
            onClick={() => {
              discardChange()
              notiPopup('clear')
            }}
            sx={{ color: colors.statusWarning, fontSize: 16, fontWeight: 400, padding: 0, lineHeight: 1, backgroundColor: 'transparent !important' }}
          >
            {t(`REGISTER.BUTTON.DISCARD`)}
          </Button>
        </div>
      )
      setDisabled(true)
    } else {
      setTreatmentActive(treatment)
      notiPopup('clear')
      setDisabled(false)
      setPreviewImage({})
    }
  }

  const loadTreatmentType = () => {
    UploadTreatmentApi.findAllImageTypes()
      .then(({ data }) => setTreatmentTypes(data))
      .catch(() => setTreatmentTypes([]))
  }

  const loadVisit = (patientId: number) => {
    UploadTreatmentApi.findTreatmentHistory(patientId)
      .then(({ data }) => setVisits(data))
      .catch(() => setVisits([]))
  }

  const loadGallery = () => {
    setSoftLoading(true)
    let condition: any = {}
    condition = { ...condition, condition: treatmentActive === TreatmentType.ALL || treatmentActive === TreatmentType.VN_NUMBER || treatmentActive === TreatmentType.NOT_VN_NUMBER ? TreatmentType[treatmentActive] : '--' }
    condition = { ...condition, vnNumber: condition.condition === '--' ? treatmentActive : '' }
    UploadTreatmentApi.getTreatmentImage(props.patient.cnNumber, condition)
      .then(({ data }) => setTreatmentImages(data))
      .catch(() => setTreatmentImages([]))
      .finally(() => setSoftLoading(false))
  }

  const onChangeFiles = async (e: any) => {
    notiPopup('clear')
    if (!_.isEmpty(e.target.files)) {
      let files: any = []
      _.map(e.target.files, (file) => {
        if (!_.some(images, (img: ImageType) => `${img.fileName}.${img.fileExtension}` === `${file.name}`)) {
          files = [...files, renderBaseFile(file)]
        } else {
          notiPopup('warning', t(`REGISTER.MESSAGE.UPLOAD_TREATMENT.ERROR_FILE`, {
            file_name: file.name
          }))
        }
      })
      const newImages = await Promise.all(files)
      setImages([...images, ...newImages])
      e.target.value = null
    }
  }

  const renderBaseFile = (file: any) => {
    return new Promise<NewImageType>((resolve, reject) => {
      const reader: any = new FileReader()
      reader.onload = () => {
        const base64 = reader.result.split(',')
        resolve({
          treatmentFileId: null,
          base64: base64.length > 0 ? base64[base64.length - 1] : reader.result,
          type: '',
          fileExtension: _.last(file.name.split('.')) || '',
          fileSize: file.size.toString(),
          fileName: _.first(file.name.split('.')) || '',
          createdAt: moment().format('YYYY-MM-DD'),
        })
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const handleLoadGallery = () => {
    if (typeActive === 0) {
      const gallery: any = []
      _.map(treatmentImages, (img: any, index: number) => {
        _.map(img.treatmentImages, (image: any, indexImage: number) => {
          gallery.push(image)
        })
      })
      setTreatmentImage(gallery)
    } else {
      const gallery: any = []
      _.map(treatmentImages, (img: any, index: number) => {
        if (img.imageTypeId === typeActive) {
          _.map(img.treatmentImages, (image: any, indexImage: number) => {
            gallery.push(image)
          })
        }
      })
      setTreatmentImage(gallery)
    }

  }

  const onSubmit = () => {
    setDisabledBtnSave(true)
    const patientTreatmentImage: any = []
    _.map([...treatmentImage, ...images], (i: any, index: number) => {
      patientTreatmentImage.push({ treatmentFileId: i.treatmentFileId || null, base64: i.base64 || '', type: i.type || '', fileExtension: i.fileExtension || '', fileSize: i.fileSize || '', fileName: i.fileName || '' })
    })

    const itemUpload: CreateTreatmentImage = {
      patientGalleryId: _.get(_.find(treatmentImages, { imageTypeId: typeActive }), 'patientGalleryId') || null,
      patientId: props.patient.patientId,
      vnNumber: treatmentActive === TreatmentType.NOT_VN_NUMBER ? '' : treatmentActive,
      imageTypeId: typeActive,
      treatmentImages: patientTreatmentImage
    }

    UploadTreatmentApi.createTreatmentImage(itemUpload)
      .then(() => {
        notiPopup('clear')
        discardChange()
        notiPopup('success', t(`REGISTER.MESSAGE.UPLOAD_TREATMENT.SAVE`))
        if (props.handleSubmit) props.handleSubmit('uploadSuccess')

      })
      .catch(() => { return })
      .finally(() => {
        setDisabledBtnSave(false)
        return loadGallery()
      })
  }

  const onDelete = (item: any) => {
    if (item.name === previewImage.fileName) setPreviewImage({})
    if (item.type === 'gallery') {
      const gallery: any = _.filter(treatmentImage, (image: any, index: number) => {
        return image.treatmentFileId !== item.val
      })
      setTreatmentImage(gallery)
      setHasDelete(true)
    } else {
      const gallery: any = _.filter(images, (image: any, index: number) => {
        return index !== item.val
      })
      setImages(gallery)
      if (_.isEmpty(images)) setHasDelete(false)
    }
  }

  const onCheck = (event: any) => {
    const { checked, value } = event.target
    if (checked) {
      setPrint([...print, String(value)])
    } else {
      setPrint(
        _.filter(print, (i: string) => {
          return i !== String(value)
        })
      )
    }
  }

  const discardChange = () => {
    loadGallery()
    setHasDelete(false)
    setImages([])
    setDisabled(false)
    setPreviewImage({})
  }

  useEffect(() => {
    loadGallery()
  }, [treatmentActive, typeActive])

  useEffect(() => {
    loadTreatmentType()
    if (props.patient.cnNumber) loadVisit(props.patient.patientId)
    if (props.handleSubmit) props.handleSubmit('')
  }, [])

  useEffect(() => {
    handleLoadGallery()
  }, [treatmentImages, treatmentActive, typeActive])

  useEffect(() => {
    if (!_.isEmpty(props.previewImage)) setPreviewImage(props.previewImage)
    if (props.typeActive) setTypeActive(props.typeActive)
    if (props.treatmentActive) setTreatmentActive(props.treatmentActive)
  }, [props.previewImage])

  return (
    <UseStyled.ModalStyle returnFocusAfterClose={false} size={'xl'} show={props.isShow} centered>
      <UseStyled.Header>
        <Typography>{t('REGISTER.UPLOAD_TREATMENT.TITLE')}</Typography>
      </UseStyled.Header>
      <UseStyled.Body className={'d-flex flex-column flex-xl-row overflow-hidden'}>
        <UseStyled.TreatmentList className={`overflow-hidden ${disabled ? 'pe-none' : ''}`}>
          <UseStyled.TreatmentListItem>
            <UseStyled.TypographyTitle className={'pb-2'}>
              {t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST')} ({visits.length + 3})
            </UseStyled.TypographyTitle>
            <div className={'custom-scroll'} style={{ overflowY: 'auto' }}>
              <UseStyled.Item className={`${treatmentActive === TreatmentType.ALL ? 'active' : ''}`} onClick={() => handleChangeTreatment(TreatmentType.ALL)}>
                <span className={'text-ellipsis'}>{t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.ALL')}</span>
              </UseStyled.Item>
              <UseStyled.Item className={`${treatmentActive === TreatmentType.VN_NUMBER ? 'active' : ''}`} onClick={() => handleChangeTreatment(TreatmentType.VN_NUMBER)}>
                <span className={'text-ellipsis'}>{t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.ALL_VISIT')}</span>
              </UseStyled.Item>
              <UseStyled.Item className={`${treatmentActive === TreatmentType.NOT_VN_NUMBER ? 'active' : ''}`} onClick={() => handleChangeTreatment(TreatmentType.NOT_VN_NUMBER)}>
                <span className={'text-ellipsis'}>{t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.NO_VISIT')}</span>
              </UseStyled.Item>
              {_.map(visits, (visit: VisitType, index: number) => {
                return (
                  <UseStyled.Item key={visit.patientCaseId} className={`${treatmentActive === visit.vnNumber ? 'active' : ''}`} onClick={() => handleChangeTreatment(visit.vnNumber)}>
                    <span className={'text-ellipsis'}>VN {visit.vnNumber}</span>
                  </UseStyled.Item>
                )
              })}
            </div>
          </UseStyled.TreatmentListItem>
          <UseStyled.TreatmentListSelect>
            <BasicSelect
              labelId="lb-select-treatment"
              selectId="sl-select-treatment"
              label=""
              onchange={(e: any) => handleChangeTreatment(e.target.value)}
              value={treatmentActive}
              options={[
                <MenuItem key={TreatmentType.ALL} value={TreatmentType.ALL}>
                  {t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.ALL')}
                </MenuItem>,
                <MenuItem key={TreatmentType.VN_NUMBER} value={TreatmentType.VN_NUMBER}>
                  {t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.ALL_VISIT')}
                </MenuItem>,
                <MenuItem key={TreatmentType.NOT_VN_NUMBER} value={TreatmentType.NOT_VN_NUMBER}>
                  {t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.NO_VISIT')}
                </MenuItem>,
                _.map(visits, (visit: VisitType, index: number) => {
                  return (
                    <MenuItem key={visit.patientCaseId} value={visit.vnNumber}>
                      {visit.vnNumber}
                    </MenuItem>
                  )
                })
              ]}
            />
          </UseStyled.TreatmentListSelect>
        </UseStyled.TreatmentList>
        <UseStyled.TreatmentType className={'overflow-hidden h-100'}>
          <UseStyled.TypographyTitle className={'pb-1 pb-md-2'}>{t('REGISTER.UPLOAD_TREATMENT.IMAGE_TYPE')}</UseStyled.TypographyTitle>
          <UseStyled.TreatmentTypeTabs className={`custom-scroll ${disabled ? 'pe-none' : ''}`}>
            <Tabs classes={{ indicator: 'd-none' }} value={typeActive} onChange={handleChangeTab}>
              <UseStyled.ButtonType
                key={0}
                value={0}
                label={
                  <span className={'text-ellipsis'} style={{ maxWidth: '96%' }}>
                    {t('REGISTER.UPLOAD_TREATMENT.ALL_TYPE')}
                  </span>
                }
                {...a11yProps(0)}
              />
              {_.map(treatmentTypes, (type: any, index: number) => {
                return (
                  <UseStyled.ButtonType
                    key={type.imageTypeId}
                    value={type.imageTypeId}
                    label={
                      <span className={'text-ellipsis'} style={{ maxWidth: '96%' }}>
                        {type.imageTypeName}
                      </span>
                    }
                    {...a11yProps(type.imageTypeId)}
                  />
                )
              })}
            </Tabs>
          </UseStyled.TreatmentTypeTabs>
          <ToastContainer enableMultiContainer containerId={'popup'} className={'Toast-popup'} />
          {!_.isEmpty(previewImage) && (
            <UseStyled.PreviewImage className="d-flex flex-column">
              <Box className="d-flex align-items-center justify-content-between mb-1">
                <Typography className="text-ellipsis" sx={{ flex: 1 }}>
                  <span style={{ fontWeight: 500 }}>{t('REGISTER.UPLOAD_TREATMENT.UPLOAD_AT')}:</span> {toBuddhistYear(moment(previewImage.createdAt), 'DD/MM/YYYY HH:mm')} {t('TIME_UNIT')} <span style={{ fontWeight: 500 }}>{t('REGISTER.UPLOAD_TREATMENT.UPLOAD_BY')}:</span> {previewImage.createdBy}
                </Typography>
                <IconButton
                  aria-label="clear"
                  onClick={() => setPreviewImage({})}
                  className="p-0"
                  sx={{
                    backgroundColor: 'transparent !important',
                    '&:hover img': {
                      opacity: `1 !important`
                    }
                  }}
                >
                  <img src={ClosePopup} alt="close" style={{ width: 17, height: 17, opacity: 0.6 }} />
                </IconButton>
              </Box>
              <PreviewImage image={previewImage} />
            </UseStyled.PreviewImage>
          )}
          <TabPanel key={0} value={typeActive} index={0} previewImage={!_.isEmpty(previewImage) ? true : false}>
            <ComponentGallery type={0} vnNumber={treatmentActive} gallery={treatmentImage} images={images} loading={softLoading} onDelete={onDelete} setPreview={(image: PreviewImageType) => setPreviewImage(image)} active={previewImage.fileName} />
          </TabPanel>
          {_.map(treatmentTypes, (panel: any, index: number) => {
            return (
              <TabPanel key={panel.imageTypeId} value={typeActive} index={panel.imageTypeId} previewImage={!_.isEmpty(previewImage) ? true : false}>
                <ComponentGallery
                  type={panel.imageTypeId}
                  vnNumber={treatmentActive}
                  gallery={treatmentImage}
                  images={images}
                  loading={softLoading}
                  onChangeFiles={(e: any) => onChangeFiles(e)}
                  onDelete={onDelete}
                  setPreview={(image: PreviewImageType) => setPreviewImage(image)}
                  active={previewImage.fileName}
                />
              </TabPanel>
            )
          })}
        </UseStyled.TreatmentType>
      </UseStyled.Body>
      <UseStyled.Footer className="flex-right text-right border-0">
        {!prints && <>
          <UseStyled.BtnCancel fullWidth onClick={() => props.setIsShow(false)}>
            {t('BUTTON.CANCEL')}
          </UseStyled.BtnCancel>
          <UseStyled.BtnConfirm disabled={disabledBtnSave || treatmentActive === TreatmentType.ALL || treatmentActive === TreatmentType.VN_NUMBER || typeActive === 0} fullWidth className={'btn-submit'} onClick={onSubmit}>
            {t('BUTTON.SAVE')}
          </UseStyled.BtnConfirm>
        </>}
        {prints && <>
          <UseStyled.BtnCancel fullWidth onClick={() => props.setIsShow(false)}>
            {t('BUTTON.CANCEL')}
          </UseStyled.BtnCancel>
          <FucntionPrint
            content={() => componentRef.current}
            docTitle="Xray"
            removeIfram
            trigger={() => <UseStyled.BtnConfirm disabled={print.length === 0}>
              {t('BUTTON.PRINT')}
            </UseStyled.BtnConfirm>}
            style={'@page { size:  793.7007874px 1122.519685px;  margin: 1cm; } @media print { body { -webkit-print-color-adjust: exact; } '}
          />
        </>}
      </UseStyled.Footer>
      <div ref={componentRef} className="print-show" >
        <PdfXray img={print} />
      </div>
    </UseStyled.ModalStyle>
  )
}

function ComponentGallery(props: ComponentGalleryProps) {
  const { t } = useTranslation()
  const { type, vnNumber, gallery, images, loading, onChangeFiles, active } = props

  const renderImage = (img: any) => {
    return `${fileUrl}/${img.fullPath}`
  }

  return (
    !loading && (
      <>
        {(vnNumber === TreatmentType.VN_NUMBER || vnNumber === TreatmentType.ALL || type === 0) && _.isEmpty(gallery) && (
          <UseStyled.EmptyItem className={'d-flex align-items-center justify-content-center flex-column'}>
            <Icons.Gallery />
            <Typography className={'mt-2'} sx={{ color: colors.black60 }}>
              {t('REGISTER.TITLE.EMPTY_GALLERY')}
            </Typography>
          </UseStyled.EmptyItem>
        )}

        <UseStyled.RowPanel className={`d-flex align-items-center flex-wrap`}>
          {((vnNumber === TreatmentType.NOT_VN_NUMBER && type !== 0) || (typeof vnNumber === 'string' && type !== 0)) && (
            <UseStyled.ColumnPanel>
              <UseStyled.CardImg>
                <UseStyled.DivUpload
                  onClick={() => {
                    $('#profilePic').click()
                  }}
                  className={'d-flex align-items-center justify-content-center'}
                >
                  <input id="profilePic" type="file" multiple onChange={onChangeFiles} className={'d-none'} />
                  <div className={'upload-image text-center'}>
                    <img src={imgs.defaultAddImage} alt="uploadImage" />
                    <p className={'pt-1 pt-sm-2 m-0'}>{t('REGISTER.UPLOAD_TREATMENT.UPLOAD_IMAGE')}</p>
                  </div>
                </UseStyled.DivUpload>
              </UseStyled.CardImg>
            </UseStyled.ColumnPanel>
          )}

          {_.map(gallery, (i: any, index: number) => {
            return (
              <UseStyled.ColumnPanel>
                <UseStyled.CardImg>
                  <UseStyled.DivImg
                    className={`${active === i.fileName ? 'active' : ''}`}
                    onClick={() => {
                      props.setPreview(i)
                    }}
                  >
                    <img src={renderImage(i)} alt={i.fileName} onError={onImgError} />
                  </UseStyled.DivImg>
                  {vnNumber !== TreatmentType.ALL && vnNumber !== TreatmentType.VN_NUMBER && type !== 0 && (
                    <IconButton
                      className={'btn-delete'}
                      aria-label="delete"
                      onClick={() => {
                        if (props?.onDelete) props?.onDelete({ type: 'gallery', val: i.treatmentFileId, name: i.fileName })
                      }}
                      sx={{ backgroundColor: 'transparent !important', padding: 0 }}
                    >
                      <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                    </IconButton>
                  )}
                </UseStyled.CardImg>
                {<p style={{ fontSize: '12px', overflow: 'hidden', textAlign: 'center' }}>{t('REGISTER.UPLOAD_TREATMENT.UPLOAD_BY')}: {i.createdBy}</p>}
                {<p style={{ fontSize: '12px', textAlign: 'center' }}>{toBuddhistYear(moment(i.createdAt), 'DD/MM/YYYY HH:mm')} {t('TIME_UNIT')}</p>}
              </UseStyled.ColumnPanel>
            )
          })}
          {_.map(images, (img: ImageType, index: number) => {
            return (
              <UseStyled.ColumnPanel>
                <UseStyled.CardImg>
                  <UseStyled.DivImg
                    className={`${active === img.fileName ? 'active' : ''}`}
                    onClick={() => {
                      props.setPreview(img)
                    }}
                  >
                    <img src={`data:image/${img.fileExtension};base64,${img.base64}`} alt={img.fileName} onError={onImgError} />
                  </UseStyled.DivImg>
                  {vnNumber !== TreatmentType.ALL && vnNumber !== TreatmentType.VN_NUMBER && type !== 0 && (
                    <IconButton
                      className={'btn-delete'}
                      aria-label="delete"
                      onClick={() => {
                        if (props?.onDelete) props?.onDelete({ type: 'upload', val: index, name: img.fileName })
                      }}
                      sx={{ backgroundColor: 'transparent !important', padding: 0 }}
                    >
                      <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                    </IconButton>
                  )}
                </UseStyled.CardImg>
              </UseStyled.ColumnPanel>
            )
          })}
        </UseStyled.RowPanel>
      </>
    ) || (
      <Box className='mx-auto' sx={{ maxWidth: 'max-content' }}>
        <Loading show type='softLoading' />
      </Box>
    )
  )
}
