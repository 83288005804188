import { makeStyles } from '@mui/styles'
import shadows from '@mui/material/styles/shadows'
import { colors } from 'constants/theme'
import { Box, styled } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'


export const BtnDay = styled('div')(({ theme }) => ({
    '&.btn-day': {
        backgroundColor: colors.lightGray,
        color: colors.textPrimary,
        height: '32px',
        width: '32px',
        borderRadius: '100px',
        fontSize: '16px'
    },
    '&.btn-day.active.open': {
        backgroundColor: colors.themeSecondColor10,
        color: colors.themeSecondColor
    },
    '&.btn-day.active.close': {
        backgroundColor: colors.statusInactive10,
        color: colors.statusInactive
    },
}))

export const CustomTable = styled('div')(({ theme }) => ({
    '.MuiTable-root': {
        '.MuiTableRow-head': {
            '.MuiTableCell-head': {
                fontSize: 16,
                fontWeight: 500,
                "&.title-work": {
                    color: colors.themeMainColor,
                    fontSize: '18px !important',
                    borderBottomColor: `${colors.lightGray} !important`,
                    fontWeight: 600,
                }
            }
        },
    }
}))

export const CardProfileImg = styled('div')(({ theme }) => ({
    width: '150px',
    height: '150px',
    position: 'relative',
    '&:not(.disabled) .personal-figure': {
        cursor: 'pointer'
    },
    img: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        borderRadius: 100,
        objectFit: 'cover'
    },
    '.btn-add-profile': {
        position: 'absolute',
        right: 12,
        bottom: 0,
        width: 29,
        height: 29,
        color: colors.white,
        backgroundColor: `${colors.themeSecondColor} !important`,
        svg: {
            width: 16,
            height: 'auto'
        }
    }
}))

export const ContainerComponent = styled('div')(({ theme }) => ({
    height: '100%',
    '& .btn-day': {
        backgroundColor: '#e5e5e5 !important',
        color: 'black'
    },
    '& .btn-day.active': {
        backgroundColor: '#E7F8F5 !important',
        color: '#1cb99a'
    },
    '& .btn-bank.active': {
        border: 'solid 2px #1cb99a',
        boxShadow: '0 0 6px 0 #1cb99a'
    },
}))

const useStyles = makeStyles((theme) => ({

    titleFirst: {
        fontSize: '18px',
        fontWeight: 600
    },
    titleSecond: {
        fontSize: '16px'
    },
    menu: {
        fontSize: '16px'
    },
    dropdown: {
        width: '70%',
        backgroundColor: `${colors.white} !important `,
        color: `${colors.black} !important `,
        border: `solid 1px ${colors.black}  !important`,
        '&:hover': {
            backgroundColor: `${colors.black} !important`,
            color: `${colors.white} !important`
        }
    },
    bank: { height: '30px', borderRadius: '3px' },
    columeHover: {
        backgroundColor: '',
        '&:hover': {
            backgroundColor: `#e5e5e5 !important`
        }
    },
    buttomDayactive: {},
    buttonClearCanvas: {
        position: 'absolute',
        top: '5%',
        left: '73%',
        color: '#fb5252',
        border: '1px solid #fb5252',
        borderRadius: '4px',
        fontSize: '16px',
        padding: '6px 17px 5px 15px',
        height: ' 32px'
    }
}))


export const ContainerSocial = styled(Box)(({ theme }) => ({
    paddingTop: '8px',
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
}))


export const CustomIconArrow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    '.icon-arrow': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: 'none',
    padding: '0 1rem',
    marginBottom: '1.5rem !important',
    '&:first-of-type': {
        marginTop: '1rem !important'
    },
    '&:before': {
        opacity: 0
    },
    '.header-text': {
        h5: {
            fontSize: 18,
            fontWeight: 500,
            '&.required::after': {
                color: colors.statusInactive
            }
        }
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        padding: '0 1.5rem',
        '&:first-of-type': {
            marginTop: '1.5rem !important'
        }


    }
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ backgroundColor: colors.white, border: `0.5px solid ${colors.textLightGray}`, borderRadius: '50px', color: colors.themeMainColor }} />} {...props} />
))(({ theme }) => ({
    height: 40,
    backgroundColor: colors.themeSecondColor10,
    '&.error': {
        backgroundColor: colors.statusInactive10
    },
    '.MuiAccordionSummary-content': {
        margin: 0
    }
}))


export default useStyles
