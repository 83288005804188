
import { useEffect, useState, useReducer } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectMe } from 'app/slice/user.slice'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import CollapseContainer from 'component/Collapse/container'
import SectionFilterPatient from 'features/home/filterPatient'
import SectionFilterDoctor from 'component/Home/filterDoctor'
import SectionAppointment from 'component/Appointment/GraphAppointment'
import CardAppointment from 'component/Appointment/CardAppointment'
import SectionServicePoints from 'features/home/sliderServicePoints'
import SendExamination from 'component/Register/SendExamination'
import ModalCustomNotification from 'component/ModalCustom/ModalCustomNotification'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** UTILS || SLICE */
import { getBranch, getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** API */
import { routeName } from 'routes/routes-name'
import HomeApi from 'api/home/home.api'
import BranchApi from 'api/setting/branch-service-points/branch.api'
import SendExaminationApi from 'api/master/sendExamination.api'

/** STYLE */
import * as UseStyled from 'features/home/UseStyled'
import CardAppointmentDetail from 'component/Appointment/CardAppointmentDetail'
import { AppointmentCardItemDayInterface } from 'features/counter/appointment/appointment-interface'
import AboutUs from 'api/admin/clinic-manage/about.api'

export default function Home() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const user = useSelector(selectMe)
  let countAp = 0
  let checkTimeStart = '';
  const [timeRange, setTimeRange] = useState<any>([])
  const [appointments, setAppointments] = useState<any>([])
  const [doctors, setDoctors] = useState<any>(null)
  const [temps, setTemps] = useState([])
  const [doctor, setDoctor]: any = useState({})
  const [hasCollapse, setHasCollapse] = useState(false)
  const [canViewIncome, setCanViewIncome] = useState(false)
  const [income, setIncome] = useState(0)
  const [servicePoints, setServicePoints] = useState([])
  const [sendExam, setSendExam] = useState(false)
  const [patientCase, setPatientCase] = useState<any | null>([])
  const [clinicIsClose, setClinicIsClose] = useState(false);
  const [viewDetails, setViewDetails] = useState([])
  const branchId = getBranch()
  const [branch, setBranch] = useState<any>()
  const [key, setkey] = useState({ nonUsedKey: 0 })

  const [dateHome, setDateHome] = useState<string>('')

  const loadBranchInfo = () => {
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        setBranch(data)
      })
      .catch((e) => { return })
  }

  useEffect(() => {
    loadBranchInfo()
  }, [branchId])

  const loadIncome = async () => {
    if (!_.isEmpty(user) && user.hasViewIncome === 'PERMISS') {
      const respIncome = await HomeApi.clinicIncome()

      if (respIncome.status === 200) {
        setIncome(respIncome.data || 0)
        setCanViewIncome(true)
      }
    } else {
      setIncome(0)
      setCanViewIncome(false)
    }
  }

  const loadListQueue = async () => {
    const queue = await HomeApi.listQueue()
    if (queue.status === 200 && !_.isEmpty(queue.data)) setServicePoints(queue.data)
    else setServicePoints([])
  }

  const loadAllAppointment = async () => {
    const respAppointments = await HomeApi.allAppointment()
    if (respAppointments.status === 200 && !_.isEmpty(respAppointments.data)) {
      setAppointments(respAppointments.data)
    }
    else setAppointments([])


  }

  const loadAllDentists = async () => {
    const selectDoc = doctor

    const dentists = await HomeApi.allDentists()
    setkey({ nonUsedKey: Date.now() })
    if (dentists.status === 200 && !_.isEmpty(dentists.data)) {
      setDoctors(dentists.data)
      setTemps(_.clone(dentists.data))


      if (_.isEmpty(doctor)) { return setDoctor(dentists.data[0]) }
      if (doctor) {

        const item = _.find(dentists.data, { doctorId: selectDoc.doctorId })
        if (item) setDoctor(item)
      }

    } else {
      setDoctor({})
      setDoctors([])
      setTemps([])
    }
  }

  const openVisitAppointment = async (id: number) => {
    const visit = await SendExaminationApi.homeOpenVisit(id)
    if (visit.status === 201 && !_.isEmpty(visit.data)) {
      setPatientCase(visit.data)
      setSendExam(true)
    } else setPatientCase([])
  }

  const onSendToRegister = (data: any) => {
    history.push({
      pathname: routeName.register,
      state: { newPatientInfo: data }
    })
  }

  const handleTimeBranch = async (branchId: number) => {
    if (branchId) {
      BranchApi.findById(branchId)
        .then(({ data }) => {
          const day = _.find(data.officeTimes, (time) => time.dayOfWeek === moment().locale('en').format('ddd'))
          if (day?.officeStatus === 'CLOSE') setClinicIsClose(true)
          let start: any = moment(day?.timeIn, 'HH:mm')
          let end: any = moment(day?.timeOut, 'HH:mm')
          if (parseInt(start.format('mm')) > 0) start = moment(data.timeIn, 'HH:mm').startOf('hour')
          if (parseInt(end.format('mm')) > 0) end = moment(data.timeOut, 'HH:mm').endOf('hour').add(1, 'minute')
          const timeEnd = parseInt(end.format('HH')) === 0 ? 24 : parseInt(end.format('HH'))
          const range: any = []
          for (let index = parseInt(start.format('HH')); index <= timeEnd; index++) {
            range.push(`${String(index).padStart(2, '0')}:00`)
          }
          if (!_.isEmpty(range)) setTimeRange(range)
          else setTimeRange(['9.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00'])
        })
    }
  }

  const handleSelectAppoinment = (val: any) => {
    countAp = 0
    checkTimeStart = ''
    if (val.appointmentId) {
      const temp = _.clone(temps)
      const dr: any = _.find(temp, { doctorId: val.doctorId })
      const tempDoctor: any = _.clone(dr)
      const apt = _.filter(tempDoctor.appointments, { appointmentId: Number(val.appointmentId) })
      setDoctor({ ...(dr || {}), ...{ appointments: apt } })
    } else {
      setDoctor(temps[0])
    }
  }

  const handleSelectDoctor = (event: any) => {
    countAp = 0
    checkTimeStart = ''
    const id = event.target.value || event.target.offsetParent.value || event.target.alt
    const item = _.find(temps, { doctorId: Number(id) })
    if (id && item) setDoctor(item)
  }

  const handleCollapse = (status: boolean) => {
    setHasCollapse(status)
  }

  const handleCallback = (value: any) => {
    if (value.key === 'openVisit') {
      setPatientCase(value.value)
      setSendExam(true)
    } else if (value.key === 'reloadListQueue' && value.value === 'success') {
      loadListQueue()
      handleClick()
    }
  }

  const dataAlert = useSelector((state: any) => state.modal.dataAlert)
  useEffect(() => {
    loadListQueue()
    loadAllAppointment()
    loadAllDentists()
  }, [dataAlert])

  useEffect(() => {
    loadIncome()
    handleTimeBranch(user?.roles?.roleBranch?.branchId || 0)
  }, [user])

  useEffect(() => {
    if (!sendExam) {
      handleCallback({ key: 'reloadListQueue', value: 'success' })
      loadAllDentists()
    }
  }, [sendExam])
  const handleViewDetail = (val: any) => {
    const temp: any = [...viewDetails]
    if (!temp.find((t: any) => t === val)) temp.push(val)
    setViewDetails(temp)
  }
  const handleCloseDetail = (val: any) => {
    const temp: any = [...viewDetails].filter((t: any) => t !== val)
    setViewDetails(temp)
  }

  const groupAppointmentByHour = (apt: any) => {
    const newApt: any = _.chain(apt)
      .map((i: any) => { return i })
      .groupBy('start')
      .map((value: any) => ({ data: value }))
      .value()

    return newApt
  }
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }

  const renderDateHome = () => {
    if (i18n.language === 'th') {
      setDateHome(`${t('TIME_DURATION_TYPE.DAY')}${moment().format('dddd')}${t('DAY_AT')} `)
    } else {
      setDateHome(`${moment().format('dddd')} `)
    }
  }

  useEffect(() => {
    renderDateHome()
  }, [i18n.language])

  return (
    <div className={'h-100 overflow-hidden'}>
      <CollapseContainer
        key={key.nonUsedKey}
        tooltipExpand={t('HOME.TOOLTIP.EXPAND_SCHEDULE')}
        tooltipShorten={t('HOME.TOOLTIP.SHORTEN_SCHEDULE')}
        styleComponentLeft={{ height: '100%' }}
        styleComponentRight={{ height: '100%' }}
        callBackStatusCollapse={handleCollapse}
        componentLeft={
          <div className={'schedule h-100 py-4 d-flex flex-column'}>
            <UseStyled.Container>
              <UseStyled.TypoHeader className={'text-ellipsis'}>
                <span className={'title-schedule pr-1'}>{t('HOME.TITLE.SCHEDULE')}</span>
                {`${dateHome}`}{toBuddhistYear(moment(), `D MMMM YYYY`)}
              </UseStyled.TypoHeader>
              <div className={'mb-3 mb-xl-4'}>
                <SectionFilterPatient
                  data={appointments}
                  onchange={(event, value: any) => {
                    if (value) handleSelectAppoinment(value)
                    else handleSelectAppoinment('')
                  }}
                  disabled={clinicIsClose}
                  branch={branch}
                />
              </div>
              <div className={'ml-4 mr-2'}>
                <SectionFilterDoctor doctors={doctors} key={key.nonUsedKey ?? 1} filterDoctor={doctor} onClick={(event) => handleSelectDoctor(event)} clinicIsClose={clinicIsClose}></SectionFilterDoctor>
              </div>
            </UseStyled.Container>

            <div className={'h-100 overflow-hidden d-flex flex-row'} key={key.nonUsedKey}>
              <div className={'graph-appointment'} style={{ width: 'calc(100% - 2rem)' }} key={key.nonUsedKey}>
                <SectionAppointment
                  timeRange={timeRange}
                  width={"100%"}
                  card={
                    _.map(groupAppointmentByHour(doctor.appointments), (time) => {
                      return _.map(time.data, (i, indexAppointments: number) => {
                        if (checkTimeStart !== i.start) {
                          checkTimeStart = i.start
                          countAp = 0
                        } else {
                          countAp = countAp + 1
                        }
                        const allRow = _.filter(time.data, (list: AppointmentCardItemDayInterface) => list.doctorId === i.doctorId).length
                        const conDiv = document.querySelector(".container-schedule")

                        const handlePatientCode = () => {

                          if (branch?.patientCodeAppointment === '1') {

                            return i.cnNumber
                          }
                          if (branch?.patientCodeAppointment === '0') {
                            return i.branchCnNumber
                          }
                          if (branch?.patientCodeAppointment === '2') {

                            return i.snCode
                          }
                        }

                        return (
                          !_.isEmpty(timeRange) &&
                          (<div key={`${key.nonUsedKey}`}>
                            <CardAppointment
                              appointmentId={i.appointmentId}
                              key={indexAppointments}
                              serviceText={`${_.map(i.appointmentOperatives, (operative: any, indexOperative: number) => {
                                return `${operative.operativeId ? operative.operativeName : operative.operativeTypeName} `
                              })}`}
                              rangeTimeText={i.rangeTimeText}
                              colorBackground={i.appointmentOperatives.length === 1 ? i.appointmentOperatives[0].color : colors.white}
                              status={i.statusAppointment}
                              branchCnNumber={handlePatientCode() ? handlePatientCode() : ''}
                              patientFullName={i.patientFullname}
                              start={i.start || i.rangeTimeText.split(' - ')[0].replace('.', ':')}
                              end={i.end || i.rangeTimeText.split(' - ')[1].replace('.', ':')}
                              hasLab={i.hasLab === 'HAS'}
                              hasXray={i.hasXray === 'HAS'}
                              timeStart={timeRange[0]}
                              onViewDetail={() => {
                                if (!i.cnNumber || !i.branchCnNumber) onSendToRegister(i)
                                else if (i.statusAppointment === "DONE") handleViewDetail(i.appointmentId)
                                else openVisitAppointment(i.appointmentId)
                              }}
                              specialInfo={i?.specialInfo}
                              width={((conDiv?.clientWidth || 394) / allRow)}
                              translateX={((conDiv?.clientWidth || 394) / allRow) * countAp}
                              phone={i?.phone}
                              allRow={allRow >= 4}
                            />
                          </div>
                          )
                        )
                      })
                    })
                  }
                  popup={
                    _.map(doctor.appointments, (ap: AppointmentCardItemDayInterface, j: number) => {
                      return (
                        <CardAppointmentDetail
                          key={j + `key${key.nonUsedKey}`}
                          timeStart={timeRange[0]}
                          timeEnd={timeRange[timeRange.length - 1]}
                          appointmentId={ap.appointmentId}
                          showPopup={_.find(viewDetails, (v: any) => v === ap.appointmentId) ? true : false}
                          onClose={handleCloseDetail}
                          onEdit={undefined}
                          hideMenu={true}
                          onEditSpecialInfo={undefined}
                          status={ap.statusAppointment}
                          branch={branch} />
                      )
                    })
                  }
                  showMinute
                />
              </div>
            </div>
          </div>
        }
        componentRight={
          <div className={'service-points h-100 pb-4 d-flex flex-column'} >
            <UseStyled.Container>
              <UseStyled.Header className={'d-flex align-items-center'}>
                <UseStyled.TypoHeader className={'text-ellipsis mb-0'}>{t('HOME.TITLE.SERVICE_POINTS')}</UseStyled.TypoHeader>
                {canViewIncome && (
                  <UseStyled.Income>
                    <span className={'pr-1'}>{t('HOME.TITLE.INCOME')}</span>
                    <span className={'ml-auto'}>{numberFormat(income)}</span>
                    <span className={'pl-1'}>{t('HOME.TITLE.CURRENCY')}</span>
                  </UseStyled.Income>
                )}
              </UseStyled.Header>
            </UseStyled.Container>
            {/* overflow-hidden */}
            <UseStyled.Container className={'pr-0 h-100 '} style={{ position: 'relative' }}>
              <SectionServicePoints
                branch={branch}
                service={servicePoints}
                hasCollapse={hasCollapse}
                setCancelCase={handleCallback}
                setPatientCase={handleCallback}
                reRender={() => {
                  loadListQueue()
                  loadAllAppointment()
                  loadAllDentists()

                }}
              />
            </UseStyled.Container>
          </div >
        }
      ></CollapseContainer >
      {sendExam && <SendExamination isShow={sendExam} setIsShow={(show: boolean) => setSendExam(show)} patient={patientCase} type={'home'} service={servicePoints} />}

      {/* <div style={{ display: "none" }}>
        <ModalCustomNotification />
      </div> */}

    </div >
  )
}
