import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/48.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  margin: '0 4px auto 0',
  top: '1.4%'
}))
export default function Teeth48(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: "36.936", height: "67.495", viewBox: "0 0 36.936 67.495" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <TextNumberL>48</TextNumberL>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <DivTeethL className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`lg teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`lg ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9184">

              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5244"
                d="M106.666 566.687c-1.266-3-1.213-6.287-2.177-9.47-1.972 2.632-2.456 6.072-4.268 8.58a2.927 2.927 0 0 1-5.146-.608 10.847 10.847 0 0 1-.518-2.211 179 179 0 0 1-2.271-18.178c-.406-8.786-3.168-16.816-6.542-24.753-1.546-3.636-3.049-7.957-.253-10.964 3.426-3.684 8.013-6.095 13.5-2.714 2.418 1.491 4.891 1.2 7.284-1.061 3.468-3.282 6.968-1.728 10 1.031 1.571 1.429 2.889 3.136 4.588 5.012-3.348 15.89-.072 32.8-5.624 48.727-.964 2.766-1.72 5.762-4.091 7.737a2.891 2.891 0 0 1-4.482-1.128z"
                transform="translate(-83.926 -500.97)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <>
                  <path
                    data-name="Path 6494"
                    d="M106.666 566.687c-1.266-3-1.213-6.287-2.177-9.47-1.972 2.632-2.456 6.072-4.268 8.58a2.927 2.927 0 0 1-5.146-.608 10.847 10.847 0 0 1-.518-2.211 179 179 0 0 1-2.271-18.178c-.406-8.786-3.168-16.816-6.542-24.753a19.165 19.165 0 0 1-1.758-5.832c.331.8.074 4.157 4.145 4.057 2.656-.134 1.8-.306 12.719 0a31.18 31.18 0 0 0 9.312-.7 43.308 43.308 0 0 0 6.61-2.157c2.7-1.437 3.672-2.125 4.091-4.068-3.348 15.89-.072 32.8-5.624 48.727-.964 2.766-1.72 5.762-4.091 7.737a2.891 2.891 0 0 1-4.482-1.124z"
                    transform="translate(-83.926 -500.97)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                  />

                  <path
                    data-name="Path 5245"
                    d="M120.773 509.239c-3.927 7.394-19.077 7.281-22.49 2.807 3.21-5.762 6.318-2.606 9.218.2 4.394-.711 3.951-8.565 9.978-6.35a4.5 4.5 0 0 1 3.294 3.343z"
                    transform="translate(-89.03 -501.696)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                  />
                  <path
                    data-name="Path 5246"
                    d="M122.963 510.331c-.409-3.271-2.588-5.009-5.491-5.75-2.876-.734-5.042.7-6.408 3.185-1.2 2.194-2.751 2.038-4.532.947a9.568 9.568 0 0 0-6.449-1.6"
                    transform="translate(-89.67 -501.3)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                  /></>
                : ''}

              <g data-name="Group 5040">
                <path
                  data-name="Path 5247"
                  d="M120.779 509.186a1.129 1.129 0 0 0-.027-.15.225.225 0 0 1-.014-.064c-.2-.865-.548-1.429-.568-1.68-3.458-7.954-11.286-10.038-16.321-4.867-2.994 3.076-5.222-.706-8.633-.617-8.121.213-10.727 5.8-10.762 5.893a8.6 8.6 0 0 0-.431 2.092 15.734 15.734 0 0 0-.095 1.636c-.047 2.9.752 6.024 5.048 5.409a.915.915 0 0 0 .2-.021c.447-.072 1.99-.086 3.972-.064 3.8.036 9.229.179 11.6.172a34.657 34.657 0 0 0 11.679-2.737c2.585-1.157 4.493-2.623 4.439-4.281v-.035a5.271 5.271 0 0 0-.087-.686z"
                  transform="translate(-83.926 -499.595)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5248"
                  d="M119.784 509.946a.988.988 0 0 0-.024-.135.218.218 0 0 1-.012-.059c-.177-.782-.5-1.292-.514-1.518-3.127-7.193-10.207-9.078-14.761-4.4-2.708 2.781-4.722-.639-7.808-.558-7.344.193-9.7 5.244-9.733 5.329a7.767 7.767 0 0 0-.389 1.892 14.236 14.236 0 0 0-.086 1.48c-.043 2.624.679 5.448 4.565 4.892a.836.836 0 0 0 .178-.019c.4-.065 1.8-.078 3.592-.059 3.439.032 8.347.162 10.489.155a31.339 31.339 0 0 0 10.562-2.476c2.338-1.047 4.064-2.372 4.015-3.871v-.032a4.761 4.761 0 0 0-.074-.621z"
                  transform="translate(-84.825 -500.192)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5249"
                  d="M121.22 511.888a.729.729 0 0 0-.019-.1.145.145 0 0 1-.009-.043c-.133-.586-.371-.968-.385-1.137-2.342-5.387-7.645-6.8-11.055-3.3-2.028 2.082-3.537-.479-5.848-.418-5.5.144-7.266 3.928-7.289 3.991a5.8 5.8 0 0 0-.292 1.417 10.617 10.617 0 0 0-.064 1.108c-.032 1.965.509 4.08 3.419 3.665a.638.638 0 0 0 .133-.015c.3-.048 1.347-.058 2.69-.044 2.575.024 6.252.121 7.855.117a23.467 23.467 0 0 0 7.911-1.854c1.751-.784 3.043-1.776 3.007-2.9v-.024a3.51 3.51 0 0 0-.054-.463z"
                  transform="translate(-88.31 -501.656)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />
              </g>

              {(isToothRoot && props.type === 'treatment') ?
                <>
                  <path
                    data-name="Path 6551"
                    d="M3.29 10.83a125.19 125.19 0 0 0 .671 16.078C3.893 40.6 5.033 56.509 5.735 57.38c-.08.705-2.144-41.35-.9-46.551-1.296-.044-.248.047-1.545.001z"
                    transform="translate(8.261 6.841)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  <path data-name="Path 6552" d="M8.4 11.942c1.373 11.595-2.419 46.783-3.972 48.71.08.705 4.774-43.8 2.643-48.333 2.629-.535-1.143.381 1.329-.377z" transform="translate(21.153 4.306)" style={{

                    fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                    stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                  }} />
                </> : ''}

              {(isExtract && props.type === 'treatment') ?
                <>
                  <g data-name="Group 9465">
                    <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-.635 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-.635 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                  </g>
                </>
                : ''}

              {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') && (
                <>
                  <defs>
                    <linearGradient id="396owb889a" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#b0b1b1" />
                      <stop offset={1} stopColor="#585959" />
                    </linearGradient>
                    <linearGradient id="ib7ymcbdkb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#c1c4c5" />
                      <stop offset={1} stopColor="#9e9e9e" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 9371">
                    <path data-name="Path 5244" d="M106.666 566.687c-1.266-3-1.213-6.287-2.177-9.47-1.972 2.632-2.456 6.072-4.268 8.58a2.927 2.927 0 0 1-5.146-.608 10.847 10.847 0 0 1-.518-2.211 179 179 0 0 1-2.271-18.178c-.406-8.786-3.168-16.816-6.542-24.753-1.546-3.636-3.049-7.957-.253-10.964 3.426-3.684 8.013-6.095 13.5-2.714 2.418 1.491 4.891 1.2 7.284-1.061 3.468-3.282 6.968-1.728 10 1.031 1.571 1.429 2.889 3.136 4.588 5.012-3.348 15.89-.072 32.8-5.624 48.727-.964 2.766-1.72 5.762-4.091 7.737a2.891 2.891 0 0 1-4.482-1.128z" transform="translate(-83.676 -500.97)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <path data-name="Path 5245" d="M120.773 509.239c-3.927 7.394-19.077 7.281-22.49 2.807 3.21-5.762 6.318-2.606 9.218.2 4.394-.711 3.951-8.565 9.978-6.35a4.5 4.5 0 0 1 3.294 3.343z" transform="translate(-88.78 -501.696)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <path data-name="Path 5246" d="M122.963 510.331c-.409-3.271-2.588-5.009-5.491-5.75-2.876-.734-5.042.7-6.408 3.185-1.2 2.194-2.751 2.038-4.532.947a9.568 9.568 0 0 0-6.449-1.6" transform="translate(-89.42 -501.3)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <g data-name="Group 5040">
                      <path data-name="Path 5247" d="M120.779 509.186a1.129 1.129 0 0 0-.027-.15.225.225 0 0 1-.014-.064c-.2-.865-.548-1.429-.568-1.68-3.458-7.954-11.286-10.038-16.321-4.867-2.994 3.076-5.222-.706-8.633-.617-8.121.213-10.727 5.8-10.762 5.893a8.6 8.6 0 0 0-.431 2.092 15.734 15.734 0 0 0-.095 1.636c-.047 2.9.752 6.024 5.048 5.409a.915.915 0 0 0 .2-.021c.447-.072 1.99-.086 3.972-.064 3.8.036 9.229.179 11.6.172a34.657 34.657 0 0 0 11.679-2.737c2.585-1.157 4.493-2.623 4.439-4.281v-.035a5.271 5.271 0 0 0-.087-.686z" transform="translate(-83.676 -499.595)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5248" d="M119.784 509.946a.988.988 0 0 0-.024-.135.218.218 0 0 1-.012-.059c-.177-.782-.5-1.292-.514-1.518-3.127-7.193-10.207-9.078-14.761-4.4-2.708 2.781-4.722-.639-7.808-.558-7.344.193-9.7 5.244-9.733 5.329a7.767 7.767 0 0 0-.389 1.892 14.236 14.236 0 0 0-.086 1.48c-.043 2.624.679 5.448 4.565 4.892a.836.836 0 0 0 .178-.019c.4-.065 1.8-.078 3.592-.059 3.439.032 8.347.162 10.489.155a31.339 31.339 0 0 0 10.562-2.476c2.338-1.047 4.064-2.372 4.015-3.871v-.032a4.761 4.761 0 0 0-.074-.621z" transform="translate(-84.575 -500.192)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5249" d="M121.22 511.888a.729.729 0 0 0-.019-.1.145.145 0 0 1-.009-.043c-.133-.586-.371-.968-.385-1.137-2.342-5.387-7.645-6.8-11.055-3.3-2.028 2.082-3.537-.479-5.848-.418-5.5.144-7.266 3.928-7.289 3.991a5.8 5.8 0 0 0-.292 1.417 10.617 10.617 0 0 0-.064 1.108c-.032 1.965.509 4.08 3.419 3.665a.638.638 0 0 0 .133-.015c.3-.048 1.347-.058 2.69-.044 2.575.024 6.252.121 7.855.117a23.467 23.467 0 0 0 7.911-1.854c1.751-.784 3.043-1.776 3.007-2.9v-.024a3.51 3.51 0 0 0-.054-.463z" transform="translate(-88.06 -501.656)" style={{ fill: colors.white }} />
                    </g>
                    <g data-name="Group 9393">
                      <g data-name="Group 9394" transform="translate(12.377 5.637)">
                        <g data-name="Rectangle 3927" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                          <rect width="11.602" height="11.602" rx={2} style={{ stroke: 'none' }} />
                          <rect x=".5" y=".5" width="10.602" height="10.602" rx="1.5" style={{ fill: 'none' }} />
                        </g>
                        <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(-6.653 -6.464)" style={{ fill: '#b0b1b1' }} />
                        <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(3.289 7.215)" style={{ fill: '#cbcccc' }} />
                        <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(3.089 3.015)" style={{ fill: '#9e9e9e' }} />
                        <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(3.289 3.015)" style={{ fill: 'url(#396owb889a)' }} />
                        <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(3.289 7.015)" style={{ fill: 'url(#ib7ymcbdkb)' }} />
                      </g>
                      <path data-name="Path 6488" d="M1.6 9.574c6.917.581 13.917 5.556 36.129-1.115" transform="translate(-1.054 .344)" style={{ strokeLinecap: 'round', stroke: '#b5b7b7', fill: 'none' }} />
                      <path data-name="Path 6486" d="M1.915 9.64c9.759 2.058 16.908 4.5 35.9-1.147" transform="translate(-1.24 -.347)" style={{ stroke: '#babbbb', strokeLinecap: 'round', fill: 'none' }} />
                    </g>
                  </g>
                </>
              ) || ""}

              {(isFixture && props.type === 'treatment') ? (
                <>
                  <defs>
                    <linearGradient id="jyalp6ofka" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#e5e5e5" />
                      <stop offset={1} stopColor="#b4b5b5" />
                    </linearGradient>
                    <linearGradient id="hv0iu634kb" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#e5e5e5" />
                      <stop offset={1} stopColor="#b0b1b1" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 9512">
                    <path data-name="Path 5245" d="M120.773 509.239c-3.927 7.394-19.077 7.281-22.49 2.807 3.21-5.762 6.318-2.606 9.218.2 4.394-.711 3.951-8.565 9.978-6.35a4.5 4.5 0 0 1 3.294 3.343z" transform="translate(-88.031 -499.812)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <path data-name="Path 5246" d="M122.963 510.331c-.409-3.271-2.588-5.009-5.491-5.75-2.876-.734-5.042.7-6.408 3.185-1.2 2.194-2.751 2.038-4.532.947a9.568 9.568 0 0 0-6.449-1.6" transform="translate(-88.671 -499.416)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <g data-name="Group 5040">
                      <path data-name="Path 5247" d="M120.779 509.186a1.129 1.129 0 0 0-.027-.15.225.225 0 0 1-.014-.064c-.2-.865-.548-1.429-.568-1.68-3.458-7.954-11.286-10.038-16.321-4.867-2.994 3.076-5.222-.706-8.633-.617-8.121.213-10.727 5.8-10.762 5.893a8.6 8.6 0 0 0-.431 2.092 15.734 15.734 0 0 0-.095 1.636c-.047 2.9-.07 7.854 4.227 7.239.068 0 .836-.821.9-.835a13.95 13.95 0 0 1 4.007-.735c3.8.036 9.309-.165 11.677-.173 2.605 0 7.935-.274 11.745-1.975 2.585-1.157 4.493 1.192 4.439-.467.219 2.163-.103-5.083-.144-5.297z" transform="rotate(6.02 4790.357 -528.657)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5248" d="M119.784 509.946a.988.988 0 0 0-.024-.135.218.218 0 0 1-.012-.059c-.177-.782-.5-1.292-.514-1.518-3.127-7.193-10.207-9.078-14.761-4.4-2.708 2.781-4.722-.639-7.808-.558-7.344.193-9.7 5.244-9.733 5.329a7.767 7.767 0 0 0-.389 1.892 14.236 14.236 0 0 0-.086 1.48c-.043 2.624-.257 7.122 3.629 6.567.061 0 .993-.534 1.054-.547a16.841 16.841 0 0 1 3.532-.371c3.439.032 8.467-.672 10.608-.678 2.356 0 7.1.215 10.543-1.324 2.338-1.047 4.5.493 4.451-1.006.337 3.298-.453-4.478-.49-4.672z" transform="rotate(6.02 4796.48 -536.901)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5249" d="M121.22 511.888a.729.729 0 0 0-.019-.1.145.145 0 0 1-.009-.043c-.133-.586-.371-.968-.385-1.137-2.342-5.387-7.645-6.8-11.055-3.3-2.028 2.082-3.537-.479-5.848-.418-5.5.144-7.266 3.928-7.289 3.991a5.8 5.8 0 0 0-.292 1.417 10.617 10.617 0 0 0-.064 1.108c-.032 1.965.509 4.08 3.419 3.665a.638.638 0 0 0 .133-.015c.3-.048 1.347-.058 2.69-.044 2.575.024 6.252.121 7.855.117a23.467 23.467 0 0 0 7.911-1.854c1.751-.784 3.043-1.776 3.007-2.9v-.024a3.51 3.51 0 0 0-.054-.463z" transform="rotate(6.02 4812.134 -569.285)" style={{ fill: colors.white }} />
                    </g>
                    <g data-name="Group 9517">
                      <g data-name="Path 6490" transform="translate(9.928 23.149)" style={{ fill: 'url(#jyalp6ofka)' }}>
                        <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                        <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <g data-name="Path 6489" transform="translate(1.256 17.946)" style={{ strokeLinejoin: 'round', fill: 'url(#hv0iu634kb)' }}>
                        <path d="M1.413 7.569c-.083 0-.092 0-.17-.527C1.14 6.349.926 4.907.1 1.9.107.907.837.1 1.734.1h32.951c.898 0 1.63.808 1.634 1.803-.437 2.007-.666 3.304-.818 4.163-.264 1.491-.271 1.491-.626 1.491h-.083l-.107-.001H1.735c-.09 0-.163.004-.221.008-.04.003-.074.005-.1.005z" style={{ stroke: 'none' }} />
                        <path d="M1.734.2C.896.2.213.955.2 1.888c.825 3.003 1.039 4.445 1.142 5.14.03.195.055.368.078.44.023 0 .053-.001.087-.004.06-.003.135-.008.227-.008h32.951l.19.001c.199 0 .205-.015.248-.11.082-.184.152-.58.28-1.299.15-.857.38-2.152.816-4.155C36.21.958 35.525.2 34.685.2H1.735m0-.2h32.95c.958 0 1.734.857 1.734 1.914-1.304 5.981-.776 5.742-1.734 5.742H1.735c-.959 0 0 .568-1.735-5.742C0 .857.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(4.417 8.285)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(4.704 13.484)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(5.152 36.011)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(4.96 18.027)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(4.96 24.027)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(4.96 30.027)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(5.151 41.649)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                    </g>
                  </g>
                </>
              ) : ""}
            </g>
          </svg>}
      </DivTeethL>
    </Div>
  )
}
