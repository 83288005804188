import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  titleSecond: {
    fontSize: '16px'
  },
  hr: {
    width: '100%'
  },
  bgMsg: {
    padding: '0 8px 1px',
    borderRadius: '8px',
    border: `solid 1px ${colors.themeMainColor}`,
    backgroundColor: colors.themeMainColor,
    color: colors.white
  }
}))

export default useStyles
