import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface DoctorScheduleOperativeInterface {
  appointmentOperativeId: number
  appointmentId: number
  operativeTypeId: number
  operativeTypeName: string
  operativeId: number
  operativeName: string
  color: string
  createdBy: string
  createdAt: Date
}

export interface DoctorScheduleInterface {
  appointmentId: number
  appointmentNumber: string
  branchId: number
  patientId: number
  patientFullname: string
  cnNumber: string
  vnNumber: string
  phone: string
  date: string
  timeStart: string
  durationText: string
  durationMinute: number
  doctorId: number
  doctorFullname: string
  practice: string
  price: number
  hasLab: string
  hasXray: string
  remark: string
  remarkCancel: string
  statusDoctorSchedule: string
  status: string
  updatedBy: string
  updatedAt: string
  createdBy: string
  createdAt: string
  appointmentOperatives?: DoctorScheduleOperativeInterface[]
}

export interface DoctorScheduleMonthItemInterface {
  doctorFullname: string
  email: string
  avatar: string
  startWork: string
  getOffWork: string
  timeWorking: string
  countAppoints: number
  doctorScheduleId: number
  branchId: number
  date: string
  day: string
  doctorId: number
  timeStart: string
  timeEnd: string
  durationHour: number
  handInsurance: number
  statusDoctorSchedule: string
  status: string
  updatedBy: string
  updatedAt: string
  createdBy: string
  createdAt: string
  doctorScheduleAssistants: []
  userTags?: DoctorScheduleTags[]
}

export interface DoctorScheduleTags {
  color: string
  tagName: string
  tagNameEn?: string
  userId?: number
}

export interface DoctorScheduleMonthListInterface {
  date: string
  schedule: DoctorScheduleMonthItemInterface[]
}
const path = 'doctor-schedules'

interface ParamFilterProps {
  branchId: number
}
interface HandInsurancesParamFilterProps {
  hour: number
}
interface MonthFilterProps {
  branchId: number
  month: string
  year: string
}
interface SendMailProps {
  type: 'IMAGE' | 'MAIL'
  branchId: number
  date: string
  doctorId: string
  base64: string
  filename: string
}

export interface SchedulesAllProps {
  branchId: number
  date: string
  doctorId: number | undefined
}

export default class DoctorScheduleApi extends BaseAPI {
  static findAll(props: FindAllInterface | SchedulesAllProps): Promise<any> {
    return this.api.get(`${path}`, { params: { ...props } })
  }

  static findAllDoctor(props: ParamFilterProps): Promise<any> {
    return this.api.get(`${path}/dentists`, { params: { ...props } })
  }
  static findHandInsurances(props: HandInsurancesParamFilterProps): Promise<any> {
    return this.api.get(`${path}/hand-insurances`, { params: { ...props } })
  }

  static findAllHandInsurances(): Promise<any> {
    return this.api.get(`${path}/hand-insurances-all`)
  }


  static findAllAssistants(props: ParamFilterProps): Promise<any> {
    return this.api.get(`${path}/assistants`, { params: { ...props } })
  }

  static findAllMonthSchedule(props: any): Promise<any> {
    return this.api.get(`${path}/history-schedule-month`, { params: { ...props } })
  }

  static getCopySchedule(props: any): Promise<any> {
    return this.api.get(`${path}/copy-schedule`, { params: { ...props } })
  }

  static findDentistByMonths(props: MonthFilterProps): Promise<any> {
    return this.api.get(`${path}/month/dentists`, { params: { ...props } })
  }

  static create(payload: any) {
    return this.api.post(`${path}`, payload)
  }

  static send(payload: SendMailProps): Promise<any> {
    return this.api.post(`${path}/send-doctor-schedule`, payload)
  }

  static doctorsOnDay(props: any): Promise<any> {
    return this.api.get(`${path}/doctors-days`, { params: { ...props } })
  }
}
