import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/61.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth61(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 38.26, height: 100, viewBox: "0 0 38.26 110" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 38.26, height: 100, viewBox: "0 0 38.26 110" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={width > 1440 ? 70 : 100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12341" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5436"
                                d="M1.7681 71.7428C0.273297 76.175 0.673744 80.9634 0.702993 83.729C0.702993 97.0391 0.685288 97.3067 12.4028 98.2406C16.6978 98.5835 21.1647 97.7807 25.4018 96.69C30.3153 95.42 33.757 87.9833 32.5774 81.3341C28.9806 60.9907 27.869 40.1872 24.6055 19.7829C23.9011 15.357 24.3184 10.6782 24.2157 6.11474C24.1695 4.05573 24.3529 1.77197 22.1201 1.12694C19.8402 0.468445 18.5579 2.13528 17.608 4.3107C14.6253 11.1585 12.8665 18.5069 11.5657 26.0192C8.90284 41.4434 6.72684 57.0112 1.7681 71.7428Z"
                                transform={"translate(2 0)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5436"
                                    d="M1.7681 71.7428C0.273297 76.175 0.673744 80.9634 0.702993 83.729C0.702993 97.0391 0.685288 97.3067 12.4028 98.2406C16.6978 98.5835 21.1647 97.7807 25.4018 96.69C30.3153 95.42 33.757 87.9833 32.5774 81.3341C28.9806 60.9907 27.869 40.1872 24.6055 19.7829C23.9011 15.357 24.3184 10.6782 24.2157 6.11474C24.1695 4.05573 24.3529 1.77197 22.1201 1.12694C19.8402 0.468445 18.5579 2.13528 17.608 4.3107C14.6253 11.1585 12.8665 18.5069 11.5657 26.0192C8.90284 41.4434 6.72684 57.0112 1.7681 71.7428Z"
                                    transform={"translate(2 0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12341" >
                                <path
                                    data-name="Path 5437"
                                    d="M20.0846 51.9805C24.8499 51.5082 31.1591 53.1928 35.4247 47.5367C39.3093 42.3832 38.0293 36.566 37.375 31.2201C36.2451 22.0056 33.5072 13.0932 27.3398 5.43115C22.6533 -0.390448 17.7513 -0.714886 13.7586 5.40806C6.87805 15.9598 0.437793 26.8256 0.855175 39.7913C1.15113 49.0042 4.64317 51.8777 14.5481 51.9787C16.0687 51.9945 17.5901 51.9805 20.0846 51.9805Z"
                                    transform={"translate(0 50)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5438"
                                    d="M21.5519 50.6816C26.1895 50.2482 32.328 51.7954 36.4791 46.6021C40.2602 41.8721 39.0142 36.5326 38.3763 31.6262C37.2775 23.1683 34.6129 14.9871 28.6114 7.95588C24.0508 2.61189 19.2806 2.31497 15.3951 7.93452C8.69967 17.6188 2.43317 27.5926 2.83901 39.4931C3.12765 47.9491 6.5252 50.5871 16.1633 50.6791C17.644 50.6943 19.1248 50.6816 21.5519 50.6816Z"
                                    transform={"translate(0 50)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5439"
                                    d="M21.2496 50.0769C25.3628 49.6694 30.8089 51.1243 34.4905 46.241C37.8436 41.7928 36.7387 36.7723 36.1733 32.1574C35.1985 24.2039 32.8345 16.5099 27.5116 9.89688C23.4666 4.87098 19.2346 4.59176 15.7876 9.87763C9.84903 18.985 4.28971 28.3642 4.64936 39.5558C4.90549 47.5086 7.92067 49.9886 16.47 50.0756C17.7833 50.0885 19.0965 50.0769 21.2496 50.0769Z"
                                    transform={"translate(0 50)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6539"
                                        d="M2.53125 45.622C2.93392 42.582 3.50138 34.3351 3.40645 31.8833L3.40218 31.8577C3.07312 27.7895 5.77338 3.08692 5.99792 2.06719C6.27312 1.20692 3.38245 27.79 3.40592 32.0263C3.35845 38.3575 2.88325 45.6855 2.53125 45.622Z"
                                        transform="translate(17 6)"
                                        style={{
                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154151" x1="20.4825" y1="74.7759" x2="18.2984" y2="87.2425" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154151" x1="20.4825" y1="83.521" x2="20.4825" y2="78.8973" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9384">
                                            <path transform={"translate(1 5)"} d="M5.41314 69.2483C3.96396 73.5453 4.35218 78.1876 4.38054 80.8688C4.38054 93.7727 4.36337 94.0322 15.7233 94.9375C19.8872 95.27 24.2178 94.4917 28.3256 93.4343C33.0892 92.203 36.4258 84.9933 35.2822 78.5469C31.7952 58.8244 30.7175 38.6558 27.5536 18.8741C26.8707 14.5833 27.2753 10.0473 27.1757 5.62312C27.1309 3.62695 27.3087 1.41288 25.1441 0.787541C22.9337 0.14914 21.6905 1.7651 20.7697 3.87414C17.878 10.513 16.1729 17.6371 14.9117 24.9201C12.3301 39.8737 10.2205 54.9664 5.41314 69.2483Z" fill="#E3D1B4" />
                                            <path transform={"translate(1 5)"} d="M19.294 96.625C23.9139 96.1671 30.0306 97.8003 34.166 92.3168C37.9321 87.3206 36.6911 81.6809 36.0568 76.4982C34.9613 67.5648 32.307 58.9244 26.3278 51.4962C21.7844 45.8523 17.032 45.5377 13.1611 51.4738C6.49054 61.7036 0.246819 72.2377 0.651463 84.8078C0.938389 93.7395 4.32386 96.5253 13.9266 96.6233C15.4007 96.6386 16.8757 96.625 19.294 96.625Z" fill="#EFEDE7" />
                                            <path transform={"translate(1 5)"} d="M20.7185 95.3642C25.2146 94.9441 31.1658 96.444 35.1902 91.4092C38.8559 86.8236 37.6479 81.647 37.0295 76.8903C35.9642 68.6905 33.381 60.759 27.5626 53.9424C23.1412 48.7615 18.5166 48.4736 14.7496 53.9217C8.25852 63.3104 2.18327 72.9799 2.57672 84.5172C2.85655 92.7151 6.15042 95.2726 15.4944 95.3618C16.9299 95.3765 18.3655 95.3642 20.7185 95.3642Z" fill="#F7F7F7" />
                                            <path transform={"translate(1 5)"} d="M20.4221 94.7793C24.4098 94.3842 29.6898 95.7947 33.259 91.0604C36.5098 86.748 35.4386 81.8807 34.8905 77.4066C33.9454 69.6958 31.6535 62.2367 26.4931 55.8254C22.5715 50.9529 18.4687 50.6822 15.1269 55.8068C9.36953 64.6362 3.97987 73.7292 4.32854 84.5792C4.57685 92.2893 7.50002 94.6936 15.7884 94.778C17.0617 94.7905 18.3348 94.7793 20.4221 94.7793Z" fill="white" />
                                            <path transform={"translate(1 5)"} d="M25.414 71H15.586C14.1578 71 13 72.1578 13 73.586V83.414C13 84.8422 14.1578 86 15.586 86H25.414C26.8422 86 28 84.8422 28 83.414V73.586C28 72.1578 26.8422 71 25.414 71Z" fill="#C1C4C5" />
                                            {/* <path transform={"translate(1 5)"} d="M25.416 71.6465H15.5879C14.5168 71.6465 13.6484 72.5148 13.6484 73.586V83.414C13.6484 84.4852 14.5168 85.3535 15.5879 85.3535H25.416C26.4871 85.3535 27.3554 84.4852 27.3554 83.414V73.586C27.3554 72.5148 26.4871 71.6465 25.416 71.6465Z" stroke="#C1C4C5" /> */}
                                            <path transform={"translate(1 5)"} d="M19.3667 85.3314C19.1081 85.7995 21.5338 85.568 23.353 85.5447C26.0605 85.4878 27.6057 86.0413 27.5798 79.7909C27.6501 77.573 27.5814 75.353 27.3742 73.1437C26.6876 72.4765 27.5552 79.9138 25.7981 83.185C24.1405 85.5473 19.8102 84.8297 19.3667 85.3314Z" fill="#B0B1B1" />
                                            <path transform={"translate(1 5)"} d="M23.0685 79.0352H17.8965C17.5394 79.0352 17.25 79.3246 17.25 79.6817V80.9746C17.25 81.3317 17.5394 81.6211 17.8965 81.6211H23.0685C23.4255 81.6211 23.715 81.3317 23.715 80.9746V79.6817C23.715 79.3246 23.4255 79.0352 23.0685 79.0352Z" fill="#CBCCCC" />
                                            <path transform={"translate(1 5)"} d="M22.8107 73.6045H17.6387C17.2816 73.6045 16.9922 73.8939 16.9922 74.251V75.544C16.9922 75.901 17.2816 76.1905 17.6387 76.1905H22.8107C23.1677 76.1905 23.4571 75.901 23.4571 75.544V74.251C23.4571 73.8939 23.1677 73.6045 22.8107 73.6045Z" fill="#9E9E9E" />
                                            <path transform={"translate(1 5)"} d="M23.0685 73.6045H17.8965C17.5394 73.6045 17.25 73.8939 17.25 74.251V75.544C17.25 75.901 17.5394 76.1905 17.8965 76.1905H23.0685C23.4255 76.1905 23.715 75.901 23.715 75.544V74.251C23.715 73.8939 23.4255 73.6045 23.0685 73.6045Z" fill="url(#paint0_linear_4126_154151)" />
                                            <path transform={"translate(1 5)"} d="M23.0685 78.7783H17.8965C17.5394 78.7783 17.25 79.0678 17.25 79.4248V80.7178C17.25 81.0749 17.5394 81.3643 17.8965 81.3643H23.0685C23.4255 81.3643 23.715 81.0749 23.715 80.7178V79.4248C23.715 79.0678 23.4255 78.7783 23.0685 78.7783Z" fill="url(#paint1_linear_4126_154151)" />
                                            <path transform={"translate(1 5)"} d="M0.628906 80.2787C0.628906 80.2787 16.2751 75.3071 36.842 79.247" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={"translate(1 5)"} d="M0.628906 80.7885C12.412 77.7935 24.8589 77.4303 36.842 79.7318" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>61</TextNumberU>
        </Div >
    )
}
