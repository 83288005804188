import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
import { getBranch } from 'utils/app.utils'

export interface DoctorFeeOperatives {
  operativeId: number
  dfAmount: number
  cfAmount: number
  dfUnit: "PERCENT" | "BAHT"
  labDoctorAmount: number
  labClinicAmount: number
}
export interface DoctorFeeOperativeTypes {
  operativeTypeId: number
  dfAmount: number
  cfAmount: number
  labDoctorAmount: number
  labClinicAmount: number
  doctorFeeOperatives: DoctorFeeOperatives[]
}
export interface DoctorFeeUsers {
  doctorId: number
}
export interface DoctorFee {
  doctorFeeName: string
  templateDoctorFeeId: number
  dateStart: string
  status?: string
  amount: number
  labDoctorAmount: number
  doctorFeeUsers: DoctorFeeUsers[]
  doctorFeeOperativeTypes: DoctorFeeOperativeTypes[]
}

export default class DoctorFeeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('doctor-fees', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('doctor-fees', playload)
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`doctor-fees/${id}`)
  }

  static update(id: number, payload: any) {
    return this.api.patch(`doctor-fees/${id}`, payload)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`doctor-fees/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`doctor-fees/${id}`)
  }

  static findAllWithOperatives(id?: number): Promise<any> {
    return this.api.get('doctor-fees/operatives', { params: { doctorFeeId: id, branchId: getBranch() } }).then((res) => res)
  }

  static findAllDoctors(): Promise<any> {
    return this.api.get(`doctor-fees/all-doctor`)
  }

  static doctorFeHistory(doctorId: number): Promise<any> {
    return this.api.get(`doctor-fees/history/${doctorId}`)
  }

}
