import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem'
import { Divider } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/personnel-managements/personnels/style'

/** UTILS || SLICE */
import { selectLoadingInfo, selectUserInfo, editInfo } from 'app/slice/user.slice';

/** CONSTANT */
import { colors } from 'constants/theme'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import InputTextarea from 'component/Input/InputTextarea'
import Box from '@mui/material/Box'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import ComponentApi from 'api/components-api'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import SelectAddress from 'component/Select/SelectAddress'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'

const initStateErrorMessage = {
  BRANCH_ADDRESS: ''
}
interface InformationProps {
  isEdit: boolean
  errorMessage?: any
  resetErrorMessage?: (type: string) => void
}
export default function Information(props: InformationProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const loading = useSelector(selectLoadingInfo)
  const userInfo = useSelector(selectUserInfo)

  /** DATA OPTIONS */
  const [prefixOptions, setPrefixOptions] = useState<any[]>([])
  const [countryOptions, setCountryOptions] = useState<any[]>([])
  const [prefix, setPrefix] = useState<string>('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstnameEn, setFirstnameEn] = useState('')
  const [lastnameEn, setLastnameEn] = useState('')
  const [nickname, setNickname] = useState('')
  const [idcard, setIdcard] = useState('')
  const [birthday, setBirthday] = useState('')
  const [age, setAge] = useState<string>('')
  const [phone, setPhone] = useState('')
  const [telephone, setTelephone] = useState('')
  const [email, setEmail] = useState('')
  const [line, setLine] = useState('')
  const [facebook, setFacebook] = useState('')

  const [address, setAddress] = useState('')
  const [countryId, setCountryId] = useState(Number)
  const [provinceId, setProvinceId] = useState(Number)
  const [districtId, setDistrictId] = useState(Number)
  const [subDistrictId, setSubDistrictId] = useState(Number)
  const [postcode, setPostcode] = useState<string>('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage)

  const calculateAge = (valueBirthday: any) => {
    const today = new Date()
    const birthDate = new Date(valueBirthday)
    let newAge = today.getFullYear() - birthDate.getFullYear()
    if (!valueBirthday) newAge = 0
    setAge(String(newAge))
  }

  const loadData = () => {
    ComponentApi.findAllPrefixes().then(({ data }) => data && setPrefixOptions(data))
    ComponentApi.findAllCountries().then(({ data }) => data && setCountryOptions(data))
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (!loading) {
      setPrefix(userInfo.prefix || '')
      setFirstname(userInfo.firstname || '')
      setLastname(userInfo.lastname || '')
      setFirstnameEn(userInfo.firstnameEn || '')
      setLastnameEn(userInfo.lastnameEn || '')
      setNickname(userInfo.displayName || '')
      setIdcard(userInfo.idCard || '')
      setBirthday(userInfo.dateOfBirth || '')
      setTelephone(userInfo.telephone || '')
      setPhone(userInfo.phone || '')
      setEmail(userInfo.email || '')
      setFacebook(userInfo.facebook || '')
      setLine(userInfo.line || '')
      setCountryId(userInfo.countryId || '')
      setAddress(userInfo.address || '')
      setProvinceId(userInfo.provinceId || 0)
      setDistrictId(userInfo.districtId || 0)
      setSubDistrictId(userInfo.subDistrictId || 0)
      setPostcode(userInfo.postcode || 0)
      if (userInfo.dateOfBirth) calculateAge(userInfo.dateOfBirth)
    }
  }, [loading])

  return (
    <div className="p-3">
      <div className={classes.titleFirst}>{t('PERSONNEL.GENARAL')}</div>
      <Row>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <BasicSelect
            required
            labelId="prefix"
            selectId="prefix"
            label={t('PERSONNEL.INPUT.INFORMATION.PREFIX')}
            value={prefix}
            onchange={(event) => {
              const value = event.target.value
              if (value) {
                setPrefix(value)
                dispatch(editInfo({ value: value, key: 'prefix' }))
              }
              if (props.resetErrorMessage) props.resetErrorMessage('info')
            }}
            options={prefixOptions.map((optPrefix: any, i: number) => (
              <MenuItem key={i} value={optPrefix.prefixName}>
                {optPrefix.prefixName}
              </MenuItem>
            ))}
            helperText={props.errorMessage.PREFIX}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            required
            label={t('PERSONNEL.INPUT.INFORMATION.FIRSTNAME')}
            value={firstname}
            onchange={(e) => {
              setFirstname(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'firstname' }))
              if (props.resetErrorMessage) props.resetErrorMessage('info')
            }}
            helperText={props.errorMessage.FIRST_NAME}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            required
            label={t('PERSONNEL.INPUT.INFORMATION.LASTNAME')}
            value={lastname}
            onchange={(e) => {
              setLastname(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'lastname' }))
              if (props.resetErrorMessage) props.resetErrorMessage('info')
            }}
            helperText={props.errorMessage.LAST_NAME}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            required
            label={t('PERSONNEL.INPUT.INFORMATION.FIRSTNAME_EN')}
            value={firstnameEn}
            onchange={(e) => {
              setFirstnameEn(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'firstnameEn' }))
              if (props.resetErrorMessage) props.resetErrorMessage('info')
            }}
            helperText={props.errorMessage.FIRST_NAME_EN}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            required
            label={t('PERSONNEL.INPUT.INFORMATION.LASTNAME_EN')}
            value={lastnameEn}
            onchange={(e) => {
              setLastnameEn(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'lastnameEn' }))
              if (props.resetErrorMessage) props.resetErrorMessage('info')
            }}
            helperText={props.errorMessage.LAST_NAME_EN}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            label={t('PERSONNEL.INPUT.INFORMATION.NICKNAME')}
            value={nickname}
            onchange={(e) => {
              setNickname(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'displayName' }))
            }}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            label={t('PERSONNEL.INPUT.INFORMATION.ID_CARDNUMBER')}
            value={idcard}
            onchange={(e) => {
              setIdcard(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'idCard' }))
            }}
          />
        </Col>
        <Col sm={4} xxl={3}>
          <Row>
            <Col sm={8} className="pt-3">
              <InputSecondNewDatePicker
                key={'date-of-birth'}
                label={t('PERSONNEL.INPUT.INFORMATION.BIRTHDAY')}
                value={birthday}
                onchange={(e: any) => {
                  const date = moment(e).format('YYYY-MM-DD')
                  setBirthday(date)
                  dispatch(editInfo({ value: date, key: 'dateOfBirth' }))
                  calculateAge(date)
                }}
              />
            </Col>
            <Col sm={4} className="pt-3">
              <InputTextField disabled={true} value={age} label={t('PERSONNEL.INPUT.INFORMATION.AGE')} />
            </Col>
          </Row>
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            label={t('PERSONNEL.INPUT.INFORMATION.PHONE')}
            value={phone}
            onchange={(e) => {
              setPhone(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'phone' }))
            }}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            label={t('PERSONNEL.INPUT.INFORMATION.TELEPHONE')}
            value={telephone}
            onchange={(e) => {
              setTelephone(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'telephone' }))
            }}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <Row className="mx-0">
            <Col className="col px-0 ">
              <InputTextField
                label={t('PERSONNEL.INPUT.INFORMATION.EMAIL')}
                value={email}
                onchange={(e) => {
                  setEmail(e.target.value)
                  dispatch(editInfo({ value: e.target.value, key: 'email' }))
                }}
              />
            </Col>
            <Col className="col-auto px-2  flex-row flex-center">
              <ButtonInfoSimple text={t('PERSONNEL.BUTTON.FORGOT_PASSWORD_SHARE')} />
            </Col>
          </Row>
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            label={t('PERSONNEL.INPUT.INFORMATION.LINE')}
            value={line}
            onchange={(e) => {
              setLine(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'line' }))
            }}
          />
        </Col>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <InputTextField
            value={facebook}
            label={t('PERSONNEL.INPUT.INFORMATION.FACEBOOK')}
            onchange={(e) => {
              setFacebook(e.target.value)
              dispatch(editInfo({ value: e.target.value, key: 'facebook' }))
            }}
          />
        </Col>
      </Row>

      <Divider className="mx-0 mt-4 mb-2" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

      <Row className={'mt-4'}>
        <Col className={classes.titleFirst}>{t('PERSONNEL.INPUT.INFORMATION.ADDRESS')}</Col>
      </Row>
      <Row>
        <Col sm={4} xxl={3} className="pt-3 px-2">
          <AutocompleteSelect
            labelId={`lb-parent-country`}
            inputLabel={t('PERSONNEL.INPUT.INFORMATION.COUNTRY')}
            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
            options={countryOptions}
            getOptionLabel={(option: any) => {
              return option.countryNameTh
            }}
            renderOption={(props, option: any) => {

              return (
                <Box component="li" {...props} key={option.countryId}>
                  {option.countryNameTh}
                </Box>
              )
            }}
            onchange={(e, value: any) => {
              if (value) {
                const val: number = value.countryId
                setCountryId(val)
                dispatch(editInfo({ value: val, key: 'countryId' }))
              }
            }}
            key={`lb-parent-country`}
            value={_.find(countryOptions, (countries) => countries.countryId === countryId) || null}
            disableClearable
          />
        </Col>
        <Col sm={6} className="pt-3 px-2">
          <SelectAddress
            patientId={0}
            countryId={countryId}
            provinceId={provinceId}
            districtId={districtId}
            subDistrictId={subDistrictId}
            postcode={postcode}
            callbackAddress={(valueAddress: any) => {
              if (valueAddress.provinceId) {
                setProvinceId(valueAddress.provinceId)
                dispatch(editInfo({ value: valueAddress.provinceId, key: 'provinceId' }))
              }
              if (valueAddress.districtId) {
                setDistrictId(valueAddress.districtId)
                dispatch(editInfo({ value: valueAddress.districtId, key: 'districtId' }))
              }
              if (valueAddress.subDistrictId) {
                setSubDistrictId(valueAddress.subDistrictId)
                dispatch(editInfo({ value: valueAddress.subDistrictId, key: 'subDistrictId' }))
              }
              if (valueAddress.postcode) {
                setPostcode(valueAddress.postcode)
                dispatch(editInfo({ value: `${valueAddress.postcode}`, key: 'postcode' }))
              }
            }}
          />
        </Col>
        <Col xl={12} className="pt-3 px-2">
          <InputTextarea
            label={t('PERSONNEL.INPUT.INFORMATION.ADDRESS_DETAIL')}
            value={address}
            helperText={errorMessage.BRANCH_ADDRESS}
            onchange={(event) => {
              setAddress(event.target.value)
              dispatch(editInfo({ value: event.target.value, key: 'address' }))
              clearErrorMessage()
            }}
          />
        </Col>
      </Row>
    </div>
  )
}
