import { Box, Button, Menu } from '@mui/material';
import { Dropdown } from 'react-bootstrap'

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'

export const FooterAction = styled(Box)(({ theme }) => ({
  height: 72,
  width: '100%',
  border: `1px solid ${colors.lightGray}`,
  left: 0,
  bottom: 0,
  backgroundColor: colors.white,
  zIndex: 10,
  button: {
    height: 44,
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      '&.MuiButton-root, &.dropdown-toggle': {
        minWidth: 'unset',
        paddingLeft: '8px !important',
        paddingRight: '8px !important'
      }
    }
  },
  '&.fragment-footer-disabled': {
    '.btn.dropdown-toggle': {
      borderColor: `${colors.disabledGray} !important`,
      color: `${colors.disabledGray} !important`
    },
    '.btn-payment': {
      backgroundColor: `${colors.disabledGray} !important`,
    }
  },
  [theme.breakpoints.up('lg')]: {
    left: scale.sidebarSize,
    maxWidth: `calc(100% - ${scale.sidebarSize})`
  }
}))

export const DropdownDocument = styled(Dropdown)(({ theme }) => ({
  '.dropdown-toggle': {
    backgroundColor: `${colors.white} !important`,
    color: `${colors.themeSecondColor} !important`,
    border: `1px solid ${colors.themeSecondColor} !important`,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none !important',
    height: 44,
    '&:after': {
      marginLeft: '0.5rem'
    },
    '&[aria-expanded="true"]:after': {
      transform: 'rotate(180deg)'
    }
  },
  '.dropdown-menu': {
    width: " 230px !important",
    marginBottom: 4,
    borderRadius: 4,
    background: colors.white,
    border: `1px solid ${colors.lightGray}`,
    '.dropdown-item': {
      '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

export const CustomButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  height: 44,
  fontSize: 18,
  '&.btn-more': {
    width: 44,
    border: `1px solid ${colors.lightGray}`,
    backgroundColor: colors.white,
    padding: 0,
    '&:disabled': {
      'svg g path': {
        stroke: `${colors.disabledGray} !important`
      }
    }
  }
}))

export const MenuBtnMore = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    marginTop: '0.5rem',
    borderRadius: 3,
    mixBlendMode: 'multiply',
    backgroundBlendMode: 'multiply',
    background: `linear-gradient(to bottom, ${colors.white}, ${colors.white})`,
    boxShadow: `none !important`,
    border: `1px solid ${colors.lightGray}`
  },
  '.MuiMenuItem-root': {
    color: colors.textPrimary,
    fontSize: '16px !important',
    minHeight: 'unset',
    '&:hover': {
      background: colors.themeSecondColor06,
      color: colors.themeSecondColor
    }
  }
}))

export const CustomTabs = styled(Box)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  '.MuiTabs-root': {
    minHeight: 'unset',
    lineHeight: 0,
    [theme.breakpoints.up('lg')]: {
      margin: '0 -8px 0 72px'
    }
  },
  '.MuiTabs-indicator': {
    display: 'none'
  },
  '.btn-tab': {
    width: '100%',
    height: 36,
    minHeight: 'unset',
    padding: 0,
    position: 'relative',
    borderRadius: 6,
    fontSize: 16,
    color: disabled ? colors.disabledGray : colors.themeSecondColor,
    pointerEvents: disabled ? 'none' : 'auto',
    [theme.breakpoints.up('md')]: {
      fontSize: 18
    },
    '.icon-selector': {
      position: 'absolute',
      right: 20
    },
    '&[aria-expanded="true"]': {
      '.icon-selector': {
        transform: 'rotate(180deg)'
      }
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-selected': {
      color: colors.white,
      '&::before': {
        backgroundColor: disabled ? colors.disabledGray : colors.themeSecondColor
      }
    },
    '&.btn-dropdown-menu-mobile-tab': {
      backgroundColor: disabled ? colors.disabledGray : colors.themeSecondColor,
      color: colors.white,
      pointerEvents: disabled ? 'none' : 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 4px',
      maxWidth: '25%',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 4,
        right: 4,
        bottom: 0,
        backgroundColor: colors.white,
        border: `1px solid ${disabled ? colors.disabledGray : colors.themeSecondColor}`,
        zIndex: -1,
        borderRadius: 6
      }
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 8px',
      '&::before': {
        left: 8,
        right: 8
      }
    }
  }
}))

export const CardProfile = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: '10px 16px',
  height: 80,
  borderRadius: 6,
  boxShadow: `0 3px 6px 0 ${colors.black10}`,
  border: `1px solid ${colors.extraLightGray}`,
  backgroundColor: colors.white,
  [theme.breakpoints.down('sm')]: {
    padding: '8px 16px',
    height: 96
  }
}))
