import { colors } from "constants/theme"

export default function ActivePencil() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 23H15.2315" style={{ stroke: colors.themeSecondColor }} strokeWidth="1.2" strokeLinecap="round" />
            <path d="M23.6323 4.05218L19.9804 0.375655C19.7391 0.135066 19.4125 0 19.072 0C18.7316 0 18.4049 0.135066 18.1636 0.375655L5.21896 13.3252L4.03688 18.435C3.99606 18.6221 3.99754 18.816 4.04121 19.0024C4.08488 19.1889 4.16964 19.3632 4.28927 19.5126C4.40891 19.662 4.56039 19.7827 4.73263 19.8659C4.90486 19.949 5.09348 19.9926 5.28468 19.9932C5.37374 20.0023 5.46348 20.0023 5.55254 19.9932L10.7101 18.8091L23.6323 5.87211C23.8725 5.63038 24.0073 5.3032 24.0073 4.96214C24.0073 4.62109 23.8725 4.29391 23.6323 4.05218ZM10.0837 17.6867L5.24974 18.7024L6.35113 13.9526L16.0374 4.28634L19.77 8.02536L10.0837 17.6867ZM20.6027 7.12456L16.8701 3.38554L19.0329 1.22979L22.7056 4.96798L20.6027 7.12456Z" style={{ stroke: colors.themeSecondColor }} />
            <path d="M5.83947 13.6301C5.83947 13.6301 7.66957 11.5401 7.47741 12.7709C7.28525 14.0017 9.30751 14.04 9.56373 15.5442C9.81994 17.0483 11.3381 15.9933 11.2799 17.3607L10.4413 18.2774L5.18064 19.4507L4.5376 18.8257L5.83947 13.6301Z" style={{ stroke: colors.themeSecondColor }} strokeWidth="1.2" strokeLinejoin="round" />
            <path d="M5.07924 16.6177C5.07924 16.6177 7.21297 16.261 7.57483 18.9026C6.75378 19.0426 5.37206 19.3518 5.17657 19.3326C5.01519 19.3326 4.63086 18.7493 4.63086 18.7493L5.07924 16.6177Z" style={{ stroke: colors.themeSecondColor }} strokeWidth="1.2" strokeLinejoin="round" />
        </svg>

    )
}
