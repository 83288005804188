import { renderToString } from "react-dom/server";

export interface LoadDataToTable {
  filter: string[];
  header: string[];
  body:
  | Array<{ [key: string]: string | number | boolean }>
  | Array<(string | number | boolean)[]>;
}

export default function createTable({ filter, header, body }: LoadDataToTable) {

  const filterCell = (
    <tr>
      {filter.map((filterItem, i) => (
        <th key={i}>{filterItem}</th>
      ))}
    </tr>
  );

  const headCell = (
    <tr>
      {header.map((head, i) => (
        <th key={i}>{head}</th>
      ))}
    </tr>
  );

  const dataCEll = body.map((val, indexValue) => {
    if (Array.isArray(val)) {
      return (
        <tr key={indexValue}>
          {val.map((value, iValueTable) => (
            <th key={iValueTable}> {value} </th>
          ))}
        </tr>
      );
    }

    if (val !== null && typeof val === "object") {
      return (
        <tr key={indexValue}>
          {Object.entries(val).map(([key, value], iValueKey) => {
            if (typeof value === "object") {
              return <th key={iValueKey}></th>;
            }

            return (
              <th key={iValueKey}>
                <>{value}</>
              </th>
            );
          })}
        </tr>
      );
    }

    return null;
  });

  return renderToString(
    <table>
      <tbody>
        {filterCell}
        {headCell}
        {dataCEll}
      </tbody>
    </table>
  );
}
