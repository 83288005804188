import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface OperativeUnitInterface {
  operativeUnitName: string
  operativeUnitNameEn?: string
  status: string
}

export default class OperativeUnitApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('operative-units', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('operative-units', playload)
  }

  static findById(id: number): Promise<OperativeUnitInterface> {
    return this.api.get(`operative-units/${id}`)
  }

  static update(id: number, body: OperativeUnitInterface) {
    return this.api.patch(`operative-units/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`operative-units/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`operative-units/${id}`)
  }
}
