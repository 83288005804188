import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import _, { forIn } from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import PrivilegeList from 'features/finance/payment/component/popupPayment/PrivilegeList'

/** UTILS */
import { numberFormat, numberToCurrencyFormat } from 'utils/app.utils'
import {
  summerySub,
  summeryTreatmentRight,
  setSummerySubByKey,
  summerySubTreatmentRight,
  setSummeryTreatmentRightByKey,
  setSummerySubTreatmentRightByKey,
} from 'app/slice/payment.slice';

import * as UseStyled from 'features/finance/payment/component/popupPayment/UseStyledFormPayment'

type TreatmentDiscountType = {
  treatments?: any
  treatmentRights: any[]
  treatmentRightValue?: any
  paymentChannelId: number
  balancePaid?: number
  menu?: 'PAYMENT' | 'OVERDUE' | 'RETROSPECTIVE' | 'EDIT_PAYMENT'
  disableFiled?: boolean | false
  disableIcon?: boolean
  onChangePaid?: (val: number) => void
}

export default function TreatmentDiscount(props: TreatmentDiscountType) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const valSummerySub = useSelector(summerySub)
  const valSummeryTreatmentRight = useSelector(summeryTreatmentRight)
  const valSummerySubTreatmentRight = useSelector(summerySubTreatmentRight)

  const [trPaid, setTrPaid] = useState<any[]>([])
  useEffect(() => {
    setTrPaid(_.map(props.treatmentRights, (tr: any) => ({ trId: tr.patientTreatmentRightId, value: 0 })))
  }, [props.treatmentRights])

  useEffect(() => {
    if (valSummeryTreatmentRight[5]) {
      const pcId: any = 5
      const objBalance: any = {}
      const objValue: any = {}
      if (valSummerySubTreatmentRight[pcId]) {
        for (const ptrId in valSummerySubTreatmentRight[pcId]) {
          if (Object.prototype.hasOwnProperty.call(valSummerySubTreatmentRight[pcId], ptrId)) {
            const strSub = valSummerySubTreatmentRight[pcId][ptrId];
            for (const index in strSub) {
              if (Object.prototype.hasOwnProperty.call(strSub, index)) {
                const valSub = strSub[index];
                const { value, treatmentPrice } = valSub
                if (objBalance[index] === undefined) objBalance[index] = treatmentPrice
                if (objValue[index] === undefined) objValue[index] = 0

                dispatch(setSummerySubTreatmentRightByKey({ key: pcId, keySub: index, patientTreatmentRightId: ptrId, value: { ...valSub, paidBalance: objBalance[index] } }))
                objBalance[index] -= value
                objValue[index] += value
              }
            }
          }
        }
        _.map(objBalance, (balance: any, index: any) => {
          const sub: any = valSummerySub[pcId] !== undefined ? valSummerySub[pcId][index] : null
          if (sub)
            dispatch(setSummerySubByKey({ key: pcId, keySub: index, value: { ...sub, value: objValue[index] } }))
        })
      }
    }

  }, [valSummeryTreatmentRight])

  useEffect(() => {
    _.map(props.treatmentRights, (treatmentRight, index) => {
      dispatch(setSummeryTreatmentRightByKey({ key: props.paymentChannelId, patientTreatmentRightId: treatmentRight.patientTreatmentRightId, value: { isCheck: false, value: 0, isDisabled: true } }))
    })
  }, [props.paymentChannelId])

  return (
    <Box className='mb-3'>
      <Typography className='mt-3' sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.TREATMENT_DISCOUNT')}</Typography>
      <Box className='pl-xl-3' sx={{ marginTop: '-8px' }}>
        {props.treatmentRights.length ? (
          <>
            {_.map(props.treatmentRights, (treatmentRight, index) => {
              return (
                <PrivilegeList
                  disableIcon={true}
                  type='TreatmentDiscount'
                  menu={props?.menu}
                  paymentChannelId={props.paymentChannelId}
                  changePaid={(val: number) => setTrPaid([...trPaid.filter((trP: any) => trP.trId !== treatmentRight.patientTreatmentRightId), { trId: treatmentRight.patientTreatmentRightId, value: val }])}
                  patientTreatmentRightId={treatmentRight.patientTreatmentRightId}
                  treatmentRight={treatmentRight}
                  treatmentRightValue={props.treatmentRightValue}
                  index={index}
                  key={index}
                  title={t('PAYMENT.FORM_PAYMENT.TITLE_DISCOUNT', { privilege: treatmentRight.treatmentRightName, price: numberFormat(treatmentRight.balance, 0, 0), unit: treatmentRight.isDivide === '1' ? (treatmentRight.unit === 'BAHT' && t('CURRENCY_CHANGE.TH') || '%') : t('PAYMENT.FORM_PAYMENT.TIMES') })}
                  treatments={props.treatments}
                  disableFiled={props.disableFiled}
                  treatmentInfo={
                    t('PAYMENT.FORM_PAYMENT.TREATMENT_DISCOUNT_INFO', {
                      price: treatmentRight.isDivide === '1' ? treatmentRight.balance : treatmentRight.price,
                      unit: treatmentRight.unit === 'BAHT' ? ` ${t('CURRENCY_CHANGE.TH')}` : '%',
                      balance: treatmentRight.isDivide === '1' ? 1 : treatmentRight.balance,
                      unitIsDivide: treatmentRight.isDivide === '1' ? t('PAYMENT.FORM_PAYMENT.RIGHT') : t('PAYMENT.FORM_PAYMENT.TIMES'),
                      isNoDF: treatmentRight.isDoctorFee === '1' ? '' : t('PAYMENT.FORM_PAYMENT.OVERDUE_CONFIRM.NO')
                    })}
                  discountHasExpired={treatmentRight.balance < 1} />
              )
            })}
          </>
        ) : (<div className='text-center p-2 mt-2 text-muted'>{t('PAYMENT.FORM_PAYMENT.NO_TREATMENT_DISCOUNT')}</div>)}
      </Box>
    </Box>
  )
}
