import BaseAPI from 'api/api'
const path = 'doctor-lab-fee'
export interface FindManageDoctorLabFeeAllInterface {
    page?: number
    pageLimit?: number
    dateStart?: string
    dateEnd?: string
    search?: string
    status?: string
}
// export interface IFDoctorFeeInterface {
//     dfAmount: number
//     dfAmountNet: number
//     dfUnit: 'BAHT' | 'PERCENT'
//     cfAmount: number
//     cfAmountNet: number
//     cfUnit: 'BAHT' | 'PERCENT'
//     dfRemark: string | null
//     doctorId: number
//     clinicFeeId: number
// }
export interface DoctorLabFeeUpdate {
    // labOrderPrice: number,
    labDoctorAmount: number,
    labClinicAmount: number,
    doctorId: number
}
export interface LabOrderFeeInterface {
    labOrderId: number;
    labOrderNumber: string;
    doctorId: number
    doctorFullname: string;
    patientsFullname: string;
    cnNumber: string;
    branchId: number;
    dateOrder: string;
    timeOrder: string;
    labCompanyId: number;
    labCompanyName: string;
    dateExport: string;
    dateImport: string;
    dateReceive: string;
    labGroupId: number;
    labGroupName: string;
    labOrderPrice: number;
    labDoctorAmount: number;
    labClinicAmount: number;
    labUnit: string;
    labOrderStatus: string;
    remark: string | null;
    labTypeId: number;
    labTypeName: string;
    labText: string;
    tempOrderTreatmentId: number | null;
    orderTreatmentId: number;
    operativeId: number,
    listOfProcedures: string;
    cancelRemark: string | null;
    cancelCode: string | null;
    cancelBy: string | null;
    status: string;
    updatedBy: string;
    updatedAt: string;
    createdBy: string;
    createdAt: string;
    dfNet: number;
    currentDfAmount: number,
    currentCfAmount: number,
}

// export interface DoctorFeeInterface {
//     orderTreatmentId?: any
//     date?: any
//     paymentNumber?: string
//     doctorFullname?: string
//     branchCode?: string
//     branchCnNumber?: any
//     patientsFullname?: any
//     operativeTypeName?: any
//     operativeName?: any
//     total?: any
//     dfAmount?: any
//     cfAmount?: any
//     dfUnit?: any
//     dfDiscountAmount?: any
//     dfDiscountUnit?: any
//     cfDiscountAmount?: any
//     cfDiscountUnit?: any
//     dfAmountNet?: any
//     cfAmountNet?: any
//     dfRemark?: any
//     clinicFeeId?: any
//     clinicFeeName?: any
//     clinicFeePrice?: any
//     createdBy?: any
//     updatedBy?: any
//     updatedAt?: any
// }
export default class ManageDoctorLabFeeApi extends BaseAPI {
    static findAll(props: FindManageDoctorLabFeeAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }
    static update(labOrderId: number, payload: DoctorLabFeeUpdate): Promise<any> {
        return this.api.patch(`${path}/${labOrderId}`, { ...payload })
    }
}