
type iconProps = {
    width?: string
    height?: string
    color?: string
}

const BtnEdit = (props: iconProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <svg
                width={props?.width || "9"}
                height={props?.height || "10"}
                viewBox="0 0 9 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_623_13151)">
                    <path
                        d="M0.40185 7.9661C0.144626 8.27859 7.76783e-05 8.70244 0 9.14443L0 9.99985H0.703923C1.06764 9.99975 1.41643 9.82409 1.67357 9.51151L6.24856 3.95194L4.97684 2.40652L0.40185 7.9661Z"
                        fill={props?.color || "#1CB99A"}
                    />
                    <path
                        d="M7.93586 0.356315C7.85236 0.254747 7.75322 0.174172 7.64408 0.119197C7.53495 0.0642229 7.41796 0.0359268 7.29983 0.0359268C7.18169 0.0359268 7.0647 0.0642229 6.95557 0.119197C6.84643 0.174172 6.74728 0.254747 6.66379 0.356315L5.46167 1.81757L6.73339 3.36298L7.93586 1.90215C8.01944 1.80069 8.08574 1.6802 8.13098 1.54758C8.17622 1.41495 8.19951 1.2728 8.19951 1.12923C8.19951 0.985667 8.17622 0.84351 8.13098 0.710887C8.08574 0.578265 8.01944 0.457778 7.93586 0.356315Z"
                        fill={props?.color || "#1CB99A"}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_623_13151">
                        <rect
                            width="8.22901"
                            height="10"
                            fill={props?.color || "#1CB99A"}
                        />
                    </clipPath>
                </defs>
            </svg>
            <svg
                style={{ marginTop: "1px" }}
                width={Number(props?.width) * 1.2 || "11"}
                height={Number(props?.height) * 0.2 || "2"}
                viewBox="0 0 11 2" fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="11" height="2" fill={props?.color || "#1CB99A"} />
            </svg>

        </div>
    )
}

export default BtnEdit