import { colors } from 'constants/theme'
import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& .border-right': {
      borderRight: `1px solid ${colors.borderInput}`
    }
  },
  formControl: {},
  inputCheckBox: {
    '& $label': {
      fontFamily: 'Kanit',
      fontSize: '12px',
      fontWeight: 500
    },
    '& $span': {
      fontSize: '12px'
    }
  },
  cardAddImage: {
    border: `1px dashed ${colors.borderInput}`,
    width: 150,
    height: 180,
    '& $img': {
      padding: 5,
      width: '100%',
      height: '100%'
    },

    '& .text-upload-image': {
      text: colors.textPrimary,
      fontSize: '12px'
    }
  }
}))

export const UploadImage = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '250px',
  maxHeight: '250px',
  '.image-wrap': {
    paddingTop: '100%',
    position: 'relative',
    left: 0,
    right: 0,
    width: '100%',
  },
  img: {
    width: '100%',
  },
  '.upload-image': {
    border: `2px dashed ${colors.lightGray}`,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    img: {
      width: 70,
    },
    p: {
      fontSize: 16,
      color: colors.textExtraLightGray,
    }
  },
  '.image-preview': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%'
  },
}))

export default useStyles
