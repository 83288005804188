import { useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Box } from '@mui/material'
import { colors } from 'constants/theme';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';

/** STYLE */
import { styled } from '@mui/material/styles';

const CustomEditor = styled(Box)(({ theme }) => ({
  '.custom-editor-wrapper': {
    border: `1px solid ${colors.black12}`,
    '.custom-editor-toolbar': {
      borderWidth: '0 0 1px 0',
      borderColor: colors.black12,
      '> div': {
        marginBottom: 0,
        '&.rdw-fontfamily-wrapper': {
          display: 'none'
        },
        '&.rdw-fontsize-wrapper': {
          '.rdw-dropdown-selectedtext': {
            minWidth: 40
          }
        },
        '.rdw-dropdown-carettoopen': {
          top: '50%',
          transform: 'translateY(-50%)'
        },
        '.rdw-dropdown-optionwrapper': {
          borderColor: colors.black12,
          boxShadow: 'none',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: 3
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.disabledGray,
            borderRadius: 100
          }
        },
        '> div': {
          marginBottom: 6
        }
      },
      '.rdw-option-wrapper, .rdw-dropdown-wrapper': {
        borderColor: colors.black12,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colors.extraLightGray,
        },
        '&.rdw-option-active': {
          boxShadow: 'none',
          borderColor: colors.lightGray,
          backgroundColor: colors.lightGray,
        },
        '.rdw-dropdown-selectedtext': {
          color: colors.textPrimary,
          textDecoration: 'none'
        }
      }
    },
    '.custom-editor-text': {
      minHeight: 200,
      padding: '0 16px'
    }
  }
}))

type InputTextEditorProps = {
  value?: string
  setValue?: (val: string) => void
}

export default function InputTextEditor(props: InputTextEditorProps) {

  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorStateDraft = EditorState.createWithContent(contentState);

    return editorStateDraft;
  };

  const [editorState, setEditorState] = useState(props.value ? prepareDraft(props.value) : EditorState.createEmpty())

  const onEditorStateChange = (editorStateValue: EditorState) => {
    const forFormik = draftToHtml(convertToRaw(editorStateValue.getCurrentContent()));
    props.setValue && props.setValue(forFormik);
    setEditorState(editorStateValue);
  }

  return (
    <CustomEditor>
      <Editor
        editorState={editorState}
        wrapperClassName="custom-editor-wrapper"
        toolbarClassName="custom-editor-toolbar custom-scroll"
        editorClassName="custom-editor-text"
        onEditorStateChange={onEditorStateChange}
      />
    </CustomEditor>
  )
}
