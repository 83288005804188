import { TableCell, TableRow } from '@material-ui/core'
import { dateTimeToView } from 'utils/date.utils'
import { colors } from 'constants/theme'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

type TableRowCommonProps = {
  key: string
  id: string
  obj: any
  columns: any
  onactive?: (val: any) => any
  oninactive?: (val: any) => any
  onedit?: (val: any) => void
  ondelete?: (val: any) => void
  oncancle?: (val: any) => void
  onadd?: (val: any) => void
  onClick?: (value: any) => void
}

export default function TableRowNonIcon(props: TableRowCommonProps) {
  const { t } = useTranslation()
  return (
    <TableRow key={props.key}>
      {props.columns.map((column: any) => {
        return (
          (column.option === 'TEXT' && (
            <TableCell onClick={props.onClick} align={column.align || 'left'} scope="row">
              {column.label}
            </TableCell>
          )) ||
          (column.option === 'STATUS' && (
            <TableCell onClick={props.onClick} className="status" align={column.align || 'left'}>
              <span className="ml-2">{t(`STATUS_NO_ICON.${column.label}`)}</span>
            </TableCell>
          )) ||
          (column.option === 'UPDATE_BY' && (
            <TableCell onClick={props.onClick} align={column.align || 'left'} className="update-by">
              {column.label.updatedBy}
              <br />
              <span>{dateTimeToView(column.label.updatedAt, false)}</span>
            </TableCell>
          )) ||
          (column.option === 'ACTION' && (
            <TableCell style={{ width: 160 }} align={column.align || 'left'}>
              {column.values.map((objVal: any, i: number) => {
                return (
                  (objVal.option === 'STATUS_INACTIVE' && (
                    <Button style={{ border: `${objVal.disabled ? `1px solid ${colors.disabledGray}` : `1px solid ${colors.themeThirdColor}`}`, color: `${objVal.disabled ? colors.disabledGray : colors.themeThirdColor}` }} onClick={props.oncancle} disabled={objVal.disabled}>
                      {objVal.label}
                    </Button>
                  )) ||
                  (objVal.option === 'STATUS_ACTIVE' && (
                    <Button style={{ border: `${objVal.disabled ? `1px solid ${colors.disabledGray}` : `1px solid ${colors.themeThirdColor}`}`, color: `${objVal.disabled ? colors.disabledGray : colors.themeThirdColor}` }} onClick={props.oncancle} disabled={objVal.disabled}>
                      {objVal.label}
                    </Button>
                  ))
                )
              })}
            </TableCell>
          )) ||
          (column.option === 'ADD' && (
            <TableCell style={{ width: 160 }} align={column.align || 'left'}>
              <AddIcon onClick={props.onadd} style={{ height: '24px', width: '24px', color: colors.white, backgroundColor: colors.themeSecondColor, cursor: 'pointer', borderRadius: '4px' }} />
            </TableCell>
          ))
        )
      })}
    </TableRow>
  )
}
