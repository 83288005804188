import { useCallback, useEffect, useState } from 'react'
import { CardContent, Grid, IconButton, Box, ClickAwayListener } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import { AddCircleRounded } from '@mui/icons-material'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import moment from 'moment'
import { icons } from 'constants/images'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi from 'api/master/clinic.api'
import ComponentApi from 'api/components-api'
import PrefixApi from 'api/setting/default-users/prefix.api'

/** UTILS || SLICE */
import { getBoolean, renderAge } from 'utils/app.utils'
import { setPatient, setReloadCareers } from 'app/slice/register/register.slice'
import { selectMe } from 'app/slice/user.slice'
import { BMI_RANGE } from 'constants/common'
import { UseSnCode } from 'utils/useSnCode'

/** COMPONENT */
import { colors } from 'constants/theme'
import InputTextarea from 'component/Input/InputTextarea'
import InputTextField from 'component/Input/InputTextField'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import BasicSelect from 'component/Select/BasicSelect'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import SelectAddress from 'component/Select/SelectAddress'

/** STYLED */
import { ButtonNotSpecified, ButtonAddEmergencyContact, CustomTagMultiple, InputBranchCnGroup, BloodPressureBox } from 'features/counter/register/usedStyles'
import InputCheckbox from 'component/Input/InputCheckbox'
import { TRUE_FALSE } from 'constants/status'
import { notiError } from 'component/notifications/notifications'
import HelperText from 'component/Input/HelperText'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: 'none',
  padding: '0 1rem',
  marginBottom: '1.5rem !important',
  '&:first-of-type': {
    marginTop: '1rem !important'
  },
  '&:before': {
    opacity: 0
  },
  '.header-text': {
    h5: {
      fontSize: 18,
      fontWeight: 500,
      '&.required::after': {
        color: colors.statusInactive
      }
    }
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '0 1.5rem',
    '&:first-of-type': {
      marginTop: '1.5rem !important'
    }
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ backgroundColor: colors.white, border: `0.5px solid ${colors.textLightGray}`, borderRadius: '50px', color: colors.themeMainColor }} />} {...props} />
))(({ theme }) => ({
  height: 40,
  backgroundColor: colors.themeSecondColor10,
  '&.error': {
    backgroundColor: colors.statusInactive10
  },
  '.MuiAccordionSummary-content': {
    margin: 0
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0
  },
  '.MuiGrid-container': {
    margin: 0,
    width: '100%',
    '.MuiGrid-item': {
      width: '100%'
    }
  },
  '.MuiIconButton-root': {
    '&.Mui-disabled': {
      path: {
        fill: colors.disabledGray
      }
    }
  },
  '.btn-parent-remove': {
    paddingTop: '0.875rem',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0.5rem'
    }
  }
}))

export interface FormRegisterProp {
  disabled: boolean
  error: any
  expandedInfoTab?: boolean
  resetErrorMessage: () => void
  handleFormCareer: () => void
  branchCode: string
  emailTempError: (status: boolean) => void
  formReq: any
}

export default function FormRegister(props: FormRegisterProp) {
  const { t, i18n } = useTranslation()
  const { register, setValue, getValues, control } = useFormContext()
  const dispatch = useDispatch()
  const formRegister = useSelector((state: any) => state.register.formRegister)
  const reloadCareers = useSelector((state: any) => state.register.reloadCareers)
  const patient = useSelector((state: any) => state.register.patient)

  const user = useSelector(selectMe)

  const formTreatmentRights = useFieldArray({
    control,
    name: 'treatmentRights'
  })

  const formParents = useFieldArray({
    control,
    name: 'parents'
  })

  const [disabled, setDisabled] = useState(true)

  /** OPTION SELECT */
  const [prefixes, setPrefixes] = useState([{ type: 'text', name: ``, value: 0 }])
  const [origins, setOrigins] = useState([{ type: 'text', name: ``, value: 0 }])
  const [nationalities, setNationalities] = useState([{ type: 'text', name: ``, value: 0 }])
  const [accessChannels, setAccessChannels] = useState([])
  const [serviceFactors, setServiceFactors] = useState([])
  const [patientTypes, setPatientTypes] = useState([])
  const [frequenciesCigarettes, setFrequenciesCigarettes] = useState([])
  const [frequenciesAlcohols, setFrequenciesAlcohols] = useState([])
  const [countries, setCountries] = useState([{ type: 'text', name: ``, value: 0 }])
  const [careers, setCareers] = useState([{ type: 'text', name: ``, value: 0 }])
  const [disease, setDisease] = useState([])
  const [medicineUse, setMedicineUse] = useState([])
  const [beAllergic, setBeAllergic] = useState([])
  const [treatmentRights, setTreatmentRights] = useState([{ type: 'text', name: ``, value: 0 }])
  const [accordionTabInfo, setAccordionTabInfo] = useState(true)
  const [openTreatmentRightSelect, setOpenTreatmentRightSelect] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [snType, setSnType] = useState(false);

  const [generalInformationTabError, setGeneralInformationTabError] = useState<boolean>(false)
  const [healthInformationTab, setHealthInformationTab] = useState<boolean>(true)
  const [healthInformationTabError, setHealthInformationTabError] = useState<boolean>(false)
  const [addressCardTab, setAddressCardTab] = useState<boolean>(true)
  const [addressCardTabError, setAddressCardTabError] = useState<boolean>(false)
  const [addressWorkTab, setAddressWorkTab] = useState<boolean>(true)
  const [addressWorkTabError, setAddressWorkTabError] = useState<boolean>(false)
  const [treatmentRightTab, setTreatmentRightTab] = useState<boolean>(true)
  const [treatmentRightTabError, setTreatmentRightTabError] = useState<boolean>(false)
  const [parentTab, setParentTab] = useState<boolean>(true)
  const [parentTabError, setParentTabError] = useState<boolean>(false)
  const [vitalSignTab, setVitalSignTab] = useState<boolean>(true)
  const [vitalSignTabError, setVitalSignTabError] = useState<boolean>(false)

  useEffect(() => {
    handleLoadPrefixes()
  }, [patient?.prefixText])

  const handleLoadPrefixes = async () => {
    await ComponentApi.findAllPrefixes().then((resPrefixes) => {
      if (resPrefixes.status === 200) {
        const newOption = []
        const prefixesItems = resPrefixes.data
        for (const prefix of prefixesItems) {
          const { prefixName, prefixId } = prefix
          newOption.push({ type: 'text', name: prefixName, value: prefixId })
          if (patient.prefixText && patient.prefixText === prefixName) {
            dispatch(setPatient({ key: 'prefixId', value: prefixId }))
            dispatch(setPatient({ key: 'prefixText', value: '' }))
            setValue('prefixId', prefixId)
            break;
          }
        }
        setPrefixes(newOption)
      }
    })
  }

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode();
      setSnType(sn);
    };

    fetchData();

  }, [snType]);

  useEffect(() => {
    handleSnCode()
  }, []);

  const loadPrefixes = useCallback(async () => {
    let hasData = false
    if (prefixes.length && prefixes[0].name !== '') {
      if (patient.prefixId) {
        for (const prefix of prefixes) {
          hasData = false
          const { name, value } = prefix
          if (patient.prefixId === value) {
            dispatch(setPatient({ key: 'prefixId', value: value }))
            dispatch(setPatient({ key: 'prefixText', value: '' }))
            setValue('prefixId', value)
            hasData = true
            break
          }
        }
      }
    }
    if (!hasData) {
      dispatch(setPatient({ key: 'prefixId', value: null }))
      dispatch(setPatient({ key: 'prefixText', value: '' }))
      setValue('prefixId', null)
    }

    if (!hasData && patient.prefixText) await PrefixApi.create(patient.prefixText, '')

    if (!hasData) {
      handleLoadPrefixes()
    }
  }, [patient.prefixId])



  const loadOrigins = useCallback(() => {
    ComponentApi.findAllOrigins().then((resOrigins) => {
      if (resOrigins.status === 200) {
        const newOption = []
        const originsItems = resOrigins.data
        for (const value of originsItems) {
          const { originId, originNameTh } = value
          newOption.push({ type: 'text', name: originNameTh, value: originId })
        }
        setOrigins(newOption)
      }
    })
  }, [])

  const loadNationalities = useCallback(() => {
    ComponentApi.findAllNationalities().then((resNationalities) => {
      if (resNationalities.status === 200) {
        const newOption = []
        const nationalitiesItems = resNationalities.data
        for (const value of nationalitiesItems) {
          const { nationalityNameTh, nationalityId } = value
          newOption.push({ type: 'text', name: nationalityNameTh, value: nationalityId })
        }
        setNationalities(newOption)
      }
    })
  }, [])

  const loadCareers = useCallback(() => {
    ComponentApi.findAllCareers().then((resCareers) => {
      if (resCareers.status === 200) {
        const newOption = []
        const careersItems = resCareers.data
        for (const career of careersItems) {
          const { careerId, careerName } = career
          newOption.push({ type: 'text', name: careerName, value: careerId })
        }
        setCareers(newOption)
      }
    })
  }, [])

  const loadTreatmentRights = useCallback(() => {
    ClinicApi.findAllTreatmentRight().then((resTreatmentRights) => {
      if (resTreatmentRights.status === 200) setTreatmentRights(resTreatmentRights.data)
    })
  }, [])

  const loadAccessChannels = useCallback(() => {
    ComponentApi.findAllAccessChannels().then((resAccessChannels) => {
      if (resAccessChannels.status === 200) setAccessChannels(resAccessChannels.data)
    })
  }, [])

  const loadServiceFactors = useCallback(() => {
    ComponentApi.findAllServiceFactors().then((resServiceFactors) => {
      if (resServiceFactors.status === 200) setServiceFactors(resServiceFactors.data)
    })
  }, [])

  const loadPatientTypes = useCallback(() => {
    const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'createdAt', status: 'ACTIVE' }
    ComponentApi.findAllPatientType(condition).then((resPatientTypes) => {
      if (resPatientTypes.status === 200) setPatientTypes(resPatientTypes.data)
    })
  }, [])

  const loadFrequenciesCigarettes = useCallback(() => {
    const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'createdAt', status: 'ACTIVE' }
    ComponentApi.findAllFrequenciesCigarette(condition).then((resFrequenciesCigarettes) => {
      if (resFrequenciesCigarettes.status === 200) setFrequenciesCigarettes(resFrequenciesCigarettes.data)
    })
  }, [])

  const loadFrequenciesAlcohols = useCallback(() => {
    const condition: any = { page: 1, pageLimit: 50, sortType: 'ASC', sortBy: 'createdAt', status: 'ACTIVE' }
    ComponentApi.findAllFrequenciesAlcohols(condition).then((resFrequenciesAlcohols) => {
      if (resFrequenciesAlcohols.status === 200) setFrequenciesAlcohols(resFrequenciesAlcohols.data)
    })
  }, [])

  const loadCountries = useCallback(() => {
    if (sessionStorage.getItem('countries')) {
      const items: any = sessionStorage.getItem('countries')
      setCountries(JSON.parse(items))
      const findThaiCountry = _.find(JSON.parse(items), { name: 'ไทย' })
      if (findThaiCountry) {
        dispatch(setPatient({ key: 'countryId', value: findThaiCountry.value }))
        setValue('countryId', findThaiCountry.value)
      }
    } else {
      ComponentApi.findAllCountries().then((resCountries) => {
        if (resCountries.status === 200) {
          const newOption = []
          const countriesItems = resCountries.data
          for (const country of countriesItems) {
            const { countryId, countryNameTh } = country
            newOption.push({ type: 'text', name: countryNameTh, value: countryId })
          }
          setCountries(_.orderBy(newOption, ['name'], ['asc', 'desc']))
          sessionStorage.setItem('countries', JSON.stringify(_.orderBy(newOption, ['name'], ['asc', 'desc'])))
        }
      })
    }
  }, [])

  const loadDisease = useCallback(() => {
    ComponentApi.findAllDisease().then((resDisease) => {
      if (resDisease.status === 200 && !_.isEmpty(resDisease.data)) {
        setDisease(resDisease.data)
      }
    })
  }, [])

  const loadMedicineUse = useCallback(() => {
    ComponentApi.findAllMedicineUse().then((resMedicineUse) => {
      if (resMedicineUse.status === 200 && !_.isEmpty(resMedicineUse.data)) {
        setMedicineUse(resMedicineUse.data)
      }
    })
  }, [])

  const loadBeAllergic = useCallback(() => {
    ComponentApi.findAllBeAllergic().then((resBeAllergic) => {
      if (resBeAllergic.status === 200 && !_.isEmpty(resBeAllergic.data)) {
        setBeAllergic(resBeAllergic.data)
      }
    })
  }, [])

  useEffect(() => {
    loadOrigins()
    loadNationalities()
    loadAccessChannels()
    loadServiceFactors()
    loadPatientTypes()
    loadCareers()
    loadTreatmentRights()
    loadFrequenciesCigarettes()
    loadFrequenciesAlcohols()
  }, [])

  useEffect(() => {
    setDisabled(props.disabled)
  }, [props.disabled])

  useEffect(() => {
    loadPrefixes()
    loadCountries()
    loadDisease()
    loadMedicineUse()
    loadBeAllergic()
  }, [loadCountries, loadPrefixes, patient.prefixText, patient.provinceId, patient.districtId, patient.disease, patient.beAllergic, patient.medicineUse])

  useEffect(() => {
    if (reloadCareers.reload === true) {
      loadCareers()
      dispatch(setPatient({ key: 'careerId', value: reloadCareers.careerId }))
      setValue('careerId', reloadCareers.careerId)
      dispatch(setReloadCareers({ reload: false, careeId: 0 }))
    }
  }, [reloadCareers, loadCareers])

  const handleChange = (event: any) => {
    const name = event.target.name
    const value = event.target.value

    switch (name) {
      case 'snCode':
        handleSnCodeChange(name, value);
        break;
      case 'email':
        dispatch(setPatient({ key: [name], value: value }));
        setValue(name, value);
        handleChangeEmail(value);
        break;
      default:

        dispatch(setPatient({ key: [name], value: value }));
        setValue(name, value);
    }
    props.resetErrorMessage()
    if (name === 'accessChannelId') {
      if (_.get(_.find(accessChannels, { accessChannelId: patient.accessChannelId }), 'isOther') === '0') {
        dispatch(setPatient({ key: 'accessChannelOther', value: '' }))
        setValue('accessChannelOther', '')
      }
    }
    if (name === 'serviceFactorId') {
      if (_.get(_.find(serviceFactors, { serviceFactorId: patient.serviceFactorId }), 'isOther') === '0') {
        dispatch(setPatient({ key: 'serviceFactorOther', value: '' }))
        setValue('serviceFactorOther', '')
      }
    }
  }

  const onClearValue = (name: string) => {
    dispatch(setPatient({ key: [name], value: '' }))
    setValue(name, '')
  }
  const handleSnCodeChange = (name: string, value: string) => {
    const regexPattern = /^[a-zA-Z0-9ก-๏]{0,10}$/;
    if (regexPattern.test(value)) {
      setValue(name, value);
      dispatch(setPatient({ key: [name], value: value }));
    }

  };
  const renderTreatmentRights = (values: any, reason: string) => {
    if (reason === 'selectOption') {
      _.map(values, (i: any, index: number) => {
        if (!_.some(formTreatmentRights.fields, (field: any, indexField: number) => field.treatmentRightId === i)) {
          const item = _.find(treatmentRights, { treatmentRightId: i })
          formTreatmentRights.append({
            treatmentRightId: _.get(item, 'treatmentRightId'),
            treatmentRightName: _.get(item, 'treatmentRightName'),
            balance: Number(_.get(item, 'isDivide')) === 1 ? _.get(item, 'price') : _.get(item, 'amount'),
            unit: Number(_.get(item, 'isDivide')) === 1 ? (_.get(item, 'unit') === 'BAHT' && 'บาท') || '%' : 'ครั้ง'
          })
        }
      })
    } else if (reason === 'removeOption' || reason === 'clearTreatment') {
      _.map(formTreatmentRights.fields, (i: any, index: number) => {
        if (!values.includes(i.treatmentRightId)) formTreatmentRights.remove(index)
      })
    } else formTreatmentRights.replace([])
  }

  const handleDateOfBirth = (date: Date | null) => {
    dispatch(setPatient({ key: 'dateOfBirth', value: moment(date).format('YYYY-MM-DD') }))
    setValue('dateOfBirth', moment(date).format('YYYY-MM-DD'))
    props.error.DATE_OF_BIRTH = ''
    props.resetErrorMessage()
  }
  const handleSurgeryDate = (date: Date | null) => {
    let dateSave: any = date
    if (date) dateSave = moment(date).format('YYYY-MM-DD')
    dispatch(setPatient({ key: 'surgeryDate', value: dateSave }))
    setValue('surgeryDate', dateSave)
  }

  const handleSetAge = (date: Date | null) => {
    return `${renderAge(date, 'year')} ${t('TIME_DURATION_TYPE.YEAR')} ${renderAge(date, 'month')} ${t('TIME_DURATION_TYPE.MONTH')} ${renderAge(date, 'day')} ${t('TIME_DURATION_TYPE.DAY')}`
  }

  const handleSelectAddress = (key: string, value: string | number, key_remove: any) => {
    dispatch(setPatient({ key: key, value: value }))
    setValue(key, value)

    if (!_.isEmpty(key_remove)) {
      _.map(key_remove, (addr, index) => {
        dispatch(setPatient({ key: addr, value: index + 1 === key_remove.length ? '' : 0 }))
        setValue(addr, index + 1 === key_remove.length ? '' : 0)
      })
    }
  }

  const getPatientAddress = (address: any) => {
    props.resetErrorMessage()
    if (address.provinceId) {
      handleSelectAddress('provinceId', address.provinceId, ['districtId', 'subDistrictId', 'postcode'])
    }
    if (address.districtId) {
      handleSelectAddress('districtId', address.districtId, ['subDistrictId', 'postcode'])
    }
    if (address.subDistrictId) {
      handleSelectAddress('subDistrictId', address.subDistrictId, [])
      handleSelectAddress('postcode', address.postcode, [])
    }
  }

  const getPatientWorkAddress = (address: any) => {
    props.resetErrorMessage()
    if (address.provinceId) {
      handleSelectAddress('officeProvinceId', address.provinceId, ['officeDistrictId', 'officeSubDistrictId', 'officePostcode'])
    }
    if (address.districtId) {
      handleSelectAddress('officeDistrictId', address.districtId, ['officeSubDistrictId', 'officePostcode'])
    }
    if (address.subDistrictId) {
      handleSelectAddress('officeSubDistrictId', address.subDistrictId, [])
      handleSelectAddress('officePostcode', address.postcode, [])
    }
  }

  const onDiseaseChange = (e: any, val: any) => {
    const arr: any = _.filter(val, (newV: any, indexNewVal: number) => {
      return val.indexOf(newV) === indexNewVal
    })
    dispatch(setPatient({ key: 'disease', value: arr }))
    setValue('disease', arr)
  }

  const onMedicineUseChange = (e: any, val: any) => {
    const arr: any = _.filter(val, (newV: any, indexNewVal: number) => {
      return val.indexOf(newV) === indexNewVal
    })
    dispatch(setPatient({ key: 'medicineUse', value: arr }))
    setValue('medicineUse', arr)
  }

  const onBeAllergicChange = (e: any, val: any) => {
    const arr: any = _.filter(val, (newV: any, indexNewVal: number) => {
      return val.indexOf(newV) === indexNewVal
    })
    dispatch(setPatient({ key: 'beAllergic', value: arr }))
    setValue('beAllergic', arr)
  }

  const handleChangeEmail = useCallback(
    _.debounce((value: string) => {
      const pattern = /\S+@\S+\.\S+/
      const valid = pattern.test(value)
      setInvalidEmail(!value || valid ? false : true)
    }, 1000),
    []
  )

  const renderBloodPressure = (systolicBloodPressure: string, diastolicBloodPressure: string) => {
    const bloodPressure = systolicBloodPressure + diastolicBloodPressure
    if (bloodPressure && bloodPressure.length > 3) return `${bloodPressure.slice(0, 3)} / ${bloodPressure.slice(3, 6)}`
    else if (bloodPressure) return bloodPressure.slice(0, 3)
    else return ''
  }

  const calculateBMI = (val: number) => {
    let bmiRange = BMI_RANGE.NORMAL_RANGE
    if (val < 18.5) bmiRange = BMI_RANGE.UNDER_WEIGHT
    else if (val >= 25 && val <= 29.9) bmiRange = BMI_RANGE.PRE_OBESE
    else if (val >= 30 && val <= 34.9) bmiRange = BMI_RANGE.OBESE_I
    else if (val >= 35 && val <= 39.9) bmiRange = BMI_RANGE.OBESE_II
    else if (val >= 40) bmiRange = BMI_RANGE.OBESE_III

    return bmiRange
  }

  const handleCheckReqHeader = useCallback((header: string) => {
    if (props?.formReq[header]) {
      return typeof props.formReq[header] === 'boolean' ? props.formReq[header] : handleErrorInput(props.formReq[header])
    }
    return false
  }, [props.formReq])

  const handleErrorInput = (itemError: any) => {
    return Object.values(itemError).some((item) => item)
  }

  useEffect(() => {
    if (props.error?.generalInformation && handleErrorInput(props.error?.generalInformation)) {
      setAccordionTabInfo(true)
      setGeneralInformationTabError(true)
    } else {
      setGeneralInformationTabError(false)
    }

    if (props.error?.addressCard && handleErrorInput(props.error?.addressCard)) {
      setAddressCardTab(true)
      setAddressCardTabError(true)
    } else {
      setAddressCardTabError(false)
    }

    if (props.error?.addressWork && handleErrorInput(props.error?.addressWork)) {
      setAddressWorkTab(true)
      setAddressWorkTabError(true)
    } else {
      setAddressWorkTabError(false)
    }

    if (props.error?.healthInformation && handleErrorInput(props.error?.healthInformation)) {
      setHealthInformationTab(true)
      setHealthInformationTabError(true)
    } else {
      setHealthInformationTabError(false)
    }

    if (props.error?.treatmentRight && handleErrorInput(props.error?.treatmentRight)) {
      setTreatmentRightTab(true)
      setTreatmentRightTabError(true)
    } else {
      setTreatmentRightTabError(false)
    }

    if (props.error?.parent && handleErrorInput(props.error?.parent)) {
      setParentTab(true)
      setParentTabError(true)
    } else {
      setParentTabError(false)
    }

    if (props.error?.vitalSign && handleErrorInput(props.error?.vitalSign)) {
      setVitalSignTab(true)
      setVitalSignTabError(true)
    } else {
      setVitalSignTabError(false)
    }

  }, [props.error])

  useEffect(() => {
    if (!patient.parents || patient.parents === '[]' || patient.parents === 'string') {
      formParents.replace([])
    }
  }, [patient.parents])

  useEffect(() => {
    if (patient.weight && patient.height) {
      const newHeight = patient.height / 100
      const bmi = (patient.weight / (newHeight * newHeight)).toFixed(2)
      const bsa = Math.sqrt((patient.weight * patient.height) / 3600).toFixed(2)
      dispatch(setPatient({ key: ['bmi'], value: bmi }))
      dispatch(setPatient({ key: ['bsa'], value: bsa }))
      setValue('bmi', bmi)
      setValue('bsa', bsa)
    } else {
      dispatch(setPatient({ key: ['bmi'], value: '' }))
      dispatch(setPatient({ key: ['bsa'], value: '' }))
      setValue('bmi', '')
      setValue('bsa', '')
    }
  }, [patient?.weight, patient?.height])

  return (
    <>
      <Accordion defaultExpanded={true} expanded={accordionTabInfo} onChange={() => setAccordionTabInfo(!accordionTabInfo)} className={`${!getBoolean(formRegister.generalInformation) ? 'd-none' : ''} w-100`}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          className={`${generalInformationTabError ? 'error' : ''}`}
        >
          <div className={'header-text'}>
            <h5 className={'required m-0'}>{t('REGISTER.TITLE.INFORMATION')}</h5>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <InputTextField
                hookValue={true}
                disabled={true}
                inputProps={{ readOnly: true, maxLength: 50 }}
                name="cnNumber"
                register={register(`cnNumber`, { value: patient.cnNumber || '' })}
                key={'cn-number'}
                onchange={handleChange}
                required
                value={patient.cnNumber || ''}
                // value={patient.cnNumber && 'XXXXXXX' || ''}
                label={t('REGISTER.FORM.CN_NUMBER')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <InputBranchCnGroup cnLength={_.size(props.branchCode) || _.size(user?.roles?.roleBranch?.branches?.branchCode) || _.size('CN')} className="d-flex flex-nowrap w-100 input-group-cn">
                <span className={`${_.isEmpty(patient) || !patient.branchCnNumber ? 'd-none' : ''} ${disabled ? 'isDisabled' : ''}`}>{props.branchCode || user?.roles?.roleBranch?.branches?.branchCode || 'CN'}</span>
                <InputTextField
                  hookValue={true}
                  disabled={true}
                  inputProps={{ maxLength: 50 }}
                  name="branchCnNumber"
                  register={register(`branchCnNumber`, { value: patient.branchCnNumber || '' })}
                  key={'branch-cn-number'}
                  onchange={handleChange}
                  required
                  helperText={props.error.BRANCH_CN_NUMBER}
                  value={patient.branchCnNumber || ''}
                  // value={patient.branchCnNumber && 'XXXXXXX' || ''}
                  label={t('REGISTER.FORM.BRANCH_NUMBER')}
                />
              </InputBranchCnGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(snType) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 10 }}
                name="snCode"
                key={'sn-code'}
                register={register(`snCode`, { value: patient.snCode, onChange: handleChange })}
                onchange={handleChange}
                value={patient.snCode || ''}
                label={t('REGISTER.FORM.SN_CODE')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.firstnameEn) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="firstnameEn"
                key={'firstname-en'}
                register={register(`firstnameEn`, { value: patient.firstnameEn, onChange: handleChange })}
                onchange={handleChange}
                value={patient.firstnameEn || ''}
                label={t('REGISTER.FORM.FIRST_NAME')}
                required={formRegister?.[`firstnameEn_isRequired`]}
                helperText={props.error?.generalInformation?.firstnameEn && t(`VALIDATION.${props.error?.generalInformation?.firstnameEn}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.lastnameEn) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="lastnameEn"
                key={'lastname-en'}
                register={register(`lastnameEn`, { value: patient.lastnameEn, onChange: handleChange })}
                onchange={handleChange}
                value={patient.lastnameEn || ''}
                label={t('REGISTER.FORM.LAST_NAME')}
                required={formRegister?.[`lastnameEn_isRequired`]}
                helperText={props.error?.generalInformation?.lastnameEn && t(`VALIDATION.${props.error?.generalInformation?.lastnameEn}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <AutocompleteSelect
                labelId="searchPrefixes"
                inputLabel={t('REGISTER.FORM.PREFIXES')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={prefixes}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box key={option.name} component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onchange={(e, value: any) => {
                  if (value) {
                    dispatch(setPatient({ key: 'prefixId', value: value.value }))
                    setValue('prefixId', value.value)
                    props.resetErrorMessage()
                  }
                }}
                disabled={disabled}
                errMessage={props.error?.generalInformation?.prefixId && t(`VALIDATION.${props.error?.generalInformation?.prefixId}`)}
                disableClearable
                required
                value={patient.prefixId ? _.find(prefixes, { value: patient.prefixId }) : null}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <InputTextField
                disabled={disabled}
                hookValue={false}
                inputProps={{ maxLength: 255 }}
                name="firstname"
                key={'firstname'}
                register={register(`firstname`, { value: patient.firstname, onChange: handleChange })}
                onchange={handleChange}
                required
                value={patient.firstname || ''}
                label={t('REGISTER.FORM.FIRST_NAME_TH')}
                helperText={props.error?.generalInformation?.firstname && t(`VALIDATION.${props.error?.generalInformation?.firstname}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="lastname"
                key={'lastname'}
                register={register(`lastname`, { value: patient.lastname, onChange: handleChange })}
                onchange={handleChange}
                required
                helperText={props.error?.generalInformation?.lastname && t(`VALIDATION.${props.error?.generalInformation?.lastname}`)}
                value={patient.lastname || ''}
                label={t('REGISTER.FORM.LAST_NAME_TH')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.displayName) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="displayName"
                key={'display-name'}
                register={register(`displayName`, { value: patient.displayName, onChange: handleChange })}
                onchange={handleChange}
                value={patient.displayName || ''}
                label={t('REGISTER.FORM.DISPLAY_NAME')}
                required={formRegister?.[`displayName_isRequired`]}
                helperText={props.error?.generalInformation?.displayName && t(`VALIDATION.${props.error?.generalInformation?.displayName}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.idCard) ? 'd-none' : ''} w-100`}>
              <InputBranchCnGroup className="w-100 d-flex flex-nowrap input-group-idCard">
                <InputTextField
                  required
                  disabled={disabled}
                  inputProps={{ maxLength: 255 }}
                  name="idCard"
                  key={'id-card'}
                  id="id-card"
                  register={register(`idCard`, {
                    value: patient.idCard,
                    onChange: (e: any) => {
                      const reg = /[^a-zA-Z0-9]/g
                      const value = e.target.value.replace(reg, '')
                      dispatch(setPatient({ key: 'idCard', value: value }))
                      setValue('idCard', value)
                      props.resetErrorMessage()
                    }
                  })}
                  onchange={(e: any) => {
                    const reg = /[^a-zA-Z0-9]/g
                    const value = e.target.value.replace(reg, '')
                    dispatch(setPatient({ key: 'idCard', value: value }))
                    setValue('idCard', value)
                    props.resetErrorMessage()
                  }}
                  value={patient.hasIdCard === 'NOT_HAS' ? t('REGISTER.BUTTON.NOT_SPECIFIED') : patient.idCard || ''}
                  label={t('REGISTER.FORM.ID_CARD')}
                  style={{ pointerEvents: patient.hasIdCard === 'NOT_HAS' ? 'none' : '' }}
                  helperText={props.error?.generalInformation?.idCard ? t(`VALIDATION.${props.error?.generalInformation?.idCard}`) : props?.error?.ID_CARD || ''}
                />
                <ButtonNotSpecified
                  className={`text-nowrap ${patient.hasIdCard === 'NOT_HAS' ? 'is-disabledInputs' : ''} ${props.error.ID_CARD ? 'has-error' : ''} ${i18n.language !== 'th' && 'w-100'}`}
                  onClick={() => {
                    props.error.ID_CARD = ''
                    onClearValue('idCard')
                    dispatch(setPatient({ key: 'hasIdCard', value: patient.hasIdCard === 'NOT_HAS' ? 'HAS' : 'NOT_HAS' }))
                    setValue('hasIdCard', patient.hasIdCard === 'NOT_HAS' ? 'HAS' : 'NOT_HAS')
                    props.resetErrorMessage()
                  }}
                  disabled={disabled}
                  variant="outlined"
                >
                  {t('REGISTER.BUTTON.NOT_SPECIFIED')}
                </ButtonNotSpecified>
              </InputBranchCnGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.passport) ? 'd-none' : ''} w-100`}>
              <InputBranchCnGroup className="w-100 d-flex flex-nowrap input-group-passport">
                <InputTextField
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  name="passport"
                  key={'passport'}
                  id="passport"
                  register={register(`passport`, {
                    value: patient.passport,
                    onChange: (e: any) => {
                      const value = e.target.value
                      dispatch(setPatient({ key: 'passport', value: value }))
                      setValue('passport', value)
                    }
                  })}
                  onchange={(e: any) => {
                    const value = e.target.value
                    dispatch(setPatient({ key: 'passport', value: value }))
                    setValue('passport', value)
                  }}
                  value={patient.hasPassport === 'NOT_HAS' ? t('REGISTER.BUTTON.NOT_SPECIFIED') : patient.passport || ''}
                  label={t('REGISTER.FORM.PASSPORT')}
                  style={{ pointerEvents: patient.hasPassport === 'NOT_HAS' ? 'none' : '' }}
                  required={formRegister?.[`passport_isRequired`]}
                  helperText={props.error?.generalInformation?.passport && t(`VALIDATION.${props.error?.generalInformation?.passport}`)}
                />
                <ButtonNotSpecified
                  className={`text-nowrap ${patient.hasPassport === 'NOT_HAS' ? 'is-disabledInputs' : ''} ${i18n.language !== 'th' && 'w-100'} `}
                  onClick={() => {
                    onClearValue('passport')
                    dispatch(setPatient({ key: 'hasPassport', value: patient.hasPassport === 'NOT_HAS' ? 'HAS' : 'NOT_HAS' }))
                    setValue('hasPassport', patient.hasPassport === 'NOT_HAS' ? 'HAS' : 'NOT_HAS')
                    props.resetErrorMessage()
                  }}
                  disabled={disabled}
                  variant="outlined"
                >
                  {t('REGISTER.BUTTON.NOT_SPECIFIED')}
                </ButtonNotSpecified>
              </InputBranchCnGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.workPermitNumber) ? 'd-none' : ''} w-100`}>
              <InputBranchCnGroup className="w-100 d-flex flex-nowrap input-group-workPermitNumber">
                <InputTextField
                  disabled={disabled}
                  inputProps={{ maxLength: 15 }}
                  name="workPermitNumber"
                  key={'work-permit-number'}
                  id="work-permit-number"
                  register={register(`workPermitNumber`, {
                    value: patient.workPermitNumber,
                    onChange: (e: any) => {
                      const reg = /[^a-zA-Z0-9]/g
                      const value = e.target.value.replace(reg, '')
                      dispatch(setPatient({ key: 'workPermitNumber', value: value }))
                      setValue('workPermitNumber', value)
                      props.resetErrorMessage()
                      props.resetErrorMessage()
                    }
                  })}
                  onchange={(e: any) => {
                    const reg = /[^a-zA-Z0-9]/g
                    const value = e.target.value.replace(reg, '')
                    dispatch(setPatient({ key: 'workPermitNumber', value: value }))
                    setValue('workPermitNumber', value)
                  }}
                  value={patient.hasWorkPermitNumber === 'NOT_HAS' ? t('REGISTER.BUTTON.NOT_SPECIFIED') : patient.workPermitNumber || ''}
                  label={t('REGISTER.FORM.WORK_PERMIT_NUMBER')}
                  style={{ pointerEvents: patient.hasWorkPermitNumber === 'NOT_HAS' ? 'none' : '' }}
                  required={formRegister?.[`workPermitNumber_isRequired`]}
                  helperText={props.error?.generalInformation?.workPermitNumber && t(`VALIDATION.${props.error?.generalInformation?.workPermitNumber}`)}
                />
                <ButtonNotSpecified
                  className={`text-nowrap ${patient.hasWorkPermitNumber === 'NOT_HAS' ? 'is-disabledInputs' : ''} ${i18n.language !== 'th' && 'w-100'}`}
                  onClick={() => {
                    onClearValue('workPermitNumber')
                    dispatch(setPatient({ key: 'hasWorkPermitNumber', value: patient.hasWorkPermitNumber === 'NOT_HAS' ? 'HAS' : 'NOT_HAS' }))
                    setValue('hasWorkPermitNumber', patient.hasWorkPermitNumber === 'NOT_HAS' ? 'HAS' : 'NOT_HAS')
                    props.resetErrorMessage()
                  }}
                  disabled={disabled}
                  variant="outlined"
                >
                  {t('REGISTER.BUTTON.NOT_SPECIFIED')}
                </ButtonNotSpecified>
              </InputBranchCnGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.gender) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`gender`, { value: patient.gender })}
                onchange={handleChange}
                name="gender"
                label={t('REGISTER.FORM.GENDER')}
                selectId="sl-gender"
                value={patient.gender || ''}
                labelId="lb-gender"
                style={{ fontSize: '16px' }}
                options={[
                  <MenuItem key="gender-female" value="FEMALE">
                    {t('REGISTER.FORM.GENDER_OPTION.FEMALE')}
                  </MenuItem>,
                  <MenuItem key="gender-male" value="MALE">
                    {t('REGISTER.FORM.GENDER_OPTION.MALE')}
                  </MenuItem>,
                  <MenuItem key="gender-other" value="OTHER">
                    {t('REGISTER.FORM.GENDER_OPTION.OTHER')}
                  </MenuItem>,
                  <MenuItem key="gender-not-specified" value="NOT_SPECIFIED">
                    {t('REGISTER.FORM.GENDER_OPTION.NOT_SPECIFIED')}
                  </MenuItem>
                ]}
                required={formRegister?.[`gender_isRequired`]}
                helperText={props.error?.generalInformation?.gender && t(`VALIDATION.${props.error?.generalInformation?.gender}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.dateOfBirth) ? 'd-none' : ''} w-100`}>
              <InputBranchCnGroup className="w-100 d-flex flex-nowrap input-group-date">
                <div style={{ flex: 1 }}>
                  {(patient.hasDateOfBirth === 'NOT_HAS' && (
                    <InputTextField
                      hookValue={true}
                      required
                      disabled={disabled}
                      inputProps={{ maxLength: 255 }}
                      name="dateOfBirth"
                      key={'dateOfBirth'}
                      id="dateOfBirth"
                      register={register(`dateOfBirth`, { value: patient.dateOfBirth })}
                      value={patient.hasDateOfBirth === 'NOT_HAS' ? t('REGISTER.BUTTON.NOT_SPECIFIED') : patient.dateOfBirth || ''}
                      label={t('REGISTER.FORM.BIRTHDAY')}
                      style={{ pointerEvents: 'none' }}
                      helperText={props.error?.generalInformation?.dateOfBirth && t(`VALIDATION.${props.error?.generalInformation?.dateOfBirth}`)}
                    />
                  )) || (
                      <InputSecondNewDatePicker
                        key="dateOfBirth"
                        inputName="dateOfBirth"
                        required
                        label={t('REGISTER.FORM.BIRTHDAY')}
                        disabled={disabled}
                        value={patient.dateOfBirth || ''}
                        onchange={(e: any) => handleDateOfBirth(e)}
                        helperText={props.error?.generalInformation?.dateOfBirth && t(`VALIDATION.${props.error?.generalInformation?.dateOfBirth}`)}
                        hideTodayButton
                        {...register(`dateOfBirth`, { value: patient.dateOfBirth })}
                      />
                    )}
                </div>
                <ButtonNotSpecified
                  className={`text-nowrap ${patient.hasDateOfBirth === 'NOT_HAS' ? 'is-disabledInputs' : ''} ${props.error.DATE_OF_BIRTH ? 'has-error' : ''} ${i18n.language !== 'th' && 'w-100'}`}
                  onClick={() => {
                    props.error.DATE_OF_BIRTH = ''
                    onClearValue('dateOfBirth')
                    dispatch(setPatient({ key: 'hasDateOfBirth', value: patient.hasDateOfBirth === 'NOT_HAS' ? 'HAS' : 'NOT_HAS' }))
                    setValue('hasDateOfBirth', patient.hasDateOfBirth === 'NOT_HAS' ? 'HAS' : 'NOT_HAS')
                    props.resetErrorMessage()
                  }}
                  disabled={disabled}
                  variant="outlined"
                >
                  {t('REGISTER.BUTTON.NOT_SPECIFIED')}
                </ButtonNotSpecified>
              </InputBranchCnGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.dateOfBirth) ? 'd-none' : ''} w-100`}>
              <InputTextField
                hookValue={true}
                name="field-age"
                key={'field-age'}
                value={patient.dateOfBirth && patient.hasDateOfBirth !== 'NOT_HAS' ? handleSetAge(patient.dateOfBirth) : ''}
                label={patient.dateOfBirth ? t('REGISTER.FORM.AGE') : ''}
                placeholder={t('REGISTER.FORM.AGE')}
                style={{
                  pointerEvents: 'none',
                  fieldset: { backgroundColor: colors.black12 },
                  '.Mui-focused fieldset': { borderColor: `${colors.black12} !important` },
                  'input::placeholder': {
                    color: colors.black60,
                    opacity: 1
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.nationalityId) ? 'd-none' : ''} w-100`}>
              <AutocompleteSelect
                labelId="searchNationalities"
                inputLabel={t('REGISTER.FORM.NATIONALITY')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={nationalities}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props} key={option.value} >
                    {option.name}
                  </Box>
                )}
                onchange={(e, value: any) => {
                  if (value) {
                    dispatch(setPatient({ key: 'nationalityId', value: value.value }))
                    setValue('nationalityId', value.value)
                  }
                }}
                key={`nationality-id`}
                value={patient.nationalityId ? _.find(nationalities, { value: patient.nationalityId }) : null}
                disabled={disabled}
                disableClearable
                required={formRegister?.[`nationalityId_isRequired`]}
                errMessage={props.error?.generalInformation?.nationalityId && t(`VALIDATION.${props.error?.generalInformation?.nationalityId}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.originId) ? 'd-none' : ''} w-100`}>
              <AutocompleteSelect
                labelId="searchOrigins"
                inputLabel={t('REGISTER.FORM.ORIGINS')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={origins}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props} key={option.value}>
                    {option.name}
                  </Box>
                )}
                onchange={(e, value: any) => {
                  if (value) {
                    dispatch(setPatient({ key: 'originId', value: value.value }))
                    setValue('originId', value.value)
                  }
                }}
                key={`origin-id`}
                value={patient.originId ? _.find(origins, { value: patient.originId }) : null}
                disabled={disabled}
                disableClearable
                required={formRegister?.[`originId_isRequired`]}
                errMessage={props.error?.generalInformation?.originId && t(`VALIDATION.${props.error?.generalInformation?.originId}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.religion) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`religion`, { value: patient.religion })}
                onchange={handleChange}
                name="religion"
                label={t('REGISTER.FORM.RELIGION')}
                selectId="sl-religion"
                value={patient.religion || ''}
                labelId="lb-religion"
                style={{ fontSize: '16px' }}
                options={[
                  <MenuItem key="religion-buddhism" value="BUDDHISM">
                    {t('REGISTER.FORM.RELIGION_OPTION.BUDDHISM')}
                  </MenuItem>,
                  <MenuItem key="religion-christianity" value="CHRISTIANITY">
                    {t('REGISTER.FORM.RELIGION_OPTION.CHRISTIANITY')}
                  </MenuItem>,
                  <MenuItem key="religion-hinduism" value="HINDUISM">
                    {t('REGISTER.FORM.RELIGION_OPTION.HINDUISM')}
                  </MenuItem>,
                  <MenuItem key="religion-islam" value="ISLAM">
                    {t('REGISTER.FORM.RELIGION_OPTION.ISLAM')}
                  </MenuItem>,
                  <MenuItem key="religion-other" value="OTHER">
                    {t('REGISTER.FORM.OTHER')}
                  </MenuItem>,
                  <MenuItem key="religion-not-specified" value="NOT_SPECIFIED">
                    {t('REGISTER.BUTTON.NOT_SPECIFIED')}
                  </MenuItem>
                ]}
                required={formRegister?.[`religion_isRequired`]}
                helperText={props.error?.generalInformation?.religion && t(`VALIDATION.${props.error?.generalInformation?.religion}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.maritalStatus) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`maritalStatus`, { value: patient.maritalStatus })}
                onchange={handleChange}
                name="maritalStatus"
                label={t('REGISTER.FORM.MARITAL')}
                selectId="sl-marital-status"
                value={patient.maritalStatus || ''}
                labelId="lb-marital-status"
                style={{ fontSize: '16px' }}
                options={[
                  <MenuItem key="marital-status-single" value="SINGLE">
                    {t('REGISTER.FORM.MARITAL_OPTION.SINGLE')}
                  </MenuItem>,
                  <MenuItem key="marital-status-marry" value="MARRY">
                    {t('REGISTER.FORM.MARITAL_OPTION.MARRY')}
                  </MenuItem>,
                  <MenuItem key="marital-status-divorce" value="DIVORCE">
                    {t('REGISTER.FORM.MARITAL_OPTION.DIVORCE')}
                  </MenuItem>,
                  <MenuItem key="marital-status-other" value="OTHER">
                    {t('REGISTER.FORM.OTHER')}
                  </MenuItem>,
                  <MenuItem key="marital-status-not-specified" value="NOT_SPECIFIED">
                    {t('REGISTER.BUTTON.NOT_SPECIFIED')}
                  </MenuItem>
                ]}
                required={formRegister?.[`maritalStatus_isRequired`]}
                helperText={props.error?.generalInformation?.maritalStatus && t(`VALIDATION.${props.error?.generalInformation?.maritalStatus}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.careerId) ? 'd-none' : ''} w-100`}>
              <div className={'d-flex align-items-center'}>
                <div className={'w-100 mr-2'}>
                  <AutocompleteSelect
                    labelId="searchCareer"
                    inputLabel={t('REGISTER.FORM.CAREER')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={careers}
                    getOptionLabel={(option: any) => option.name}
                    renderOption={(props, option: any) => (
                      <Box key={option.name} component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      if (value) {
                        dispatch(setPatient({ key: 'careerId', value: value.value }))
                        setValue('careerId', value.value)
                      }
                    }}
                    key={`career-id`}
                    value={patient.careerId ? _.find(careers, { value: patient.careerId }) : null}
                    disabled={disabled}
                    disableClearable
                    required={formRegister?.[`careerId_isRequired`]}
                    errMessage={props.error?.generalInformation?.careerId && t(`VALIDATION.${props.error?.generalInformation?.careerId}`)}
                  />
                </div>
                <Box sx={{ height: '43px', display: 'flex', marginBottom: 'auto' }}>
                  <IconButton className={'p-0'} disabled={disabled} sx={{ color: colors.themeSecondColor, backgroundColor: 'transparent !important' }} component="span" onClick={props.handleFormCareer}>
                    <AddCircleRounded style={{ fontSize: 28 }} />
                  </IconButton>
                </Box>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.school) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="school"
                key={'school'}
                register={register(`school`, { value: patient.school, onChange: handleChange })}
                onchange={handleChange}
                value={patient.school || ''}
                label={t('REGISTER.FORM.SCHOOL')}
                required={formRegister?.[`school_isRequired`]}
                helperText={props.error?.generalInformation?.school && t(`VALIDATION.${props.error?.generalInformation?.school}`)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.accessChannelId) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`accessChannelId`, { value: patient.accessChannelId })}
                onchange={handleChange}
                name="accessChannelId"
                label={t('REGISTER.FORM.ACCESS_CHANNEL')}
                selectId="sl-access-channel-id"
                value={patient.accessChannelId || ''}
                labelId="lb-access-channel-id"
                style={{ fontSize: '16px' }}
                options={_.map(accessChannels, (val: any) => {
                  return (
                    <MenuItem key={`patient-access-channel-${val.accessChannelId}`} value={val.accessChannelId}>
                      {val.channelName}
                    </MenuItem>
                  )
                })}
                required={formRegister?.[`accessChannelId_isRequired`]}
                helperText={props.error?.generalInformation?.accessChannelId && t(`VALIDATION.${props.error?.generalInformation?.accessChannelId}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.accessChannelId) ? 'd-none' : _.get(_.find(accessChannels, { accessChannelId: patient.accessChannelId }), 'isOther') === '1' ? '' : 'd-none'} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="accessChannelOther"
                key={'accessChannelOther'}
                hideControl
                register={register(`accessChannelOther`, { value: patient.accessChannelOther, onChange: handleChange })}
                onchange={handleChange}
                value={patient.accessChannelOther || ''}
                label={t('REGISTER.FORM.ACCESS_CHANNEL_OTHER')}
                required={formRegister?.[`accessChannelId_isRequired`]}
                helperText={props.error?.generalInformation?.accessChannelId && t(`VALIDATION.${props.error?.generalInformation?.accessChannelId}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.serviceFactorId) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`serviceFactorId`, { value: patient.serviceFactorId })}
                onchange={handleChange}
                name="serviceFactorId"
                label={t('REGISTER.FORM.SERVICE_FACTOR')}
                selectId="sl-service-factor-id"
                value={patient.serviceFactorId || ''}
                labelId="lb-service-factor-id"
                style={{ fontSize: '16px' }}
                options={_.map(serviceFactors, (val: any) => {
                  return (
                    <MenuItem key={`patient-service-factor-${val.serviceFactorId}`} value={val.serviceFactorId}>
                      {val.factorName}
                    </MenuItem>
                  )
                })}
                required={formRegister?.[`serviceFactorId_isRequired`]}
                helperText={props.error?.generalInformation?.serviceFactorId && t(`VALIDATION.${props.error?.generalInformation?.serviceFactorId}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.serviceFactorId) ? 'd-none' : _.get(_.find(serviceFactors, { serviceFactorId: patient.serviceFactorId }), 'isOther') === '1' ? '' : 'd-none'} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="serviceFactorOther"
                key={'serviceFactorOther'}
                hideControl
                register={register(`serviceFactorOther`, { value: patient.serviceFactorOther, onChange: handleChange })}
                onchange={handleChange}
                value={patient.serviceFactorOther || ''}
                label={t('REGISTER.FORM.SERVICE_FACTOR_OTHER')}
                required={formRegister?.[`serviceFactorId_isRequired`]}
                helperText={props.error?.generalInformation?.serviceFactorId && t(`VALIDATION.${props.error?.generalInformation?.serviceFactorId}`)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} className={'header-text pt-4'}>
              <h5 className={'m-0'}>{t('REGISTER.TITLE.CONTACT')}</h5>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="phone"
                key={'phone'}
                hideControl
                register={register(`phone`, { value: patient.phone, onChange: handleChange })}
                onchange={handleChange}
                required
                value={patient.phone || ''}
                label={t('REGISTER.FORM.PHONE')}
                helperText={props.error?.generalInformation?.phone && t(`VALIDATION.${props.error?.generalInformation?.phone}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.telephone) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                hideControl
                name="telephone"
                key={'telephone'}
                register={register(`telephone`, { value: patient.telephone, onChange: handleChange })}
                onchange={handleChange}
                value={patient.telephone || ''}
                label={t('REGISTER.FORM.TEL')}
                required={formRegister?.[`telephone_isRequired`]}
                helperText={props.error?.generalInformation?.telephone && t(`VALIDATION.${props.error?.generalInformation?.telephone}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.line) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="line"
                key={'line'}
                register={register(`line`, { value: patient.line, onChange: handleChange })}
                onchange={handleChange}
                value={patient.line || ''}
                label={t('REGISTER.FORM.LINE')}
                required={formRegister?.[`line_isRequired`]}
                helperText={props.error?.generalInformation?.line && t(`VALIDATION.${props.error?.generalInformation?.line}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.email) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="email"
                key={'email'}
                register={register(`email`, { value: patient.email, onChange: handleChange })}
                onchange={handleChange}
                value={patient.email || ''}
                label={t('REGISTER.FORM.EMAIL')}
                required={formRegister?.[`email_isRequired`]}
                helperText={invalidEmail ? t('REGISTER.MESSAGE.EMAIL_PATTERN_ERROR') : props.error?.generalInformation?.email && t(`VALIDATION.${props.error?.generalInformation?.email}`)}
                style={{
                  '.MuiFormHelperText-root.Mui-error': {
                    span: {
                      whiteSpace: 'nowrap'
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.facebook) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="facebook"
                key={'facebook'}
                register={register(`facebook`, { value: patient.facebook, onChange: handleChange })}
                onchange={handleChange}
                value={patient.facebook || ''}
                label={t('REGISTER.FORM.FACEBOOK')}
                required={formRegister?.[`facebook_isRequired`]}
                helperText={props.error?.generalInformation?.facebook && t(`VALIDATION.${props.error?.generalInformation?.facebook}`)}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion >

      <Accordion defaultExpanded={true} expanded={healthInformationTab} onChange={() => setHealthInformationTab(!healthInformationTab)} className={` ${!getBoolean(formRegister.healthInformation) ? 'd-none' : ''} w-100`}>
        <AccordionSummary aria-controls="panel2d-content" className={`${healthInformationTabError ? 'error' : ''}`}>
          <div className={'header-text'}>
            <h5 className={`${handleCheckReqHeader('healthInformation') && 'required'} m-0`}>{t('REGISTER.TITLE.HEALTH_INFORMATION')}</h5>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.patientTypeId) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`patientTypeId`, { value: patient.patientTypeId })}
                onchange={handleChange}
                name="patientTypeId"
                label={t('REGISTER.FORM.PATIENT_TYPE')}
                selectId="sl-patient-type-id"
                value={patient.patientTypeId || ''}
                labelId="lb-patient-type-id"
                style={{ fontSize: '16px' }}
                options={_.map(patientTypes, (val: any) => {
                  return (
                    <MenuItem key={`patient-type-id-${val.patientTypeId}`} value={val.patientTypeId}>
                      {val.patientTypeName}
                    </MenuItem>
                  )
                })}
                required={formRegister?.[`patientTypeId_isRequired`]}
                helperText={props.error?.healthInformation?.patientTypeId && t(`VALIDATION.${props.error?.healthInformation?.patientTypeId}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.bloodType) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`bloodType`, { value: patient.bloodType })}
                onchange={handleChange}
                name="bloodType"
                label={t('REGISTER.FORM.BLOOD_TYPE')}
                selectId="sl-blood-type"
                value={patient.bloodType || ''}
                labelId="lb-blood-type"
                style={{ fontSize: '16px' }}
                options={[
                  <MenuItem key={`blood-type-not-specified`} value="NOT_SPECIFIED">
                    {t('REGISTER.BUTTON.NOT_SPECIFIED')}
                  </MenuItem>,
                  <MenuItem key={`blood-type-o`} value="O">
                    O
                  </MenuItem>,
                  <MenuItem key={`blood-type-a`} value="A">
                    A
                  </MenuItem>,
                  <MenuItem key={`blood-type-b`} value="B">
                    B
                  </MenuItem>,
                  <MenuItem key={`blood-type-ab`} value="AB">
                    AB
                  </MenuItem>
                ]}
                required={formRegister?.[`bloodType_isRequired`]}
                helperText={props.error?.healthInformation?.bloodType && t(`VALIDATION.${props.error?.healthInformation?.bloodType}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.frequencyCigarette) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`frequencyCigarette`, { value: patient.frequencyCigarette })}
                onchange={handleChange}
                name="frequencyCigarette"
                label={t('REGISTER.FORM.FREQUENCY_CIGARETTE')}
                selectId="sl-frequency-cigarette"
                value={patient.frequencyCigarette || ''}
                labelId="lb-frequency-cigarette"
                style={{ fontSize: '16px' }}
                options={_.map(frequenciesCigarettes, (val: any) => {
                  return (
                    <MenuItem key={`frequency-cigarette-id-${val.frequencyId}`} value={val.frequencyName}>
                      {val.frequencyName}
                    </MenuItem>
                  )
                })}
                required={formRegister?.[`frequencyCigarette_isRequired`]}
                helperText={props.error?.healthInformation?.frequencyCigarette && t(`VALIDATION.${props.error?.healthInformation?.frequencyCigarette}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.frequencyAlcohol) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`frequencyAlcohol`, { value: patient.frequencyAlcohol })}
                onchange={handleChange}
                name="frequencyAlcohol"
                label={t('REGISTER.FORM.FREQUENCY_ALCOHOL')}
                selectId="sl-frequency-alcohol"
                value={patient.frequencyAlcohol || ''}
                labelId="lb-frequency-alcohol"
                style={{ fontSize: '16px' }}
                options={_.map(frequenciesAlcohols, (val: any) => {
                  return (
                    <MenuItem key={`frequency-alcohol-id-${val.frequencyId}`} value={val.frequencyName}>
                      {val.frequencyName}
                    </MenuItem>
                  )
                })}
                required={formRegister?.[`frequencyAlcohol_isRequired`]}
                helperText={props.error?.healthInformation?.frequencyAlcohol && t(`VALIDATION.${props.error?.healthInformation?.frequencyAlcohol}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.pregnantHistory) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`pregnantHistory`, { value: patient.pregnantHistory })}
                onchange={handleChange}
                name="pregnantHistory"
                label={t('REGISTER.FORM.PREGNANT_HISTORY')}
                selectId="sl-pregnant-history"
                value={patient.pregnantHistory || ''}
                labelId="lb-pregnant-history"
                style={{ fontSize: '16px' }}
                options={_.map(['NOT_HAS', 'HAS'], (val: string, index: number) => {
                  return (
                    <MenuItem key={`pregnant-history-id-${index}`} value={val}>
                      {t(val)}
                    </MenuItem>
                  )
                })}
                required={formRegister?.[`pregnantHistory_isRequired`]}
                helperText={props.error?.healthInformation?.pregnantHistory && t(`VALIDATION.${props.error?.healthInformation?.pregnantHistory}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.surgeryHistory) ? 'd-none' : ''} w-100`}>
              <BasicSelect
                disabled={disabled}
                register={register(`surgeryHistory`, { value: patient.surgeryHistory })}
                onchange={handleChange}
                name="surgeryHistory"
                label={t('REGISTER.FORM.SURGERY_HISTORY')}
                selectId="sl-surgery-history"
                value={patient.surgeryHistory || ''}
                labelId="lb-surgery-history"
                style={{ fontSize: '16px' }}
                options={_.map(['NOT_HAS', 'HAS'], (val: string, index: number) => {
                  return (
                    <MenuItem key={`surgery-history-id-${index}`} value={val}>
                      {t(val)}
                    </MenuItem>
                  )
                })}
                required={formRegister?.[`surgeryHistory_isRequired`]}
                helperText={props.error?.healthInformation?.surgeryHistory && t(`VALIDATION.${props.error?.healthInformation?.surgeryHistory}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.surgeryDate) ? 'd-none' : ''} w-100`}>
              <InputSecondNewDatePicker
                key="surgeryDate"
                inputName="surgeryDate"
                label={t('REGISTER.FORM.SURGERY_DATE')}

                value={patient.surgeryDate !== "0000-00-00" || patient.surgeryHistory === 'HAS' ? patient.surgeryDate : ''}
                onchange={(e: any) => handleSurgeryDate(e)}
                hideTodayButton
                allowClear
                onClear={() => {
                  dispatch(setPatient({ key: 'surgeryDate', value: '' }))
                  setValue('surgeryDate', '')
                }}
                {...register(`surgeryDate`, { value: patient.surgeryDate })}
                disabled={!patient.surgeryHistory || patient.surgeryHistory === 'NOT_HAS'}
                required={formRegister?.[`surgeryHistory_isRequired`] && !(!patient.surgeryHistory || patient.surgeryHistory === 'NOT_HAS')}
                helperText={(props.error?.healthInformation?.surgeryHistory && !(!patient.surgeryHistory || patient.surgeryHistory === 'NOT_HAS')) ? t(`VALIDATION.${props.error?.healthInformation?.surgeryHistory}`) : ''}
              />

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.disease) ? 'd-none' : ''} w-100`}>
              <AutocompleteSelect
                multiple
                labelId="disease"
                inputLabel={t('REGISTER.FORM.DISEASE')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={disease}
                getOptionLabel={(option: any) => option}
                renderOption={(props, option: any) => (
                  <Box key={option} component="li" {...props}>
                    {option}
                  </Box>
                )}
                renderTags={(tagValue, getTagProps) => _.map(tagValue, (option: any, index: number) => <CustomTagMultiple type="disease" size="small" label={option} {...getTagProps({ index })} />)}
                onchange={onDiseaseChange}
                onInputChange={(e, value: any) => {
                  const text = value.split(/[,]+/)
                  if (text.length > 1) {
                    const val = (_.isEmpty(patient.disease) && []) || (typeof patient.disease === 'string' && patient.disease.split(',')) || patient.disease
                    const item = [...val, text[0]]
                    onDiseaseChange(e, item)
                  }
                }}
                value={(_.isEmpty(patient.disease) && []) || (typeof patient.disease === 'string' && patient.disease.split(',')) || patient.disease}
                key={`disease-id`}
                disabled={disabled}
                popupIcon={<span></span>}
                disableClearable
                freeSolo
                height={96}
                clearOnBlur
                clearOnEscape
                required={formRegister?.[`disease_isRequired`]}
                errMessage={props.error?.healthInformation?.disease && t(`VALIDATION.${props.error?.healthInformation?.disease}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.medicineUse) ? 'd-none' : ''} w-100`}>
              <AutocompleteSelect
                multiple
                labelId="medicine-use"
                inputLabel={t('REGISTER.FORM.MEDICINE_USE')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={medicineUse}
                getOptionLabel={(option: any) => option}
                renderOption={(props, option: any) => (
                  <Box key={option} component="li" {...props}>
                    {option}
                  </Box>
                )}
                renderTags={(tagValue, getTagProps) => _.map(tagValue, (option: any, index: number) => <CustomTagMultiple type="medicine-use" size="small" label={option} {...getTagProps({ index })} />)}
                onchange={onMedicineUseChange}
                onInputChange={(e, value: any) => {
                  const text = value.split(/[,]+/)
                  if (text.length > 1) {
                    const val = (_.isEmpty(patient.medicineUse) && []) || (typeof patient.medicineUse === 'string' && patient.medicineUse.split(',')) || patient.medicineUse
                    const item = [...val, text[0]]
                    onMedicineUseChange(e, item)
                  }
                }}
                value={(_.isEmpty(patient.medicineUse) && []) || (typeof patient.medicineUse === 'string' && patient.medicineUse.split(',')) || patient.medicineUse}
                key={`medicine-use`}
                disabled={disabled}
                popupIcon={<span></span>}
                disableClearable
                freeSolo
                height={96}
                clearOnBlur
                clearOnEscape
                required={formRegister?.[`medicineUse_isRequired`]}
                errMessage={props.error?.healthInformation?.medicineUse && t(`VALIDATION.${props.error?.healthInformation?.medicineUse}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.beAllergic) ? 'd-none' : ''} w-100`}>
              <AutocompleteSelect
                multiple
                labelId="be-allergic"
                inputLabel={t('REGISTER.FORM.BE_ALLERGIC')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={beAllergic}
                getOptionLabel={(option: any) => option}
                renderOption={(props, option: any) => (
                  <Box key={option} component="li" {...props}>
                    {option}
                  </Box>
                )}
                renderTags={(tagValue, getTagProps) => _.map(tagValue, (option: any, index: number) => <CustomTagMultiple type="be-allergic" size="small" label={option} {...getTagProps({ index })} />)}
                onchange={onBeAllergicChange}
                onInputChange={(e, value: any) => {
                  const text = value.split(/[,]+/)
                  if (text.length > 1) {
                    const val = (_.isEmpty(patient.beAllergic) && []) || (typeof patient.beAllergic === 'string' && patient.beAllergic.split(',')) || patient.beAllergic
                    const item = [...val, text[0]]
                    onBeAllergicChange(e, item)
                  }
                }}
                value={(_.isEmpty(patient.beAllergic) && []) || (typeof patient.beAllergic === 'string' && patient.beAllergic.split(',')) || patient.beAllergic}
                key={'be-allergic'}
                disabled={disabled}
                popupIcon={<span></span>}
                disableClearable
                freeSolo
                height={96}
                clearOnBlur
                clearOnEscape
                required={formRegister?.[`beAllergic_isRequired`]}
                errMessage={props.error?.healthInformation?.beAllergic && t(`VALIDATION.${props.error?.healthInformation?.beAllergic}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.importantSymptoms) ? 'd-none' : ''} w-100`}>
              <InputTextarea
                disabled={disabled}
                hookValue={true}
                {...register(`importantSymptoms`, { value: patient.importantSymptoms })}
                label={t('REGISTER.FORM.IMPORTANT_SYMPTOMS')}
                key={'important-symptoms'}
                name="importantSymptoms"
                onchange={handleChange}
                value={patient.importantSymptoms || ''}
                height={96}
                required={formRegister?.[`importantSymptoms_isRequired`]}
                helperText={props.error?.healthInformation?.importantSymptoms && t(`VALIDATION.${props.error?.healthInformation?.importantSymptoms}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.symptoms) ? 'd-none' : ''} w-100`}>
              <InputTextarea
                disabled={disabled}
                hookValue={true}
                {...register(`symptoms`, { value: patient.symptoms })}
                label={t('REGISTER.FORM.SYMPTOMS')}
                key={'symptoms'}
                name="symptoms"
                onchange={handleChange}
                value={patient.symptoms || ''}
                height={96}
                required={formRegister?.[`symptoms_isRequired`]}
                helperText={props.error?.healthInformation?.symptoms && t(`VALIDATION.${props.error?.healthInformation?.symptoms}`)}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.symptoms) ? 'd-none' : ''} w-100`}>
              <InputTextarea
                disabled={disabled}
                hookValue={true}
                {...register(`SYMPTOMS`, { value: patient.symptoms })}
                label={t('REGISTER.FORM.SYMPTOMS')}
                key={'symptoms'}
                name="symptoms"
                onchange={handleChange}
                value={patient.symptoms || ''}
                height={96}
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={2} className={` ${!getBoolean(formRegister.isPregnant) ? 'd-none' : ''} w-100 align-items-center`}>
            <Grid item xs={4} sm={4} md={4} xl={4}  > {t('REGISTER.FORM.IS_PREGNANT')} {formRegister?.[`isPregnant_isRequired`] && <span style={{ color: colors.statusInactive }}>*</span>}</Grid>
            <Grid item xs={4} sm={4} md={4} xl={4} className='d-flex flex-row align-items-center' >
              <InputCheckbox
                className={`checkbox-input`}
                id={`checkbox-input`}
                key={`checkbox-input`}
                disabled={disabled}
                {...register('isPregnant', { value: patient.isPregnant })}
                checked={patient.isPregnant === TRUE_FALSE.TRUE ? true : false}
                label={t('REGISTER.CHECKBOX.YES')}
                onChange={(e) => {
                  const val = e?.target?.checked ? TRUE_FALSE.TRUE : ''
                  dispatch(setPatient({ key: 'isPregnant', value: val }))
                  setValue('isPregnant', val)
                  props.resetErrorMessage()
                }}
              />
              <Form className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-xl-between w-100">
                <Form.Label className="mr-3 mb-0">{t('REGISTER.FORM.PREGNANT_MONTH')}</Form.Label>
                <BasicSelect
                  disabled={patient.isPregnant === TRUE_FALSE.TRUE ? false : true}
                  register={register(`pregnantMonth`, { value: patient.pregnantMonth || null })}
                  onchange={handleChange}
                  name="pregnantMonth"
                  label={t('REGISTER.FORM.SELECT_PREGNANT_MONTH')}
                  selectId="sl-pregnant-month"
                  value={patient.isPregnant === TRUE_FALSE.FALSE ? '' : patient.pregnantMonth || ''}
                  renderValue={() => patient.pregnantMonth === 'NOT_SPECIFIED' ? t(patient.pregnantMonth) : patient.pregnantMonth}
                  labelId="lb-pregnant-month"
                  style={{ fontSize: '16px' }}
                  options={_.map(['NOT_SPECIFIED', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], (val: string, index: number) => {
                    return (
                      <MenuItem key={`pregnant-month-id-${index}`} value={val}>
                        {val === 'NOT_SPECIFIED' ? t(val) : val}
                      </MenuItem>
                    )
                  })}
                />
              </Form>
            </Grid>
            <Grid item xs={4} sm={4} md={4} xl={4}  >
              <InputCheckbox
                className={`checkbox-input`}
                id={`checkbox-input`}
                key={`checkbox-input`}
                {...register('isPregnant', { value: patient.isPregnant })}
                checked={patient.isPregnant === TRUE_FALSE.FALSE ? true : false}
                label={t('REGISTER.CHECKBOX.NO')}
                onChange={(e) => {
                  const val = e?.target?.checked ? TRUE_FALSE.FALSE : ''
                  dispatch(setPatient({ key: 'isPregnant', value: val }))
                  setValue('isPregnant', val)
                  props.resetErrorMessage()
                }}

              />
            </Grid>
          </Grid>
          {props.error?.healthInformation?.isPregnant &&
            <Box sx={{ paddingLeft: '16px', }}>
              <HelperText label={t(`VALIDATION.${props.error?.healthInformation?.isPregnant}`)} />
            </Box>
          }

          <Grid container spacing={2} className={` ${!getBoolean(formRegister.isRecuperate) ? 'd-none' : ''} w-100 align-items-center`}>
            <Grid item xs={4} sm={4} md={4} xl={4}  > {t('REGISTER.FORM.IS_RECUPERATE')} {formRegister?.[`isRecuperate_isRequired`] && <span style={{ color: colors.statusInactive }}>*</span>}</Grid>
            <Grid item xs={4} sm={4} md={4} xl={4} className='d-flex flex-row align-items-center' >
              <InputCheckbox
                className={`checkbox-input`}
                id={`checkbox-input`}
                key={`checkbox-input`}
                disabled={disabled}
                {...register('isRecuperate', { value: patient.isRecuperate })}
                checked={patient.isRecuperate === TRUE_FALSE.TRUE ? true : false}
                label={t('REGISTER.CHECKBOX.HAVE_HISTORY')}
                onChange={(e) => {
                  const val = e?.target?.checked ? TRUE_FALSE.TRUE : ''
                  dispatch(setPatient({ key: 'isRecuperate', value: val }))
                  setValue('isRecuperate', val)
                  props.resetErrorMessage()
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} xl={4}  >
              <InputCheckbox
                className={`checkbox-input`}
                id={`checkbox-input`}
                key={`checkbox-input`}
                disabled={disabled}
                {...register('isRecuperate', { value: patient.isRecuperate })}
                checked={patient.isRecuperate === TRUE_FALSE.FALSE ? true : false}
                label={t('REGISTER.CHECKBOX.NOT_HAVE_HISTORY')}
                onChange={(e) => {
                  const val = e?.target?.checked ? TRUE_FALSE.FALSE : ''
                  dispatch(setPatient({ key: 'isRecuperate', value: val }))
                  setValue('isRecuperate', val)
                  props.resetErrorMessage()
                }}
              />
            </Grid>
          </Grid>
          {props.error?.healthInformation?.isRecuperate &&
            <Box sx={{ paddingLeft: '16px', }}>
              <HelperText label={t(`VALIDATION.${props.error?.healthInformation?.isRecuperate}`)} />
            </Box>
          }

          <Grid container spacing={2} className={` ${!getBoolean(formRegister.isMedicalCertificate) ? 'd-none' : ''} w-100 align-items-center`}>
            <Grid item xs={4} sm={4} md={4} xl={4}  > {t('REGISTER.FORM.IS_MEDICAL_CERTIFICATE')} {formRegister?.[`isMedicalCertificate_isRequired`] && <span style={{ color: colors.statusInactive }}>*</span>}</Grid>
            <Grid item xs={4} sm={4} md={4} xl={4} className='d-flex flex-row align-items-center' >
              <InputCheckbox
                className={`checkbox-input`}
                id={`checkbox-input`}
                key={`checkbox-input`}
                disabled={disabled}
                {...register('isMedicalCertificate', { value: patient.isMedicalCertificate })}
                checked={patient.isMedicalCertificate === TRUE_FALSE.TRUE ? true : false}
                label={t('REGISTER.CHECKBOX.NEED_MEDICAL_CERTIFICATE')}
                onChange={(e) => {
                  const val = e?.target?.checked ? TRUE_FALSE.TRUE : ''
                  dispatch(setPatient({ key: 'isMedicalCertificate', value: val }))
                  setValue('isMedicalCertificate', val)
                  props.resetErrorMessage()
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} xl={4}  >
              <InputCheckbox
                className={`checkbox-input`}
                id={`checkbox-input`}
                key={`checkbox-input`}
                disabled={disabled}
                {...register('isMedicalCertificate', { value: patient.isMedicalCertificate })}
                checked={patient.isMedicalCertificate === TRUE_FALSE.FALSE ? true : false}
                label={t('REGISTER.CHECKBOX.NOT_NEED_MEDICAL_CERTIFICATE')}
                onChange={(e) => {
                  const val = e?.target?.checked ? TRUE_FALSE.FALSE : ''
                  dispatch(setPatient({ key: 'isMedicalCertificate', value: val }))
                  setValue('isMedicalCertificate', val)
                  props.resetErrorMessage()
                }}
              />
            </Grid>
          </Grid>
          {props.error?.healthInformation?.isMedicalCertificate &&
            <Box sx={{ paddingLeft: '16px', }}>
              <HelperText label={t(`VALIDATION.${props.error?.healthInformation?.isMedicalCertificate}`)} />
            </Box>
          }

          <Grid container spacing={2} className={` ${!getBoolean(formRegister.isInsure) ? 'd-none' : ''} w-100 align-items-center`}>
            <Grid item xs={4} sm={4} md={4} xl={4}  > {t('REGISTER.FORM.IS_INSURE')} {formRegister?.[`isInsure_isRequired`] && <span style={{ color: colors.statusInactive }}>*</span>}</Grid>
            <Grid item xs={4} sm={4} md={4} xl={4} className='d-flex flex-row align-items-center' >
              <InputCheckbox
                className={`checkbox-input`}
                id={`checkbox-input`}
                key={`checkbox-input`}
                disabled={disabled}
                {...register('isInsure', { value: patient.isInsure })}
                checked={patient.isInsure === TRUE_FALSE.TRUE ? true : false}
                label={t('REGISTER.CHECKBOX.NEED_INSURE')}
                onChange={(e) => {
                  const val = e?.target?.checked ? TRUE_FALSE.TRUE : ''
                  dispatch(setPatient({ key: 'isInsure', value: val }))
                  setValue('isInsure', val)
                  props.resetErrorMessage()
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} xl={4}  >
              <InputCheckbox
                className={`checkbox-input`}
                id={`checkbox-input`}
                key={`checkbox-input`}
                disabled={disabled}
                {...register('isInsure', { value: patient.isInsure })}
                checked={patient.isInsure === TRUE_FALSE.FALSE ? true : false}
                label={t('REGISTER.CHECKBOX.NOT_NEED_INSURE')}
                onChange={(e) => {
                  const val = e?.target?.checked ? TRUE_FALSE.FALSE : ''
                  dispatch(setPatient({ key: 'isInsure', value: val }))
                  setValue('isInsure', val)
                  props.resetErrorMessage()
                }}
              />
            </Grid>
          </Grid>
          {props.error?.healthInformation?.isInsure &&
            <Box sx={{ paddingLeft: '16px', }}>
              <HelperText label={t(`VALIDATION.${props.error?.healthInformation?.isInsure}`)} />
            </Box>
          }

        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true} expanded={treatmentRightTab} onChange={() => setTreatmentRightTab(!treatmentRightTab)} className={` ${!getBoolean(formRegister.treatmentRight) ? 'd-none' : ''} w-100`}>
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          className={`${treatmentRightTabError ? 'error' : ''}`}
        >
          <div className={'header-text'}>
            <h5 className={`${handleCheckReqHeader('treatmentRight') && 'required'} m-0`}>{t('REGISTER.TITLE.RIGHT_TO_TREATMENT')}</h5>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} className="w-100">
              <ClickAwayListener onClickAway={() => setOpenTreatmentRightSelect(false)}>
                <Box onClick={() => setOpenTreatmentRightSelect(true)}>
                  <AutocompleteSelect
                    multiple
                    labelId="sl-treatment-right"
                    inputLabel={t('REGISTER.TITLE.RIGHT_TO_TREATMENT')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={treatmentRights}
                    getOptionLabel={(option: any) => option.treatmentRightName}
                    renderOption={(props, option: any) => (
                      <Box
                        key={option.treatmentRightId}
                        component="li"
                        {...props}
                        style={{
                          backgroundColor: _.some(patient.treatmentRights, (i: any, index: number) => i.treatmentRightId === option.treatmentRightId) ? colors.themeSecondColor06 : colors.white,
                          color: _.some(patient.treatmentRights, (i: any, index: number) => i.treatmentRightId === option.treatmentRightId) ? colors.themeSecondColor : null
                        }}
                      >
                        {option.treatmentRightName}
                      </Box>
                    )}
                    renderTags={(tagValue, getTagProps) => _.map(tagValue, (option: any, index: number) => <CustomTagMultiple size="small" label={option.treatmentRightName} {...getTagProps({ index })} />)}
                    onchange={(e, value: any, reason: string) => {
                      props.resetErrorMessage()
                      const lastTreatmentRightId = _.get(_.last(value), 'treatmentRightId') || 0
                      const hasTreatment = _.some(patient.treatmentRights, { treatmentRightId: lastTreatmentRightId })
                      let newReason = reason
                      let newTreatmentRights = []
                      if (hasTreatment && newReason !== 'removeOption') {
                        newReason = 'clearTreatment'
                        newTreatmentRights = _.filter(patient.treatmentRights, (tr, index) => {
                          if (tr.treatmentRightId !== lastTreatmentRightId) return tr
                        })
                      } else newTreatmentRights = value
                      const val = _.map(newTreatmentRights, (i: any, index) => {
                        return i.treatmentRightId
                      })
                      renderTreatmentRights(val, newReason)
                      dispatch(setPatient({ key: 'treatmentRights', value: newTreatmentRights }))
                    }}
                    key={'treatment-right'}
                    value={patient.treatmentRights ? patient.treatmentRights : []}
                    disabled={disabled}
                    height={43}
                    open={openTreatmentRightSelect}
                    required={formRegister?.[`treatmentRight_isRequired`]}
                    errMessage={props.error?.treatmentRight?.length && t(`VALIDATION.${props.error?.treatmentRight}`)}
                  />
                </Box>
              </ClickAwayListener>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <p className={'m-0'} style={{ fontSize: 18, fontWeight: 500, color: colors.textPrimary }}>
                  {t('REGISTER.TITLE.RIGHT_TO_TREATMENT_LIST')}
                </p>
              </Grid>
              <Grid item sm={6} md={4} className={'d-none d-sm-block'}>
                <p className={'m-0'} style={{ fontSize: 18, fontWeight: 500, color: colors.textPrimary }}>
                  {t('REGISTER.TITLE.BALANCE')}
                </p>
              </Grid>
            </Grid>
            {(!_.isEmpty(formTreatmentRights.fields) &&
              _.map(formTreatmentRights.fields, (field: any, iTreatmentRights: number) => {
                if (field !== undefined) {
                  return (
                    <Grid key={iTreatmentRights} container alignItems="center" spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <p className={'m-0'} style={{ fontSize: 16, color: colors.textPrimary }}>
                          {iTreatmentRights + 1}. {field?.treatmentRightName}
                          <input type="hidden" {...register(`treatmentRights.${iTreatmentRights}.patientTreatmentRightId`)} value={field.patientTreatmentRightId} />
                          <input type="hidden" {...register(`treatmentRights.${iTreatmentRights}.treatmentRightId`)} value={field.treatmentRightId} />
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className={'d-flex align-items-center'}>
                        <InputTextField
                          disabled={disabled}
                          label=""
                          type="number"
                          inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*', max: 100000 }}
                          key={`treatment-right-${iTreatmentRights}-balance`}
                          register={register(`treatmentRights.${iTreatmentRights}.balance`, {
                            value: getValues(`treatmentRights.${iTreatmentRights}.balance`),
                            onChange: (e: any) => {
                              const value = Number(e.target.value)
                              if (value < 0) {
                                setValue(`treatmentRights.${iTreatmentRights}.balance`, 0)
                              } else if (value > 100000) {
                                setValue(`treatmentRights.${iTreatmentRights}.balance`, 100000)
                              }
                            }
                          })}
                          inputTextAlign="right"
                          style={{ maxWidth: '150px' }}
                        />
                        <p className={'m-0 pl-3'} style={{ fontSize: 16, color: colors.textPrimary }}>
                          {field.unit}
                        </p>
                      </Grid>
                    </Grid>
                  )
                }
              })) || (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className={'m-0'} style={{ fontSize: 16, color: colors.black60 }}>
                      {t('REGISTER.TITLE.NO_RIGHT_TO_TREATMENT_LIST')}
                    </p>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true} expanded={addressCardTab} onChange={() => setAddressCardTab(!addressCardTab)} className={` ${!getBoolean(formRegister.addressCard) ? 'd-none' : ''} w-100`}>
        <AccordionSummary
          aria-controls="panel4d-content"
          id="panel4d-header"
          className={`${addressCardTabError ? 'error' : ''}`}
        >
          <div className={'header-text'}>
            <h5 className={`${handleCheckReqHeader('addressCard') && 'required'} m-0`}>{t('REGISTER.TITLE.ADDRESS_ID_CARD')}</h5>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.countryId) ? 'd-none' : ''} w-100`}>
              <AutocompleteSelect
                labelId="lb-country"
                inputLabel={t('REGISTER.FORM.COUNTRY')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={countries}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props} key={option.value}>
                    {option.name}
                  </Box>
                )}
                onchange={(e, value: any) => {
                  props.resetErrorMessage()
                  if (value) {
                    dispatch(setPatient({ key: 'countryId', value: value.value }))
                    setValue('countryId', value.value)
                  }
                }}
                key={'country'}
                value={patient.countryId ? _.find(countries, { value: patient.countryId }) : null}
                disabled={disabled}
                disableClearable
                required={formRegister?.[`countryId_isRequired`]}
                errMessage={props.error?.addressCard?.countryId && t(`VALIDATION.${props.error?.addressCard?.countryId}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} xl={9} className={` ${!getBoolean(formRegister.address) ? 'd-none' : ''} w-100`}
              sx={{
                'button': {
                  '.MuiOutlinedInput-notchedOutline': {
                    border: props.error?.addressCard?.address && `2px solid ${colors.statusInactive} !important ` || ''
                  }
                }
              }}
            >
              <SelectAddress
                patientId={patient.cnNumber}
                disabled={disabled}
                countryId={patient.countryId}
                provinceId={patient.provinceId}
                districtId={patient.districtId}
                subDistrictId={patient.subDistrictId}
                postcode={patient.postcode}
                provinceText={patient.provinceText}
                districtText={patient.districtText}
                subDistrictText={patient.subDistrictText}
                callbackAddress={getPatientAddress}
                required={formRegister?.[`address_isRequired`]}
              />
              {props.error?.addressCard?.address &&
                <Box>
                  <HelperText label={t(`VALIDATION.${props.error?.addressCard?.address}`)} />
                </Box>
              }

            </Grid>
            <Grid item xs={12} className={` ${!getBoolean(formRegister.address_detail) ? 'd-none' : ''} w-100`}>
              <InputTextarea
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="address"
                key={'address'}
                register={register(`address`, { value: patient.address })}
                onchange={handleChange}
                value={patient.address || ''}
                label={t('REGISTER.FORM.ADDRESS_DETAILS')}
                height={96}
                required={formRegister?.[`address_detail_isRequired`]}
                helperText={props.error?.addressCard?.address_detail && t(`VALIDATION.${props.error?.addressCard?.address_detail}`)}
              />
            </Grid>
            <Grid item xs={12} className={` ${!getBoolean(formRegister.address_detail_en) ? 'd-none' : ''} w-100`}>
              <InputTextarea
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="addressEn"
                key={'addressEn'}
                register={register(`addressEn`, { value: patient.addressEn })}
                onchange={handleChange}
                value={patient.addressEn || ''}
                label={t('REGISTER.FORM.ADDRESS_DETAILS_EN')}
                height={96}
                required={formRegister?.[`address_detail_en_isRequired`]}
                helperText={props.error?.addressCard?.address_detail_en && t(`VALIDATION.${props.error?.addressCard?.address_detail_en}`)}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion >

      <Accordion defaultExpanded={true} expanded={addressWorkTab} onChange={() => setAddressWorkTab(!addressWorkTab)} className={` ${!getBoolean(formRegister.addressWork) ? 'd-none' : ''} w-100`}>
        <AccordionSummary
          aria-controls="panel5d-content"
          id="panel4d-header"
          className={`${addressWorkTabError ? 'error' : ''}`}
        >
          <div className={'header-text'}>
            <h5 className={`${handleCheckReqHeader('addressWork') && 'required'} m-0`}>{t('REGISTER.TITLE.ADDRESS_AT_WORK')}</h5>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.work_countryId) ? 'd-none' : ''} w-100`}>
              <AutocompleteSelect
                labelId="lb-country"
                inputLabel={t('REGISTER.FORM.COUNTRY')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={countries}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props} key={option}>
                    {option.name}
                  </Box>
                )}
                onchange={(e, value: any) => {
                  props.resetErrorMessage()
                  if (value) {
                    dispatch(setPatient({ key: 'officeCountryId', value: value.value }))
                    setValue('officeCountryId', value.value)
                  }
                }}
                key={'officeCountryId'}
                value={patient.officeCountryId ? _.find(countries, { value: patient.officeCountryId }) : null}
                disabled={disabled}
                disableClearable
                required={formRegister?.[`work_countryId_isRequired`]}
                errMessage={props.error?.addressWork?.work_countryId && t(`VALIDATION.${props.error?.addressWork?.work_countryId}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} xl={9} className={` ${!getBoolean(formRegister.work_address) ? 'd-none' : ''} w-100`}
              sx={{
                'button': {
                  '.MuiOutlinedInput-notchedOutline': {
                    border: props.error?.addressCard?.address && `2px solid ${colors.statusInactive} !important ` || ''
                  }
                }
              }}
            >
              <SelectAddress
                patientId={patient.cnNumber}
                disabled={disabled}
                countryId={patient.officeCountryId}
                provinceId={patient.officeProvinceId}
                districtId={patient.officeDistrictId}
                subDistrictId={patient.officeSubDistrictId}
                postcode={patient.officePostcode}
                provinceText={patient.officeProvinceText}
                districtText={patient.officeDistrictText}
                subDistrictText={patient.officeSubDistrictText}
                callbackAddress={getPatientWorkAddress}
                required={formRegister?.[`work_address_isRequired`]}
              />
              {props.error?.addressWork?.work_address &&
                <Box>
                  <HelperText label={t(`VALIDATION.${props.error?.addressWork?.work_address}`)} />
                </Box>
              }
            </Grid>
            <Grid item xs={12} className={` ${!getBoolean(formRegister.work_address_detail) ? 'd-none' : ''} w-100`}>
              <InputTextarea
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="officeAddress"
                key={'officeAddress'}
                register={register(`officeAddress`, { value: patient.officeAddress })}
                onchange={handleChange}
                value={patient.officeAddress || ''}
                label={t('REGISTER.FORM.ADDRESS_DETAILS')}
                height={96}
                required={formRegister?.[`work_address_detail_isRequired`]}
                helperText={props.error?.addressWork?.work_address_detail && t(`VALIDATION.${props.error?.addressWork?.work_address_detail}`)}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true} expanded={parentTab} onChange={() => setParentTab(!parentTab)} className={` ${!getBoolean(formRegister.parent) ? 'd-none' : ''} w-100`}>
        <AccordionSummary
          aria-controls="panel6d-content"
          id="parents-header"
          className={`${parentTabError ? 'error' : ''}`}
        >
          <div className={'header-text'}>
            <h5 className={`${handleCheckReqHeader('parent') && 'required'} m-0`}>
              {t('REGISTER.TITLE.PARENTS')} ({formParents.fields.length})
            </h5>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonAddEmergencyContact
                disableRipple
                disabled={props.disabled}
                variant="text"
                onClick={() => {
                  props.resetErrorMessage()
                  if (formParents.fields.length < 3) {
                    formParents.append({})
                  } else {
                    notiError(t('REGISTER.MESSAGE.PERSON_CONTACT'))
                  }

                }}
              >
                + {t('REGISTER.BUTTON.PARENTS')}
              </ButtonAddEmergencyContact>
            </Grid>
            {props.error?.parent?.length &&
              <Box sx={{ paddingLeft: '16px', }}>
                <HelperText label={t(`VALIDATION.${props.error?.parent}`)} />
              </Box>
            }
            <Grid item xs={12} className={'p-0'}>
              {formParents.fields.map((field: any, iParent: number) => (
                <CardContent key={`parent-${iParent}`} className={'px-0 pb-0 pt-2'}>
                  <Grid container spacing="auto" key={`grid-parent-${iParent}`}>
                    <Row className={'w-100'} style={{ flex: 1 }}>
                      <Col sm className={'pr-2 order-1 order-sm-0'}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4} xl={3}>
                            <InputTextField
                              disabled={disabled}
                              hookValue={false}
                              inputProps={{ maxLength: 255 }}
                              name="fullName"
                              key={'fullName'}
                              register={register(`parents.${iParent}.fullname`, {
                                value: getValues(`parents.${iParent}.fullname`)
                              })}
                              label={t('REGISTER.FORM.FULL_NAME')}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} xl={3}>
                            <InputTextField
                              disabled={disabled}
                              hookValue={false}
                              inputProps={{ maxLength: 255 }}
                              name="relation"
                              key={'relation'}
                              register={register(`parents.${iParent}.relation`, { value: getValues(`parents.${iParent}.relation`) })}
                              label={t('REGISTER.FORM.RELATIONSHIP')}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} xl={3}>
                            <InputTextField
                              disabled={disabled}
                              hookValue={false}
                              inputProps={{ maxLength: 255 }}
                              hideControl
                              name="phone"
                              key={`parent-${iParent}-phone`}
                              register={register(`parents.${iParent}.phone`, { value: getValues(`parents.${iParent}.phone`) })}
                              label={t('REGISTER.FORM.PHONE_NUMBER')}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4} xl={3}>
                            <AutocompleteSelect
                              labelId={`lb-parent-${iParent}-country`}
                              inputLabel={t('REGISTER.FORM.COUNTRY')}
                              noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                              options={countries}
                              getOptionLabel={(option: any) => option.name}
                              renderOption={(props, option: any) => (
                                <Box component="li" {...props} key={option}>
                                  {option.name}
                                </Box>
                              )}
                              onchange={(e, value: any) => {
                                if (value) {
                                  dispatch(setPatient({ key: `parents.${iParent}.countryId`, value: value.value }))
                                  setValue(`parents.${iParent}.countryId`, value.value)
                                }
                              }}
                              key={`lb-parent-${iParent}-country`}
                              value={_.find(countries, { value: getValues(`parents.${iParent}.countryId`) }) || null}
                              disabled={props.disabled}
                              disableClearable
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={8} xl={9}>
                            <SelectAddress
                              disabled={disabled}
                              patientId={`parents.${iParent}.patientId`}
                              countryId={getValues(`parents.${iParent}.countryId`)}
                              provinceId={field.provinceId}
                              districtId={field.districtId}
                              subDistrictId={field.subDistrictId}
                              postcode={field.postcode}
                              callbackAddress={(address: any) => {
                                if (address.provinceId) {
                                  handleSelectAddress(`parents.${iParent}.provinceId`, address.provinceId, [`parents.${iParent}.districtId`, `parents.${iParent}.subDistrictId`, `parents.${iParent}.postcode`])
                                }
                                if (address.districtId) {
                                  handleSelectAddress(`parents.${iParent}.districtId`, address.districtId, [`parents.${iParent}.subDistrictId`, `parents.${iParent}.postcode`])
                                }
                                if (address.subDistrictId) {
                                  handleSelectAddress(`parents.${iParent}.subDistrictId`, address.subDistrictId, [])
                                  handleSelectAddress(`parents.${iParent}.postcode`, address.postcode, [])
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <InputTextarea
                              disabled={disabled}
                              hookValue={false}
                              inputProps={{ maxLength: 255 }}
                              label={t('REGISTER.FORM.ADDRESS_DETAILS')}
                              key={`parent.${iParent}.address`}
                              register={register(`parents.${iParent}.address`, { value: getValues(`parents.${iParent}.address`) })}
                              {...register(`parents.${iParent}.address`, { value: getValues(`parents.${iParent}.address`) })}
                              height={96}
                            />
                          </Grid>
                        </Grid>
                      </Col>
                      <Col sm="auto" className={'pl-2 text-right order-0 order-sm-1 btn-parent-remove'}>
                        <IconButton aria-label="delete" onClick={() => formParents.remove(iParent)} sx={{ backgroundColor: 'transparent !important' }}>
                          <img src={icons.btnTrash} alt="trash" />
                        </IconButton>
                      </Col>
                    </Row>
                  </Grid>
                </CardContent>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true} expanded={vitalSignTab} onChange={() => setVitalSignTab(!vitalSignTab)} className={` ${!getBoolean(formRegister.vitalSign) ? 'd-none' : ''} w-100`}>
        <AccordionSummary
          aria-controls="panel6d-content"
          id="vital-sign-header"
          className={`${vitalSignTabError ? 'error' : ''}`}
        >
          <div className={'header-text'}>
            <h5 className={`${handleCheckReqHeader('vitalSign') && 'required'} m-0`}>{t('REGISTER.TITLE.VITAL_SIGN')}</h5>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.weight) ? 'd-none' : ''} w-100`}>
              <InputTextField
                type="number"
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="weight"
                key={'weight'}
                register={register(`weight`, { value: patient.weight, onChange: handleChange })}
                onchange={handleChange}
                value={patient.weight || ''}
                label={t('REGISTER.FORM.WEIGHT')}
                hideControl
                required={formRegister?.[`weight_isRequired`]}
                helperText={props.error?.vitalSign?.weight && t(`VALIDATION.${props.error?.vitalSign?.weight}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.height) ? 'd-none' : ''} w-100`}>
              <InputTextField
                type="number"
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="height"
                key={'height'}
                register={register(`height`, { value: patient.height, onChange: handleChange })}
                onchange={handleChange}
                value={patient.height || ''}
                label={t('REGISTER.FORM.HEIGHT')}
                hideControl
                required={formRegister?.[`height_isRequired`]}
                helperText={props.error?.vitalSign?.height && t(`VALIDATION.${props.error?.vitalSign?.height}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.bloodPressure) ? 'd-none' : ''} w-100`}>
              <BloodPressureBox>
                <InputTextField
                  disabled={disabled}
                  hookValue={true}
                  inputProps={{ maxLength: 255 }}
                  name="bloodPressure"
                  key={'bloodPressure'}
                  onchange={(e) => {
                    const val = String(e.target.value)
                      .replace(/[^0-9.]/g, '')
                      .slice(0, 6)
                    const sp = val.slice(0, 3)
                    const dp = val.slice(3, 6)
                    setValue('systolicBloodPressure', sp)
                    setValue('diastolicBloodPressure', dp)
                    dispatch(setPatient({ key: ['systolicBloodPressure'], value: sp }))
                    dispatch(setPatient({ key: ['diastolicBloodPressure'], value: dp }))
                    props.resetErrorMessage()
                  }}
                  value={renderBloodPressure(patient.systolicBloodPressure, patient.diastolicBloodPressure)}
                  label={t('REGISTER.FORM.SYSTOLIC_BLOOD_PRESSURE')}
                  placeholder={'xxx / xxx'}
                  required={formRegister?.[`bloodPressure_isRequired`]}
                  helperText={props.error?.vitalSign?.bloodPressure && t(`VALIDATION.${props.error?.vitalSign?.bloodPressure}`)}
                />
              </BloodPressureBox>
            </Grid>

            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.waistline) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="waistline"
                key={'waistline'}
                register={register(`waistline`, { value: patient.waistline, onChange: handleChange })}
                onchange={handleChange}
                value={patient.waistline || ''}
                label={t('REGISTER.FORM.WAISTLINE')}
                required={formRegister?.[`waistline_isRequired`]}
                helperText={props.error?.vitalSign?.waistline && t(`VALIDATION.${props.error?.vitalSign?.waistline}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.temperature) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="temperature"
                key={'temperature'}
                register={register(`temperature`, { value: patient.temperature, onChange: handleChange })}
                onchange={handleChange}
                value={patient.temperature || ''}
                label={t('REGISTER.FORM.TEMPERATURE')}
                required={formRegister?.[`temperature_isRequired`]}
                helperText={props.error?.vitalSign?.temperature && t(`VALIDATION.${props.error?.vitalSign?.temperature}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.pulseRate) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="pulseRate"
                key={'pulseRate'}
                register={register(`pulseRate`, { value: patient.pulseRate, onChange: handleChange })}
                onchange={handleChange}
                value={patient.pulseRate || ''}
                label={t('REGISTER.FORM.PULSE_RATE')}
                required={formRegister?.[`pulseRate_isRequired`]}
                helperText={props.error?.vitalSign?.pulseRate && t(`VALIDATION.${props.error?.vitalSign?.pulseRate}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.heartRate) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="heartRate"
                key={'heartRate'}
                register={register(`heartRate`, { value: patient.heartRate, onChange: handleChange })}
                onchange={handleChange}
                value={patient.heartRate || ''}
                label={t('REGISTER.FORM.HEART_RATE')}
                required={formRegister?.[`heartRate_isRequired`]}
                helperText={props.error?.vitalSign?.heartRate && t(`VALIDATION.${props.error?.vitalSign?.heartRate}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.regIrreq) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="regIrreq"
                key={'regIrreq'}
                register={register(`regIrreq`, { value: patient.regIrreq, onChange: handleChange })}
                onchange={handleChange}
                value={patient.regIrreq || ''}
                label="Reg/Irreq"
                required={formRegister?.[`regIrreq_isRequired`]}
                helperText={props.error?.vitalSign?.regIrreq && t(`VALIDATION.${props.error?.vitalSign?.regIrreq}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.rr) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="rr"
                key={'rr'}
                register={register(`rr`, { value: patient.rr, onChange: handleChange })}
                onchange={handleChange}
                value={patient.rr || ''}
                label="RR"
                required={formRegister?.[`rr_isRequired`]}
                helperText={props.error?.vitalSign?.rr && t(`VALIDATION.${props.error?.vitalSign?.rr}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.o2Sat) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled={disabled}
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="o2Sat"
                key={'o2Sat'}
                register={register(`o2Sat`, { value: patient.o2Sat, onChange: handleChange })}
                onchange={handleChange}
                value={patient.o2Sat || ''}
                label="O2Sat(%)"
                required={formRegister?.[`o2Sat_isRequired`]}
                helperText={props.error?.vitalSign?.o2Sat && t(`VALIDATION.${props.error?.vitalSign?.o2Sat}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.bmi) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="bmi"
                key={'bmi'}
                value={patient.bmi ? t(`${patient.bmi}`) : ''}
                label={t('REGISTER.FORM.BMI')}
                required={formRegister?.[`bmi_isRequired`]}
                helperText={props.error?.vitalSign?.bmi && t(`VALIDATION.${props.error?.vitalSign?.bmi}`)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3} className={` ${!getBoolean(formRegister.bsa) ? 'd-none' : ''} w-100`}>
              <InputTextField
                disabled
                hookValue={true}
                inputProps={{ maxLength: 255 }}
                name="bsa"
                key={'bsa'}
                value={patient.bsa || ''}
                label={t('REGISTER.FORM.BSA')}
                required={formRegister?.[`bsa_isRequired`]}
                helperText={props.error?.vitalSign?.bsa && t(`VALIDATION.${props.error?.vitalSign?.bsa}`)} />

            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
