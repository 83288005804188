import { Box, styled } from "@mui/material";

export const BoxQrCode = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    '.box-container-qrcode': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        padding: '32px 24px',
        '.text-header': {
            width: '100%'
        },
        '.card-qrcode-checkin': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: '32px',
            '.card-qrcode-checkin-content': {
                display: 'flex',
                width: '100%',
                maxWidth: '720px',
                padding: '32px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '24px',
                backgroundColor: '#fff',
                boxShadow: '0px 0px 12px 0px #CCC',
                '.card-qrcode-img': {
                    img: {
                        height: '80px'
                    }
                },
                '.card-qrcode-clinicname': {
                    fontSize: '24px'
                },
                '.card-qrcode-content-qrcode': {
                    width: 'fit-content',
                    height: 'fit-content',
                    position: 'relative',
                    '.card-qrcode-create': {
                        position: 'absolute',
                        top: '0',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#ffffffcc',
                    }
                },
                '.card-qrcode-btn': {
                    maxWidth: '480px',
                    width: '100%',
                    '.MuiButtonBase-root': {
                        width: '100%'
                    }
                }
            }
        }
    }
}))