import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Typography, TableRow, TableCell, Divider, TableHead, TableSortLabel } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  doctors: DentistInterface[]
  filter: any
  filterDateStart: string
  filterDateEnd: string
  componentRef: any
}

export default function RemunerationClinicFeeDentistList(props: FcComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])
  const renderSalaryTable = (data: any) => {
    const items = _.chain(data)
      .map((i: any) => {
        i.dateFormat = toBuddhistYear(moment(i.date), 'DD/MM/YYYY')
        return i
      })
      .groupBy('dateFormat')
      .map((value: any, key: string) => ({ date: key, data: value }))
      .value()
    return items
  }

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.#NO', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.BILL_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DOCTOR', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.PATIENT_CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.PATIENT_SN_CODE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.ITEM', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.PAYMENT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.CASH', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.TRANSFER', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DEBIT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.CREDIT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.ADVANCE', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.NAME_CLINICFEE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.PRICE_CLINICFEE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DF', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.CREDIT_DF', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT_DF', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.NET_DF', align: 'right', class: 'text-nowrap' }
  ]

  const headLabCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.#NO', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DOCTOR', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.PATIENT_CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.PATIENT_SN_CODE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DESCRIPTION', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.LAB_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.JOB', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'Lab Fee', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'DF Lab Fee', align: 'right', class: 'text-nowrap' }
  ]

  const headDFCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.#NO', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DOCTOR', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.NET_PRICE', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'ค่าประกันมือ', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.SUM_PAID', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DF', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.CREDIT_DF', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT_DF', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.SUM_DF', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'DF Lab', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'Net DF', align: 'right', class: 'text-nowrap' }
  ]

  const headTotalCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.INCOME', align: 'right', class: 'text-nowrap' }, //Income
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.NET_CLINIC', align: 'right', class: 'text-nowrap' }, //Net clinic
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DF_NET', align: 'right', class: 'text-nowrap' }, //Net lab
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DENTIST_FREE', align: 'right', class: 'text-nowrap' }, //Dentist Free
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DF_LAB', align: 'right', class: 'text-nowrap' }, //Df lab

    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.CASH', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.TRANSFER', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.DEBIT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.CREDIT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMUNERATION.ADVANCE', align: 'right', class: 'text-nowrap' }
  ]
  if (!snType) {
    headCells.splice(4, 1)
    headLabCells.splice(3, 1)
  }
  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const {
        billNo,
        branchCnNumber,
        snCode,
        patientFullname,
        list,
        amountPaid,
        amountCash,
        amountTransfer,
        amountDebit,
        amountCredit,
        amountTrementRight,
        amountAdvance,
        dfPaid,
        dfLostTreatment,
        creditCost,
        dfNet,
        clinicFeeName,
        clinicFeePrice,
        doctorId
      } = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', label: num, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: billNo || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: _.get(_.find(props.doctors, { dentistId: Number(doctorId) }), 'dentistFullname') || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: branchCnNumber || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: snCode || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: patientFullname || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: list || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountPaid || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountTrementRight || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: clinicFeeName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(clinicFeePrice || 0) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfPaid || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(creditCost || 0, 3, 3), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfLostTreatment || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfNet || 0), class: 'text-nowrap' }
        ]
      }
      if (!snType) {
        objRenderData.columns.splice(4, 1)
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: snType ? 6 : 5 },
          {
            option: 'TEXT',
            align: 'left',
            label: (
              <Typography className="text-right" sx={{ fontWeight: 500, fontSize: exportData ? 10 : '1rem' }}>
                {t('REPORT.TITLE.SUM')}
              </Typography>
            ),
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountPaid')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountCash')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountTransfer')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountDebit')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountCredit')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountTrementRight')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountAdvance')), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 1 },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'clinicFeePrice')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfPaid')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'creditCost'), 3, 3), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfLostTreatment')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfNet')), class: 'text-nowrap' }
        ],
        classes: 'row-summary'
      }

      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  const renderDataLab = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const { dentist, branchCnNumber, snCode, patientFullname, remark, lab, operativeName, labFee, dfLabFee } = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', label: num, class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: dentist || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: branchCnNumber || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: snCode || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: patientFullname || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: remark || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: lab || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: operativeName || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(labFee || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfLabFee || 0), class: 'text-ellipsis' }
        ]
      }
      if (!snType) {
        objRenderData.columns.splice(3, 1)
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: _.size(headLabCells) - 3 },
          {
            option: 'TEXT',
            align: 'left',
            label: (
              <Typography className="text-right" sx={{ fontWeight: 500, fontSize: exportData ? 10 : '1rem' }}>
                {t('REPORT.TITLE.SUM')}
              </Typography>
            ),
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'labFee')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfLabFee')), class: 'text-nowrap' }
        ],
        classes: 'row-summary'
      }

      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  const renderDataDF = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const { doctorId, totalAmount, amountDf, dfCredit, dfLabFee, dfLostCreditAndTreatment, dfLostTreatment, dfNet, handInsurance, amountPaid } = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', label: num, class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: _.get(_.find(props.doctors, { dentistId: Number(doctorId) }), 'dentistFullname') || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(totalAmount || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(handInsurance || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountPaid || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(amountDf || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfCredit || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfLostTreatment || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfLostCreditAndTreatment || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfLabFee || 0), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(dfNet || 0), class: 'text-ellipsis' }
        ]
      }

      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', label: '' },
          {
            option: 'TEXT',
            align: 'left',
            label: (
              <Typography className="text-right" sx={{ fontWeight: 500, fontSize: exportData ? 10 : '1rem' }}>
                {t('REPORT.TITLE.SUM')}
              </Typography>
            ),
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'totalAmount')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'handInsurance')), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountPaid')), class: 'text-ellipsis' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountDf')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfCredit')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfLostTreatment')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfLostCreditAndTreatment')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfLabFee')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfNet')), class: 'text-nowrap' }
        ],
        classes: 'row-summary'
      }

      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  const renderDataTotal = (exportData: boolean) => {
    let sumAmountCash = 0
    let sumAmountTransfer = 0
    let sumAmountDebit = 0
    let sumAmountCredit = 0
    let sumAmountTrementRight = 0
    let sumAmountAdvance = 0
    let sumLab = 0
    let sumAmountPaid = 0
    let sumDf = 0
    let dfNet = 0
    let dfLabFee = 0
    _.map(props.data.rows, (salary, indexSalary: number) => {
      sumAmountPaid += salary.table.map((amountPaidData: any) => amountPaidData.amountPaid).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountCash += salary.table.map((amountCashData: any) => amountCashData.amountCash).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountTransfer += salary.table.map((amountTransferData: any) => amountTransferData.amountTransfer).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountDebit += salary.table.map((amountDebitData: any) => amountDebitData.amountDebit).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountCredit += salary.table.map((amountCreditData: any) => amountCreditData.amountCredit).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountTrementRight += salary.table.map((amountTreatmentRightData: any) => amountTreatmentRightData.amountTrementRight).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
      sumAmountAdvance += salary.table.map((amountAdvanceData: any) => amountAdvanceData.amountAdvance).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
    })
    _.map(props.data.rowLab, (lab, indexLab: number) => {
      sumLab += lab.table.map((sumLabData: any) => sumLabData.labFee).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
    })

    if (props.data.rowDf !== undefined) {
      if (props.data.rowDf.length > 0) {
        sumDf += props.data.rowDf ? props.data.rowDf.map((rowDfData: any) => rowDfData.dfLostCreditAndTreatment).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
        dfNet += props.data.rowDf ? props.data.rowDf.map((rowDfData: any) => rowDfData.dfNet).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
        dfLabFee += props.data.rowDf ? props.data.rowDf.map((rowDfData: any) => rowDfData.dfLabFee).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) : 0
      }
    }

    const netClinic = (sumAmountPaid - dfNet) < 0 ? 0 : (sumAmountPaid - dfNet)
    const objRenderData = {
      key: 'total',
      id: 'total',
      obj: [1],
      columns: [
        { option: 'TEXT', align: 'right', label: numberFormat(sumAmountPaid) }, //Income
        { option: 'TEXT', align: 'right', label: numberFormat(netClinic) }, // Net clinic
        { option: 'TEXT', align: 'right', label: numberFormat(dfNet) },
        { option: 'TEXT', align: 'right', label: numberFormat(dfNet + dfLabFee) },
        { option: 'TEXT', align: 'right', label: numberFormat(dfLabFee) },
        { option: 'TEXT', align: 'right', style: { color: colors.blue }, label: numberFormat(sumAmountCash), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { color: colors.blue }, label: numberFormat(sumAmountTransfer), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { color: colors.blue }, label: numberFormat(sumAmountDebit), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { color: colors.blue }, label: numberFormat(sumAmountCredit), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { color: colors.blue }, label: numberFormat(sumAmountTrementRight), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { color: colors.blue }, label: numberFormat(sumAmountAdvance) }
      ]
    }

    return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
  }
  return (
    <>
      <Box>
        <Typography className={`mb-1`} sx={{ fontSize: '20px', fontWeight: 600 }}>
          <span className="pr-2">{t('REPORT.TABLE.DOCTOR_FEE')}</span>
        </Typography>
        <UseStyled.ResponsiveReportTable className="mt-2">
          <TableCustom
            hidePagination
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={_.map(props.data.rows, (d: any) => {
              return (
                <>
                  <TableRow>
                    <TableCell colSpan={_.size(headCells)} sx={{ fontWeight: '500 !important' }}>
                      <span className="mr-2">{t('MOMENT_AT')}</span>
                      {toBuddhistYear(moment(d.date), 'DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                  {_.map(d.table, (dataTable, indexTable: number) => {
                    return renderData(dataTable, indexTable, false, 'data')
                  })}
                  {renderData(d.table, 0, false, 'sum')}
                </>
              )
            })}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <Divider className="mx-0 mt-4 mb-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

      <Box className="mt-3">
        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Lab Report</Typography>
        <Typography className="mt-2">
          <span className="mr-2">{t('MOMENT_AT')}</span>
          {toBuddhistYear(moment(props.filterDateStart), 'DD/MM/YYYY')}
          <span className="mx-1">-</span>
          {toBuddhistYear(moment(props.filterDateEnd), 'DD/MM/YYYY')}
        </Typography>
        <UseStyled.ResponsiveReportTable className="mt-2">
          <TableCustom
            hidePagination
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headLabCells}
            customScroll
            rowsData={_.map(props.data.rowLab, (d: any) => {
              return (
                <>
                  <TableRow>
                    <TableCell colSpan={_.size(headLabCells)} sx={{ fontWeight: '500 !important' }}>
                      <span className="mr-2">{t('MOMENT_AT')}</span>
                      {toBuddhistYear(moment(d.date), 'DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                  {_.map(d.table, (dataTable, indexTable: number) => {
                    return renderDataLab(dataTable, indexTable, false, 'data')
                  })}
                  {renderDataLab(d.table, 0, false, 'sum')}
                </>
              )
            })}
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <Divider className="mx-0 mt-4 mb-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
      <Box className="mt-3">
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{t(`REPORT.TABLE.REMUNERATION.DENTIST_FREE`)}</Typography>
        <Typography className="mt-2">
          <span className="mr-2">{t('MOMENT_AT')}</span>
          {toBuddhistYear(moment(props.filterDateStart), 'DD/MM/YYYY')}
          <span className="mx-1">-</span>
          {toBuddhistYear(moment(props.filterDateEnd), 'DD/MM/YYYY')}
        </Typography>
        <UseStyled.ResponsiveReportTable className="mt-2">
          <TableCustom
            hidePagination
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headDFCells}
            rowsData={
              !_.isEmpty(props.data.rowDf)
                ? [
                  _.map(props.data.rowDf, (d: any, indexTable: number) => {
                    return renderDataDF(d, indexTable, false, 'data')
                  }),
                  renderDataDF(props.data.rowDf, 0, false, 'sum')
                ]
                : []
            }
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <Divider className="mx-0 mt-4 mb-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

      <Box className="mt-3">
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Total Income Summary</Typography>
        <UseStyled.ResponsiveReportTable className="mt-2">
          <TableCustom
            hidePagination
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headTotalCells}
            rowsData={[renderDataTotal(false)]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>

      <div className="print-show" ref={props.componentRef}>
        <RemunerationExport
          filter={props.filter}
          headCells={headCells}
          headLabCells={headLabCells}
          headDFCells={headDFCells}
          headTotalCells={headTotalCells}
          rowCells={_.map(props.data.rows, (salary) => {
            return (
              <>
                {_.map(renderSalaryTable(salary.table), (d: any) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={_.size(headCells)} style={{ fontWeight: 500, fontSize: 10, border: 0, textAlign: 'left' }}>
                          <span className="mr-2">{t('MOMENT_AT')}</span>
                          {d.date}
                        </td>
                      </tr>
                      {_.map(d.data, (dataTable, indexTable: number) => {
                        return renderData(dataTable, indexTable, true, 'data')
                      })}
                      {renderData(d.data, 0, true, 'sum')}
                    </>
                  )
                })}
              </>
            )
          })}
          rowLabCells={_.map(props.data.rowLab, (d: any) => {
            return (
              <>
                <tr>
                  <td colSpan={_.size(headLabCells)} style={{ fontWeight: 500, fontSize: 10, border: 0, textAlign: 'left' }}>
                    <span className="mr-2">{t('MOMENT_AT')}</span>
                    {toBuddhistYear(moment(d.date), 'DD/MM/YYYY')}
                  </td>
                </tr>
                {_.map(d.table, (dataTable, indexTable: number) => {
                  return renderDataLab(dataTable, indexTable, true, 'data')
                })}
                {renderDataLab(d.table, 0, true, 'sum')}
              </>
            )
          })}
          rowDFCells={[
            _.map(props.data.rowDf, (d: any, indexTable: number) => {
              return renderDataDF(d, indexTable, true, 'data')
            }),
            renderDataDF(props.data.rowDf, 0, true, 'sum')
          ]}
          rowTotalCells={renderDataTotal(true)}
        />
      </div>
    </>
  )
}
export interface Data {
  filter: any
  headCells: any
  headLabCells: any
  headDFCells: any
  headTotalCells: any
  rowCells: any
  rowLabCells: any
  rowDFCells: any
  rowTotalCells: any
}
export function RemunerationExport(props: Data) {
  const { t } = useTranslation()

  const FILTER_TITLES = [
    { key: 'BRANCH', label: 'REPORT.FILTER.BRANCH' },
    { key: 'DATE_START', label: 'REPORT.FILTER.DATE_START' },
    { key: 'DATE_END', label: 'REPORT.FILTER.DATE_END' },
    { key: 'RECEIPT_TYPE', label: 'REPORT.FILTER.RECEIPT_TYPE' },
    { key: 'DOCTOR', label: 'REPORT.FILTER.DOCTOR' },
    { key: 'LAB_GROUP', label: 'REPORT.FILTER.LAB_GROUP' },
    { key: 'LAB_COMPANY', label: 'REPORT.FILTER.LAB_COMPANY' },
    { key: 'OPTION_DF', label: 'REPORT.FILTER.OPTION_DF' }
  ]

  const renderValue = (value: any, index: any) => {
    if (index === 1 || index === 2) {
      return toBuddhistYear(moment(value), 'DD/MM/YYYY')
    }
    return value
  }

  return (
    <>
      <TableHead style={{ fontWeight: 800 }}>{t('REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE_ALL_DENTIST')}</TableHead>
      <table>
        <tr>
          {FILTER_TITLES.map((filter, index) => (
            <TableSortLabel key={index}>
              <td className="mr-2" style={{ fontSize: 10, fontWeight: 800 }}>
                {t(filter.label)} :{' '}
              </td>
              <td style={{ fontSize: 10, fontWeight: 500 }}>{renderValue(props.filter[index], index)}</td>
            </TableSortLabel>
          ))}
        </tr>
      </table>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(props.headCells, (val: any, index: number) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowCells}
            {!props.rowCells.length && (
              <td colSpan={props.headCells.length} style={{ fontSize: 10 }}>
                {t('REPORT.TITLE.NO_DATA')}
              </td>
            )}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(props.headLabCells, (val: any, index: number) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowLabCells}
            {!props.rowLabCells.length && (
              <td colSpan={props.headLabCells.length} style={{ fontSize: 10 }}>
                {t('REPORT.TITLE.NO_DATA')}
              </td>
            )}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(props.headDFCells, (val: any, index: number) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowDFCells}
            {!props.rowDFCells.length && (
              <td colSpan={props.headDFCells.length} style={{ fontSize: 10 }}>
                {t('REPORT.TITLE.NO_DATA')}
              </td>
            )}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(props.headTotalCells, (val: any, index: number) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>{props.rowTotalCells}</tbody>
        </table>
      </UseStyled.Styles>
    </>
  )
}
