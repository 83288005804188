import { IconButton, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { setDisabled, setSelected } from 'app/slice/select.slice'
import { setDisabledBtn } from 'app/slice/button.slice'
import { Col, Form, Row } from 'react-bootstrap'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { icons } from 'constants/images'

type SelectProps = {
  setSearch: (value: any) => void
  removeRecommend: (value: any) => void
  index: number
  key?: any
  value?: any
  className?: string
  disabled?: boolean
  type?: 'button' | 'reset' | 'submit'
}

export default function SelectRecommends(props: SelectProps) {
  const options = useSelector((state: any) => state.select.options)
  const selectedValue = useSelector((state: any) => state.select.value)
  const dispatch = useDispatch()
  const tempOptions = options[props.index]
  const count = props.index

  return (
    <Col sm={12} className="mt-3">
      <Form id={`form-recommends-${count}`} className="col-12 p-0 m-0">
        <Form.Group as={Row} controlId={`form-recommends-${count + 1}`}>
          <Col xs="12">
            <div className='d-flex align-items-center w-100'>
              <p className='m-0 pr-4 text-right' style={{ width: '4%' }}>{'-'}</p>
              <Box className='w-100 pr-3' sx={{ width: { lg: '70% !important', xl: '60% !important' } }}>
                <AutocompleteSelect
                  key={props.key}
                  labelId={props.key}
                  options={options[props.index].map((value: any) => {
                    return { label: value.label, value: value.value }
                  })}
                  onchange={(event, value: any) => {
                    if (value) {
                      dispatch(setSelected({ key: props.index, value: value.value }))
                      dispatch(setDisabled({ key: props.index, value: true }))
                      dispatch(setDisabledBtn(false))
                    }
                  }}
                  getOptionLabel={(option: any) => option.label}
                  onInputChange={(event: any, newValue: any) => props.setSearch(newValue)}
                  value={tempOptions.find((v: any) => v.value === selectedValue[props.index])}
                  isOptionEqualToValue={(option: any, value: any) => option.value === selectedValue[props.index]}
                  disableClearable
                />
              </Box>
              <IconButton disableFocusRipple disableRipple onClick={() => props.removeRecommend(count)} className="p-0" sx={{ backgroundColor: 'transparent' }}>
                <img src={icons.btnMinus} alt="discard" />
              </IconButton>
            </div>
          </Col>
        </Form.Group>
      </Form>
    </Col>
  )
}
