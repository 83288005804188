import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import { routeName } from 'routes/routes-name';
import { Box, MenuItem } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { getBranch } from 'utils/app.utils';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/** COMPONENT */
import Loading from 'component/Loading';
import RemittanceList from './remittanceList';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import InputCheckbox from 'component/Input/InputCheckbox';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker';

/** STYLE */
import * as UseStyled from 'features/report/useStyled';

export default function Remittance() {
    const { t } = useTranslation();
    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);
    const momentNow = moment().format('YYYY-MM-DD');

    const [branchId, setBranchId] = useState<any>(getBranch());
    const [branches, setBranches] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');
    const [loading, setLoading] = useState<boolean>(false);

    const [remittanceData, getRemittanceData] = useState<any[]>([]);
    const [remittanceDate, setRemittanceDate] = useState<string>(momentNow);
    const [remittancePage, setRemittancePage] = useState<number>(1);
    const [remittanceLimitPage, setRemittanceLimitPage] = useState<number>(10);

    const [checkDF, setCheckDf] = useState<boolean>(false);
    const [checkCash, setCheckCash] = useState<boolean>(false);

    const [checkTreatmentRight, setCheckTreatmentRight] = useState<boolean>(false);


    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'REMITTANCE') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    const fetchRemittance = useCallback(() => {
        let condition: any = {
            page: remittancePage, pageLimit: remittanceLimitPage,
            branchId: branchId, date: remittanceDate
        }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (checkDF) condition = { ...condition, df: checkDF === true ? 1 : 0 }
        if (checkTreatmentRight) condition = { ...condition, treatmentRight: checkTreatmentRight === true ? 1 : 0 }
        if (remittanceDate !== null)
            condition = { ...condition, date: remittanceDate }

        ReportApi.reportRemittance(condition)
            .then(({ headers, data }) => {
                getRemittanceData(data)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

    }, [remittancePage, remittanceLimitPage, sortBy, sortType, branchId, remittanceDate, checkDF, checkTreatmentRight])

    const filter = [
        branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
        remittanceDate
    ]

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.REMITTANCE`)} (${remittanceDate})`,
        sheetName: `${t(`REPORT.REPORT_NAME.REMITTANCE`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setRemittanceLimitPage(limit)

    const handleChangePage = (val: number) => setRemittancePage(val)

    /** UseEffect */
    useEffect(() => {
        fetchRemittance()
    }, [fetchRemittance])

    return (
        <div>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.REMITTANCE`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)
                                        setRemittancePage(1)
                                    }}
                                    renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={
                                        _.map(branches, (b: BranchInterface, index) => {
                                            return (
                                                <MenuItem key={b.branchId} value={b.branchId}>
                                                    {b.branchName || b.branchNameEn}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputSecondNewDatePicker
                                    dateFormat={"DD/MM/YYYY"}
                                    placeholder={t('REPORT.FILTER.DATE')}
                                    label={''}
                                    inputHeight={32}
                                    value={remittanceDate}
                                    onchange={(e: any) => {
                                        setRemittanceDate(moment(e).format('YYYY-MM-DD'))
                                        setRemittancePage(1)
                                    }}
                                    views={PICKER_VIEWS.DAY || PICKER_VIEWS.MONTH || PICKER_VIEWS.YEAR}
                                />
                            </Col>

                            <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                                <InputCheckbox
                                    onChange={() => {
                                        setCheckDf(!checkDF)
                                        setRemittancePage(1)
                                    }}
                                    value="1"
                                    label={t('DF')}
                                    checked={checkDF}
                                    style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }} />
                            </Col>

                            <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                                <InputCheckbox
                                    onChange={() => {
                                        setCheckCash(!checkCash)
                                        setRemittancePage(1)
                                    }}
                                    value="true"
                                    label={t('REPORT.FILTER.CASH')}
                                    checked={checkCash}
                                    style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }} />
                            </Col>

                            <Col sm={2} md={2} xl={2} className={'pt-2 px-1'}>
                                <InputCheckbox
                                    onChange={() => {
                                        setCheckTreatmentRight(!checkTreatmentRight)
                                        setRemittancePage(1)
                                    }}
                                    value="true"
                                    label={t('REPORT.FILTER.TREATMENT')}
                                    checked={checkTreatmentRight}
                                    style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }} />
                            </Col>

                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                        <FucntionPrint
                                            content={() => componentRef.current}

                                            removeIfram
                                            trigger={() => <Dropdown.Item >
                                                {t('PDF')}
                                            </Dropdown.Item>}
                                            style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                        />
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row >
                    </Card.Title >
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <RemittanceList
                            page={remittancePage}
                            pageLimit={remittanceLimitPage}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={remittanceData}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                            cash={checkCash}
                            treatmentRight={checkTreatmentRight}
                            df={checkDF}
                            filter={filter}
                        />

                    </Box>
                </Card.Body >
            </Card >
            <Loading show={loading} type='fullLoading' />
        </div >
    )
}