import { useSelector, useDispatch } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { colors } from 'constants/theme'
import { useTranslation } from 'react-i18next'
import 'antd/dist/antd.css'
import * as UseStyled from 'features/setting/personnel-managements/personnels/useStyles'
import { Box, Divider } from '@mui/material'
import _ from 'lodash'

/** UTILS || SLICE */
import { selectLoadingDocument, selelctDocument, editDocument } from 'app/slice/user.slice';

//COMPONENT
import AttachFileIcon from '@mui/icons-material/AttachFile'
import UploadImage from 'component/Upload/UploadImage'
import { useState, useEffect, useRef, useMemo } from 'react';
import CanvasDraw from 'react-canvas-draw'
import { ButtonDeleteReg } from 'component/Button/ButtonDelete'
import { fileUrl } from 'api/api'
import { notiError } from 'component/notifications/notifications'

interface UserAttachmentsInterface {
  userAttachmentId: number | null
  attachmentType: string
  fileId: number
  base64: string
  type: string
  fileExtension: string
  fileSize: string
}
interface DocumentProps {
  isEdit: boolean
  attachment?: any
}

export default function Document(props: DocumentProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const loading = useSelector(selectLoadingDocument)

  const userDoc = useSelector(selelctDocument)
  const [tabSignature, setTabSignature] = useState('DRAW')
  const canvasRef: any = useRef()

  const [signatureAttachments, setSignatureAttachments] = useState<any>({})
  const [uploadSignatureAttachments, setUploadSignatureAttachments] = useState<any>({})

  const [userAttachments, setUserAttachments] = useState<UserAttachmentsInterface[]>([])
  const ID_CARD = {
    userAttachmentId: null,
    attachmentType: 'ID_CARD',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
  const HOUSE_REGISTRATION = {
    userAttachmentId: null,
    attachmentType: 'HOUSE_REGISTRATION',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
  const DENTIST_LICENSE = {
    userAttachmentId: null,
    attachmentType: 'DENTIST_LICENSE',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
  const DENTAL_COUNCIL_BOOK = {
    userAttachmentId: null,
    attachmentType: 'DENTAL_COUNCIL_BOOK',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
  const TRETMENT_FEE_SHEET = {
    userAttachmentId: null,
    attachmentType: 'TRETMENT_FEE_SHEET',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
  const DOCTOR_SCHEDULE = {
    userAttachmentId: null,
    attachmentType: 'DOCTOR_SCHEDULE',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
  const OPERATING_SYSTEM = {
    userAttachmentId: null,
    attachmentType: 'OPERATING_SYSTEM',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
  const PROMOTION = {
    userAttachmentId: null,
    attachmentType: 'PROMOTION',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }
  const IMAGE = {
    userAttachmentId: null,
    attachmentType: 'IMAGE',
    fileId: userAttachments.length,
    base64: 'string',
    type: 'string',
    fileExtension: 'string',
    fileSize: 'string'
  }

  useEffect(() => {
    if (!loading) {
      if (props.isEdit) {
        setUserAttachments(userDoc.userAttachments)
        setSignatureAttachments(userDoc.signatureAttachments)
      }
    }
  }, [loading])

  const setAttachments = (url: string) => {
    const isJpgFile = /\/([^/?]+)\.jpg($|\?)/i.test(url);
    return isJpgFile
  }
  const setAttachments_cache = useMemo(() => setAttachments(signatureAttachments.filePath), [signatureAttachments]);

  async function FiletoBase64(event: any) {
    const blob = event.target.files[0]
    const reader = new FileReader()
    try {
      await new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
      return reader.result
    } catch (error) {
      return
    }
  }

  const addDocument = async (event: any, fileId: any, objType: any) => {
    if (event.target.files != null) {
      const file = event.target.files[0]
      const fileName = file.name.split('.')

      try {
        const name = event.target.files[0].name
        await FiletoBase64(event).then((base64) => {
          let base64string: any = `${base64}`.split(',')

          base64string = base64string[base64string.length - 1]

          dispatch(
            editDocument({
              value: [...userAttachments, { ...objType, fileId: fileId, type: name, fileExtension: fileName[fileName.length - 1], fileSize: file.size.toString(), base64: base64string }],
              key: 'userAttachments'
            })
          )
          setUserAttachments([...userAttachments, { ...objType, fileId: fileId, type: name, fileExtension: fileName[fileName.length - 1], fileSize: file.size.toString(), base64: base64string }]) //can't set base 64
        })
      } catch (error) {
        return
      }
    }
  }

  const hendlerDelete = (clickId: any) => {
    const user: any = userAttachments.filter(({ fileId }: any, index: any) => fileId !== clickId)
    setUserAttachments(user)
    dispatch(editDocument({ value: user, key: 'userAttachments' }))
  }

  const openImgOnInsert = (img: any) => {
    if (img.fileExtension === "pdf") {
      const w = window.open('_blank');
      if (w) {
        w.document.write('<body style="margin: 0px"></body>')
        w.document.write(
          `<div style="background-color:black;text-align:center;height:100%;width:100%">
                <iframe style="min-height:100vh;width:100%" frameborder="0" src=${"data:application/pdf;base64," + img.base64}></iframe>
            </div>`
        )
      }
    }
    if (img.fileExtension !== "pdf") {
      const w = window.open('_blank');
      if (w) {
        w.document.write('<body style="margin: 0px"></body>')
        w.document.write(
          `<div style="background-color:black;text-align:center;height:100%;width:100%">
                <img style="display:block; margin:auto; height:100%;" src=${"data:image/png;base64," + img.base64}></img>
                </div>`
        )
      }
    }
  }

  const renderFileUploadAndPreview = (title: string, option: string) => {
    let addObj: any = ID_CARD
    if (option === 'ID_CARD') addObj = ID_CARD
    if (option === 'HOUSE_REGISTRATION') addObj = HOUSE_REGISTRATION
    if (option === 'DENTIST_LICENSE') addObj = DENTIST_LICENSE
    if (option === 'DENTAL_COUNCIL_BOOK') addObj = DENTAL_COUNCIL_BOOK
    if (option === 'TRETMENT_FEE_SHEET') addObj = TRETMENT_FEE_SHEET
    if (option === 'DOCTOR_SCHEDULE') addObj = DOCTOR_SCHEDULE
    if (option === 'OPERATING_SYSTEM') addObj = OPERATING_SYSTEM
    if (option === 'PROMOTION') addObj = PROMOTION
    if (option === 'IMAGE') addObj = IMAGE
    return (
      <Row className={'mx-0 mt-2 py-1'}>
        <p className="mb-1 pl-0">{t(title)}</p>
        <Col sm={12}>
          {userAttachments
            .filter(({ attachmentType }: any) => attachmentType === option)
            ?.map((obj: any, index: any) => (
              <div key={obj.fileId} className="w-100">
                <Row>
                  <Col sm={'auto'} className="pr-1">
                    <label
                      onClick={() => {
                        let file = _.find(props.attachment, (att) => att?.file?.fileId === obj.fileId)
                        file = !_.isEmpty(file?.file) ? file?.file : ''
                        if (file && file?.filePath && file?.fileName) {
                          return window.open(`${fileUrl}/${file?.filePath}/${file?.fileName}`)
                        }
                        else {
                          return openImgOnInsert(obj);

                        }
                      }}
                      className={`mr-2 d-inline-block text-nowrap-ellipsis ${_.get(_.find(props.attachment, (att) => att?.file?.fileId === obj.fileId), 'file')?.fileName ? 'cursor-pointer' : ''}`}
                      style={{ maxWidth: '300px' }}
                      htmlFor={`contained-button-file-${obj.fileId}`}>
                      <AttachFileIcon className="text-dark align-text-top mr-1" style={{ transform: 'rotate(45deg)', fontSize: '22px' }} />
                      {`${obj.type ? obj.type : 'document'}`}
                    </label>
                  </Col>
                  <Col sm={'auto'} className="px-0">
                    <ButtonDeleteReg onClick={() => hendlerDelete(obj.fileId)} />
                  </Col>
                </Row>
              </div>
            ))}
        </Col>
        <Row>
          <Col>
            <div>
              <input
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                id={`contained-button-file-${option}`}
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => {
                  addDocument(e, addObj.fileId, addObj)
                }}
              />
              <label htmlFor={`contained-button-file-${option}`}>
                <UseStyled.DivAdd className="ac-hover mt-2"> + {t('PERSONNEL.DOCUMENT.ADD_DOCUMENT')}</UseStyled.DivAdd>
              </label>
            </div>
            <Divider sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important`, margin: '16px -16px 0 !important' }} />
          </Col>
        </Row>
      </Row>
    )
  }

  const renderCanvas = () => {
    if (canvasRef.current) {
      const base64 = canvasRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
      let base64string: any = `${base64}`.split(',')

      base64string = base64string[base64string.length - 1]
      const val: any = { ...signatureAttachments, base64: base64string, fileExtension: 'jpg', fileSize: '0', type: 'signature' }

      setSignatureAttachments({ ...signatureAttachments, base64: base64, fileExtension: 'jpg', fileSize: '0', type: 'signature' })
      dispatch(editDocument({ value: val, key: 'signatureAttachments' }))
    }
  }

  const clearCanvas = () => {
    setSignatureAttachments({
      fileId: null,
      base64: '',
      fileExtension: 'jpg',
      fileSize: '0',
      type: 'signature',
    })
    dispatch(editDocument({
      value: {
        fileId: null,
        base64: '',
        type: 'signature',
        fileExtension: '',
        fileSize: '',
        filePath: ''
      }, key: 'signatureAttachments'
    }))
    if (canvasRef.current) {
      canvasRef.current.clear()
      canvasRef.current.imgSrc = ''
      canvasRef.current.resetView()
    }
  }

  const uploadNewsImageSign = (files: any) => {
    if (files[0]) {
      const checkFileName = ["jpg", "png"];
      const file = files[0]
      const fileName = file.name.split('.')
      if (file.size < 2 * 1024 * 1024 && checkFileName.includes(fileName[fileName.length - 1])) {
        const detailImage = {
          fileId: signatureAttachments?.fileId || null,
          base64: '',
          type: 'signature',
          fileExtension: fileName[fileName.length - 1],
          fileSize: file.size.toString(),
        }
        const reader: any = new FileReader()
        reader.addEventListener('load', () => {
          /** reader.result is base64 */
          const base64 = reader.result.split(',')
          setUploadSignatureAttachments({ ...uploadSignatureAttachments, base64: reader.result })
          detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
          dispatch(editDocument({ value: detailImage, key: 'signatureAttachments' }))
        })
        reader.readAsDataURL(file)
        setUploadSignatureAttachments({ ...uploadSignatureAttachments, fileExtension: detailImage.fileExtension, fileSize: detailImage.fileSize, type: 'signature' })
      } else {
        notiError(t('USER.ALERT.FILE_SUPPORT'))
      }

    }
  }

  const clearUploadSign = () => {
    clearCanvas()
    setUploadSignatureAttachments({ fileId: null, base64: '', fileExtension: '', fileSize: '', type: 'signature', filePath: '' })
    dispatch(editDocument({
      value: {
        fileId: null,
        base64: '',
        type: 'signature',
        fileExtension: '',
        fileSize: '',
        filePath: ''
      }, key: 'signatureAttachments'
    }))
  }

  return (
    <div className="p-3">
      <Row className="mx-0">
        <Col xs={12} md={6}>
          <UseStyled.DivHeadSession>{t('PERSONNEL.INPUT.INFORMATION.PERSONAL_DOCUMENT')}</UseStyled.DivHeadSession>
          {renderFileUploadAndPreview(t('PERSONNEL.INPUT.INFORMATION.ID_CARD'), 'ID_CARD')}
          {renderFileUploadAndPreview(t('PERSONNEL.INPUT.INFORMATION.HOUSE_REGISTRATION'), 'HOUSE_REGISTRATION')}
          <Row className={'mx-0 mt-2 py-1'}>
            <p className="mb-1 pl-0">{t('PERSONNEL.INPUT.INFORMATION.SIGNATURE')}</p>
            {!setAttachments_cache && (
              <Col className={'px-2'} sm={10}>
                <div className="overflow-auto w-100 mb-3" style={{ borderBottom: '0.5px sold gray' }}>
                  <a
                    className={`btn mx-3 pb-0 ${uploadSignatureAttachments.base64 ? 'disabled' : ''}`}
                    style={{ borderBottom: tabSignature === 'DRAW' ? `3px solid ${colors.themeSecondColor}` : 'none', borderRadius: 0 }}
                    type={'button'}
                    onClick={(e) => {
                      setTabSignature('DRAW')
                      const canvasCollection = document.getElementsByTagName('canvas')
                      Array.from(canvasCollection).forEach(function (element) {
                        element.style.removeProperty('width')
                        element.style.removeProperty('height')
                      })
                    }}
                  >
                    {t('PERSONNEL.DOCUMENT.DROW_SIGNATURE')}
                  </a>
                  <a className={`btn mx-3 pb-0 ${signatureAttachments.base64 ? 'disabled' : ''}`} style={{ borderBottom: tabSignature === 'UPLOAD' ? `3px solid ${colors.themeSecondColor}` : 'none', borderRadius: 0 }} type={'button'} onClick={(e) => setTabSignature('UPLOAD')}>
                    {t('PERSONNEL.DOCUMENT.UPLOADE')}
                  </a>
                </div>
              </Col>
            )}
          </Row>

          <Row>
            <Col className={'py-1'}>
              <div className="" style={{ display: tabSignature === 'DRAW' ? 'block' : 'none' }}>
                <div style={{ position: 'relative' }}>
                  {signatureAttachments.filePath && setAttachments_cache ? (
                    <img src={signatureAttachments.filePath} alt="" className="py-2" style={{ width: '90%', height: '172px', border: `1px solid ${colors.disabledGray}`, objectFit: 'contain' }} />
                  ) : (
                    <CanvasDraw
                      imgSrc={''}
                      style={{ width: '90%', height: '172px', border: `1px solid ${colors.disabledGray}` }}
                      brushRadius={2}
                      lazyRadius={2}
                      hideInterface
                      hideGrid
                      brushColor={colors.black}
                      ref={canvasRef}
                      onChange={renderCanvas}
                    />
                  )}

                  <div
                    className='text-center'
                    onClick={clearCanvas}
                    style={{ width: '85px', position: 'absolute', padding: '6px 17px 5px 15px', top: '5%', right: '11%', color: colors.themeThirdColor, border: `1px solid ${colors.themeThirdColor}`, borderRadius: '4px', fontSize: '16px', cursor: 'pointer' }}>
                    {t('PERSONNEL.BUTTON.CLEAR')}
                  </div>
                </div>
              </div>
              <Box className="py-1 position-relative" sx={{
                display: tabSignature === 'UPLOAD' ? 'block' : 'none',
                width: '90%',
                height: '175px',
                '.MuiFormControl-root': {
                  height: '172px',
                  img: {
                    objectFit: 'contain',
                    height: '100%'
                  }
                }
              }}>
                <UploadImage
                  setValue={uploadNewsImageSign}
                  pathFile={signatureAttachments.filePath || uploadSignatureAttachments.base64}
                />
                <div
                  onClick={clearUploadSign}
                  className='text-center'
                  style={{ width: '85px', position: 'absolute', padding: '6px 17px 5px 15px', top: '15px', right: '10px', color: colors.themeThirdColor, border: `1px solid ${colors.themeThirdColor}`, borderRadius: '4px', fontSize: '16px', cursor: 'pointer' }}>
                  {t('PERSONNEL.BUTTON.CLEAR')}
                </div>
                <div className='d-flex justify-content-center' style={{ height: '24px', marginTop: '8px' }}>
                  {t(`USER.ALERT.FILE_SUPPORT`)}
                </div>
              </Box>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <UseStyled.DivHeadSession>{t('PERSONNEL.DOCUMENT.DOCUMENT_DOCTOR')}</UseStyled.DivHeadSession>
          {renderFileUploadAndPreview(t('PERSONNEL.INPUT.DOCTOR_LICENSE'), 'DENTIST_LICENSE')}
          {renderFileUploadAndPreview(t('PERSONNEL.INPUT.DOCTOR_COUNCIL_BOOK'), 'DENTAL_COUNCIL_BOOK')}
          {renderFileUploadAndPreview(t('PERSONNEL.DOCUMENT.TREAMENT_FEE_SHEET'), 'TRETMENT_FEE_SHEET')}
          {renderFileUploadAndPreview(t('PERSONNEL.DOCUMENT.SHIFT_SHEDULE'), 'DOCTOR_SCHEDULE')}
          {renderFileUploadAndPreview(t('PERSONNEL.DOCUMENT.OPERATING_SYSTEM'), 'OPERATING_SYSTEM')}
        </Col>
        <Col xs={12} sm={12} className="mt-sm-5">
          <UseStyled.DivHeadSession>{t('PERSONNEL.DOCUMENT.ADDITIONAL_DOCUMENTS')}</UseStyled.DivHeadSession>
          <Row className="mx-0">
            <Col xs={12} md={6}>
              {renderFileUploadAndPreview(t('PERSONNEL.DOCUMENT.PROMOTION'), 'PROMOTION')}
            </Col>
            <Col xs={12} md={6}>
              {renderFileUploadAndPreview(t('PERSONNEL.DOCUMENT.IMAGE'), 'IMAGE')}
            </Col>
          </Row>
        </Col>
      </Row >
    </div >
  )
}
