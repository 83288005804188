import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box } from '@mui/material'
import { UseSnCode } from 'utils/useSnCode'
import { useTranslation } from 'react-i18next'
/** COMPONENT */
import { numberFormat, toBuddhistYear } from "utils/app.utils"
import TableRowCommon from 'component/Table/TableRowCommon'
import TableRowExport from 'component/Pdf/TableRowExport'
import TableCustom from 'component/Table/TableCustom'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { SORT_TYPE } from 'constants/common'
interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    data: any
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    onRequestSort: (sortBy: string, sortType: string) => void
}

export default function TreatmentRightBalanceList(props: FcComponent) {
    const { t } = useTranslation()
    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);
    useEffect(() => {
        handleSnCode()
    }, [])
    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.#', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.CN', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.BRANCH_CN_NUMBER', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.SN_CODE', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.PATIENT', align: 'left', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.TREATMENT_RIGHT', align: 'left', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.UNIT', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.AMOUNT', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.AMOUNT_USED', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_BALANCE.BALANCE', align: 'center', class: 'text-nowrap', },

    ]
    if (!snType) {
        headCells.splice(3, 1)
    }
    const renderData = (data: any, no: number) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1
        const columns = [
            { option: 'TEXT', align: 'center', label: num, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.cnNumber, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.branchCnNumber, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.snCode ?? '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.patientFullName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.treatmentRightName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.unit || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.unit === t('REPORT.UNIT.BATH') ? numberFormat(data.amount) : data.amount, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.unit === t('REPORT.UNIT.BATH') ? numberFormat(data.amountUsed) : data.amountUsed, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.unit === t('REPORT.UNIT.BATH') ? numberFormat(data.balance) : data.balance, class: 'text-nowrap' },
        ]
        if (!snType) {
            columns.splice(3, 1)
        }
        return <>
            <TableRowCommon
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={columns} />
        </>
    }

    return <>
        <Box>
            <UseStyled.ResponsiveReportTable>
                <TableCustom
                    rowHeadCell={2}
                    tableMinWidth={992}
                    page={props.page}
                    pageLimit={props.pageLimit}
                    sortType={''}
                    sortBy={''}
                    rowCount={props.rowCount}
                    onSort={(val) => { return }}
                    setPageLimit={props.handleChangeRowsPerPage}
                    setPage={props.handleChangePage}
                    headCells={headCells}
                    rowsData={_.map(props.data, (d: any, index: number) => {
                        return renderData(d, index)
                    })}
                    customScroll
                />
            </UseStyled.ResponsiveReportTable>
        </Box>
    </>
}