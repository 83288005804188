import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { Col } from 'react-bootstrap'
import { Typography, Button } from '@mui/material';

export const ColLabGroup = styled(Col)(({ theme }) => ({
  paddingBottom: 24
}))

export const TitleLabType = styled(Typography)(({ theme }) => ({
  fontWeight: 600, color: colors.themeMainColor,
  fontSize: 18,
  paddingBottom: 16,
}))
export const BtnAdd = styled(Button)(({ theme }) => ({
  color: colors.themeSecondColor,
  fontSize: 16,
  '&:hover': {
    backgroundColor: `${colors.lightGray} !important`
  }
}))

export const HeaderTable = styled(Typography)(({ theme }) => ({
  fontSize: 16
}))

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.borderInput
    }
  },
  header: {
    backgroundColor: colors.themeMainColor,
    color: `${colors.white} !important`,
    '& $th': {
      backgroundColor: colors.themeMainColor,
      color: `${colors.white} !important`,
      padding: 15
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: `${colors.white} !important`
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      color: `${colors.white} !important`,
      fontSize: 16
    },

    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: `${colors.white} !important`
    }
  },
  body: {
    borderRight: `1px solid ${colors.black20} !important`,
    borderLeft: `1px solid ${colors.black20} !important`,
    '& .sm-data-table .MuiTableCell-root': {
      padding: '10px 10px !important'
    }
  },
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  columeHover: {
    fontSize: '16px',
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.lightGray} !important`
    }
  },
  inputDisable: {
    backgroundColor: colors.themeThirdColor
  },
  btnSidebar: {

    padding: '3px !important',
    width: 'fit-contant',
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    borderRadius: '10px 0px 0px 10px !important'
  },
  btnDiscard: {

    padding: '3px !important',
    width: 'fit-contant',
    backgroundColor: `${colors.white}  `,

    border: `solid 1px ${colors.themeSecondColor}  !important`
  },
  icon: {
    color: colors.white,
    cursor: 'pointer',

    marginRight: '5px'
  }
}))

export default useStyles
