import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface RolesInterface {
  positionName: string
  positionNameEn?: string
  status: string
}
enum true_false {
  TRUE = 1,
  FALSE = 0
}

export interface RolePermissionsInterface {
  branchId: number
  servicePointId: number
  menuFirstId: number
  menuSecondId: number
  menuThirdId: number
  isView: true_false
  isCreate: true_false
  isUpdate: true_false
  isDelete: true_false
  isExport: true_false
}

export interface RoleInterface {
  roleId?: number
  roleName: string
  roleNameEn: string
  status?: any
  rolePermissions?: RolePermissionsInterface[]
  roleBranches?: any[]
}

export default class RolesApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('roles', { params: { ...props } }).then((res) => res)
  }
  static findAllwithbranchs(props: FindAllInterface): Promise<any> {
    return this.api.get('roles/find-all-with-branchs', { params: { ...props } }).then((res) => res)
  }
  static create(playload: RoleInterface) {
    return this.api.post('roles', playload)
  }
  static findById(id: number): Promise<any> {
    return this.api.get(`roles/${id}`)
  }
  static update(id: number, body: RoleInterface) {
    return this.api.patch(`roles/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`roles/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`roles/${id}`)
  }
  static findAllBranch() {
    return this.api.get(`roles/branchs`)
  }
  static findAllServicePointMenu(props: any) {
    return this.api.get(`roles/service-point-menus`, { params: { ...props } })
  }
}
