import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, FormControlLabel, MenuItem, RadioGroup } from '@mui/material'
import { routeName } from 'routes/routes-name'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { getBranch, getClinicInfo } from 'utils/app.utils'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import LabGroupsApi from 'api/setting/default-labs/lab-group.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import LabCompanyApi from 'api/setting/default-labs/lab-company.api'
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'

/** COMPONENT */
import Loading from 'component/Loading'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import RemunerationClinicFeeDentistList from './RemunerationClinicFeeDentistList'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import ButtonCustom from 'component/Button/ButtonCustom'

import { ReportFormulaDF, optionFormula } from '../report-option-df'
import InputRadio from 'component/Input/InputRadio'
import { KEY_STORAGE } from 'constants/common'
import AboutUs from 'api/admin/clinic-manage/about.api'
import { useDispatch } from 'react-redux'
import { editBranchInfo } from 'app/slice/user.slice'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

export default function RemunerationClinicFeeDentist() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange
  const dispatch = useDispatch()

  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches] = useState<any[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [loading, setLoading] = useState<boolean>(true)
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])
  const [prefix, setPrefix] = useState('ALL')
  const [selectedOptionPrice, setSelectedOptionPrice] = useState<any>(localStorage.getItem(KEY_STORAGE.REPORT_CONDITION) || 'TREATMENT')

  const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [selectDoctors, setSelectDoctors] = useState<any[]>(['ALL'])

  const [labGroup, setLabGroup] = useState<any[]>([])
  const [labCompany, setLabCompany] = useState<any[]>([])
  const [labGroupId, setLabGroupId] = useState<number>(0)
  const [labCompanyId, setLabCompanyId] = useState<number>(0)

  const [optionDf, setOptionDf] = useState<'0' | '1' | '2'>('1')

  const [remunerationCfDentistData, getRemunerationCfDentistData] = useState<any[]>([])
  const [remunerationCfDentistPage, setRemunerationCfDentistPage] = useState<number>(1)
  const [remunerationCfDentistTypePage, setRemunerationCfDentistTypePage] = useState<number>(1)
  const [remunerationCfDentistLimitPage, setRemunerationCfDentistLimitPage] = useState<number>(10)
  const [checkDF, setCheckDF] = useState<boolean>(false)

  const [popFormula, setPopFormula] = useState<boolean>(false)
  const [imageFormula, setImageFormula] = useState<string>('')

  const branchInfo = getClinicInfo()

  const loadPermissExport = useCallback(async () => {
    const branchId = getBranch()
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        dispatch(editBranchInfo({ value: { ...data } }))
        localStorage.setItem(KEY_STORAGE.CLINIC_INFO, JSON.stringify({ ...data, lastUpdate: moment().format('YYYY-MM-DD HH:mm') }))
      })
      .catch((e) => { return })
  }, [])

  useEffect(() => {
    loadPermissExport()
  }, [])

  useEffect(() => {
    localStorage.setItem(KEY_STORAGE.REPORT_CONDITION, (branchInfo?.defaultDfReport || '0').toString())
    setSelectedOptionPrice(branchInfo?.defaultDfReport || '0')
    setOptionDf(branchInfo?.defaultDfCon || '1')
  }, [branchInfo])

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    setOptionDf(getClinicInfo().defaultDfCon)
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'REMUNERATION_CLINIC_FEE_ALL_DENTIST') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    ClinicApi.allBillPrefix()
      .then((resp) => setReceiptPrefix(resp.data))
      .catch((e) => {
        return
      })

    ClinicApi.findAllDoctors()
      .then((resp) => {
        setDoctors(resp.data)
        const dentists: DentistInterface[] = resp?.data?.map((d: any) => {
          return {
            dentistId: d.userId,
            dentistFullname: d.fullname
          }
        })
        setDoctorsMultiple(dentists)
      })
      .catch((e) => {
        return
      })

    LabGroupsApi.findAll({ page: 1, pageLimit: 100, status: 'ACTIVE' })
      .then(({ data: dataLabGroup }) => {
        setLabGroup(dataLabGroup)
      })
      .catch((e) => {
        return
      })

    LabCompanyApi.findAll({ page: 1, pageLimit: 100, status: 'ACTIVE' })
      .then(({ data: dataLabCompany }) => {
        setLabCompany(dataLabCompany)
      })
      .catch((e) => {
        return
      })

    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
      const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
        return {
          dentistId: d.userId,
          dentistFullname: d.fullname
        }
      })
      // setDoctorsMultiple(dentists)
      setSelectDoctors(['ALL', ...dentists.map((d: DentistInterface) => d.dentistId)])
    })
    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [branchId])

  const fetchRemunerationClinicFee = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: remunerationCfDentistPage,
      pageLimit: remunerationCfDentistLimitPage,
      branchId: branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1],
      labCompanyId: labCompanyId,
      labGroupId: labGroupId,
      optionPrice: selectedOptionPrice

    }

    if (selectDoctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }
    if (prefix) condition = { ...condition, prefix: prefix === 'ALL' ? '' : prefix }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (optionDf) condition = { ...condition, optionDf: Number(optionDf) }
    if (rangeDate) {
      if ((rangeDate[0] !== null || rangeDate[0] !== '') && (rangeDate[1] !== null || rangeDate[1] !== '')) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }

    ReportApi.reportRemittanceClinicFeeAllDentist(condition)
      .then(({ headers, data }) => {
        getRemunerationCfDentistData(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [sortBy, sortType, branchId, labCompanyId, labGroupId, remunerationCfDentistTypePage, remunerationCfDentistPage, remunerationCfDentistLimitPage, rangeDate, selectDoctors, prefix, doctors, optionDf, selectedOptionPrice])

  const setDoc = (data: any) => {
    const sortedSelectData = data.sort((a: any, b: any) => {
      const idA = a.dentistId
      const idB = b.dentistId
      return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB)
    })
    return sortedSelectData
  }
  const filter = [
    branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
    rangeDate[0],
    rangeDate[1],
    prefix === 'ALL' ? `${t(`REPORT.FILTER.ALL`)}` : prefix,
    _.isEmpty(selectDoctors) || _.includes(selectDoctors, '')
      ? ` ${t('REPORT.FILTER.NO_SELECT')}`
      : _.includes(selectDoctors, 'ALL')
        ? `${t('REPORT.FILTER.ALL')}`
        : `${setDoc(doctorsMultiple.filter((d: any) => selectDoctors.includes(d.dentistId)))
          ?.map((d: any) => d.dentistFullname)
          .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
          .join(', ')}`,
    labGroupId ? `${_.get(_.find(labGroup, { labGroupId: labGroupId }), 'labGroupName' || 'labGroupNameEn')} ` : `${t(`REPORT.FILTER.ALL`)}`,
    labCompanyId ? `${_.get(_.find(labCompany, { labCompanyId: labCompanyId }), 'labCompanyName' || 'labCompanyNameEn')} ` : `${t(`REPORT.FILTER.ALL`)}`,
    optionDf === '0' ? t('REPORT.FILTER.NO_CAL_DF') : t(`REPORT.FILTER.DF${optionDf}`)
  ]
  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE_ALL_DENTIST`)} (${rangeDate[0]} - ${rangeDate[1]})`,
    sheetName: `${t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE_ALL_DENTIST`)}`
  })

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setRemunerationCfDentistLimitPage(limit)

  const handleChangePage = (val: number) => setRemunerationCfDentistPage(val)

  const handleFilterDoctors = (e: any) => {
    setRemunerationCfDentistPage(1)
    setRemunerationCfDentistLimitPage(1)
    const value = e.target?.value
    const valLength: number = value.length
    if (selectDoctors.includes('ALL') === true && value.includes('ALL') === false) setSelectDoctors([''])
    else if (value[valLength - 1] === 'ALL' || (doctors.length === valLength && !value.includes('ALL'))) setSelectDoctors(['ALL', ...doctorsMultiple.map((d: DentistInterface) => d.dentistId)])
    else if (value[valLength - 1] === '' || valLength === 0) setSelectDoctors([''])
    else setSelectDoctors(value.filter((v: any) => v > 0))
  }

  useEffect(() => {
    fetchRemunerationClinicFee()
  }, [fetchRemunerationClinicFee])

  useEffect(() => {
    if (imageFormula === '') {
      setPopFormula(false)
    } else {
      setPopFormula(true)
    }
  }, [imageFormula])

  return (
    <div>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.REMUNERATION_CLINIC_FEE_ALL_DENTIST`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col xs={12}>
                <Box className={`d-flex align-items-center`} sx={{ gap: '24px' }}>
                  <Box className={`d-flex align-items-center gap-2`}>
                    <Box sx={{ fontSize: "1rem", fontWeight: '400' }}>
                      {t('REPORT.FILTER.DISPLAY_DF')} :
                    </Box>
                    <Box sx={{ fontSize: "1rem", fontWeight: '400' }}>
                      {t(`REPORT.FILTER.DEFAULTDF_${branchInfo?.defaultDfReport}`)}
                    </Box>
                  </Box>
                  <Box className={`d-flex align-items-center gap-2`}>
                    <Box sx={{ fontSize: "1rem", fontWeight: '400' }}>
                      {t('BRANCH.TAB.SENTTING.CONDITION_REPORT_DF')} :
                    </Box>
                    <Box sx={{ fontSize: "1rem", fontWeight: '400' }}>
                      {t(`REPORT.FILTER.DF_${branchInfo?.defaultDfCon}`)}
                    </Box>
                    <Box>
                      <ButtonCustom
                        textButton={t('REPORT.FILTER.CONDITION')}
                        variant={'outlined'}
                        btnStyle={{ height: "24px" }}
                        onClick={(event) => {
                          event.stopPropagation()
                          setImageFormula(optionFormula[`DF_${branchInfo?.defaultDfCon || 0}`] || '')
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Col>
              {/* hidden old defaultDfReport */}
              {/* <RadioGroup
                row
                value={selectedOptionPrice}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="d-inline"
                onChange={(e) => {
                  localStorage.setItem(KEY_STORAGE.REPORT_CONDITION, e.target.value.toString())
                  setSelectedOptionPrice(e.target.value)
                }}
              >
                <FormControlLabel value="TREATMENT" control={<InputRadio />} label={t('REPORT.FILTER.PRICE_OF_TREATMENT')} />
                <FormControlLabel value="PAID" control={<InputRadio />} label={t('REPORT.FILTER.ACTUAL_PRICE_PAID')} />
              </RadioGroup> */}
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setRemunerationCfDentistPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                    setRemunerationCfDentistPage(1)
                    setRemunerationCfDentistTypePage(1)
                  }}
                  label={''}
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setPrefix(e.target.value)
                    setRemunerationCfDentistPage(1)
                    setRemunerationCfDentistTypePage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}`}
                  label={''}
                  selectId="select-prefix"
                  labelId="label-prefix"
                  value={prefix}
                  options={[
                    <MenuItem key="0" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    _.map(receiptPrefix, (data: BillInterface) => {
                      return (
                        <MenuItem key={data.billPrefixId} value={data.prefix}>
                          {data.prefix}
                        </MenuItem>
                      )
                    })
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col
                sm={6}
                md={4}
                xl={3}
                className={'pt-2 px-1'}
                onClick={() => {
                  setCheckDF(!checkDF)
                  setRemunerationCfDentistPage(1)
                }}
              >
                <FilterSelect
                  formControlClass={'filter filter-doctors'}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  multiple
                  fullWidth={true}
                  onchange={handleFilterDoctors}
                  renderValue={(e) => {
                    if (_.isEmpty(selectDoctors) || _.includes(selectDoctors, '')) return `${t('REPORT.FILTER.DOCTOR')}: ${t('REPORT.FILTER.NO_SELECT')}${t('REPORT.FILTER.DOCTOR')}`
                    else if (_.includes(selectDoctors, 'ALL')) return `${t('REPORT.FILTER.DOCTOR')}: ${t('REPORT.FILTER.DOCTOR')}${t(`REPORT.FILTER.ALL`)}`
                    else {
                      return `${t('REPORT.FILTER.DOCTOR')}: ${doctorsMultiple
                        .filter((d: DentistInterface) => selectDoctors.includes(d.dentistId))
                        ?.map((d: DentistInterface) => d.dentistFullname)
                        .join(', ')}`
                    }
                  }}
                  label={t('REPORT.FILTER.DOCTOR')}
                  selectId="select-doctors"
                  value={selectDoctors}
                  labelId="label-doctors"
                  options={[
                    <MenuItem className="py-1" key="ALL" value="ALL">
                      <InputCheckbox label={''} checked={_.find(selectDoctors, (dentistId: any) => dentistId === 'ALL') || _.size(selectDoctors) === _.size(doctorsMultiple)} />
                      {t(`REPORT.FILTER.ALL`)}
                    </MenuItem>,
                    _.map(doctorsMultiple, (doctor: DentistInterface, i: number) => (
                      <MenuItem className="py-1" key={i} value={doctor.dentistId}>
                        <InputCheckbox label={''} checked={selectDoctors.find((dentistId: number) => dentistId === doctor.dentistId) ? true : false} />
                        {doctor.dentistFullname}
                      </MenuItem>
                    )),
                    <hr key={'hr'} className="mt-2 mb-1" />,
                    <MenuItem className="py-1 no-bg " key="clear" value="" style={{ backgroundColor: '#fff' }} selected>
                      <UseStyled.SpanClearFilter>{t(`REPORT.FILTER.CLEAR`)}</UseStyled.SpanClearFilter>
                    </MenuItem>
                  ]}
                />
              </Col>
            </Row>

            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <UseStyled.HeightAutocompleteSelect>
                  <AutocompleteSelect
                    key={'lab-group'}
                    labelId="lab-group"
                    inputLabel={t('REPORT.FILTER.LAB_GROUP')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={labGroup}
                    getOptionLabel={(option: any) => option.labGroupName}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.labGroupName}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      if (value) {
                        setLabGroupId(value.labGroupId)
                      }
                    }}
                    value={_.find(labGroup, (val: any) => Number(val.labGroupId) === Number(labGroupId)) || null}
                    disableClearable
                  />
                </UseStyled.HeightAutocompleteSelect>
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <UseStyled.HeightAutocompleteSelect>
                  <AutocompleteSelect
                    key={'lab-company'}
                    labelId="lab-company"
                    inputLabel={t('REPORT.FILTER.LAB_COMPANY')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={labCompany}
                    getOptionLabel={(option: any) => option.labCompanyName}
                    renderOption={(props, option: any) => (
                      <Box key={option} component="li" {...props}>
                        {option.labCompanyName}
                      </Box>
                    )}
                    onchange={(e, value: any) => {
                      if (value) {
                        setLabCompanyId(value.labCompanyId)
                      }
                    }}
                    value={_.find(labCompany, (val: any) => Number(val.labCompanyId) === Number(labCompanyId)) || null}
                    disableClearable
                  />
                </UseStyled.HeightAutocompleteSelect>
              </Col>
              {/* hidden old defaultDfCon */}
              {/* <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setOptionDf(e.target.value)
                    setRemunerationCfDentistPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.OPTION_DF')}: ${optionDf === '0' ? t('REPORT.FILTER.NO_CAL_DF') : t(`REPORT.FILTER.DF${optionDf}`)}`}
                  label={''}
                  selectId="select-optionDf"
                  labelId="label-optionDf"
                  value={optionDf}
                  options={[
                    <MenuItem key="0" value="0">
                      {t('REPORT.FILTER.NO_CAL_DF')}
                    </MenuItem>,
                    new Array(3).fill(null).map((item: any, index: number) => {
                      return (
                        <MenuItem key={index + 1} value={`${index + 1}`}>
                          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>{t(`REPORT.FILTER.DF_${index + 1}`)}</Box>
                            <Box>
                              <ButtonCustom
                                textButton={t('REPORT.FORMULA')}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  setImageFormula(optionFormula[`DF_${index + 1}`] || '')
                                }}
                              />
                            </Box>
                          </Box>
                        </MenuItem>
                      )
                    })
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col> */}

              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <RemunerationClinicFeeDentistList
              page={remunerationCfDentistPage}
              pageLimit={remunerationCfDentistLimitPage}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={remunerationCfDentistData}
              doctors={doctorsMultiple}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              filter={filter}
              filterDateStart={rangeDate[0]}
              filterDateEnd={rangeDate[1]}
              componentRef={componentRef}
            />
          </Box>
        </Card.Body>
      </Card>
      <Loading show={loading} type="fullLoading" />
      {popFormula && imageFormula !== '' && <ReportFormulaDF img={imageFormula} setImg={setImageFormula} />}
    </div>
  )
}
