import React, { useState, useEffect } from 'react'
import { Box, Typography, Tab, MenuItem } from '@mui/material'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import HistoryTreatmentApi from 'api/historyTreatment/historyTreatmentApi'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'

/** CONSTANTS */
import { Icons, imgs, onImgError } from 'constants/images';

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import ButtonCustom from 'component/Button/ButtonCustom'
import Loading from 'component/Loading'
import EmrTx from 'component/Treatments/PopupEmrOverview/EmrTx'
import EmrDx from 'component/Treatments/PopupEmrOverview/EmrDx'
import EmrTxp from 'component/Treatments/PopupEmrOverview/EmrTxp'
import EmrTxc from 'component/Treatments/PopupEmrOverview/EmrTxc'

/** STYLE */
import * as UseStyled from 'component/Treatments/PopupEmrOverview/UseStyled'

interface IPopupEmrOverviewType {
  patientId: number
  onClose: () => void
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export interface ItemRender {
  date: string
  treatment: any
  diagnosis: any
  treatmentCourse: any
  treatmentPlan: any
}

export interface TreatmentPanelProps {
  treatments: ItemRender[]
}

export default function PopupEmrOverview(props: IPopupEmrOverviewType) {
  const { t } = useTranslation()
  const tabs: string[] = ['TX.POPUP_EMR_OVERVIEW.TABS.TX', 'TX.POPUP_EMR_OVERVIEW.TABS.DX', 'TX.POPUP_EMR_OVERVIEW.TABS.TXP', 'TX.POPUP_EMR_OVERVIEW.TABS.TXC'];
  const [activeTab, setActiveTab] = useState<number>(0);
  const [treatments, setTreatments] = useState<ItemRender[]>([]);
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingToothChart, setLoadingToothChart] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const loadOverview = async () => {
    const resp = await HistoryTreatmentApi.findOverview(props.patientId).finally(() => setLoading(false))
    if (resp.status === 200) {
      const items = resp.data
      const newItems: ItemRender[] = []

      _.map(items, (item, index) => {
        newItems.push({
          date: item.createdAt,
          treatment: [...item.order.orderTreatments, ...item.order.orderProducts],
          diagnosis: item.patientDiagnosis,
          treatmentCourse: item.patientTreatmentCourse,
          treatmentPlan: item.patientTreatmentPlan
        })
      })

      if (!_.isEmpty(resp.data)) {
        const data: any = []
        const item = _.orderBy(resp.data, ['updatedAt'], ['desc', 'asc'])
        _.map(item, (trm: any, index) => {
          if (!_.isEmpty(trm?.order?.orderTreatments)) return data.push(trm?.order?.orderTreatments ? [...trm.order.orderTreatments] : [])
        })
        if (!_.isEmpty(data) && props.patientId) loadToothChart(props.patientId, data[0].patientCaseId || item[0]?.order?.patientCaseId)
      } else {
        return
      }

      setTreatments(_.orderBy(newItems, ['date'], ['desc', 'asc']))
    } else {
      return
    }
  }

  const loadToothChart = async (patientId: number, patientCaseId: number) => {
    setLoadingToothChart(true)
    const resp = await TreatmentHistoryApi.findTreatmentHistoryEmr(Number(patientId), Number(patientCaseId)).finally(() => setLoadingToothChart(false))
    if (resp.status === 200) setImage(resp.data.patientCaseInfo.toothChart)
  }

  const renderImage = (val: any) => {
    return `${fileUrl}/${val.filePath}/${val.fileName}`
  }

  const handleClickBtnMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseBtnMore = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (props.patientId) loadOverview()
  }, [props.patientId]);

  return (
    <ModalCustom
      size={'xl'}
      title={t('TX.POPUP_EMR_OVERVIEW.TITLE')}
      closeButton
      onClose={props.onClose}
      modalStyle={'modal-emr-overview'}
      component={
        <>
          {(!loading && !loadingToothChart) && (
            <UseStyled.CustomPopupEmrOverview>
              <Box>
                {(!_.isEmpty(image) && _.some(treatments, (trm) => !_.isEmpty(trm.treatment))) && (
                  <UseStyled.ToothChartImage className='position-relative mx-auto mb-4'>
                    <img src={renderImage(image) || imgs.defaultToothChart} alt="" className={`w-100`} onError={onImgError} />
                    <UseStyled.ButtonMore
                      id={'btn-more'}
                      variant="outlined"
                      aria-controls={open ? 'basic-menu-more' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClickBtnMore}
                      disableRipple
                      className='d-none'>
                      <Icons.Meatball />
                    </UseStyled.ButtonMore>

                    <UseStyled.MenuBtnMore
                      id="basic-menu-more"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseBtnMore}
                      MenuListProps={{
                        'aria-labelledby': 'btn-more'
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem
                        className={'d-flex'}
                        onClick={() => {
                          handleCloseBtnMore()
                        }}
                      >
                        {t('TX.POPUP_EMR_OVERVIEW.TOOTH_TYPE.TOOTH_ADULT')}
                      </MenuItem>
                      <MenuItem
                        className={'d-flex'}
                        onClick={() => {
                          handleCloseBtnMore()
                        }}
                      >
                        {t('TX.POPUP_EMR_OVERVIEW.TOOTH_TYPE.TOOTH_CHILD')}
                      </MenuItem>
                    </UseStyled.MenuBtnMore>
                  </UseStyled.ToothChartImage>
                )}
                <Box>
                  <Box>
                    <UseStyled.CustomTabsEmr value={activeTab} onChange={handleChangeTab} aria-label="emr overview tabs" scrollButtons="auto" variant="scrollable">
                      {_.map(tabs, (tab: string, index: number) => {
                        return <Tab key={index} label={t(tab)} {...a11yProps(index)} />
                      })}
                    </UseStyled.CustomTabsEmr>
                    <TabPanel value={activeTab} index={0}>
                      {_.some(treatments, (trm) => !_.isEmpty(trm.treatment)) ? <EmrTx treatments={treatments} /> : <NoItem />}
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                      {_.some(treatments, (trm) => !_.isEmpty(trm.diagnosis)) ? <EmrDx treatments={treatments} /> : <NoItem />}
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                      {_.some(treatments, (trm) => !_.isEmpty(trm.treatmentPlan)) ? <EmrTxp treatments={treatments} /> : <NoItem />}
                    </TabPanel>
                    <TabPanel value={activeTab} index={3}>
                      {_.some(treatments, (trm) => !_.isEmpty(trm.treatmentCourse)) ? <EmrTxc treatments={treatments} /> : <NoItem />}
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
              <Box className='text-right'>
                <ButtonCustom variant="outlined" onClick={props.onClose} textButton={t('BUTTON.CLOSE')} btnStyle={{ fontSize: '16px', height: '44px' }} />
              </Box>
            </UseStyled.CustomPopupEmrOverview>
          ) || (
              <Box className='my-4 mx-auto' sx={{ maxWidth: 'max-content' }}>
                <Loading show={true} type='softLoading' />
              </Box>
            )}
        </>
      }
    />
  )
}

function a11yProps(index: number) {
  return {
    id: `emr-overview-tab-${index}`,
    'aria-controls': `emr-overview-tabpanel-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" style={{height:'500px', overflow:'scroll'}} hidden={value !== index} id={`emr-overview-tabpanel-${index}`} aria-labelledby={`emr-overview-tab-${index}`} {...other}>
      {value === index && <Box className='mb-4'>{children}</Box>}
    </div>
  )
}

export function NoItem() {
  const { t } = useTranslation()

  return (
    <Typography className='mt-4 text-center'>{t('REGISTER.NO_ITEM')}</Typography>
  )
}