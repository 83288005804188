

import { useRef } from 'react';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { numberFormat, toBuddhistYear } from 'utils/app.utils';
interface NewPatientListTotalProps {
    type: string;
    date: any;
    page: number;
    pageLimit: number;
    rowCount: number;
    sortBy: string;
    sortType: string;
    data: any;
    onRequestSort: (sortBy: string, sortType: string) => void;
    handleChangeRowsPerPage: (val: any) => void;
    handleChangePage: (val: any) => void;
}

export default function NewPatientListTotal(props: NewPatientListTotalProps) {
    const { t } = useTranslation()
    const componentRefPDF = useRef<HTMLDivElement>(null);
    const headCells = [
        { id: 'accessChannel', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.ACCESS_CHANAL', align: 'left', class: 'text-nowrap', width: "100px" },
        { id: 'newPatientTotal', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.NEW_PATIENT_TOTAL', align: 'center', class: 'text-nowrap', width: "140px" },
    ];

    const renderData = (data: any, no: number) => {
        return <>
            <TableRowCommon
                key={no.toString()}
                id={no.toString()}
                obj={data}
                columns={[
                    { option: 'TEXT', align: 'letf', label: data.channelName || "-" },
                    { option: 'TEXT', align: 'center', label: data.count || "-" }
                ]}
            />
        </>
    }

    return (

        <>
            {props.type !== "export" ?
                <Box sx={{ maxWidth: '480px' }}>
                    <UseStyled.ResponsiveReportTable>
                        <TableCustom
                            page={props.page}
                            pageLimit={props.pageLimit}
                            sortType={''}
                            sortBy={''}
                            rowCount={props.rowCount}
                            onSort={props.onRequestSort}
                            setPageLimit={props.handleChangeRowsPerPage}
                            setPage={props.handleChangePage}
                            headCells={headCells}
                            rowsData={
                                [
                                    _.map(props.data, (sd: any, index: number) => {
                                        return (
                                            renderData(sd, index)
                                        )
                                    })
                                    ,
                                    <TableRow key="total">
                                        <TableCell style={{ fontWeight: 'bold' }} align="left" scope="row">
                                            รวม
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="center" scope="row">

                                            {_.sumBy(props.data, 'count')}
                                        </TableCell>
                                    </TableRow>
                                ]
                            }
                            hidePagination={true}
                            tableMinWidth={480}
                            customScroll
                        />

                    </UseStyled.ResponsiveReportTable>
                </Box>
                :
                <div className="print-show" ref={componentRefPDF} >
                    <Box sx={{ mt: "2rem" }}>
                        <Box sx={{ fontSize: 10 }}>
                            {`${t('REPORT.FILTER.DAY')} ${toBuddhistYear(moment(props.date[0]), 'DD/MM/YYYY')} >> ${toBuddhistYear(moment(props.date[1]), 'DD/MM/YYYY')}`}
                        </Box>
                        <UseStyled.Styles className="pt-3">
                            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '500px', maxWidth: '100%', border: "1px solid" }}>
                                <thead>
                                    <tr>
                                        {_.map(headCells, (val: any, index: number) => {
                                            return (
                                                <td
                                                    style={{
                                                        textAlign: val.align,
                                                        fontSize: 12,

                                                        width: val.width
                                                    }}>
                                                    {t(val.labelKey)}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(_.groupBy(props.data, 'channelName'), (dataGroup: any[], channelName: string) => {
                                        const totalCount = _.sumBy(dataGroup, 'count');
                                        return (
                                            <tr>
                                                <td style={{ textAlign: "center", fontSize: 10, }}>{channelName}</td>
                                                <td style={{ textAlign: "center", fontSize: 10, }}>{totalCount}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td style={{ textAlign: "center", fontSize: 10 }}>{t('REPORT.TITLE.SUM')}</td>
                                        <td style={{ textAlign: "center", fontSize: 10 }}>{_.sumBy(props.data, 'count')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </UseStyled.Styles>
                    </Box>
                </div>
            }

        </>
    )
}

