import _ from 'lodash'
import moment from 'moment'
import { Box } from '@mui/material'

/** COMPONENT */

import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { SORT_TYPE } from 'constants/common'
import { isBuddhistYear } from 'utils/app.utils'
interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    data: any
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    onRequestSort: (sortBy: string, sortType: string) => void
    setSortType: (val: SORT_TYPE) => void
    setSortBy: (val: string) => void
    sortType: string
    sortBy: string
}

export default function FollowUpList(props: FcComponent) {
    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.#', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'order_treatments.createdAt', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE', align: 'center', class: 'text-nowrap', rowSpan: 2, sortable: true },
        { id: 'patientFullName', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.PATIENTNAME', align: 'center', class: 'text-nowrap', rowSpan: 2, sortable: true },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.PHONE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.LINE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.EMAIL', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.FACEBOOK', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.OPERATIVE_TYPE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.OPERATIVE', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DOCTOR', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.1', align: 'center', class: 'text-nowrap', rowSpan: 1, colSpan: 3 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE_FOLLOW', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.RESULT', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.2', align: 'center', class: 'text-nowrap', rowSpan: 1, colSpan: 3 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE_FOLLOW', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.RESULT', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.3', align: 'center', class: 'text-nowrap', rowSpan: 1, colSpan: 3 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE_FOLLOW', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.RESULT', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.4', align: 'center', class: 'text-nowrap', rowSpan: 1, colSpan: 3 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.DATE_FOLLOW', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.RESULT', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', row: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.REMARK', align: 'center', class: 'text-nowrap', rowSpan: 2 },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.FOLLOW_UP.CREATEDBY', align: 'center', class: 'text-nowrap', rowSpan: 2 },

    ]
    const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
        props.setSortType(sortTypeVal)
        props.setSortBy(sortByVal)
        props.handleChangePage(1)
    }

    const renderData = (data: any, no: number) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1

        return <>
            <TableRowCommon
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={[
                    { option: 'TEXT', align: 'center', label: num, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: moment(data.createdAt).add(isBuddhistYear(), 'y').format('DD/MM/YYYY'), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.patientFullName, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.phone || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.line || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.email || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.facebook || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.operativeTypeName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.operativeName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.createdBy || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpDate1, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpStatus1 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpRemark1 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpDate2 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpStatus2 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpRemark2 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpDate3 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpStatus3 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpRemark3 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpDate4 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpStatus4 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.followUpRemark4 || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.remark || '-', class: 'text-nowrap' },
                    { option: 'UPDATE_BY', align: 'center', label: { updatedBy: data.createdBy, updatedAt: data.createdAt }, class: 'text-nowrap' },
                ]} />
        </>
    }

    return <>
        <Box>
            <UseStyled.ResponsiveReportTable>
                <TableCustom
                    rowHeadCell={2}
                    tableMinWidth={992}
                    page={props.page}
                    pageLimit={props.pageLimit}
                    sortType={props.sortType}
                    sortBy={props.sortBy}
                    rowCount={props.rowCount}
                    onSort={onRequestSort}
                    setPageLimit={props.handleChangeRowsPerPage}
                    setPage={props.handleChangePage}
                    headCells={headCells}
                    rowsData={_.map(props.data.rows, (d: any, index: number) => {
                        return renderData(d, index)
                    })}
                    customScroll
                />
            </UseStyled.ResponsiveReportTable>
        </Box>
    </>
}