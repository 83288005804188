import { Box, Grid, Typography } from '@mui/material'
import { InventoryTabs } from 'features/warehouses/inventory/form-inventory'
import { numberFormat } from 'utils/app.utils'
import { FilesType } from 'features/warehouses/inventory/inventory'
import _ from 'lodash'
import { fileUrl } from 'api/api'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** STYLE */
import * as UseStyled from 'features/warehouses/inventory/styled'

export default function GeneralTab(props: InventoryTabs) {
    const { t } = useTranslation()
    const { inventory: inventoryObj } = props

    const medicines = [
        {
            data: [
                {
                    key: 'CODE',
                    item: inventoryObj?.itemCode || '-'
                },
                {
                    key: 'TYPE',
                    item: inventoryObj.itemTypeName || inventoryObj.itemTypeNameEn || '-'
                },
                {
                    key: 'INSTRUCTION',
                    item: inventoryObj.productInstructionName || '-'
                },
                {
                    key: 'UNIT',
                    item: inventoryObj.itemUnitName || inventoryObj.itemUnitNameEn || '-'
                },
                {
                    key: 'USES',
                    item: inventoryObj.productUseDetail || '-'
                },
                {
                    key: 'FORMAT',
                    item: inventoryObj.productFormatName || '-'
                },
                {
                    key: 'STRENGTH',
                    item: inventoryObj.strength || '-'
                },
                {
                    key: 'WARNING',
                    item: inventoryObj.warnings || '-'
                },
            ]
        },
        {
            data: [
                {
                    key: 'PRESERVE',
                    item: inventoryObj.productPreserveName || '-'
                },
                {
                    key: 'PRICE',
                    item: `${numberFormat(inventoryObj.costPrice || 0)} ${t('CURRENCY_CHANGE.TH')}`
                },
                {
                    key: 'SELL',
                    item: `${numberFormat(inventoryObj.price || inventoryObj.salePrice || 0)} ${t('CURRENCY_CHANGE.TH')}`
                },
                {
                    key: 'MIN_QTY',
                    item: inventoryObj.minStock || 0
                },
                {
                    key: 'EXPIRED',
                    item: inventoryObj.amounDayBeforeExpired || '-'
                },
                {
                    key: 'DESCRIPTION',
                    item: inventoryObj.explanation || '-'
                },
                {
                    key: 'DOCUMENT',
                    item: inventoryObj.files || []
                },
            ]
        }
    ]

    const products = [
        {
            data: [
                {
                    key: 'CODE',
                    item: inventoryObj.itemCode || '-'
                },
                {
                    key: 'TYPE',
                    item: inventoryObj.itemTypeName || inventoryObj.itemTypeNameEn || '-'
                },
                {
                    key: 'INSTRUCTION',
                    item: inventoryObj.productInstructionName || '-'
                },
                {
                    key: 'UNIT',
                    item: inventoryObj.itemUnitName || inventoryObj.itemUnitNameEn || '-'
                },
                {
                    key: 'PRESERVE',
                    item: inventoryObj.productPreserveName || '-'
                },
                {
                    key: 'PRICE',
                    item: `${numberFormat(inventoryObj.costPrice || 0)} ${t('CURRENCY_CHANGE.TH')}`
                },
            ]
        },
        {
            data: [
                {
                    key: 'SELL',
                    item: `${numberFormat(inventoryObj.price || inventoryObj.salePrice || 0)} ${t('CURRENCY_CHANGE.TH')}`
                },
                {
                    key: 'MIN_QTY',
                    item: inventoryObj.minStock || 0
                },
                {
                    key: 'EXPIRED',
                    item: inventoryObj.amounDayBeforeExpired || '-'
                },
                {
                    key: 'DESCRIPTION',
                    item: inventoryObj.explanation || '-'
                },
                {
                    key: 'DOCUMENT',
                    item: inventoryObj.files || []
                }
            ]
        }
    ]

    const inventories: { data: { key: string, item: any }[] }[] = inventoryObj.group === 'MEDICINE' ? medicines : products

    const renderFiles = (file?: FilesType) => {
        if (file?.fileName && file.filePath) {
            const path = `${fileUrl}/${file.filePath}/${file.fileName}`
            return (
                <Box className='d-flex'>
                    <Typography className='pr-2'>•</Typography>
                    <Typography onClick={() => window.open(path)} className={`cursor-pointer`} sx={{ textDecoration: 'underline' }}>{file.fileName}</Typography>
                </Box>
            )
        } else return <></>
    }

    return (
        <Grid container spacing={2}>
            {_.map(inventories, (inventory: { data: { key: string, item: any } }) => {
                return (
                    <Grid lg={6} className="pl-3 w-100">
                        {
                            _.map(inventory.data, (inv: { key: string, item: any }) => {
                                return (
                                    <Box className={`d-flex pt-3 ${inv.key === 'DOCUMENT' ? 'flex-column' : 'flex-column flex-sm-row'}`}>
                                        <UseStyled.Typo type='title'>
                                            {t(`INVENTORY.FORM.${inventoryObj.group}.${inv.key}`)}
                                        </UseStyled.Typo>
                                        <UseStyled.Typo className={`${inv.key === 'DOCUMENT' ? 'pt-1' : ' pt-1 pt-sm-0'}`}>
                                            {inv.key === 'DOCUMENT' && (
                                                !_.isEmpty(inv.item) && (
                                                    _.map(inv.item, (file) => {
                                                        return renderFiles(file)
                                                    })
                                                ) || '-'
                                            ) || inv.item || '-'}
                                        </UseStyled.Typo>
                                    </Box>
                                )
                            })
                        }
                    </Grid>
                )
            })}
        </Grid>
    )
}