import { Box, TableCell, TableRow, Typography, Grid } from '@mui/material'
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import TreatmentApi from 'api/dentists/treatment.api'
import TxInformationBox from 'component/Treatments/TxInformationBox'
import { IconAddBox, IconAddBoxDim } from 'features/treatments/MainMenu/OPD/style/UseStyle'
import AddBoxIcon from '@mui/icons-material/AddBox'
import _ from 'lodash'
import { numberFormat } from 'utils/app.utils'
import { dateTimeToView, dateToView } from 'utils/date.utils'
import ClinicApi from 'api/master/clinic.api'
import { Col, Row } from 'react-bootstrap';
import ButtonCustom from 'component/Button/ButtonCustom'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import PopupCreateByUsers from 'component/Treatments/PopupCreateByUsers'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { swalDelete, swalWarning } from 'component/Alert/Swal'
import Loading from 'component/Loading'

/** UTILS || SLICE */
import {
  treatment,
  setTreatmentByKey,
  TreatmentStateInterface,
  setColorToothChart,
  setSubmitTootChart,
  toothChartSelected,
  setToothChartSelected,
  historyToothChart,
} from 'app/slice/treatment.slice';
import useWindowSize from 'utils/useWindowSize'
import OperativeApi from 'api/setting/treatments/operative.api';
import OperativeTypeApi from 'api/setting/treatments/operative-type.api';
import { selectMe } from 'app/slice/user.slice';

export const Item = styled(Box)(({ theme }) => ({
  margin: '0 -0.5rem -1rem',
  maxHeight: 400,
  overflowY: 'auto'
}))

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: 'unset',
    maxWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-root': {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 1200,
    },
  },
}))

type TxpType = {
  patient?: any
  history?: boolean
  hasCreateTreatmentPlan?: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatmentPlan?: 'PERMISS' | 'UNPERMISS'
  type?: any
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export default function TXP(props: TxpType) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const valueToothChartSelected = useSelector(toothChartSelected)
  const historyToothCharts = useSelector(historyToothChart)
  const user = useSelector(selectMe)
  const [txp, setTxp] = useState<any>()
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [operativeTypes, setOperativeTypes] = useState<any>([])
  const [header, setHeader] = useState<any>('')
  const [edit, setEdit] = useState<any>('')
  const [width] = useWindowSize()
  const [createByUsers, setCreateByUsers] = useState(false)
  const [createTemplate, setCreateTemplate] = useState(false)
  const [loading, setLoading] = useState(true);

  const [opertatives, setOperative] = useState<any[]>([])
  const [operativeType, setOperativeType] = useState<any[]>([])

  const loadData = useCallback(() => {
    TreatmentApi.getTxp(props.patient.patientId).then((res) => {
      if (res.status === 200) setTxp(res.data);
    }).finally(() => setLoading(false))
  }, [props.patient.patientId])

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors().finally(() => setLoading(false))
    if (resp.status === 200) setDoctors(resp.data)
  }

  const loadDataOperativeType = useCallback(() => {
    OperativeApi.findAll({ export: 1 }).then(({ data }) => {
      setOperative(() => {
        const newData: any = data.map((item: any) => {
          const mapData = {
            itemCode: item?.operativeCode,
            itemId: item?.operativeId,
            itemName: item?.operativeName,
            itemNameEn: item?.operativeNameEn,
            itemTypeId: item?.operativeTypeId,
            itemTypeName: item?.operative_type?.operativeTypeName,
            itemTypeNameEn: item?.operative_type?.operativeTypeNameEn,
            itemUnitName: item?.operative_unit?.operativeUnitName,
            itemUnitNameEn: item?.operative_unit?.operativeUnitNameEn,
            price: item.salePrice,
          }
          return mapData
        })
        return newData
      })
    }).catch((e) => { return })

    OperativeTypeApi.findAll({ export: 1 }).then(({ data }) => {
      setOperativeType(() => {
        const newData: any = data.map((item: any) => {
          const mapData = {
            itemTypeId: item.operativeTypeId,
            itemTypeName: item.operativeTypeName,
            itemTypeNameEn: item.operativeTypeNameEn
          }
          return mapData
        })
        return newData
      })
    }).catch((e) => { return })

    TreatmentApi.getTxpTypes().then((res) => {
      if (res.status === 200) setOperativeTypes(res.data)
    }).finally(() => setLoading(false))

  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    loadDoctor()
  }, [])

  useEffect(() => {
    loadDataOperativeType()
  }, [])

  const popupCreateTemplate = () => {
    setCreateByUsers(true)
    setCreateTemplate(true)
    setHeader(t('TXP.POPUP.HEADER_TEMPLATE'))
  }

  const popupCreateByUsers = () => {
    setCreateByUsers(true)
    setCreateTemplate(false)
    setHeader(t('TXP.POPUP.HEADER_BY_USER'))
  }

  const onEdit = (val: any) => {
    setEdit(val)
    setHeader(t('TXP.POPUP.HEADER_EDIT'))
    setCreateByUsers(true)
    setCreateTemplate(false)
  }

  const updateStatus = (data: any, type: string) => {
    const { patientTreatmentPlanId, treatmentPlanName, createdAt } = data
    const status = { status: type }
    if (type === 'PENDING') {
      swalWarning(t('TXP.MESSAGE.TITLE_OPEN_TXP'), `${t('TXP.MESSAGE.OPEN_DO_TXP')} ${treatmentPlanName} ${dateTimeToView(createdAt)} ${t('TXP.MESSAGE.YES_OR_NO')}`, (res: any) => {
        TreatmentApi.upDatePatientTreatmentPlans(patientTreatmentPlanId, status)
          .then((resp) => {
            notiSuccess(t('TXP.MESSAGE.OPEN_TXP_SUCCESS'), '', null, null, !props.history ? 'register-page' : '')
            loadData()
          })
          .catch((e) => {
            notiError(t('TXP.MESSAGE.OPEN_TXP_FAIL'), '', null, null, !props.history ? 'register-page' : '')
          })
      }, {
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonText: t('TXP.MESSAGE.OPEN_TXP'),
        cancelButtonText: t('BUTTON.CANCEL')
      })
    } else {
      swalDelete(
        (type === 'DELETED' && t('TXP.MESSAGE.DEL_TXP_YES_OR_NO')) || (type === 'CLOSE' && t('TXP.MESSAGE.CLOSE_TXP_YES_OR_NO')) || t('TXP.MESSAGE.CANCEL_TXP_YES_OR_NO'),
        `${(type === 'DELETED' && t('TXP.MESSAGE.TODO_DEL_TXP')) || (type === 'PENDING' && t('TXP.MESSAGE.TODO_OPEN_TXP')) || (type === 'CLOSE' && t('TXP.MESSAGE.TODO_CLOSE_TXP')) || t('TXP.MESSAGE.TODO_CANCEL_TXP')}
        ${treatmentPlanName} ${dateTimeToView(createdAt)} ${t('TXP.MESSAGE.YES_OR_NO')}`,
        (res: any) => {
          if (res) {
            TreatmentApi.upDatePatientTreatmentPlans(patientTreatmentPlanId, status)
              .then((resp) => {
                notiSuccess(t(type === 'DELETED' ? 'TXP.MESSAGE.DEL_SUCCESS' : type === 'CLOSE' ? 'TXP.MESSAGE.CLOSE_SUCCESS' : 'TXP.MESSAGE.CANCEL_SUCCESS'), '', null, null, !props.history ? 'register-page' : '')
                loadData()
              })
              .catch((e) => {
                notiError(t(type === 'DELETED' ? 'TXP.MESSAGE.DEL_FAIL' : type === 'CLOSE' ? 'TXP.MESSAGE.CLOSE_FAIL' : 'TXP.MESSAGE.CANCEL_FAIL'), '', null, null, !props.history ? 'register-page' : '')
              })
          }
        },
        {
          confirmButtonText: t(type === 'DELETED' ? 'TXP.MESSAGE.DEL_TXP' : type === 'CLOSE' ? 'TXP.MESSAGE.CLOSE_TXP' : type === 'PENDING' ? 'TXP.MESSAGE.OPEN_TXP' : 'TXP.MESSAGE.CANCEL_TXP'),
          cancelButtonText: (t('BUTTON.CANCEL'))
        }
      )
    }
  }

  const handleAddOperative = (tx: any) => {
    const item = {
      operativeCode: tx.operativeCode,
      operativeId: tx.operativeId,
      operativeName: tx.operativeName,
      operativeNameEn: tx.operativeNameEn,
      operativeTypeId: tx.operativeTypeId,
      operativeTypeName: tx.operativeTypeName,
      operativeTypeNameEn: tx.operativeTypeNameEn,
      operativeUnitName: tx.operativeUnitName,
      operativeUnitNameEn: tx.operativeUnitNameEn,
      tempOrderTreatmentId: null,
      teeth: tx.teeth || '',
      teethJson: tx.teethJson || '',
      teethType: tx.teethType || '',
      qty: 1,
      price: tx.price,
      doctorId: tx.doctorId || user.userId || 0,
      discount: 0,
      additional: 0,
      discountUnit: 'BAHT',
      additionalUnit: 'BAHT',
      total: tx.price,
      dfAmount: 0,
      cfAmount: 0,
      dfDiscountAmount: 0,
      cfDiscountAmount: 0,
      dfDiscountUnit: 'BAHT',
      cfDiscountUnit: 'BAHT',
      note: tx.remark || '',
      noteNextVisit: '',
      refType: 'PLAN',
      refId: tx.id,
    }
    if (tx.teethJson) {
      const toothChart = JSON.parse(tx.teethJson)
      const newArray: any = [];
      _.map(treatments.summeryTreatments, (summery: any, index: any) => {
        if (summery.teethJson) newArray.push(JSON.parse(summery.teethJson))
      })
      newArray.push(toothChart)
      dispatch(setToothChartSelected([...historyToothCharts, ...newArray]))
    }

    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, item] }))
    dispatch(setColorToothChart({ value: tx.color }))
  }

  const defaultHeadCells = [
    { id: 'no', disablePadding: false, align: 'center', label: '#', width: width >= 1200 ? '5%' : '50px' },
  ]

  const ItemsHeadCells = [
    { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.TYPE'), width: '12%' },
    { id: 'message', disablePadding: false, align: 'center', label: t('TXP.TABLE.CODE') },
    { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.DETAIL'), width: '20%' },
    { id: 'message', disablePadding: false, align: 'right', label: t('TXP.TABLE.PRICE') },
    { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.DATE') },
    { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.DOCTOR'), width: '15%' },
    { id: 'message', disablePadding: false, align: 'left', label: t('TXP.TABLE.TIME') },
    { id: 'message', disablePadding: false, align: 'center', label: t('TXP.TABLE.STATUS') },
  ]

  const headCells = width >= 1200 ? [
    ...defaultHeadCells,
    ...ItemsHeadCells,
    { id: 'message', disablePadding: false, align: 'center', label: t('Action'), width: '7%' }] : [
    ...defaultHeadCells,
    { id: 'message', disablePadding: false, align: 'left', label: t('') }, {
      id: 'message',
      disablePadding: false, align: 'center', label: t('Action'), width: '90px'
    }]

  const headCellsHistory = width >= 1200 ? [
    ...defaultHeadCells,
    ...ItemsHeadCells] : [
    ...defaultHeadCells,
    { id: 'message', disablePadding: false, align: 'left', label: t('') }]

  const renderData = (data: any, no: number, status: any) => {
    return (
      <>
        <TableRow key={`diagnosis-${no}`} className="main-table-row">
          <TableCell align="center" scope="row">
            {no + 1}
          </TableCell>
          {(width >= 1200 && (
            <>
              <TableCell align="left" scope="row">
                {data.operativeTypeName || '-'}
              </TableCell>
              <TableCell align="center" scope="row">
                {data.operativeCode || '-'}
              </TableCell>
              <TableCell align="left" scope="row">
                {data.operativeName || '-'}
              </TableCell>
              <TableCell align="right" scope="row">
                {numberFormat(data.price) || '-'}
              </TableCell>
              <TableCell align="left" scope="row">
                {data.dateUse ? dateToView(data.dateUse) : '-'}
              </TableCell>
              <TableCell align="left" scope="row">
                {_.find(doctors, { userId: data.doctorId })?.fullname || '-'}
              </TableCell>
              <TableCell align="left" scope="row">
                {data.duration || '-'} {
                  data.duration && (
                    data.durationUnit === 'YEARS' ? t('TXP.DATE.YEARS') : data.durationUnit === 'MONTH' ? t('TXP.DATE.MONTH') :
                      data.durationUnit === 'DAYS' ? t('TXP.DATE.DAYS') : data.durationUnit === 'HOURS' ? t('TXP.DATE.HOURS') : t('TXP.DATE.MINUTES') || '-'
                  )}
              </TableCell>
              <TableCell align="center" scope="row">
                {
                  _.some(treatments.summeryTreatments, { refId: data.id }) && t('TX_INFORMATION.STATUS.IN_PROGRESS') ||
                  data.status === 'DONE' && t('TX_INFORMATION.STATUS.DONE') ||
                  t('TX_INFORMATION.STATUS.PENDING')
                }
              </TableCell>
              {!props.history && (
                <TableCell align="center" scope="row" className="py-3 py-md-2">
                  {(status === 'CLOSE' || status === 'CANCEL' || data.status === 'DONE' || _.some(treatments.summeryTreatments, { refId: data.id })) &&
                    (
                      <IconAddBoxDim key={data.id} className="p-0 ml-1 pe-none" sx={{ backgroundColor: 'transparent !important' }}>
                        <AddBoxIcon style={{ width: 30, height: 30 }} />
                      </IconAddBoxDim>
                    ) || (
                      <IconAddBox key={data.id} onClick={() => handleAddOperative(data)} className="p-0 ml-1" sx={{ backgroundColor: 'transparent !important' }}  >
                        <AddBoxIcon style={{ width: 30, height: 30 }} />
                      </IconAddBox>
                    )}
                </TableCell>
              )}
            </>
          )) ||
            <>
              <TableCell align="left" scope="row">
                <Grid container spacing={1}>
                  <Grid item className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                        {t('TXP.TABLE.TYPE')}
                      </Typography>
                      <Typography> {data.operativeTypeName || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                        {t('TXP.TABLE.CODE')}
                      </Typography>
                      <Typography> {data.operativeCode || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                        {t('TXP.TABLE.DETAIL')}
                      </Typography>
                      <Typography> {data.operativeName || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                        {t('TXP.TABLE.PRICE')}
                      </Typography>
                      <Typography> {numberFormat(data.price) || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                        {t('TXP.TABLE.DATE')}
                      </Typography>
                      <Typography>{data.dateUse ? dateToView(data.dateUse) : '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                        {t('TXP.TABLE.DOCTOR')}
                      </Typography>
                      <Typography>{_.find(doctors, { userId: data.doctorId })?.fullname || '-'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                        {t('TXP.TABLE.TIME')}
                      </Typography>
                      <Typography>{data.duration} {data.durationUnit === 'YEARS' ? t('TXP.DATE.YEARS') : data.durationUnit === 'MONTH' ? t('TXP.DATE.MONTH') :
                        data.durationUnit === 'DAYS' ? t('TXP.DATE.DAYS') : data.durationUnit === 'HOURS' ? t('TXP.DATE.HOURS') : t('TXP.DATE.MINUTES') || '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item className='w-100'>
                    <Box className="d-flex">
                      <Typography sx={{ width: width >= 480 ? '130px' : '90px', fontWeight: 600, flex: 'none' }}>
                        {t('TXP.TABLE.STATUS')}
                      </Typography>
                      <Typography className="d-inline">
                        {
                          _.some(treatments.summeryTreatments, { refId: data.id }) && t('TX_INFORMATION.STATUS.IN_PROGRESS') ||
                          data.status === 'DONE' && t('TX_INFORMATION.STATUS.DONE') ||
                          t('TX_INFORMATION.STATUS.PENDING')
                        }
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              {!props.history && <TableCell align="center" scope="row" className="py-3 py-md-2">
                {(status === 'CLOSE' || status === 'CANCEL' || data.status === 'DONE' || _.some(treatments.summeryTreatments, { refId: data.id })) && (
                  <IconAddBoxDim key={data.id} className="p-0 ml-1" sx={{ backgroundColor: 'transparent !important' }}  >
                    <AddBoxIcon style={{ width: 30, height: 30 }} />
                  </IconAddBoxDim>
                ) || (
                    <IconAddBox key={data.id} onClick={() => handleAddOperative(data)} className="p-0 ml-1" sx={{ backgroundColor: 'transparent !important' }}  >
                      <AddBoxIcon style={{ width: 30, height: 30 }} />
                    </IconAddBox>
                  )}
              </TableCell>
              }
            </>
          }
        </TableRow>
      </>
    )
  }

  return (
    <div className="w-100 h-100">
      <div className="d-flex justify-content-between align-items-center pb-2">
        <Row className="mx-0 w-100 align-items-center">
          <Col className="col-12 col-sm-4 px-1 justify-content-start">
            <Typography sx={{ fontSize: 18, fontWeight: 500, flex: 'none' }}>{t('TXP.TITLE')} ({txp?.length || 0})</Typography>
          </Col>
          {!props.history && <Col className="col-12 col-sm-8 px-1 ml-auto">
            <Row className='justify-content-md-end'>
              <Col className="col-12 col-sm-5 col-md-auto mt-2 mt-sm-0 px-2">
                <ButtonCustom disabled={props?.hasCreateTreatmentPlan === 'PERMISS' ? false : true} variant="outlined" className={'px-2 px-md-3'} btnStyle={{ height: 42 }} textButton={t('TXP.BUTTON.ADD_TEMPLATE')} style={{ fontSize: 16, width: '100%' }} onClick={popupCreateTemplate} />
              </Col>
              <Col className="col-12 col-sm-7 col-md-auto mt-2 mt-sm-0 px-2">
                <ButtonCustom disabled={props?.hasCreateTreatmentPlan === 'PERMISS' ? false : true} variant="outlined" btnStyle={{ height: 42 }} textButton={t('TXP.BUTTON.ADD_BY_USER')} style={{ fontSize: 16, width: '100%' }} onClick={popupCreateByUsers} />
              </Col>
            </Row>
          </Col>}
        </Row>
      </div>
      <Loading show={loading} type='softLoading' className='m-auto' />
      <Item className='custom-scroll' style={{ minHeight: 500 }}>
        {_.map(txp, (val: any, index: number) => {
          return (
            <TableWidth className='pt-2'>
              <TxInformationBox
                headCells={props.history ? headCellsHistory : headCells}
                renderData={renderData}
                titleName={val.treatmentPlanName}
                arrayTable={val.patientTreatmentPlanItems}
                status={val.status}
                remark={val.remark}
                createdAt={dateTimeToView(val.createdAt)}
                createdBy={val.createdBy}
                txp={true}
                onEdit={() => onEdit(val)}
                onDelete={() => updateStatus(val, 'DELETED')}
                onClosePlan={() => updateStatus(val, 'CLOSE')}
                onCancelPlan={() => updateStatus(val, 'CANCEL')}
                onPendingPlan={() => updateStatus(val, 'PENDING')}
                doctors={doctors}
                patient={props.patient}
                history={props.history}
                hasDeleteTreatmentPlan={props?.hasDeleteTreatmentPlan || 'PERMISS'}
                type={props.type}
              />
            </TableWidth>
          )
        })}
      </Item>

      {createByUsers && (
        <PopupCreateByUsers
          header={header}
          isShow={createByUsers}
          onSubmit={(val: 'true' | '') => {
            if (val === 'true') loadData()
          }}
          txp={true}
          doctors={doctors}
          operativeTypes={operativeTypes}
          showTemplate={createTemplate}
          patientCaseId={props.patient.patientCase?.patientCaseId}
          patientId={props.patient.patientId}
          edit={edit}
          setIsShow={(show: boolean) => {
            setCreateByUsers(show)
            setEdit('')
          }}
          operativeType={operativeType}
          opertatives={opertatives}
        />
      )}
    </div>
  )
}
