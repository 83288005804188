import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface RolesInterface {
  roleName: string
  roleNameEn?: string
  status: string
}

export default class RolesApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('roles', { params: { ...props } }).then((res) => res)
  }

  static create(careerName: string) {
    return this.api.post('roles', { careerName })
  }

  static findById(id: number): Promise<RolesInterface> {
    return this.api.get(`roles/${id}`)
  }

  static update(id: number, body: RolesInterface) {
    return this.api.patch(`roles/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`roles/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`roles/${id}`)
  }

  static findAllwithbranchs(props: FindAllInterface): Promise<any> {
    return this.api.get('roles/find-all-with-branchs', { params: { ...props } }).then((res) => res)
  }
}
