/** COMPONENT */
import AppointmentHistory from 'features/treatments/AppointmentHistory'

type AppointmentHistoryType = {
  patientId?: number
}

export default function AppointmentHistoryIndex(props: AppointmentHistoryType) {
  return (
    <AppointmentHistory type='HistoryTreatment' patientId={props.patientId} />
  )
}
