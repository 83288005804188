import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material';
import { resetModal } from 'app/slice/modal.slice';

/** API */
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'
import ImportInventoryApi from 'api/warehouses/import-inventory.api'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import { notiSuccess, notiError } from 'component/notifications/notifications'

type PopupCancelImportType = {
    inventoryDocumentId: number,
    document: { prefix: string, documentNo: string }
    onCancel: (show: boolean) => void
    onAfterSubmit: (show: boolean) => void
}

const initStateErrorMessage = {
    username: '',
    password: '',
    cancelRemark: ''
}

export default function PopupCancelImport(props: PopupCancelImportType) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [inputs, setInputs]: any = useState({})
    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

    const handleChange = (event: any) => {
        const name = event.target.name
        const value = event.target.value
        setInputs((values: any) => ({ ...values, [name]: value }))
        setErrorMessage(initStateErrorMessage)
    }

    const onSubmitCancel = async () => {
        if (!inputs.username || !inputs.password || !inputs.cancelRemark) {
            return setErrorMessage({
                username: !inputs.username ? t('IMPORT_PRODUCT.MESSAGE.USER_NAME') : '',
                password: !inputs.password ? t('IMPORT_PRODUCT.MESSAGE.PASSWORD') : '',
                cancelRemark: !inputs.cancelRemark ? t('IMPORT_PRODUCT.MESSAGE.REASON') : ''
            })
        }

        PersonnelApi.findPermissions({ username: inputs.username, password: inputs.password })
            .then(({ data }) => {
                if (data.hasCancelVisit === 'PERMISS') {
                    ImportInventoryApi.cancel(props.inventoryDocumentId, { cancelBy: inputs.username, cancelRemark: inputs.cancelRemark })
                        .then(({ data }) => {
                            notiSuccess(t('IMPORT_PRODUCT.MESSAGE.CANCEL_SUCCESS'))
                            dispatch(resetModal())
                            props.onAfterSubmit && props.onAfterSubmit(false)
                        })
                        .catch((e) => {
                            const message = e?.response?.data?.message
                            if (message === 'SOME_ITEMS_HAVE_ALREADY_BEEN_USED') notiError(t('IMPORT_PRODUCT.MESSAGE.SOME_ITEMS_HAVE_ALREADY_BEEN_USED'))
                            else notiError(t('IMPORT_PRODUCT.MESSAGE.CANNOT_CANCEL'))
                        })
                } else return setErrorMessage({ ...errorMessage, ...{ username: t('IMPORT_PRODUCT.MESSAGE.PERMISSION_NOT_FOUND') } })
            })
            .catch((e) => {
                const status = e?.response?.status
                setErrorMessage({ ...errorMessage, ...{ username: status === 404 ? t('INPUT.NOT_FOUND_USER_NAME') : '', password: status === 400 ? t('INPUT.PASSWORD_INCORRECT') : '' } })
            })
    }

    return (
        <ModalCustom
            size={'lg'}
            title={t('IMPORT_PRODUCT.POPUP.HEADER')}
            component={
                <Grid container spacing={2}>
                    <Grid item className={`w-100`}>
                        {t(`IMPORT_PRODUCT.POPUP.TITLE`,
                            { name: ((props.document.prefix && props.document.documentNo) && `${props.document.prefix}-${props.document.documentNo}`) || props.document.documentNo || '-' }
                        )}
                    </Grid>
                    <Grid item md={6} className={`w-100`}>
                        <InputTextField
                            onchange={handleChange}
                            name="username"
                            value={inputs.username}
                            label={t('IMPORT_PRODUCT.POPUP.USER_NAME')}
                            helperText={errorMessage?.username}
                            required
                            disabledAutoComplete />
                    </Grid>
                    <Grid item md={6} className={`w-100`}>
                        <InputTextField
                            onchange={handleChange}
                            name="password"
                            value={inputs.password}
                            type="password"
                            helperText={errorMessage?.password}
                            label={t('IMPORT_PRODUCT.POPUP.PASSWORD')}
                            required
                            disabledAutoComplete />
                    </Grid>
                    <Grid item className={`w-100`}>
                        <InputTextarea
                            name="cancelRemark"
                            helperText={errorMessage?.cancelRemark}
                            required
                            label={t('IMPORT_PRODUCT.POPUP.REASON')}
                            value={inputs.cancelRemark}
                            onchange={handleChange} />
                    </Grid>
                </Grid>
            }
            onClose={() => {
                props.onCancel && props.onCancel(false)
                setInputs({})
            }}
            onReset={() => {
                props.onCancel && props.onCancel(false)
                setInputs({})
            }}
            onSubmit={onSubmitCancel}
            textBtnCancel={t('IMPORT_PRODUCT.BUTTON.CANCEL')}
            textBtnConfirm={t('IMPORT_PRODUCT.BUTTON.CONFIRM')}
        />
    )
}
