import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableSortLabel, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, numberToCurrencyFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'
import { dateTimeToView, dateToView, timeToView } from 'utils/date.utils'

export interface AdvanceComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  summary: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
  componentRef: any
  branchName: string
}

interface AdvanceSummaryIF {
  date: string
  createdAt: string
  paymentNumber: string
  statusOrder: string
  cnNumber: string
  branchCnNumber: string
  snCode: string
  patientFullname: string
  receiptAdvanceCash: number
  receiptAdvanceTransfer: number
  receiptAdvanceDebit: number
  receiptAdvanceCredit: number
  receiptAdvance: number
  amountCash: number
  amountTransfer: number
  amountDebit: number
  amountCredit: number
  amountAdvance: number
  advanceBalance: number
  staff: string
  remark: string
  cancelBy: string
  cancelRemark: string
}
interface AdvanceSummarySummaryIF {
  paymentChannelId: number
  amountTotal: number
  amountCancel: number
}
export default function AdvanceSummaryList(props: AdvanceComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap', width: '30px' },
    { id: '2', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: '3', disablePadding: false, labelKey: 'เลขที่ใบเสร็จ', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: '4', disablePadding: false, labelKey: 'สถานะ', align: 'center', class: 'text-nowrap', width: '250px' },
    { id: '5', disablePadding: false, labelKey: 'รหัสผู้ป่วย', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '6', disablePadding: false, labelKey: 'รหัสผู้ป่วยในสาขา', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '7', disablePadding: false, labelKey: 'รหัสผู้ป่วยพิเศษ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '8', disablePadding: false, labelKey: 'ชื่อผู้ป่วย', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '9', disablePadding: false, labelKey: 'เงินสด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '10', disablePadding: false, labelKey: 'เงินโอน', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '11', disablePadding: false, labelKey: 'เดบิด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '12', disablePadding: false, labelKey: 'เครดิต', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '13', disablePadding: false, labelKey: 'รับเงินล่วงหน้า', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '14', disablePadding: false, labelKey: 'เงินสด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '15', disablePadding: false, labelKey: 'เงินโอน', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '16', disablePadding: false, labelKey: 'เดบิด', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '17', disablePadding: false, labelKey: 'เครดิต', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '18', disablePadding: false, labelKey: 'ใช้งินล่วงหน้า', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '19', disablePadding: false, labelKey: 'เงินรับล่วงหน้าคงเหลือ', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '20', disablePadding: false, labelKey: 'ผู้บันทึก', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '21', disablePadding: false, labelKey: 'รายละเอียด', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '22', disablePadding: false, labelKey: 'หมายเหตุการยกเลิก', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '23', disablePadding: false, labelKey: 'ผู้ยกเลิก', align: 'center', class: 'text-nowrap', width: '350px' }
  ]
  const headTotalCells = [
    { id: '1', disablePadding: false, labelKey: 'ประเภทการจ่าย', align: 'center', class: 'text-nowrap', width: '30px' },
    { id: '3', disablePadding: false, labelKey: 'สรุปรับเงินล่วงหน้า', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: '4', disablePadding: false, labelKey: 'สรุปยกเลิก', align: 'center', class: 'text-nowrap', width: '150px' }
  ]

  if (!snType) {
    headCells.splice(6, 1)
  }
  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const advance: AdvanceSummaryIF = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
          {
            option: 'TEXT',
            align: 'center',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            label: (
              <>
                {dateToView(advance.createdAt)} <br /> {timeToView(advance.createdAt)} น.
              </>
            ),
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.paymentNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: t(`REPORT.BILL_STATUS.${advance.statusOrder}`), class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.cnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.branchCnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.snCode || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.patientFullname, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.receiptAdvanceCash), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.receiptAdvanceTransfer), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.receiptAdvanceDebit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.receiptAdvanceCredit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.receiptAdvance), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.amountCash), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.amountTransfer), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.amountDebit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.amountCredit), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.amountAdvance), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(advance.advanceBalance), class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.staff || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.remark || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.cancelRemark || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: advance.cancelBy || '-', class: 'text-nowrap' },
        ]
      }
      if (!snType) {
        objRenderData.columns.splice(6, 1)
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: !snType ? 6 : 7 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvanceCash') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvanceTransfer') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvanceDebit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvanceCredit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCash') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTransfer') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountDebit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCredit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'advanceBalance') || 0), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 4 },

        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }
  const renderSummary = () => {
    const summary = props.summary
    const sumChannel1 = _.find(props.summary, { paymentChannelId: 1 })
    const sumChannel2 = _.find(props.summary, { paymentChannelId: 2 })
    const sumChannel3 = _.find(props.summary, { paymentChannelId: 3 })
    const sumChannel4 = _.find(props.summary, { paymentChannelId: 4 })

    const objRenderData1 = {
      key: 'chanel-1',
      id: 'chanel-1',
      obj: sumChannel1,
      columns: [
        { option: 'TEXT', align: 'center', style: { fontSize: 16 }, label: t(`REPORT.PAYMENT_CHANNEL.1`), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel1?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel1?.amountCancel || 0), class: 'text-nowrap' }
      ]
    }
    const objRenderData2 = {
      key: 'chanel-2',
      id: 'chanel-2',
      obj: sumChannel2,
      columns: [
        { option: 'TEXT', align: 'center', style: { fontSize: 16 }, label: t(`REPORT.PAYMENT_CHANNEL.2`), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel2?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel2?.amountCancel || 0), class: 'text-nowrap' }
      ]
    }
    const objRenderData3 = {
      key: 'chanel-3',
      id: 'chanel-3',
      obj: sumChannel3,
      columns: [
        { option: 'TEXT', align: 'center', style: { fontSize: 16 }, label: t(`REPORT.PAYMENT_CHANNEL.3`), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel3?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel3?.amountCancel || 0), class: 'text-nowrap' }
      ]
    }
    const objRenderData4 = {
      key: 'chanel-4',
      id: 'chanel-4',
      obj: sumChannel4,
      columns: [
        { option: 'TEXT', align: 'center', style: { fontSize: 16 }, label: t(`REPORT.PAYMENT_CHANNEL.4`), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel4?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel4?.amountCancel || 0), class: 'text-nowrap' }
      ]
    }

    return [
      <>{<TableRowCommon {...objRenderData1} />}</>,
      <>{<TableRowCommon {...objRenderData2} />}</>,
      <>{<TableRowCommon {...objRenderData3} />}</>,
      <>{<TableRowCommon {...objRenderData4} />}</>,
    ]

    return <></>

  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            // tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.dataExport, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.ADVANCE`)}
          branchName={props.branchName}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      _.map(props.dataExport, (d: any, index: number) => {
                        return renderData(d, index, true, 'data')
                      }),
                      renderData(props.dataExport, 0, true, 'sum')
                    ]}
                  </tbody>
                </table>
              </UseStyled.Styles>
            </>
          }
        />
      </div>
    </>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      <div className="large">สาขา: {props.branchName}</div>
      {props.componant}
    </>
  )
}
