import { Box } from '@mui/material'

/** STYLE */
import { styled } from '@mui/material/styles'

export const TableResponsive = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        paddingTop: 8,
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        }
      }
    }
  }
}))