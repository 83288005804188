/** CONSTANTS */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'

export const SliderItem = styled('div')(({ theme }) => ({
  flex: '0 0 250px',
  width: 250,
  maxWidth: 250,
  border: `1px solid ${colors.extraLightGray}`,
  background: colors.servicePoint.panel,
  borderRadius: 8,
  overflowX: 'hidden',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    flex: '0 0 277px',
    width: 277,
    maxWidth: 277
  }
}))

export const Header = styled('div')(({ theme }) => ({
  position: 'sticky',
  margin: '0 -1rem',
  padding: '0 0.5rem',
  height: 56,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  fontSize: 20,
  fontWeight: 600,
  color: colors.white,
  'span::selection': {
    background: 'transparent'
  },
  [theme.breakpoints.down('sm')]: {
    height: 48,
    fontSize: 18
  }
}))

export const Body = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  paddingBottom: '1rem',
  height: 'calc(100% - 56px)',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100% - 48px)'
  }
}))

type QueueAreaProp = {
  headerName: string
  body?: any
  count?: number
  height?: any
}

export default function QueueArea(props: QueueAreaProp) {

  return (
    <SliderItem className={'slider-area'}>
      <Header style={{ backgroundImage: colors.servicePoint.lab }}>
        <span className={'text-ellipsis'}>{props.headerName} {props.count && `(${props.count})`}</span>
      </Header>
      <Body className={'custom-scroll'}>{props.body}</Body>
    </SliderItem>
  )
}
