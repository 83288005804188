import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  colorPicker: {
    border: 'none',
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
    '& .MuiMenu-list': {
      backgroundColor: 'transparent',
      padding: 0
    },
    '& .block-picker': {
      width: '100% !important',
      boxShadow: 'none !important',
      overflow: 'hidden',
      '& div': {
        '&:first-of-type': {
          top: '0 !important'
        },
        '&:nth-of-type(2)': {
          height: '50px !important',
          marginTop: 10,
          '& > div': {
            border: 'none'
          }
        },
        '&:last-of-type': {
          overflowX: 'auto',
          maxHeight: 140,
          border: `1px solid ${colors.lightGray}`,
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar ': {
            width: 3
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.disabledGray,
            borderRadius: 50
          },
          '& > div:first-of-type': {
            marginRight: '0 !important',
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 30px)',
            gridGap: 8,
            justifyContent: 'center',
            '& span ': {
              display: 'inline-block',
              width: 30,
              height: 30,
              '& > div': {
                margin: '0 !important',
                width: '30px !important',
                height: '30px !important',
                border: `1px solid ${colors.lightGray}`
              }
            },
            '& > div': {
              border: 'none'
            }
          },
          '& > div:last-of-type': {
            display: 'none'
          }
        }
      }
    }
  }
}))

export const BtnColorPicker = styled(Button)(({ theme }) => ({
  height: 43,
  width: '100%',
  color: 'transparent !important',
  borderRadius: 4,
  border: `1px solid ${colors.lightGray}`
}))

export default useStyles
