import { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Button, MenuItem, Box, ClickAwayListener, Typography } from '@mui/material'
import { getNameMonthByNo } from 'constants/date-time'
import FilterSelect from 'component/Select/FilterSelect'
import ClinicApi, { BranchInterface, DentistInterface } from 'api/master/clinic.api'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import { getBranch, getPermissionActiveMenu, isBuddhistYear } from 'utils/app.utils'
import { isCreate, isDelete, isExport, isUpdate, isView } from 'utils/verification.utils'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'
import { selectMe } from 'app/slice/user.slice'
import { colors } from 'constants/theme'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { clinicTheme } from 'api/api'

/** STYLE */
const DivHeadSchedule = styled('div')(({ theme }) => ({
  padding: '1rem',
  boxShadow: `0px 3px 6px ${colors.black08}`,
  position: 'relative'
}))

const DateDisplaySelect = styled('div')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  '& .arrow': {
    width: '30px',
    height: '30px',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'inline-block',
    '& svg': {
      height: '16px',
      verticalAlign: 'top',
      marginTop: '7px',
      '&.arrowNext': {
        transform: 'rotate(180deg)'
      }
    },
    '&:hover': {
      backgroundColor: colors.extraLightGray,
      borderRadius: '50%'
    }
  },
  '& .monthPicker': {
    cursor: 'pointer',
    padding: '0px',
    display: 'inline-block',
    '& .ant-picker ': {
      verticalAlign: 'top !important',
      padding: '0px'
    },
    '& .ant-picker-input ': {
      padding: '0.25rem',
      '&:hover': {
        backgroundColor: colors.extraLightGray,
        borderRadius: '50%'
      },
      '& input:placeholder-shown': {
        display: 'none'
      },
      '& input': {
        display: 'none'
      },
      '& .ant-picker-borderless': {
        verticalAlign: 'top'
      }
    },
    '& .ant-picker-suffix': {
      margin: '0'
    }
  }
}))

const SpanClearFilter = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  color: colors.themeSecondColor
}))

const ButtonAdd = styled(Button)(({ theme }) => ({
  fontSize: '16px !important',
  border: `1px solid ${colors.themeSecondColor} !important`,
  backgroundColor: `${colors.themeSecondColor} !important`,
  color: `${colors.white} !important`,
  '&.Mui-disabled': {
    opacity: 0.7
  }
}))

const ButtonCancel = styled(Button)(({ theme }) => ({
  fontSize: '16px !important',
  border: `1px solid ${colors.themeSecondColor} !important`,
  color: `${colors.themeSecondColor} !important`
}))

const ButtonShare = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  border: `1px solid ${colors.themeSecondColor}`,
  color: colors.themeSecondColor,
  '&.Mui-disabled': {
    borderColor: `${colors.disabledLightGray} !important`
  }
}))

const CustomFilterDoctor = styled(Box)<{ open?: boolean }>(({ theme, open }) => ({
  '.MuiOutlinedInput-input': {
    marginTop: '-1px',
    padding: `4px 0 0 0 !important`
  },
  '.Mui-multiple': {
    '.MuiFormControl-root': {
      '.MuiOutlinedInput-root': {
        marginTop: 0,
        padding: '4px 0 4px 12px !important',
        flexWrap: 'nowrap'
      },
      '.MuiOutlinedInput-input': {
        height: open ? 'auto' : 0,
        minWidth: 'unset',
        padding: '0 !important'
      }
    }
  },
  '.edit-filter-doctor': {
    zIndex: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    left: '12px'
  }
}))

const ListFilterDoctors = styled(Box)(({ theme }) => ({
  '.MuiFormControlLabel-root': {
    margin: 0,
    overflow: 'hidden',
    '.MuiCheckbox-root': {
      padding: '0 8px 0 0'
    },
    '.MuiTypography-root': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }
}))

type ViewDisplayType = 'DATE' | 'WEEK' | 'MONTH' | 'OVERALL'
export interface DateInterface {
  date: number
  month: number
  year: number
}

export interface IHeadScheduleProps {
  viewDisplay: ViewDisplayType
  branchId: number
  date: DateInterface
  doctor: string
  handleViewDisplay: (display: ViewDisplayType) => void
  handleBranchId: (branchId: number) => void
  handleDate: (objDate: DateInterface) => void
  handleDoctor: (dictors: string) => void
  maDentist: number
  handleDentist: (dentistId: number) => void
  handleClickAdd: (val: any) => void
  handleClickView: (val: any) => void
  onUseCopyMonth: (yearMonth: string, dentistId: number) => void
  share: () => void
  onCancel: () => void
  onSave: () => void
  edit: boolean
  listTemplateDoctor: any[]
}

export function HeadSchedule(props: IHeadScheduleProps) {
  const { t, i18n } = useTranslation()
  const user = useSelector(selectMe)

  const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isExport: isExport(),
    isView: isView()
  }

  const [userData, setUserData]: any = useState({})
  const [branches, setBranches] = useState<BranchInterface[]>([])
  const [doctors, setDoctors] = useState<DentistInterface[]>([])
  const [branchId, setBranchId] = useState(getBranch() || 1)
  const [selectDate, setSelectDate] = useState<DateInterface>(props.date)
  const [selectDoctors, setSelectDoctors] = useState<any[]>([])
  const [selectDentist, setSelectDentist] = useState<number>(0)
  const [selectTemplate, setSelectTemplate] = useState<string>('')
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  useEffect(() => props.handleDate(selectDate), [selectDate])
  useEffect(() => props.handleDoctor(selectDoctors.filter((v: any) => v > 0).join(',') || '0'), [selectDoctors])
  useEffect(() => props.handleDentist(selectDentist), [selectDentist])
  useEffect(() => setUserData(user), [user])

  const loadData = useCallback(() => {
    setSelectTemplate('')
    ClinicApi.findAllBranches().then(({ data }) => setBranches(data))
    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data }) => {
      const dentists: DentistInterface[] = data.map((d: any) => {
        return {
          dentistId: d.userId,
          dentistFullname: d.fullname
        }
      })
      let users: any[] = dentists
      const meOnly: boolean = userData?.ownSchedule === '1' ? true : false
      if (meOnly) {
        users = _.filter(dentists, (usersData: any) => Number(usersData.dentistId) === Number(usersData?.userId))
      }
      setDoctors(users)
      setSelectDoctors([...users.map((d: DentistInterface) => d.dentistId)])
    })
  }, [props.branchId, props.date])

  useEffect(() => {
    if (selectDentist !== props.maDentist) setSelectDentist(props.maDentist)
    setSelectTemplate('')
  }, [props.maDentist])

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    setSelectTemplate('')
  }, [props.listTemplateDoctor])

  const handleClickPrevious = () => {
    let unit: any = 'day'
    if (props.viewDisplay === 'WEEK') unit = 'week'
    else if (props.viewDisplay === 'MONTH' || props.viewDisplay === 'OVERALL') unit = 'month'
    const dateBefore: string = moment(`${selectDate.year}-${selectDate.month}-${selectDate.date}`, 'YYYY-M-D').format('YYYY-MM-DD')
    const date: string = moment(dateBefore).subtract(1, unit).format('YYYY-MM-DD')
    const dateSlip: any = date.split('-')
    setSelectDate({ year: parseInt(dateSlip[0]), month: parseInt(dateSlip[1]), date: parseInt(dateSlip[2]) })
  }

  const handleClickNext = () => {
    let unit: any = 'day'
    if (props.viewDisplay === 'WEEK') unit = 'week'
    else if (props.viewDisplay === 'MONTH' || props.viewDisplay === 'OVERALL') unit = 'month'
    const dateBefore: string = moment(`${selectDate.year}-${selectDate.month}-${selectDate.date}`, 'YYYY-M-D').format('YYYY-MM-DD')
    const date: string = moment(dateBefore).add(1, unit).format('YYYY-MM-DD')
    const dateSlip: any = date.split('-')
    setSelectDate({ year: parseInt(dateSlip[0]), month: parseInt(dateSlip[1]), date: parseInt(dateSlip[2]) })
  }

  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: DentistInterface) => d.dentistId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }

  const handleSelectTemplate = (e: any) => {
    const val: string = e?.target?.value || ''
    if (val !== '') {
      props.onUseCopyMonth(val, selectDentist)
      setSelectTemplate(val)
    }
  }

  return (
    <DivHeadSchedule className="head-schedule" style={{}}>
      <Row className="mx-0 mb-1 mb-xl-0 align-items-center">
        <Col xs={'12'} xl={'auto'} className="px-1 pb-3 pb-xl-0 mb-2">
          <DateDisplaySelect className="flex-center">
            <div className="arrow">
              <ArrowBackIosNewIcon onClick={handleClickPrevious} />
            </div>
            <div style={{ width: 160 }} className="text-center">{`${props.viewDisplay === 'DATE' ? selectDate.date : ''} ${getNameMonthByNo(selectDate.month, i18n.language)} ${selectDate.year + isBuddhistYear()}`}</div>
            <div className="arrow">
              <ArrowBackIosNewIcon onClick={handleClickNext} className={`arrowNext`} />
            </div>
            <div className="monthPicker">
              <InputSecondNewDatePicker
                disabledTextField
                views={PICKER_VIEWS.MONTH}
                value={moment(`${selectDate.year}-${String(selectDate.month).padStart(2, '0')}-${String(selectDate.date).padStart(2, '0')}`, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                onchange={(e) => {
                  setSelectDate({
                    ...selectDate,
                    date: moment(e)?.date() || moment()?.date(),
                    month: (moment(e)?.month()) + 1,
                    year: moment(e)?.year() || moment()?.year()
                  })
                }}
              />
            </div>
          </DateDisplaySelect>
        </Col>
        <Col xs={'12'} sm={6} xl={3} xxl={2} className="px-1 mb-2">
          <FilterSelect
            formControlClass={'filter filter-branch not-max-width'}
            fullWidth={true}
            onchange={(e) => {
              setBranchId(e.target.value)
              props.handleBranchId(e.target.value)
            }}
            renderValue={() => `${t('DOCTOR_SCHEDULE.HEADER.BRANCH')}: ${t(`${_.find(branches, (b: BranchInterface) => b.branchId === branchId)?.branchName}`)}`}
            label={t('DOCTOR_SCHEDULE.HEADER.BRANCH')}
            selectId="select-branchId"
            value={branchId}
            labelId="label-branchId"
            options={_.map(branches, (branch: BranchInterface, i: number) => (
              <MenuItem className="py-1" key={i} value={branch.branchId}>
                {branch.branchName}
              </MenuItem>
            ))}
            formControlStyle={{ maxWidth: '100% !important' }}
          />
        </Col>
        {props.edit ? (
          <Col xs={'12'} sm={6} xl={3} xxl={3} className="px-1 mb-2">
            <CustomFilterDoctor className="position-relative">
              <AutocompleteSelect
                labelId="select-dentist"
                options={doctors}
                getOptionLabel={(option) => `${t('USER_DOCTOR')}: ${option.dentistFullname || t('DOCTOR_SCHEDULE.HEADER.NOT_CHOOSE')}`}
                renderOption={(props, option) => {
                  return (
                    <ListFilterDoctors {...props} key={option.dentistId} component="li">
                      {option.dentistFullname}
                    </ListFilterDoctors>
                  )
                }}
                onchange={(e, value) => setSelectDentist(value?.dentistId)}
                noOptionsText={t('NOT_FOUND')}
                filterOptions={(option) => option.dentistFullname}
                value={_.find(doctors, { dentistId: selectDentist }) || ''}
                height={32}
                disableClearable
              />
            </CustomFilterDoctor>
          </Col>
        ) : (
          <Col xs={'12'} sm={6} xl={3} xxl={3} className="px-1 mb-2">
            <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
              <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                <AutocompleteSelect
                  open={openFilterDoctors}
                  multiple
                  labelId="label-doctors"
                  options={['ALL', ...doctors, 'CLEAR']}
                  getOptionLabel={(option) => _.find(doctors, { dentistId: option })?.dentistFullname || '-'}
                  renderOption={(props, option) => {
                    if (option === 'ALL') {
                      return (
                        <ListFilterDoctors {...props} key={option} component="li" value={option}>
                          <InputCheckbox label={t('DOCTOR_SCHEDULE.HEADER.ALL')} checked={_.size(selectDoctors) === _.size(doctors)} indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors) !== _.size(doctors)} className={'pe-none'} />
                        </ListFilterDoctors>
                      )
                    } else if (option === 'CLEAR') {
                      return (
                        <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                          <SpanClearFilter className="pe-none">{t('DOCTOR_SCHEDULE.HEADER.RESET')}</SpanClearFilter>
                        </Box>
                      )
                    } else {
                      return (
                        <ListFilterDoctors {...props} key={option.dentistId} component="li" value={option.dentistId}>
                          <InputCheckbox label={option.dentistFullname} checked={_.includes(selectDoctors, option.dentistId)} className={'pe-none'} />
                        </ListFilterDoctors>
                      )
                    }
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    return (
                      <>
                        <span className="pr-1">{t('USER_DOCTOR')}:</span>
                        {!openFilterDoctors &&
                          ((_.size(tagValue) === _.size(doctors) && <span>{t('DOCTOR_SCHEDULE.HEADER.ALL')}</span>) || (
                            <Box className="text-ellipsis">
                              {_.map(tagValue, (option: any, index: number) => {
                                return (
                                  <span>
                                    {_.find(doctors, { dentistId: option })?.dentistFullname}
                                    {index !== _.findLastIndex(tagValue) && option && `,`}
                                  </span>
                                )
                              })}
                            </Box>
                          ))}
                      </>
                    )
                  }}
                  onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                  noOptionsText={t('NOT_FOUND')}
                  filterOptions={(option) => 'ALL' + option.dentistFullname + 'CLEAR'}
                  value={selectDoctors}
                  height={32}
                  disableClearable
                />
              </CustomFilterDoctor>
            </ClickAwayListener>
          </Col>
        )}
        {props.edit && (
          <Col xs={'12'} sm={6} xl={3} xxl={2} className="px-1 mb-2">
            <FilterSelect
              formControlClass={'select-template not-max-width'}
              fullWidth={true}
              onchange={handleSelectTemplate}
              renderValue={() => `${t('DOCTOR_SCHEDULE.HEADER.PROTOTYPE')}: ${selectTemplate !== '' ? `${getNameMonthByNo(Number(String(selectTemplate).split('-')[1]), i18n.language)} ${Number(String(selectTemplate).split('-')[0]) + isBuddhistYear()}` : t('DOCTOR_SCHEDULE.HEADER.NOT_CHOOSE')}`}
              label={t('DOCTOR_SCHEDULE.HEADER.PROTOTYPE')}
              selectId="select-template"
              value={selectTemplate || 0}
              labelId="label-select-template"
              options={
                props.listTemplateDoctor.length ? (
                  _.map(props.listTemplateDoctor, (template: any, i: number) => (
                    <MenuItem className="py-1" key={i} value={template.monthSchedule}>
                      {getNameMonthByNo(Number(template.month), i18n.language)} {Number(template.year) + isBuddhistYear()}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem className="py-1" key={'no'} disabled value="">
                    {t('DOCTOR_SCHEDULE.HEADER.NOTFIND_PROTOTYPE')}
                  </MenuItem>
                )
              }
              formControlStyle={{ maxWidth: '100% !important' }}
            />
          </Col>
        )}
        {props.edit ? (
          <Col xs={'auto'} sm={'auto'} xl={'auto'} className="px-1 ml-auto">
            <ButtonCancel className="mx-1 mb-1 align-top" onClick={props.onCancel}>
              {t('DOCTOR_SCHEDULE.BUTTON.CANCEL')}
            </ButtonCancel>
            <ButtonAdd className="mx-1 mb-1 align-top" onClick={props.onSave}>
              {t('DOCTOR_SCHEDULE.BUTTON.SAVE')}
            </ButtonAdd>
          </Col>
        ) : (
          <Col xs={'auto'} sm={'auto'} xl={'auto'} className="px-1 ml-auto">
            {/* <ButtonShare disabled={selectDoctors.length && selectDoctors[0] && selectDoctors.length === 1 ? false : true} className="mx-1 align-top mb-1" onClick={props.share}>
              {t('แชร์')}
            </ButtonShare> */}
            <ButtonShare disabled={getPermissionActiveMenu()?.isExport === 1 ? false : true} className="mx-1 align-top mb-1" onClick={props.share}>
              {t('DOCTOR_SCHEDULE.BUTTON.SHARE')}
            </ButtonShare>
            <ButtonAdd className="mx-1 mb-1 align-top" disabled={permissions.isUpdate.disabled} onClick={props.handleClickAdd}>
              {t('DOCTOR_SCHEDULE.BUTTON.MANAGE_SCHEDULE')}
            </ButtonAdd>
          </Col>
        )}
      </Row>
    </DivHeadSchedule>
  )
}
