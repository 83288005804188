import { Box, styled } from '@mui/material'
import React from 'react'
import { getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export const PdfOutStandingPaymentCustom = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '210mm',
    maxHeight: '450px',
    position: 'relative',
    // border: '1px solid black',
    '.f-12': {
        fontSize: '12px',
        color: '#29302E'
    },
    '.f-10': {
        fontSize: '10px'
    },
    '.box-footer-pdf': {
        // position: 'absolute',
        // bottom: '18px',
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        width: 'calc(100% - 64px)',
        '.box-sig': {
            display: 'flex',
            gap: '5px',
            width: '100%',
            alignItems: 'center',
            '.sig-img': {
                img: {
                    maxWidth: '125px',
                    width: '100%',
                    maxHeight: '30px',
                    height: '100%'
                }
            },
            '.line-sig': {
                width: '125px',
                height: '2px',
                backgroundColor: 'black',
                borderRadius: '3px'
            }
        }
    }
}))

type PdfOutStandingPaymentProps = {
    dataOut: any
    type?: 'OUT' | 'OUT_FINISHED'
}

const PdfOutStandingPayment = (props: PdfOutStandingPaymentProps) => {
    const { dataOut, type } = props
    const { t } = useTranslation()
    const pdfTitle = type !== 'OUT_FINISHED' ? "MODAL_OUTSTANDINGPAYMENT" : "MODAL_OUTSTANDINGPAYMENT_FINISHED"
    const branchInfo = getClinicInfo()

    return (
        <PdfOutStandingPaymentCustom>
            <Box sx={{ width: '100%', height: '100%', padding: '18px 32px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ maxWidth: '57px', width: '100%', alignContent: 'center', img: { maxWidth: '100%' } }}>
                        <img src={branchInfo?.branchLogo || ''} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '8px' }}>
                        <Box className={`f-12`}>
                            {dataOut?.patientCode}
                        </Box>
                        <Box sx={{ fontWeight: '500' }}>
                            {t(`${pdfTitle}.TITLE`)}
                        </Box>
                        <Box className={`f-12`} sx={{ color: 'black' }}>
                            {branchInfo?.hospitalName}
                        </Box>
                        <Box className={`f-12`}>
                            {t('MODAL_OUTSTANDINGPAYMENT.PDF_.DATE_START')} {toBuddhistYear(moment(dataOut?.dateStart))}
                        </Box>
                    </Box>
                </Box>
                <Box className={`f-12`} sx={{ margin: '24px 0px' }}>
                    {t(`${pdfTitle}.FORM.DETAIL_EXAM`,
                        {
                            hospitalName: branchInfo?.hospitalName,
                            branchName: branchInfo?.branchName,
                            dateStart: dataOut?.dateStart ? toBuddhistYear(moment(dataOut?.dateStart)) : '-',
                            dateEnd: dataOut?.dateEnd ? toBuddhistYear(moment(dataOut?.dateEnd)) : '-',
                            price: dataOut?.price ? numberFormat(dataOut?.price) : '-',
                            paymentId: dataOut?.paymentNumber || '-'
                        })}
                </Box>
                {/* dataOut?.signature?.patientSignature */}
                <Box className={`box-footer-pdf`}>
                    <Box className={`box-sig f-10`} sx={{ justifyContent: 'flex-end', }}>
                        {type !== 'OUT_FINISHED' ?
                            <>
                                <Box>
                                    {t('MODAL_OUTSTANDINGPAYMENT.PDF_.PATIENT_SIG')}
                                </Box>
                                <Box>
                                    <Box className={`sig-img`}>
                                        {dataOut?.signature?.patientSignature && (
                                            <img src={`data:image/png;base64,${dataOut?.signature?.patientSignature}`} className="w-100 h-100" alt="" />
                                        ) || dataOut?.patientSignature && (
                                            <img src={`data:image/png;base64,${dataOut?.patientSignature}`} />
                                        ) || (
                                                <Box sx={{ maxWidth: '120px', width: '100%', height: '15px' }} />
                                            )
                                        }
                                    </Box>
                                    <Box className={`line-sig`} sx={{ marginTop: (dataOut?.signature?.patientSignature || dataOut?.patientSignature) ? '5px' : '0' }} />
                                    <Box sx={{ marginTop: '5px', textAlign: 'center' }}>
                                        {`(${dataOut?.patientFullName})`}
                                    </Box>
                                </Box>
                                <Box className={`f-10`}>
                                    {t('MODAL_TREATMENT_CONSENT.SIGN.PATIENT')}
                                </Box>
                            </>

                            :
                            <>
                                <Box>
                                    {t(`${pdfTitle}.PDF_.MANAGER_SIG`)}
                                </Box>
                                <Box>
                                    <Box className={`sig-img`}>
                                        {dataOut?.signature?.managerSignature && (
                                            <img src={`data:image/png;base64,${dataOut?.signature?.managerSignature}`} className="w-100 h-100" alt="" />
                                        ) || dataOut?.managerSignature && (
                                            <img src={`data:image/png;base64,${dataOut?.managerSignature}`} />
                                        ) || (
                                                <Box sx={{ maxWidth: '120px', width: '100%', height: '15px' }} />
                                            )
                                        }
                                    </Box>
                                    <Box className={`line-sig`} />
                                    <Box sx={{ marginTop: '5px', textAlign: 'center', visibility: 'hidden', display: (dataOut?.signature?.managerSignature || dataOut?.managerSignature) ? 'block' : 'none' }}>
                                        {`( )`}
                                    </Box>
                                </Box>
                                <Box className={`f-10`}>
                                    {t('MODAL_OUTSTANDINGPAYMENT.FORM.MANAGER')}
                                </Box>
                            </>
                        }
                    </Box>
                    <Box className={`box-sig f-10`}>
                        {type !== 'OUT_FINISHED' ?
                            <>
                                <Box>
                                    {t('MODAL_OUTSTANDINGPAYMENT.PDF_.DOCTOR_SIG')}
                                </Box>
                                <Box>
                                    <Box className={`sig-img`}>
                                        {dataOut?.signature?.doctorSignature && (
                                            <img src={`data:image/png;base64,${dataOut?.signature?.doctorSignature}`} className="w-100 h-100" alt="" />
                                        ) || dataOut?.doctorSignature && (
                                            <img src={`data:image/png;base64,${dataOut?.doctorSignature}`} />
                                        ) || (
                                                <Box sx={{ maxWidth: '120px', width: '100%', height: '15px' }} />
                                            )
                                        }
                                    </Box>
                                    <Box className={`line-sig`} sx={{ marginTop: (dataOut?.signature?.doctorSignature || dataOut?.doctorSignature) ? '5px' : '0' }} />
                                    <Box sx={{ marginTop: '5px', textAlign: 'center' }}>
                                        {`(${dataOut?.doctorName || ''})`}
                                    </Box>
                                </Box>
                                <Box className={`f-10`}>
                                    {t('MODAL_TREATMENT_CONSENT.SIGN.DOCTOR')}
                                </Box>
                            </> :
                            <>
                                <Box>
                                    {t(`${pdfTitle}.PDF_.PATIENT_SIG`)}
                                </Box>
                                <Box>
                                    <Box className={`sig-img`}>
                                        {dataOut?.signature?.patientSignature && (
                                            <img src={`data:image/png;base64,${dataOut?.signature?.patientSignature}`} className="w-100 h-100" alt="" />
                                        ) || dataOut?.patientSignature && (
                                            <img src={`data:image/png;base64,${dataOut?.patientSignature}`} />
                                        ) || (
                                                <Box sx={{ maxWidth: '120px', width: '100%', height: '15px' }} />
                                            )
                                        }
                                    </Box>
                                    <Box className={`line-sig`} sx={{ marginTop: (dataOut?.signature?.patientSignature || dataOut?.patientSignature) ? '5px' : '0' }} />
                                    <Box sx={{ marginTop: '5px', textAlign: 'center' }}>
                                        {`(${dataOut?.patientFullName})`}
                                    </Box>
                                </Box>
                                <Box className={`f-10`}>
                                    {t('MODAL_TREATMENT_CONSENT.SIGN.PATIENT')}
                                </Box>
                            </>
                        }

                    </Box>
                    {type !== 'OUT_FINISHED' &&
                        <Box className={`box-sig f-10`} sx={{ marginBottom: (dataOut?.signature?.managerSignature || dataOut?.managerSignature) ? '0' : '20px' }}>
                            <Box>
                                {t('MODAL_OUTSTANDINGPAYMENT.PDF_.MANAGER_SIG')}
                            </Box>
                            <Box>
                                <Box className={`sig-img`}>
                                    {dataOut?.signature?.managerSignature && (
                                        <img src={`data:image/png;base64,${dataOut?.signature?.managerSignature}`} className="w-100 h-100" alt="" />
                                    ) || dataOut?.managerSignature && (
                                        <img src={`data:image/png;base64,${dataOut?.managerSignature}`} />
                                    ) || (
                                            <Box sx={{ maxWidth: '120px', width: '100%', height: '15px' }} />
                                        )
                                    }
                                </Box>
                                <Box className={`line-sig`} />
                                <Box sx={{ marginTop: '5px', textAlign: 'center', visibility: 'hidden', display: (dataOut?.signature?.managerSignature || dataOut?.managerSignature) ? 'block' : 'none' }}>
                                    {`( )`}
                                </Box>
                            </Box>
                            <Box className={`f-10`}>
                                {t('MODAL_OUTSTANDINGPAYMENT.FORM.MANAGER')}
                            </Box>
                        </Box>
                    }
                </Box>
                {type === "OUT_FINISHED" &&
                    <Box className='mt-3 f-10'>
                        {t('MODAL_OUTSTANDINGPAYMENT_FINISHED.FORM.REMARK')}
                    </Box>
                }
            </Box>
        </PdfOutStandingPaymentCustom>
    )
}

export default PdfOutStandingPayment