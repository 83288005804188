import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface FrequenciesCigaretteInterface {
  frequencyName: string
  type?: string
  status: string
}

export default class FrequenciesCigaretteApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('frequencies-cigarettes', { params: { ...props } }).then((res) => res)
  }

  static create(frequencyName: string) {
    return this.api.post('frequencies-cigarettes', { frequencyName, type: 'CIGARETTE' })
  }

  static findById(id: number): Promise<FrequenciesCigaretteInterface> {
    return this.api.get(`frequencies-cigarettes/${id}`)
  }

  static update(id: number, body: FrequenciesCigaretteInterface) {
    body.type = 'CIGARETTE'
    return this.api.patch(`frequencies-cigarettes/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`frequencies-cigarettes/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`frequencies-cigarettes/${id}`)
  }
}
