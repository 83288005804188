import { dentalColor, dentalOperativeType, dentalSidebarIcon, dentalLocaleTH, dentalTemplateImage, DentalTemplatePickRoom, DentalIcons, dentalWatermark, dentalLocaleEN } from "./theme/dental"
import { plusColor, plusOperativeType, plusSidebarIcon, plusLocaleTH, plusTemplateImage, PlusTemplatePickRoom, PlusIcons, plusWatermark, plusLocaleEN } from "./theme/plus"
import { clinicTheme } from "api/api"
import { watermark as wm } from './images';

export interface ColorThemeInterface {
  transparent: string
  white: string
  black: string

  themeMainColor: string
  themeSecondColor: string
  themeSecondDarkColor: string
  themeThirdColor: string

  statusActive: string
  statusInactive: string
  statusSuccess: string
  statusDangerColor: string
  statusWarning: string

  extraLightBlue: string
  borderHeaderCard: string
  bgBase: string
  highlightSelect: string
  lightYellow: string
  blue: string
  extraLightSky: string
  sky: string
  secondSky: string
  orange: string

  extraLightGray: string
  lightGray: string
  gray: string
  darkGray: string

  disabledLightGray: string
  disabledGray: string
  borderInput: string

  textExtraLightGray: string
  textLightGray: string
  textPrimary: string

  navbar: string
  navbarLinkActive: string

  white18: string
  white30: string
  white70: string
  white80: string
  white90: string
  black03: string
  black05: string
  black08: string
  black10: string
  black12: string
  black16: string,
  black20: string
  black25: string
  black30: string
  black60: string
  black75: string

  themeMainColor06: string
  themeMainColor09: string
  themeSecondColor06: string
  themeSecondColor10: string
  themeSecondColor15: string
  themeSecondColor30: string
  themeSecondColor80: string
  statusInactive10: string

  lightThemeMain: string
  backdrop: string
  filterDropdown: string

  // service type
  servicePoint: {
    panel: string
    counter: string
    roomServices: string
    services: string
    payment: string
    lab: string
    label: {
      counter: string
      roomServices: string
      payment: string
    }
  },

  // member ranking
  memberRanking: {
    rankingBronze: string
    rankingSilver: string
    rankingGold: string
    rankingPlatinum: string
    rankingDiamond: string
    background: {
      rankingBronze: string
      rankingSilver: string
      rankingGold: string
      rankingPlatinum: string
      rankingDiamond: string
    }
  },

  // status
  appointmentStatus: {
    statusNormal: string
    statusCancel: string
    statusUnConnect: string
    statusChange: string
    statusEnter: string
    statusArrived: string
    statusUnArrived: string
    statusDone: string
    statusWaitPayment: string
    statusPayment: string
    statusService: string
    statusCounterCheckIn: string
    statusCancelVisit: string
  },

  doctorScheduleStatus: {
    leave: string
    missing: string
    solid: {
      leave: string
      missing: string
    }
  },

  // tag
  tag: {
    disease: string
    medicineUse: string
    beAllergic: string
    df: string
    lab: string
  },

  // expired 
  expired: {
    danger: string
    danger2: string
    warning: string
    warningLinear: string
  },

  tooth: {
    defaultLayer1: string
    defaultLayer2: string
    defaultLayer3: string
    layer1: string
    layer2: string
    layer3: string
    lowerTeeth: string
  },

  approveStatus: {
    approve: string
    pending: string
    cancel: string
  },

  paletteColors: string[]
  paletteColorsDoctorNote: string[]

  // dashboard
  dashboard: {
    summaryBox: {
      sales: string
      salesMarginUp: string
      salesMarginDown: string
      service: string
      customer: string
    },
    saleProduct: {
      evenBg: string[]
      oddBg: string[]
    },
    saleOperative: string[]
    paymentMethod: string[]
    totalExpenses: string[]
    operativeType: string[]
    hightSaleOperative: string[]
  },
  appointmentDoctor: {
    colorEven: string
    colorOdd: string
  }
}

export interface SidebarInterface {
  Counter: any
  Doctor: any
  Finance: any
  Lab: any
  Xray: any
  Application: any
  Management: any
  Custom: any
  Warehouses: any
  Report: any
  Setting: any
  Update: any
}

export interface TemplateImageInterface {
  logo: any
  logoHorizontal: any
  login: any
  welcome: any
  pick: {
    left: any
    right: any
  }
}

export interface TemplatePickRoomInterface {
  Branch: any
  Counter: any
  Service: any
  Payment: any
  OtherService: any
}

export interface SpecificIconInterface {
  Favicon: any
  SendExam: any
  Counter: any
  Service: any
  Payment: any
  Treatment: any
  DoneTreatment: any
}

let colors: ColorThemeInterface = dentalColor
let colorOperativeTypes: string[] = dentalOperativeType
let sidebarIcon: SidebarInterface = dentalSidebarIcon
let localeTH = dentalLocaleTH
let localeEN = dentalLocaleEN
let templateImage: TemplateImageInterface = dentalTemplateImage
let PickRoom: TemplatePickRoomInterface = DentalTemplatePickRoom
let ThemeIcon: SpecificIconInterface = DentalIcons
const cliniterWatermark = wm.cliniter
let watermark = dentalWatermark

if (clinicTheme.theme === clinicTheme.type.PLUS) {
  colors = plusColor
  colorOperativeTypes = plusOperativeType
  sidebarIcon = plusSidebarIcon
  localeTH = plusLocaleTH
  localeEN = plusLocaleEN
  templateImage = plusTemplateImage
  PickRoom = PlusTemplatePickRoom
  ThemeIcon = PlusIcons
  watermark = plusWatermark
}

export { colors, colorOperativeTypes, sidebarIcon, localeTH, localeEN, templateImage, PickRoom, ThemeIcon, watermark, cliniterWatermark }