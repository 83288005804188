import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  modal: {
    '& .modal-sm': {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '400px !important'
      }
    }
  },
  formControl: {},
  inputField: {
    '& .MuiFormHelperText-root.Mui-error': {
      whiteSpace: 'nowrap'
    }
  }
}))

export default useStyles
