import { Box, Typography, styled } from '@mui/material'
import { SignatureAttachmentsProps } from 'api/dentists/treatment.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import { colors } from 'constants/theme'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CanvasDraw from 'react-canvas-draw'
import { useTranslation } from 'react-i18next'
import { getClinicInfo, numberFormat } from 'utils/app.utils'
import { dateToView } from 'utils/date.utils'

// type Props = {}

export const PDFContainer = styled(Box)(({ theme }) => ({
    width: '297mm',
    height: '420mm',
    backgroundColor: "#fff",
    overflow: "hidden",
    '.pdf-body': {
        padding: '20px 60px',
        height: '100%',
        width: "100%",
        position: 'relative',
        '.pdf-title': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '24px',
            '.pdf-branch-logo': {
                height: '100px',
                img: {
                    maxHeight: '100%'
                }
            },
            '.pdf-title-text': {
                fontWeight: 'bolder',
                color: 'blue'
            }
        },
        '.pdf-content': {
            '.pdf-content-date': {
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'relative'
            },
            '.pdf-table-container': {
                marginTop: '8px',
                'table': {
                    width: '100%'
                },
                'table .table-head th': {
                    border: '1px solid blue',
                    textAlign: 'center',
                    padding: '8px 8px ',
                },
                'table .table-body td': {
                    border: '1px solid blue',
                    padding: '8px',
                    height: '24px',
                    minHeight: '19px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }
            },
            '.pdf-text-detail': {
                display: 'flex',
                marginTop: "24px",
                '.pdf-text-detail-value': {
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    width: 'calc(100% - 150px)',
                    '.pdf-value-text': {
                        position: 'relative',
                        width: '100%',
                    }
                }
            },
        },
        '.pdf-container-signature': {
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: ' center',
            width: '100%'
        }
    },
    '.text-break-word': {
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        overflowWrap: 'break-word'
    }
}))

const PDFLineText = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '90px',
    backgroundColor: 'black',
    height: '1.5px'
}))

type PdfInvoiceProps = {
    type: 'treatment' | 'historyTreatment'
    treatment: any
    renderCanvas: (type: 'doctor' | 'patient') => void
    clearCanvas: (type: 'doctor' | 'patient' | 'both') => void
    canvasDoctorRef: any
    canvasPatientRef: any
    signature: any
}

const PdfInvoice = (props: PdfInvoiceProps) => {
    const { treatment, canvasDoctorRef, canvasPatientRef, signature } = props
    const { t } = useTranslation()

    const [textRemark, setTextRemark] = useState<string[]>([''])
    const [lineRemark, setLineRemark] = useState<number>(1)
    const [textNextVisit, setTextNextVisit] = useState<string[]>([''])
    const [lineNextVisit, setLineNextVisit] = useState<number>(1)
    const [totalTreatment, setTotalTreatment] = useState<number>(0)

    const textRemarkRef = useRef<any>(null)
    const textNextVisitRef = useRef<any>(null)

    const BranchInfo = getClinicInfo()

    const headCells = [
        { title: t('PDF_INVOICE.PDF.NO'), title_en: t('PDF_INVOICE.PDF.NO_EN'), style: { width: '70px' } },
        { title: t('PDF_INVOICE.PDF.CODE'), title_en: t('PDF_INVOICE.PDF.CODE_EN'), style: { width: '70px' } },
        { title: t('PDF_INVOICE.PDF.TREATMENT_LIST'), title_en: t('PDF_INVOICE.PDF.TREATMENT_LIST_EN'), style: { width: 'auto' } },
        { title: t('PDF_INVOICE.PDF.QUANTITY'), title_en: t('PDF_INVOICE.PDF.QUANTITY_EN'), style: { width: '100px' } },
        { title: t('PDF_INVOICE.PDF.PRICE'), title_en: t('PDF_INVOICE.PDF.PRICE_EN'), style: { width: '120px' } },
    ]

    useEffect(() => {
        renderTextDetail()
        handleTotalTreatment()
    }, [treatment])

    const handleTotalTreatment = () => {
        const newTotalPD = treatment?.summeryProducts?.map((item: any) => item?.total || 0)
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
        const newTotal = treatment?.summeryTreatments?.map((treatment: any) => treatment?.total || 0)
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
        setTotalTreatment((newTotal + newTotalPD) || 0)
    }

    const handlePatientCode = () => {
        if (BranchInfo?.patientCodeAppointment === '1') {
            return treatment?.patientInfo?.cnNumber || '-'
        } else if (BranchInfo?.patientCodeAppointment === '0') {
            return treatment?.patientInfo?.branchCnNumber || '-'
        } else if (BranchInfo?.patientCodeAppointment === '2') {
            return treatment?.patientInfo?.snCode || '-'
        } else {
            return '-'
        }
    }

    const renderTextDetail = useCallback(() => {

        const handleTreatment = (data: string) => {
            const dataTreatment = JSON.parse(data)
            const textTreatment: string[] = []
            dataTreatment?.forEach((item: any) => {
                textTreatment.push(item?.operativeName || item?.operativeTypeName)
            })
            return textTreatment.toString()
        }

        const newTextRemark: string[] = []
        const newTextNextVisit: string[] = []
        treatment?.summeryTreatments?.forEach((item: any) => {
            const dataNV = item?.nextVisit
            newTextRemark.push(item.note)
            if (dataNV?.date) {
                // thai
                newTextNextVisit.push(`วันที่: ${dateToView(dataNV?.date)} ${moment(dataNV?.timeStart, 'HH:mm:ss').format('HH:mm')} ${dataNV?.prefix || ''} ${dataNV?.doctorFullname} บริการ: ${handleTreatment(dataNV?.treatment)}`)
            }

        })
        setTextRemark(newTextRemark)
        setTextNextVisit(newTextNextVisit)
    }, [treatment])

    useEffect(() => {
        if (textRemarkRef?.current) {
            setLineRemark((textRemarkRef?.current?.clientHeight / 24) || 1)
        }
    }, [textRemarkRef?.current?.clientHeight, textRemark])

    useEffect(() => {
        if (textNextVisitRef?.current) {
            setLineNextVisit((textNextVisitRef?.current?.clientHeight / 24) || 1)
        }
    }, [textNextVisitRef?.current?.clientHeight, textNextVisit])

    const onTableEmpty = useCallback(() => {
        const numberTotal = (treatment?.summeryTreatments?.length || 0) + (treatment?.summeryProducts?.length || 0)
        const numberTB = numberTotal >= 13 ? 0 : 13 - numberTotal
        return numberTB
    }, [treatment])

    return (
        <>
            <PDFContainer >
                <Box className={'pdf-body'} >
                    <Box className={'pdf-title'} >
                        <Box className={'pdf-branch-logo'} >
                            <img src={BranchInfo?.branchLogo || ''} />
                        </Box>
                        <Box
                            className={'pdf-title-text'}
                            sx={{ fontSize: '20px' }}
                        >
                            {t("PDF_INVOICE.TITLE")}
                        </Box>
                        <Box
                            className={'pdf-title-text'}
                            sx={{ fontSize: '18px' }}
                        >
                            {t("PDF_INVOICE.TITLE_EN")}
                        </Box>
                    </Box>
                    <Box className={'pdf-content'}>
                        <Box className={'pdf-content-date'} >
                            <span style={{ fontWeight: '500' }}>{t("PDF_INVOICE.PDF.DATE")}:</span>
                            <span style={{ width: '90px' }}>{dateToView(new Date(), false)}</span>
                            <PDFLineText sx={{ bottom: '0', }} />
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: '8px' }}>
                            <Box sx={{ width: '60%' }}>
                                <Box sx={{ fontWeight: '500', }}>
                                    <span>{t('PDF_INVOICE.PDF.PATIENT_NAME')}: </span><span> {`${treatment?.patientInfo?.firstname} ${treatment?.patientInfo?.lastname}`}</span>
                                </Box>
                                <Box sx={{ fontSize: '14px', }}>
                                    {t('PDF_INVOICE.PDF.SUB_PATIENT_NAME')}
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ fontWeight: '500', }}>
                                    <span>{t('PDF_INVOICE.PDF.PATIENT_ID')}: </span><span> {handlePatientCode()}</span>
                                </Box>
                                <Box sx={{ fontSize: '14px', }}>
                                    {t('PDF_INVOICE.PDF.SUB_PATIENT_ID')}
                                </Box>
                            </Box>
                        </Box>

                        <Box className={'pdf-table-container'}>
                            <table>
                                <tr className={'table-head'}>
                                    {headCells.map((item, index: number) => {
                                        return (
                                            <th
                                                key={index}
                                                style={{ ...item?.style }}
                                            >
                                                <Box>
                                                    {item.title}
                                                </Box>
                                                <Box>
                                                    {item.title_en}
                                                </Box>
                                            </th>
                                        )
                                    })}
                                </tr>
                                {treatment?.summeryTreatments && treatment?.summeryTreatments?.map((item: any, index: number) => {
                                    if (index <= 12) {
                                        return (
                                            <tr className={'table-body'} key={index}>
                                                <td style={{ textAlign: 'center', maxWidth: '70px' }}>{index + 1}</td>
                                                <td style={{ textAlign: 'center', maxWidth: '120px' }}>{item?.operativeCode || ''}</td>
                                                <td style={{ maxWidth: '600px' }}>{item?.operativeName}</td>
                                                <td style={{ textAlign: 'center', maxWidth: '100px' }} >{item?.qty || 0}</td>
                                                <td style={{ textAlign: 'right', maxWidth: '120px' }}>{numberFormat(item?.total || 0)}</td>
                                            </tr>
                                        )
                                    }
                                })}
                                {treatment?.summeryProducts && treatment?.summeryProducts?.map((item: any, index: number) => {
                                    if (((treatment?.summeryTreatments?.length || 0) + index) <= 12) {
                                        return (
                                            <tr className={'table-body'} key={index}>
                                                <td style={{ textAlign: 'center', maxWidth: '70px' }}>{index + 1 + (treatment?.summeryTreatments?.length || 0)}</td>
                                                <td style={{ textAlign: 'center', maxWidth: '120px' }}>{item?.itemCode || ''}</td>
                                                <td style={{ maxWidth: '600px' }}>{item?.itemName || ''}</td>
                                                <td style={{ textAlign: 'center', maxWidth: '100px' }} >{item?.qty || 0}</td>
                                                <td style={{ textAlign: 'right', maxWidth: '120px' }}>{numberFormat(item?.total || 0)}</td>
                                            </tr>
                                        )
                                    }
                                })}
                                {new Array(onTableEmpty())?.fill(undefined)?.map((item, index: number) => {
                                    return (
                                        <tr className={'table-body'} key={index}>
                                            {new Array(5).fill(undefined).map((info, num: number) => (
                                                <td key={num} >
                                                    <Box sx={{ height: '24px', }}>
                                                    </Box>
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                })}
                                <tr style={{ fontWeight: '500' }}>
                                    <td colSpan={3} >
                                    </td>
                                    <td style={{ textAlign: 'center', padding: '8px 8px ', border: '1px solid blue', }} >
                                        <Box sx={{ minHeight: '19px' }}>
                                            <Box>
                                                {t('PDF_INVOICE.PDF.TOTAL')}
                                            </Box>
                                            <Box>
                                                {t('PDF_INVOICE.PDF.TOTAL_EN')}
                                            </Box>
                                        </Box>
                                    </td>
                                    <td style={{ border: '1px solid blue', padding: '8px', textAlign: 'right', }}>
                                        <Box sx={{ minHeight: '19px' }}>
                                            {numberFormat(totalTreatment)}
                                        </Box>
                                    </td>
                                </tr>
                            </table>
                        </Box>

                        <Box className={'pdf-text-detail'}>
                            <Box>
                                <Box sx={{ fontWeight: '500', width: '150px' }}>
                                    {t('PDF_INVOICE.PDF.REMARK')}:
                                </Box>
                                <Box sx={{ fontSize: '14px', }}>
                                    {t('PDF_INVOICE.PDF.REMARK_EN')}
                                </Box>
                            </Box>
                            <Box className={'pdf-text-detail-value'}>
                                <Box className={'pdf-value-text pdf-text-remark text-break-word'} ref={textRemarkRef} >
                                    {textRemark.filter((item) => item !== '').join(', ')}

                                    {new Array(lineRemark).fill(undefined).map((item: any, index: any) =>
                                        <PDFLineText
                                            key={index}
                                            sx={{
                                                top: `${23 * (index + 1)}px`,
                                                width: '100% !important',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        <Box className={'pdf-text-detail'} sx={{ marginTop: "40px !important" }}>
                            <Box>
                                <Box sx={{ fontWeight: '500', width: '150px' }}>
                                    {t('PDF_INVOICE.PDF.NEXT_VISIT')}:
                                </Box>
                                <Box sx={{ fontSize: '14px', }}>
                                    {t('PDF_INVOICE.PDF.NEXT_VISIT_EN')}
                                </Box>
                            </Box>
                            <Box className={'pdf-text-detail-value'}>
                                <Box className={'pdf-value-text pdf-text-next-visit text-break-word'} ref={textNextVisitRef} >
                                    {textNextVisit.toString().replaceAll(',', ', ')}
                                    {new Array(lineNextVisit).fill(undefined).map((item: any, index: any) =>
                                        <PDFLineText
                                            key={index}
                                            sx={{
                                                top: `${23 * (index + 1)}px`,
                                                width: '100% !important',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ position: 'absolute', bottom: '100px', width: "calc(100% - 120px)" }}>
                        <Box className={'pdf-container-signature'}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Box>
                                    {treatment?.invoice?.doctorSignature && (
                                        <img src={`data:image/png;base64,${treatment?.invoice?.doctorSignature}`} className="w-100 h-100" alt="" />
                                    ) || signature?.doctorSignature && (
                                        <img src={`data:image/png;base64,${signature?.doctorSignature}`} style={{ width: '300px', height: '150px' }} />
                                    ) || (
                                            <Box sx={{ width: '300px', height: '150px' }} />
                                        )
                                    }
                                </Box>
                                <Box>
                                    <Box sx={{ fontWeight: '500' }}>{`${treatment?.firstname} ${treatment?.lastname}`}</Box>
                                    <Box sx={{ fontSize: 14 }}>{t('PDF_INVOICE.PDF.DOCTOR_SIGNATURE')}</Box>
                                    <Box sx={{ fontSize: 14 }}>{t('PDF_INVOICE.PDF.DOCTOR_SIGNATURE_EN')}</Box>
                                </Box>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <Box>
                                    {treatment?.invoice?.patientSignature && (
                                        <img src={`data:image/png;base64,${treatment?.invoice?.patientSignature}`} className="w-100 h-100" alt="" />
                                    ) || signature?.patientSignature && (
                                        <img src={`data:image/png;base64,${signature?.patientSignature}`} style={{ width: '300px', height: '150px' }} />
                                    ) || (
                                            <Box sx={{ width: '300px', height: '150px' }} />
                                        )
                                    }
                                </Box>
                                <Box>
                                    <Box sx={{ fontWeight: '500' }}>{`${treatment?.patientInfo?.firstname} ${treatment?.patientInfo?.lastname}`}</Box>
                                    <Box sx={{ fontSize: 14 }}>{t('PDF_INVOICE.PDF.PATIENT_SIGNATURE')}</Box>
                                    <Box sx={{ fontSize: 14 }}>{t('PDF_INVOICE.PDF.PATIENT_SIGNATURE_EN')}</Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ textAlign: 'center', marginTop: '100px' }}>
                            <Box className={'pdf-branch-name'} sx={{ fontWeight: "500" }}>
                                {BranchInfo?.branchName || ''}
                            </Box>
                            <Box className={'pdf-branch-address'}>
                                {BranchInfo?.receiptFullAddress || ''}
                            </Box>
                            <Box className={'pdf-branch-phone'}>
                                {BranchInfo?.branchPhone || ''}
                            </Box>
                        </Box>
                    </Box>


                </Box>
            </PDFContainer>
        </>
    )
}

export default PdfInvoice