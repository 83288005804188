import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { memberTypeLabel } from 'utils/app.utils'
import { MEMBERSHIP_RANKING } from 'constants/common'
import _ from 'lodash'

type RankType = {
  rank?: string
  disabled?: boolean
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize'
}

const Ranking = styled(Chip)(({ theme }) => ({
  height: 29,
  borderRadius: 4,
  span: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.white
  },
  [theme.breakpoints.down('sm')]: {
    height: 24,
    span: {
      fontSize: 14
    }
  }
}))

export default function LabelRanking(props: RankType) {
  return <Ranking label={props.rank ? _.get(_.find(MEMBERSHIP_RANKING, { key: props.rank }), 'text') || `${props.rank} Member` : '-'} size="small" style={{ background: props.disabled ? colors.disabledGray : memberTypeLabel(props.rank || ''), textTransform: props.textTransform || 'uppercase' }} />
}
