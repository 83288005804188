import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

/** ICON */
import IconBtnInfo from 'assets/button/btn-info.svg'

/** STYLE */
const CustomImg = styled('img')(({ theme }) => ({
  opacity: 0.7,
  '&:hover': { opacity: 1 }
}))

interface IButtonInfoProps {
  text: any
  placement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top'
  onClick?: (e: any) => void
}

export function ButtonInfoSimple(props: IButtonInfoProps) {
  return (
    <Tooltip
      title={<span style={{ fontSize: '14px' }}>{props.text || ''}</span>}
      placement={props?.placement || 'top-start'}>
      <CustomImg src={IconBtnInfo} alt="delete" onClick={props?.onClick} style={{ cursor: 'pointer' }} />
    </Tooltip>
  )
}
