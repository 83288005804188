import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Grid, MenuItem, Box } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { CardSalesProps, handleChangeFilterSelect, renderFilterSelect } from 'features/dashboard/dashboard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'

/** UTILS || SLICE */
import { numberFormat } from 'utils/app.utils'

/** API */
import { BranchInterface } from 'api/master/clinic.api'
import DashboardApi, { IFInventoriesPurchase, IFSumeryFilter } from 'api/dashboard.api'

/** STYLE */
import * as UseStyled from 'features/dashboard/useStyled'

export default function HightSaleProduct(props: CardSalesProps) {
  const { t } = useTranslation()

  const [branches, setBranches] = useState<BranchInterface[]>([])
  const [branch, setBranch] = useState<(string | number)[]>(['ALL'])
  const [rangeDate, setRangeDate] = useState([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
  const [products, setProducts]: any[] = useState([])

  const handleChangeSelectBranch = (e: any) => {
    setBranch(handleChangeFilterSelect(e))
  }

  useEffect(() => {
    setBranches(props.branches || [])
  }, [props.branches])

  useEffect(() => {
    setRangeDate(props.rangeDate || [])
  }, [props.rangeDate])

  useEffect(() => {
    setBranch(props.branch || [])
  }, [props.branch])

  const loadData = useCallback(async () => {
    const condition: IFSumeryFilter = { dateStart: rangeDate[0], dateEnd: rangeDate[1], branchId: branch[0] === 'ALL' ? `` : branch.toString() }
    const res = await DashboardApi.findTopBuyProduct(condition)
    const newProducts: any = []

    setProducts([])
    if (res.status === 200) {
      const summeris: IFInventoriesPurchase[] = res.data
      for (const x of summeris) {
        newProducts.push({
          name: x.productName,
          qty: x.qty,
          price: x.total
        },)
      }
    }

    setProducts(newProducts)
  }, [branch, rangeDate])

  useEffect(() => {
    loadData()
  }, [loadData])

  const headCells = [
    { id: 'text', disablePadding: false, label: t('DASHBOARD.SALE_PRODUCT_CHART.TABLE.NAME'), class: 'p-3' },
    { id: 'text', disablePadding: false, label: t('DASHBOARD.SALE_PRODUCT_CHART.TABLE.QTY'), width: '100px', class: 'p-3' },
    { id: 'price', disablePadding: false, label: t('DASHBOARD.SALE_PRODUCT_CHART.TABLE.SUM'), width: '150px', class: 'p-3' }
  ]

  const renderData = (objData: any, no: number) => {
    const { name, qty, price } = objData

    const objRenderData = {
      key: String(no),
      id: String(no),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'left', label: name || '-', class: 'p-3' },
        { option: 'TEXT', align: 'left', label: qty || '-', class: 'p-3' },
        { option: 'TEXT', align: 'right', label: numberFormat(price || 0), class: 'p-3' },
      ]
    }

    return <TableRowCommon {...objRenderData} />
  }

  return (
    <UseStyled.Card>
      <Box className='pt-3 px-3 px-xl-4' sx={{ borderBottom: `1px solid ${colors.lightGray}`, paddingBottom: '12px' }}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs={12} md={4} lg={12} xl={4} className={'w-100'}>
            <UseStyled.Title type='title' sx={{ flex: 'none' }}>{t('DASHBOARD.HIGHT_SALE_PRODUCT')}</UseStyled.Title>
          </Grid>
          <Grid item xs={6} md={4} lg={6} xl={4} className={'w-100 pr-sm-2'}>
            <FilterSelect
              multiple
              label={t('')}
              labelId="label-branch"
              selectId="select-branch"
              onchange={handleChangeSelectBranch}
              renderValue={() => `${t('DASHBOARD.FILTER.BRANCH')}: ${renderFilterSelect('branch', branches, branch) === 'ALL' ? t('STATUS.ALL') : renderFilterSelect('branch', branches, branch)}`}
              value={branch}
              options={[
                <MenuItem
                  key="ALL"
                  value="ALL"
                  sx={{
                    '&.MuiMenuItem-root.Mui-selected': {
                      pointerEvents: _.includes(branch, 'ALL') ? `none` : 'auto',
                      backgroundColor: _.includes(branch, 'ALL') ? `${colors.themeSecondColor06} !important` : 'transparent',
                      color: _.includes(branch, 'ALL') ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                    }
                  }}>
                  {t('STATUS.ALL')}
                </MenuItem>,
                _.map(props.branches, (val: BranchInterface, indexBranch: number) => {
                  return (
                    <MenuItem
                      key={indexBranch}
                      value={val.branchId}
                      sx={{
                        '&.MuiMenuItem-root.Mui-selected': {
                          backgroundColor: _.includes(branch, val.branchId) ? `${colors.themeSecondColor06} !important` : 'transparent',
                          color: _.includes(branch, val.branchId) ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                        }
                      }}>
                      {val.branchName}
                    </MenuItem>
                  )
                })
              ]}
              formControlClass={'not-max-width'}
              classesOption="style-select-doctors"
            />
          </Grid>
          <Grid item xs={6} md={4} lg={6} xl={4} className={'w-100'}>
            <InputSecondNewRangePicker
              inputHeight={32}
              value={rangeDate}
              onchange={(val) => {
                if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
              }}
              allowClear
              onClear={() => setRangeDate(emptyRangePicker)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className='p-3 p-xl-4'>
        <TableCustom
          page={1}
          pageLimit={0}
          sortType={'ASC'}
          sortBy={''}
          rowCount={0}
          onSort={() => { return }}
          setPageLimit={() => { return }}
          setPage={() => { return }}
          headCells={headCells}
          rowsData={
            _.map(products, (product: any, index: number) => {
              return renderData(product, index)
            })
          }
          hidePagination
          tableFixedWidth
        />
      </Box>
    </UseStyled.Card>
  )
}
