import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/71.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 25.78, height: 93.28, viewBox: "0 0 25.78 110" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })


        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 25.78, height: 93.28, viewBox: "0 0 25.78 110" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>71</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={width > 1440 ? 70 : 100} position='L' />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12347">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5590"
                                d="M26.4118 13.399C26.1935 14.8754 25.9688 16.2876 25.7575 17.6186C24.6336 21.9456 23.7718 26.3366 23.1769 30.7675C21.5505 42.36 21.2565 54.2014 19.6642 65.8022C18.5405 74.009 16.8921 82.0318 14.1281 89.7016C13.3584 91.8511 12.5481 93.9851 10.0902 93.2471C8.86388 92.3653 8.73494 91.049 8.64656 89.6853C7.43475 70.0061 6.50159 50.2862 4.06347 30.7604C3.24044 24.1824 2.24614 17.6276 1.01355 11.1057C-0.430077 3.45192 2.2873 0.139429 8.93893 0.123164C13.9983 0.115195 19.0554 0.115195 24.1101 0.123164V0.933457C18.8125 0.933457 13.522 0.941489 8.22426 0.92542C5.52003 0.91719 3.4509 2.28871 4.06366 5.64295C5.67053 14.3551 6.70578 23.3406 10.3079 31.3228C12.8959 37.0509 16.9266 36.9145 20.0118 31.4265C22.6331 26.7497 23.8855 21.5431 24.3484 16.0072C24.4711 14.5156 24.2537 12.767 25.8192 11.8356C26.1302 12.3064 26.3326 12.8404 26.4118 13.399Z"
                                transform={"translate(2 0)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />

                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5590"
                                    d="M26.4118 13.399C26.1935 14.8754 25.9688 16.2876 25.7575 17.6186C24.6336 21.9456 23.7718 26.3366 23.1769 30.7675C21.5505 42.36 21.2565 54.2014 19.6642 65.8022C18.5405 74.009 16.8921 82.0318 14.1281 89.7016C13.3584 91.8511 12.5481 93.9851 10.0902 93.2471C8.86388 92.3653 8.73494 91.049 8.64656 89.6853C7.43475 70.0061 6.50159 50.2862 4.06347 30.7604C3.24044 24.1824 2.24614 17.6276 1.01355 11.1057C-0.430077 3.45192 2.2873 0.139429 8.93893 0.123164C13.9983 0.115195 19.0554 0.115195 24.1101 0.123164V0.933457C18.8125 0.933457 13.522 0.941489 8.22426 0.92542C5.52003 0.91719 3.4509 2.28871 4.06366 5.64295C5.67053 14.3551 6.70578 23.3406 10.3079 31.3228C12.8959 37.0509 16.9266 36.9145 20.0118 31.4265C22.6331 26.7497 23.8855 21.5431 24.3484 16.0072C24.4711 14.5156 24.2537 12.767 25.8192 11.8356C26.1302 12.3064 26.3326 12.8404 26.4118 13.399Z"
                                    transform={"translate(2 0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12347">
                                <path
                                    data-name="Path 5591"
                                    d="M28.1538 11.1283C27.9155 13.1767 27.6639 15.1617 27.4121 17.0567C27.1938 18.717 26.969 20.3051 26.7579 21.803C25.6317 26.6827 24.7702 31.6197 24.1772 36.5924C23.695 37.6188 23.0254 38.5461 22.2029 39.3266C22.2029 39.3266 18.4581 43.5858 15.3937 43.089C12.3295 42.6017 8.10107 41.8349 5.0635 36.5832L5.05723 36.5742C5.05723 36.5742 -3.24987 5.97613 3.81819 2.62797C10.8792 -0.710585 25.7705 1.44595 25.7705 1.44595C25.7705 1.44595 29.2366 1.77086 28.1538 11.1283Z"
                                    transform={"translate(0 -1)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5592"
                                    d="M26.5344 12.3118C26.3216 14.1364 26.0978 15.9041 25.873 17.5916C25.6792 19.0705 25.4788 20.4845 25.291 21.8182C24.2878 26.1641 23.5208 30.5611 22.9934 34.99C22.5638 35.9041 21.9674 36.73 21.2349 37.4254C21.2349 37.4254 17.8998 41.2179 15.1711 40.7763C12.4414 40.3424 8.67604 39.6593 5.9718 34.9825L5.96554 34.9745C5.96554 34.9745 -1.43291 7.72441 4.86189 4.74309C11.1504 1.7698 24.4131 3.69021 24.4131 3.69021C24.4131 3.69021 27.4981 3.97866 26.5344 12.3118Z"
                                    transform={"translate(0 -1)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5593"
                                    d="M25.2295 14.9018C25.0428 16.5061 24.8458 18.0608 24.6485 19.546C24.4778 20.8462 24.302 22.0897 24.1368 23.2637C23.254 27.0855 22.5793 30.9524 22.1155 34.8473C21.7374 35.6509 21.213 36.377 20.569 36.9885C20.569 36.9885 17.6355 40.3245 15.2352 39.9355C12.8359 39.5538 9.52318 38.953 7.14463 34.8406L7.13933 34.8334C7.13933 34.8334 0.633488 10.8675 6.16933 8.24581C11.6999 5.63073 23.3636 7.3199 23.3636 7.3199C23.3636 7.3199 26.0776 7.57288 25.2295 14.9018Z"
                                    transform={"translate(0 -1)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />

                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6568"
                                        d="M4.98421 2.06543C4.97426 2.32701 5.09482 8.44177 4.87637 14.5637C4.77462 17.4649 2.00011 53.2996 2.15882 53.4965C2.14113 53.6519 5.29999 17.5484 5.48083 14.5654C5.86075 8.2919 6.18814 2.21143 6.21081 2.06709C5.80317 2.11232 5.39174 2.11176 4.98421 2.06543Z"
                                        transform="translate(11 40)"
                                        style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4107_155354" x1="14.4926" y1="19.271" x2="12.5999" y2="30.0744" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4107_155354" x1="14.4966" y1="27.4078" x2="14.4966" y2="23.4009" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 12372">
                                            <path d="M26.7986 15.0825C26.5836 16.5365 26.3623 17.9274 26.1542 19.2382C25.0473 23.4997 24.1986 27.8241 23.6127 32.1878C22.0109 43.6047 21.7214 55.2667 20.1532 66.6917C19.0466 74.7741 17.4232 82.6753 14.701 90.2289C13.943 92.3458 13.145 94.4475 10.7243 93.7207C9.51656 92.8522 9.38958 91.5559 9.30254 90.2129C8.10909 70.8319 7.19007 51.4108 4.78889 32.1809C3.97833 25.7026 2.99909 19.247 1.78519 12.824C0.363431 5.28616 3.03963 2.02386 9.59048 2.00784C14.5732 1.99999 19.5536 1.99999 24.5318 2.00784V2.80585C19.3145 2.80585 14.1041 2.81376 8.88664 2.79794C6.22338 2.78983 4.1856 4.14058 4.78908 7.44398C6.37159 16.0241 7.39116 24.8735 10.9387 32.7347C13.4875 38.376 17.4571 38.2417 20.4956 32.8368C23.0772 28.2309 24.3106 23.1032 24.7664 17.6512C24.8872 16.1822 24.6732 14.4601 26.215 13.5428C26.5212 14.0065 26.7206 14.5324 26.7986 15.0825Z" fill="#E3D1B4" />
                                            <path d="M27.527 10.8745C27.2923 12.8918 27.0445 14.8468 26.7965 16.713C26.5815 18.3483 26.3602 19.9122 26.1523 21.3875C25.0431 26.1932 24.1947 31.0554 23.6107 35.9528C23.1358 36.9636 22.4764 37.8768 21.6663 38.6456C21.6663 38.6456 17.9782 42.8402 14.9603 42.351C11.9425 41.871 7.77815 41.1159 4.78661 35.9437L4.78044 35.9348C4.78044 35.9348 -3.40079 5.80042 3.56017 2.503C10.5142 -0.784971 25.1799 1.33889 25.1799 1.33889C25.1799 1.33889 28.5935 1.65887 27.527 10.8745Z" fill="#EFEDE7" />
                                            <path d="M25.9293 12.0411C25.7197 13.838 25.4993 15.579 25.2779 17.2408C25.0871 18.6973 24.8896 20.0899 24.7047 21.4034C23.7167 25.6834 22.9613 30.0138 22.4419 34.3756C22.0188 35.2759 21.4315 36.0893 20.7101 36.7741C20.7101 36.7741 17.4256 40.5092 14.7382 40.0742C12.0498 39.6469 8.34151 38.9742 5.67825 34.3683L5.67208 34.3604C5.67208 34.3604 -1.61426 7.52318 4.58516 4.58703C10.7784 1.6588 23.8402 3.5501 23.8402 3.5501C23.8402 3.5501 26.8784 3.83418 25.9293 12.0411Z" fill="#F7F7F7" />
                                            <path d="M24.6491 14.5912C24.4652 16.1712 24.2712 17.7024 24.0769 19.1651C23.9088 20.4456 23.7356 21.6703 23.573 22.8265C22.7035 26.5904 22.039 30.3986 21.5823 34.2345C21.2099 35.0259 20.6934 35.7411 20.0592 36.3433C20.0592 36.3433 17.1701 39.6288 14.8062 39.2457C12.4432 38.8698 9.18074 38.278 6.83822 34.228L6.83301 34.2208C6.83301 34.2208 0.425739 10.6182 5.8777 8.03614C11.3245 5.46069 22.8114 7.12427 22.8114 7.12427C22.8114 7.12427 25.4843 7.37342 24.6491 14.5912Z" fill="white" />
                                            <path d="M18.759 16H10.241C9.00333 16 8 17.0033 8 18.241V26.759C8 27.9967 9.00333 29 10.241 29H18.759C19.9967 29 21 27.9967 21 26.759V18.241C21 17.0033 19.9967 16 18.759 16Z" fill="#C1C4C5" />
                                            {/* <path d="M18.7574 16.5605H10.2393C9.31109 16.5605 8.55859 17.313 8.55859 18.2413V26.7593C8.55859 27.6876 9.31109 28.4401 10.2393 28.4401H18.7574C19.6856 28.4401 20.4381 27.6876 20.4381 26.7593V18.2413C20.4381 17.313 19.6856 16.5605 18.7574 16.5605Z" stroke="#C1C4C5" /> */}
                                            <path d="M13.5126 28.4176C13.2885 28.8232 15.3906 28.6227 16.9671 28.6025C19.3134 28.5532 20.6524 29.0328 20.63 23.6163C20.6909 21.6943 20.6314 19.7704 20.4519 17.8558C19.8569 17.2776 20.6087 23.7227 19.086 26.5576C17.6495 28.6047 13.8969 27.9829 13.5126 28.4176Z" fill="#B0B1B1" />
                                            <path d="M16.4993 18.2559H12.0173C11.7079 18.2559 11.457 18.5067 11.457 18.8161V19.9366C11.457 20.246 11.7079 20.4969 12.0173 20.4969H16.4993C16.8087 20.4969 17.0595 20.246 17.0595 19.9366V18.8161C17.0595 18.5067 16.8087 18.2559 16.4993 18.2559Z" fill="#9E9E9E" />
                                            <path d="M16.7336 18.2559H12.2517C11.9422 18.2559 11.6914 18.5067 11.6914 18.8161V19.9366C11.6914 20.246 11.9422 20.4969 12.2517 20.4969H16.7336C17.0431 20.4969 17.2939 20.246 17.2939 19.9366V18.8161C17.2939 18.5067 17.0431 18.2559 16.7336 18.2559Z" fill="url(#paint0_linear_4107_155354)" />
                                            <path d="M16.7375 23.5215H12.2556C11.9461 23.5215 11.6953 23.7723 11.6953 24.0817V25.2022C11.6953 25.5116 11.9461 25.7625 12.2556 25.7625H16.7375C17.047 25.7625 17.2978 25.5116 17.2978 25.2022V24.0817C17.2978 23.7723 17.047 23.5215 16.7375 23.5215Z" fill="#CBCCCC" />
                                            <path d="M16.7375 23.2979H12.2556C11.9461 23.2979 11.6953 23.5487 11.6953 23.8581V24.9786C11.6953 25.288 11.9461 25.5388 12.2556 25.5388H16.7375C17.047 25.5388 17.2978 25.288 17.2978 24.9786V23.8581C17.2978 23.5487 17.047 23.2979 16.7375 23.2979Z" fill="url(#paint1_linear_4107_155354)" />
                                            <path d="M1.28125 21.0449C7.26943 21.5113 12.3321 23.1149 26.1679 21.1488" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path d="M1.28125 20.5156C6.84823 21.0258 13.5599 22.6104 26.1679 20.5317" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
