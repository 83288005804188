import { menuSeconds } from 'constants/menus'
import RetrospectiveOrder from 'features/retrospectiveOrder/retrospectiveOrder'

const routeRetrospectiveOrder = [
  {
    ...menuSeconds.RETROSPECTIVE_ORDER,
    key: menuSeconds.RETROSPECTIVE_ORDER.key,
    name: menuSeconds.RETROSPECTIVE_ORDER.name,
    textName: menuSeconds.RETROSPECTIVE_ORDER.textName,
    active: false,
    appBar: true,
    component: RetrospectiveOrder
  },
]

export default routeRetrospectiveOrder