import { Divider, Typography, Box, IconButton, Autocomplete, TextField, Grid } from '@mui/material'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import ProductsApi from 'api/warehouses/product.api'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { icons } from 'constants/images'

import InputTextField from 'component/Input/InputTextField'
import { ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import InputCheckbox from 'component/Input/InputCheckbox'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import _ from 'lodash'
import { clinicTheme } from 'api/api'
import { PRODUCT_GROUP } from 'api/warehouses/inventory.api'

export type BindProductProps = {
    group?: PRODUCT_GROUP
    itemTypeName?: string
    itemTypeId?: number
    itemName?: string
    itemId?: number
    qty?: number
    branchId?: number[] | "ALL"
}

type BoxSelectMedPdProps = {
    clearError: () => void
    index: number
    product: BindProductProps
    handleProduct: (data: BindProductProps, index: number) => void
    handleDelProduct: (index: number) => void
    productsError: boolean
    operativeProducts: BindProductProps[]
}

const BoxSelectMedPd = (props: BoxSelectMedPdProps) => {
    const { product, handleProduct, clearError, index, handleDelProduct, productsError, operativeProducts } = props
    const { t } = useTranslation()

    const [productTypes, setProductTypes] = useState([])
    const [loadItemType, setLoadItemType] = useState<boolean>(true)

    const [products, setProducts] = useState([])
    const [loadItemProduct, setLoadItemProduct] = useState<boolean>(true)
    const [branches, setBranches] = useState<BranchInterface[]>([])
    const [seletorBranch, setSelectorBranch] = useState<boolean>(false)

    const loadData = () => {
        // ClinicApi.findAllBranches().then(({ data }) => setBranches(data))
        ClinicApi.findAllActiveBranches().then(({ data }) => data && setBranches(data))
    }

    useEffect(() => {
        loadData()
    }, [])

    const handleChangeProducts = (val: BindProductProps, index: any, nameElement?: string) => {
        let temp = { ...product }
        if (nameElement === 'group') {
            temp = { ...val, branchId: temp?.branchId }
            handleProduct(temp, index)
            return
        }
        if (nameElement === 'itemType') {
            temp = { ...temp, ...val, branchId: temp?.branchId, itemId: undefined, itemName: undefined, qty: undefined }
            handleProduct(temp, index)
            return
        }

        temp = { ...temp, ...val }
        handleProduct(temp, index)
    }

    const handleChangeProductsBranchId = (val: BranchInterface | 'ALL') => {
        clearError()
        const temp: BindProductProps = { branchId: [] }
        if (val !== "ALL") {
            if (product.branchId === 'ALL') {
                const newBrnachId: number[] = []
                branches.forEach((item) => {
                    if (item.branchId !== val.branchId) {
                        newBrnachId.push(item.branchId)
                    }
                })
                temp['branchId'] = newBrnachId
            } else {
                if (product.branchId?.includes(val.branchId)) {
                    const newBrnachId = product.branchId.filter((item) => item !== val.branchId)
                    temp['branchId'] = newBrnachId
                } else {
                    const newBrnachId = product?.branchId?.length ? [...product?.branchId, val.branchId] : [val.branchId]
                    temp['branchId'] = newBrnachId
                }
            }
        } else {
            const newBrnachId = (product.branchId === 'ALL' || product.branchId?.length === branches?.length) ? [] : branches.map((item) => item.branchId)
            temp['branchId'] = newBrnachId
        }
        handleChangeProducts(temp, index)
    }

    const loadItemProductType = async (group: PRODUCT_GROUP, search?: string) => {
        setLoadItemType(true)
        let condition: any = { group: group, pageLimit: 10, status: 'ACTIVE' }
        if (search) condition = { ...condition, search: search || '' }
        const res = await ProductTypesApi.findAll(condition)
        if (res.status === 200) {
            const newData = res.data.map((value: any) => {
                return { itemTypeName: value.productTypeName, itemTypeId: value.productTypeId }
            })
            setProductTypes(newData)
        }
        setLoadItemType(false)
    }

    const loadItemProducts = async (group: PRODUCT_GROUP, search?: string) => {
        setLoadItemProduct(true)
        const condition: any = { group: group, status: 'ACTIVE', page: 1, pageLimit: 10, search: search || '' }

        const res = await ProductsApi.findAll(condition)
        if (res.status === 200) {
            const newData = res.data.map((value: any) => {
                return { itemName: value.productName, itemId: value.productId }
            })
            setProducts(newData)
        }
        setLoadItemProduct(false)
    }

    const onCheckBranchId = (option: BranchInterface) => {
        if (product?.branchId === "ALL") {
            return true
        }
        if (!product?.branchId?.length) {
            return false
        }
        const check = product?.branchId?.some((item) => item === option?.branchId)
        return check
    }

    const renderValueBranchId = () => {
        if (product?.branchId === "ALL") {
            return branches?.map((item) => {
                return [item.branchId]
            }) || []
        }
        return product?.branchId || []
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', padding: '0', gap: '16px' }}>
            <Box sx={{ width: 'fit-content', padding: '0', marginTop: '11px', height: '24px' }}>
                {index + 1}
            </Box>
            <Grid container xs={12} className="px-0 " rowSpacing={2} sx={{ width: 'calc(100% - 30px)' }}>
                <Grid item lg={10.5 / 4} md={12 / 4} sm={6} xs={12} className={'w-100'}>
                    <Box sx={{
                        padding: '0 16px 0 0',
                        '.Mui-multiple': {
                            '.MuiFormControl-root': {
                                '.MuiOutlinedInput-root': {
                                    marginTop: 0,
                                    padding: '10px 24px 0px 16px !important',
                                    flexWrap: 'nowrap'
                                },
                                '.MuiOutlinedInput-input': {
                                    height: 'auto',
                                    minWidth: 'unset',
                                    padding: '0 !important'
                                },
                            }
                        },
                    }}>
                        <AutocompleteSelect
                            labelId="branches"
                            getInputSearch={(e) => setSelectorBranch(e?.inputProps?.value !== "")}
                            key={`branches-${index}`}
                            inputLabel={t('OPERATIVE.BRANCH_PRICE.BRANCH')}
                            options={['ALL', ...branches]}
                            getOptionLabel={(option: any) => `${option?.branchId || ''}`}
                            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                            renderOption={(props, option) => {
                                if (option === 'ALL') {
                                    return (
                                        <ListFilterDoctors
                                            {...props}
                                            key={option}
                                            component="li"
                                            value={option}
                                            onClick={() => handleChangeProductsBranchId("ALL")}
                                        >
                                            <InputCheckbox
                                                label={t('ALL')}
                                                checked={product?.branchId?.length === branches?.length || product?.branchId === "ALL"}
                                                indeterminate={product?.branchId && product?.branchId?.length >= 1 && product?.branchId !== "ALL" && product?.branchId?.length !== branches?.length}
                                                className={'pe-none'}
                                            />
                                        </ListFilterDoctors>
                                    )
                                } else {
                                    return (
                                        <ListFilterDoctors
                                            {...props}
                                            key={option.dentistId}
                                            component="li"
                                            value={option.dentistId}
                                            onClick={() => handleChangeProductsBranchId(option)}
                                            title={option.branchName}
                                        >
                                            <InputCheckbox
                                                label={option.branchName}
                                                checked={onCheckBranchId(option)}
                                                className={'pe-none'}
                                            />
                                        </ListFilterDoctors>
                                    )
                                }
                            }}
                            renderTags={(tagValue, getTagProps) => {
                                return (
                                    <Box className='text-ellipsis ' sx={seletorBranch && { width: "0px !important" } || {}}>
                                        {((_.size(tagValue) === branches?.length) || product.branchId === 'ALL') && (
                                            <span>{t('ALL')}</span>
                                        ) || (
                                                <Box className='text-ellipsis '>
                                                    {
                                                        tagValue.map((option: any, index: number) => {
                                                            return (
                                                                <span key={index}>
                                                                    {_.find(branches, { branchId: option })?.branchName}
                                                                    {index !== _.findLastIndex(tagValue) && option && `,`}
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            )}
                                    </Box>
                                )
                            }}
                            filterOptions={(option) => 'ALL' + option?.branchName || ''}
                            value={renderValueBranchId()}
                            required
                            multiple
                            disableClearable
                            errMessage={productsError && (!product?.branchId?.length) ? t('OPERATIVE.BRANCH_PRICE.ERROR.BRANCH') : ''}
                            height={44}
                        />
                    </Box>
                </Grid>
                <Grid item lg={10.5 / 4} md={12 / 4} sm={6} xs={12} className={'w-100'}>
                    {/* PRODUCT_GROUP */}
                    <Box sx={{ padding: '0 16px 0 0' }}>
                        <AutocompleteSelect
                            required
                            onchange={(event, value) => {
                                const newValue: BindProductProps = { group: value?.group || undefined }
                                handleChangeProducts(newValue, index, 'group')
                                clearError()
                            }}
                            inputLabel={t('OPERATIVE.MED_PRODUCT_BIND.CATEGORY')}
                            labelId="categoryProducts"
                            value={product}
                            options={[{ group: PRODUCT_GROUP.MEDICINE }, { group: PRODUCT_GROUP.PRODUCT }, { group: PRODUCT_GROUP.MATERIAL }]}
                            getOptionLabel={(option: any) => option?.group && t(`OPERATIVE.MED_PRODUCT_BIND.${option?.group || ''}`) || ''}
                            renderOption={(props, option: any) => (
                                <Box
                                    key={option.group}
                                    component="li"
                                    {...props}
                                >
                                    {t(`OPERATIVE.MED_PRODUCT_BIND.${option.group}`)}
                                </Box>
                            )}
                            errMessage={productsError && !product?.group ? t('OPERATIVE.MED_PRODUCT_BIND.ERROR.CATEGORY') : ''}
                        />
                    </Box>
                </Grid>
                <Grid item lg={10.5 / 4} md={12 / 4} sm={6} xs={12} className={'w-100'}>
                    <Box sx={{ padding: '0 16px 0 0' }}>
                        <AutocompleteSelect
                            labelId="product-type"
                            inputLabel={t('OPERATIVE.MED_PRODUCT_BIND.TYPE')}
                            options={productTypes}
                            onchange={(e, value) => {
                                clearError()
                                const newValue: BindProductProps = { itemTypeId: value?.itemTypeId || undefined, itemTypeName: value?.itemTypeName || undefined }
                                handleChangeProducts(newValue, index, 'itemType')
                            }}
                            loading={loadItemType}
                            onOpen={(event, value) => product?.group && loadItemProductType(product.group)}
                            onClose={(event, value) => product?.group && loadItemProductType(product.group)}
                            onInputChange={(event: any, newValue: any) => product?.group && loadItemProductType(product.group, newValue)}
                            getOptionLabel={(option: any) => `${option?.productTypeName || option?.itemTypeName || ''}`}
                            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                            renderOption={(props, option: any) => (
                                <Box
                                    key={option?.itemTypeId}
                                    component="li"
                                    {...props}
                                >
                                    {`${option?.itemTypeName}`}
                                </Box>
                            )}
                            value={product}
                            required
                            disabled={!product?.group}
                            errMessage={productsError && !product?.itemTypeId ? t('OPERATIVE.MED_PRODUCT_BIND.ERROR.TYPE') : ''}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={12 / 4} lg={10.5 / 4} className={'w-100'}>
                    <Box sx={{ padding: '0 16px 0 0' }}>
                        <AutocompleteSelect
                            labelId="products"
                            inputLabel={t('OPERATIVE.MED_PRODUCT_BIND.LIST')}
                            options={products}
                            onchange={(e, value) => {
                                clearError()
                                const newValue: BindProductProps = { itemId: value?.itemId || undefined, itemName: value?.itemName || undefined, qty: undefined }
                                handleChangeProducts(newValue, index)
                            }}
                            loading={loadItemProduct}
                            onOpen={(event, value) => product?.group && loadItemProducts(product.group)}
                            onClose={(event, value) => product?.group && loadItemProducts(product.group)}
                            onInputChange={(event: any, newValue: any) => product?.group && loadItemProducts(product.group, newValue)}
                            getOptionLabel={(option: any) => `${option?.itemName || ''}`}
                            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                            renderOption={(props, option: any) => {
                                const checkDupItem = (product && operativeProducts.some((item) => item.itemId === option?.itemId))
                                return (
                                    <Box
                                        key={option?.itemId}
                                        component="li"
                                        {...props}
                                        sx={{
                                            pointerEvents: checkDupItem ? 'none' : 'auto',
                                            filter: checkDupItem ? 'grayscale(0.5)' : '',
                                            opacity: checkDupItem ? '0.5' : '1'
                                        }}
                                    >
                                        {`${option?.itemName}`}
                                    </Box>
                                )
                            }}
                            value={product}
                            required
                            disabled={!product?.itemTypeId}
                            errMessage={productsError && !product?.itemId ? t('OPERATIVE.MED_PRODUCT_BIND.ERROR.LIST') : ''}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1.5} container className={'w-100'}>
                    <Grid item xs={8}>
                        <Box sx={{ padding: '0 0px 0 0' }}>
                            <InputTextField
                                onchange={(event) => {
                                    const value = event.target.value
                                    if (event.target.value === '') {
                                        const newValue = { qty: undefined }
                                        handleChangeProducts(newValue, index)
                                        return
                                    }
                                    if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                        clearError()
                                        const newValue = { qty: value }
                                        handleChangeProducts(newValue, index)
                                    }
                                }}
                                label={t('OPERATIVE.MED_PRODUCT_BIND.COUNT')}
                                value={product?.qty || ''}
                                required
                                disabled={!product?.itemId}
                                helperText={productsError && !product?.qty ? t('OPERATIVE.MED_PRODUCT_BIND.ERROR.COUNT') : ''}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={4} className={`d-flex `}>
                        <Box sx={{ height: '43px' }}>
                            <IconButton onClick={() => handleDelProduct(index)}>
                                <img src={icons.btnTrash} alt="trash" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BoxSelectMedPd