import { Card } from 'react-bootstrap'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'

const StyledHeader = styled(Card.Header)((theme) => ({
  minHeight: '80px',
  backgroundColor: `${colors.white} !important`,
  border: `1px solid ${colors.borderHeaderCard} !important`,
  boxShadow: `0 0.188rem 0.375rem 0 ${colors.black05} !important`,
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.25rem',
  fontWeight: 500
}))

type CardProps = {
  text?: string
}
export default function HeaderCard(props: CardProps) {
  return <StyledHeader className={`sticky-top px-3 py-0`}>{props.text}</StyledHeader>
}
