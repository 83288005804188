import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface DentistCostsInterface {
  branchId: number
  prefix: string
  documentNo: string
  documentDate: string
  documentStatus: string
  monthlyTemplate: string
  monthly: string
  total: any
  remark: any
  status?: 'ACTIVE' | 'ISACTIVE'
  dentistCostItems: DentistCostItemsInterface[]
}

export interface DentistCostItemsInterface {
  dentistCostItemId: number
  dentistCostId: number
  itemName: string
  userId: number
  paidDate: any
  paymentChannelId: any
  cost: number
}

export default class DentistCostsApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('dentist-costs', { params: { ...props } })
  }
  static create(payload: any) {
    return this.api.post('dentist-costs', payload).then((res) => res)
  }
  static findById(id: number): Promise<DentistCostsInterface> {
    return this.api.get(`dentist-costs/${id}`)
  }
  static update(id: number, payload: any) {
    return this.api.patch(`dentist-costs/${id}`, payload)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`dentist-costs/${id}/status`, { id, status })
  }
  static updateDocumentStatus(id: number, payload: any): Promise<any> {
    return this.api.patch(`dentist-costs/${id}/document-status`, payload)
  }
  static remove(id: number) {
    return this.api.delete(`dentist-costs/${id}`)
  }
  static monthly(): Promise<any> {
    return this.api.get(`dentist-costs/monthy`)
  }
  static templates(props: any): Promise<any> {
    return this.api.get(`dentist-costs/templates`, { params: props })
  }
}
