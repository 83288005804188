import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface TreatmentPlanTypeInterface {
  treatmentPlanTypeName: string
  treatmentPlanTypeNameEn?: string
  status: string
}

export default class TreatmentPlanTypeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('treatment-plan-types', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('treatment-plan-types', playload)
  }

  static findById(id: number): Promise<TreatmentPlanTypeInterface> {
    return this.api.get(`treatment-plan-types/${id}`)
  }

  static update(id: number, body: TreatmentPlanTypeInterface) {
    return this.api.patch(`treatment-plan-types/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`treatment-plan-types/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`treatment-plan-types/${id}`)
  }
}
