import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function XRayPlus(props: IconProps) {
    return (
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.7867 25.3362H1.61426V2.24124L2.57114 1.23926H23.1747L23.9913 2.22153V21.2041L19.7867 25.3362Z" fill="white" stroke={props.color || colors.themeSecondColor} />
            <path d="M22.9388 0.5C23.4933 0.500443 24.0185 0.706002 24.4005 1.0616C24.7815 1.41611 24.9884 1.8889 24.989 2.37384L24.989 21.4597C24.989 21.4598 24.989 21.46 24.989 21.4601C24.9887 21.5259 24.961 21.5966 24.8994 21.6548C24.8991 21.655 24.8989 21.6553 24.8987 21.6555L19.802 26.4006C19.8018 26.4008 19.8015 26.4011 19.8013 26.4013C19.7369 26.4604 19.6434 26.4991 19.5393 26.5H2.54955C1.99482 26.4994 1.46959 26.2935 1.08761 25.9376L0.750783 26.2991L1.08761 25.9376C0.706883 25.5829 0.500263 25.1099 0.5 24.6249C0.5 24.6249 0.5 24.6248 0.5 24.6247L0.5 2.37355C0.500693 1.88884 0.707501 1.41628 1.08817 1.06186C1.47005 0.706312 1.99495 0.50067 2.54929 0.5H22.9388ZM19.891 24.3V25.4487L20.7317 24.6659L22.9317 22.6177L23.8618 21.7517H22.591H20.391H19.891V22.2517V24.3ZM23.791 21.169H24.2911L24.291 20.6689L24.289 2.37315V2.37258C24.2886 2.01839 24.1369 1.68672 23.8805 1.44794C23.6252 1.21028 23.2863 1.08221 22.9395 1.08183H22.939L2.549 1.08183L2.54845 1.08183C2.20174 1.08221 1.8628 1.21028 1.60754 1.44794C1.35107 1.68672 1.19945 2.0184 1.199 2.37258V2.37321L1.199 24.6259L1.199 24.6265C1.19945 24.9807 1.35108 25.3123 1.60754 25.5511C1.86281 25.7888 2.20175 25.9169 2.54845 25.9172H2.549H18.691H19.191V25.4172V21.4603C19.191 21.4272 19.198 21.3935 19.2125 21.3607L18.7557 21.1575L19.2125 21.3607C19.2272 21.3279 19.2497 21.2956 19.2807 21.2667C19.3117 21.2378 19.3505 21.2131 19.3956 21.1957C19.4408 21.1783 19.4903 21.169 19.541 21.169H23.791Z" fill={props.color || colors.themeSecondColor} stroke={props.color || colors.themeSecondColor} />
            <path d="M4.56683 22.397H8.22083C8.34158 22.397 8.45081 22.442 8.52606 22.512C8.60009 22.5809 8.63432 22.6665 8.63432 22.7475C8.63432 22.8284 8.60011 22.914 8.52606 22.9829C8.45081 23.053 8.34158 23.098 8.22083 23.098H4.56683C4.44607 23.098 4.33684 23.053 4.26159 22.9829C4.18753 22.914 4.15332 22.8284 4.15332 22.7475C4.15332 22.6665 4.18755 22.581 4.26159 22.512C4.33684 22.442 4.44607 22.397 4.56683 22.397Z" fill={props.color || colors.themeSecondColor} stroke={props.color || colors.themeSecondColor} />
            <path d="M10.9902 22.397H12.8172C12.9379 22.397 13.0471 22.442 13.1224 22.512C13.1964 22.581 13.2307 22.6665 13.2307 22.7475C13.2307 22.8284 13.1965 22.914 13.1224 22.9829C13.0471 23.053 12.9379 23.098 12.8172 23.098H10.9902C10.8694 23.098 10.7602 23.053 10.6849 22.9829C10.6109 22.914 10.5767 22.8284 10.5767 22.7475C10.5767 22.6665 10.6109 22.581 10.6849 22.512C10.7602 22.442 10.8694 22.397 10.9902 22.397Z" fill={props.color || colors.themeSecondColor} stroke={props.color || colors.themeSecondColor} />
            <path d="M12.2549 4.02342C12.1011 4.08934 11.9913 4.19606 11.9191 4.34986L11.8438 4.50993V7.77425C11.8438 10.9287 11.8469 11.0417 11.9065 11.1641C11.9819 11.3211 12.0195 11.365 12.1576 11.4623C12.2518 11.5282 12.302 11.5408 12.5029 11.5408C12.7038 11.5408 12.754 11.5282 12.8482 11.4623C12.9863 11.365 13.0239 11.3211 13.0993 11.1641C13.1589 11.0417 13.162 10.9287 13.162 7.77425V4.50993L13.0867 4.34672C12.9768 4.11759 12.8136 4.01087 12.5374 4.00145C12.4244 3.99518 12.2957 4.00773 12.2549 4.02342Z" fill={props.color || colors.themeSecondColor} />
            <path d="M9.6467 4.77988C7.9706 4.91171 6.76846 5.28522 6.30392 5.81881C6.06851 6.08875 5.95238 6.50934 6.01829 6.86088C6.04654 7.00527 6.20348 7.18732 6.36669 7.25951C6.53619 7.33798 6.83437 7.33798 6.97875 7.25951C7.13255 7.18104 7.26124 6.98643 7.28635 6.79811C7.30832 6.64431 7.31146 6.64431 7.51234 6.54387C7.98316 6.30846 9.42385 6.06364 10.331 6.06364H10.5883V5.4045V4.74536L10.2839 4.74849C10.1144 4.75163 9.82875 4.76733 9.6467 4.77988Z" fill={props.color || colors.themeSecondColor} />
            <path d="M14.4175 5.39508V6.05422L14.8663 6.07305C15.8017 6.117 16.7653 6.27393 17.3208 6.47795C17.641 6.59409 17.6975 6.64117 17.7226 6.8138C17.7446 6.9833 17.8795 7.18418 18.0271 7.25951C18.1714 7.33798 18.4696 7.33798 18.6391 7.25951C18.8023 7.18732 18.9593 7.00527 18.9875 6.86088C18.9969 6.80125 19.0001 6.64431 18.9938 6.51876C18.9624 6.02283 18.6517 5.64618 18.0302 5.35114C17.3648 5.03726 16.1721 4.81127 14.8883 4.75477L14.4175 4.73594V5.39508Z" fill={props.color || colors.themeSecondColor} />
            <path d="M9.85032 7.30984C9.04994 7.37262 8.61051 7.43539 8.03925 7.5578C6.72411 7.84029 6.05869 8.33622 6.01161 9.06755C5.99278 9.37829 6.02103 9.49756 6.15286 9.64508C6.34118 9.85538 6.52637 9.91502 6.7963 9.85852C7.05996 9.80202 7.29223 9.54464 7.29223 9.3061C7.29223 9.24332 7.32048 9.19938 7.39581 9.14916C7.7599 8.90747 8.98716 8.66893 10.1422 8.61557L10.5879 8.59674V7.94073V7.28787L10.2835 7.29415C10.114 7.29729 9.91938 7.30356 9.85032 7.30984Z" fill={props.color || colors.themeSecondColor} />
            <path d="M14.4175 7.93762V8.59676L14.8663 8.61559C16.0747 8.67209 17.3397 8.92633 17.6598 9.18057C17.6881 9.20568 17.7132 9.26218 17.7132 9.30926C17.7132 9.54781 17.9486 9.80519 18.2217 9.85855C18.5042 9.91504 18.6893 9.84285 18.8871 9.59803C18.9812 9.48189 18.9844 9.46934 18.9844 9.16174C18.9844 8.87611 18.975 8.82275 18.8996 8.66895C18.7364 8.33938 18.4759 8.10711 18.0302 7.89368C17.3648 7.5798 16.1721 7.35381 14.8883 7.29731L14.4175 7.27848V7.93762Z" fill={props.color || colors.themeSecondColor} />
            <path d="M9.89793 9.8522C7.9362 9.9903 6.69325 10.3764 6.24441 10.9884C6.08433 11.2081 6.00586 11.4436 6.00586 11.7135C6.00586 11.9583 6.0498 12.0933 6.17535 12.222C6.48609 12.5453 7.02596 12.4605 7.23312 12.0556C7.26451 11.9897 7.29275 11.9018 7.29275 11.861C7.29275 11.7668 7.36181 11.7041 7.57524 11.6068C8.05234 11.3902 9.52756 11.1485 10.3782 11.1485H10.6293L10.6104 10.6526C10.5979 10.3826 10.5885 10.0845 10.5885 9.99344V9.83022L10.3154 9.8365C10.1616 9.83964 9.9764 9.84592 9.89793 9.8522Z" fill={props.color || colors.themeSecondColor} />
            <path d="M14.4178 10.2476C14.4178 10.4799 14.4083 10.7749 14.3989 10.9068L14.377 11.1485H14.6281C14.7662 11.1485 15.102 11.1704 15.3782 11.1955C16.3544 11.2897 17.0857 11.4404 17.4875 11.6381C17.6852 11.7354 17.7135 11.7605 17.7135 11.8327C17.7135 11.8798 17.7417 11.9739 17.7731 12.043C17.9897 12.5201 18.7085 12.5452 18.9344 12.0838C19.0129 11.9175 19.0223 11.5816 18.9564 11.3525C18.7712 10.7436 18.0587 10.3167 16.8032 10.0687C16.1661 9.94003 15.1522 9.83017 14.6155 9.83017H14.4178V10.2476Z" fill={props.color || colors.themeSecondColor} />
            <path d="M11.9223 12.6521C11.4389 12.8686 11.4138 13.556 11.8752 13.8166C12.0102 13.8919 12.0416 13.895 12.503 13.895C12.9644 13.895 12.9957 13.8919 13.1307 13.8166C13.5921 13.556 13.567 12.8686 13.0836 12.6521C12.9016 12.5673 12.1043 12.5673 11.9223 12.6521Z" fill={props.color || colors.themeSecondColor} />
            <path d="M12.0573 14.8808C11.7497 14.9467 11.5488 15.2009 11.5488 15.5273C11.552 15.8098 11.6869 16.0139 11.9443 16.1206C12.1107 16.1896 12.8954 16.1896 13.0617 16.1206C13.316 16.0139 13.4541 15.8098 13.4572 15.5273C13.4603 15.2794 13.3599 15.0942 13.1527 14.9655C13.0084 14.8776 12.9864 14.8713 12.5878 14.8651C12.3586 14.8588 12.1201 14.8682 12.0573 14.8808Z" fill={props.color || colors.themeSecondColor} />
            <path d="M8.76452 15.8065C8.29998 15.9038 7.92019 16.1141 7.61259 16.4374C6.95659 17.1279 6.97228 18.0852 7.65339 18.7601C8.06457 19.1744 8.58874 19.3753 9.23847 19.3753C9.68104 19.3753 9.97608 19.3062 10.3465 19.1148C10.6446 18.961 11.037 18.5937 11.1343 18.374L11.1939 18.2422H12.5028H13.8117L13.8713 18.374C13.9027 18.4493 14.0376 18.6157 14.1663 18.7444C14.4394 19.0206 14.7753 19.2121 15.1613 19.3125C15.4972 19.4004 16.037 19.4004 16.3729 19.3125C17.2486 19.0834 17.8701 18.3709 17.8701 17.5925C17.8701 17.1185 17.7194 16.7732 17.3522 16.4029C17.076 16.1235 16.7527 15.9383 16.3635 15.8348C16.0873 15.7594 15.4532 15.7594 15.1707 15.8348C14.656 15.9697 14.1444 16.3338 13.8964 16.7387L13.7865 16.9239H12.5059H11.2253L11.0966 16.723C10.8267 16.3087 10.3465 15.9697 9.85053 15.841C9.58373 15.7688 9.02189 15.7531 8.76452 15.8065Z" fill={props.color || colors.themeSecondColor} />
        </svg>
    )
}
