import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { loginFailed, loginSuccess } from 'context/login-context'
import { Grid } from '@mui/material'
import Cookies from 'universal-cookie'
import { removeBranchStorage, KEY_STORAGE } from 'constants/common'
import { Config } from 'config'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { cliniterWatermark, templateImage, watermark } from 'constants/theme'

/** API */
import { routeName } from 'routes/routes-name'
import LoginApi from 'api/login-api'

/** COMPONENT */
import InputPasswordField from 'component/Input/InputPasswordField'
import InputTextField from 'component/Input/InputTextField'
import InputCheckbox from 'component/Input/InputCheckbox'
import ForgetPassword from 'features/auth/forget-password'
import ButtonCustom from 'component/Button/ButtonCustom'

/** STYLE */
import useStyles, { ButtonForgetPassword, CardFormLogin, CardLogin, CliniterWatermark, License, Logo, SectionWatermark, Watermark } from 'features/auth/style'
import { getBranch, getServicePoint, getToken, getPermissionShortcuts, getPermissionMenus } from 'utils/app.utils'

const initStateErrorMessage = {
  USERNAME: '',
  PASSWORD: '',
  INVALID_USERNAME_PASSWORD: ``,
  INVALID_USERNAME: ``,
  INVALID_PASSWORD: ``,
  INVALID_EMAIL: ``
}

export default function Login() {
  const { t } = useTranslation()
  const cookies = new Cookies()

  const classes = useStyles()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [loading, setLoading] = useState(false)
  const [forgetPassword, setForgetPassword] = useState(false)
  const [isSucceeded, setSucceeded] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)

  const loadSignedIn = useCallback(async () => {
    const data = await LoginApi.rememberMe(cookies.get('TOKEN'))
    if (data) {
      localStorage.setItem(KEY_STORAGE.TOKEN, cookies.get('TOKEN'))
      localStorage.setItem('BRANCH_ID', data.branchId)
      localStorage.setItem('SERVICE_POINT_ID', data.servicePointId)
      window.location.href = '/'
    }
  }, [])

  useEffect(() => {
    if (cookies.get('REMEMBER_ME') && cookies.get('REMEMBER_ME') !== '') {
      setRememberMe(localStorage.rememberMe === 'true' ? true : false)
      loadSignedIn()
    }
  }, [])

  useEffect(() => {
    const currPath = window.location.href
    const token = getToken()
    const branchId = getBranch()
    const servicePointId = getServicePoint()
    const permissionMenus = getPermissionMenus()
    const permissionShortcuts = getPermissionShortcuts()

    if (token && (branchId === null || servicePointId === null || !permissionMenus || !permissionShortcuts) && !currPath.includes('pick')) {
      removeBranchStorage()
      window.location.href = routeName.pick
    }
  }, [])

  const handleChangeRememberMe = (event: any) => {
    if (event.target.checked) cookies.set('REMEMBER_ME', event.target.checked, { path: '/' })
    else cookies.remove('REMEMBER_ME')

    setRememberMe(event.target.checked)
  }

  const submit = () => {
    if (!username) return setErrorMessage({ ...errorMessage, ...{ USERNAME: t('LOGIN.MESSAGE.USERNAME') } })
    if (!password) return setErrorMessage({ ...errorMessage, ...{ PASSWORD: t('LOGIN.MESSAGE.PASSWORD') } })
    setLoading(true)
    LoginApi.login(username, password)
      .then((token) => {
        loginSuccess(token, rememberMe)
        if (rememberMe) cookies.set('TOKEN', token, { path: '/' })
        window.location.href = routeName.pick
      })
      .catch((e) => {
        const err = e.response.data
        loginFailed()
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`LOGIN.MESSAGE.${err.message}`) } })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const onClickForgetPassword = () => {
    setForgetPassword((forgetPasswordStatus) => !forgetPasswordStatus)
    setSucceeded(false)
    setEmail('')
    clearErrorMessage()
  }

  const submitForgetPassword = () => {
    if (!email) return setErrorMessage({ ...errorMessage, ...{ INVALID_EMAIL: t(`LOGIN.MESSAGE.INVALID_EMAIL`) } })
    setLoading(true)

    LoginApi.forgetPassword(email)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          setSucceeded(true)
          setEmail('')
        }
        else setErrorMessage({ ...errorMessage, ...{ INVALID_EMAIL: t(`LOGIN.MESSAGE.INVALID_EMAIL`) } })
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({
          ...errorMessage,
          ...{
            [err.message]: t('LOGIN.MESSAGE.INVALID_EMAIL_ACCOUNT')
          }
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <CardLogin>
      <Grid className={'h-100'}>
        <Grid item className={classes.login}>
          <CardFormLogin xs={9} xl={3} md={5} sm={8}>
            <Grid container>
              <Grid item xs={12} sm={12} xl={12} md={12} className="w-100">
                <Logo>
                  <div className='logo-wrap'>
                    <img src={Config.REACT_APP_LOGO || templateImage.logoHorizontal} alt="Logo" />
                  </div>
                </Logo>
              </Grid>
              <Grid item xs={12} sm={12} xl={12} md={12} className="w-100 mt-3 mx-3 mb-0 m-md-0">
                {!forgetPassword && (
                  <Card className={`shadow ${classes.card}`}>
                    <Card.Body>
                      <Card.Title className={`${classes.loginText} login-text`}>{t(`LOGIN.TITLE`)}</Card.Title>
                      <Card.Body>
                        <Grid container spacing={2} className=" justify-content-center align-content-center">
                          <Grid item xs={12} sm={12} xl={12} md={12} className="w-100">
                            <InputTextField
                              onchange={(event) => {
                                setUsername(event.target.value)
                                clearErrorMessage()
                              }}
                              onkeyup={(event) => event.key === 'Enter' && submit()}
                              label={t(`LOGIN.INPUT.USERNAME`)}
                              helperText={errorMessage.USERNAME || errorMessage.INVALID_USERNAME}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} xl={12} md={12} className="w-100">
                            <InputPasswordField
                              onchange={(event) => {
                                setPassword(event.target.value)
                                clearErrorMessage()
                              }}
                              onkeyup={(event) => event.key === 'Enter' && submit()}
                              label={t(`LOGIN.INPUT.PASSWORD`)}
                              helperText={errorMessage.PASSWORD || errorMessage.INVALID_PASSWORD}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} className="pb-2 pt-2 justify-content-between">
                          <Grid item xl={8} md={7} className="text-sm-left text-md-left text-nowrap">
                            <InputCheckbox id="rememberMe" checked={rememberMe} onChange={handleChangeRememberMe} className={classes.inputCheckBox} label={t(`LOGIN.INPUT.REMEMBER_ME`)} />
                          </Grid>
                          <Grid item xl={4} md={5} className="text-sm-right text-md-right text-nowrap">
                            <ButtonForgetPassword className="ml-auto justify-content-end" href="#" onClick={() => onClickForgetPassword()} style={{ textDecoration: 'underline' }}>
                              {t(`LOGIN.INPUT.FORGET_PASSWORD`)}
                            </ButtonForgetPassword>
                          </Grid>
                        </Grid>

                        <Row>
                          <Col xl={8} md sm xs={12} className="text-sm-left text-md-left text-nowrap"></Col>
                          <Col xl={4} md sm xs={12} className="text-sm-right text-md-right text-nowrap"></Col>
                        </Row>
                        <Grid item className="pb-3">
                          <ButtonCustom
                            type="submit"
                            onClick={submit}
                            textButton={t('LOGIN.BUTTON.SUBMIT')}
                            disabled={loading}
                            btnStyle={{ fontSize: '16px', marginTop: '15px', width: '100%' }} />
                        </Grid>
                      </Card.Body>
                    </Card.Body>
                  </Card>
                )}
                {forgetPassword && (
                  <ForgetPassword
                    onClick={onClickForgetPassword}
                    onChange={(event) => {
                      setEmail(event.target.value)
                      clearErrorMessage()
                    }}
                    submit={submitForgetPassword}
                    helperText={errorMessage.INVALID_EMAIL}
                    isSucceeded={isSucceeded}
                    loading={loading}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} xl={12} md={12} className="w-100">
                <License className={`${classes.license}`}>Copyright © 2020. Cliniter | All rights reserved.</License>
              </Grid>
            </Grid>
          </CardFormLogin>
        </Grid>
      </Grid>
      <SectionWatermark>
        <CliniterWatermark src={cliniterWatermark} alt="waterMark" />
        <Watermark src={watermark} alt="waterMark" />
      </SectionWatermark>
    </CardLogin>
  )
}
