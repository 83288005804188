import IconProps from "assets/icon/payment-method/iconProps"

export default function Cash(props: IconProps) {
    return (
        <svg data-name="Group 8595" xmlns="https://www.w3.org/2000/svgg" width="62" height="40" viewBox="0 0 73 40">
            <g data-name="Rectangle 3856" style={{ fill: '#fff', stroke: props.active || '#9e9e9e', strokeWidth: '2px' }}>
                <rect width="73" height="40" rx="4" style={{ stroke: 'none' }} />
                <rect x="1" y="1" width="71" height="38" rx="3" style={{ fill: 'none' }} />
            </g>
            <path data-name="Icon material-attach-money" d="M16.787 15.033c-3.027-.787-4-1.6-4-2.867 0-1.453 1.347-2.467 3.6-2.467 2.373 0 3.253 1.133 3.333 2.8h2.947a5.315 5.315 0 0 0-4.28-5.08V4.5h-4v2.88C11.8 7.94 9.72 9.62 9.72 12.193c0 3.08 2.547 4.613 6.267 5.507 3.333.8 4 1.973 4 3.213 0 .92-.653 2.387-3.6 2.387-2.747 0-3.827-1.227-3.973-2.8H9.48c.16 2.92 2.347 4.56 4.907 5.107V28.5h4v-2.867c2.6-.493 4.667-2 4.667-4.733-.001-3.787-3.241-5.08-6.267-5.867z" transform="translate(20.52 3.072)" style={{ fill: props.active || '#9e9e9e' }} />
        </svg>
    )
}
