import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography, Divider, IconButton, MenuItem } from '@mui/material';
import moment from 'moment'
import _ from 'lodash'
import AddBoxIcon from '@mui/icons-material/AddBox'


/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import ClinicApi from 'api/master/clinic.api'
import MedicinesApi from 'api/warehouses/medicine.api'
import ProductInstructionsApi from 'api/setting/default-products/product-instruction.api'
import ProductUnitsApi from 'api/setting/default-products/product-unit.api'
import ProductUsesApi from 'api/setting/default-products/product-use.api'

/** CONSTANT */
import { colors, templateImage } from 'constants/theme'
import { icons, onImgError } from 'constants/images';
import { numberFormat, toBuddhistYear, getClinicInfo } from 'utils/app.utils';
import { treatment, setTreatmentByKey, TreatmentStateInterface, ProductStickerType, StickerType, newStickerProduct, setNewStickerProduct } from 'app/slice/treatment.slice'

/** COMPONENT */
import { ImageType } from 'features/treatments/MainMenu/OPD/Med'
import Loading from 'component/Loading'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import FunctionPrint from 'component/Print/print'
import PdfMedSticker from 'features/pdf/pdf-med-sticker'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { DoctorsType } from 'features/treatments/treatmentsIndex';
import { doctorHasOverDue } from 'features/treatments/SummaryTreatment/SummaryTreatment';

/** STYLED */
import * as UseStyled from 'component/Treatments/style/popupManageProdStyled'
import TreatmentProductApi from 'api/dentists/treatmentProduct.api'
import ProductSymptomApi from 'api/setting/default-products/product-symptom';
import { IconAddBox } from 'features/treatments/MainMenu/OPD/style/UseStyle';
import { showModal } from 'app/slice/modal.slice';
import { PopupManageProductAddForm } from './PopupManageProductAddForm';
import { notiError, notiSuccess } from 'component/notifications/notifications';
import BasicSelect from 'component/Select/BasicSelect';
import { CURRENCY_UNIT } from './PopupManageTreatmentCourse';

interface PopupManageProductProps {
    page: 'Treatment' | 'Payment' | 'RetrospectiveOrder'
    group: 'MEDICINE' | 'PRODUCT' | 'MATERIAL'
    type: 'thumbnail' | 'summaryTreatment'
    patientName: string
    patientPhone?: string
    item: ProductStickerType
    index?: number
    patientCaseId: number
    hasChangeProductPrice?: 'PERMISS' | 'UNPERMISS'
    hasChangeProductDiscountAdditional?: 'PERMISS' | 'UNPERMISS'
    onSubmit?: () => void
    onCancel?: () => void
}

export const initialProductState: ProductStickerType = {
    sticker: ``,
    tempOrderProductId: 0,
    doctorId: 0,
    qty: 0,
    price: 0,
    discount: 0,
    discountUnit: "BAHT" || "PERCENT",
    additional: 0,
    additionalUnit: "BAHT" || "PERCENT",
    total: 0,
    itemId: 0
}

interface ProductObjType {
    id: number
    name: string
    nameEn?: string
}

const initialErrorMessageState = {
    EMPTY_QTY_ORDER: "",
    EMPTY_PRICE: "",
    EMPTY_DOCTOR: ""
}

export default function PopupManageProduct(props: PopupManageProductProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const treatments: TreatmentStateInterface = useSelector(treatment)
    const branch = getClinicInfo();

    const componentRef = useRef<HTMLDivElement>(null)
    const newStickerProducts: ProductStickerType[] = useSelector(newStickerProduct)
    const units: CURRENCY_UNIT[] = [CURRENCY_UNIT.PERCENT, CURRENCY_UNIT.BAHT]

    // data
    const [product, setProduct] = useState<ProductStickerType>(initialProductState);
    const [stickerProduct, setStickerProduct] = useState<StickerType>({});
    const [errorMessage, setErrorMessage] = useState(initialErrorMessageState);
    const [isShowAddProductChoice, setIsShowAddProductChoice] = useState<boolean>(false)
    const [typeAdd, setTypeAdd] = useState<'PRODUCT_SYMPTOM' | 'PRODUCT_USES' | 'PRODUCT_UNITS' | 'PRODUCT_INSTRUCTION'>('PRODUCT_INSTRUCTION')
    const [items, setItems] = useState<any>()
    const [medicineInformationId, setMedicineInformationId] = useState<any>()

    // default data
    const [doctors, setDoctors] = useState<DoctorsType[]>([]);
    const [productInstructions, setProductInstructions] = useState<ProductObjType[]>([]);
    const [productUnits, setProductUnits] = useState<ProductObjType[]>([]);
    const [productUses, setProductUses] = useState<ProductObjType[]>([]);
    const [productSymptom, setProductSymptom] = useState<ProductObjType[]>([]);
    const [productUnitName, setProductUnitName] = useState('');

    // loading
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [loadingProductInstructions, setLoadingProductInstructions] = useState(false);
    const [loadingProductUnits, setLoadingProductUnits] = useState(false);
    const [loadingProductUses, setLoadingProductUses] = useState(false);
    const [loadingDoctors, setLoadingDoctors] = useState(false);

    const [maxLength, setMaxLength] = useState<number>(0);
    const [textLength, setTextLength] = useState<number>(0)
    const [errorMaxLength, setErrorMaxLength] = useState<boolean>(false)

    const previewMedRef = useRef<any>(null)

    const loadData = async () => {
        setLoading(true)
        let sticker: StickerType = {}
        if (props.item.sticker) sticker = JSON.parse(props.item.sticker)
        setProduct(props.item)
        setStickerProduct({ ...stickerProduct, ...sticker })
        const id: any = sticker.itemId
        if (id) {
            const res = props.group === 'MEDICINE' ? await TreatmentProductApi.getMedicineInformation(id) : ''
            if (res.status === 200 && res.data) {
                const rawData = res.data.sticker
                const data = JSON.parse(rawData)
                const objSticker: StickerType = {
                    ...sticker,
                    dosage: data.dosage,
                    ...handleProductDetail(sticker, data),
                    logo: branch?.branchLogo,
                    clinicName: branch?.clinicAndBranchName,
                    branchAddress: branch?.branchFullAddress,
                    branchTel: branch?.branchPhone,
                    patientFullName: props?.patientName
                }
                let newData: any = {}
                if (!objSticker?.image || !objSticker?.stock) {
                    const item: any = props.item
                    newData = {
                        ...stickerProduct, ...objSticker,
                        image: item?.image || {},
                        stock: item?.stock || "0"
                    }
                } else {
                    newData = { ...stickerProduct, ...objSticker }
                }
                setStickerProduct(newData)
                setMedicineInformationId(res.data.medicineInformationId)
                setLoading(false)
            }
            else
                if (props.group === 'MEDICINE' && props.type === 'thumbnail' && sticker.itemId) {
                    MedicinesApi.findById(sticker.itemId)
                        .then(({ data }: any) => {
                            const objSticker: StickerType = {
                                ...sticker,
                                productInstructionId: data?.productInstruction?.productInstructionId,
                                productInstructionName: data?.productInstruction?.productInstructionName,
                                productUnitId: data?.productUnit?.productUnitId,
                                productUnitName: data?.productUnit?.productUnitName,
                                productUnitNameEn: data?.productUnit?.productUnitNameEn,
                                productUseId: data?.productUse?.productUseId,
                                productUseDetail: data?.productUse?.productUseDetail,
                                logo: branch?.branchLogo,
                                clinicName: branch?.clinicAndBranchName,
                                branchAddress: branch?.branchFullAddress,
                                branchTel: branch?.branchPhone,
                                patientFullName: props?.patientName
                            }
                            setStickerProduct({ ...stickerProduct, ...objSticker })
                        })
                        .catch(() => { return })
                        .finally(() => setLoading(false))
                } else setLoading(false)
        } else setLoading(false)
    }


    const handleProductDetail = (sticker: any, data: any) => {
        const newData = {
            productInstructionId: sticker?.productInstructionId || data.productInstructionId,
            productInstructionName: sticker?.productInstructionName || data.productInstructionName,
            productUnitId: sticker?.productUnitId || data.productUnitId,
            productUnitName: sticker?.productUnitName || data.productUnitName,
            productUseId: sticker?.productUseId || data.productUseId,
            productUseDetail: sticker?.productUseDetail || data.productUseDetail,
            productSymptomId: sticker?.productSymptomId || data.productSymptomId,
            productSymptomDetail: sticker?.productSymptomDetail || data.productSymptomDetail,
        }
        return newData
    }

    const loadDefaultProductInstructions = (search?: string) => {
        ProductInstructionsApi
            .findAll({ status: 'ACTIVE', group: 'MEDICINE', pageLimit: 10, search: search || '' })
            .then(({ data }) => {
                let newProductInstructions: ProductObjType[] = []
                _.forEach(data, (i) => {
                    newProductInstructions = [...newProductInstructions, { id: i.productInstructionId, name: i.productInstructionName || i.productInstructionNameEn }]
                })
                setProductInstructions(newProductInstructions)
            })
            .catch(() => { return })
            .finally(() => {
                setLoadingProductInstructions(false)
                setDataLoading(false)
            })
    }

    const loadDefaultProductUnits = (search?: string) => {
        ProductUnitsApi
            .findAll({ status: 'ACTIVE', group: 'MEDICINE', pageLimit: 10, search: search || '' })
            .then(({ data }) => {
                let newProductUnits: ProductObjType[] = []
                _.forEach(data, (i) => {
                    newProductUnits = [...newProductUnits, { id: i.productUnitId, name: i.productUnitName, nameEn: i.productUnitNameEn }]
                })
                setProductUnits(newProductUnits)
            })
            .catch(() => { return })
            .finally(() => {
                setLoadingProductUnits(false)
                setDataLoading(false)
            })
    }

    const loadDefaultProductUses = (search?: string) => {
        ProductUsesApi
            .findAll({ status: 'ACTIVE', group: 'MEDICINE', pageLimit: 10, search: search || '' })
            .then(({ data }) => {
                let newProductUses: ProductObjType[] = []
                _.forEach(data, (i) => {
                    newProductUses = [...newProductUses, { id: i.productUseId, name: i.productUseDetail }]
                })
                setProductUses(newProductUses)
            })
            .catch(() => { return })
            .finally(() => {
                setLoadingProductUses(false)
                setDataLoading(false)
            })
    }
    const loadDefaultProductSymtom = (search?: string) => {
        ProductSymptomApi
            .findAll({ status: 'ACTIVE', group: 'MEDICINE', pageLimit: 10, search: search || '' })
            .then(({ data }) => {
                let newProductSymptom: ProductObjType[] = []
                _.forEach(data, (i) => {
                    newProductSymptom = [...newProductSymptom, { id: i.productSymptomId, name: i.detail }]
                })
                setProductSymptom(newProductSymptom)
            })
            .catch(() => { return })
            .finally(() => {
                setLoadingProductUses(false)
                setDataLoading(false)
            })
    }

    const loadDefaultDoctor = () => {
        ClinicApi.findAllDoctors()
            .then(({ data }) => setDoctors(data))
            .catch(() => { return })
            .finally(() => setLoadingDoctors(false))
    }

    const renderImage = (image: ImageType) => {
        if (image?.filePath && image?.fileName) return `${fileUrl}/${image.filePath}/${image.fileName}`
        else if (props.group === 'MATERIAL') return icons.matDefault
        else return icons.medDefault
    }

    const handleChangeProductDetail = (name: string, val: number | string) => {
        let value = val
        if (name !== 'doctorId' && name !== "discountUnit" && name !== "additionalUnit") {
            const valueNumber = !value ? 0 : String(value).replace(/[^0-9.]/g, '')
            value = Number(valueNumber)
        }
        setProduct({ ...product, [name]: value })
        setErrorMessage(initialErrorMessageState)
    }

    const onRemember = async () => {
        const newValue: any = {
            sticker: JSON.stringify(stickerProduct),
            qty: product.qty,
            itemId: product.itemId
        }
        //API HERE
        if (medicineInformationId) {
            const res = await TreatmentProductApi.editMedicineInformation(medicineInformationId, newValue)
            if (res.status === 200) {
                notiSuccess(t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.REMEM_SUCCESS'))
            }
        }
        else {
            const res = await TreatmentProductApi.addMedicineInformation(newValue)
            if (res.status === 201) {
                notiSuccess(t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.SAVE_SUCCESS'))
            }
        }


    }

    const onSubmitPopup = () => {
        if (product.qty > (stickerProduct.stock || 0) || product.discount > (product.price * product.qty)) return
        // || (props.group === 'MEDICINE' && !_.find(doctors, { userId: product.doctorId }))
        else if (product.price.toString() === '' || !product.qty) {
            return setErrorMessage({
                EMPTY_QTY_ORDER: !product.qty ? t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.EMPTY_QTY_ORDER') : '',
                EMPTY_PRICE: product.price.toString() === '' ? t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.EMPTY_PRICE') : '',
                // (props.group === 'MEDICINE' && !_.find(doctors, { userId: product.doctorId })) ? t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.EMPTY_DOCTOR') : 
                EMPTY_DOCTOR: ``
            })
        } else {
            const total = cal_sum()
            const newProduct = {
                ...product,
                stock: stickerProduct.stock,
                total: total,
                sticker: JSON.stringify(stickerProduct)
            }
            onSaveSticker(total)
            const hasItemInSummary = _.some(treatments.summeryTreatments, (sum, index) => sum.itemId === stickerProduct.itemId)
            if (!hasItemInSummary) dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, newProduct] }))
            else {
                if (props.index || props.index === 0) {
                    const summaryTreatments = _.merge([], treatments.summeryTreatments)
                    summaryTreatments[props.index] = newProduct
                    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: summaryTreatments }))
                } else return
            }
            props.onSubmit && props.onSubmit()
        }
    }

    const onSaveSticker = async (total: number) => {

        const newValue: ProductStickerType = {
            sticker: JSON.stringify(stickerProduct),
            tempOrderProductId: product.tempOrderProductId,
            doctorId: product.doctorId,
            qty: product.qty,
            price: product.price,
            discount: product.discount,
            discountUnit: product.discountUnit,
            additional: product.additional,
            additionalUnit: product.additionalUnit,
            total: total,
            itemId: product.itemId
        }

        if (!newValue.tempOrderProductId) {
            const i = _.filter(newStickerProducts, (stk: ProductStickerType) => stk.itemId !== product.itemId)
            dispatch(setNewStickerProduct({ value: [...i, newValue] }))
        } else {
            await TreatmentProductApi.updateStickerById(props.patientCaseId, newValue.itemId, newValue).then(() => { return }).catch(() => { return })
        }
    }

    const handleInputSearch = useCallback(
        _.debounce((type: 'productInstructions' | 'productUnits' | 'productUses' | 'prodctSymptom', search?: string) => {
            if (type === 'productInstructions') loadDefaultProductInstructions(search)
            if (type === 'productUnits') loadDefaultProductUnits(search)
            if (type === 'productUses') loadDefaultProductUses(search)
            if (type === 'prodctSymptom') loadDefaultProductUses(search)
        }, 1000), []);

    useEffect(() => {
        setLoading(true)
        if (props.group === 'MEDICINE' && (props.type === 'thumbnail' || props.type === 'summaryTreatment')) {
            setLoadingProductInstructions(true)
            setLoadingProductUnits(true)
            setLoadingProductUses(true)
            setLoadingDoctors(true)
            loadData()
            loadDefaultProductInstructions()
            loadDefaultProductUnits()
            loadDefaultProductUses()
            loadDefaultProductSymtom()
            loadDefaultDoctor()
        } else {
            setLoadingDoctors(true)
            loadData()
            loadDefaultDoctor()
        }
    }, [stickerProduct.itemId]);

    const reloadProduct = () => {
        loadDefaultProductInstructions()
        loadDefaultProductUnits()
        loadDefaultProductUses()
        loadDefaultProductSymtom()
    }
    const onAddProductInstruction = () => {
        setTypeAdd('PRODUCT_INSTRUCTION')
        setItems(productInstructions)
        setMaxLength(80)
        setIsShowAddProductChoice(true)
        dispatch(showModal())
    }
    const onAddProductUnit = () => {
        setTypeAdd('PRODUCT_UNITS')
        setItems(productUnits)
        setMaxLength(10)
        setIsShowAddProductChoice(true)
        dispatch(showModal())
    }
    const onAddProductUse = () => {
        setTypeAdd('PRODUCT_USES')
        setItems(productUses)
        setMaxLength(40)
        setIsShowAddProductChoice(true)
        dispatch(showModal())
    }
    const onAddProductSymptom = () => {
        setTypeAdd('PRODUCT_SYMPTOM')
        setItems(productSymptom)
        setMaxLength(40)
        setIsShowAddProductChoice(true)
        dispatch(showModal())
    }

    const handleSubmit = (data: any, type: any) => {
        const autoSelect: any = {
            PRODUCT_INSTRUCTION: { productInstructionId: data.productInstructionId, productInstructionName: data.productInstructionName, productInstructionNameEn: data.productInstructionNameEn },
            PRODUCT_UNITS: { productUnitId: data.productUnitId, productUnitName: data.productUnitName, productUnitNameEn: data.productUnitNameEn },
            PRODUCT_USES: { productUseId: data.productUseId, productUseDetail: data.productUseDetail, productUseDetailEn: data.productUseDetailEn },
            PRODUCT_SYMPTOM: { productSymptomId: data.productSymptomId, productSymptomDetail: data.detail, productSymptomDetailEn: data.detailEn }
        }
        reloadProduct()
        setStickerProduct({ ...stickerProduct, ...autoSelect[type] })
        return setIsShowAddProductChoice(false)
    }

    const handleDiscard = async (labelId: any, id: any) => {
        if (labelId === 'productInstructionId') {
            await ProductInstructionsApi
                .remove(id)
                .then(({ data }) => {
                    reloadProduct()
                    setStickerProduct({
                        ...stickerProduct,
                        productInstructionId: 0,
                        productInstructionName: ''
                    })
                })
                .catch((e) => {
                    const err = e.response.data
                    return notiError(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (labelId === 'productUnitId') {
            await ProductUnitsApi
                .remove(id)
                .then(({ data }) => {
                    reloadProduct()
                    setStickerProduct({
                        ...stickerProduct,
                        productUnitId: 0,
                        productUnitName: '',
                        productUnitNameEn: '',
                    })
                })
                .catch((e) => {
                    const err = e.response.data
                    return notiError(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (labelId === 'productUseId') {
            await ProductUsesApi
                .remove(id)
                .then(({ data }) => {
                    reloadProduct()
                    setStickerProduct({
                        ...stickerProduct,
                        productUseId: 0,
                        productUseDetail: ''
                    })
                })
                .catch((e) => {
                    const err = e.response.data
                    return notiError(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (labelId === 'productSymptomId') {
            await ProductSymptomApi
                .remove(id)
                .then(({ data }) => {
                    reloadProduct()
                    setStickerProduct({
                        ...stickerProduct,
                        productSymptomId: 0,
                        productSymptomDetail: ''
                    })
                })
                .catch((e) => {
                    const err = e.response.data
                    return notiError(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }

    }

    const cal_sum = () => {
        if (props.group !== 'MEDICINE') {
            return ((product.price * product.qty) - product.discount) + product.additional
        } else {
            const discount = product.discountUnit === "BAHT" ? product.discount : (product.price * product.qty) * (product.discount / 100)
            const additional = product.additionalUnit === "BAHT" ? product.additional : (product.price * product.qty) * (product.additional / 100)
            return ((product.price * product.qty) - discount) + additional
        }

    }

    const handleSizePreviewMed = useCallback(() => {
        if (previewMedRef?.current) {
            const newWidth = previewMedRef.current.clientWidth / 302.36
            const newHeight = previewMedRef.current.clientHeight / 188.97
            return `scale(${newHeight})`
        } else {
            return `scale(1)`
        }
    }, [previewMedRef])

    const handleChange = (content: any) => {
        setStickerProduct({
            ...stickerProduct,
            itemRecommend: content
        })
        setErrorMessage(initialErrorMessageState)
        const contentLength = content.length
        if (contentLength > 40) {
            setErrorMaxLength(true)
        } else {
            setTextLength(contentLength)
            setErrorMaxLength(false)
        }
    }

    return (
        <ModalCustom
            size='xl'
            title={t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.${props.group}_TITLE`)}
            modalStyle={`popup-manage-product`}
            component={
                (!loading && !loadingDoctors && !loadingProductInstructions && !loadingProductUnits && !loadingProductUses) && (
                    <UseStyled.CustomPopupManageMedicine>
                        <Box className='d-flex flex-column flex-sm-row'>
                            {stickerProduct.itemCode && (
                                <Typography sx={{ marginRight: { xs: '32px' }, fontWeight: 500 }}>
                                    {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.CODE')}: <span className='pl-1 pl-md-3'>{stickerProduct.itemCode}</span>
                                </Typography>
                            )}
                            <Typography sx={{ fontWeight: 500 }}>
                                {t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.${props.group}_NAME`)}: <span className='pl-1 pl-md-3'>{stickerProduct.itemName || stickerProduct.itemNameEn}</span>
                            </Typography>
                        </Box>
                        <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <Box className='d-sm-flex'>
                            <UseStyled.ImageMedicine className='w-100 mx-auto mb-4 mb-sm-0'>
                                <Box className='image-wrap position-relative'>
                                    <img src={renderImage(stickerProduct.image || {})} alt={stickerProduct.itemName} onError={onImgError} className={`w-100 h-100`} />
                                </Box>
                                <Box className='d-flex justify-content-between inventory pt-1'>
                                    <Typography sx={{ flex: 'none' }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.QTY')}</Typography>
                                    <Box className='d-flex align-items-center w-100 overflow-hidden'>
                                        <Typography>{stickerProduct.stock}</Typography>
                                        <Typography className='pl-1 text-ellipsis' title={stickerProduct.defaultProductUnitName}>{stickerProduct.defaultProductUnitName}</Typography>
                                    </Box>
                                </Box>
                            </UseStyled.ImageMedicine>
                            <Box className='w-100' sx={{ paddingTop: { md: ((props.group === 'MEDICINE') && '8px') || 0 } }}>
                                <Grid container spacing={2} className={'m-sm-0'} sx={{ width: { xs: '100%' } }}>
                                    {(props.group === 'MEDICINE') && (
                                        <>
                                            <Grid item md={4} lg={5} className={'w-100 pt-sm-0'}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={11} className='' style={{ width: "calc(100% - 45px)" }}>
                                                        <AutocompleteSelect
                                                            labelId="productInstructionId"
                                                            inputLabel={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_INSTRUCTION')}
                                                            noOptionsText={t('NOT_FOUND')}
                                                            options={productInstructions}
                                                            getOptionLabel={(option: any) => option.name || stickerProduct.productInstructionName}
                                                            renderOption={(props, option: any) => (
                                                                <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                    {option.name}
                                                                    <IconButton
                                                                        onClick={() => handleDiscard("productInstructionId", option.id)}
                                                                        className={`p-0`}
                                                                        sx={{
                                                                            backgroundColor: 'transparent',
                                                                            filter: 'grayscale(0)',
                                                                            opacity: 1
                                                                        }}
                                                                    >
                                                                        <img src={icons.btnMinus} alt="discard" />
                                                                    </IconButton>
                                                                </Box>

                                                            )}
                                                            onchange={(e, value: any) => {
                                                                setStickerProduct({
                                                                    ...stickerProduct,
                                                                    productInstructionId: value?.id || 0,
                                                                    productInstructionName: _.get(_.find(productInstructions, { id: value?.id || 0 }), 'name')
                                                                })
                                                                setErrorMessage(initialErrorMessageState)
                                                            }}
                                                            inputProps={{
                                                                onChange: (e: any) => {
                                                                    setDataLoading(true)
                                                                    setProductInstructions([])
                                                                    handleInputSearch('productInstructions', e.target.value)
                                                                }
                                                            }}
                                                            onOpen={(e: any) => loadDefaultProductInstructions()}
                                                            value={stickerProduct.productInstructionId ? _.find(productInstructions, { id: stickerProduct.productInstructionId }) || '' : null}
                                                            loading={dataLoading}
                                                            disabledBorder
                                                            disableClearable={!stickerProduct.productInstructionId} />
                                                    </Grid>
                                                    <Grid item lg={1} className="pl-4" >
                                                        <IconAddBox className="" sx={{ backgroundColor: 'transparent !important', marginLeft: '-25px' }} onClick={onAddProductInstruction}>
                                                            <AddBoxIcon style={{ width: 30, height: 30 }} />
                                                        </IconAddBox>
                                                    </Grid>
                                                    <Grid item lg={4} className='w-100'>
                                                        <InputTextField
                                                            type='number'
                                                            hideControl
                                                            label={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_DOSAGE')}
                                                            value={stickerProduct.dosage}
                                                            onchange={(e: any) => {
                                                                const valueNumber = !e.target.value ? 0 : String(e.target.value).replace(/[^0-9.]/g, '')
                                                                setStickerProduct({ ...stickerProduct, 'dosage': Number(valueNumber) })
                                                                setErrorMessage(initialErrorMessageState)
                                                            }} />
                                                    </Grid>
                                                    <Grid item lg={7} className='' style={{ width: "calc(100% - 45px)" }}>
                                                        <AutocompleteSelect
                                                            inputLabel={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_UNIT')}
                                                            labelId="productUnitId"
                                                            noOptionsText={t('NOT_FOUND')}
                                                            options={productUnits}
                                                            getOptionLabel={(option: any) => option.name || stickerProduct.productUnitName}
                                                            renderOption={(props, option: any) => (
                                                                <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                    {option.name}
                                                                    <IconButton
                                                                        onClick={() => handleDiscard("productUnitId", option.id)}
                                                                        className={`p-0`}
                                                                        sx={{
                                                                            backgroundColor: 'transparent',
                                                                            filter: 'grayscale(0)',
                                                                            opacity: 1
                                                                        }}
                                                                    >
                                                                        <img src={icons.btnMinus} alt="discard" />
                                                                    </IconButton>
                                                                </Box>
                                                            )}
                                                            onchange={(e, value: any) => {
                                                                setStickerProduct({
                                                                    ...stickerProduct,
                                                                    productUnitId: value?.id || 0,
                                                                    productUnitName: _.get(_.find(productUnits, { id: value?.id || 0 }), 'name'),
                                                                    productUnitNameEn: _.get(_.find(productUnits, { id: value?.id || 0 }), 'nameEn'),
                                                                })
                                                                setErrorMessage(initialErrorMessageState)
                                                            }}
                                                            inputProps={{
                                                                onChange: (e: any) => {
                                                                    setDataLoading(true)
                                                                    setProductUnits([])
                                                                    handleInputSearch('productUnits', e.target.value)
                                                                }
                                                            }}
                                                            onOpen={(e: any) => loadDefaultProductUnits()}
                                                            value={stickerProduct.productUnitId ? _.find(productUnits, { id: stickerProduct.productUnitId }) || '' : null}
                                                            loading={dataLoading}
                                                            disabledBorder
                                                            disableClearable={!stickerProduct.productUnitId} />
                                                    </Grid>
                                                    <Grid item lg={1} className="pl-4">
                                                        <IconAddBox className="" sx={{ backgroundColor: 'transparent !important', marginLeft: '-25px' }} onClick={onAddProductUnit}>
                                                            <AddBoxIcon style={{ width: 30, height: 30 }} />
                                                        </IconAddBox>
                                                    </Grid>
                                                    <Grid item lg={11} className='' style={{ width: "calc(100% - 45px)" }}>
                                                        <AutocompleteSelect
                                                            inputLabel={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_USE')}
                                                            labelId="productUseId"
                                                            noOptionsText={t('NOT_FOUND')}
                                                            options={productUses}
                                                            getOptionLabel={(option: any) => option.name || stickerProduct.productUseDetail}
                                                            renderOption={(props, option: any) => (
                                                                <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                    {option.name}
                                                                    <IconButton
                                                                        onClick={() => handleDiscard("productUseId", option.id)}
                                                                        className={`p-0`}
                                                                        sx={{
                                                                            backgroundColor: 'transparent',
                                                                            filter: 'grayscale(0)',
                                                                            opacity: 1
                                                                        }}
                                                                    >
                                                                        <img src={icons.btnMinus} alt="discard" />
                                                                    </IconButton>
                                                                </Box>
                                                            )}
                                                            onchange={(e, value: any) => {
                                                                setStickerProduct({
                                                                    ...stickerProduct,
                                                                    productUseId: value?.id || 0,
                                                                    productUseDetail: _.get(_.find(productUses, { id: value?.id || 0 }), 'name')
                                                                })
                                                                setErrorMessage(initialErrorMessageState)
                                                            }}
                                                            inputProps={{
                                                                onChange: (e: any) => {
                                                                    setDataLoading(true)
                                                                    setProductUses([])
                                                                    handleInputSearch('productUses', e.target.value)
                                                                }
                                                            }}
                                                            onOpen={(e: any) => loadDefaultProductUses()}
                                                            value={stickerProduct.productUseId ? _.find(productUses, { id: stickerProduct.productUseId }) || '' : null}
                                                            loading={dataLoading}
                                                            disabledBorder
                                                            disableClearable={!stickerProduct.productUseId} />
                                                    </Grid>
                                                    <Grid item lg={1} className="pl-4">
                                                        <IconAddBox className="" sx={{ backgroundColor: 'transparent !important', marginLeft: '-25px' }} onClick={onAddProductUse}>
                                                            <AddBoxIcon style={{ width: 30, height: 30 }} />
                                                        </IconAddBox>
                                                    </Grid>
                                                    <Grid item lg={11} className='' style={{ width: "calc(100% - 45px)" }}>
                                                        <AutocompleteSelect
                                                            inputLabel={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.SYMPTOM')}
                                                            labelId="productSymptomId"
                                                            noOptionsText={t('NOT_FOUND')}
                                                            options={productSymptom}
                                                            getOptionLabel={(option: any) => option.name || ''}
                                                            renderOption={(props, option: any) => (
                                                                <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                    {option.name}
                                                                    <IconButton
                                                                        onClick={() => handleDiscard("productSymptomId", option.id)}
                                                                        className={`p-0`}
                                                                        sx={{
                                                                            backgroundColor: 'transparent',
                                                                            filter: 'grayscale(0)',
                                                                            opacity: 1
                                                                        }}
                                                                    >
                                                                        <img src={icons.btnMinus} alt="discard" />
                                                                    </IconButton>
                                                                </Box>
                                                            )}
                                                            onchange={(e, value: any) => {
                                                                setStickerProduct({
                                                                    ...stickerProduct,
                                                                    productSymptomId: value?.id || 0,
                                                                    productSymptomDetail: _.get(_.find(productSymptom, { id: value?.id || 0 }), 'name')
                                                                })
                                                                setErrorMessage(initialErrorMessageState)
                                                            }}
                                                            inputProps={{
                                                                onChange: (e: any) => {
                                                                    setDataLoading(true)
                                                                    setProductSymptom([])
                                                                    handleInputSearch('prodctSymptom', e.target.value)
                                                                }
                                                            }}
                                                            onOpen={(e: any) => loadDefaultProductSymtom()}
                                                            value={stickerProduct.productSymptomId ? _.find(productSymptom, { id: stickerProduct.productSymptomId }) || '' : null}
                                                            loading={dataLoading}
                                                            disabledBorder
                                                            disableClearable={!stickerProduct.productSymptomId} />

                                                    </Grid>
                                                    <Grid item lg={1} className="pl-4">
                                                        <IconAddBox className="" sx={{ backgroundColor: 'transparent !important', marginLeft: '-25px' }} onClick={onAddProductSymptom}>
                                                            <AddBoxIcon style={{ width: 30, height: 30 }} />
                                                        </IconAddBox>
                                                    </Grid>

                                                    <Grid item className='w-100'>
                                                        <InputTextarea
                                                            height={'80px'}
                                                            label={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_NOTE')}
                                                            value={stickerProduct.itemRecommend}
                                                            onchange={(e: any) => {
                                                                if (e.target.value.length <= 40) handleChange(e.target.value)
                                                            }}
                                                            inputProps={{ maxLength: 40 }}
                                                        />
                                                        <Box sx={{ position: 'absolute', marginTop: '-20px', left: 530 }}>
                                                            <Typography sx={{ fontWeight: 400, fontSize: '10px', color: '#808080' }}>{`${textLength}/40`}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* label */}
                                            <Grid item md={8} lg={7} className={'w-100 pt-lg-0 d-flex flex-column'}>
                                                <Typography sx={{ fontWeight: 500, marginTop: { md: '-16px' } }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MEDICINE_LABEL_PREVIEW')}</Typography>
                                                <Box ref={previewMedRef} className="h-100 w-100" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                    <Box sx={{ width: '302.36220472px ', height: '188.97637795px', transform: handleSizePreviewMed(), backgroundColor: "#fff" }}>
                                                        <Box ref={componentRef} className="h-100 w-100">
                                                            <PdfMedSticker stickerData={[{ ...product, sticker: JSON.stringify(stickerProduct) }]} doctors={doctors} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </>
                                    )}

                                    {/* summary */}
                                    <Grid item className={`${((props.group === 'MEDICINE') && 'mt-2') || 'pt-sm-0'} w-100`}>
                                        <Typography sx={{ fontWeight: 500 }}>{t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.${props.group}_DETAIL`)}</Typography>
                                        <Box className='d-sm-flex align-items-center justify-content-between mt-3'>
                                            <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none' }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PREVIEW.DOCTOR')}</Typography>
                                            <Box className='w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                <AutocompleteSelect
                                                    labelId="sl-dentist"
                                                    options={doctors}
                                                    getOptionLabel={(option) => option.fullname}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <Box
                                                                {...props}
                                                                key={option.userId}
                                                                component="li"
                                                                style={{
                                                                    pointerEvents: doctorHasOverDue(treatments, option.userId) ? 'none' : 'auto',
                                                                    color: doctorHasOverDue(treatments, option.userId) ? colors.disabledGray : colors.textPrimary
                                                                }}>
                                                                {option.fullname}
                                                            </Box>
                                                        )
                                                    }}
                                                    onchange={(e, value) => handleChangeProductDetail('doctorId', value.userId)}
                                                    noOptionsText={t('NOT_FOUND')}
                                                    filterOptions={(option) => option.fullname}
                                                    value={product.doctorId ? _.find(doctors, { userId: product.doctorId }) : null}
                                                    disableClearable
                                                    errMessage={errorMessage.EMPTY_DOCTOR || ``}
                                                />
                                            </Box>
                                        </Box>
                                        <Box className='d-sm-flex justify-content-between mt-2'>
                                            <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none', paddingTop: '10px' }}>
                                                {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.QTY_ORDER')}
                                            </Typography>
                                            <Box className='d-flex justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                    <InputTextField
                                                        inputTextAlign={'right'}
                                                        value={product.qty}
                                                        onchange={(e: any) => handleChangeProductDetail('qty', Number(e.target.value))}
                                                        helperText={Number(product.qty) > (stickerProduct.stock || 0) && t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.OVER_STOCK', { stock: stickerProduct.stock, unit: stickerProduct.defaultProductUnitName }) || errorMessage.EMPTY_QTY_ORDER || ''} />
                                                </Box>
                                                <Typography className='pl-1 text-right' sx={{ width: '80px', paddingTop: '10px' }}>{stickerProduct.productUnitName}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className='d-sm-flex justify-content-between mt-2'>
                                            <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none', paddingTop: '10px' }}>
                                                {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRICE')}
                                            </Typography>
                                            <Box className='d-flex justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                    <InputTextField
                                                        disabled={props.hasChangeProductPrice === 'UNPERMISS'}
                                                        inputTextAlign={'right'}
                                                        value={product.price}
                                                        onchange={(e: any) => handleChangeProductDetail('price', e.target.value)}
                                                        helperText={errorMessage.EMPTY_PRICE || ''} />
                                                </Box>
                                                <Typography className='pl-1 text-right' sx={{ width: '80px', paddingTop: '10px' }}>{t('CURRENCY_CHANGE.TH')}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className='d-sm-flex justify-content-between mt-2'>
                                            <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none', paddingTop: '10px' }}>
                                                {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.DISCOUNT')}
                                            </Typography>
                                            <Box className='d-flex justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                    <InputTextField
                                                        disabled={props.hasChangeProductDiscountAdditional === 'UNPERMISS'}
                                                        hideControl
                                                        inputTextAlign={'right'}
                                                        value={product.discount}
                                                        onchange={(e: any) => handleChangeProductDetail('discount', e.target.value)}
                                                        helperText={product.discount > (product.price * product.qty) && t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.OVER_DISCOUNT', { price: numberFormat(product.price * product.qty || 0) }) || ''} />
                                                </Box>
                                                {props.group !== 'MEDICINE' ?
                                                    <Typography className='pl-1 text-right' sx={{ width: '80px', paddingTop: '10px' }}>{t('CURRENCY_CHANGE.TH')}</Typography>
                                                    :
                                                    <Box sx={{ width: "80px" }}>
                                                        <BasicSelect
                                                            labelId="lb-unit"
                                                            selectId="sl-unit"
                                                            label=""
                                                            value={product.discountUnit || ""}
                                                            options={
                                                                _.map(units, (unit: string, index: number) => {
                                                                    return <MenuItem key={index} value={unit}>
                                                                        {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                                                    </MenuItem>
                                                                })
                                                            }
                                                            onchange={(e: any) => handleChangeProductDetail('discountUnit', e.target.value)}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                        <Box className='d-sm-flex align-items-center justify-content-between mt-2'>
                                            <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none' }}>
                                                {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.ADDON')}
                                            </Typography>
                                            <Box className='d-flex align-items-center justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                    <InputTextField
                                                        disabled={props.hasChangeProductDiscountAdditional === 'UNPERMISS'}
                                                        inputTextAlign={'right'}
                                                        value={product.additional}
                                                        onchange={(e: any) => handleChangeProductDetail('additional', e.target.value)} />
                                                </Box>
                                                {props.group !== 'MEDICINE' ?
                                                    <Typography className='pl-1 text-right' sx={{ width: '80px' }}>{t('CURRENCY_CHANGE.TH')}</Typography>
                                                    : <Box sx={{ width: "80px" }}>
                                                        <BasicSelect
                                                            labelId="lb-unit"
                                                            selectId="sl-unit"
                                                            label=""
                                                            value={product.additionalUnit || ""}
                                                            options={
                                                                _.map(units, (unit: string, index: number) => {
                                                                    return <MenuItem key={index} value={unit}>
                                                                        {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                                                    </MenuItem>
                                                                })
                                                            }
                                                            onchange={(e: any) => handleChangeProductDetail('additionalUnit', e.target.value)}
                                                        />
                                                    </Box>}
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item className={'w-100'}>
                                        <UseStyled.SummaryCost className='d-flex justify-content-end align-items-center px-3'>
                                            <Typography>{t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')}</Typography>
                                            <Typography className='pl-4 text-right' sx={{ minWidth: { sm: '200px' } }}>
                                                {numberFormat(cal_sum())} {t('CURRENCY_CHANGE.TH')}
                                            </Typography>
                                        </UseStyled.SummaryCost>
                                    </Grid>
                                    <Grid item className={'w-100'}>
                                        <UseStyled.GroupButton className='d-sm-flex align-items-center justify-content-end mt-2'>
                                            {props.group === 'MEDICINE' && <ButtonCustom onClick={onRemember} textButton={t('TREATMENT_SUMMARY.BUTTON.REMEMBER')} variant={'outlined'} className='mr-sm-3' style={{ margin: 0 }} />}
                                            <ButtonCustom onClick={props.onCancel} textButton={t('BUTTON.CANCEL')} variant={'outlined'} className='mr-sm-3' style={{ margin: 0 }} />
                                            {(props.group === 'MEDICINE') && (
                                                <>
                                                    <FunctionPrint
                                                        content={() => componentRef.current}
                                                        docTitle={t('TX_INFORMATION.TITLE')}
                                                        removeIfram
                                                        trigger={() => <ButtonCustom
                                                            disabled={(!product.qty || product.price.toString() === '' || (product.qty > (stickerProduct?.stock || 0)) || product.discount > (product.price * product.qty)) || (props.group === 'MEDICINE' && !_.find(doctors, { userId: product.doctorId }))}
                                                            textButton={t('BUTTON.PRINT')}
                                                            variant={'outlined'}
                                                            className='mt-3 mt-sm-0 mr-sm-3'
                                                            style={{ margin: 0 }} />}
                                                        style={'@page { size: 302.36220472px 188.97637795px;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
                                                    />
                                                </>
                                            )}
                                            <ButtonCustom onClick={onSubmitPopup} textButton={t('BUTTON.SAVE')} className='mt-3 mt-sm-0' style={{ margin: 0 }} />
                                        </UseStyled.GroupButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        {isShowAddProductChoice && (
                            <PopupManageProductAddForm
                                type={typeAdd}
                                items={items}
                                isShow={isShowAddProductChoice}
                                onSubmit={(data, type) => handleSubmit(data, type)}
                                onCancel={() => setIsShowAddProductChoice(false)}
                                maxLength={maxLength}
                            />
                        )}
                    </UseStyled.CustomPopupManageMedicine>

                ) || (
                    <Loading show={true} type='softLoading' className='mx-auto' />
                )
            }
        />
    )
}
