import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const CustomColorBtn = (colorMain: boolean,
  colorMainHover: boolean,
  mode?: 'add' | 'default' | 'del' | 'commission',) => {
  if (mode === 'del') {
    if (colorMain) {
      return colors.statusDangerColor
    }
    if (colorMainHover) {
      return colors.statusInactive
    }
  } else if (mode === 'commission') {
    return ""
  } else {
    if (colorMain) {
      return colors.themeSecondColor
    }
    if (colorMainHover) {
      return colors.themeSecondDarkColor
    }
  }
}

const CustomButton = styled(Button)<{ mode?: 'add' | 'default' | 'del' | 'commission' }>(({ theme, mode }) => {
  const colorMain = CustomColorBtn(true, false, mode)
  const colorMainHover = CustomColorBtn(false, true, mode)
  const btnPadding = () => {
    if (mode === 'add') {
      return '8px 16px !important'
    } else if (mode === 'commission') {
      return ''
    } else {
      return '10px 16px !important'
    }
  }
  return ({
    '&.Mui-disabled': {
      opacity: 0.7,
      '&.MuiButton-contained': {
        background: `${colors.disabledGray} !important`,
        backgroundColor: `${colors.disabledGray} !important`,
      },
      '&.MuiButton-outlined': {
        border: `1px solid ${colors.disabledGray} !important`,
        color: `${colors.disabledGray} !important`
      }
    },
    '&.MuiButton-text': {
      color: `${colorMain} !important`
    },
    '&.MuiButton-outlined': {
      color: `${colorMain} !important`,
      border: `1px solid ${colorMain} !important`,
      '&:hover': {
        backgroundColor: `${colors.themeSecondColor06}`,
      }
    },
    '&.MuiButton-contained': {
      background: `${colorMain} !important`,
      backgroundColor: `${colorMain} !important`,
      color: `${colors.white} !important`,
      padding: btnPadding,
      border: 'none !important',
      '&:hover': {
        backgroundColor: `${colorMainHover} !important`,
        color: `${colors.white} !important`
      },
      '& .MuiButton-startIcon': {
        marginLeft: 0
      }
    },
    '&.btn-style': {
      borderRadius: '4px'
    }
  })
})

export const ButtonBase = styled(Button)(({ theme }) => ({
  height: 44,
  fontSize: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem'
}))

export default function ButtonCustom(props: any) {
  return (
    <Box sx={{ margin: 'auto', ...props.style }}>
      <CustomButton
        sx={{ ...props.style, ...props.btnStyle }}
        endIcon={props.endIcon}
        startIcon={props.startIcon}
        onClick={props.onClick}
        type={props.type}
        className={`btn-style ${props.className} shadow-none`}
        disabled={props.disabled}
        variant={props.variant || 'contained'}
        mode={props.mode}
      >
        {(props.mode === 'add' && (
          <span style={{ fontSize: '1rem' }}>
            <AddCircleIcon className="mr-2" style={{ fontSize: 20 }} />
            <span className="align-middle">{props.textButton}</span>
          </span>
        )) ||
          props.textButton}
      </CustomButton>
    </Box>
  )
}

ButtonCustom.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  textButton: PropTypes.any,
  style: PropTypes.any,
  endIcon: PropTypes.any,
  startIcon: PropTypes.any,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  mode: PropTypes.oneOf(['add', 'del', 'default', 'commission']),
  btnStyle: PropTypes.any
}
