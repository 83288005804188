
import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface LabsInterface {
  status: string
}

export interface LabsGroupsInterface {
  labGroupName: string
  status: string
  labGroupDetails: [
    {
      labTypeId: number
      labTypeName: string
      labId: number
      labName: string
    }
  ]
}

const path: any = 'lab-groups'

export default class LabGroupsApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(path, { params: { ...props } }).then((res) => res)
  }

  static create(body: LabsGroupsInterface) {
    return this.api.post(path, body)
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`${path}/${id}`)
  }

  static update(id: number, body: LabsGroupsInterface) {
    return this.api.patch(`${path}/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`${path}/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`${path}/${id}`)
  }
}
