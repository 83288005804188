import { useState } from 'react'
import classNames from 'classnames'
import 'component/Calendar/CalendarMonth/Style.css'
import { Image } from 'react-bootstrap'
import { daysOfWeek, createDaysForCurrentMonth, createDaysForNextMonth, createDaysForPreviousMonth, isWeekendDay } from 'utils/calendar-day-helper.utils'
import moment from 'moment'
import cancel from 'assets/icon/status/cancel.svg'
import changeSchedule from 'assets/icon/status/changeSchedule.svg'
import enter from 'assets/icon/status/enter.svg'
import notConnect from 'assets/icon/status/notConnect.svg'
import userCheckFalse from 'assets/icon/status/userCheckFalse.svg'
import userCheckTrue from 'assets/icon/status/userCheckTrue.svg'
import statusDefault from 'assets/icon/status/statusDefault.svg'
import PopupAppointment from 'component/CardCustom/PopupAppointment'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import _ from 'lodash'

const statusIcon: any = {
  arrived: { src: userCheckTrue, color: colors.appointmentStatus.statusArrived },
  unarrived: { src: userCheckFalse, color: colors.appointmentStatus.statusUnArrived },
  cancel: { src: cancel, color: colors.appointmentStatus.statusCancel },
  enter: { src: enter, color: colors.appointmentStatus.statusEnter },
  unconnect: { src: notConnect, color: colors.appointmentStatus.statusUnConnect },
  change: { src: changeSchedule, color: colors.appointmentStatus.statusChange }
}

type Calendar = {
  className?: string
  yearAndMonth: number[]
  onYearAndMonthChange?: (a: any) => any
  handleMonthNavBackButtonClick?: () => any
  handleMonthNavForwardButtonClick?: () => any
  handleMonthSelect?: (a: any) => any
  handleYearSelect?: (a: any) => any
  renderDay?: (val: any) => any
}

export default function CalendarDay(props: Calendar) {
  const [year, month] = props.yearAndMonth
  const currentMonthDays = createDaysForCurrentMonth(year, month)
  const previousMonthDays = createDaysForPreviousMonth(year, month, currentMonthDays)
  const nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays)
  const calendarGridDayObjects = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays]

  return (
    <div className="calendar-root">
      <div className="days-of-week sticky-top">
        {daysOfWeek.map((day, index) => (
          <div
            key={day}
            className={classNames('day-of-week-header-cell', {
              'weekend-day': [6, 0].includes(index)
            })}
          >
            {day}
          </div>
        ))}
      </div>
      <div className="days-grid">
        {calendarGridDayObjects.map((day) => (
          <div
            key={day.dateString}
            className={classNames('day-grid-item-container', {
              'weekend-day': isWeekendDay(day.dateString),
              'current-month': day.isCurrentMonth
            })}
          >
            <div className="day-content-wrapper">{props.renderDay ? props.renderDay(day) : undefined}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const DivDayNumber = styled('div')(({ theme }) => ({
  paddingTop: '1px',
  marginLeft: '.5rem',
  '&.now': { color: colors.white, backgroundColor: colors.themeMainColor }
}))

type CalendarItemProps = {
  calendarDayObject: any
  allDentists?: any
  editAppointment?: any
  items?: any
}
export function CalendarItem(props: CalendarItemProps) {
  const [dropDown, setDropDown] = useState(false)
  const [dentistIndex, setDentistIndex] = useState(Number)

  const getIcon = (obj: any) => {
    if (obj.arrived) {
      return statusIcon.arrived.src
    } else if (obj.status.unarrived) {
      return statusIcon.unarrived.src
    } else if (obj.status.cancel) {
      return statusIcon.cancel.src
    } else if (obj.status.enter) {
      return statusIcon.enter.src
    } else if (obj.status.unconnect) {
      return statusIcon.unconnect.src
    } else if (obj.status.change) {
      return statusIcon.change.src
    } else {
      return statusDefault
    }
  }

  const getColor = (obj: any) => {
    if (obj.status.arrived) {
      return statusIcon.arrived.color
    } else if (obj.status.unarrived) {
      return statusIcon.unarrived.color
    } else if (obj.status.cancel) {
      return statusIcon.cancel.color
    } else if (obj.status.enter) {
      return statusIcon.enter.color
    } else if (obj.status.unconnect) {
      return statusIcon.unconnect.color
    } else if (obj.status.change) {
      return statusIcon.change.color
    } else {
      return colors.lightGray
    }
  }

  return (
    <div style={{ backgroundColor: colors.white, height: '100%' }}>
      <div className="container-item custom-scroll px-0">
        <DivDayNumber className={`day-grid-item-header ${props.calendarDayObject.dateString === moment().format('YYYY-MM-DD') ? 'now' : ''}`}>{props.calendarDayObject.dayOfMonth}</DivDayNumber>
        {props.items}
        <div>
          {_.map(props.allDentists, (dentist: any, index: any) => {
            if (dentist.date === props.calendarDayObject.dateString) {
              return (
                <div
                  key={index}
                  style={{ position: 'relative' }}
                  onClick={(e) => {
                    setDentistIndex(index)
                  }}>
                  <div
                    className="tab-dentist-container"
                    onMouseDown={() => {
                      setDropDown(!dropDown)
                      setDentistIndex(-1)
                    }}>
                    <div className="icon-card-dentist-time-name" style={{ backgroundColor: getColor(dentist) }}>
                      <Image src={getIcon(dentist)} />
                    </div>
                    <div className="card-dentist-time-name">
                      <div className="dentist-time-name">{`${dentist?.time.timeStart} น. -  ${dentist?.time.timeEnd} น. | `}</div>
                      <div className="dentist-name">{dentist?.dentist}</div>
                    </div>
                  </div>
                  <div className="popup-info-tab" style={{ position: 'absolute', zIndex: 200, display: dropDown && dentistIndex === index ? 'block' : 'none' }}>
                    <PopupAppointment index={index} editAppointment={props.editAppointment} appointment={dentist} setDropDown={setDropDown} setDentistName={() => { return }} />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}
