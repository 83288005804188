import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import TemplateDoctorFeeApi from 'api/setting/doctor-fee-managements/template-doctor-fee.api'
import type { TemplateDoctorFee as DoctorFee } from 'api/setting/doctor-fee-managements/template-doctor-fee.api'

import { isCopy, isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive, swalCustom } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom'
import { closeModal, showModal } from 'app/slice/modal.slice';
import { useDispatch } from 'react-redux';
import InputTextField from 'component/Input/InputTextField';
import ModalCustom from 'component/ModalCustom/ModalCustom';
import { Box, ClickAwayListener, MenuItem, Tooltip, Fade, useTheme } from '@mui/material';
import { getBranch } from 'utils/app.utils';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import _ from 'lodash';
import { handleChangeFilterSelect, renderFilterSelect } from 'features/dashboard/dashboard';
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment';
import InputCheckbox from 'component/Input/InputCheckbox';
import AutocompleteSelect from 'component/Select/AutocompleteSelect';
import { SpanClearFilter } from 'features/report/useStyled';
import { colors } from 'constants/theme';
import './style'
import Loading from 'component/Loading';
import { ButtonInfoSimple } from 'component/Button/ButtonInfo';
import ReactDOMServer from 'react-dom/server';

type Branch = {
  branchId: number
  branchName: string
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
  isCopy: isCopy(),
}

const initStateErrorMessage = {
  TEMPLATE_DOCTOR_FEE_NAME: ``,
  BRANCH_ID: ``,
  BRANCH_MAIN_ID: ``
}

export default function TemplateDoctorFee() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [templateDoctorFees, setTemplateDoctorFee] = useState([])
  const [loading, setLoading] = useState(false)
  const [objCopy, setObjCopy] = useState({ templateDoctorFeeId: 0, templateDoctorFeeName: '', })
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const [getBranchId, setgetBranchId] = useState<number>(getBranch())
  const [selectBranch, setSelectBranch] = useState<any[]>([])
  const [branches, setBranches] = useState<BranchInterface[]>([])
  const [openFilterBranch, setOpenFilterBranch] = useState(false)
  const [checkLink, setCheckLink] = useState<'1' | '0'>('0')

  const [selectBranchOption, setSelectBranchOption] = useState<any>([])
  const [branchMainId, setBranchMainId] = useState<number>(0)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const status = ''

  const theme = useTheme()

  useEffect(() => {
    if (selectBranch?.length > 1) {
      const newData = selectBranch.map((item) => {
        const data = branches.find((info) => info.branchId === item)
        if (data?.branchId) {
          return data
        } else {
          return {}
        }
      })
      setSelectBranchOption(newData)
    } else {
      setCheckLink('0')
      setSelectBranchOption([])
    }
  }, [selectBranch])

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status }

    setgetBranchId(getBranch())

    const res = await TemplateDoctorFeeApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setTemplateDoctorFee(res.data)
    }

  }, [page, pageLimit, search, sortBy, sortType, status])

  const loadBranche = useCallback(() => {
    ClinicApi.findAllBranches().then(({ data }) => {
      setBranches(data)
      // setSelectBranch(['ALL', ...data.map((d: any) => d.branchId)])
      setSelectBranch([])
    })

  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    loadBranche()
  }, [loadBranche])

  const onCreate = () => {
    history.push(routeName.templateDoctorFee + '/create')
  }

  const onSubmit = () => {
    setLoading(true)

    const branch = [..._.map(_.filter(selectBranch, (branchId: any) => { if (branchId !== "ALL") return branchId }), (branchId: any) => { return { branchId: branchId } })]
    if (!objCopy.templateDoctorFeeName || !branch.length || (checkLink === '1' && !branchMainId)) {
      setLoading(false)
      return setErrorMessage({
        TEMPLATE_DOCTOR_FEE_NAME: !objCopy.templateDoctorFeeName ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        BRANCH_ID: !branch.length ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.BRANCH_ID') : '',
        BRANCH_MAIN_ID: (checkLink === '1' && !branchMainId) ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.BRANCH_MAIN_ID') : ''
      })
    }

    const objData = {
      templateDoctorFeeName: objCopy.templateDoctorFeeName,
      branch: branch,
      connect: checkLink,
      branchMainId: branchMainId
    }

    TemplateDoctorFeeApi.copy(objCopy.templateDoctorFeeId, objData)
      .then((res) => {
        notiSuccess(t('TEMPLATE_DOCTOR_FEE.MESSAGE.SUCCESS.CREATE'), '', null, null)
        loadData()
        setObjCopy({ templateDoctorFeeId: 0, templateDoctorFeeName: '', })
        setSelectBranch([])
        setCheckLink('0')
        dispatch(closeModal())
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        if (e.response.data.message === 'IS_MAIN_TEMPLATE_ALREADY_EXISTS') return notiError(t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.IS_MAIN_TEMPLATE_ALREADY_USE'), '', null, null, '')
        else if (e.response.data.message === 'IS_DUPLICATE_TEMPLATE_DOCTOR_FEE_NAME') {
          const dataError = e?.response?.data?.error?.split(',').map((item: any) => parseInt(item.trim()));
          const newHtml = (
            <div>
              <div>{t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.NAME_IS_DUP_TITLE')}{`"${objCopy?.templateDoctorFeeName}"`}</div>
              <div>{t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.NAME_IS_DUP_SUBTITLE')}</div>
              <div style={{ textAlign: 'left', marginTop: '6px' }}>
                <div style={{ fontWeight: 500, color: 'black' }}>{t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.NAME_IS_DUP_HEADER')}</div>
                <ul>
                  {dataError.map((item: any, index: number) => {
                    return <li key={index}>{branches.find((info) => info.branchId === item)?.branchName || ''}</li>
                  })}
                </ul>
              </div>
            </div>
          )

          const htmlString = ReactDOMServer.renderToString(newHtml);
          const parser = new DOMParser();
          const htmlElement = parser.parseFromString(htmlString, 'text/html').body.firstChild;

          swalCustom(
            t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.IS_DUP_NAME_TEMPLATE_DF'),
            htmlElement,
            (res: any) => console.log(),
            'warning',
            {
              confirmButton: {
                showConfirmButton: false
              },
              cancelButton: {
                cancelButtonText: t('BUTTON.ENTER')
              }
            }
          )
          //  return notiError(t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.IS_DUPLICATE_TEMPLATE_DOCTOR_FEE_NAME'), '', null, null, '')
          return
        }
        else return notiError(e.response.data.message, '', null, null, '')
      })
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TEMPLATE_DOCTOR_FEE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          TemplateDoctorFeeApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('TEMPLATE_DOCTOR_FEE.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { templateDoctorFeeId, templateDoctorFeeName } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TEMPLATE_DOCTOR_FEE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TEMPLATE_DOCTOR_FEE.ALERT.CONFIRM_DELETE_', {
        templateDoctorFeeName: templateDoctorFeeName
      })}</p>`,
      (res: any) => {
        if (res) {
          TemplateDoctorFeeApi.remove(templateDoctorFeeId)
            .then((resp) => {
              notiSuccess(t('TEMPLATE_DOCTOR_FEE.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { templateDoctorFeeId } = data
    history.push(`${routeName.templateDoctorFee}/${templateDoctorFeeId}`)
  }

  const onCopy = async (data: any) => {
    const { templateDoctorFeeId, templateDoctorFeeName, amount, labDoctorAmount, isMain } = data
    setObjCopy({ templateDoctorFeeId: templateDoctorFeeId, templateDoctorFeeName: `${templateDoctorFeeName}(Copy)` })
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }


  const handleChangeSelectBranch = (value: any) => {
    if ((value === 'ALL' && _.size(selectBranch.filter((item) => typeof item === 'number')) === _.size(branches)) || value === 'CLEAR') setSelectBranch([])
    else if (value === 'ALL') setSelectBranch([..._.map(branches, (d: any) => d.branchId)])
    else if (_.includes(selectBranch, value)) setSelectBranch(_.filter(selectBranch, (d) => d !== value))
    else setSelectBranch([...selectBranch, value])

    setBranchMainId(0)
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '6%' },
    { id: 'templateDoctorFeeName', disablePadding: false, label: t(`DOCTOR_FEE.FORM.DF_LAB_NAME`), width: '36%' },
    { id: 'amount', disablePadding: false, label: t(`สาขาที่ใช้งาน`), width: '19%' },
    { id: 'status', disablePadding: false, label: t('TABLE.CELL.STATUS'), width: '14%' },
    { id: 'updatedBy', disablePadding: false, label: t('TABLE.CELL.UPDATED_BY'), width: '15%' },
    { id: 'action', disablePadding: false, label: 'Action', width: '10%' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { templateDoctorFeeId, templateDoctorFeeName, amount, status: tempDfStatus, updatedAt, updatedBy, branchConnect, connectTemplateDoctorFeeId
    } = objData
    const branchNames = branchConnect.map((branches: any) => { return branches.brnachName })

    const statusBtnActive = tempDfStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = tempDfStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: templateDoctorFeeId,
      id: templateDoctorFeeId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: templateDoctorFeeName },
        {
          option: 'COMPONENT', align: 'left',
          component: <Tooltip
            title={branchNames.length ? branchNames.join(', ') : ''}
            TransitionComponent={Fade}
            placement="bottom" >
            <div>{branchNames.length ? branchNames[0] : ''}{branchNames?.length > 1 && '...'}</div>
          </Tooltip>
        },
        { option: 'STATUS', align: 'center', label: tempDfStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive || connectTemplateDoctorFeeId },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive || connectTemplateDoctorFeeId },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'COPY', label: t(`BUTTON.COPY`), disabled: !permissions.isCopy.disabled },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled || connectTemplateDoctorFeeId }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        oncopy={() => onCopy(objRenderData.obj)}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div>
      <Card className="border-0 h-100">
        <HeaderCard text={t('TEMPLATE_DOCTOR_FEE.TITLE.MANAGE_DEFAULT')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col sm={8}>
                <Row>
                  <Col md={8} lg={6} xxl={5}>
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                      formStyle={{ maxWidth: { lg: '380px' } }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('TEMPLATE_DOCTOR_FEE.TITLE.ADD_TEMPLATE_DEFAULT')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={templateDoctorFees.map((val, i) => {
                return renderData(val, i)
              })}
              tableFixedWidth
              tableMinWidth={1000}
            />
          </div>
        </Card.Body>
      </Card>
      <Loading show={loading} />

      <ModalCustom
        title={t('TEMPLATE_DOCTOR_FEE.TITLE.COPY_TEMPLATE_DEFAULT')}
        component={
          <Box className="pb-2">
            <Box>
              <InputTextField
                onchange={(event) => {
                  setObjCopy({ ...objCopy, templateDoctorFeeName: event.target.value })
                  clearErrorMessage()
                }}
                value={objCopy.templateDoctorFeeName}
                label={t('TEMPLATE_DOCTOR_FEE.FORM.TEMPLATE_NAME')}
                helperText={errorMessage.TEMPLATE_DOCTOR_FEE_NAME}
                required={true}
                style={{ marginBottom: '1rem' }}
              />
            </Box>
            <Box className={`mb-3`}>
              <ClickAwayListener onClickAway={() => setOpenFilterBranch(false)}>
                <CustomFilterDoctor
                  open={openFilterBranch}
                  onClick={() => setOpenFilterBranch(true)}
                  type={'templateDF'}
                  sx={{
                    [theme.breakpoints.up('sm')]: {
                      '.MuiAutocomplete-popper':
                      {
                        inset: 'unset !important',
                        transform: 'unset !important',
                        width: 'fit-content !important',
                        maxWidth: '340px'
                      }
                    }
                  }}
                >
                  <AutocompleteSelect
                    open={openFilterBranch}
                    multiple
                    labelId="label-doctors"
                    options={['ALL', ...branches, 'CLEAR']}
                    getOptionLabel={(option) => _.find(branches, { branchId: option.branchId })?.branchName || '-'}
                    renderOption={(props, option) => {
                      if (option === 'ALL') {
                        return (
                          <ListFilterDoctors {...props} key={option} component="li" value={option}>
                            <InputCheckbox
                              label={t('REPORT.REPORT_GROUP.ALL')}
                              checked={_.size(selectBranch.filter((item) => typeof item === 'number')) === _.size(branches)}
                              indeterminate={_.size(selectBranch) > 1 && _.size(selectBranch.filter((item) => typeof item === 'number')) !== _.size(branches)}
                              className={'pe-none'}
                            />
                          </ListFilterDoctors>
                        )
                      } else if (option === 'CLEAR') {
                        return (
                          <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                            <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                          </Box>
                        )
                      } else {
                        return (
                          <ListFilterDoctors {...props} key={option.branchId} component="li" value={option.branchId} title={option.branchName}>
                            <InputCheckbox label={option.branchName} checked={_.includes(selectBranch, option.branchId)} className={'pe-none'} />
                          </ListFilterDoctors>
                        )
                      }
                    }}
                    renderTags={(tagValue, getTagProps) => {
                      return (
                        <>
                          <span className="pr-1">{t('TEMPLATE_DOCTOR_FEE.FORM.BRANCH')}:</span>
                          {!openFilterBranch &&
                            ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(branches) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                              <Box className="text-ellipsis">
                                {_.map(tagValue, (option: any, index: number) => {
                                  return (
                                    <span>
                                      {_.find(branches, { branchId: option })?.branchName}
                                      {index !== _.findLastIndex(tagValue) && option && `,`}
                                    </span>
                                  )
                                })}
                              </Box>
                            ))}
                        </>
                      )
                    }}
                    onchange={(e, value) => handleChangeSelectBranch(e?.target?.value || e?.target?.attributes?.value?.value)}
                    noOptionsText={t('NOT_FOUND')}
                    filterOptions={(option) => 'ALL' + option.branchName + 'CLEAR'}
                    value={selectBranch}
                    height={43}
                    disableClearable
                    errMessage={errorMessage.BRANCH_ID}
                  />
                </CustomFilterDoctor>
              </ClickAwayListener>
            </Box>
            <Box>
              <InputCheckbox
                disabled={selectBranch?.length <= 1}
                label={t('TEMPLATE_DOCTOR_FEE.FORM.LINK_MANY_BRANCH')}
                name={'isLink'}
                checked={checkLink === '1' ? true : false}
                value={checkLink} onChange={() => {
                  setBranchMainId(0)
                  setCheckLink(checkLink === '1' ? '0' : '1')
                }}
              />
              <ButtonInfoSimple text={t('TEMPLATE_DOCTOR_FEE.TITLE.LINK_BRANCH')} />
            </Box>
            <Box className={`${checkLink !== '1' && 'd-none'}`}>
              <AutocompleteSelect
                required
                labelId="select-branch-option"
                inputLabel={t('TEMPLATE_DOCTOR_FEE.FORM.BRANCH_MAIN')}
                options={selectBranchOption}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => {
                  return (
                    <Box {...props}>
                      {option.branchName}
                    </Box>
                  )
                }}
                onchange={(e, value) => {
                  clearErrorMessage()
                  setBranchMainId(value?.branchId || 0)
                }}
                noOptionsText={t('NOT_FOUND')}
                value={branchMainId ? branches.find((item: any) => item?.branchId === branchMainId)?.branchName || '' : ''}
                height={43}
                disableClearable
                errMessage={errorMessage.BRANCH_MAIN_ID}
              />
            </Box>
          </Box>
        }
        onSubmit={onSubmit}
        onReset={() => {
          setObjCopy({ templateDoctorFeeId: 0, templateDoctorFeeName: '' })
          setCheckLink('0')
          setSelectBranch([])
          clearErrorMessage()
        }}
        textBtnCancel={t('TEMPLATE_DOCTOR_FEE.BUTTON.CANCEL')}
        textBtnConfirm={t('TEMPLATE_DOCTOR_FEE.BUTTON.ADD')}
      />
    </div>
  )
}
