import { Box, Typography, styled } from '@mui/material';
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { useTranslation } from 'react-i18next'
import { colors } from 'constants/theme'
import { Icons } from 'constants/images';

export type AlertProductOutOfStockType = {
    type?: 'product' | 'medicine' | 'material'
    name: string
    code: string
    qty: number
    unit: string
}

const CustomIcon = styled(Box)(({ theme }) => ({
    svg: {
        width: '100%',
        height: '100%',
        maxWidth: 103
    }
}))

export default function AlertProductOutOfStock(props: AlertProductOutOfStockType) {
    const { t } = useTranslation()

    return (
        <ModalCustom
            size={'lg'}
            title={``}
            closeButton
            modalStyle={`modal-alert-out-of-stock`}
            component={
                <Box sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
                    <CustomIcon className='text-center'>
                        <Icons.WarningCircle type='thin' />
                    </CustomIcon>
                    <Typography className='text-center pt-3' sx={{ fontSize: '20px', fontWeight: 500 }}>{t(`TREATMENT_PRODUCT.TITLE.${props.type?.toLocaleUpperCase()}_OUT_OF_STOCK`)}</Typography>
                    <Typography className='text-center pt-2' sx={{ fontSize: '14px', color: colors.disabledGray }}>{t('TREATMENT_PRODUCT.TITLE.DETAIL_OUT_OF_STOCK', { code: props.code, name: props.name, qty: props.qty, unit: props.unit })}</Typography>
                </Box>
            }
        />
    )
}
