import { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Typography, Box, MenuItem, Grid, Divider, IconButton, Collapse, TableRow, TableCell } from '@mui/material'
import { useHistory, useParams } from 'react-router'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import TemplateCreditFeeApi, { TemplateCreditFee } from 'api/setting/credit-fee-managements/template-credit-fee.api'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import BasicSelect from 'component/Select/BasicSelect'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableCustom from 'component/Table/TableCustom'
import InputCheckbox from 'component/Input/InputCheckbox'
import { notiSuccess, notiError } from 'component/notifications/notifications'

/** STYLE */
import * as UseStyled from 'features/setting/doctor-fee-managements/template-doctor-fee/style'
import { colors } from 'constants/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { numberFormat } from 'utils/app.utils'
import { swalCustom } from 'component/Alert/Swal'


type errorMessage = {
  EMPTY_DF_NAME?: string
  EMPTY_DF_AMOUNT?: string
  EMPTY_LAB_AMOUNT?: string
}

export enum DF_UNIT {
  'PERCENT' = 'PERCENT',
  'BATH' = 'BAHT'
}

export default function FormTemplateCreditFee() {
  const { t } = useTranslation()
  const { id: templateCreditFeeId }: any = useParams()
  const history = useHistory()

  const units: DF_UNIT[] = [DF_UNIT.PERCENT, DF_UNIT.BATH]
  const [operativeTypes, setOperativeTypes] = useState([]);
  const [templateOperativeId, setTemplateOperativeId] = useState(0)
  const [templateOperatives, setTemplateOperatives] = useState<TemplateCreditFee>({
    templateCreditFeeName: '',
    amount: 0,
    isMain: '0',
    templateCreditFeeOperativeTypes: [],
    templateClinicFeeAmount: 0
  });

  const [status, setStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState<errorMessage>({});

  const [creditFee, setCreditFee] = useState<number>(0)

  const handleAllFee = () => {

    if (Number(creditFee)) {
      const name = 'templateClinicFeeAmount'
      const value = Number(creditFee) - Number(templateOperatives.amount)
      setTemplateOperatives({ ...templateOperatives, [name]: value })
    } else {
      handleChangeTemplateOperative('clear', operativeTypes, templateOperatives, 0)
    }
  }

  useEffect(() => {
    handleAllFee()
  }, [creditFee, templateOperatives.amount])

  const loadTemplateOperative = useCallback((templateCreditFeeId?: number) => {
    let templateCreditFee = {}
    if (templateCreditFeeId) {
      TemplateCreditFeeApi.findById(templateCreditFeeId)
        .then(({ data }) => {
          setTemplateOperativeId(data.templateCreditFeeId)
          setStatus(data.status)
          templateCreditFee = {
            templateCreditFeeName: data.templateCreditFeeName,
            amount: data.amount,
            isMain: data.isMain,
            templateCreditFeeOperativeTypes: []
          }
          setCreditFee(data?.creditFee || 0)
        })
        .catch((e) => { return })
    }

    TemplateCreditFeeApi.findAllWithOperatives(templateCreditFeeId)
      .then(({ data }) => {
        setOperativeTypes(data)
        if (!_.isEmpty(data)) handleChangeTemplateOperative(templateCreditFeeId ? 'edit' : 'add', data, templateCreditFee, '')
      })
      .catch((e) => { return })
  }, [])

  const handleChangeTemplateOperative = (type: 'add' | 'edit' | 'updateDF' | 'updateLab' | 'clear', operativesData: any, value?: any, valueUpdate?: any) => {
    const operativeTypes: any = []
    const newCreditBranch = type === 'clear' ? 0 : templateOperatives.templateClinicFeeAmount
    _.map(operativesData, (optType, index) => {
      let newDataOperativeType = {}
      const operative: any = []
      _.map(optType.operatives, (opt, indexOpt) => {
        let newDataOperative = {}
        newDataOperative = {
          operativeId: opt.operativeId,
          creditAmount: type === 'clear' ? 0 : valueUpdate || Number(opt.templateCreditFeeOperatives?.creditAmount) || 0,
          creditBranch: type === 'clear' ? 0 : type === 'updateDF' ? templateOperatives.templateClinicFeeAmount : opt.templateCreditFeeOperatives?.creditBranch,
          creditUnit: opt.templateCreditFeeOperatives?.creditUnit || DF_UNIT.PERCENT
        }
        operative.push(newDataOperative)
      })
      newDataOperativeType = {
        operativeTypeId: optType.operativeTypeId,
        creditAmount: type === 'clear' ? 0 : valueUpdate || Number(optType.templateCreditFeeOperativeTypes?.creditAmount) || 0,
        creditBranch: type === 'clear' ? 0 : type === 'updateDF' ? templateOperatives.templateClinicFeeAmount : optType.templateCreditFeeOperativeTypes?.creditBranch,
        templateCreditFeeOperatives: operative
      }
      operativeTypes.push(newDataOperativeType)
    })
    const rawTemplateOperatives: TemplateCreditFee = {
      templateCreditFeeName: value.templateCreditFeeName || '',
      amount: type === 'updateDF' ? Number(valueUpdate) : type === 'clear' ? 0 : Number(value.amount) || 0,
      isMain: value.isMain || '0',
      templateClinicFeeAmount: newCreditBranch,
      templateCreditFeeOperativeTypes: operativeTypes
    }

    setTemplateOperatives(rawTemplateOperatives)
  }

  const clearErrorMessage = () => {
    setErrorMessage({})
  }

  const onSubmit = () => {
    if (!templateOperatives.templateCreditFeeName || (!templateOperatives.amount && templateOperatives.amount !== 0)) {
      return setErrorMessage({
        EMPTY_DF_NAME: !templateOperatives.templateCreditFeeName ? t('TEMPLATE_CREDIT_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        EMPTY_DF_AMOUNT: (!templateOperatives.amount && templateOperatives.amount !== 0) ? t('TEMPLATE_CREDIT_FEE.MESSAGE.ERROR.EMPTY_DF_AMOUNT') : '',
      })
    }

    const newTemplateOperatives = { ...templateOperatives, creditFee: Number(creditFee) }

    TemplateCreditFeeApi.create(newTemplateOperatives)
      .then(() => {
        notiSuccess(t('TEMPLATE_CREDIT_FEE.MESSAGE.SUCCESS.CREATE'), '', null, null)
        history.push(routeName.templateCreditFee)
      })
      .catch((e) => {
        if (e.response.data.message === 'IS_MAIN_TEMPLATE_ALREADY_EXISTS') return notiError(t('TEMPLATE_CREDIT_FEE.MESSAGE.ERROR.IS_MAIN_TEMPLATE_ALREADY_USE'), '', null, null, '')
        else if (e.response.data.message === 'IS_DUPLICATE_TEMPLATE_CREDIT_FEE_NAME') return notiError(t('TEMPLATE_CREDIT_FEE.MESSAGE.ERROR.IS_DUPLICATE_TEMPLATE_CREDIT_FEE_NAME'), '', null, null, '')
        else return notiError(e.response.data.message, '', null, null, '')
      })
  }

  const onSubmitEdit = () => {
    if (!templateOperatives.templateCreditFeeName || (!templateOperatives.amount && templateOperatives.amount !== 0)) {
      return setErrorMessage({
        EMPTY_DF_NAME: !templateOperatives.templateCreditFeeName ? t('TEMPLATE_CREDIT_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        EMPTY_DF_AMOUNT: (!templateOperatives.amount && templateOperatives.amount !== 0) ? t('TEMPLATE_CREDIT_FEE.MESSAGE.ERROR.EMPTY_DF_AMOUNT') : '',
      })
    }
    swalCustom(
      `${t('TEMPLATE_CREDIT_FEE.MESSAGE.CAN_UPDATE')} \n"${templateOperatives.templateCreditFeeName}"`,
      `${t('TEMPLATE_CREDIT_FEE.MESSAGE.CAN_USE')}`,
      (res: any) => {
        if (res) {
          const rawTemplateOperatives = { ...templateOperatives, status: status, creditFee: Number(creditFee) }
          TemplateCreditFeeApi.update(templateOperativeId, rawTemplateOperatives)
            .then(() => {
              notiSuccess(t('TEMPLATE_CREDIT_FEE.MESSAGE.SUCCESS.UPDATE'), '', null, null)
              history.push(routeName.templateCreditFee)
            })
            .catch((e) => {
              if (e.response.data.message === 'IS_MAIN_TEMPLATE_ALREADY_EXISTS') return notiError(t('TEMPLATE_CREDIT_FEE.MESSAGE.ERROR.IS_MAIN_TEMPLATE_ALREADY_USE'), '', null, null, '')
              else if (e.response.data.message === 'IS_DUPLICATE_TEMPLATE_CREDIT_FEE_NAME') return notiError(t('TEMPLATE_CREDIT_FEE.MESSAGE.ERROR.IS_DUPLICATE_TEMPLATE_CREDIT_FEE_NAME'), '', null, null, '')
              else return notiError(e.response.data.message, '', null, null, '')
            })
        }
      },
      'question',
      {
        confirmButton: {
          confirmButtonText: t('TEMPLATE_CREDIT_FEE.BUTTON.CONFIRM')
        },
        cancelButton: {
          cancelButtonText: t('TEMPLATE_CREDIT_FEE.BUTTON.CANCEL')
        }
      }
    )


  }

  const handleChangeDefault = (event: any) => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'amount') {
      let stringValue = value === '' ? '0' : String(value).replace(/[^0-9.]/g, '') as any
      const strValueSplit = stringValue.split('.')
      if (strValueSplit.length === 1) {
        stringValue = Number(stringValue).toString()
      }
      if (strValueSplit.length >= 2 && strValueSplit[1].length > 3) {
        stringValue = stringValue.slice(0, -1)
      }
      if (Number(stringValue) > creditFee) {
        stringValue = creditFee
      }
      value = stringValue
      event.target.value = Number(value)
      if (String(value).length === 1) {
        event.target.focus()
        event.target.type = 'string'
        event.target.setSelectionRange(String(value).length, String(value).length)
        event.target.type = 'number'
      }
    }

    if (name === 'isMain') setTemplateOperatives({ ...templateOperatives, [name]: value === '0' ? '1' : '0' })
    else {
      setTemplateOperatives({ ...templateOperatives, [name]: value })
    }
    clearErrorMessage()
  }

  const onUpdateDefaultDF = () => {
    const dfValue = templateOperatives.amount
    handleChangeTemplateOperative('updateDF', operativeTypes, templateOperatives, dfValue)
  }


  const onChangeValueOperativeType = (event: any, index: number) => {
    const name = event.target.name
    let value = event.target.value
    value = value === '' ? 0 : String(value).replace(/[^0-9.]/g, '')
    let stringValue = value === '' ? '0' : String(value).replace(/[^0-9.]/g, '') as any
    const strValueSplit = stringValue.split('.')
    if (strValueSplit.length === 1) {
      stringValue = Number(stringValue).toString()
    }
    if (strValueSplit.length >= 2 && strValueSplit[1].length > 3) {
      stringValue = stringValue.slice(0, -1)
    }
    if (Number(stringValue) > creditFee) {
      stringValue = creditFee
    }
    const number = Math.floor(Number(value) * 1000) / 1000
    value = Number(number) > creditFee ? creditFee : Number(number)
    event.target.value = Number(value)
    if (String(value).length === 1) {
      event.target.focus()
      event.target.type = 'string'
      event.target.setSelectionRange(String(value).length, String(value).length)
      event.target.type = 'number'
    }


    const operativeType = _.merge({}, templateOperatives)
    let selectOperative = _.merge({}, operativeType.templateCreditFeeOperativeTypes[index])

    if (name === 'creditAmount') selectOperative = { ...selectOperative, [name]: stringValue, creditAmount: stringValue, creditBranch: Number(creditFee) - Number(stringValue) }
    else selectOperative = { ...selectOperative, [name]: value }

    _.map(selectOperative.templateCreditFeeOperatives, (opt: any) => {
      opt[name] = value
      opt.creditUnit = DF_UNIT.PERCENT
      if (name === 'creditAmount') {
        opt.creditAmount = Number(value)
        opt.creditBranch = Number(creditFee) - Number(value)
      }
    })

    operativeType.templateCreditFeeOperativeTypes[index] = selectOperative
    setTemplateOperatives(operativeType)
  }

  const onChangeValueOperative = (event: any, typeIndex: number, index: number, operativePrice: number) => {
    const name = event.target.name
    let value = event.target.value
    let stringValue = value === '' ? '0' : String(value).replace(/[^0-9.]/g, '') as any
    const strValueSplit = stringValue.split('.')
    if (strValueSplit.length === 1) {
      stringValue = Number(stringValue).toString()
    }
    if (strValueSplit.length >= 2 && strValueSplit[1].length > 3) {
      stringValue = stringValue.slice(0, -1)
    }
    if (Number(stringValue) > creditFee) {
      stringValue = creditFee
    }
    const number = Math.floor(Number(value) * 1000) / 1000
    event.target.value = Number(value)
    if (String(value).length === 1) {
      event.target.focus()
      event.target.type = 'string'
      event.target.setSelectionRange(String(value).length, String(value).length)
      event.target.type = 'number'
    }
    value = number
    if (name !== 'creditUnit') {
      value = value === '' ? 0 : String(value).replace(/[^0-9.]/g, '')
      value = Number(value)
      if (name === 'creditAmount') onChangeValueCreditAmount(typeIndex, index, value, operativePrice, stringValue)
    } else onChangeValueCreditUnit(typeIndex, index, value, operativePrice)
  }

  const calculateByPercent = (price: number, percent: number) => {
    return (Number(price) * Number(percent)) / 100
  }

  const onChangeValueCreditAmount = (typeIndex: number, index: number, value: number, operativePrice: number, strValue: any) => {
    const operativeType = _.merge({}, templateOperatives)
    let selectOperative = _.merge({}, operativeType.templateCreditFeeOperativeTypes[typeIndex].templateCreditFeeOperatives[index])
    if (selectOperative.creditUnit === DF_UNIT.PERCENT) {
      let val;

      switch (true) {
        case strValue === '0.0' || strValue === '0.00':
          val = strValue;
          break;
        case Number(value) > creditFee:
          val = creditFee;
          break;
        default:
          val = value;
      }

      selectOperative = { ...selectOperative, creditAmount: val, creditBranch: Number(creditFee) - Number(val) }
    } else {
      selectOperative = { ...selectOperative, creditAmount: value }
    }

    operativeType.templateCreditFeeOperativeTypes[typeIndex].templateCreditFeeOperatives[index] = selectOperative
    setTemplateOperatives(operativeType)
  }

  const onChangeValueCreditUnit = (typeIndex: number, index: number, value: DF_UNIT, operativePrice: number) => {
    const operativeType = _.merge({}, templateOperatives)
    const selectOperativeType = _.merge({}, operativeType.templateCreditFeeOperativeTypes[typeIndex])
    let selectOperative = _.merge({}, selectOperativeType.templateCreditFeeOperatives[index])
    if (value === DF_UNIT.PERCENT) {
      selectOperative = {
        ...selectOperative,
        creditUnit: value,
        creditAmount: selectOperativeType.creditAmount,
      }
    } else {
      selectOperative = {
        ...selectOperative,
        creditUnit: value,
        creditAmount: calculateByPercent(operativePrice, selectOperativeType.creditAmount),
      }
    }
    operativeType.templateCreditFeeOperativeTypes[typeIndex].templateCreditFeeOperatives[index] = selectOperative
    setTemplateOperatives(operativeType)
  }

  useEffect(() => {
    loadTemplateOperative(templateCreditFeeId)
  }, []);

  const headCells = [
    { id: 'message', disablePadding: false, label: t('') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.CATEGORY') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.PRICE'), align: 'right' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.DF_DOCTOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.DF_BRANCH'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_CREDIT_FEE.CELLS.UNIT'), align: 'center' },
  ]

  return (
    <div className={`container-card h-100 overflow-hidden`}>
      <div className="d-flex flex-column h-100 overflow-auto">
        <HeaderLabel
          text={templateCreditFeeId ? t(`TEMPLATE_CREDIT_FEE.TITLE.EDIT_TEMPLATE_DEFAULT`) : t(`TEMPLATE_CREDIT_FEE.TITLE.ADD_TEMPLATE_DEFAULT`)}
          navigator={{ previousTo: routeName.templateCreditFee, previousTitle: t(`TEMPLATE_CREDIT_FEE.TITLE.DEFAULT`), currentTitle: templateCreditFeeId ? t(`TEMPLATE_CREDIT_FEE.TITLE.EDIT_TEMPLATE_DEFAULT`) : t(`TEMPLATE_CREDIT_FEE.TITLE.ADD_TEMPLATE_DEFAULT`) }}
          goBack={routeName.templateCreditFee}
          onSubmit={templateCreditFeeId ? onSubmitEdit : onSubmit}
        />
        <Card className="border shadow-sm mx-4 mb-4 card">
          <Card.Body>
            <Grid container>
              <Grid item lg={6} className="w-100">
                <Box className="mt-2">
                  <InputTextField
                    key={'templateCreditFeeName'}
                    name={'templateCreditFeeName'}
                    label={t('TEMPLATE_CREDIT_FEE.FORM.TEMPLATE_NAME')}
                    value={templateOperatives.templateCreditFeeName}
                    onchange={handleChangeDefault}
                    required={true}
                    helperText={errorMessage.EMPTY_DF_NAME ?? ''}
                  />
                </Box>
              </Grid>
              <Grid item className="w-100">
                <Box className='mt-3'>
                  <InputCheckbox
                    label={t('TEMPLATE_CREDIT_FEE.FORM.USE_AS_DEFAULT_BRANCH')}
                    name={'isMain'}
                    checked={templateOperatives.isMain === '1'}
                    value={templateOperatives.isMain}
                    onChange={handleChangeDefault} />
                </Box>
              </Grid>
              <Grid item className="w-100">
                <Box className='d-flex align-items-center mt-4'>
                  <Typography className='mr-2' sx={{ fontSize: '18px', fontWeight: 600 }}>{t('TEMPLATE_CREDIT_FEE.TITLE.MANAGE')}</Typography>
                  <ButtonInfoSimple text={t('TEMPLATE_CREDIT_FEE.TITLE.TOOLTIP')} />
                </Box>
                <Box className='pl-md-3'>
                  <Grid container spacing={2} className="mt-0">
                    <Grid item xs={2} lg={1} className='w-100'>
                      <Typography className='pt-sm-2'>{t('TEMPLATE_CREDIT_FEE.FORM.CREDIT_VALUE')}</Typography>
                    </Grid>
                    <Grid item xs={10} lg={11} className='w-100'>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3} lg={2} className='w-100'>
                          <InputTextField
                            key={'amount'}
                            name={'amount'}
                            label={t('TEMPLATE_CREDIT_FEE.TITLE.ALL')}
                            value={creditFee}
                            onchange={(event) => {
                              let newInput = event.target.value
                              if (newInput >= 100) {
                                newInput = 100
                              }
                              setCreditFee(newInput)
                            }}
                            required={true}
                            hideControl
                            helperText={errorMessage.EMPTY_DF_AMOUNT ?? ''}
                            type='number'
                          />
                        </Grid>
                        <Grid item xs={3} sm={3} lg={2} className='w-100'>
                          <InputTextField
                            key={'amount'}
                            name={'amount'}
                            label={t('TEMPLATE_CREDIT_FEE.FORM.DOCTOR_SECTION')}
                            value={templateOperatives.amount}
                            disabled={!creditFee}
                            onchange={handleChangeDefault}
                            required={true}
                            hideControl
                            helperText={errorMessage.EMPTY_DF_AMOUNT ?? ''}
                            type='number'
                          />
                        </Grid>
                        <Grid item xs={3} sm={3} lg={2} className='w-100 pr-sm-2'>
                          <BasicSelect
                            name="defaultUnit"
                            labelId="lb-defaultUnit"
                            selectId="sl-defaultUnit"
                            label=""
                            value={DF_UNIT.PERCENT}
                            options={
                              _.map(units, (unit: string, index: number) => {
                                return (
                                  <MenuItem key={index} value={unit}>
                                    {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                  </MenuItem>
                                )
                              })
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3} sm={3} lg={2} className='w-100'>
                          <InputTextField
                            key={'templateClinicFeeAmount'}
                            name={'templateClinicFeeAmount'}
                            label={t('TEMPLATE_CREDIT_FEE.FORM.CLINIC_FEE_AMOUNT')}
                            value={templateOperatives.templateClinicFeeAmount || 0}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} className='w-100 pl-md-4'>
                          <ButtonCustom textButton={t('TEMPLATE_CREDIT_FEE.FORM.BUTTON_UPDATE_VALUE')} onClick={onUpdateDefaultDF} style={{ margin: 0 }} btnStyle={{ fontSize: 16, height: '43px' }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            <UseStyled.ResponsiveTable section={'default'}>
              <TableCustom
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => {
                  return
                }}
                setPageLimit={() => {
                  return
                }}
                setPage={() => {
                  return
                }}
                headCells={headCells}
                rowsData={
                  _.map(operativeTypes, (temp, index) => {
                    return (
                      <RenderData
                        key={index}
                        no={index}
                        row={temp}
                        headCells={headCells}
                        units={units}
                        templateOperative={templateOperatives?.templateCreditFeeOperativeTypes[index]}
                        onChangeValueOperativeType={onChangeValueOperativeType}
                        onChangeValueOperative={onChangeValueOperative}
                        creditFee={creditFee}
                      />
                    )
                  })
                }
                hidePagination
                tableFixedWidth
              />
            </UseStyled.ResponsiveTable>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

function RenderData(props: any) {
  const { t } = useTranslation()
  const { row, no, headCells, units, templateOperative } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell align='center'>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className="p-0"
            sx={{ backgroundColor: 'transparent !important' }}>
            {open ? (
              <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20, transform: 'rotate(180deg)' }} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20, }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.operativeTypeName}
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          <InputTextField
            key={`creditAmount`}
            name={`creditAmount`}
            label={''}
            value={templateOperative?.creditAmount}
            onchange={(e) => {
              if (props.onChangeValueOperativeType) props.onChangeValueOperativeType(e, no)
            }}
            disabled={!props?.creditFee || open}
            hideControl
            type='number'
          />
        </TableCell>
        <TableCell>
          <InputTextField
            label={''}
            value={templateOperative?.creditBranch || 0}
            disabled
            type="number"
            hideControl
          />
        </TableCell>
        <TableCell>
          <BasicSelect
            key={`defaultUnit`}
            labelId="lb-defaultUnit"
            selectId="sl-defaultUnit"
            label=""
            value={DF_UNIT.PERCENT}
            options={
              _.map(units, (unit: string, index: number) => {
                return (
                  <MenuItem key={index} value={unit}>
                    {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                  </MenuItem>
                )
              })
            }
            onchange={() => { return }}
            disabled
          />
        </TableCell>
      </TableRow>
      <TableRow className='section-collapse'>
        <TableCell colSpan={_.size(headCells)} className="p-0 border-0">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <UseStyled.ResponsiveTable section='collapse'>
              <TableCustom
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => { return }}
                setPageLimit={() => { return }}
                setPage={() => { return }}
                headCells={headCells}
                rowsData={
                  _.map(row.operatives, (opt, indexOpt) => {

                    return (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          {opt.operativeName}
                        </TableCell>
                        <TableCell align='right'>{numberFormat(opt.salePrice || 0)}</TableCell>
                        <TableCell>
                          <InputTextField
                            key={`creditAmount`}
                            name={`creditAmount`}
                            label={''}
                            value={templateOperative?.templateCreditFeeOperatives[indexOpt]?.creditAmount}
                            disabled={!props?.creditFee}
                            onchange={(e) => {
                              if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                            }}
                            hideControl
                            type='number'
                          />
                        </TableCell>
                        <TableCell>
                          <InputTextField
                            label={''}
                            value={templateOperative?.templateCreditFeeOperatives[indexOpt]?.creditBranch || 0}
                            disabled
                            type="number"
                            hideControl
                          />
                        </TableCell>
                        <TableCell>
                          <BasicSelect
                            key={`creditUnit`}
                            name={`creditUnit`}
                            labelId="lb-defaultUnit"
                            selectId="sl-defaultUnit"
                            label=""
                            value={DF_UNIT.PERCENT}
                            options={
                              <MenuItem key={0} value={DF_UNIT.PERCENT}>
                                {'%'}
                              </MenuItem>

                            }
                            onchange={(e) => {
                              if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
                hidePagination
                tableFixedWidth
              />
            </UseStyled.ResponsiveTable>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}