import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableSortLabel, Tooltip, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'
import { dateTimeToView } from 'utils/date.utils'

export interface DoctorFeeComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
  componentRef: any
  filterTable: any
}

interface DoctorFeeIF {
  amountAdvance: number
  amountCash: number
  amountCredit: number
  amountDebit: number
  amountPaid: number
  amountPaidAdvance: number
  amountPaidOverdue: number
  amountTransfer: number
  amountTreatmentRight: number
  amountVoucher: number
  amountBalanceBefore: number
  branchCnNumber: string
  cancelBy: string
  cancelRemark: string
  cfAmountCredit: number
  cnNumber: string
  code: string
  createdAt: string
  creditCardFee: number
  creditCost: number
  date: string
  dfAmountCredit: number
  doctorFullname: string
  itemName: string
  overdue: number
  patientFullname: string
  paymentNumber: string
  statusOrder: string
  price: number
  refItemId: number
  remark: string
  staff: string
  total: number
}
export default function BalanceList(props: DoctorFeeComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap' },
    { id: '2', disablePadding: false, labelKey: 'Date', align: 'center', class: 'text-nowrap' },
    { id: '3', disablePadding: false, labelKey: 'Receipt Status', align: 'center', class: 'text-nowrap' },
    { id: '4', disablePadding: false, labelKey: 'Receipt No.', align: 'center', class: 'text-nowrap' },
    { id: '5', disablePadding: false, labelKey: 'CN No.', align: 'center', class: 'text-nowrap' },
    { id: '6', disablePadding: false, labelKey: 'Pt No.', align: 'center', class: 'text-nowrap' },
    { id: '7', disablePadding: false, labelKey: 'Pt. Name', align: 'center', class: 'text-nowrap' },
    { id: '8', disablePadding: false, labelKey: 'Dentist', align: 'center', class: 'text-nowrap' },
    { id: '9', disablePadding: false, labelKey: 'Code', align: 'center', class: 'text-nowrap' },
    { id: '10', disablePadding: false, labelKey: 'รายการ', align: 'center', class: 'text-nowrap' },
    { id: '11', disablePadding: false, labelKey: 'ยอดรวมสุทธิค่ารักษา', align: 'center', class: 'text-nowrap' },
    { id: '12', disablePadding: false, labelKey: 'จ่ายค่ารักษา', align: 'center', class: 'text-nowrap' },
    { id: '13', disablePadding: false, labelKey: 'ยอดที่ค้างชำระ', align: 'center', class: 'text-nowrap' },
    { id: '14', disablePadding: false, labelKey: 'จ่ายค้างชำระ', align: 'center', class: 'text-nowrap' },
    { id: '15', disablePadding: false, labelKey: 'เงินสด', align: 'center', class: 'text-nowrap' },
    { id: '16', disablePadding: false, labelKey: 'เงินโอน', align: 'center', class: 'text-nowrap' },
    { id: '17', disablePadding: false, labelKey: 'เดบิต', align: 'center', class: 'text-nowrap' },
    { id: '18', disablePadding: false, labelKey: 'เครดิต', align: 'center', class: 'text-nowrap' },
    { id: '19', disablePadding: false, labelKey: 'voucher', align: 'center', class: 'text-nowrap' },
    { id: '20', disablePadding: false, labelKey: 'สิทธิการรักษา', align: 'center', class: 'text-nowrap' },
    { id: '21', disablePadding: false, labelKey: 'Clear Advance', align: 'center', class: 'text-nowrap' },
    { id: '22', disablePadding: false, labelKey: 'Balance', align: 'center', class: 'text-nowrap' },
    { id: '23', disablePadding: false, labelKey: 'Staff', align: 'center', class: 'text-nowrap' },
    { id: '24', disablePadding: false, labelKey: 'Remark', width: '350px', align: 'center', class: 'text-wrap' },
    { id: '25', disablePadding: false, labelKey: 'Cancel  Remark', width: '350px !important', align: 'center', class: 'text-wrap' },
    { id: '26', disablePadding: false, labelKey: 'Cancel By', align: 'center', class: 'text-nowrap' }
  ]

  if (!snType) {
    // headCells.splice(3, 1)
  }
  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const doctorFee: DoctorFeeIF = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: dateTimeToView(doctorFee.createdAt), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: t(`REPORT.BILL_STATUS.${doctorFee.statusOrder}`), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFee.paymentNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFee.cnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFee.branchCnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFee.patientFullname, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFee.doctorFullname, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, format: 'CODE', label: doctorFee.code, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFee.itemName, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.total, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountPaid, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountBalanceBefore, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountPaidOverdue, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountCash, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountTransfer, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountDebit, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountCredit, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountVoucher, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountTreatmentRight, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.amountAdvance, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(doctorFee.overdue, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFee.staff, class: 'text-nowrap' },
          {
            option: 'COMPONENT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16, width: '350px', },
            component: <Tooltip title={doctorFee.cancelRemark} placement="top">
              <UseStyled.StylesRemark style={{ wordWrap: 'break-word', width: '350px' }}>{doctorFee.remark || '-'}</UseStyled.StylesRemark>
            </Tooltip>,
            class: 'text-wrap'
          },
          {
            option: 'COMPONENT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16, width: '350px !important', },
            component: <Tooltip title={doctorFee.cancelRemark} placement="top">
              <UseStyled.StylesRemark style={{ wordWrap: 'break-word', width: '350px' }}>{doctorFee.cancelRemark || '-'}</UseStyled.StylesRemark>
            </Tooltip>,
            class: 'text-wrap'
          },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFee.cancelBy || '-', class: 'text-nowrap' }
        ]
      }
      // if (!snType) {
      //   objRenderData.columns.splice(3, 1)
      // }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: 9 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaid') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountBalanceBefore') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaidOverdue') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCash') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTransfer') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountDebit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCredit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountVoucher') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTreatmentRight') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'overdue') || 0), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 4 }
        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.data, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.BALANCE`)}
          componant={
            <>
              <Box>
                <table>
                  <tr>
                    {props.filterTable.map((filter: any, index: number) => (
                      <TableSortLabel key={index}>
                        {filter.label !== t('REPORT.FILTER.DOCTOR') && (
                          <Box>
                            <td className="mr-2" style={{ textAlign: 'left', fontSize: 16, fontWeight: 800 }}>
                              {t(filter.label)} :{' '}
                            </td>
                            <td style={{ textAlign: 'left', fontSize: 16, fontWeight: 500 }}>{filter.value}</td>
                          </Box>
                        )}
                      </TableSortLabel>
                    ))}
                  </tr>
                </table>
              </Box>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(props.dataExport, (d: any, index: number) => {
                      return renderData(d, index, true, 'data')
                    })}
                  </tbody>
                </table>
              </UseStyled.Styles>
            </>
          }
        />
      </div>
    </>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  )
}
