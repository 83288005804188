import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material';
import { colors } from 'constants/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: 'calc(100% - 80px)',
    backgroundColor: colors.white
  },
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  titleSecond: {
    fontSize: '16px',
    textAlign: 'center'
  },
  columeHover: {
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.extraLightGray} !important`
    }
  },
  inputDisable: {
    backgroundColor: colors.themeThirdColor,
  },
  tableBody: {
    '&.sm-data-table': {
      border: `1px solid ${colors.black03} !important`,
      height: 'auto',
    }
  },
  iconButton: {
    '&.MuiIconButton-root': {
      padding: '94% !important',

    }
  },
  inputSelect: {
    '&.MuiOutlinedInput-notchedOutline': {
      height: '32px !important'
    }
  }

}))

export default useStyles

export const CustomTable = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        verticalAlign: 'middle',
        paddingTop: 10,
        paddingBottom: 10
      },
      '.row-add-new-row': {
        '.btn-add-new-row': {
          padding: '0 0 0 14px',
          fontSize: 16,
          color: colors.themeSecondColor,
          backgroundColor: 'transparent !important',
          fontWeight: 400,
          span: {
            fontSize: 20,
            color: colors.themeSecondColor
          },
          '&:hover': {
            fontWeight: 600
          },
          '&.Mui-disabled, &.Mui-disabled span': {
            color: colors.disabledGray,
          }
        },
        '.MuiTableCell-root:hover': {
          cursor: 'pointer',
          '.btn-add-new-row': {
            fontWeight: 600
          }
        }
      }
    }
  }
}))

export const NetPrice = styled(Box)(({ theme }) => ({
  height: 48,
  fontSize: 20,
  color: colors.themeMainColor,
  backgroundColor: colors.themeMainColor06,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18
  }
}))