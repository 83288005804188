import { ColorThemeInterface, SidebarInterface, TemplateImageInterface, TemplatePickRoomInterface, SpecificIconInterface } from "constants/theme"
import { dentalSidebarIcon } from "constants/theme/dental"
import { Icons, imgs, icons, favicon, pickBackground, loginBackground, logo, watermark } from "constants/images"
import { clinicLocaleTH, clinicLocaleEN } from "locales/plus/clinic.locale"

export const plusOperativeType = [
    '#FFD0C2',
    '#FFC1C1',
    '#FFC5D9',
    '#FFA0E3',
    '#FFAAB2',
    '#F3ACC7',

    '#BEF6FF',
    '#92ECF6',
    '#94DFFF',
    '#A8E1FF',
    '#B2D9F8',
    '#A8BFFF',

    '#FFF0B2',
    '#F9ED8C',
    '#FAF37C',
    '#FFE782',
    '#FFD589',
    '#FFBB77',

    '#F4BBBB',
    '#E4A4A4',
    '#BDD2F5',
    '#97BDFD',
    '#EA9191',
    '#DDA2DD',

    '#FFD1F6',
    '#F6BAFF',
    '#DEA9FE',
    '#D2B9FA',
    '#C49BFA',
    '#B37CF4',

    '#A2FAE9',
    '#EDFF8D',
    '#D5F5B0',
    '#C5F195',
    '#B1F1B4',
    '#A9EDD2',

    '#FFCABA',
    '#EFC0AE',
    '#ECAD80',
    '#F0A293',
    '#D9AA9A',
    '#FFB8A3'
]

export const plusColor: ColorThemeInterface = {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000000',

    themeMainColor: '#2F4269',
    themeSecondColor: '#8C68D4',
    themeSecondDarkColor: '#7c56c8',
    themeThirdColor: '#B00020',

    statusActive: '#1CB99A',
    statusInactive: '#B00020',
    statusSuccess: '#68BB86',
    statusDangerColor: '#DC3545',
    statusWarning: '#F88F41',

    extraLightBlue: '#F1F9FF',
    borderHeaderCard: '#F3F1F1',
    bgBase: '#F9FAFC',
    highlightSelect: '#E7F8F5',
    lightYellow: '#FFF7D4',
    blue: '#007BFF',
    extraLightSky: '#c9dae1',
    sky: '#469ACC',
    secondSky: '#87adbd',
    orange: '#E67E22',

    extraLightGray: '#F5F5F5',
    lightGray: '#E5E5E5',
    gray: '#6C757D',
    darkGray: '#343A40',

    disabledLightGray: '#C5C5C5',
    disabledGray: '#9E9E9E',
    borderInput: '#D3D3D4',

    textExtraLightGray: '#929292',
    textLightGray: '#707070',
    textPrimary: '#29302E',

    navbar: 'linear-gradient(270.17deg, rgba(51, 102, 154, 0.1) -20.32%, rgba(255, 245, 255, 0.6) -20.31%, rgba(156, 112, 245, 0.2) 15.37%, rgba(194, 135, 217, 0.25) 53.06%, rgba(255, 255, 255, 0.6) 106.63%)',
    navbarLinkActive: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 1%, rgba(242, 242, 242, 0.8) 171%)',

    white18: 'rgba(255, 255, 255, 0.18)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white80: 'rgba(255, 255, 255, 0.8)',
    white90: 'rgba(255, 255, 255, 0.9)',
    black03: 'rgba(0, 0, 0, 0.03)',
    black05: 'rgba(0, 0, 0, 0.05)',
    black08: 'rgba(0, 0, 0, 0.08)',
    black10: 'rgba(0, 0, 0, 0.10)',
    black12: 'rgba(0, 0, 0, 0.12)',
    black16: 'rgba(0, 0, 0, 0.16)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black25: 'rgba(0, 0, 0, 0.25)',
    black30: 'rgba(0, 0, 0, 0.3)',
    black60: 'rgba(0, 0, 0, 0.6)',
    black75: 'rgba(0, 0, 0, 0.75)',

    themeMainColor06: 'rgba(47, 66, 105, 0.06)',
    themeMainColor09: 'rgba(47, 66, 105, 0.9)',
    themeSecondColor06: 'rgba(140, 104, 212, 0.06)',
    themeSecondColor10: 'rgba(140, 104, 212, 0.1)',
    themeSecondColor15: 'rgba(140, 104, 212, 0.15)',
    themeSecondColor30: 'rgba(140, 104, 212, 0.3)',
    themeSecondColor80: 'rgba(140, 104, 212, 0.8)',
    statusInactive10: 'rgba(176, 0, 32, 0.1)',

    lightThemeMain: 'rgba(220, 223, 230, 0.9)',
    backdrop: 'rgba(35, 31, 32, 0.3)',
    filterDropdown: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.53), rgba(242, 242, 242, 0.34))',

    // service type
    servicePoint: {
        panel: `linear-gradient(174deg, rgba(242, 242, 242, 0.5) -9%, rgba(233, 227, 227, 0.2) 98%)`,
        counter: 'linear-gradient(149.28deg, #EBE0FA -46.34%, rgba(136, 109, 171, 0.898) 27.2%, #75589A 98.99%)',
        roomServices: 'linear-gradient(152.61deg, #F1F5F9 -114.18%, #72A9BE 6.61%, #387AB6 111.17%)',
        services: '#F5F5F5',
        payment: 'linear-gradient(150.94deg, #F1F5F9 -91.79%,#1EB29A 45.11%,  rgba(51, 102, 154, 0.502) 163.63%)',
        lab: `linear-gradient(149.28deg, #EBE0FA -46.34%, rgba(136, 109, 171, 0.898) 27.2%, #75589A 98.99%)`,
        label: {
            counter: 'linear-gradient(149.28deg, #EBE0fA -46.34%, rgba(136, 109, 171, 0.898) 27.2%, #75589A 98.99%)',
            roomServices: 'linear-gradient(152.61deg, #F1F5F9 -114.18%, #72A9BE 6.61%, #387AB6 111.17%)',
            payment: 'linear-gradient(150.94deg, #F1F5F9 -91.79%,#1EB29A 45.11%,  rgba(51, 102, 154, 0.502) 163.63%)',
        }
    },

    // member ranking
    memberRanking: {
        rankingBronze: '#EA4D2C',
        rankingSilver: '#C1C4C5',
        rankingGold: '#FFBE0D',
        rankingPlatinum: '#3A3985',
        rankingDiamond: '#5DBFEB',
        background: {
            rankingBronze: 'linear-gradient(to bottom, #FFA62E, #EA4D2C 127%)',
            rankingSilver: 'linear-gradient(to bottom, #C1C4C5, #7A7A7D 127%)',
            rankingGold: 'linear-gradient(179deg, #FFBE0D -2%, #FF9201 97%)',
            rankingPlatinum: 'linear-gradient(176deg, #3499FF 14%, #3A3985 109%)',
            rankingDiamond: 'linear-gradient(to bottom, #64E8DE -24%, #5DBFEB)',
        }
    },

    // status
    appointmentStatus: {
        statusNormal: '#9E9E9E',
        statusCancel: '#FB5252',
        statusUnConnect: '#FFCB1E',
        statusChange: '#33669A',
        statusEnter: '#1CB99A',
        statusArrived: '#7BB6C1',
        statusUnArrived: '#D46532',
        statusWaitPayment: '#FFAF36',
        statusPayment: '#C66B3F',
        statusService: '#9932CC',
        statusCounterCheckIn: '#7BB6C1',
        statusDone: '#BCBEC0',
        statusCancelVisit: '#BCBEC0'
    },

    doctorScheduleStatus: {
        leave: '#E2A70619',
        missing: '#FB52521A',
        solid: {
            leave: '#E2A706',
            missing: '#FB5252',
        }
    },

    // tag
    tag: {
        disease: '#D34848',
        medicineUse: '#469ACC',
        beAllergic: '#F88F41',
        df: '#0C98D6',
        lab: '#FF881B',
    },

    // expired 
    expired: {
        danger: '#E60000',
        danger2: '#C33012',
        warning: '#E29124',
        warningLinear: 'linear-gradient(180.53deg, #E29124 0.5%, #C33012 140.52%)'
    },

    tooth: {
        defaultLayer1: '',
        defaultLayer2: '',
        defaultLayer3: '',
        layer1: '',
        layer2: '',
        layer3: '',
        lowerTeeth: ''
    },

    approveStatus: {
        approve: '#1CB99A',
        pending: '#F5B700',
        cancel: '#FB5252'
    },

    paletteColors: ["#FF0013", "#FF008F", "#99004E", "#FFC126", "#FF7829", "#691506", "#11B03C", "#017420", "#26C9FF", "#0050CD", "#666666", "#000000"],
    paletteColorsDoctorNote: ["#D5C0A9", "#FBF2E2", "#EBDBC5", "#A28847", "#C5B29A", "#A28F84", "#615346", "#564D47", "#333333", "#686754", "#B9B295", "#7C8865", "#698478", "#779698", "#ACB9B2", "#4A5B54", "#738187", "#201E1F", "#58A6A1", "#9AC9C6", "#EECA87", "#E1B3A5", "#D97156", "#D7483C", "#B00020", "#2F4269"],

    // dashboard
    dashboard: {
        summaryBox: {
            sales: 'linear-gradient(218.62deg, #FFE53B -28.93%, #FF005B 135.35%)',
            salesMarginUp: 'linear-gradient(215.07deg, #00FF5B -44.15%, #0014FF 193.45%)',
            salesMarginDown: 'linear-gradient(215.07deg, #FF689B -44.15%, #730000 193.45%)',
            service: 'linear-gradient(216.03deg, #EF76A3 -15.68%, #176BA0 125.76%)',
            customer: 'linear-gradient(218.58deg, #FFDD00 -39.38%, #00D2D2 130.29%)'
        },
        saleProduct: {
            evenBg: ['#47BBE5', '#49DDDD'],
            oddBg: ['#4588B3', '#479B99']
        },
        saleOperative: ['#FFC05D', '#7B6CAD'],
        paymentMethod: ['#7B6CAD', '#FFC05D', '#FFA289', '#BF71AC', '#EE819D', '#BA8475'],
        totalExpenses: ['#4589B3', '#32C0C4', '#6FC9A5', '#FFCC7D', '#FFA289'],
        operativeType: plusOperativeType,
        hightSaleOperative: ['#7B6CAD', '#BF70AC', '#EE819D', '#FFA288', '#FFCD7D']
    },

    appointmentDoctor: {
        colorEven: '#DFBBEC',
        colorOdd: '#F3E7F7'
    }
}

export const plusSidebarIcon: SidebarInterface = {
    ...dentalSidebarIcon,
    Doctor: Icons.Doctor,
    Xray: Icons.XRayPlus
}

export const plusLocaleTH: any = clinicLocaleTH
export const plusLocaleEN: any = clinicLocaleEN

export const plusTemplateImage: TemplateImageInterface = {
    logo: logo.plusLogo,
    logoHorizontal: logo.plusLogoHorizontal,
    login: loginBackground.bgPlusLogin,
    welcome: imgs.plusWelcome,
    pick: {
        left: pickBackground.bgPlusPickLeft,
        right: pickBackground.bgPlusPickRight
    }
}

export const PlusTemplatePickRoom: TemplatePickRoomInterface = {
    Branch: pickBackground.PlusBranch,
    Counter: pickBackground.CounterRoom,
    Service: pickBackground.PlusRoomServiceRoom,
    Payment: pickBackground.PaymentRoom,
    OtherService: pickBackground.PlusOtherServiceRoom,
}

export const PlusIcons: SpecificIconInterface = {
    Favicon: favicon.plusFavicon,
    SendExam: Icons.PlusIconExam,
    Treatment: Icons.ApTreatment,
    Counter: Icons.CounterRoom,
    Service: Icons.PlusRoomServiceRoom,
    Payment: Icons.PaymentRoom,
    DoneTreatment: icons.userCheckTrue
}

export const plusWatermark = watermark.plus