import BaseAPI from 'api/api'

/** UTIILS */
import { getBranch, getServicePoint, getToken } from 'utils/app.utils'

export interface PropsShortcutPermission {
  servicePointId: number
  branchId: number
}

export default class LoginApi extends BaseAPI {
  static login(username: string, password: string): Promise<any> {
    return this.api.post('login', { username, password }, {}).then(({ data: { accessToken } }: any) => accessToken)
  }

  static whoami(): Promise<boolean> {
    return this.api.post('whoami')
  }

  static forgetPassword(email: string): Promise<any> {
    const token = getToken()
    return this.api.post('forget-password', { email }, { headers: { authorization: `Bearer ${token}` } }).then((res) => res)
  }

  static roles(): Promise<any> {
    return this.api.get('role-user', {})
  }

  static findByToken(token: string): Promise<any> {
    return this.api.get(`validate-token`, { params: { token } })
  }

  static rememberMe(token: string): Promise<any> {
    return this.api.get(`remember-me`, { params: { token } })
  }

  static pick(branchId: string, servicePointId: string): Promise<any> {
    const token = getToken()
    return this.api
      .post('pick', { token, branchId, servicePointId })
      .then(() => true)
      .catch(() => false)
  }

  static rolesPermission(props: PropsShortcutPermission): Promise<any> {
    return this.api.get('role-permissions', { params: { ...props } }).then((res) => res)
  }

  static shortcutPermission(props: PropsShortcutPermission): Promise<any> {
    return this.api.get(`role-permissions-shortcut`, { params: { ...props } }).then((res) => res)
  }

  static menus(): Promise<any> {
    const branchId = getBranch()
    const servicePointId = getServicePoint()
    return this.api.get('menus', { params: { branchId, servicePointId } }).then((res) => res)
  }
}
