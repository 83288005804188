import { Dropdown } from 'react-bootstrap'
import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dateTimeToView, dateToView } from 'utils/date.utils'
import { Button, IconButton } from '@mui/material'
import { colors } from 'constants/theme'
import { fileUrl } from 'api/api'

/** TRANSLATION */
import { icons } from 'constants/images'

type IncomeExpensesTableRowProps = {
  key: string
  id: string
  obj: any
  columns: any
  onView?: (val: any) => void
  onedit?: (val: any) => void
  ondelete?: (val: any) => void
  noButton?: boolean
}

const useStyles = makeStyles((theme) => ({
  tableButton: {
    backgroundColor: '',
    color: `${colors.textPrimary} !important`,
    '&:hover': {
      backgroundColor: `transparent !important`,
      color: `${colors.themeSecondColor} !important`
    }
  },
  hide: {
    visibility: 'hidden'
  },
  show: {
    visibility: 'visible',
    cursor: 'text',
    color: colors.textPrimary
  }
}))

export default function IncomeExpensesTableRow(props: IncomeExpensesTableRowProps) {
  const classes = useStyles()

  return (
    <TableRow key={props.key} style={{ height: 'auto' }} hover={true}>
      {props.columns.map((column: any) => {
        return (
          (column.option === 'CURRENCY' && (
            <TableCell align={column.align || 'left'} scope="row" size="small">
              <Button className={props.noButton ? classes.hide : classes.tableButton} variant="text" onClick={props.onView}>
                <p className={`${props.noButton ? classes.show : ''} border-0`} style={{ fontSize: '16px', fontWeight: 400, margin: '0', width: '100%' }}>
                  {Intl.NumberFormat('th-TH', {
                    style: 'currency',
                    currency: 'THB'
                  }).format(column.label)}
                </p>
              </Button>
            </TableCell>
          )) ||
          (column.option === 'TEXT' && (
            <TableCell align={column.align || 'left'} scope="row" size="small">
              <Button className={props.noButton ? classes.hide : classes.tableButton} variant="text" onClick={props.onView}>
                <p className={`${props.noButton ? classes.show : ''} border-0`} style={{ fontSize: '16px', fontWeight: 400, margin: '0', width: '100%' }}>
                  {column.label}
                </p>
              </Button>
            </TableCell>
          )) ||
          (column.option === 'LINK' && (
            <TableCell align={column.align || 'left'} scope="row" size="small">
              <Button className={props.noButton ? classes.hide : classes.tableButton} variant="text" onClick={props.onView}>
                <a href={`${fileUrl}/${column.path}`} target='_blank' rel="noreferrer" className={`${props.noButton ? classes.show : ''} border-0`} style={{ fontSize: '16px', fontWeight: 400, margin: '0', width: '100%', color: '#006BC8', textDecoration: 'underline' }}>
                  <img className='mb-2' style={{ marginRight: '-15px' }} src={icons.photo} /> &nbsp;&nbsp;&nbsp;&nbsp;{column.label}
                </a>
              </Button>
            </TableCell>
          )) ||
          (column.option === 'DATE' && (
            <TableCell align={column.align || 'left'} scope="row">
              <Button className={props.noButton ? classes.hide : classes.tableButton} variant="text" onClick={props.onView}>
                <p className={props.noButton ? classes.show : ''} style={{ fontSize: '16px', fontWeight: 400, margin: '0', width: '100%' }}>
                  {dateToView(column.label)}
                </p>
              </Button>
            </TableCell>
          )) ||
          (column.option === 'UPDATE_BY' && (
            <TableCell align={column.align || 'left'} className="update-by" size="small">
              {column.label.updatedBy}
              <br />
              <span>{dateTimeToView(column.label.updatedAt, false)}</span>
            </TableCell>
          )) ||
          (column.option === 'DROPDOWN_ACTION' && (
            <TableCell style={{ width: 160 }} align={column.align || 'left'} size="small">
              <Dropdown>
                <Dropdown.Toggle variant="link" bsPrefix="p-0  " className=" text-black-50  ">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {column.values.map((objVal: any, i: number) => {
                    return (
                      (objVal.option === 'EDIT' && (
                        <Dropdown.Item onClick={props.onedit} disabled={objVal.disabled}>
                          <img src={icons.iconEdit} alt="iconEdit" onClick={props.onedit} style={{ cursor: 'pointer', marginRight: '10px' }} />

                          <span className="ml-2">{objVal.label}</span>
                        </Dropdown.Item>
                      )) ||
                      (objVal.option === 'DELETE' && (
                        <Dropdown.Item onClick={props.ondelete} disabled={objVal.disabled}>
                          <img src={icons.iconFaTrash} alt="iconFaTrash" onClick={props.ondelete} style={{ cursor: 'pointer', marginRight: '10px' }} />
                          <span className="ml-2">{objVal.label}</span>
                        </Dropdown.Item>
                      ))
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </TableCell>
          )) ||
          (column.option === 'EXTERNAL_ACTION' && (
            <TableCell align={column.align || 'left'} size="small">
              <IconButton aria-label="delete" onClick={props.onedit}>
                <img src={icons.iconEdit} alt="iconEdit" onClick={props.onedit} style={{ cursor: 'pointer', marginRight: '10px' }} />
              </IconButton>
              <IconButton aria-label="delete" onClick={props.ondelete} size="small">
                <img src={icons.iconFaTrash} alt="iconFaTrash" onClick={props.ondelete} style={{ cursor: 'pointer', marginRight: '10px' }} />
              </IconButton>
            </TableCell>
          ))
        )
      })}
    </TableRow>
  )
}
