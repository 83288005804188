import { menuSeconds } from 'constants/menus'
import ManageDoctorFee from 'features/counter/manage-doctor-fee/manage-doctor-fee'

const routeManageDoctorFee = [
  {
    ...menuSeconds.MANAGE_DF,
    component: ManageDoctorFee,
    appBar: true
  }
]

export default routeManageDoctorFee
