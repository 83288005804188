import { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom'
import { Box, ClickAwayListener, FormControlLabel, MenuItem, RadioGroup, Typography } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch, toBuddhistYear } from 'utils/app.utils'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/**COMPONENT */
import DecreaseList from './DecreaseList'
import Loading from 'component/Loading'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/**STYLE */
import * as UseStyled from 'features/report/useStyled'
import InputRadio from 'component/Input/InputRadio'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputCheckbox from 'component/Input/InputCheckbox'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { colors } from 'constants/theme'
import { SpanClearFilter } from 'features/report/useStyled'
import { SORT_TYPE } from 'constants/common'
import ComponentApi from 'api/components-api'
import InputTextSearch from 'component/Input/InputTextSearch'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

export default function AdvanceDecrease() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)

  const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches] = useState<any[]>([])
  const [search, setSearch] = useState<string>('')
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [billStatus, setBillStatus] = useState<string>('ALL')

  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [fetch, setFetch] = useState(false)
  const [rows, setRows] = useState<any[]>([])
  const [rowsExport, setRowsExport] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(10)
  const [loading, setLoading] = useState<boolean>(true)

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'ADVANCE_DECREASE') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [branchId])

  const fetchDF = useCallback(async () => {
    let condition: any = {
      page,
      pageLimit,
      branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1],
      search
    }

    if (billStatus && billStatus !== 'ALL') condition = { ...condition, billStatus: billStatus }

    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }

    ReportApi.reportAdvanceDecrease(condition)
      .then(({ headers, data }) => {
        setRows(data.rows)
        setRowsExport(data.rowsExport)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => {
        setLoading(false)
      })
    setFetch(false)
  }, [page, pageLimit, sortBy, sortType, fetch])

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.ADVANCE_DECREASE`)}`,
    sheetName: `${t(`REPORT.REPORT_NAME.ADVANCE_DECREASE`)}`
  })

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  const handleChangePage = (val: number) => setPage(val)

  useEffect(() => {
    fetchDF()
  }, [fetchDF])

  return (
    <>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.ADVANCE_DECREASE`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2'}>
                <InputTextSearch
                  keyInput={'search'}
                  label={t('INPUT.SEARCH_PATIENT')}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                  }}
                  onClear={(event) => {
                    setSearch('')
                  }}
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface, index) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  }}
                  label={''}
                  onClear={() => setRangeDate(emptyRangePicker)}
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1 d-flex flex-row align-items-center'}>
                <FilterSelect
                  onchange={(e) => {
                    setBillStatus(e.target.value)
                    setPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.RECEIPT_STATUS')}: ${billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)} `}
                  label={''}
                  selectId="select-bill-status"
                  labelId="label-bill-status"
                  value={billStatus}
                  options={[
                    <MenuItem key="1" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    <MenuItem key="2" value="OVERDUE">
                      {t('REPORT.BILL_STATUS.OVERDUE')}
                    </MenuItem>,
                    <MenuItem key="3" value="PAID">
                      {t('REPORT.BILL_STATUS.PAID')}
                    </MenuItem>,
                    <MenuItem key="4" value="CANCEL_BILL">
                      {t('REPORT.BILL_STATUS.CANCEL_BILL')}
                    </MenuItem>
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
                <ButtonCustom
                  onClick={() => {
                    setFetch(true)
                    setPage(1)
                    setLoading(true)
                  }}
                  textButton={t('INPUT.SEARCH')}
                  style={{ height: '30px', marginLeft: '5px' }}
                />
              </Col>

              <Col md={4} xl={2} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 '}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <DecreaseList
              page={page}
              pageLimit={pageLimit}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={rows}
              dataExport={rowsExport}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              setSortType={setSortType}
              setSortBy={setSortBy}
              componentRef={componentRef}
              branchName={_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}
              rangeDate={rangeDate}
              billStatus={billStatus}
            />
          </Box>
        </Card.Body>
        <Loading show={loading} type="fullLoading" />
      </Card>
    </>
  )
}
