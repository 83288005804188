import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import _ from 'lodash'

dayjs.extend(weekday)
dayjs.extend(weekOfYear)

export const convertToArray = (max: any) => {
  const day = []
  for (let i = 1; i <= max; i++) {
    day.push(i)
  }
  return day
}

export const daysOfWeek = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์']

export function getYearDropdownOptions(currentYear: any) {
  const date = {
    minYear: currentYear - 4,
    maxYear: currentYear + 5
  }

  return _.range(date.minYear, date.maxYear + 1).map((y: any) => ({ label: `${y}`, value: y }))
}

export function getMonthDropdownOptions() {
  return _.range(1, 13).map((m: any) => ({
    value: m,
    label: dayjs()
      .month(m - 1)
      .format('MMMM')
  }))
}

export function getNumberOfDaysInMonth(year: any, month: any) {
  return dayjs(`${year}-${month}-01`).daysInMonth()
}

export function createDaysForCurrentMonth(year: any, month: any) {
  return convertToArray(getNumberOfDaysInMonth(year, month)).map((numOfDay, index) => {
    return {
      dateString: dayjs(`${year}-${month}-${index + 1}`).format('YYYY-MM-DD'),
      dayOfMonth: index + 1,
      isCurrentMonth: true
    }
  })
}

export function createDaysForPreviousMonth(year: any, month: any, currentMonthDays: any) {
  const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].dateString)
  const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, 'month')

  const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday

  const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays[0].dateString).subtract(visibleNumberOfDaysFromPreviousMonth, 'day').date()

  return convertToArray(visibleNumberOfDaysFromPreviousMonth).map((numOfDay, index) => {
    return {
      dateString: dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).format('YYYY-MM-DD'),
      dayOfMonth: previousMonthLastMondayDayOfMonth + index,
      isCurrentMonth: false,
      isPreviousMonth: true
    }
  })
}

export function createDaysForNextMonth(year: any, month: any, currentMonthDays: any) {
  const lastDayOfTheMonthWeekday = getWeekday(`${year}-${month}-${currentMonthDays.length}`)
  const nextMonth = dayjs(`${year}-${month}-01`).add(1, 'month')
  const visibleNumberOfDaysFromNextMonth = 6 - lastDayOfTheMonthWeekday

  return convertToArray(visibleNumberOfDaysFromNextMonth).map((day, index) => {
    return {
      dateString: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format('YYYY-MM-DD'),
      dayOfMonth: index + 1,
      isCurrentMonth: false,
      isNextMonth: true
    }
  })
}

export function getWeekday(dateString: any) {
  return dayjs(dateString).weekday()
}

export function isWeekendDay(dateString: any) {
  return [6, 0].includes(getWeekday(dateString))
}
