import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface PositionInterface {
  positionName: string
  positionNameEn?: string
  status: string
}

export default class PositionApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('positions', { params: { ...props } }).then((res) => res)
  }

  static create(positionName: string, positionNameEn: string) {
    return this.api.post('positions', { positionName, positionNameEn })
  }

  static findById(id: number): Promise<PositionInterface> {
    return this.api.get(`positions/${id}`)
  }

  static update(id: number, body: PositionInterface) {
    return this.api.patch(`positions/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`positions/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`positions/${id}`)
  }
}
