import { toast, cssTransition } from 'react-toastify'

export function notiSuccess(title?: string, option?: string, url?: any, callback?: any, toastClassName?: string) {
  toast.success(title, {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: toastClassName || '',
    onClose: () => {
      if (option === 'reset') {
        return
      } else if (option === 'reload') {
        window.location.reload()
      } else if (option === 'go') {
        window.location.href = url
      } else if (callback != null) callback(true)
    }
  })
}

export function notiError(title?: string, option?: string, url?: any, callback?: any, toastClassName?: string) {
  toast.error(title, {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: toastClassName || '',
    onClose: () => {
      if (option === 'reset') {
        return
      } else if (option === 'reload') {
        window.location.reload()
      } else if (option === 'go') {
        window.location.href = url
      } else if (callback != null) callback(true)
    }
  })
}

const slide = cssTransition({
  enter: 'slide-in',
  exit: 'slide-out'
})

export function notiPopup(status: 'success' | 'warning' | 'custom' | 'clear', title?: string, customToast?: any, callback?: any,) {
  if (status === 'clear') {
    toast.dismiss()
  } else {
    if (status === 'success') {
      toast.success(title, {
        containerId: 'popup',
        position: 'top-center',
        transition: slide,
        autoClose: 3000,

        hideProgressBar: true
      })
    } else if (status === 'custom') {
      toast.info(customToast, {
        containerId: 'popup',
        position: 'top-center',
        transition: slide,
        closeButton: false,
        autoClose: false,
        closeOnClick: false,
        hideProgressBar: true,
        draggable: false,
        className: 'toast-custom'
      })
    } else if (callback != null) {
      callback(true)
    } else {
      toast.info(title, {
        containerId: 'popup',
        position: 'top-center',
        transition: slide,
        autoClose: 3000,

        hideProgressBar: true
      })
    }
  }
}
