import { Tab, Box } from '@mui/material';
import _ from 'lodash'

/** COMPONENT */
import Dx from 'features/treatments/MainMenu/OPD/Dx'
import Tx from 'features/treatments/MainMenu/OPD/Tx'
import Txp from 'features/treatments/MainMenu/OPD/Txp'
import Txc from 'features/treatments/MainMenu/OPD/Txc'
import Med from 'features/treatments/MainMenu/OPD/Med'
import Pd from 'features/treatments/MainMenu/OPD/Pd'
import XRay from 'features/treatments/MainMenu/OPD/XRay'
import Lab from 'features/treatments/MainMenu/OPD/lab/lab'
import History from 'features/historyTreatments/MainMenu/OPD/History'

import { SUB_TABS_NAME } from 'features/treatments/treatmentsIndex'

/** STYLE */
import * as UseStyled from 'features/treatments/MainMenu/OPD/style/UseStyle'
import { useSelector } from 'react-redux';
import { selectMe } from 'app/slice/user.slice';
import Mat from './Mat';

/** UTILS || SLICE */

interface TabPanelProps {
  children?: React.ReactNode
  index: number | string
  value: number | string
  className?: string
}

type IndexOpdProps = {
  userId?: number
  tabs: SUB_TABS_NAME[]
  activeTab: SUB_TABS_NAME
  patient?: any
  setActiveTab: (tab: SUB_TABS_NAME) => void
  setFooterActiveTab?: (tab: number) => void
  type?: 'Treatment' | 'Payment' | 'HistoryTreatment' | 'RetrospectiveOrder' | 'RetrospectiveEdit'
  disabledMenu?: boolean
  setGotoPage?: (page: string) => void
  onSaveTreatment?: (status: 'saveToLab' | 'saveToXray') => void
  setSaveTreatment?: (val: boolean) => void
  saveTreatment?: boolean | false
  hasChangeProductPrice: 'PERMISS' | 'UNPERMISS'
  hasChangeProductDiscountAdditional: 'PERMISS' | 'UNPERMISS'
  hasCreateTreatment?: 'PERMISS' | 'UNPERMISS'
  hasCreateTreatmentPlan?: 'PERMISS' | 'UNPERMISS'
  hasCreateTreatmentCourse?: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatment?: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatmentPlan?: 'PERMISS' | 'UNPERMISS'
  hasDeleteTreatmentCourse?: 'PERMISS' | 'UNPERMISS'
  medShowType?: '1' | '0'
  pdShowType?: '1' | '0'
}

export default function IndexOPD(props: IndexOpdProps) {
  const user = useSelector(selectMe)

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    if (props.setActiveTab) props.setActiveTab(props.tabs[newValue])
    if (props.setFooterActiveTab)
      if (newValue === 0 && (props.type === 'Treatment' || props.type === 'RetrospectiveOrder' || props.type === 'Payment')) props.setFooterActiveTab(1)
      else props.setFooterActiveTab(0)
  }

  const handleHasDianosis = () => {
    if (props.type === 'Payment') {
      if (user.hasDiagnosis === "PERMISS") {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
      <UseStyled.CustomTabs disabled={props.disabledMenu} orientation="vertical" variant="scrollable" value={props.activeTab} onChange={handleChangeTab} className="d-none d-xl-flex" sx={{ flex: 'none' }}>
        {_.map(props.tabs, (tab: string, index: number) => {
          return <Tab key={index} label={tab} {...a11yProps(tab)} className={`${props.activeTab === tab ? 'Mui-selected' : ''}`} />
        })}
      </UseStyled.CustomTabs>
      {props.type === 'HistoryTreatment' && props.activeTab === 'All' && (
        <TabPanel value={props.activeTab} index={props.activeTab} className="overflow-hidden w-100">
          <History patient={props.patient} disabled={props.disabledMenu} setGotoPage={(tab: string) => props.setGotoPage && props.setGotoPage(tab)} />
        </TabPanel>
      )}
      {props.type === 'RetrospectiveEdit' && props.activeTab === 'All' && (
        <TabPanel value={props.activeTab} index={props.activeTab} className="overflow-hidden w-100">
          <History patient={props.patient} disabled={props.disabledMenu} type='RetrospectiveEdit' setGotoPage={(tab: string) => props.setGotoPage && props.setGotoPage(tab)} />
        </TabPanel>
      )}
      {((props.type === 'Treatment' || props.type === 'RetrospectiveOrder' || handleHasDianosis()) && props.activeTab === 'DX') && (
        <TabPanel value={props.activeTab} index={props.activeTab}>
          <Dx />
        </TabPanel>
      )}
      {((props.type === 'Payment' || props.type === 'Treatment' || props.type === 'RetrospectiveOrder') && props.activeTab === 'TX') && (
        <TabPanel value={props.activeTab} index={props.activeTab}>
          <Tx userId={props.userId} patientId={props.patient?.patientId || 0} disabled={props.disabledMenu} type={props.type} hasCreateTreatment={props.hasCreateTreatment} patient={props.patient} />
        </TabPanel>
      )}
      {props.activeTab === SUB_TABS_NAME.TXP && (
        <TabPanel value={props.activeTab} index={props.activeTab}>
          <Txp type={props.type} patient={props.patient} history={props.type === 'HistoryTreatment' ? true : false} hasCreateTreatmentPlan={props.hasCreateTreatmentPlan} hasDeleteTreatmentPlan={props.hasDeleteTreatmentPlan} />
        </TabPanel>
      )}
      {props.activeTab === SUB_TABS_NAME.TXC && (
        <TabPanel value={props.activeTab} index={props.activeTab}>
          <Txc type={props.type} userId={props.userId} patient={props.patient} history={props.type === 'HistoryTreatment' ? true : false} hasCreateTreatmentCourse={props.hasCreateTreatmentCourse} hasDeleteTreatmentCourse={props.hasDeleteTreatmentCourse} />
        </TabPanel>
      )}
      {(props.type === 'Payment' || props.type === 'Treatment' || props.type === 'RetrospectiveOrder') && (
        <>
          {props.activeTab === SUB_TABS_NAME.MED && (
            <TabPanel value={props.activeTab} index={props.activeTab}>
              <Med
                userId={props.userId}
                patient={props.patient}
                patientCaseId={props?.patient?.patientCase?.patientCaseId}
                hasChangeProductPrice={props.hasChangeProductPrice}
                hasChangeProductDiscountAdditional={props.hasChangeProductDiscountAdditional}
                type={props.type}
                medShowType={props.medShowType}
              />
            </TabPanel>
          )}
          {props.activeTab === SUB_TABS_NAME.PD && (
            <TabPanel value={props.activeTab} index={props.activeTab}>
              <Pd
                userId={props.userId}
                patient={props.patient}
                patientCaseId={props?.patient?.patientCase?.patientCaseId}
                hasChangeProductPrice={props.hasChangeProductPrice}
                hasChangeProductDiscountAdditional={props.hasChangeProductDiscountAdditional}
                type={props.type}
                pdShowType={'0'}
              />
            </TabPanel>
          )}
        </>
      )}
      {(props.type === 'Treatment' || props.type === 'Payment') && props.activeTab === SUB_TABS_NAME.MAT &&
        <TabPanel value={props.activeTab} index={props.activeTab}>
          <Mat
            userId={props.userId}
            patient={props.patient}
            patientCaseId={props?.patient?.patientCase?.patientCaseId}
            hasChangeProductPrice={'PERMISS'}
            hasChangeProductDiscountAdditional={'PERMISS'}
            type={props.type}
            pdShowType={props.pdShowType}
          />
        </TabPanel>
      }
      {(props.type === 'Treatment' || props.type === 'HistoryTreatment' || props.type === 'RetrospectiveEdit') && (
        <>
          {props.activeTab === 'X-Ray' && (
            <TabPanel value={props.activeTab} index={props.activeTab}>
              <XRay
                isClose={props.type === 'HistoryTreatment'}
                patient={props.patient}
                type={props.type}
                onSaveTreatment={() => props.onSaveTreatment && props.onSaveTreatment('saveToXray')}
              />
            </TabPanel>
          )}
          {/* props.type !== 'RetrospectiveEdit' && */}
          {props.activeTab === 'LAB' && (
            <TabPanel value={props.activeTab} index={props.activeTab}>
              <Lab
                type={props.type}
                userId={props.userId}
                patient={props.patient}
                onSaveTreatment={() => props.onSaveTreatment && props.onSaveTreatment('saveToLab')}
                saveTreatment={props.saveTreatment}
                setSaveTreatment={(val: boolean) => { props.setSaveTreatment && props.setSaveTreatment(val) }} />
            </TabPanel>
          )}
        </>
      )}
    </Box>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} className={'w-100'} {...other}>
      {value === index && <Box className="pl-xl-4">{children}</Box>}
    </div>
  )
}

function a11yProps(index: number | string) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}
