import { Box } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PopupTreatmentFees from './PopupTreatmentFees'
import PopupTreatmentConsent from './popupTreatmentConsent'

type PopTreatmentDocumentProps = {
    type: 'treatment' | 'historyTreatment'
    patientCaseId: number
    servicePointId: number
    setIsShow: (show: boolean) => void
}

const PopTreatmentDocument = (props: PopTreatmentDocumentProps) => {
    const { t } = useTranslation()

    const [tap, setTap] = useState<'TREATMENT_CONSENT' | 'TREATMENT_INVOICE'>("TREATMENT_CONSENT")

    return (
        <ModalCustom
            size={'lg'}
            title={t('TREATMENT_DOCUMENT_HEADER')}
            alignFooter={'end'}
            fullscreen
            footerInline
            closeButton
            modalStyle={'modal-med-certificate modal-treatment-consent'}
            component={
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Box sx={{ width: '20%' }}>
                            <ButtonCustom
                                btnStyle={{ width: '100%' }}
                                variant={tap === 'TREATMENT_CONSENT' ? "contained" : "outlined"}
                                textButton={t('TREATMENTS.BUTTON.TREATMENT_CONSENT')}
                                onClick={() => setTap('TREATMENT_CONSENT')}
                            />
                        </Box>
                        <Box sx={{ width: '20%' }}>
                            <ButtonCustom
                                btnStyle={{ width: '100%' }}
                                variant={tap === 'TREATMENT_INVOICE' ? "contained" : "outlined"}
                                textButton={t('PDF_INVOICE.HEADER')}
                                onClick={() => setTap('TREATMENT_INVOICE')}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ padding: '32px 0 16px' }}>
                        {tap === 'TREATMENT_CONSENT' &&
                            <PopupTreatmentConsent
                                type={props.type}
                                patientCaseId={props.patientCaseId}
                                servicePointId={props.servicePointId}
                                setIsShow={props.setIsShow}
                                modal={false}
                            />
                        }
                        {tap === 'TREATMENT_INVOICE' &&
                            <PopupTreatmentFees
                                type={props.type}
                                patientCaseId={props.patientCaseId}
                                servicePointId={props.servicePointId}
                                setIsShow={props.setIsShow}
                            />
                        }
                    </Box>
                </>
            }
        />
    )
}

export default PopTreatmentDocument