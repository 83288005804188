import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'
import { MenuItem } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import NewsApi from 'api/application/news.api'
import { routeName } from 'routes/routes-name'
import { fileUrl } from 'api/api'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { toBuddhistYear } from 'utils/app.utils'
import { onImgError } from 'constants/images'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import FilterSelect from 'component/Select/FilterSelect'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { swalActive } from 'component/Alert/Swal'
import { notiSuccess, notiError } from 'component/notifications/notifications'

/** STYLE */
import * as PromotionUseStyled from 'features/application/promotions/useStyled'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function News() {
  const { t } = useTranslation()
  const history = useHistory()

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const [news, setNews] = useState([]);

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status } //fix filter status all
    const resp = await NewsApi.findAll(condition)
    if (resp.status === 200) {
      setRowCount(resp.headers['x-total'])
      setNews(resp.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const onActive = (id: number, subject: string, statusValue: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 475px">${t('NEWS.MESSAGE.ALERT.CONFIRM_STATUS')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 450px">${t(`NEWS.MESSAGE.ALERT.CONFIRM_STATUS_`, {
        subject: subject || '-',
        status: t(`STATUS.${statusValue}`)
      })}</p>`,
      (res: any) => {
        if (res) {
          NewsApi.updateNewsStatus(id, statusValue)
            .then((resp) => {
              notiSuccess(t('NEWS.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('NEWS.MESSAGE.ERROR.DEFAULT'))
            })
        }
      },
      'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }


  const onDelete = (id: number, subject: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 400px">${t('NEWS.MESSAGE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 350px">${t('NEWS.MESSAGE.ALERT.CONFIRM_DELETE_', {
        subject: subject
      })}</p>`,
      (res: any) => {
        if (res) {
          NewsApi.removeNews(id)
            .then((resp) => {
              notiSuccess(t('NEWS.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('NEWS.MESSAGE.ERROR.DEFAULT'))
            })
        }
      }, 'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onCreate = () => {
    history.push(routeName.news + '/create')
  }

  const onEdit = (id: any) => {
    history.push(routeName.news + '/edit', { newsId: id })
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '50px' },
    { id: 'message', disablePadding: false, label: t('NEWS.TABLE.IMAGE'), width: '150px' },
    { id: 'message', disablePadding: false, label: t('NEWS.TABLE.TITLE') },
    { id: 'message', disablePadding: false, label: t('NEWS.TABLE.DATE_START'), width: '160px' },
    { id: 'message', disablePadding: false, label: t('NEWS.TABLE.DATE_END'), width: '160px' },
    { id: 'status', disablePadding: false, label: t('NEWS.TABLE.STATUS'), width: '150px' },
    { id: 'updatedBy', disablePadding: false, label: t('NEWS.TABLE.UPDATED_BY'), width: '180px' },
    { id: 'action', disablePadding: false, label: 'Action', width: '80px' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { newsId, image, subject, datePublic, timePublic, dateEnd, timeEnd, status: statusNews, updatedBy, updatedAt } = objData
    const statusBtnActive = statusNews === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = statusNews === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: newsId,
      id: newsId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        {
          option: 'COMPONENT', align: 'left', component: (
            <PromotionUseStyled.ImageWrap>
              {
                image?.filePath && image?.fileName && (
                  <img src={`${fileUrl}/${image?.filePath}/${image?.fileName}`} onError={onImgError} className="w-100" alt='' />
                )
              }
            </PromotionUseStyled.ImageWrap>
          )
        },
        { option: 'TEXT', align: 'left', label: subject },
        { option: 'TEXT', align: 'left', label: datePublic && datePublic !== '0000-00-00' && timePublic ? toBuddhistYear(moment(`${datePublic} ${timePublic}`), 'DD/MM/YYYY HH.mm') : '-' },
        { option: 'TEXT', align: 'left', label: dateEnd && dateEnd !== '0000-00-00' && timeEnd ? toBuddhistYear(moment(`${dateEnd} ${timeEnd}`), 'DD/MM/YYYY HH.mm') : '-' },
        { option: 'STATUS', align: 'center', label: statusNews },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }

    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), subject, 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), subject, 'INACTIVE')}
        onedit={() => onEdit(objRenderData.id)}
        ondelete={() => onDelete(objRenderData.id, subject)}
      />
    )
  }

  return (
    <Card className={'border-0 h-100 overflow-auto'}>
      <HeaderCard text={t('NEWS.HEADER')} />
      <Card.Body>
        <Card.Title className={'mb-0 pl-xl-3'}>
          <Row>
            <Col md={8}>
              <Row>
                <Col sm={12} md={6} xl={4} className={'pt-2'}>
                  <InputTextSearch
                    keyInput={'search'}
                    label={t('INPUT.SEARCH')}
                    value={search}
                    onchange={(event) => {
                      setSearch(event.target.value)
                      setPage(1)
                    }}
                    onClear={(event) => {
                      setSearch('')
                      setPage(1)
                    }}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className={'pl-md-0 pt-2'}>
                  <FilterSelect
                    onchange={(event) => {
                      const value = event.target.value
                      if (value) {
                        handleChangeStatus(value)
                        setPage(1)
                      }
                    }}
                    renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                    label={t('STATUS.LABEL')}
                    selectId="select-status"
                    value={status}
                    labelId="label-status"
                    options={[
                      <MenuItem key="1" value="ALL">
                        {t('STATUS.ALL')}
                      </MenuItem>,
                      <MenuItem key="2" value="ACTIVE">
                        {t('STATUS.ACTIVE')}
                      </MenuItem>,
                      <MenuItem key="3" value="INACTIVE">
                        {t('STATUS.INACTIVE')}
                      </MenuItem>
                    ]}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={4} className="mt-2 mt-md-auto">
              <div className="pr-xl-2">
                <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={onCreate} textButton={t('NEWS.BUTTON.ADD_NEWS')} className="w-auto ml-auto d-flex mt-auto" />
              </div>
            </Col>
          </Row>
        </Card.Title>

        <PromotionUseStyled.TableResponsive className={'mt-3 pl-xl-3 pr-xl-2'}>
          <TableCustom
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            onSort={onRequestSort}
            setPageLimit={handleChangeRowsPerPage}
            setPage={handleChangePage}
            headCells={headCells}
            rowsData={(
              _.map(news, (item, index: number) => {
                return renderData(item, index)
              })
            )}
            tableFixedWidth
            tableMinWidth={1200}
          />
        </PromotionUseStyled.TableResponsive>
      </Card.Body>
    </Card>
  )
}
