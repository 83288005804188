import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { routeName } from 'routes/routes-name'
import { Box, FormControlLabel, Grid, MenuItem, RadioGroup, styled } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { getBranch } from 'utils/app.utils'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import Loading from 'component/Loading'
import RemittanceList from './remittancesList'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import ButtonCustom from 'component/Button/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import InputRadio from 'component/Input/InputRadio'
import { colors } from 'constants/theme'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { useDispatch } from 'react-redux'
import { resetModal, showModal } from 'app/slice/modal.slice'
import { KEY_STORAGE } from 'constants/common'

const SelectorProcess = styled(Box)(({ theme }) => ({
  '.css-16wllh2-MuiButtonBase-root-MuiButton-root.btn-style': {
    color: `${colors.disabledGray} !important`,
    border: '1px solid rgba(0, 0, 0, 0.12)!important'
  },
  '.btn-pop-process': {
    maxHeight: '32px',
    width: ' 100% !Important',
    maxWidth: '180px',
    display: 'flex !Important',
    justifyContent: 'space-between !Important',
    alignItems: 'center !Important',

    '.icon-pop': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black'
    }
  },
  '.pop-process-open': {
    opacity: '1 !important',
    display: 'block !important'
  },
  '.pop-process-close': {
    opacity: '0',
    display: 'none'
  },
  '.pop-process-container': {
    transition: 'all 0.2s ',
    position: 'fixed',
    border: '1px solid #E5E5E5',
    backgroundColor: '#fff',
    padding: '6px 10px',
    '.pop-content': {
      padding: '8px 0px',
      fontSize: '14px',
      '.pop-content-main': {
        display: 'flex ',
        justifyContent: 'space-between ',
        alignItems: 'center '
      }
    }
  }
}))

export type SubSelectorProcessProps = {
  name: string
  title: string
  isShow?: boolean | undefined
  isProcess?: boolean | undefined
  type: 'topic' | 'sub'
}

export type SelectorProcessProps = {
  DF: SubSelectorProcessProps
  LAB: SubSelectorProcessProps
  TREATMENTRIGHT: SubSelectorProcessProps
  CASHSLIP: SubSelectorProcessProps
  INCOME_EXPENSES: SubSelectorProcessProps
}

export default function Remittances() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNow = moment().format('YYYY-MM-DD')

  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches] = useState<any[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [loading, setLoading] = useState<boolean>(false)

  const [remittanceData, getRemittanceData] = useState<any[]>([])
  const [remittanceDate, setRemittanceDate] = useState<string>(momentNow)
  const [remittancePage, setRemittancePage] = useState<number>(1)
  const [remittanceLimitPage, setRemittanceLimitPage] = useState<number>(10)

  const [checkDF, setCheckDf] = useState<boolean>(false)
  const [checkCash, setCheckCash] = useState<boolean>(false)

  const [checkTreatmentRight, setCheckTreatmentRight] = useState<boolean>(false)
  const [popSettingDisplay, setPopSettingDisplay] = useState<boolean>(false)
  // SelectorProcessProps[]
  const branchInfo = localStorage.getItem(KEY_STORAGE.CLINIC_INFO)
  const [dataProcess, setDataProcess] = useState<SelectorProcessProps>({
    "DF": {
      title: t('REPORT.MODAL_DISPLAY.DF'),
      isShow: false,
      isProcess: false,
      type: 'topic',
      name: 'DF'
    },
    "LAB": {
      title: t('REPORT.MODAL_DISPLAY.LAB'),
      isShow: false,
      isProcess: undefined,
      type: 'sub',
      name: 'LAB'
    },
    "TREATMENTRIGHT": {
      title: t('REPORT.MODAL_DISPLAY.TREATMENTRIGHT'),
      isShow: false,
      isProcess: false,
      type: 'topic',
      name: 'TREATMENTRIGHT'
    },
    "CASHSLIP": {
      title: t('REPORT.MODAL_DISPLAY.CASHSLIP'),
      isShow: undefined,
      isProcess: false,
      type: 'topic',
      name: "CASHSLIP"
    },
    "INCOME_EXPENSES": {
      title: t('REPORT.MODAL_DISPLAY.INCOME_EXPENSES'),
      isShow: false,
      isProcess: false,
      type: 'topic',
      name: "INCOME_EXPENSES"
    }
  })

  const onOpenSentingDisplay = () => {
    setPopSettingDisplay(true)
    dispatch(showModal())
  }

  const onCloseSentingDisplay = () => {
    dispatch(resetModal())
    setPopSettingDisplay(false)
  }

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'REMITTANCES') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  const fetchRemittance = useCallback(() => {
    let condition: any = {
      page: remittancePage,
      pageLimit: remittanceLimitPage,
      branchId: branchId,
      date: remittanceDate
    }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (checkDF) condition = { ...condition, df: checkDF === true ? 1 : 0 }
    if (checkTreatmentRight) condition = { ...condition, expenses: checkTreatmentRight === true ? 1 : 0 }
    if (dataProcess) condition = { ...condition, lab: dataProcess?.LAB.isShow === true ? 1 : 0 }
    if (remittanceDate !== null) condition = { ...condition, date: remittanceDate }

    ReportApi.reportRemittances(condition)
      .then(({ headers, data }) => {
        getRemittanceData(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [remittancePage, remittanceLimitPage, sortBy, sortType, branchId, remittanceDate, checkDF, checkTreatmentRight, dataProcess])

  const filter = [branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`, remittanceDate]

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.REMITTANCE_NEW`)} (${remittanceDate})`,
    sheetName: `${t(`REPORT.REPORT_NAME.REMITTANCE_NEW`)}`
  })

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setRemittanceLimitPage(limit)

  const handleChangePage = (val: number) => setRemittancePage(val)

  /** UseEffect */
  useEffect(() => {
    fetchRemittance()
  }, [fetchRemittance])
  /** UseEffect */
  useEffect(() => {
    const defaultModal = localStorage.getItem('REMITTANCES')
    if (defaultModal) {
      setDataProcess(JSON.parse(defaultModal))
    }

  }, [])

  const handleChangeSettingDisplay = (data: SelectorProcessProps) => {
    localStorage.setItem('REMITTANCES', JSON.stringify(data))
    setDataProcess(data)
    console.log(dataProcess.LAB.isShow)
  }

  return (
    <div>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.REMITTANCE_NEW`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setRemittancePage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface, index: number) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewDatePicker
                  dateFormat={'DD/MM/YYYY'}
                  placeholder={t('REPORT.FILTER.DATE')}
                  label={''}
                  inputHeight={32}
                  value={remittanceDate}
                  onchange={(e: any) => {
                    setRemittanceDate(moment(e).format('YYYY-MM-DD'))
                    setRemittancePage(1)
                  }}
                  views={PICKER_VIEWS.DAY || PICKER_VIEWS.MONTH || PICKER_VIEWS.YEAR}
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <ButtonCustom
                  onClick={() => onOpenSentingDisplay()}
                  variant={"outlined"}
                  textButton={t('REPORT.MODAL_DISPLAY.BUTTON_SETTING_DISPLAY')}
                  btnStyle={{ height: '32px' }}
                />
              </Col>
              {/* <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                <InputCheckbox
                  onChange={() => {
                    setCheckDf(!checkDF)
                    setRemittancePage(1)
                  }}
                  value="1"
                  label={t('DF')}
                  checked={checkDF}
                  style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }}
                />
              </Col>

              <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                <InputCheckbox
                  onChange={() => {
                    setCheckCash(!checkCash)
                    setRemittancePage(1)
                  }}
                  value="true"
                  label={t('REPORT.FILTER.CASH')}
                  checked={checkCash}
                  style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }}
                />
              </Col>

              <Col sm={2} md={2} xl={2} className={'pt-2 px-1'}>
                <InputCheckbox
                  onChange={() => {
                    setCheckTreatmentRight(!checkTreatmentRight)
                    setRemittancePage(1)
                  }}
                  value="true"
                  label={t('REPORT.FILTER.INCOME')}
                  checked={checkTreatmentRight}
                  style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }}
                />
              </Col> */}

              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <RemittanceList
              page={remittancePage}
              pageLimit={remittanceLimitPage}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={remittanceData}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              componentRef={componentRef}
              dataProcess={dataProcess}
              branchInfo={branchInfo}
              filter={filter}
            />
          </Box>
        </Card.Body>
      </Card>
      <Loading show={loading} type="fullLoading" />
      {popSettingDisplay &&
        <ModalSettingDisplay
          onClose={onCloseSentingDisplay}
          dataProcess={dataProcess}
          handleChangeSettingDisplay={handleChangeSettingDisplay}
        />
      }
    </div>
  )
}

type ModalSettingDisplayProps = {
  onClose: () => void
  dataProcess: SelectorProcessProps
  handleChangeSettingDisplay: (data: SelectorProcessProps) => void
}

const ModalSettingDisplay = (props: ModalSettingDisplayProps) => {
  const { t } = useTranslation()
  const { onClose, dataProcess, handleChangeSettingDisplay } = props

  const [infoProcess, setInfoProcess] = useState<SelectorProcessProps>(dataProcess)
  const keyPass = [1, 3]

  const onHandleChangeSettingDisplay = (elementName: 'isShow' | 'isProcess', index: number) => {
    const newDataProcess = Object.values(infoProcess)
    if (elementName === 'isShow' && !keyPass.includes(index)) {
      const newIsProcess = !newDataProcess[index][elementName] ? {} : { isProcess: false }
      newDataProcess[index] = { ...newDataProcess[index], [elementName]: !newDataProcess[index][elementName], ...newIsProcess }
    } else {
      newDataProcess[index] = { ...newDataProcess[index], [elementName]: !newDataProcess[index][elementName] }
    }
    setInfoProcess({ ...infoProcess, [newDataProcess[index].name]: { ...newDataProcess[index] } })
  }

  const renderFormSettingDisplay = (data: SubSelectorProcessProps, index: number) => {
    return (
      <Grid container item xs={12} key={index}>
        <Grid item xs={6} >
          <Box sx={{ marginLeft: data.type !== 'topic' ? '1rem' : '' }}>
            {`${data.type !== 'topic' ? '-' : ''}${t(`REPORT.MODAL_DISPLAY.${data.name}`)}`}
          </Box>
        </Grid>
        <Grid item xs={3} sx={{ '.MuiButtonBase-root': { paddingLeft: '0 !important' } }}>
          {data?.isShow !== undefined &&
            <InputCheckbox
              checked={data.isShow}
              onChange={() => onHandleChangeSettingDisplay('isShow', index)}
            />
          }
        </Grid>
        <Grid item xs={3} sx={{ '.MuiButtonBase-root': { paddingLeft: '0 !important' } }}>
          {data?.isProcess !== undefined &&
            <InputCheckbox
              checked={data?.isProcess}
              onChange={() => onHandleChangeSettingDisplay('isProcess', index)}
              disabled={!keyPass.includes(index) && !data.isShow}
            />
          }
        </Grid>
      </Grid>
    )
  }

  return (
    <ModalCustom
      title={t('REPORT.MODAL_DISPLAY.TITLE')}
      size={'lg'}
      onClose={() => onClose()}
      onSubmit={() => {
        handleChangeSettingDisplay(infoProcess)
        onClose()
      }}
      textBtnConfirm={t('REPORT.MODAL_DISPLAY.BUTTON_SUBMIT')}
      textBtnCancel={t('BUTTON.CANCEL')}
      closeButton
      alignFooter='end'
      component={(
        <Box>
          <Grid container>
            <Grid container item xs={12}>
              <Grid item xs={6} />
              <Grid item xs={3} >
                {t('REPORT.MODAL_DISPLAY.ISSHOW')}
              </Grid>
              <Grid item xs={3} >
                {t('REPORT.MODAL_DISPLAY.ISPROCESS')}
              </Grid>
            </Grid>
            {infoProcess && Object.values(infoProcess).map((item, index) => {
              return (renderFormSettingDisplay(item, index))
            })}
          </Grid>
        </Box>
      )}
    />
  )
}