import { Typography } from '@mui/material'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

type EmptyDataProps = {
  className?: string
}

export default function EmptyData(props: EmptyDataProps) {
  const { t } = useTranslation()

  return (
    <Typography className={`text-center ${props.className}`}>{t('REGISTER.NO_ITEM')}</Typography>
  )
}
