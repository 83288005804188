import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/84.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 52.7, height: 101.14, viewBox: "0 0 52.7 101.14" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 52.7, height: 101.14, viewBox: "0 0 52.7 101.14" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>84</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12351">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5563"
                                d="M16.061 91.4413C12.5836 74.4851 18.7939 58.8462 19.2729 45.1124C19.3582 42.6915 23.0034 42.608 23.203 45.0226C24.2769 58.0301 31.3328 68.8829 30.0729 87.1289C29.8017 91.0635 35.7852 91.6903 36.3913 87.7919L36.4067 87.6895C37.7718 78.7942 39.4053 69.8788 39.829 60.9203C40.4414 47.979 44.6017 36.1511 49.6857 24.4612C52.0149 19.1052 54.279 12.7411 50.0666 8.31146C44.9059 2.88476 37.9939 -0.666341 29.7345 4.31421C26.0928 6.51109 22.3656 6.08839 18.7604 2.75239C13.5358 -2.08299 8.26152 0.206473 3.69009 4.26911C1.34606 6.35254 -3.10088 60.0018 5.25112 83.4222C6.61617 87.2498 7.70467 91.3776 10.7923 94.274C13.036 96.3801 16.6724 94.4219 16.061 91.4413Z"
                                transform={width > 1440 ? "translate(0 -10)" : "translate(0 -5)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />

                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5563"
                                    d="M16.061 91.4413C12.5836 74.4851 18.7939 58.8462 19.2729 45.1124C19.3582 42.6915 23.0034 42.608 23.203 45.0226C24.2769 58.0301 31.3328 68.8829 30.0729 87.1289C29.8017 91.0635 35.7852 91.6903 36.3913 87.7919L36.4067 87.6895C37.7718 78.7942 39.4053 69.8788 39.829 60.9203C40.4414 47.979 44.6017 36.1511 49.6857 24.4612C52.0149 19.1052 54.279 12.7411 50.0666 8.31146C44.9059 2.88476 37.9939 -0.666341 29.7345 4.31421C26.0928 6.51109 22.3656 6.08839 18.7604 2.75239C13.5358 -2.08299 8.26152 0.206473 3.69009 4.26911C1.34606 6.35254 -3.10088 60.0018 5.25112 83.4222C6.61617 87.2498 7.70467 91.3776 10.7923 94.274C13.036 96.3801 16.6724 94.4219 16.061 91.4413Z"
                                    transform={width > 1440 ? "translate(0 -10)" : "translate(0 -5)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12351">
                                <path
                                    data-name="Path 5564"
                                    d="M25.9607 41.0938C35.2383 40.2303 39.1318 42.2313 43.2895 39.5083C50.7672 34.6123 53.8646 23.803 53.4874 20.078C53.3868 18.9489 53.229 17.8395 53.0602 16.8182C52.8562 15.4167 52.5256 14.0366 52.0725 12.6947C52.0054 12.5117 47.2925 1.69967 35.6805 2.40189C30.8026 2.69559 28.3036 10.5685 23.4877 4.83358C15.3866 -4.81118 4.60275 0.442568 1.09637 16.831C1.11272 17.3345 -0.956318 27.7981 5.91462 32.94C10.4106 36.3057 12.7235 42.3265 25.9607 41.0938Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5565"
                                    d="M25.6352 41.6549C34.5559 40.8251 38.2983 42.748 42.2965 40.1303C49.4848 35.4236 52.4627 25.0309 52.1006 21.4491C52.0035 20.3634 51.852 19.2975 51.6897 18.3154C51.4936 16.9679 51.1757 15.641 50.7401 14.3508C50.6757 14.1748 46.1442 3.78029 34.9804 4.45415C30.2902 4.73819 27.8892 12.3062 23.257 6.79246C15.4691 -2.47922 5.10084 2.57203 1.73037 18.3272C1.74573 18.8116 -0.243337 28.8716 6.36168 33.8149C10.6847 37.0508 12.9092 42.8396 25.6352 41.6549Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5566"
                                    d="M6.60852 25.0396L6.61305 25.0931C6.77989 27.6247 9.05123 29.6545 12.0145 31.1458C16.3819 33.3337 22.2376 34.3566 25.065 34.0808C27.6373 33.8413 33.5086 33.0494 37.6321 32.5932C39.7809 32.3512 41.458 32.2105 41.9524 32.2714C42.0235 32.2869 42.0967 32.2912 42.1691 32.284C46.9162 32.7656 47.3582 27.9239 46.9119 23.5093C46.8347 22.6495 46.7149 21.804 46.5863 21.0274C46.431 19.9597 46.179 18.9084 45.8334 17.8864C45.7826 17.7467 42.1927 9.51172 33.3491 10.0467C29.6329 10.2718 27.7299 16.2672 24.061 11.8983C17.8913 4.55302 9.6782 8.55499 7.00779 21.0366C7.01961 21.4205 6.71489 22.317 6.61876 23.6552C6.62101 23.6886 6.61916 23.7222 6.61325 23.7551C6.60517 23.8321 6.60517 23.9093 6.60418 23.9863C6.58895 24.3165 6.5904 24.6676 6.60852 25.0396Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />

                                {(isToothRoot && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6577" d="M4.5011 2.14796C4.38258 3.6073 3.1477 6.2542 2.73776 13.433C2.47195 21.5168 6.88067 64.0155 6.68299 64.1749C6.66811 64.3306 2.24937 21.3453 2.17961 13.5861C2.37077 7.3791 3.25744 4.63076 3.59002 2.06979C3.88779 2.14314 4.19519 2.16951 4.5011 2.14796Z" transform={width > 1440 ? "translate(5 16)" : "translate(5 22)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                        <path data-name="Path 6578" d="M28.074 6.72817C27.6139 8.11814 27.0366 11.5619 27.3515 18.4637C27.7876 28.2387 27.9553 59.5267 28.074 59.7504C28.027 59.8996 28.3053 22.6092 27.981 18.4335C27.4901 12.4315 28.09 9.01016 28.7828 6.52233C28.5412 6.57094 28.3041 6.63979 28.074 6.72817Z" transform={width > 1440 ? "translate(5 16)" : "translate(5 22)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                    </>
                                    : ''}

                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4107_155426" x1="26.9932" y1="19.0374" x2="24.0813" y2="35.658" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4107_155426" x1="26.9776" y1="31.5516" x2="26.9776" y2="25.3871" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M17.7689 96.8022C14.3441 80.103 20.4603 64.701 20.9321 51.1753C21.0161 48.7912 24.6061 48.7089 24.8026 51.0869C25.8603 63.8973 32.8093 74.5857 31.5685 92.5552C31.3014 96.4302 37.1942 97.0474 37.7912 93.2081L37.8063 93.1073C39.1507 84.3468 40.7594 75.5665 41.1767 66.7438C41.7798 53.9985 45.8771 42.3499 50.8841 30.837C53.178 25.5622 55.4078 19.2945 51.2592 14.932C46.1767 9.58756 39.3695 6.09026 31.2352 10.9953C27.6487 13.1589 23.978 12.7426 20.4273 9.4572C15.2819 4.69508 10.0876 6.94985 5.5854 10.9509C3.2769 13.0028 -1.10266 65.8392 7.12279 88.9046C8.46715 92.6743 9.53915 96.7396 12.58 99.592C14.7897 101.666 18.371 99.7377 17.7689 96.8022Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M26.5446 41.3112C35.6816 40.4607 39.516 42.4315 43.6108 39.7498C50.9751 34.9279 54.0257 24.2824 53.6542 20.6138C53.555 19.5019 53.3996 18.4093 53.2334 17.4034C53.0325 16.0232 52.707 14.6639 52.2607 13.3424C52.1946 13.1622 47.5531 2.51398 36.1171 3.20557C31.3131 3.49481 28.8519 11.2485 24.109 5.6004C16.1307 -3.89822 5.51023 1.27593 2.05698 17.416C2.07308 17.9119 0.0353867 28.2169 6.80221 33.2809C11.2301 36.5956 13.5079 42.5252 26.5446 41.3112Z" fill="#EFEDE7" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M26.2221 41.8651C35.0076 41.0478 38.6932 42.9416 42.6309 40.3636C49.7102 35.7282 52.643 25.493 52.2864 21.9654C52.1908 20.8961 52.0416 19.8464 51.8818 18.8792C51.6886 17.5521 51.3755 16.2453 50.9465 14.9747C50.8831 14.8013 46.4203 4.56434 35.4256 5.22798C30.8065 5.50772 28.4419 12.9611 23.8798 7.53087C16.21 -1.60033 5.99881 3.37439 2.67941 18.8908C2.69454 19.3679 0.735612 29.2754 7.24055 34.1438C11.4981 37.3307 13.6888 43.0318 26.2221 41.8651Z" fill="#F7F7F7" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M7.49111 25.5016L7.49557 25.5544C7.65988 28.0476 9.8968 30.0467 12.8152 31.5154C17.1164 33.6701 22.8834 34.6775 25.668 34.4059C28.2013 34.17 33.9836 33.3901 38.0446 32.9408C40.1609 32.7024 41.8125 32.5639 42.2995 32.6239C42.3695 32.6392 42.4415 32.6434 42.5129 32.6363C47.1881 33.1106 47.6234 28.3423 47.1838 23.9945C47.1077 23.1477 46.9898 22.3151 46.8631 21.5502C46.7102 20.4988 46.462 19.4634 46.1217 18.4568C46.0716 18.3193 42.5361 10.2091 33.8265 10.736C30.1666 10.9577 28.2925 16.8622 24.6792 12.5595C18.603 5.32551 10.5143 9.26684 7.88433 21.5593C7.89597 21.9374 7.59586 22.8203 7.5012 24.1383C7.50341 24.1711 7.50159 24.2042 7.49577 24.2366C7.48781 24.3125 7.48781 24.3885 7.48684 24.4644C7.47184 24.7895 7.47326 25.1353 7.49111 25.5016Z" fill="white" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M33.5523 14H20.4477C18.5436 14 17 15.5436 17 17.4477V30.5523C17 32.4564 18.5436 34 20.4477 34H33.5523C35.4564 34 37 32.4564 37 30.5523V17.4477C37 15.5436 35.4564 14 33.5523 14Z" fill="#C1C4C5" />
                                            {/* <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M33.5498 14.8623H20.4451C19.0171 14.8623 17.8594 16.02 17.8594 17.4481V30.5527C17.8594 31.9808 19.0171 33.1385 20.4451 33.1385H33.5498C34.9779 33.1385 36.1355 31.9808 36.1355 30.5527V17.4481C36.1355 16.02 34.9779 14.8623 33.5498 14.8623Z" stroke="#C1C4C5" /> */}
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M25.4785 33.1039C25.1338 33.7279 28.3677 33.4193 30.7931 33.3883C34.4029 33.3125 36.4629 34.0503 36.4284 25.7172C36.5221 22.7603 36.4305 19.8004 36.1543 16.8549C35.2389 15.9654 36.3956 25.881 34.0529 30.2423C31.843 33.3917 26.0698 32.435 25.4785 33.1039Z" fill="#B0B1B1" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.0815 17.4756H23.1861C22.7101 17.4756 22.3242 17.8615 22.3242 18.3375V20.0613C22.3242 20.5374 22.7101 20.9233 23.1861 20.9233H30.0815C30.5575 20.9233 30.9434 20.5374 30.9434 20.0613V18.3375C30.9434 17.8615 30.5575 17.4756 30.0815 17.4756Z" fill="#9E9E9E" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.4409 17.4756H23.5455C23.0695 17.4756 22.6836 17.8615 22.6836 18.3375V20.0613C22.6836 20.5374 23.0695 20.9233 23.5455 20.9233H30.4409C30.9169 20.9233 31.3028 20.5374 31.3028 20.0613V18.3375C31.3028 17.8615 30.9169 17.4756 30.4409 17.4756Z" fill="url(#paint0_linear_4107_155426)" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.4253 25.5742H23.5299C23.0539 25.5742 22.668 25.9601 22.668 26.4361V28.16C22.668 28.636 23.0539 29.0219 23.5299 29.0219H30.4253C30.9013 29.0219 31.2872 28.636 31.2872 28.16V26.4361C31.2872 25.9601 30.9013 25.5742 30.4253 25.5742Z" fill="#CBCCCC" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.4253 25.2285H23.5299C23.0539 25.2285 22.668 25.6144 22.668 26.0904V27.8143C22.668 28.2903 23.0539 28.6762 23.5299 28.6762H30.4253C30.9013 28.6762 31.2872 28.2903 31.2872 27.8143V26.0904C31.2872 25.6144 30.9013 25.2285 30.4253 25.2285Z" fill="url(#paint1_linear_4107_155426)" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M1.24219 22.8691C13.983 23.5688 24.7547 25.9741 54.1926 23.025" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M1.24219 22.3398C13.0868 23.1051 27.3669 25.482 54.1926 22.364" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
