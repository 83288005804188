import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ProductUsesInterface {
  group: string
  productUseDetail: string
  status?: string
}

export interface FindAllProductUsesInterface extends FindAllInterface {
  group: string
}

export default class ProductSymptomApi extends BaseAPI {
  static findAll(props: FindAllProductUsesInterface): Promise<any> {
    return this.api.get('product-symptoms', { params: { ...props } }).then((res) => res)
  }
  static create(payload: any) {
    return this.api.post('product-symptoms', payload)
  }
  static findById(id: number): Promise<ProductUsesInterface> {
    return this.api.get(`product-symptoms/${id}`)
  }
  static update(id: number, body: ProductUsesInterface) {
    return this.api.patch(`product-symptoms/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`product-symptoms/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`product-symptoms/${id}`)
  }
}
