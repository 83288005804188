import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/11.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginTop: 'auto',
  top: '1.4%',
  left: '45.2%',
}))

export default function Teeth11(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = operative ? props.color || colors.themeSecondColor : ''
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    setStyleSvg({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <DivTeethU className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9164" >
              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ''}
              <path
                data-name="Path 5278"
                d="M488.255 116.389c1.682 4.164 1.231 8.662 1.2 11.26 0 12.5.019 12.755-13.167 13.631a53.755 53.755 0 0 1-14.63-1.457c-5.53-1.192-9.4-8.179-8.076-14.424 4.047-19.11 5.3-38.651 8.972-57.819.793-4.157.322-8.553.438-12.839.052-1.934-.155-4.08 2.359-4.686 2.565-.619 4.009.947 5.079 2.991a77.569 77.569 0 0 1 6.8 20.392c2.995 14.489 5.444 29.113 11.025 42.951z"
                transform="translate(-452.786 -49.918)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />
              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6517"
                  d="M488.661 117.475a98.216 98.216 0 0 0-5.746-11.161c-1.93-3.026-2.661-3.717-4.176-6.324-2.062-2.406-2.219-3.641-5.719-4.875-3.016-.562-5.375.969-7.734 3.234-3.078 3.422-3.7 4.859-6.17 8.77a50.234 50.234 0 0 0-3.535 7.577c2.51-15.261 4-31.629 6.97-47.116.793-4.157.322-8.553.438-12.839.052-1.934-.155-4.08 2.359-4.686 2.565-.619 4.009.947 5.079 2.991a77.569 77.569 0 0 1 6.8 20.392c2.998 14.489 5.853 30.199 11.434 44.037z"
                  transform="translate(-452.786 -49.918)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 9722" >
                <path
                  data-name="Path 5279"
                  d="M472.044 167.763c-4.766-.443-11.076 1.14-15.341-4.174-3.885-4.841-2.605-10.305-1.95-15.326 1.13-8.656 3.868-17.029 10.036-24.225 4.687-5.469 9.59-5.774 13.583-.022 6.882 9.911 13.322 20.119 12.9 32.3-.3 8.654-3.788 11.354-13.695 11.448-1.516.012-3.038-.001-5.533-.001z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-453.034 -83.872)" : "translate(-453.034 -74.766)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5280"
                  d="M470.035 168.041c-4.638-.407-10.778 1.046-14.929-3.832-3.781-4.443-2.535-9.459-1.9-14.068 1.1-7.945 3.765-15.63 9.766-22.236 4.561-5.019 9.332-5.3 13.218-.019 6.7 9.1 12.963 18.466 12.558 29.644-.288 7.944-3.687 10.421-13.327 10.508-1.478.014-2.959.003-5.386.003z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-452.49 -85.372)" : "translate(-452.49 -76.266)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5281"
                  d="M471.6 168.606c-4.114-.383-9.561.984-13.243-3.6-3.354-4.178-2.249-8.895-1.683-13.23.975-7.471 3.339-14.7 8.663-20.911 4.046-4.721 8.277-4.983 11.724-.019 5.94 8.555 11.5 17.366 11.14 27.879-.256 7.471-3.27 9.8-11.821 9.882-1.312.01-2.626-.001-4.78-.001z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-453.752 -86.504)" : "translate(-453.752 -77.398)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />
                {(isToothRoot && props.type === 'treatment') ?
                  <path
                    data-name="Path 6565"
                    d="M6.466 31.7C6.35 16.21 4.178 13.74 4.659 12.3a42.7 42.7 0 0 1 .976 8.479c-.015 2.724.429 7.162.43 10.923-.283 8.71 1.235 16.583 1.528 21.313a6.151 6.151 0 0 1 1.848-.224c-.068-1.258-3.287-3.199-2.975-21.091z"
                    transform="translate(9.726 -8.191)"
                    style={{
                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || ((isToothRoot && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || ((isToothRoot && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  : ''}
                {(isExtract && props.type === 'treatment') ?
                  <>
                    <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-1.245 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                  </>
                  : ''}
                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <linearGradient id="l6wxivalja" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="wcuk3nvzhb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9384">
                      <g data-name="Group 9380" transform="translate(.296 66.698)">
                        <g data-name="Rectangle 3927" transform="translate(13.517)" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                          <rect width="11.601" height="11.601" rx={2} style={{ stroke: 'none' }} />
                          <rect x=".5" y=".5" width="10.601" height="10.601" rx="1.5" style={{ fill: 'none' }} />
                        </g>
                        <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(6.863 -6.464)" style={{ fill: '#b0b1b1' }} />
                        <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(16.806 6.215)" style={{ fill: '#cbcccc' }} />
                        <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(16.605 2.015)" style={{ fill: '#9e9e9e' }} />
                        <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(16.806 2.015)" style={{ fill: 'url(#l6wxivalja)' }} />
                        <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(16.806 6.015)" style={{ fill: 'url(#wcuk3nvzhb)' }} />
                      </g>
                      <path data-name="Path 6488" d="M-.743 11.94s16.159-5.691 37.4-1.181" transform="translate(1.381 63.118)" style={{ strokeLinecap: 'round', stroke: '#babbbb', fill: 'none' }} />
                      <path data-name="Path 6486" d="M-.526 11.912a76.245 76.245 0 0 1 37.45-1.243" transform="translate(1.195 63.799)" style={{ stroke: '#b5b7b7', strokeLinecap: 'round', fill: 'none' }} />
                    </g>
                  </> || ''}
                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="tv9lqifg5a" x1=".209" y1="-.059" x2="1.176" y2="1.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="35mbhgboxb" x1="-.342" y1="-.081" x2="1.352" y2={1} gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>

                    <g data-name="Group 9545">
                      <g data-name="Path 6490" transform="translate(9.671)" style={{ fill: 'url(#tv9lqifg5a)' }}>
                        <path d="M17.343 44.996H1.734c-.422 0-.825-.367-1.135-1.033-.322-.69-.499-1.61-.499-2.59C.845 24.493 1.818 12.58 2.991 5.97 3.682 2.075 4.427.1 5.203.1h8.671c.852 0 5.103 1.99 5.103 41.273 0 .98-.177 1.9-.499 2.59-.31.666-.713 1.033-1.135 1.033z" style={{ stroke: 'none' }} />
                        <path d="M17.343 44.896c.74 0 1.534-1.416 1.534-3.523 0-16.645-.753-28.547-2.238-35.377-.504-2.319-1.081-3.983-1.715-4.946-.486-.74-.867-.85-1.05-.85H5.203c-.406 0-1.22.752-2.114 5.788C1.917 12.593.945 24.498.2 41.378c.001 2.104.794 3.518 1.534 3.518h15.609m0 .2H1.734C.776 45.096 0 43.429 0 41.373 1.734 2.085 4.245 0 5.203 0h8.671c.958 0 5.203 2.085 5.203 41.373 0 2.056-.777 3.723-1.734 3.723z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <g data-name="Path 6489" transform="translate(1 42.63)" style={{ strokeLinejoin: 'round', fill: 'url(#35mbhgboxb)' }}>
                        <path d="M34.685 7.569H1.735c-.898 0-1.628-.807-1.635-1.8C.926 2.761 1.14 1.32 1.243.626 1.321.1 1.33.1 1.413.1c.027 0 .061.002.1.005.06.004.132.008.221.008h33.058l.083-.001c.355 0 .362 0 .626 1.491.152.859.381 2.155.818 4.163-.005.995-.736 1.803-1.634 1.803z" style={{ stroke: 'none' }} />
                        <path d="M34.685 7.469c.84 0 1.524-.758 1.534-1.693a98.161 98.161 0 0 1-.817-4.155c-.127-.72-.197-1.115-.28-1.3-.042-.095-.048-.11-.247-.11l-.19.002H1.735c-.093 0-.168-.005-.228-.008C1.473.202 1.443.2 1.42.2c-.023.073-.049.246-.078.442-.103.694-.317 2.136-1.142 5.14.013.932.696 1.687 1.534 1.687h32.951m0 .2H1.735C.775 7.669 0 6.812 0 5.755 1.734-.555.776.013 1.734.013h32.951c.958 0 .43-.24 1.734 5.742 0 1.057-.776 1.914-1.734 1.914z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <path data-name="Path 6491" d="M5.625 21.2s18.057.9 18.962 1.957" transform="translate(4.161 15.597)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6492" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(4.447 10.345)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6493" d="M7.08 21.281s14.6.592 15.5 1.645" transform="translate(4.895 -11.973)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6584" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(4.703 5.802)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6585" d="M6.115 21.2S23.1 22.185 24 23.237" transform="translate(4.703 -.222)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6586" d="M6.643 21.3s16.036.854 16.941 1.907" transform="translate(4.703 -6.281)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6587" d="M8.144 21.444s12.509.373 13.415 1.426" transform="translate(4.895 -17.717)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                    </g>
                  </>
                  : ''}
              </g>
            </g>
          </svg>}
      </DivTeethU>

      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <TextNumberU>11</TextNumberU>
    </Div >
  )
}
