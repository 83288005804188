import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ProductFormatsInterface {
  group: string
  productFormatName: string
  productFormatNameEn: string
  status?: string
}

export default class ProductFormatsApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('product-formats', { params: { ...props } }).then((res) => res)
  }
  static create(payload: any) {
    return this.api.post('product-formats', payload)
  }
  static findById(id: number): Promise<ProductFormatsInterface> {
    return this.api.get(`product-formats/${id}`)
  }
  static update(id: number, body: ProductFormatsInterface) {
    return this.api.patch(`product-formats/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`product-formats/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`product-formats/${id}`)
  }
}
