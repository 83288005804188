import Dropdown from 'react-bootstrap/Dropdown'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { makeStyles } from '@material-ui/core'
import { useState } from 'react'
import { colors } from 'constants/theme'
import InputCheckbox from 'component/Input/InputCheckbox'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  btnDropdown: {
    '& .btn': {
      width: '10vw',
      fontSize: '16px !important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colors.white,
      border: `1px solid ${colors.black12} !important`,
      color: colors.black60
    },
    '& .btn-primary': {
      width: '100%',
      backgroundColor: `${colors.white} !important`,
      border: `1px solid ${colors.black12} !important`,
      color: `${colors.black60} !important`
    },
    '& .btn-check': {
      backgroundColor: `${colors.white} !important`,
      color: `${colors.black60} !important`,
      border: `1px solid ${colors.black12} !important`
    }
  },
  dropDownMenu: {
    padding: '8px 16px',
    '& .ant-checkbox-wrapper': {
      fontSize: '16px',
      margin: '0px'
    },
    '& .css-ahj2mt-MuiTypography-root': {
      fontSize: '16px'
    }
  },
  clearMenu: {
    fontSize: '16px',
    color: colors.themeSecondColor,
    textDecoration: 'underline'
  }
}))

interface CheckboxSelectProps {
  allCheck?: boolean
  check?: boolean
  onChangeAllCheck: (value: any) => void
  onChangeCheck: (value: any) => void
  onClearAll: (value: any) => void
  objDentists: any
  label: string
}

export default function CheckboxSelect(props: CheckboxSelectProps) {
  const { t } = useTranslation()

  const classes = useStyles()
  const [checkTest, setCheckTest] = useState(false)

  return (
    <div>
      <Dropdown className={`${classes.btnDropdown} d-inline`} autoClose="outside">
        <Dropdown.Toggle id="dropdown-autoclose-outside">{props.label}</Dropdown.Toggle>
        <Dropdown.Menu className={classes.dropDownMenu}>
          <InputCheckbox checked={props.objDentists.find((dentist: any) => dentist.check === false) ? false : props.allCheck} onChange={props.onChangeAllCheck} label={'All'} />
          <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
            {props.objDentists.map((dentist: any, index: any) => {
              return (
                <Checkbox
                  key={index}
                  checked={dentist.check}
                  onChange={(e) => {
                    const tempDentist = props.objDentists
                    tempDentist[index].check = e.target.checked
                    props.onChangeCheck(tempDentist)
                    setCheckTest(!checkTest)
                  }}>
                  {`  ${dentist.label}`}
                </Checkbox>
              )
            })}
          </div>
          <Dropdown.Divider />
          <div
            className={classes.clearMenu}
            onClick={(e) => {
              props.onClearAll(e)
              setCheckTest(!checkTest)
            }}>
            {t('BUTTON.CLEAR')}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
