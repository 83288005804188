import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { MenuItem, Box, Divider } from '@mui/material'
import { useDispatch } from 'react-redux'


/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles, { CardHeader } from 'features/setting/defaults/template-text/style'

/** API */
import TemplateTextApi from 'api/setting/defaults/template-text.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { colors } from 'constants/theme'

import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import InputTextarea from 'component/Input/InputTextarea'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'
import FilterSelect from 'component/Select/FilterSelect'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

const initStateErrorMessage = {
  TEMPLATE_TEXT: ``,
  IS_DUPLICATE_TEMPLATE_TEXT: ``,
  TEMPLATE_TEXT_STRING_BASE: ``,
  TEMPLATE_TEXT_STRING_EMPTY: ``,
  TEMPLATE_TEXT_MAXIMUM_LENGTH: ``,
  TEMPLATE_TEXT_ANY_REQUIRED: ``,

  CONTINUE_USED_REQUIRED: ``,
  USER_USED_REQUIRED: ``,
  MASSAGE_TYPE_REQUIRED: ``,
  MASSAGE_ANY_REQUIRED: ``,

  MASSAGE_STRING_BASE: '',
  MASSAGE_STRING_EMPTY: '',
  MASSAGE_MAXIMUM_LENGTH: ''
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function TemplateText() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [templateTexts, setTemplateTexts] = useState([])
  const [templateTextId, setTemplateTextId] = useState(Number)
  const [users, setUsers]: any = useState([])
  const [user, setUser]: any = useState({ type: 'text', name: ``, value: 0 })
  const [useWith, setUseWith] = useState<"BRANCH" | "USER" | ''>('')
  const [textType, setTextType] = useState<'SERVICE_TXT' | 'LINE_TXT' | 'PRACTICE_TXT' | 'REMARK_TXT' | '' | 'ADVANCE_TXT'>('')
  const [userSelect, setUserSelect] = useState<number>()
  const [message, setMessage] = useState('')
  const [templateTextStatus, setTemplateTextStatus] = useState('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')
  const [filterTextType, setFilterTextType] = useState<string>('ALL')
  const [filterUseWith, setFilterUseWith] = useState('ALL')

  const useWithSelect = [
    { value: 'BRANCH', label: t('TEMPLATE_TEXT.BRANCH') },
    { value: 'USER', label: t('TEMPLATE_TEXT.USER') }
  ]
  const textTypeSelect = [
    { value: 'SERVICE_TXT', label: t('TEMPLATE_TEXT.SERVICE_TXT') },
    { value: 'LINE_TXT', label: t('TEMPLATE_TEXT.LINE_TXT') },
    { value: 'PRACTICE_TXT', label: t('TEMPLATE_TEXT.PRACTICE_TXT') },
    { value: 'REMARK_TXT', label: t('TEMPLATE_TEXT.REMARK_TXT') },
    { value: 'ADVANCE_TXT', label: t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.ADVANCE_TXT') }
    // { value: 'APP_TXT', label: t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.APP_TXT') }
  ]

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
    if (filterTextType) condition = { ...condition, textType: filterTextType === 'ALL' ? '' : filterTextType }
    if (filterUseWith) condition = { ...condition, useWith: filterUseWith === 'ALL' ? '' : filterUseWith }

    const res = await TemplateTextApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setTemplateTexts(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status, filterTextType, filterUseWith])

  useEffect(() => {
    loadData()
  }, [loadData])

  const loadDoctor = useCallback((searchSelect = ' ') => {
    PersonnelApi.findAll({ status: 'ACTIVE', search: searchSelect, pageLimit: 100 }).then(({ data }) => {
      if (data) {
        const newUser: any[] = []
        for (const index in data) {
          const user = data[index]
          newUser.push({ type: 'text', name: `${user.firstname} ${user.lastname}`, value: user.userId })
        }
        setUsers(newUser)
      }
    })
  }, [])

  useEffect(() => {
    loadDoctor()
  }, [])

  const submit = () => {
    if (!useWith || (useWith === 'USER' && !userSelect) || !textType || !message) {
      return setErrorMessage({
        ...errorMessage,
        ...{
          CONTINUE_USED_REQUIRED: !useWith ? t('กรุณากรอกการนำไปใช้งาน') : '',
          USER_USED_REQUIRED: useWith === 'USER' && !userSelect ? t('TEMPLATE_TEXT.MESSAGE.USER_USED_REQUIRED') : '',
          MASSAGE_TYPE_REQUIRED: !textType ? t('กรุณากรอกประเภทข้อความเพิ่มเติม') : '',
          MASSAGE_ANY_REQUIRED: !message ? t('กรุณากรอกข้อความเพิ่มเติม') : ''
        }
      })
    }

    const objCreate = {
      useWith: useWith,
      userId: userSelect,
      textType: textType,
      message: message,
      status: 'ACTIVE'
    }

    dispatch(submitModal())

    TemplateTextApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('TEMPLATE_TEXT.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()

          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_TEXT.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_TEXT.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {

    if (!useWith || (useWith === 'USER' && !userSelect) || !textType || !message) {
      return setErrorMessage({
        ...errorMessage,
        ...{
          CONTINUE_USED_REQUIRED: !useWith ? t('กรุณากรอกการนำไปใช้งาน') : '',
          USER_USED_REQUIRED: useWith === 'USER' && !userSelect ? t('TEMPLATE_TEXT.MESSAGE.USER_USED_REQUIRED') : '',
          MASSAGE_TYPE_REQUIRED: !textType ? t('กรุณากรอกประเภทข้อความเพิ่มเติม') : '',
          MASSAGE_ANY_REQUIRED: !message ? t('กรุณากรอกข้อความเพิ่มเติม') : ''
        }
      })
    }

    const objCreate = {
      useWith: useWith,
      userId: userSelect,
      textType: textType,
      message: message,
      status: templateTextStatus
    }
    dispatch(submitModal())

    TemplateTextApi.update(templateTextId, objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`TEMPLATE_TEXT.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          if (err.message === 'IS_DUPLICATE_TEMPLATE_TEXT') {

            setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_TEXT.MESSAGE.${err.message}`) } })
          } else setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_TEXT.MESSAGE.${err.message}`) } })

          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TEMPLATE_TEXT.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setTemplateTextId(0)
    setTextType('')
    setTemplateTextStatus('')
    setUseWith('')
    setUserSelect(0)
    setUser({ type: 'text', name: ``, value: 0 })
    loadDoctor()
    setMessage('')
    clearErrorMessage()
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TEMPLATE_TEXT.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          TemplateTextApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('TEMPLATE_TEXT.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('TEMPLATE_TEXT.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { templateTextId: tempTextId, message: tempTextMessage } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TEMPLATE_TEXT.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TEMPLATE_TEXT.ALERT.CONFIRM_DELETE_', {
        templateText: tempTextMessage
      })}</p>`,
      (res: any) => {
        if (res) {
          TemplateTextApi.remove(tempTextId)
            .then((resp) => {
              notiSuccess(t('TEMPLATE_TEXT.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('TEMPLATE_TEXT.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { templateTextId: tempTextId, textType: tempTextType, useWith: tempTextUseWith, userId, message: tempTextMessage, status: tempTextStatus, userFUllname } = data
    setTemplateTextId(tempTextId)
    setTextType(tempTextType)
    setTemplateTextStatus(tempTextStatus)
    setUseWith(tempTextUseWith)
    if (userId) {
      setUserSelect(userId)
      setUser({ type: 'text', name: userFUllname, value: userId })
    }
    setMessage(tempTextMessage)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const handleChangeTextType = (valueTextType: string) => {
    setFilterTextType(valueTextType)
  }

  const handleChangeUseWith = (valueUseWith: string) => {
    setFilterUseWith(valueUseWith)
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'textType', disablePadding: false, label: t('TEMPLATE_TEXT.TABLE.CELL.TYPE'), align: 'left' },
    { id: 'useWith', disablePadding: false, label: t('TEMPLATE_TEXT.TABLE.CELL.USE_WITH'), align: 'left' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_TEXT.TABLE.CELL.TEMPLATE_TEXT') },
    { id: 'status', disablePadding: false, label: t('TEMPLATE_TEXT.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('TEMPLATE_TEXT.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { templateTextId: tempTextId, textType: tempTextType, useWith: tempTextUseWith, message: tempTextMessage, updatedBy, updatedAt, status: tempTextStatus } = objData
    const statusBtnActive = tempTextStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = tempTextStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: tempTextId,
      id: tempTextId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: t(`TEMPLATE_TEXT.${tempTextType}`) },
        { option: 'TEXT', align: 'left', label: tempTextUseWith === 'BRANCH' ? t(`TEMPLATE_TEXT.BRANCH`) : t(`TEMPLATE_TEXT.USER`) },
        { option: 'TEXT', align: 'left', label: tempTextMessage },
        { option: 'STATUS', align: 'center', label: tempTextStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <CardHeader className="border-0 h-100">
        <HeaderCard text={t('TEMPLATE_TEXT.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={3} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={3} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={3} className="pl-xl-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeTextType(value)
                        }
                      }}
                      renderValue={() => `${t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.LABEL')}: ${t(`TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.${filterTextType}`)}`}
                      label={t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.LABEL')}
                      selectId="select-message-type"
                      value={filterTextType}
                      labelId="label-message-type"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="SERVICE_TXT">
                          {t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.SERVICE_TXT')}
                        </MenuItem>,
                        <MenuItem key="3" value="LINE_TXT">
                          {t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.LINE_TXT')}
                        </MenuItem>,
                        <MenuItem key="4" value="REMARK_TXT">
                          {t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.REMARK_TXT')}
                        </MenuItem>,
                        <MenuItem key="5" value="PRACTICE_TXT">
                          {t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.PRACTICE_TXT')}
                        </MenuItem>,
                        <MenuItem key="6" value="ADVANCE_TXT">
                          {t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.ADVANCE_TXT')}
                        </MenuItem>

                      ]}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={3} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeUseWith(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('TEMPLATE_TEXT.FILTER.USE_WITH.LABEL')}: ${t(`TEMPLATE_TEXT.FILTER.USE_WITH.${filterUseWith}`)}`}
                      label={t('TEMPLATE_TEXT.FILTER.USE_WITH.LABEL')}
                      selectId="select-use-with"
                      value={filterUseWith}
                      labelId="label-use-with"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('TEMPLATE_TEXT.FILTER.USE_WITH.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="BRANCH">
                          {t('TEMPLATE_TEXT.FILTER.USE_WITH.BRANCH')}
                        </MenuItem>,
                        <MenuItem key="3" value="USER">
                          {t('TEMPLATE_TEXT.FILTER.USE_WITH.USER')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('TEMPLATE_TEXT.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={templateTexts.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </CardHeader>

      <ModalCustom
        size={'sm'}
        title={templateTextId ? t('TEMPLATE_TEXT.TITLE_UPDATE') : t('TEMPLATE_TEXT.TITLE_CREATE')}
        component={
          <div className="pb-2">
            {templateTextId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('TEMPLATE_TEXT.TITLE_STATUS')}</Form.Label>
                  <Form.Check type="switch" id="custom-switch" onChange={() => setTemplateTextStatus(templateTextStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')} checked={templateTextStatus === 'ACTIVE' ? true : false} style={{ marginLeft: 'auto' }} />
                </Form>
              </div>
            ) : (
              ''
            )}

            <Col>
              <BasicSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setTextType(value)
                    clearErrorMessage()
                  }
                }}
                required={true}
                label={t('TEMPLATE_TEXT.FILTER.MESSAGE_TYPE.LABEL')}
                selectId="sl-text-type"
                value={textType}
                labelId="label-text-type"
                options={textTypeSelect.map((obj: any) => {
                  return (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  )
                })}
                helperText={errorMessage.MASSAGE_TYPE_REQUIRED}
              />
            </Col>
            <Col className="mt-3">
              <BasicSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setUseWith(value)
                    clearErrorMessage()
                  }
                }}
                required={true}
                label={t('TEMPLATE_TEXT.FILTER.USE_WITH.LABEL')}
                selectId="sl-use-with"
                value={useWith}
                labelId="label-use-with"
                options={useWithSelect.map((obj: any) => {
                  return (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  )
                })}
                helperText={errorMessage.CONTINUE_USED_REQUIRED}
              />
            </Col>
            <Col style={{ display: useWith === 'USER' ? '' : 'none' }} className="mt-3">
              <AutocompleteSelect
                labelId="userSelecte"
                inputLabel={t('TEMPLATE_TEXT.INPUT.USER_USED')}
                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                options={users}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box key={option.name} component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onchange={(e, value: any) => {
                  if (value) {
                    setUserSelect(value.value)
                    setUser(value)
                    clearErrorMessage()
                  }
                }}
                onInputChange={(e, value: any) => {
                  loadDoctor(value)
                }}
                errMessage={errorMessage.USER_USED_REQUIRED}
                disableClearable
                required
                value={user}
              />
            </Col>
            <Col className="mt-3">
              <Divider className="mx-0 mb-3" sx={{ backgroundColor: 'transparent', borderColor: `${colors.lightGray} !important`, opacity: 1 }} />

              <InputTextarea
                required={true}
                label={t('TEMPLATE_TEXT.INPUT.MESSAGE')}
                value={message}
                helperText={errorMessage.MASSAGE_STRING_BASE || errorMessage.MASSAGE_STRING_EMPTY || errorMessage.MASSAGE_MAXIMUM_LENGTH || errorMessage.MASSAGE_ANY_REQUIRED}
                onchange={(e) => {
                  setMessage(e.target.value)
                  clearErrorMessage()
                }}
              />
            </Col>
          </div>
        }
        onReset={resetForm}
        onSubmit={templateTextId ? submitEdit : submit}
        textBtnCancel={t('TREATMENT_RIGHT.BUTTON.CANCEL')}
        textBtnConfirm={templateTextId ? t('TEMPLATE_TEXT.BUTTON.UPDATE') : t('TEMPLATE_TEXT.BUTTON.SAVE')}
      />
    </div>
  )
}
