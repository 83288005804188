import { menuSeconds } from 'constants/menus'
import FormMedicines from 'features/warehouses/medicines/form-medicines'
import Medicines from 'features/warehouses/medicines/medicines'
import FormProducts from 'features/warehouses/products/form-products'
import Products from 'features/warehouses/products/products'
import routeInventory from 'routes/warehouses/inventory.routes'
import routeAdjustment from 'routes/warehouses/adjustment.routes'
import FormMaterials from 'features/warehouses/materials/form-materials'
import Materials from 'features/warehouses/materials/materials'

const routeWarehouses = [
  {
    name: menuSeconds.MEDICINE.name,
    key: menuSeconds.MEDICINE.key,
    textName: 'เพิ่มรายการยา',
    active: false,
    path: menuSeconds.MEDICINE.path + '/create',
    component: FormMedicines,
    appBar: true
  },
  {
    name: menuSeconds.MEDICINE.name,
    key: menuSeconds.MEDICINE.key,
    textName: 'แก้ไขรายการยา',
    active: false,
    path: menuSeconds.MEDICINE.path + '/:id',
    component: FormMedicines,
    appBar: true
  },
  {
    ...menuSeconds.MEDICINE,
    component: Medicines,
    appBar: true
  },
  {
    name: menuSeconds.PRODUCT.name,
    key: menuSeconds.PRODUCT.key,
    textName: 'เพิ่มรายการสินค้าและผลิตภัณฑ์',
    active: false,
    path: menuSeconds.PRODUCT.path + '/create',
    component: FormProducts,
    appBar: true
  },
  {
    name: menuSeconds.PRODUCT.name,
    key: menuSeconds.PRODUCT.key,
    textName: 'แก้ไขรายการสินค้าและผลิตภัณฑ์',
    active: false,
    path: menuSeconds.PRODUCT.path + '/:id',
    component: FormProducts,
    appBar: true
  },
  {
    ...menuSeconds.PRODUCT,
    component: Products,
    appBar: true
  },
  ...routeInventory,
  ...routeAdjustment,
  {
    ...menuSeconds.MATERIAL,
    component: Materials,
    appBar: true
  },
  {
    ...menuSeconds.MATERIAL_ADD,
    component: FormMaterials,
    appBar: true
  },
  {
    ...menuSeconds.MATERIAL_EDIT,
    component: FormMaterials,
    appBar: true
  },
]

export default routeWarehouses
