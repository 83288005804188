/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

import { Typography } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
    marginTop: 26,
    fontSize: 34,
    fontWeight: 600,
    color: colors.themeMainColor,
    [theme.breakpoints.up('xl')]: {
        fontSize: 38,
    }
}))

export const Subtitle = styled(Typography)(({ theme }) => ({
    marginTop: 8,
    fontSize: 16,
    fontWeight: 400,
    color: colors.themeMainColor,
    [theme.breakpoints.up('xl')]: {
        fontSize: 18,
    }
}))
