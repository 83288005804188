import { routeName } from 'routes/routes-name'
import { menuFirsts, menuSeconds } from 'constants/menus'

import Home from 'features/home/home'
import Dashboard from 'features/dashboard/dashboard'
import Welcome from 'features/page/welcome'
import UpdateSidebar from 'features/update/update'
import routePersonnelManagements from 'routes/settings/personnel-managements.routes'
import routeDefaultUsers from 'routes/settings/default-users.routes'
import routeDefaultProducts from 'routes/settings/default-products.routes'
import routeFinances from 'routes/settings/finances.routes'
import routeDefaults from 'routes/settings/defaults.routes'
import routeDefaultLabs from 'routes/settings/default-labs.routes'
import routeTreatmentCourses from 'routes/settings/treatment-courses.routes'
import routeTreatmentPlans from 'routes/settings/treatment-plans.routes'
import routeTreatments from 'routes/settings/treatments.routes'
import routeBranchServicePoint from 'routes/settings/branch-service-point.routes'
import routeDoctorFeeManagements from 'routes/settings/doctor-fee-managements.routes'
import routeCreditFeeManagements from 'routes/settings/credit-fee-managements.routes'
import routeWarehouses from 'routes/warehouses/warehouses.routes'
import routeImportProduct from 'routes/warehouses/import-product.routes'
import routeTreatmentRight from 'routes/settings/treatment-right.routes'
import routeDoctorSchedule from 'routes/counter/doctor-schedule.routes'
import routeRecallLists from 'routes/counter/recall-list.routes'
import routeAppointment from 'routes/counter/appointment.routes'
import routeDoctorAppointmentSchedule from 'routes/counter/doctor-appointment-schedule.routes'
import routePatientManagement from 'routes/counter/patient-management.routes'
import routeRegister from 'routes/counter/register.routes'
import routeTreatmentsIndex from 'routes/treatment/treatment.routes'
import routeClinicManagements from 'routes/admin/clinic-managements.routes'
import routeCustom from 'routes/admin/custom.routes'
import routeFinancesMain from 'routes/finance/finances.routes'
import routeXray from 'routes/xray/xray.routes'
import routeLabOrder from 'routes/lab-order.routes'
import routeRetrospectiveOrder from 'routes/retrospectiveOrder/retrospectiveOrder.routes'
import routeApplication from 'routes/application/application.routes'
import routeReport from 'routes/report.routes'
import routeManageDoctorFee from 'routes/counter/manage-doctor-fee.routes'
import routeDoctorNote from 'routes/settings/dotor-note.routes'
import routeRetrospectiveEdit from 'routes/counter/retrospectiveEdit.routes'
import routeManageDoctorLabFee from 'routes/counter/manage-doctor-lab-fee.routes'
import routeCheckIn from 'routes/check-in/check-in'
import routeApproval from 'routes/application/approval.routes'

const routes = [
  {
    key: menuSeconds.HOME.key,
    name: menuSeconds.HOME.name,
    textName: menuSeconds.HOME.textName,
    component: Home,
    path: '/',
    appBar: true
  },
  {
    key: menuFirsts.WELCOME.key,
    name: menuFirsts.WELCOME.name,
    textName: menuFirsts.WELCOME.textName,
    component: Welcome,
    path: routeName.welcome,
    appBar: true
  },
  {
    key: menuFirsts.WELCOME.key,
    name: menuFirsts.WELCOME.name,
    textName: menuFirsts.WELCOME.textName,
    component: UpdateSidebar,
    path: routeName.update,
    appBar: true
  },
  {
    key: menuSeconds.DASHBOARD.key,
    packageKey: menuSeconds.DASHBOARD.packageKey,
    name: menuSeconds.DASHBOARD.name,
    textName: menuSeconds.DASHBOARD.textName,
    component: Dashboard,
    path: routeName.dashboard,
    appBar: true
  },
  ...routeDefaults,
  ...routeDefaultProducts,
  ...routeFinances,
  ...routePersonnelManagements,
  ...routeDefaultUsers,
  ...routeDefaultLabs,
  ...routeTreatmentCourses,
  ...routeTreatmentPlans,
  ...routeTreatments,
  ...routeBranchServicePoint,
  ...routeDoctorFeeManagements,
  ...routeWarehouses,
  ...routeImportProduct,
  ...routeTreatmentRight,
  ...routeRegister,
  ...routeDoctorSchedule,
  ...routeDoctorAppointmentSchedule,
  ...routeAppointment,
  ...routePatientManagement,
  ...routeRetrospectiveEdit,
  ...routeTreatmentsIndex,
  ...routeClinicManagements,
  ...routeCustom,
  ...routeFinancesMain,
  ...routeTreatmentsIndex,
  ...routeXray,
  ...routeLabOrder,
  ...routeRetrospectiveOrder,
  ...routeApplication,
  ...routeReport,
  ...routeRecallLists,
  ...routeManageDoctorFee,
  ...routeManageDoctorLabFee,
  ...routeDoctorNote,
  ...routeCreditFeeManagements,
  ...routeCheckIn,
  ...routeApproval
]

export default routes
